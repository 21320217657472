/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import { Card, CardBody, Col } from 'reactstrap';
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
// import {groupBy} from '../../../factories/utils';
import DashBoardActiveSimTable from './DashBoardActiveSimTable';
import { Bar } from 'react-chartjs-2';
import { HorizontalBar } from 'react-chartjs-2';
import { getActivatedSimDashboard } from '../../../../../redux/actions/userAction';
import { loadDashBoardLayout, updateDashBoardLayout, loadDashBoardLayoutList, setDefaultLayout } from '../../../../../redux/actions/dashboardActions';
import { groupBy } from '../../../../../factories/utils';
import PlaylistEditIcon from 'mdi-react/PlaylistEditIcon'
import GridLayout from 'react-grid-layout';
// import { Responsive, WidthProvider } from "react-grid-layout";
import RGL, { WidthProvider } from "react-grid-layout";

// import SimplePackDeviceEditIcon from './SimplePackDeviceEditIcon';
// import {SquareEditOutlineIcon} from 'mdi-react';

import { toast } from 'react-toastify';

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
const ReactGridLayout = WidthProvider(RGL);



class ActiveUsageCard extends Component {
  constructor(props) {

    super(props);
    this.state = {

    }

  }

  componentDidMount() {

  }


  //-----------------------------------------------------------------
  render() {

    return (
      <Fragment>
        <DashBoardActiveSimTable
            type={this.props.pusageType}
            filterIdentifierValueSort={this.props.filterIdentifierValueSort}
            filterDeviceNameSort={this.props.filterDeviceNameSort}
            filterClientNameSort={this.props.filterClientNameSort}
            filterStatusSort={this.props.filterStatusSort}
          page={this.props.page}
          pageSize={this.props.pageSize}
          filterDataUsageSort={this.props.filterDataUsageSort}
          exportSimListData={this.props.exportSimListData}
          LoadUsageDate={this.props.LoadUsageDate}
          filterStartTime={this.props.filterStartTime}
          filterEndTime={this.props.filterEndTime}
          onPageChange={this.props.onPageChange}
          resellerCode={this.props.currentUserObj.resellerCode}
          getActivatedSimDashboard={this.props.getActivatedSimDashboard}
          dashboardActivatedSimList={this.props.dashboardActivatedSimList}
          dashboardActivatedSimListLoading={this.props.dashboardActivatedSimListLoading}
        />
      </Fragment>
    );
  }
}

// export default DashboardCard;

function mapStateToProps(state) {


  const deviceTypes = state.mapboard.types;
  // const mapData = state.mapboard.mapData;
  const mapData = state.mapboard.assetData;
  const dashboardActivatedSimList = state.user.dashboardActivatedSimList;
  const dashboardActivatedSimListLoading = state.user.dashboardActivatedSimListLoading;
  const currentLayout = state.dashboard.currentLayout;
  const currentLayoutList = state.dashboard.currentLayoutList;
  const actionLogs = state.job.actionLogs;
  const { currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, currentDeviceDetailsList, error, message, type } = state.asset;
  const props = {
    deviceTypes, mapData, currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, currentDeviceDetailsList, error,
    message, type, currentLayout, actionLogs, currentLayoutList, dashboardActivatedSimList,dashboardActivatedSimListLoading
  };
  return props;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    loadDashBoardLayout, updateDashBoardLayout, loadDashBoardLayoutList, setDefaultLayout,
    getActivatedSimDashboard

  }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveUsageCard));
