/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, InputGroupText, InputGroupAddon, InputGroup, Form, Input } from 'reactstrap';
import CloseIcon from 'mdi-react/CloseIcon';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class ClientPlanAddOn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feature: { id: 0, itemId: 0, type: "feature", description: "", price: "", status: 1 },
            position: 0,
            nonNegative: false //negative is allowed
        }
    }
    componentDidMount() {

        this.setState({
            feature: this.props.feature,
            position: this.props.index,
            nonNegative: this.props.nonNegative ? this.props.nonNegative : false

        })
    }
    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            feature: nextProps.feature,
            position: nextProps.index,
            nonNegative: nextProps.nonNegative ? nextProps.nonNegative : false
        })
    }
    validateDescription = () => {
        return (this.state.feature.description && this.state.feature.description != null)
    }
    validatePrice = () => {
        return ((!isNaN(this.state.feature.price)) && parseFloat(this.state.feature.price) > 0)

    }
    onInputChange = (event) => {

        // if (event.target.name === 'value') {
        //     this.state.feature[event.target.name] = event.target.value;
        // } else {
        //     this.state.feature[event.target.name] = event.target.value;
        // }
        this.state.feature[event.target.name] = event.target.value;
        this.props.onFeatureChange(this.state.feature, this.state.position);
    };

    isDecimal = (value) => {
        console.log(`isDecimal`, !!(value % 1))
        return !!(value % 1);
    }


    render() {

        let formRow = ''

        switch (this.state.feature.type) {
            case "overage":
                formRow =
                    <Row>
                        <Col md={5}>
                            <Input
                                type="text"
                                name="description"
                                id="description"
                                className="form-control"
                                placeholder="Title"
                                onChange={this.onInputChange}
                                value={this.state.feature.description}
                            />
                            <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 50 Character Limit</span>

                        </Col>
                        <Col md={3}>
                            <Input
                                name="value"
                                id="value"
                                className="form-control"
                                placeholder="Data (MB)"
                                onChange={this.onInputChange}
                                value={this.state.feature.value}
                                invalid={(this.state.feature.value == null || this.state.feature.value == "") ? false : isNaN(parseFloat(this.state.feature.value)) || (parseFloat(this.state.feature.value) < 0)}

                            />
                            <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> Data Usage (MB)</span>
                        </Col>
                        <Col md={3}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="text"
                                    name="price"
                                    id="price"
                                    className="form-control"
                                    placeholder="Price"
                                    onChange={this.onInputChange}
                                    value={this.state.feature.price}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={1}>
                            <Button color="custom" type="button" onClick={() => { this.props.onDeleteFeature(this.state.position) }}>
                                <CloseIcon />
                            </Button>
                        </Col>
                    </Row>
                break;
            case "sms":
                formRow =
                    <Row>
                        <Col md={5}>
                            <Input
                                type="text"
                                name="description"
                                id="description"
                                className="form-control"
                                placeholder="Title"
                                onChange={this.onInputChange}
                                value={this.state.feature.description}
                                invalid={!this.state.feature.description}
                            />
                            <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 50 Character Limit</span>

                        </Col>
                        <Col md={3}>
                            <Input
                                name="value"
                                id="value"
                                //type="number"
                                //min="0"
                                className="form-control"
                                placeholder="Count"
                                onChange={this.onInputChange}
                                value={this.state.feature.value}
                                // invalid={!this.state.feature.value}
                                // invalid={isNaN(parseInt(this.state.feature.value) || !Number.isInteger(parseInt(this.state.feature.value)))}
                                invalid={isNaN(parseInt(this.state.feature.value)) || (parseFloat(this.state.feature.value) % 1) > 0
                                    || (parseInt(this.state.feature.value) < 0 || (parseFloat(this.state.feature.value) % 1) < 0)}
                            />
                            <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> SMS Limit</span>
                        </Col>
                        <Col md={3}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="text"
                                    name="price"
                                    id="price"
                                    className="form-control"
                                    placeholder="Price"
                                    onChange={this.onInputChange}
                                    value={this.state.feature.price}
                                    invalid={isNaN(parseFloat(this.state.feature.price)) || (parseFloat(this.state.feature.price) < 0)}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={1}>
                            <Button color="custom" type="button" onClick={() => { this.props.onDeleteFeature(this.state.position) }}>
                                <CloseIcon />
                            </Button>
                        </Col>
                    </Row>
                break;
            default:
                formRow =
                    <Row>
                        <Col md={7}>
                            <Input
                                type="text"
                                name="description"
                                id="description"
                                className="form-control"
                                placeholder="Title"
                                onChange={this.onInputChange}
                                value={this.state.feature.description}
                            />
                            <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 50 Character Limit</span>

                        </Col>
                        <Col md={4}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>$</InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="text"
                                    name="price"
                                    id="price"
                                    className="form-control"
                                    placeholder="Price"
                                    onChange={this.onInputChange}
                                    value={this.state.feature.price}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={1}>
                            <Button color="custom" type="button" onClick={() => { this.props.onDeleteFeature(this.state.position) }}>
                                <CloseIcon />
                            </Button>
                        </Col>
                    </Row>
        }

        return (
            <Form>
                {formRow}
            </Form>
        );
    }
}
export default ClientPlanAddOn;