/* eslint-disable */

import React, { Component, Fragment, useRef, useEffect } from 'react';
import * as d3 from 'd3';
import ReactDOM from 'react-dom';
import moment from 'moment';



function TortoiseHistoryLocation({deviceId, beaconId, position, timestamp}) {
    const ref = useRef(null)
    const tooltipref = useRef(null)
    useEffect(() => {
        const nodeEl = ReactDOM.findDOMNode(ref.current);
        const tooltipEl = ReactDOM.findDOMNode(tooltipref.current);
        const tooltip = d3.select(tooltipEl)
        const node = d3.select(nodeEl)
        node.on('mouseover', ()=>{
            return tooltip.style("visibility", "visible")
        }).on("mouseout", ()=>{
            return tooltip.style("visibility", "hidden")
        })
    }, [])
    const positionVariant =  0 + Math.floor(Math.random()* 50);
    return (
        <Fragment>
            <defs>
                <g id={beaconId} transform="scale(1)" style={{cursor: 'pointer'}}>
                    {/* <rect width="150" height="100" stroke="black" stroke-width="1" fill="black"/> */}
                    {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                    
                    <path d="M2,10a8,8 0 1,0 16,0a8,8 0 1,0 -16,0"/>
                </g>
            </defs>
            <use id="use1" ref={ref} xlinkHref={`#${beaconId}`} x={ position ? position.x + positionVariant : '50'} y={ position ? position.y : '50'} fill="red" />
            <text id="tooltipid" style={{visibility: "hidden"}} ref={tooltipref} x={ position? position.x + positionVariant : '50'} y={ position? position.y : '50'} fill="black">{`${deviceId} at ${moment(timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}`}</text>

        </Fragment>
    )
}

export default TortoiseHistoryLocation
