/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Button } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AssetClassBoard from './AssetClassBoard';
import DeviceTypeBoard from './DeviceTypeBoard';



// -------------------- Material UI Tab -------------------------
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

// backOnClick = (e) => {
//     window.location.href = '/#/pages/dashboard';
// }

// ---------------------------- Reactstrapt Tab -----------------------------
// function NavTabs({ activeTab, toggleTab }) {
//     return (
//        <Nav tabs style={{
//           border: 'none'
//        }}>
//           <NavItem>
//              <NavLink style={{
//                 border: 'none'
//              }}
//                 className={classnames({ active: activeTab === '1' })}
//                 onClick={() => { toggleTab('1'); }}>
//                 <h4><b>Asset Location</b></h4>
//              </NavLink>
//           </NavItem>
//           <NavItem>
//              <NavLink style={{
//                 border: 'none'
//              }}
//                 className={classnames({ active: activeTab === '2' })}
//                 onClick={() => { toggleTab('2'); }}>
//                 <h4><b>Device Location</b></h4>
//              </NavLink>
//           </NavItem>
//        </Nav>
//     )
//  }

export function NavTabs({ tabArrs, activeTab, toggleTab, styles, handleBackClick }) {
    return (
        <Nav tabs style={{
            border: 'none',
            ...styles
        }}>
            {handleBackClick && <Button variant="primary" color="secondary" onClick={handleBackClick} > {'< Back'} </Button> }
            {tabArrs && tabArrs.map((tab, index) => {
                let tabInd = index + 1
                return (
                    <NavItem style={{ cursor: 'pointer' }}>
                        <NavLink style={{
                            /*border: 'none'*/
                        }}

                            className={classnames({ active: activeTab == tabInd.toString() })}
                            onClick={() => { toggleTab(tabInd.toString()); }}>

                            <h4><b>{tab}</b></h4>
                        </NavLink>

                    </NavItem>
                )
            })}

        </Nav>
    )
}


// --------------------------------------------------------------------------
class MainBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            activeTab: '1',
        }
    }

    componentDidMount() {
        // const script = document.createElement('script');
        // script.src = 'https://cdnjs.cloudflare.com/ajax/libs/OverlappingMarkerSpiderfier/1.0.3/oms.min.js';
        // script.async = true;
        // document.body.appendChild(script);

        //  
        // this.renderCluster();
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue,
        });
    };

    renderMaterialUiTab = () => {
        return (
            <div>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        aria-label="simple tabs example"
                        indicatorcolor="custom"
                        textcolor="custom"
                    >

                        {/* <Tab value={0} label="Asset Location"  {...a11yProps('one')}/>
                        <Tab value={1} label="Device Location" {...a11yProps('two')}/> */}

                        <Tab value={0} label="Asset Location" />
                        <Tab value={1} label="Device Location" />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    {/* Panal 1 */}
                    <AssetClassBoard />
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    Panal 2
                </TabPanel>

            </div>
        );
    }


    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab })
        }
    }

    renderReactstrapTab = () => {
        return (
            <Fragment>
                <NavTabs tabArrs={['Device Location']} activeTab={this.state.activeTab} toggleTab={this.toggleTab} />
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" >
                        <Card>
                            <CardBody>
                                {/* Panal 1 */}
                                <AssetClassBoard />
                            </CardBody>
                        </Card>
                    </TabPane>
                    <TabPane tabId="2" >
                        <Card>
                            <CardBody>
                                {/* Panal 2 */}
                                <DeviceTypeBoard />
                            </CardBody>
                        </Card>
                    </TabPane>
                </TabContent>
            </Fragment>
        )
    }

    render() {
        return (
            <Container md={12}>

                <Row md={12}>
                    <Col>
                        {/* {this.renderMaterialUiTab()} */}
                        {this.renderReactstrapTab()}
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default MainBoard;

