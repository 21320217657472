/* eslint-disable */
import React from "react";
import Card from "react-credit-cards";
import { Row, Col } from 'reactstrap';
import './CCWalletStyle.scss';

/**
 * 
 * @param {*} props 
 * @returns 
 */
const PaymentWallet = (props) => {
    if (props.type == null) {
        return null;
    }
    return (
        <div>
            {
                (props.type == "CC" && props.card != null) ?
                    (props.card.name !== null && props.card.number !== null) ?
                        <div className="wallet-style wallet-active" style={{ cursor: "pointer" }} onClick={() => { props.onClick(props.card) }}>
                            <Row>
                                <Col md={8} >
                                    <span>Credit Card Ending **** {props.card.number.substring(props.card.number.length - 4)}</span>
                                </Col>
                                <Col md={8} style={{ paddingTop: "10px" }}>
                                    <span>Optional Name: {props.card.nickName}</span>
                                </Col>
                                <Col md={4}>
                                    <div className="wallet-list" style={{ maxWidth: "10px" }}>
                                        <Card
                                            number={props.card.number}
                                            preview={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            {
                                (props.onRadioClick != null && props.isAdmin) ?
                                    <Row>
                                        <Col md={8}>
                                            <label>
                                                <input type="radio" checked={(props.card != null) ? props.card.selected == "Y" : false} onClick={() => props.onRadioClick(props.card)} /> {props.RadioLabel}
                                            </label>
                                        </Col>
                                    </Row>
                                    :
                                    null
                            }
                            {
                                (props.onRadioClick != null && !props.isAdmin) ?
                                    <Row>
                                        <Col md={8}>
                                            {(props.card != null) ? (props.card.selected == "Y") ? <span style={{ fontWeight: "bold" }}>Set as default</span> : null : null}
                                        </Col>
                                    </Row>
                                    :
                                    null
                            }
                        </div>
                        :
                        null
                    : (props.type == "CC" && props.card == null) ?
                        <div className="wallet-style wallet-active" style={{ cursor: "pointer" }} onClick={() => { props.onClick(props.card) }}>
                            <Row>
                                <Col md={8}>
                                    <span>Add New Credit Card</span>
                                </Col>
                                <Col md={4}>
                                    <div className="wallet-list" style={{ maxWidth: "10px" }}>
                                        <Card
                                            number={"****************"}
                                            preview={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        :
                        <div className="wallet-style wallet-active">
                            <Row>
                                <Col md={8}>
                                    <span>{props.type}</span>
                                </Col>
                            </Row>
                            <br />
                            {
                                (props.onRadioClick != null && props.isAdmin) ?
                                    <Row>
                                        <Col md={8}>
                                            <label>
                                                <input type="radio" checked={(props.card != null) ? props.card.selected == "Y" : false} onClick={() => props.onRadioClick(props.card)} /> {props.RadioLabel}
                                            </label>
                                        </Col>
                                    </Row>
                                    :
                                    null
                            }
                            {
                                (props.onRadioClick != null && !props.isAdmin) ?
                                    <Row>
                                        <Col md={8}>
                                            {(props.card != null) ? (props.card.selected == "Y") ? <span style={{ fontWeight: "bold" }}>Set as default</span> : null : null}
                                        </Col>
                                    </Row>
                                    :
                                    null
                            }
                        </div>
            }
        </div>
    )

}
export default PaymentWallet;