/* eslint-disable */
import {
    DASHBOARD_GET_LAYOUT_SUCCEED,
    DASHBOARD_GET_LAYOUT_FAILED, 
    DASHBOARD_GET_LAYOUT_LIST_SUCCEED,
    DASHBOARD_GET_LAYOUT_LIST_FAILED,
    DASHBOARD_GET_ASSET_MAP_SUCCEED,
    DASHBOARD_GET_ASSET_MAP_FAILED,
    DASHBOARD_GET_DEVICE_DATA_SUCCEED,
    DASHBOARD_GET_DEVICE_DATA_FAILED,
    DASHBOARD_UPDATE_LAYOUT_SUCCEED,
    DASHBOARD_UPDATE_LAYOUT_FAILED,
    DASHBOARD_GET_DEVICE_MSG_COUNT_SUCCEED,
    DASHBOARD_GET_DEVICE_MSG_COUNT_FAILED,
    DASHBOARD_GET_DEVICE_LIST_SUCCEED,
    DASHBOARD_GET_DEVICE_LIST_FAILED,
    DASHBOARD_GET_ASSETS_DATA_SUCCEED,
    DASHBOARD_GET_ASSETS_DATA_FAILED,
    DASHBOARD_GET_ASSETS_LIST_SUCCEED,
    DASHBOARD_GET_ASSETS_LIST_FAILED,
    DASHBOARD_GET_ASSETS_LIST_CLEAR,
    DASHBOARD_GET_THRESHOLD_SUCCEED,
    DASHBOARD_GET_THRESHOLD_FAILED,
    DASHBOARD_RECURSIVE_START,
    DASHBOARD_GET_RECURSIVE_DATA_SUCCEED,
    DASHBOARD_GET_RECURSIVE_DATA_FAILED,
    DASHBOARD_GET_ATTRIBUTE_CHART_SUCCEED,
    DASHBOARD_GET_ATTRIBUTE_CHART_FAILED,
    PUBSUB_UPDATE_DEVICE,
} from '../../constants/actionTypes';

const initState = {   
    currentLayout:[],    
    currentLayoutList:[],
    msgCounts:null,
    assetmapdata:{},
    devicemapdata:{},
    attributeValue:{},
    attributeChart:{},
    threshold:{},
    pubsubDevice: null,
    deviceList:[],
    assetList:[],
    error: 0,
    message: '',
    type: '',
}

export default function(state = initState, action){
    let dashName;
    switch(action.type){
        case DASHBOARD_GET_LAYOUT_SUCCEED:


        case DASHBOARD_UPDATE_LAYOUT_SUCCEED:
            console.log('currentLayout in dashboard reducer is DASHBOARD_UPDATE_LAYOUT_SUCCEED and action.payload is',action.payload)
            return {
                ... state,
                error: 0,
                currentLayout: action.payload,
                message: 'Get layout successfully',
                type: action.type
            }
        case DASHBOARD_GET_LAYOUT_LIST_SUCCEED:
            return{
                ... state,
                error: 0,
                currentLayoutList: action.payload,
                message: 'Get layout list successfully',
                type: action.type
            }
        case DASHBOARD_GET_ASSET_MAP_SUCCEED:
            dashName = action.name;
            let assetmapdata = {
                ...state.assetmapdata,
                [dashName] : action.payload,
            }
            return {
                ... state,
                error: 0,
                assetmapdata: assetmapdata,
                message: 'Get map data successfully',
                type: action.type
            }
        case DASHBOARD_GET_DEVICE_DATA_SUCCEED:
            dashName = action.name;
            let devicemapdata = {
                ...state.devicemapdata,
                [dashName] : action.payload,
            }
            return {
                ... state,
                error: 0,
                devicemapdata: devicemapdata,
                message: 'Get map data successfully',
                type: action.type
            }
        case DASHBOARD_GET_DEVICE_MSG_COUNT_SUCCEED:
            return{
                ... state,
                error: 0,
                msgCounts: action.payload,
                message: 'Get message count data successfully',
                type: action.type
            }
        case DASHBOARD_GET_DEVICE_LIST_SUCCEED:
            return{
                ... state,
                error: 0,
                deviceList: action.payload,
                message: 'Get message count data successfully',
                type: action.type
            }
        case  DASHBOARD_GET_ASSETS_DATA_SUCCEED:
            dashName = action.name;
            let attributeValue = {
                ...state.attributeValue,
                [dashName] : action.payload,
            }
            return {
                ... state,
                error: 0,
                attributeValue: attributeValue,
                message: 'Get farm attribute successfully',
                type: action.type
            }
        case DASHBOARD_GET_THRESHOLD_SUCCEED:
            dashName = action.name;
            let threshold = {
                ...state.threshold,
                [dashName] : action.payload,
            }
            return {
                ... state,
                error: 0,
                threshold: threshold,
                message: 'Get farm attribute successfully',
                type: action.type
            }
        case PUBSUB_UPDATE_DEVICE:
            return {
                ... state,
                error: 0,
                pubsubDevice: action.payload,
                type: action.type
            }
        case DASHBOARD_GET_ASSETS_LIST_SUCCEED:
            return {
                ...state,
                error: 0,
                assetList: action.payload,
                type: action.type,
            }
        case DASHBOARD_GET_ASSETS_LIST_CLEAR:
            return {
                ...state,
                error: 0,
                assetList: [],
                type: action.type,
            }
        case DASHBOARD_GET_ASSETS_LIST_FAILED:
            return {
                ... state,
                error: 1,
                assetList: [],
                message: action.error,
                type: action.type
            }
        case DASHBOARD_GET_RECURSIVE_DATA_SUCCEED:
            dashName = action.name;
            let assetdata = {
                ...state.assetdata,
                [dashName] : action.payload,
            }
            return {
                ...state,
                error: 0,
                assetdata : assetdata,
                message: 'Get dashboard asset data successfully',
                type: action.type
            }
        case DASHBOARD_GET_ATTRIBUTE_CHART_SUCCEED:
            dashName = action.name;
            let attributeChart = {
                ...state.attributeChart,
                [dashName] : action.payload,
            }
            return {
                ...state,
                error: 0,
                attributeChart : attributeChart,
                message: 'Get dashboard asset data successfully',
                type: action.type
            }
        case DASHBOARD_GET_ATTRIBUTE_CHART_FAILED:
        case DASHBOARD_GET_RECURSIVE_DATA_FAILED:
        case DASHBOARD_GET_THRESHOLD_FAILED:
        case DASHBOARD_GET_ASSETS_DATA_FAILED:    
        case DASHBOARD_GET_DEVICE_LIST_FAILED:
        case DASHBOARD_GET_DEVICE_MSG_COUNT_FAILED:
        case DASHBOARD_GET_LAYOUT_FAILED:
        case DASHBOARD_UPDATE_LAYOUT_FAILED:
        case DASHBOARD_GET_ASSET_MAP_FAILED:
        case DASHBOARD_GET_DEVICE_DATA_FAILED:
            return {
                ... state,
                error: 1,
                message: action.error,
                type: action.type
            }
        default:
            return {...state};
    }
}
