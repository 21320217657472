/* eslint-disable */
import React, { Component, Fragment } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router-dom';
// import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Col, Row, Card, CardBody, CardTitle, CardText, Label, Input, InputGroup, Button, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup } from 'reactstrap';
import Select from 'react-select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StepTemplate from './StepTemplate';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';

import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import AtIcon from 'mdi-react/AtIcon';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
import { PlusCircleIconButton, RemoveCircleIconButton } from '../../../shared/components/Button/IconButton';
import { getActionAlert, upsertActionAlert, clearActionAlert } from '../../../redux/actions/jobAction';
import { getCurrentUser, hasRole } from '../../../factories/auth';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

class StepNotification extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            value: 0,
            weekOption: [
                { value: 1, label: 'Mon' },
                { value: 2, label: 'Tue' },
                { value: 3, label: 'Wed' },
                { value: 4, label: 'Thu' },
                { value: 5, label: 'Fri' },
                { value: 6, label: 'Sat' },
                { value: 7, label: 'Sun' },
            ],
            selectedPeriod: null,
            callBackParams: [],
            customStyles: {}
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }

    componentDidMount() {


        this.props.getActionAlert({ actionid: this.props.actionId });
    }
    componentWillUnmount() {

        this.props.clearActionAlert();
    }

    // componentWillReceiveProps(nextProps){}

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.activeStep === 3) {
            nextProps.history.push(`/pages/action`);
        }

        if (nextProps.actionAlert !== prevState.actionAlert) {
            // nextProps.getActionAlert({actionid: nextProps.actionId});
            let email = '';
            let subject = '';
            let emailMessage = '';
            let phoneNo = '';
            let smsMessage = '';
            let fromTime = '';
            let toTime = '';
            let sendOnChange = '';
            let messageInterval = 0;
            let callbackUrl = '';
            let alertAuthenticationList = [{
                field: "",
                value: "",
                newAuth: true,
                conceal: false,
                newParam: true
            }];
            let authenticationRequired = 0;
            let authenticationUrl = "";
            let selectedPeriod = [];
            let callBackParams = [];
            if (nextProps.actionAlert.length > 0) {
                let alertPart = nextProps.actionAlert[0];
                sendOnChange = alertPart.sendOnChange ? alertPart.sendOnChange : 0;
                messageInterval = alertPart.messageInterval ? alertPart.messageInterval : 0;
                fromTime = alertPart.starttime ? alertPart.starttime.substring(0, 5) : '';
                toTime = alertPart.endtime ? alertPart.endtime.substring(0, 5) : '';
                let weekdayArr = alertPart.weekday ? JSON.parse(alertPart.weekday) : [];
                weekdayArr.forEach((item, index) => {
                    let selectedItem = prevState.weekOption.find((day, dindex) => (day.label == item));
                    if (selectedItem) {
                        selectedPeriod.push(selectedItem);
                    }
                });
            }
            nextProps.actionAlert.map((item, index) => {
                if (item.alerttype === '01') {
                    email = item.destination ? item.destination : '';
                    subject = item.title ? item.title : '';
                    emailMessage = item.content ? item.content : '';
                } else if (item.alerttype === '02') {
                    phoneNo = item.destination ? item.destination : '';
                    smsMessage = item.content ? item.content : '';
                } else if (item.alerttype === '03') {
                    callbackUrl = item.destination ? item.destination : '';
                    authenticationUrl = item.authenticationUrl;
                    if (item.authenticationRequired == 1) {
                        authenticationRequired = item.authenticationRequired;
                        if (item.alertAuthenticationList) {
                            if (item.alertAuthenticationList.length != 0) {
                                item.alertAuthenticationList.map((obj) => obj.newParam = false);
                                alertAuthenticationList = item.alertAuthenticationList
                            }
                        }
                    }
                    if (item.content) {
                        let parameterObj = JSON.parse(item.content);
                        {
                            Object.entries(parameterObj).map(([key, value], index) => {
                                let param = {
                                    label: key,
                                    value: value
                                }
                                callBackParams.push(param);
                            })
                        }
                    }
                }
            });
            return {
                selectedPeriod: selectedPeriod,
                fromTime: fromTime,
                toTime: toTime,
                actionAlert: nextProps.actionAlert,
                email: email,
                subject: subject,
                emailMessage: emailMessage,
                phoneNum: phoneNo,
                smsMessage: smsMessage,
                callbackUrl: callbackUrl,
                authenticationRequired: authenticationRequired,
                authenticationUrl: authenticationUrl,
                alertAuthenticationList: alertAuthenticationList,
                sendOnChange: sendOnChange,
                messageInterval: messageInterval,
                callBackParams: callBackParams,
            }
        } else {
            return null;
        }
    }

    onInputChange = (event) => {
        let value = event.target.value;
        if (event.target.name == "messageInterval") {
            if (event.target.value <= 0) {
                value = 0
            }
        }
        this.setState({
            [event.target.name]: value
        });
    };
    handleInputClick = (event) => {
        this.setState({
            [event.target.name]: event.target.checked
        });
    }

    handlePeriodsSelect = (value) => {
        this.setState({
            selectedPeriod: value,
        });
    }

    renderTimePeriod = () => {
        return (
            <Form>
                <Row>
                    <Col md={12}>
                        <FormGroup row >

                            <Col md={2}>
                                <Label for="period" style={{ display: 'inline', width: "100%" }}>Time periods</Label>
                                {/* <Input type="select" name="period" id="period">
                                    <option>Mon</option>
                                    <option>Tue</option>
                                    <option>Web</option>
                                    <option>Thu</option>
                                    <option>Fri</option>
                                    <option>Sat</option>
                                    <option>Sun</option>
                                </Input> */}
                                <Select
                                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                                    isMulti
                                    name="period"
                                    options={this.state.weekOption}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={this.state.selectedPeriod}
                                    onChange={this.handlePeriodsSelect}
                                    styles={this.state.customStyles}
                                />
                            </Col>
                            <Col md={2}>
                                <Label for="fromTime" style={{ display: 'inline', width: "100%" }}>From</Label>
                                <Input
                                    type="time"
                                    name="fromTime"
                                    id="fromTime"
                                    placeholder="time placeholder"
                                    onChange={this.onInputChange}
                                    value={this.state.fromTime}
                                />
                            </Col>
                            <Col md={2}>
                                <Label for="fromTime" style={{ display: 'inline', width: "100%" }}>To</Label>
                                <Input
                                    type="time"
                                    name="toTime"
                                    id="toTime"
                                    placeholder="time placeholder"
                                    onChange={this.onInputChange}
                                    value={this.state.toTime}
                                />
                            </Col>
                            <Col md={2}>
                                <Label for="messageInterval" style={{ display: 'inline', width: "100%" }}>Message Interval (Min)</Label>
                                <Input
                                    type="number"
                                    name="messageInterval"
                                    id="messageInterval"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onChange={this.onInputChange}
                                    value={this.state.messageInterval}

                                />
                            </Col>
                            <Col md={2}>
                                <div>Send Only On Value Change</div>
                                <div style={{ paddingLeft: "20px" }}>
                                    <Input
                                        type="checkbox"
                                        name="sendOnChange"
                                        id="sendOnChange"
                                        onClick={this.handleInputClick}
                                        checked={this.state.sendOnChange}
                                    />
                                </div>
                            </Col>

                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    validatePhoneNumber = () => {

        // var regex =  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        // var regex = /^\+?[0-9]{10,14}$/;
        var regex = /^[0-9]{10,14}(;[0-9]{10,14})*$/;
        return (this.state.phoneNum && regex.test(this.state.phoneNum));
    }

    validateEmail = () => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(;(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/;
        return (this.state.email && regex.test(this.state.email));
    }

    validateUrl = () => {
        // var regex =/^(((https|http)?:\/\/)?([a-z0-9]+[.])|(www.))\\w+[.|\\/]([a-z0-9]{0,})?[[.]([a-z0-9]{0,})]+((/[\\S&&[^,;\u4E00-\u9FA5]]+)+)?([.][a-z0-9]{0,}+|/?)$/;
        var pattern = new RegExp('^((https|http)?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + //port
            '(\\?[;&amp;a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i');
        return (this.state.callbackUrl && pattern.test(this.state.callbackUrl));
    }


    renderEmailNotification = () => {
        return (
            <Form>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="email" style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >Email To</Label>
                                <Input
                                    type="email"
                                    id="email"
                                    name="email"
                                    width="100%"
                                    onChange={this.onInputChange}
                                    value={this.state.email}
                                    valid={this.state.value == 0 && this.validateEmail()}
                                    invalid={this.state.value == 0 && !this.validateEmail()}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label
                                    for="subject"
                                    style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                    Subject
                                </Label>
                                <Input type="text" id="subject" name="subject" width="100%" onChange={this.onInputChange} value={this.state.subject}
                                    valid={this.state.value == 0 && this.state.subject}
                                    invalid={this.state.value == 0 && !this.state.subject}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label
                                    for="emailMessage"
                                    style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                    Message
                                </Label>
                                <Input type="textarea" id="emailMessage" name="emailMessage" width="100%" onChange={this.onInputChange} value={this.state.emailMessage}
                                    valid={this.state.value == 0 && this.state.emailMessage}
                                    invalid={this.state.value == 0 && !this.state.emailMessage}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    renderMsmNotification = () => {
        return (
            <Form>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label
                                    for="phoneNum"
                                    style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                    Phone number
                                </Label>
                                <Input
                                    type="text"
                                    id="phoneNum"
                                    name="phoneNum"
                                    width="100%"
                                    onChange={this.onInputChange}
                                    placeholder={'Country Code + Area Code + Number (Number Only No Space)'}
                                    style={{ fontSize: '14px' }}
                                    value={this.state.phoneNum}
                                    valid={this.state.value == 1 && this.validatePhoneNumber()}
                                    invalid={this.state.value == 1 && !this.validatePhoneNumber()}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label
                                    for="endTime"
                                    style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                    Message
                                </Label>
                                <Input type="textarea" id="smsMessage" name="smsMessage" width="100%" onChange={this.onInputChange} value={this.state.smsMessage}
                                    valid={this.state.value == 1 && this.state.smsMessage}
                                    invalid={this.state.value == 1 && !this.state.smsMessage}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
    onParameterAuthAddClick = (e) => {
        e.preventDefault();
        let newParameter = { field: '', value: '', newAuth: true, conceal: false, newParam: true };
        this.setState(prevState => {
            prevState.alertAuthenticationList.push(newParameter);
            return ({
                alertAuthenticationList: prevState.alertAuthenticationList,
            })
        });
    }
    onParameterAuthRemoveClick = (e, index) => {
        e.preventDefault();

        this.setState(prevState => {

            prevState.alertAuthenticationList.splice(index, 1);
            return ({
                alertAuthenticationList: prevState.alertAuthenticationList,
            })
        });
    }

    revealAuthParameter = (index) => {
        this.setState(prevState => {

            prevState.alertAuthenticationList[index]["conceal"] = false;
            prevState.alertAuthenticationList[index]["value"] = "";
            prevState.alertAuthenticationList[index]["newAuth"] = true;
            return ({
                alertAuthenticationList: prevState.alertAuthenticationList,
            })
        });
    }

    onParamAuthInputChange = (e, index) => {
        let label = e.target.name;
        let value = e.target.value;
        this.setState(prevState => {

            prevState.alertAuthenticationList[index][label] = value;
            return ({
                alertAuthenticationList: prevState.alertAuthenticationList,
            })
        });

    }

    onParameterAddClick = (e) => {
        e.preventDefault();
        let newParameter = { label: '', value: ''};
        this.setState(prevState => {
            prevState.callBackParams.push(newParameter);
            return ({
                callBackParams: prevState.callBackParams,
            })
        });
    }

    onParameterRemoveClick = (e, index) => {
        e.preventDefault();

        this.setState(prevState => {

            prevState.callBackParams.splice(index, 1);
            return ({
                callBackParams: prevState.callBackParams,
            })
        });
    }

    onParamInputChange = (e, index) => {
        let label = e.target.name;
        let value = e.target.value;
        this.setState(prevState => {

            prevState.callBackParams[index][label] = value;
            return ({
                callBackParams: prevState.callBackParams,
            })
        });

    }

    renderCallbackTrigger = () => {
        let paramArr = [];
        // if (this.state.callBackParams){
        //     {Object.entries(this.state.callBackParams).map(([label, value], index) => {
        //         let param = {
        //             label: label,
        //             value: value
        //         }
        //         paramArr.push(param);
        //     })}
        // }
        paramArr = this.state.callBackParams;
        return (
            <Form>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            <Col md={12}>
                                <Input
                                    type="checkbox"
                                    name="authenticationRequired"
                                    id="authenticationRequired"
                                    onClick={this.handleInputClick}
                                    checked={this.state.authenticationRequired}
                                />
                                <Label
                                    for="authenticationRequired"
                                    style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                    Authentication Required
                                </Label>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                {(this.state.authenticationRequired == 1) ?
                    <div style={{ paddingBottom: "50px" }}>
                        <Row>
                            <Col md={8}>
                                <FormGroup row >
                                    {/* <Label for="startTime" md={2} >From</Label> */}
                                    <Col md={12}>
                                        <Label
                                            for="authenticationUrl"
                                            style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                            Authentication URL
                                        </Label>
                                        <Input
                                            type="text"
                                            id="authenticationUrl"
                                            name="authenticationUrl"
                                            width="100%"
                                            onChange={this.onInputChange}
                                            value={this.state.authenticationUrl}

                                        >
                                        </Input>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <>
                            <Row>
                                <Col md={2}>
                                    <Label
                                        for="callbackurl"
                                        style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                        Authentication Parameters
                                    </Label>
                                </Col>
                                {/* <Col md={3}></Col> */}
                                <Col md={1}>
                                    <PlusCircleIconButton
                                        onClick={(e) => this.onParameterAuthAddClick(e)}
                                    />
                                </Col>
                            </Row>
                            {this.state.alertAuthenticationList.map((alertAuthentication, index) => {
                                return (
                                    <Row key={index}>
                                        <Col md={4}>
                                            <Label
                                                for="callbackurl"
                                                style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                                Key
                                            </Label>
                                            <Input
                                                type="text"
                                                id="callbackurl"
                                                name="field"
                                                width="100%"
                                                disabled={!alertAuthentication.newParam}
                                                onChange={(e) => { this.onParamAuthInputChange(e, index) }}
                                                value={alertAuthentication.field}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Label
                                                for="callbackurl"
                                                style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                                Value
                                            </Label>
                                            <InputGroup>
                                                <Input
                                                    type="text"
                                                    id="callbackurl"
                                                    name="value"
                                                    width="100%"
                                                    disabled={alertAuthentication.conceal}
                                                    // onChange={this.onParamInputChange} 
                                                    onChange={(e) => { this.onParamAuthInputChange(e, index) }}
                                                    value={!alertAuthentication.conceal ? alertAuthentication.value : "conceal"}
                                                />
                                                <Button disabled={!alertAuthentication.conceal} color="custom" style={{ padding: '6px' }} onClick={(e) => this.revealAuthParameter(index)}>
                                                    <SquareEditOutlineIcon />
                                                </Button>
                                            </InputGroup>
                                        </Col>
                                        <Col md={1}>
                                            <Label
                                                for="callbackurl"
                                                style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                            </Label>
                                            <RemoveCircleIconButton
                                                onClick={(e) => this.onParameterAuthRemoveClick(e, index)}
                                            />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </>
                    </div>
                    : null}

                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label
                                    for="callbackurl"
                                    style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                    Callback URL
                                </Label>
                                <Input
                                    type="text"
                                    id="callbackurl"
                                    name="callbackUrl"
                                    width="100%"
                                    onChange={this.onInputChange}
                                    value={this.state.value == 2 && this.state.callbackUrl}
                                    invalid={this.state.value == 2 && !this.state.callbackUrl}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Label
                            for="callbackurl"
                            style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                            Parameters
                        </Label>
                    </Col>
                    {/* <Col md={3}></Col> */}
                    <Col md={1}>
                        <PlusCircleIconButton
                            onClick={(e) => this.onParameterAddClick(e)}
                        />
                    </Col>
                </Row>
                {
                    paramArr.map((item, index) => {
                        return (
                            <Row key={index}>
                                <Col md={4}>
                                    <Label
                                        for="callbackurl"
                                        style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                        Key
                                    </Label>
                                    <Input
                                        type="text"
                                        id="callbackurl"
                                        name="label"
                                        width="100%"
                                        onChange={(e) => { this.onParamInputChange(e, index) }}
                                        value={item.label}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Label
                                        for="callbackurl"
                                        style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                        Value
                                    </Label>
                                    <Input
                                        type="text"
                                        id="callbackurl"
                                        name="value"
                                        width="100%"
                                        // onChange={this.onParamInputChange} 
                                        onChange={(e) => { this.onParamInputChange(e, index) }}
                                        value={item.value}
                                    />
                                </Col>
                                <Col md={1}>
                                    <Label
                                        for="callbackurl"
                                        style={{ fontFamily: "Poppins", fontSize: '14px', display: 'inline', width: "100%" }} >
                                    </Label>
                                    <RemoveCircleIconButton
                                        onClick={(e) => this.onParameterRemoveClick(e, index)}
                                    />
                                </Col>
                            </Row>
                        )
                    })
                }
            </Form >
        )
    }

    handleChange = (event, newValue) => {
        // alert(newValue);
        this.setState({
            value: newValue,
        });
    };

    renderTab = () => {
        return (
            <div >
                {/* <AppBar position="static"> */}
                <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">

                    <Tab icon={<AtIcon />} aria-label="email" />
                    <Tab icon={<MessageTextOutlineIcon />} aria-label="message" />
                    <Tab icon={<LinkVariantIcon />} aria-label="callback" />
                </Tabs>
                {/* </AppBar> */}
                <TabPanel value={this.state.value} index={0}>
                    {this.renderEmailNotification()}
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    {this.renderMsmNotification()}
                </TabPanel>
                <TabPanel value={this.state.value} index={2}>
                    {this.renderCallbackTrigger()}
                </TabPanel>
            </div>
        );
    }

    validEmailPart = () => {
        let isEmailEmpty = !this.state.email || this.state.email.length === 0;
        let isSubjectEmpty = !this.state.subject || this.state.subject.length === 0;
        let isEmailMsgEmpty = !this.state.emailMessage || this.state.subject.emailMessage === 0;
        let checkEmail = (!isEmailEmpty) && (this.validateEmail()) && (!isSubjectEmpty) && (!isEmailMsgEmpty);
        return (checkEmail);
    }

    validSmsPart = () => {
        let isPhoneNum = !this.state.phoneNum || this.state.phoneNum.length === 0;
        let isSmsMsgEmpty = !this.state.smsMessage || this.state.subject.smsMessage === 0;
        let checkSMS = (!isPhoneNum) && (this.validatePhoneNumber()) && (!isSmsMsgEmpty);
        return (checkSMS);
    }

    validataNotification = () => {
        let isEmailEmpty = !this.state.email || this.state.email.length === 0;
        let isPhoneNum = !this.state.phoneNum || this.state.phoneNum.length === 0;
        let isSubjectEmpty = !this.state.subject || this.state.subject.length === 0;
        let isEmailMsgEmpty = !this.state.emailMessage || this.state.subject.emailMessage === 0;
        let isSmsMsgEmpty = !this.state.smsMessage || this.state.subject.smsMessage === 0;
        let checkEmail = (!isEmailEmpty) && (this.validateEmail()) && (!isSubjectEmpty) && (!isEmailMsgEmpty);
        let checkSMS = (!isPhoneNum) && (this.validatePhoneNumber()) && (!isSmsMsgEmpty);
        let isCallBackEmpty = !this.state.callbackUrl || this.state.callbackUrl.length === 0;

        // return (!isEmailEmpty || !isPhoneNum) && (isEmailEmpty || this.validatePhoneNumber()) && (isPhoneNum || this.validateEmail()) && (isEmailEmpty);





        let result = {
            valid: false,
            msg: ''
        }
        result.valid = ((!isEmailEmpty || !isPhoneNum) && ((isEmailEmpty || checkEmail) && (isPhoneNum || checkSMS))) || !isCallBackEmpty;
        if (!result.valid) {
            if (isEmailEmpty && isPhoneNum && isCallBackEmpty)
                result.msg = 'Please enter email address, phone number or callback url.'
            else if (!isEmailEmpty && !checkEmail)
                result.msg = 'Please enter valid email address, subject and message.'
            else if (!isPhoneNum && !checkSMS)
                result.msg = "Please enter valid phone number and message";
        }
        return result;
    }

    handleCompleteClick = (e) => {
        let valCheck = this.validataNotification();
        if (!valCheck.valid) {
            toast.error(valCheck.msg);
            return;
        }
        let weekday = [];
        let fromTime = '00:00:00';
        let toTime = '00:00:00';
        if (this.state.selectedPeriod) {
            this.state.selectedPeriod.forEach((item, index) => {
                weekday.push(item.label);
            });
        }
        let timeRegex = /^\d{2}[:]\d{2}$/;
        if (this.state.fromTime && timeRegex.test(this.state.fromTime)) {
            fromTime = this.state.fromTime + ':00';
        }
        if (this.state.toTime && timeRegex.test(this.state.toTime)) {
            toTime = this.state.toTime + ':00';
        }


        // let alertArray = [
        //     {
        //         weekday: JSON.stringify(weekday),
        //         starttime: fromTime,
        //         endtime: toTime,
        //         actionid: this.props.actionId,
        //         alerttype: "01",
        //         destination: this.state.email,
        //         title: this.state.subject,
        //         content: this.state.emailMessage,
        //     },
        //     {
        //         weekday: JSON.stringify(weekday),
        //         starttime: fromTime,
        //         endtime: toTime,
        //         actionid: this.props.actionId,
        //         alerttype: "02",
        //         destination: this.state.phoneNum,
        //         title: this.state.subject,
        //         content: this.state.smsMessage,
        //     },
        // ];

        let alertArray = [];
        let unique = this.state.sendOnChange ? 1 : 0
        if (this.validEmailPart()) {
            let newAlert = {
                weekday: JSON.stringify(weekday),
                starttime: fromTime,
                endtime: toTime,
                actionid: this.props.actionId,
                alerttype: "01",
                destination: this.state.email,
                title: this.state.subject,
                content: this.state.emailMessage,
                sendOnChange: unique,
                messageInterval: this.state.messageInterval

            }
            alertArray.push(newAlert);
        }

        if (this.validSmsPart()) {
            let newAlert = {
                weekday: JSON.stringify(weekday),
                starttime: fromTime,
                endtime: toTime,
                actionid: this.props.actionId,
                alerttype: "02",
                destination: this.state.phoneNum,
                title: this.state.subject,
                content: this.state.smsMessage,
                sendOnChange: unique,
                messageInterval: this.state.messageInterval

            }
            alertArray.push(newAlert);
        }

        if (this.state.callbackUrl && this.state.callbackUrl.length > 0) {
            let paramObj = {};
            let content = '';
            if (this.state.callBackParams && this.state.callBackParams.length > 0) {
                this.state.callBackParams.forEach((item, index) => {
                    if (item.label && item.label.length > 0) {
                        paramObj = { ...paramObj, [item.label]: item.value }
                    }
                });
            }
            if (Object.keys(paramObj).length > 0) {
                content = JSON.stringify(paramObj);
            }
            let authenticate = this.state.authenticationRequired ? 1 : 0
            let newAlert = {
                weekday: JSON.stringify(weekday),
                starttime: fromTime,
                endtime: toTime,
                actionid: this.props.actionId,
                alerttype: "03",
                destination: this.state.callbackUrl,
                title: '',
                content: content,
                sendOnChange: unique,
                messageInterval: this.state.messageInterval,
                authenticationRequired: authenticate,
                authenticationUrl: this.state.authenticationRequired ? this.state.authenticationUrl : "",
                alertAuthenticationList: this.state.alertAuthenticationList

            }
            alertArray.push(newAlert);
        }

        if (alertArray.length > 0) {
            this.props.upsertActionAlert(alertArray);
        }
        // this.props.upsertActionAlert(alertArray);

        // this.props.onNext();
    }

    render() {
        return (
            <StepTemplate
                classes={this.props.classes}
                canGoBack={this.props.canGoBack}
                canGoForward={this.props.canGoForward}
                onBack={this.props.onBack}
                // onNext = {this.props.onNext}
                onNext={this.handleCompleteClick}
                onCancel={this.props.onCancel}
                text={this.props.text}
            >
                {this.renderTimePeriod()}
                {this.renderTab()}
            </StepTemplate>
        );
    }
}

// export default StepNotification; 

function mapStateToProps(state) {
    const props = { ...state.job };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getActionAlert, upsertActionAlert, clearActionAlert }, dispatch);
};

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
);

export default enhance(StepNotification);