/* eslint-disable */
import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, CardBody, Col } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { EditTextButton } from '../../../shared/components/Button/IconButton';
import { getPageContent } from '../../../redux/actions/userAction';
import { getCurrentUser, hasRole } from '../../../factories/auth';

import ClientActiveSIMModal from './ClientActiveSIMModal';
import ClientActiveDevice from './ClientActiveDevice';
import { toast } from 'react-toastify';
class ClientActiveSIMBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            showModal: false,
            selectedSIM: null
        }
        this.userObj = getCurrentUser();
    }
    async componentDidMount() {
        this.props.getPageContent({ "content": "Client" });

        await this.props.getDeviceList({"customerId": this.userObj.customerId, "resellerId":this.props.selectedRow.id})
        // console.log("ClientActiveSIMBoard > componentDidMount > this.props.selectedRow: ", this.props.selectedRow, this.userObj)
        // console.log("ClientActiveSIMBoard > componentDidMount > this.props.SIMTypeList: ", this.props.SIMTypeList)
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            isAdmin: nextProps.isAdmin
        })
    }

    planFormatter = (cell, row) => {
        if (cell != null) {
            return `${cell.itemName}`;
        }
        else {
            if (row.status == 2) { //hard code suspended
                return <span>suspended</span>;
            }
            return <span>no plan</span>;
        }
    }
    planPriceFormatter = (cell, row) => {
        if (cell != null) {
            return '$' + `${cell.unitPrice}`;
        }
        else {
            if (row.status == 2) { //hard code suspended
                return <span>suspended</span>;
            }
            return <span>0</span>;
        }
    }
    nameFormatter = (cell) => {
        if (cell != null) {
            return `${cell.businessName}`
        }
    }
    carrierNameFormatter = (cell) => {
        if (cell != null) {
            return `${cell.carrierName}`
        }
    }
    dateFormatter = (cell, row) => {
        if (cell != null) {
            var date = new Date(cell)
            var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            return (`${str}`);
        }
    }

    csvFormatter = (cell, row) => {
        return cell + '\t';
    }

    cellButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={''} onClick={(e) => { this.onOpen(row) }} />
            </div>
        );
    };
    onOpen = (sim) => {
        this.setState({
            showModal: true,
            selectedSIM: sim
        })
    }
    onClose = () => {
        this.setState({
            showModal: false,
            selectedSIM: null
        })
    }
    onActivate = (param) => {
        this.props.activateMultipleSIM({ "sims": param }).then(() => {
            this.props.getFullSIMList({ "companyId": this.props.selectedRow.companyId, "resellerId": this.props.selectedRow.id, "customerId": null, "fullInventory": "false" });
        });
        this.onClose()
    }

    onBeforeSaveCell = (row, cellName, cellValue) => {
        if (this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'USER', 'CLIENTADMIN', 'CLIENTUSER'])) {
            if (cellValue != "") {
                return true;
            }
            else {
                toast.error("name can't be empty")
                return false;
            }
        }
        return false;

    }
    onAfterSaveCell = (row, cellName, cellValue) => {
        this.props.setSimName({ "id": row.inventoryId, "resellerId": row.resellerId, "itemName": cellValue })
    }

    renderPaginationPanel = (props) => {
        let dropdown = props.components.sizePerPageDropdown;
        let beginning;
        let totalData;
        beginning = (props.currPage - 1) * dropdown.props.currSizePerPage + 1;
        totalData = (parseInt(beginning) + parseInt(dropdown.props.currSizePerPage)) - 1;
        return (
            <>
                {
                    (this.props.activeSIMList.length == 0) ?
                        <>
                            <Col md={12} xs={12} sm={12} lg={12} style={{ display: "block", fontSize: "14px" }}>
                                <span style={{ float: "right" }}> 0 - 0 of 0</span>
                            </Col>
                        </>
                        : <>
                            <Col md={6} xs={6} sm={6} lg={6}>
                                {props.components.sizePerPageDropdown}
                            </Col>
                            <Col md={6} xs={6} sm={6} lg={6} style={{ display: "block", fontSize: "14px" }}>

                                <span style={{ float: "right" }}>{props.components.pageList}</span>
                                <span style={{ float: "right", fontSize: "14px", verticalAlign: "text-top", marginRight: "10px", marginTop: "9px" }}> {beginning} - {totalData} of {this.props.activeSIMList.length}</span>
                            </Col>

                        </>

                }
            </>
        );
    }


    renderPaginationPanelSuspend = (props) => {
        let dropdown = props.components.sizePerPageDropdown;
        let beginning;
        let totalData;
        beginning = (props.currPage - 1) * dropdown.props.currSizePerPage + 1;
        totalData = (parseInt(beginning) + parseInt(dropdown.props.currSizePerPage)) - 1;
        return (
            <>
                {
                    (this.props.suspendedSIMList.length == 0) ?
                        <>
                            <Col md={12} xs={12} sm={12} lg={12} style={{ display: "block", fontSize: "14px" }}>
                                <span style={{ float: "right" }}> 0 - 0 of 0</span>
                            </Col>
                        </>
                        : <>
                            <Col md={6} xs={6} sm={6} lg={6}>
                                {props.components.sizePerPageDropdown}
                            </Col>
                            <Col md={6} xs={6} sm={6} lg={6} style={{ display: "block", fontSize: "14px" }}>

                                <span style={{ float: "right" }}>{props.components.pageList}</span>
                                <span style={{ float: "right", fontSize: "14px", verticalAlign: "text-top", marginRight: "10px", marginTop: "9px" }}> {beginning} - {totalData} of {this.props.suspendedSIMList.length}</span>
                            </Col>

                        </>

                }
            </>
        );
    }

    render() {

        const { pageContentList } = this.props

        let cellEditProp = {
            mode: 'click',
            blurToSave: true,
            beforeSaveCell: this.onBeforeSaveCell, // a hook for before saving cell
            afterSaveCell: this.onAfterSaveCell  // a hook for after saving cell
        };

        let options = {
            noDataText: 'No Active Devices Found',
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
            paginationPanel: this.renderPaginationPanel,
        }
        let suspendOptions = {
            noDataText: "No suspended Devices Found",
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
            paginationPanel: this.renderPaginationPanelSuspend,
        }
        let displayText;
        let deviceNameDisplayText;
        let itemDescriptionDisplayText;
        if (pageContentList.includes("Active Devices") /* TEISA */
            && pageContentList.includes("Device ID")
            && pageContentList.includes("Client Order")
            && pageContentList.includes("Client Order Name")) {
            displayText = ['Active Reseller', 'Device ID'];
            deviceNameDisplayText = ['Order', '11%']
            itemDescriptionDisplayText = ['Order Name']
        } else {
            displayText = ['Active Device(s)', 'Identifier Value'];
            deviceNameDisplayText = ['Device Name', '15%']
            itemDescriptionDisplayText = ['Device Type']
        }

        return (
            <Card>
                <CardBody className="mainCardBody">
                    <ClientActiveSIMModal
                        pageContentList={pageContentList}
                        showModal={this.state.showModal}
                        selectedSIM={this.state.selectedSIM}
                        selectedRow={this.props.selectedRow}
                        consumerList={this.props.consumerList}
                        ServicePlanList={(this.state.selectedSIM != null) ? this.props.ServicePlanList.filter(plan => plan.carrier.carrierId == this.state.selectedSIM.carrierId) : []}
                        BURelationshipList={this.props.BURelationshipList}
                        onActivate={this.onActivate}
                        onClose={this.onClose}
                    />
                    <h3>{displayText[0]}</h3>

                    <br />
                    <BootstrapTable
                        data={this.props.activeSIMList}
                        tableHeaderClass='header_white'
                        options={options}
                        bordered={false}
                        height='100%'
                        pagination={true}
                        exportCSV
                        csvFileName='Active_Devices.csv'
                    >

                        <TableHeaderColumn row='0' rowSpan='1' dataField='devicename' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} sortFunc={this.props.OnSortChange} width={deviceNameDisplayText[1]} csvHeader='Device Name'>{deviceNameDisplayText[0]}</TableHeaderColumn>
                        <TableHeaderColumn
                            row='0' rowSpan='1'
                            dataField='itemDescription'
                            dataAlign='center'
                            dataSort
                            filter={{ type: 'TextFilter', placeholder: ' ' }}
                            caretRender={sortCaret}
                            width="15%"
                            editable={false}
                            hidden={(pageContentList.includes("Item Description *hidden*") /* TEISA */
                                ? false : true)}
                        >
                            {itemDescriptionDisplayText[0]}
                        </TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' isKey={true} dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} editable={false} csvHeader='Identifier Value' csvFormat={this.csvFormatter}>{displayText[1]} </TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='iccid' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} editable={false} hidden={(pageContentList.includes("iccid *hidden*")) ? false : true} csvHeader='ICCID' csvFormat={this.csvFormatter}>ICCID</TableHeaderColumn>
                        {/*<TableHeaderColumn row='0' rowSpan='1' dataField='customer' dataSort filterFormatted dataFormat={this.nameFormatter.bind(this)} dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} width="12%" caretRender={sortCaret} editable={ false }>Consumer</TableHeaderColumn>*/}
                        <TableHeaderColumn row='0' rowSpan='1' dataField='planName' dataAlign='center' filterFormatted filter={{ type: 'TextFilter', placeholder: ' ' }} dataSort width="11%" caretRender={sortCaret} editable={false} csvHeader='Plan'>Plan</TableHeaderColumn>
                        <TableHeaderColumn
                            row='0' rowSpan='1'
                            dataField='planName'
                            dataAlign='center'
                            filterFormatted
                            filter={{ type: 'TextFilter', placeholder: ' ' }}
                            dataSort width="11%"
                            caretRender={sortCaret}
                            editable={false}
                            hidden={(pageContentList.includes("Plan Price *hidden*") /* TEISA */
                                ? false : true)}
                        >
                            Plan Price
                        </TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='carrierName' dataAlign='center' filterFormatted filter={{ type: 'TextFilter', placeholder: ' ' }} hidden={(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN'])) ? false : true} dataSort width="11%" caretRender={sortCaret} editable={false} csvHeader='Carrier Name'>Carrier Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='activeDate' dataAlign='center' filterFormatted filter={{ type: 'TextFilter', placeholder: ' ' }} dataFormat={this.dateFormatter.bind(this)} csvFormat={this.dateFormatter.bind(this)} dataSort width="11%" caretRender={sortCaret} editable={false} csvHeader='Activation'>Activation</TableHeaderColumn>

                        <TableHeaderColumn
                            dataField='Operations'
                            dataAlign="center"
                            dataFormat={this.cellButton.bind(this)}
                            width="9%"
                            editable={false}
                            hidden={pageContentList.includes("HideDeviceItemIdentifierIccidTable") || !hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'CLIENTADMIN']) ? true : false}
                            export={false}
                        >
                            Edit
                        </TableHeaderColumn>
                    </BootstrapTable>

                    {(pageContentList.includes("Suspended SIM Cards *NO*")) ? null : <h3>Suspended Device(s)</h3>}
                    <br />
                    {(pageContentList.includes("DeviceSimPlanCarrierTable *NO*")) ? null : <BootstrapTable
                        data={this.props.suspendedSIMList}
                        tableHeaderClass='header_white'
                        options={suspendOptions}
                        exportCSV
                        csvFileName='Client_Suspended_Devices.csv'

                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        pagination={true}
                        containerStyle={{ fontSize: "1vw" }}
                        expandColumnOptions={{
                        }}
                    >
                        <TableHeaderColumn row='0' rowSpan='1' dataField='devicename' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} sortFunc={this.props.OnSortChange}  editable={false} csvHeader='Device Name'>Device Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' isKey={true} dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} editable={false} csvFormat={this.csvFormatter} csvHeader='Identifier Value'>Identifier Value </TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='iccid' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} editable={false} csvHeader='ICCID' csvFormat={this.csvFormatter}>ICCID</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='planName' dataAlign='center' filterFormatted filter={{ type: 'TextFilter', placeholder: ' ' }} dataSort width="17%" caretRender={sortCaret} editable={false} csvHeader='Plan Name'>Plan Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='carrierName' dataAlign='center' filterFormatted filter={{ type: 'TextFilter', placeholder: ' ' }} dataSort width="17%" caretRender={sortCaret} editable={false} hidden={(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN'])) ? false : true} csvHeader='Carrier Name'>Carrier Name</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='Operations'
                            dataAlign="center"
                            dataFormat={this.cellButton.bind(this)}
                            width="10%"
                            editable={false}
                            hidden={!hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'CLIENTADMIN']) ? true : false}

                            export={false}
                        >
                            Edit
                        </TableHeaderColumn>
                    </BootstrapTable>}

                    {(pageContentList.includes("Client Active Device Table")) ? 
                    <ClientActiveDevice
                        ChangeDeviceStatus={this.props.ChangeDeviceStatus}
                        SIMTypeList={this.props.SIMTypeList}
                        clientActiveDeviceList={this.props.clientActiveDeviceList}
                        itemType={this.props.itemType}
                        registerDevice={this.props.registerDevice}
                    /> 
                    : null}
                </CardBody>
            </Card>
        )
    }

}

function mapStateToProps(state) {
    const { pageContentList } = state.user
    const props = { pageContentList }
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPageContent
    }, dispatch);
};

export default (connect(mapStateToProps, mapDispatchToProps))(ClientActiveSIMBoard);