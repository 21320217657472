/* eslint-disable */
import axios from 'axios';
import {
    USER_LOGIN_SUCESS,
    USER_LOGIN_FAILED,
    USER_ADD_SUCCESS,
    USER_ADD_FAILED,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILED,
    USER_REMOVE_SUCCESS,
    USER_REMOVE_FAILED,
    USER_GET_FAILED,
    USER_ADD_ASSETCLASS_LIST_SUCCESS,
    USER_ADD_ASSETCLASS_LIST_FAILED,
    USER_CHECK_ASSETCLASS_LIST_SUCCESS,
    USER_CHECK_ASSETCLASS_LIST_FAILED,
    USER_ADD_DEVICETYPE_LIST_SUCCESS,
    USER_ADD_DEVICETYPE_LIST_FAILED,
    USER_CHECK_DEVICETYPE_LIST_SUCCESS,
    USER_CHECK_DEVICETYPE_LIST_FAILED,
    USER_GET_COMPANY_LIST_SUCCESS,
    USER_GET_COMPANT_LIST_FAILED,
    USER_GET_COMPANY_DEVICE_TYPES_SUCCESS,
    USER_GET_COMPANY_DEVICE_TYPES_FAILED,
    USER_GET_COMPANY_ASSET_CLASS_SUCCESS,
    USER_GET_COMPANY_ASSET_CLASS_FAILED,
    USER_GET_ROLES_SUCCESS,
    USER_GET_ROLES_FAILED,
    USER_GET_COMPANY_OBJ_SUCCESS,
    USER_GET_COMPANY_OBJ_FAILED,
    USER_UPDATE_COMPANY_OBJ_SUCCESS,
    USER_UPDATE_COMPANY_OBJ_FAILED,
    USER_DELETE_COMPANY_OBJ_SUCCESS,
    USER_DELETE_COMPANY_OBJ_FAILED,
    USER_GET_ALL_COMPANIES_SUCCESS,
    USER_GET_ALL_COMPANIES_FAILED,
    USER_GET_COUNTRY_LIST_SUCCESS,
    USER_GET_PROVINCE_LIST_SUCCESS,
    USER_GET_ACCOUNT_LIST_SUCCESS,
    USER_GET_PLAN_ITEMS_SUCCESS,
    USER_GET_PLAN_ITEMS_FAILED,
    DASHBOARDTEMPLATE_USERLIST_GET_SUCCESS,
    DASHBOARDTEMPLATE_LAYOUT_GET_SUCCESS,
    DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS, DASHBOARDTEMPLATE_DELETE_SUCCESS, DASHBOARDTEMPLATE_NEW_SUCCESS
} from '../../constants/actionTypes';

const initState = {    
    currentUser: {},
    dashboardUserList: [],
    dashboardTemplateLayout: [],
    userAssetClassList:[],
    userDeviceTypeList: [],
    companyList: [],
    companyAll: [],
    planItemsAll: [],
    companyObj: null,
    companyDevicetypeList: [],
    companyAssetClassList: [],
    roleList: [],
    countryList:[],
    provinceList: [],
    isAuthenticated: false,
    error: 0,
    message: '',
    type: '',
    accountList: [],
}

export default function(state = initState, action){
    //  
     
    // alert(action.type);
    //  
    switch (action.type) {
        case USER_LOGIN_SUCESS:
            if (! action.payload) {
                return {
                    ...state,
                    error: 1 ,
                    isAuthenticated: false,
                    message: 'Failed to get the user login info',
                    type: action.type,
                } 
            }
            sessionStorage.setItem('currentUser', action.payload.userName);
            sessionStorage.setItem('email', action.payload.userName);
            sessionStorage.setItem('companyCode', action.payload.companyCode);
            sessionStorage.setItem('department', action.payload.department);
            sessionStorage.setItem('businessUnit', action.payload.businessUnit);
            sessionStorage.setItem('network', action.payload.network);
            sessionStorage.setItem('currentUserObj', JSON.stringify(action.payload));
            sessionStorage.setItem('isAuthenticated', true);
            sessionStorage.setItem('jToken', action.token);
            return {
                ...state,
                currentUser: action.payload,
                isAuthenticated: true,
                error: 0 ,
                message: '',
                type: action.type,
            }
        case USER_LOGIN_FAILED:
            sessionStorage.setItem('currentUser', null);
            sessionStorage.setItem('email', null);
            sessionStorage.setItem('companyCode', null);
            sessionStorage.setItem('department', null);
            sessionStorage.setItem('businessUnit', null);
            sessionStorage.setItem('network', null);
            sessionStorage.setItem('currentUserObj', null);
            sessionStorage.setItem('isAuthenticated', false);
            sessionStorage.setItem('jToken', null);
            return {
                ...state,
                error: 1 ,
                isAuthenticated: false,
                message: action.message,
                type: action.type,
            }        
        case USER_UPDATE_SUCCESS:
            if (!action.payload) {
                return {
                    ...state,
                    error: 1 ,
                    message: 'Failed to get the user login info',
                    type: action.type,
                } 
            }
            // sessionStorage.setItem('currentUser', action.payload.userName);
            return {
                ...state,
                currentUser: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }
        case USER_ADD_SUCCESS:
        case USER_REMOVE_SUCCESS:
            if (!action.payload) {
                return {
                    ...state,
                    error: 1 ,
                    message: 'Failed to get the user login info',
                    type: action.type,
                } 
            }
            // sessionStorage.setItem('currentUser', action.payload.userName);
            return {
                ...state,
                // currentUser: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }

        case DASHBOARDTEMPLATE_USERLIST_GET_SUCCESS:
            return {
                ...state,
                dashboardUserList: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }



        case DASHBOARDTEMPLATE_LAYOUT_GET_SUCCESS:
            return {
                ...state,
                dashboardTemplateLayout: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }
        case DASHBOARDTEMPLATE_NEW_SUCCESS:
            return {
                ...state,
                dashboardTemplateNewResult: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }
        case DASHBOARDTEMPLATE_DELETE_SUCCESS:
            return {
                ...state,
                dashboardTemplateDeleteResult: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }
        case DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS:
            return {
                ...state,
                dashboardTemplateDeleteResult: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }

        case USER_CHECK_ASSETCLASS_LIST_SUCCESS:
             
             
            return {
                ...state,
                userAssetClassList: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }
        case USER_CHECK_DEVICETYPE_LIST_SUCCESS:
            return {
                ...state,
                userDeviceTypeList: action.payload,
                error: 0 ,
                message: '',
                type: action.type,
            }
        
        case USER_CHECK_ASSETCLASS_LIST_FAILED:
            return {
                ...state,
                userAssetClassList: null,
                error: 1 ,
                message: action.message,
                type: action.type,
            }
        case USER_CHECK_DEVICETYPE_LIST_FAILED:
            return {
                ...state,
                userDeviceTypeList: null,
                error: 1 ,
                message: action.message,
                type: action.type,
            }
        case USER_ADD_ASSETCLASS_LIST_SUCCESS:
        case USER_ADD_DEVICETYPE_LIST_SUCCESS:       
            return {
                ...state,
                error: 0 ,
                message: '',
                type: action.type,
            }

        case USER_GET_COMPANY_LIST_SUCCESS:
            return{
                ...state,
                companyList: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }
        case USER_GET_ROLES_SUCCESS:
            return{
                ...state,
                roleList: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }
        case  USER_GET_COMPANY_DEVICE_TYPES_SUCCESS:
            return{
                ...state,
                companyDevicetypeList: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }
        case  USER_GET_COMPANY_ASSET_CLASS_SUCCESS:
            return{
                ...state,
                companyAssetClassList: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }
        case  USER_GET_COMPANY_DEVICE_TYPES_FAILED:
            return{
                ...state,
                companyDevicetypeList: [],
                error: 1 ,
                message: action.message,
                type: action.type,
            }
        case  USER_GET_COMPANY_ASSET_CLASS_FAILED:
            return{
                ...state,
                companyAssetClassList: [],
                error: 1 ,
                message: action.message,
                type: action.type,
            }
        case USER_GET_COMPANY_OBJ_SUCCESS:
             
            return{
                ...state,
                companyObj: action.payload[0],
                error: 0,
                message: '',
                type: action.type,
            }
        case USER_UPDATE_COMPANY_OBJ_SUCCESS:
            return{
                ...state,
                companyObj: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }
        case USER_DELETE_COMPANY_OBJ_SUCCESS:
            return{
                ...state,
                companyObj: null,
                error: 0,
                message: '',
                type: action.type,
            }
        case USER_GET_COUNTRY_LIST_SUCCESS:
        return{
            ...state,
            countryList: action.payload,
            error: 0,
            message: '',
            type: action.type,
        }
        case USER_GET_PROVINCE_LIST_SUCCESS:
            return{
                ...state,
                provinceList: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }
        case USER_GET_ACCOUNT_LIST_SUCCESS:
            return{
                ...state,
                accountList: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }    
        case USER_GET_ALL_COMPANIES_SUCCESS:
            return{
                ...state,
                companyAll: action.payload,
                error: 0,
                message: '',
                type: action.type,
            }

            case USER_GET_PLAN_ITEMS_SUCCESS:
                return{
                    ...state,
                    planItemsAll: action.payload,
                    error: 0,
                    message: '',
                    type: action.type,
                }   
        case USER_GET_ALL_COMPANIES_FAILED:
        case USER_DELETE_COMPANY_OBJ_FAILED:
        case USER_UPDATE_COMPANY_OBJ_FAILED:
        case USER_GET_COMPANY_OBJ_FAILED:
        case USER_GET_ROLES_FAILED:
        case USER_GET_COMPANT_LIST_FAILED:
        case USER_ADD_FAILED:        
        case USER_UPDATE_FAILED:        
        case USER_REMOVE_FAILED:
        case USER_GET_FAILED:
        case USER_ADD_ASSETCLASS_LIST_FAILED:
        case USER_ADD_DEVICETYPE_LIST_FAILED: 
        // case USER_CHECK_ASSETCLASS_LIST_FAILED:
            return {
                ...state,
                error: 1 ,
                message: action.message,
                type: action.type,
            }
        default:
            return state;
    }
}
// ---------------- data processing helpers ------------------------
export function assetHistoryDataProcessor(AssetData, parameters=null, allAttriList=null){
    let starttime = null;
    let endtime = null;
    if (parameters && parameters.starttime && parameters.endtime) {
        starttime = parameters.starttime;
        endtime = parameters.endtime;
    }
    let reData = null;
    const shownAtrributes = ATTRIBUTE_ORDER.filter(item => (item.selected === true)).map(item => (item.attributename));
    const shownAllAttributes = [...shownAtrributes, 'Alarm'];
    if (Array.isArray(AssetData) && AssetData.length > 0) {
        const assets = AssetData[0].asset;
        if (Array.isArray(assets) && assets.length > 0) {
            reData = {};
            for (let asset of assets) {
                const key = asset.assetid;
                const attrmap = {};
                Array.isArray(asset.device) &&  asset.device.forEach( dev => {
                    Array.isArray(dev.msgs) &&  dev.msgs.forEach((msg, mindex) => {
                        const time = msg.timestamp;
                        msg.msg && Object.entries(msg.msg).forEach(([key, value])=>{
                            if (allAttriList && shownAllAttributes.includes(value.attributename)) {
                                let attriObj = {
                                    assetid: asset.assetid,
                                    assetname: asset.assetname,
                                    attributename: value.attributename,
                                    displayname: value.displayname,
                                    type: ATTRIBUTE_TYPE.DEVICE,
                                    display: value.display,
                                    attritime:msg.timestamp,
                                    timestamp:msg.timestamp,
                                    value: value.value,
                                };
                                allAttriList.push(attriObj);
                                
                            }
                            if (! shownAtrributes.includes(value.attributename)) {
                                return;
                            }
                            const group_key = `${dev.deviceid}:${key}`;
                            if (attrmap[group_key]){
                                attrmap[group_key]['data'].push({x:time, y: value.value});
                                attrmap[group_key]['lasttime']= time;
                                attrmap[group_key]['lastvalue']= value.value;
                            }
                            else{
                                const data = [{x:time, y: value.value}];
                                const lasttime = time;
                                const lastvalue = value.value;
                                const displayname = value.displayname;
                                const type = ATTRIBUTE_TYPE.DEVICE;
                                const attrbase = ATTRIBUTE_ORDER.find(item => (item.attributename === value.attributename));
                                let attritem = {
                                    ...attrbase,
                                    data,
                                    lasttime,
                                    lastvalue,
                                    displayname,
                                    type,
                                    starttime,
                                    endtime,
                                }
                                attrmap[group_key] = attritem;
                            }
                            // if (mindex == dev.msgs.length -1) {
                            //     attrmap[group_key]['lasttime']= time;
                            //     attrmap[group_key]['lastvalue']= value.value;
                            // }
                        });
                    });
                    // reData[key] = attrmap;
                });
                reData[key] = attrmap;
            }
        }
    }

     
    return reData;
}

export function updateAssetHistoryData (history, allAttriList, pubsub, startDateMoment, endDateMoment) {
     

    // assetHistoryObj,
    // allAttriList,

    const starttime = startDateMoment ? startDateMoment.utc().valueOf(): null;
    const endtime = endDateMoment ? endDateMoment.utc().valueOf() : null;
    // let reValue = history;
    let reObj = {};
    reObj.hisoryObj = history;
    reObj.allAttriList = allAttriList;
    if (pubsub && history && history[pubsub.assetid]!= null) {
        const historyClone = JSON.parse(JSON.stringify(history));
        Array.isArray(pubsub.msgs) && pubsub.msgs.forEach(msg => {
            msg.msg && Object.entries(msg.msg).forEach(([key, value])=>{
                if (Array.isArray(allAttriList) && allAttriList.length > 0 && shownAllAttributes.includes(value.attributename)) {
                    
                    let allAttriListClone = allAttriList.slice(0);
                    let firstItem = allAttriListClone[0];
                    if (firstItem.assetid == pubsub.assetid) {
                        let attriObj = {
                            ...firstItem,
                            attributename: value.attributename,
                            displayname: value.displayname,
                            type: ATTRIBUTE_TYPE.DEVICE,
                            display: value.display,
                            value: value.value,
                            attritime:msg.timestamp,
                            timestamp:msg.timestamp,
                        };
                        allAttriListClone.push(attriObj);
                        if (starttime && endtime) {
                            allAttriListClone = allAttriListClone.filter(item => (item.timestamp >= starttime && item.timestamp <= endtime));
                        }
                        reObj.allAttriList = allAttriListClone
                    }
                }
                const group_key = `${pubsub.deviceid}:${key}`;
                if (historyClone[pubsub.assetid][group_key]){
                    let attriObj = historyClone[pubsub.assetid][group_key];
                    attriObj['data'].push({x:msg.timestamp, y: value.value});
                    // if (attriObj['starttime'] && attriObj['endtime']) {
                    //     attriObj['data'] = attriObj['data'].filter(item => (item.x >= attriObj['starttime'] && item.x <= attriObj['endtime']));
                    // }
                    if (starttime && endtime) {
                        attriObj['starttime'] = starttime;
                        attriObj['endtime'] = endtime;
                        attriObj['data'] = attriObj['data'].filter(item => (item.x >= starttime && item.x <= endtime));
                    }
                }
            });
        });

        reObj.hisoryObj = historyClone;
    }
    return reObj;
}

function lightningHourlyCountsProcessor(payload, parameters) {
    let hourlyCount = [];
    if (payload && parameters && parameters.starttime) {
        let timeArray = parameters.starttime.split(':');
        // hourlyCount = action.payload.map((item, index) => ({date:item.time.substr(0,5), yellow: item.yellow, orange: item.orange, red: item.red, green: item.green}));
        if (Array.isArray(timeArray) && timeArray.length > 0) {
            hourlyCount = payload.map((item, index) => ({date:`${timeArray[0]}T${item.time}.000+0000`, yellow: item.yellow, orange: item.orange, red: item.red, green: item.green}));
        }       
    }
    return hourlyCount;
}
