/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
// import Worker from "worker-loader!./videoWorker.jsx";
import { initWebsocketAndAssignRef } from '.././websocket';
import _, { initial } from 'lodash';
import CryptoJS from 'crypto-js'
import { initNVRDecoderWorker } from './initNVRDecodeWorker';
import WebGLPlayer from '.././webgl';
import PCMPlayer from '.././pcmPlayer';
import { NVR_PROXY, WEBSOCKET_PROXY } from '../../../../../constants/appConstants';
import { CMD_PREVIEW, CMD_STOP_PREVIEW } from '../websocket.cmd';
import { webSession } from './nvrXMLRequestsUtils';

export default function NVRPlay({ type, nvrIP, camNumber, nvrChannelList, camName, callback, port, nvr }) {
  //NVR live feed command params include camera channel and streamType
  const decodeWorker = useRef(null)
  const [cmd, setcmd] = useState(null);
  const [paramURL, setparamURL] = useState(null);
  const playType = useRef(type || 'live')
  const taskIDRef = useRef('') // Guid to interact with the device
  const canvasRef = useRef(null)
  // const onopen= useRef(options.onopen)
  // const onsuccess= useRef(options.onsuccess)
  // const onstop= useRef(options.onstop)
  // const onerror= useRef(options.onerror)
  // const ontime= useRef(options.ontime)
  // const onrecordFile= useRef(options.onrecordFile)
  const wasmReady = useRef(false) // wasm Whether the file is loaded successfully
  const videoQueue = useRef([]) // Caching video frame queue
  const maxVideoQueueLength = useRef(8) // Maximum length of cached video frame queue
  const videoWidth = useRef(0)
  const videoHeight = useRef(0)
  const webglPlayer = useRef(null)
  const isDecoding = useRef(false) // Is decoding in progress?
  const isFirstDecod = useRef(false) // Whether to decode for the first time
  const basicRealTime = useRef(0) // Play base real time
  const basicFrameTime = useRef(0) // Play base frame time
  const displayLoopID = useRef(null) // Frame animation playback ID handle
  const playStateRef = useRef('') // Playing status
  const playSpeed = useRef(1) // Playback speed multiple
  const isKeyFramePlay = useRef(false) // Whether to play keyframes
  const frameTimestamp = useRef(0) // current frame time
  const seeking = useRef(false)
  const frameIndex = useRef(0)
  const pageVisibleChangeHandle = useRef(null) // Web page visibility change processing function
  const webPageVisible = useRef(true) // Is the current webpage visible?
  const isEndPlay = useRef(false) // The device has finished transmitting data and is ready to stop playing (finish playing the cached frame data first)
  const notDecodeNumber = useRef(0) // Number of undecoded (buffered) frame data
  const wsRef = useRef(null)
  const PLAY_STATE_PAUSE = 'PAUSE'
  const PLAY_STATE_PLAYING = 'PLAYING'
  const PLAY_STATE_STOP = 'STOP'
  const PLAY_STATE_NEXT_FRAME = 'NEXT_FRAME'
  const DECODE_TYPES = {
    live: 1,
    record: 0
  }
  useEffect(() => {
    if (port && nvrIP && camName && nvrChannelList && nvrChannelList.length > 0 && nvr) {
      let camIndex = nvrChannelList.find(cam => cam.name == camName)
      // console.log("CAM INDEX",camIndex)
      let devId = camIndex ? camIndex.devId : null
      console.log('LIVE FEED FOR', nvr, ' camera ', camName, 'port ', port)
      setparamURL(`${WEBSOCKET_PROXY}?ip=${nvrIP}&port=${port}&sessionId=${webSession(nvr.nvrType+"sessionId")}`)
      let cmd
      if (devId) {
        cmd = CMD_PREVIEW(devId, null, null)
      } else {
        console.warn("Camera name not existed in NVR device list")
      }
      if (cmd) {
        taskIDRef.current = cmd.data.task_id
        setcmd(cmd)
        playStateRef.current = PLAY_STATE_PLAYING
        // if (type==='live')
        // startDownload(callback)
      }
    }
  }, [nvrIP, camName, port])

  useEffect(() => {
    // unmountPlayer()
    if (paramURL && cmd) {
      if (decodeWorker.current) {
        clearFrameList()
      }
      createNVRWebSocket()
    }
  }, [paramURL, cmd])

  useEffect(() => {
    // console.log('NVR PLAY RENDER')
    createNVRDecoder()
    createWebGLPlayer()
    return () => {
      //on component unmount destroy player and close websocket
      unmountPlayer()
    }
  }, [])

  const unmountPlayer = () => {
    // console.log("CLEARING PLAYER ON UNMOUNT")
    if (wsRef.current) {
      wsRef.current.close()
      wsRef.current = null
    }
    //stop download
    stopDownload()
    //stop decode
    stopDecode()
    clearFrameList()
    clearDisplayLoop()
    playStateRef.current = PLAY_STATE_STOP
    if (webglPlayer.current) {
      webglPlayer.current.clear()
    }
    //onstop function
  }


  const createNVRDecoder = () => {
    if (!decodeWorker.current) decodeWorker.current = initNVRDecoderWorker(handleDecoderOnLoad, handleOnVideoFrame, handleOnBufferError, handleOnErrorCode)
  }

  const createNVRWebSocket = () => {
    if (wsRef.current) {
      wsRef.current.close()
    }
    wsRef.current = initWebsocketAndAssignRef(paramURL, "arraybuffer", null, handleSocketOpen, handleSocketMessage, handleSocketClose, handleSocketError)
  }


  /**
* 结束播放
*/
  const endPlay = (data) => {
    // console.log('END PLAY')
    if (isEndPlay.current && notDecodeNumber.current == 0) {
      stopDecode()
      // if (typeof onerror === 'function') {
      //     this.onerror(data.code, data.url)
      // }
      isEndPlay.current = false
    } else {
      setTimeout(function () { endPlay(data) }, 50)
    }
  }

  /**
  * 初始化webgl渲染器
  */
  const createWebGLPlayer = () => {
    webglPlayer.current = new WebGLPlayer(canvasRef.current, { preserveDrawingBuffer: false })
  }

  const handleDecoderOnLoad = () => {
    // console.log('DECODER ON LOAD')
    wasmReady.current = true;
    decodeWorker.current.postMessage({
      cmd: 'init',
      type: DECODE_TYPES[self.type]
    })
  }
  const handleOnVideoFrame = (data) => {
    let frame = data
    notDecodeNumber.current = frame.notDecodeNumber
    onVideoFrame(frame)
  }
  const handleOnBufferError = () => {
    // console.log("BUFFER ERROR")
    stopDecode();
  }
  const handleOnErrorCode = (code, url) => {
    onDecodeError(code)
  }

  const onDecodeError = (code) => {
    console.log("Error decoding data", code)
  }


  const onVideoFrame = function (frame) {
    // console.log('frameIndex: ' + frame.frameIndex, 'frameIndex-chlID: ' + cmdParams.chlID)
    if (type === 'record') {
      // In playback mode, the device needs to be notified to refresh the frame index every 8 frames.
      var frameIndex = frame.frameIndex
      if (frameIndex > 0 && (frameIndex % 8 === 0 || _isKeyFramePlay)) {
        // console.log('刷新帧索引：' + frameIndex, new Date().getTime())
        _refreshPlaybackFrameIndex(frameIndex)
      }
    }
    if (!webPageVisible.current) {
      // console.log('webpage not visible')
      return
    }
    videoQueue.current.push(frame)
    if (videoQueue.current.length > maxVideoQueueLength.current) {
      // console.log('exceed queue length')
      stopDecode()
    }
  }

  const handleSocketOpen = () => {
    // console.log('NVR WEBSOCKET CONNECTED')
  }
  const handleSocketMessage = (e) => {
    try {
      // console.log("received message", e)
      let res = JSON.parse(e)
      let url = res.url
      let resBasic = res.basic || {}
      let resData = res.data || {}
      let dataCode = resData.code

      if (url === "/device/create_connection#response" && dataCode === 0) {
        wsRef.current.send(JSON.stringify(cmd))
      }

      let code = dataCode || resBasic.code
      if (code && code !== 0) {
        // postMessage({
        //   cmd: 'error',
        //   code: code,
        //   taskID: resData.task_id,
        //   url: url
        // })
        if (isEndPlay.current) return
        isEndPlay.current = true
        endPlay()
      }
    } catch (error) { //buffer data received
      if (!isFirstDecod.current) {
        // console.log('error 1')
        startDecode()
        isFirstDecod.current = true
        clearDisplayLoop()
        displayLoop()
        // if (typeof onsuccess.current === 'function') {
        //     onsuccess()
        // }
      }
      if (!isDecoding.current && type === 'live') {
        // console.log('error 2')
        startDecode()
        // clearDisplayLoop()
        // displayLoop()
      }
      decodeWorker.current.postMessage({
        cmd: 'sendData',
        buffer: e
      })
    }
  }
  const handleSocketError = () => {
    console.log('NVR WEBSOCKET ERROR')
  }
  const handleSocketClose = () => {
    console.log('NVR WEBSOCKET CLOSE')
  }

  const startDecode = () => {
    // console.log('START DECODE')
    isDecoding.current = true
    decodeWorker.current.postMessage({
      cmd: 'decodeFrame'
    })
  }

  const stopDecode = () => {
    // console.log('STOP DECODE')
    isDecoding.current = false
    if (decodeWorker.current)
      decodeWorker.current.postMessage({
        cmd: 'stopDecode'
      })
  }

  const stopDownload = () => {
    var cmdParams = null
    if (type === 'live') {
      cmdParams = CMD_STOP_PREVIEW(taskIDRef.current)
    }
    // downloadWorker.postMessage({
    //   cmd: 'stop',
    //   data: JSON.stringify(cmdParams)
    // })
    if (wsRef.current) {
      wsRef.current.send(JSON.stringify(cmdParams))
    }
  }

  const clearFrameList = () => {
    videoQueue.current = []
    if (decodeWorker.current)
      decodeWorker.current.postMessage({
        cmd: 'clear'
      })
  }




  //start download live feed by sending stream request to websocket
  const startDownload = (callback) => {
    if (wasmReady.current) {
      if (wsRef.current) {
        wsRef.current.send(cmd)
      } else {
        console.log("WEBSOCKET NOT CONNECTED OR NOT READY")
        createNVRWebSocket()
      }
      // if (typeof onopen === 'function') {
      //     onopen()
      // }
      typeof callback === 'function' && callback()
    } else {
      setTimeout(function () {
        startDownload(callback)
      }, 100);
    }
  }




  const clearDisplayLoop = () => {
    cancelAnimationFrame(displayLoopID.current)
    displayLoopID.current = null
  }

  const displayLoop = () => {
    if (playStateRef.current === PLAY_STATE_PLAYING || playStateRef.current === PLAY_STATE_NEXT_FRAME) {
      displayLoopID.current = requestAnimationFrame(displayLoop)
    }
    if ((playStateRef.current !== PLAY_STATE_PLAYING && playStateRef.current !== PLAY_STATE_NEXT_FRAME) || videoQueue.current.length === 0 || seeking.current) {
      return
    }
    for (let i = 0; i < 2; i++) {
      let frame = videoQueue.current[0]
      if (displayNextVideoFrame(frame)) {
        videoQueue.current.shift()
        if (playStateRef.current === PLAY_STATE_NEXT_FRAME) {
          if (videoQueue.current.length === 0) {
            _refreshPlaybackFrameIndex(frameIndex.current)
          }
          clearDisplayLoop()
          playStateRef.current = PLAY_STATE_PAUSE
          break
        }
      }

      if (videoQueue.current.length === 0) {
        break
      }
    }
    // When the buffered frame queue is less than half of the maximum buffer, decoding is restarted.
    if (videoQueue.current.length < maxVideoQueueLength.current / 2 && !isDecoding.current) {
      startDecode()
    }
  }

  /**
   * Render the next video frame
   * Only playback for playback rhythm control
   */
  const displayNextVideoFrame = (frame) => {
    // let frame = videoQueue.current[0]
    let currentRealTime = new Date().getTime()
    let currentFrameTime = frame.realTimestamp
    if (!basicFrameTime.current) {
      basicRealTime.current = currentRealTime
      basicFrameTime.current = currentFrameTime
    }
    let intervalReal = currentRealTime - basicRealTime.current
    let intervalFrame = currentFrameTime - basicFrameTime.current
    // if (intervalReal * playSpeed.current < intervalFrame) {
    //     if (typeof ontime === 'function' && !seeking.current) {
    //         this.ontime(frame.realTimestamp)
    //     }
    //     return false
    // } else {
    renderVideoFrame(frame)
    if (typeof ontime === 'function' && !seeking.current) {
      ontime(frame.realTimestamp)
    }
    return true
    // }
  }

  /**
   * Render video frames
   */
  const renderVideoFrame = frame => {
    if (!webglPlayer.current) {
      return
    }
    let buffer = new Uint8Array(frame.buffer)
    let videoBuffer = buffer.slice(0, frame.yuvLen)
    let yLength = frame.width * frame.height;
    let uvLength = (frame.width / 2) * (frame.height / 2);
    videoWidth.current = frame.width
    videoHeight.current = frame.height
    webglPlayer.current.renderFrame(videoBuffer, frame.width, frame.height, yLength, uvLength);
    // Update frame time
    frameTimestamp.current = frame.realTimestamp
  }


  return (
    <>
      <canvas width={700} height={500} id="wasmCanvas" ref={canvasRef}>
      </canvas>
    </>
  )
}

