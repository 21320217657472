/* eslint-disable */
import React, { Component } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_add from '../../../shared/img/icon/add.png';
import icon_edit from '../../../shared/img/icon/edit.png';

import {Redirect} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon'
import {getActions, stepReset, deleteActionAlert, setActionId} from '../../../redux/actions/jobAction';
import PlusIcon from 'mdi-react/PlusIcon';
import {EditTextButton, DeleteTextButton} from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class ActionList extends Component {
    constructor(props){
        super(props);
        this.state={
            data: [
                {
                    actionid: 1,
                    actionname: 'action_test',
                    actiontype: 'Alert',
                    geofencename: 'geo_fence_1',
                    rulename: 'rule_name',
                }
            ]
        }
    }



    data = [
        {
            actionid: 'e250e806-324a-4de2-8a0a-0fe0cbbaf102',
            actionname: 'action_test',
            actiontype: 'Alert',
            geofencename: 'geo_fence_1',
            rulename: 'rule_name',
        }
    ];

    componentDidMount(){
        this.props.getActions();
        this.props.stepReset();
    }
    // --------------------------------------------------------------------------------------------
    handleInsertButtonClick1 = (onClick) => {
    }
    handleInsertButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        // alert('This is my custom function for InserButton click event');
        //  
        // alert(this.data.length);
        // this.props.history.push('/pages/geofence');
        // return <Redirect  to='/pages/asset/' />
        this.props.setActionId(0);
        this.props.history.push('/pages/actionnotificationedit/0');
        // onClick();
      }

      handleBasicButtonClick = (onClick) => {
        this.props.setActionId(0);
        this.props.history.push('/pages/actionnotificationedit/-1');
      }
      createCustomInsertButton = (onClick) => {
        return (
        //   <InsertButton
        //     btnText='Add New'
        //     btnContextual='btn-success'
        //     className='my-custom-class'
        //     btnGlyphicon='glyphicon-edit'
        //     onClick={ () => this.handleInsertButtonClick(onClick) }>
        //         {/* <PlusCircleOutlineIcon size={100}/> */}
        //         <PlusIcon size={100}/> Add New
        //     </InsertButton>
        // <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
        <UncontrolledDropdown>
      <DropdownToggle caret color="custom" className='my-custom-class' onClick ={()=> this.handleInsertButtonClick1(onClick)}>
        Add New
      </DropdownToggle>
      <DropdownMenu>
        {/* <DropdownItem header>Header</DropdownItem> */}
        {/* <DropdownItem disabled>Action</DropdownItem> */}
        <DropdownItem onClick = {() => this.handleBasicButtonClick(onClick)}>Basic Alert</DropdownItem>
        <DropdownItem onClick={ () => this.handleInsertButtonClick(onClick) }>Advanced Alert</DropdownItem>
        {/* <DropdownItem divider />
        <DropdownItem>Another Action</DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
        );
      }

      handelAddnewBasicClick = () => {
        this.props.setActionId(0);
        this.props.history.push('/pages/actionnotificationedit/-1');
      }

      handelAddnewAdvancedClick = () => {
        this.props.setActionId(0);
        this.props.history.push('/pages/actionnotificationedit/0');
      }

    renderAddNewButton = () => {
        return(
            <div>
            <UncontrolledDropdown>
            <DropdownToggle caret style={{float:'left'}} color="custom" className='my-custom-class' >
              Add New
            </DropdownToggle>
            <DropdownMenu>
              {/* <DropdownItem header>Header</DropdownItem> */}
              {/* <DropdownItem disabled>Action</DropdownItem> */}
              {/* <DropdownItem onClick = {() => this.handelAddnewBasicClick()}>Basic Alert</DropdownItem> */}
              <DropdownItem onClick={ () => this.handelAddnewAdvancedClick() }>Advanced Alert</DropdownItem>
              {/* <DropdownItem divider />
              <DropdownItem>Another Action</DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
          </div>
        )
    }
    
    // --------------------------------------------------------------------------------------------
    handleRowClick = (e, row) => {
        let actionid = row.actionid;
        // alert(actionid);
        this.props.setActionId(actionid);
        this.props.history.push(`/pages/actionnotificationedit/${actionid}`);
    }

    handleRowDeleteClick = (e, row) => {
        let actionid = row.actionid;
        this.props.deleteActionAlert(actionid);
    }
    editButtonFormatter = (cell, row) => {
        // let path = `/pages/device/${cell}`;
        let path = ``;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                <EditTextButton label={'Edit'} onClick={(e)=>{this.handleRowClick(e, row);}}/>{' '}
                {/* <DeleteTextButton label={"Remove"} onClick={(e)=>{this.handleRowDeleteClick(e, row);}}/> */}
                <ConfirmDeleteDialog 
                    label={"Remove"} 
                    onConfirm={(e)=>{this.handleRowDeleteClick(e, row);}}
                    msg={'Do you want to delete the selected event?'}
                />
                {/* <img src={icon_edit} style={{width: 20, height: 20}} alt={"Edit"} onClick={(e)=>{this.handleRowClick(e, row);}}/>{' '} */}
                {/* <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} onClick={(e)=>{this.handleRowDeleteClick(e, row);}} /> */}
            </div>
        )
    }

    activeFormatter = (cell, row) => {
        return (
            cell ?
            <span> Yes </span>
            : <span> No </span>
        )
    }

    renderActionList(){
        const deviceIdLinkFormatter = (cell, row) => {
            // let path = `/pages/device/${cell}`;
            let path = ``;
            return(
                <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            )
          }
      
          const options = {
            // page: 1,  // which page you want to show as default
            // sizePerPageList: [ {
            //   text: '5', value: 5
            // }, {
            //   text: '10', value: 10
            // }, 
            //     // {
            //     // text: 'All', value: this.props.currentDeviceList.length
            //     // } 
            // ], // you can change the dropdown list for size per page
            // sizePerPage: 5,  // which size per page you want to locate as default
            // pageStartIndex: 1, // where to start counting the pages
            // paginationSize: 5,  // the pagination bar size.
            // prePage: '<', // Previous page button text
            // nextPage: '>', // Next page button text
            // firstPage: '<<', // First page button text
            // lastPage: '>>', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
            // insertBtn: this.createCustomInsertButton,
            // clearSearch: true, 
          };
        return(
            <Col md={12}>
                <Card>
                <CardBody>
                {this.renderAddNewButton()}
                  {/* <BootstrapTable data={ this.data }  tableHeaderClass = 'header_white' pagination = {false} options={ options }     bordered={ false } search insertRow ref='table' >
                      <TableHeaderColumn dataField='actionid' isKey={ true } dataAlign="center"   >Action ID</TableHeaderColumn>
                      <TableHeaderColumn dataField='actionname' dataAlign="center" dataSort  >Action Name</TableHeaderColumn> 
                      <TableHeaderColumn dataField='actiontype' dataAlign="center" dataSort  >Action Type</TableHeaderColumn> 
                      <TableHeaderColumn dataField='geofencename' dataAlign="center" dataSort  >Associated GeoFence</TableHeaderColumn>
                      <TableHeaderColumn dataField='rulename' dataAlign="center" >Associated Rule</TableHeaderColumn>
                      <TableHeaderColumn dataField='action' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn>
                  </BootstrapTable> */}
                   <BootstrapTable data={ this.props.actionList }  tableHeaderClass = 'header_white' pagination = {false} options={ options }      bordered={ false } search 
                        // insertRow 
                        ref='table' >
                      <TableHeaderColumn dataField='actionid' isKey={ true } dataAlign="center"  hidden >Action ID</TableHeaderColumn>
                      <TableHeaderColumn dataField='actionname' dataAlign="center" dataSort  >Action Name</TableHeaderColumn> 
                      <TableHeaderColumn dataField='actiondescription' dataAlign="center" dataSort  >Description</TableHeaderColumn>
                      {/*<TableHeaderColumn dataField='action' dataAlign="center" dataSort  >Action Type</TableHeaderColumn>   */}                  
                      <TableHeaderColumn dataField='companyname' dataAlign="center" dataSort >Company</TableHeaderColumn>  
                      <TableHeaderColumn dataField='active' dataAlign="center" dataFormat={this.activeFormatter}>Active</TableHeaderColumn>
                      <TableHeaderColumn dataField='edit' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn>
                  </BootstrapTable>
                  </CardBody>
                  </Card>
            </Col>
        );
    }

    render(){
        return(
            <div>
                
                {this.renderActionList()}
            </div>
        );
    }
}

// export default withRouter(ActionList);

function mapStateToProps(state) {
    const props = {...state.job};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getActions, stepReset, deleteActionAlert, setActionId}, dispatch);
};

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
);

export default enhance(ActionList);