/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Col, Row, Button, Container, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
// import {Modal} from "react-overlays";
import { Trans } from "react-i18next";
import { addUser, removeUser, getUserAssetclassList, upsertUserAssetclassList, getUserDevicetypeList, upsertUserDevicetypeList, getUserCompanyDeviceTypes, getUserCompanyAssetClass, getAllResellers } from '../../../redux/actions/userAction';
import { loadAssetClass, loadDevice } from '../../../redux/actions/mapActions';
import UserEditFrom from './userEditForm';
import UserAccessForm from './userAccessForm';
import UserClientAccessForm from './userClientAccessForm';
import UserDeviceTypeAccessForm from './userDeviceTypeAccessForm';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { UserContext } from '../../../constants/myContexts';

class UserEditModal extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        let index = localStorage.getItem('activeTab');
        this.userObj = getCurrentUser();
        this.state = {
            // showModal: false,
            activeTab: '1',
            currentUserId: '',
        }
    }

    componentDidMount() {
        let currentUser = sessionStorage.getItem('currentUserObj');
        if (currentUser) {
            try {
                let userObj = JSON.parse(currentUser);
                // this.props.loadAssetClass();
                // this.props.loadDevice();
                // this.props.getUserCompanyAssetClass();
                // this.props.getUserCompanyDeviceTypes();
                // this.props.getUserAssetclassList(userObj.userId);
                // this.props.getUserAssetclassList(this.props.userId);
            } catch (e) {
                console.error(e);
            }
        }
        // this.props.loadAssetClass();
        // this.props.loadDevice();
        this.props.getUserCompanyAssetClass();
        this.props.getUserCompanyDeviceTypes();
        this.props.getAllResellers();
    }

    componentWillReceiveProps(nextProps) {
        // alert(nextProps.userId);
        //  
        //  
        //  
        if (nextProps.userId && nextProps.userId != this.state.currentUserId) {
            nextProps.getUserAssetclassList(nextProps.userId);
            nextProps.getUserDevicetypeList(nextProps.userId);
            this.props.loadAssetClass();
            this.props.loadDevice();
            this.props.getAllResellers();
            // this.setState({
            //     currentUserId : nextProps.userId,
            // });
        }

        this.setState({
            currentUserId: nextProps.userId,
        });
    }

    tabToggle = (tab) => {
        localStorage.setItem('activeTab', tab);
        // alert('y');
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    modalToggle = () => {
        this.setState({
            currentUserId: '',
        });
        this.props.toggle();
    }

    render() {
        let disableRight = !hasRole(this.props.currentUserObj, ['SUPERADMIN', 'ADMIN']);
        let deviceTypeList = [];
        let assetClassList = [];
        let isCurrent = (this.context.userObj.userName === this.props.userId);
        if (this.props.currentUserObj.userName === this.props.userId) {
            if (hasRole(this.props.userObj, ['SUPERADMIN'])) {
                deviceTypeList = this.props.companyDevicetypeList;
                assetClassList = this.props.companyAssetClassList;
            } else {
                deviceTypeList = this.props.companyDevicetypeList.filter((item, index) => (item.companycode == this.props.userObj.companyCode));
                // assetClassList = this.props.companyAssetClassList.filter((item, index)=>(item.company_code == this.props.userObj.companyCode));
                assetClassList = this.props.companyAssetClassList.filter((item, index) => (item.companycode == this.props.userObj.companyCode));
            }
            // deviceTypeList = this.props.companyDevicetypeList;
            // assetClassList = this.props.companyAssetClassList;
        } else if (this.props.userObj) {
            if (hasRole(this.props.userObj, ['SUPERADMIN'])) {
                // deviceTypeList = this.props.deviceTypes.map((item, index)=>({devicetypeid: item.id, devicetypename: item.name}));
                // assetClassList = this.props.assetClass;

                deviceTypeList = this.props.companyDevicetypeList.filter((item, index) => (this.props.deviceTypes.some(type => type.id == item.devicetypeid)));
                assetClassList = this.props.companyAssetClassList.filter((item, index) => (this.props.assetClass.some(assetclass => assetclass.assetclassid == item.assetclassid)));
            } else {
                let companyDevicetypes = this.props.companyDevicetypeList.filter((item, index) => (item.companycode == this.props.userObj.companyCode));
                // let companyAssetclasses = this.props.companyAssetClassList.filter((item, index)=>(item.company_code == this.props.userObj.companyCode));
                let companyAssetclasses = this.props.companyAssetClassList.filter((item, index) => (item.companycode == this.props.userObj.companyCode));

                // let deviceTypesWork = this.props.deviceTypes.filter((item, index) => (companyDevicetypes.some(type => type.devicetypeid == item.id)));
                // deviceTypeList = deviceTypesWork.map((item, index)=>({devicetypeid: item.id, devicetypename: item.name}));
                // assetClassList = this.props.assetClass.filter((item, index) => (companyAssetclasses.some(assetclass => assetclass.assetclassid ==item.assetclassid)));

                deviceTypeList = companyDevicetypes.filter((item, index) => (this.props.deviceTypes.some(type => type.id == item.devicetypeid)));
                assetClassList = companyAssetclasses.filter((item, index) => (this.props.assetClass.some(assetclass => assetclass.assetclassid == item.assetclassid)));
            }
            // deviceTypeList = this.props.deviceTypes.map((item, index)=>({devicetypeid: item.id, devicetypename: item.name}));
            // assetClassList = this.props.assetClass;
        }
        return (
            <Modal
                // style={modalStyle()}
                isOpen={this.props.show}
                toggle={this.props.toggle}
                // renderBackdrop={this.renderBackdrop}
                size='lg'
            >
                <ModalHeader toggle={this.props.toggle} >{this.props.userName ? this.props.userName : 'Edit User'}</ModalHeader>
                <ModalBody>
                    <Nav tabs>
                        <NavItem style={{ cursor: 'pointer' }}>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.tabToggle('1'); }}
                            >
                                User Info
                            </NavLink>
                        </NavItem>

                        <NavItem style={{ cursor: 'pointer' }}>
                            {this.props.pageContentList.includes("Asset Class Access") && hasRole(this.props.currentUserObj, ['SUPERADMIN', 'ADMIN']) ?
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '2' })}
                                    onClick={() => { this.tabToggle('2'); }}
                                    disabled={disableRight}
                                >
                                    Asset Class Access
                                </NavLink>
                                :
                                <span></span>
                            }
                            </NavItem>
                        
                        <NavItem style={{ cursor: 'pointer' }}>
                            {this.props.pageContentList.includes("Device Type Access") && hasRole(this.props.currentUserObj, ['SUPERADMIN', 'ADMIN']) ?
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '3' })}
                                    onClick={() => { this.tabToggle('3'); }}
                                    disabled={disableRight}
                                >
                                    Device Type Access
                                </NavLink>
                                :
                                <span></span>
                            }
                            </NavItem>
                        
                        
                        <NavItem style={{ cursor: 'pointer' }}>
                            {this.props.pageContentList.includes("Client Access") && hasRole(this.props.currentUserObj, ['SUPERADMIN', 'ADMIN']) ?
                                <NavLink
                                    className={classnames({ active: this.state.activeTab === '4' })}
                                    onClick={() => { this.tabToggle('4'); }}
                                    disabled={disableRight}
                                >
                                    Client Access
                                </NavLink>
                                : 
                                <span></span>
                            }
                            </NavItem>
                        
                    </Nav>
                    
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    {/* <h4>Tab 1 Contents</h4> */}
                                    <UserEditFrom
                                        userObj={this.props.userObj}
                                        toggle={this.props.toggle}
                                        isCurrent={isCurrent}
                                        updateUserCallBack={this.context.updateUserObj}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    {/* <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                    </Card> */}
                                    {this.props.pageContentList.includes("Asset Class Access") && hasRole(this.props.currentUserObj, ['SUPERADMIN', 'ADMIN']) ?
                                        // <UserAccessForm userId = {this.state.currentUserId} assetClasses = {this.props.companyAssetClassList} toggle={this.modalToggle}/>
                                        <UserAccessForm
                                            userId={this.state.currentUserId}
                                            assetClasses={assetClassList}
                                            companyAssetClasses={this.props.companyAssetClassList}
                                            toggle={this.modalToggle} />
                                        : <div> Please contact your admin to assign the asset class access </div>
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    {/* <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                    </Card> */}
                                    {this.props.pageContentList.includes("Device Type Access") && hasRole(this.props.currentUserObj, ['SUPERADMIN', 'ADMIN']) ?
                                        // <UserDeviceTypeAccessForm userId = {this.state.currentUserId} deviceTypes = {this.props.companyDevicetypeList} toggle={this.modalToggle}/>
                                        <UserDeviceTypeAccessForm
                                            userId={this.state.currentUserId}
                                            deviceTypes={deviceTypeList}
                                            companyDeviceTypes={this.props.companyDevicetypeList}
                                            toggle={this.modalToggle}
                                        />
                                        : <div> Please contact your admin to assign the device type access </div>
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    {/* <Card body>
                                    <CardTitle>Special Title Treatment</CardTitle>
                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                    <Button>Go somewhere</Button>
                                    </Card> */}
                                    {this.props.pageContentList.includes("Client Access") && hasRole(this.props.currentUserObj, ['SUPERADMIN', 'ADMIN']) ?
                                        // <UserDeviceTypeAccessForm userId = {this.state.currentUserId} deviceTypes = {this.props.companyDevicetypeList} toggle={this.modalToggle}/>
                                        <UserClientAccessForm
                                            toggle={this.modalToggle}
                                            userObj={this.props.userObj}

                                        />
                                        : <div> Please contact your admin to assign the device type access </div>
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </ModalBody>
            </Modal>
        );
    }
}

// export default UserEditModal;

const mapStateToProps = (state) => {
    const assetClass = state.mapboard.assetClass;
    const deviceTypes = state.mapboard.types;
    return { ...state.user, assetClass, deviceTypes };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllResellers, getUserAssetclassList, upsertUserAssetclassList, getUserDevicetypeList, upsertUserDevicetypeList, getUserCompanyDeviceTypes, getUserCompanyAssetClass, loadAssetClass, loadDevice }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEditModal);
