/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import { Card, CardBody, Col } from 'reactstrap';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import { EditIcon } from 'mdi-react';
import {chunk} from '../../../factories/utils';
import {loadDeviceData} from '../../../redux/actions/dashboardActions';
import DeviceTypeEditModal from './DeviceTypeEditModal';

class DeviceTableWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            modal: false,
        }
    }

    componentDidMount(){
        // const devicetypeId = this.props.devicetypeId;
        // const dashname = this.props.dashName;
        // this.props.loadDeviceData(devicetypeId, dashname);
    }

    componentWillReceiveProps(nextProps){
         
         
        if (nextProps.devicetypeId && nextProps.dashName && (nextProps.devicetypeId !== this.state.devicetypeId || nextProps.dashName !== this.state.dashName)) {
            this.setState({
                devicetypeId: nextProps.devicetypeId,
                dashName: nextProps.dashName,
            });
             
            this.props.loadDeviceData(nextProps.devicetypeId, nextProps.dashName);
        }
    }

    renderDeviceTable = () => {
        let dashname = this.props.dashName;
        let data = this.props.devicemapdata[dashname];
         
         
        if (Array.isArray(data)){
            data = data.filter(item => "inactive" != item.status);
        }
        // let maxHeight = JSON.parse(this.props.layout.position).h * 30 - 20;
        const deviceIdLinkFormatter = (cell, row) => {
        //   let path = `/pages/device/${cell}`;
        let path = `/pages/device/${row.deviceid}`;
          return(
              // <a href={path} onClick = {() => {
              //                                   // alert(cell); 
              //                                   // alert(row['devicename']);
              //                                 }}>{cell}</a>
            //  <Button color="link" onClick = {() => {
            //                                             this.props.history.push(path);
            //                                         }}>{cell}</Button>     
              <Link to={path}>{cell}</Link>                   
          )
        }

        const lastTimeFormatter = (cell, row) => {
            // let lastTime = new Date(cell).toLocaleString('en-US', { timeZone: 'America/New_York' })
            let lastTime = new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' });
            return(
                <span>{lastTime}</span>
            )
        }
    
        const options = {
        };
        return(
          <div>
          {/* <Col > */}
              {/* <Card>
                  <CardBody> */}
                      <BootstrapTable 
                        data={ data } 
                        tableHeaderClass = 'header_white' 
                        wrapperClasses="table-responsive" 
                        containerStyle={ {  height:'100%' } } 
                        options={ options }  
                          
                          
                        bordered={ false } 
                        search 
                        // maxHeight={maxHeight.toString()} 
                        maxHeight={'430'} 
                     >
                          <TableHeaderColumn dataField='deviceid' isKey={ true } dataAlign="center" dataFormat={ deviceIdLinkFormatter } width='10%' hidden >Device ID</TableHeaderColumn>
                          <TableHeaderColumn dataField='devicename' dataAlign="center" dataSort dataFormat={ deviceIdLinkFormatter } >Device Name</TableHeaderColumn> 
                          <TableHeaderColumn dataField='devicetypename' dataAlign="center" dataSort  >Device Type</TableHeaderColumn>
                          <TableHeaderColumn dataField='lastmessagetime' dataAlign="center" dataFormat = {lastTimeFormatter} dataSort >Last Message Time</TableHeaderColumn>
                      </BootstrapTable>
                  {/* </CardBody>
              </Card> */}
          {/* </Col> */}
          </div>
        );
      }

      toggle = () => {
        // alert('yes');
        this.setState((prevState) => ({
            modal : !prevState.modal
        }))
    }
    renderModal = () => {
        return(
            <DeviceTypeEditModal
                modal = {this.state.modal}
                toggle = {this.toggle}
                layout = {this.props.layout}
                onEditSubmit = {this.props.onEditSubmit}
            />
        )
    }

      render() {
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                            {this.props.dashName} 
                            {this.props.layout && 0 == this.props.layout.locked &&
                            <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>    
                            }
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                <CardBody style={{padding:15}}>  
                    {/* <div className="card__title">
                        <h5 className="bold-text">{this.props.dashName} </h5>                      
                    </div> */}
                    {this.renderDeviceTable()}
                </CardBody>  
          </Card>
          {this.renderModal()}
          </Fragment>
        );
    }
}
function mapStateToProps(state) { 
    const props = {...state.dashboard};
    return props;
};
    
function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDeviceData}, dispatch);
};
  
export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceTableWidget));