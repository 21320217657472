/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loadDeviceInfo } from '../../../redux/actions/mapActions';
import { getCustomerPurchaseDevices, getAccounts, postOrder, customerCheckout, customerPayOrder, customerReleaseOrder, releaseOrder } from '../../../redux/actions/userAction';
import { Card, CardTitle, CardBody, Col, Row, Collapse, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container, Input } from 'reactstrap';
import CompanyDeviceActive from './CompanyDeviceActive';
import CompanyPurchaseDevices from './CompanyPurchaseDevices';
import CompanyDevicePayment from './CompanyDevicePayment';
import CompanyPaymentCheckout from './CompanyPaymentCheckout';
import {BackButton} from '../../../shared/components/Button/IconButton'; 
import {toast} from "react-toastify";

class CompanyDeviceBoard extends Component {
    constructor(props){
        super(props);
        this.state = {
            step: 1,
            isBuyClick: false,
            currentItemList: null,
            currentAccout: [],
            autoPay: false, 

        }
    }

    componentDidMount() {
        // Load initial data
         
        // this.props.getAccounts();
        this.props.loadDeviceInfo();
         
    }

    clickNext = () => {
        this.setState(prevState => ({
            step: prevState.step + 1,
        }),()=>{
             
        });
    }

    clickBack = () => {
        this.setState(prevState => {
            return {
                step: (prevState.step-1) > 0 ? prevState.step-1 : 1,
            }
        }, ()=>{
             
        });
    }

    paymentBack = () => {
         
        if (this.props.purchaseOrder && this.props.purchaseOrder.orderData){
            this.props.releaseOrder(this.props.purchaseOrder.orderData.id);
        }
        this.clickBack();
    }

    handleBuyClick = () => {
        this.setState({
            isBuyClick : true,
            step : 1,
        });
    }

    handlePurchaseBack = () => {
        this.setState({
            isBuyClick : false,
            step : 1,
        });
    }

    handleSwitchAccount = (value) => {
      this.setState({
        currentAccout: [value],
      })
    }

    createOrderToAccount = (data) => {
    
        var self = this;
        this.props.postOrder(data)
          .then(res => {
              
             if (res && res.status === 200 && res.data ){
                   // let storeData = res.data[0];
                   if (res.data.error != "ERROR") {
                     toast.success(res.data.message);
                    //  this.setState({
                    //  }) 
                    self.handleBuyClick();
                   }else{
                     toast.error(res.data.message)
                   }               
             }else if (!res || res.status !== 200){
                    
                   
             }           
          })
     }

     payOrder = (orderObj) => {
         let self = this;
         this.props.customerPayOrder(orderObj)
         .then(res => {
             
            if (res && res.status === 200 && res.data ){
                  // let storeData = res.data[0];
                  if (res.data.error != "ERROR") {
                    toast.success('success');
                    this.props.getInventoryList();
                    // this.setState({
                    // }) 
                    self.handlePurchaseBack();
                  }else{
                    toast.error(res.data.message)
                  }               
            }else if (!res || res.status !== 200){
                   
                  toast.error('create order error');
                  
            }           
         })
     }

    render() {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ !this.state.isBuyClick }>
                            <CompanyDeviceActive 
                                currentDeviceList={this.props.currentDeviceList}
                                onBuyClick = {this.handleBuyClick} 
                            />
                        </Collapse>  
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isBuyClick && this.state.step == 1 }>
                        <CompanyPurchaseDevices
                            userObj = {this.props.userObj}
                            getInventoryList = {this.props.getInventoryList}
                            inventoryList = {this.props.inventoryList}
                            onBackClick = {this.handlePurchaseBack}
                            onNextClick = {this.clickNext}
                            getCustomerPurchaseDevices = {this.props.getCustomerPurchaseDevices}
                           
                            // {...props}
                        />
                        </Collapse>  
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isBuyClick && this.state.step == 2 }>
                        <CompanyDevicePayment
                            userObj = {this.props.userObj}
                            account = {this.state.currentAccout}
                            accountList = {this.props.accountList}
                            purchaseDeviceList = {this.props.purchaseDeviceList}
                            purchaseItemList = {this.props.purchaseItemList}
                            onBackClick = {this.clickBack}
                            onNextClick = {this.clickNext}
                            handleSwitchAccount = {this.handleSwitchAccount}
                            customerCheckout = {this.props.customerCheckout}
                            customerObj = {this.props.customerObj}
                            // {...props}
                        />
                        </Collapse>  
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isBuyClick && this.state.step == 3 }>  
                        {/* <Button onClick={this.handlePurchaseCheckoutCompBackClick}> Back e</Button> */}
                        <BackButton
                        //    onClick={this.clickBack} 
                           onClick={this.paymentBack} 
                           label="Back"
                           size={30}
                           color = 'black'
                        ></BackButton>
                        </Collapse>
                    </Col>
                  </Row>
                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isBuyClick && this.state.step == 3 }>
                        <CompanyPaymentCheckout
                            userObj = {this.props.userObj}
                            account = {this.state.currentAccout}
                            accountList = {this.props.accountList}
                            purchaseDeviceList = {this.props.purchaseDeviceList}
                            purchaseItemList = {this.props.purchaseItemList}
                            purchaseOrder = {this.props.purchaseOrder}
                            autoPay={this.state.autoPay}
                            createOrder = {this.createOrderToAccount}
                            payOrder = {this.payOrder}
                            onBackClick = {this.clickBack}
                            // {...props}
                        />
                        </Collapse>  
                    </Col>
                </Row>
                
            </div>
        ) 
    }
}

function mapStateToProps(state) {
    const currentDeviceList = state.asset.currentDeviceList;
    const { purchaseDeviceList, purchaseItemList, accountList, purchaseOrder } = state.user;
    const props = { currentDeviceList, purchaseDeviceList, purchaseItemList, accountList, purchaseOrder }
    return props;
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadDeviceInfo,
        getCustomerPurchaseDevices,
        postOrder,customerCheckout, customerPayOrder,customerReleaseOrder,releaseOrder,
     }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyDeviceBoard);