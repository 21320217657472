/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label, BarChart, Bar } from 'recharts';
import { renderSpinner } from './CarCountBoard';
import moment from 'moment';
import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { groupByArray, groupByArrayObject } from '../../../../factories/utils';
import IndividualCamChart from './IndividualCamChart';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


const CarCountGraph = ({camData, limit, loadCarCount, handleDateFilter, selectedDateTime}) => {
    const [chartData, setChartData] = useState([])
    const [filterDate, setFilterDate] = useState(moment(new Date()).format('MM-DD-YYYY'))
    // const [SortedData, setSortedData] = useState([])

    useEffect(() => {
        if(camData && camData.data.length > 0){
            //convert sql timestamp to normal time grouped by hour
            let groupedDataByTime = groupByTime([...camData.data])
            // console.log('GROUPED DATA BY TIME', groupedDataByTime)
            let camHourlyData = []
            //some hour has multiple data, get the most current data
            for(const [key, value] of Object.entries(groupedDataByTime)){
                let lastData = value[value.length - 1]
                camHourlyData.push({
                    time: lastData.time,
                    timeLabel: lastData.timeLabel,
                    entranceCount: lastData.camera?.name.includes('Gas') ? lastData.entranceCount + lastData.exitCount : lastData.entranceCount,
                })
            }
            camHourlyData = camHourlyData.sort((a, b) => {
                if(a.time < b.time){
                    return -1
                }else if(a.time > b.time){
                    return 1
                }
                return 0
            })
            // console.log('CAM HOURLY DATA', camHourlyData)


            
            //reduce camChartData to get different entranceCount and exitCount for each hour
            let chartData = camHourlyData.reduce((acc, cur, index, arr)=>{
                let differentEntranceCount
                if(index > 0){
                    differentEntranceCount = cur.entranceCount - arr[index-1].entranceCount < 0 ? 0 : cur.entranceCount - arr[index-1].entranceCount
                }else{
                    differentEntranceCount = cur.entranceCount
                }
                acc.push({
                    time: cur.time,
                    timeLabel: cur.timeLabel,
                    Limit: limit ? limit : 0, 
                    entranceCount: differentEntranceCount
                })
                return acc

            }, [])

            // console.log('CHART DATA', chartData)

            setChartData(chartData);

        }else{
            setChartData([])
        }
    }, [camData, limit])


    const groupByTime = (arr) =>{
        let groupedArray = groupByArray(arr, 'time')
        return groupedArray
    }

    const groupByCameraId = (arr) =>{
        let groupedObjectArray = groupByArrayObject(arr, 'camera', 'id')
        return groupedObjectArray
    }

    const handleDateChange =(date) =>{
        date = moment(date).format('MM-DD-YYYY')
        handleDateFilter(date);
        // console.log('========DATE CHANGE=========', date.toString());
        // debugger;
        // loadCarCount(locationName + ' - Car Counting', date.toString());
    }
    return (
        <>
        <Row className="container center" style={{marginTop: "20px", width: "100%"}}>
            <MuiPickersUtilsProvider utils={MomentUtils}>

                <KeyboardDatePicker 
                    autoOk
                    inputVariant="outlined"
                    label="Choose Date"
                    onChange={date=>handleDateChange(date)}    
                    variant="inline"
                    format="MM/DD/YYYY"
                    InputAdornmentProps={{ position: "start" }}
                    value={selectedDateTime}
                    maxDate={new Date()}
                />
            </MuiPickersUtilsProvider>
            {/*TODO TEST RETREE !!!!!!!!!!!!!!!!!*/}
            {/*{<Button color="seconday" className="box" onClick={(event) => {
                this.props.getResellersTree({isGetAll:true})
            }}>getResellersTree</Button>}*/}
        </Row>
            {chartData.length > 0 &&
                <ResponsiveContainer height={250} width="98%" maxWidth={300}>
                    <BarChart
                        data={chartData}
                        width={250} height={230}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="timeLabel">
                                <Label value="Hourly car counting" offset={-2} position="insideBottom" />
                            </XAxis>
                            <YAxis>
                                <Label value="Number of Car" angle={-90} position="insideLeft" />
                            </YAxis>
                            <Tooltip />
                            <Legend verticalAlign="top" height={36}/>
                            <Bar dataKey="entranceCount" name="Entry" legendType="circle" fill="#80ff80" />
                            {/* <Bar dataKey="West Bound/exit" legendType="circle" fill="#3399ff" /> */}
                            {/* <Line type="monotone" dataKey="East Bound" legendType="circle" stroke="#0066ff" />
                            <Line type="monotone" dataKey="West Bound" legendType="circle" stroke="#ff3300" /> */}
                            {/* <Line type="monotone" dataKey="Limit" legendType="circle" stroke="#4f4443" />
                            <Line type="monotone" dataKey="On Premises" stroke="#33cc33" legendType="circle" strokeWidth={3} /> */}
                    </BarChart>
                </ResponsiveContainer>
            }
        </>
    )
}

// const mapStateToProps = (state) => ({
    
// })

// const mapDispatchToProps = (dispatch) => {
    
// }

export default CarCountGraph
