/* eslint-disable */
import React, { Component, Fragment } from "react";

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Collapse,
} from "reactstrap";
import DeviceConfigList from "./DeviceConfigList";
import DeviceConfigBoard from "./DeviceConfigBoard";

class DeviceConfigMainBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
  }

  render() {
    
    return (
      <Card className={"card-pg"}>
        <DeviceConfigList
          messageDecodeList={this.props.messageDecodeList}
        />
        {/* <DeviceConfigBoard
          assetClassList={this.props.assetClassList}
          deviceList={this.props.deviceList}
          updateMessageDecodeOnStore={this.props.updateMessageDecodeOnStore}
         /> */}
      </Card>
    );
  }
}

export default DeviceConfigMainBoard;
