/* eslint-disable */
import React, {Component, Fragment} from 'react';
import {
    // compose,
    bindActionCreators
} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import {WEB_SERVICE_ROOT} from "../../../constants/appConstants";
import {toast} from "react-toastify";
import BootstrapTableNext from "react-bootstrap-table-next";
import {sortCaret} from "../../../shared/components/Table/tableElements";



class OutlookCalendarDisplay extends Component{
    constructor(props){
        super(props);
        this.state={
            userInfo:null,
            calendarInfo:[]
        };

    }

    async componentDidMount(){

    }
    //------------------------------------------------------------------------------


    async clickShowMe() {
        let res = await axios.get(`${WEB_SERVICE_ROOT}opg/showme`, {});
        this.setState({
            userInfo: res.data
        })
    }



    async clickGetCalander() {
        let data={start: document.getElementById("c_start_1").value,end:document.getElementById("c_end_1").value}
        let res = await axios.post(`${WEB_SERVICE_ROOT}opg/getCalendar`, data);

        if(!(res&&res.data&&res.data.value)){
            return;
        }
        let listData=res.data.value[0].scheduleItems;
        let calendarInfo=[];
        for (let i = 0; i < listData.length; i++) {
            let item=listData[i];
            calendarInfo.push({
                date:item.start.dateTime.split("T")[0],
                startTime:item.start.dateTime.split("T")[1].replace(".0000000",""),
                endTime:item.end.dateTime.split("T")[1].replace(".0000000",""),
                subject:item.subject,
            })
        }
        this.setState({
            calendarInfo: calendarInfo

        })
    }


    render(){
        let col = [
            { dataField: 'date', text: 'Date' },
            { dataField: 'startTime', text: 'Start Time' },
            { dataField: 'endTime', text: 'End Time' },
            { dataField: 'subject', text: 'Subject' }
        ]


        return (<div>
            <h1>Microsoft Outlook</h1>
            <h2>
                <a style={{color:"blue",cursor:"pointer"}} href={"https://login.microsoftonline.com/d5189ead-72d5-4d23-b57e-1a91ca41a8f6/oauth2/v2.0/authorize?"+encodeURI("client_id=b5b04774-9a9a-45dd-806c-dc8e86bfa007&response_type=code&scope=User.Read Calendars.ReadWrite&response_mode=query&state=123&redirect_uri="+window.location.origin+"/")}>
                    Click to auth
                </a>
            </h2>
            {/* <p>
              <button onClick={()=>this.postGetAccessToken()}>
                  click to get access token
              </button>
          </p>*/}
            <p>
                <button onClick={()=>this.clickShowMe()}>
                    Click to show me
                </button>
            </p>
            {this.state.userInfo!=null?(<div>
                Mail:{this.state.userInfo.mail}
            </div>):null}

            <p>
                {/*Start Time: <input id={"c_start"} type="text" defaultValue={"2022-05-01T21:00:00"}  />*/}
                Start Time: <input id={"c_start_1"} type="text" defaultValue={""}  />
                <br/>
                <br/>
                {/*End Time:  <input id={"c_end"} type="text" defaultValue={"2022-05-30T00:00:00"}  />*/}
                End Time:  <input id={"c_end_1"} type="text" defaultValue={""}  />
                <br/>
                <br/>
                <button onClick={()=>this.clickGetCalander()}>
                    click to get calendar
                </button>
            </p>
            {/*{this.state.calendarInfo!=null?(<div>
                {
                    this.state.calendarInfo.map((item,index)=>{
                        return (<p>start:{item.start.dateTime}-end:{item.end.dateTime}-type:{item.subject}</p>)
                    })
                }
            </div>):null}*/}

            <br/>
            <br/>
            <BootstrapTableNext key={'expand_' + 1} keyField='deviceid' data={this.state.calendarInfo} columns={col} dataSort caretRender={ sortCaret } />

        </div>);
    }
}

function mapStateToProps(state) {

    return state.weather;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlookCalendarDisplay);

/*
Display name
:
test app
Application (client) ID:b5b04774-9a9a-45dd-806c-dc8e86bfa007
Object ID:47d13930-4d97-4b09-ad80-f57575ed4c59
Directory (tenant) ID:d5189ead-72d5-4d23-b57e-1a91ca41a8f6
Supported account types:All Microsoft account users


https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/quickStartType//sourceType/Microsoft_AAD_IAM/appId/b5b04774-9a9a-45dd-806c-dc8e86bfa007/objectId/47d13930-4d97-4b09-ad80-f57575ed4c59/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true
DOCUMENTATION:https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/quickStartType//sourceType/Microsoft_AAD_IAM/appId/b5b04774-9a9a-45dd-806c-dc8e86bfa007/objectId/47d13930-4d97-4b09-ad80-f57575ed4c59/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true
*/