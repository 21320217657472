/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DeviceTable from './DeviceTable';
import DeviceTypeTable from './DeviceTypeTable';

import {
    getCarriers,
    getResellerList
} from '../../../../redux/actions/userAction';
import {
    getFullSIMList,
    uploadFileSims,
    SetDeviceType,
    SetSimType
} from '../../../../redux/actions/inventoryActions';
import InventoryInactiveBoard from './InventoryInactiveBoard';

class InventoryBoard extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {

        this.props.getCarriers();
        this.props.getResellerList();
    }

    render() {
        return (
            <Fragment>
                <Card>
                    <CardBody>
                        {/* <h4><b>SIM Types</b></h4> */}
                        <DeviceTypeTable
                            pageContentList={this.props.pageContentList}
                            carrierList={this.props.carrierList}
                            currentUserObj={this.props.currentUserObj}
                            SetSimType={this.props.SetSimType}
                            typeList={this.props.typeList}
                            resellerList={this.props.resellerList}
                            itemType={this.props.itemType}
                        />
                    </CardBody>
                </Card>
            </Fragment>
        )
    }
}
function mapStateToProps(state) {

    let {
        carrierList,
        resellerList
    } = state.user;
    let props = {
        resellerList,
        carrierList
    }

    return props;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCarriers,
        getResellerList,
        uploadFileSims,
        SetDeviceType,
        SetSimType
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryBoard);