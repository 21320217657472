import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
// eslint-disable-next-line import/first
import axios from 'axios';
import { WEB_SERVICE_ROOT } from '../../constants/appConstants';

class MainWrapper extends PureComponent {
  static propTypes = {
    theme: ThemeProps.isRequired,
    children: PropTypes.element.isRequired,
  };
  async componentDidMount() {
    const code = this.getQueryString('code');
    if (code && code !== '') {
      await this.postGetAccessToken();
      window.location.href = '/#/pages/OpgApp';
      localStorage.setItem('isToCalendar', '1');
    }
  }

  // eslint-disable-next-line react/sort-comp
  async postGetAccessToken() {
    const code = this.getQueryString('code');
    if (code === '') {
      return;
    }
    const data = { code, redirectUrl: window.location.origin };
    await axios.post(`${WEB_SERVICE_ROOT}opg/microsoftGraphAuth`, data);
  }

  // eslint-disable-next-line class-methods-use-this
  getQueryString(name) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    const r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }
  render() {
    const { theme } = this.props;
    return (
      <div className={theme.className}>
        <div className="wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  theme: state.theme,
}))(MainWrapper);
