/*eslint-disable*/
import React, { Component } from "react";
import  Card from "react-credit-cards";
import uuid from "uuid/v4";
import AddPaymentModal from "./payment/addPaymentModal";
import {Row, Col, Card as Card2, CardBody,  CardGroup, Label, Input, Button, Table} from 'reactstrap';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { toast } from "react-toastify";
import { USAGE_PRICE } from '../../../constants/dataConstants';

class CompanyPaymentCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
    };     
  }

  manufactureOnChangeHandler = (e) => {
    let selectedValue = dropdownOnChangeHandler(e); //alert(selectedValue.id);
     
    // this.props.getAttributes(selectedValue.id);
    // this.props.loadAssets({devicetypeid: selectedValue.id});
    this.setState({
        currentDeviceTypeId : selectedValue.id,
        currentDeviceTypeName : selectedValue.name,
     });
    
}

orderNow = () => {
//   if (this.props.currentPlanItem && this.props.account && Array.isArray(this.props.account) && this.props.account.length > 0) {
  if (Array.isArray(this.props.purchaseItemList) && this.props.purchaseItemList.length > 0 && Array.isArray(this.props.account) && this.props.account.length > 0) {
    let orderList = [];
    this.props.purchaseItemList.forEach((item, index) => {
        let qty = 1;
        if (Array.isArray(this.props.purchaseDeviceList)) {
            let devices = this.props.purchaseDeviceList.filter(device => (device.upc == item.itemDescription));
            qty = devices.length > 0 ? devices.length : 1;
        }
        let obj = {
                    "itemId": item.itemId,
                    "itemQty": qty,
                    "promoCode": 1
                   };
        orderList.push(obj);
    });
    let defaultData = {
      "accountId": this.props.account[0].id,
      "currency": "CAD",
      "comment": "1",
      "autoPay": this.props.autoPay ? 1 : 0,
      "orderItemDataList": orderList
    //   "orderItemDataList": [
    //       {
    //           "itemId": this.props.currentPlanItem.itemId,
    //           "itemQty": 1,
    //           "promoCode": 1
    //       }
    //   ]
  
    }
  // SPECIFY CORRECT ATTRIBUTES
    let data = {
        "accountId": this.props.account[0].id,
        "currency": "CAD",
        "comment": "1",
        "autoPay": this.props.autoPay ? 1 : 0,
        "orderItemDataList": orderList
        //   "orderItemDataList": [
        //       {
        //           "itemId": this.props.currentPlanItem.itemId,
        //           "itemQty": 1,
        //           "promoCode": 1
        //       }
        //   ]
    }

    this.props.createOrder({ ...defaultData, ...data })
  } else{
    toast.error('No Item selected');
  }
}

payOrder = () => {
    if (this.props.purchaseOrder && this.props.purchaseOrder.orderData){
        let param = {
            id :  this.props.purchaseOrder.orderData.id
        };
        this.props.payOrder(param);
    }
    else{
        toast.error('No Item selected');
    }
}

priceFormater = (cell, row) => {
    return (
        <span>{`CAD$ ${cell}`}</span>
    )
}


  render() {   
    const  plans  = this.state.plans;
    const aList = this.props.accountList;
     
    const cardsstyle ={  
        'width': '100%',
    }
    const btn ={
      'float': 'right'
    }

    const company = this.props.userObj.company;

    //   
    //  const ac = this.props.account? this.props.account.filter(account => account.cardMask) : [];
     const ac = this.props.account.filter(account => account.cardMask);
    //  let price = ''; 
    // if (this.props.itemType && this.props.itemType === '1' && this.props.currentPlanItem) {
    //   if (this.props.currentPlanItem.itemTypeId == 1) {
    //     price = USAGE_PRICE;
    //   }
    //   else if (this.props.currentPlanItem.itemTypeId == 2 && this.props.currentPlanItem.unitPrice) {
    //     price = `${this.props.currentPlanItem.unitPrice}`;
    //   }
    // }

    // ------------------------------------------------------
    // let totalPriceNum = 0;
    // let totalPriceStr = '';
    // let usageList = [];
    // Array.isArray(this.props.purchaseItemList) && this.props.purchaseItemList.forEach( (item, index) => {
    //   let price = '';
    //   let paymentperiod = 'one shot';
    //   if (item.itemTypeId == 1) {
    //     price = USAGE_PRICE;
    //     totalPriceStr = USAGE_PRICE;
    //   }
    //   else if (item.itemTypeId == 2 && item.unitPrice) {
    //     price = `CAD$ ${item.unitPrice}`;
    //     if (!isNaN(item.unitPrice)) {
    //         totalPriceNum += Number(item.unitPrice);
    //     }
    //   }

    //   if (item && item.payFrequency) {
    //     paymentperiod = item.payFrequency.itemPayFreqName
    //   }
    //   let itemObj = {
    //           "product": item.itemName,
    //           "quantity": 1,
    //           "price": price,
    //           "paymentperiod": paymentperiod,
    //           "autocharg": this.props.autoPay ? 'Yes': 'No',
    //           "card": 1000,
    //       };
    //   usageList.push(itemObj)
    // })
    // if (totalPriceNum > 0) {
    //     totalPriceStr = totalPriceNum.toString();
    // }

    let deviceList = [];
    let TotalTax = '';
    let SubTotal='';
    let Total='';
    if (this.props.purchaseOrder){
        deviceList = this.props.purchaseOrder.deviceList;
        if (this.props.purchaseOrder.orderData){
            SubTotal = isNaN(this.props.purchaseOrder.orderData.ordrTtl) ? "" : Number(this.props.purchaseOrder.orderData.ordrTtl).toFixed(2);
            TotalTax = isNaN(this.props.purchaseOrder.orderData.ordrTtlTax) ? "" : Number(this.props.purchaseOrder.orderData.ordrTtlTax).toFixed(2);;
            Total = isNaN(this.props.purchaseOrder.orderData.ordrAmt) ? "" : Number(this.props.purchaseOrder.orderData.ordrAmt).toFixed(2);;
        }
    }else{
        toast.error('Failed to get order data, please try again')
    }
      
    return(
    <div className="App-cards-list">
        <div class="container">

      <div class="row">
        <div class="col-md-4 order-md-2 mb-4">
          <h4 class="d-flex justify-content-between align-items-center mb-3">
            <span class="text-muted">Your cart</span>
            <span class="badge badge-secondary badge-pill">1</span>
          </h4>
          <ul class="list-group mb-3">
            <li class="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 class="my-0">Items</h6>
                {/* <small class="text-muted">IOT - Basic</small> */}
              </div>
              {/* <span class="text-muted">{price}</span> */}
              <span class="text-muted">{SubTotal}</span>
            </li>
            {/* <li class="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 class="my-0">Second product</h6>
                <small class="text-muted">Brief description</small>
              </div>
              <span class="text-muted">$8</span>
            </li> */}
            
            <li class="list-group-item d-flex justify-content-between bg-light">
              <div class="text-success">
                <h6 class="my-0">Promo code</h6>
                <small>EXAMPLECODE</small>
              </div>
              <span class="text-success">-$0</span>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Total Tax(CAD)</span>
              <strong>{TotalTax}</strong>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Total (CAD)</span>
              <strong>{Total}</strong>
            </li>
          </ul>

          <form class="card p-2">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Promo code"/>
              <div class="input-group-append">
                <button type="submit" class="btn btn-secondary">Redeem</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-8 order-md-1">
        {/* <Card2 >
        <CardBody> */}
        <div class="row mt-3">       
          <div class="col-md-6">
            <h4 class="mb-3">Billing address</h4>
            <div class="mb-1">
              {company.addressline1 && <div><label>{company.addressline1}</label><br/></div>}
              {company.addressline2 && <div><label>{company.addressline2}</label><br/></div>}
              <label>{company.city + " " + company.province + " " + company.country + " " + company.postcode}</label>
            </div>
          </div>

          <div class="col-md-6">
            <h4 class="mb-3">Payment</h4> 
            {ac && ac.map(card => (<div>
            <div class="row">
              <div class="col-md-4 mb-1">
                <Card 
                  number={card.cardMask}
                  customCardsSupport={true}
                  customCardsStyles={{'width': '50px', 'height': '30px'}}
                />
              </div>
              <div class="col-md-4 mb-1">                
                <label>{card.cardMask}</label>
              </div>
            </div>
            </div>
            ))}
            </div>
          
            </div>
            {/* </CardBody>
            </Card2> */}
            <hr/>
            <div class="row mt-4 mb-5">
            <h4 class="mb-3">Order Summary</h4>
            {/* <div><h4>Order Summary</h4></div><br/> */}
              {/* <BootstrapTable 
                  data={ usageList }  
                  // tableHeaderClass = 'header_white' 
                  pagination = {false} 
                    
                    
                  bordered={ false } 
                  // insertRow 
                  ref='table' 
              >

                <TableHeaderColumn dataField='product' isKey={ true } dataAlign="center" width='47%'>Item</TableHeaderColumn>
                <TableHeaderColumn dataField='quantity' dataAlign="center" dataSort width='10%' >Quantity</TableHeaderColumn> 
                <TableHeaderColumn dataField='price' dataAlign="center" dataSort width='18%' >Price</TableHeaderColumn>
                <TableHeaderColumn dataField='paymentperiod' dataAlign="center" dataSort width='10%' >Payment Period</TableHeaderColumn>
                <TableHeaderColumn dataField='autocharg' dataAlign="center" dataSort width='10%' >Auto Charge</TableHeaderColumn>
                <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater}  dataAlign="center" dataSort width='10%' >Payment card</TableHeaderColumn>
                                      
              </BootstrapTable> */}
              <BootstrapTable 
                    data={ deviceList }  
                    // tableHeaderClass = 'header_white' 
                    pagination = { false } 
                      
                      
                    bordered={ false }
                    ref='table' 
                >
                <TableHeaderColumn dataField='upc' dataAlign="center" dataSort width='10%' >UPC</TableHeaderColumn>
                <TableHeaderColumn dataField='itemName' dataAlign="center" width='10%'>Product</TableHeaderColumn>
                <TableHeaderColumn dataField='deviceId' isKey={ true } dataAlign="center" dataSort width='10%' >Device</TableHeaderColumn>                 
                <TableHeaderColumn dataField='networkSupported' dataAlign="center" dataSort width='10%' >Network</TableHeaderColumn>
                <TableHeaderColumn dataField='unitPrice' dataFormat={this.priceFormater} dataAlign="center" dataSort width='10%' >Price</TableHeaderColumn>
                
                {/* <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater}  dataAlign="center" dataSort width='10%' >Payment card</TableHeaderColumn> */}
                                        
            </BootstrapTable>
            </div>
            <br/>
            <button 
                class="btn btn-primary btn-lg btn-block" 
                type="submit" 
                onClick={() => {
                    //this.orderNow()
                    this.payOrder() 
                }}
            >
                Confirm and Pay
            </button>
        </div>
      </div>

    </div> 
    </div>
    );
  }
}


export default CompanyPaymentCheckout;

