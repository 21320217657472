/* eslint-disable */
import {
    POWER_GET_ASSET_HISTORY_START,
    POWER_GET_ASSET_HISTORY_SUCCEED,
    POWER_GET_ASSET_HISTORY_CLEAR,
    WEATHER_GET_ASSET_DATA_SUCCEED,
    WEATHER_GET_ASSET_DATA_FAILED,
    WEATHER_GET_ASSET_DATA_CLEAR,
    WEATHER_GET_ALARM_COUNT_SUCCEED,
    WEATHER_GET_ALARM_COUNT_FAILED,
    WEATHER_GET_ALARM_COUNT_CLEAR,
    WEATHER_SET_STORE_ASSET_DATA,
    WEATHER_GET_ASSET_HISTORY_START,
    WEATHER_GET_ASSET_HISTORY_SUCCEED,
    WEATHER_GET_ASSET_HISTORY_FAILED,
    WEATHER_GET_ASSET_HISTORY_CLEAR,
    WEATHER_UPDATE_ASSET_HISTORY_SUCCEED,
    WEATHER_SET_STORE_ASSET_HISTORY_DATA,
    WEATHER_SUB_DATA_UPDATED,
    WEATHER_GET_ALL_CONFIG_SUCCEED,
    WEATHER_GET_ALL_CONFIG_FAILED, 
    WEATHER_POST_CONFIG_SUCCEED, 
    WEATHER_POST_CONFIG_FAILED,
    WEATHER_GET_HOURLYALARM_COUNT_SUCCEED,
    WEATHER_GET_HOURLYALARM_COUNT_FAILED,  
    WEATHER_GET_HOURLYALARM_COUNT_CLEAR,
    WEATHER_GET_24_HISTORY_SUCCEED,
    WEATHER_GET_24_HISTORY_FAILED, 
    WEATHER_UPDATE_24_HISTORY_SUCCEED,
    WEATHER_GET_1_MONTH_HISTORY_SUCCEED,
    WEATHER_GET_1_MONTH_HISTORY_FAILED,
    WEATHER_GET_24_HISTORY_CLEAR,
    WEATHER_GET_ASSET_DATA_START,
    WEATHER_GET_ALARM_COUNT_START,
    WEATHER_GET_HOURLYALARM_COUNT_START,
    WEATHER_GET_24HYALARM_COUNT_START, 
    WEATHER_GET_24HALARM_COUNT_SUCCEED,
    WEATHER_GET_24HALARM_COUNT_FAILED, 
    WEATHER_GET_24HALARM_COUNT_CLEAR,
    WEATHER_GET_MONTHLYALARM_COUNT_START,
    WEATHER_GET_MONTHLYALARM_COUNT_SUCCEED,
    WEATHER_GET_MONTHLYALARM_COUNT_FAILED,
    WEATHER_GET_MONTHLYALARM_COUNT_CLEAR,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_START,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_SUCCEED,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_FAILED,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_CLEAR,
    WEATHER_GET_6MONTHSALARM_COUNT_START,
    WEATHER_GET_6MONTHSALARM_COUNT_SUCCEED,
    WEATHER_GET_6MONTHSALARM_COUNT_FAILED,
    WEATHER_GET_6MONTHSALARM_COUNT_CLEAR,
    WEATHER_GET_ALARM_COUNT_LIST_START,
    WEATHER_GET_ALARM_COUNT_LIST_SUCCEED,
    WEATHER_GET_ALARM_COUNT_LIST_FAILED,
    WEATHER_GET_ALARM_COUNT_LIST_CLEAR,
    WEATHER_GET_ROLLING24HALARM_COUNT_SUCCEED,
    WEATHER_GET_ROLLING24HALARM_COUNT_START,
} from '../../constants/actionTypes';
import {
    ATTRIBUTE_ORDER , ATTRIBUTE_TYPE, POWER_ATTRIBUTE_ORDER
} from '../../constants/dataConstants';

const initState = {
    assetDataLoading: false,
    assetHistoryLoading: false,
    assetCountLoading: false,
    assetCountListLoading: false,
    assetHourlyCountLoading: false,
    assetMonthlyCountLoading: false,
    assetQuarterMonthCountLoading: false,
    asset6MonthsCountLoading: false,
    alarm24HCountLoading: false,
    rawAssetData:[],
    assetData:[],
    assetExpendData:[],
    rawAssetHistoryData:[],
    assetHistoryData:[],
    assetHistoryObj: null,
    powerAssetHistoryObj:null,
    allAttriList: [],
    powerAttrList:[],
    asset24HObj: null,
    alarmCounts:[],
    countUnit: 'day',
    alarmMonthlyCounts: [],
    monthlyCountUnit: 'day',
    alarmCountsList: [],
    alarmQuarterMonthCounts: [],
    alarmQuarterMonthCountsUnit: 'day',
    alarm6MonthsCounts: [],
    alarm6MonthsCountsUnit: 'day',
    alarm24HCounts:[],
    alarm24HCountUnit: 'hour',
    alarmRolling24HCounts:[],
    alarmRolling24HCountUnit: 'hour',
    allConfig:[],
    error: 0,
    message: '',
    type: '',
}

const msg_succeed = 'SUCCEED';

export default function(state=initState, action){
     
    switch(action.type){
        case WEATHER_GET_ASSET_DATA_SUCCEED:
            return {
                ...state,
                rawAssetData: action.payload,
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ASSET_DATA_START:
            return {
                ...state,
                assetDataLoading: true,
                error: 0 ,
                //message: msg_succeed,
                type: action.type,
            }
        case WEATHER_SET_STORE_ASSET_DATA:
            return{
                ...state,
                assetDataLoading: false,
                rawAssetData: action.payloada,
                assetData: action.payloadb,
                assetExpendData: action.payloadc,
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_SUB_DATA_UPDATED: 
            return{
                ...state,
                assetDataLoading: false,
                // rawAssetData: action.payloada,
                assetData: action.payloada,
                assetExpendData: action.payloadb,
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ASSET_DATA_CLEAR:
            return {
                ...state,
                assetDataLoading: false,
                rawAssetData: [],
                assetData:[],
                assetExpendData:[],
                error: 0 ,
                message: action.message,
                type: action.type,
            }
        case WEATHER_SET_STORE_ASSET_HISTORY_DATA:
            return {
                ...state,
                rawAssetHistoryData: action.payloada,
                assetHistoryData: action.payloadb,
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ASSET_HISTORY_START: 
            return{
                ...state,
                assetHistoryLoading: true,
                error: 0 ,
                type: action.type,
            }
        case WEATHER_GET_ASSET_HISTORY_SUCCEED:
            const allAttriList = [];
            const assetHistoryObj = assetHistoryDataProcessor(action.payload, action.parameters, allAttriList);
            return  {
                ...state,
                assetHistoryLoading: false,
                assetHistoryObj,
                allAttriList,
                error: 0 ,
                // message: msg_succeed,
                type: action.type,
            }
        case POWER_GET_ASSET_HISTORY_START: 
            return{
                ...state,
                assetHistoryLoading: true,
                error: 0 ,
                type: action.type,
            }
        case POWER_GET_ASSET_HISTORY_SUCCEED:
            const powerAttrList = [];
            const powerAssetHistoryObj = powerAssetHistoryDataProcessor(action.payload, action.parameters, powerAttrList);
            return  {
                ...state,
                assetHistoryLoading: false,
                powerAssetHistoryObj,
                powerAttrList,
                error: 0 ,
                // message: msg_succeed,
                type: action.type,
            }

        case POWER_GET_ASSET_HISTORY_CLEAR:
            return {
                ...state,
                rawAssetHistoryData: [],
                assetHistoryData:[],
                assetHistoryLoading: false,
                powerAssetHistoryObj: null,
                powerAttrList: [],
                error: 0 ,
                message: action.message,
                type: action.type,
            }

        case WEATHER_UPDATE_ASSET_HISTORY_SUCCEED:
            let updateObj = updateAssetHistoryData(state.assetHistoryObj, state.allAttriList, action.payload, action.startDateMoment, action.endDateMoment);
            return {
                ...state,
                assetHistoryLoading: false,
                assetHistoryObj: updateObj.hisoryObj,
                allAttriList: updateObj.allAttriList,
                error: 0,
                type: action.type,
            }
        case WEATHER_GET_ASSET_HISTORY_CLEAR:
            return {
                ...state,
                rawAssetHistoryData: [],
                assetHistoryData:[],
                assetHistoryLoading: false,
                assetHistoryObj: null,
                allAttriList: [],
                error: 0 ,
                message: action.message,
                type: action.type,
            }
        case WEATHER_GET_24_HISTORY_SUCCEED:
            const asset24HObj = assetHistoryDataProcessor(action.payload)
            return{
                ...state,
                asset24HObj,
                error:0,
                // message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_24_HISTORY_FAILED:
            return{
                ...state,
                asset24HObj: null,
                error:1,
                message: action.error,
                type: action.type,
            }
        case WEATHER_UPDATE_24_HISTORY_SUCCEED:
            let update24Obj = updateAssetHistoryData(state.asset24HObj, null, action.payload, action.startDateMoment, action.endDateMoment);
            return {
                ...state,
                asset24HObj: update24Obj.hisoryObj,
                error: 0,
                type: action.type,
            }
        case WEATHER_GET_1_MONTH_HISTORY_SUCCEED:
            const asset1MonthObj = assetHistoryDataProcessor(action.payload)
            return{
                ...state,
                asset1MonthObj,
                error:0,
                // message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_1_MONTH_HISTORY_FAILED:
            return{
                ...state,
                asset1MonthObj: null,
                error:1,
                message: action.error,
                type: action.type,
            }
        case WEATHER_GET_ALARM_COUNT_START:
             
            return{
                ...state,
                assetCountLoading: true,
                error: 0 ,
                // message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ALARM_COUNT_LIST_START:
        return {
            ...state,
            assetCountListLoading: true,
            error: 0,
            type: action.type,
        }
        case WEATHER_GET_ALARM_COUNT_SUCCEED: 
             
            return{
                ...state,
                assetCountLoading: false,
                alarmCounts: action.payload,
                countUnit: 'day',
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ALARM_COUNT_LIST_SUCCEED:
            return{
                ...state,
                assetCountListLoading: false,
                alarmCountsList: action.payload,
                countUnit: 'day',
                error: 0,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_HOURLYALARM_COUNT_START:
            return{
                ...state,
                assetHourlyCountLoading: true,
                error: 0 ,
                // message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_MONTHLYALARM_COUNT_START:
            return {
                ...state,
                assetMonthlyCountLoading: true,
                error: 0,
                type: action.type,
            }
        case WEATHER_GET_QUARTERMONTHALARM_COUNT_START:
            return {
                ...state,
                assetQuarterMonthCountLoading: true,
                error: 0,
                type: action.type,
            }
        case WEATHER_GET_6MONTHSALARM_COUNT_START:
            return {
                ...state,
                asset6MonthsCountLoading: false,
                error: 0,
                type: action.type,
            }
        case WEATHER_GET_MONTHLYALARM_COUNT_SUCCEED:
            return {
                ...state,
                assetMonthlyCountLoading: false,
                alarmMonthlyCounts: action.payload,
                monthlyCountUnit: 'day',
                error: 0,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_QUARTERMONTHALARM_COUNT_SUCCEED:
            return {
                ...state,
                assetQuarterMonthCountLoading: false,
                alarmQuarterMonthCounts: action.payload,
                alarmQuarterMonthCountsUnit: 'day',
                error: 0,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_6MONTHSALARM_COUNT_SUCCEED:

            const sixMonthsCounts = sixMonthsAlarmProcessor(action.payload, action.parameters)
            return {
                ...state,
                asset6MonthsCountLoading: false,
                alarm6MonthsCounts: action.payload,
                alarm6MonthsCountsUnit: 'day',
                error: 0,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_24HYALARM_COUNT_START:
            return {
                ...state,
                alarm24HCountLoading: true,
                error: 0,
                type: action.type,
            }
        case WEATHER_GET_24HALARM_COUNT_SUCCEED:
            // console.log(action.payload)
            // console.log(action.parameters)
            const alarm24HCounts = lightningHourlyCountsProcessor(action.payload, action.parameters);
            return{
                ...state,
                alarm24HCountLoading: false,
                alarm24HCounts: alarm24HCounts,
                alarm24HCountUnit: 'hour',
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ROLLING24HALARM_COUNT_START:
            return {
                ...state,
                alarm24HCountLoading: true,
                error: 0,
                type: action.type,
            }
        case WEATHER_GET_ROLLING24HALARM_COUNT_SUCCEED:
            const alarmRolling24HCounts = lightningHourlyCountsProcessor(action.payload, action.parameters);
            return {
                ...state,
                alarm24HCountLoading: false,
                alarmRolling24HCounts: alarmRolling24HCounts,
                alarm24HCountUnit: 'hour',
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        
        case WEATHER_GET_ALARM_COUNT_LIST_FAILED:
            return {
                ...state,
                assetCountListLoading: false,
                alarmCountsList: [],
                countUnit: 'day',
                error: 1,
                message: 'error',
                type: action.type,
                
            }
        case WEATHER_GET_MONTHLYALARM_COUNT_FAILED:
            return {
                ...state,
                assetMonthlyCountLoading: false,
                monthlyAlarmCounts: [],
                monthlyCountUnit: 'day',
                error: 1,
                message: 'error',
                type: action.type,
            }
        case WEATHER_GET_QUARTERMONTHALARM_COUNT_FAILED:
            return {
                ...state,
                assetQuarterMonthCountLoading: false,
                alarmQuarterMonthCounts: [],
                alarmQuarterMonthCountsUnit: 'day',
                error: 1,
                message: 'error',
                type: action.type,
            }
        case WEATHER_GET_6MONTHSALARM_COUNT_FAILED:
            return {
                ...state,
                asset6MonthsCountLoading: false,
                alarm6MonthsCounts: [],
                alarm6MonthsCountsUnit: 'day',
                error: 1,
                message: 'error',
                type: action.type,
            }
        case WEATHER_GET_ALARM_COUNT_LIST_CLEAR:
            return {
                ...state,
                assetCountListLoading: false,
                alarmCountsList: [],
                countUnit: 'day',
                error: 0,
                message: 'clear',
                type: action.type,
            }
        case WEATHER_GET_MONTHLYALARM_COUNT_CLEAR:
            return {
                ...state,
                assetMonthlyCountLoading: false,
                monthlyAlarmCounts: [],
                monthlyCountUnit: 'day',
                error: 0,
                message: 'clear',
                type: action.type,
            }
        case WEATHER_GET_QUARTERMONTHALARM_COUNT_CLEAR:
            return {
                ...state,
                assetQuarterMonthCountLoading: false,
                alarmQuarterMonthCounts: [],
                alarmQuarterMonthCountsUnit: 'day',
                error: 0,
                message: 'clear',
                type: action.type,
            }
        case WEATHER_GET_6MONTHSALARM_COUNT_CLEAR:
            return {
                ...state,
                asset6MonthsCountLoading: false,
                alarm6MonthsCounts: [],
                alarm6MonthsCountsUnit: 'day',
                error: 0,
                message: 'clear',
                type: action.type,
            }
        case WEATHER_GET_24HALARM_COUNT_FAILED:
            return{
                ...state,
                alarm24HCountLoading: false,
                alarm24HCounts: [],
                alarm24HCountUnit: 'hour',
                error: 1 ,
                message: 'error',
                type: action.type,
            }
        case WEATHER_GET_24HALARM_COUNT_CLEAR:
            return{
                ...state,
                alarm24HCountLoading: false,
                alarm24HCounts: [],
                alarm24HCountUnit: 'hour',
                error: 0 ,
                message: 'clear',
                type: action.type,
            }
        case WEATHER_GET_HOURLYALARM_COUNT_SUCCEED:
            let hourlyCount = [];
             
            //  
            if (action.payload && action.parameters && action.parameters.starttime) {
                let timeArray = action.parameters.starttime.split(':');
                // hourlyCount = action.payload.map((item, index) => ({date:item.time.substr(0,5), yellow: item.yellow, orange: item.orange, red: item.red, green: item.green}));
                hourlyCount = action.payload.map((item, index) => ({date:`${timeArray[0]}T${item.time}.000+0000`, yellow: item.yellow, orange: item.orange, red: item.red, green: item.green}));
            }
            //  
             
            //  

            return{
                ...state,
                assetHourlyCountLoading: false,
                alarmCounts: hourlyCount,
                countUnit: 'hour',
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ALARM_COUNT_CLEAR:
             
            return{
                ...state,
                assetCountLoading: false,
                alarmCounts:[],
                countUnit: 'day',
                error: 0 ,
                message: action.message,
                type: action.type,
            }
        case WEATHER_GET_ALL_CONFIG_SUCCEED:
            return{
                ...state,
                allConfig: action.payload,
                error: 0 ,
                message: msg_succeed,
                type: action.type,
            }
        case WEATHER_GET_ALL_CONFIG_FAILED: 
            return{
                ...state,
                allConfig: [],
                error: 1 ,
                message: action.error,
                type: action.type,
            }
        case WEATHER_GET_ASSET_DATA_FAILED:
            return {
                ...state,
                assetDataLoading: false,
                rawAssetData:[],
                assetData:[],
                assetExpendData:[],
                error: 1 ,
                message: action.error,
                type: action.type,
            }
        case WEATHER_GET_ASSET_HISTORY_FAILED:
            return {
                ...state,
                rawAssetHistoryData:[],
                assetHistoryData:[],
                error: 1 ,
                message: action.error,
                type: action.type,
            }
        case WEATHER_GET_HOURLYALARM_COUNT_FAILED:
            return {
                ...state,
                assetHourlyCountLoading: false,
                alarmCounts:[],
                error: 1 ,
                message: action.error,
                type: action.type,
            }
        case WEATHER_GET_ALARM_COUNT_FAILED:
             
            return {
                ...state,
                assetCountLoading: false,
                alarmCounts:[],
                error: 1 ,
                message: action.error,
                type: action.type,
            }
        default:
            return state;
    }
}

// ---------------- data processing helpers ------------------------
export function assetHistoryDataProcessor(AssetData, parameters=null, allAttriList=null){
    let starttime = null;
    let endtime = null;
    if (parameters && parameters.starttime && parameters.endtime) {
        starttime = parameters.starttime;
        endtime = parameters.endtime;
    }
    let reData = null;
    const shownAtrributes = ATTRIBUTE_ORDER.filter(item => (item.selected === true)).map(item => (item.attributename));
    const shownAllAttributes = [...shownAtrributes, 'Alarm','LevelTotal'];
    if (Array.isArray(AssetData) && AssetData.length > 0) {
        const assets = AssetData[0].asset;
        if (Array.isArray(assets) && assets.length > 0) {
            reData = {};
            for (let asset of assets) {
                const key = asset.assetid;
                const attrmap = {};
                Array.isArray(asset.device) &&  asset.device.forEach( dev => {
                    Array.isArray(dev.msgs) &&  dev.msgs.forEach((msg, mindex) => {
                        const time = msg.timestamp;
                        msg.msg && Object.entries(msg.msg).forEach(([key, value])=>{
                            if (allAttriList && shownAllAttributes.includes(value.attributename)) {
                                let attriObj = {
                                    assetid: asset.assetid,
                                    assetname: asset.assetname,
                                    attributename: value.attributename,
                                    displayname: value.displayname,
                                    type: ATTRIBUTE_TYPE.DEVICE,
                                    display: value.display,
                                    attritime:msg.timestamp,
                                    timestamp:msg.timestamp,
                                    value: value.value,
                                };
                                allAttriList.push(attriObj);
                                
                            }
                            if (! shownAtrributes.includes(value.attributename)) {
                                let flag = true;
                                for(let z = 0; z < asset.assetattributes.length; z++){
                                    let strraw = asset.assetattributes[z].value;
                                    if(strraw.toLowerCase()=="parking"){
                                        flag = false;
                                    }
                                }

                                if(flag){
                                    return;
                                }
                            }
                            const group_key = `${dev.deviceid}:${key}`;
                            if (attrmap[group_key]){
                                attrmap[group_key]['data'].push({x:time, y: value.value});
                                attrmap[group_key]['lasttime']= time;
                                attrmap[group_key]['lastvalue']= value.value;
                            }
                            else{
                                const data = [{x:time, y: value.value}];
                                const lasttime = time;
                                const lastvalue = value.value;
                                const displayname = value.displayname;
                                const type = ATTRIBUTE_TYPE.DEVICE;
                                const attrbase = ATTRIBUTE_ORDER.find(item => (item.attributename === value.attributename));
                                let attritem = {
                                    ...attrbase,
                                    data,
                                    lasttime,
                                    lastvalue,
                                    displayname,
                                    type,
                                    starttime,
                                    endtime,
                                }
                                attrmap[group_key] = attritem;
                            }
                            // if (mindex == dev.msgs.length -1) {
                            //     attrmap[group_key]['lasttime']= time;
                            //     attrmap[group_key]['lastvalue']= value.value;
                            // }
                        });
                    });
                    // reData[key] = attrmap;
                });
                reData[key] = attrmap;
            }
        }
    }
    return reData;
}

export function powerAssetHistoryDataProcessor(AssetData, parameters=null, powerAttrList=null){
    let starttime = null;
    let endtime = null;
    if (parameters && parameters.starttime && parameters.endtime) {
        starttime = parameters.starttime;
        endtime = parameters.endtime;
    }
    let reData = null;
    const shownAttributes = POWER_ATTRIBUTE_ORDER.filter(item => (item.selected === true)).map(item => (item.attributename));
    const shownAllAttributes = [...shownAttributes];
    if (Array.isArray(AssetData) && AssetData.length > 0) {
        const assets = AssetData[0].asset;
        // console.log("===weatherReducerassets", assets);
        if (Array.isArray(assets) && assets.length > 0) {
            reData = {};
            for (let asset of assets) {
                const key = asset.assetid;
                const attrmap = {};
                Array.isArray(asset.device) &&  asset.device.forEach( dev => {
                    Array.isArray(dev.msgs) &&  dev.msgs.forEach((msg, mindex) => {
                        const time = msg.timestamp;
                        msg.msg && Object.entries(msg.msg).forEach(([key, value])=>{
                            if (powerAttrList && shownAllAttributes.includes(value.attributename)) {
                                let attriObj = {
                                    deviceid: dev.deviceid,
                                    assetid: asset.assetid,
                                    assetname: asset.assetname,
                                    numberofdevices: asset.device.length,
                                    attributename: value.attributename,
                                    displayname: value.displayname,
                                    type: ATTRIBUTE_TYPE.DEVICE,
                                    display: value.display,
                                    attritime:msg.timestamp,
                                    timestamp:msg.timestamp,
                                    value: value.value,
                                };
                                powerAttrList.push(attriObj);
                            }
                            // console.log("===weatherReducerPowerAttr", powerAttrList);
                            if (! shownAttributes.includes(value.attributename)) {
                                let flag = true;
                                for(let z = 0; z < asset.assetattributes.length; z++){
                                    let strraw = asset.assetattributes[z].value;
                                    if(strraw.toLowerCase()=="parking"){
                                        flag = false;
                                    }
                                }

                                if(flag){
                                    return;
                                }
                            }
                            const group_key = `${dev.deviceid}:${key}`;
                            if (attrmap[group_key]){
                                attrmap[group_key]['data'].push({x:time, y: value.value});
                                attrmap[group_key]['lasttime']= time;
                                attrmap[group_key]['lastvalue']= value.value;
                            }
                            else{
                                const data = [{x:time, y: value.value}];
                                const lasttime = time;
                                const lastvalue = value.value;
                                const displayname = value.displayname;
                                const type = ATTRIBUTE_TYPE.DEVICE;
                                const attrbase = POWER_ATTRIBUTE_ORDER.find(item => (item.attributename === value.attributename));
                                let attritem = {
                                    ...attrbase,
                                    data,
                                    lasttime,
                                    lastvalue,
                                    displayname,
                                    type,
                                    starttime,
                                    endtime,
                                }
                                attrmap[group_key] = attritem;
                            }
                            // if (mindex == dev.msgs.length -1) {
                            //     attrmap[group_key]['lasttime']= time;
                            //     attrmap[group_key]['lastvalue']= value.value;
                            // }
                        });
                    });
                    // reData[key] = attrmap;
                });
                reData[key] = attrmap;
            }
        }
    }
    return reData;
}

export function updateAssetHistoryData (history, allAttriList, pubsub, startDateMoment, endDateMoment) {
     

    // assetHistoryObj,
    // allAttriList,

    const starttime = startDateMoment ? startDateMoment.utc().valueOf(): null;
    const endtime = endDateMoment ? endDateMoment.utc().valueOf() : null;
    // let reValue = history;
    let reObj = {};
    reObj.hisoryObj = history;
    reObj.allAttriList = allAttriList;
    if (pubsub && history && history[pubsub.assetid]!= null) {
        const historyClone = JSON.parse(JSON.stringify(history));
        Array.isArray(pubsub.msgs) && pubsub.msgs.forEach(msg => {
            msg.msg && Object.entries(msg.msg).forEach(([key, value])=>{
                if (Array.isArray(allAttriList) && allAttriList.length > 0 && shownAllAttributes.includes(value.attributename)) {
                    
                    let allAttriListClone = allAttriList.slice(0);
                    let firstItem = allAttriListClone[0];
                    if (firstItem.assetid == pubsub.assetid) {
                        let attriObj = {
                            ...firstItem,
                            attributename: value.attributename,
                            displayname: value.displayname,
                            type: ATTRIBUTE_TYPE.DEVICE,
                            display: value.display,
                            value: value.value,
                            attritime:msg.timestamp,
                            timestamp:msg.timestamp,
                        };
                        allAttriListClone.push(attriObj);
                        if (starttime && endtime) {
                            allAttriListClone = allAttriListClone.filter(item => (item.timestamp >= starttime && item.timestamp <= endtime));
                        }
                        reObj.allAttriList = allAttriListClone
                    }
                }
                const group_key = `${pubsub.deviceid}:${key}`;
                if (historyClone[pubsub.assetid][group_key]){
                    let attriObj = historyClone[pubsub.assetid][group_key];
                    attriObj['data'].push({x:msg.timestamp, y: value.value});
                    // if (attriObj['starttime'] && attriObj['endtime']) {
                    //     attriObj['data'] = attriObj['data'].filter(item => (item.x >= attriObj['starttime'] && item.x <= attriObj['endtime']));
                    // }
                    if (starttime && endtime) {
                        attriObj['starttime'] = starttime;
                        attriObj['endtime'] = endtime;
                        attriObj['data'] = attriObj['data'].filter(item => (item.x >= starttime && item.x <= endtime));
                    }
                }
            });
        });

        reObj.hisoryObj = historyClone;
    }
    return reObj;
}

function lightningHourlyCountsProcessor(payload, parameters) {
    let hourlyCount = [];
    if (payload && parameters && parameters.starttime) {
        // console.log("===hourlyparameters", parameters);
        // console.log("===parameters.starttime", parameters.starttime);
        let timeArray = parameters.starttime.split(':');
        // console.log("===timeArray", timeArray);
        // hourlyCount = action.payload.map((item, index) => ({date:item.time.substr(0,5), yellow: item.yellow, orange: item.orange, red: item.red, green: item.green}));
        if (Array.isArray(timeArray) && timeArray.length > 0) {
            hourlyCount = payload.map((item, index) => ({date:`${timeArray[0]}T${item.time}.000+0000`, yellow: item.yellow, orange: item.orange, red:item.red, green: item.green}));
        // console.log("===hourlyCount", hourlyCount);

        }       
    }
    return hourlyCount;
}

function sixMonthsAlarmProcessor(payload, parameters) {
    let sixMonthsCount = [];
    if(payload && parameters && parameters.starttime) {
        // console.log("===parametersMonthly", parameters);
        let timeArray = parameters.starttime;
        if(Array.isArray(timeArray) && timeArray.length > 0) {
            sixMonthsCount = payload.map((item, index) => ({data: `${timeArray[0]}`, red: item.red}));
            // console.log("===sixMonthsCount", sixMonthsCount);
        }
    }

    return sixMonthsCount;
}