/* eslint-disable */
import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  FormText,
  Input,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classNames from "classnames";

const backdropStyle = {
  position: "fixed",
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#000",
  opacity: 0.5,
  overflow: "auto",
};

class DecoderEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataElement: "",
      showModal: false,
      data: [],
      lowerLimit: null,
      upperLimit: null,
      attributeId: null,
      calculateExpression: null,
      conditionExpression: null,
      convertedDataType: null,
      endPosition: null,
      startPosition: null,
      initialDataType: null,
      readDirection: null,
      subEndPoint: null,
      subStartPoint: null,
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    console.log("MODAL PROPS", nextProps);
    this.setState(
      {
        dataElement: nextProps.editRow.dataElement,
        // size: nextProps.editRow.size,
        // from: nextProps.editRow.from,
        // to: nextProps.editRow.to,
        // dataType: nextProps.editRow.dataType,
        lowerLimit: nextProps.editRow.lowerLimit,
        upperLimit: nextProps.editRow.upperLimit,
        // initialValue: nextProps.editRow.initialValue,
        // formula: nextProps.editRow.formula,

        // Calculate_rule Table
        attributeId: nextProps.editRow.attributeId,
        conditionExpression: nextProps.editRow.conditionExpression,
        calculateExpression: nextProps.editRow.calculateExpression,

        //Decode_rule Table
        startPosition: nextProps.editRow.startPosition,
        endPosition: nextProps.editRow.endPosition,
        initialDataType: nextProps.editRow.initialDataType,
        convertedDataType: nextProps.editRow.convertedDataType,
        subStartPoint: nextProps.editRow.subStartPoint,
        subEndPoint: nextProps.editRow.subEndPoint,
        readDirection: nextProps.editRow.readDirection,
      },
      () => {
        if (nextProps.show === true) {
          this.setState({
            showModal: true,
          });
        }
      }
    );
  }

  renderBackdrop = (props) => {
    return <div {...props} style={backdropStyle} />;
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  onSubmit = () => {
    let dataObj = {
      dataElement: this.state.dataElement,
      // size: this.state.size,
      // from: this.state.from,
      // to: this.state.to,
      // dataType: this.state.dataType,
      lowerLimit: this.state.lowerLimit,
      upperLimit: this.state.upperLimit,
      // initialValue: this.state.initialValue,
      // formula: this.state.formula,

      // Calculate_rule Table
      attributeId: this.state.attributeId,
      conditionExpression: this.state.conditionExpression,
      calculateExpression: this.state.calculateExpression,

      //Decode_rule Table
      startPosition: this.state.startPosition,
      endPosition: this.state.endPosition,
      initialDataType: this.state.initialDataType,
      convertedDataType: this.state.convertedDataType,
      subStartPoint: this.state.subStartPoint,
      subEndPoint: this.state.subEndPoint,
      readDirection: this.state.readDirection,
    };

    this.props.handelEditTableData(dataObj);

    this.onClose();
  };

  onClose = () => {
    this.setState({
      showModal: false,
    });
    this.props.toggle();
    // let currentLocation = window.location;
    // currentLocation.pathname == "/pages/deviceconfig" ? history.back() : this.props.toggle();
  };
  render() {
    const {
      /* size,
      from,
      to,
      dataType,
      initialValue,
      formula, */

      lowerLimit,
      upperLimit,
      // Calculate_rule Table:
      attributeId,
      name,
      displayName,
      calculateExpression,
      conditionExpression,

      // Decode_rule Table:
      startPosition,
      endPosition,
      initialDataType,
      convertedDataType,
      subStartPoint,
      subEndPoint,
      readDirection,
    } = this.props;

    
    return (
      <Modal
        isOpen={this.state.showModal}
        renderBackdrop={this.renderBackdrop}
        size="lg"
      >
        <ModalHeader toggle={this.onClose}>
          {this.props.editRow ? this.props.editRow.dataElement : "Config"}
        </ModalHeader>
        <ModalBody>
          {/* <Nav tabs>
            <NavItem style={{cursor: 'pointer'}}>
              <NavLink className={classNames({active:this.state.activeTab === '1'})} onClick={()=>{this.tabToggle('1')}}>
                Calculate Rule
              </NavLink>
            </NavItem>
            <NavItem style={{cursor: 'pointer'}}>
              <NavLink className={classNames({active:this.state.activeTab==='2'})} onClick={()=>{this.tabToggle('2')}}>
                Decode Rule
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              
            </TabPane>
          </TabContent> */}
          <Col md={12}>
            <Row>
              <Col md={3}>
                <label style={{ marginBottom: "3px" }}>Attribute ID</label>
                <Input
                  name="attributeId"
                  placeholder="Attribute ID"
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: "14px" }}
                  value={attributeId}
                />
              </Col>
              <Col md={3}>
                <label style={{ marginBotton: "3px" }} for="displayName">
                  Display Name
                </label>
                <Input
                  name="displayName"
                  placeholder="Display Name"
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: "14px" }}
                  value={displayName}
                />
              </Col>
              <Col md={3}>
                <label
                  style={{ marginBottom: "3px" }}
                  for="calculateExpression"
                >
                  Calculate Expression
                </label>
                <Input
                  name="calculateExpression"
                  placeholder="Calculate Expression"
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: "14px" }}
                  value={calculateExpression}
                />
              </Col>
              <Col md={3}>
                <label
                  style={{ marginBotton: "3px" }}
                  for="conditionExpression"
                >
                  Condition Expression
                </label>
                <Input
                  name="conditionExpression"
                  placeholder="Condition Expression"
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: "14px" }}
                  value={conditionExpression}
                />
              </Col>
            </Row>
              <Row>
                <Col md={3}>
                  <label style={{ marginBotton: "3px" }} for="startPosition">Start Position</label>
                  <Input
                    name="startPosition"
                    placeholder="Start Position"
                    onChange={this.onInputChange}
                    className="form-control"
                    style={{ fontSize: "14px" }}
                    value={startPosition}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ marginBotton: "3px" }} for="endPosition">End Position</label>
                  <Input
                    name="endPosition"
                    placeholder="End Position"
                    onChange={this.onInputChange}
                    className="form-control"
                    style={{ fontSize: "14px" }}
                    value={endPosition}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ marginBotton: "3px" }} for="initialDataType">Initial Data Type</label>
                  <Input
                    name="initialDataType"
                    placeholder="Initial Data Type"
                    onChange={this.onInputChange}
                    className="form-control"
                    style={{ fontSize: "14px" }}
                    value={initialDataType}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ marginBotton: "3px" }} for="convertedDataType">Converted Data Type</label>
                  <Input
                    name="convertedDataType"
                    placeholder="Converted Data Type"
                    onChange={this.onInputChange}
                    className="form-control"
                    style={{ fontSize: "14px" }}
                    value={convertedDataType}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label style={{ marginBottom: '3px'}} for="subStartPoint">Sub Start Point</label>
                  <Input
                    name="subStartPoint"
                    placeholder="Sub Start Point"
                    onChange={this.onInputChange}
                    className="form-control"
                    style={{fontSize:'14px'}}
                    value={subStartPoint}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ marginBottom: '3px'}} for="subEndPoint">Sub End Point</label>
                  <Input
                    name="subEndPoint"
                    placeholder="Sub End Point"
                    onChange={this.onInputChange}
                    className="form-control"
                    style={{fontSize:'14px'}}
                    value={subEndPoint}
                  />
                </Col>
                <Col md={3}>
                  <label style={{ marginBottom: '3px'}} for="readDirection">Read Direction</label>
                  <Input
                    name="readDirection"
                    placeholder="Read Direction"
                    onChange={this.onInputChange}
                    className="form-control"
                    style={{fontSize:'14px'}}
                    value={readDirection}
                  />
                </Col>
              </Row>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Col md={12}>
            <Row>
              <Col md={12} className={"text-right"}>
                <Button
                  outline
                  color="custom"
                  type="submit"
                  onClick={this.onSubmit}
                >
                  Submit
                </Button>
                <Button
                  outline
                  color="secondary"
                  type="reset"
                  onClick={this.onClose}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DecoderEditModal;
