/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";

import SelectQuantity from './SelectQuantity';
import AdditionalCharges from './AdditionalCharges';
import ShippingAddress from './ShippingAddress';
import PaymentMethod from './PaymentMethod';

import { getProvinces } from '../../../../redux/actions/userAction';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toast } from "react-toastify";


class ClientGenerateInvoiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,

            paymentMethod: {},
            //Select Quantity
            deviceQuantityRequest: new Map(),
            //Additional Charges
            additionalChargesList: [
                {
                    "description": '',
                    "price": '',
                    "quantity": '',
                    "tax": false
                }
            ],
            //Shipping Address
            shippingAddress: { choseAddress: 0, shippingCharges: '' },
            //Payment Method
            stateList: [],
            billingAddress: { choseAddress: 0, isSameWithShippingAddress: false },
            remapPaymentAccountList: this.remapPaymentAccountList(),
        }
        // console.log("ClientGenerationInvoiceModal > Constructor", props)
    }

    objsteps = {
        "Select Quantity": SelectQuantity,
        "Additional Charges": AdditionalCharges,
        "Shipping Address": ShippingAddress,
        "Payment Method": PaymentMethod
    };

    // Lifecycle
    componentDidMount() {
        this.setState({
            statesList: (this.props.countryList.length > 0) ? this.props.provinceMap.get(this.props.countryList[0].value) : []
        })

    }


    componentWillReceiveProps(nextProps, nextContent) {

        /*
        this.setState({
            remapPaymentAccountList: this.remapPaymentAccountList(),
        })
        */
    }

    componentWillUpdate(nextProps, nextState) {
        // console.log("componentWillUpdate")
    }

    // Navigation

    handleNext = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep + 1,
        }));
    }

    handleBack = () => {
        if (this.state.activeStep == 1) {
            this.setState(prevState => {
                return {
                    /*
                    additionalChargesList: [
                        {
                            "description": '',
                            "price": '',
                            "quantity": '',
                            "tax": false
                        }
                    ],*/
                    activeStep: (prevState.activeStep - 1) >= 0 ? prevState.activeStep - 1 : 0,
                }
            });

        } else if (this.state.activeStep == 2) {

            this.setState(prevState => {
                return {
                    shippingAddress: { choseAddress: 0, shippingCharges: '' },

                    activeStep: (prevState.activeStep - 1) >= 0 ? prevState.activeStep - 1 : 0,
                }
            });

        } else if (this.state.activeStep == 3) {
            var pm = this.state.billingAddress;
            pm.isSameWithShippingAddress = false;
            this.setState(prevState => {
                return {
                    //paymentMethod: {},
                    billingAddress: pm,
                    //remapPaymentAccountList: this.remapPaymentAccountList(),
                    activeStep: (prevState.activeStep - 1) >= 0 ? prevState.activeStep - 1 : 0,
                }
            });

        }
        else {
            this.setState(prevState => {
                return {
                    activeStep: (prevState.activeStep - 1) >= 0 ? prevState.activeStep - 1 : 0,
                }
            });
        }
    }

    onClose = () => {
        this.setState({
            activeStep: 0,
            paymentMethod: {},
            deviceQuantityRequest: new Map(),
            additionalChargesList: [
                {
                    "description": '',
                    "price": '',
                    "quantity": '',
                    "tax": false
                }
            ],
            shippingAddress: { choseAddress: 0, shippingCharges: '' },
            billingAddress: { choseAddress: 0, isSameWithShippingAddress: false },
            remapPaymentAccountList: this.remapPaymentAccountList(),
        }, () => {
            this.props.onClose()
        })
    }

    // remap list

    remapPaymentAccountList = () => {
        var payments = {
            paymentTypes: {
                "CC": "Credit Card",
                "ACH": " Automated Clearing House",
                "CHK": "Check",
            }
        }

        var accountsByMethod = {}
        var defaultPayment = {}
        var selectedPayment = {}
        var selectedMethod = {}

        this.props.paymentAccountList.map(ac => {
            var account = {
                accountId: ac.accountId,
                paymentMethod: ac.paymentMethod,
                cardMask: ac.cardMask,
                defaultPayment: ac.defaultPayment,
                status: ac.status,
                origin: ac,
            }

            if (account.defaultPayment === 'Y') {
                defaultPayment = account
                selectedPayment = account
                selectedMethod = account.paymentMethod
            }

            if (ac.paymentMethod in accountsByMethod) {
                accountsByMethod[ac.paymentMethod].push(account)
            } else {
                accountsByMethod[ac.paymentMethod] = [account]
            }

        })

        payments.paymentMethods = Object.keys(accountsByMethod)
        payments.paymentAccounts = accountsByMethod
        payments.defaultPayment = defaultPayment
        payments.selectedPayment = selectedPayment
        payments.selectedMethod = selectedMethod

        return payments
    }

    // onChange

    onCountryChange = (event) => {

        this.setState({
            statesList: this.props.provinceMap.get(event.target.value)
        })
    }

    onQuantityChange = (e, cell, row, enumObject, index) => {
        const digitreg = /^[0-9\b]+$/;
        if (e.target.value == '' || digitreg.test(e.target.value)) {
            let newRequest = new Map()
            this.state.deviceQuantityRequest.forEach((value, key) => {
                newRequest.set(key, value);
            })
            if (e.target.value == 0) {
                newRequest.delete(row.itemId);
            } else {
                newRequest.set(row.itemId, {
                    "id": row.itemId,
                    "name": row.itemName,
                    "unitPrice": row.unitPrice,
                    "quantity": e.target.value
                });
            }
            // console.log("newRequest: ", newRequest);
            // console.log("ClientGenerateInvoiceModal > deviceQuantityRequest", newRequest, "<<<>>>", this.state.deviceQuantityRequest)

            this.setState({
                deviceQuantityRequest: newRequest
            });
        }

    }

    onAddCharge = () => {
        const charge = [{
            "description": '',
            "price": '',
            "quantity": '',
            "tax": false
        }];
        const chargesList = this.state.additionalChargesList.concat(charge);
        this.setState({
            additionalChargesList: chargesList
        })

    }

    onDeleteCharge = (i) => {
        // console.log(i);
        const chargesList = this.state.additionalChargesList.filter((value, index) => i != index);
        // console.log("after delete:", chargesList);
        this.setState({
            additionalChargesList: chargesList
        })
    }

    onAdditionalChargesChange = (index, name, value) => {
        const digitreg = /^[0-9\b]+$/;
        const pricereg = /^(\d+\.{0,1}\d{0,2})$|^(\.{0,1}\d{0,2})$/;

        // console.log("onAdditionalChargesChange....");
        // console.log(index, name, value)
        // console.log("before change: ", this.state.additionalChargesList)

        // let charge = this.state.additionalChargesList.filter(element => element.index == index)
        let newChargeList = this.state.additionalChargesList.map((e, i) => {
            if (i == index) {
                // console.log('found!')
                let element = { ...e };
                if (name == "description") {
                    if (value.length <= 40) {
                        element.description = value;
                    }
                } else if (name == "quantity") {
                    if (value == '' || digitreg.test(value)) {
                        element.quantity = value;
                    }
                } else if (name == "unitPrice") {
                    if (value == '' || pricereg.test(value)) {
                        element.price = value
                    }
                } else if (name == "tax")
                    element.tax = value
                else {
                    // console.log("error AdditionalChargesChange name", name)
                }
                // if(!element.description && !element.quantity && !element.price && element.tax!=true){
                //     console.log("add charge is set to null, remove from list, ", e, i, element);       
                // }
                // else {
                //     console.log("element", element)
                //     return element;
                // }

                return element;

            } else {
                return e;
            }
        });

        // console.log("newChargeList.length ", newChargeList.length, newChargeList );
        if (newChargeList.length == 0) {
            newChargeList = [
                {
                    "description": '',
                    "price": '',
                    "quantity": '',
                    "tax": false
                }
            ]
        }

        // console.log("after change: ", newChargeList);
        this.setState({
            additionalChargesList: newChargeList
        });
    }

    onShippingAddressChange = (event) => {
        const pricereg = /^(\d+\.{0,1}\d{0,2})$|^(\.{0,1}\d{0,2})$/;

        switch (event.target.name) {
            case "sa_newShippingAddress":
                var sa = this.state.shippingAddress;
                sa.choseAddress = event.target.value;
                var address = this.props.addressList.find(address => address.id == sa.choseAddress)
                var country = this.props.countryList[0].value
                if (address != null) {
                    country = address.country;
                    sa.name = address.name
                    sa.addressLine1 = address.addressLine1
                    sa.addressLine2 = address.addressLine2
                    sa.city = address.city
                    sa.zipCode = address.zipCode
                    sa.province = address.state
                    sa.country = address.country
                }
                if (sa.choseAddress == 0) {
                    sa.name = ""
                    sa.addressLine1 = ""
                    sa.addressLine2 = ""
                    sa.city = ""
                    sa.zipCode = ""
                    sa.province = this.props.provinceMap.get(this.props.countryList[0].value)[0].abbrev
                    sa.country = this.props.countryList[0].value

                }
                this.setState({ shippingAddress: sa, statesList: this.props.provinceMap.get(country) });
                break;

            case "sa_name":
                this.state.shippingAddress.name = event.target.value;
                this.setState({ shippingAddress: this.state.shippingAddress });
                break;

            case "sa_shippingAddressLine1":
                this.state.shippingAddress.addressLine1 = event.target.value;
                this.setState({ shippingAddress: this.state.shippingAddress });
                break;

            case "sa_shippingAddressLine2":
                this.state.shippingAddress.addressLine2 = event.target.value;
                this.setState({ shippingAddress: this.state.shippingAddress });
                break;

            case "sa_city":
                this.state.shippingAddress.city = event.target.value;
                this.setState({ shippingAddress: this.state.shippingAddress });
                break;

            case "sa_zipCode":
                this.state.shippingAddress.zipCode = event.target.value;
                this.setState({ shippingAddress: this.state.shippingAddress });
                break;

            case "sa_country":
                this.state.shippingAddress.country = event.target.value;
                this.setState({ shippingAddress: this.state.shippingAddress });
                break;

            case "sa_stateProvince":
                this.state.shippingAddress.province = event.target.value;
                this.setState({ shippingAddress: this.state.shippingAddress });
                break;

            case "sa_shippingCharges":
                if (pricereg.test(event.target.value)) {
                    this.state.shippingAddress.shippingCharges = event.target.value;
                    this.setState({ shippingAddress: this.state.shippingAddress });
                }

                break;
        }
    }

    onPaymentMethodChange = (event) => {
        switch (event.target.name) {
            case "pm_paymentType":
                var payments = this.state.remapPaymentAccountList;
                payments.selectedMethod = event.target.value;
                payments.defaultPayment = null;
                payments.selectedPayment = null;
                if (event.target.value === "ACH" || event.target.value === "CHK") {
                    payments.selectedPayment = payments.paymentAccounts[event.target.value][0];
                }
                this.setState({ remapPaymentAccountList: payments })
                break;

            case "pm_paymentCard":
                this.state.remapPaymentAccountList.selectedPayment = this.props.paymentAccountList.find(ac => ac.accountId == event.target.value)
                this.setState({ remapPaymentAccountList: this.state.remapPaymentAccountList })
                break;

            case "pm_billingAddress":
                var pm = this.state.billingAddress;
                pm.choseAddress = event.target.value;
                var address = this.props.addressList.find(address => address.id == pm.choseAddress)
                var country = this.props.countryList[0].value
                if (address != null) {
                    country = address.country;
                    pm.name = address.name
                    pm.addressLine1 = address.addressLine1
                    pm.addressLine2 = address.addressLine2
                    pm.city = address.city
                    pm.zipCode = address.zipCode
                    pm.province = address.state
                    pm.country = address.country
                }
                if (pm.choseAddress == 0) {
                    pm.name = ""
                    pm.addressLine1 = ""
                    pm.addressLine2 = ""
                    pm.city = ""
                    pm.zipCode = ""
                    pm.province = this.props.provinceMap.get(this.props.countryList[0].value)[0].abbrev
                    pm.country = this.props.countryList[0].value
                    pm.isSameWithShippingAddress = false
                }
                this.setState({ billingAddress: pm, statesList: this.props.provinceMap.get(country) });
                break;

            case "pm_sameShippingAddress":
                if (event.target.checked) {
                    this.state.billingAddress = this.state.shippingAddress
                    this.state.billingAddress.isSameWithShippingAddress = true
                } else {
                    this.state.billingAddress = {
                        choseAddress: 0,
                        name: '', addressLine1: '', addressLine2: '',
                        city: '', zipCode: '', province: '', country: '',
                        isSameWithShippingAddress: false,
                    }
                }
                this.setState({ billingAddress: this.state.billingAddress });
                // console.log("onPaymentMethod > same with shipping address", this.state.billingAddress)
                break;

            case "pm_name":
                this.state.billingAddress.name = event.target.value
                this.setState({ billingAddress: this.state.billingAddress })
                break;

            case "pm_billingAddressLine1":
                this.state.billingAddress.addressLine1 = event.target.value
                this.setState({ billingAddress: this.state.billingAddress })
                break;

            case "pm_billingAddressLine2":
                this.state.billingAddress.addressLine2 = event.target.value
                this.setState({ billingAddress: this.state.billingAddress })
                break;

            case "pm_city":
                this.state.billingAddress.city = event.target.value
                this.setState({ billingAddress: this.state.billingAddress })
                break;

            case "pm_zipCode":
                this.state.billingAddress.zipCode = event.target.value
                this.setState({ billingAddress: this.state.billingAddress })
                break;

            case "pm_stateProvince":
                this.state.billingAddress.province = event.target.value
                this.setState({ billingAddress: this.state.billingAddress })
                break;

            case "pm_country":
                this.state.billingAddress.country = event.target.value
                this.setState({ billingAddress: this.state.billingAddress })
                break;

        }
    }

    // Validation 

    validateSelectQuantity = () => {
        if (this.state.deviceQuantityRequest && this.state.deviceQuantityRequest.size > 0) {
            let valid = true;
            this.state.deviceQuantityRequest.forEach((value, key) => {
                if (isNaN(value.quantity) || value.quantity < 0) {
                    valid = false;
                }
            });
            return valid;
        } else
            return false;
    }

    validateAdditionalCharges = () => {
        // console.log(this.state.additionalChargesList)
        if (this.state.additionalChargesList && this.state.additionalChargesList.length > 0) {
            let flag = this.state.additionalChargesList.map((element, index) => {
                if (!element.description && !element.price && !element.quantity && element.tax != true) {
                    return true;
                } else if (element.description && element.price && element.quantity) {
                    if (!isNaN(element.price) && parseFloat(element.price) > 0
                        && !isNaN(element.quantity) && Number.isInteger(Number(element.quantity)) && Number(element.quantity) > 0) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });
            let result = flag.reduce((prev, cur) => {
                return prev && cur;
            }, true);
            return result;
        } else {
            return true;
        }
    }

    validateShippingAddress = () => {
        return (
            this.state.shippingAddress.addressLine1 && this.state.shippingAddress.addressLine1 !== '' && this.state.shippingAddress.addressLine1.trim()
            && this.state.shippingAddress.city && this.state.shippingAddress.city !== '' && this.state.shippingAddress.city.trim()
            && this.state.shippingAddress.zipCode && this.state.shippingAddress.zipCode !== '' && this.state.shippingAddress.zipCode.trim()
            && this.state.shippingAddress.province && this.state.shippingAddress.province !== '' && this.state.shippingAddress.province.trim()
            && this.state.shippingAddress.country && this.state.shippingAddress.country !== '' && this.state.shippingAddress.country.trim()
            && (this.state.shippingAddress.shippingCharges == '' || parseFloat(this.state.shippingAddress.shippingCharges) > 0)
        )
    }

    validatePaymentMethod = () => {
        // console.log("validatePaymentMethod")

        if (this.state.billingAddress.choseAddress == 0) {
            return (
                this.state.billingAddress.addressLine1 && this.state.billingAddress.addressLine1 !== '' && this.state.billingAddress.addressLine1.trim()
                && this.state.billingAddress.city && this.state.billingAddress.city !== '' && this.state.billingAddress.city.trim()
                && this.state.billingAddress.zipCode && this.state.billingAddress.zipCode !== '' && this.state.billingAddress.zipCode.trim()
                && this.state.billingAddress.province && this.state.billingAddress.province !== '' && this.state.billingAddress.province.trim()
                && this.state.billingAddress.country && this.state.billingAddress.country !== '' && this.state.billingAddress.country.trim()
            ) && this.state.remapPaymentAccountList.selectedPayment != null
        }

        if (this.state.billingAddress.choseAddress > 0) {
            return this.state.remapPaymentAccountList.selectedPayment != null
        }

        return false
    }

    // Completion

    onSubmit = () => {
        let deviceQuantities = {}
        this.state.deviceQuantityRequest.forEach((value, key) => {
            deviceQuantities[key] = parseInt(value.quantity)//.push({ [key]: parseInt(value.quantity)})
        })

        let additionalChargeList = this.state.additionalChargesList.filter(value =>
            value.description != '' && value.price != '' && value.quantity != ''
        ).map(value => {

            return {
                name: value.description,
                unitPrice: parseFloat(value.price),
                quantity: parseInt(value.quantity),
                tax: value.tax
            }

        })
        let params = {
            companyId: String(this.props.selectedRow.companyId),
            resellerId: String(this.props.selectedRow.id),
            deviceQuantityRequest: deviceQuantities,//this.state.deviceQuantityRequest,
            additionalChargeList: additionalChargeList,
            shippingAddress: {
                companyId: String(this.props.selectedRow.companyId),
                resellerId: String(this.props.selectedRow.id),
                id: this.state.shippingAddress.choseAddress,
                name: this.state.shippingAddress.name ? this.state.shippingAddress.name : '',
                addressLine1: this.state.shippingAddress.addressLine1,
                addressLine2: this.state.shippingAddress.addressLine2 ? this.state.shippingAddress.addressLine2 : '',
                city: this.state.shippingAddress.city,
                zipCode: this.state.shippingAddress.zipCode,
                state: this.state.shippingAddress.province,
                country: this.state.shippingAddress.country,
            },
            shippingCharges: (this.state.shippingAddress.shippingCharges != '') ? this.state.shippingAddress.shippingCharges : 0,
            paymentMethodId: this.state.remapPaymentAccountList.selectedPayment.accountId,
            billingAddress: {
                companyId: String(this.props.selectedRow.companyId),
                resellerId: String(this.props.selectedRow.id),
                id: this.state.billingAddress.choseAddress,
                name: this.state.billingAddress.name ? this.state.billingAddress.name : '',
                addressLine1: this.state.billingAddress.addressLine1,
                addressLine2: this.state.billingAddress.addressLine2 ? this.state.billingAddress.addressLine2 : '',
                city: this.state.billingAddress.city,
                zipCode: this.state.billingAddress.zipCode,
                state: this.state.billingAddress.province,
                country: this.state.billingAddress.country,
            },
        }

        // console.log("state", this.state, "props", this.props, "params", params);
        //console.log("params", params);

        if (params.shippingAddress.name == '') {
            delete params.shippingAddress.name
        }

        this.props.generatePurchaseOrder(params).then(res => {
            // console.log("generatePurchaseOrder > response", res)

            if (res && res.status == 200) {

                if (res.data.statusCode == 500) {
                    toast.error(res.data.statusDetail);
                    return;
                }

                this.props.handleRefreshInvoiceList()
            }
        })



        this.onClose();
    }

    renderInvoiceGeneration() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.props.showInvoiceGenerationModal}
                size='lg'
            >
                <ModalHeader toggle={this.onClose}>Generate Invoice</ModalHeader>
                <ModalBody>
                    <Stepper activeStep={this.state.activeStep} orientation="vertical">
                        {Object.entries(this.objsteps).map(([label, CustomStep], index) => (
                            <Step key={label}>
                                <StepLabel><h3>{label}</h3></StepLabel>
                                <StepContent>
                                    <CustomStep
                                        // navigation
                                        onClose={this.onClose}
                                        handleNext={this.handleNext}
                                        handleBack={this.handleBack}

                                        // common
                                        onCountryChange={this.onCountryChange}
                                        countryList={this.props.countryList}
                                        provinceList={this.state.statesList}

                                        // select quantity
                                        SIMTypeList={this.props.SIMTypeList != null ? this.props.SIMTypeList : []}
                                        deviceQuantityRequest={this.state.deviceQuantityRequest}
                                        onQuantityChange={this.onQuantityChange}
                                        validateSelectQuantity={this.validateSelectQuantity}

                                        // additional charges
                                        additionalChargesList={this.state.additionalChargesList}
                                        onAdditionalChargesChange={this.onAdditionalChargesChange}
                                        onAddCharge={this.onAddCharge}
                                        onDeleteCharge={this.onDeleteCharge}
                                        validateAdditionalCharges={this.validateAdditionalCharges}

                                        // shipping address
                                        shippingAddress={this.state.shippingAddress}
                                        addressList={this.props.addressList != null ? this.props.addressList : []}
                                        onShippingAddressChange={this.onShippingAddressChange}
                                        validateShippingAddress={this.validateShippingAddress}

                                        // payment method
                                        billingAddress={this.state.billingAddress}
                                        remapPaymentAccountList={this.state.remapPaymentAccountList}
                                        paymentAccountList={this.props.paymentAccountList != null ? this.props.paymentAccountList : []}
                                        onPaymentMethodChange={this.onPaymentMethodChange}
                                    />
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='secondary' type="reset" onClick={this.onClose}>
                        Cancel
                    </Button>
                    <Button outline color='custom' type="button" disabled={!this.validatePaymentMethod()} onClick={this.onSubmit}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {
        return (
            <div>
                {this.renderInvoiceGeneration()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        countryList,
        provinceMap
    } = state.user;

    let props = {
        countryList,
        provinceMap
    };
    return props;
};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators({
        getProvinces
    }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(ClientGenerateInvoiceModal);