/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { bindActionCreators } from 'redux';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { disableReseller, getAllResellers, getCountries } from '../../../redux/actions/userAction';
import { ManageTextButton } from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import ClientAddModal from './ClientAddModal';

class ClientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdmin: false,
            filterStatus: 1
        };
        this.userObj = getCurrentUser()


    }

    async componentDidMount() {
        await this.props.getCountries();

    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isAdmin: nextProps.isAdmin
        })
    }

    handleRowEditClick = (row) => {
        this.props.handleRowEditClick(row);
    }

    nameFormatter = (cell, row) => {
        return <span style={{ fontWeight: "bold" }}>{cell}</span>
    }

    phoneFormatter = (cell, row) => {
        // return (`(${cell.slice(0, 3)})-${cell.slice(3, 6)}-${cell.slice(6, 10)} ${(row.phoneExt) ? `Ext.${row.phoneExt}` : ""}`)
        // return cell ? `(${cell.slice(0, 3)}) ${cell.slice(3, 6)}-${cell.slice(6, 10)} Ext.${(row.phoneExt)} ` : ""
        return (cell ? `(${cell ? cell.slice(0, 3) : ''}) ${cell ? cell.slice(3, 6) : ''}-${cell ? cell.slice(6, 10) : ""} ${(row.phoneExt) ? `Ext.${row.phoneExt}` : ""}` : "")
    }

    tableActionColFormater = (cell, row) => {
        return (
            <div>
                <ManageTextButton label={''} onClick={(e) => { this.handleRowEditClick(row); }} />{' '}
            </div>
        )
    }

    tableActionColFormaterDelete = (cell, row) => {

        return (
            <div>
                <ConfirmDeleteDialog
                    label={""}
                    onConfirm={(e) => { this.disableReseller(row); }}
                    msg={'Do you want to disable the select Client ? '}
                />
            </div>
        )
    }
    disableReseller = (row) => {
        this.props.disableReseller({ "companyId": row.companyId, "resellerId": row.id }).then(res => {
            if (res.data != null) {
                if (res.data.includes("error")) {
                    toast.error(res.data)
                } else {
                    toast.success(res.data)
                    this.props.getAllResellers({ "resellerCode": row.resellerCode })

                }
            }
        })

    }
    statusFormatter(cell, row) {
        if (cell === 1) {
            return `Active`
        }
        else if (cell === 0) {
            return `Inactive`
        }
        else {
            return `Error`
        }
    }
    renderPaginationPanel = (props) => {
        let dropdown = props.components.sizePerPageDropdown;
        let beginning;
        let totalData;
        beginning = (props.currPage - 1) * dropdown.props.currSizePerPage + 1;
        totalData = (parseInt(beginning) + parseInt(dropdown.props.currSizePerPage)) - 1;

        return (
            <>
                {
                    (this.props.resellerList.length == 0) ?
                        <>
                            <Col md={12} xs={12} sm={12} lg={12} style={{ display: "block", fontSize: "14px" }}>
                                <span style={{ float: "right" }}> 0 - 0 of 0</span>
                            </Col>
                        </>
                        : <>
                            <Col md={6} xs={6} sm={6} lg={6}>
                                {props.components.sizePerPageDropdown}
                            </Col>
                            <Col md={6} xs={6} sm={6} lg={6} style={{ display: "block", fontSize: "14px" }}>

                                <span style={{ float: "right" }}>{props.components.pageList}</span>
                                <span style={{ float: "right", fontSize: "14px", verticalAlign: "text-top", marginRight: "10px", marginTop: "9px" }}> {beginning} - {totalData} of {(this.state.filterStatus != null) ? this.props.resellerList.filter(reseller => reseller.status == this.state.filterStatus).length : this.props.resellerList.length}</span>
                            </Col>

                        </>

                }
            </>
        );
    }
    onFilterChange = (filterObj) => {
        if (filterObj.status != null) {
            if (filterObj.status.value != null) {
                this.state.filterStatus = filterObj.status.value; //cant use this.setState because page wont reset to 1 on filter change

            }
        } else {
            this.state.filterStatus = null;
        }
        return;

    }


    render() {
        const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'CLIENT']);
        const options = {
            insertBtn: this.props.addNewUserButton,
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
            defaultSortName: 'resellerName',
            defaultSortOrder: 'asc',
            paginationPanel: this.renderPaginationPanel,
            onFilterChange: this.onFilterChange




        };
        const statusType = {
            0: 'Inactive',
            1: 'Active'
        };
        return (
            <Card>
                <CardBody>
                    <ClientAddModal
                        show={this.props.showAddModal}
                        companyCode={this.props.userObj.companyCode}
                        toggle={this.props.handleAddNewButtonClick} />


                    <BootstrapTable
                        data={this.props.resellerList}
                        tableHeaderClass='header_white'
                        options={options}
                        bordered={false}
                        insertRow={this.state.isAdmin}
                        pagination
                        exportCSV
                        csvFileName={(this.userObj.companyCode && this.userObj.companyCode == "TEISA") ? 'Reseller_List.csv' : 'Client_List.csv'}

                    >
                        <TableHeaderColumn row='0' rowSpan='1' dataField='resellerName' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} dataFormat={this.nameFormatter} csvHeader='Name'>Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='phone' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} dataFormat={this.phoneFormatter} csvHeader='Phone Number'>Phone Number</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='email' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Email'>Email</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='status' dataSort dataAlign='center' hidden={!(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'USER']))} formatExtraData={statusType} filter={{ type: 'SelectFilter', options: statusType, defaultValue: 1 }} dataFormat={this.statusFormatter.bind(this)} csvFormat={this.statusFormatter} caretRender={sortCaret} csvHeader='Status'>Status</TableHeaderColumn>
                        {/*<TableHeaderColumn row='0' rowSpan='1' dataField='resellerAddresses' dataFormat={this.tableAddressFormater} dataSort dataAlign='center' caretRender={sortCaret}>Address</TableHeaderColumn>*/}
                        <TableHeaderColumn
                            dataField='action'
                            dataAlign="center"
                            width="10%"
                            dataFormat={this.tableActionColFormater}
                            export={false}
                        >
                            Manage
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField='action'
                            dataAlign="center"
                            width="10%"
                            dataFormat={this.tableActionColFormaterDelete}
                            hidden={!this.state.isAdmin}
                            export={false}
                        >
                            Disable
                        </TableHeaderColumn>



                    </BootstrapTable>
                </CardBody>
            </Card>
        )
    }
}
const mapStateToProps = (state) => {
    return state.user;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getAllResellers, disableReseller, getCountries }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientList);