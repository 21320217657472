/* eslint-disable */
import axios from 'axios';
import {
    API_ADDR,
    API_ROOT_URL,
    GET_USER_DATA,
    GET_USER_DATA_STARTED,
    GET_USER_DATA_SUCCEED,
    GET_USER_DATA_FAILED,
    SUCCESS_MSG
} from '../../../constants/actionTypes';

function fetchDetailStarted() {
    return {
        type: GET_USER_DATA_STARTED
    }
}

function fetchDetailSucceed(res) {
    if (res.data.message === SUCCESS_MSG) {
        return {
            type: GET_USER_DATA_SUCCEED,
            payload: res
        }
    }
    else {
        return {
            type: GET_USER_DATA_FAILED,
            payload: res
        }
    }
}

function fetchDetailFailed(err) {
    return {
        type: GET_USER_DATA_FAILED,
        payload: err
    }
}

export function fetchDetail(criteria) {
    const url = `${API_ADDR}${API_ROOT_URL}${GET_USER_DATA}`;

    const promise = axios({
        url: url,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            token: sessionStorage.getItem('authToken'),
            userName: criteria.userName || undefined
        }
    });

    return function (dispatch) {
        dispatch(fetchDetailStarted());
        promise.then(
            res => {
                dispatch(fetchDetailSucceed(res));
            }
        ).catch(
            err => {
                dispatch(fetchDetailFailed(err));
            }
        );
    }
}