/* eslint-disable */
import { Col, Row, DatePicker, Input, Progress, Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import axios from 'axios';
import { AI_API_ROOT_HTTPCONTROL } from '../../../../constants/appConstants';
import '../../../../scss/component/btn.scss'
import { queryDatesExistRec } from '../videoPlayer/nvr/nvrXMLRequestsUtils';
import moment from 'moment';
import '../../../../scss/settings/antd-primary.scss';

export default function VideoDownLoadForm(props) {
  const [timeDisable, setTimeDisable] = useState(true);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  // init timeRange if don't have any record then disable the timeRange
  useEffect( () => {
      async function fetchData() {
          let res;
          try {
              res = await queryDatesExistRec(props.nvrIP, props.devId, props.HttpPort);
          } catch (e) {
            console.log(e.message);
          }
          if (res && res.startTime && res.endTime) {
              setStartTime(res.startTime);
              setEndTime(res.endTime);
              setTimeDisable(false);
          }
      }
      fetchData();
  }, []);
  // collector server address
  let collectorRoot = AI_API_ROOT_HTTPCONTROL;
  // download vodeo time limite(s)
  let timeLimit = 15 * 60;
  let interval = null;
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [processIsShow, setProcessIsShow] = useState(false);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [time, setTime] = useState('');
  const phones = [];
  const emails = [];

  // transfer input phone to E.164 format
  const phoneChange = (value) => {
    if (value) {
      setPhone(value);
    }
  }
  const emailChange = (value) => {
    setEmail(value);
  }
  const showProcess = () => {
    setProcessIsShow(true);
  }
  const getProcess = async (taskId) => {
    const url = `${collectorRoot}videoController/getDownloadProgressV2?taskId=${taskId}`;
    try {
      const res = await axios({
        url,
        method: 'get'
      });
      // not get downloadProgress reason may be task not start yet
      if (!res.data.downloadProgress) {
        // handleCancel();
        return;
      }
      if (res && (res.data.downloadProgress.taskStatus === 'running'||res.data.downloadProgress.taskStatus === 'complete')) {
        const processStr = res.data.downloadProgress.progress
        const percentNum = Number(processStr.replace('%', ''));
        setPercent(percentNum);
        if (res.data.downloadProgress.taskStatus === 'complete') {
           clearInterval(interval);
           toast.success("Video downloaded successfully and link sent to your email");
           setLoading(false);
           setProcessIsShow(false);
        }
      } else if (res.data.downloadProgress.taskStatus === 'error') {
          clearInterval(interval);
          toast.error("No video");
          setLoading(false);
          setProcessIsShow(false);
      }
    } catch (error) {
      clearInterval(interval);
      toast.error(err.message);
      setLoading(false);
      setProcessIsShow(false);
    }
  }
  const handleCancel = () => {
    setLoading(false);
    setProcessIsShow(false);
    setTime(null);
    setEmail([]);
    setPhone([]);
    if(interval) {
      clearInterval(interval);
    }
  }

  const onChange = (e) => {
    setPhone(e.target.value);
  }

  const changeTime = (time, timeString) => {
    if (timeString?.length > 0) {
      setTime(time);
    }
  }

  const changeEmail = (e) => {
    setEmail(e.target.value);
  }

  const checkParam = ()=> {
    if (!time?.length) {
      return 'Please select time';
    }
    const startTime = time[0];
    const endTime = time[1];
    if (endTime.diff(startTime, 'seconds') > timeLimit) {
      return 'Time range more than 15 min';
    }
    if (moment().diff(startTime, 'seconds') < 0 || moment().diff(endTime, 'seconds') < 0) {
      return 'Select Time can not later than current time';
    }
    if (!email?.length && !phone?.length) {
      return 'Email or phone is empty';
    }
    if (email?.length) {
      const emailReg = /^[a-zA-Z0-9_.]+@[a-zA-Z0-9-]+[.a-zA-Z]+$/;
      for (let index = 0; index < email.length; index++) {
        const element = email[index];
        if (!emailReg.test(element)) {
          return 'exist invalid email';
        }
      }
    }
    if (phone?.length) {
      const phoneReg = /^(\+1)?[2-9][0-8]\d-?[2-9]\d{2}-?\d{4}$/;
      for (let index = 0; index < phone.length; index++) {
        const element = phone[index];
        if (!phoneReg.test(element)) {
          return 'exist invalid phone';
        }
      }
    }
    return '';
  };
  const handleOk = () => {
    const msg = checkParam();
    if(msg) {
      toast.error(msg);
      return;
    }
    let phoneStr = '';
    if (phone?.length) {
        phoneStr = phone.map(x => {
            if (x.includes('-')) {
                x = x.replaceAll('-', '');
            }
            if (!x.startsWith('+1')) {
                x = '+1' + x;
            }
            return x;
        }).join(',');
    }
    const formatStr = 'YYYY-MM-DD HH:mm:ss';
    const data = {email: email.join(','), phone: phoneStr, chl: props.chl, chlNum: props.chlNum, startTime: time[0].format(formatStr), endTime: time[1].format(formatStr)};
    setLoading(true);
    setPercent(0);
    const url = `${collectorRoot}videoController/downLoadVideoV2?chl=${data.chl}&chlNum=${data.chlNum}&startTime=${data.startTime}&endTime=${data.endTime}&email=${data.email ?? ''}&phone=${data.phone ?? ''}`;
    axios({
     url,
     method: 'get'
    }).then(res => {
     if (res.status !== 200) {
      toast.error('request error');
     } else {
      if  (res.data.code !== 0) {
        toast.error(res.data.msg);
        handleCancel();
      } else {
        showProcess();
        const taskId = res.data.taskId;
        interval = setInterval(() => {
          getProcess(taskId);
        }, 2000);
      }
     }
    }).catch(err => {
     toast.error(err.message);
     setLoading(false);
     if(interval) {
      clearInterval(interval);
     }
    });
  };
  const disabledDate = current => {
      if (current) {
          if (startTime && endTime) {
              return current < moment(startTime).startOf('day') || current > moment(endTime).endOf('day');
          }
      } else {
          return false;
      }
  }
    return (
    <div style={{marginTop:10}} className="container">
        <Row>
            <Col span={4}>
                Time: 
            </Col>
            <Col span={20}>
                <DatePicker.RangePicker disabled={timeDisable} showTime disabledDate={disabledDate} value = {time} onChange={(time, timeString) => changeTime(time, timeString)} style={{width:'100%'}} />
            </Col>
        </Row>
        <Row style={{marginTop:10}}>
            <Col span={4}>
                Email: 
            </Col>
            <Col span={20}>
                {/* <Input placeholder="email" type='email' value = {email} onChange={(e) => changeEmail(e)}/> */}
                <Select
                  mode="tags"
                  maxTagCount="responsive"
                  style={{ width: '100%' }}
                  placeholder="Input email press and enter to add"
                  onChange={emailChange}
                  tokenSeparators={[',']}
                  // options={emails}
                  open = {false}
                  value = {email}
                />
            </Col>
        </Row>
        <Row style={{marginTop:10}}>
            <Col span={4}>
                Phone: 
            </Col>
            <Col span={20}>
                {/* <Input placeholder="phone" value = {phone} onChange={(e) => onChange(e)}/> */}
                <Select
                  mode="tags"
                  maxTagCount="responsive"
                  style={{ width: '100%' }}
                  placeholder="Input phone press and enter to add"
                  onChange={phoneChange}
                  tokenSeparators={[',']}
                  // options={phones}
                  open = {false}
                  value = {phone}
                />
            </Col>
        </Row>
        <Row style={{marginTop:10}}>
          <Button className="btn btn-secondary" style={{height:'auto', padding:'auto'}} 
          loading={loading} onClick={handleOk}>
                Download
          </Button>
        </Row>
        <div>
            {processIsShow?<Progress percent={percent} status="active" />:''}
        </div>
    </div>
  );
}
