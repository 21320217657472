/* eslint-disable */
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import Videosvg from '../../../../../shared/img/Video_camera_icon.svg';
import office_img from '../../../../../shared/img/Office_Layout.jpg';

class DragVideoModalIncon extends Component {
    constructor(props){
        super(props);
    }

    componentDidMount(){
        var deltaX, deltaY;
        const handleDrag = d3.drag()
        .subject(function() {
            const me = d3.select(this);
            return { x: me.attr('x'), y: me.attr('y') }
          })
        // .on("start", function () {
        //     var current = d3.select(this);
        //     deltaX = current.attr("x") - d3.event.x;
        //     deltaY = current.attr("y") - d3.event.y;
        // })
        .on('drag', function() {
            const me = d3.select(this);
            me.attr('x', d3.event.x);
            me.attr('y', d3.event.y);
            console.log('x', d3.event.x)
        })
        .on('end', function() {
            const me = d3.select(this);
             
        });
        const node = ReactDOM.findDOMNode(this.refs.useref);
        var icon = d3.select(node);
        icon.on('click', function() {
            alert('click');
        });
        handleDrag(icon);
        // handleDrag(d3.select("#use1"));
    }

    renderSvgIcon=()=>{
        return(
            <svg style={{
                backgroundImage: `url(${office_img})`,backgroundRepeat: 'no-repeat',width:'100%',height:'650px',color:'white'
            }}> 
                <defs>
                    <circle id="circle1" cx="50" cy="50" r="40" stroke="green" stroke-width="4" fill="yellow" />
                    <g id="video1" transform="scale(0.5)" >
                        {/* <path d="M72.9,14.4L56,25.3V22c0-4.4-3.6-8-8-8H8c-4.4,0-8,3.6-8,8v32c0,4.4,3.6,8,8,8h40c4.4,0,8-3.6,8-8v-3.3
                            l16.9,10.9c1.9,1,3.1-0.7,3.1-1.7V16C76,15,74.9,13.2,72.9,14.4z M52,54c0,2.2-1.8,4-4,4H8c-2.2,0-4-1.8-4-4V22c0-2.2,1.8-4,4-4h40
                            c2.2,0,4,1.8,4,4V54z M72,56.3L56,46V30l16-10.3V56.3z"/> */}

                        <path d="M0-1c-14.5-25.6-14.5-25.7-14.5-33.8c0-8.1,6.5-14.6,14.5-14.6s14.5,6.6,14.5,14.6C14.5-26.7,14.5-26.6,0-1z"></path>
                        <path d="M0-49c7.7,0,14,6.3,14,14.1c0,8,0,8.1-14,32.8c-14-24.7-14-24.9-14-32.8C-14-42.7-7.7-49,0-49 M0-50c-8.3,0-15,6.8-15,15.1 S-15-26.5,0,0c15-26.5,15-26.5,15-34.9S8.3-50,0-50L0-50z"></path>
                    </g>
                </defs>
                {/* <use id="use1" ref='useref' xlinkHref={"#circle1"} x={'100'} y={'100'}/> */}
                <use id="use1" ref='useref' xlinkHref={"#video1"} x={'50'} y={'50'}/>
            </svg>
        )
    }

    render(){
        return (
            <div>
                {this.renderSvgIcon()}
            </div>
        );
    }

}

export default DragVideoModalIncon;