/* eslint-disable */

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const SUPPORT_EMAIL = "waivsupport@itsp-inc.com";

export const WEB_UPLOAD_ROOT = process.env.REACT_APP_WEB_UPLOAD_ROOT;
export const VIDEO_DOWNLOAD_ROOT = process.env.REACT_APP_VIDEO_DOWNLOAD_ROOT;


// eslint-disable-next-line camelcase
export const WEB_SERVICE_ROOT_DashBoardSetting = `${WEB_SERVICE_ROOT}Layout/Template/`;
// eslint-disable-next-line camelcase
export const WEB_SERVICE_ROOT_DashBoard_Template_Setting = `${WEB_SERVICE_ROOT}Layout/`;

//SMART API CONFIG
export const WEB_SERVICE_SOCKET_ROOT = process.env.REACT_APP_WEB_SERVICE_SOCKET_ROOT;
export const VIDEO_PROXY_ROOT = process.env.REACT_APP_VIDEO_PROXY_ROOT;
export const VIDEO_ROOT= process.env.REACT_APP_VIDEO_ROOT;
export const AI_API_ROOT_HTTPCONTROL = process.env.REACT_APP_AI_API_ROOT_HTTPCONTROL;


export const MAILGUN_ROOT = process.env.REACT_APP_MAILGUN_ROOT; //email & SMS sender
export const MESSAGER_ROOT =process.env.REACT_APP_MESSAGER_ROOT;

// -------------------- START config for UAT ---------------------- //

//LOGO
//LOCAL
// export const WEB_SERVICE_LOGO = 'http://localhost:8080/';
//DEV
// export const WEB_SERVICE_LOGO = "https://waiviot-dev.itsp-inc.com:3443/upload/logo/";
//UAT
// export const WEB_SERVICE_LOGO = 'https://waiviot-uat.itsp-inc.com:3443/upload/logo/';
//DEMO/UAT2
// export const WEB_SERVICE_LOGO = 'https://waiv-demo.itsp-inc.com:8443/upload/logo/';
//PROD
//export const WEB_SERVICE_LOGO = 'https://waiv.itsp-inc.com:8443/upload/logo/'
//PROD-Helix
//export const WEB_SERVICE_LOGO = 'https://waiv-helix.itsp-inc.com:8443/upload/logo/'
//NEW PRODUCTION 192.168.161.21
// export const WEB_SERVICE_LOGO = 'https://waiviot.com:9443/upload/logo/'
//TEISA
//export const WEB_SERVICE_LOGO = 'https://waiv-teisa.itsp-inc.com:8443/upload/logo/';
//ONROUTE
// export const WEB_SERVICE_LOGO = "https://waiv.az.onroute.ca:8443/upload/logo/";

export const WEB_SERVICE_LOGO = process.env.REACT_APP_WEB_SERVICE_LOGO;

//dev sigfox farm
//LOCAL
// export const WEB_SERVICE_ROOT = 'http://localhost:9080/';
//DEV
// export const WEB_SERVICE_ROOT = "https://waiviot-dev.itsp-inc.com:3443/sigfox-farm/";
//UAT
// export const WEB_SERVICE_ROOT = 'https://waiviot-uat.itsp-inc.com:3443/sigfox-farm/';
//DEMO
// export const WEB_SERVICE_ROOT = 'https://waiv-demo.itsp-inc.com:8443/sigfox-farm/';
//PROD
//  export const WEB_SERVICE_ROOT = 'https://waiv.itsp-inc.com:8443/sigfox-farm/'
//PROD-Helix
//export const WEB_SERVICE_ROOT = 'https://waiv-helix.itsp-inc.com:8443/sigfox-farm/'
//PROD-OnRoute
// export const WEB_SERVICE_ROOT = "https://waiv.az.onroute.ca:8443/sigfox-mall/";
//NEW PRODUCTION 192.168.161.21
// export const WEB_SERVICE_ROOT = 'https://waiviot.com:9443/sigfox-farm/'
//TEISA
//export const WEB_SERVICE_ROOT = 'https://waiv-teisa.itsp-inc.com:8443/sigfox-farm/';

export const WEB_SERVICE_ROOT = process.env.REACT_APP_WEB_SERVICE_ROOT;

// sigfox payment
//LOCAL
// export const WEB_SERVICE_USER_ROOT = 'http://localhost:8444/';
//DEV
// export const WEB_SERVICE_USER_ROOT = "https://waiviot-dev.itsp-inc.com:3443/payment/";
//UAT
// export const WEB_SERVICE_USER_ROOT = 'https://waiviot-uat.itsp-inc.com:3443/payment/';
//DEMO
// export const WEB_SERVICE_USER_ROOT = 'https://waiv-demo.itsp-inc.com:8443/payment/';
//PROD
//export const WEB_SERVICE_USER_ROOT = 'https://waiv.itsp-inc.com:8443/payment/'
//PROD-Helix
//export const WEB_SERVICE_USER_ROOT = 'https://waiv-helix.itsp-inc.com:8443/payment/'
//PROD-OnRoute
// export const WEB_SERVICE_USER_ROOT = "https://waiv.az.onroute.ca:8443/payment/";
//NEW PRODUCTION 192.168.161.21
// export const WEB_SERVICE_USER_ROOT = 'https://waiviot.com:9443/payment/'
//TEISA
//export const WEB_SERVICE_USER_ROOT = 'https://waiv-teisa.itsp-inc.com:8443/payment/'

export const WEB_SERVICE_USER_ROOT = process.env.REACT_APP_WEB_SERVICE_USER_ROOT;
// telecom
//LOCAL
// export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = 'http://localhost:9080/';
//DEV
// export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = "https://waiviot-dev.itsp-inc.com:3443/telecom/";
// UAT
// export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = "https://waiviot-uat.itsp-inc.com:3443/telecom/";
// Demo
// export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = 'https://waiv-demo.itsp-inc.com:8443/telecom/';
//PROD
//export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = 'https://waiv.itsp-inc.com:8443/telecom/'
//PROD-Helix
//export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = 'https://waiv-helix.itsp-inc.com:8443/telecom/'
//ONRoute
// export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = 'https://waiv.az.onroute.ca:8443/telecom/';
//NEW PRODUCTION 192.168.161.21
// export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = 'https://waiviot.com:9443/telecom/'
//TEISA
//export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = 'https://waiv-teisa.itsp-inc.com:8443/telecom/';

export const WEB_SERVICE_TELELCOM_BACKEND_ROOT = process.env.REACT_APP_WEB_SERVICE_TELELCOM_BACKEND_ROOT;
//PAYMENT NEW
//LOCAL
// export const WEB_SERVICE_PAYMENT_ORDER_ROOT = 'http://localhost:9085/';
//DEV
// export const WEB_SERVICE_PAYMENT_ORDER_ROOT = "https://waiviot-dev.itsp-inc.com:3443/paymentNew/";
//UAT
// export const WEB_SERVICE_PAYMENT_ORDER_ROOT = "https://waiviot-uat.itsp-inc.com:3443/paymentNew/";
//Demo
// export const WEB_SERVICE_PAYMENT_ORDER_ROOT = 'https://waiv-demo.itsp-inc.com:8443/paymentNew/';
//PROD
//export const WEB_SERVICE_PAYMENT_ORDER_ROOT = 'https://waiv.itsp-inc.com:8443/paymentNew/'
//PROD-Helix
//export const WEB_SERVICE_PAYMENT_ORDER_ROOT = 'https://waiv-helix.itsp-inc.com:8443/paymentNew/'
//ONRoute
// export const WEB_SERVICE_PAYMENT_ORDER_ROOT = 'https://waiv.az.onroute.ca:8443/paymentNew/';
 //NEW PRODUCTION 192.168.161.21
// export const WEB_SERVICE_PAYMENT_ORDER_ROOT = 'https://waiviot.com:9443/paymentNew/'
//TEISA
//export const WEB_SERVICE_PAYMENT_ORDER_ROOT = 'https://waiv-teisa.itsp-inc.com:8443/paymentNew/';

export const WEB_SERVICE_PAYMENT_ORDER_ROOT = process.env.REACT_APP_WEB_SERVICE_PAYMENT_ORDER_ROOT;

//Web-socket
//LOCAL
// export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='ws://localhost:9080/websocketbroker';
//DEV
// export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT = "ws://waiviot-dev.itsp-inc.com:3443/sigfox-farm/websocketbroker";
//UAT
// export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='wss://waiviot-uat.itsp-inc.com:3443/sigfox-farm/websocketbroker';
//DEMO
// export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='wss://waiv-demo.itsp-inc.com:8443/sigfox-farm/websocketbroker';
//PROD
//export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='wss://waiv.itsp-inc.com:8443/sigfox-farm/websocketbroker';
//ONRoute
// export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT ="wss://waiv.az.onroute.ca:8443/sigfox-mall/websocketbroker";
//PROD-Helix
//export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='wss://waiv-helix.itsp-inc.com:8443/sigfox-farm/websocketbroker';
//NEW PRODUCTION 192.168.161.21
// export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='wss://waiviot.com:9443/sigfox-farm/websocketbroker';
//TEISA
//export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='ws://waiv-teisa.itsp-inc.com:8443/sigfox-farm/websocketbroker';

export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT=process.env.REACT_APP_WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT;

//ONROUTE SOCKET
// export const WEB_SUB_SOCKET ="wss://waiv.az.onroute.ca:8443/sigfox-mall/websocketbroker";
// export const VIDEO_WS_ROOT = "wss://waiv.az.onroute.ca:8043";
//LOCAL DEV
//export const WEB_SUB_SOCKET = 'ws://localhost:8181/sigfox-farm/websocketbroker'
// export const WEB_SUB_SOCKET = 'ws://localhost:9080/websocketbroker'
//DEMO/UAT2
// export const WEB_SUB_SOCKET = 'wss://waiv-demo.itsp-inc.com:8443/sigfox-mall/websocketbroker';
// export const VIDEO_WS_ROOT = 'wss://waiv-demo.itsp-inc.com:8043';
//DEV
// export const WEB_SUB_SOCKET = "wss://waiviot-dev.itsp-inc.com:3443/sigfox-farm/websocketbroker";
// export const VIDEO_WS_ROOT = "wss://waiviot-dev.itsp-inc.com:8043";
//UAT
// export const WEB_SUB_SOCKET = 'wss://waiviot-uat.itsp-inc.com:3443/sigfox-farm/websocketbroker';
// export const VIDEO_WS_ROOT = 'wss://waiviot-uat.itsp-inc.com:8043';
//ONroute PROD
// export const WEB_SUB_SOCKET = 'wss://waiv.az.onroute.ca:8443/sigfox-mall/websocketbroker';

export const WEB_SUB_SOCKET = process.env.REACT_APP_WEB_SUB_SOCKET;
export const VIDEO_WS_ROOT = process.env.REACT_APP_VIDEO_WS_ROOT;

//ONRoute nginx reverse proxy
//LOCAL
// export const NGINX_ROOT = 'http://localhost'
// export const NVR_PROXY = 'http://localhost:8001/nvr/';
// export const CAMERA_PROXY = "http://localhost:8001/camera";
// export const WEBSOCKET_PROXY = 'ws://localhost:8001/ws'

//DEV
// export const NGINX_ROOT = 'waiviot-dev.itsp-inc.com'
// export const NVR_PROXY = 'https://waiviot-dev.itsp-inc.com:1443/nvr/';
// export const CAMERA_PROXY = "https://waiviot-dev.itsp-inc.com:1443/camera";
// export const WEBSOCKET_PROXY = 'wss://waiviot-dev.itsp-inc.com:1443/ws'
// export const NVR_PROXY = 'http://waiviot-dev.itsp-inc.com:8001/nvr/';
// export const CAMERA_PROXY = "http://waiviot-dev.itsp-inc.com:8001/camera";
// export const WEBSOCKET_PROXY = 'ws://waiviot-dev.itsp-inc.com:8001/ws'

export const NGINX_ROOT = process.env.REACT_APP_NGINX_ROOT;
export const NVR_PROXY = process.env.REACT_APP_NVR_PROXY;
export const CAMERA_PROXY = process.env.REACT_APP_CAMERA_PROXY;
export const WEBSOCKET_PROXY = process.env.REACT_APP_WEBSOCKET_PROXY;

//CDP UAT
// export const NGINX_ROOT = 'waiviot-uat.itsp-inc.com'
// export const NGINX_ROOT = 'mbux.az.onroute.ca'
// export const NGINX_ROOT = '52.237.20.93'
// export const NVR_PROXY = 'http://52.237.20.93:8001/nvr/'
// export const WEBSOCKET_PROXY = 'ws://52.237.20.93:8001/ws'

//UAT
// export const NGINX_ROOT = "13.88.246.7";
// export const NVR_PROXY = "https://waiviot-uat.itsp-inc.com:1443/nvr/";
// export const CAMERA_PROXY = "https://waiviot-uat.itsp-inc.com:1443/camera";
// export const WEBSOCKET_PROXY = "wss://waiviot-uat.itsp-inc.com:1443/ws";
// export const NGINX_ROOT = '13.88.246.7';
// export const NVR_PROXY = 'http://waiviot-uat.itsp-inc.com:8001/nvr/';
// export const CAMERA_PROXY = "http://waiviot-uat.itsp-inc.com:8001/camera";
// export const WEBSOCKET_PROXY = 'ws://waiviot-uat.itsp-inc.com:8001/ws'

//ONRoute
// export const NGINX_ROOT = "waiv-nvr.az.onroute.ca"; //HTTP server mask for NVR integration NGINX
// export const NVR_PROXY = "https://waiv.az.onroute.ca:1443/nvr/";
// export const CAMERA_PROXY = "https://waiv.az.onroute.ca:1443/camera";
// export const WEBSOCKET_PROXY = "wss://waiv.az.onroute.ca:1443/ws";
// export const NVR_PROXY = 'http://waiv.az.onroute.ca:8001/nvr/';
// export const CAMERA_PROXY = "http://waiv.az.onroute.ca:8001/camera";
// export const WEBSOCKET_PROXY = 'ws://waiv.az.onroute.ca:8001/ws'

//encrypted ipc password
export const IPC_PASSWORD = "5278C4D3875DEEC1CA2A60DBB9A7A1AF";

//Forgot Password URLs
export const FORGOT_PASSWORD_URL = "/#/forgot_password";
export const FORGOT_PASSWORD_ROOT = "/#/";
export const TIMEOUT = 300000; //5 minutes to idle
export const PROMPT_BEFORE_TIMEOUT = 30000; //30 seconds count down before logging out
export const TIMEOUT_COMPANIES = [
  "BA",
  "INN",
  "KC",
  "Maple",
  "MTN",
  "ODSA",
  "PH",
  "TN",
  "TS",
  "ONRT",
  "DTN",
  "CBN",
  "WL",
  "NPN",
  "CBS",
  "BSVL",
  "IGS",
  "TBS",
  "TBN",
  "MBG",
  "121",
]; //ONroute companies

////activation sim (claudiu)ItsP!@0223
//export const WEB_SERVICE_IOTCellAPI_USER_ROOT = 'http://192.168.2.23:44313/IOTCellAPI/';
//uat
//export const WEB_SERVICE_IOTCellAPI_USER_ROOT = 'https://waiviot-dev.itsp-inc.com:44315/IOTCellAPI/';

//export const WEB_SERVICE_IOTCellAPI_USER_ROOT = 'https://192.168.161.9:44315/IOTCellAPI/'

export const WEB_SERVICE_IOTCellAPI_GET_AVAILABLE_SERVICE_PLAN =
  "api/ChangeService/GetAvailableServicePlans";

// export const WEB_SUB_CHANNEL = 'http://192.168.2.103:8080/sendRedisMessageTest/get';
// export const WEB_SUB_CHANNEL = 'http://13.88.246.7:8080/sigfox-farm/sendRedisMessageTest/get';
// export const WEB_SUB_CHANNEL = 'https://waiviot.itsp-inc.com:8443/sigfox-farm/sendRedisMessageTest/get';
// export const WEB_SUB_CHANNEL = 'https://waiviot.itsp-inc.com:8443/sigfox/sendRedisMessageTest/get';
export const WEB_SUB_CHANNEL = `${WEB_SERVICE_ROOT}sendRedisMessageTest/get`;
export const WEB_SUB_EVENT = "devicemessage";

export const WEB_SERVICE_MESSAGE_DECODE_GROUP = "MessageDecode/Group";
export const WEB_SERVICE_MESSAGE_DECODE_CALCULATE_RULE =
  "MessageDecode/CalculateRule";
export const WEB_SERVICE_GET_BRANDING = "GetBranding";
export const WEB_SERVICE_SET_BRANDING = "SetBranding";

export const WEB_SERVICE_GEOFENCE = "Geofence";
export const WEB_SERVICE_GEOFENCE_OBJ = "GeofenceObject";
export const WEB_SERVICE_ATTRIBUTES = "Attributes";
export const WEB_SERVICE_DEVICETYPE = "Devicetypes";
export const WEB_SERVICE_ASSET = "Assets";
export const WEB_SERVICE_ASSET_PURE = "Asset";
export const WEB_SERVICE_ACTION = "Action";
export const WEB_SERVICE_ACTION_TELECOM = "ActionTelecom";
export const WEB_SERVICE_DEVICE = "Device";
export const WEB_SERVICE_DEVICE_NAME = "Device/ChangeName";
export const WEB_SERVICE_DEVICE_ACTIVE = "Device/Active";
export const WEB_SERVICE_DEVICE_ATTRIBUTE = "DeviceAttribute";
export const WEB_SERVICE_RULE = "Rule";
export const WEB_SERVICE_GET_ASSETS_DATA = "GetdataN/Redis";
export const WEB_SERVICE_GET_GROUP_ASSETS_DATA = "GetGroupdataN2/Redis";
export const WEB_SERVICE_GET_GROUP_DEVICES_DATA = "GetGroupdata";
export const WEB_SERVICE_GET_DEVICES_BY_GROUP = "DevicelistByGroups";
// export const WEB_SERVICE_GET_ASSETCLASS = 'AssetClassN';
export const WEB_SERVICE_GET_ASSETCLASS = "AssetClass";
export const WEB_SERVICE_GET_ASSETCLASS_ATTRIBUTES = "Getclassattributes";
export const WEB_SERVICE_GET_THEMES = "Themes";
export const WEB_SERVICE_GET_ASSETCLASS_THEME = "Gettheme";
export const WEB_SERVICE_REMOVE_ASSETCLASS_THEME = "Gettheme";
export const WEB_SERVICE_GET_DEVICE_TYPE_THEME = "Getthemelist_DT";
export const WEB_SERVICE_GET_THEME_LIST = "Getthemelist";
// export const WEB_SERVICE_ACTION = 'Action';
export const WEB_SERVICE_ACTION_RULE = "Actionrule";
export const WEB_SERVICE_ACTION_ALERT = "Alert";
export const WEB_SERVICE_ACTION_LOG = "Actionlog";
export const WEB_SERVICE_ACTION_TEMPLATE = "RuleTemplate";

export const WEB_SERVICE_LAYOUT = "Layout";
export const WEB_SERVICE_LAYOUT_LIST = "Layout/List";
export const WEB_SERVICE_LAYOUT_SET_DEFAULT = "Layout/setdefault";
export const WEB_SERVICE_LAYOUT_DELET_WIDGET = "Layout/Byid";
export const WEB_SERVICE_MSG_COUNT = "MessageCount";

export const WEB_SERVICE_GET_COMPANY = "Company";
export const WEB_SERVICE_GET_RESELLER = "Reseller";
export const WEB_SERVICE_GET_RESELLER_TREE = "getResellerTree";
export const WEB_SERVICE_DISABLE_RESELLER = "DisableReseller";
export const WEB_SERVICE_GET_ROLES = "Role";
export const WEB_SERVICE_GET_COUNTRY = "getCountryPermission";

export const WEB_SERVICE_ACTION_CONTACT = "Contact";
export const WEB_SERVICE_ACTION_POST_CONTACT = "Contact";
export const WEB_SERVIDE_ACTION_NOTIFICATION_TEMPLATE = "NotificationTemplate";
export const WEB_SERVICE_ACTION_NOTIFICATION_TEMPLATE_APPNAME =
  "NotificationTemplateByAppname";
export const WEB_SERVIDE_ACTION_DASHBOARDSETTING_TEMPLATE = "List";
export const WEB_SERVICE_USER_ADD = "addUser";
/////Weatherapp adding multiple users at the same time (csv file)
export const WEB_SERVICE_MULTI_USER_ADD = "addMultUser";
export const WEB_SERVICE_USER_REMOVE = "removeUser";
export const WEB_SERVICE_USER_CHECK = "checkUser";
export const WEB_SERVICE_USER_UPDATE = "updateUser";
export const WEB_SERVICE_CHANGE_NVR_PASSWORD = "nvr/users/change-password/client-name/";
export const WEB_SERVICE_USER_GET_CONTENT = "GetPageContent";
export const WEB_SERVICE_FORGET_PASSWORD = "forgetPassword";
export const WEB_SERVICE_RESET_PASSWORD = "resetPassword";
export const WEB_SERVICE_USER_GET = "getUserList";
export const WEB_SERVICE_DASHBOARDTEMPLATE_USER_GET = "UserTemplateList";
export const WEB_SERVICE_GET_DASHBOARDTEMPLATE_LAYOUT_BY_ID =
  "Layout/Template/Entry";
export const WEB_SERVICE_UPDATE_DASHBOARDTEMPLATE_LAYOUT =
  "Layout/TemplateEntry";
export const WEB_SERVICE_DELETE_DASHBOARDTEMPLATE_BYID = "Layout/Template";
export const WEB_SERVICE_NEW_DASHBOARDTEMPLATE_BY_NAME = "Layout/Template/New";
export const WEB_SERVICE_DASHBOARDTEMPLATE_ASSIGN_POST = "Template/Assign";
export const WEB_SERVICE_USER_ADD_ASSET = "addAssetMap";
export const WEB_SERVICE_USER_ADD_ASSET_USER_LIST = "addAssetUserListMap";
export const WEB_SERVICE_USER_ADD_ASSET_LIST = "addAssetListMap";
export const WEB_SERVICE_USER_CHECK_ASSET = "checkUserAssets";
export const WEB_SERVICE_USER_CHECK_ASSET_USER = "checkAssetUsers";
export const WEB_SERVICE_USER_ADD_ASSETCLASS_LIST = "addAssetclassListMap";
export const WEB_SERVICE_USER_ADD_ASSETCLASS_USER = "addAssetclassUserListMap";
export const WEB_SERVICE_USER_CHECK_ASSETCLASS_LIST = "checkUserAssetclasses";
export const WEB_SERVICE_USER_CHECK_DEVICETYPE_USER_LIST =
  "checkDevicetypesUsers";
export const WEB_SERVICE_USER_CHECK_DEVICETYPE_LIST = "checkUserDevicetypeses";
export const WEB_SERVICE_USER_ADD_DEVICETYPE_LIST = "addDevicetypesListMap";
export const WEB_SERVICE_USER_SUCCESS_CODE = "Success";

export const WEB_SERVICE_GET_COMPANY_DEVICETYPE = "CompanyDevicetypes";
export const WEB_SERVICE_GET_COMPANY_ASSETCLASS = "CompanyAssetClass";

export const WEB_SERVICE_CHANGE_COMPANY_DEVICETYPE = "CompanyDevicetypes";
export const WEB_SERVICE_CHANGE_COMPANY_ASSETCLASS = "ChangeCompanyCode";
export const WEB_SERVICE_LOAD_CUSTOMER_INVENTORY_RECORDS =
  "inventory/addCustomerInventoryRecords";

export const WEB_SERVICE_POST_INVENTORY_DEVICE = "LoadInventoryMaster";
export const WEB_SERVICE_GET_ALL_INVENTORY = "getAllInventory";
export const WEB_SERVICE_GET_DEVICE_MASTER = "getDeviceMaster";
export const WEB_SERVICE_GET_ONE_DEVICE_MASTER = "getOneDeviceMaster";
export const WEB_SERVICE_GET_SAP_CSV = "getInvoiceListCompany";

export const WEB_SERVICE_THRESHOLD = "Actionrule/threshold";

export const WEB_SERVICE_GET_RECURSIVE_ASSET_DATA = "GetRecursiveData/Redis";

export const WEB_SERVICE_GET_CALCULATE_ATTRIBUTE = "CalcAttribute/Get";
export const WEB_SERVICE_POST_CALCULATE_ATTRIBUTE = "CalcAttribute/Post";
export const WEB_SERVICE_UPDATE_CALCULATE_ATTRIBUTE = "CalcAttribute/Update";
export const WEB_SERVICE_UPDATE_BATCH_CALCULATE_ATTRIBUTE =
  "CalcAttribute/UpdateBatch";
export const WEB_SERVICE_DEL_CALCULATE_ATTRIBUTE = "CalcAttribute/Del";
export const WEB_SERVICE_RESET_CALCULATE_ATTRIBUTE = "ResetAttributes";

export const WEB_SERVICE_GET_CALCULATE_RESULT = "getCalculateResult";
export const WEB_SERVICE_GET_LATEST_CALCULATE_RESULT =
  "getLatestCalculateResult";

export const WEB_SERVICE_ASSET_ATTRIBUTE = "GetAssetAttributes";

export const WEB_SERVICE_GET_ATTRIBUTE = "Attributes";

export const WEB_SERVICE_SEND_EMAIL = "notifaction/sendEmail";

export const WEB_SERVICE_PAYMENT_ITEM = "devicebyupc";
export const WEB_SERVICE_PAYMENT_CHECKOUT = "checkoutorder";
export const WEB_SERVICE_PAYMENT_PAY = "payorder";
export const WEB_SERVICE_PAYMENT_HISTORY = "getAccountPaymentHistory";
export const WEB_SERVICE_TRANSACTION_HISTORY = "getTransactionPaymentHistory";
export const WEB_SERVICE_REFUND_INVOICE = "refundSingleInvoice"; //works outside of invoices too
export const WEB_SERVICE_PAYMENT_INVOICE = "getAccountInvoiceHistory";
export const WEB_SERVICE_PAYMENT_INVOICE_DETAILS = "getInvoiceDetail";
export const WEB_SERVICE_PAYMENT_GET_GL_CODES_BY_COMPANY =
  "getAllGeneralLedgerCodes";
export const WEB_SERVICE_PAYMENT_ADD_GL_CODE_BY_COMPANY =
  "addGeneralLedgerCode";
export const WEB_SERVICE_PAYMENT_MODIFY_GL_CODE_BY_COMPANY =
  "modifyGeneralLedgerCode";
export const WEB_SERVICE_PAYMENT_GET_GL_CODE_REASONS_BY_COMPANY =
  "getAllGeneralLedgerCodeReasons";
export const WEB_SERVICE_PAYMENT_ADD_GL_CODE_REASON_BY_COMPANY =
  "addGeneralLedgerCodeReason";
export const WEB_SERVICE_PAYMENT_MODIFY_GL_CODE_REASON_BY_COMPANY =
  "modifyGeneralLedgerCodeReason";
export const WEB_SERVICE_PAYMENT_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY =
  "getAllInvoiceAdjustments";
export const WEB_SERVICE_PAYMENT_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY =
  "addInvoiceAdjustments";
export const WEB_SERVICE_PAYMENT_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY =
  "cancelInvoiceAdjustments";
export const WEB_SERVICE_PAYMENT_INVOICE_ADJUSTMENT = "saveInvoiceAdjustments";
export const WEB_SERVICE_PAYMENT_INVOICE_WITH_APPROVAL_DETAILS =
  "getInvoiceApprovalDetails";
export const WEB_SERVICE_PAYMENT_INVOICE_CONFIRM_APPROVAL =
  "confirmInvoiceApproval";
export const WEB_SERVICE_PAYMENT_USAGE_REPORT = "getUsageReport";
export const WEB_SERVICE_PAYMENT_PAY_INVOICE = "paySingleInvoice";
export const WEB_SERVICE_PAYMENT_INVOICE_LIST = "getInvoiceList";
export const WEB_SERVICE_PAYMENT_CARD_MGMT = "cardManagement";

export const WEB_SERVICE_ORDER = "order/";
export const WEB_SERVICE_PAYMENT_DEVICE = "device/";
export const WEB_SERVICE_PAYMENT_UPC_DEVICE = "byupc";
export const WEB_SERVICE_PAYMENT_CHECHOUT_DEVICE = "checkout";
export const WEB_SERVICE_PAYMENT_PAY_DEVICE = "pay/";
export const WEB_SERVICE_PAYMENT_RELEASE_DEVICE = "release/";
export const WEB_SERVICE_PAYMENT_SERVICE = "service/";
export const WEB_SERVICE_ORDER_ADJUSTMENT = "history";
export const WEB_SERVICE_EMAIL_PURCHASE_ORDER = "order/EmailPurchaseOrder";

export const WEB_SERVICE_PROMOTE = "promote";

export const WEB_SERVICE_CUSTOMER_BY_COMPANY = "getcustomerbycompanycode";
export const WEB_SERVICE_GET_ORDERS = "getorderbycompanycode"; // getorderbycustomer
export const WEB_SERVICE_UPDATE_ORDER = "updateorder";

export const WEB_SERVICE_GET_BUSINESS_UNIT = "GetBusinessUnit";
export const WEB_SERVICE_UPDATE_BUSINESS_UNIT = "UpdateBusinessUnit";
export const WEB_SERVICE_UPDATE_BUSINESS_UNIT_RELATION =
  "UpdateBusinessUnitRelations";
export const WEB_SERVICE_GET_SIM_INVENTORY = "inventory/getInventory";
export const WEB_SERVICE_SET_SIM_NAME = "inventory/setSimName";
export const WEB_SERVICE_GET_ACTIVITY_REPORT = "inventory/getActivityReport";
export const WEB_SERVICE_GET_CARRIER_PLANS = "carrier/GetAvailableServicePlans";
export const WEB_SERVICE_UPDATE_CARRIER_PLANS = "carrier/updateCarrierInfo";
export const WEB_SERVICE_GET_SIM_SERVICE_PLAN = "item/getServicePlan";
export const WEB_SERVICE_GET_RESELLER_ITEM_AND_INVENTORY =
  "item/getFromItemAndInventory";
export const WEB_SERVICE_INVENTORY_LOAD_RESELLER_BY_CONTRACT =
  "inventory/findResellerCodeByContract";
export const WEB_SERVICE_INVENTORY_RUN_MANUAL_BILLING =
  "order/runTeisaMonthlyBilling";
export const WEB_SERVICE_INVENTORY_FETCH_MANUAL_BILLING_STATUS =
  "order/manualBillingStatus";

export const WEB_SERVICE_GET_RESELLER_FULL_INVENTORY_AND_ITEM =
  "inventory/getInventory";
export const WEB_SERVICE_ACTIVATE_SIM = "inventory/SetMultiSimCardActivity";
export const WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM =
  "inventory/getActivatedSimNew";
// export const WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM_FOR_DOWNLOAD = 'inventory/getActivatedSimNewForDownload';
export const WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM_FOR_DOWNLOAD_BY_PAGE =
  "inventory/getActivatedSimNewForDownloadByPage";
export const WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM_FOR_DOWNLOAD_BY_CVS =
  "inventory/getActivatedSimNewForDownloadByCvs";
export const WEB_SERVICE_SIFOX_GET_SIM_USAGE = "usage/getSimUsage";
export const WEB_SERVICE_GET_PLAN_USAGE = "usage/getPlanUsageData";
export const WEB_SERVICE_GET_IDENTIFIER_USAGE = "usage/getIdentifierUsageData";
export const WEB_SERVICE_SIFOX_GET_USAGE_FOR_RESELLER =
  "usage/getUsageForReseller";
export const WEB_SERVICE_SIFOX_GET_CURRENT_TOTAL_USAGE =
  "usage/getCurrentTotalUsage";
export const WEB_SERVICE_SIFOX_GET_CLIENT_USAGE_STATS =
  "usage/getClientUsageStats";
export const WEB_SERVICE_GET_SIM_LAST_KNOWN_LOCATION =
  "inventory/findSIMLastKnownLocation";
export const WEB_SERVICE_CLIENT_GET = "client";

export const WEB_SERVICE_GET_CARRIER = "carrier/getCarrier";

export const WEB_SERVICE_CONSUMER_ADD = "addConsumer";
export const WEB_SERVICE_CONSUMER_GET = "consumer";
export const WEB_SERVICE_UPDATE_CONSUMER = "updateConsumer";
export const WEB_SERVICE_CONSUMER_GET_PLAN_USAGE = "usage/consumerPlanUsage";

export const WEB_SERVICE_GET_OPERATIONCOUNTRY = "country";
export const WEB_SERVICE_GET_LOBS = "lob";
export const WEB_SERVICE_GET_DIVISIONS = "division";

export const WEB_SERVICE_GENERATE_PURCHASE_ORDER =
  "order/GeneratePurchaseOrder";

//WeatherAPP
// export const WEB_SUB_CHANNEL_WEATHER = 'http://13.88.246.7:8080/sigfox-farm/sendRedisMessageTest/alarm/get';
// export const WEB_SUB_CHANNEL_WEATHER = 'http://192.168.2.183:8080/sendRedisMessageTest/alarm/get';
// export const WEB_SUB_CHANNEL_WEATHER = 'http://13.88.246.7:8080/sigfox-weather/sendRedisMessageTest/alarm/get';
// export const WEB_SUB_CHANNEL_WEATHER = 'https://waiviot.itsp-inc.com:8443/sigfox-weather/sendRedisMessageTest/alarm/get';
// export const WEB_SUB_CHANNEL_WEATHER = 'http://192.168.2.100:8080/sendRedisMessageTest/alarm/get';
// export const WEB_SUB_CHANNEL_WEATHER_ALARM = 'http://192.168.2.100:8080/sendRedisMessageTest/alert/get';
// export const WEB_SUB_CHANNEL_DEVICE = 'http://192.168.2.100:8080/sendRedisMessageTest/get';
// export const WEB_SUB_CHANNEL_WEATHER_ALARM = 'http://192.168.2.72:8080/sendRedisMessageTest/alert/get';
// export const WEB_SUB_CHANNEL_DEVICE = 'http://192.168.2.72:8080/sendRedisMessageTest/get';
// export const WEB_SUB_CHANNEL_WEATHER = 'http://localhost:8080/sendRedisMessageTest/alarm/get';
// export const WEB_SUB_CHANNEL_WEATHER_ALARM = 'http://localhost:8080/sendRedisMessageTest/alert/get';
// export const WEB_SUB_CHANNEL_DEVICE = 'http://localhost:8080/sendRedisMessageTest/get';
export const WEB_SUB_CHANNEL_WEATHER = `${WEB_SERVICE_ROOT}sendRedisMessageTest/alarm/get`;
export const WEB_SUB_CHANNEL_WEATHER_ALARM = `${WEB_SERVICE_ROOT}sendRedisMessageTest/alert/get`;
export const WEB_SUB_CHANNEL_DEVICE = `${WEB_SERVICE_ROOT}sendRedisMessageTest/get`;
//export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT=`${WEB_SERVICE_ROOT}websocketbroker`;
//export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='ws://localhost:8080/websocketbroker';
//export const WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT='wss://waiviot-dev.itsp-inc.com:3443/sigfox-farm/websocketbroker';
export const WEB_SUB_WEBSOCKET_WEATHER_DEVICEMESSAGE = `/topic/devicemessage`;
export const WEB_SUB_WEBSOCKET_WEATHER_ALERTMESSAGE = `/topic/alertMessage`;
export const WEB_SUB_WEBSOCKET_WEATHER_ALARMMESSAGE = `/topic/alarmMessage`;
export const WEB_SUB_WEBSOCKET_OCCUPANCY_DEVICEMESSAGE = `/topic/devicemessageoccupancy`;
export const WEB_SUB_WEBSOCKET_OCCUPANCY_ALERTMESSAGE = `/topic/alertMessageoccupancy`;
export const WEB_SUB_WEBSOCKET_OCCUPANCY_ALARMMESSAGE = `/topic/alarmMessageoccupancy`;
export const WEB_SUB_WEBSOCKET_PARKING_DEVICEMESSAGE = `/topic/devicemessageparking`;
export const WEB_SUB_WEBSOCKET_PARKING_ALERTMESSAGE = `/topic/alertMessageparking`;
export const WEB_SUB_WEBSOCKET_PARKING_ALARMMESSAGE = `/topic/alarmMessageparking`;
export const WEB_SUB_WEBSOCKET_SNOW_DEVICEMESSAGE = `/topic/devicemessagesnow`;
export const WEB_SUB_WEBSOCKET_SNOW_ALERTMESSAGE = `/topic/alertMessagesnow`;
export const WEB_SUB_WEBSOCKET_SNOW_ALARMMESSAGE = `/topic/alarmMessagesnow`;

export const WEB_SUB_WEBSOCKET_OPG_DEVICEMESSAGE = `/topic/deviceMessageOPG`;
export const WEB_SUB_WEBSOCKET_OPG_ALERTMESSAGE = `/topic/alertMessageOPG`;
export const WEB_SUB_WEBSOCKET_OPG_ALARMMESSAGE = `/topic/alarmMessageOPG`;

export const WEB_SUB_WEBSOCKET_POWERMGNT_DEVICEMESSAGE = `/topic/deviceMessagePowermgnt`;
export const WEB_SUB_WEBSOCKET_POWERMGNT_ALERTMESSAGE = `/topic/alertMessagePowermgnt`;
export const WEB_SUB_WEBSOCKET_POWERMGNT_ALARMMESSAGE = `/topic/alarmMessagePowermgnt`;

//export const WEB_SUB_CHANNEL_WEATHER = '${WEB_SERVICE_ROOT}sendRedisMessageTest/alarm/get';
//export const WEB_SUB_CHANNEL_WEATHER_ALARM = '$${WEB_SERVICE_ROOT}sendRedisMessageTest/alert/get';
//export const WEB_SUB_CHANNEL_DEVICE = '${WEB_SERVICE_ROOT}sendRedisMessageTest/get';
export const WEB_SUB_EVENT_WEATERHR = "alarm";
export const WEB_SUB_EVENT_ALARM = "alert";
export const WEB_SUB_EVENT_DEVICE = "devicemessage";
export const WEB_SERVICE_WEATHER_ALARM_COUNT = "MessageCount/DailyAlarm";
export const WEB_SERVICE_WEATHER_ALARM_COUNT_LIST =
  "MessageCount/DailyAlarmList";
export const WEB_SERVICE_WEATHER_GROUP_DATA = "GetGroupdataN2/Weather/Redis";
// export const WEB_SERVICE_WEATHER_CONFIG_GETALL = 'Config/getall';
export const WEB_SERVICE_WEATHER_CONFIG_GETALL = "Config/get";
export const WEB_SERVICE_WEATHER_CONFIG_POST = "Config/post";
export const WEB_SERVICE_WEATHER_CONFIG_GETBYAPPNAME =
  "Config/getByAppnameModulename";
export const WEB_SERVICE_WEATHER_GET_TIME_POINT_DATA =
  "GetdataN/Weather/latest";
// export const WEB_SERVICE_WEATHER_GET_TIME_POINT_DATA = 'GetGroupdataN2/Weather';
export const WEB_SERVICE_WEATHER_HOURLYALARM_COUNT = "MessageCount/HourlyAlarm";
export const WEB_SERVICE_WEATHER_MONTHLYALARM_COUNT =
  "MessageCount/MonthlyAlarm";

export const WEB_SERVICE_GET_CARRIER_LIST = "carrier/getCarrier";

export const WEB_SERVICE_SET_DEVICE_TYPE = "item/EditOrAddSimCardTypes";
export const WEB_SERVICE_DELETE_DEVICE_TYPE = "item/DeleteSimCardTypes";
export const WEB_SERVICE_UPLOAD_DEVICE = "item/addDevices";
export const WEB_SERVICE_UPLOAD_INVENTORY = "inventory/addInventory"; //this may replace function uptop
export const WEB_SERVICE_SET_SIM_TYPE = "item/EditAddSimType";

export const WEB_SERVICE_PAYMENT_ORDER_GET_ADDRESS = "getAddress";
export const WEB_SERVICE_PAYMENT_ORDER_SET_ADDRESS = "setAddress";

export const WEB_SERVICE_SET_SERVICE_PLAN = "item/EditOrAddServicePlan";
export const WEB_SERVICE_DELETE_SERVICE_PLAN = "item/DeleteServicePlan";

export const WEB_SERVICE_SIFOX_ORDER_CLIENT_GET_ACCOUNT_BY_ENTITY =
  "getAccountByEntity";
export const WEB_SERVICE_SIFOX_ORDER_CLIENT_CHECKOUT = "order/checkout";
export const WEB_SERVICE_PAYMENT_INVOICE_GENERATE = "generateSingleInvoice";

export const WEB_SERVICE_PAYMENTNEW_DEFAULT_PAYMENT = "defaultAccount";
export const WEB_SERVICE_PAYMENTNEW_UPDATE_ACCOUNT = "updateAccount";
export const WEB_SERVICE_PAYMENTNEW_PAYMENT_ACCOUNTS = "account";

export const WEB_SERVICE_DELETE_SPECIAL_LINE = "specialLine/DeleteSpecialLine";
export const WEB_SERVICE_GET_SPECIAL_LINE = "specialLine/getSpecialLine";
export const WEB_SERVICE_POST_SPECIAL_LINE = "specialLine/EditOrAddSpecialLine";

//SMART BUILDING

export const WEB_SERVICE_GET_PASSLINE_COUNT =
  "httpController/GetPassLineCountStatistics";

//Power Management

export const WEB_SERVICE_POWER_GET_TIME_POINT_DATA = "GetdataN/Weather/latest";
export const WEB_SERVICE_POWER_GROUP_DATA = "GetGroupdataN2Ext/Weather/Redis";

// Teisa Device Management
export const WEB_SERVICE_GET_DEVICE_LIST = "inventory/getDeviceList";
export const WEB_SERVICE_CHANGE_DEVICE_STATUS = "inventory/changeDeviceStatus";
export const WEB_SERVICE_REGISTER_DEVICE = "inventory/registerDevice";

export const WEB_SERVICE_GET_USER_ACTIVITY_TRACKING = "GetUserActivityTracking";
