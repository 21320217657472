/* eslint-disable */
import React from 'react';
import {
    compose,
    bindActionCreators
} from "redux";
import { connect } from "react-redux";
import ContactForm from './ContactForm';
import { Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import { sendEmail } from '../../../redux/actions/supportAction';

class SupportMain extends React.Component {

    submit = values => {
        // print the form values to the console
        console.log('Form values', values)
    }

    render() {
        return (
            <Row className="justify-content-center" >
                {/* <Col md={6} sm={12}> */}
                <Card>
                    {/* the class name of "card-background" contains the app background image */}
                    <CardBody className="contact-us-wrapper card-background">
                        <div className='contact-us-header'>
                            <h3 className="page-title" style={{ paddingLeft: "0px" }}>Contact Us</h3>
                            <div>We're here to help! <br />If you encounter any issues or have suggestions for improvement, please let us know. Your feedback helps us to serve you better. We're always listening.</div>
                        </div>
                        <ContactForm onSubmit={this.props.sendEmail} />
                    </CardBody>
                </Card>
                {/* </Col> */}
            </Row>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendEmail,
    }, dispatch);
};

export default connect(null, mapDispatchToProps)(SupportMain)