/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Card, CardBody, Label, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import RenderDeviceList from './RenderDeviceList';
import RenderAssetTableEdit from './RenderDeviceList'

import ClassCreateModal from './ClassCreateModal'
import EditAssetPanel from './EditAssetPanel';
import { SingleSelection } from './SelectionComponents'
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import ClassDisplayDetail from './ClassDisplayDetail';
import {getGroupAssetData} from '../../../redux/actions/mapActions';

export default class ClassDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetClassToEdit: null,
      assetClassToDelete: null,
      classAttributes: [],
    }
  }

  closeDetailsPanel = () => { this.setState({ assetClassToEdit: null }) }

  onTableEditClick = (id) => {
    this.setState({ assetClassToEdit: id });
    this.props.getAssetClassAttributeAjax(id).then(data => {
      if (data) {
        this.setState({classAttributes: data});
      }
    });
  }
  onTableDeleteClick = (e, id) => {
    e.stopPropagation();
    let ac = this.props.assetClassList.find(ac => ac.id == id)
    if (ac && ac.assets && ac.assets.length == 0)
      this.setState({ assetClassToDelete: id })
    else
      alert('Remove assets first before deleting')
  }
  deleteAssetClass = () => {
    this.closeDetailsPanel()
    this.props.deleteAssetClass(this.state.assetClassToDelete)
    this.toggleDeleteModalOff()
  }
  toggleDeleteModalOff = () => { this.setState({ assetClassToDelete: null }) }

  render() {
    const onRowClick = ()=>{} // this.onTableEditClick
    const onEditClick = this.onTableEditClick
    const onDeleteClick = this.onTableDeleteClick
    return (
        <>
          <Card>
            <div className='card-pg'>

              <RenderDeviceList
                  devmaList={this.props.devmaList}
                  devmaLocation={this.props.devmaLocation}
                  devmaStatus={this.props.devmaStatus}
                  devmaNvr={this.props.devmaNvr}
                  loadDevmaList={this.props.loadDevmaList}
                  assetClasses={this.props.assetClassList}
                  tableActions={{ onRowClick, onEditClick, onDeleteClick }}
                  getGroupAssetData = {this.props.getGroupAssetData}
              />
            </div>
          </Card>
        </>
    );
  }
}
