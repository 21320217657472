/* eslint-disable */
import React, { Component, Fragment, useEffect, useState } from "react";
import { getCurrentUser } from "../../../factories/utils";
import PowerManagementMainBoard from "./PowerManagementMainBoard";
import { connect } from "react-redux";
import { loadAssets, loadClasses } from "../../../redux/actions/assetActions";
import {
  loadDevice,
  loadDeviceInfo,
  getDeviceAttributes,
} from "../../../redux/actions/mapActions";
import {
  loadMessageDecode,
  loadMessageDecodeGroup,
} from "../../../redux/actions/decoderActions";
import { getPowerAssetData } from "../../../redux/actions/powerAction";
import {
  reformWeatherAssetData,
  reformAlertPubsubData,
  updateStatusAsset,
} from "../../../factories/weather";
import { bindActionCreators } from "redux";
import {
  getCompanyAssetClass,
  getWeatherAssetData,
  getPowerAssetHistoryData,
  clearWeatherAssetData,
  getWeatherAssetHistoryData,
  clearWeatherAssetHistoryData,
  clearPowerAssetHistoryData,
} from "../../../redux/actions/weatherAction";
import { toast } from 'react-toastify';

const POWER_ASSETCLASS_NAME_ARRAY = [
  "Power Management ",
  "Power Management Demo",
  "Power Management",
];

class MainBoard extends Component {
  constructor(props) {
    super(props);
    let index = localStorage.getItem("activeTab");
    this.state = {
      showHistory: false,
      startDateMoment: null,
      endDateMoment: null,
      startTime: null,
      endTime: null,
      isTimeDisabled: true,
      currentAsset: null,
      assetList: [],
      deviceid: null,
      alarmList: [],
      alarmSet: {},
      alarmSetObj: {},
      statusData: [],
      refresh: false,
      interval: null,
    };

    this.userObj = getCurrentUser();
    this.powerClassId = null;
  }

  componentDidMount() {
    this.props.loadDevice();
    this.props.loadDeviceInfo();
    this.props.loadAssets();
    this.props.loadClasses();
    this.props.loadMessageDecode();
    this.props.loadMessageDecodeGroup();
    this.loadAssetByCompanyClass();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.assetData &&
      nextProps.assetData.length > 0 &&
      nextProps.assetData != this.props.assetData
    ) {
      let statusData = reformWeatherAssetData(nextProps.assetData);
      this.setState({
        statusData,
      });
    }
    this.updateHistoryDataObj();
  }
  // POWER_ASSETCLASS_NAME_ARRAY needs to be dynamic but I am still thinking how to do it when we create a new assetclass.
  loadAssetByCompanyClass = async () => {
    try {
      if (this.powerClassId == null) {
        let res = await getCompanyAssetClass();
        //
        if (res.status === 200 && res.data) {
          this.powerClassId = [];
          for (let i = 0; i < res.data.length; i++) {
            if (
              POWER_ASSETCLASS_NAME_ARRAY.includes(res.data[i].assetclassname)
            ) {
              let assetclassId = res.data[i].assetclassid;
              this.powerClassId.push(assetclassId);
              let assets = res.data[i].assets;
              this.setState((prevState) => {
                if (
                  Array.isArray(prevState.assetList) &&
                  prevState.assetList.length > 0
                ) {
                  return {
                    assetList: [...prevState.assetList, ...assets],
                  };
                } else {
                  return {
                    assetList: assets,
                  };
                }
              });
            }
          }
        } else {
          throw new Error("Failed to get power management Asset Class!");
        }
      }
      if (this.powerClassId != null) {
        this.loadAssetData(this.powerClassId);
      } else {
        throw new Error("Could not find Power Asset Class!");
      }
    } catch (err) {
      console.error(err);
      toast.error(err);
    }
  };

  loadAssetData = (assetclassId) => {
    let params = {
      entitygroup: assetclassId,
      entity: "",
      starttime: "",
      endtime: "",
      latest: "1",
      themeid: "",
    };

    this.props.getWeatherAssetData(params);
  };

  updateAttributeHistoryData = async (historyCriteria) => {
    await this.props.getPowerAssetHistoryData(historyCriteria);
    // let {
    //   currentAsset,
    //   startDateMoment,
    //   endDateMoment,
    //   startTime,
    //   endTime,
    //   isTieDisabled,
    // } = this.state;
    // let startTimestamp = startDateMoment ? startDateMoment.utc().valueOf() : 0;
    // let endTimestamp = endDateMoment
    //   ? endDateMoment.utc().valueOf()
    //   : moment()
    //       .utc()
    //       .valueOf();

    // if (startTimestamp == endTimestamp) {
    //   if (isTimeDisabled) {
    //     this.setState({
    //       isTimeDisabled: false,
    //     });
    //   }
    //   if (startTimestamp != 0 && startTime && endTime) {
    //     let startDateStr = startDateMoment.format("YYY-MM-DD");
    //     if (startDateStr) {
    //       startTimestamp = moment(`${startDateStr}T${startTime.value}`)
    //         .utc()
    //         .valueOf();
    //       endTimestamp = moment(`${startDateStr}T${endTime.value}`)
    //         .utc()
    //         .valueOf();
    //     }
    //   }
    // }

    // const assetCriteria = {
    //   entity: currentAsset ? currentAsset.assetid : 0,
    // };

    // const historyCriteria = {
    //   ...assetCriteria,
    //   starttime: startTimestamp,
    //   endtime: endTimestamp,
    // };
  };

  toggle = (toggle, asset) => {};

  historyDataProcessor = (assetid, deviceid) => {
    // console.log(
    //   "===finalHistoryObj",
    //   Object.values(this.props.power.powerAssetHistoryObj[assetid])
    // );
    return Object.values(this.props.power.powerAssetHistoryObj[assetid]);
  };

  filterHistroyObj = (obj, deviceid) => {
    let filterByDevice = Object.fromEntries(
      Object.entries(obj).filter(([key]) => key.includes(deviceid))
    );

    return Object.values(filterByDevice);
  };

  setDeviceid = (deviceid) => {
    this.setState({
      deviceid,
    });
  };

  updateHistoryDataObj = () => {
    if (
      this.props.power.powerAssetHistoryObj &&
      this.props.power.powerAssetHistoryObj.length != 0
    ) {
      let historyObj = this.props.power.powerAssetHistoryObj;

      for (let key in historyObj[Object.keys(historyObj)[0]]) {
        historyObj[Object.keys(historyObj)[0]][key].graphname =
          historyObj[Object.keys(historyObj)[0]][key].displayname +
          " - " +
          key.split(":")[0];
      }
      var historyDataObj = this.filterHistroyObj(
        historyObj[Object.keys(historyObj)[0]],
        this.state.deviceid
      );
    }
    return historyDataObj;
  };
  render() {
    if (
      this.props.power.powerAssetHistoryObj &&
      this.props.power.powerAssetHistoryObj.length != 0
    ) {
      // var oldhistoryDataObj = this.historyDataProcessor(
      //   Object.keys(this.props.power.powerAssetHistoryObj)
      // );
      let historyObj = this.props.power.powerAssetHistoryObj;

      for (let key in historyObj[Object.keys(historyObj)[0]]) {
        historyObj[Object.keys(historyObj)[0]][key].graphname =
          historyObj[Object.keys(historyObj)[0]][key].displayname +
          " - " +
          key.split(":")[0];
      }
      var historyDataObj = this.filterHistroyObj(
        historyObj[Object.keys(historyObj)[0]],
        this.state.deviceid
      );
      // let filterHistoryData = Object.fromEntries(
      //   Object.entries(historyObj[Object.keys(historyObj)[0]]).filter(([key]) =>
      //     key.includes("POWER01")
      //   )
      // );
      // console.log("===ProcessorBefore", oldhistoryDataObj);
      // console.log("===ProcessorAfter", historyDataObj);
      // console.log("===historyObjBefore", historyObj);
      // console.log("===historyObjAfter", filterHistoryData);
      // console.log("===Object.keys(this.props.power.powerAssetHistoryObj)", Object.keys(this.props.power.powerAssetHistoryObj));
      // console.log("===finalObject.values(historyObjAfter)", Object.values(filterHistoryData));
      // console.log("===this.props.power.powerAssetHistoryObj", this.props.power.powerAssetHistoryObj);
      // console.log("===ProcessorBefore", this.props.power.powerAssetHistoryObj);
      //myobj[Object.keys(myobj)[0]]
    }
    return (
      <Fragment>
        <PowerManagementMainBoard
          assetClassList={this.props.assetClassList}
          assetList={this.props.assetList.filter(
            (item) => (item.assetclassid == 216 || item.assetclassid == 225)
          )}
          statusData={this.state.statusData}
          assetData={this.props.assetData}
          assetHistoryObj={this.props.assetHistoryObj}
          assetExpendData={this.props.assetExpendData}
          deviceList={this.props.currentDeviceList}
          deviceAttrList={this.props.deviceAttrList}
          setDeviceid={this.setDeviceid}
          messageDecodeList={this.props.messageDecodeList}
          messageDecodeGroup={this.props.messageDecodeGroup}
          updateAttributeHistoryData={this.updateAttributeHistoryData}
          historyDataObj={historyDataObj}
          powerAttrList={this.props.power.powerAttrList}
          power={this.props.power}
          toggle={this.toggle}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  let props = {
    deviceAttrList: state.mapboard.deviceAttrList,
    messageDecodeList: state.messagedecode.messageDecodeList,
    messageDecodeGroup: state.messagedecode.messageDecodeGroup,
    assetData: state.weather.assetData,
    assetHistoryObj: state.weather.powerAssetHistoryObj,
    assetExpendData: state.weather.assetExpendData,
    power: state.weather,
    ...state.asset,
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadDevice,
      loadDeviceInfo,
      loadAssets,
      loadClasses,
      getDeviceAttributes,
      loadMessageDecode,
      loadMessageDecodeGroup,
      getPowerAssetData,
      getPowerAssetHistoryData,
      getWeatherAssetData,
      clearWeatherAssetData,
      getWeatherAssetHistoryData,
      clearWeatherAssetHistoryData,
      clearPowerAssetHistoryData,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainBoard);

// for(let i =0; i != Object.values(historyObj).length; i++) {
//   for(let deviceid in historyObj[Object.keys(historyObj)[i]]) {
//     console.log("===i includes", deviceid.includes("POWER01"));
//     console.log("===i in", deviceid);
//     if(deviceid.includes("POWER02")) {
//       let attr = []
//       for(let q = 0; q != Object.values(historyObj[Object.keys(historyObj)[i]]).length ; q++){
//         attr.push(Object.values(historyObj[Object.keys(historyObj)[i]]))
//       };
//       console.log("===i attr", attr);
//       Object.values(historyObj[Object.keys(historyObj)[i]])
//       console.log("=== i includes key", Object.values(historyObj[Object.keys(historyObj)[i]]));
//     }
//   }
//   console.log("===i", historyObj[Object.keys(historyObj)[i]]);
//   console.log("===i.length", Object.values(historyObj[Object.keys(historyObj)[i]]).length);
//   console.log("===i.values", Object.values(historyObj[Object.keys(historyObj)[i]]));
//   console.log("===key == value", i );
//   let filterHistoryData = Object.fromEntries(Object.entries(historyObj[Object.keys(historyObj)[i]]).filter(([key]) => key.includes("POWER02")))
// }

// for (let i = 0; i != Object.values(historyObj).length; i++) {
//   for (let deviceid in historyObj[Object.keys(historyObj)[i]]) {
//     console.log("===i includes", deviceid.includes("POWER01"));
//     console.log("===i in", deviceid);
//     if (deviceid.includes("POWER02")) {
//       let attr = [];
//       for (
//         let q = 0;
//         q != Object.values(historyObj[Object.keys(historyObj)[i]]).length;
//         q++
//       ) {
//         attr.push(Object.values(historyObj[Object.keys(historyObj)[i]]));
//       }
//       console.log("===i attr", attr);
//       Object.values(historyObj[Object.keys(historyObj)[i]]);
//       console.log(
//         "=== i includes key",
//         Object.values(historyObj[Object.keys(historyObj)[i]])
//       );
//     }
//   }
//   console.log("===i", historyObj[Object.keys(historyObj)[i]]);
//   console.log(
//     "===i.length",
//     Object.values(historyObj[Object.keys(historyObj)[i]]).length
//   );
//   console.log(
//     "===i.values",
//     Object.values(historyObj[Object.keys(historyObj)[i]])
//   );
//   console.log("===key == value", i);
//   let filterHistoryData = Object.fromEntries(
//     Object.entries(historyObj[Object.keys(historyObj)[i]]).filter(
//       ([key]) => key.includes("POWER02")
//     )
//   );
// }
// }
