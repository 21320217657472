/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label } from 'recharts';
import { renderSpinner } from './PeopleCountBoard';
import moment from 'moment';
import Select from 'react-select';
import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { groupByArray } from '../../../../factories/utils';
import { Camera } from '@material-ui/icons';
import { getCurrentUser, hasRole } from '../../../../factories/auth';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from 'react-bootstrap-table-next';
import VideoMpegPlayer from '../VideoMpegPlayer';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ReactPlayer from '../ReactPlayer';
import { Client } from '@stomp/stompjs';
import NVRPlay from '../videoPlayer/nvr/NVRPlay';
import { local } from 'd3';
// import './SmartBuilding.css'




export default function IndividualCamChart({ localData, loadPeopleCount, nvrIP, nvrChannelList, httpPort, mainNVR }) {
    // const [LocalData, setLocalData] = useState(localData)
    const User = getCurrentUser();
    const [CameraChannel, setCameraChannel] = useState(null);
    const [NVRChannel, setNVRChannel] = useState(null)
    const [defaultSelectValue, setDefaultSelectValue] = useState(null)
    const [SelectStyle, setSelectStyle] = useState({
        menu: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            zIndex: 9999
        }),
        option: (provided, state) => ({
            ...provided,
            color: "black",
            width: "100%",
            cursor: "pointer",
            '&:active': { backgroundColor: user.detail.appButtonColorHover },
            backgroundColor: state.isFocused ? user.detail.appButtonColorHover : "white",
        }),
        control: (base, selectState) => ({
            ...base,
            // height: 35,
            // minHeight: 35
            width: "100%",
            height: 35,
            minHeight: 35,
            cursor: "pointer",
            borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor,
            // This line disable the blue border
            boxShadow: selectState.isFocused ? 0 : 0,
            '&:hover': { borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor }
        }),
        indicatorsContainer: (base, selectState) => ({
            ...base,
            padding: "0",
        }),
    })
    const [SelectedCam, setSelectedCam] = useState(null)
    const [CameraOptions, setCameraOptions] = useState(null)
    const user = getCurrentUser();
    useEffect(() => {
        let cameraOptions = []
        if (localData && localData.length > 0) {
            localData.map(camData => {
                let dataOption = { label: camData.camName, value: camData.camChannel }
                cameraOptions.push(dataOption)
            })
            setCameraOptions(cameraOptions)
            if (cameraOptions.length > 0) {
                setSelectedCam(cameraOptions[0].label)
                setDefaultSelectValue(cameraOptions[0])
            }
        } else {
            setCameraOptions([])
            setSelectedCam(null)
            setDefaultSelectValue(null)
        }
    }, [localData])


    const handleCameraChange = (option) => {
        if (option) {
            setDefaultSelectValue(option)
            setSelectedCam(option.label) //camera name e.g IPC1
            setCameraChannel(option.value) //camera channel e.g 1
        }
    }
    const handleDateChange = (date => {
        date = moment(date).format('MM-DD-YYYY')
        setFilterDate(date)
        loadPeopleCount(date.toString())
    })

    const renderTable = (data) => {
        let currentData
        if (data) {
            currentData = data[data.length - 1]
        }
        const columns = [
            { dataField: 'Entrance Count', text: 'Entrance Count' },
            { dataField: 'Exit Count', text: 'Exit Count' },
            { dataField: 'On Premises', text: 'No. of People at Premise', style: { fontWeight: 'bold', fontSize: '1.25em', color: "#ff3300" } },
            { dataField: 'timestamp', text: 'Last Updated Date' },
        ]
        if (currentData) {
            console.log('Table data', currentData)
            return (
                <table className="stat-table">
                    <tr>
                        <th>Entrance Count</th>
                        <td>{currentData.entranceCount}</td>
                    </tr>
                    <tr>
                        <th>Exit Count</th>
                        <td>{currentData.exitCount}</td>
                    </tr>
                    <tr>
                        <th>NO. of People at Premises</th>
                        <td style={{ fontWeight: "bold", color: "#33cc33" }}>{currentData.inPremises}</td>
                    </tr>
                    <tr>
                        <th>Last Updated date</th>
                        <td>{moment(currentData.timestamp).local().subtract(1, 'hours').format('MM-DD-YYYY HH:mm:ss')}</td>
                    </tr>
                </table>
                // <BootstrapTable striped={true} data={currentData} keyField='id' columns={columns} filter={filterFactory()}/>
            )
        }
    }

    const renderLiveFeedComponent = () => {
        if (httpPort && nvrIP && nvrChannelList && nvrChannelList.length > 0) {
            return (
                <NVRPlay
                    type={'live'}
                    nvrChannelList={nvrChannelList}
                    nvrIP={nvrIP}
                    camNumber={CameraChannel}
                    camName={SelectedCam}
                    port={httpPort}
                    nvr={mainNVR} />
            )
        } else {
            return (<ReactPlayer
                type="ppc"
                ch={NVRChannel}
                chid={CameraChannel}
                height="400px"
                width="100%" />)
        }
    }
    if (CameraOptions && CameraOptions.length > 0) {
        return (
            <>
                <Row>
                    <Col md={6}>
                        <p className="footage-header">Real Time Footage</p>
                    </Col>
                </Row>
                <Row className="mb-15">
                    <Col md={6}>
                        <Select
                            // isClearable
                            isSearchable
                            // options = {this.state.assets} //assetList
                            options={CameraOptions}
                            value={defaultSelectValue}
                            placeholder={'Select Camera ...'}
                            onChange={handleCameraChange}
                            styles={SelectStyle}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col md={8}>
                        {renderLiveFeedComponent()}
                    </Col>
                    <Col md={2}>
                    </Col>
                </Row>

            </>
        )
    } else {
        return null
    }

}