/* eslint-disable */
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Card, CardBody, Col } from "reactstrap";
import Select from 'react-select';
import { faSleigh } from "@fortawesome/free-solid-svg-icons";

const BEACON_VISIBLE = "Beacon Visible";
class TortoiseBoard extends Component {
  constructor(props) {
    super(props);
    this.id = [];
    this.state = {
      selectedAssetOption: [],
      assetName: [],
      assetClick: [],
      assetSelector: false,
      displayAsset: false,
      displayDevice: false.valueOf,
    };
  }

  componentDidUpdate() {
    // console.log("===Updated"); // Testing purpose
  }

  messageId = (cell, row) => {
    let messageId = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes? Object.values(attributes.MessageId)[0] : null;
      
      if(obj) {
        messageId = obj.value;
      }
    }

    return (
      `${messageId}`
    )
  };

  batteryRender = (cell, row) => {
    let obj = Object.values(row)[0];
    let batteryLevel;
    let batteryUnit = "%";
    if(cell){
      if(cell.Battery) {
        for(let i = 0; i < cell.Battery.length; i++) {
          for(const property in this.id) {
            if(this.id[property] == cell.Battery[i].deviceid) {
              batteryLevel = cell.Battery[i].value;
  
              break;
            }
          }
        }
      }
    }
    return(
      `${batteryLevel}${batteryUnit}`
    )
  };

  moveDetect = (cell, row) => {
    let moveDetect = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj =  attributes ? Object.values(attributes.MoveDetect)[0] : null
      if(obj) moveDetect = obj.value;
    }

    return(
      `${moveDetect}`
    )
  };
 
  beaconVisible = (cell, row) => {
    let beaconVisible = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes? Object.values(attributes.BeaconVisible)[0] : null;

      if(obj) {
        beaconVisible = obj.value;
      }
    };

    return(
      `${beaconVisible}`
    )
  };

  gpsLock = (cell, row) => {
    let gpsLock = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes ? Object.values(attributes.GPSLock)[0] : null;

      if(obj) {
        gpsLock = obj.value;
      }
    };

    return(
      `${gpsLock}`
    )
  };

  gpsUsed = (cell, row) => {
    let gpsUsed = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes? Object.values(attributes.GPSUsed)[0] : null;
      
      if(obj) {
        gpsUsed = obj.value;
      }
    };

    return(
      `${gpsUsed}`
    )
  };

  hdop = (cell, row) => {
    let hdop = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes? Object.values(attributes.HDOP)[0] : null;

      if(obj) {
        hdop = obj.value;
      }
    };

    return(
      `${hdop}`
    )
  };

  cnOk = (cell, row) => {
    let cnOk = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes ? Object.values(attributes.CN0_OK)[0] : null

      if(obj) {
        cnOk = obj.value;
      }
    };

    return (
      `${cnOk}`
    )
  };

  beaconId = (cell, row) => {
    let beaconId = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes ? Object.values(attributes.BeaconId)[0] : null;
      let messageId = attributes ? Object.values(attributes.MessageId)[0] : null;

      if(obj && messageId) {
        if(messageId.value == "Beacon Visible") {
          beaconId = obj.value;
        } else {
          beaconId = "N/A";
        }
      };
    };

    return (
      `${beaconId}`
    )
  }

  lat = (cell, row) => {
    let lat = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes? Object.values(attributes.Latitude)[0] : null;
      let messageObj = attributes ? Object.values(attributes.MessageId)[0] : null;
      
      if(obj && messageObj) {
        if(messageObj.value == "Beacon Visible") {
          lat = "N/A";
        } else {
          lat = obj.value;
        }
      }
    };

    return(
      `${lat}`
    )
  };

  lng = (cell, row) => {
    let lng = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes ? Object.values(attributes.Longitude)[0] : null;
      let messageObj = obj ? Object.values(attributes.MessageId)[0] : null;

      if(obj && messageObj) {
        if(messageObj.value == "Beacon Visible") {
          lng = "N/A";
        } else {
          lng = obj.value;
        }
      }
    };

    return(
      `${lng}`
    )
  }

  zoneId = (cell, row) => {
    let zoneId = "N/A";
    if(row) {
      let attributes = row.attributes;
      let obj = attributes ? Object.values(attributes.ZoneId)[0] : null;

      if(obj) {
        zoneId = obj.value;
      }
    };

    return(
      `${zoneId}`
    )
  }

  render() {
    let options = {
      noDataText: this.props.assetDataLoading ? 'Loading...' : 'No data to display',
    }
    this.id = [];
    let statusData = this.props.statusDataProp;
    for(let i = 0; i < statusData.length; i++) {
      let data = statusData[i];
      if(data.attributes){
        data.attributes["MessageId"].sort((a, b) => (a.value < b.value)? 1 : ((b.value < a.value) ? -1 : 0));
        this.id.push(data.attributes["MessageId"][0].deviceid);
      }
    }

    
    return(
      <Card>
                <CardBody>
                    <BootstrapTable data={statusData} options={options} tableHeaderClass='header_white' exportCSV csvFileName='OPG Monitoring.csv'>
                        <TableHeaderColumn dataField='assetname' dataFormat={this.assetnameFormat} filter={{type: 'TextFilter', placeholder:'Search...', delay: 1000}} dataAlign='center' csvHeader='Station Name' isKey>Station Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.messageId} headerAlign='center' filter={{type: 'TextFilter', placeholder:'Search...', delay: 1000}} dataAlign='center' csvFormat={this.occupancyStatusCSV} csvHeader='Message ID'>Message ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.batteryRender}  headerAlign='center' filter={{type: 'TextFilter', placeholder:'Search...', delay: 1000}} dataAlign='center' csvFormat={this.batteryRenderCSV} csvHeader='Battery Level'>Battery Level</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.moveDetect} filter={{type: 'TextFilter', placeholder:'Search...', delay: 1000}} dataAlign='center' csvFormat csvHeader='Move Detect'>Move Detect</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.beaconVisible} dataAlign='center' csvFormat csvHeader='Beacon Visible' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>Beacon Visible</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.gpsLock} dataAlign='center' csvFormat csvHeader='GPS Lock' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>GPS Lock</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.gpsUsed} dataAlign='center' csvFormat csvHeader='GPS Used' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>GPS Used</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.hdop} dataAlign='center' csvFormat csvHeader='HDOP' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>HDOP</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.cnOk} dataAlign='center' csvFormat csvHeader='CN/0_OK' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>CN/0_OK</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.beaconId} dataAlign='center' csvFormat csvHeader='Beacon ID' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>Beacon ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.lat} dataAlign='center' csvFormat csvHeader='Latitude' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>Latitude</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.lng} dataAlign='center' csvFormat csvHeader='Longitute' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>Longitute</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.zoneId} dataAlign='center' csvFormat csvHeader='Zone ID' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>Zone ID</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
    )
  }
}

export default TortoiseBoard;
