/* eslint-disable */
import React, { Component } from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Collapse } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { EditTextButton } from '../../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../../shared/components/Dialog/ConfirmationDialog';

import { Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';


import { getServicePlanItem, SetServicePlan, DeleteServicePlan } from '../../../../redux/actions/userAction';
import { getFullInventoryList, getCarrierPlans, getInventoryList, uploadFile, SetDeviceType }
    from '../../../../redux/actions/inventoryActions';


class ServicePlanManagementBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        //this.props.getCountries();
        //this.props.getCarriers();
        //this.props.getResellerList();
        //this.props.getCompanyCode();
        this.props.getServicePlanItem({ "companyCode": this.props.currentUserObj.companyCode, "resellerCode": this.props.currentUserObj.resellerCode })
        this.props.getCarrierPlans({ "CompanyCode": this.props.currentUserObj.companyCode });

    }
    componentWillReceiveProps(nextProps, nextContext) {
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeTab !== prevProps.userID) {

        }
    }

    cellEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.onEditServiceOpen(row) }} />
            </div>
        );
    };
    cellItemEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.onEditItemOpen(row) }} />
            </div>
        );
    };

    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 365) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }

    dataFormatter = (cell, row) => {
        if (cell < 1024) {
            return (`${cell} MB`);
        }
        if (cell >= 1024) {
            var gb = (cell / 1024).toFixed(2)
            return (`${gb} GB`);
        }
    }


    statusFormatter = (cell, row) => {
        if (cell == 1) {
            return <span style={{ color: "green" }}>Active</span>
        }
        if (cell == 2) {
            return <span style={{ color: "red" }}>Suspend</span>
        }
    }


    customFormatter = (cell, row) => {
        if (cell != null) {
            return <span style={{ color: "green" }}>TRUE</span>;
        }
        return;
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onInputCarrierChange = (event) => {
        if (event.target.value >= 0) {
            this.setState({
                itemCode: this.props.carrierPlanList[event.target.value].name,
                itemDescription: this.props.carrierPlanList[event.target.value].description
            });
        }
        else {
            this.setState({
                itemCode: "",
                itemDescription: ""
            });
        }
    }

    validateForm = (Management) => {
        if (Management == "service") {
            if (this.state.itemName != "" && this.state.itemCode != "" && this.state.itemDescription != "" && !isNaN(this.state.planDataVolume) && !isNaN(this.state.price)) {
                if (parseFloat(this.state.price) > 0 && parseInt(this.state.planDataVolume) > 0) {
                    return true;
                }
            }
        }

        return false;

    }

    onServiceSubmit = () => {
        var data = {};
        if (this.state.id > 0) {
            data = {
                "id": this.state.id,
                //"resellerId": this.props.editResellerObj.resellerId,
                "companyCode": this.props.currentUserObj.companyCode,
                "itemCode": this.state.itemCode,
                "itemName": this.state.itemName,
                "planDataVolume": this.state.planDataVolume,
                "itemDescription": this.state.itemDescription,
                "unitPrice": this.state.price,
                "itemPayFrequency": this.state.itemPayFrequency,
                "itemPayType": this.state.itemPayType,
                "autoRenewFlag": this.state.autoRenewFlag

            }
        }
        else {
            data = {
                //"resellerId": this.props.editResellerObj.resellerId,
                "companyCode": this.props.currentUserObj.companyCode,
                "itemCode": this.state.itemCode,
                "itemName": this.state.itemName,
                "planDataVolume": this.state.planDataVolume,
                "itemDescription": this.state.itemDescription,
                "unitPrice": this.state.price,
                "itemPayFrequency": this.state.itemPayFrequency,
                "itemPayType": this.state.itemPayType,
                "autoRenewFlag": this.state.autoRenewFlag
            }
        }
        this.props.SetServicePlan(data);
        this.onServiceClose();
    }
    onRemoveServicePlan = () => {
        var data = {};
        if (this.state.id > 0) {
            data = {
                "id": this.state.id,
                "companyCode": this.props.currentUserObj.companyCode
            }
        }
        this.props.DeleteServicePlan(data);
        this.onServiceClose();
    }

    onServiceClose = () => {
        this.setState({
            showServiceModal: false
        });
    };

    onServiceOpen = () => {
        this.setState({
            showServiceModal: true,
            newServicePlan: true,
            id: 0,
            resellerId: "",
            companyCode: "",
            itemCode: "",
            itemName: "",
            planDataVolume: "",
            itemDescription: "",
            price: "",
            itemPayFrequency: "Monthly",
            itemPayType: "",
            autoRenewFlag: 0
        });
    };

    onEditServiceOpen = (row) => {
        if (row.itemPayFrequencyId == 1) {
            var itemPayFrequency = "Monthly"
        }
        if (row.itemPayFrequencyId == 2) {
            var itemPayFrequency = "Yearly"
        }
        this.setState({
            showServiceModal: true,
            newServicePlan: false,
            id: row.itemId,
            resellerId: row.resellerId,
            companyCode: row.companyCode,
            itemCode: row.itemCode,
            itemName: row.itemName,
            planDataVolume: row.planDataVolume,
            itemDescription: row.itemDescription,
            price: row.unitPrice,
            itemPayFrequency: itemPayFrequency,
            itemPayType: row.itemPayType,
            autoRenewFlag: row.autoRenewFlag
        });
    };

    render() {
        return (
            <div>
                {this.renderServicePlanModal()}

                <Card>
                    <CardBody className="mainCardBody" >
                        <h3>Current Service Plans</h3>
                        <div>
                            <InsertButton
                                btnText='Start A New Plan'
                                btnContextual='btn-success'
                                btnGlyphicon='glyphicon-edit'
                                onClick={this.onServiceOpen}
                            >
                                Start A New Service Plan
                            </InsertButton>
                        </div>
                        <BootstrapTable
                            data={this.props.ServicePlanList}
                            tableHeaderClass='header_white'
                             
                            hover
                            height='100%'
                            bordered={false}
                            bodyStyle={{ overflow: 'overlay' }}
                            containerStyle={{ fontSize: "1vw" }}
                            pagination
                        >
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort  >Plan Name</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemCode' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Plan' }} dataSort  >Data Plan</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='customerId' dataFormat={this.customFormatter.bind(this)} dataAlign='center' dataSort  >Custom</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' dataFormat={this.priceFormatter.bind(this)} dataAlign='center' dataSort filter={{ type: 'NumberFilter', delay: 1000 }} >Price</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='Operations'
                                dataAlign="center"
                                dataFormat={this.cellEditButton.bind(this)}
                            >
                                Operations
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </CardBody>
                </Card>
            </div>
        )


    }

    renderServicePlanModal() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showServiceModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onServiceClose}>{(this.state.newServicePlan) ? "New Plan" : "Customize " + this.state.itemName}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Carrier Plans <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrierPlans'
                                    id='carrierPlans'
                                    placeholder='Carrier'
                                    className="form-control"
                                    onChange={this.onInputCarrierChange}
                                >
                                    <option key={-1} value={-1}>None Selected</option>
                                    {this.props.carrierPlanList && this.props.carrierPlanList.map((plan, index) =>
                                        (plan.name == this.state.itemCode) ? <option key={index} value={index} selected>{plan.name}</option> : <option key={index} value={index}>{plan.name}</option>

                                    )}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Plan Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                                <Input
                                    name='itemName'
                                    id='itemName'
                                    placeholder='Plan Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.itemName}
                                />
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2} >
                                <span>Description <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='itemDescription'
                                    id='itemDescription'
                                    placeholder='Item Description'
                                    className="form-control"
                                    value={this.state.itemDescription}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Data Usage <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='planDataVolume'
                                    id='planDataVolume'
                                    placeholder='Data Amount'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.planDataVolume}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Price <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='price'
                                    id='price'
                                    placeholder='Price'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.price}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Billing Cycle <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='itemPayFrequency'
                                    id='itemPayFrequency'
                                    placeholder='billingCycle'
                                    className="form-control"
                                    //value={this.state.company}
                                    onChange={this.onInputChange}
                                    disabled={false}
                                    defaultValue={this.state.itemPayFrequency}
                                >
                                    {["Monthly", "Yearly"].map((cycle, index) => (
                                        <option key={index} value={cycle}>{cycle}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Pay Type <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='itemPayType'
                                    id='itemPayType'
                                    placeholder='Pay Type'
                                    className="form-control"
                                    //value={this.state.company}
                                    onChange={this.onInputChange}
                                    disabled={false}
                                    defaultValue={this.state.itemPayType}
                                >
                                    <option key={1} value={""}>None Selected</option>
                                    <option key={2} value={"PAYG"}>Pay As You Go</option>

                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Auto Renew <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='autoRenewFlag'
                                    id='autoRenewFlag'
                                    placeholder='Auto Renew'
                                    className="form-control"
                                    //value={this.state.company}
                                    onChange={this.onInputChange}
                                    disabled={false}
                                    defaultValue={this.state.autoRenewFlag}
                                >
                                    <option key={1} value={0}>None Selected</option>
                                    <option key={2} value={1}>Yes</option>

                                </Input>
                            </Col>
                        </Row>
                    </Col>

                    {/* <hr/> */}
                </ModalBody>
                <ModalFooter>
                    {(this.state.newServicePlan) ?
                        null
                        :
                        <Button outline color='danger' type="button">
                            <ConfirmDeleteDialog
                                label={"Remove"}
                                onConfirm={(e) => { this.onRemoveServicePlan() }}
                                msg={`Do you want to delete ${this.state.itemName}? `}
                            />
                        </Button>
                    }
                    <Button color='custom' type="button" onClick={this.onServiceSubmit} disabled={!this.validateForm("service")} >
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onServiceClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}


function mapStateToProps(state) {

    let {
        ServicePlanList
    } = state.user;
    let {
        resellerServicePlanInventory,
        resellerDeviceInventory,
        resellerServicePlanItems,
        resellerDeviceItems,
        inventoryList,
        carrierPlanList,
        activeInventoryList,
        inactiveInventoryList,
    } = state.inventory;
    let props = {
        resellerServicePlanInventory,
        resellerDeviceInventory,
        resellerServicePlanItems,
        resellerDeviceItems,
        inventoryList,
        carrierPlanList,
        activeInventoryList,
        inactiveInventoryList,
        ServicePlanList

    };
    return props;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFullInventoryList,
        getCarrierPlans,
        uploadFile,
        SetDeviceType,
        SetServicePlan,
        DeleteServicePlan,
        getServicePlanItem
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicePlanManagementBoard);