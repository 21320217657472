/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
// import {Modal} from "react-overlays";
import {
    addConsumer,
    getAllResellers,
    //addUser, getCompanyList,
    getRoles
} from '../../../redux/actions/userAction';
//import UserAccessForm from './userAccessForm';
import { getCurrentUser, hasRole } from '../../../factories/auth';

import '../../../scss/component/form.scss';
import './ConsumerAddModal.css';

// managing autofill
const AutofillManager = (props) => {

    const handleSubmit = (event) => {
        event.preventDefault()
    }

    return (
        <form onSubmit={handleSubmit}>
            <Input type='hidden' value='' />
            {props.children}
        </form>
    )

}

const backdropStyle = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    overflow: 'auto',
};

const modalStyle = function () {
    const top = 5;
    const left = 5;

    return {
        position: 'fixed',
        width: 800,
        height: 650,
        zIndex: 1040,
        top: top + '%',
        left: left + '%',
        border: '0px solid #ffffff',
        backgroundColor: 'white',
        // boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflow: 'auto',
        resize: 'both'
    };
};

//list all the company code if current user is superadmin/system admin
//list one company code if current user is company user and company admin
//list reseller if company code is selected.

//props: currentRole(The highest role from the current user)

class ConsumerAddModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showModal: false,
            data: [],
            email: '',
            fullName: '',
            userName: '',
            companyCode: '',
            resellerCode: '',
            isCustomer: true,
            isAdmin: false,
            newUser: true,
            activeTab: '1',
            // groupdata: [],
            passConfirmed: true,
            isBusiness: true,
            selectedCountryId: "",
            countryId: '',
            lobId: '',
            relationId: '',
            countryList: [],
            LOBList: [],
            divisionList: [],
            country: "",
            state: "",
            isSubmit: false
        };
        this.userObj = getCurrentUser();
    }

  async componentDidMount() {
    //this.props.getCompanyList()
    //this.props.getRoles();   //end client only EndUser
    // if (this.userObj.companyCode && this.userObj.companyCode == "TEISA") {
    //   await this.props.getProvinces("Paraguay");
    // } else {
    //   await this.props.getProvinces("USA");
    // }
    let userObj = getCurrentUser();
    this.setState({
      companyCode: userObj.companyCode,
      resellerCode: userObj.resellerCode,
    });

    if (userObj && hasRole(userObj, ["ADMIN"])) {
      this.props.getAllResellers();
      this.setState({ isAdmin: true });
    }


    // let cval = this.props.countryList[0] ? this.props.countryList[0].value : "";
    // if (cval) {
    //   this.props.getProvinces(cval);
    // }


    let perval = this.props.provinceList[0]?this.props.provinceList[0].value:''
    if(perval) {
      this.setState({ state: perval });
    }

  }

    componentWillReceiveProps(nextProps, nextContext) {
        // alert(nextProps.modules.groupType);
        if (nextProps.show === true) {

            this.setState({
                showModal: true,
                userName: '',
                password: '',
                passwordConfirm: '',
                email: '',
                phoneNum: '',
                creator: '',
                createTime: '',
                companyCode: this.state.companyCode,
                network: '',
                resellerCode: this.state.resellerCode,
                customerId: '',
                expiredDate: '',
                role: ["ENDUSER"],
                customer: null,
                businessName: "",
                firstName: "",
                lastName: "",
                middleName: "",
                ssn: "",
                birthDate: "",
                language: "",
                zipCode: "",
                city: "",
                state: "",
                country: "",
                addressLine1: "",
                addressLine2: "",
                isBusiness: true,
                paymentTermId: 1,
                countryId: '',
                lobId: '',
                relationId: '',
                isSubmit: false
            });
        }
        if (nextProps.companyList && nextProps.companyList.length > 0) {
            this.setState({
                company: nextProps.companyList[0].code,

            });
        }

        //this.props.getProvinces('USA');

    }

    renderBackdrop = (props) => {
        return <div {...props} style={backdropStyle} />;
    };

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onCompanyCodeChange = (event) => {

        let value = event.target.value;
        if (value == "") {
            this.props.getResellerCode("-");
        }
        else {
            this.props.getResellerCode(value);
        }
        this.setState(prevState => {
            prevState.companyCode = value;
            prevState.resellerCode = '';
            return ({
                companyCode: prevState.companyCode,
            })
        });

    }

    onCountryChange = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        this.props.getProvinces(value);
        this.setState(prevState => {
            prevState.country = value;
            prevState.province = '';
            return ({
                country: prevState.country,
            })
        });
    }

    onSubmit = () => {
        // if (this.props.userName !== '') {
        //     this.props.saveDetail({
        //         email: this.state.email,
        //         fullName: this.state.fullName,
        //         data: this.state.data,
        //         userName: this.state.loginName
        //     });
        // }
        // else {
        //     this.props.addDetail({
        //         email: this.state.email,
        //         fullName: this.state.fullName,
        //         data: this.state.data,
        //         userName: this.state.loginName
        //     });
        // }

        if (!sessionStorage.getItem('companyCode') || !sessionStorage.getItem('currentUser')) {
            return;
        }
        let phoneNo = this.state.phoneNum;
        if (phoneNo) {
            phoneNo = phoneNo.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
        if (this.validateForm()) {
            // alert ('v yes');
            let userObj = {
                isnew: true,
                role: this.state.role, //['USER'],
                userName: this.state.userName,
                password: this.state.password,
                passwordConfirm: this.state.passConfirmed,
                email: this.state.email,
                phoneNum: phoneNo,
                businessUnit: this.state.relationId,
                creator: sessionStorage.getItem('currentUser'),
                createTime: new Date().toISOString().split('.')[0].replace('T', ' '),
                companyCode: this.state.companyCode,
                resellerCode: this.state.resellerCode,
                paymentTermId: this.state.paymentTermId,
                //network:this.state.network,
                customerId: null,
                expiredDate: null,
                //role:[],
                customer: null

            }
            //check if it is intended to create new client
            if (this.state.isCustomer) {
                let businessName = null, businessTaxId = null, ssn = null;
                if (this.state.isBusiness == true) {
                    businessName = this.state.businessName;
                    businessTaxId = this.state.businessTaxId;
                    ssn = null;
                }
                else {
                    businessName = null;
                    businessTaxId = null;
                    ssn = null;
                }
                let customerInfo = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    middleName: this.state.middleName,
                    ssn: this.state.ssn,
                    birthDate: this.state.birthDate,
                    language: this.state.language,
                    phone: phoneNo,
                    zipCode: this.state.zipCode,
                    city: this.state.city,
                    state: this.state.state,
                    country: this.state.country,
                    addressLine1: this.state.addressLine1,
                    addressLine2: this.state.addressLine2,
                    email: this.state.email,
                    businessName: this.state.businessName,
                    businessTaxId: this.state.businessTaxId,
                    paymentTermId: this.state.paymentTermId,
                    //customerId
                    //resellerId
                }
                userObj.customer = customerInfo;
            }

            this.props.addConsumer(userObj, true, "consumer");

            this.onClose();
        } else {
            // alert('v no');
            this.setState({
                isSubmit: true
            })
        }
    };

    onClose = () => {
        this.setState({
            //resellerCode: null,
            showModal: false,
            countryList: [],
            LOBList: [],
            divisionList: [],
        });
    };

    validatePassConfirm = () => {
        return (this.state.passwordConfirm && this.state.password === this.state.passwordConfirm);
    }

    validateUserId = () => {
        return (this.state.userName && this.state.userName !== '');
    }

    validateUserName = () => {
        return (this.state.userName && this.state.userName !== '' && this.state.userName.trim());
    }
    validateBusinessName = () => {
        return (this.state.businessName && this.state.businessName !== '' && this.state.businessName.trim());
    }
    validateFirstName = () => {
        return (this.state.firstName && this.state.firstName !== '' && this.state.firstName.trim());
    }
    validateLastName = () => {
        return (this.state.lastName && this.state.lastName !== '' && this.state.lastName.trim());
    }
    validateAddressLine1 = () => {
        return (this.state.addressLine1 && this.state.addressLine1 !== '' && this.state.addressLine1.trim());
    }
    validateCity = () => {
        return (this.state.city && this.state.city !== '' && this.state.city.trim());
    }
    validateZipCode = () => {
        return (this.state.zipCode && this.state.zipCode !== '' && this.state.zipCode.trim());
    }
    validateCountry = () => {
        return (this.state.country && this.state.country !== '' && this.state.country.trim());
    }
    validateState = () => {
        return (this.state.state && this.state.state !== '' && this.state.state.trim());
    }
    validatePaymentTermId = () => {
        return (this.state.paymentTermId && this.state.paymentTermId !== '');
    }
    validatePassword = () => {
        return (this.state.password && this.state.password !== '');
    }

    validatePhoneNumber = () => {
        var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return (this.state.phoneNum && regex.test(this.state.phoneNum));
    }
    validBusinessUnit = () => {
        return (this.state.relationId != null && !isNaN(this.state.relationId))
    }
    validResellerCode = () => {
        return (this.state.resellerCode != null && this.state.resellerCode != '')
    }

    validateEmail = () => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (this.state.email && regex.test(this.state.email));
    }

    validateForm = () => {
        let businessNameCheck = true;
        if (this.state.isBusiness == true) {
            businessNameCheck = this.validateBusinessName();
        }

        const { userName, firstName, lastName,
            addressLine1, city, zipCode,
            country, state } = this.state

        return (userName && userName.trim()
            && this.validatePhoneNumber()
            && this.validateEmail()
            && firstName && firstName.trim()
            && lastName && lastName.trim()
            && addressLine1 && addressLine1.trim()
            && city && city.trim()
            && country && country.trim()
            && state && state.trim()
            && zipCode && zipCode.trim()
            && this.validatePaymentTermId()
            && this.validatePassConfirm()
            && this.validBusinessUnit()
            && this.validResellerCode()
            && businessNameCheck
        );
    }
    /*
        onRoleCheckboxClick = (event) => {
            if (event.target.value == 'ENDUSER') {
                debugger;
                if (event.target.checked == true) {
                    //uncheck all other roles

                    //add customer section input
                    this.setState({
                        isCustomer: true
                    });
                }
                else {
                    this.setState({
                        isCustomer: false
                    });
                }
            }

            if (!this.state.role.includes(event.target.value)) {
                this.setState({
                    role: [...this.state.role, event.target.value]
                });
            }

        }
    */
    onSwitchChange = (event) => {
        //render business field if it is checked
        if (event.target.checked == true) {
            //clean ssn field
            this.setState({
                isBusiness: true,
                ssn: null
            });
        }
        else {
            //not business, then clear value in business field
            this.setState({
                isBusiness: false,
                businessName: null,
                businessTaxId: null
            });
        }
    }

    renderBusinessOrClientField = () => {
        //let labalStyle = { textAlign: 'right', verticalAlign: 'middle', marginTop: 'auto', marginBottom: 'auto' };
        const { businessName, businessTaxId, firstName, lastName, paymentTermId, isSubmit } = this.state

        if (this.state.isBusiness == true) {
            return (
                <Fragment>

                    <Row>
                        <Col md={4}>
                            <label style={{ marginBottom: '3px' }} for="businessName"><span>Business Name <span style={{ color: "red" }}>*</span></span></label>
                            <AutofillManager>
                                <Input
                                    name='businessName'
                                    placeholder='Business Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={businessName}
                                    invalid={isSubmit && !this.validateBusinessName()}
                                />
                            </AutofillManager>
                        </Col>
                        <Col md={4} >
                            <label style={{ marginBottom: '3px' }} for="businessTaxId"><span> Business Tax ID</span></label>
                            <AutofillManager>
                                <Input
                                    name='businessTaxId'
                                    placeholder='Business Tax ID'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={businessTaxId}
                                />
                            </AutofillManager>
                        </Col>
                        <Col md={4} >
                            <label style={{ marginBottom: '3px' }} for="country"><span> Payment Term <span style={{ color: "red" }}>*</span></span></label>
                            <Input
                                type='select'
                                name='paymentTermId'
                                placeholder='Payment Term'
                                onChange={this.onInputChange}
                                className={`form-control ${(isSubmit && !paymentTermId) ? styles['is-invalid'] : ''}`}
                                style={{ fontSize: '14px' }}
                                value={paymentTermId ? paymentTermId : ''}
                                invalid={isSubmit && !this.validatePaymentTermId()}
                            // valid = {this.validatePaymentTermId()}

                            >

                                {/* <option value={''}>{'No Selection'}</option> */}
                                {[{ PaymentTermID: 1, PaymentTermName: "NET0" }, { PaymentTermID: 2, PaymentTermName: "NET15" }, { PaymentTermID: 3, PaymentTermName: "NET30" }].map((option, index) => (
                                    <option key={index} value={option.PaymentTermID}>{option.PaymentTermName}</option>
                                ))}
                            </Input>
                        </Col>
                    </Row>

                    <br />
                    <Row>
                        <Col md={6} >
                            <label style={{ marginBottom: '3px' }} for="firstName"><span> Contact First Name <span style={{ color: "red" }}>*</span></span></label>
                            <AutofillManager>
                                <Input
                                    name='firstName'
                                    id='firstName'
                                    placeholder='First Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={firstName}
                                    invalid={isSubmit && !this.validateFirstName()}
                                />
                            </AutofillManager>
                        </Col>
                        <Col md={6} >
                            <label style={{ marginBottom: '3px' }} for="lastName"><span> Contact Last Name <span style={{ color: "red" }}>*</span></span></label>
                            <AutofillManager>
                                <Input
                                    name='lastName'
                                    id='lastName'
                                    placeholder='Last Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={lastName}
                                    invalid={isSubmit && !this.validateLastName()}
                                />
                            </AutofillManager>
                        </Col>

                    </Row>


                </Fragment>
            );
        }
        else {
            return (
                <Fragment>

                    <Row>
                        <Col md={4} >
                            <label style={{ marginBottom: '3px' }} for="firstName"><span> First Name <span style={{ color: "red" }}>*</span></span></label>
                            <AutofillManager>
                                <Input
                                    name='firstName'
                                    id='firstName'
                                    placeholder='First Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{ fontSize: '14px' }}
                                    value={firstName}
                                    invalid={isSubmit && !this.validateFirstName()}
                                />
                            </AutofillManager>
                        </Col>
                        <Col md={4} >
                            <label style={{ marginBottom: '3px' }} for="lastName"><span> Last Name <span style={{ color: "red" }}>*</span></span></label>
                            <Input
                                name='lastName'
                                id='lastName'
                                placeholder='Last Name'
                                onChange={this.onInputChange}
                                className="form-control"
                                style={{ fontSize: '14px' }}
                                value={lastName}
                                invalid={isSubmit && !this.validateLastName()}
                            />
                        </Col>
                        <Col md={4} >
                            <label style={{ marginBottom: '3px' }} for="country"><span> Payment Term <span style={{ color: "red" }}>*</span></span></label>
                            <Input
                                type='select'
                                name='paymentTermId'
                                placeholder='Payment Term'
                                onChange={this.onInputChange}
                                className="form-control"
                                style={{ fontSize: '14px' }}
                                value={paymentTermId ? paymentTermId : ''}
                            // valid = {this.validatePaymentTermId()}

                            >

                                {/* <option value={''}>{'No Selection'}</option> */}
                                {[{ PaymentTermID: 1, PaymentTermName: "NET0" }, { PaymentTermID: 2, PaymentTermName: "NET15" }, { PaymentTermID: 3, PaymentTermName: "NET30" }].map((option, index) => (
                                    <option key={index} value={option.PaymentTermID}>{option.PaymentTermName}</option>
                                ))}
                            </Input>
                        </Col>

                    </Row>
                    {/* <br /> */}
                    {/* <Row>
                        <Col md={2} >
                            <span>SSN</span>
                        </Col>
                        <Col md={8}>
                            <AutofillManager>
                                <Input
                                    name='ssn'
                                    placeholder='SSN'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    style={{fontSize:'14px'}}
                                    value={this.state.ssn}
                                />
                            </AutofillManager>
                        </Col>
                    </Row> */}

                </Fragment>
            );
        }
    }
    onResellerChange = async (event) => {
        this.props.ClearBusinessUnitRelations();
        this.setState({
            resellerCode: event.target.value,
            countryId: '',
            lobId: '',
            relationId: '',
            countryList: [],
            LOBList: [],
            divisionList: []
        }, () => {
            if (this.state.isAdmin && this.state.resellerCode != '' && this.state.resellerCode != null) {
                this.props.getBusinessUnits("ByCode", {
                    companyCode: this.props.userObj.companyCode,
                    resellerCode: this.state.resellerCode,
                })
            }
        })
    }
    onOperationCountryChange = (event) => {
        this.setState({
            countryId: event.target.value,
            lobId: '',
            relationId: '',
            LOBList: [],
            divisionList: []
        })
    }
    onLobChange = (event) => {
        this.setState({
            lobId: event.target.value,
            relationId: '',
            divisionList: []
        })
    }

    reduceBusinessUnitList = () => {
        if (this.props.BURelationshipList.length > 0 && this.state.resellerCode != '') {
            this.props.BURelationshipList.forEach((relation) => {
                //country
                if (!(this.state.countryList.map((country) => country.name)).includes(relation.country.name)) {
                    this.state.countryList.push(relation.country)
                }
                //LOB
                if (this.state.countryId != '') {
                    if (relation.country.countryId == parseInt(this.state.countryId)) {
                        if (!(this.state.LOBList.map((lineOfBusiness) => lineOfBusiness.name)).includes(relation.lineOfBusiness.name)) {
                            this.state.LOBList.push(relation.lineOfBusiness)
                        }
                    }
                }
                //Division
                if (this.state.countryId != '' && this.state.lobId != '') {
                    if (relation.country.countryId == parseInt(this.state.countryId)) {
                        if (relation.lineOfBusiness.lobId == parseInt(this.state.lobId)) {
                            if (!(this.state.divisionList.map((relation) => relation.division.name)).includes(relation.division.name)) {
                                this.state.divisionList.push(relation);
                            }
                        }
                    }
                }
            })
        }
    }
    render() {
        const { resellerCode, userName, phoneNum, email, addressLine1, addressLine2,
            city, zipCode, country, state, paymentTermId, password, passwordConfirm, isSubmit } = this.state

        this.reduceBusinessUnitList()
        let labalStyle = { textAlign: 'right', verticalAlign: 'middle', marginTop: 'auto', marginBottom: 'auto' };

        const { pageContentList } = this.props

        let textDisplay = [];
        if (pageContentList.includes("Reseller")) {
            textDisplay = ['Reseller'];
        } else {
            textDisplay = ['Client'];
        }

        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showModal}
                toggle={this.props.toggle}
                renderBackdrop={this.renderBackdrop}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onClose}> New Consumer</ModalHeader >
                <ModalBody style={{ fontSize: '14px', paddingBottom: '5px' }} >
                    <Col md={12}>
                        <Row > {
                            (this.state.isAdmin) ?
                                <Col md={6} >
                                    <label style={{ marginBottom: '3px' }} for="resellerCode"><span>{textDisplay[0]} <span style={{ color: "red" }}>*</span></span></label>
                                    <Input
                                        type='select'
                                        name='resellerCode'
                                        id="resellerCode"
                                        placeholder='Reseller'
                                        onChange={this.onResellerChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={this.state.resellerCode}
                                    >
                                        <option value={''}>{'No Selection'}</option>
                                        {this.props.resellerList.map((reseller, index) => (
                                            <option key={index} value={reseller.resellerCode}>{reseller.resellerName}</option>
                                        ))}
                                    </Input>
                                </Col>

                                :
                                null
                        }

                            {
                                (this.state.resellerCode != '' && this.state.resellerCode != null) ?
                                    <Col md={6} >
                                        <label style={{ marginBottom: '3px' }} for="countryId"><span>Operation Country <span style={{ color: "red" }}>*</span></span></label>
                                        <Input
                                            type='select'
                                            name='countryId'
                                            placeholder='Country'
                                            onChange={this.onOperationCountryChange}
                                            className="form-control"
                                            style={{ fontSize: '14px' }}
                                            value={this.state.countryId ? this.state.countryId : ''}
                                        >
                                            {/* <option value={'CA'}>Canada</option>
                                        <option value={'USA'}>United States</option>} */}
                                            <option value={''}>No Selection</option>
                                            {this.state.countryList && this.state.countryList.map((countryItem, index) => (
                                                <option key={index} value={countryItem.countryId}>{countryItem.name}</option>
                                            ))}
                                        </Input>
                                    </Col> :
                                    null}
                        </Row>
                        <br />
                        <Row>
                            {(this.state.countryId != '') ?
                                <Col md={6} >
                                    <label style={{ marginBottom: '3px' }} for="lobId"><span>Line of Business <span style={{ color: "red" }}>*</span></span></label>
                                    <Input
                                        type='select'
                                        name='lobId'
                                        placeholder='Lob'
                                        onChange={this.onLobChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={this.state.lobId ? this.state.lobId : ''}
                                    >
                                        {/* <option value={'CA'}>Canada</option>
                                        <option value={'USA'}>United States</option>} */}
                                        <option value={''}>{'No Selection'}</option>
                                        {this.state.LOBList && this.state.LOBList.map((lobItem, index) => (
                                            <option key={index} value={lobItem.lobId}>{lobItem.name}</option>
                                        ))}
                                    </Input>
                                </Col> :
                                null
                            }

                            {
                                (this.state.lobId != '' && this.state.countryId != '') ?
                                    <Col md={6} >
                                        <label style={{ marginBottom: '3px' }} for="relationId"><span>Department <span style={{ color: "red" }}>*</span></span></label>
                                        <Input
                                            type='select'
                                            name='relationId'
                                            placeholder='Department'
                                            style={{ fontSize: '14px' }}
                                            onChange={this.onInputChange}
                                            className="form-control"
                                            value={this.state.relationId ? this.state.relationId : ''}
                                        >
                                            <option value={''}>{'No Selection'}</option>
                                            {this.state.divisionList && this.state.divisionList.map((divisionItem, index) => (
                                                <option key={index} value={divisionItem.id}>{divisionItem.division.name}</option>
                                            ))}
                                        </Input>
                                    </Col> :
                                    null
                            }
                        </Row>
                        <br />

                        <Row>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="userName"><span> User Name <span style={{ color: "red" }}>*</span></span></label>
                                <AutofillManager>
                                    <Input
                                        name='userName'
                                        placeholder='User Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={userName}
                                        invalid={isSubmit && !this.validateUserName()}
                                    />
                                </AutofillManager>
                            </Col>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} >Is Business? <span style={{ color: "red" }}>*</span></label>
                            </Col>
                            <Col md={6} style={{ marginLeft: "385px", marginTop: "-30px", textAlign: "left" }}>
                                <div className="custom-control custom-switch">
                                    <input
                                        type="checkbox"
                                        checked={this.state.isBusiness}
                                        className="custom-control-input"
                                        id="isClientBusinessSwitch1"
                                        style={{ fontSize: '14px' }}
                                        onChange={this.onSwitchChange} >
                                    </input>
                                    <label className="custom-control-label" style={{ color: "red", fontWeight: "bold" }} for="isClientBusinessSwitch1">Toggle off if client is NOT a business</label>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6}>
                                <label style={{ marginBottom: '3px' }} for="phoneNum"><span> Phone Number <span style={{ color: "red" }}>*</span></span></label>
                                {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                                <AutofillManager>
                                    <Input
                                        name='phoneNum'
                                        placeholder='Phone Number'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={phoneNum}
                                        invalid={isSubmit && !this.validatePhoneNumber()}
                                    />
                                </AutofillManager>
                            </Col>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="email"><span> Email <span style={{ color: "red" }}>*</span></span></label>
                                <AutofillManager>
                                    <Input
                                        name='email'
                                        id='email'
                                        placeholder='Email'
                                        onChange={this.onInputChange}
                                        pattern={'^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={email}
                                        invalid={isSubmit && !this.validateEmail()}
                                    />
                                </AutofillManager>
                            </Col>
                        </Row>
                        <br style={{ display: "none" }} />
                        <Row style={{ display: "none" }}>
                            <Col md={2} >
                                <span>Company</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='companyCode'
                                    id='companyCode'
                                    placeholder='Company Code'
                                    onChange={this.onCompanyCodeChange}
                                    className="form-control"
                                    value={this.state.companyCode ? this.state.companyCode : ''}

                                    disabled={false}
                                >
                                    <option value={''}>{'No Selection'}</option>
                                    {/* {this.props.companyCodeList.map((code, index)=>(
                                            <option key={index} value={code}>{code}</option>
                                        ))} */}
                                </Input>
                            </Col>
                        </Row>
                        <br />

                    </Col>

          <hr style={{ marginTop: "0" }} />
          {this.state.isCustomer == true && (
            <Fragment>
              <Col md={12}>
                {this.renderBusinessOrClientField()}
                <br />
                <Row>
                  <Col md={6}>
                    <label style={{ marginBottom: "3px" }} for="addressLine1">
                      <span>
                        Address Line 1 <span style={{ color: "red" }}>*</span>
                      </span>
                    </label>
                    <AutofillManager>
                      <Input
                        name="addressLine1"
                        id="addressLine1"
                        placeholder="Address Line 1"
                        onChange={this.onInputChange}
                        className="form-control"
                        style={{ fontSize: "14px" }}
                        value={addressLine1}
                        invalid={isSubmit && !this.validateAddressLine1()}
                      />
                    </AutofillManager>
                  </Col>
                  <Col md={6}>
                    <label style={{ marginBottom: "3px" }} for="addressLine2">
                      <span>Address Line 2</span>
                    </label>
                    <AutofillManager>
                      <Input
                        name="addressLine2"
                        id="addressLine2"
                        placeholder="Address Line 2"
                        onChange={this.onInputChange}
                        className="form-control"
                        style={{ fontSize: "14px" }}
                        value={addressLine2}
                      />
                    </AutofillManager>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <label style={{ marginBottom: "3px" }} for="city">
                      <span>
                        {" "}
                        City <span style={{ color: "red" }}>*</span>
                      </span>
                    </label>
                    <AutofillManager>
                      <Input
                        name="city"
                        id="city"
                        placeholder="City"
                        onChange={this.onInputChange}
                        className="form-control"
                        style={{ fontSize: "14px" }}
                        value={city}
                        invalid={isSubmit && !this.validateCity()}
                      />
                    </AutofillManager>
                  </Col>
                  <Col md={6}>
                    <label style={{ marginBottom: "3px" }} for="zipCode">
                      <span>
                        {" "}
                        Zip Code <span style={{ color: "red" }}>*</span>
                      </span>
                    </label>
                    <AutofillManager>
                      <Input
                        name="zipCode"
                        id="zipCode"
                        placeholder="Zip Code"
                        onChange={this.onInputChange}
                        className="form-control"
                        style={{ fontSize: "14px" }}
                        value={zipCode}
                        invalid={isSubmit && !this.validateZipCode()}
                      />
                    </AutofillManager>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <label style={{ marginBottom: "3px" }} for="country">
                      <span>
                        {" "}
                        Country <span style={{ color: "red" }}>*</span>
                      </span>
                    </label>
                    <Input
                      type="select"
                      name="country"
                      placeholder="Country"
                      onChange={this.onCountryChange}
                      className="form-control"
                      style={{ fontSize: "14px" }}
                      value={country}
                      // valid = {this.validateCountry()}
                    >
                      {/* {this.userObj.companyCode &&
                        this.userObj.companyCode !== "TEISA" && (
                          <option value={"USA"}>{"USA"}</option>
                        )} */}
                      {this.userObj.companyCode &&
                        this.props.countryList &&
                        this.props.countryList.map((option, index) => {
                          //   if (option.value != "USA") {
                          return (
                            <option key={index} value={option.value}>
                              {option.label}
                            </option>
                          );
                          //   }
                        })}
                      {/* {this.userObj.companyCode &&
                        this.userObj.companyCode == "TEISA" && (
                          <option>Paraguay</option>
                        )}
                      )} */}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <label style={{ marginBottom: "3px" }} for="country">
                      <span>
                        {" "}
                        {this.userObj.companyCode &&
                        this.userObj.companyCode !== "TEISA"
                          ? "State"
                          : "State/City"}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    </label>
                    <Input
                      type="select"
                      name="state"
                      placeholder="State"
                      onChange={this.onInputChange}
                      className="form-control"
                      style={{ fontSize: "14px" }}
                      value={state}
                      // invalid = {!this.validateState()}
                    >
                      {/* <option value={''}>{'No Selection'}</option> */}
                      {this.props.provinceList &&
                        this.props.provinceList.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </Input>
                  </Col>
                </Row>
                <br />
              </Col>
            </Fragment>
          )}

                    <hr style={{ marginTop: '0' }} />

                    <Col md={12}>
                        <Row>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="password"><span> Password <span style={{ color: "red" }}>*</span></span></label>
                                {/* <span>User Name</span> */}
                                <AutofillManager>
                                    <Input
                                        invalid={isSubmit && !(passwordConfirm && password === passwordConfirm)}
                                        name='password'
                                        id='password'
                                        type='password'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        value={password}
                                    // disabled={this.props.userName !== ''}
                                    />
                                </AutofillManager>
                            </Col>
                            <Col md={6} >
                                <label style={{ marginBottom: '3px' }} for="passwordConfirm"><span> Password Confirmation</span></label>
                                {/* <span>User Name</span> */}
                                <AutofillManager>
                                    <Input
                                        // valid = {this.state.passConfirmed}
                                        // valid = {this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                                        // invalid = {this.state.passConfirmed}
                                        invalid={passwordConfirm && password !== passwordConfirm}
                                        name='passwordConfirm'
                                        id='passwordConfirm'
                                        type='password'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        style={{ fontSize: '14px' }}
                                        //value={this.state.passwordConfirm}
                                        disabled={!password || password == ''}
                                    // disabled = {true}
                                    />
                                </AutofillManager>
                            </Col>
                        </Row>
                        {/* <Row>
                                <UserAccessForm/>
                            </Row> */}
                    </Col>
                    <br />
                    {/* <hr/> */}

                </ModalBody>
                <ModalFooter style={{ padding: '0', borderTop: '0' }}>
                    <Col md={12}>
                        <Row>
                            <Col md={12} className={'text-right'}>
                                {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                                    <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} /*disabled={!this.validateForm()}*/ >
                                        Submit
                                    </Button>}
                                {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                                    <Button outline color='custom' type="button" onClick={this.onSubmit} /*disabled={!this.validateForm()}*/ >
                                        Submit
                                    </Button>}
                                <Button outline color='secondary' type="reset" onClick={this.onClose}>
                                    Close
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </ModalFooter>
                {/* </div> */}
            </Modal>
        )
    }
}

// export default consumerAddModal;

const mapStateToProps = (state) => {
    return state.user;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addConsumer,
        //addUser,
        //getCompanyList,
        //getResellerListByCode,
        getRoles,
        getAllResellers
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerAddModal);