/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody, Button } from 'reactstrap';

import ClientPlanList from './ClientPlanList';
import ClientPlanModal from './ClientPlanModal';
import PlusIcon from 'mdi-react/PlusIcon';
import { getCurrentUser } from '../../../factories/auth';
//import {CSVLink} from "react-csv";

import { PowerIcon, ThumbDownIcon } from 'mdi-react';
import { CSVLink } from "react-csv";

class ClientPlanBoard extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser()
        this.state = {
            selectedPlan: null,
            show: false,
            carrierPlanList: [],
            usageCsvData: [],
            usageCsvHeaders: [
                //{ label: "Plan Name", key: "itemName" },
                //{ label: "Carrier Name", key: "carrier" },
                //{ label: "Unit Price/mo", key: "unitPrice"},
                //{ label: "Data Volume", key: "planDataVolume"},
                //{ label: "Description", key:"extensions"}
            ]

        }
    }
    componentDidMount() {
        var csvdata = [];
        var labels = []
        if (this.props.isAdmin === true) {
            labels = [
                { label: "Plan Name", key: "itemName" },
                { label: "Carrier Name", key: "carrier" },
                { label: "Unit Price/mo", key: "unitPrice" },
                { label: "Data Volume", key: "planDataVolume" },
                { label: "Description", key: "extensions" }
            ]
        } else {
            labels = [
                { label: "Plan Name", key: "itemName" },
                { label: "Unit Price/mo", key: "unitPrice" },
                { label: "Data Volume", key: "planDataVolume" },
                { label: "Description", key: "extensions" }
            ]
        }
        this.setState({ usageCsvHeaders: labels });
        for (let i = 0; i < this.props.ServicePlanList.length; i++) {
            csvdata.push({
                itemName: this.props.ServicePlanList[i].itemName,
                carrier: (this.props.ServicePlanList[i].carrier) ? this.props.ServicePlanList[i].carrier.carrierName : null,
                unitPrice: this.props.ServicePlanList[i].unitPrice,
                planDataVolume: this.props.ServicePlanList[i].planDataVolume,
                extensions: this.props.ServicePlanList[i].extensions.map(e => { return e.type + ' ,' + e.description + ' ,$' + e.price }).join('\n')

            })
        }

        this.setState({
            usageCsvData: csvdata
        })
    }

    onPlanOpen = (plan) => {
        this.setState({
            selectedPlan: plan,
            show: true
        })
    }
    onGeneralOpen = () => {
        this.setState({
            show: true
        })
    }
    onClose = () => {
        this.setState({
            selectedPlan: null,
            show: false
        })
    }

    render() {
        return (
            <Card>
                <CardBody className="mainCardBody">
                    <ClientPlanModal
                        carrierPlanList={this.props.carrierPlanList}
                        selectedPlan={this.state.selectedPlan}
                        selectedRow={this.props.selectedRow}
                        show={this.state.show}
                        SetServicePlan={this.props.SetServicePlan}
                        DeleteServicePlan={this.props.DeleteServicePlan}
                        getServicePlanItem={this.props.getServicePlanItem}
                        carrierList={this.props.carrierList}
                        getCarrierPlans={this.props.getCarrierPlans}
                        pageContentList={this.props.pageContentList}
                        onClose={this.onClose}
                    />
                    {(this.props.isAdmin) ? <Button color="custom" onClick={this.onGeneralOpen}> Add Plan </Button> : null}

                    <CSVLink
                        class="btn btn-success react-bs-table-csv-btn  hidden-print"
                        data={this.state.usageCsvData}
                        headers={this.state.usageCsvHeaders}
                        filename={(this.userObj.companyCode && this.userObj.companyCode == "TEISA") ? 'Reseller_Plan_List.csv' : 'Client_Plan_List.csv'}>
                        <i
                            style={{ marginRight: "5px" }}
                            class="fa glyphicon glyphicon-export fa-download"
                        />
                        Export to CSV
                    </CSVLink>
                    <br />
                    <ClientPlanList
                        ServicePlanList={this.props.ServicePlanList}
                        isAdmin={this.props.isAdmin}
                        onPlanOpen={this.onPlanOpen}
                    />
                </CardBody>
            </Card>
        )
    }

}
export default ClientPlanBoard;