/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Card, CardBody, Col, Row, Button, Container } from 'reactstrap';
import { Trans } from "react-i18next";
// import { connect } from 'react-redux';
// import { withRouter } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
// import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import PlusIcon from 'mdi-react/PlusIcon';
import { EditTextButton, DeleteTextButton, ManageTextButton } from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { sortCaret } from '../../../shared/components/Table/tableElements';



class CompanyList extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        // this.props.getCompanyList();
    }

    handleRowEditClick = (row) => {
        this.props.handleRowEditClick(row);
    }

    handleRowDeleteClick = (row) => {
        // let code = row.companyCode; 
        // let selectedCompany = this.props.companyList.find((element,index) => (element.companyCode === code));
        // if (selectedCompany) {
        //     this.props.deleteCompany(selectedCompany);
        // }
        this.props.handleRowDeleteClick(row);
    }

    tableActionColFormater = (cell, row) => {
        const check1 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']);
        const check2 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        // const check2 = this.props.userObj && this.props.userObj.userName === row.userName;
        // const disabled = !(check1 || check2);
        return (
            <div>
                {check1 &&
                    <Fragment>
                        <ManageTextButton label={''} onClick={(e) => { this.handleRowEditClick(row); }} />{' '}
                        {/* <DeleteTextButton label={"Remove"} onClick={(e) => { this.handleRowDeleteClick(row); }}/> */}
                        {/* {check2 &&
                            <ConfirmDeleteDialog
                                label={"Delete"}
                                onConfirm={(e) => { this.handleRowDeleteClick(row); }}
                                msg={'Do you want to delete the selected company?'}
                            />
                        } */}
                    </Fragment>
                }
            </div>
        )
    }

    tableDeleteColFormater = (cell, row) => {
        const check1 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']);
        const check2 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        // const check2 = this.props.userObj && this.props.userObj.userName === row.userName;
        // const disabled = !(check1 || check2);
        return (
            <div>
                {check1 &&
                    <Fragment>
                        {/* <ManageTextButton label={''} onClick={(e) => { this.handleRowEditClick(row); }} />{' '} */}
                        {/* <DeleteTextButton label={"Remove"} onClick={(e) => { this.handleRowDeleteClick(row); }}/> */}
                        {check2 &&
                            <ConfirmDeleteDialog
                                // label={"Delete"}
                                onConfirm={(e) => { this.handleRowDeleteClick(row); }}
                                msg={'Do you want to delete the selected company?'}
                            />
                        }
                    </Fragment>
                }
            </div>
        )
    }

    tableAddressFormater = (cell, row) => {

        return (
            <p>
                {row.addressline1} <br />
                {row.addressline2 ? row.addressline2 : ''}{' '}
                {row.city ? row.city : ''}{' '}
                {row.province ? row.province : ''}{' '}
                {row.postcode ? row.postcode : ''}{' '}
                {row.country ? row.country : ''}
            </p>
        )
    }
    phoneFormatter = (cell, row) => {
        return (`(${cell ? cell.slice(0, 3) : '---'})-${cell ? cell.slice(3, 6) : '---'}-${cell ? cell.slice(6, 10) : "----"} ${(row.phoneExt) ? `Ext.${row.phoneExt}` : ""}`)
    }

    companyNameFormater = (cell, row) => {
        return (
            // <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleRowEditClick(row, true);}}>{cell}</a>
            <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleRowEditClick(row); }}>{cell}</a>
        );
    }

    handleAddNewButtonClick = (onClick) => {
        this.props.handleAddNewClick();

    }

    addNewUserButton = (onClick) => {
        const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        return (

            <InsertButton
                //   btnText='Add Company'
                btnContextual='btn-success'
                btnGlyphicon='glyphicon-edit'
                //   color='success'
                onClick={() => this.handleAddNewButtonClick(onClick)}
            //   disabled = {!check}
            >
                {/* <PlusCircleOutlineIcon size={100}/> */}
                {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
                <PlusIcon /> Add Company
            </InsertButton>
        );
    }

    renderCompanyList = () => {
        const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        const options = {
            insertBtn: this.addNewUserButton,
        };
        return (
            <BootstrapTable
                data={this.props.companyList}
                tableHeaderClass='header_white'
                pagination
                options={options}
                exportCSV
                csvFileName='Company_List.csv'

                insertRow={check}
                bordered={false}
                height='660'
                bodyStyle={{ overflowY: 'auto', overflowX: "hidden" }}
                striped
            >
                <TableHeaderColumn dataField='companyCode' isKey={true} dataAlign="center" hidden={this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']) ? false : true} dataSort caretRender={sortCaret} csvHeader='Company Code'>Company Code</TableHeaderColumn>
                <TableHeaderColumn dataField='companyName' dataAlign="center" dataSort caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Name'>Name</TableHeaderColumn>
                <TableHeaderColumn dataField='phoneNum' dataAlign="center" dataSort caretRender={sortCaret} dataFormat={this.phoneFormatter} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Phone'>Phone</TableHeaderColumn>
                <TableHeaderColumn dataField='email' dataAlign="center" dataSort caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Email'>Email</TableHeaderColumn>
                <TableHeaderColumn
                    dataField='action'
                    dataAlign="center"
                    width="10%"
                    dataFormat={this.tableActionColFormater}
                    hidden={(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN'])) ? false : true}
                    export={false}
                >
                    Manage
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField='action'
                    dataAlign="center"
                    width="10%"
                    dataFormat={this.tableDeleteColFormater}
                    hidden={(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN'])) ? false : true}
                    export={false}
                >
                    Delete
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }

    render() {
        return (
            <Col md={12}>
                <Card>
                    <CardBody>
                        {this.renderCompanyList()}
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default CompanyList
