/* eslint-disable */
import axios from 'axios';
import {
    API_ADDR,
    API_ROOT_URL,
    SEARCH_USER,
    SEARCH_USER_STARTED,
    SEARCH_USER_SUCCEED,
    SEARCH_USER_FAILED,
    SUCCESS_MSG,
} from '../../../constants/actionTypes';

function searchUsersStarted() {
    return {
        type: SEARCH_USER_STARTED
    }
}

function searchUsersSucceed(res) {
    if (res.data.message === SUCCESS_MSG) {
        return {
            type: SEARCH_USER_SUCCEED,
            payload: res
        }
    }
    else {
        return {
            type: SEARCH_USER_FAILED,
            payload: res
        }
    }
}

function searchUsersFailed(err) {
    return {
        type: SEARCH_USER_FAILED,
        payload: err
    }
}

// export function searchUsers(criteria) {
//     const url = `${API_ADDR}${API_ROOT_URL}${SEARCH_USER}`;
//     const promise = axios({
//         url: url,
//         method: 'get',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         params: {
//             token: sessionStorage.getItem('authToken'),
//             email: criteria.email !== ''? criteria.email: 'null',
//             fullName: criteria.fullName !== ''? criteria.fullName: 'null',
//             userName: criteria.userName !== ''? criteria.userName: 'null'
//         }
//     });

//     return function (dispatch) {
//         dispatch(searchUsersStarted());
//         promise.then(
//             res => {
//                 dispatch(searchUsersSucceed(res));
//             }
//         ).catch(
//             err => {
//                 dispatch(searchUsersFailed(err));
//             }
//         );
//     }
// }

export function searchUsers(criteria) {
    let mokeData = [
        {
            user_id: "5c75be83e833d96a670ab4fb",
            user_name: "Kent",
            email: "kent.rawlings@sigfoxcanada.com",
            full_name: "Kent Rawlings",
            last_login: 0,
            creation_time: 1551220532842,
            time_zone: "America/Toronto",
        },
        {
            user_id: "5cab66462564322b9a5e53a5",
            user_name: "Matt",
            email: "matt.davis@sigfoxcanada.com",
            full_name: "Matt Davis",
            last_login: 1568647582014,
            creation_time: 1554736925199,
            time_zone: "America/Toronto",
        },
        {
            user_id: "5d026d93e833d924bddebd31",
            user_name: "david",
            email: "david.bazargan@fonturinternational.com",
            full_name: "david Bazargan",
            last_login: 1560440389648,
            creation_time: 1560440344536,
            time_zone: "UTC",
        },
        
        {
            user_id: "5d1a161b0499f57e0ec0428a",
            user_name: "Manuel",
            email: "manuel.vera@sigfoxcanada.com",
            full_name: "Manuel Vera",
            last_login: 1568217499492,
            creation_time: 1561990771018,
            time_zone: "America/Toronto",
        },
    ];

    let email = criteria.email !== ''? criteria.email: 'null';
    let fullName = criteria.fullName !== ''? criteria.fullName: 'null';
    let userName = criteria.userName !== ''? criteria.userName: 'null';
    const filteredData = mokeData.filter((item) =>{
        return(
            ('null' == email || email == item.email) &&
            ('null' == fullName || fullName == item.full_name) &&
            ('null' == userName || userName == item.user_name) 
        );
    });

    console.log('fileterData')
     
     
     
     

    return((dispatch) => {dispatch(
        {
            type: SEARCH_USER_SUCCEED,
            payload: filteredData
        }
    )});
}