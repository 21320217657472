/*eslint-disable*/
import React, { Component } from "react";
import  Card from "react-credit-cards";

import {Row, Col, Card as Card2, CardBody,  CardGroup, Label, Input, Button, Table,Collapse} from 'reactstrap';

import { toast } from "react-toastify";
import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';
import "./ConfirmPay.css";

class ConfirmPay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false
    };     
  }

payOrder = () => {

    //get peed account id or token, amount to charge
    if (this.props.checkOutResult && this.props.checkOutResult.orderData){
        let simActivationList = [];
        if(this.props.mapSimToPlan!=null){
          for (var key in this.props.mapSimToPlan) {
              let tempPlan = this.props.mapSimToPlan[key];
              if (tempPlan != null) {
                  
                  if(tempPlan.selectedSim!=null&&tempPlan.selectedSim.length>0){
                    tempPlan.selectedSim.forEach((item, index) => {
                      let activationObj = {
                        itemPlanId:tempPlan.itemId,
                        inventoryId:item.inventoryId,
                        deviceName: item.deviceName
                      }
                      simActivationList.push(activationObj);
                    });
                    
                  }
                  
              }
          }
        }
        let param = {
            id :  this.props.checkOutResult.orderData.id,
            amount: this.props.checkOutResult.orderData.ordrAmt,
            //tokenCode: this.props.editUserObj.customer.tokenCode,
            //accountNumber: this.props.account[0].accountId,
            accountNumber: null,
            //user token code
            ordrId:this.props.checkOutResult.orderData.id,
            isbatchRequest:"N",
            paymentOption:this.props.payByOption,
            paidOff:this.props.isPaidOff,
            comment:this.props.comment,
            //get activation list from 
            simActivationList:simActivationList,
            orderData: this.props.checkOutResult.orderData
        };
        this.props.handleConfirmPay(param);
    }
    else{
        toast.error('No Item selected');
    }
}

priceFormater = (cell, row) => {
  let price = 0;
  if(cell>=0){
    price = parseFloat(cell).toFixed(2);
  }
  return (
      <span>{`$${price}`}</span>
  )
}

viewMoreTaxDetail = (val)=>{
  this.setState({
    collapseOpen:val
  });
}

handleOnBack = () => {
  this.props.onBack();
}
  render() {   
    let checkOutOrder=this.props.checkOutResult;
    
    const customer = this.props.editUserObj==null?"":this.props.editUserObj.customer;
    if(customer==null){
      return (<span></span>);
    }
    
   
    const ac = this.props.selectedPaymentAccount;
    //const ac = {"cardMask":"test"};

 
    let itemDataList = [];
    let TotalTax = '';
    let SubTotal='';
    let Total='';
    let totalItemsQty = 0;
    let totalDiscountAmount = "";
    let totalBeforeDiscount = 0;
    let telecomTax ="";
    let salesTax="";
    let taxItemlist=[];
    let orderList = [];
    let billingToAddress = {
      addressLine1:"",
      addressLine2:"",
      country:"",
      state:"",
      city:"",
      zipCode:""
    };
    if (checkOutOrder){
        
        itemDataList = checkOutOrder.orderData!=null?checkOutOrder.orderData.orderItemDataList:[];
        if (checkOutOrder){
            SubTotal = isNaN(checkOutOrder.orderData.ordrTtl) ? "" : Number(checkOutOrder.orderData.ordrTtl).toFixed(2);
            TotalTax = isNaN(checkOutOrder.orderData.ordrTtlTax) ? "" : Number(checkOutOrder.orderData.ordrTtlTax).toFixed(2);
            Total = isNaN(checkOutOrder.orderData.ordrAmt) ? "" : Number(checkOutOrder.orderData.ordrAmt).toFixed(2);
            totalDiscountAmount = isNaN(checkOutOrder.orderData.discountAmt) ? "0" : Number(checkOutOrder.orderData.discountAmt).toFixed(2);
            telecomTax = isNaN(checkOutOrder.orderData.telecomTax) ? "0" : Number(checkOutOrder.orderData.telecomTax).toFixed(2);
            salesTax = isNaN(checkOutOrder.orderData.salesTax) ? "0" : Number(checkOutOrder.orderData.salesTax).toFixed(2);
            totalBeforeDiscount = isNaN(checkOutOrder.orderData.totalBeforeDiscount) ? "0" : Number(checkOutOrder.orderData.totalBeforeDiscount).toFixed(2);
        }
        if(itemDataList!=null){
          //itemQty
          itemDataList.forEach((item,index)=>{
            totalItemsQty = totalItemsQty + item.itemQty;
          });
        }
        if(checkOutOrder.taxJSONResult!=null&&checkOutOrder.taxJSONResult!=""){
          taxItemlist = JSON.parse(checkOutOrder.taxJSONResult).GroupList;
        }

        if(checkOutOrder.orderData.billingToAddress!=undefined&&checkOutOrder.orderData.billingToAddress!=null){
          billingToAddress = checkOutOrder.orderData.billingToAddress;
        }
        
    }else{
        //toast.error('Failed to get order data, please try again')
    }

    if(itemDataList!=null){
      itemDataList.forEach((item, index) => {
        //check for device
        if(item.itemData.itemTypeId!=1){
          orderList.push(
            {
              sku: item.itemData.itemName,
              description: item.itemData.itemDescription,
              deviceId: item.simIdentifierValue,
              qty:item.itemQty,
              discountAmt:item.promoAmt,
              unitPrice:item.itemPrice,
              total:item.costAmt
            }

          );
          
        }
        else if(item.itemData.itemTypeId==1){  //if it is a plan
          orderList.push({
            sku: item.itemData.itemName,
            description: item.itemData.itemDescription,
            deviceId: "",
            qty:item.itemQty,
            discountAmt:item.promoAmt,
            unitPrice:item.itemPrice,
            total:item.costAmt
          });
        }
        
      });
        
    }
  
    let paymentAccountDisplay = "";
    if(this.props.payByOption&&(this.props.payByOption=="ACH"||this.props.payByOption=="CHK")){
      let payOptionText = "";
      if(this.props.payByOption=="ACH"){
        payOptionText = "External ACH";
      }
      else{
        payOptionText = "Check";
      }
      let paidOffText = "";
      if(this.props.isPaidOff==true){
        paidOffText = "Paid off via";
      }
      else{
        paidOffText = "Pay by";
      }
      paymentAccountDisplay = (<div>
        <div class="row">
          <div class="col-md-4 mb-1">
            <Card 
              number={"************"}
              customCardsSupport={true}
              customCardsStyles={{'width': '50px', 'height': '30px'}}
            />
          </div>
          <div class="col-md-5 mb-1">                
            <label>{paidOffText}{" "}{payOptionText}</label>
          </div>
        </div>
        </div>);
    }
    else{
      paymentAccountDisplay = (ac!=null ?  (<div>
        <div class="row">
          <div class="col-md-4 mb-1">
            <Card 
              number={ac.cardMask}
              customCardsSupport={true}
              customCardsStyles={{'width': '50px', 'height': '30px'}}
            />
          </div>
          <div class="col-md-5 mb-1">                
            <label>{ac.cardMask}</label>
          </div>
        </div>
        </div>
        ):"");
    }
    
    return(
    <div className="App-cards-list">
      <Row>
          <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <BackButton
                  onClick={this.handleOnBack}
                  label="Back"
                  size={30}
                  color='black'
              />
          </Col>
          <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          </Col>
          <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              
          </Col>
      </Row>
      <div class="container deviceCheckouCartPage">
      
      <div class="row">
        <div class="col-md-4 order-md-2 mb-4">
          <h4 class="d-flex justify-content-between align-items-center mb-3">
            <span class="text-muted">Cart Summary</span>
            <span class="badge badge-secondary badge-pill">{totalItemsQty}</span>
          </h4>
          <ul class="list-group mb-3">
            <li class="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 class="my-0">Items</h6>
                {/* <small class="text-muted">IOT - Basic</small> */}
              </div>
              {/* <span class="text-muted">{price}</span> */}
              <span class="text-muted">${totalBeforeDiscount}</span>
            </li>
      
            
            <li class="list-group-item d-flex justify-content-between bg-light">
              <div class="text-success">
                <h6 class="my-0">Discount Amt</h6>
                
              </div>
              <span class="text-success">-${totalDiscountAmount}</span>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Telecom Tax</span>
              <strong>${telecomTax}</strong>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Sales Tax</span>
              <strong>${salesTax}</strong>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Total Tax</span>
              <strong>${TotalTax}</strong>
            </li>
            <li class="list-group-item d-flex justify-content-between" style={{borderTop: "2px solid rgba(0, 0, 0, 0.125)"}}>
              <span><strong>Total</strong></span>
              <strong>${Total}</strong>
            </li>
          </ul>
          <ul class="list-group mb-3">
            <li class="list-group-item d-flex justify-content-between lh-condensed">
            <Row>
              <Col md={12} style={{paddingLeft:"0px"}}>
                  <Button
                  onClick={() => this.viewMoreTaxDetail(!this.state.collapseOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}

                >View Tax Detail</Button>
                <br/>
                <Collapse isOpen={this.state.collapseOpen}>
                <div id="example-collapse-text">
                <ul class="list-group mb-3">
                    {taxItemlist.map((taxItem,index)=>{
                    
                      return (
                        <li class="list-group-item d-flex justify-content-between" style={{marginLeft:"0px",marginRight:"0px"}}>
                         
                          <Row>
                            <Col md={12} style={{marginLeft:"0px",marginRight:"0px",paddingLeft:"0px"}}>
                              <span><strong>Line Item #: {taxItem.LineNumber}</strong></span><br/>
                              {taxItem.TaxList.map((taxListItem,taxIndex)=>{
                                return(
                                  
                                <div>
                                  <span>TaxTypeCode: {taxListItem.TaxTypeCode}</span><br/>
                                  <span>TaxTypeDesc: {taxListItem.TaxTypeDesc}</span><br/>
                                  <span>TaxAmount: ${taxListItem.TaxAmount}</span><br/>
                                  <span>Revenue: ${taxListItem.Revenue}</span><br/>
                                  <span>TaxRate: {taxListItem.TaxRate}</span><br/>
                                  <span>PercentTaxable: {taxListItem.PercentTaxable}</span><br/>
                                  <span>FeeRate: {taxListItem.FeeRate}</span><br/>
                                  <span>RevenueBase: ${taxListItem.RevenueBase}</span><br/>
                                  <span>TaxOnTax: ${taxListItem.TaxOnTax}</span><br/>
                                </div>
                                );
                              })}
                            </Col>
                          </Row>
                          
                         
                        </li>
                      )}
                    )}
                    
                </ul>
                </div>
              </Collapse>
              </Col>
            </Row>
            
            </li>
          </ul>

          
        </div>
        <div class="col-md-8 order-md-1" style={{boxShadow:"rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"}}>
          {/* <Card2 >
          <CardBody> */}
          <div class="row mt-3" style={{borderBottom:"1px solid rgba(0, 0, 0, 0.1)"}}>       
            <div class="col-md-6">
              <h4 class="mb-3">Billing Address</h4>
              <div class="mb-1">
                {billingToAddress.addressLine1 && <div><label>{billingToAddress.addressLine1}</label><br/></div>}
                {billingToAddress.addressLine2 && <div><label>{billingToAddress.addressLine2}</label><br/></div>}
                <label>{billingToAddress.city + " " + billingToAddress.state + " " + billingToAddress.country + " " + billingToAddress.zipCode}</label>
              </div>
            </div>

            <div class="col-md-6">
              <h4 class="mb-3">Shipping Address</h4>
              <div class="mb-1">
                {billingToAddress.addressLine1 && <div><label>{billingToAddress.addressLine1}</label><br/></div>}
                {billingToAddress.addressLine2 && <div><label>{billingToAddress.addressLine2}</label><br/></div>}
                <label>{billingToAddress.city + " " + billingToAddress.state + " " + billingToAddress.country + " " + billingToAddress.zipCode}</label>
              </div>
            </div>

          </div>
          <div class="row mt-3" style={{borderBottom:"1px solid rgba(0, 0, 0, 0.1)"}}>       
            <div class="col-md-3">
            <h4 class="mb-3">Payment Account</h4> 
            </div>

            <div class="col-md-9">
              
              {paymentAccountDisplay}
              </div> 
          </div>
              {/* </CardBody>
              </Card2> */}
        
              <div class="row mt-4 mb-5">
              <h4 class="mb-3">Order Summary</h4>
                <BootstrapTable 
                      data={ orderList }  
                      // tableHeaderClass = 'header_white' 
                      pagination
                        
                        
                      bordered={ false }
                      ref='table' 
                      containerStyle = {{fontSize: "1vw"}}
                  >
                  <TableHeaderColumn dataField='sku' isKey={ true } dataAlign="center" dataSort >SKU</TableHeaderColumn>
                  <TableHeaderColumn dataField='deviceId'  dataAlign="center" dataSort >Device ID</TableHeaderColumn>                 
                  {/*<TableHeaderColumn dataField='qty' dataAlign="center" dataSort width='9%' >QTY</TableHeaderColumn>*/}
                  <TableHeaderColumn dataField='unitPrice' dataFormat={this.priceFormater} dataAlign="center" dataSort width='20%' >Unit Price</TableHeaderColumn>
                  <TableHeaderColumn dataField='total' dataFormat={this.priceFormater} dataAlign="center" dataSort width='15%' >Total</TableHeaderColumn>
                                          
              </BootstrapTable>
              </div>
              <br/>
              <button 
                  class="btn btn-primary btn-lg btn-block" 
                  type="submit" 
                  onClick={() => {
                      //this.orderNow()
                      this.payOrder() 
                  }}
              >
                  Confirm and Pay
              </button>
        </div>
      </div>

    </div> 
    </div>
    );
  }
}


export default ConfirmPay;

