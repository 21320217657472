/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Button, Table, Input} from 'reactstrap';
import classnames from 'classnames';

class MapInfoWindow extends Component {
    constructor(props){
        super(props);
        this.state={
            activeTab: '1',
        }
    }

    toggle = (tab) => {
        alert('y');
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    renderInfoWin = (msg)=>{
         
            console.log(msg)
        if (msg && msg.length>0 && msg[0].msg){
            let msgObj =  msg[0].msg;
            // let time = new Date(msg[0].timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' });
            let time = new Date(msg[0].timestamp).toLocaleString('en-US', { timeZone: 'UTC' });
            return (
                <Table>
                    <caption style={{captionSide:"top"}}>{time}</caption>
                    <tbody> 
                        {Object.keys(msgObj).map((key,i)=>(
                            // key != 'Lat' && key !='Lng' &&    
                            msgObj[key].display &&                    
                            <tr>
                                <td>{msgObj[key].displayname}</td>
                                <td>{msgObj[key].value}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )
        }
        else{
             
            return <p>no data display</p>;
        }
    }

    render(){
        const numInRow = 3;
        return(
            <div>
                {/* <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { alert('te'); this.toggle('1'); }}
                        >
                            Tab111111111111111111111111111111111
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            Tab2222222222222222
                        </NavLink>
                    </NavItem>
                </Nav> */}
                <Row>
                {this.props.device && this.props.device.map((item, index)=>{
                     
                    return(
                        
                        <Col md={12}>
                            <div style={{width:'100%'}}>{item.devicename}</div>
                            {this.renderInfoWin(item.msgs)}
                        </Col> 
                    
                    )                    
                })}
                </Row>
            </div>
        )
    }
}

export default MapInfoWindow;