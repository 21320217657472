import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ActionList from './components/ActionList';

const ActionNotificationPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Notification Setup</h3>
        <hr />
      </Col>
    </Row>
    <Row>
      <ActionList />
    </Row>
  </Container>
);

export default ActionNotificationPage;
