/* eslint-disable */

import {
    REPORT_LOAD_ALL_REPORTS_SUCCESS,
    REPORT_LOAD_ALL_REPORTS_FAILED, 
    REPORT_GET_ONE_REPORT_SUCCESS,
    REPORT_GET_ONE_REPORT_FAILED ,
    REPORT_ADD_NEW_REPORT,
    REPORT_UPDATE_EXISTING_REPORT
} from '../../constants/actionTypes'

const initState = {    
    reportList: [],
    currentReport:{},
    currentGeneralSection:{},
    currentSectionList:[],
    currentReportId: null,
    error: 0,
    message: '',
    type: '',
}

export default function(state = initState, action){
    //  
    //  
    //  
    switch(action.type){
        case REPORT_LOAD_ALL_REPORTS_SUCCESS: 
            return{
                ...state,
                error: 0,
                reportList: action.payload,
                message: 'SUCCESSFULLY LOAD REPORTS LIST',
                type: action.type,
            }
        case REPORT_GET_ONE_REPORT_SUCCESS:
            let reportObj = action.payload;
             
             
            let sections = getReportGeneralPart(reportObj)
            let id = null;
            if (sections.general.reportid && sections.general.reportid > 0){
                id = sections.general.reportid;
            }
            return{
                ...state,
                error: 0,
                currentReport: reportObj,
                currentGeneralSection: sections.general,
                currentSectionList: sections.sectionList,
                currentReportId: id,
                message: 'SUCCESSFULLY LOAD ONE REPORT',
                type: action.type,
            }
        case REPORT_LOAD_ALL_REPORTS_FAILED:
        case REPORT_GET_ONE_REPORT_FAILED:
            return{
                ...state,
                error: 1,
                message: action.error,
                type: action.type,
            }
        default: 
            return {...state}
    }
}

function getReportGeneralPart(reportObj){
     
     
    if (!reportObj) {
        return {
            general:{},
            sectionList: [],
        };
    }
    let {sectionList, ...rest} = reportObj;
    return {
        general: {...rest},
        sectionList: JSON.parse(JSON.stringify(sectionList)),
    }
}