/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Col, Row, Card, CardBody, Label, Button, Input, FormGroup, Collapse } from 'reactstrap';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import {BackButton} from '../../../shared/components/Button/IconButton'; 
// import {
//     getAssetRecursiveData,
//     clearAssetRecursiveData,
//     removeRecursiveLeve,
// } from '../../../redux/actions/recursiveAction';
import {
    loadAssetClass,
    getAssetsData,
    getGroupAssetData, 
    getGroupDeviceData,
    clearGroupAssetData,
} from '../../../redux/actions/mapActions';
import {
    // getAssetsData, 
    // loadAssetList
    updatePubSubDevice,
    // getAttributeThreshold,
} from '../../../redux/actions/dashboardActions';
import {getAssetRecursiveData, clearAssetRecursiveData} from '../../../redux/actions/recursiveActions'; 
import {LevelColors} from '../../../constants/dataConstants';
import { groupBy, getLevelColor } from '../../../factories/utils';
import DataList from './DataList';
import SchemaChart from './SchemaChart';
// import FilterCard from '../components/FilterCard';
// import LevelCard from '../components/LevelCard';
import {WEB_SUB_CHANNEL, WEB_SUB_EVENT} from '../../../constants/appConstants';
import {getCurrentUser} from "../../../factories/auth";

class SchemaBoard extends Component {
    constructor(props){
        super(props);
        this.state = {
            // selectedAssetId : 220,
            showDataList: false,
        }
        // this.eventSource = new EventSource("http://192.168.2.103:8080/sendRedisMessageTest/get");
        this.eventSource = new EventSource(WEB_SUB_CHANNEL);
        this.userObj = getCurrentUser();
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color:  "black",
                '&:active': {backgroundColor: this.userObj.detail.appButtonColorHover},
                backgroundColor:  state.isFocused ? this.userObj.detail.appButtonColorHover: "white",
            }),
            control: (base,selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': {borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor}
            }),
            indicatorsContainer:  (base,selectState)  => ({
                ...base,
                padding: "0",
            }),
        }
    }
    componentDidMount(){
        this.props.loadAssetClass();
        this.loadAssetData();
        this.loadDeviceData();

        this.props.getAssetsData({assetclassid: 142, latest: 1});
        // this.loadInterval2 = setInterval(() => {
        //       
        //     this.props.getAssetsData({assetclassid: 142, latest: 1});
        // }, 10000);
        // this.eventSource.addEventListener("devicemessage", (e)=> {
        this.eventSource.addEventListener(WEB_SUB_EVENT, (e)=> {
                //  
                //  
                this.props.updatePubSubDevice(e.data);
                this.setState({updatedData: e.data});
            } 
        );
    }

    componentWillReceiveProps(nextProps){
        //  
        // if (nextProps.assetClass){
        //      
        //      
        // }
        // if (nextProps.pubsubDevice) {
        //     this.setState({updatedData: nextProps.pubsubDevice});
        // }
    }

    // componentWillUnmount(){
    //      
    //     // if (this.interval){
    //     //     clearInterval(this.interval);
    //     // }
    //     this.props.clearGroupAssetData();
    // }
    componentWillUnmount(){
        // 
        if (this.loadInterval){
            clearInterval(this.loadInterval);
        }
        if (this.loadInterval2){
            clearInterval(this.loadInterval2);
        }
        if (this.eventSource){
            this.eventSource.close();
        }
        this.props.clearGroupAssetData();
        this.props.clearAssetRecursiveData();
    }
    // ------------------------------------------------------
    loadAssetData = () => {
        let params = {themeid:"", starttime: "", endtime: ""};
        let entitygroup = [];
        let entity = '';
        if (this.state.selectedAssetId){            
            entity = this.state.selectedAssetId;             
            params = {...params, entitygroup, entity, latest: "1"}; 
            this.props.getGroupAssetData(params);
        }
    }

    loadDeviceData = () => {
        let params = {themeid:"", starttime: "", endtime: ""};
        let entitygroup = [];
        let entity = '';
        if(this.state.selectedAssetId){
            entity = this.state.selectedAssetId;             
            params = {...params, entitygroup, entity, latest: "1"}; 
            this.props.getGroupDeviceData(params);
        }

    }

    handleSelectionChange = (e) => {
         
        if (this.loadInterval){
            clearInterval(this.loadInterval);
        }
        let selected =  e.data;
        this.setState({selectedAssetId: selected.assetid, showDataList: true,}, ()=>{
            this.loadAssetData();
            this.loadDeviceData();
            // this.loadInterval = setInterval(() => {
            //       
            //     this.loadAssetData();
            // }, 10000);
        })
    }
    // ------------------------------------------------------
    renderAssetData = () => {
        let assetObj = null;
        if (this.props.assetGroupData && this.props.assetGroupData.length > 0){
            let assetclassObj = this.props.assetGroupData[0];
            if (assetclassObj.asset && assetclassObj.asset.length >0 ){
                assetObj = assetclassObj.asset[0];
                 
                // return (
                //     <DataList 
                //         assetObj={assetObj}  
                //         updatedData={this.state.updatedData}
                //         loading = {this.props.assetLoading}
                //         backOnClick = {this.backOnClick}
                //     />
                // );
            }else{
                // return <span>No data display...</span>;
                // return (
                //     <DataList 
                //         assetObj={null}  
                //         updatedData={null}
                //         loading = {this.props.assetLoading}
                //         backOnClick = {this.backOnClick}
                //     />
                // );
            }
        }else{
            // return  <span>No data display...</span>;
            // return (
            //     <DataList 
            //         assetObj={null}  
            //         updatedData={null}
            //         loading = {this.props.assetLoading}
            //         backOnClick = {this.backOnClick}
            //     />
            // );
        }
        return (
            <DataList 
                assetObj={assetObj}  
                updatedData={this.state.updatedData}
                loading = {this.props.assetLoading}
                backOnClick = {this.backOnClick}
            />
        );
    }
    // -----------------------------------------------------
    handleAssetClassChange = (val) => {
        this.props.clearAssetRecursiveData();
        this.setState({
            selectedAssetClass: val,
            selectedAsset: null,
            assets: val?.assets,
        });
    }
    handleAssetChange = (val) => {
        if (val) {
            this.props.getAssetRecursiveData(val.assetid)
        } else {
            this.props.clearAssetRecursiveData();
        }
        this.setState({
            selectedAsset: val,
        });
    }
    renderFilter = () => {
        return (
            <Fragment>
                <Col md={6} style={{marginBottom: '.5rem'}}>
                    <Select
                        isClearable
                        options = {this.props.assetClass}
                        getOptionLabel={({ assetclassname }) => assetclassname}
                        getOptionValue={({ assetclassid }) => assetclassid}
                        value = {this.state.selectedAssetClass}
                        onChange={this.handleAssetClassChange}
                        placeholder='Select Asset Class...'
                        styles={this.state.customStyles}
                    />
                </Col>
                <Col md={6} style={{marginBottom: '.5rem'}}>
                    <Select
                        isClearable
                        options = {this.state.assets}
                        getOptionLabel={({ assetname }) => assetname}
                        getOptionValue={({ assetid }) => assetid}
                        value = {this.state.selectedAsset}
                        onChange={this.handleAssetChange}
                        placeholder='Select Asset...'
                        styles={this.state.customStyles}
                    />
                </Col>
            </Fragment>
           
        );
    }
    // -----------------------------------------------------
    backOnClick =(e)=>{
        this.setState({
            showDataList: false
        });
    }
    // -----------------------------------------------------
    render(){
        let treeData = [];
        if (this.props.recursiveData) {
            treeData = generateTreeData(this.props.recursiveData);
             
        }
        return (
            <Fragment>
                <Row style={{marginBottom: '10px'}}>
                    {/* <Col md={4}>
                        {this.renderFilter()}
                    </Col> */}
                     {this.renderFilter()}
                </Row>
                <Collapse isOpen={!this.state.showDataList}>
                <Row>                
                    <Col md={12}>
                        <SchemaChart
                            treeData = {treeData}
                            assetData = {this.props.assetData}
                            handleSelectionChange = {this.handleSelectionChange}
                            updatedData={this.state.updatedData}
                            loading = {this.props.recursiveLoading}
                        />
                    </Col>
                </Row>
                </Collapse>
                <Collapse isOpen={this.state.showDataList}>
                {/* <Row>
                    <Col md={2}>
                        <BackButton 
                            onClick={this.backOnClick} 
                            label="Back"
                            size={30}
                            color = 'black'
                        />  
                    </Col>
                </Row> */}
                <Row>
                    <Col md={6}>
                        {this.renderAssetData()}
                    </Col>
                </Row>
                </Collapse>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => { 
    const assetClass = state.mapboard.assetClass;
    const assetGroupData = state.mapboard.assetGroupData;
    const assetData = state.mapboard.assetData;
    const assetLoading = state.mapboard.loading;
    const pubsubDevice = state.dashboard.pubsubDevice;
    const recursiveData = state.recursive.recursiveData;
    const recursiveLoading = state.recursive.loading;
    // const props = {...state.recursive, assetClass, assetGroupData, assetData, assetLoading, pubsubDevice, recursiveData}
    const props = { assetClass, assetGroupData, assetData, assetLoading, pubsubDevice, recursiveData, recursiveLoading }
    return props;
}
    
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
            loadAssetClass,
            // getAssetRecursiveData,
            // clearAssetRecursiveData,
            getGroupAssetData,
            getGroupDeviceData,
            clearGroupAssetData,
            // removeRecursiveLeve,
            getAssetsData, 
            // getAttributeThreshold,
            updatePubSubDevice,
            getAssetRecursiveData,
            clearAssetRecursiveData,
    }, dispatch);
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SchemaBoard);

function generateTreeData(dataList){
    let treeData = [];
    if (dataList){
        let rootAssets = dataList.filter((item, index)=>(!dataList.some(parent => item.parent === parent.assetid)));
        rootAssets && rootAssets.forEach((root, index) => {
            let rootClass = {
                assetid: root.assetid,
                label: root.assetname,
                type: 'assetnode',
                className: 'p-person',
                expanded: true,
                // data: {name:'Saul Goodman', avatar: 'saul.jpg', assetid: 0, assetname: "test_01", icon: icon_2},
                data: root.msgAttributes,
                icon: root.assetIcon ? root.assetIcon.iconsvg : null,
            };
            // let myChildren = getChildNodes(dataList, childClass);
            // if (myChildren && myChildren.length > 0){
            //     childClass.children = myChildren;
            // }
            getChildNodes(dataList, rootClass);
            treeData.push(rootClass)
        }); 
    }
    return treeData;
}

function getChildNodes(dataList, parentNode) {
    let children = [];
    if (dataList && parentNode.assetid) {
        let childrenList = dataList.filter((item, index)=>(item.parent == parentNode.assetid));
        if (childrenList) {
            childrenList.forEach((childNode, index) => {
                let childClass = {
                    assetid: childNode.assetid,
                    label: childNode.assetname,
                    type: 'assetnode',
                    className: 'p-person',
                    expanded: true,
                    // data: {name:'Saul Goodman', avatar: 'saul.jpg', assetid: 0, assetname: "test_01", icon: icon_2},
                    data: childNode.msgAttributes,
                    icon: childNode.assetIcon ? childNode.assetIcon.iconsvg : null,
                };
                // let myChildren = getChildNodes(dataList, childClass);
                // if (myChildren && myChildren.length > 0){
                //     childClass.children = myChildren;
                // }
                getChildNodes(dataList, childClass);
                children.push(childClass)
            });
        }
    }
    // return children;
    if (children && children.length > 0){
        parentNode.children = children;
    }
}

function generateTreeData2(dataList, parentNode) {
    // let tree = [];
    let children = [];
    if (dataList) {
        let childrenList = null;
        if (!parentNode){
            childrenList = dataList.filter((item, index)=>(!dataList.some(parent => item.parent === parent.assetid)));
        }else if (parentNode.assetid) {
            childrenList = dataList.filter((item, index)=>(item.parent == parentNode.assetid));
        }
        
        if (childrenList) {
            childrenList.forEach((childNode, index) => {
                let childClass = {
                    assetid: childNode.assetid,
                    label: childNode.assetname,
                    type: 'assetnode',
                    className: 'p-person',
                    expanded: true,
                    // data: {name:'Saul Goodman', avatar: 'saul.jpg', assetid: 0, assetname: "test_01", icon: icon_2},
                    data: childNode.msgAttributes,
                    icon: childNode.assetIcon ? childNode.assetIcon.iconsvg : null,
                };
                let myChildren = generateTreeData2(dataList, childClass);
                if (myChildren && myChildren.length > 0){
                    childClass.children = myChildren;
                }
                children.push(childClass)
            });
        }
    }
    return children;
}

