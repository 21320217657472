/*eslint-disable*/
import React, { Component, Fragment } from "react";
import { Col, Row, Card, CardBody, Label, Input, Button, } from 'reactstrap';
import { BackButton, NextButton } from '../../../shared/components/Button/IconButton';

class CompanyPurchaseDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemList: [],
    }
  }
  componentDidMount() {
    this.props.getInventoryList();
  }
  // --------------------------------------------

  checkFormater = (cell, row) => {
    return (
      <Input type="checkbox" />
    )
  }

  InputFormater = (cell, row) => {
    if (Number.isInteger(cell)) {
      const q = parseInt(cell);
      if (q > 0) {
        return (
          <Input type='number' min="0" step="1" max={cell} defaultValue='0' onChange={(e) => { this.onInputValueChange(e.target.value, row) }} ></Input>
        );
      }
    }

    return (
      <span>Out of Stock</span>
    );
  }
  onInputValueChange = (value, obj) => {
    this.setState(prevState => {
      var foundItem = prevState.itemList.find(ele => (ele.id == obj.upc))
      if (foundItem) {
        foundItem.qty = value
      } else {
        prevState.itemList.push({ id: obj.upc, qty: value })
      }
      return {
        itemList: prevState.itemList,
      }
    }, () => {

    });
  }

  renderDevices = () => {
    return (
      <Fragment>
        <Row>
          <Col md={12}>
            <br />
            <h4>Devices</h4>
            <br />
            <BootstrapTable
              data={this.props.inventoryList}
              // tableHeaderClass = 'header_white' 
              pagination={false}


              bordered={false}
              // insertRow 
              ref='table'
            >
              {/* <TableHeaderColumn dataField='' dataAlign="center"  width='5%' dataFormat={this.checkFormater}></TableHeaderColumn>  */}
              <TableHeaderColumn dataField='upc' isKey={true} dataAlign="center" width='15%' >UPC</TableHeaderColumn>
              <TableHeaderColumn dataField='description_EN' dataAlign="center" width='15%'>Item</TableHeaderColumn>
              {/* <TableHeaderColumn dataField='company' dataAlign="center" dataSort width='20%' >COMPANY</TableHeaderColumn>  */}
              <TableHeaderColumn dataField='mfg' dataAlign="center" dataSort width='15%' >MFG</TableHeaderColumn>
              {/* <TableHeaderColumn dataField='group' dataAlign="center" dataSort hidden >GROUP</TableHeaderColumn>                      */}
              {/* <TableHeaderColumn dataField='oursku' dataAlign="center" dataSort width='10%' >SKU</TableHeaderColumn>   */}
              {/* <TableHeaderColumn dataField='mfgsku' dataAlign="center" dataSort width='10%'>MFG_SKU</TableHeaderColumn>   */}
              <TableHeaderColumn dataField='quantity' dataAlign="center" dataSort width='20%' >Remain Quantity</TableHeaderColumn>
              <TableHeaderColumn dataField='quantity' dataAlign="center" width='15%' dataFormat={this.InputFormater}>Quantity</TableHeaderColumn>
              {/* <TableHeaderColumn dataField='soldquantity' dataAlign="center" dataSort width='10%' >SOLD QUANTITY</TableHeaderColumn>   */}
              <TableHeaderColumn dataField='network_SUPPORTED' dataAlign="center" dataSort >Network</TableHeaderColumn>


            </BootstrapTable>
          </Col>
        </Row>
      </Fragment>
    );
  }

  handlePurchaseCardClick = () => {
    // todo
    var upcObj = { upcs: this.state.itemList }
    this.props.getCustomerPurchaseDevices(upcObj);

    // this.props.onBackClick();
    this.props.onNextClick();
  }
  render() {
    return (
      <Col md={12}>
        <Card>
          <CardBody style={{ padding: '0px 30px' }}>
            {this.renderDevices()}
          </CardBody>
        </Card>

        <br />

        <Row>
          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <BackButton
              onClick={this.props.onBackClick}
              label="Back"
              size={30}
              color='black'
            />
          </Col>
          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <NextButton
              onClick={this.handlePurchaseCardClick}
              label="Next"
              size={30}
              color='black'
            />
          </Col>
        </Row>
      </Col>
    )
  }
}
export default CompanyPurchaseDevices
