/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Container, Card, CardBody, Row, Col, Button, Table, Input, Label } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { UserContext } from '../../../constants/myContexts';
import WeatherMap from './WeatherMap';
import WeatherBarChart from './WeatherBarChart';
import WeatherLineChart from './WeatherLineChart';
import TrafficLightWidget from './TrafficLightWidget';
import BatteryWidget from './BatteryWidget';
import BatteryIcon from './BatteryIcon';
import GarageBoard from './GarageBoard';
import DeviceManagement from './DeviceManagement';
import StatusTable from './StatusTable';
import { DeleteCircleIcon, BatteryFullIcon, BatteryEmptyIcon, Battery20Icon, Battery50Icon, Battery80Icon } from 'mdi-react';
// import BatteryFullIcon from 'mdi-react/BatteryFullIcon';
import './blinking.scss';
import { weatherSeverity, hex2bin, splitStatusValue, mapWeatherSeverity } from '../../../factories/utils';
import { reformWeatherAssetData } from '../../../factories/weather';
import SettingPage from '../../Setting/index';


class GarageMainBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mapProps: {
                showingInfoWindow: false,
                activeMarker: {},
                selectedPlace: { attr: {} },
            },
            activeTab: '1',
        };
    }

    componentDidMount() {

    }

    renderLegend = () => {
        let legendList = [
            { color: 'gray', label: 'No Activity' },
            { color: 'red', label: 'Lightning near (<5km)' },
            { color: 'orange', label: 'Lightning approaching (5km to 15km)' },
            { color: 'yellow', label: 'Lightning Detected (+15km)' },
            { color: 'green', label: 'All Clear' },
        ];

        return (
            <div style={{ textAlign: 'center', width: '100%', margin: '30px' }}>
                {/* <div style={{width: '60%',  margin: '0 auto', borderRadius: '25px'}}> */}
                <table style={{ width: '60%', margin: '0 auto', borderRadius: '20px' }}>
                    <tr>
                        {legendList.map((item, index) => (
                            <td style={{ textAlign: 'center', width: '50px' }}>
                                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle key={index} id={`legend_${index}`} fill={item.color} stroke='black' stroke-width='0' cx="12" cy="12" r="10"></circle>
                                </svg>
                                {/* <span style={{fontSize:'11px'}}>{item.label}</span> */}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        {legendList.map((item, index) => (
                            <td style={{ textAlign: 'center' }}>
                                <span style={{ fontSize: '11px' }}>{item.label}</span>
                            </td>
                        ))}
                    </tr>
                </table>
                {/* </div> */}
            </div>
        )
    }
    //--------------------------------------------------------------------

    handleMapMarkerClick = (props, marker, e) => {
        //  
        this.setState(prevState => {
            return ({
                mapProps: {
                    showingInfoWindow: true,
                    // showingInfoWindow: !prevState.mapProps.showingInfoWindow,
                    activeMarker: marker,
                    selectedPlace: props,
                    lat: props.position.lat,
                    lng: props.position.lng,
                },
            });
        });
    }

    handleMapInfoWinShow = (props, marker, e) => {

        this.setState(prevState => {
            if ((props.position.lat != prevState.mapProps.lat || props.position.lng != prevState.mapProps.lng)) { //!prevState.mapProps.showingInfoWindow && 
                return ({
                    mapProps: {
                        showingInfoWindow: true,
                        // showingInfoWindow: !prevState.mapProps.showingInfoWindow,
                        activeMarker: marker,
                        selectedPlace: props,
                        lat: props.position.lat,
                        lng: props.position.lng,
                    },
                });
            }
        });
    }

    handleMapInfoWinClose = (props) => {
        this.setState((prevState) => ({
            mapProps: {
                ...prevState.mapProps,
                showingInfoWindow: false,
                // activeMarker: null
            }
        }));
    }

    tabToggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                mapShow: (tab == '2')
            }, () => {
                if (this.state.activeTab == '2') {
                    this.onMapShow();
                }
            });
        }
    }

    onMapShow = () => { }

    render() {

        let processedMapdata = [];
        let statusData = [];
        let mapData = [];

        if (this.props.assetData) {
            this.props.assetData.forEach((asset, index) => {
                let assetObj = {
                    ...asset,
                    color: 'gray',
                    alarm: '0',
                };

                if (asset.attributes) {
                    asset.attributes.forEach((attribute, index) => {
                        if ('CurrentDigitalInputState' === attribute.attributename) {
                            let slicedValue = splitStatusValue(attribute.value);
                            // switch(weatherSeverity(attribute.value)){
                            switch (mapWeatherSeverity(slicedValue.alarm)) {
                                case 1:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'yellow',
                                        // alarm: weatherSeverity(attribute.value),
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                                case 2:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'orange',
                                        // alarm:  weatherSeverity(attribute.value),
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                                case 3:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'red',
                                        // alarm:  weatherSeverity(attribute.value),
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                                case 4:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'green',
                                        // alarm:  weatherSeverity(attribute.value), 
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                            }
                        }
                    });
                }
                mapData.push(assetObj);
            });
        }

        return (
            <Fragment >
                <Nav tabs>
                    <NavItem style={{cursor:'pointer'}}>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.tabToggle('1'); }}
                        >
                            Assets
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.tabToggle('2'); }}
                        >
                            Device Management
                        </NavLink>
                    </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Card>
                            <GarageBoard
                                activeTab={this.state.activeTab}
                                toggle={this.props.toggle}
                                loading={this.props.assetDataLoading}
                                assetData={this.props.assetData}
                                //statusData={statusData}
                                height={'400px'}
                                isSmall={this.props.isSmall}
                                alarmObj={this.props.alarmObj}
                                alarmSet={this.props.alarmSet}
                                // statusDataProp={this.props.statusData.filter(status => status.grouping === "weather")}
                                alarm24HCounts = {this.props.alarm24HCounts}
                                statusData={this.props.statusData}
                                alarmSetObj={this.props.alarmSetObj}

                            />
                        </Card>
                    </TabPane>
                    {/* <TabPane tabId="2">
                        <Card>
                        <DeviceManagement
                                statusData={this.props.statusData}
                        />
                        </Card>
                    </TabPane> */}
                </TabContent>
            </Fragment>
        );
    }

}

export default GarageMainBoard;