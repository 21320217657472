/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { EditTextButton } from '../../../shared/components/Button/IconButton';
import { getCurrentUser, hasRole } from '../../../factories/auth';

class ClientDiscountList extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser()
        this.state = {
        };
    }
    componentDidMount() {

    }

    dateFormatter = (cell, row) => {
        var str = cell.substring(0, 10);
        return (`${str}`);
    }
    typeFormatter = (cell, row) => {
        if (row.promoteType == "dollar") {
            return `$ ${cell.toFixed(2)}`;
        }
        if (row.promoteType == "percentage") {
            return `${cell} %`;
        }
    }
    onEditOpen = (row) => {
        this.props.editDiscountButton(row);
    }
    editButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={''} onClick={(e) => { this.onEditOpen(row) }} />
            </div>
        );
    };
    render() {

        let options = {
            noDataText: "No Discounts Found",
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        }
        if (this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN'])) {
            options["insertBtn"] = this.props.addNewDiscountButton;
        }
        const type = {
            "percentage": '%',
            "dollar": '$'
        };
        return (
            <BootstrapTable
                data={this.props.discountList}
                tableHeaderClass='header_white'
                options={options}
                exportCSV
                csvFileName={(this.userObj.companyCode && this.userObj.companyCode == "TEISA") ? 'Reseller_Discount_List.csv' : 'Client_Discount_List.csv'}
                bordered={false}
                height='100%'
                pagination={true}
                insertRow={(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false}
            >
                <TableHeaderColumn row='0' rowSpan='1' dataField='promoteName' isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} dataSort caretRender={sortCaret} csvHeader='Name'> Name</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='promoteType' dataAlign='center' filter={{ type: 'SelectFilter', options: type, placeholder: ' ' }} dataSort caretRender={sortCaret} csvHeader='Type'>Type</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='promoteValue' dataAlign='center' dataFormat={this.typeFormatter.bind(this)} filter={{ type: 'NumberFilter', delay: 1000, placeholder: ' ' }} dataSort caretRender={sortCaret} csvHeader='Value'>Value</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='validStartDate' dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} dataFormat={this.dateFormatter.bind(this)} csvFormat={this.dateFormatter} dataSort caretRender={sortCaret} csvHeader='Start Date'>Start Date</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='validEndDate' dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} dataFormat={this.dateFormatter.bind(this)} csvFormat={this.dateFormatter} dataSort caretRender={sortCaret} csvHeader='End Date'>End Date</TableHeaderColumn>

                <TableHeaderColumn
                    dataField='Operations'
                    dataAlign="center"
                    dataFormat={this.editButton.bind(this)}
                    width="10%"
                    export={false}
                    hidden={(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN'])) ? false : true}
                >
                    Edit
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }
}
export default ClientDiscountList;