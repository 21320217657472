/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_view from '../../../shared/img/icon/view.svg';
import {loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff} from '../../../redux/actions/jobAction';
import GeoFenceMap from '../../MapComponents/GeoFenceMap';
import GeoFenceMapSearch from '../../MapComponents/GeoFenceMapSearch';
import '../../../scss/component/table.scss';


class GeofenceSection extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedShape: null,
            selectedFenceId: null,
            selectedFenceName: '',
            mapModal: false,
        };
        this.myMap = React.createRef();
    }

    typeFormatter = (cell, row) => {
        let type = '' ;
        switch(cell){
            case 0:
                type = 'circle';
                break;
            case 1:
                type = 'rectangle';
                break;
            case 2:
                type = 'polygon';
                break;
        }
        return type;
    }


    onRowSelect = (row, isSelected, e) => {
        let id = row.fenceid;
        // alert(id);
         
        let fence = this.props.allfences.find((e, i) => (e.fenceid == id));
        // alert(fence.type);
         
        
        //  
        let type = '' ;
        switch(fence.shape){
            case 0:
                type = 'circle';
                break;
            case 1:
                type = 'rectangle';
                break;
            case 2:
                type = 'polygon';
                break;
        }
        this.setState({
            selectedShape: {type: type, data: JSON.parse(fence.geodata)},
            selectedFenceId: fence.fenceid,
            selectedFenceName: fence.fencename,
            // currentFenceName: fence.fencename,
            // currentFenceId: fence.fenceid,
            // fenceDeviceTypeName: fence.devicetypename,
            // fenceDeviceName: fence.devicename
        });
    }

    onAddClick =() => {
        // const mapNode = this.myMap.current;
        //  
        //  
        //  
        this.props.onFenceAddClick(this.state.selectedFenceId);
    }

    renderAllFences() {
        
        const options = {};
        const selectRowProp = {
            mode: 'radio',
            bgColor: 'pink', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
            hideSelectColumn: true,  // enable hide selection column.
            clickToSelect: true,  // you should enable clickToSelect, otherwise, you can't select column.
            onSelect: this.onRowSelect,
        };
        return(
            <div>
            {/* <Label  style={{ display: 'inline'}} ><h5>All Geofence</h5></Label> */}
            <Row>
            <Col md={6}>
                <Card>
                    <CardBody>                   
                        <Label  style={{ display: 'inline'}} ><h4>All Geofences</h4></Label> 
                        <BootstrapTable data={ this.props.allfences }  options={ options }     search bordered={ false } selectRow={ selectRowProp } height='400' >
                            <TableHeaderColumn dataField='fenceid' isKey={ true } dataAlign="center" hidden >Fence ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='fencename' dataAlign="center" dataSort >Fence Name</TableHeaderColumn> 
                            <TableHeaderColumn dataField='shape' dataAlign="center" dataFormat = {this.typeFormatter} >Shape</TableHeaderColumn> 
                        </BootstrapTable>
                    </CardBody>
                </Card>
            </Col>
            <Col md={6}>
                <Card>
                    <CardBody style={{padding:15}}>
                        <GeoFenceMap width='100%' height='50vh' shape = {this.state.selectedShape}/>
                        {/* <GeoFenceMapSearch ref={this.myMap} width='100%' height='50vh' shape = {this.state.selectedShape}/> */}
                    </CardBody>          
                </Card>    
            </Col>
            </Row>
            <Row>
                <Col md={12} style={{ textAlign: 'right' }}>
                    <br/>
                    {/* <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleCancleClick}>
                        Cancel
                    </Button> {' '} */}
                    <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.onAddClick} >
                        Add
                    </Button>
                </Col>
            </Row>
        </div>
        )   
    }
    // ------------------------------------------------------------------------------------------------------------------------------------------------------
    handleRowDeleteClick = (row) => {
        this.props.handleRowDeleteClick(row);
    }

    handleRowViewClick = (row) => {
        this.modalToggle();
    }
    
    editButtonFormatter = (cell, row) => {
        let path = `/pages/device/${cell}`;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                <img src={icon_view} style={{width: 23, height: 23}} alt={"View"} onClick={(e)=>{this.handleRowViewClick(row);}}/>
                <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} onClick={(e)=>{this.handleRowDeleteClick(row);}} />
            </div>
        )
    }

    renderActionFence() {
        const options = {};
        return(
            <Col md={12}>
            <Card>
                <CardBody>
                    <BootstrapTable data={ this.props.actionfence }  tableHeaderClass = 'header_white' options={ options }     >
                        <TableHeaderColumn dataField='fenceid' isKey={ true } dataAlign="center" hidden >Fence ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='fencename' dataAlign="center" dataSort >Fence Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='shape' dataAlign="center" dataFormat = {this.typeFormatter}>Shape</TableHeaderColumn> 
                        <TableHeaderColumn dataAlign="center" dataFormat={ this.editButtonFormatter } ></TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        </Col>
        )   
    }

    //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
    modalToggle = () => {
        this.setState(prevState => ({
            mapModal: !prevState.mapModal
        }));
    }
    renderFenceMap() {
        // let data = this.props.currentDeviceList.filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
        let currentShape = null;
        let modalTitle = '';
        if (this.props.actionfence && this.props.actionfence.length >0 ){
            let fence = this.props.actionfence[0];
            modalTitle = fence.fencename;
            let type = '' ;
            switch(fence.shape){
                case 0:
                    type = 'circle';
                    break;
                case 1:
                    type = 'rectangle';
                    break;
                case 2:
                    type = 'polygon';
                    break;
            }
            currentShape= {type: type, data: JSON.parse(fence.geodata)};
        }

        
        
        // return(
        //     <Col md={12}>
        //     <Card>
        //       <CardBody style={{padding:15}}>
        //         <Row>
        //           <Col md={12}>
        //             <GeoFenceMap width='100%' height='30vh' shape={currentShape}/>
        //         </Col>
        //         </Row>
        //       </CardBody>          
        //     </Card>    
        //   </Col>
        // );

        return(
            <Modal isOpen={this.state.mapModal} toggle={this.modalToggle} size='lg' >
                <ModalHeader toggle={this.toggle}>{modalTitle}</ModalHeader>
                <ModalBody>
                    <GeoFenceMap width='100%' height='50vh' shape={currentShape}/>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.modalToggle}>Ok</Button>{' '}
                    {/* <Button color="custom" onClick={this.toggle}>Cancel</Button> */}
                </ModalFooter>
            </Modal>
        );

    }


    //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
    render(){
        return(
            <div>
                {this.renderAllFences()}
                <hr style={{width: '90%'}} />
                {this.renderActionFence()}
                {this.renderFenceMap()}
            </div>
        );
    }
}
export default GeofenceSection; 