/* eslint-disable */

import React, { Component, Fragment } from 'react';
import {Card, CardBody} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import BatteryWidget from './BatteryWidget';
import Tooltip from '@material-ui/core/Tooltip';
import DonutWidget from './DonutWidget';
import {
    GREEN_COLOR, 
    RED_COLOR, 
    GREY_COLOR,
    TIME_ZONE,
} from '../../../constants/dataConstants';


class DeviceManagement extends Component {
    constructor(props){
        super(props);
    }

    nameFormatter = (cell, row) => {
        // let data = this.props.statusData;
        // let device = data[0].devices;
        // let deviceName = '';
        // let nameData = this.props.statusData[0].devices;
        // for(let i=0; i<cell.length; i++){
        //     if(cell == device[i].deviceid){
        //         deviceName = device[i].deviceNameAlias;
        //     }
        // }
        let deviceNameAlias;
        if(this.props.currentAssetData){
            deviceNameAlias = this.props.currentAssetData.devices.find(d => d.deviceid == cell);
            return <div>{deviceNameAlias.deviceNameAlias}</div> 
        }
        return cell.deviceid;  

    }

    idFormatter = (cell) => {
        return cell = <div>{cell}</div>
    }

    batteryFormatter = (cell, row) => {
        let batteryLevel = cell;
        let batteryText;
        if(cell) {
            batteryLevel = cell;
            
            if(batteryLevel == null)
                batteryLevel = 1;
        }

        // if(batteryLevel=="4" || "5"){
        //     batteryText=' : Full'
        // }else if(batteryLevel=="2"){
        //     batteryText=' : Low'
        // }else if(batteryLevel=="0"){
        //     batteryText=' : Empty'
        // }

        
        if(batteryLevel >= 3.3){
            batteryText = ' : Fully Charged';
        }else if(2.7 < batteryLevel && batteryLevel < 3.3){
            batteryText = ' : Charged';
        } else if(batteryLevel == null || batteryLevel == undefined) {
            batteryText = 'No Battery Detected';
        } else if(batteryLevel <= 2.7){
            batteryText = ' : Low Battery';
        }
        // console.log("===Battery Row", row);
        return(
            <div>
                <Tooltip title={"Battery"}>
                    {/* <BatteryWidget level={parseInt(batteryLevel)} style={{ width: '23px', height: '45px' }} />  */}
                    <BatteryWidget level={batteryLevel} style={{ width: '23px', height: '45px' }} /> 
                </Tooltip> {batteryText}
            </div>
        )
    }

    // alarmFormatter = (cell, row) => {
    //     if(cell == "OFF" || cell == "UNKNOWN")
    //         return "No";
        
    //     return "Yes";
    // }

    statusFormatter = (cell, row) => {
        let donutColor;
        let statusMessage
        const now = new Date().getTime()
        const messageDate = new Date(cell).getTime()
        const oneday = 60 * 60 * 24 * 1000;
        if(row) {
            let status = row.alarm;
            if(status) {
                switch(status) {
                    case "GREEN":
                        statusMessage = "ON";
                        donutColor = GREEN_COLOR;
                        break;
                    case "OFF":
                        statusMessage = "OFF";
                        donutColor = GREY_COLOR;
                        break;
                    default:
                        statusMessage = "Unknown";
                        donutColor = GREY_COLOR;
                        break;
                }
            }
        }
        let compareDatesBoolean = (now - messageDate) > oneday;

            // switch(compareDatesBoolean){
            //     case(true):
            //         statusMessage = "NO";
            //         donutColor = RED_COLOR;
            //         break;
            //     case(false):
            //         statusMessage = "YES";
            //         donutColor = GREEN_COLOR;
            //         break;
            //     default:
            //         statusMessage = "Unknown";
            //         donutColor = GREY_COLOR;
            // }

            return (
                <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start', verticalAlign: 'Top'}}>
                    <DonutWidget style={{width: '35px', alignItems: 'stretch', justifyContent: 'flex-start', alignContent: 'flex-start'}} color={donutColor}/>
                    <span>{statusMessage}</span>
                </div>
                // `${occupancyMessage}`
                )
            
    }

    messageFormatter = (cell, row) => {
        // console.log("===message row", row);
        return cell = <div>{cell}</div>
    }


    render(){

        let statusData = this.props.statusData;
        if(statusData[0]){
            statusData = statusData[0].attributes;
        
        }

        
        
        let alarmArray = statusData.Alarm;
        let batteryArray = statusData.Battery;
        let batteryVoltsArray = statusData.BatteryInVolts;
        let messageArray = statusData.LastMessageTime;
        let dataArray = [];

        // for(let i = 0; i <= alarmArray.length; i++){
        //     let j = alarmArray[i];
        //     console.log("===OBJ===j", j);
        // }

        // console.log('===OBJ===alarmArray', alarmArray)
        // console.log('===OBJ===batteryVoltsArray', batteryVoltsArray)
        // console.log('===OBJ===messageArray', messageArray)
        // console.log('===OBJ===statusData', statusData)

        // for(let i = 0; i < alarmArray.length; i++){
        //     batteryArray[i].alarmvalue = alarmArray[i].value;
        // }

        if ((alarmArray && alarmArray.length > 0)
            && (batteryVoltsArray && batteryVoltsArray.length > 0)
            && (messageArray && messageArray.length > 0)) {

            for(let i = 0; i < alarmArray.length; i++){
                // if( i < 5){
                    dataArray.push({
                        deviceName:alarmArray[i].deviceName,
                        // deviceName:statusData[i].assetname,
                        id: alarmArray[i].deviceid,
                        alarm:alarmArray[i].value,
                        battery:batteryVoltsArray[i].value,
                        messageTime:messageArray[i].value,
                    })
                // }else{
                //     dataArray.push({
                //         deviceName:alarmArray[i].deviceName,
                //         id: alarmArray[i].deviceid,
                //         alarm:alarmArray[i].value,
                //         battery:batteryVoltsArray[i].value,
                //         messageTime:messageArray[i].value,
                //     })
                // }
                
            }
            // console.log("===statusData", statusData);
        }
        // for(let i = 0; i < batteryVoltsArray.length; i++){
            // dataArray.push({
            //     battery:{...batteryVoltsArray[i].value}
                
            // })
        // }
        
        // dataArray.push({
        //     deviceName:deviceName
        // })

        return(
            <Card>
                <CardBody>
                    <BootstrapTable data={dataArray} tableHeaderClass='header_white'>
                        <TableHeaderColumn dataField='id'/* 'deviceName' */ dataAlign='center' dataFormat={this.nameFormatter}>Device Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='id' dataAlign='center'isKey dataFormat={this.idFormatter}>Device ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='messageTime' dataAlign='center' dataFormat={this.statusFormatter}>Status</TableHeaderColumn>
                        <TableHeaderColumn dataField='battery' dataAlign='center' dataFormat={this.batteryFormatter}>Battery Level</TableHeaderColumn>
                        <TableHeaderColumn dataField='messageTime' dataAlign='center' dataFormat={this.messageFormatter}>Last Message Received</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        )
    }
}


export default DeviceManagement