/* eslint-disable */
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Collapse,
} from "reactstrap";
import ConfirmDeleteDialog from "../../Asset/components/ConfirmDeleteDialog";
import { sortCaret } from "../../../shared/components/Table/tableElements";
import { EditIcon } from "mdi-react";
import EditMessageDecodePanel from "./EditMessageDecodePanel";

class DeviceConfigList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgDecodeToEdit: null,
      msgDecodeToDelete: null,
      confirmDialogOpen: null,
      modalVisible: false,
      modalName: false,
      currentMsgDecodeObj: null,
      currentMsgDecodeId: "",
      currentName: null,
    };
  }

  componentDidMount() {}

  toggleDialog = () => {
    this.setState({ confirmDialogOpen: !this.state.confirmDialogOpen });
  };

  toggleNameEdit = () => {
    this.setState((prevState) => ({
      modalName: !prevState.modalName,
    }));
  };

  promptDelete = (id) => {
    this.setState({ msgDecodeToDelete });
  };

  deleteMsgDecode = () => {
    this.closePanel();
    // this.props.deleteMsgDecode(this.state.msgDecodeToDelete);
    this.toggleDialog();
  };

  closePanel = () => this.setState({ msgDecodeToEdit: null });

  toggle = () => {
    this.setState((prevState) => ({
      modalVisible: !prevState.modalVisible,
    }));
  };

  msgDecodeIdFormatter = (cell, row) => {
    return <div>{cell}</div>;
  };

  nameFormatter = (cell, row) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          this.onNameCellClick(e, cell, row);
        }}
      >
        <span>{cell}</span>
      </div>
    );
  };

  onNameCellClick = (e, cell, row) => {
    this.setState({
      currentName: cell,
      currentMsgDecodeObj: row,
    });
    this.toggleNameEdit();
  };

  onNameChange = (newName) => {
    let newMsgDecodeObj = {
      ...this.state.currentMsgDecodeObj,
      name: newName,
    };
    this.setState({
      currentName: newName,
      currentMsgDecodeObj: newMsgDecodeObj,
    });
    this.props.updateMessageDecodeOnStore({messageDecodeItemId: this.state.currentMsgDecodeObj.messageDecodeItemId, data:{name: newName}})
  };

  editSelectedMsgDecode = (msgDecodeItemId) => {
    const msgDecodeObj = this.props.messageDecodeList.find(
      ({ messageDecodeItemId }) => messageDecodeItemId == msgDecodeItemId)
    this.setState({
      msgDecodeToEdit: msgDecodeItemId,
      currentMsgDecodeObj: msgDecodeObj,
    });
  };

  editForm = (cell, row) => {
    if (cell > 0) {
      return (
        <div
          className="actions"
          style={{ cursor: "pointer", alignItems: "center" }}
        >
          <a
            onClick={() => this.editSelectedMsgDecode(row.messageDecodeItemId)}
          >
            <EditIcon color="#0066cc" />
            Edit
          </a>
        </div>
      );
    }
  };
  render() {
    // console.log("===this.props.assetClassList", this.props.assetClassList);
    // console.log("===this.state", this.state);
    const options = {
      sortIndicator: true,
      noDataText: this.props.isLoading
        ? "Loading..."
        : "There is no Data to display",
    };
    if (this.props.messageDecodeList) {
    }
    return (
      <>
        <ConfirmDeleteDialog
          modalVisible={this.state.confirmDialogOpen}
          onConfirm={this.deleteMsgDecode}
          toggleOff={this.toggleDialog}
          msg="Are you sure you want to delete this Message decode?"
        />
        <Collapse
          isOpen={
            this.state.msgDecodeToEdit == undefined ||
            this.state.msgDecodeToEdit == null
          }
        >
          <Card>
            <div className="card-pg">
              <BootstrapTable
                data={this.props.messageDecodeList}
                tableHeaderClass="header_white"
                pagination={false}
                option={options}
                bordered={true}
                height="600"
                bodyStyle={{ overflow: "overlay" }}
              >
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataAlign="center"
                  isKey={true}
                  dataField="messageDecodeItemId"
                  dataFormat={this.msgDecodeIdFormatter}
                  caretRender={sortCaret}
                  dataSort
                >
                  Message Decode ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataAlign="center"
                  dataField="name"
                  dataFormat={this.nameFormatter}
                  caretRender={sortCaret}
                  dataSort
                >
                  Name
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataAlign="center"
                  dataField="deviceTypeId"
                  dataFormat={this.deviceTypeIdFormatter}
                  caretRender={sortCaret}
                  dataSort
                >
                  Device Type ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataAlign="center"
                  dataField="messageLength"
                  dataFormat={this.messageLengthFormatter}
                  caretRender={sortCaret}
                  dataSort
                >
                  Message Length
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataAlign="center"
                  dataField="status"
                  dataFormat={this.statusFormatter}
                  dataSort
                  caretRender={sortCaret}
                >
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn
                  row="0"
                  rowSpan="1"
                  dataAlign="center"
                  dataField="messageDecodeItemId"
                  dataFormat={this.editForm}
                  dataSort
                  // caretRender={sortCaret}
                  width="6%"
                >
                  Edit
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </Card>
        </Collapse>
        <Collapse
          isOpen={
            this.state.msgDecodeToEdit != undefined &&
            this.state.msgDecodeToEdit != null
          }
        >
          {this.state.msgDecodeToEdit && (
            <EditMessageDecodePanel
              assetClassList={this.props.assetClassList}
              deviceList={this.props.deviceList}
              messageDecodeList={this.props.messageDecodeList}
              msgDecodeInfo={this.props.messageDecodeList.find(
                ({ messageDecodeItemId }) => messageDecodeItemId== this.state.msgDecodeToEdit
              )}
              selectedMsgDecode={this.state.msgDecodeToEdit}
              closePanel={this.closePanel}
              messageDecodeAttribute={this.props.messageDecodeList
                .filter((id) => id == this.state.msgDecodeToEdit)
                .map((attr) => attr)}
              updateMessageDecodeOnStore={this.props.updateMessageDecodeOnStore}
            />
          )}
        </Collapse>
        <NameEditModal
          toggle={this.toggleNameEdit}
          modal={this.state.modalName}
          name={this.state.currentName}
          onChange={this.onNameChange}
        />
      </>
    );
  }
}

export default DeviceConfigList;

const NameEditModal = (props) => {
  const { toggle, modal, name, onChange } = props;
  let editRef;
  React.useEffect(() => {
    editRef && editRef.focus();
  });
  const onAddClick = (e) => {
    onChange(editRef.value);
    toggle();
  };
  const onCancelClick = (e) => {
    toggle();
  };

  return (
    <Modal
      animationType="slide"
      transparent={false}
      isOpen={modal}
      toggle={toggle}
    >
      <ModalBody>
        <Col md={8}>
          <Input
            type="text"
            id="displayname"
            name="displayname"
            width="90%"
            defaultValue={name}
            innerRef={(input) => {
              editRef = input;
            }}
          ></Input>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onAddClick} disabled={false}>
          Submit
        </Button>
        <Button color="custom" onClick={onCancelClick}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
