/* eslint-disable */
import {
    ATTRI_MGMT_TOGGLE_ERROR,
    ATTRI_MGMT_TOGGLE_SUCCESS,
    ATTRI_MGMT_MSG_TOGGLE_OFF,
    ATTRI_MGMT_MSG_TOGGLE_ON,
    ATTRI_MGMT_GET_ATTRIBUTE_SUCCEED,
    ATTRI_MGMT_GET_ATTRIBUTE_FAILED,
    ATTRI_MGMT_POST_ATTRIBUTE_SUCCEED,
    ATTRI_MGMT_POST_ATTRIBUTE_FAILED,
    ATTRI_MGMT_DELETE_ATTRIBUTE_SUCCEED,
    ATTRI_MGMT_DELETE_ATTRIBUTE_FAILED,
    ATTRI_MGMT_LOAD_DEVICETYPE_SUCCEED,
    ATTRI_MGMT_LOAD_DEVICETYPE_FAILED,
    ATTRI_MGMT_LOAD_ASSET_SUCCEED,    
    ATTRI_MGMT_LOAD_ASSET_FAILED,
    ATTRI_MGMT_CLEAR_ATTRIBUTE,
} from '../../constants/actionTypes';

const initState = { 
    currentAttribute: null,   
    devicetypes: [],
    assets: [],
    attributes: [],
    error: 0,
    message: '',
    type: '',
    messagevisible: false,
    messagetype: '',
}

export default function(state = initState, action){
     
    switch(action.type){
        case ATTRI_MGMT_MSG_TOGGLE_OFF:
            return {
                ...state,
                type: action.type,
                messagevisible: false,
                messagetype: '',
            }
        case ATTRI_MGMT_GET_ATTRIBUTE_SUCCEED:
            return {
                ...state,
                attributes: action.payload,
                error: 0,
                message: 'GET SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ATTRI_MGMT_GET_ATTRIBUTE_FAILED:
            return {
                ...state,
                error: 1,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case ATTRI_MGMT_POST_ATTRIBUTE_SUCCEED:
            return {
                ...state,
                currentAttribute: action.payload,
                error: 0,
                message: 'UPSERT SUCCESS ---',
                type: action.type,
                messagevisible: true,
                messagetype: 'success',
            }
        case ATTRI_MGMT_POST_ATTRIBUTE_FAILED:
            return {
                ...state,
                error: 2,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case ATTRI_MGMT_DELETE_ATTRIBUTE_SUCCEED:
            return {
                ...state,
                error: 0,
                message: 'DELETE SUCCESS ---',
                type: action.type,
                messagevisible: true,
                messagetype: 'success',
            }
        case ATTRI_MGMT_CLEAR_ATTRIBUTE:
            return{
                ...state,
                attributes: [],
                error: 0,
                message: 'CLEAR SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ATTRI_MGMT_DELETE_ATTRIBUTE_FAILED:
            return {
                ...state,
                error: 3,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case ATTRI_MGMT_LOAD_DEVICETYPE_SUCCEED:
            return {
                ...state,
                devicetypes: action.payload,
                error: 0,
                message: 'DEVICETYPE LOAD SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ATTRI_MGMT_LOAD_DEVICETYPE_FAILED:
            return {
                ...state,
                error: 4,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case ATTRI_MGMT_LOAD_ASSET_SUCCEED:
            return {
                ...state,
                assets: action.payload,
                error: 0,
                message: 'ASSET LOAD SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ATTRI_MGMT_LOAD_ASSET_FAILED:
            return {
                ...state,
                error: 5,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        default: 
            return state;
    }
}


