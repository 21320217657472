/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Container, Card, CardBody, Row, Col, Button, Table, Input, Label } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import {
  sortCaret,
  AdjustableTableHeaderColumn,
  AdjustableTableHeaderColumn2,
  // AdjustableDiv 
} from '../../../shared/components/Table/tableElements';
import { groupBy } from '../../../factories/utils';
import { ATTRIBUTE_TYPE, ATTRIBUTE_NAME, TIME_ZONE } from '../../../constants/dataConstants';

class StatusTable extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  renderAttrTable = () => {
    let cols = [
      {
        field: 'assetid',
        displayName: 'Asset ID',
        isKey: true,
        hidden: true
      },
      {
        field: 'assetname',
        displayName: 'Sensor',
        dataSort: false,
        hidden: true,
      },
      {
        field: 'attritime',
        displayName: 'Timestamp',
        dataFormat: this.timestampFormat,
        dataSort: true,
      },
    ];

    let colsFields = cols.map((item) => (item.field));
    let data = this.props.tableData ?
      this.props.tableData.filter((item, index) => (
        item.type !== ATTRIBUTE_TYPE.META &&
        item.display !== false &&
        // item.assetname !=='Alarm' 
        item.displayname !== 'CO2'
        // item.timestamp !== item.attritime
      )) : [];

    let dataMap = groupBy(data, item => item.attritime);

    let tableData = [];
    for ([key, value] of dataMap) {
      const { assetid, assetname, attritime } = value[0];
      let assetObj = { assetid, assetname, attritime };
      for (let item of value) {
        if (!Object.keys(assetObj).includes(item.attributename)) {
          assetObj[item.attributename] = item.value;
        }
        if (!colsFields.includes(item.attributename)) {
          cols.push({
            field: item.attributename,
            displayName: item.displayname,
            dataSort: true,
          });
          // colsFields = cols.map((item)=>(item.field));
          colsFields.push(item.attributename);
        }
      }
      assetObj.attritime = new Date(assetObj.attritime).toLocaleString(
        "en-US",
        { timeZone: TIME_ZONE }
      )
      tableData.push(assetObj);
    }
    //  
    //  
    //

    let maxHeight = 400;
    let options = {
      defaultSortName: 'attritime',  // default sort column name
      defaultSortOrder: 'desc',  // default sort order
      exportCSVBtn: this.createCustomExportCSVButton
    };
    return (
      <Fragment>
        {/* <Card style={{padding: '0', border: '1px solid black'}}>
            <CardBody style={{padding: '10px'}}> */}
        <BootstrapTable
          // data={this.state.data} 
          data={tableData}
          tableHeaderClass='header_white'
          pagination={true}
          options={options}

          hover
          bordered={true}
          //maxHeight={maxHeight}
          // containerStyle={{overflowY: 'auto'}}
          tableStyle={{ minWidth: '900px' }}
          printable
          exportCSV
        //bodyStyle={{overflow: 'overlay'}}
        >
          {cols && cols.map((col, cindex) => (
            <TableHeaderColumn
              dataField={col.field}
              isKey={col.isKey ? true : false}
              hidden={col.hidden ? true : false}
              dataSort={col.dataSort ? true : false}
              caretRender={col.dataSort ? sortCaret : undefined}
              dataFormat={col.dataFormat ? col.dataFormat : undefined}
              // {...(col.dataSort ? {caretRender: {sortCaret}} : {})}
              // {...(col.dataFormat ?  {dataFormat: {col.dataFormat}} : {})}
              dataAlign='center'
            >
              {col.displayName}
            </TableHeaderColumn>
          ))}

        </BootstrapTable>
        {/* </CardBody>
        </Card> */}
      </Fragment>
    )
  }
  render() {
    return (
      <Fragment>
        <br />
        <div>
          {this.renderAttrTable()}
        </div>
      </Fragment>
    );
  }
}

export default StatusTable;
