/* eslint-disable */
export function toggleAlertOff(toggleOffType){
    return((dispatch) => {dispatch(
            {
                type: toggleOffType,
                data: false
            }
        )}
    );
}

export function toggleAlertOn(toggleOnType){
    return((dispatch) => {dispatch(
            {
                type: toggleOnType,
                data: true
            }
        )}
    );
}