/* eslint-disable */
import React, {Component, Fragment} from 'react';
import {Button, Card, CardBody, Col, Form, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
import WeatherMixChart from './WeatherMixChart';
import SingleVarValueWidget from './SingleVarValueWidget';
import SingleAlarmBarChartWidget from './SingleAlarmBarChartWidget';
import StatusTable from './StatusTable';
import {DateRangePicker} from 'react-dates';
import moment from 'moment';
// import {ChevronLeftIcon} from 'mdi-react';
import {ALARM_META_ATTRI_OBJ, ATTRIBUTE_ORDER, TIME_OPTION, TIME_RANGE_OPTION} from '../../../constants/dataConstants';
import {groupBy} from '../../../factories/utils';
import { AutorenewIcon } from 'mdi-react';

const customStyles = {
    control: base => ({
        ...base,
        // height: 35,
        // minHeight: 35
        height: 35,
        minHeight: 35
    }),
    indicatorsContainer: base => ({
        ...base,
        padding: "0",
    }),
};

class HistoryBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDateMoment: null,
            endDateMoment: null,
            selectedAsset: null,
            assets: [],
            startTimeOptions: TIME_OPTION.filter((item, index) => (index !== 24)),
            endTimeOptions: TIME_OPTION.filter((item, index) => (index !== 0)),
            timeRangeOptions: TIME_RANGE_OPTION,
            activeTab: '1',
            attributea: 'Occupancy',
            attributeb: 'Lightning',
            activeChartTab: '3',
            startDate:null,
            endDate:null
        };
    }

    componentWillReceiveProps(nextProps) {

        this.setState({})
    }

    tabToggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    chartTabToggle = (tab) => {
        this.setState(prevState => {
            if (prevState.activeChartTab !== tab) {
                return {
                    activeChartTab: tab,
                }
            } else {
                return null;
            }

        });
    }
    
    handleValueClick = (data) => {
        const val = data.attributename;
        if (this.state.attributea && val == this.state.attributea) {
            this.setState(prevState => ({
                attributea: prevState.attributeb ? prevState.attributeb : null,
                attributeb: null,
                // attributea: null,
            }));
        } else if (this.state.attributeb && val == this.state.attributeb) {
            this.setState({
                attributeb: null,
            });
        } else if (!this.state.attributea) {
            this.setState({
                attributea: val,
            });
        } else if (!this.state.attributeb) {
            this.setState({
                attributeb: val,
            });
        } else if (val != this.state.attributea && val != this.state.attributeb) {
            // else{
            this.setState(prevState => ({
                attributea: prevState.attributeb,
                attributeb: val,
            }));
        }
    }

    handleAssetChange = (value) => {
        this.props.handleFilterChange({
            currentAsset: value,
        });
    }

    handleDateRangeChange = (value) => {
        //{startDate     endDate}
        // if(value.startDate==null && value.endDate==null){
        //     this.setState({focusedInput:"startDate"})
        // }
        this.setState({
            // startDate:value.startDate,
            // endDate:value.endDate,
            startTimeOptions: TIME_OPTION.filter((item, index) => (index !== 24)),
            endTimeOptions: TIME_OPTION.filter((item, index) => (index !== 0)),
        });
        this.props.handleFilterChange({
            startDateMoment: value.startDate,
            endDateMoment: value.endDate,
            startTime: TIME_OPTION[0],
            endTime: TIME_OPTION[24],
        });
        // setTimeout(()=>{
        //     if(value.startDate!=null&&value.endDate==null){
        //         this.setState({focusedInput:"endDate"})
        //     }
        // },300)

    }

    handleStartTimeChange = (value) => {
        if (!value) {
            this.setState({
                endTimeOptions: TIME_OPTION,
            });
        } else {
            let selectedIndex = value.index;
            this.setState({
                endTimeOptions: TIME_OPTION.filter((item, index) => (index > selectedIndex)),
            });
        }
        this.props.handleFilterChange({
            startTime: value,
            endTime: null,
        });

    }

    handleEndTimeChange = (value) => {
        this.props.handleFilterChange({
            endTime: value,
        });
    }

    backOnClick = (e) => {
        this.props.toggle();
    }

    onRefresh = () => {
        this.props.updataHistoryData();
        this.props.updateAttributeHistoryData();
    }
    renderFilter = () => {
        //  
        return (
            <div style={{width: "100%", margin: '20px'}}>
                <Form md={12}>
                    <Row form>
                        <Col md={2}>
                            <Button
                                variant="primary" color="custom"
                                onClick={this.backOnClick}
                                //color='black'
                            > {'< Back'} </Button> 
                        </Col>
                        <Col md={6}></Col>
                        <Col md={3}>
                            <Select
                                // isClearable
                                isSearchable
                                // options = {this.state.assets} //assetList
                                options={this.props.assetList}
                                getOptionLabel={({assetname}) => assetname}
                                getOptionValue={({assetid}) => assetid}
                                placeholder={'Select A Station ...'}
                                value={this.props.currentAsset}
                                // defaultValue={}
                                onChange={this.handleAssetChange}
                                styles={customStyles}
                            />
                            {/* </Col> */}
                            {/* </FormGroup> */}
                        </Col>
                    </Row>

                </Form>
            </div>
        )
    }

    renderTimeFilter = () => {
        var startDate=this.props.startDateMoment;
        return (
            <div style={{width: "100%", margin: '20px'}}>
                <Form md={12}>
                    <Row form>
                        <Col md={5}></Col>
                        <Col md={3}>
                            <DateRangePicker
                                isOutsideRange={
                                    (date)=>{
                                        if(startDate&&startDate._d.getTime()>date._d.getTime() && this.state.focusedInput !== "startDate"){
                                            return true;
                                        }
                                        return false;
                                    }
                                }
                                startDate={this.props.startDateMoment} // momentPropTypes.momentObj or null,
                                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                endDate={this.props.endDateMoment} // momentPropTypes.momentObj or null,
                                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                minimumNights={0}
                                onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={(focusedInput) =>
                                {
                                    this.setState({ focusedInput })
                                }
                                } // PropTypes.func.isRequired,
                                showClearDates={true}
                                small={true}
                                block={true}
                                //disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}

                            />
                        </Col>
                        <Col md={3}>
                            <Row style={{padding: '0px 10px'}}>
                                <Col md={6} style={{padding: '0px 5px'}}>
                                    <Select
                                        isClearable
                                        isDisabled={this.props.isTimeDisabled}
                                        options={this.state.startTimeOptions}
                                        getOptionLabel={({label}) => label}
                                        getOptionValue={({value}) => value}
                                        placeholder={'Start...'}
                                        value={this.props.startTime}
                                        onChange={this.handleStartTimeChange}
                                        styles={customStyles}

                                    />
                                </Col>
                                <Col md={6} style={{padding: '0px 5px'}}>
                                    <Select
                                        isClearable
                                        isDisabled={this.props.isTimeDisabled}
                                        options={this.state.endTimeOptions}
                                        getOptionLabel={({label}) => label}
                                        getOptionValue={({value}) => value}
                                        placeholder={'End...'}
                                        value={this.props.endTime}
                                        onChange={this.handleEndTimeChange}
                                        styles={customStyles}
                                        minDate={this.props.startTime}

                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    renderSingalValue = (currentAttributeData) => {
        return (
            <Row style={{width: '100%', padding: '0px 15px 0px 15px', justifyContent: 'center'}}>
                {currentAttributeData && currentAttributeData.map((item) => {

                    let isBlink = false;
                    let updatedValue = null;

                    if (this.props.currentAsset && this.props.alarmSet) {

                        let assetid = this.props.currentAsset.assetid;

                        if (this.props.alarmSet[assetid] && this.props.alarmSet[assetid][item.attributename]) {
                            //  
                            isBlink = this.props.alarmSet[assetid][item.attributename].status;
                        }
                    }
                    // -------------------------------------
                    if (this.props.currentAsset && this.props.alarmSetObj) {
                        let assetid = this.props.currentAsset.assetid;
                        this.props.alarmSetObj[assetid] && Object.entries(this.props.alarmSetObj[assetid]).forEach(([key, value], index) => {
                            if (value[item.attributename]) {
                                isBlink = isBlink || value[item.attributename].status;
                            }
                        });
                    }
                    // --------------------------------------

                    return (
                        <Col md='auto' sm={3} xs={6} style={{padding: '0px 5px'}}>
                            <SingleVarValueWidget
                                assetData={item}
                                // attributea={this.state.attributea}
                                attributeb={this.state.attributeb}
                                handleValueClick={this.handleValueClick}
                                // isBlink={isBlink}
                                updatedValue={updatedValue}
                            />
                        </Col>
                    )
                })}
            </Row>
        );

    }

    render() {

        const tabPaneStyle = {backgroundColor: '#F8F8F8', padding: '15px'};
        const setTabStyle = (isActive) => {
            return {
                backgroundColor: isActive ? '#F8F8F8' : 'white'
            }
        };

        const setChartTabStyle = (isActive) => {
            return {
                backgroundColor: isActive ? 'white' : '#F8F8F8'
            }
        };

        // let filtedHistoryData = filterHistoryData(this.props.historyData);
        let filtedHistoryData = this.props.allAttriList;
        // let attrubuteChartData = setAttributeChartData(filtedHistoryData);
        let attrubuteChartData = this.props.history24HObjData;
        if (attrubuteChartData) {
            attrubuteChartData = attrubuteChartData.filter(item => (item.attributename != 'Battery'));
        }
        let currentAttributeData = setCurrentAttribute(this.props.currentAssetData);
        if (this.props.currentAssetData) {
            if (this.props.currentAssetData.grouping != "wind") {
                currentAttributeData.push(ALARM_META_ATTRI_OBJ);
            }
        }

        //  

        // let attrubuteChartData = setAttributeChartData(this.props.historyData); 

        return (
            <Fragment>
                {this.renderFilter()}
                <Nav tabs>
                    <NavItem style={{cursor: 'pointer'}}>
                        <NavLink
                            className={classnames({active: this.state.activeChartTab === '3'})}
                            // style={setChartTabStyle(this.state.activeChartTab === '3')}
                            onClick={() => {
                                this.chartTabToggle('3');
                            }}
                        >
                            Monthly Charts
                        </NavLink>
                    </NavItem>
                    <NavItem style={{cursor: 'pointer'}}>
                        <NavLink
                            className={classnames({active: this.state.activeChartTab === '4'})}
                            // style={setChartTabStyle(this.state.activeChartTab === '4')}
                            onClick={() => {
                                this.chartTabToggle('4');
                            }}
                        >
                            Details
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeChartTab}>
                    <TabPane tabId="3">
                        <div>
                            <Card>
                                <CardBody>
                                    {/* {console.log("===HistoryBoard", this.props.alarmMonthlyCounts)} */}
                                    <Row style={{width: '100%', padding: '0px 15px 0px 15px'}}>
                                        <Col md="12" /* sm={6} xs={9} */ style={{padding: '0px 5px'}}>
                                            <SingleAlarmBarChartWidget
                                                loading={this.props.alarm24HCountLoading}
                                                countData={this.props.alarm24HCounts}
                                                monthlyLoading={this.props.assetMonthlyCountLoading}
                                                monthlyCountData={this.props.alarmMonthlyCounts}
                                                quarterMonthCountData={this.props.alarmQuarterMonthCounts}
                                                sixMonthsCountData={this.props.alarmSixMonthsCounts}
                                                monthlyUnits = {this.props.monthlyCountUnit}
                                                units={this.props.alarm24HCountUnit}
                                                isSmall={true}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    </TabPane>
                    <TabPane tabId="4">
                        <div>
                            <Card style={{width: '100%'}}>
                                <CardBody>
                                    {this.renderSingalValue(currentAttributeData)}
                                    {this.renderTimeFilter()}
                                    <Nav tabs>
                                        <NavItem style={{cursor: 'pointer'}}>
                                            <NavLink
                                                className={classnames({active: this.state.activeTab === '1'})}
                                                style={setTabStyle(this.state.activeTab === '1')}
                                                onClick={() => {
                                                    this.tabToggle('1');
                                                }}
                                            >
                                                Charts
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{cursor: 'pointer'}}>
                                            <NavLink
                                                className={classnames({active: this.state.activeTab === '2'})}
                                                style={setTabStyle(this.state.activeTab === '2')}
                                                onClick={() => {
                                                    this.tabToggle('2');
                                                }}
                                            >
                                                Data
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab}>
                                        <TabPane tabId="1" style={tabPaneStyle}>
                                            <button
                                                className="panel__btn"
                                                style={{ float: "right", marginRight: '2%' }}
                                                onClick={this.onRefresh}
                                            >
                                                <AutorenewIcon/>
                                            </button>
                                            <br/>
                                            <br/>
                                            <div>
                                                <WeatherMixChart
                                                    chartData={this.props.historyObjData}
                                                    attributea={this.state.attributea}
                                                    attributeb={this.state.attributeb}
                                                    countData={this.props.countData}
                                                    units={this.props.countUnit}
                                                    isSmall={this.props.isSmall}
                                                    historyLoading={this.props.assetHistoryLoading}
                                                    countLoading={this.props.assetCountLoading}
                                                    hourlyCountLoading={this.props.assetHourlyCountLoading}
                                                    isDays={this.props.isTimeDisabled}
                                                />
                                                {/* </Col>
                            </Row> */}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2" style={tabPaneStyle}>
                                            <br/>
                                            {/* <Row style={{width: '100%', justifyContent:'center'}}>
                                <Col md={12}> */}
                                            <StatusTable
                                                currentAsset={this.props.currentAssetData}
                                                toggle={this.props.toggle}
                                                // tableData = {this.props.historyData}
                                                tableData={filtedHistoryData}
                                                filterAttr={'Alarm'}
                                            />
                                            {/* </Col>
                            </Row>         */}
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </div>
                    </TabPane>
                </TabContent>
            </Fragment>
        );
    }
}

export default HistoryBoard

function filterHistoryData(historyData) {
    let reData = [];
    if (!historyData) {
        return reData;
    }
    let attributeArr = ATTRIBUTE_ORDER.map((item) => (item.attributename));
    attributeArr.push('Alarm');
    reData = historyData.filter((item) => (item.type === 'device' && attributeArr.includes(item.attributename)));
    return reData;
}

function setAttributeChartData(historyData) {
    let reData = [];
    // if ( !historyData){
    //     return reData;
    // }
    let now = new Date();
    let before24 = new Date(now.getTime() - (24 * 3600 * 1000));
    if (historyData) {
        const data24 = historyData.filter((item, index) => {
            // const itemTime = new Date(item.timestamp);
            const itemTime = new Date(item.attritime);
            return (
                // item.type === 'device' && 
                itemTime >= before24 && itemTime <= now
            )
        });

        const dataMap = groupBy(data24, asset => asset.attributename);
        for (let attr of ATTRIBUTE_ORDER) {
            let attrData = dataMap.get(attr.attributename);
            if (attrData) {
                let currentValue = attrData[attrData.length - 1].value;
                let timestamp = attrData[attrData.length - 1].attritime;
                let dataList = attrData.map((item) => ({x: item.attritime, y: item.value}));
                let attrDataObj = {...attr, value: currentValue, timestamp: timestamp, data: dataList};
                reData.push(attrDataObj);
            }
        }
    } else {
        for (let attr of ATTRIBUTE_ORDER) {
            if (attrData) {

                let dataList = [{x: before24.toString(), y: 0}, {x: now.toString(), y: 0}];
                let attrDataObj = {...attr, value: 0, timestamp: null, data: null};
                reData.push(attrDataObj);
            }
        }
    }
    return reData;
}

function setCurrentAttribute(currentAssetData) {
    let reData = [];
    if (!currentAssetData) {
        return reData;
    }
    if (currentAssetData && currentAssetData.attributes)
        for (let attr of ATTRIBUTE_ORDER) {
            if (!attr.selected) {
                continue;
            }
            let attrObj = currentAssetData.attributes.find((item) => (attr.attributename == item.attributename && item.attributename != 'Battery'));
            if (attrObj) {
                let currentValue = attrObj.value;
                let timestamp = currentAssetData.timestamp;
                // let dataList = attrData.map((item) => ({x: item.attritime, y: item.value}));
                let attrDataObj = {...attr, value: currentValue, timestamp: timestamp};
                reData.push(attrDataObj);
            }
        }
    return reData;
}
