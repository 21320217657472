/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getActionLog} from '../../../../redux/actions/jobAction';
import { Col, Row, Label, Input, Button, Container, Card, CardBody } from 'reactstrap';
import BaseWidget from './BaseWidget';
import SimpleNameEditForm from  './SimpleNameEditForm';

class AlertTableWidget extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        this.props.getActionLog();
    }

    renderAlertTable = (layout, pubsubData) => {
        const options = {
          // page: 1,  // which page you want to show as default
          // sizePerPageList: [ {
          //   text: '5', value: 5
          // }, {
          //   text: '10', value: 10
          // }, {
          //   text: 'All', value: this.props.currentDeviceList.length
          // } ], // you can change the dropdown list for size per page
          // sizePerPage: 3,  // which size per page you want to locate as default
          // pageStartIndex: 1, // where to start counting the pages
          // paginationSize: 5,  // the pagination bar size.
          // prePage: '<', // Previous page button text
          // nextPage: '>', // Next page button text
          // firstPage: '<<', // First page button text
          // lastPage: '>>', // Last page button text
          // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
          // paginationPosition: 'top'  // default is bottom, top and both is all available
          // hideSizePerPage: true > You can hide the dropdown for sizePerPage
          // alwaysShowAllBtns: true // Always show next and previous button
          // withFirstAndLast: false > Hide the going to First and Last page button
        };
        let actionLogs =  [];
        if (this.props.actionLogs ){
          actionLogs = this.props.actionLogs.reverse(); 
        }
        let maxHeight = JSON.parse(layout.position).h * 30 - 20;
    
        return(
        <Row>
          <Col md={12}>
              {/* <Card>
                  <CardBody> */}
                      <BootstrapTable data={ actionLogs } tableHeaderClass = 'header_white' pagination = {false} options={ options }     search bordered={ false } maxHeight={maxHeight.toString()}>
                          <TableHeaderColumn dataField='actionid' isKey={ true } dataAlign="center" width='15%' hidden >Action ID</TableHeaderColumn>
                          {/* <TableHeaderColumn dataField='assetname' dataAlign="center" dataSort>Asset Name</TableHeaderColumn>                        */}
                          <TableHeaderColumn dataField='alerttime' dataAlign="center" dataFormat = {this.timeColFormater} dataSort >Alert Time</TableHeaderColumn>
                          <TableHeaderColumn dataField='alertmsg' dataAlign="center" dataSort>Message</TableHeaderColumn>
                      </BootstrapTable>
                  {/* </CardBody>
              </Card> */}
          </Col>
        </Row>
        );
    }

    renderNameEditForm = (defaultLayout, onOkClick, onCancleClick) => {
        let layoutid = defaultLayout && defaultLayout.layoutid ? defaultLayout.layoutid : null;
         
        return (
            <SimpleNameEditForm
                key={layoutid}
                defaultLayout={defaultLayout}
                onOkClick={onOkClick}
                onCancleClick={onCancleClick}
            />
        )
    }

    render(){
        return (
            <BaseWidget
                dashName={this.props.dashName}
                layout={this.props.layout}
                pubsubData={null}
                renderEditForm={this.renderNameEditForm}
                renderContent={this.renderAlertTable}
                editHandler={this.props.onEditSubmit}
                removeHandler={null}
            />
        );
    }
}

function mapStateToProps(state) {
    //  
    const actionLogs = state.job.actionLogs;    
    const props = { actionLogs };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getActionLog }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertTableWidget);