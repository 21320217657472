/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Map as MapAlias, GoogleApiWrapper, Marker, InfoWindow, Polyline } from 'google-maps-react';
import InfoWindowEx from './InfoWindowEx';
import { Container, Row, Col, Button, Table, Input, Label, Collapse } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { loadAllGeoFences } from '../../../redux/actions/fenceAction';
import MarkerClusterer from "@google/markerclusterer";
import { MagnifyIcon, CloseIcon, CircleOutlineIcon } from 'mdi-react';
// import TortoiseMapInfo form './TortoiseMapInfoWin';
import { setDonut } from '../../../factories/weather';
import { debounce } from 'lodash';
import { curveNatural, dispatch } from 'd3';
import ClusterWrapper from './ClusterWrapper';
import TortoiseMapInfo from './TortoiseMapInfo';
// import TortoiseHistoryInfo from './TortoiseHistoryInfo';
import { LIGHT_GREY_COLOR } from '../../../constants/dataConstants';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { getSelectStyles } from '../../../factories/utils';


export class TortoiseMap extends Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    this.myMap = React.createRef();
    this.state = {
      msg: '',
      collapse: true,
      assetSelector: false,
      mapData: [],
      assetName: [],
      assetList: [],
      startDate: null,
      endDate: null,
      historyArray: [],
      renderMarkers: [],
      showingInfoWindow: false,
      activeMarker: null,
      selectedPlace: null,
      activeMarkerLat: null,
      activeMarkerLng: null
    };

    this.lineSymbol = {
      path: 'M-2,4 0,0 M2,4 0,0',
      strokeOpacity: 1,
      scale: 2,
    };

    this.generateIcon = this.generateIcon.bind(this)
    this.handleAssetChange = this.handleAssetChange.bind(this)
    this.formatMapData = this.formatMapData.bind(this)
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this)
    this.getRenderMarkers = this.getRenderMarkers.bind(this)
    this.handleMapMarkerMouseOver = this.handleMapMarkerMouseOver.bind(this)
    this.handleHistoryMarkerMouseOver = this.handleHistoryMarkerMouseOver.bind(this)
    this.handleHistoryMarkerMouseOut = this.handleHistoryMarkerMouseOut.bind(this)
    this.groupedByDateHistory = this.groupedByDateHistory.bind(this)
    this.generateHistoryPathAndMarkers = this.generateHistoryPathAndMarkers.bind(this);
  }

  componentDidMount () {
    // console.log("MAP DATA", this.props.mapData)
    // console.log("ASSET DATA", this.props.assetData, this.props.statusData)
    // console.log(this.formatMapData(this.props.statusData))
    if(this.props.statusData){
      let {formattedData,filterData} = this.formatMapData(this.props.statusData, this.state.selectedValue);
      // filterData.push({
      //   value: "-1",
      //   label: this.state.mapData.length < 2 ? "Reset Selection" : "--- Select a station ---",
      // });
      this.setState({
        ...this.state,
        assetName: filterData,
        mapData: formattedData,
        renderMarkers: this.getRenderMarkers(formattedData)
      })
  
      // console.log("==SELECT OPTIONS===", assetNames)
    }
    if(this.props.assetHistoryData){
      // console.log("HISTORY", this.props.assetHistoryData)
    }
  }
  componentDidUpdate (prevProps, prevState) {
    // console.log("PREV PROPS", prevProps.statusData);
    if(this.props.statusData && this.props.statusData.length > 0 && this.props.statusData.length !== prevProps.statusData.length){
      //console.log("ASSET DATA", this.props.assetData, this.props.statusData)
      let {formattedData, filterData} = this.formatMapData(this.props.statusData, this.state.selectedValue);
      //console.log('SELECT MAP DATA', formattedData)

        // assetNames.push({
        //   value: "-1",
        //   label: this.state.mapData.length < 2 ? "Reset Selection" : "--- Select a station ---",
        // });
      this.setState({
        ...this.state,
        assetName: filterData,
        mapData: formattedData,
        renderMarkers: this.getRenderMarkers(formattedData)
      })

    }
    if(this.state.selectedValue != prevState.selectedValue) {
      // console.log("==STATUS DATA, ",this.props.statusData);
      // console.log("==ASSET SELECTOR, ",this.state.assetSelector);
      // this.state.mapData = this.props.statusData;
      let {formattedData, filterData} = this.formatMapData(this.props.statusData, this.state.selectedValue)
      //console.log('SELECT MAP DATA', formattedData)
      // let assetNames = []
      // if(selectMapData){
      //   selectMapData.map(asset => {
      //     var obj = {
      //       value: asset,
      //       label: asset.assetName,
      //       locationlat: asset.locationlat,
      //       locationlng: asset.locationlng
      //     }
  
      //     assetNames.push(obj);
      //   });
      // }
      this.setState({
        ...this.state,
        assetName: filterData,
        mapData: formattedData,
        renderMarkers: this.getRenderMarkers(formattedData),
        historyMarkers: this.state.historyMarkers ? this.state.historyMarkers.filter(item=> { item.props.attributes.asset == this.state.selectedValue}) : null
      })

    }
    if(this.props.assetHistoryData && JSON.stringify(this.props.assetHistoryData) !== JSON.stringify(prevProps.assetHistoryData) || ((this.state.selectedValue !== prevState.selectedValue) && this.state.endDate)){
      // console.log("CHANGE POLYLINE")
      let historyLocations = this.props.assetHistoryData.filter(item=>(item.attributename.includes("Latitude") || item.attributename.includes("Longitude")))
      if(this.state.selectedValue){
        historyLocations = historyLocations.filter(item=>item.assetname == this.state.selectedValue)
        // console.log('HISTORY RAW DATA', historyLocations)
        let latData = historyLocations.filter(item=>item.attributename.includes("Latitude"));
        let lngData = historyLocations.filter(item=>item.attributename.includes("Longitude"));
        // console.log(latData.length, lngData.length);
        // let nullVals = historyLocations.filter(x=>isNaN(parseFloat(x.value)))
        // console.log("NULL VALUES, ", nullVals)
      }
      let groupedByDateHistory = this.groupedByDateHistory(historyLocations);
      let {polylinePaths, historyMarkers} = this.generateHistoryPathAndMarkers(groupedByDateHistory);

      //console.log("GROUPED BY TIME", groupedByDateHistory, polylinePaths)
      //console.log('History Markers', historyMarkers)
      this.setState({
        ...this.sate,
        historyArray: polylinePaths,
        historyMarkers: historyMarkers
      }, ()=>{
        //console.log('POLY path', polylinePaths)
      })
    }

  }

  groupedByDateHistory = (historyLocations)=>{
    let groupedByDateHistory
    if(historyLocations.length > 0){
      let nullLocations = historyLocations.filter(x=>x.value== null);

      // console.log(" HISTORY LOCATIONS, ", nullLocations, historyLocations)
      groupedByDateHistory = historyLocations.reduce((agg,curr)=>{
        // console.log("AGG", agg)
        let found = agg.find(x=>x.timestamp ===curr.timestamp);
        if(found){
          // console.log("FOUND",found)
          if(curr.attributename == "Latitude"){
            found.coords.lat = curr.value
          }else{
            found.coords.lng = curr.value
          }
        }else{
          agg.push({
            timestamp: curr.timestamp,
            asset: curr.assetname,
            coords: {lat: curr.attributename == "Latitude" ? curr.value: null, lng: curr.attributename == "Longitude" ? curr.value : null }
          })
        }
        return agg;
      }, [])
    }
    //console.log("GROUPED BY DATE HISTORY", groupedByDateHistory)
    return groupedByDateHistory
  }

  generateHistoryPathAndMarkers = (groupedByDateHistory) => {
    let polylinePaths = [];
    let historyMarkers = []; 
    if(groupedByDateHistory){
      groupedByDateHistory.forEach(item=>{
        let position = ({lat: parseFloat(item.coords.lat), lng: parseFloat(item.coords.lng)})
        polylinePaths.push(position)
        historyMarkers.push(
          <Marker
          onMouseover={this.handleHistoryMarkerMouseOver}
          // onClick={this.handleHistoryMarkerClick}
          onMouseout={this.handleHistoryMarkerMouseOut}
          position = {position}
          name={item.assetName}
          attributes={item}
          icon={
            {
              path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0",
              fillOpacity: .9,
              fillColor: "#4299f5",
              strokeColor: '#4299f5',
              strokeWeight: 0,
              scale: .5,
              anchor: new google.maps.Point(0,0),
            }
          }
        />
        )
      })
    }

    return {polylinePaths, historyMarkers}
  }

  getRenderMarkers = (data) =>{
    const defaultIconPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0";
    // const defaultIconPath = "M125 410 c-56 -72 -111 -176 -120 -224 -7 -36 11 -83 49 -124 76 -85 223 -67 270 31 28 60 29 88 6 150 -19 51 -122 205 -148 221 -6 3 -32 -21 -57 -54z m110 -175 c35 -34 33 -78 -4 -116 -35 -35 -71 -37 -105 -7 -40 35 -43 78 -11 116 34 41 84 44 120 7z";
    const defaultColor = "#7d7272";
    let markers = []
    //console.log("RENDER MARKERS")
    if(data && data.length > 0){
      data.map((item,index)=>{
        ////console.log("MARKER ITEM", item);
        let position = {lat: item.locationlat, lng: item.locationlng}
        let path = defaultIconPath
        let color = defaultColor
        markers.push(
          <Marker
            onMouseover={this.handleMapMarkerMouseOver}
            onClick={e => this.handleMarkerClick(item.assetid)}
            onMouseout={this.handleMapMarkerMouseOut}
            position = {position}
            name={item.assetname}
            attributes={item}
            icon={
              {
                path: path,
                fillColor: defaultColor,
                fillOpacity: .9,
                fillColor: color,
                strokeColor: '#000',
                strokeWeight: 0,
                scale: 1,
                anchor: new google.maps.Point(0,0),
              }
            }
          />

        )
      })
    }
    return markers
  }

  handleMarkerClick = (assetid) =>{
    let path = '/pages/surveillance';
    let asset = this.props.assetList? this.props.assetList.find(asset=>asset.assetid == assetid) : null
    let parentasset = asset && asset.parentasset ? this.props.assetList.find(a=>a.assetid == asset.parentasset) : null
    let opgLayout = this.props.assetList.find(a=>a.assetname == 'OPG Office')
    let selectedFloor
    if(opgLayout){
      //console.log("OPG LAYOUT,", opgLayout)
      let opgFloors = opgLayout.assetattributes.filter(at=>at.attmetaname.includes('floor'))
      selectedFloor = opgFloors.map(object=>object.value).indexOf(parentasset.assetid.toString())
      //console.log("SELECTED FLOOR, ", selectedFloor)
      if(parentasset){
        this.props.history.push({
          pathname: path,
          state:{
            selectedPlace: opgLayout,
            selectedFloor: selectedFloor
          }
        });
      }
    }
  }

  handleHistoryMarkerMouseOver = (props, marker, e)=> {
    setTimeout(()=>{this.setState({
      showingHistoryInfoWindow: true,
      activeHistoryMarker: marker,
      selectedHistoryPlace: props,
      activeHistoryMarkerLat: props.position.lat,
      activeHistoryMarkerLng: props.position.lng
    })}, 400)
  }

  handleHistoryMarkerMouseOut = ()=>{
    this.handleHistoryInfoWindowClose();
  }

  componentWillReceiveProps(nextProps) {
    
  }

  setSelection = (googleShape) => {
    this.selectedShape = googleShape;
  }

  deleteSelectedShape = () => {
    if(this.selectedShape) {
      this.selectedShape.setMap(null);
    }
  }

  showShapeOnMap = (shape) => {
    if(shape && this.myMap && this.myMap.current) {
      let mapComponent = this.myMap.current;
      let google = mapComponent.props.google;
      let map = mapComponent.map;
      this.deleteSelectedShape();
      this.setShape(google, map, shape);
    } else {
      this.deleteSelectedShape();
    }
  }
  setShape = (google, map, shape) => {
    let self = this;
    let newCenter;
    let bounds;
    if(shape) {
      switch(shape.type) {
        case "circle":
          const initCircle = new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            center: shape.data.center,
            raduis: shape.data.radius,
            editable: false
          });

          bounds = initCircle.getBounds();
          newCenter = initCircle.getCenter();
          map.setCenter(newCenter);
          map.fitBounds(bounds);
          this.setSelection(initCircle);

          break;
        case "rectangle":
          const initRectangle = new google.maps.Rectangle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            bounds: shape.data,
            editable: false
          });

          bounds = initRectangle.getBounds();
          newCenter = bounds.getCenter();
          map.setCenter(newCenter);
          map.fitBOunds(bounds);
          this.setSelection(initRectangle);

          break;
        case "polygon":
          google.maps.Polygon.prototype.getBoundingBox = function () {
            var bounds = new google.maps.LatLngBounds();
            this.getPath().forEach(function (element, index) {
              bounds.extend(element)
            });
            return (bounds);
          }

          const initPolygon = new google.maps.Polygon({
            paths: shape.data,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            map: map,
            editable: false
          });

          bounds = initPolygon.getBoundingBox();
          newCenter = bounds.getCenter();
          map.setCenter(newCenter);
          map.fitBounds(bounds);
          this.setSelection(initPolygon);
          
          break;
      }
    }
  }

  setMapBounds = (mapData) => {
    if(this.myMap && this.myMap.current) {
      let mapComponent = this.myMap.current;
      let google = mapComponent.props.google;
      let map = mapComponenet.map;
      if(google, map) {
        let bounds = null;
        if(mapData && mapData.length > 0) {
          bounds = new google.maps.LatLngBounds();
          mapData.forEach((location) => {
            bounds.extend(
              new google.maps.LatLng(location.locationlat, location.locationlng),
            );
          });
        }
        if(bounds) {
          map.fitBounds(bounds);
        }
      }
    }
  }

  getMapBounds = (google, map) => {
    let bounds = null;
    if(this.props.mapData && this.props.mapData.length > 0) {
      bounds = new google.maps.LatLngBounds();
      this.props.mapData.forEach((location) => {
        bounds.extend(
          new google.maps.LatLng(location.locationlat, location.locationlng),
        );
      });
    }
    return bounds;
  }

  getCompanyBounds = (google, map) => {
    let bounds = null; 
    if(this.props.userObj.company && this.props.userObj.company.businessArea) {
      let points = JSON.parse(this.props.userObj.company.businessArea);
      bounds = new google.maps.LatLngBounds();
      points.forEach((point, index) => {
        bounds.extend(
          new google.maps.LatLng(point.lat, point.lng),
        );
      });
    }

    return bounds;
  }

  initMap(mapProps, map) {
    var self = this;
    const { google } = mapProps;
    let bounds = this.getMapBounds(google, map);
    if(bounds) {
      map.fitBounds(bounds);
    }

    let initStyles = [
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          { visibility: "off"},
        ]
      }
    ];

    map.setOptions({ styles: initStyles });
    self.setShape(google, map, self.state.shape);
    var input = ReactDOM.findDOMNode(self.refs.inputTest);
    var searchBox = new google.maps.places.SearchBox(input);
    map.addListener("bounds_changed", function () {
      searchBox.setBounds(map.getBounds());
    });
    searchBox.addListener("places_changed", () => {
      var places = searchBox.getPlaces();
      if(places.length == 0) {
        return;
      }

      map.setCenter(places[0].geometry.location);
    });

    var controlMarkerUI = document.createElement('div');

    let searchBtn = 
    <button 
      style={{ border: 0, background: "none", backgroundColor: "white", boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;", height: "40px", width: "40px", margin: "10px"}}
      onClick={self.mapSearchBarToggle}
    >
      <MagnifyIcon size={25}/>
    </button>;

    ReactDOM.render(searchBtn, controlMarkerUI);
    map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlMarkerUI);

  }

  handleFenceChange = (val) => {
    let id = val.value;
    let fence = this.props.allfences.find((e, i) => (e.fenceid == id));
    let currentPolygonData = null;
    let currentRectangleData = null;
    let currentCircleData = null;
    let selectedShape = null;
    let type = "";
    
    if(fence) {
      switch(fence.shape) {
        case 0:
          type = "circle";
          currentCircleData = JSON.parse(fence.geodata);
          break;
        case 1:
          type = "rectangle"  ;
          currentRectangleData = JSON.parse(fence.geodata);
          break;
        case 2:
          type = "polygon";
          currentPolygonData = JSON.parse(fence.geodata);
          break;
      }

      selectedShape = { type: type, data: JSON.parse(dence.geodata) };
    }

    this.setState({
      shape: selectedShape,
    });

    this.showShapeOnMap(selectedShape)
  }

  handleAssetChange = (val) => {
    //console.log('HANDLE FILTER change', val)
    if(val.value == "-1") {
      this.setState({mapData: this.formatMapData(this.props.statusData, null)})
      // this.state.mapData = this.props.statusData;
      this.setState({
        assetSelector: false,
        selectedValue: null
      })
      // this.state.assetSelector = false;
      // this.forceUpdate();
    } else {
      ////console.log('SELECTED ',val, val.value)
      this.setState({
        // mapData: [val.value],
        assetSelector: true,
        selectedValue: val.label
      })
    }
  }

  mapSearchBarToggle = () => {
    this.setState(prevState => ({
      collapse: !prevState.collapse,
    }));
  }

  allAssets = () => {
    this.setState({assetSelector: false})
    this.setState({mapData: this.formatMapData(this.props.statusData, null)})
    // this.forceUpdate();
  }

  formatMapData = (mapData, assetName) => {
    let map1 = new Map();
    let set1 = new Set();
     
    for(let i = 0; i < mapData.length; i++) {
      let  data = mapData[i];
      if(data.attributes) {
        let attributes = data.attributes
        for(let key of Object.keys(attributes)){
          // console.log(`${key}:`, attributes[key])
          for(const obj of attributes[key]) {
            // console.log(obj)
            map1.set(obj.deviceid + ":" + key, obj.value)
            set1.add(obj.deviceid);

          }
        }
      }
    };

    let listDevices = [];
    for(const device of set1) {
      // console.log(device);
      this.device = {};
      for(const [key, value] of map1) {
        if(key.startsWith(device)) {
          // console.log(key)
          let local = key.split(":")[1];
          this.device[local] = value;
        }
      }

      let pushObject = {};
      pushObject[device] = this.device;
      listDevices.push(pushObject);
    }

    let formattedData = [];
    let filterData = []
    for(let i = 0; i < mapData.length; i++) {
      let asset = new Object(mapData[i])
      for(let value of Object.keys(asset)) {
        if(value == "attributes" && asset[value]) {
          for (let key of Object.keys(asset[value])) {
            // console.log("===key", key);
            if(key == "MessageId") {
              for(let noOfAsset of Object.keys(asset[value][key])){
                let deviceIdToMatch = asset[value][key][noOfAsset].deviceid;
                let deviceFromList;
                for(let j = 0; j <listDevices.length; j++) {
                  if(Object.keys(listDevices[j])[0] == deviceIdToMatch) {
                    deviceFromList = Object.values(listDevices[j])[0];
                    break;
                  }
                }

                let assetModify = new Object();
                assetModify.devicenamealias = asset.devices[0].deviceNameAlias;
                assetModify.assetid = asset.assetid;
                assetModify.assetName = asset.assetname;
                assetModify.locationlat = deviceFromList.Latitude;
                assetModify.locationlng = deviceFromList.Longitude;
                assetModify.messageId = deviceFromList.MessageId;
                assetModify.cn0_ok = deviceFromList.CN0_OK;
                assetModify.zoneId = deviceFromList.ZoneId;
                assetModify.beaconId = deviceFromList.BeaconId;
                assetModify.moveDetect= deviceFromList.MoveDetect;
                assetModify.gpsLock = deviceFromList.GPSLock;
                assetModify.gpsUsed = deviceFromList.GPSUsed;
                assetModify.beaconVisible = deviceFromList.BeaconVisible;
                assetModify.hdop = deviceFromList.HDOP;
                assetModify.battery = deviceFromList.Battery;
                assetModify.timestamp = deviceFromList.LastMessageTime;

                let attributesToAdd = new Object();
                let listToFormatter = ['MessageId', 'Battery', 'Latitude', 'Longitute', 'DeviceNameAlias', 'CN0_OK', 'ZoneId', 'MoveDetec', 'GPSLock', 'BeaconVisible', 'HDOP', 'Battery', 'LastMessageTime']

                for(var n = 0; n < listToFormatter.length; n++) {
                  attributesToAdd[listToFormatter[n]] = {"deviceid": deviceIdToMatch, "value": deviceFromList[listToFormatter[n]], "devicename": undefined, status: false}
                }

                filterData.push({
                  value: assetModify,
                  label: assetModify.assetName,
                  locationlat: assetModify.locationlat,
                  locationlng: assetModify.locationlng
                })

                if(assetName && !assetModify.assetName.includes(assetName)){
                  break;
                }

                formattedData.push(assetModify);
              }
            }
          }
        }
      }
    }

   return {formattedData, filterData}
  }

   generateIcon(color, path) {
    let myPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0";
    if(path && path.length > 0) {
      myPath = path;
    }

    return (
      {
        path: myPath,
        fillColor: color,
        fillOpacity: 1,
        strokeColor: '#000',
        strokeWeight: 0,
        scale: 0.1,
        anchor: new google.maps.Point(185, 500)
      }
    )
  };

  renderInfoWin() {
    if(this.state.selectedPlace.attr) {
      return (
        Object.keys(this.state.selectedPlace.attr).map((key, i) => (
          key != "Lat" && key != "Lng" &&
          <tr>
            <td>{key}</td>
            <td>{this.state.selectedPlace.attr[key].value}</td>
          </tr>
        ))
      )
    } else{
      return null;
    }
  }

  handleMapInfoWinClose = (e) =>{
    this.setState({
      showingInfoWindow: false
    })
  }


  handleHistoryInfoWindowClose = (e) =>{
    this.setState({
      showingHistoryInfoWindow: false
    })
  }

  handleMapMarkerMouseOver = (props, marker, e)=>{
    setTimeout(()=>{this.setState({
      showingInfoWindow: true,
      activeMarker: marker,
      selectedPlace: props,
      activeMarkerLat: props.position.lat,
      activeMarkerLng: props.position.lng
    })}, 400)
  }

  handleMapMarkerMouseOut = (props, marker, e)=>{
    this.handleMapInfoWinClose()
  }

  handleDateRangeChange = ({ startDate, endDate }) => {
   
    //  
    //  
    this.setState({
        startDate: startDate,
        endDate: endDate
    }, ()=>{
      //passing date in miliseconds to MainBoard
      this.props.handleMapDateChange({startDate: moment(this.state.startDate).valueOf(), endDate: moment(this.state.endDate).valueOf()})
    })
  }

  resetDateRange = ()=>{
    this.setState({
      historyArray: [],
      historyMarkers: null
    })
  }

  dataMomentValidation = (startMoment, endMoment) => {
    let reVal = false;
    if (startMoment && endMoment){
        reVal = endMoment.isAfter(startMoment);
        if (!reVal) {
            toast.error('End date should be later than start date.');
        }
    }else if (!startMoment && !endMoment){
        reVal = true;
    }

    return reVal;
}

  render() {
    const mapStyle = {
      width: "100%",
      height: "67vh",
      position: "static",
      paddingTop: 10,
    };

    const mapContainerStyle = {
      width: '100%',
      position: 'relative'
    };

    // let v = this.state.selectedPlace;
    // let lat = this.state.lat;
    // let lng = this.state.lng;

    let icon = {
      path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0", //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
      fillColor: '#FF0000',
      fillOpacity: .6,
      anchor: new google.maps.Point(0, 0),
      strokeWeight: 0,
      scale: 1
    };

    const defaultIconPath = "M125 410 c-56 -72 -111 -176 -120 -224 -7 -36 11 -83 49 -124 76 -85 223 -67 270 31 28 60 29 88 6 150 -19 51 -122 205 -148 221 -6 3 -32 -21 -57 -54z m110 -175 c35 -34 33 -78 -4 -116 -35 -35 -71 -37 -105 -7 -40 35 -43 78 -11 116 34 41 84 44 120 7z";
    const defaultColor = "#FF0000";



    if(this.props.mapShow) {
      this.setMapBounds(this.state.mapData);
    }

    // this.state.assetName =[];

    // this.state.mapData.map(asset => {
    //   var obj = {
    //     value: asset,
    //     label: asset.assetName,
    //     locationlat: asset.locationlat,
    //     locationlng: asset.locationlng
    //   }

    //   this.state.assetName.push(obj);
    //   return (0);
    // });
    // console.log('MAP DATA TORTOISE', this.state.mapData)
    // console.log('MAP DATA', this.state.mapData)
    return (
      <Row>
        <Col md={12}>
          <div style={{ position: "relative" }}>
            <MapAlias
              ref={this.myMap}
              google={this.props.google}
              zoom={9}
              onReady={this.initMap}
              containerStyle={mapContainerStyle}
              style={mapStyle}
              // initialCenter={{ lat:43.6907917, lng: -79.4761116 }}
              initialCenter={{ lat:43.77513, lng: -79.3377 }}
            >
              {this.state.renderMarkers && this.state.renderMarkers.map((m,index)=>{
                return m
              })}

              {this.state.historyMarkers && this.state.historyMarkers.map((m, index)=>{
                return m
              })}
              {/* <ClusterWrapper
                mapData={this.state.mapData}
                defaultIconPath={defaultIconPath}
                defaultColor={defaultColor}
                generateIcon={this.generateIcon}
                onMarkerClick={this.props.onMarkerClick}
                onMarkerHover={this.props.onMarkerHover}
                onMarkerOut={this.props.handleMapInfoWinClose}
                alarmSetObj={this.props.alarmSetObj}
                statusData={this.props.statusData}
                initialCenter={{ lat:43.77513, lng: -79.3377 }}
              /> */}

              {this.state.historyArray && this.state.historyArray.length > 0 && 
                  <Polyline
                  path={this.state.historyArray}
                  strokeColor="#0000FF"
                  strokeOpacity={0.8}
                  strokeWeight={2}
                  icons={[{
                      // icon: lineSymbol,
                      // offset: '100%',
                      icon: this.lineSymbol,
                      offset: '70%',
                      // repeat: '100px'
                  }]}
                  />
              }
              <InfoWindowEx
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
                onClose={this.handleMapInfoWinClose}
              >
                <div style={{ marginLeft: "8px"}}>
                  <TortoiseMapInfo
                    props={this.state.selectedPlace}
                    toggle={this.props.toggle}
                    alarmSetObj={this.props.alarmSetObj}
                    statusDataProp={this.props.statusDataProp}
                  />
                </div>
              </InfoWindowEx>

              <InfoWindowEx
                marker={this.state.activeHistoryMarker}
                visible={this.state.showingHistoryInfoWindow}
                onClose={this.handleHistoryInfoWindowClose}
              >
                <div style={{ marginLeft: "8px"}}>
                  {/* <TortoiseHistoryInfo
                    props={this.state.selectedHistoryPlace}
                    toggle={this.props.toggle}
                    // alarmSetObj={this.props.alarmSetObj}
                    // statusDataProp={this.props.statusDataProp}
                  /> */}
                </div>
              </InfoWindowEx>
            </MapAlias>
            <Collapse
              isOpen={this.state.collapse}
              style={{
                width: "100%",
                position: "absolute",
                zIndex: 900,
                backgroundColor: "grey",
                opacity: 0.9,
                paddingLeft: "5px",
                paddingTop: "5px",
                paddingBottom: "5px"
              }}
            >
              <Row>
                <Col md={5}>
                  <Select
                    value={this.state.selectedAssetOption}
                    options={this.state.assetName}
                    onChange={this.handleAssetChange}
                    menuPlacement="top"
                    placeholder={"Select Asset..."}
                    styles = {getSelectStyles()}
                  />
                </Col>
                <Col md={2}>
                  <Input
                    id="pac-input"
                    class="controls"
                    type="text"
                    placeholder="Search by address"
                    ref="inputTest"
                    style={{ width: "100%"}}
                  />
                </Col>
                <Col md={4}>
                    {/* <Label for="endTime"  style={{display: 'inline', width: "100%"}} ><h4><b>From ~ To</b></h4></Label> */}
                    <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                        onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        showClearDates = {true}
                        small = {true}
                        block = {true}
                        disabled = {!this.state.mapData || this.state.mapData.length == 0}
                        openDirection="up"
                        maxDate={new Date()}
                        onClose={this.resetDateRange}
                        
                        />
                </Col>
                <Col md={1} style={{ textAlign: "right" }}>
                  <botton
                    style={{ border:0, background: "none", height: "35px", width: "35px", hidden: true}}
                    onClick={this.mapSearchBarToggle}
                  >
                    <CloseIcon
                      onClick={this.allAssets}
                      size={25}
                    />
                  </botton>
                </Col>
              </Row>
            </Collapse>
            {this.props.loading &&
              <div style={{
                  position: 'Absolute',
                  height: '100%',
                  width: '100%',
                  top: '0px',
                  left: '0px',
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                  backgroundColor: LIGHT_GREY_COLOR,
                  opacity: '0.6',
                }}
              >
                <Spinner
                  className='spinner'
                  as='span'
                  animation='border'
                  variant='primary'
                  style={{ display: 'inline-block', margin: 'auto' }}
                />
              </div>
            }
          </div>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
  const allfences = state.fence.fenceData;
  const fenceObj = state.fence.fenceObj;
  const fenceStatus = state.fence.type;
  const assetList = state.asset.assetList
  const props = { allfences, fenceObj, fenceStatus, assetList};
  return props;
};

const mapDispatchToProps = (dispach) => {
  return bindActionCreators({ loadAllGeoFences }, dispatch);
};

const enhance = compose(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["drawing", "places"]
  }),

  connect(mapStateToProps, mapDispatchToProps)
);

export default withRouter(enhance(TortoiseMap));
