/* eslint-disable */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {Row, Col, Card, CardBody, Label, Input, Button, Table} from 'reactstrap';
import { uploadFile } from '../../../redux/actions/inventoryActions';

class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seletedFile: null,
    };
  }

  onFileChangeHandler = (e) => {
    this.props.uploadFile(e.target.files[0]);
  }

  render() {
    return (
      <Col md={4}>
        <Card>
            <div className="form-group files color">
              {/* <label htmlFor="firstName">
                <input type="file" className="form-control" name="file" onChange={this.onFileChangeHandler} />
              </label> */}
              <input type="file" name="file" class="custom-file-input" id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01" onChange={this.onFileChangeHandler}/>
              <label class="custom-file-label" for="inputGroupFile01">Choose a CSV file</label>
            </div>
            </Card>
      </Col>
       
    );
  }
}

function mapStateToProps(state) {
   
   
  const props = state.job;
  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ uploadFile }, dispatch);
}

export default connect(null, mapDispatchToProps)(Uploader);
