/* eslint-disable */
import React, { Component } from 'react';
import { ChromePicker} from 'react-color';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class AttributeSetModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            showColorPicker: false
        }
    }

    handleLowValueOnchang =(e) => {
        this.props.onLowValueChange(e);
    }

    handleUpValueOnchang =(e) => {
        this.props.onUpValueChange(e);
    }

    handleChangeComplete =(c,e) => {
        this.props.onColorValueChange(c,e)
    }

    handleShowColorClick = (e) => {
        e.stopPropagation();
        this.setState(
            (preState) =>(
                {showColorPicker: !preState.showColorPicker}
            )
        )
    }

    closeColorPicker = () => {
        // alert('pass');
        this.setState(
            (preState) =>{
                if (preState.showColorPicker){
                    return({showColorPicker: false});
                }
            }
        )
    }

    handleColorOnClick = (event) => { 
        // alert('click event');
        event.preventDefault();
        event.stopPropagation();
    }

    OnSaveClick = (e) => {
        this.props.onSaveAttrSet(e);
        this.props.toggle();
    }

    render(){
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle} onClick={this.closeColorPicker}>
                <ModalHeader toggle={this.toggle}>New Entry</ModalHeader>
                <ModalBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="lowinput" >Low Value</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="text" id="lowinput" name="lowinput" width="90%" value={this.props.lowvalue} onChange={this.handleLowValueOnchang} >                                    
                                </Input>
                            </Col>
                    </Row>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="upinput" >Up Value</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="text" id="upinput" name="upinput" width="90%" value={this.props.upvalue} onChange={this.handleUpValueOnchang} >                                    
                                </Input>
                            </Col>
                    </Row>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="upinput" >Color</Label>
                            </Col>
                            
                            <Col md={8}>
                                {/* <Input type="text" id="colorinput" name="colorinput" width="60%" style={{float: 'left'}} value={this.props.colorvalue} onClick={this.handleShowColorClick} ></Input> */}
                                <div style={{backgroundColor: this.props.colorvalue, width: 20, height: 20, float: 'left'}} onClick={this.handleShowColorClick} ></div>
                                <div  style={{position:'relative'}}>
                                    <div style={{position:'absolute',top:20, zIndex:100}}>
                                        {this.state.showColorPicker &&
                                        <div onClick={this.handleColorOnClick}>
                                        <ChromePicker
                                            color={this.props.colorvalue}
                                            onChangeComplete={ this.handleChangeComplete }                                                                       
                                        />
                                        </div>}
                                    </div>
                                </div>
                            </Col>
                    </Row>
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.OnSaveClick}>Ok</Button>{' '}
                    <Button color="custom" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }
}

export default AttributeSetModal;

