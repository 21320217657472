/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import {EditableText} from '../../../Asset/components/ClassDisplayDetail';

const WidgetTitleEditComponent = ({defaulLayout, onChangeHandler}) => {
    const onInputChange = (val) => {
        const newLayout = JSON.parse(JSON.stringify(defaulLayout));
        newLayout.name = val;
        onChangeHandler(newLayout);
    }
    return (        
        <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
            <Col md={3}>
                <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
            </Col>
            <Col md={8}>
                {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                <EditableText
                    type='text'
                    defaultValue={defaulLayout ? defaulLayout.name : ''} 
                    exitOnEnterKeyPress={false}
                    onSave={newVal => {onInputChange(newVal) }}
                />
            </Col>
        </Row>           
    );
}

export default WidgetTitleEditComponent;