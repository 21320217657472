/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {BatteryFullIcon, BatteryEmptyIcon, Battery20Icon, Battery50Icon, Battery80Icon} from 'mdi-react';

const BatteryIcon = (props) => {
    const renderBattery = () => {
        let {level} = props;
        // const size = 50;
        const size = 30;
        const colorEmpty = '#d40000';
        const colorLow = '#d40000';
        const colorMidLow = '#aa4400';
        const colorMidHigh = '#ffcc00';
        const colorFull = '#00a900';
        switch(level) {
            case 0:
                return (
                    <BatteryEmptyIcon 
                        size={size} 
                        color={colorEmpty}
                        className= {'pulse_high'} // css animation
                    />
                )
            case 1:
                return (
                    <Battery20Icon 
                        size={size} 
                        color={colorLow}
                        className= {'pulse_mid'} // css animation
                    />
                )
            case 2:
                return (
                    <Battery50Icon 
                        size={size} 
                        color={colorMidLow}
                    />
                )
            case 3:
                return (
                    <Battery80Icon 
                        size={size} 
                        color={colorMidHigh}
                    />
                )
            case 4:
                return(
                    <BatteryFullIcon 
                        size={size} 
                        color={colorFull}
                    />
                )
            default:
                return null;
        }
    }

    return (
        <Fragment>
            {renderBattery()}
        </Fragment>
    )
}

export default BatteryIcon;