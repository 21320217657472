/* eslint-disable */
import React, { Component } from "react";
import { Row, Col, Card as Card2 } from 'reactstrap';
import PaymentWallet from "./PaymentWallet"
import PaymentEditWallet from "./PaymentEditWallet"
import { getCurrentUser, hasRole } from '../../../factories/auth';

/**
 * @param {*} List : the list of credit cards formated (example below)
 * @param {*} onRadioClick :on radio button click (if this is null, radio button will not appear)
 * @param {*} onSave :on save of credit card
 */
export default class PaymentBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            card: "",
            userObj: getCurrentUser(),
            defaultPaymentId: 0
        }
    }
    componentDidMount() {
        if (this.props.list.length != null) {
            this.setState({ list: this.props.list })
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.list.length != null) {
            this.setState({ list: nextProps.list }, () => {
                if (nextProps.defaultPaymentId != 0) {
                    var updatedCard = this.state.list.filter((card) => card.id == this.state.defaultPaymentId);
                    if (updatedCard.length > 0) {
                        this.setState({ card: updatedCard[0] })
                    }
                }
            })
        }

    }
    onCreditCardSelect = (card, String) => {
        this.setState({
            card: card,
            defaultPaymentId: (card) ? card.id : 0
        });
    }
    onNewCreditCardSelect = () => {
        this.setState({
            card: null,
            defaultPaymentId: 0
        })
    }
    render() {
        /*
        format example
        var list = [
            {
                id: 1, //id of account
                type: "CC", //there is also "ACH" or "CHK"
                name: "Jason Wang", //name of card holder
                number: "51** **** **** 5436", //formatted card number
                selected: "Y", //default billing, "N" for no
                cvc: "", //CVC
                expiry: "1122" //valid thru date
            }
        ]
        */

        return (
            <div>
                <Row>
                    <Col xl={4} style={{ marginBottom: "30px" }}>
                        <form action="">
                            {
                                this.state.list.filter((card) => card.type == "CC").map((card) =>
                                    <PaymentWallet
                                        type={"CC"}
                                        card={card}
                                        isAdmin={this.props.isAdmin}
                                        onClick={this.onCreditCardSelect}
                                        RadioLabel={"Set as default"}
                                        onRadioClick={this.props.onRadioClick}
                                    />)
                            }
                            {
                                this.state.list.filter((card) => card.type != "CC").map((card) =>
                                    <PaymentWallet
                                        type={card.type}
                                        card={card}
                                        isAdmin={this.props.isAdmin}
                                        RadioLabel={"Set as default"}
                                        onRadioClick={this.props.onRadioClick}
                                    />)
                            }
                            {
                                (this.props.onSave != null && (this.state.userObj && hasRole(this.state.userObj, ['ADMIN', 'USER', 'CLIENTADMIN']))) ?
                                    <div>
                                        <hr style={{ borderTop: "3px dotted  #8c8b8b" }} />
                                        <PaymentWallet
                                            type={"CC"}
                                            card={null}
                                            onClick={this.onCreditCardSelect}
                                            onSave={this.props.onSave}
                                        />
                                    </div>
                                    :
                                    null
                            }
                        </form>
                    </Col>
                    {
                        (this.state.userObj && hasRole(this.state.userObj, ['ADMIN', 'USER', 'CLIENTADMIN'])) ?
                            <Col xl={8}>
                                <PaymentEditWallet
                                    card={this.state.card}
                                    onSave={this.props.onSave}
                                />
                            </Col>

                            :
                            null
                    }
                </Row>
            </div>
        )
    }
}