/* eslint-disable */
import React, {useState, useEffect} from 'react'
import './SmartBuilding.css'
import {renderSpinner} from './CarCountBoard'
import {groupByArray} from '../../../../factories/utils'
import { Row, Card, CardBody} from 'reactstrap';

function CarCountInfo({camCurrentData}) {
    const [EntranceCount, setEntranceCount] = useState(null);
    const [ExitCount, setExitCount] = useState(null);
    const [Stay, setStay] = useState(null)
    const [Direction, setDirection] = useState(null);
    const [CamNum, setCamNum] = useState(null)
    const [CamName, setCamName] = useState(null);
    

    useEffect(() => {
        if(camCurrentData) {
            setCamName(camCurrentData.camera?.name)
            setEntranceCount(camCurrentData.entranceCount)
            setExitCount(camCurrentData)
            setStay(camCurrentData.entranceCount - camCurrentData.exitCount)
            setDirection(camCurrentData.camera?.nvr?.direction)

        }else{
            setCamName(null)
            setEntranceCount(null)
            setExitCount(null)
            setStay(null)
            setDirection
        }
    }, [camCurrentData]);

    // useEffect(() => {
    //     //console.log('GROUPED LIST', groupedListByCam)

    // }, [groupedListByCam])

    // useEffect(() => {
    //     handleStayCountChange(Stay)
    // }, [Stay])

    //Data differentiated by camera
    const renderCamEntryStat = () =>{
        if(CamName){
            // console.log('CAM NAME', CamName, EntranceCount, ExitCount, Stay, Direction)
            if(CamName.includes('Highway')){
                return (
                    <div className="box" >
                        <p>{Direction ? `${Direction === 'NA' ? '' : `${Direction} / `} Passing By` : null}</p>
                        <p className="stat">{EntranceCount? EntranceCount: 'N/A'}</p>
                    </div>
                )
            }else{
                return (
                <div className="box" >
                    <p>Entry</p>
                    <p className="stat">{EntranceCount != null ? EntranceCount : 'N/A'}</p>
                </div>
                )
            }
        }
    }
    return (
        <>
            {renderCamEntryStat()}

            {/* <div className="box">
                <p>West Bound/ Exit</p>
                <p className="stat">{ExitCount ? ExitCount : renderSpinner()}</p>
            </div> */}

            {/* <div className="box">
                <p>NO. of Car at Premises</p> */}
                {/* {this.props.loading?this.renderSpinner():null} */}
                {/* {<p className="stat total">{Stay ? Stay : 0}</p>}
            </div> */}
            {/* <div className="box">
                <p>NO. of Cameras Used</p> */}
                {/* {this.props.loading?this.renderSpinner():null} */}
                {/* {camNum ? <p className="stat">{camNum}</p>: renderSpinner()}
            </div> */}
        </>
    )
}

export default CarCountInfo

