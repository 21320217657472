/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Line} from 'react-chartjs-2';
import './blinking.scss';

class SingleVarValueWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            defaultStyle: {
                backgroundColor: '#b1dd8c',               
            },
            defaultFontStyle: {
                color: 'black',
            },
            style: {
                backgroundColor: '#b1dd8c',               
            },
            fontStyle: {
                color: 'black',
            },
            isOn: false,
        };
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
         
        if ( nextProps.assetData) {
            //  
            //  
            //  
            //  
            if ((nextProps.attributea && nextProps.attributea == nextProps.assetData.attributename) || (nextProps.attributeb && nextProps.attributeb == nextProps.assetData.attributename)){
                this.setState( prevState =>({
                    style: {
                        backgroundColor: nextProps.assetData.backgroundcolor,    
                    },
                    fontStyle: {
                        color: 'white',
                    },
                    isOn: !prevState.isOn
                }), () => {
                    // alert(this.state.isOn)
                });
            }else{
                this.setState( prevState=>({
                    style: prevState.defaultStyle,
                    fontStyle: {color:nextProps.assetData.backgroundcolor},
                    isOn: !prevState.isOn
                }), () => {
                    // alert(this.state.isOn)
                });
            }
        }
        
    }

    onCardClick = (e) => {
        // alert('hello');
        if(this.props.assetData){
            // // alert('Yes');
            // if (this.state.isOn){
            //     this.setState( prevState=>({
            //         style: prevState.defaultStyle,
            //         fontStyle: prevState.defaultFontStyle,
            //         isOn: !prevState.isOn
            //     }), () => {
            //         // alert(this.state.isOn)
            //     });
            // }else{
            //     this.setState( prevState =>({
            //         style: {
            //             backgroundColor: this.props.assetData.backgroundcolor,    
            //         },
            //         fontStyle: {
            //             color: 'white',
            //         },
            //         isOn: !prevState.isOn
            //     }), () => {
            //         // alert(this.state.isOn)
            //     });
            // }

            this.props.handleValueClick(this.props.assetData);
        }
    }
    render(){
        let attribute = '';
        let displayname = '';
        let value = '';
        let units = '';
        if (this.props.assetData) {
            attribute = this.props.assetData.attributename;
            displayname = this.props.assetData.displayname;
            value = this.props.updatedValue ? this.props.updatedValue : this.props.assetData.value;
            units = this.props.assetData.units;
        }
        let valueStyle = this.state.fontStyle;
        let unitStyle = this.state.fontStyle;
        if (this.props.isBlink){
            valueStyle = {...valueStyle, animation: 'valueBlink 0.7s infinite'};
            // unitStyle = {className: 'alertColor'}
            unitStyle = {...unitStyle, color: '#ff7f7f'}
        }
        return(
            <Card key={this.state.isOn} onClick={this.onCardClick} style={{height:'120px', padding: '0', margin: '5px 0px', cursor:'pointer'}}>
                <CardBody style={{...this.state.style, padding: '15px 5px 5px 10px', border: '0px solid #ffffff', /* cursor: 'pointer' */ height:'105px', width: '115px'}}>
                    <Row style= {{margin: '0px 0px 10px 0px'}}>
                        {/* <Col md={12}> */}
                            <h6>
                            Occupancy
                            </h6>
                        {/* </Col> */}
                    </Row>
                    <Row style={{display: 'flex', justifyContent: 'center', textAlign:'center'}}>
                        <Col md={12}>
                        <h4>
                        
                            {value}
                        
                            {' '}
                        <span  style={unitStyle}>
                            {units}
                        </span>
                        </h4>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default SingleVarValueWidget;