/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import { Row, Col, Label } from 'reactstrap';
import '../../../scss/animation.scss';
import axios from 'axios';

const GREEN_COLOR = '#00cc00';
const RED_COLOR = '#ff0000';
const WHITE_COLOR = '#ffffff';

const AlarmAttributeDisplay = ({ status, count, switchOff }) => {
    const [exitHuman, setexitHuman] = useState(0)
    const [entranceHuman, setentranceHuman] = useState(0)
    const [keepCount, setkeepCount] = useState(true);
    useEffect(() => {
            loadCount();
    }, []);
    const normalTextStyle = {
        fontSize: '1.7vh',
        lineHeight: '1.7vh',
    }
    const titleRowStyle = {
        // margin: '5px 10px',
        // padding:'5px', 
        margin: '0px 0px',
        padding:'0px',
        // justifyContent: 'flex-end',
        justifyContent: 'space-between',
        alignItems: "center",
        lineHeight: '30px',
        height: '30px'
    }
    const onClick = (e) => {
        switchOff();
    }

    const loadCount = async()=>{
        try{
            const countingRequest = await axios.get("http://localhost:10247/api/httpController/GetPassLineCountStatistics?ip=192.168.2.91&port=80&account=admin&password=123456*")
            const counter = countingRequest.data;
            if (counter){
                setexitHuman(counter.exitCount.person);
                setentranceHuman(counter.entranceCount.person)
            }
            console.log('PEOPLE COUNTER', counter)

        }catch(err){
            console.error(err);
        }
        // debugger;
    }
    // setIsBlink(status)
    return (
        <Row>
            <Col md = {5}>
            <Row style={{ height: '50px' }}>
                <Col md={12} style={{textAlign: 'center'}}>
                    <span style={{fontWeight:'bold', ...normalTextStyle}}>{'ATTRIBUTE'}</span>
                </Col>
            </Row>
            <Row style={{ height: '50px' }}>
                <Col md={12} style={rowClassGnerator(status, null, null)} onClick={onClick}>
                    <span style={normalTextStyle}>
                        {' PEOPLE '}
                    </span>
                </Col>
            </Row>
            </Col>
            <Col md = {5}>
                <Row style={{ height: '50px' }}>
                    <Col md={12} style={{textAlign: 'center'}}>
                        <span style={{fontWeight:'bold', ...normalTextStyle}}>COUNT</span>
                    </Col>
                </Row>
                <Row style={{ height: '50px' }}>
                    <Col md={12} style={rowClassGnerator(false, null, null, WHITE_COLOR)}>
                        <span style={{color:'black', ...normalTextStyle}}>{entranceHuman - exitHuman < 0 ? 0: entranceHuman - exitHuman}</span>
                    </Col>
                </Row>
            </Col>
            <Col md = {2}>
                <Row style={{ height: '50px' }}>
                </Row>
                <Row style={{ height: '50px' }}>
                    <Col md={12} style={{textAlign: 'center'}}>
                        <button style={{marginTop: "9%", borderRadius: "6px", border: "none", backgroundColor: "#ececec", padding: "10%"}} onClick={loadCount}>Refresh</button>
                    </Col>
                </Row>
            </Col>
        </Row>
       
    )
}

const rowClassGnerator = (status, item, attributeName,  color=GREEN_COLOR, hasrule=false, isSmall=false) => {
    let backgroupdColor = color
    let horizontalAlign = 'center'; //'space-evenly'; 
    let alarmColor = RED_COLOR;

    let reClass = {
        // color: 'black', 
        // fontWeight: 'normal',
        display: 'flex',
        margin: '5px 0px',
        padding:'5px 30px', 
        // paddingLeft: '0px',
        justifyContent: 'center',
        alignContent: 'center',
        // lineHeight: '30px',
        // height: '30px',
        lineHeight: isSmall ? null : '35px',
        height: isSmall ? null : '35px',
        backgroundColor: backgroupdColor, 
        color: 'white',
    };
    
    if (status == true){        
        return ({
           ...reClass,
            // animation: `${blink} 1s infinite`,
            backgroundColor: alarmColor,
            animation: 'alertBlink 1s infinite',
        });
    }else {
        return ({
            ...reClass,
        })
    }
}

export default AlarmAttributeDisplay;