/* eslint-disable */
import React, { Component, Fragment, useState } from 'react';
import { Col, Row, Label, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import { EditIcon } from 'mdi-react';

const BaseWidgetEditDialog = ({icon, renderForm, defaultLayout, onEditSubmit}) => {
    // const [layout, setLayout] = useState(defaultLayout);
    const [modal, setModal] = useState(false);
    
    const toggle = () => {
        setModal(!modal);
    }
    const onCancleClick = (e) => {
        toggle();
    }
    const onOkClick = (layout) => {
        onEditSubmit(layout);
        toggle();
    }
    // const onFormChange = (newLayout) => {
    //     setLayout(newLayout);
    // }
    // const validateLayout = () => {
    //     return {
    //         validated: false,
    //         message: 'Error'
    //     };
    // }
    // const onOkClick = () => {
    //     const {validated, message} = validateLayout();
    //     if (validated) {
    //         // let layoutClone = JSON.parse(JSON.stringify(layout));
    //         onEditSubmit(layout);
    //         toggle();
    //     }else{
    //         toast.error(message);
    //     }
    // }

    return (
        <Fragment>
             {  
                icon ?
                <>
                    {icon({onClick:(e)=>{toggle()}})} 
                </>
                : 
                <EditIcon label={label} onClick={(e)=>{toggle()}}/>
            }
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={modal}
                toggle={toggle}
                size = {'lg'}
            >
                <ModalHeader 
                    toggle={toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {/* {this.state.tital} */}
                    Edit Widget
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    {/* {this.renderTitle()} */}
                    <Row>
                        <Col md={12}>     
                            {renderForm(defaultLayout, onOkClick, onCancleClick)}                       
                        </Col>
                    </Row>
                </ModalBody>
                
                {/* <ModalFooter>
                    <Button color="secondary" onClick={onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={onCancleClick}>Cancel</Button>
                </ModalFooter> */}
            </Modal>
        </Fragment>
    );
}

export default BaseWidgetEditDialog;