/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProfileMainBoard from './components/ProfileMainBoard';
import { getCurrentUser } from '../../factories/auth';
import {UserContext} from '../../constants/myContexts';

const ProfilePage = (props) => {
    // let userObj = getCurrentUser();
    let {userObj, updateUserObj} = React.useContext(UserContext);
    if (!userObj) {
      toast.error('Can not fetch login user information, Please re-login.');
      props.history.push('/log_out');
    } else {
      
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h2 className="page-title"><b>Profile </b></h2>
          </Col>
        </Row>

        <Row>
          <ProfileMainBoard userObj={userObj} updateUserObj={updateUserObj}/>
        </Row>
      </Container>
    );
  };
  
  export default withRouter(ProfilePage);