/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row, Button, Input } from 'reactstrap';
import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';


class PlanSelectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ServicePlanList: []
        }
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps) {
        //filter plan by carrier

        this.setState({
            ServicePlanList: nextProps.ServicePlanList
        });

    }
    priceFormatter = (price, billCycle) => {
        var per = "";
        if (billCycle == 30) {
            per = "/mo"
        }
        else if (billCycle == 365) {
            per = "/yr"
        }
        return `$ ${price.toFixed(2)}${per}`;
    }

    dataFormatter = (data) => {
        if (data < 1024) {
            return (`${data} MB`);
        }
        if (data >= 1024) {
            var gb = (data / 1024).toFixed(2)
            return (`${gb} GB`);
        }
    }
    onModalOpen = (plan) => {
        this.props.onOpen(plan)
    }
    handleOnNext = () => {
        if (this.props.isClick === "activation") {
            if (JSON.stringify(this.props.mapSimToPlan) != "{}") {
                this.props.buildOrderSummary()
                this.props.onNext()
            }
            else {
                toast.error("A plan must be selected")
            }
        }
        else {
            this.props.buildOrderSummary()
            this.props.onNext()
        }
    }
    handleOnBack = () => {
        this.props.clear();
        this.props.onBack();
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <BackButton
                            onClick={this.handleOnBack}
                            label="Back"
                            size={30}
                            color='black'
                        />
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NextButton
                            onClick={this.handleOnNext}
                            label="Next"
                            size={30}
                            color='black'
                        />
                    </Col>
                </Row>
                <h3>Select Plan</h3>
                <br />
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {this.state.ServicePlanList != null && this.state.ServicePlanList.map((plan, index) => {
                        var buttonText = "Select";
                        var buttonColor = "primary";
                        var selected = false;
                        if (this.props.mapSimToPlan != null) {
                            if (this.props.mapSimToPlan[plan.itemId] != null && this.props.mapSimToPlan[plan.itemId] != undefined) {
                                selected = true;
                            }
                        }
                        if (selected) {
                            buttonText = "Unselect";
                            buttonColor = "danger";
                        }
                        if (this.props.isAdmin) {
                            return (
                                <div>
                                    {(plan.resellerId == null) ?
                                        <div style={{ display: "inline-block", margin: "0px 15px 15px 20px" }}>
                                            <Card className="pricing-card" style={{ "box-shadow": "0px 3px 12px 4px rgba(0, 0, 0, 0.16)" }}>
                                                <CardBody className="pricing-card__body">
                                                    <h3 className="pricing-card__plan" style={{ fontWeight: "bold" }}>{plan.itemName}</h3>
                                                    <hr />
                                                    {(this.props.isAdmin) ? <p className="pricing-card__feature">{(plan.carrier != null) ? plan.carrier.carrierName : ""}</p> : null}
                                                    <p className="pricing-card__feature">{this.priceFormatter(plan.unitPrice, plan.billCycle)}</p>
                                                    <p className="pricing-card__feature">{this.dataFormatter(plan.planDataVolume)}</p>
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "activationFee" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Activation, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "feature" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Feature, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "overage" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Overage, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.itemPayType === "PAYG") ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Pay As You Go</p> : ``}
                                                    {(plan.autoRenewFlag === 1) ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>AutoRenewal</p> : ``}
                                                    <Button className="pricing-card__button" color={buttonColor} onClick={() => this.onModalOpen(plan)}>{buttonText}</Button>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        :
                                        <div style={{ display: "inline-block", margin: "0px 15px 15px 20px" }}>
                                            <Card className="pricing-card" style={{ "box-shadow": "0px 3px 12px 4px rgba(0, 0, 0, 0.16)" }}>
                                                <CardBody className="pricing-card__body">
                                                    <h3 className="pricing-card__plan" style={{ fontWeight: "bold" }}>{plan.itemName}</h3>
                                                    <hr />
                                                    {(this.props.isAdmin) ? <p className="pricing-card__feature">{(plan.carrier != null) ? plan.carrier.carrierName : ""}</p> : null}
                                                    <p className="pricing-card__feature">{this.priceFormatter(plan.unitPrice, plan.billCycle)}</p>
                                                    <p className="pricing-card__feature">{this.dataFormatter(plan.planDataVolume)}</p>
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "activationFee" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Activation, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "feature" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Feature, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "overage" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Overage, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.itemPayType === "PAYG") ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Pay As You Go</p> : ``}
                                                    {(plan.autoRenewFlag === 1) ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Auto-Renew</p> : ``}
                                                    <Button className="pricing-card__button" color={buttonColor} onClick={() => this.onModalOpen(plan)}>{buttonText}</Button>
                                                </CardBody>
                                            </Card>
                                        </div>

                                    }
                                </div>
                            )
                        }
                        else {
                            if (plan.resellerId != null) {
                                return (
                                    <Col>
                                        <div style={{ display: "inline-block", margin: "0px 15px 15px 20px" }}>
                                            <Card className="pricing-card" style={{ "box-shadow": "0px 3px 12px 4px rgba(0, 0, 0, 0.16)" }}>
                                                <CardBody className="pricing-card__body">
                                                    <h3 className="pricing-card__plan" style={{ fontWeight: "bold" }}>{plan.itemName}</h3>
                                                    <hr />
                                                    <p className="pricing-card__feature">{this.priceFormatter(plan.unitPrice, plan.billCycle)}</p>
                                                    <p className="pricing-card__feature">{this.dataFormatter(plan.planDataVolume)}</p>
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "feature" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Feature, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.extensions.length > 0) ?
                                                        plan.extensions.filter((item, index) => (item.type == "overage" && item.status == 1)).map((feature, index) =>
                                                            < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Overage, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                        )
                                                        : ``}
                                                    {(plan.itemPayType === "PAYG") ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Pay As You Go</p> : ``}
                                                    {(plan.autoRenewFlag === 1) ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Auto-Renew</p> : ``}
                                                    <Button className="pricing-card__button" color={buttonColor} onClick={() => this.onModalOpen(plan)}>{buttonText}</Button>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Col>

                                )
                            }

                        }
                    })}


                </div>

            </div>

        );


    }


}
export default PlanSelectList;