/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Bar} from 'react-chartjs-2';

class WeatherBarChart extends Component {
    constructor(props){
        super(props);
        this.state={}
    }
    renderBarChart = () => {
        const chartlabel = ['A', 'B', 'C', 'D', 'E'];
        // const chartdata1 = [20,30,50,40,10];
        // const chartdata2 = [10,40,30,20,30];
        const chartdata1 = [
            {x:'2016-12-25', y:20},
            {x:'2016-12-26', y:30},
            {x:'2016-12-27', y:50},
            {x:'2016-12-28', y:40},
            {x:'2016-12-29', y:10}
        ];
        const chartdata2 = [
            {x:'2016-12-25', y:10},
            {x:'2016-12-26', y:40},
            {x:'2016-12-27', y:30},
            {x:'2016-12-28', y:20},
            {x:'2016-12-29', y:50}
        ];
        let chartdatared = [];
        let chartdataorange = [];
        let chartdatayellow = [];
        if (this.props.countData){
            this.props.countData.forEach((item, index) => {
                let red = {x: item.date, y: item.red};
                let orange = {x: item.date, y: item.orange};
                let yellow = {x: item.date, y: item.yellow};
                chartdatared.push(red);
                chartdataorange.push(orange);
                chartdatayellow.push(yellow);
            });

        }
         
        const data = {
            // labels: chartlabel,
            datasets: [
                {
                    label: 'Alarm Red',
                    // backgroundColor: 'rgba(255,99,132,0.2)',
                    backgroundColor: 'rgba(255,0,0,0.7)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdatared,
                },
                {
                    label: 'Alarm Orenge',
                    // backgroundColor: 'rgba(99,255,100,0.2)',
                    backgroundColor: 'rgba(255,155,0,0.5)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdataorange
                },
                {
                    label: 'Alarm Yellow',
                    // backgroundColor: 'rgba(99,255,255,0.2)',
                    backgroundColor: 'rgba(255,255,0,0.5)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdatayellow
                }
            ]
        };
         
        let unit = this.props.units ==='day' ? 'day': 'hour';
        let rationOption = this.props.isSmall ? {responsive: true, maintainAspectRatio: false,} : { aspectRatio: 4, responsive: true, maintainAspectRatio: true,} 
        return (
            <Fragment>
                <Bar
                    key={this.props.isSmall}
                    data={data}
                    // width={60}
                    width={null}
                    height={null}
                    // height={height.toString()} 
                    // height={300}
                    // height={'45vh'}
                    options={{
                        // aspectRatio: 4,
                        // responsive: true,
                        // maintainAspectRatio: true,
                        ...rationOption,
                        scales: {
                            bounds: 'data',
                            // xAxes: [{
                            //     // stacked: false,
                            //     // type: 'linear',
                            // }],
                            xAxes: [{
                                type: 'time',
                                distribution: 'series',
                                offset: true,
                                time: {
                                    // unit: 'day'
                                    // unit: unit
                                },
                                ticks:{
                                    // min: '2016-12-24',
                                    // max: '2016-12-30',
                                    source: 'data',
                                }
                            }],
                            yAxes: [{
                                // stacked: false,
                                // type: 'linear',
                                ticks: {
                                    min: 0,
                                }
                            }]
                        },
                        legend: {
                            display: this.props.isSmall? false: true,
                            // position: 'right',
                            position: 'bottom',
                            labels: {
                                boxWidth: 10,
                                fontSize: 12,
                                fontColor: 'rgb(255, 99, 132)'
                            }
                        }
                    }}
                />
            </Fragment>
        );
    }
    render(){
        return(
            <Card  style={{padding: '0', border: '1px solid black'}}>
                <CardBody style={{padding: '20px 5px 10px 5px'}}>
                    {this.renderBarChart()}
                </CardBody>
            </Card>
        );
    }
}

export default WeatherBarChart;