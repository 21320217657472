/* eslint-disable */
import React, { Component, Fragment, useState } from 'react';
import {
    compose, 
    bindActionCreators
} from "redux";
import {connect} from "react-redux";
import { Row, Col, Card, CardBody, CardTitle} from 'reactstrap';
// import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import {
    getAllConfig,
    getConfigByAppName,
    updateConfig,
} from '../../../redux/actions/weatherAction';
import {
    getNotificationTemplate,
    getNotificationTemplateByAppname,
    upsertNotificationTemplate,
    upsertNotificationTemplateByAppname,
    // deleteNotificationTemplate,
    getContact, 
    upsertContact, 
    deleteContact,
    // getActions,
    // getActionRule,
    upsertActionRule
} from '../../../redux/actions/jobAction'; 
import TimerSetting from './TimerSetting';
import SMSTemplateSetting from './SMSTemplateSetting';
import StationAlarmSetting from './StationAlarmSetting';
import {groupBy} from '../../../factories/utils';
// import NotificationTemplateList from '../../NotificationTemplate/components/TemplateList';
// import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
// import './drawercss.scss';

class SettingMainBoard extends Component {
    constructor(props){
        super(props);
        this.state={
            activeTab: '1',
            right: false,
        };
    }
    componentDidMount(){
         if (this.props.appname != null && this.props.companycode != null){
             this.props.getConfigByAppName({appname:this.props.appname, modulename:"alarm"});/*  <- you have to make this (axiosGet) */
             this.props.getNotificationTemplateByAppname({appname: this.props.appname, companycode: this.props.companycode});
            } else {
                this.props.getAllConfig();
                this.props.getNotificationTemplate();
            }
        // this.props.getNotificationTemplateByAppname();
        this.props.getContact();
        // this.props.getActions();
    }
    // ------------------------------------------------------
    // renderTimmerSetting(){
    //      
    //     return (
    //         <Col md={12}>
    //             <TimerSetting
    //                 configs={this.props.allConfig}
    //                 updateConfig={this.props.updateConfig}
    //             />
    //         </Col>
    //     );
    // }
    // -------------------------------------------------------
    // renderTemplateSetting(){
    //      
    //     return(
    //         <SMSTemplateSetting
    //             notificationTemplateList={this.props.notificationTemplateList}
    //             contactList={this.props.contactList}
    //             upsertNotificationTemplate={this.props.upsertNotificationTemplate}
    //             upsertContact={this.props.upsertContact}
    //             deleteContact = {this.props.deleteContact}
    //         />
    //     );
    // }
    // -------------------------------------------------------
    renderSettingGroup(title, configList, notificationList){
        return(
            <Card>
                <CardBody>
                    <CardTitle>{title}</CardTitle>
                    {/* <Row> */}
                        {/* <Col md={12}> */}
                            {/* <TimerSetting
                                configs={configList}
                                updateConfig={this.props.updateConfig}
                            /> */}
                        {/* </Col> */}
                    {/* </Row> */}
                    {/* <Row> */}
                    {notificationList && notificationList.length > 0 && 
                        <SMSTemplateSetting
                            notificationTemplateList={notificationList}
                            contactList={this.props.contactList}
                            // upsertNotificationTemplate={this.props.upsertNotificationTemplate}
                            upsertNotificationTemplate={this.props.upsertNotificationTemplateByAppname}
                            upsertContact={this.props.upsertContact}
                            deleteContact = {this.props.deleteContact}
                        />
                    }
                    {/* </Row> */}
                    {/* <Row> */}
                        {/* <Col md={12}> */}
                        <TimerSetting
                                configs={configList}
                                updateConfig={this.props.updateConfig}
                                appname = {this.props.appname}
                            />
                        {/* </Col> */}
                    {/* </Row> */}
                </CardBody>
            </Card>
        );
    }
    // -------------------------------------------------------
    // renderStationSetting(){
    //     return(
    //         <StationAlarmSetting
    //             actionList = {this.props.actionList}
    //             notificationTemplateList={this.props.notificationTemplateList}
    //             contactList={this.props.contactList}
    //             upsertNotificationTemplate={this.props.upsertNotificationTemplate}
    //             upsertContact={this.props.upsertContact}
    //             deleteContact = {this.props.deleteContact}
    //             getActionRule = {this.props.getActionRule}
    //             upsertActionRule = {this.props.upsertActionRule}
    //             actionRules = {this.props.actionRules}
    //         />
    //     )
    // }
    // -------------------------------------------------------
    toggle = (tab) => {
        this.setState(prevState =>{
            if (prevState.activeTab !== tab){
                return {
                    activeTab: tab
                }
            }else{
                return null;
            }
        });
    }

    toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        this.setState({[side]: open });
    };
    // render(){
    //     let { activeTab } = this.state;
    //     return(
    //         <div  style={{width: '75%'}}>
    //             <Nav tabs>
    //                 <NavItem>
    //                     <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => {this.toggle('1')}}>
    //                         <h4><b>Timer </b></h4>
    //                     </NavLink>
    //                 </NavItem>
    //                 <NavItem>
    //                     <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => {this.toggle('2')}}>
    //                         <h4><b>Template </b></h4>
    //                     </NavLink>
    //                 </NavItem>
    //             </Nav>
    //             <TabContent activeTab={activeTab}>
    //                 <TabPane tabId='1'>
    //                     {this.renderTimmerSetting()}
    //                 </TabPane>
    //                 <TabPane tabId='2'>
    //                     {this.renderTemplateSetting()}
    //                 </TabPane>
    //             </TabContent>              
    //         </div>
    //     );
    // }
    // render(){
    //     return(
    //         <div>
    //             <Button onClick={this.toggleDrawer('right', true)}>Open Right</Button>
    //             <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)} width={100}>
    //                 {this.renderTab()}
    //             </Drawer>
    //         </div>
    //     );
    // }
    render(){
        let dataList = groupSetting(this.props.allConfig, this.props.notificationTemplateList);
        // return(
        //     <div  style={{width: '75%'}}>
        //         <h5>Parameters </h5>
        //         <br/>
        //         <Row>
        //         {this.renderTimmerSetting()}
        //         </Row>
        //         <h5>Lighting Message Template </h5>
        //         <br/>
        //         <Row>
        //         {this.renderTemplateSetting()}
        //         </Row>
        //         {/* <h5>Station Alarm Set</h5>
        //         <br/>
        //         <Row>
        //         {this.renderStationSetting()}
        //         </Row> */}
        //     </div>
        // );

        return(
            <div style={{width: '100%'}}>
                {dataList && dataList.map(item => (
                    <Row>
                        {/* <h5>{item.groupKey} </h5>
                        <br/> */}
                        {this.renderSettingGroup(item.groupKey, item.data.config, item.data.notification)}
                    </Row>
                ))}

            </div>
        )
    }
}

function groupSetting(allConfigList, notificationList) {
    const reObj = []
    if (allConfigList && notificationList){
         
         
        let groupedConfigList = null
        let groupedNotificationList = null
        if (allConfigList) {
            groupedConfigList = groupBy(allConfigList, config => config.settinggroup);

             
        }
        if (notificationList) {
            groupedNotificationList = groupBy(notificationList, notification => notification.settinggroup);
             
        }

        if (groupedConfigList) {
            for (let [key, value] of groupedConfigList.entries()) {
                //  
                let setObj = {
                    groupKey: key,
                    data: {
                        notification: groupedNotificationList.get(key) ? groupedNotificationList.get(key) : [], 
                        config: value, //groupedConfigList.get(key),
                    }
                };
                reObj.push(setObj);
            }
            let restNotification = notificationList.filter((notification) => (![...groupedConfigList.keys()].includes(notification.settinggroup)));
            // if (restNotification && restNotification.length > 0) {
            //     let restObj = {
            //         groupKey: 'Parameter',
            //         data: {
            //             notification: restNotification, 
            //             config: []
            //         }
            //     };
            //     reObj.push(restObj);
            // }
            
        }else{
            // let setObj = {
            //     groupKey: 'Parameter',
            //     data: {
            //         notification: notificationList, 
            //         config: []
            //     }
            // };
            // reObj.push(setObj);
        }
    }
     
    return reObj;

}

function mapStateToProps(state) {
    let { allConfig } = state.weather;
    let { contactList, notificationTemplateList, actionList, actionRules } = state.job;
    return { allConfig, contactList, notificationTemplateList, actionList, actionRules };
    // return state.weather;
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAllConfig,
        getConfigByAppName,
        updateConfig,
        getNotificationTemplate,
        upsertNotificationTemplate,
        upsertNotificationTemplateByAppname,
        getNotificationTemplateByAppname,
        // deleteNotificationTemplate,
        getContact, 
        upsertContact, 
        deleteContact,
        // getActions,
        // getActionRule,
        upsertActionRule
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingMainBoard);