/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import {connect } from 'react-redux';
// import {bindActionCreators } from 'redux';
import { Card, CardTitle, CardBody, Col, Row, Collapse, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container, Input } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import { Modal, ModalHeader , ModalBody,ModalFooter } from 'reactstrap';
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import CompanyAccountOptionsBoard from './CompanyAccountOptionsBoard';
import CompanyAccountTotalBoard from './CompanyAccountTotalBoard';
import CompanyAccountPaymentComp from './CompanyAccountPaymentComp';
import CompanyAccountHistoryComp from './CompanyAccountHistoryComp';
import CompanyAccountPurchaseComp from './CompanyAccountPurchaseComp';
import CompanyAccountPurchaseCardComp from './CompanyAccountPurchaseCardComp';
import CompanyAccountPurchaseCheckoutComp from './CompanyAccountPurchaseCheckoutComp';
import {getAllPlanItems, createCard, getAccounts, postAccount, deleteAccount, postOrder, customerCheckout, customerPayOrder, releaseOrder} from '../../../redux/actions/userAction';
import {getInventoryList} from '../../../redux/actions/inventoryActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {BackButton} from '../../../shared/components/Button/IconButton'; 
import {itemFinder, dropdownOnChangeHandler} from '../../../factories/utils';
import log from 'loglevel';

class CompanyAcountBoard extends Component {
    constructor(props){
        super(props);
        this.handlePaymentClick = this.handlePaymentClick.bind(this);
        this.handleHistoryClick = this.handleHistoryClick.bind(this);
        this.handlePurchaseClick = this.handlePurchaseClick.bind(this);
        this.handlePurchaseCardClick = this.handlePurchaseCardClick.bind(this);
        this.state={
            activeTab : '1',
            collapse: this.props.collapse ? true :false,
            accountTitle:'abc',
            modalVisible: false,
            isDetailCollapse: this.props.isDetailCollapse ? true :false,
            isPaymentCollapse: this.props.isPaymentCollapse ? true :false,
            isPaymentClicked: this.props.isPaymentClicked ? true :false,
            isHistoryClicked: false,
            isPurchaseClicked: this.props.isPurchaseClicked ? true :false,
            isPurchaseCardClicked: false,
            isPurchaseCheckoutClicked: false,
            account:[],
            currentPlanItemId: null,
            currentPlanItem: null,
            autoPay: false,
            billCycle: 30,
            itemType: this.props.itemType ? this.props.itemType : '1',
        }

       
      
       
    }

    componentDidMount(){
      // this.props.getAccounts();
      this.props.getAllPlanItems();
  }   

    componentWillReceiveProps(nextProps){
      if (nextProps.accountList && this.state.account && Array.isArray(this.state.account) && this.state.account.length>1){
        let newAccount = nextProps.accountList.find((item, index)=>(item.id == this.state.account[0].id));
        if (newAccount) {
          this.setState({
            account: [{...newAccount}],
          });
        }
      }
      if (Array.isArray(nextProps.accountList) && nextProps.accountList.length>0 && Array.isArray(this.state.account) && this.state.account.length==0){
        let newAccount = nextProps.accountList[0];
        if (newAccount) {
          this.setState({
            account: [{...newAccount}],
          });
        }
      }
    }

    toggle = (tab) => {

     this.setState({
      collapse: !this.state.collapse,
    })

     
    }

    handlePlanChange = (e) => {
      let value = dropdownOnChangeHandler(e);
      // const planItem = this.props.planItemsList.filter(item => (item.itemId == value.id));
      const planItem = this.props.planItemsAll.find(item => (item.itemId == value.id));
      //  
      this.setState( { 
          currentPlanItemId: value.id,
          currentPlanItem: planItem,
      });          
  }

  handleAutoPayClick = (e) => {
    let checked = e.target.checked;
    this.setState({
      autoPay: checked
    });
  }

  handBillCycleChange = (e) => {
    let value = e.target.value
    this.setState({
        billCycle: value
      },()=>{
        // log.debug('billCycle', this.state.billCycle);
      }
    );
  }

  typeToggle = (value) => {
    this.setState({
      itemType: value
    });
  }

    handleAccountClick = (item) => {
      this.setState({
        collapse: !this.state.collapse,
        isDetailCollapse: false,
        account: [{
          id: item.id,
          customerId: item.customerId,
          accountType: item.accountType,
          companyCode: item.companyCode,
          password: item.password,
          currentBalance: item.currentBalance,
          overdueBalance: item.overdueBalance,
          currentBalanceTax: item.currentBalanceTax,
          overdueBalanceTax: item.overdueBalanceTax,
          paymentMethod: item.paymentMethod,
          createDt: item.createDt,
          updateDt: item.updateDt,
          status: item.status,
          isNotification: item.isNotification,
          tokenCode: item.tokenCode,
          cardMask: item.cardMask,
          expDate: item.expDate,
        }]
      })
     
  }

  handleSwitchAccount = (value) => {
    this.setState({
      account: [value],
    })
  }

    handlePaymentClick = () => {
          this.setState({
            isPaymentClicked: !this.state.isPaymentClicked,
          }) 
    }

    handleHistoryClick = () => {
      this.setState({
        isHistoryClicked: !this.state.isHistoryClicked,
      }) 
  
    }

    handlePurchaseClick = () => {
      this.setState({
        isPurchaseClicked: !this.state.isPurchaseClicked,
      }) 
    }

    
    handlePurchaseCardClick = () => {
      this.setState({
        isPurchaseCardClicked: !this.state.isPurchaseCardClicked,
      }) 
    }

    handlePurchaseCheckoutClick = () => {
      this.setState({
        isPurchaseCheckoutClicked: !this.state.isPurchaseCheckoutClicked,
      }) 

       
    }

    

    toggleCollaps = () => {
        this.state.isDetailCollapse ?
          this.setState({
            collapse: !this.state.collapse,
          }) :
          this.setState({
            collapse: !this.state.collapse,
          })

         
    }

    togglePaymentCollaps = () => {
        this.state.isPaymentCollapse ?
          this.setState({
            collapse: !this.state.collapse,
            isPaymentCollapse:  !this.state.isPaymentCollapse
          }) :
          this.setState({
            collapse: !this.state.collapse,
            isPaymentCollapse:  !this.state.isPaymentCollapse
          })

         
    }

    handleBackClick = () => {
        this.toggleCollaps();
    }

    
    handlePaymentCompBackClick = () => {
      this.state.isPaymentClicked ?
      this.setState({
        isPaymentClicked: !this.state.isPaymentClicked,
      }) :
      this.setState({
        isPaymentClicked: !this.state.isPaymentClicked,
      })
  }

  handleHistoryCompBackClick = () => {

    this.setState({
      isHistoryClicked: !this.state.isHistoryClicked,
    }) 
  }

  handlePurchaseCompBackClick = () => {

    this.setState({
      isPurchaseClicked: !this.state.isPurchaseClicked,
    }) 
  }

  handlePurchaseCardCompBackClick = () => {
    this.setState({
      isPurchaseCardClicked: !this.state.isPurchaseCardClicked,
    }) 
  }

  handlePurchaseCheckoutCompBackClick = () => {
    this.setState({
      isPurchaseCheckoutClicked: !this.state.isPurchaseCheckoutClicked,
    }) 
  }

  addNewAccountToCompany = (data) => {
    // console.log(data)
    this.props.postAccount(data)
       .then(res => {
           
          if (res && res.status === 200 && res.data && res.data.length > 0){
                let storeData = res.data[0];
                this.props.addToAccounts(storeData);
            
          }else if (!res || res.status !== 200){
                 
                
          }           
       })
 }

 createOrderToAccount = (data) => {
  this.props.postOrder(data)
     .then(res => {
         
       
      //  toast.success(res.data.message);
      //   this.setState({
      //     isPurchaseCheckoutClicked: false,
      //     isPurchaseCardClicked: false,
      //     isPurchaseClicked: false,
      //     isDetailCollapse:true
      //   }) 
        if (res && res.status === 200 && res.data ){
              // let storeData = res.data[0];
              if (res.data.error != "ERROR") {
                // toast.success(res.data.message);
                toast.success("Successfully create a plan");
                this.setState({
                  isPurchaseCheckoutClicked: false,
                  isPurchaseCardClicked: false,
                  isPurchaseClicked: true,
                  isDetailCollapse:false
                });
                 
                this.props.getorderbycustomer(res.data.companyCode); 
              }else{
                // toast.error(res.data.message);
                toast.error("Error occurred when creating a plan");
              }
             
          
        }else if (!res || res.status !== 200){
               
              
        }           
     })
}

payOrder = (orderObj) => {
  let self = this;
  this.props.customerPayOrder(orderObj)
  .then(res => {
      
     if (res && res.status === 200 && res.data ){
           // let storeData = res.data[0];
           if (res.data.error != "ERROR") {
             toast.success('success');
             this.setState({
              isPurchaseCheckoutClicked: false,
              isPurchaseCardClicked: false,
              isPurchaseClicked: true,
              isDetailCollapse:false
            });
            //  
            // this.props.getorderbycustomer(res.data.companyCode); 
            // if (this.props.companyObj) {
            //   this.props.getorderbycustomer(res.data.companyCode); 
            // }
            
           }else{
             toast.error(res.data.message);
           }               
     }else if (!res || res.status !== 200){
            
           toast.error('create order error');
           
     }           
  })
}

 
 addAccountNewCard = (data, companycode) => {
    
  this.props.createCard(data, companycode)
     .then(res => {
         
        if (res && res.status === 200 && res.data && res.data.length > 0){
          let storeData = res.data[0];
         
          
        }else if (!res || res.status !== 200){
               
              
        }           
     })
}

 deleteCompanyAccount = (data) => {
  // console.log(data)
  this.props.deleteAccount(data)
     .then(res => {
         
        if (res && res.status === 200 && res.data && res.data.length > 0){
              let storeData = res.data[0];
              this.props.addToAccounts(storeData);
          
        }else if (!res || res.status !== 200){
               
              
        }           
     })
}

  

    renderPlan = () => {

       return(
            <div>
                <Row>
                  <Col md={12}>
                    <Collapse isOpen={!this.state.collapse || this.state.isDetailCollapse}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                        <CompanyAccountTotalBoard
                            userObj = {this.props.userObj}
                            handleAccountClick = {this.handleAccountClick}
                            toggle = {this.toggle}
                            cancelToggle = {this.props.toggle}
                            addNewAccount = {this.addNewAccountToCompany}
                            deleteAccount = {this.deleteCompanyAccount}
                            companyObj = {this.props.companyObj}
                            accountList = {this.props.accountList}
                            companyDevicetypeList = {this.props.companyDevicetypeList}
                            updateCompanyDeviceList = {this.props.updateCompanyDeviceList}
                         />
                     </Collapse>
                   </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Collapse isOpen={this.state.collapse && !this.state.isDetailCollapse && !this.state.isPaymentClicked && !this.state.isHistoryClicked && !this.state.isPurchaseClicked && !this.state.isPurchaseCardClicked && !this.state.isPurchaseCheckoutClicked}>  
                        {/* <Button onClick={this.handleBackClick}> Back </Button> */}
                        <BackButton
                           onClick={this.handleBackClick} 
                           label="Back"
                           size={30}
                           color = 'black'
                        ></BackButton>
                        </Collapse>
                    </Col>
                  </Row>
                <Row>
                  <Col md={12}>
                    <Collapse isOpen={this.state.collapse && !this.state.isDetailCollapse && !this.state.isPaymentClicked && !this.state.isHistoryClicked && !this.state.isPurchaseClicked && !this.state.isPurchaseCardClicked && !this.state.isPurchaseCheckoutClicked}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                        <CompanyAccountOptionsBoard
                            account = {this.state.account}
                            userObj = {this.props.userObj}
                            toggle = {this.props.toggle}
                            accountList = {this.props.accountList}
                            companyObj = {this.props.companyObj}
                            handleBackClick = {this.handleBackClick}
                            handlePaymentClick = {this.handlePaymentClick}
                            handleHistoryClick = {this.handleHistoryClick}
                            handlePurchaseClick = {this.handlePurchaseClick}
                           />
                     </Collapse>
                     </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isPaymentClicked && !this.props.isBackHide}>  
                        {/* <Button onClick={this.handlePaymentCompBackClick}> Back a </Button> */}
                        <BackButton
                           onClick={this.handlePaymentCompBackClick} 
                           label="Back"
                           size={30}
                           color = 'black'
                        ></BackButton>
                        </Collapse>
                    </Col>
                  </Row>
               
                <Row>
                  <Col md={12}>
                    <Collapse isOpen={ this.state.isPaymentClicked}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                        <CompanyAccountPaymentComp
                            account = {this.state.account}
                            userObj = {this.props.userObj}
                            toggle = {this.props.toggle}
                            createNewCard = {this.addAccountNewCard}
                            accountList = {this.props.accountList}
                            companyObj = {this.props.companyObj}
                            // isHide = {this.props.isHide}
                            />
                     </Collapse>
                     </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isHistoryClicked}>  
                        {/* <Button onClick={this.handleHistoryCompBackClick}> Back b </Button> */}
                        <BackButton
                           onClick={this.handleHistoryCompBackClick} 
                           label="Back"
                           size={30}
                           color = 'black'
                        ></BackButton>
                        </Collapse>
                    </Col>
                  </Row>
               
                <Row>
                  <Col md={12}>
                    <Collapse isOpen={ this.state.isHistoryClicked}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                        <CompanyAccountHistoryComp
                            userObj = {this.props.userObj}
                            toggle = {this.props.toggle}
                            // accountTitle = {this.state.accountTitle}
                            companyObj = {this.props.companyObj}/>
                     </Collapse>
                     </Col>
                </Row>

                {/* <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isPurchaseClicked && !this.state.isPurchaseCardClicked && !this.state.isPurchaseCheckoutClicked}>  
                        <Button onClick={this.handlePurchaseCompBackClick}> Back c </Button>
                        <BackButton
                           onClick={this.handlePurchaseCompBackClick} 
                           label="Back"
                           size={30}
                           color = 'black'
                        ></BackButton>
                        </Collapse>
                    </Col>
                  </Row> */}
               
                <Row>
                  <Col md={12}>
                    <Collapse isOpen={ this.state.isPurchaseClicked && !this.state.isPurchaseCardClicked && !this.state.isPurchaseCheckoutClicked}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                        <CompanyAccountPurchaseComp
                            userObj = {this.props.userObj}
                            toggle = {this.props.toggle}
                            planItemsList = {this.props.planItemsAll}
                            // accountTitle = {this.state.accountTitle}
                            companyObj = {this.props.companyObj}
                            handlePurchaseCardClick = {this.handlePurchaseCardClick}
                            handlePurchaseCompBackClick = {this.handlePurchaseCompBackClick}
                            handlePlanChange = {this.handlePlanChange}
                            handleAutoPayClick = {this.handleAutoPayClick}
                            handBillCycleChange = {this.handBillCycleChange}
                            typeToggle = {this.typeToggle}
                            currentPlanItemId = {this.state.currentPlanItemId}
                            currentPlanItem={this.state.currentPlanItem}
                            autoPay={this.state.autoPay}
                            billCycle={this.state.billCycle}
                            itemType={this.state.itemType}
                            getInventoryList = {this.props.getInventoryList}
                            inventoryList = {this.props.inventoryList}
                            isHide = {this.props.isHide}
                        />
                    </Collapse>
                  </Col>
                </Row>

                 {/* <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isPurchaseCardClicked && !this.state.isPurchaseCheckoutClicked}>  
                        <Button onClick={this.handlePurchaseCardCompBackClick}> Back d </Button>
                        <BackButton
                           onClick={this.handlePurchaseCardCompBackClick} 
                           label="Back"
                           size={30}
                           color = 'black'
                        ></BackButton>
                        </Collapse>
                    </Col>
                  </Row> */}

                <Row>
                  <Col md={12}>
                    <Collapse isOpen={ this.state.isPurchaseCardClicked && !this.state.isPurchaseCheckoutClicked}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                        <CompanyAccountPurchaseCardComp
                          account = {this.state.account}
                          userObj = {this.props.userObj}
                          
                          accountList = {this.props.accountList}
                          handlePurchaseCheckoutClick = {this.handlePurchaseCheckoutClick}
                          handlePurchaseCardCompBackClick = {this.handlePurchaseCardCompBackClick}
                          currentPlanItemId = {this.state.currentPlanItemId}
                          currentPlanItem={this.state.currentPlanItem}
                          autoPay={this.state.autoPay}
                          itemType={this.state.itemType}
                          handleSwitchAccount = {this.handleSwitchAccount}
                          customerCheckout = {this.props.customerCheckout}
                          customerObj = {this.props.customerObj}
                        />
                     </Collapse>
                     </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Collapse isOpen={ this.state.isPurchaseCheckoutClicked}>  
                        {/* <Button onClick={this.handlePurchaseCheckoutCompBackClick}> Back e</Button> */}
                        <BackButton
                           onClick={this.handlePurchaseCheckoutCompBackClick} 
                           label="Back"
                           size={30}
                           color = 'black'
                        ></BackButton>
                        </Collapse>
                    </Col>
                  </Row>

                <Row>
                  <Col md={12}>
                    <Collapse isOpen={ this.state.isPurchaseCheckoutClicked}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                        <CompanyAccountPurchaseCheckoutComp
                          createOrder = {this.createOrderToAccount}
                          account = {this.state.account}
                          userObj = {this.props.userObj}
                          accountList = {this.props.accountList}
                          currentPlanItemId = {this.state.currentPlanItemId}
                          currentPlanItem={this.state.currentPlanItem}
                          payOrder = {this.payOrder}
                          purchaseOrder = {this.props.purchaseOrder}
                          autoPay={this.state.autoPay}
                          itemType={this.state.itemType}
                          customerObj = {this.props.customerObj}
                          billCycle={this.state.billCycle}
                        />
                     </Collapse>
                     </Col>
                </Row>

                
            </div>
        )
    }

    render(){
        return (
            <Col md={12}>
                <Card>
                    <CardBody> 
                        {/* {this.renderTab()} */}
                        {this.renderPlan()}
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

const TabLabel = (props) => {
    return (
        <NavItem style={{ cursor:'pointer'}}>
            <NavLink
                className={classnames({ active: props.activeTab === props.tabId })}
                onClick={() => { props.tabToggle(props.tabId); }}
                // disabled={props.disabled}
            >
                <h4><b>{props.children}</b></h4>
            </NavLink>
        </NavItem>
    )
}

const mapStateToProps = (state) => {
  let {accountList, planItemsAll, purchaseOrder} = state.user;
  let {inventoryList} = state.inventory;
  let props = { accountList, planItemsAll, purchaseOrder, inventoryList}; 
  return props;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    createCard, getAccounts, postAccount, deleteAccount, postOrder, getAllPlanItems, getInventoryList, customerCheckout, customerPayOrder, releaseOrder
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAcountBoard);
