/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import CompanyPaymentInvoice from './CompanyPaymentInvoice';
import CompanyPaymentAdjustment from './CompanyPaymentAdjustment';
import classnames from 'classnames'
import { toast } from "react-toastify";
import log from 'loglevel';

class CompanyPaymentInvoiceBoard extends Component {
    constructor(props){
        super(props);
        this.state={
            activeTab: '1',
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
           this.setState({ activeTab: tab })
        }
     }
  
    render(){
        return (
            <Card>
            <CardBody style={{paddingLeft: '0px', paddingRight: '0px'}}>
            <Row>
                <Col md={12}>
                    <Nav tabs>
                        <NavItem style={{width:'50%'}}>
                            <NavLink 
                                style={{
                                    // border: 'none'
                                    textAlign:'center'
                                }}
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTab('1'); }}
                            >
                                <h4><b>Invoice</b></h4>
                            </NavLink>
                        </NavItem >
                        <NavItem style={{width:'50%'}}>
                        <NavLink 
                            style={{
                                // border: 'none'
                                textAlign:'center'
                            }}
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggleTab('2'); }}
                        >
                            <h4><b>Adjustment</b></h4>
                        </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1" >
                            <CompanyPaymentInvoice
                                userObj = {this.props.userObj}
                                // toggle = {this.toggle}
                                toggle = {this.props.toggle}
                                // accountTitle = {this.state.accountTitle}
                                companyObj = {this.props.companyObj}
                                accountList={this.props.accountList}
                                paymentInvoice = {this.props.paymentInvoice}
                                paymentInvoiceDetails = {this.props.paymentInvoiceDetails}
                                getCustomerInvoiceHistory = {this.props.getCustomerInvoiceHistory}
                                clearCustomerInvoiceHistory = {this.props.clearCustomerInvoiceHistory}
                                getCustomerInvoiceDetails = {this.props.getCustomerInvoiceDetails}
                                activeTab = {this.props.activeTab}
                                // {...props}
                            />
                        </TabPane>
                        <TabPane tabId="2" >
                            <CompanyPaymentAdjustment
                                adjustmentList={this.props.adjustmentList}
                            />
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
            </CardBody>
            </Card>
        );
    }

}

export default CompanyPaymentInvoiceBoard;