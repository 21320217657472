/* eslint-disable */
import React, { PureComponent } from 'react';
import { Col, Container, Row } from 'reactstrap';
import NotificationSystem from 'rc-notification';
import Notification  from 'rc-notification';
// import { translate } from 'react-i18next';

const BasicNotification = ({ color, title, message }) => {
    return (
        <div className={`notification notification--${color}`}>
          <h5 className="notification__title bold-text">{title}</h5>
          <p className="notification__message">{message}</p>
        </div>
        // <div >
        //   <h5 >{title}</h5>
        //   <p >{message}</p>
        // </div>
    );
}

let notificationRU = null;
let notification = null;

const showNotification = ({ color, title, message }) => {
     
     
    notificationRU.notice({
        content: <BasicNotification
                    color={color}
                    title='tests'
                    message='testsetststsetstse'
                />,
        // duration: 5,
        // closable: true,
        style:  { top: 0, left: 240 },
        className: 'right-up',
    });
};

function simpleFn() {
    alert('yes');
    notification.notice({
      content: <span>simple show</span>,
      onClose() {
         
      },
    });
  }

class Notifications extends PureComponent {
    constructor(props){
        super(props);
    }

    componentDidMount() {
        NotificationSystem.newInstance({},  n => notificationRU = n);  
        Notification.newInstance({}, (n) => notification = n);      
    }

    componentWillUnmount() {   
        notificationRU.destroy();
    }

    componentWillReceiveProps(nextProps) {
        // showNotification({
        //     color: nextProps.color,
        //     title: nextProps.title,
        //     message: nextProps.message,
        // });
        // Notification.newInstance({}, n => {n.notice({
        //     content: <BasicNotification
        //                         color={'danger'}
        //                         title={"tsteste"}
        //                         message={' mssageama dfasdf as;df asldf '}
        //                     />,
        // })});    
    }

    render(){
        return (
            // <div>
            //     <showNotification color={this.props.color} title={this.props.title} message = {this.props.message}/>
            // </div>
        //    
        <div>
            <button onClick={() => showNotification({color:'danger', title: "tsteste", message: ' mssageama dfasdf as;df asldf '})}>
                 simple show 
            </button>
        </div>
        );
    }
}

export default Notifications;
// export default translate('common')(Notifiction);