/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import { Card, CardBody, Col } from 'reactstrap';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import {chunk} from '../../../../factories/utils';
import {loadAssetMapData} from '../../../../redux/actions/dashboardActions';
import BasicDashboadEditIcon from './BasicDashboadEditIcon';
import { EditIcon } from 'mdi-react';
import AssetClassEditModal from './AssetClassEditModal';
import ConfirmDeleteDialog from "../../../../shared/components/Dialog/ConfirmationDialog";
import DeleteIcon from "mdi-react/DeleteIcon";

class AssetTableWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            modal: false,
        }
    }

    componentDidMount(){
        // const assetclassid = this.props.assetclassId;
        // const dashname = this.props.dashName;
        // this.props.loadAssetMapData(assetclassid, dashname);
    }

    componentWillReceiveProps(nextProps){
         
        if (nextProps.assetclassId && nextProps.dashName && (nextProps.assetclassId !== this.state.assetclassId || nextProps.dashName !== this.state.dashName)) {
            this.setState({
                assetclassId: nextProps.assetclassId,
                dashName: nextProps.dashName,
            });
             
            this.props.loadAssetMapData(nextProps.assetclassId, nextProps.dashName);
        }
    }

    // ---------------------------------------------------
    deviceColumnFormat = (cell, row) => {
        let colspan = 2;
        //  
        // const handleDeviceOnClick = (e, deviceId) => {
        //     e.preventDefault();
        //     // alert('deviceId');
        //     // return(<div>{this.renderDetailsTb(deviceId)}</div>)
        //     let deviceData = cell.find((item, index) => (item.deviceid === deviceId));
        //     //  
        //     //  
        //     this.setState({
        //         showDetails: true,
        //         curretnDeviceId: deviceId,
        //         currentDeviceData: deviceData,
        //     });
        // }

        // const deviceIdLinkFormatter = (cell, row) => {
        //     let path = `/pages/device/${cell}`;
        //     return(
        //         // <a href={path} onClick = {() => {
        //         //                                   // alert(cell); 
        //         //                                   // alert(row['devicename']);
        //         //                                 }}>{cell}</a>
        //       //  <Button color="link" onClick = {() => {
        //       //                                             this.props.history.push(path);
        //       //                                         }}>{cell}</Button>     
        //         <Link to={path}>{cell}</Link>                   
        //     )
        // }
        
        if (cell){
            let chunkedDevices = chunk(cell, colspan); 
            return (
                <table border='0'>
                    {chunkedDevices.map((dviceRow, rindex) => (
                        <tr>
                            {dviceRow.map((device, dindex)=>(
                                <td>
                                    {/* <a href = {`/pages/device/${device.deviceid}`}  onClick={()=>{}}>{device.deviceid}</a> */}
                                    {/* <a href = {'#'}  onClick={(e)=>{handleDeviceOnClick(e, device.deviceid);}}>{device.deviceid}</a> */}
                                    <Link to={ `/pages/device/${device.deviceid}`}>{device.deviceid}</Link>   
                                </td>
                            ))}
                        </tr>
                    ))}
                </table>
            );
        }
       else{
           return null;
       }

    }

    renderAssetsTb2 = (assetclass) => {
        let data = [];
    // this.props.assetData.forEach((assetclass, acindex)=>{
        let assetClassObj = {};
        let keys = ['assetid', 'assetname', 'className', 'description'];
        let labals = {assetid: 'Asset ID', assetname: 'Asset Name', className: 'Asset Class Name', description: 'Descriptions' };
        let maxHeight = JSON.parse(this.props.layout.position).h * 30 - 20;
        Object.entries(assetclass).forEach(([key, value],index) => {
            if (!Array.isArray(value)){
                assetClassObj[key] = value;
            }
        });

        assetclass.asset.forEach((asset, asindex) => {
            let assetObj = {...assetClassObj, ...asset};
            //  
            //  
            if (asset.assetattributes) {
                asset.assetattributes.forEach((attr, attrindex) => {
                    if (attr.attmetaname) {
                        if (!keys.includes(attr.attmetaname)){
                            keys.push(attr.attmetaname);
                            labals[attr.attmetaname] = attr.displayname ? attr.displayname : attr.attmetaname;
                        }   
                        assetObj[attr.attmetaname]=  attr.value ? attr.value : '';
                        // {
                        //     label: attr.displayname ? attr.displayname : attr.attmetaname,
                        //     value: attr.value ? attr.value : '',
                        //     id: attr.id ? attr.value: -1,
                        // };
                    }                       
                });
            }
            // let assetObj = {...assetObj, ...asset};
            data.push(assetObj);
        });
        keys.push('device');
        labals.device = 'Devices';

        data.forEach((item, index) => {
            keys.forEach((key, kindex) => {
                if (item[key] === undefined || item[key] === null) {
                    item[key] = '';
                }
            });
        });
    // });
        let that = this;
        return (
            <BootstrapTable 
            data = {data} 
            tableHeaderClass = 'header_white' 
            wrapperClasses="table-responsive" 
            containerStyle={ {  height:'100%' } }
            // option = {options}   
            keyField='assetid'          
              
              
            bordered={ false } 
            search 
            maxHeight={maxHeight.toString()} // maxHeight = '430' 
        >
            {/* <TableHeaderColumn dataField='assetid' dataAlign="center" hidden >Asset ID</TableHeaderColumn>
            <TableHeaderColumn dataField='assetname' dataAlign="center"  >Asset Name</TableHeaderColumn>
            <TableHeaderColumn dataField='className' dataAlign="center" >Asset Class Name</TableHeaderColumn>    
            <TableHeaderColumn dataField='description' dataAlign="center"  >Description</TableHeaderColumn> */}
            {keys && labals && keys.map((key, index) => (
                key !== 'device' ?
                <TableHeaderColumn dataField={key} dataAlign="center" hidden={key=='assetid'}>{labals[key]}</TableHeaderColumn>
                :
                <TableHeaderColumn dataField={key} dataAlign="center" dataFormat={that.deviceColumnFormat} >{labals[key]}</TableHeaderColumn>
            ))}
            {/* <TableHeaderColumn dataField='device' dataFormat={this.deviceColumnFormat}  >Devices</TableHeaderColumn> */}
        </BootstrapTable>
        );
    }

    renderAssetClassTbs = () => {
        let dashname = this.props.dashName;
        let data = this.props.assetmapdata[dashname];
        return (
            <div>
                {
                    data && data.map((assetclass, acindex)=>(            
                        <Fragment>{this.renderAssetsTb2(assetclass)}</Fragment>  
                    ))
                }
            </div>
            // <div>
            // {data &&
            // this.renderAssetsTb2(data[0])}
            // </div>
            
        );
        
    }
    toggle = () => {
        // alert('yes');
        console.log('this is asset table widget')
        this.setState((prevState) => ({
            modal : !prevState.modal
        }))
    }
    deleteWidget = (val) => {
        console.log ('val is',val)
        console.log('id in delete is ',this.props.layout.id)

        this.props.onDeleteSubmit(this.props.layout.id);

        // this.setState(prevState=>({
        //     modal: ! prevState.modal,
        // }));
    }
    render() {
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    <h5 className="bold-text">
                        {this.props.dashName} 
                        {/* <BasicDashboadEditIcon 
                            size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}}
                            layout={this.props.layout}
                            onEditSubmit = {this.props.onEditSubmit}
                        />   */}

                        <div style={{float: 'right', display:'inline', cursor: 'pointer'}}>
                            {/* {this.props.layout.locked == 0 && <ConfirmDeleteDialog
                                // label={""}
                                icon = {(props)=>(<DeleteIcon size={15} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} {...props} />)}
                                style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                onConfirm={(e)=>{this.deleteWidget()}}
                                msg={'Do you want to delete the selected widget ? '}
                            />} */}
                        </div>

                        {/* {this.props.layout.display === 0 && */}
                        <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>
                        {/* } */}
                        <AssetClassEditModal
                            modal = {this.state.modal}
                            toggle = {this.toggle}
                            layout = {this.props.layout}
                            onEditSubmit = {this.props.onEditSubmit}
                        />
                    </h5>                      
                </CardHeader>
            
                <CardBody style={{padding:15}}>  
                    {/* <div className="card__title">
                        <h5 className="bold-text">{this.props.dashName} </h5>                      
                    </div> */}
                    {this.renderAssetClassTbs()}
                </CardBody>  
          </Card>
          </Fragment>
        );
    }
}

function mapStateToProps(state) { 
    const props = {...state.dashboard};
    return props;
};
    
function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAssetMapData}, dispatch);
};
  
export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetTableWidget));
