/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse } from 'reactstrap';
import check_mark_circle from '../../../../shared/img/icon/check_mark_circle.svg';
import icon_edit from '../../../../shared/img/icon/edit.png';
import icon_delete from '../../../../shared/img/icon/delete.png';
import icon_add from '../../../../shared/img/icon/add.png';
import ListTable from './ListTable';
import CameraCreateModal from './CameraCreateModal';
import { map } from 'd3-array';
import CameraEditPanel from './cameraEditPanel/CameraEditPanel';
import ConfirmDeleteDialog from '../../../Asset/components/ConfirmDeleteDialog';
import { deleteCamera, loadAssets, removeFromAssets, postAssetAttribute } from '../../../../redux/actions/assetActions';
import PlusIcon from 'mdi-react/PlusIcon';
// import EditAssetPanel from '../EditAssetPanel';
// import FileInputModal from '../FileInputModal';

const CameraListDisplay = ({ cameraList, deleteCamera }) => {
    const [CameraToEdit, setCameraToEdit] = useState(null);
    const [CameraToDelete, setCameraToDelete] = useState(null);
    const [ConfirmDialogueOpen, setConfirmDialogueOpen] = useState(false);
    const [ModalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        // console.log('Camera', cameraList)
    }, [cameraList])
    const toggleDialog = () => {
        setConfirmDialogueOpen(!ConfirmDialogueOpen)
    }

    const toggleModalVisible = () => {
        setModalVisible(!ModalVisible)
    }

    const promptDelete = (id) => {
        console.log('deleting ', id)
        setCameraToDelete(id);
        toggleDialog();
    }

    const deleteCam = () => {
        deleteCamera(CameraToDelete);
        toggleDialog()
    }

    const editSelectedCamera = (id) => {
        console.log('Editing', id)
        setCameraToEdit(id)
    }

    const closePanel = () => {
        setCameraToEdit(null);
    }

    return (
        <>
            <ConfirmDeleteDialog
                modalVisible={ConfirmDialogueOpen}
                onConfirm={deleteCam}
                toggleOff={toggleDialog}
                msg="Are you sure you want to DELETE this camera?"
            />
            <Collapse isOpen={CameraToEdit == null}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={2}>
                                <InsertButton
                                    btnText='New Camera'
                                    variant="primary"
                                    btnContextual='btn-success'
                                    color="custom"
                                    btnGlyphicon='glyphicon-edit'
                                    onClick={toggleModalVisible}
                                >
                                    <PlusIcon /> New Camera
                                </InsertButton>
                                <CameraCreateModal
                                    // assetClassList={this.props.assetClassList}
                                    // devices={this.props.deviceList}
                                    // deviceTypes={this.props.deviceTypes}
                                    toggleModalVisible={toggleModalVisible}
                                    modalVisible={ModalVisible}
                                // saveCam={saveAssetToStore}
                                // assetList={this.props.assetList}
                                />
                            </Col>
                        </Row>
                        <ListTable
                            editable={true} //not used for now
                            // editable={this.state.groupEditable}
                            list={cameraList}
                            handleEditClick={editSelectedCamera}
                            handleDeleteClick={promptDelete}
                        // loadAssets={loadAssets}
                        // postAssetAttribute={postAssetAttribute}
                        />
                    </CardBody>
                </Card>
            </Collapse>
            <Collapse isOpen={CameraToEdit != null}>
                <CameraEditPanel cameraToEdit={CameraToEdit} closePanel={closePanel} />
            </Collapse>
        </>
    )
}

const mapToStateProps = (state) => ({
    cameraList: state.asset.assetList.filter(asset => asset.assetclassname.includes('Camera_Base')),
});


export default connect(mapToStateProps, { deleteCamera, loadAssets, postAssetAttribute, removeFromAssets })(CameraListDisplay);