/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Button, FormGroup, Label, Input, Row, Col } from 'reactstrap';

import { DeleteCircleIcon, AddCircleIcon, CloseCircleIcon, PlusCircleIcon } from 'mdi-react';

import SingleSelection from './SelectionComponents';
import { loadAssetClass, getAssetClassAttribute, getAssetClassAttributeAjax } from '../../../redux/actions/mapActions';
import { generateSelectOptions } from '../../../factories/utils';

class ReportAddDataComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: [10, 21],
      sectionList: {
        10: {
          header: 'Header Test',
          headerSize: 2,
        },
        21: {
          chartType: 1,
          chartName: 'Chart',
          variablesToChart: [
            {
              assetClass: "",
              asset: "",
              variable: "",
              operation: "",
              time: "",
            },
          ]
        },
      }
    }
  }

  componentDidMount() {
    this.props.loadAssetClass();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sectionList) {
      this.setState({
        sectionList: nextProps.sectionList,
      });
    }
  }

  componentWillUpdate() {
    //  
    //  
  }

  componentDidUpdate() {


    this.props.reportAddDataCallBack(this.state.sectionList);
  }

  addSectionClick = (type) => {
    let newId = (this.state.sections.length + 1) * 10 + type
    this.setState({ sections: [...this.state.sections, newId] })
    // --------------------------------------------------------
    let newKey = (Object.keys(this.state.sectionList).length + 1) * 10 + type;
    if (type === 0) {
      this.setState((prevState) => ({
        sectionList: {
          ...prevState.sectionList,
          [newKey]: {
            header: 'Header',
            headerSize: 2,
          },
        }
      }));
    } else if (type === 1) {
      this.setState((prevState) => ({
        sectionList: {
          ...prevState.sectionList,
          [newKey]: {
            chartType: 1,
            chartName: 'Chart',
            variablesToChart: [{
              assetClass: '',
              asset: '',
              deviceType: '',
              variable: '',
              operation: '',
              time: '',
            }]
          },
        }
      }));
    }
  }

  removeButtonClick = (sId) => {

    let newSections = [...this.state.sections]
    newSections.splice(newSections.indexOf(sId), 1)

    this.setState({ sections: newSections })
    // -------------------------------------------------
    this.setState((prevState) => {
      let newSectionList = { ...prevState.sectionList };

      delete newSectionList[sId];
      console.log(newSectionList)
      return ({
        sectionList: newSectionList,
      });
    });
  }

  downArrowClick = (sId) => {
    let newSections = [...this.state.sections]
    let index = newSections.indexOf(sId)
    return
  }

  // headerStateCallBack = (state, id) => {
  //    
  //    
  //    
  // }

  headerStateCallBack = (state, sId) => {
    this.props.headerStateCallBack(state, sId);
    // this.setState((prevState) => {
    //   return({
    //     sectionList: {
    //                     ...prevState.sectionList,
    //                     [sId]: state
    //                   }
    //   });
    // });
  }

  setParentState = (state, sId) => {

    //  
    this.setState((prevState) => {
      let subState = {};
      if (prevState.sectionList[sId]) {
        subState = prevState.sectionList[sId];
      }
      let newSubState = {
        ...subState,
        ...state
      }
      //  
      //  
      //  
      //  
      return {
        // [sId]: newSubState
        // [sId]: {...state},
        sectionList: {
          ...prevState.sectionList,
          [sId]: newSubState,
        }
      }
    });
  }

  setChartDataState = (state, sId) => {
    this.setState((prevState) => {
      let subState = {};
      if (prevState.sectionList[sId]) {
        subState = prevState.sectionList[sId];
      }
      return {
        sectionList: {
          ...prevState.sectionList,
          [sId]: newSubState,
        }
      }
    });
  }

  render() {
    // const renderSections = this.state.sections.map(sId => {
    //   if (sId % 10 === 0)
    //     return (
    //       <HeaderSelectDataOptions
    //         key={sId}
    //         removeButtonClick={this.removeButtonClick}
    //         sectionId={sId}
    //         stateCallBack = {(state) => {this.props.headerStateCallBack(state, sId)}}
    //       />
    //     )
    //   return (
    //     <ChartSelectDataOptions
    //       key={sId}
    //       removeButtonClick={this.removeButtonClick}
    //       sectionId={sId}
    //       stateCallBack = {(state) => {this.props.chartStateCallBack(state, sId)}}
    //       loadAssetClass = {this.props.loadAssetClass}
    //       getAssetClassAttribute = {this.props.getAssetClassAttribute}
    //       getAssetClassAttributeAjax = {this.props.getAssetClassAttributeAjax}
    //       assetClass = {this.props.assetClass}
    //       assetClassAttr = {this.props.assetClassAttr}
    //     />
    //   )
    // })
    // ------------------------------------------------------------------
    const renderSections = Object.entries(this.state.sectionList).map(([sId, data], index) => {
      if (sId % 10 === 0)
        return (
          <HeaderSelectDataOptions
            key={sId}
            removeButtonClick={this.removeButtonClick}
            sectionId={sId}
            section={data}
            stateCallBack={(state) => { this.headerStateCallBack(state, sId) }}
            handleHeaderOnChange={(state) => { this.setParentState(state, sId) }}
          />
        )
      return (
        <ChartSelectDataOptions
          key={sId}
          removeButtonClick={this.removeButtonClick}
          sectionId={sId}
          section={data}
          stateCallBack={(state) => { this.props.chartStateCallBack(state, sId) }}
          loadAssetClass={this.props.loadAssetClass}
          getAssetClassAttribute={this.props.getAssetClassAttribute}
          getAssetClassAttributeAjax={this.props.getAssetClassAttributeAjax}
          assetClass={this.props.assetClass}
          assetClassAttr={this.props.assetClassAttr}
          setParentState={(state) => { this.setParentState(state, sId) }}
        />
      )
    })
    // ------------------------------------------------------------------
    return (
      <>
        <Row>
          <Col>
            <PlusIconButton onClick={() => this.addSectionClick(0)} label="Add Header" />
            <PlusIconButton onClick={() => this.addSectionClick(1)} label="Add Chart" />
          </Col>
        </Row>
        <br />
        {renderSections}
      </>
    )
  }
}
function mapStateToProps(state) {
  const assetClass = state.mapboard.assetClass;
  const assetClassAttr = state.mapboard.assetClassAttr;
  // const allrules = state.rule.rules;
  const props = { assetClass, assetClassAttr };
  return props;
};
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadAssetClass, getAssetClassAttribute, getAssetClassAttributeAjax }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(ReportAddDataComponent);

// -------------------------------------------------------------------------------------
function VariableContainer({ children, onClick }) {
  return (
    <div
      className='container'
      style={{
        border: '1px solid #ddd',
        padding: 20,
        marginBottom: 10,
      }}
    >
      {children}
      <RemoveSectionButton onClick={onClick} />
    </div>
  )
}
function LabelTopTextInput({ md, forIdName, onChange, label, value }) {
  return (
    <Col md={md}>
      <Label htmlFor={forIdName}>{label}</Label>
      <Input type='text' width="90%"
        value={value}
        id={forIdName}
        name={forIdName}
        onChange={onChange} />
    </Col>
  )
}
function LabelSelectionInput({ label, md, ...props }) {
  return (
    <Col md={md}>
      <Label htmlFor={props.forIdName}>{label}</Label>
      <SingleSelection
        {...props} />
    </Col>
  )
}
function IconTextButton({ onClick, icon, label }) {
  return (
    <button style={{ border: 0, background: 'none', }}
      onClick={onClick}>
      {icon({
        size: 25,
        color: '#fa0',
        style: {
          marginRight: 5,
          marginBottom: 5
        }
      })}
      {label}
    </button>
  )
}
function RemoveSectionButton({ onClick }) {
  return (
    <div className='text-center'>
      <IconTextButton onClick={onClick} label="Remove Section"
        icon={(props) => (<CloseCircleIcon {...props} />)}
      />
    </div>
  )
}
function PlusIconButton({ onClick, label }) {
  return (
    <IconTextButton onClick={onClick} label={label}
      icon={(props) => (<PlusCircleIcon {...props} />)}
    />
  )
}

class HeaderSelectDataOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      header: 'Header',
      headerSize: 2,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.section) {
      this.setState({
        ...nextProps.section
      });
    }
  }

  componentDidUpdate() {


    this.props.stateCallBack(this.state);
  }

  handleChange = (event) => {
    let { id, value } = event.currentTarget

    let derivedId = id.split('_')[0]
    // this.setState({ [derivedId]: value });
    this.props.handleHeaderOnChange({ [derivedId]: value });
  }

  render() {
    let sectionId = this.props.sectionId
    return (
      <VariableContainer onClick={() => this.props.removeButtonClick(sectionId)}>
        <h3 className='text-center'>Header</h3>
        <br />
        <FormGroup row>
          <LabelTopTextInput
            forIdName={"header"} label="Header"
            md={6}
            onChange={this.handleChange}
            value={this.state.header}
          />
          <LabelSelectionInput
            forIdName={"headerSize"} label="Size"
            md={6}
            onChange={this.handleChange}
            value={this.state.headerSize}
            options={sizeOptions}
          />
        </FormGroup>
      </VariableContainer>
    )
  }
}

const deepClone = (items) => items.map(item => Array.isArray(item) ? deepClone(item) : item);

class ChartSelectDataOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      chartType: 1,
      chartName: 'Chart',
      variablesToChart: [{
        assetClass: '',
        asset: '',
        deviceType: '',
        variable: '',
        operation: '',
        time: '',
      }]
    }
  }

  componentDidMount() {
    // this.props.loadAssetClass();
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.section && nextProps.section !== this.props.section) {
      this.setState({
        ...nextProps.section
      });
    }
  }

  componentDidUpdate() {


    this.props.stateCallBack(this.state);
  }
  handleChange = (event) => {
    let { id, value } = event.currentTarget;
    let derivedId = id.split('_')[0];
    // this.setState({ [derivedId]: value });
    this.props.setParentState({ [derivedId]: value });
  }
  handleAssetClassChange = (event) => {

    let { id, value } = event.currentTarget;
    let derivedIdAndIndex = id.split('_');
    let updatedVars = deepClone(this.state.variablesToChart);
    updatedVars[derivedIdAndIndex[1]][derivedIdAndIndex[0]] = value;

    // let allAssetOptions = [];
    // let selectedClass = this.props.assetClass.find((item, index) => (item.assetclassid == value));
    // this.props.getAssetClassAttribute({assetclassid: value});

    // if (selectedClass && selectedClass.assets){
    //     allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
    // }

    // this.setState({
    //   variablesToChart: updatedVars,
    //     // selectedAssetClassOption: val,
    //   // allAssetOptions: allAssetOptions,
    //     // selectedAssetOption: null,
    //     // selectedAttributeOption: null,
    //     // isPostback: true,
    // });
    this.props.setParentState({
      variablesToChart: updatedVars,
    });

  }
  handleVariableSelectComponentChange = (event) => {
    let { id, value } = event.currentTarget


    let derivedIdAndIndex = id.split('_')
    let updatedVars = deepClone(this.state.variablesToChart)
    updatedVars[derivedIdAndIndex[1]][derivedIdAndIndex[0]] = value
    // this.setState({
    //   variablesToChart: updatedVars
    // })
    this.props.setParentState({
      variablesToChart: updatedVars,
    });
  }
  deleteVariableSelectComponent = (i) => {
    let updatedVars = deepClone(this.state.variablesToChart)
    updatedVars.splice(i, 1)
    // this.setState({
    //   variablesToChart: updatedVars
    // })
    this.props.setParentState({
      variablesToChart: updatedVars,
    });
  }
  addNewVariableSelectComponent = () => {
    let updatedVars = deepClone(this.state.variablesToChart)
    updatedVars.push({
      assetClass: '',
      asset: '',
      deviceType: '',
      variable: '',
      operation: '',
      time: '',
    })
    this.setState({
      variablesToChart: updatedVars
    })
  }

  onAssetClassChange = (event) => {
    //  
    // let { id, value } = event.currentTarget
    // let derivedId = id.split('_')[0]
    // this.setState({ [derivedId]: value })

    let { id, value } = event.currentTarget
    let derivedId = id.split('_')[0]
    this.setState({ [derivedId]: value })

    // let allAssetOptions = [];
    // let selectedClass = this.props.assetClass.find((item, index) => (item.assetclassid == value));
    // // this.props.getAssetClassAttribute({assetclassid: value});
    //  
    //  
    //  
    //  
    // if (selectedClass && selectedClass.assets){
    //     allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
    // }
    // this.setState({
    //     [derivedId]: value,
    //     // selectedAssetClassOption: val,
    //     // allAssetOptions: allAssetOptions,
    //     // selectedAssetOption: null,
    //     // selectedAttributeOption: null,
    //     // isPostback: true,
    // });
  }

  render() {
    let varsToRender = this.state.variablesToChart


    let sectionId = this.props.sectionId
    return (
      <VariableContainer onClick={() => this.props.removeButtonClick(sectionId)}>
        <h3 className='text-center'>Table</h3>
        <br />
        <FormGroup row>
          <LabelSelectionInput
            forIdName={"chartType"} label="Type"
            md={6}
            onChange={this.handleChange}
            value={this.state.chartType}
            options={typeOptions}
          />
          <LabelTopTextInput
            forIdName={"chartName"} label="Name"
            md={6}
            onChange={this.handleChange}
            value={this.state.chartName}
          />
        </FormGroup>
        <div className='text-right'>
          <PlusIconButton onClick={this.addNewVariableSelectComponent} label="Add Variable" />
        </div>
        {varsToRender.map((variableData, i) => (
          <ChartSelectVariableComponent key={i}
            onChange={this.handleVariableSelectComponentChange}
            values={variableData}
            onClick={this.deleteVariableSelectComponent}
            i={i}
            sId={sectionId}
            assetClass={this.props.assetClass}
            // allAssetOptions = {this.state.allAssetOptions}
            handleAssetClassChange={this.handleAssetClassChange}
            // assetClassAttr = {this.props.assetClassAttr}
            getAssetClassAttributeAjax={this.props.getAssetClassAttributeAjax}
          />
        ))}
      </VariableContainer>
    )
  }
}



function ChartSelectVariableComponent({ onChange, onClick, values, i, sId, assetClass, handleAssetClassChange, getAssetClassAttributeAjax }) {
  //  
  //  
  const [attributeOptions, setAttributeOptions] = useState([]);

  const assetClassOptions = generateSelectOptions(assetClass, 'assetclassname', 'assetclassid');

  let allAssetOptions = [];
  let selectedClass = assetClass.find((item, index) => (item.assetclassid == values.assetClass));
  if (selectedClass && selectedClass.assets) {
    allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
  }

  useEffect(
    () => {
      //  
      //  



      setAttributeOptions([]);
      if (values.assetClass === '') {
        return;
      }
      getAssetClassAttributeAjax(values.assetClass).then((res) => {
        if (res) {

          //  
          //  
          //  
          // const assetAttributeOption = res && res.length > 0 ? generateSelectOptions(res[0].atrributes, 'displayname', 'attributeid') : []; //attributename
          const assetAttributeOption = res && res.length > 0 ? generateSelectOptions(res[0].atrributes, 'displayname', 'attributename') : []; //

          setAttributeOptions([...assetAttributeOption]);
        }
      });
    },
    [values.assetClass],
  );

  // const assetAttributeOption = assetClassAttr && assetClassAttr.length > 0 ? generateSelectOptions(assetClassAttr[0].atrributes, 'displayname', 'attributeid') : [];
  return (
    <FormGroup row /*align='center'*/ style={{ marginTop: 10 }}>
      <LabelSelectionInput
        forIdName={`assetClass_${i}_${sId}`} label="Asset Class"
        md={2}
        // onChange={onChange}
        onChange={handleAssetClassChange}
        value={values.assetClass}
        options={assetClassOptions}
      />
      <LabelSelectionInput
        forIdName={`asset_${i}_${sId}`} label="Asset"
        md={2}
        onChange={onChange}
        value={values.asset}
        // options={varDeviceOptions}
        options={allAssetOptions}
      />
      <LabelSelectionInput
        forIdName={`variable_${i}_${sId}`} label="Variable"
        md={2}
        onChange={onChange}
        value={values.variable}
        // options={varVariableOptions}
        options={attributeOptions}
      />
      <LabelSelectionInput
        forIdName={`operation_${i}_${sId}`} label="Operation"
        md={2}
        onChange={onChange}
        value={values.operation}
        options={varOperationOptions}
      />
      {
        // Only Show if operation != raw
      }
      <LabelSelectionInput
        forIdName={`time_${i}_${sId}`} label="Time"
        md={2}
        onChange={onChange}
        value={values.time}
        options={varTimeOptions}
      />
      <Col md={1}>
        <button style={{ border: 0, background: 'none' }} onClick={() => onClick(i)}>
          <DeleteCircleIcon size={28} color="#fa0" style={{ marginTop: 32 }} />
        </button>
      </Col>
    </FormGroup>
  )
}

const sizeOptions = [
  { value: 1, label: "Small" },
  { value: 2, label: "Medium" },
  { value: 3, label: "Large" },
]
const typeOptions = [
  { value: 1, label: "Table" },
  { value: 3, label: "Chart" },
  { value: 2, label: "Chart and Table" },
]
// const varOperationOptions = [
//   { value: 'max', label: 'Max' },
//   { value: 'min', label: 'Min' },
//   { value: 'average', label: 'Average' },
//   { value: 'count', label: 'Count' },
//   { value: 'sum', label: 'Sum' },
//   { value: 'raw', label: 'Raw' },
// ]
const varOperationOptions = [
  { value: 1, label: 'Max' },
  { value: 2, label: 'Min' },
  { value: 3, label: 'Average' },
  { value: 4, label: 'Count' },
  { value: 5, label: 'Sum' },
  { value: 6, label: 'Raw' },
]
const varTimeOptions = [
  { value: 1, label: "1 Minute" },
  { value: 5, label: "5 Minutes" },
  { value: 30, label: "30 Minutes" },
  { value: 60, label: "1 Hour" },
  { value: 360, label: "6 Hours" },
  { value: 1440, label: "1 Day" },
]

////////////////////////////////
// Placeholder Select Options //
////////////////////////////////
const varDeviceOptions = [
  { value: 1231, label: "Device Name 1" },
  { value: 1232, label: "Device Name 2" },
  { value: 1233, label: "Device Name 3" },
]
const varVariableOptions = [
  { value: 1231, label: "Variable Name 1" },
  { value: 1232, label: "Variable Name 2" },
  { value: 1233, label: "Variable Name 3" },
]

