/* eslint-disable */
import React, { Component, PureComponent } from 'react';
import { Col, Container, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, linearGradient } from 'recharts'; 
import WidgetChart1 from './widgets/widget_chart_1';
import WidgetChart2 from './widgets/widget_chart_2';
import { TIME_OPTION, PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3 } from '../../../constants/dataConstants'
import WidgetCircle1 from './widgets/widget_circle_1';
import WidgetCircle2 from './widgets/widget_circle_2';
import WidgetCircle3 from './widgets/widget_circle_3';
import WidgetCircle4 from './widgets/widget_circle_4';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Select from 'react-select';
import Panel from './widgets/Panel';


const customStyles = {
  control: base => ({
    ...base,
    // height: 35,
    // minHeight: 35
    height: 35,
    minHeight: 35
  }),
  indicatorsContainer: base => ({
    ...base,
    padding: "0",
  }),
};
class GarageBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDateMoment: moment(),
      endDateMoment: moment(),
      startTimeOptions: TIME_OPTION.filter((item, index) => (index !== 24)),
      endTimeOptions: TIME_OPTION.filter((item, index) => (index !== 0)),
      startTime: TIME_OPTION[0],
      endTime: TIME_OPTION[24],
    }
  }


  totalOccupancy = (statusData) => {
    if (statusData) {
      let alarm = statusData.attributes.Alarm;
      let total;
      let value;

      alarm.forEach(item => {
        if (item.value == "OFF") {
          value = 0;
        }
        else
          value = item.value;

        total += value;
      })

      return total;
    }
  }
  toPercent = (decimal, fixed = 0) => {
    return `${(decimal * 100).toFixed(fixed)}%`;
  }

  getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;

    return this.toPercent(ratio, 2);
  };
  renderTooltipContent = (o) => {
    const { payload, label } = o;

    return (
      <div style={{ "background-color": "rgba(255, 255, 255, 0.75)", padding: "10px", "border-radius": "10px" }}>
        <ul className="list">
          {payload && payload.map((entry, index) => {
            return (
              <li key={`item-${index}`} style={{ color: entry.color }}>
                {`${entry.name}: ${this.toPercent(entry.value, 2)}`}
              </li>
            )
          })}
          <>
            {`Date: ${label ? label.slice(0, 16) : null}`}
          </>
        </ul>
      </div>
    );

  };
  handleDateRangeChange = ({ startDate, endDate }) => {
    this.setState({
      startDateMoment: startDate,
      endDateMoment: endDate
    })
  }
  onReset = () => {
    this.setState({
      startDateMoment: moment(),
      endDateMoment: moment(),
      startTime: TIME_OPTION[0],
      endTime: TIME_OPTION[24],
    }, () => {
      let obj = {
        "assetId": this.props.currentAsset ? this.props.currentAsset.assetid : 0,
        "deviceId": 0,
        "msgId": 0,
        "attributeId": 0,
        "startTime": this.state.startDateMoment.format('YYYY-MM-DD') + " " + this.state.startTime.label,
        "endTime": this.state.endDateMoment.format('YYYY-MM-DD') + " " + this.state.endTime.label

      }
      this.props.getCalculateResult(obj);

    })
  }
  handleStartTimeChange = (value) => {
    this.setState({
      startTime: value,
      endTime: null,
    })
  }

  handleEndTimeChange = (value) => {
    this.setState({
      endTime: value,
    });
  }
  validate = () => {
    return this.state.startTime != null &&
      this.state.endTime != null &&
      this.state.startDateMoment != null &&
      this.state.endDateMoment != null
  }
  onSubmit = () => {
    if (this.state.startDateMoment != null && this.state.endDateMoment != null) {
      let obj = {

        "assetId": this.props.currentAsset ? this.props.currentAsset.assetid : 0,
        "deviceId": 0,
        "msgId": 0,
        "attributeId": 0,
        "startTime": this.state.startDateMoment.format('YYYY-MM-DD') + " " + this.state.startTime.label,
        "endTime": this.state.endDateMoment.format('YYYY-MM-DD') + " " + this.state.endTime.label

      }
      this.props.getCalculateResult(obj);
    }
  }
  render() {
    const { t } = this.props;
    const renderColorfulLegendText = (value, entry) => {
      const { color } = entry;

      return <span style={{ color, marginRight: 20 }}>{value}</span>;
    };

    var newList = this.props.calculatedResult.map((payload) => {
      var newPayload = {
        Level1: 0.0,
        Level2: 0.0,
        Level3: 0.0,
        LevelTotal: 0.0,
        date: ""
      };
      // if (parseInt(payload.Level1) > 0) {
      //   newPayload.Level1 = parseInt(payload.Level1) / 73
      // }
      // if (parseInt(payload.Level2) > 0) {
      //   newPayload.Level2 = parseInt(payload.Level2) / 83
      // }
      // if (parseInt(payload.Level3) > 0) {
      //   newPayload.Level3 = parseInt(payload.Level3) / 97
      // }
      // if (parseInt(payload.LevelTotal) > 0) {
      //   newPayload.LevelTotal = parseInt(payload.LevelTotal) / 253
      // }
      if (payload.date != "") {
        newPayload.date = payload.date;
      }

      // To limit number overage or negative for graph
      if (parseInt(payload.Level1) > 0) {
        if(parseInt(payload.Level1) > 73) newPayload.Level1 = 1;
        else newPayload.Level1 = parseInt(payload.Level1) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[1];
      }
      if (parseInt(payload.Level2) > 0) {
        if(parseInt(payload.Level2) > 83) newPayload.Level2 = 1;
        else newPayload.Level2 = parseInt(payload.Level2) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[2];
      }
      if (parseInt(payload.Level3) > 0) {
        if(parseInt(payload.Level3) > 97) newPayload.Level3 = 1;
        else newPayload.Level3 = parseInt(payload.Level3) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[3];
      }
      
      newPayload.LevelTotal = (newPayload.Level3 + newPayload.Level2 + newPayload.Level1) / 3;
      
      return newPayload;
    })

    class CustomizedAxisTick extends PureComponent {
      render() {
        const { x, y, payload } = this.props;
        return (
          <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end"
              fill="#666" transform="rotate(-35)" fontSize={11} fontWeight={400}>
              {payload.value.slice(0, 16)}
            </text>
          </g>
        );
      }
    }
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('Dashboard')}</h3>
          </Col>
        </Row>
        <Row>
          <WidgetCircle1 statusData={this.props.statusData}
            latestCalculateResult={this.props.latestCalculateResult}
          />
          <WidgetCircle2 statusData={this.props.statusData}
            latestCalculateResult={this.props.latestCalculateResult}
          />
          <WidgetCircle3 statusData={this.props.statusData}
            latestCalculateResult={this.props.latestCalculateResult}
          />
          <WidgetCircle4 statusData={this.props.statusData}
            latestCalculateResult={this.props.latestCalculateResult}
          />
        </Row>
        <br />
        <br />
        <Row>
          <Col md={5}>
            <DateRangePicker
              isOutsideRange={() => false}
              startDate={this.state.startDateMoment} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDateMoment} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              minimumNights={0}
              onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              small={true}
              block={true}
            />
          </Col>
          <Col md={3}>
            <Row style={{ padding: '0px 10px' }}> 
            
              <Col md={6} style={{ padding: '0px 5px' }}>
                <Select
                  isDisabled={this.props.isTimeDisabled}
                  options={this.state.startTimeOptions}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  placeholder={'Start...'}
                  value={this.state.startTime}
                  onChange={this.handleStartTimeChange}
                  styles={customStyles}
                />
              </Col>
            
              <Col md={6} style={{ padding: '0px 5px' }}>
                <Select
                  isDisabled={this.props.isTimeDisabled}
                  options={this.state.endTimeOptions}
                  getOptionLabel={({ label }) => label}
                  getOptionValue={({ value }) => value}
                  placeholder={'End...'}
                  value={this.state.endTime}
                  onChange={this.handleEndTimeChange}
                  styles={customStyles}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Button color='custom' onClick={this.onSubmit} disabled={!this.validate()} >
              Submit
            </Button>
            <Button color='custom' type="reset" onClick={this.onReset}>
              Reset/Refresh
            </Button>
          </Col>
        </Row>
        <Row>
          <ResponsiveContainer height={600} className="dashboard__area">
            <AreaChart data={newList} margin={{ top: 20, left: -20, bottom: 20, right: 20 }}>
              {/*<XAxis dataKey="date" dy={15} tick={1} height={70} interval="preserveEnd" hide={true} tickLine={true} />*/}
              <defs>
                <linearGradient id="colorLvT" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#4666e2" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#4666e2" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorLv1" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#9dd3fb" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#9dd3fb" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorLv2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#efdde3" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#efdde3" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorLv3" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#ebcbfd" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#ebcbfd" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey="date" dy={15} tick={<CustomizedAxisTick />} height={70} interval="preserveEnd" />
              <YAxis type="number" tickFormatter={this.toPercent} width={70} />
              {/* <Tooltip content={<CustomTooltipTestBootstrapCard />} /> */}
              <Tooltip content={this.renderTooltipContent} />
              <Legend iconType="square" formatter={renderColorfulLegendText} />
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <Area name="Total Lot Occupancies" stackId="1" type="monotone" dataKey="LevelTotal" fill="url(#colorLvT)" stroke="#4666e2" fillOpacity={0.5} strokeWidth={5} />
              <Area name="Level 1 Lot Occupancies" stackId="2" type="monotone" dataKey="Level1" fill="url(#colorLv1)" stroke="#4baff8" fillOpacity={0.5} strokeWidth={5} />
              <Area name="Level 2 Lot Occupancies" stackId="3" type="monotone" dataKey="Level2" fill="url(#colorLv2)" stroke="#f0c7ce" fillOpacity={0.5} strokeWidth={5} />
              <Area name="Level 3 Lot Occupancies" stackId="4" type="monotone" dataKey="Level3" fill="url(#colorLv3)" stroke="#e2a4fc" fillOpacity={0.5} strokeWidth={5} />
            </AreaChart>
          </ResponsiveContainer>
          {/*
            <WidgetChart1 
              history24HObjData={this.props.history24HObjData}
              asset24HObj = {this.props.asset24HObj}
              currentAsset = {this.props.currentAsset}
            />
            <WidgetChart2 
              historyObjData={this.props.historyObjData}
              asset1MonthObj = {this.props.asset1MonthObj}
              currentAsset = {this.props.currentAsset}
            />
    */}
        </Row>
      </Container>
    )
  }
}


GarageBoard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(GarageBoard);
