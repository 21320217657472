/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ActionEditForm from './components/ActionEditForm';
import ActionEditStepper from './components/ActionEditStepper';
import ActioinEdit from './components/ActionEdit';

const ActionNotificationEditPage = (props) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Notification Edit</h3>
        <hr />
      </Col>
    </Row>
    {/* <Row>
      <ActionEditForm actionId={props.match.params.id} />
    </Row> */}
    <Row>
        {/* <ActionEditStepper actionId={props.match.params.id} /> */}
        <ActioinEdit actionID={props.match.params.id} />
    </Row>
  </Container>
);

export default ActionNotificationEditPage;
