/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import {connect } from 'react-redux';
// import {bindActionCreators } from 'redux';
import { Card, CardTitle, CardBody, CardGroup, CardImg, CardFooter, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container, Input, Label } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import { Modal, ModalHeader, ModalBody,ModalFooter, FormGroup } from 'reactstrap';
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import CompanyAccountOptionsBoard from './CompanyAccountOptionsBoard';
import PlusIcon from 'mdi-react/PlusIcon';
import profile_img from '../../../shared/img/profile.png';
import {getAccounts} from '../../../redux/actions/userAction';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class CompanyAcountTotalBoard extends Component {
    constructor(props){
        super(props);
        this.state={
            activeTab : '1',
            showModal: false,
            showAddModal: false,
            checked: true,
            accountName:''
           
        }

        // const [checked, setChecked] = React.useState(false);

       

         
    } 

  

    toggleModalVisible = () => {
        // Reset modal if closing
        if (this.state.modalVisible) { this.setState(this.initialState) }
        this.setState({ modalVisible: !this.state.modalVisible })
         
     }

     addNewUserButton = () => {
        // const check1 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']);
        return (
            <InsertButton
              btnText='Add New'
              btnContextual='btn-success'
              btnGlyphicon='glyphicon-edit'
              onClick={ () => this.handleAddNewButtonClick(onClick) }>
                  <PlusIcon/> Add New
              </InsertButton>
          );
    }

    handleAddNewButtonClick = () => {
        this.setState({
            showAddModal: true,
          });
    }

    renderAddModal = () => {
        this.setState({
          showModal: true,
        });
      }

 onCancleClick = (e) => {
    this.setState({
        showAddModal: false,
      });
    }

    toggleChecked = () => {
        this.setState({
            checked: !this.state.checked,
          });
      };  

      handleChange = (e) =>{
        this.setState({accountName: e.target.value});
      }

      onSaveClick = () => {
        if (this.props.companyObj) {
           let defaultData = {
            id: 0,
            name: this.state.accountName,
            customerId: 1,
            accountType: "C",
            companyCode: this.props.companyObj.companyCode,
            password: "evan123",
            paymentMethod: "CC",
            isNotification: "Y"
           }
           // SPECIFY CORRECT ATTRIBUTES
           let data = {
                id: 0,
                name: this.state.accountName,
                customerId: 1,
                accountType: "C",
                companyCode: this.props.companyObj.companyCode,
                password: "evan123",
                paymentMethod: "CC",
                isNotification: "Y"
           }
           this.props.addNewAccount({ ...defaultData, ...data })

           this.setState({
            showAddModal: false,
          });   
        }else{
            toast.error('No Company Info')
        }
     }

     deleteAcct = (item) => {
         
        let defaultData = {
         id: item.id,
         companyCode: item.companyCode,
        }
        // SPECIFY CORRECT ATTRIBUTES
        let data = {
            id: item.id,
            companyCode: item.companyCode,
        }
        this.props.deleteAccount({ ...defaultData, ...data })

  }

  onClose = () => {
    // this.setState({
    //     showModal: false
    // });
    // this.clearCompanyObj();
    this.props.cancelToggle();
};

    renderPlan = () => {

        const cardsstyle ={
            'margin': '0 auto',
            'max-width': '1280px',
            'text-align': 'center',
            'vertical-align':'top',
            'float':'left'
            
          };
    
        //   const activeAccounts = this.props.accountList.filter(account => {
        //     return account.status === 1 
        //   });
    
        return(
               <div className="App-cards" style={ cardsstyle}>
                
                {/* <div className="App-cards" style={ cardsstyle}> */}
                {/* <div> */}
                {/* <div> */}
                {/* <CardGroup> */}
                <Row>
                     
                    {/* <Card>
                        <CardBody> 
                        <CardImg top style={{'width':"200px", 'height':"200px"}}  src={profile_img} alt="Card image cap" />
                         <br/>
                        <Button   variant="primary" color="custom" onClick={this.props.toggle}>Account 3</Button>
                        </CardBody>
                    </Card> */}
                   
                    {this.props.accountList && Array.isArray(this.props.accountList) && this.props.accountList.filter(item => item.status === 1).map((item, index) => 
                    <Col md={4} style={{padding: '20px 10px 20px 10px'}}>
                    <Card style={{ border: '1px solid #D3D3D3', padding: '0px', margin: '20px 0px 20px 0px' }}>
                        <CardBody style={{padding: '30px 30px 0px 30px'}}>
                            {/* <CardImg top style={{'width':"200px", 'height':"200px"}}  src={profile_img} alt="Card image cap" /> */}
                         {/* <br/> */}
                         {/* <Button  variant="primary" color="custom" onClick={() => this.props.handleAccountClick(item)}>Account: {item.name}</Button> */}
                         {/* <Button color="danger" onClick={() => this.deleteAcct(item)}>Remove</Button>{' '} */}
                         <Row>
                             <Col md={3}>
                                <img src={profile_img} alt="Card image cap"></img>
                             </Col>
                             <Col md={9} style={{display:'flex', alignItems: 'center'}}>
                                <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleAccountClick(item)}}><span style={{fontSize:'18px'}}><b>{item.name}</b></span></a>
                             </Col>
                         </Row>
                        </CardBody> 
                         <CardFooter style={{textAlign:'right', padding:'0px 0px 5px 0p',backgroundColor:'white'}}>
                            <Button color="link" onClick={() => this.deleteAcct(item)} size="sm" style={{color: 'red', margin: '0px'}}>Remove</Button>{' '}
                         </CardFooter>
                    </Card>
                    </Col>
                    )}
                    {/* </CardGroup> */}
                    </Row>
                  {/* </div> */}
                  {/* <br/> */}
                  <Row style={{margin:'10px', textAlign: 'right', display: 'flex', justifyContent: 'flex-end'}}>
                    {/* <InsertButton
                        btnText='Add New'
                        btnContextual='btn-success'
                        btnGlyphicon='glyphicon-edit'
                        onClick={ () => this.handleAddNewButtonClick() }>
                            <PlusIcon/> Add New Account 
                    </InsertButton> */}
                    <Button
                        outline
                        style={{
                            borderColor: "black",
                            border: "dashed",
                            borderRadius: "20px",
                            textAlign: "right"
                        }}
                        onClick={ () => this.handleAddNewButtonClick() }
                    >
                    <div><i class="fas fa-plus"></i> <span style={{marginLeft:'10px'}}>Add New Account</span> </div>
                    {/* <PlusIcon/> Add New Account  */}
                </Button>
                 </Row>
                {/* </div> */}
                <div>
                <div style={{textAlign:'left'}}>
            
                        <Button outline color='secondary' type="reset" onClick={this.onClose}>
                            Close
                        </Button>
                        </div>
         

                 <Modal animationType="slide" 
                    transparent={false} 
                    size='lg'
                    isOpen={this.state.showAddModal} toggle={this.renderAddModal}>
                <ModalHeader toggle={this.props.toggle}>New Account</ModalHeader>
                <ModalBody>
                <LabelTextInput
                  forIdName={'accountName'} label={'Account Name'}
                  onChange={this.handleChange}
                 

               />

               
                <FormControlLabel
                        control={<Switch checked={this.state.checked} onChange={this.toggleChecked} />}
                        label="Notification"
                    />
                   
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onSaveClick} >Save</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
                 
                </div>
            </div>
        )
    }

    render(){
         
        return (
            <Fragment>
            <Col md={12}>
                <Card>
                    <CardBody> 
                        {/* {this.renderTab()} */}
                        {this.renderPlan()}
                    </CardBody>
                </Card>
            </Col>
            </Fragment>
        );
    }
}


export default CompanyAcountTotalBoard;

function LabelTextInput({ forIdName, onChange, label, value, big }) {
    return (
       <FormGroup row>
          <Col md={3} style={{ textAlign: 'left' }}>
             <Label htmlFor={forIdName}>{label}</Label>
          </Col>
          <Col md={8}>
             <Input type={`text${big ? 'area' : ''}`} width="90%" style={{ marginBottom: 5 }}
                // style={{ borderColor: err ? 'red' : '' }}
                value={value}
                id={forIdName}
                name={forIdName}
                onChange={onChange} />
          </Col>
       </FormGroup>
    )
 }
