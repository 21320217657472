/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { EditIcon } from 'mdi-react';
import AssetAttrEditform from './AssetAttrEditform';

class AssetAttrEditModal extends Component {
    constructor(props){
        super(props);
        this.state={
            modal: false,
            tital: ''
        }
    }
// --------------------------------------------------------------
    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    handleOk = (userObj, name, parameter) => {
        let layout = JSON.parse(JSON.stringify(this.props.layout));
        layout.name = name ;
        layout.parameter = parameter;
        this.props.onEditSubmit(layout);
        this.toggle();

    }
    handleCancle = () => {
        this.toggle();
    }
    render(){
        return (
            <Fragment>
                <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>   
                <Modal 
                    animationType="slide" 
                    transparent={false} 
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    size = {'lg'}
                >
                    <ModalHeader 
                        toggle={this.toggle}
                        //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                    >
                        {this.state.tital}
                    </ModalHeader>
                    <ModalBody>
                    <AssetAttrEditform
                        name = {this.props.layout.name}
                        parameter={this.props.layout.parameter}
                        handleOk = {this.handleOk}
                        handleCancle = {this.handleCancle}
                    />
                    </ModalBody>
                </Modal>
            </Fragment>
        ); 
    }
}

export default AssetAttrEditModal