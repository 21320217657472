/* eslint-disable */

export default function PCMPlayer(option) {
    this.init(option);
    this.logger = new Logger("pcmPlayer");
}

/**
 * 初始化配置
 */
PCMPlayer.prototype.init = function(option) {
    var defaults = {
        encoding: '16bitInt',
        channels: 1,
        sampleRate: 8000,
    };
    this.option = Object.assign({}, defaults, option);
    this.samples = new Float32Array();
    this.maxValue = this.getMaxValue();
    this.typedArray = this.getTypedArray();
    this.cacheBuffer = 4000; // 缓存的buffer数量
    this.createContext();
};

/**
 * 获取二进制数组对应的最大值
 */
PCMPlayer.prototype.getMaxValue = function () {
    var encodings = {
        '8bitInt': 128,
        '16bitInt': 32768,
        '32bitInt': 2147483648,
        '32bitFloat': 1
    }

    return encodings[this.option.encoding] ? encodings[this.option.encoding] : encodings['16bitInt'];
};

/**
 * 获取对应的二进制数组类型
 */
PCMPlayer.prototype.getTypedArray = function () {
    var typedArrays = {
        '8bitInt': Int8Array,
        '16bitInt': Int16Array,
        '32bitInt': Int32Array,
        '32bitFloat': Float32Array
    }

    return typedArrays[this.option.encoding] ? typedArrays[this.option.encoding] : typedArrays['16bitInt'];
};

/**
 * 创建音频播放环境
 */
PCMPlayer.prototype.createContext = function() {
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();//由音频模块连接而成的音频处理图
    this.gainNode = this.audioCtx.createGain();//创建一个GainNode,可以控制音频的总音量
    this.gainNode.gain.value = 1;//音量，取值0-1
    this.gainNode.connect(this.audioCtx.destination);//destination表示当前AudioContext中所有节点的最终节点，一般表示音频渲染设备
    this.startTime = this.audioCtx.currentTime;
};

/**
 * 判断数据是否为ArrayBuffer
 */
PCMPlayer.prototype.isTypedArray = function(data) {
    return (data.byteLength && data.buffer && data.buffer.constructor == ArrayBuffer);
};

/**
 * 格式化数据为Float32Array格式
 */
PCMPlayer.prototype.getFormatedValue = function(data) {
    var data = new this.typedArray(data.buffer),
        float32 = new Float32Array(data.length),
        i;

    for (i = 0; i < data.length; i++) {
        float32[i] = data[i] / this.maxValue;
    }
    return float32;
};

/**
 * 设置音量
 */
PCMPlayer.prototype.setVolume = function(volume) {
    this.gainNode.gain.value = volume;
};

/**
 * 关闭音频播放
 */
PCMPlayer.prototype.destroy = function() {
    this.audioCtx.close();
    this.audioCtx = null;
    this.samples = null;
};

/**
 * 缓存音频数据
 */
PCMPlayer.prototype.feed = function (data) {
    if (!this.isTypedArray(data)) {
        return;
    }

    data = this.getFormatedValue(data);
    var tmp = new Float32Array(this.samples.length + data.length);
    tmp.set(this.samples, 0);
    tmp.set(data, this.samples.length);
    this.samples = tmp;
    if (this.samples.length > this.cacheBuffer) { // 累计到一定buffer，执行播放
        this.play(this.samples);
        this.samples = new Float32Array();
    }
};

/**
 * PCM格式音频播放
 */
PCMPlayer.prototype.play = function (data) {
    if (!data.length) {
        return;
    }

    var bufferSource = this.audioCtx.createBufferSource(),//创建一个AudioBufferSourceNode对象, 可以通过AudioBuffer对象来播放和处理包含在内的音频数据
        length = data.length / this.option.channels,
        audioBuffer = this.audioCtx.createBuffer(this.option.channels, length, this.option.sampleRate),//创建一个空的AudioBuffer对象, 并且能够通过AudioBufferSourceNode来进行数据填充和播放
        audioData,
        channel,
        offset,
        i,
        decrement;

    for (channel = 0; channel < this.option.channels; channel++) {
        audioData = audioBuffer.getChannelData(channel);
        offset = channel;
        decrement = 50;
        for (i = 0; i < length; i++) {
            audioData[i] = this.samples[offset];
            /* fadein */
            if (i < 50) {
                audioData[i] = (audioData[i] * i) / 50;
            }
            /* fadeout*/
            if (i >= (length - 51)) {
                audioData[i] = (audioData[i] * decrement--) / 50;
            }
            offset += this.option.channels;
        }
    }

    if (this.startTime < this.audioCtx.currentTime) {
        this.startTime = this.audioCtx.currentTime;
    }
    bufferSource.buffer = audioBuffer;
    bufferSource.connect(this.gainNode);
    bufferSource.start(this.startTime);//开始播放声源
    this.startTime += audioBuffer.duration;
};

/**
 * 暂停播放音频
 */
PCMPlayer.prototype.pause = function () {
    if (this.audioCtx.state === 'running') {
        this.audioCtx.suspend();
    }
}

/**
 * 恢复播放音频
 */
PCMPlayer.prototype.resume = function () {
    if (this.audioCtx.state === 'suspended') {
        this.audioCtx.resume();
    }
}