/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import icon_edit from '../../../shared/img/icon/edit.png';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_add from '../../../shared/img/icon/add.png';

class GeoFenceActionCard extends Component{
    constructor(props){
        super(props);
        this.state={
            data: [
                {
                    actionid: 1,
                    actionname: 'action_test',
                    actiontype: 'Alert',
                    geofencename: 'geo_fence_1',
                    rulename: 'rule_name',
                }
            ]
        }
    }

    editButtonFormatter = (cell, row) => {
        // let path = `/pages/device/${cell}`;
        let path = ``;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                <img src={icon_edit} style={{width: 20, height: 20}} alt={"Edit"} onClick={(e)=>{this.handleRowClick(row);}}/>{' '}
                <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} onClick={(e)=>{this.handleRowDeleteClick(row);}} />
            </div>
        )
    }

    renderActionList(){
        const deviceIdLinkFormatter = (cell, row) => {
            // let path = `/pages/device/${cell}`;
            let path = ``;
            return(
                <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            )
          }
      
          const options = {
            page: 1,  // which page you want to show as default
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, 
                // {
                // text: 'All', value: this.props.currentDeviceList.length
                // } 
            ], // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
          };
        return(
            <Col md={12}>
                  <BootstrapTable data={ this.state.data } tableHeaderClass = 'header_white' pagination = {true} options={ options }     bordered={ false } search>
                      <TableHeaderColumn dataField='actionid' isKey={ true } dataAlign="center" dataFormat={ deviceIdLinkFormatter }  >Action ID</TableHeaderColumn>
                      <TableHeaderColumn dataField='actionname' dataAlign="center" dataSort  >Action Name</TableHeaderColumn> 
                      <TableHeaderColumn dataField='actiontype' dataAlign="center" dataSort  >Action Type</TableHeaderColumn> 
                      <TableHeaderColumn dataField='geofencename' dataAlign="center" dataSort  >Associated GeoFence</TableHeaderColumn>
                      <TableHeaderColumn dataField='rulename' dataAlign="center" >Associated Rule</TableHeaderColumn>
                      <TableHeaderColumn dataField='action' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn>
                  </BootstrapTable>
            </Col>
        );
    }

    render(){
        return(
            <div>
                {this.renderActionList()}
            </div>
        );
    };
} 

export default GeoFenceActionCard;