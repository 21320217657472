/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Label, Input, Button, Container, CardFooter } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import { loadIcons, deleteIcon } from '../../../redux/actions/assetActions'
import IconElement from './IconElement'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { event } from 'jquery';
import axios from 'axios';
import {toast} from 'react-toastify';
import {WEB_SERVICE_ROOT} from '../../../constants/appConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

class AssetIconEditModal extends Component{
    constructor(props){
        super(props);
        this.state={
            title: 'Asset Icon',
            currentIconId: null,
            assetId: this.props.assetId,
            assetName:  this.props.assetName,
            uploadedFile: null,
            iconSettingModal: false,
            selectedIcon: null,

        }
    }

    componentDidMount() {
        this.props.loadIcons();
    }

    onAddClick = (e) => {
        // this.upsertAssetIcon(this.props.assetId, this.state.currentIconId);
        this.props.editIcon(this.state.selectedIcon.iconid, this.state.selectedIcon.iconsvg);
        this.props.toggle();
    }

    onCancleClick = (e) => {
        this.props.toggle();
    }

    upsertAssetIcon = (assetId, iconId) => {

    }

    onIconListClick = (index, icon) => {
        // alert('okay');
        this.setState({
            ...this.state,
            selectedIcon: icon
        });
    }

    onFileChange = event =>{
        this.setState({
            ...this.state,
            uploadedFile: event.target.files[0]
        })
    }

    onFileUpload = ()=>{
        const formData = new FormData();
        formData.append("file", this.state.uploadedFile);
        axios.post(`${WEB_SERVICE_ROOT}Icon/upload`, formData).then(res=>{
            toast.success(res.data.message)
            this.props.loadIcons()
        }).catch(err=>{
            console.log(err);
            toast.error('File size exceeded limit. Try again')
        }).finally(()=>{
            this.setState({
                ...this.state,
                uploadedFile: null
            })
        })
    }

    handleIconDelete = () => {
        if (confirm(`Delete icon ${this.state.selectedIcon.iconname}`)){
            this.props.deleteIcon(this.state.selectedIcon)
            this.setState({
                ...this.state,
                selectedIcon: null
            })
        }
    }

    render(){
        return(
            <Modal
                // animationType="slide"
                // transparent={false}
                isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle}>
                <ModalHeader toggle={this.toggle}>{this.state.tital}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                        {/* More Details Can go here */}
                            <Row style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                                <Col md={3} style={{ textAlign: 'left' }}>
                                    <Label htmlFor='react-select-2-input'>{this.props.assetName}</Label>
                                </Col>
                                <Col md={9}>
                                    {this.state.selectedIcon && <IconElement width={32} height={32} path={this.state.selectedIcon.iconsvg} data={this.state.selectedIcon.data}/>}
                                    {this.state.selectedIcon && <FontAwesomeIcon icon={faTrashAlt} color='red' onClick={this.handleIconDelete}/>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        {/* <Label > Icons </Label> */}
                        <Container md={12}>
                        <Card md={10}>
                            <CardTitle>Icons</CardTitle>
                            <CardBody>
                                <Row>
                                    {this.props.iconList && this.props.iconList.map((icon, index) =>(
                                            <Col key={index} md={1}>
                                                <div onClick={()=>this.onIconListClick(index, icon)}>
                                                    <IconElement width={32} height={32} path={icon.iconsvg} type={icon.type} data={icon.data}/>
                                                </div>
                                            </Col>
                                        )
                                    )}
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col md={8}>

                                    <div className="custom-file" >
                                        <input
                                            type="file"
                                            name="file"
                                            className="custom-file-input"
                                            id="iconFile"
                                            // aria-describedby="inputGroupFileAddon01"
                                            onChange={this.onFileChange}
                                            style={{position:'absolute'}}
                                            />
                                            <label className="custom-file-label" htmlFor="iconFile" >{this.state.uploadedFile ? this.state.uploadedFile.name : 'New Icon'}</label>
                                    </div>
                                    </Col>
                                    <Col md={4}>
                                        <Button color="secondary" size="sm" onClick={this.onFileUpload}>Upload</Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                        </Container>
                    </Row>

                </ModalBody>

                <ModalFooter>
                    <Button  color="primary" onClick={this.onAddClick} disabled={!this.state.selectedIcon} >Apply</Button>
                    <Button color="secondary" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }
}

// export default AssetIconEditModal;
function mapStateToProps(state) {
    // const deviceTypes = state.mapboard.types;
    const props = {...state.asset };
    return props;
 };

 function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadIcons, deleteIcon}, dispatch);
 };

 export default connect(mapStateToProps, mapDispatchToProps)(AssetIconEditModal);
