/*eslint-disable*/
import React, { Component, useState, useRef, useEffect } from "react";
import ReactDOM from 'react-dom';
import  Card from "react-credit-cards";
import {Row, Col, Card as StrapCard, Collapse, CardBody,  CardGroup, Label, Input, Button, Table, CardFooter, Modal} from 'reactstrap';
import log from 'loglevel';
import { toast } from "react-toastify";
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

const cardsstyle ={
    'margin': '0 auto',
    'max-width': '1280px',
    'text-align': 'center'
  }

const cardList = {
        'display': 'flex',
        'margin-top': '30px',
        'flex-wrap': 'wrap',
        'justify-content': 'space-evenly',
     
} 

const CompanyPaymentMethod = ({ accountList, removePayment, companyCode, getAccounts, customerObj }) => {
    const refPayForm = useRef(null);
    const [modal, setModal] = useState(false);
    const [custid, setCustid] = useState(customerObj? customerObj.id : null);

    useEffect(()=>{
        if (companyCode) {
            log.debug('calling effect', companyCode);
            getAccounts(companyCode);
        }
    }, [companyCode]);

    useEffect(()=>{
        if (customerObj) {
            setCustid(customerObj.id);
        }
    }, [customerObj]);

    const toggle = () => {
        setModal(!modal);
    }
    const deletePayment = (card) => {
        log.debug('remove card', card);
        if (removePayment) {
            removePayment(card);
        }
    };

    // let cust_id = null;
    // log.debug('cust_id', customerObj);
    // if (customerObj) {
    //     setCustid(customerObj.id);
    // }

    const paySubmit = () => {
        // toggle();
        // var externalWindow = window.open('', '', 'width=800,height=600,left=200,top=200');
        // // externalWindow.document.body.appendChild(payFormEle);
        // var formEle = document.createElement('form');
        // formEle.action= "https://esqa.moneris.com/HPPDP/index.php";
        // var inputEle1 = document.createElement('input');
        // inputEle1.type="hidden";
        // inputEle1.id="fname";
        // inputEle1.name="res_id";
        // inputEle1.value="T9MQBtore5";
        // var inputEle2 = document.createElement('input');
        // inputEle2.type="hidden";
        // inputEle2.id="lname";
        // inputEle2.name="res_key";
        // inputEle2.value="resTJL84UZLA";
        // var inputEle3 = document.createElement('input');
        // inputEle3.type="hidden";
        // inputEle3.id="cname";
        // inputEle3.name="cust_id";
        // inputEle3.value="32";
        // formEle.appendChild(inputEle1);
        // formEle.appendChild(inputEle2);
        // formEle.appendChild(inputEle3);
        // externalWindow.document.body.appendChild(formEle);
        // formEle.submit();
        //-----------------------------------------------------
        if (custid) {
            var payForm = ReactDOM.findDOMNode(refPayForm.current);
            payForm.submit();
        }
        else {
            toast.error("Error occurred during getting customer, please try again!");
        }

        
    }

    const payFormEle =  (<form action="https://esqa.moneris.com/HPPDP/index.php" ref={refPayForm}>
                            <input hidden type="text" id="fname" name="res_id" value="T9MQBtore5"/>
                            <input hidden type="text" id="lname" name="res_key" value="resTJL84UZLA"/>
                            <input hidden type="text" id="cname" name="cust_id" value={custid}/>
                            {/* <input hidden type="text" id="mname" name="comp_id" value="DFI"/> */}
                            {/* <Button
                                outline
                                type="submit"
                                style={{
                                    borderColor: "black",
                                    border: "dashed",
                                    borderRadius: "20px",
                                    textAlign: "right"
                                }}
                                
                            >
                                {'Add A Payment Method'}
                            </Button> */}
                        </form>
                        );
    return (
        <div className="App-cards" style={ cardsstyle}>
            <div className="App-cards-list" style={cardList}>
                {Array.isArray(accountList) && accountList.map(card => (
                    <Col md={5} style={{margin:'15px'}}>
                        <StrapCard style={{ border: '1px solid #D3D3D3', padding: '0px' }}>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <Card 
                                            number={card.cardMask} 
                                        />
                                    </Col>
                                    <Col md={8}>
                                        <Row><Col md={3}></Col><Col md={9}><span style={{fontSize:'15px'}}>{card.cardMask}</span></Col></Row>
                                        <Row><Col md={3}></Col><Col md={9}><span>Expires </span>{card.expDate}</Col></Row>                                  
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter style={{textAlign:'right', padding:'0px 0px 5px 0p',backgroundColor:'white'}}>
                                {/* <Button 
                                    color="link" 
                                    size="sm"
                                    onClick={() => deletePayment(card)}  
                                    style={{color: 'red', margin: '0px'}}
                                >
                                    Remove
                                </Button> */}
                                <ConfirmDeleteDialog
                                    // modalVisible={this.state.confirmDialogOpen}
                                    onConfirm={() => { deletePayment(card)}}
                                    noIcon
                                    link
                                    label="Remove"
                                    // toggleOff={this.toggleDialog}
                                    msg="Are you sure you want to delete the payment method?"
                                />
                            </CardFooter>
                        </StrapCard>
                    </Col>
                ))}
            </div>
            <div style={{ float: "right", margin:'30px' }}>
                    <Button
                        outline
                        // type="submit"
                        onClick = {paySubmit}
                        style={{
                            borderColor: "black",
                            border: "dashed",
                            borderRadius: "20px",
                            textAlign: "right"
                        }}
                        
                    >
                        {'Add A Payment Method'}
                    </Button>
                    {/* <Modal isOpen={modal} toggle={toggle}>
                        test
                        </Modal> */}
                {/* <form action="https://esqa.moneris.com/HPPDP/index.php" ref={refPayForm}>
                    <input hidden type="text" id="fname" name="res_id" value="T9MQBtore5"/>
                    <input hidden type="text" id="lname" name="res_key" value="resTJL84UZLA"/>
                    <Button
                        outline
                        type="submit"
                        style={{
                            borderColor: "black",
                            border: "dashed",
                            borderRadius: "20px",
                            textAlign: "right"
                        }}
                        
                    >
                        {'Add A Payment Method'}
                    </Button>
                </form> */}
                {payFormEle}

            </div>
        </div>
    );
}

export default CompanyPaymentMethod;