/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './ClientMiscellaneousInvoiceItemEditModal.module.css';

class ClientMiscellaneousInvoiceItemEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            generalLedgerCodes: [],
            generalLedgerCodeUsageReasons: [],
            glCode: '',
            miscellaneousInvoiceItemSelected: null,
            amount: 0
        }
        this.onInputChange = this.onInputChange.bind(this)
        this.getGlCodeStr = this.getGlCodeStr.bind(this)
        this.onClose = this.onClose.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            showModal: nextProps.showModal
        })
        if (nextProps.miscellaneousInvoiceItemSelected != null) {

            const { reasonId } = nextProps.miscellaneousInvoiceItemSelected
            const { generalLedgerCodeUsageReasons, 
                generalLedgerCodes, isEditMode, 
                BUDivisionList, miscellaneousInvoiceItemSelected } = nextProps

            this.setState({
                isEditMode: isEditMode,
                generalLedgerCodes: generalLedgerCodes,
                generalLedgerCodeUsageReasons: generalLedgerCodeUsageReasons,
                BUDivisionList: BUDivisionList,
                miscellaneousInvoiceItemSelected: miscellaneousInvoiceItemSelected,
                glCode: this.getGlCodeStr(reasonId, generalLedgerCodeUsageReasons, generalLedgerCodes),
                divisionId: miscellaneousInvoiceItemSelected.divisionId,
                reasonId: reasonId,
                amount: Number(miscellaneousInvoiceItemSelected.amount)
            })
        }
    }

    getGlCodeStr = (reasonId, generalLedgerCodeUsageReasons, generalLedgerCodes) => {

        let glCode = ''

        const reasonCodeResult = generalLedgerCodeUsageReasons && 
                                        generalLedgerCodeUsageReasons
                                        .filter((reasonCodeItem) => {
                                            return reasonCodeItem.id === parseInt(reasonId)})

        if (reasonCodeResult && reasonCodeResult.length > 0) {
            const glCodeResult = generalLedgerCodes
                                    .filter((glCodeItem) => { 
                                        return glCodeItem.id === reasonCodeResult[0].codeId })

            glCode = `${glCodeResult[0].generalLedgerCode} - ${glCodeResult[0].description}`
        }

        return glCode
    }

    onInputChange = (event) => {

        if (event.target.name === 'reasonId') {
            const { generalLedgerCodeUsageReasons, 
                generalLedgerCodes } = this.state
            
            this.setState({
                glCode: this.getGlCodeStr(event.target.value, generalLedgerCodeUsageReasons, generalLedgerCodes)
            })
        }

        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onClose = () => {
        this.props.onClose()
    }

    validateForm = () => {
        let { divisionId, reasonId, amount } = this.state

        if (reasonId > 0 
            && !isNaN(amount) 
            && (parseFloat(+amount).toFixed(2) > 0 || parseFloat(+amount).toFixed(2) < 0)
            && divisionId > 0) {
            return true
        }
        return false
    }

    onSubmit = () => {
        const { onSave, onClose } = this.props

        let { miscellaneousInvoiceItemSelected, divisionId, 
            reasonId, amount, isEditMode, 
            generalLedgerCodeUsageReasons } = this.state

        let generalLedgerCodeStr = generalLedgerCodeUsageReasons.filter(gl => gl.id === parseInt(reasonId));

        miscellaneousInvoiceItemSelected.divisionId = parseInt(divisionId)
        miscellaneousInvoiceItemSelected.reasonId = parseInt(reasonId)
        miscellaneousInvoiceItemSelected.generalLedgerCodeStr = generalLedgerCodeStr[0].generalLedgerCode
        miscellaneousInvoiceItemSelected.amount = parseFloat(amount).toFixed(2)
        
        onSave(miscellaneousInvoiceItemSelected, isEditMode);
        onClose();
    }

    render() {

        let { showModal, isEditMode, reasonId, 
            BUDivisionList, divisionId, glCode,
            generalLedgerCodeUsageReasons, amount } = this.state

        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={showModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader 
                    toggle={this.onClose}
                >
                    {
                        isEditMode ? 'Edit Adjustment Item' : 'Add an Adjustment Item'
                    }
                </ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>GL Code</span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='glCode'
                                    id='glCode'
                                    placeholder='GL Code'
                                    className="form-control"
                                    value={glCode}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2}>
                                <span>Department <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    type="select"
                                    name='divisionId'
                                    id='divisionId'
                                    placeholder='Department'
                                    onChange={this.onInputChange}
                                    className={`form-control ${parseInt(divisionId) < 0 ? styles['is-invalid'] : ''}`}
                                    value={divisionId}
                                    invalid={parseInt(divisionId) < 0}
                                >
                                    <option key={-1} value={-1}>Select a Department</option>
                                    {
                                        BUDivisionList && BUDivisionList.length > 0 &&
                                        BUDivisionList.map((BUDivision, idx) => 
                                            (<option key={BUDivision.divisionId} value={BUDivision.divisionId}>
                                                {BUDivision.name}
                                            </option>))
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2}>
                                <span>Reason Code <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    type="select"
                                    name='reasonId'
                                    id='reasonId'
                                    placeholder='General Ledger Code'
                                    onChange={this.onInputChange}
                                    className={`form-control ${parseInt(reasonId) < 0 ? styles['is-invalid'] : ''}`}
                                    value={reasonId}
                                    invalid={parseInt(reasonId) < 0}
                                >
                                    <option key={-1} value={-1}>Select a Reason Code</option>
                                    {
                                        generalLedgerCodeUsageReasons && generalLedgerCodeUsageReasons.length > 0 &&
                                        generalLedgerCodeUsageReasons.map((glCodeReason, idx) => 
                                            (<option key={glCodeReason.id} value={glCodeReason.id}>
                                                {glCodeReason.reasonCode} - {glCodeReason.reason}
                                            </option>))
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2}>
                                <span>Amount <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name="amount"
                                    id="amount"
                                    className="form-control"
                                    value={amount}
                                    onChange={this.onInputChange}
                                    placeholder="Please enter an adjustment amount"
                                    invalid={isNaN(amount) || (+amount) === 0 || parseFloat(amount).toFixed(2) == 0}
                                />
                            </Col>
                        </Row>
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onClose} >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ClientMiscellaneousInvoiceItemEditModal;