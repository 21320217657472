/* eslint-disable */
import React, { Component, Fragment} from "react";
import { Line } from "react-chartjs-2";
import Spinner from "react-bootstrap/Spinner";

class PowerChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasets: [],
    };
  }

  renderKey = 0;

  renderMixChart = () => {
    let datasets = [];
    let labelRight = "";
    let labelLeft = "";

    if (this.props.chartData && this.props.attributea) {
      let attrDataSet = setChartData(
        this.props.chartData,
        this.props.attributea,
        "left-y-axis"
      );
      datasets = [...datasets, ...attrDataSet.dataSet];
      labelLeft = this.props.attributea;
    }

    if (this.props.chartData && this.props.attributeb) {
      let attrDataSet = setChartData(
        this.props.chartData,
        this.props.attributeb,
        "right-y-axis"
      );
      datasets = [...datasets, ...attrDataSet.dataSet];
      labelRight = this.props.attributeb;
    }
    datasets = datasets.filter((val) => {
      return (
        val !== undefined &&
        val !== null &&
        typeof val === "object" &&
        val.hasOwnProperty("data")
      );
    });
    this.state.datasets = datasets;
    var data = {
      datasets: datasets ? datasets : [],
    };

    const options = {
      aspectRatio: 3,
      responsive: true,
      maintainAspecRatio: true,
      scales: {
        bounds: "data",
        xAxes: [
          {
            type: "time",
            afterBuildTicks: function(axis, ticks) {
              const toElements = 30;
              if (ticks) {
                if (ticks.length <= toElements) {
                  return ticks;
                }

                const density = Math.trunc(ticks.length / toElements);
                if (isNaN(density)) {
                  console.warn("Nan in ticks");
                  return ticks;
                }
                return ticks.filter((t, index) => {
                  if (index === 0 || index === ticks.length - 1) {
                    return t;
                  } else if (index % density === 0) {
                    return t;
                  }
                });
              }
            },

            distribution: "series",
            offset: false,
            bounds: "ticks",
            source: "auto",
            time: {
              unit: "hour",

              displayFormats: {
                hour: "MMM-DD hA",
              },
            },
            ticks: {
              autoSkip: true,
              autoSkipPadding: 5,
              bounds: "data",
              source: "auto",
              includeBounds: true,
            },
          },
        ],

        yAxes: [
          {
            id: "left-y-axis",
            position: "left",
            distribution: "series",
            // display: this.props.attributea == "" ? false : true,
            display: true,
            ticks: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: labelLeft,
            },
          },
          {
            id: "right-y-axis",
            position: "right",
            distribution: "series",
            display: this.props.attributeb == "" ? false : true,
            ticks: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: labelRight,
            },
            grid: {
              drawOnChartArea: false,
            },
          },
        ],
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 10,
          fontSize: 12,
          fontColor: "rgb(255,99,132)",
        },
      },
    };

    return (
      <Line
        key={this.renderKey}
        width={null}
        height={null}
        data={data}
        options={options}
      />
    );
  };

  render() {
    return <Fragment>{this.renderMixChart()}</Fragment>;
  }
}

function setChartData(chartData, attribute, y_axis_id) {
  let dataSet = [];
  if (attribute) {

    let linedDataSet1 = null;
    let assetAttribute1 = chartData.find(
      (item) => item.attributename == attribute
    );

    if (assetAttribute1) {
      linedDataSet1 = assetAttribute1.data;
      if (linedDataSet1) {
        const dataObj = {
          label: attribute,
          backgroundColor: "white",
          borderColor: assetAttribute1.backgroundcolor,
          pointRaduis: 1,
          pointHoverBackgroundColor: "rgba(255,99,132,0.4)",
          pointHoverBorderColor: "rgba(255,99,132,1)",
          yAxisID: y_axis_id,
          fill: false,
          data: assetAttribute1.data,
        };

        dataSet.push(dataObj);
      }
    }
  }
  return { dataSet };
}

export default PowerChart;
