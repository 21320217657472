/* eslint-disable */
import axios from 'axios';
import {
    API_ADDR,
    API_ROOT_URL,
    ADD_USER_DATA,
    ADD_USER_DATA_STARTED,
    ADD_USER_DATA_SUCCEED,
    ADD_USER_DATA_FAILED,
    SUCCESS_MSG
} from '../../../constants/actionTypes';

function addDetailStarted() {
    return {
        type: ADD_USER_DATA_STARTED
    }
}

function addDetailSucceed(res) {
    if (res.data.status === SUCCESS_MSG) {
        return {
            type: ADD_USER_DATA_SUCCEED,
            payload: res
        }
    }
    else {
        return {
            type: ADD_USER_DATA_FAILED,
            payload: res
        }
    }
}

function addDetailFailed(err) {
    return {
        type: ADD_USER_DATA_FAILED,
        payload: err
    }
}

export function addDetail(criteria) {
    const url = `${API_ADDR}${API_ROOT_URL}${ADD_USER_DATA}`;

    const promise = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            email: criteria.email,
            fullName: criteria.fullName,
            data: criteria.data,
            userName: criteria.userName,
            token: sessionStorage.getItem('authToken')
        }
    });

    return function (dispatch) {
        dispatch(addDetailStarted());
        promise.then(
            res => {
                dispatch(addDetailSucceed(res));
            }
        ).catch(
            err => {
                dispatch(addDetailFailed(err));
            }
        );
    }
}