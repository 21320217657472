/* eslint-disable */
import React, { Component } from "react";
import { Col, Row, Button } from 'reactstrap';
import Select from 'react-select';
import { toast } from "react-toastify";
import { getCurrentUser, hasRole } from '../../../factories/auth';

class MultiSelectTable extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser()
        this.state = {
            id: 0,
            country: {},
            lineOfBusiness: {},
            division: {}
        }
    }
    componentDidMount() {
        this.setState({
            id: this.props.relationship.id,
            country: { "value": this.props.relationship.country, "label": this.props.relationship.country.name },
            lineOfBusiness: { "value": this.props.relationship.lineOfBusiness, "label": this.props.relationship.lineOfBusiness.name },
            division: { "value": this.props.relationship.division, "label": this.props.relationship.division.name }
        })
    }
    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            id: nextProps.relationship.id,
            country: { "value": nextProps.relationship.country, "label": nextProps.relationship.country.name },
            lineOfBusiness: { "value": nextProps.relationship.lineOfBusiness, "label": nextProps.relationship.lineOfBusiness.name },
            division: { "value": nextProps.relationship.division, "label": nextProps.relationship.division.name }
        })
    }
    onCountryChange = (event) => {
        this.setState({ country: event })
    }
    onLobChange = (event) => {
        this.setState({ lineOfBusiness: event })
    }
    onDivisionChange = (event) => {
        this.setState({ division: event })
    }
    onSubmitBusinessUnit = (status) => {
        var obj = {
            id: this.state.id,
            companyId: this.props.user.companyId,
            resellerId: this.props.user.id,
            country: this.state.country.value,
            lineOfBusiness: this.state.lineOfBusiness.value,
            division: this.state.division.value,
            status: status
        }
        this.props.UpdateBusinessUnitRelations(obj).then(res => {
            if (res.status == 200) {
                toast.success(res.data.result);
            } else {
                toast.error("error")
            }
        });
    }

    render() {

        return (
            <div>
                <Row style={{ borderRadius: "5px", padding: "10px", borderStyle: "solid" }}>
                    <Col md={3}>
                        <p> Country :
                            <Select
                                classNamePrefix="select"
                                value={this.state.country}
                                onChange={this.onCountryChange}
                                isSearchable={true}
                                isDisabled={!hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])}
                                name="country"
                                options={
                                    this.props.BUCountryList.map((country) => {
                                        var value = { "value": country, "label": country.name }
                                        return value;
                                    })
                                }
                            />
                        </p>
                    </Col>
                    <Col md={3}>
                        <p>Line of Business :
                            <Select
                                classNamePrefix="select"
                                value={this.state.lineOfBusiness}
                                onChange={this.onLobChange}
                                isSearchable={true}
                                isDisabled={!hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])}
                                name="lineOfBusiness"
                                options={
                                    this.props.BULOBList.map((lob) => {
                                        var value = { "value": lob, "label": lob.name }
                                        return value;
                                    })
                                }
                            />
                        </p>
                    </Col>
                    <Col md={3}>
                        <p>Department :
                            <Select
                                classNamePrefix="select"
                                value={this.state.division}
                                onChange={this.onDivisionChange}
                                isSearchable={true}
                                isDisabled={!hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])}
                                name="division"
                                options={
                                    this.props.BUDivisionList.map((division) => {
                                        var value = { "value": division, "label": division.name }
                                        return value;
                                    })
                                }
                            />
                        </p>
                    </Col>
                    <Col md={3}>
                        {(hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ?
                            <Button className="btn-withhover" outline color='custom' type="button" style={{ marginTop: "10px" }} onClick={() => this.onSubmitBusinessUnit(1)}>
                                Save
                            </Button>
                            : null
                        }
                        {(hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ?
                            <Button outline color='secondary' type="button" style={{ marginTop: "10px" }} onClick={() => this.onSubmitBusinessUnit(0)}>
                                Delete
                            </Button>
                            : null
                        }
                    </Col>
                </Row>
                <br />
            </div>
        )
    }
}
export default MultiSelectTable;