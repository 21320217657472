/* eslint-disable */
import axios from "axios";
import PropTypes from "prop-types";
import {
  WEB_SERVICE_ROOT,
  WEB_SERVICE_POWER_GET_TIME_POINT_DATA,
  WEB_SERVICE_POWER_GROUP_DATA,
} from "../../constants/appConstants";
import {
  POWER_GET_ASSET_DATA_START,
  POWER_GET_ASSET_DATA_SUCCEED,
  POWER_GET_ASSET_DATA_FAILED,
  POWER_SET_STORE_ASSET_DATA,
  POWER_GET_ASSET_HISTORY_START,
  POWER_GET_ASSET_HISTORY_SUCCEED,
  POWER_GET_ASSET_HISTORY_FAILED,
} from "../../constants/actionTypes";
import {
  axiosGet,
  axiosPost,
  processAssetData,
  expandAssetAttributes,
} from "../../factories/utils";
import moment from "moment";

function setStoreAssetModifiedData(data) {
  let modifiedData = processAssetData(data);
  let expandedData = expandAssetAttributes(modifiedData);

  return {
    type: POWER_SET_STORE_ASSET_DATA,
    payloada: data,
    payloadb: modifiedData,
    payloadc: expandedData,
  };
}

function getPowerAssetHistoryDataFunc(
  criteria,
  startType,
  successType,
  failedType,
  successCallback
) {
  let paramObj = {
    url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_POWER_GROUP_DATA}`,
    criteria: criteria,
    logText: "Get power asset data url ",
    startType: startType,
    sccessType: successType,
    failedType: failedType,
    errorMessage: "Failed to get power asset data.",
    successCallback: successCallback,
    passedParameters: criteria,
  };

  return axiosPost(paramObj);
}

export function getPowerAssetData(criteria) {
  let paramObj = {
    url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_POWER_GET_TIME_POINT_DATA}`,
    criteria: criteria,
    logText: "Get power asset data url.",
    startType: POWER_GET_ASSET_DATA_START,
    sccessType: POWER_GET_ASSET_DATA_SUCCEED,
    failedType: POWER_GET_ASSET_DATA_FAILED,
    errorMessage: "Failed to get power asset data.",
    successCallback: setStoreAssetModifiedData,
  };

  return axiosPost(paramObj);
}

export function getPowerAssetHistoryData(historyCriteria) {
  let criteria = {
    ...historyCriteria,
    entitygroup: [],
    latest: "0",
    themeid: "",
  };

  return getPowerAssetHistoryDataFunc(
    criteria,
    POWER_GET_ASSET_HISTORY_START,
    POWER_GET_ASSET_HISTORY_SUCCEED,
    POWER_GET_ASSET_HISTORY_FAILED,
    null
  );
}
