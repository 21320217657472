/* eslint-disable */
import React, { Component } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {EditTextButton, DeleteTextButton, PlusIconButton} from '../../../shared/components/Button/IconButton';

class InventoryList extends Component {
    constructor(props){
        super(props);
        this.state={}
    }

    componentDidMount(){}

// --------------------------------------------------------------------------------------

    UPCLinkFormatter = (cell, row) => {
        const onLinkClick = (e) => {
            // alert('okay-e');
            this.props.handleInventroyClicked(cell);
        } 
        return(
            <div>
                {/* <Button color="link">{cell}</Button> */}
                <a href='javascript:;' onClick={onLinkClick} ><span>{cell}</span></a>
            </div>
        )
    }

    editButtonFormatter = (cell, row) => {
        // let path = `/pages/device/${cell}`;
        let path = ``;
        return(
            <div>
                <EditTextButton label={'Detail'} onClick={(e)=>{this.handleRowClick(row);}}/>{' '}
                {/* <PlusIconButton label={'Activate'} onClick={(e)=>{}}/> */}
            </div>
        )
    }

    timeColFormater = (cell, row) => {
        let time = ''
        if (cell){
          // time = cell.split('.')[0].replace('T', ' ');
          // time = new Date(cell).toLocaleString('en-US', { timeZone: 'America/New_York' });
          time = new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' });
        }
    
        return (
            <span>{time}</span>
        )
      }

    render(){
        //  
        return(
            <Col md={12}>
            <Card>
            <div className='card-pginside' style={{padding:'0px'}}>
               <BootstrapTable 
                    data={ this.props.inventoryList }  
                    tableHeaderClass = 'header_white' 
                    pagination = {false} 
                      
                     
                    bordered={ false } 
                    search 
                    // insertRow 
                    ref='table' 
                >
                  {/* <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='5%' >UPC</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='10%' dataFormat={this.UPCLinkFormatter}>UPC</TableHeaderColumn>
                  <TableHeaderColumn dataField='description_EN' dataAlign="center" width='15%'>Item</TableHeaderColumn>
                  <TableHeaderColumn dataField='company' dataAlign="center" dataSort width='10%' >Company</TableHeaderColumn> 
                  <TableHeaderColumn dataField='mfg' dataAlign="center" dataSort width='10%' >MFG</TableHeaderColumn>
                  {/* <TableHeaderColumn dataField='group' dataAlign="center" dataSort hidden >GROUP</TableHeaderColumn>                      */}
                  {/* <TableHeaderColumn dataField='oursku' dataAlign="center" dataSort width='10%' >SKU</TableHeaderColumn>   */}
                  {/* <TableHeaderColumn dataField='mfgsku' dataAlign="center" dataSort width='10%'>MFG_SKU</TableHeaderColumn>   */}
                  <TableHeaderColumn dataField='quantity' dataAlign="center" dataSort width='10%' >Quantity</TableHeaderColumn>  
                  {/* <TableHeaderColumn dataField='soldquantity' dataAlign="center" dataSort width='10%' >SOLD QUANTITY</TableHeaderColumn>   */}
                  <TableHeaderColumn dataField='network_SUPPORTED' dataAlign="center" dataSort width='10%' >Network</TableHeaderColumn>  
                  <TableHeaderColumn dataField='create_DATETIME' dataAlign="center" dataSort dataFormat = {this.timeColFormater} >Created At</TableHeaderColumn> 
                  <TableHeaderColumn dataField='update_DATETIME' dataAlign="center" dataSort dataFormat = {this.timeColFormater} >Update At</TableHeaderColumn> 
                  {/* <TableHeaderColumn dataField='edit' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn> */}
              </BootstrapTable>
              </div>
              </Card>
        </Col>
        );
    }
}

export default InventoryList