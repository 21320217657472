/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'redux';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card } from 'reactstrap';
import classNames from 'classnames';
import SettingPage from '../../Setting/index';
import TortoiseBoard from './TortoiseBoard';
import OutlookCalendar from './OutlookCalendar';
import OutlookCalendarDisplay from "./OutlookCalendarDisplay";
import TortoiseMap from './TortoiseMap';
import {getCurrentUser} from '../../../factories/auth'


class TortoiseMainBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapProps: {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: { attr:{} },
      },
      activeTab: '1',
    };
    this.userObj = getCurrentUser();
  }

  componentDidMount() {
    // console.log("Tortoise Main Board reset")
    let toCal= localStorage.getItem("isToCalendar");
    if(toCal==1){
      this.tabToggle("2");
      localStorage.removeItem("isToCalendar")
    }
  }

  getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }

  tabToggle = (tab) => {
    if(this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        mapShow: (tab == '3')
      })
    }
  }

  handleMapMarkerClick = (props, marker, e) => {
    this.setState(prevState => {
      return ({
        mapProps: {
          showingInfoWindow: true,
          activeMarker: marker,
          selectedPlace: props,
          lat: props.position.lat,
          lng: props.position.lng,
        },
      });
    });
  }

  handleMapInfoWinShow = (props, marker, e) => {
    this.setState(prevState => {
        return ({
          mapProps: {
            showingInfoWindow: true,
            activeMarker: marker,
            selectedPlace: props,
            lat: props.position.lat,
            lng: props.position.lng,
          },
        });
      }
    );

  }

  handleMapInfoWinClose = (props) => {
    this.setState((prevState) => ({
      mapProps: {
        ...prevState.mapProps,
        showingInfoWindow: false,
      }
    }));
  }

  onMapShow = () => {console.log("TAB3")};

  render() {
    let processedMapdata = [];
    let statusData= [];
    let mapData = [];
    if(this.props.assetData) {
      this.props.assetData.forEach((asset, inex) => {
        let assetObj = {
          ...asset,
          color: 'grey',
          alarm:'0'
        };

        mapData.push(assetObj);
      })
    }
    return (
      <Fragment>
        <Nav tabs>
          <NavItem style = {{ cursor: 'pointer' }}>
            <NavLink
              className = { classNames({ active: this.state.activeTab === '1' })}
              onClick = {() => { this.tabToggle('1') }}
              >
              OPG Monitoring
            </NavLink>
          </NavItem>
          {/*<NavItem style = {{ cursor: 'pointer' }}>
            <NavLink
              className = {classNames({ active: this.state.activeTab === '2'})}
              onClick = {() => { this.tabToggle('2')}}
              >
              Outlook Calendar
            </NavLink>
          </NavItem>*/}

          <NavItem style = {{ cursor: 'pointer' }}>
            <NavLink
                className = {classNames({ active: this.state.activeTab === '2'})}
                onClick = {() => {this.tabToggle('2')}}
            >
              OPG Calendar
            </NavLink>
          </NavItem>

          <NavItem style = {{ cursor: 'pointer' }}>
            <NavLink
              className = { classNames({ active: this.state.activeTab === '3' })}
              onClick = {() => { this.tabToggle('3') }} 
            >
              OPG Map
            </NavLink>
          </NavItem>
          {/*<NavItem style = {{ cursor: 'pointer' }}>
            <NavLink
                className = {classNames({ active: this.state.activeTab === '4'})}
                onClick = {() => {this.tabToggle('4')}}
            >
              OPG DISPLAY
            </NavLink>
          </NavItem>*/}
          
          {/* <NavItem style = {{ cursor: 'pointer' }}>
            <NavLink
              className = {classNames({ active: this.state.activeTab === '5'})}
              onClick = {() => {this.tabToggle('5')}}
            >
              Notification
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab = { this.state.activeTab }>
          <TabPane tabId = "1">
            <Card>
              <TortoiseBoard
                activeTab = {this.state.activeTab}
                toggle = {this.props.toggle}
                assetDataLoading = {this.props.assetDataLoading}
                statusData = {this.props.statusData}
                height = {'400px'}
                isSmall = {this.props.isSmall}
                statusDataProp = {this.props.statusData}
              />
            </Card>
          </TabPane>
          {/*<TabPane tabId = "2">
            <Card>
              <OutlookCalendar
                  activeTab = {this.state.activeTab}
                  toggle = {this.props.toggle}
                  loading = {this.props.assetDataLoading}
                  statusData = {this.state.statusData}
                  height = {'400px'}
                  isSmall = {this.props.isSmall}
                  statusDataProp = {this.props.statusData}
              />
            </Card>
          </TabPane>*/}

          <TabPane tabId = "2">
            <Card>
              <OutlookCalendarDisplay
                  activeTab = {this.state.activeTab}
                  toggle = {this.props.toggle}
                  loading = {this.props.assetDataLoading}
                  statusData = {this.state.statusData}
                  height = {'400px'}
                  isSmall = {this.props.isSmall}
                  statusDataProp = {this.props.statusData}
              />
            </Card>
          </TabPane>

          <TabPane tabId = "3">
            <Card>
              <TortoiseMap
              userObj={this.userObj}
              onMarkerClick={this.handleMapMarkerClick}
              onMarkerHover={this.handleMapInfoWinShow}
              handleMapInfoWinClose={this.handleMapInfoWinClose}
              markerState={this.state.mapProps}
              mapData={this.props.assetGroupData}
              pMapData={processedMapdata}
              loading={this.props.assetDataLoading}
              assetData={mapData}
              statusData={this.props.statusData}
              alarmSetObj={this.props.alarmSetObj}
              toggle={this.props.toggle}
              // mapShow={this.state.mapShow}
              key={this.state.mapShow}
              statusDataProp={this.props.statusData}
              assetHistoryData = {this.props.assetHistoryData}
              handleMapDateChange = {this.props.handleMapDateChange}
              />
            </Card>
          </TabPane>
          {/*<TabPane tabId = "4">
            <Card>
              <OutlookCalendarDisplay
                  activeTab = {this.state.activeTab}
                  toggle = {this.props.toggle}
                  loading = {this.props.assetDataLoading}
                  statusData = {this.state.statusData}
                  height = {'400px'}
                  isSmall = {this.props.isSmall}
                  statusDataProp = {this.props.statusData}
              />
            </Card>
          </TabPane>*/}
          
          {/* <TabPane tabId = "5">
            <Card>
              Notification
            </Card>
          </TabPane> */}
        </TabContent>
      </Fragment>
    )
  }
}

export default TortoiseMainBoard;