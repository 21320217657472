/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { SortIcon, SortAscendingIcon, SortDescendingIcon } from 'mdi-react';
// import ReactResizeDetector from 'react-resize-detector';
import { Resizable, ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

export const sortCaret = (direction, fieldName) => {
    if (direction === 'asc') {
      return (
          <SortAscendingIcon style={{ color: 'white !important' }} />
      );
    }
    if (direction === 'desc') {
      return (
          <SortDescendingIcon style={{ color: 'white !important' }} />
      );
    }
    return (
        <SortIcon style={{ color: 'white !important' }} />
    );
}

export const AdjustableTableHeaderColumn = ({width , ...props}) => {

    const [awidth, setWidth] = React.useState(200);
    const [aheight, setHeight] = React.useState(200);
    
    const onResize = (event, {element, size, handle}) => {
         
        setWidth(size.width);
         
    }
    return(
        <Resizable 
            // style={{backgroundColor :'blue'}}
            className='box'
            axis='x'
            width={awidth}
            height={aheight}
            onResize = {onResize}
            resizeHandles={['e']}
        >
            <TableHeaderColumn width={awidth} {...props}>{props.children}</TableHeaderColumn>
        </Resizable>
    )
} 

export class AdjustableTableHeaderColumn2 extends Component {
    constructor(props){
        super(props);
        this.state = {
            width: 200,
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.width !== this.props.width){
            this.setState({width: nextProps.width});
        }
    }

    onResize = (event, {element, size, handle}) => {
         
        // event.stopPropagation();
        this.setState({width: size.width});
    };

    onResizeStart = (event, data) => {
         
    }

    render() {
        return(
            <Resizable 
                // style={{backgroundColor :'blue'}}
                className='box'
                axis='x'
                width={this.props.width? this.props.width: 200}
                // height={aheight}
                // onResize = {(e, data) => {this.props.onResize(e, data, 'testid')}}
                onResizeStart = {this.props.onResizeStart}
                onResizeStop = {this.props.onResizeStop}
                onResize = {this.props.onResize}
                handle={<span className={"react-resizable-handle react-resizable-handle-e"} style={{touchAction: 'none'}}></span>}
                resizeHandles={['e']}
            >
                {/* <TableHeaderColumn width={this.state.width} {...this.props}>{this.props.children}</TableHeaderColumn> */}
                <TableHeaderColumn {...this.props} > 
                    {this.props.children} 
                </TableHeaderColumn>
            </Resizable>
            // <ResizableBox className="box" width={200} height={200}>
            //      <TableHeaderColumn {...this.props} > {this.props.children} </TableHeaderColumn>
            // </ResizableBox>
        )
    }
}

// export const AdjustableDiv = () => {

//     const [width, setWidth] = React.useState('400px')
//     const [height, setHeight] = React.useState('400px')

//     const onResize = (width, height) => {
//         setWidth(width);
//         setHeight(height);
//     };
     
//     return (
//       <div style = {{backgroundColor: 'red'}}>
//         Width:{width}, Height:{height}
//         {/* <ReactResizeDetector handleWidth handleHeight onResize={onResize} /> */}
//       </div>
//     );
      
// }