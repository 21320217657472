import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import AttributeBoard from './components/AttributeSearchCard';

const AttributePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Appearance</h3>
      </Col>
    </Row>
    <Row>
      <AttributeBoard />
    </Row>
  </Container>
);

export default AttributePage;
