/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody} from 'reactstrap';
import ClientDiscountList from './ClientDiscountList';
import ClientDiscountModal from './ClientDiscountModal';


class ClientDiscountBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            selectedDiscount: null
        };
    }
    componentDidMount() {

    }
    addNewDiscountButton = (onClick) => {
        return (
            <InsertButton
                btnContextual='btn-success'
                btnGlyphicon='glyphicon-edit'
                onClick={() => this.setState({
                    show: true,
                    selectedDiscount: null
                })}
            >
                Add Discount
            </InsertButton>
        );
    }
    editDiscountButton = (row) => {
        this.setState({
            show: true,
            selectedDiscount: row
        })
    }
    onClose = () => {
        this.setState({
            show: false,
            selectedDiscount: null
        })
    }

    render() {
        return (
            <Card>
                <CardBody className="mainCardBody">

                    <ClientDiscountModal
                        show={this.state.show}
                        onClose={this.onClose}
                        selectedRow={this.props.selectedRow}
                        selectedDiscount={this.state.selectedDiscount}
                        getDiscountList={this.props.getDiscountList}
                        setDiscount={this.props.setDiscount}
                    />
                    <ClientDiscountList
                        addNewDiscountButton={this.addNewDiscountButton}
                        editDiscountButton={this.editDiscountButton}
                        discountList={this.props.discountList}
                        userObj={this.props.userObj}
                    />
                </CardBody>
            </Card>
        )
    }
}

export default ClientDiscountBoard;