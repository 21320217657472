/* eslint-disable */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { toast } from "react-toastify";

// const renderField = ({ input, label, type, meta: { touched, error } }) => (
//     <div>
//       <label>{label}</label>
//       <div>
//         <input {...input} placeholder={label} type={type} />
//         {touched && error && <span>{error}</span>}
//       </div>
//     </div>
//   )

const renderField = ({ input, label, type, meta: { touched, error, warning } }) => (
  <div>
    <div className="control">
      {/* <label className="field">{label}</label> */}
      <input className="form-control" {...input} placeholder={label} type={type} style={{ border: touched && error ? '1px solid red' : '1px solid #ced4da' }} />
      {touched && ((error && <span style={{ color: 'red', fontSize: "12px" }}>{error}</span>) || (warning && <span style={{ color: 'orange' }}>{warning}</span>))}
    </div>
  </div>
)

// let ContactForm = props => {
//     const { handleSubmit, reset } = props
//     const onSubmitClick = (values) => {
//       if(values){
//         handleSubmit(values)
//         .then(res => {

//             toast.success("Your message has been successfully sent out!");
//             reset();
//         }).catch(err => {

//             toast.error("Error occurred when sending your email, please send again.");
//         });
//       }
//         // reset();
//     } 
//     return (
//       <Form onSubmit={onSubmitClick}>        
//         <FormGroup>
//           {/* <Label  htmlFor="email">Email</Label> */}
//           <Field name="email" component={renderField} type="email" placeholder="Email" className="form-control" label='Email'/>
//         </FormGroup>
//         <FormGroup>
//           {/* <Label  htmlFor="phone">Phone</Label> */}
//           <Field name="phone" component="input" type="text" placeholder="Phone Number (Optional)" className="form-control"/>
//         </FormGroup>
//         <FormGroup>
//           {/* <Label  htmlFor="phone">Phone</Label> */}
//           <Field name="subject" component="input" type="text" placeholder="Subject" className="form-control"/>
//         </FormGroup>
//         <FormGroup>
//           {/* <Label  htmlFor="message">Message</Label> */}
//           <Field name="message" component="textarea" type="text" placeholder="Message" rows="10" className="form-control"/>
//         </FormGroup>
//         <Col md={{size:8, offset: 2}} style={{textAlign:'center'}}>
//         <Button type="submit" color="custom" block>Send Message</Button>
//         </Col>
//       </Form>
//     )
// }

let ContactForm = props => {
  const { handleSubmit, reset } = props
  return (
    <Form onSubmit={handleSubmit} className="contact-us-form">
      <FormGroup>
        {/* <Label  htmlFor="email">Email</Label> */}
        <Field name="email" component={renderField} type="email" placeholder="Email" className="form-control" label='Email' />
      </FormGroup>
      <FormGroup>
        {/* <Label  htmlFor="phone">Phone</Label> */}
        <Field name="phone" component="input" type="text" placeholder="Phone Number (Optional)" className="form-control" />
      </FormGroup>
      <FormGroup>
        {/* <Label  htmlFor="phone">Phone</Label> */}
        <Field name="subject" component="input" type="text" placeholder="Subject" className="form-control" />
      </FormGroup>
      <FormGroup>
        {/* <Label  htmlFor="message">Message</Label> */}
        <Field name="message" component="textarea" type="text" placeholder="Message" rows="10" className="form-control" />
      </FormGroup>
      <Col md={{ size: 8, offset: 2 }} style={{ textAlign: 'center' }}>
        <Button type="submit" color="custom" outline>Send Message</Button>
      </Col>
    </Form>
  )
}

const validate = val => {
  const errors = {};
  if (!val.email) {

    // toast.error('email is required');
    errors.email = 'Please enter your email address';
  }
  else if (!/^.+@.+$/i.test(val.email)) {

    errors.email = 'Invalid email address';
  }
  return errors;
}

ContactForm = reduxForm({
  // a unique name for the form
  form: 'contact',
  validate
})(ContactForm)

export default ContactForm