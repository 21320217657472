/* eslint-disable */
import React, { Component, Fragment } from "react";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router-dom';
// import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Col, Row, Card, CardBody, CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup } from 'reactstrap';
import Select from 'react-select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import StepTemplate from './StepTemplate';

import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import AtIcon from 'mdi-react/AtIcon';
import { getActionAlert, upsertActionAlert } from '../../../redux/actions/jobAction';
import ContactModal from "../../Contact/components/CreateNewContactModal";
import EditContactModal from '../../Contact/components/EditContactModal';
import { getCurrentUser, hasRole } from '../../../factories/auth';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

class BasicNotificationForm extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            value: 0,
            weekOption: [
                { value: 1, label: 'Mon' },
                { value: 2, label: 'Tue' },
                { value: 3, label: 'Wed' },
                { value: 4, label: 'Thu' },
                { value: 5, label: 'Fri' },
                { value: 6, label: 'Sat' },
                { value: 7, label: 'Sun' },
            ],
            selectedPeriod: null,
            customStyles: {}
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }



    componentDidMount() {


        // this.props.getActionAlert({actionid: this.props.actionId});
    }

    // componentWillReceiveProps(nextProps){}

    // static getDerivedStateFromProps(nextProps, prevState){
    //      
    //     // if (nextProps.activeStep === 3){
    //     //     nextProps.history.push(`/pages/action`);
    //     // }
    //      
    //     if (nextProps.actionAlert !== prevState.actionAlert){
    //         // nextProps.getActionAlert({actionid: nextProps.actionId});
    //          
    //         let email = '';
    //         let subject = '';
    //         let emailMessage = '';
    //         let phoneNo = '';
    //         let smsMessage = '';
    //         nextProps.actionAlert.map((item, index) => {
    //             if (item.alerttype === '01'){
    //                 email = item.destination ? item.destination : '';
    //                 subject = item.title ? item.title : '' ;
    //                 emailMessage = item.content ? item.content: '' ;
    //             }else if  (item.alerttype === '02') {
    //                 phoneNo = item.destination ? item.destination : '';
    //                 smsMessage = item.content ? item.content: '' ;
    //             }
    //         });
    //         return {
    //                     actionAlert: nextProps.actionAlert, 
    //                     email: email,
    //                     subject: subject,
    //                     emailMessage: emailMessage,
    //                     phoneNum: phoneNo,
    //                     smsMessage: smsMessage,
    //                 }
    //     }else{
    //         return null;
    //     }
    // }

    // ------------------------------------------------------------------------------------------------

    onInputChange = (event, alertType) => {

        // this.setState({
        //    [event.target.name]: event.target.value
        // });
        this.props.handleInputChange(event, alertType);
    };

    onTimeInputChange = (e) => {
        this.props.handleTimeInputChange(e);
    }

    handlePeriodsSelect = (value) => {
        // this.setState({
        //     selectedPeriod: value,
        // });
        this.props.handlePeriodsSelect(value);
    }

    renderTimePeriod = () => {
        let fromTime = '';
        let toTime = '';
        let selectedPeriod = [];
        if (this.props.actionAlert && this.props.actionAlert.length > 0) {
            let alertPart = this.props.actionAlert[0];
            fromTime = alertPart.starttime ? alertPart.starttime : '';
            toTime = alertPart.endtime ? alertPart.endtime : '';
            let weekdayArr = alertPart.weekday ? JSON.parse(alertPart.weekday) : [];
            weekdayArr.forEach((item, index) => {
                let selectedItem = this.state.weekOption.find((day, dindex) => (day.label == item));
                if (selectedItem) {
                    selectedPeriod.push(selectedItem);
                }
            });
        }
        return (
            <Form>
                <Row>
                    <Col md={12}>
                        <FormGroup row >

                            <Col md={3}>
                                <Label for="period">Time periods</Label>
                                <Select
                                    // defaultValue={[colourOptions[2], colourOptions[3]]}
                                    isMulti
                                    id="period"
                                    name="weekday"
                                    options={this.state.weekOption}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={selectedPeriod}
                                    onChange={this.handlePeriodsSelect}
                                    styles={this.state.customStyles}
                                />
                            </Col>
                            <Col md={3}>
                                <Label for="fromTime">From</Label>
                                <Input
                                    type="time"
                                    name="starttime"
                                    id="fromTime"
                                    placeholder="time placeholder"
                                    onChange={(e) => { this.onTimeInputChange(e) }}
                                    value={fromTime}
                                />
                            </Col>
                            <Col md={3}>
                                <Label for="fromTime">To</Label>
                                <Input
                                    type="time"
                                    name="endtime"
                                    id="toTime"
                                    placeholder="time placeholder"
                                    onChange={(e) => { this.onTimeInputChange(e) }}
                                    value={toTime}
                                />
                            </Col>

                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    validatePhoneNumber = (phoneNum) => {
        var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return (phoneNum && regex.test(phoneNum));
    }

    validateEmail = (email) => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (email && regex.test(email));
    }


    renderEmailNotification = () => {
        // let email = '';
        // let subject = '';
        // let emailMessage = '';
        // let alertType = '01';
        // if (this.props.actionAlert){
        //     let emailPart = this.props.actionAlert.find((item,index)=>(item.alerttype === alertType)); 
        //     // let emailPart = this.props.actionAlert[0]; 
        //     if (emailPart){
        //         email = emailPart.destination ? emailPart.destination : '';
        //         subject = emailPart.title ? emailPart.title : '' ;
        //         emailMessage = emailPart.content ? emailPart.content: '' ;
        //     }                 
        // } 

        let title = '';
        let content = '';
        let disabled = true;

        if (this.props.actionAlert && this.props.actionAlert.length > 0) {
            title = this.props.actionAlert[0].title;
            content = this.props.actionAlert[0].content;
            disabled = false;
        } else {
            disabled = true;
        }

        return (
            <Form>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                {/* <Label for="startTime"  style={{display: 'inline', width: "100%"}} >Email to</Label>
                                <Input 
                                    type="email" 
                                    id="email" 
                                    name="destination" 
                                    width="100%" 
                                    onChange={(e) => {this.onInputChange(e, alertType)}} 
                                    value={email}  
                                    valid = {this.validateEmail(email)}
                                >
                                </Input>      */}
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="startTime" style={{ display: 'inline', width: "100%" }} >Subject</Label>
                                <Input
                                    type="text"
                                    id="subject"
                                    name="title"
                                    width="100%"
                                    // onChange={(e) => {this.onInputChange(e, alertType)}} 
                                    onChange={(e) => { this.onInputChange(e, null) }}
                                    // value={subject}
                                    value={title}
                                    disabled={disabled}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label for="emailMessage" style={{ display: 'inline', width: "100%" }} >Message</Label>
                                <Input
                                    type="textarea"
                                    id="emailMessage"
                                    name="content"
                                    width="100%"
                                    // onChange={(e) => {this.onInputChange(e, alertType)}} 
                                    onChange={(e) => { this.onInputChange(e, null) }}
                                    // value={emailMessage}
                                    value={content}
                                    disabled={disabled}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    // renderMsmNotification = () => {
    //     let phoneNo = '';
    //     let smsMessage = '';
    //     let alertType = '02';
    //     if (this.props.actionAlert){
    //         let msmPart = this.props.actionAlert.find((item,index)=>(item.alerttype === alertType));     
    //         if (msmPart){
    //             phoneNo = msmPart.destination ? msmPart.destination : '';
    //             smsMessage = msmPart.content ? msmPart.content: '' ;
    //         }       

    //     } 
    //     return(
    //         <Form>
    //             <Row>
    //                 <Col md={3}>
    //                     <FormGroup row >
    //                         {/* <Label for="startTime" md={2} >From</Label> */}
    //                         <Col md={12}>
    //                             <Label for="startTime"  style={{display: 'inline', width: "100%"}} >Phone number</Label>
    //                             <Input 
    //                                 type="text" 
    //                                 id="phoneNum" 
    //                                 name="destination" 
    //                                 width="100%" 
    //                                 onChange={(e) => {this.onInputChange(e, alertType)}} 
    //                                 value={phoneNo} 
    //                                 valid={this.validatePhoneNumber(phoneNo)}
    //                             >
    //                             </Input>     
    //                         </Col>                   
    //                     </FormGroup>
    //                 </Col>
    //             </Row>
    //             <Row>
    //                 <Col md={8}>
    //                     <FormGroup row >
    //                         {/* <Label for="endTime" md={2} >To</Label> */}
    //                         <Col md={12}>
    //                             <Label for="endTime"  style={{display: 'inline', width: "100%"}} >Message</Label>
    //                             <Input 
    //                                 type="textarea" 
    //                                 id="smsMessage" 
    //                                 name="content" 
    //                                 width="100%" 
    //                                 onChange={(e) => {this.onInputChange(e, alertType)}} 
    //                                 value={smsMessage}
    //                             >
    //                             </Input>  
    //                         </Col>                      
    //                     </FormGroup>
    //                 </Col>
    //             </Row>
    //         </Form>
    //     )
    // }

    handleChange = (event, newValue) => {
        // alert(newValue);
        this.setState({
            value: newValue,
        });
    };

    // renderTab = () => {
    //     return(
    //         <div >
    //             {/* <AppBar position="static"> */}
    //                 {/* <Tabs value={this.state.value} onChange={this.handleChange} aria-label="simple tabs example">                       
    //                     <Tab icon={<AtIcon />} aria-label="email" />
    //                     <Tab icon={<MessageTextOutlineIcon />} aria-label="message"/>
    //                 </Tabs> */}
    //             {/* </AppBar> */}
    //             <TabPanel value={this.state.value} index={0}>
    //                 {this.renderEmailNotification()}
    //             </TabPanel>
    //             <TabPanel value={this.state.value} index={1}>
    //                 {this.renderMsmNotification()}
    //             </TabPanel>
    //         </div>
    //     );
    // }

    renderContact = () => {


        let selectedContactOption = []
        if (this.props.actionAlert && this.props.contactList) {

            selectedContactOption = this.props.contactList.filter((item, index) => (this.props.actionAlert.some(action => (action.contactid == item.id))));
        }
        return (
            <Form>
                <span className="title">Receiver's List</span>
                <Row>
                    <Col md={8}>
                        {/* <div className="container"> */}
                        {/* <br /> */}
                        {/* <span className="title">Receiver's List</span> */}
                        <Select
                            isMulti
                            value={selectedContactOption}
                            options={this.props.contactList}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            onChange={this.props.handleContactChange}
                        />      <br />
                    </Col>
                    {/* <Col md={2}> */}
                    {/* <Row>  */}
                    {/* {this.props.userObj && hasRole(this.props.userObj,['ADMIN', 'USER', 'ENDUSER']) && */}
                    <Col md={2}>
                        <ContactModal
                            label={'Add Receiver'}
                            className={'container'}
                            upsertContact={this.props.upsertContact}
                        ></ContactModal>
                    </Col>
                    {/* } */}
                    <Col md={2}>
                        <EditContactModal
                            label={'Edit Receiver'}
                            contactList={this.props.contactList}
                            upsertContact={this.props.upsertContact}
                            deleteContact={this.props.deleteContact}
                        />
                    </Col>
                    {/* </Row> */}
                    {/* </div> */}
                    {/* </Col> */}
                </  Row>
            </Form>
        );
    };

    // ----------------------------------------------------------------------

    handleNotificationTemplateChange = (val) => {
        this.props.handleNotificationTemplateChange(val);
    }

    renderNotificationTemplate = () => {
        return (
            <Form>
                <span className="title">Notification Template</span>
                <Row>
                    <Col md={8}>
                        <Select
                            // value={this.props.selectedContactOption}
                            isClearable
                            isSearchable
                            options={this.props.notificationTemplateList}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            onChange={this.handleNotificationTemplateChange}
                        />
                    </Col>
                </Row>
            </Form>
        )
    }

    // ----------------------------------------------------------------------
    // onInputChange = (event) => {
    //     this.setState({
    //        [event.target.name]: event.target.value
    //     });
    // };

    // handlePeriodsSelect = (value) => {
    //     this.setState({
    //         selectedPeriod: value,
    //     });
    // }

    // renderTimePeriod = () => {
    //     return(
    //         <Form>
    //             <Row>
    //             <Col md={12}>
    //                 <FormGroup row >

    //                         <Col md={3}>
    //                             <Label for="period">Time periods</Label>
    //                             <Select
    //                                 // defaultValue={[colourOptions[2], colourOptions[3]]}
    //                                 isMulti
    //                                 name="period"
    //                                 options={this.state.weekOption}
    //                                 className="basic-multi-select"
    //                                 classNamePrefix="select"
    //                                 value={this.state.selectedPeriod}
    //                                 onChange={this.handlePeriodsSelect}
    //                             />
    //                         </Col>
    //                         <Col md={3}>
    //                             <Label for="fromTime">From</Label>
    //                             <Input
    //                                 type="time"
    //                                 name="fromTime"
    //                                 id="fromTime"
    //                                 placeholder="time placeholder"
    //                                 onChange={this.onInputChange} 
    //                                 value={this.state.fromTime}
    //                             />
    //                         </Col>
    //                         <Col md={3}>
    //                             <Label for="fromTime">To</Label>
    //                             <Input
    //                                 type="time"
    //                                 name="toTime"
    //                                 id="toTime"
    //                                 placeholder="time placeholder"
    //                                 onChange={this.onInputChange} 
    //                                 value={this.state.toTime}
    //                             />
    //                         </Col>

    //                 </FormGroup>
    //                 </Col>
    //             </Row>
    //         </Form>
    //     );
    // }

    // ----------------------------------------------------------------------
    validataNotification = () => {
        let isEmailEmpty = !this.state.email || this.state.email.length === 0;
        let isPhoneNum = !this.state.phoneNum || this.state.phoneNum.length === 0;
        let isSubjectEmpty = !this.state.subject || this.state.subject.length === 0;
        let isEmailMsgEmpty = !this.state.emailMessage || this.state.subject.emailMessage === 0;
        let isSmsMsgEmpty = !this.state.smsMessage || this.state.subject.smsMessage === 0;
        let checkEmail = (!isEmailEmpty) && (this.validateEmail()) && (!isSubjectEmpty) && (!isEmailMsgEmpty);
        let checkSMS = (!isPhoneNum) && (this.validatePhoneNumber()) && (!isSmsMsgEmpty)

        // return (!isEmailEmpty || !isPhoneNum) && (isEmailEmpty || this.validatePhoneNumber()) && (isPhoneNum || this.validateEmail()) && (isEmailEmpty);
        //  
        //  
        //  
        //  
        //  
        return (!isEmailEmpty || !isPhoneNum) && ((isEmailEmpty || checkEmail) && (isPhoneNum || checkSMS));
        // return true;
    }

    handleCompleteClick = (e) => {
        if (!this.validataNotification()) {
            toast.error('The input is not valided.');
            return;
        }
        let weekday = [];
        let fromTime = '00:00:00';
        let toTime = '00:00:00';
        if (this.state.selectedPeriod) {
            this.state.selectedPeriod.forEach((item, index) => {
                weekday.push(item.label);
            });
        }
        let timeRegex = /^\d{2}[:]\d{2}$/;
        if (this.state.fromTime && timeRegex.test(this.state.fromTime)) {

            fromTime = this.state.fromTime + ':00';
        }
        if (this.state.toTime && timeRegex.test(this.state.toTime)) {

            toTime = this.state.toTime + ':00';
        }
        let alertArray = [
            {
                weekday: JSON.stringify(weekday),
                starttime: fromTime,
                endtime: toTime,
                actionid: this.props.actionId,
                alerttype: "01",
                destination: this.state.email,
                title: this.state.subject,
                content: this.state.emailMessage,
            },
            {
                weekday: JSON.stringify(weekday),
                starttime: fromTime,
                endtime: toTime,
                actionid: this.props.actionId,
                alerttype: "02",
                destination: this.state.phoneNum,
                title: this.state.subject,
                content: this.state.smsMessage,
            },
        ];
        this.props.upsertActionAlert(alertArray);
        // this.props.onNext();
    }

    render() {
        return (
            // <StepTemplate
            //     classes = {this.props.classes}
            //     canGoBack = {this.props.canGoBack}
            //     canGoForward = {this.props.canGoForward}
            //     onBack = {this.props.onBack}
            //     // onNext = {this.props.onNext}
            //     onNext = {this.handleCompleteClick}
            //     onCancel = {this.props.onCancel}
            //     text = {this.props.text}
            // >
            //     {this.renderTimePeriod()}
            //     {this.renderTab()}
            // </StepTemplate>
            <Fragment>
                {this.renderNotificationTemplate()}
                <hr />
                {this.renderContact()}
                {this.renderTimePeriod()}
                {/* {this.renderTab()} */}
                {this.renderEmailNotification()}
            </Fragment>
        );
    }
}

export default BasicNotificationForm;

// function mapStateToProps(state) {
//     const props = {...state.job};
//     return props;
// };

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({getActionAlert, upsertActionAlert}, dispatch);
// };

// const enhance = compose(
//     connect(mapStateToProps, mapDispatchToProps),
//     withRouter
// );

// export default enhance(BasicNotificationForm);