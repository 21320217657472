/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {Col, Row, Container} from 'reactstrap';
import { getCurrentUser } from '../../factories/auth';
import MainBoard from './components/MainBoard';


class DeviceConfigPage extends Component {
  constructor (props) {
    super(props);
    this.userObj = null;
    this.state = {
      userObj: null,
      companyCode: null,
    };
    this.companycode = null;
  }

  componentDidMount() {
    let queryStr = this.props.location.search;
    let companycode = new URLSearchParams(queryStr).get("companycode");

    let userObj = getCurrentUser();
    if(userObj === null || userObj.userName.length === 0) {
      this.props.history.push('/log_out');
    };

    this.companycode = companycode;
    this.setState({
      userObj: userObj,
      companyCode: companycode,
    });
  }

  componentWillUnmount() {

  }

  render() {
    return (
      <Container className='dashboard'>
        <Row>
          <Col md={12}>
            <h3 className='page-title'>Device Configuration</h3>
          </Col>
        </Row>
        <Row>
            <MainBoard
              userObj={this.state.userObj}
              companyCode={this.companycode}
            />
        </Row>
      </Container>
    );
  }
}

export default withRouter(DeviceConfigPage);