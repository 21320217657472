/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import { sortCaret } from '../../../shared/components/Table/tableElements';

class CompanyActivityReport extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {

    }

    csvFormatter = (cell, row) => {
        return cell + '\t';
    }

    resellerFormatter = (cell) => {
        if (cell != null) {
            return `${cell.resellerName}`
        }
        else {
            return `No name found`
        }

    }
    revertSortFunc = (a, b, order) => {   // order is desc or asc
        var firstResellerName = "";
        var secondResellerName = "";
        if (a.reseller != null && a.reseller.resellerName != null && a.reseller.resellerName != "") {
            firstResellerName = a.reseller.resellerName;
        }
        if (b.reseller != null && b.reseller.resellerName != null && b.reseller.resellerName != "") {
            secondResellerName = b.reseller.resellerName;
        }
        if (order === 'asc') {
            return firstResellerName.localeCompare(secondResellerName);
        } else {
            return secondResellerName.localeCompare(firstResellerName);
        }
    }
    dateFormatter = (cell, row) => {
        let currentDate = new Date(cell);
        let time = currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();
        let cDay = currentDate.getDate();
        let cMonth = currentDate.getMonth() + 1;
        let cYear = currentDate.getFullYear();
        return (
            <b> {`${cYear}-${cMonth}-${cDay} ${time}`}</b>
        )
    }
    render() {
        let reportList = this.props.activityReportList.slice(0).reverse();
        const options = {
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        }

        return (
            <Card>
                <CardBody className="mainCardBody">
                    {/* <h3>Activity Report</h3> */}
                    {/* <br /> */}
                    <BootstrapTable
                        data={reportList}
                        tableHeaderClass='header_white'
                        exportCSV
                        csvFileName='Company_Activity_Report.csv'

                        bordered={false}
                        pagination={true}
                        options={options}
                    >
                        <TableHeaderColumn row='0' rowSpan='1' dataField='username' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'user' }} caretRender={sortCaret} filterFormatted csvHeader='requester'>Requester</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='reseller' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Client' }} caretRender={sortCaret} dataFormat={this.resellerFormatter.bind(this)} csvFormat={this.resellerFormatter} sortFunc={this.revertSortFunc} filterFormatted csvHeader='Name'>Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='identifierType' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Type' }} caretRender={sortCaret} width="10%" csvHeader='Type'>Type</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Value' }} caretRender={sortCaret} csvHeader='Value' csvFormat={this.csvFormatter}>Value</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='activity' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Activity' }} caretRender={sortCaret} width="15%" csvHeader='Activity'>Activity</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='status' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Status' }} caretRender={sortCaret} width="15%" csvHeader='Status'>Status</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='updateDate' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Date' }} caretRender={sortCaret} dataFormat={this.dateFormatter.bind(this)} filterFormatted csvHeader='Update'>Update</TableHeaderColumn>

                    </BootstrapTable>
                </CardBody>
            </Card>

        )
    }
}
export default CompanyActivityReport;