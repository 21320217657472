/* eslint-disable */
import { uniq } from "lodash";
import * as moment from "moment";
import uuid from "uuid/v4";
import {
    CLEAR_CLIENT_DATA,
    CLIENT_GET_BUSINESS_UNITS_FAILED,
    CLIENT_GET_BUSINESS_UNITS_SUCCESS,
    CLIENT_GET_BUSINESS_UNIT_RELATION_FAILED,
    CLIENT_GET_BUSINESS_UNIT_RELATION_SUCCESS,
    COMPANY_GET_BRANDING_FAILED,
    COMPANY_GET_BRANDING_SUCCESS,
    COMPANY_GET_PAYMENT_ITEM_FAILED,
    COMPANY_GET_PAYMENT_ITEM_SUCCESS,
    COMPANY_MONERIS_ACCOUNT_FAILED,
    COMPANY_MONERIS_ACCOUNT_START,
    COMPANY_MONERIS_ACCOUNT_SUCCESS,
    COMPANY_PAYMENT_ADJUST_INVOICE_FAILED,
    COMPANY_PAYMENT_ADJUST_INVOICE_SUCCESS,
    COMPANY_PAYMENT_CHECKOUT_FAILED,
    COMPANY_PAYMENT_CHECKOUT_SUCCESS,
    COMPANY_PAYMENT_HISTORY_FAILED,
    COMPANY_PAYMENT_HISTORY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS,
    COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_FAILED,
    COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
    COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_CLEAR,
    COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED,
    COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS,
    COMPANY_PAYMENT_INVOICE_DETAILS_FAILED,
    COMPANY_PAYMENT_INVOICE_DETAILS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_FAILED,
    COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
    COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_FAILED,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_SUCCESS,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_FAILED,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_FAILED,
    COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS,
    COMPANY_PAYMENT_INVOICE_SUCCESS,
    COMPANY_PAYMENT_USAGE_REPORT_FAILED,
    COMPANY_PAYMENT_USAGE_REPORT_SUCCESS,
    COMPANY_SET_CURRENT_COMPANY_CODE,
    COMPANY_TRANSACTION_HISTORY_FAILED,
    COMPANY_TRANSACTION_HISTORY_SUCCESS,
    COMPANY_TRANSACTION_REFUND_FAILED,
    COMPANY_TRANSACTION_REFUND_SUCCESS,
    CONSUMER_ADD_FAILED,
    CONSUMER_ADD_SUCCESS,
    CONSUMER_GET_FAILED,
    CONSUMER_GET_PLAN_USAGE_FAILED,
    CONSUMER_GET_PLAN_USAGE_SUCCESS,
    CONSUMER_GET_SUCCESS,
    CUSTOMER_COMPANY_GET_FAILED,
    CUSTOMER_COMPANY_GET_SUCCESS,
    CUSTOMER_GET_ORDERS_FAILED,
    CUSTOMER_GET_ORDERS_SUCCESS,
    GET_ACTIVATED_SIM_DASHBOARD_FAILED,
    GET_ACTIVATED_SIM_DASHBOARD_START,
    GET_ACTIVATED_SIM_DASHBOARD_SUCCESS,
    GET_CARRIER_LIST_FAILED,
    GET_CARRIER_LIST_SUCCESS,
    GET_IDENTIFIER_USAGE_DATA_FAILED,
    GET_IDENTIFIER_USAGE_DATA_START,
    GET_IDENTIFIER_USAGE_DATA_SUCCESS,
    GET_PLAN_USAGE_DATA_FAILED,
    GET_PLAN_USAGE_DATA_START,
    GET_PLAN_USAGE_DATA_SUCCESS,
    GET_PROMOTE_FAILED,
    GET_PROMOTE_SUCCESS,
    GET_TOTAL_USAGE_FAILE,
    GET_TOTAL_USAGE_START,
    GET_TOTAL_USAGE_SUCCESS,
    MULTI_USER_ADD_FAILED,
    MULTI_USER_ADD_SUCCESS,
    ORDER_ADJUSTMENT_CLEAR,
    ORDER_ADJUSTMENT_GET_FAILED,
    ORDER_ADJUSTMENT_GET_SUCCESS,
    PAYMENT_ORDER_GET_ADDRESS_FAILED,
    PAYMENT_ORDER_GET_ADDRESS_SUCCESS,
    RESELLER_SET_ITEM_FAILED,
    RESELLER_SET_ITEM_SUCCEED,
    RESELLER_SET_SPECIAL_LINE_FAILED,
    RESELLER_SET_SPECIAL_LINE_SUCCEED,
    SET_PROMOTE_FAILED,
    SET_PROMOTE_SUCCESS,
    USER_ADD_ASSETCLASS_LIST_FAILED,
    USER_ADD_ASSETCLASS_LIST_SUCCESS,
    USER_ADD_DEVICETYPE_LIST_FAILED,
    USER_ADD_DEVICETYPE_LIST_SUCCESS,
    USER_ADD_FAILED,
    USER_ADD_SUCCESS,
    USER_CHECK_ASSETCLASS_LIST_FAILED,
    USER_CHECK_ASSETCLASS_LIST_SUCCESS,
    USER_CHECK_DEVICETYPE_LIST_FAILED,
    USER_CHECK_DEVICETYPE_LIST_SUCCESS,
    USER_DELETE_COMPANY_OBJ_FAILED,
    USER_DELETE_COMPANY_OBJ_SUCCESS,
    USER_DISABLE_RESELLER_OBJ_FAILED,
    USER_DISABLE_RESELLER_OBJ_SUCCESS,
    USER_FORGET_PASSWORD_FAILED,
    USER_FORGET_PASSWORD_NOT_ALLOWED,
    USER_FORGET_PASSWORD_NOT_FOUND,
    USER_FORGET_PASSWORD_SUCCESS,
    USER_GET_ACCOUNT_LIST_SUCCESS,
    USER_GET_ALL_COMPANIES_FAILED,
    USER_GET_ALL_COMPANIES_SUCCESS,
    USER_GET_ALL_RESELLER_FAILED,
    USER_GET_ALL_RESELLER_SUCCESS,
    USER_GET_CARRIER_LIST_FAILED,
    USER_GET_CARRIER_LIST_SUCCESS,
    USER_GET_COMPANT_LIST_FAILED,
    USER_GET_COMPANY_ASSET_CLASS_FAILED,
    USER_GET_COMPANY_ASSET_CLASS_SUCCESS,
    USER_GET_COMPANY_DEVICE_TYPES_FAILED,
    USER_GET_COMPANY_DEVICE_TYPES_SUCCESS,
    USER_GET_COMPANY_LIST_SUCCESS,
    USER_GET_COMPANY_OBJ_FAILED,
    USER_GET_COMPANY_OBJ_SUCCESS,
    USER_GET_COUNTRY_LIST_SUCCESS,
    USER_GET_FAILED,
    USER_GET_PAGE_CONTENT_FAILED,
    USER_GET_PAGE_CONTENT_SUCCESS,
    USER_GET_PAYMENT_ACCOUNT_LIST_SUCCESS,
    USER_GET_PLAN_ITEMS_SUCCESS,
    USER_GET_PROVINCE_LIST_SUCCESS,
    USER_GET_RESELLER_LIST_FAILED,
    USER_GET_RESELLER_LIST_SUCCESS,
    USER_GET_ROLES_FAILED,
    USER_GET_ROLES_SUCCESS,
    USER_GET_SIM_SERVICE_PLAN_FAILED,
    USER_GET_SIM_SERVICE_PLAN_SUCCESS,
    USER_GET_SUCCESS,
    USER_LOGIN_FAILED,
    USER_LOGIN_SUCESS,
    USER_REMOVE_FAILED,
    USER_REMOVE_SUCCESS,
    USER_RESET_PASSWORD_FAILED,
    USER_RESET_PASSWORD_SUCCESS,
    USER_UPDATE_COMPANY_OBJ_FAILED,
    USER_UPDATE_COMPANY_OBJ_SUCCESS,
    USER_UPDATE_FAILED,
    USER_UPDATE_PAYMENT_ACCOUNT_FAILED,
    USER_UPDATE_PAYMENT_ACCOUNT_SUCCESS,
    USER_UPDATE_RESELLER_OBJ_FAILED,
    USER_UPDATE_RESELLER_OBJ_SUCCESS,
    USER_UPDATE_SUCCESS,
    WEB_SERVICE_GET_SPECIAL_LINE_FAILED,
    WEB_SERVICE_GET_SPECIAL_LINE_SUCCESS,
    GET_USER_ACTIVITY_TRACKING_SUCCEED,
    GET_USER_ACTIVITY_TRACKING_FAILED
} from "../../constants/actionTypes";

const initState = {
  currentUser: {},
  pageContentList: [],
  userList: [],
  addressList: [],
  userAssetClassList: [],
  consumerList: [],
  consumerPlanUsageList: [],
  userDeviceTypeList: [],
  companyList: [],
  resellerList: [],
  companyAll: [],
  planItemsAll: [],
  ServicePlanList: [],
  specialLineList: [],
  companyObj: null,
  companyDevicetypeList: [],
  companyAssetClassList: [],
  roleList: [],
  countryList: [],
  provinceList: [],
  provinceMap: new Map(),
  isAuthenticated: false,
  error: 0,
  message: "",
  type: "",
  accountList: [],
  transactionHistory: [],
  purchaseDeviceList: [],
  purchaseItemList: [],
  purchaseOrder: {},
  paymentHistory: {},
  paymentInvoice: {},
  monerisStatus: 0,
  monerisStart: false,
  customerObj: null,
  adjustmentList: [],
  carrierList: [],
  carrierPlanList: [],
  discountList: [],
  dashboardActivatedSimList: [],
  dashboardActivatedSimTotalElement: 0,
  dashboardPlanUsageData: [],
  dashboardIdentifierUsageData: [],
  paymentAccountList: [],
  paymentInvoicePendingDetails: {},
  generalLedgerCodes: [],
  generalLedgerCodeUsageReasons: [],
  usageReportList: [],
  forgetPasswordMessage: "",
  resetPasswordMessage: "",
  branding: null,
  BUCountryList: [],
  BULOBList: [],
  BUDivisionList: [],
  BURelationshipList: [],
  dashboardActivatedSimListLoading: false,
  totalUsage: 0,
  userActivityTrackingList :[]
};

export default function(state = initState, action) {
  //
  //
  // alert(action.type);
  switch (action.type) {
    case USER_LOGIN_SUCESS:
      if (!action.payload) {
        return {
          ...state,
          error: 1,
          isAuthenticated: false,
          message: "Failed to get the user login info",
          type: action.type,
        };
      }
      sessionStorage.setItem("currentUser", action.payload.userName);
      sessionStorage.setItem("email", action.payload.userName);
      sessionStorage.setItem("companyCode", action.payload.companyCode);
      sessionStorage.setItem("department", action.payload.department);
      sessionStorage.setItem("businessUnit", action.payload.businessUnit);
      sessionStorage.setItem("network", action.payload.network);
      sessionStorage.setItem("currentUserObj", JSON.stringify(action.payload));
      sessionStorage.setItem("isAuthenticated", true);
      sessionStorage.setItem("jToken", action.token);
      return {
        ...state,
        currentUser: action.payload,
        isAuthenticated: true,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_LOGIN_FAILED:
      sessionStorage.setItem("currentUser", null);
      sessionStorage.setItem("email", null);
      sessionStorage.setItem("companyCode", null);
      sessionStorage.setItem("department", null);
      sessionStorage.setItem("businessUnit", null);
      sessionStorage.setItem("network", null);
      sessionStorage.setItem("currentUserObj", null);
      sessionStorage.setItem("isAuthenticated", false);
      sessionStorage.setItem("jToken", null);
      return {
        ...state,
        error: 1,
        isAuthenticated: false,
        message: action.message,
        type: action.type,
      };
    case USER_UPDATE_SUCCESS:
      if (!action.payload) {
        return {
          ...state,
          error: 1,
          message: "Failed to get the user login info",
          type: action.type,
        };
      }
      // sessionStorage.setItem('currentUser', action.payload.userName);
      return {
        ...state,
        currentUser: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_ADD_SUCCESS:
    case CONSUMER_ADD_SUCCESS:
    case USER_REMOVE_SUCCESS:
      if (!action.payload) {
        return {
          ...state,
          error: 1,
          message: "Failed to get the user login info",
          type: action.type,
        };
      }
      // sessionStorage.setItem('currentUser', action.payload.userName);
      return {
        ...state,
        // currentUser: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case MULTI_USER_ADD_SUCCESS:
      return {
        ...state,
        multiAddUserDetail: action.payload,
      };
    case MULTI_USER_ADD_FAILED:
      return {
        ...state,
        multiAddUserDetail: action.payload,
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        userList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case CONSUMER_GET_SUCCESS:
      return {
        ...state,
        consumerList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_CHECK_ASSETCLASS_LIST_SUCCESS:
      return {
        ...state,
        userAssetClassList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_CHECK_DEVICETYPE_LIST_SUCCESS:
      return {
        ...state,
        userDeviceTypeList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };

    case USER_CHECK_ASSETCLASS_LIST_FAILED:
      return {
        ...state,
        userAssetClassList: null,
        error: 1,
        message: action.message,
        type: action.type,
      };
    case USER_CHECK_DEVICETYPE_LIST_FAILED:
      return {
        ...state,
        userDeviceTypeList: null,
        error: 1,
        message: action.message,
        type: action.type,
      };
    case USER_ADD_ASSETCLASS_LIST_SUCCESS:
    case USER_ADD_DEVICETYPE_LIST_SUCCESS:
      return {
        ...state,
        error: 0,
        message: "",
        type: action.type,
      };

    case USER_GET_COMPANY_LIST_SUCCESS:
      return {
        ...state,
        companyList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_CARRIER_LIST_SUCCESS:
      return {
        ...state,
        carrierList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_RESELLER_LIST_SUCCESS:
      return {
        ...state,
        resellerList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_ROLES_SUCCESS:
      return {
        ...state,
        roleList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_COMPANY_DEVICE_TYPES_SUCCESS:
      return {
        ...state,
        companyDevicetypeList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_COMPANY_ASSET_CLASS_SUCCESS:
      return {
        ...state,
        companyAssetClassList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_COMPANY_DEVICE_TYPES_FAILED:
      return {
        ...state,
        companyDevicetypeList: [],
        error: 1,
        message: action.message,
        type: action.type,
      };
    case USER_GET_COMPANY_ASSET_CLASS_FAILED:
      return {
        ...state,
        companyAssetClassList: [],
        error: 1,
        message: action.message,
        type: action.type,
      };
    case USER_GET_COMPANY_OBJ_SUCCESS:
      return {
        ...state,
        companyObj: action.payload[0],
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_UPDATE_COMPANY_OBJ_SUCCESS:
      return {
        ...state,
        companyObj: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case GET_TOTAL_USAGE_START:
      return {
        ...state,
        totalUsage: 0,
      };
    case GET_TOTAL_USAGE_FAILE:
      return {
        ...state,
        totalUsage: 0,
      };
    case GET_TOTAL_USAGE_SUCCESS:
      return {
        ...state,
        totalUsage: action.payload.summary,
      };
    case USER_DELETE_COMPANY_OBJ_SUCCESS:
      return {
        ...state,
        companyObj: null,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        countryList: action.payload,
        provinceMap: action.provinces,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_PROVINCE_LIST_SUCCESS:
      return {
        ...state,
        provinceList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        accountList: action.payload.payment,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_PAYMENT_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        paymentAccountList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_UPDATE_PAYMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        message: "",
      };
    case USER_UPDATE_PAYMENT_ACCOUNT_FAILED:
      return {
        ...state,
        message: "",
      };
    case USER_GET_ALL_COMPANIES_SUCCESS:
      return {
        ...state,
        companyAll: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_SIM_SERVICE_PLAN_SUCCESS:
      return {
        ...state,
        ServicePlanList: action.payload.plans,
      };

    case USER_GET_SIM_SERVICE_PLAN_FAILED:
      return {
        ...state,
        ServicePlanList: [],
      };
    case WEB_SERVICE_GET_SPECIAL_LINE_SUCCESS:
      return {
        ...state,
        specialLineList: action.payload.resellerConfigs,
      };

    case WEB_SERVICE_GET_SPECIAL_LINE_FAILED:
      return {
        ...state,
        specialLineList: [],
      };

    case USER_GET_PLAN_ITEMS_SUCCESS:
      return {
        ...state,
        planItemsAll: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case USER_GET_ALL_COMPANIES_FAILED:
    case USER_DELETE_COMPANY_OBJ_FAILED:
    case USER_UPDATE_COMPANY_OBJ_FAILED:
    case USER_GET_COMPANY_OBJ_FAILED:
    case USER_GET_ROLES_FAILED:
    case USER_GET_COMPANT_LIST_FAILED:
    case USER_ADD_FAILED:
    case CONSUMER_ADD_FAILED:
    case USER_UPDATE_FAILED:
    case USER_GET_CARRIER_LIST_FAILED:
    case USER_ADD_FAILED:
    case CONSUMER_ADD_FAILED:
    case USER_UPDATE_FAILED:
    case USER_REMOVE_FAILED:
    case USER_GET_FAILED:
    case CONSUMER_GET_FAILED:
    case USER_GET_RESELLER_LIST_FAILED:
    case USER_ADD_ASSETCLASS_LIST_FAILED:
    case USER_ADD_DEVICETYPE_LIST_FAILED:
      // case USER_CHECK_ASSETCLASS_LIST_FAILED:
      return {
        ...state,
        error: 1,
        message: action.message,
        type: action.type,
      };
    case COMPANY_GET_PAYMENT_ITEM_SUCCESS:
      return {
        ...state,
        purchaseDeviceList: action.payload.deviceList,
        purchaseItemList: action.payload.itemList,
        error: 0,
        type: action.type,
      };
    case COMPANY_GET_PAYMENT_ITEM_FAILED:
      return {
        ...state,
        purchaseDeviceList: [],
        purchaseItemList: [],
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_PAYMENT_CHECKOUT_SUCCESS:
      return {
        ...state,
        purchaseOrder: action.payload,
        error: 0,
        type: action.type,
      };
    case COMPANY_PAYMENT_CHECKOUT_FAILED:
      return {
        ...state,
        purchaseOrder: {},
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_TRANSACTION_REFUND_SUCCESS:
      return {
        ...state,
        error: 0,
      };
    case COMPANY_TRANSACTION_REFUND_FAILED:
      return {
        ...state,
        error: 1,
      };
    case COMPANY_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        transactionHistory: action.payload,
        error: 0,
      };
    case COMPANY_TRANSACTION_HISTORY_FAILED:
      return {
        ...state,
        transactionHistory: [],
        error: 1,
      };
    case COMPANY_PAYMENT_HISTORY_SUCCESS:
      return {
        ...state,
        paymentHistory: action.payload,
        error: 0,
        type: action.type,
      };
    case COMPANY_PAYMENT_HISTORY_FAILED:
      return {
        ...state,
        paymentHistory: {},
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_SUCCESS:
      return {
        ...state,
        paymentInvoice: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_CLEAR:
      return {
        ...state,
        paymentInvoice: {},
        error: 0,
        message: "",
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_FAILED:
      return {
        ...state,
        paymentInvoice: {},
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        paymentInvoiceDetails: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_DETAILS_FAILED:
      return {
        ...state,
        paymentInvoiceDetails: {},
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_SUCCESS:
    case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS:
    case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS:
      /* Perform enhancements such as date formatting */
      for (let m = 0; m < action.payload.generalLedgerCodes.length; m++) {
        const generalLedgerCode = action.payload.generalLedgerCodes[m];
        generalLedgerCode.createdAtFormatted = moment(
          generalLedgerCode.createdAt
        ).format("MM/DD/YYYY");
      }

      return {
        ...state,
        generalLedgerCodes: action.payload.generalLedgerCodes,
        error: 0,
        message: "",
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_FAILED:
    case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED:
    case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED:
      return {
        ...state,
        generalLedgerCodes: [...state.generalLedgerCodes],
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_SUCCESS:
    case COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS:
      return {
        ...state,
        error: 0,
        message: "",
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_SUCCESS:
      return {
        ...state,
        error: 0,
        message: "",
        type: action.type,
        paymentInvoicePendingDetails: {
          ...state.paymentInvoicePendingDetails,
          [action.payload.invoiceInfoOrderList.invInfo.invId]: {
            ...state.paymentInvoicePendingDetails[
              action.payload.invoiceInfoOrderList.invInfo.invId
            ],
            approvalStatus:
              action.payload.invoiceInfoOrderList.invInfo.approvalStatus,
            adjustmentStatus:
              action.payload.invoiceInfoOrderList.invInfo.adjustmentStatus,
            statusDesc: action.payload.invoiceInfoOrderList.invInfo.statusDesc,
          },
        },
      };
    case COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS:
    case COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS:
    case COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS:
      for (let m = 0; m < action.payload.generalLedgerCodeUsages.length; m++) {
        const generalLedgerCodeUsage =
          action.payload.generalLedgerCodeUsages[m];
        generalLedgerCodeUsage.amount = parseFloat(
          generalLedgerCodeUsage.amount
        ).toFixed(2);
        generalLedgerCodeUsage.listItemIndex = m;
        generalLedgerCodeUsage.operationsIndex = uuid();
        generalLedgerCodeUsage.operationsEdit = true;
        generalLedgerCodeUsage.operationsDelete = true;
        generalLedgerCodeUsage.generalLedgerCodeStr = state.generalLedgerCodes.filter(
          (code) => code.id === generalLedgerCodeUsage.codeId
        )[0].generalLedgerCode;
        generalLedgerCodeUsage.reason = state.generalLedgerCodeUsageReasons.filter(
          (reason) => reason.id === generalLedgerCodeUsage.reasonId
        )[0].reason;
        generalLedgerCodeUsage.department = state.BUDivisionList.filter(
          (department) =>
            department.divisionId === generalLedgerCodeUsage.divisionId
        )[0].name;
        generalLedgerCodeUsage.amountFormatted = `$${generalLedgerCodeUsage.amount}`;
        generalLedgerCodeUsage.createdAtFormatted = moment(
          generalLedgerCodeUsage.createdAt
        ).format("MM/DD/YYYY");
      }

      return {
        ...state,
        error: 0,
        message: "",
        type: action.type,
        paymentInvoicePendingDetails: {
          ...state.paymentInvoicePendingDetails,
          [action.payload.invoiceId]: {
            ...state.paymentInvoicePendingDetails[action.payload.invoiceId],
            miscellaneous: action.payload.generalLedgerCodeUsages,
          },
        },
      };
    // case COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_FAILED:
    // case COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_FAILED:
    //     return {
    //         ...state,
    //         error: 1,
    //         message: action.error,
    //         type: action.type,
    //     }
    case COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_FAILED:
    case COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_FAILED:
    case COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED:
    case COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED:
    case COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED:
    case COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED:
      return {
        ...state,
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_SUCCESS:
    case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS:
    case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS:
      /* Perform enhancements such as date formatting */
      for (
        let m = 0;
        m < action.payload.generalLedgerCodeUsageReasons.length;
        m++
      ) {
        const generalLedgerCodeReason =
          action.payload.generalLedgerCodeUsageReasons[m];
        generalLedgerCodeReason.createdAtFormatted = moment(
          generalLedgerCodeReason.createdAt
        ).format("MM/DD/YYYY");
      }

      return {
        ...state,
        generalLedgerCodeUsageReasons:
          action.payload.generalLedgerCodeUsageReasons,
        error: 0,
        message: "",
        type: action.type,
      };
    case COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_FAILED:
    case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED:
    case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED:
      return {
        ...state,
        generalLedgerCodeUsageReasons: [...state.generalLedgerCodeUsageReasons],
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_PAYMENT_USAGE_REPORT_SUCCESS:
      return {
        ...state,
        usageReportList: action.payload,
      };
    case COMPANY_PAYMENT_USAGE_REPORT_FAILED:
      return {
        ...state,
        usageReportList: [],
      };
    case COMPANY_PAYMENT_ADJUST_INVOICE_SUCCESS:
      return {
        ...state,
        message: action.payload.statusDetail,
      };
    case COMPANY_PAYMENT_ADJUST_INVOICE_FAILED:
      return {
        ...state,
        message: action.payload.statusDetail,
      };
    case COMPANY_MONERIS_ACCOUNT_START:
      return {
        ...state,
        monerisStart: true,
        type: action.type,
      };
    case COMPANY_MONERIS_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountList: action.payload.accList,
        monerisStart: false,
        monerisStatus: action.payload.statusCode,
        monerisMessage: action.payload.message,
        error: 0,
        message: "",
        type: action.type,
      };
    case COMPANY_MONERIS_ACCOUNT_FAILED:
      return {
        ...state,
        // accountList: action.payload,
        monerisStart: false,
        monerisStatus: 1,
        monerisMessage: action.error,
        error: 1,
        message: action.error,
        type: action.type,
      };
    case COMPANY_SET_CURRENT_COMPANY_CODE:
      return {
        ...state,
        currentCompanyCode: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case CUSTOMER_COMPANY_GET_SUCCESS:
      return {
        ...state,
        customerObj: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case CUSTOMER_COMPANY_GET_FAILED:
      return {
        ...state,
        customerObj: null,
        error: 1,
        message: action.error,
        type: action.type,
      };
    case CUSTOMER_GET_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: action.payload,
        error: 0,
        message: "",
        type: action.type,
      };
    case CUSTOMER_GET_ORDERS_FAILED:
      return {
        ...state,
        orderList: [],
        error: 1,
        message: action.error,
        type: action.type,
      };
    case ORDER_ADJUSTMENT_GET_SUCCESS:
      return {
        ...state,
        adjustmentList: action.payload,
        error: 0,
        message: "got adjustment list",
        type: action.type,
      };
    case ORDER_ADJUSTMENT_GET_FAILED:
      return {
        ...state,
        adjustmentList: [],
        error: 1,
        message: action.error,
        type: action.type,
      };
    case ORDER_ADJUSTMENT_CLEAR:
      return {
        ...state,
        adjustmentList: [],
        error: 0,
        message: "clear adjustment list",
        type: action.type,
      };
    case USER_UPDATE_RESELLER_OBJ_SUCCESS:
    case USER_UPDATE_RESELLER_OBJ_FAILED:
      return {
        ...state,
        message: action.message,
      };
    case USER_DISABLE_RESELLER_OBJ_SUCCESS:
    case USER_DISABLE_RESELLER_OBJ_FAILED:
      return {
        ...state,
        message: action.message,
      };
    case USER_GET_ALL_RESELLER_SUCCESS:
      return {
        ...state,
        resellerList: action.payload,
      };
    case USER_GET_ALL_RESELLER_FAILED:
      return {
        ...state,
        resellerList: [],
      };
    case GET_CARRIER_LIST_SUCCESS:
      return {
        ...state,
        carrierList: action.payload.carriers,
      };
    case GET_CARRIER_LIST_FAILED:
      return {
        ...state,
        carrierList: [],
      };
    case GET_PROMOTE_SUCCESS:
      return {
        ...state,
        discountList: action.payload,
      };
    case GET_PROMOTE_FAILED:
      return {
        ...state,
        discountList: [],
      };
    case SET_PROMOTE_SUCCESS:
      return {
        ...state,
        message: action.payload,
      };
    case SET_PROMOTE_FAILED:
      return {
        ...state,
        message: action.payload,
      };
    case RESELLER_SET_ITEM_SUCCEED:
      return {
        ...state,
        message: action.payload,
      };
    case RESELLER_SET_ITEM_FAILED:
      return {
        ...state,
        message: action.payload,
      };
    case RESELLER_SET_SPECIAL_LINE_SUCCEED:
      return {
        ...state,
        message: action.payload,
      };
    case RESELLER_SET_SPECIAL_LINE_FAILED:
      return {
        ...state,
        message: action.payload,
      };
    case GET_ACTIVATED_SIM_DASHBOARD_START:
      return {
        ...state,
        dashboardActivatedSimListLoading: true,
        dashboardActivatedSimList: [],
        dashboardActivatedSimTotalElement: 0,
      };
    case GET_ACTIVATED_SIM_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardActivatedSimListLoading: false,
        dashboardActivatedSimList: action.payload.activatedSims,
        dashboardActivatedSimTotalElement: action.payload.totalElements,
      };
    case GET_ACTIVATED_SIM_DASHBOARD_FAILED:
      return {
        ...state,
        dashboardActivatedSimListLoading: false,
        dashboardActivatedSimList: [],
        dashboardActivatedSimTotalElement: 0,
      };
    case GET_PLAN_USAGE_DATA_START:
      return {
        ...state,
        dashboardPlanUsageData: [],
      };
    case GET_PLAN_USAGE_DATA_SUCCESS:
      return {
        ...state,
        dashboardPlanUsageData: action.payload,
      };
    case GET_PLAN_USAGE_DATA_FAILED:
      return {
        ...state,
        dashboardPlanUsageData: [],
      };
    case GET_IDENTIFIER_USAGE_DATA_START:
      return {
        ...state,
        dashboardIdentifierUsageData: [],
      };
    case GET_IDENTIFIER_USAGE_DATA_SUCCESS:
      return {
        ...state,
        dashboardIdentifierUsageData: action.payload,
      };
    case GET_IDENTIFIER_USAGE_DATA_FAILED:
      return {
        ...state,
        dashboardIdentifierUsageData: [],
      };
    case CONSUMER_GET_PLAN_USAGE_SUCCESS:
      return {
        ...state,
        consumerPlanUsageList: action.payload,
      };
    case CONSUMER_GET_PLAN_USAGE_FAILED:
      return {
        ...state,
        consumerPlanUsageList: [],
      };
    case PAYMENT_ORDER_GET_ADDRESS_SUCCESS:
    case PAYMENT_ORDER_GET_ADDRESS_FAILED:
      return {
        ...state,
        addressList: action.payload,
      };
    case USER_GET_PAGE_CONTENT_SUCCESS:
      let uniqueContentList = uniq([
        ...state.pageContentList,
        ...action.payload,
      ]);

      return {
        ...state,
        pageContentList: uniqueContentList,
      };
    case USER_GET_PAGE_CONTENT_FAILED:
      return {
        ...state,
        pageContentList: [],
      };
      break;
    case USER_FORGET_PASSWORD_SUCCESS:
      return {
        ...state,
        forgetPasswordMessage:
          "Reset password link has been sent to your email.",
      };
    case USER_FORGET_PASSWORD_FAILED:
      return {
        ...state,
        forgetPasswordMessage:
          "Server error. Please contact administrator for more information.",
      };
    case USER_FORGET_PASSWORD_NOT_FOUND:
      return {
        ...state,
        forgetPasswordMessage:
          "This username is unavailable. Please try again.",
      };
    case USER_FORGET_PASSWORD_NOT_ALLOWED:
      return {
        ...state,
        forgetPasswordMessage:
          "This username is not allowed to reset password. Please contact administrator for more information.",
      };
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordMessage: "Password Successfully Reset.",
      };
    case USER_RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetPasswordMessage: "Invalid Token.",
      };
    case COMPANY_GET_BRANDING_SUCCESS:
      return {
        ...state,
        branding: action.payload.detail,
      };
    case COMPANY_GET_BRANDING_FAILED:
      return {
        ...state,
        branding: null,
      };
    case CLIENT_GET_BUSINESS_UNITS_SUCCESS:
      return {
        ...state,
        BUCountryList: action.payload.countryList,
        BULOBList: action.payload.lineOfBusinessList,
        BUDivisionList: action.payload.divisionList,
        BURelationshipList: action.payload.relations,
      };
    case CLIENT_GET_BUSINESS_UNITS_FAILED:
      return {
        ...state,
        BUCountryList: [],
        BULOBList: [],
        BUDivisionList: [],
        BURelationshipList: [],
      };
    case CLIENT_GET_BUSINESS_UNIT_RELATION_SUCCESS:
      return {
        ...state,
        BURelationshipList: action.payload.relations,
      };
    case CLIENT_GET_BUSINESS_UNIT_RELATION_FAILED:
      return {
        ...state,
        BURelationshipList: [],
      };
    case CLEAR_CLIENT_DATA:
      return {
        ...state,
        BUCountryList: [],
        BULOBList: [],
        BUDivisionList: [],
        BURelationshipList: [],
        usageReportList: [],
        ServicePlanList: [],
        discountList: [],
        accountList: [],
        consumerList: [],
        paymentInvoice: {},
        paymentInvoiceDetails: {},
        addressList: [],
        paymentAccountList: [],
        carrierList: [],
      };
      case GET_USER_ACTIVITY_TRACKING_SUCCEED:
        return {
            ...state,
            userActivityTrackingList: action.payload.detail
        }
    case GET_USER_ACTIVITY_TRACKING_FAILED:
        return {
            ...state,
            userActivityTrackingList: []
        }

    default:
      return state;
  }
}
