/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Client } from '@stomp/stompjs';
import BootstrapTable from 'react-bootstrap-table-next';
import { Table, Col, Container, Row, Card, CardBody, CardTitle, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse, Dropdown, DropdownMenu, DropdownToggle, Badge } from 'reactstrap';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import Icon from '@mdi/react'
import { mdiRefresh } from '@mdi/js';
import axios from 'axios';
import { name } from 'file-loader';
import { LabelTextInput } from '../cameraList/CameraCreateModal';
import { sendSMS } from '../../../../factories/utils';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { AI_API_ROOT_HTTPCONTROL, WEB_SERVICE_GET_PASSLINE_COUNT, NGINX_ROOT} from '../../../../constants/appConstants';
import { postAssetAttribute, loadAssets } from '../../../../redux/actions/assetActions';
import { bindActionCreators } from 'redux';
import  jQuery from 'jquery'





function NvrDisplay({nvrIP, port}) {
    const [urlNvrMiddleClient,setUrlNvrMiddleClient] = useState(null)
    const [urlNvrWebClient,setUrlNvrWebClient] = useState(null)

    useEffect(()=>{
        if(port){
            let url = `${NGINX_ROOT}:${port}`;
            
            setUrlNvrMiddleClient(url); // Middle page
            setUrlNvrWebClient(url+"/nvrIndex"); // Nvr web client page
        }
    }, [port])

    const onClickNvrWebClient = (middlePage, webClient) => {
        let user = sessionStorage.getItem('userid')
        let pass = sessionStorage.getItem('password')

        var nvrMiddlePage = window.open(middlePage)
        setTimeout( ()=> {
          let msg = {pUrl: webClient, pUser: user, pPass: pass}

          nvrMiddlePage.postMessage(msg, middlePage)
        }, 2000)
    };

    return (
        <Collapse isOpen={true}>
            <Card>
                <CardBody>
                    {/* <iframe id={"nvriFrame"} src="http://localhost/nvrIndex/" name="iframe_a" style={{width:"100%",height:"1080px"}}></iframe> */}
                    {/* <a target='_blank' href={urlNvrWebClient}>NVR Web Client</a> */}
                    <Button className={'btn btn-link'} onClick={()=>{onClickNvrWebClient(urlNvrMiddleClient, urlNvrWebClient)}}  >NVR Web Client</Button>
                </CardBody>
            </Card>
        </Collapse>
    )
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({ postAssetAttribute, loadAssets }, dispatch)
}

export default connect(null, mapDispatchToProps)(NvrDisplay)
