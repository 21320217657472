/* eslint-disable */
import {
    GET_ALL_MODULES_STARTED,
    GET_ALL_MODULES_SUCCEED,
    GET_ALL_MODULES_FAILED,
    GET_ALL_GROUPS_STARTED,
    GET_ALL_GROUPS_SUCCEED,
    GET_ALL_GROUPS_FAILED,
} from '../../../constants/actionTypes';//'../../const'

const initState = {
    loading: false,
    type: '',
    payload: '',
    groupType: '',
    groupLoading: false,
    groupPayload: [],
};

export default function (state = initState, action) {
    switch (action.type) {
        case GET_ALL_MODULES_STARTED:
            return {
                loading: true,
                type: GET_ALL_MODULES_STARTED
            };
        case GET_ALL_MODULES_SUCCEED:
            // for (let index in action.payload.data.data) {
            //     action.payload.data.data[index]['user_role']= '';
            //     action.payload.data.data[index]['user_group']= '';
            // }
            // return {
            //     ...state,
            //     loading: false,
            //     type: GET_ALL_MODULES_SUCCEED,
            //     payload: action.payload.data.data
            // };
            return {
                ...state,
                loading: false,
                type: GET_ALL_MODULES_SUCCEED,
                payload: action.payload
            };
        case GET_ALL_MODULES_FAILED:
            return {
                ...state,
                loading: false,
                type: GET_ALL_MODULES_FAILED,
                payload: action.payload
            };
        case GET_ALL_GROUPS_STARTED:
            return {
                groupLoading: true,
                groupType: GET_ALL_GROUPS_STARTED
            }
        case GET_ALL_GROUPS_SUCCEED:
            return{
                ...state,
                groupLoading: false,
                groupType: GET_ALL_GROUPS_SUCCEED,
                groupPayload: action.payload,
            }
        case GET_ALL_GROUPS_FAILED:
            return{
                ...state,
                groupLoading: false,
                groupType: GET_ALL_MODULES_FAILED,
                groupPayload: action.payload,
            }
        default:
            return state;
    }
}