/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ClientInvoiceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adjustAmount: null,
            paymentMethod:"",
            paidAmount: 0,
            invAmt: 0,
            userPayAmt: 0,
            comment: "",
            status: 12
        }
    }

    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            adjustAmount: nextProps.adjustAmount,
            paidAmount: nextProps.paidAmount,
            invAmt: nextProps.invAmt,
            comment: nextProps.comment,
            status: nextProps.status,
            paymentMethod: nextProps.paymentMethod,
        })
    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onSubmit = () => {
        this.onClose();
        this.props.handleInvoiceAdjustment(this.props.invoiceId, this.state.userPayAmt, this.props.isAdmin);
    }
    onPaymentSubmit = () => {
        this.onClose();
        this.props.handleInvoiceAdjustment(this.props.invoiceId, this.state.paymentMethod, this.props.isAdmin);
    }
    onClose = () => {
        this.setState({
            adjustAmount: null,
            paidAmount: 0,
            comment: "",
            status: 0
        }, () => {
            this.props.onClose()
        })
    }
    onInputChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };
    ValidateAmount = () => {
        var regex = /^[0-9]+$|^[0-9]+\.[0-9]{1,2}$/;
        return (this.state.userPayAmt && regex.test(this.state.userPayAmt));
    }
    ValidatePaymentMethod = () => {
        return this.state.paymentMethod && this.state.paymentMethod != "";
    }
    render() {
        // console.log("this.state.paymentMethod", this.state.paymentMethod);
        if (!this.props.isAdmin) {            
            return (
                <Modal
                    style={{ maxWidth: '800px' }}
                    isOpen={this.props.modal}
                    size='lg'
                    contentClassName="custom-modal-style"
                >
                    <ModalHeader toggle={this.onClose}>Pay Invoice</ModalHeader >
                    <ModalBody>
                        <Col md={12}>
                            <br />
                            <Row>
                                <Col md={2} >
                                    <span> Amount</span>
                                </Col>
                                <Col md={8}>
                                    <Input
                                        name='userPayAmt'
                                        id='userPayAmt'
                                        placeholder='Amount'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        value={this.state.userPayAmt}
                                    />
                                </Col>
                            </Row>
                            <div>
                                NOTE: There is need still ${this.state.invAmt - this.state.paidAmount} left on the Invoice
                            </div>
                            <br />
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.ValidateAmount()} >
                            Pay
                        </Button>
                        <Button outline color='secondary' type="reset" onClick={this.onClose} >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        } else {
            return (
                <Modal
                    style={{ maxWidth: '800px' }}
                    isOpen={this.props.modal}
                    size='lg'
                    contentClassName="custom-modal-style"
                >
                    <ModalHeader toggle={this.onClose}>Pay Invoice</ModalHeader >
                    <ModalBody>
                        <Col md={12}>
                            <br />
                            <Row>
                                <Col md={2} >
                                    <span> Payment Method</span>
                                </Col>
                                <Col md={8}>
                                    <Input
                                        type='select'
                                        name='paymentMethod'
                                        id='paymentMethod'
                                        placeholder='Payment'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        value={this.state.paymentMethod}
                                    >
                                        {[{name:"CHK", value:"check"},{name:"CC", value:"credit card"},{name:"ACH", value:"ACH"}].map(pay => 
                                            (this.state.paymentMethod===pay.name)?<option key={pay.name} value={pay.name}>{pay.value}</option>
                                            :
                                            null
                                        )}
                                        <option key={"POP"} value={"POP"}>Paid outside Platform</option>


                                    </Input>
                                </Col>
                            </Row>
                            <br />
                        </Col>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color='custom' type="button" onClick={this.onPaymentSubmit} disabled={!this.ValidatePaymentMethod()} >
                            Mark As Pay
                        </Button>
                        <Button outline color='secondary' type="reset" onClick={this.onClose} >
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            )
        }
    }
}
export default ClientInvoiceModal;