/* eslint-disable */
import React, {Component} from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './Panel';
import {TIME_ZONE} from '../../../../constants/dataConstants';
import { cloneDeep, sortBy } from 'lodash';
import moment from 'moment';
//import CustomTooltipTestBootstrapCard from './CustomTooltipTestBootstrapCard';
import {chartDataProcess, formatDayLevelData} from '../../../../factories/chartDataProcess'

class ChartOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedDayLevelData: []
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.asset24HObj && nextProps.currentAsset.assetid) {
      let formattedDayLevelData = formatDayLevelData(nextProps.asset24HObj[nextProps.currentAsset.assetid])
      this.setState({ formattedDayLevelData })
    }
  }

  toPercent = (decimal, fixed = 0) =>
  {
    return `${(decimal * 100).toFixed(fixed)}%`;
  } 

  getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

    return this.toPercent(ratio, 2);
  };
  renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);
  
    return (
      <div className="customized-tooltip-content">
        <ul className="list">
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${this.toPercent(entry.value,2)}`}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  render() {
    const { t } = this.props;

    const renderColorfulLegendText = (value, entry) => {
      const { color } = entry;
  
      return <span style={{ color, marginRight: 20 }}>{value}</span>;
    };

    return (
      <>
        <Panel xs={12} lg={12} title={t('Occupancy Rate for Today (In Hours)')}>
          <ResponsiveContainer height={500} className="dashboard__area">
            <AreaChart data={this.state.formattedDayLevelData} margin={{ top: 20, left: -15, bottom: 20, right:15 }}>
              <XAxis dataKey="slot" tick={{fontSize:12}} dy={10}/>
              {/* <YAxis tickFormatter={value => `${value}`} tickLine={false} tickCount={1} /> */}
              <YAxis type="number" tickFormatter={this.toPercent} tickLine={false}/>
              {/* <Tooltip content={<CustomTooltipTestBootstrapCard />} /> */}
              <Tooltip content={this.renderTooltipContent} />
              <Legend iconType="square" formatter={renderColorfulLegendText} />
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <Area name="Total Lot Occupancies" stackId="1" type="monotone" dataKey="total" fill="#9aabf3" stroke="#4666e2" fillOpacity={0.5} />
              <Area name="Level 1 Lot Occupancies" stackId="2" type="monotone" dataKey="l1" fill="#9dd3fb" stroke="#4baff8" fillOpacity={0.5} />
              <Area name="Level 2 Lot Occupancies" stackId="3" type="monotone" dataKey="l2" fill="#efdde3" stroke="#f0c7ce" fillOpacity={0.5} />
              <Area name="Level 3 Lot Occupancies" stackId="4" type="monotone" dataKey="l3" fill="#ebcbfd" stroke="#e2a4fc" fillOpacity={0.5} />
            </AreaChart>
          </ResponsiveContainer>
        </Panel>
      </>
    )
  }
}

ChartOne.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ChartOne);
