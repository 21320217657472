/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Collapse, Button, CardBody, Card, CardHeader } from 'reactstrap';
import { loadDevice, loadDeviceAttr, getGroupDeviceData, clearGroupDeviceData, getDevicesByGroup, clearGroupDevices, } from '../../../redux/actions/mapActions';
import DeviceFilter from './DeviceFilter';
import DeviceTable from './DeviceTable';
import MapDeviceContainer from './MapDeviceContainer';


class DeviceTypeBoard extends Component {
    constructor(props){
        super(props);
        this.state={
            isOpenTag1: true,
            isOpenTag2: true,
            mapProps: {
                showingInfoWindow: false,
                activeMarker: {},
                selectedPlace: {attr:{}},
            },
        }
    }

    // -------------------- lifecycl methods ---------------------
    componentDidMount(){
        this.props.loadDevice();
    }

    componentWillReceiveProps(nextProps){
    }

    componentWillUnmount(){
        this.props.clearGroupDeviceData();
        this.props.clearGroupDevices();
    }
// -------------------- customer methods ----------------------

    toggle = tagname => () => {
        this.setState(prevState => ({
            [tagname]: !prevState[tagname]
        }));
    }

    handleMapMarkerClick = (props, marker, e) => {    
         
        
        this.setState(prevState => ({
            mapProps: {
                // showingInfoWindow: true,
                showingInfoWindow: !prevState.mapProps.showingInfoWindow,
                activeMarker: marker,
                selectedPlace: props,
                lat: props.position.lat,
                lng: props.position.lng,
            },
        }));      
    }

    handleMapInfoWinClick = (props) => {
        if(this.state.mapProps.showingInfoWindow){
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    handleMapInfoWinClose = (props) => {
        //  
        // if(this.state.mapProps.showingInfoWindow){
            this.setState((prevState) => ({
                mapProps: {
                    ... prevState.mapProps,
                    showingInfoWindow: false,
                    // activeMarker: null
                }                
            }));
        // }
    }

    renderCollapsCard=()=>{
        return (
            <Fragment>
            <Card>
                <CardHeader style={{cursor: 'pointer'}} onClick={this.toggle('isOpenTag1')}>
                    <div>
                        Map
                    </div>
                </CardHeader>
                <CardBody style={{padding:0}}>
                    <Collapse isOpen={this.state.isOpenTag1}> 
                        <div style={{padding:15}}>
                        <MapDeviceContainer 
                            onMarkerClick={this.handleMapMarkerClick} 
                            onInfoWinClick={this.handleMapInfoWinClick}
                            handleMapInfoWinClose={this.handleMapInfoWinClose}
                            values = {this.state.mapProps}
                            mapData = {this.props.deviceGroupData}
                            loading = {this.props.loading}
                        />
                        </div>
                    </Collapse>
                </CardBody>
            </Card>

            <Card>
                <CardHeader className={'dragBar'} style={{cursor: 'pointer'}} onClick={this.toggle('isOpenTag2')}>
                    <div>
                        Data
                    </div>
                </CardHeader>
                <CardBody style={{padding:0}}>
                    <Collapse isOpen={this.state.isOpenTag2}> 
                        <div style={{padding:15}}>
                            <DeviceTable
                                mapData = {this.props.deviceGroupData}
                            />
                        </div>
                    </Collapse>
                </CardBody>
            </Card>
            </Fragment>
        );
    }

    renderFilter = () => {
        return (
            <Fragment>
                <Card style={{padding:0}}>
                    <CardBody style={{paddingBottom:0}}>
                        <DeviceFilter
                            types = {this.props.types}
                            groupDevices = {this.props.groupDevices}
                            getGroupDeviceData = {this.props.getGroupDeviceData}
                            clearGroupDeviceData = {this.props.clearGroupDeviceData}
                            getDevicesByGroup = {this.props.getDevicesByGroup}
                            clearGroupDevices = {this.props.clearGroupDevices}
                            loading = {this.props.loading}
                        />
                    </CardBody>
                </Card>
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
            {this.renderFilter()}
            <hr/>
            {/* {this.renderExpandPanel()}
            <br/> */}
            {this.renderCollapsCard()}
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    const mapData = state.mapboard.mapData;
    const types = state.mapboard.types;
    const deviceGroupData = state.mapboard.deviceGroupData;
    const groupDevices = state.mapboard.groupDevices;
    const loading = state.mapboard.loading;

    const props = { mapData, types, deviceGroupData, groupDevices, loading};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadDevice, 
        loadDeviceAttr,
        getGroupDeviceData,
        clearGroupDeviceData,
        getDevicesByGroup, 
        clearGroupDevices,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypeBoard);