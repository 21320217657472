/* eslint-disable */
import React, { Component } from 'react';
import {Row, Col, Card, CardBody, Label, Input, Button, Table} from 'reactstrap';
import { ChromePicker} from 'react-color';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import check_mark_circle from '../../../shared/img/icon/check_mark_circle.svg' 
import { ContentSaveSettingsOutlineIcon, OneTwoThreeIcon } from 'mdi-react';
import {EditTextButton, DeleteTextButton, PlusIconButton} from '../../../shared/components/Button/IconButton';

class DeviceSetCard extends Component{

    constructor(props){
        super(props);
        this.state={
        }
        this.renderThisDevice = this.renderThisDevice.bind(this);
    }

    handleCancelOnclick = (e) => {
        this.props.handleCancelOnclick();
    }

    renderThisDevice(){
            return(               
                <tbody>
                    <tr>
                    <th>Device ID</th>
                        <td>1234</td>
                    </tr>
                    <tr>
                    <th>UPC</th>
                        <td>{this.props.upc}</td>
                    </tr>
                    <tr>
                    <th>Company</th>
                        <td>{this.props.company}</td>
                    </tr>
                    <tr>
                    <th>MFG</th>
                        <td>2100</td>
                    </tr>
                    <tr>
                    <th>Group</th>
                        <td>ITSP group 0</td>
                    </tr>
                    <tr>
                    <th>Network_Supported</th>
                        <td>ns0</td>
                    </tr>
                    <tr>
                    <th>Device Status</th>
                        <td>on</td>
                    </tr>
                    <tr>
                    <th>Old Device ID</th>
                        <td>4321</td>
                    </tr>
                </tbody>
            )
    }

    render(){
        return(
            <div onClick = {this.closeColorPicker}>
            <Col md={12}>
                <Card onClick={this.handelCardOnclick}>
                    <CardBody>
                        <h3>Device Detail</h3>
                        <br/>
                        <Row>
                            <Col md={5}>
                                <table class="table">
                                  {this.renderThisDevice()}
                                </table>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                           <Col md={12} style={{ textAlign: 'right' }}>
                                <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleCancelOnclick}>
                                    Close
                                </Button>{' '}
                           </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            </div>
        )
    }

}

export default DeviceSetCard;