/* eslint-disable */
import React from "react";
import { Col, Row, Button, Input, Label } from 'reactstrap';

const ShippingAddress = (props) => {

    return (
        <Row>
            
            <Col md={12}>
                <Row>
                    <Col md={5}>
                        <Input
                            type='select'
                            name='sa_newShippingAddress'
                            id='sa_newShippingAddress'
                            className="form-control"
                            onChange={props.onShippingAddressChange}
                        >
                            {props.addressList.map((address, index) => (
                                <option key={index} value={address.id} selected={
                                    props.shippingAddress.choseAddress == address.id ? true : false
                                }>{address.addressLine1}</option>
                            ))}
                            <option key={1} value={0} selected={props.shippingAddress.choseAddress == 0 ? true : false }>New Shipping Address</option>
                        </Input>
                    </Col>
                </Row>
                <Row><Col><br/></Col></Row>
                <Row>
                    <Col md={6}>
                        <label style={{ marginBottom: '3px' }} for="sa_name"><span>Name </span><span style={{color:'grey'}}>(Optional)</span></label>
                        <Input
                            name='sa_name'
                            id='sa_name'
                            placeholder='ATTN'
                            onChange={props.onShippingAddressChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.shippingAddress.name}
                            disabled={props.shippingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                </Row>
                <Row><Col><br/></Col></Row>
                <Row>
                    <Col md={6}>
                        <label style={{ marginBottom: '3px' }} for="sa_shippingAddressLine1"><span>Shipping Address Line 1</span></label>
                        <Input
                            name='sa_shippingAddressLine1'
                            id='sa_shippingAddressLine1'
                            placeholder='Enter Street Address'
                            onChange={props.onShippingAddressChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.shippingAddress.addressLine1}
                            disabled={props.shippingAddress.choseAddress != 0 ? true : false}
                            invalid={false}
                        />
                    </Col>
                    <Col md={6}>
                        <label style={{ marginBottom: '3px' }} for="sa_shippingAddressLine2"><span>Shipping Address Line 2 </span><span style={{color:'grey'}}>(Optional)</span></label>
                        <Input
                            name='sa_shippingAddressLine2'
                            id='sa_shippingAddressLine2'
                            placeholder='Entre Apt, Unit, Suite, Building'
                            onChange={props.onShippingAddressChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.shippingAddress.addressLine2}
                            disabled={props.shippingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                </Row>
                <Row><Col><br/></Col></Row>
                <Row>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="sa_city"><span>City</span></label>
                        <Input
                            name='sa_city'
                            id='sa_city'
                            placeholder='Enter City'
                            onChange={props.onShippingAddressChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.shippingAddress.city}
                            disabled={props.shippingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="sa_zipCode"><span>Zip Code</span></label>
                        <Input
                            name='sa_zipCode'
                            id='sa_zipCode'
                            placeholder='Enter Zip Code'
                            onChange={props.onShippingAddressChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.shippingAddress.zipCode}
                            disabled={props.shippingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                </Row>
                <Row><Col><br/></Col></Row>
                <Row>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="sa_stateProvince"><span>State/Province</span></label>
                        <Input
                            type='select'
                            name='sa_stateProvince'
                            id='sa_stateProvince'
                            className="form-control"
                            onChange={props.onShippingAddressChange}
                            disabled={props.shippingAddress.country === undefined || props.shippingAddress.choseAddress != 0 ? true : false}
                        >
                            {/* {console.log("shipping address > country", props.shippingAddress ,props.shippingAddress.country, isNaN(props.shippingAddress.country), props.shippingAddress.country == null, props.shippingAddress.country === undefined)} */}
                            <option disabled={props.shippingAddress.country === undefined ? false : true} value="" selected>Choose a State/Province</option>
                            {props.provinceList.map((province, index) => (
                                props.shippingAddress.province === province.abbrev ?
                                <option key={index} value={province.abbrev} selected={true}>{province.name}</option>:
                                <option key={index} value={province.abbrev}>{province.name}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="sa_country"><span>Country</span></label>
                        <Input
                            type='select'
                            name='sa_country'
                            id='sa_country'
                            className="form-control"
                            placeholder="Choose a Country"
                            onChange={(e) => {props.onCountryChange(e); props.onShippingAddressChange(e)}}
                            disabled={props.shippingAddress.choseAddress != 0 ? true : false}
                        >
                            <option disabled={true} value="" selected>Choose a Country</option>
                            {props.countryList.map((country, index) => (
                                props.shippingAddress.country === country.value ? 
                                <option key={index} value={country.value} selected={true}>{country.label}</option> :
                                <option key={index} value={country.value}>{country.label}</option>
                            ))}
                        </Input>
                    </Col>
                </Row>
                <Row><Col><br/></Col></Row>
                <Row>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="sa_city"><span>Shipping Charges</span></label>
                        <Input
                            name='sa_shippingCharges'
                            id='sa_shippingCharges'
                            placeholder='Shipping Charges'
                            onChange={props.onShippingAddressChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.shippingAddress.shippingCharges}
                        />
                    </Col>
                </Row>
            </Col>

            <Col md={12}><br/></Col>

            <Col md={12}>
                <Button outline color='secondary' type="reset" 
                    onClick={() => {props.handleBack()} }
                >
                    Back
                </Button>
                <Button outline color='custom' type="button"
                    onClick={ () => {props.handleNext()} }
                    disabled={!props.validateShippingAddress()}
                >
                    Continue
                </Button>
            </Col>
        </Row>
    )
}

export default ShippingAddress;