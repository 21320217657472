/*eslint-disable*/
import React, { Component } from "react";
import { CardBody, Card } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { hasRole } from "../../../factories/auth";
class CompanyTransactionBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {

    }
    refundButton = (cell, row) => {
        if (row != null) {
            if (row.accountType === "CC" && row.accountId != null && row.accountId != 0) {
                return (
                    <ConfirmDeleteDialog
                        label={"Refund"}
                        msg={'Do you want to refund transaction?'}
                        onConfirm={() => { this.refundAction(row); }}
                        noIcon={true}
                        disabled={!(row.complete === "true" && (row.statusDesc === "paid" || row.statusDesc === "refund failed"))}
                    />
                )
            }
        }
    }
    refundAction = (row) => {
        let obj = {
            "companyId": row.companyId,
            "resellerId": row.resellerId,
            "transId": row.transId
        }
        this.props.refundTransaction(obj).then(() => this.props.loadTransactionHistory({ "objId": row.companyId }));


    }
    priceFormater = (cell, row) => {
        let price = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
        }
        return (
            <span>{`$${price}`}</span>
        )
    }
    dateFormatter = (cell, row) => {
        if (cell != null) {
            var date = new Date(cell)
            var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            return (`${str}`);
        }
    }
    render() {
        let list = [];
        if (this.props.transactionHistory != null) {
            if (Array.isArray(this.props.transactionHistory.historyList)) {
                list = this.props.transactionHistory.historyList.slice(0).reverse();
            }
        }

        const options ={
            sizePerPageList: [ {
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            } ],
        }

        return (
            <Card>
                <CardBody className="mainCardBody">
                    <BootstrapTable
                        data={list}
                        tableHeaderClass='header_white'
                        bordered={false}
                        options={options}
                        pagination
                        exportCSV
                        csvFileName='Company_Payment_History.csv'
                    >
                        <TableHeaderColumn dataField='transId' caretRender={sortCaret} isKey={true} dataAlign="center" width='10%' hidden >Transaction Id</TableHeaderColumn>
                        <TableHeaderColumn dataField='resellerName' caretRender={sortCaret} dataAlign="center" dataSort width='10%' csvHeader='Client'>Client</TableHeaderColumn>
                        <TableHeaderColumn dataField='transNum' caretRender={sortCaret} dataAlign="center" dataSort width='10%' csvHeader='Reference'>Reference</TableHeaderColumn>
                        <TableHeaderColumn dataField='transAmount' caretRender={sortCaret} dataAlign="center" dataFormat={this.priceFormater.bind(this)} dataSort width='10%' csvHeader='Amount'>Amount</TableHeaderColumn>
                        <TableHeaderColumn dataField='transDate' caretRender={sortCaret} dataAlign="center" dataFormat={this.dateFormatter.bind(this)} dataSort width='10%' csvHeader='Initial'>Initial</TableHeaderColumn>
                        <TableHeaderColumn dataField='updateDate' caretRender={sortCaret} dataAlign="center" dataFormat={this.dateFormatter.bind(this)} dataSort width='10%' csvHeader='Update'>Update</TableHeaderColumn>
                        <TableHeaderColumn dataField='statusDesc' caretRender={sortCaret} dataAlign="center" dataSort width='10%' csvHeader='Status'>Status</TableHeaderColumn>
                        <TableHeaderColumn dataField='complete' caretRender={sortCaret} dataAlign="center" dataSort width='10%' csvHeader='Complete'>Complete</TableHeaderColumn>
                        <TableHeaderColumn dataField='msg' caretRender={sortCaret} dataAlign="center" dataSort width='10%' csvHeader='Message'>Message</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='action'
                            dataAlign="center"
                            width="10%"
                            dataFormat={this.refundButton.bind(this)}
                            export={false}
                            hidden = {(this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']))? false: true}
                        >
                            Manage
                        </TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card >
        )
    }

}
export default CompanyTransactionBoard;