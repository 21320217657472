/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import SettingMainBoard from './components/SettingMainBoard';
import { getCurrentUser } from '../../factories/auth';
import {UserContext} from '../../constants/myContexts';

const SettingPage = (props) => {
    // let userObj = getCurrentUser();
    let {userObj} = React.useContext(UserContext);
    if (!userObj) {
      toast.error('Can not fetch login user information, Please re-login.');
      props.history.push('/log_out');
    } else {
       
    }
  

    return (
      <Container className="dashboard">
        <Row>
          {/* <Col md={12}>
            <h2 className="page-title"><b>Setting </b></h2>
          </Col> */}
          <h2 className="page-title"><b> </b></h2>
        </Row>

        <Row>
          <SettingMainBoard 
          appname = {props.appname}
          companycode = {props.companycode}
          />
        </Row>
      </Container>
    );
  };
  
  export default withRouter(SettingPage);