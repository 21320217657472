/* eslint-disable */
import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import {SingleSelection, MultiSelectionComponent} from '../../Asset/components/SelectionComponents';
import { toast } from "react-toastify";

import moment from 'moment';
import 'react-dates/initialize';
import {getCurrentUser} from "../../../factories/auth";

class AssetFilter extends Component{
    constructor(props){
        super(props);
        this.state={

        }
        this.userObj = getCurrentUser();
        if( this.userObj.detail==null){
            return;
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color:  "black",
                '&:active': {backgroundColor: this.userObj.detail.appButtonColorHover},
                backgroundColor:  state.isFocused ? this.userObj.detail.appButtonColorHover: "white",
            }),
            control: (base,selectState) => ({
                ...base,
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': {borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor}
            }),
            indicatorsContainer:  (base,selectState)  => ({
                ...base,
                padding: "0",
            }),
        }

    }

    componentDidMount(){}
    componentWillReceiveProps(nextProps){
        if (nextProps.assetClass){
        }
    }
    componentWillUnmount(){
    }

    changeLocation = curLocation => {
        this.setState(prevState => {
            return ({
                selectedLocation: curLocation,
            })
        }, this.onRefreshClick);
    };

    changeStatus = curStatus => {
        this.setState({
            selectedStatus: curStatus,
        }, this.onRefreshClick);
    }

    changeSub = curSub => {
        this.setState({
            selectedSub: curSub,
        }, this.onRefreshClick);
    }


    onRefreshClick = () => {
        // return;
        // let filter = {
        //     location: this.state.selectedLocation?.value,
        //     status: this.state.selectedStatus?.value
        // }
        this.props.handleFilterRefresh(this.state.selectedLocation?.value);
    }

    render(){
        let locationOpt=[];
            // { value:'Kitchen', label:'Kitchen' },
            // { value:'Storage', label:'Storage' },
        let statusOpt = [];
            // { value:'online', label:'online' },
            // { value:'offline', label:'offline' },
        let subOpt=[];
            // { value:'Trenton North', label:'Trenton North' },
            // { value:'Trenton South', label:'Trenton South' },
        // this.props.devmaLocation?.forEach(item=>{
        //     locationOpt.push({ value:item, label:item });
        // });
        let devmaStatus = [];
        if (this.props.devmaStatus) {
            // deep copy
            devmaStatus = JSON.parse(JSON.stringify(this.props.devmaStatus));
        }
        if (devmaStatus.length === 0) {
            devmaStatus = ['online', 'offline'];
        } else if (devmaStatus.length === 1) {
            let devmaStatusStr = JSON.stringify(devmaStatus);
            if (devmaStatusStr.indexOf('online')) {
                devmaStatus.push('offline');
            } else if (devmaStatusStr.indexOf('offline')) {
                devmaStatus.unshift('online');
            }
        }
        devmaStatus?.forEach(item=>{
            statusOpt.push({ value:item, label:item.charAt(0).toUpperCase()+item.slice(1) });
        });
        this.props.devmaNvr?.forEach(item=>{
            locationOpt.push({ value:item, label:item });
        });

        return (
        <div style={{top:'70px', left:'-90px'}}>
            <Form>
                <Select
                    isClearable
                    isSearchable
                    options = {locationOpt}
                    value={this.state.selectedLocation}
                    onChange={this.changeLocation}
                    styles={this.state.customStyles}
                />
                <Label for="locations" style={{display: 'inline', width: "100%"}} ><p style={{fontSize:'12px'}}>Filter By Locations</p></Label>
            </Form>
        </div>
        );
    }
}

export default AssetFilter