/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Card, CardBody, Col, Container, Row, Button, Input } from 'reactstrap';
import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';
import {
    sortCaret,
    // AdjustableDiv 
} from '../../../../shared/components/Table/tableElements';
class NameDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            genericName: ""
        }
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps, nextContext) {
    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleOnBack = () => {
        this.props.clearDeviceName();
        this.props.onBack();
    }
    handleOnNext = () => {
        for (var sim of this.props.cart) {
            if (sim.deviceName == "" || sim.deviceName == null) {
                toast.error("must name all devices")
                return;
            }
        }
        this.props.onNext()
    }
    onDiscountChange = (event, row) => {

        if (event.target.value == -1) {
            toast.success("discount removed")
            this.props.setMultiDiscount(null)

        }
        else {
            toast.success("discount added")
            this.props.setMultiDiscount(event.target.value)
        }
    }

    render() {
        let cellEditProp = {
            mode: 'click',
            blurToSave: true,
            afterSaveCell: this.props.addDeviceName  // a hook for after saving cell
        };
        var list = [];
        var today = new Date();
        list = this.props.discountList.filter((discount) => (today.getTime() >= new Date(discount.validStartDate).getTime() && today.getTime() <= new Date(discount.validEndDate).getTime()));
        return (
            <div>
                <Row>
                    <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <BackButton
                            onClick={this.handleOnBack}
                            label="Back"
                            size={30}
                            color='black'
                        />
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NextButton
                            onClick={this.handleOnNext}
                            label="Next"
                            size={30}
                            color='black'
                        />
                    </Col>
                </Row>
                {(this.props.isAdmin == true) ? <Row>
                    <Col md={5}>
                        <Input
                            type='select'
                            name='discountId'
                            id='discountId'
                            placeholder='Discount'
                            className="form-control"
                            onChange={(event) => this.onDiscountChange(event)}
                        >
                            <option key={-1} value={-1}>Select Discount</option>
                            {list.map((discount, index) =>
                                <option key={index} value={discount.promoteId}>{discount.promoteName}</option>
                            )}

                        </Input>
                    </Col>
                </Row>
                    :
                    null}
                <br />
                <h3>Name Devices</h3>
                <br />
                <Row>
                    <Col md={5}>
                        <Input
                            name='genericName'
                            placeholder='Device Name'
                            onChange={this.onInputChange}
                            className="form-control"
                            value={this.state.genericName}
                            autocomplete='off'
                        />
                    </Col>
                    <Col md={5}>
                        <Button outline color='secondary' type="reset" onClick={() => { this.props.addMultiDeviceName(this.state.genericName) }}>
                            Apply
                        </Button>
                    </Col>
                </Row>
                <BootstrapTable
                    data={this.props.cart}
                    tableHeaderClass='header_white'
                     
                    bordered={false}
                    height='100%'
                    pagination
                    //expandableRow={this.isExpandableRow}
                    //expandComponent={this.expandComponent}
                    expandColumnOptions={{
                        //expandColumnComponent: this.expandColumnComponent,
                    }}
                    cellEdit={cellEditProp}
                >
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'SIM' }} caretRender={sortCaret} editable={false}>SIM Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='deviceName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} caretRender={sortCaret} >Device Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='identifierType' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Type' }} caretRender={sortCaret} editable={false}>SIM Identifier Type</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Value' }} caretRender={sortCaret} editable={false}>SIM Identifier Value </TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
}
export default NameDevice;