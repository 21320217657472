/* eslint-disable */
import React from "react";
import { Col, Row, Button, Input, Label } from 'reactstrap';


const EventAction = (props) => {
    return (
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <Label
                            for="eventName"
                        >
                            <span style={{ fontWeight: "bold" }}>Event Name</span> <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                            id="eventName"
                            name='eventName'
                            placeholder='Enter Name'
                            className="form-control"
                            onChange={props.onInputChange}
                            value={props.eventName}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <Label
                            for="eventDescription"
                        >
                            <span style={{ fontWeight: "bold" }}>Event Description</span> <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                            type="textarea"
                            id="eventDescription"
                            name='eventDescription'
                            placeholder='Enter Description'
                            className="form-control"
                            onChange={props.onInputChange}
                            value={props.eventDescription}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <Button className="btn-withhover" outline color="custom" onClick={() => { props.handleNext(0) }} disabled={!props.validateEventAction()}>Continue</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );

}
export default EventAction;