/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import DeviceSetSection from './DeviceSetSection';
import GeofenceSection from './GeofenceSection';
import RuleSection from './RuleSection';
import {loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff} from '../../../redux/actions/jobAction';
import {loadAllGeoFences} from '../../../redux/actions/fenceAction';
import {getRuls} from '../../../redux/actions/ruleActions'; 

class ActionEditForm extends Component{
    constructor(props){
        super(props);
        this.refDeviceSection = React.createRef();
        this.state={
            currentDeivecTypeId: '5cf828390499f50b995ae851',
            activeType:'1',
            activeDevice:'1',
            geofenceChecked: false,
            ruleChecked: false,
            actions: [],
            allfences: [],
            allrules: [],
            actionDeviceList: [],
            currentAction: {},
        }
    }
    // -----------------------------------
    componentDidMount(){
        this.props.loadAction({actionid:this.props.actionId});
        this.props.loadAllGeoFences({devicetypeid: this.state.currentDeivecTypeId});
        this.props.getRuls();
    }
    componentWillReceiveProps(nextProps){
        const actions = JSON.parse(JSON.stringify(nextProps.actions));
        let currentAction = null;
        let actionFence = [];
        let actionRule = [];
        let allfences = [];
        let allrules = [];
        let actionDeviceList = [];
        let geofenceChecked = false;
        let ruleChecked = false;

        if (actions && actions.length > 0 ){
            currentAction = actions[0];
            let devices = currentAction.deviceid;
            let assetid = currentAction.assetid;
            let assetname = currentAction.assetname;  
            if(this.state.actionDeviceList.length == 0){
                devices.forEach((item) => {
                    let obj = {
                        deviceid: item, 
                        devicename: '',
                        assetid: assetid,
                        assetname: assetname,
                    };
                    actionDeviceList.push(obj);
                });
            }else{
                actionDeviceList = this.state.actionDeviceList
            }        
           
        }
       
        if (currentAction != null && nextProps.allfences){
            const fenceid = currentAction.fenceid;

            actionFence = nextProps.allfences.filter(item => item.fenceid == fenceid);
            allfences = nextProps.allfences.filter(item => item.fenceid != fenceid);
            if (actionFence.length > 0){
                geofenceChecked = true;
            }
        }

       
        if (currentAction != null && nextProps.allrules){
            const ruleid = currentAction.ruleid;
            actionRule = nextProps.allrules.filter(item => item.ruleid == ruleid);
            allrules = nextProps.allrules.filter(item => item.ruleid != ruleid);
            if (actionRule.length > 0){
                ruleChecked = true;
            }
        }

        this.setState({
            actions: actions,
            currentAction: currentAction,
            actionFence : actionFence,
            actionRule : actionRule,
            allfences: allfences,
            allrules: allrules,
            actionDeviceList: actionDeviceList,
            geofenceChecked: geofenceChecked,
            ruleChecked: ruleChecked,
        });
    }
    // static getDerivedStateFromProps(props, state) {}
    // -----------------------------------
    typeToggle = (pageIndex) => {
        this.setState({
            activeType:pageIndex,
        });
    }
    renderActionType(){
        return(
            <div>
                <h4> Action Type </h4>
                <hr />
                <Row>
                    <Col md={1}>
                    </Col>
                    <Col md={1}>
                        <Label check>
                        <Input type="radio" name="radio1" checked={this.state.activeType === '1'}  onClick={() => { this.typeToggle('1'); }}/>{' '}
                            Alert
                        </Label>
                    </Col>
                    <Col md={1}>
                        <Label check>
                        <Input type="radio" name="radio1" checked={this.state.activeType === '2'}  onClick={() => { this.typeToggle('2'); }}/>{' '}
                            Grouping
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                    {this.state.activeType =='1' && <div>
                        {/* content 1 */}
                    </div>}
                    {this.state.activeType =='2' && <div>
                        {/* content 2 */}
                    </div>}
                    </Col>
                </Row>
            </div>
        );
    }
// -------------------------------------------------------------------------------------------------------------------------------
    deviceToggle = (tab) => {
        // this.setState({
        //     activeDevice:pageIndex,
        // });

        if (this.state.activeDevice !== tab) {
            this.setState({
                activeDevice: tab
            });
        }
    }

    handleDeviceAddOnClick = (assetid, assetname, deviceid) => {
        if (this.state.currentAction.deviceid.length > 0 && this.state.currentAction.assetid != assetid) {
            alert('have to choose a device in the same asset');
            return;
        }
    
        if (!this.state.currentAction.deviceid.includes(deviceid)){ 
            this.setState(prevState =>{ 
                if (prevState.currentAction.deviceid.length == 0) {
                    prevState.currentAction.assetid = assetid;
                    prevState.currentAction.assetname = assetname;
                }
                prevState.currentAction.deviceid.push(deviceid);
                let deviceList = prevState.currentAction.deviceid.slice();
                let actionDeviceList = [];
                deviceList.forEach((item) => {
                    let obj = {
                        deviceid: item, 
                        devicename: '',
                        assetid: prevState.currentAction.assetid,
                        assetname: prevState.currentAction.assetname,
                    };
                    actionDeviceList.push(obj);
                });
                return ({
                    currentAction: prevState.currentAction,
                    actionDeviceList: actionDeviceList,
                })
            });
        } 
    }

    handleDeviceRowDeleteClick = (assetid, deviceid) => {
        this.setState(prevState =>{ 
            let movedList = prevState.currentAction.deviceid.filter(item => (item != deviceid));
            prevState.currentAction.deviceid = movedList;
            // let deviceList = prevState.currentAction.deviceid.slice();
            let actionDeviceList = [];
            if (movedList.length == 0){
                prevState.currentAction.assetid = 0;
                prevState.currentAction.assetname = '';
            }
            movedList.forEach((item) => {
                let obj = {
                    deviceid: item, 
                    devicename: '',
                    assetid: prevState.currentAction.assetid,
                    assetname: prevState.currentAction.assetname,
                };
                actionDeviceList.push(obj);
            });
            return ({
                currentAction: prevState.currentAction,
                actionDeviceList: actionDeviceList,
            })
        });
    }

    renderActionDevice(){
        return(
            <div>
                <h4>Action Items</h4>
                <hr />
                {/* <Row>
                    <Col md={1}>
                    </Col>
                    <Col md={1}>
                        <Label check>
                        <Input type="radio" name="radio2" />{' '}
                            Device
                        </Label>
                    </Col>
                    <Col md={1}>
                        <Label check>
                        <Input type="radio" name="radio2" />{' '}
                            Asset
                        </Label>
                    </Col>
                </Row> */}
                {/* <Nav tabs>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeDevice === '1' })}
                        onClick={() => { this.deviceToggle('1'); }}
                        >
                            Device
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                        className={classnames({ active: this.state.activeDevice === '2' })}
                        onClick={() => { this.deviceToggle('2'); }}
                        >
                            Asset
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeDevice}>
                    <TabPane tabId="1">
                        <Row>
                        <Col sm="12">
                            <DeviceSetSection ref={this.refDeviceSection} actionId={this.props.actionId} actionList={this.state.actions} />
                        </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                        <Col sm="12">
                           
                        </Col>
                        </Row>
                    </TabPane>
                </TabContent> */}

                 <Row>
                        <Col md={12}>
                            <DeviceSetSection ref={this.refDeviceSection} 
                                                actionId={this.props.actionId} 
                                                actionList={this.state.actionDeviceList} 
                                                handleAddOnClick = {this.handleDeviceAddOnClick}
                                                handleRowDeleteClick = {this.handleDeviceRowDeleteClick}
                            />
                        </Col>
                </Row>

            </div>
        )
    }
// ---------------------------------------------------------------------------------------------------------------------------------
    handleGeofenceOnChange = (e) => {
        this.setState({
            geofenceChecked:e.target.checked,
        });
    }
    handleFenceRowDeleteClick = (row) => {
        // alert(row.fenceid);
        //  
        let newAction = Object.assign({},this.state.currentAction);
        newAction.fenceid = 0;
        let actionFence = this.props.allfences.filter(item => item.fenceid== 0);
        let allfences = this.props.allfences.filter(item => item.fenceid != 0);
        this.setState({
            currentAction: newAction,
            // actionFence:[],
            actionFence: actionFence,
            allfences: allfences,
        });
    }
    handleFenceAddClick = (fenceid) =>{
        if (fenceid == null) {
            alert('Please select a geofence first.');
            return;
        }
        if (this.state.actionFence.length > 0) {
            alert('Please delete the existing geofence first.');
            return;
        }
        let newAction = Object.assign({},this.state.currentAction);
        newAction.fenceid = fenceid;
        let actionFence = this.props.allfences.filter(item => item.fenceid==fenceid);
        let allfences = this.props.allfences.filter(item => item.fenceid != fenceid);
        this.setState({
            currentAction: fenceid,
            actionFence: actionFence,
            allfences: allfences,
        });
    }
    renderActionGeofence(){
        // let actionFence = []
        // // if (this.state.actions && this.state.actions.length > 0  && this.props.actionId && this.props.allfences){
        // //     const fenceid = this.state.actions[0].fenceid;
        // //     actionFence = this.props.allfences.filter(item => item.fenceid==fenceid);
        // // }
        // if (this.state.currentAction && this.props.allfences){
        //     const fenceid = this.state.currentAction.fenceid;
        //     actionFence = this.props.allfences.filter(item => item.fenceid==fenceid);
        // }
        return(
            <div >
                 <Input type="checkbox" checked = {this.state.geofenceChecked} onClick = {this.handleGeofenceOnChange}/>{' '}
                <h4>Geofence
                {/* <Input type="checkbox"/> {' '} */}
                </h4>
                {/* <Label check>
                    <Input type="checkbox" />{' '}
                    <h4>Check me out</h4>
                </Label> */}
                <hr />

                {this.state.geofenceChecked && 
                    // <Col md={12}>
                        <GeofenceSection allfences={this.state.allfences} 
                                            actionId={this.props.actionId} 
                                            actionList={this.state.actions} 
                                            actionfence = {this.state.actionFence} 
                                            handleRowDeleteClick = {this.handleFenceRowDeleteClick}
                                            onFenceAddClick = {this.handleFenceAddClick}
                        />
                    // </Col>
                }
            </div>
        )
    }

// ---------------------------------------------------------------------------------------------------------------------------------
    handleRuleOnChange = (e) => {
        this.setState({
            ruleChecked:e.target.checked,
        });
    }

    handleRuleRowDeleteClick = (row) => {
        // alert(row.ruleid);
        let newAction = Object.assign({},this.state.currentAction);
        newAction.ruleid = 0;
        let actionRule = this.props.allrules.filter(item => item.ruleid== 0);
        let allrules = this.props.allrules.filter(item => item.ruleid != 0);
        this.setState({
            currentAction: newAction,
            // actionRule:[],
            actionRule: actionRule,
            allrules: allrules,
        });
    }

    handleRuleAddClick = (ruleid) =>{
        if (ruleid == null) {
            alert('Please select a rule first.');
            return;
        }
        if (this.state.actionRule.length>0) {
            alert('Please delete the existing rule first.');
            return;
        }
        let newAction = Object.assign({},this.state.currentAction);
        newAction.ruleid = ruleid;
        let actionRule = this.props.allrules.filter(item => item.ruleid==ruleid);
        let allrules = this.props.allrules.filter(item => item.ruleid != ruleid);
        this.setState({
            currentAction: ruleid,
            actionRule: actionRule,
            allrules: allrules,
        });
    }

    renderActionRule(){
        // let actionRule = []
        // if (this.state.actions && this.state.actions.length > 0  && this.props.actionId && this.props.allfences){
        //     const ruleid = this.state.actions[0].ruleid;
        //     actionRule = this.props.allrules.filter(item => item.ruleid==ruleid);
        // }
        return(
            <div>
                <Input type="checkbox" checked = {this.state.ruleChecked} onClick = {this.handleRuleOnChange} />{' '}
                <h4>Rule</h4>
                <hr />
                {this.state.ruleChecked && 
                    <RuleSection allrules={this.state.allrules} 
                                    actionId={this.props.actionId} 
                                    actionList={this.state.actions} 
                                    actionrule={this.state.actionRule} 
                                    handleRowDeleteClick = {this.handleRuleRowDeleteClick}
                                    onRuleAddClick = {this.handleRuleAddClick}
                    />
                }
            </div>
        )
    }

    // -------------------------------------------------------------------------------------------------------------------------------
    handleSubmitClick = () => {
    }

    renderButtonGroup(){
        return(
            <div>
                <Row>
                        <Col md={12} style={{ textAlign: 'right' }}>
                                <br/>
                                <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleCancleClick}>
                                    Cancel
                                </Button> {' '}
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleSubmitClick}>
                                    Submit
                                </Button>
                           </Col>
                        </Row>
            </div>
        )
    }
    render(){
        return(
            <Card>
                <CardBody>
                    {this.renderActionType()}
                    {this.renderActionDevice()}
                    {this.renderActionGeofence()}
                    {this.renderActionRule()}
                    {this.renderButtonGroup()}
                </CardBody>
            </Card>
        )
    }
}

// export default ActionEditForm;

function mapStateToProps(state) {
     
     
     
    const allfences = state.fence.fenceData;
    const allrules = state.rule.rules;
    const props = {...state.job, allfences, allrules};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff, loadAllGeoFences, getRuls}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionEditForm);