/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";

const BackToAction = ({ title, route, onClick }) => (
  <Button>
    <NavLink
      to={route}
      onClick={onClick}
      activeClassName="sidebar__link-active"
      style={{ color: "black" }}
    >
      {title}
    </NavLink>
  </Button>
);

BackToAction.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
};
BackToAction.defaultProps = {
  icon: "",
  newLink: false,
  route: "/",
  onClick: () => {},
};

export default BackToAction;
