/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import Select from 'react-select';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';
import {
    // loadDevice, 
    loadAssetClass,   
    getAssetClassAttribute,
    clearAssetClassAttribute
} from '../../../redux/actions/mapActions';
import {
    getAssetList,
    // addDashBoardLayout,
} from '../../../redux/actions/dashboardActions';
import {
    getAttributes,
    clearAttributes,
} from '../../../redux/actions/attributeMgmtAction';
import { toast } from "react-toastify";
import {UserContext} from '../../../constants/myContexts';
import { isStringEmpty, groupBy } from '../../../factories/utils';

class AssetDataEditElement extends Component {
    static contextType = UserContext;
    constructor(props){
        super(props);
        this.state={
            tital: 'Dashboard Element',
            currentAssetClassId: null,
            currentAssetId: null,
            currentAttrList: null,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
            devices: [],
            currentlayoutId:null,
        }
    }

    componentDidMount(){
        const  {userObj, updateUserObj} = this.context;
         
        this.props.loadAssetClass();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.layout ){ //&& nextProps.layout !== this.state.layout
            if (nextProps.layout.parameter){
                let parameter = JSON.parse(nextProps.layout.parameter);
                if (parameter.assetclassid && parameter.assetclassid != this.state.rawassetclassid) {
                    nextProps.getAssetList({assetclassid: parameter.assetclassid});
                    nextProps.getAssetClassAttribute({assetclassid: parameter.assetclassid});
                    this.setState({
                        currentAssetClassId: parameter.assetclassid,       
                        rawassetclassid: parameter.assetclassid,                  
                    }, ()=>{});
                }
                if (parameter.assetid && parameter.assetid != this.state.rawassetid){
                    this.setState({
                        currentAssetId: parameter.assetid, 
                        rawassetid: parameter.assetid,                      
                    });
                }
                if (nextProps.assetClassAttr && nextProps.assetClassAttr.length > 0 && nextProps.assetClassAttr[0].atrributes) {
                    if (parameter.attributes && nextProps.layout.parameter != this.state.rawparameter){
                        // currentAttrList = selectedOption.map(item=>({attrid: item.attributeid, attributename:item.attributename}));
                        let selectedOption = nextProps.assetClassAttr[0].atrributes.filter(item => (parameter.attributes.some(attr => (attr.attrid == item.attributeid && attr.attributename==item.attributename))));
                        this.setState({
                            selectedOption: selectedOption,
                            currentAttrList: parameter.attributes,
                            rawparameter: nextProps.layout.parameter
                        });
                    } 
                }
               

            }
            
            if (nextProps.layout.layoutid !== this.state.currentlayoutId) {
                
                this.setState({
                    layout: nextProps.layout,
                    currentlayoutId: nextProps.layout.layoutid           
                }, ()=>{});
            }
        }
    }
// --------------------------------------------------------------
    handleInputChange =(newVal) => {
        // this.setState((prevState)=>{
        //     return ({
        //         currentName: newVal,
        //         // layout: prevState.layout,
        //     });
        // }, ()=>{
        //      
        //      
        // });
        this.setState((prevState)=>{
            if (prevState.layout){
                prevState.layout.name = newVal;
                return ({
                    currentName: newVal,
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        }, ()=>{
             
        });
    }
    renderTitle =()=> {
        return(
            
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                        type='text'
                        defaultValue={this.state.layout ? this.state.layout.name : ''} 
                        // defaultValue={this.state.currentName ? this.state.currentName : ''} 
                        exitOnEnterKeyPress={false}
                        onSave={newVal => { this.handleInputChange(newVal)}}
                    />
                </Col>
            </Row>           
        )
    }
// --------------------------------------------------------------
    onAssetClassChange = (e) => {
         
        let assetClassId = e.target.value;
        this.setState({
            currentAssetClassId: assetClassId,
            currentAssetId: null,
            devices:[],
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
            devices: [],
        });
        this.props.getAssetList({assetclassid: assetClassId});
        this.props.getAssetClassAttribute({assetclassid: assetClassId});
        //  
        // this.props.clearAttributes();
    }
    renderAssetClass = () => {
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="assetclass" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="assetclass" name="assetclass" width="100%" value={this.state.currentAssetClassId} onChange={this.onAssetClassChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={0}>NONE</option> 
                        {this.props.assetClasses && this.props.assetClasses.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }
// --------------------------------------------------------------
    onAssetChange = (e) => {
        let assetId = e.target.value;
        this.setState({
            currentAssetId: assetId,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
        }, () => {
            // this.setDeviceList(this.props.assets);
        });
        // this.props.clearAttributes();
    }
    renderAsset = () => {
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="asset" name="asset" width="100%" value={this.state.currentAssetId} onChange={this.onAssetChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={0}>NONE</option> 
                        {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }
// --------------------------------------------------------------
    // onAttrChange = (e) => {
    //      
    //     let index = e.target.selectedIndex
    //     this.setState({
    //         currentAttrId: e.target.value,
    //         currentAttrName: e.target.options[index].text
    //     });
    // }
    onAttrChange = selectedOption => {
        let currentAttrList = selectedOption.map(item=>({attrid: item.attributeid, attributename:item.attributename}));
        this.setState({
            selectedOption: selectedOption,
            currentAttrList: currentAttrList
        });
    }

    renderAttribute = () => {
         
        let attributeOptions = [];
        if (this.props.assetClassAttr && this.props.assetClassAttr.length > 0 && this.props.assetClassAttr[0].atrributes) {
            let attributeMap = groupBy(this.props.assetClassAttr[0].atrributes, attr => attr.devicetypename);
            for (let [key, value] of attributeMap) {
                let group = {
                    label: key,
                    options: value,
                }
                attributeOptions.push(group);
            }
        }
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="attribute" style={{display: 'inline', width: "100%"}} ><h4><b>Attribute</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Input type="select" id="attribute" name="attribute" width="100%" value={this.state.currentAttrId} onChange={this.onAttrChange} >                                    
                        <option value={0}>NONE</option> 
                        {this.props.attributes && this.props.attributes.map((attr, i) => (<option key={attr.attributeid} value={attr.attributeid}>{attr.attributename}</option>))}
                    </Input> */}
                    <Select 
                        isMulti
                        isClearable
                        isSearchable
                        options = {attributeOptions}
                        getOptionLabel={({ attributename }) => attributename}
                        getOptionValue={({ attributeid }) => attributeid}
                        value={this.state.selectedOption}
                        onChange={this.onAttrChange}
                    />
                </Col>
            </Row>
        )
    }
// --------------------------------------------------------------
    onCancleClick = (e) => {
        this.props.toggle();
    }

    validateInput(){
        // let isValidated = true;
        let hasUser = this.context != undefined && this.context != null && this.context.userObj != undefined && this.context.userObj != null;
        if (!hasUser){
             
            toast.error("Can not get user infomation");
        }
        let isInput = !isStringEmpty(this.state.currentName) 
        let isSelect = this.state.currentAssetId != null && this.state.currentAssetId != 0 && this.state.currentAssetClassId != null && this.state.currentAssetClassId != 0;
        let isTooLong = this.state.currentAttrList != undefined && this.state.currentAttrList != null && this.state.currentAttrList.length > 4;
        if (!isInput || !isSelect){
             
            toast.error("Input is not valid");
        }
        if (isTooLong) {
             
            toast.error("Max attributes are 4");
        }
         
         
        return hasUser && isInput && isSelect && !isTooLong;
    }
    // onOkClick = (e) => {
    //     //  
    //     if (!this.validateInput()) {
    //         //  
    //         return;
    //     }
    //     //  
    //     let position = {w:3,h:7,x:8,y:8};
    //     let parameter = {
    //         assetclassid: this.state.currentAssetClassId,
    //         assetid:this.state.currentAssetId, 
    //         attributes: this.state.currentAttrList ? this.state.currentAttrList : [],
    //     };
    //     let layout = {
    //         name: this.state.currentName,
    //         type: "AssetElement",
    //         width: 9,
    //         locked: 0,
    //         display: 1,
    //         position: JSON.stringify(position),
    //         parameter: JSON.stringify(parameter),
    //         username: this.context.userObj.userName,
    //         createdby: this.context.userObj.userName,
    //         companycode: this.context.userObj.companyCode,
    //         // createdtime: "2019-02-04T03:28:50.000+0000",
    //         createdtime: new Date().toUTCString(),
    //     }
    //      
    //     this.props.addDashBoardLayout(layout);
    //     this.props.toggle();
    //     // this.props.parentToggle();
    // }

    onOkClick = (e) => {
        this.setState((prevState)=>{
            if (prevState.layout){
                let parameter = {
                    assetclassid: prevState.currentAssetClassId,
                    assetid: prevState.currentAssetId,
                    attributes: prevState.currentAttrList ? prevState.currentAttrList : [],
                }
                prevState.layout.parameter = JSON.stringify(parameter);
                return ({
                    layout: prevState.layout,
                })
            }else{
                return null;
            }
        }, ()=>{
            this.props.onEditSubmit(this.state.layout);
        });
        // this.props.onEditSubmit(this.state.layout);
        this.props.toggle();
    }

// --------------------------------------------------------------
    render(){
        return (
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                size = {'lg'}
            >
                 <ModalHeader 
                    toggle={this.props.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody>
                    {/* <CardTitle>Set Attribute Value</CardTitle> */}
                    {this.renderTitle()}
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAssetClass()}
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAsset()}
                        </Col>
                    </Row>
                    {/* <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderDevice()}
                        </Col>
                    </Row> */}
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAttribute()}
                        </Col>
                    </Row>
                    {/* <hr/>
                    <div style={{textAlign: 'right'}}>
                    <Button color="custom" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                    </div> */}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
        ); 
    }
}
function mapStateToProps(state) {
    // const types = state.mapboard.types;
    const assets = state.dashboard.assetList;
    const assetClasses = state.mapboard.assetClass;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const attributes = state.attributemgmt.attributes;
    const props = {
        // types,  
        assetClasses,
        assetClassAttr,
        assets,
        attributes,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            // loadDevice, 
            loadAssetClass,
            getAssetClassAttribute,
            clearAssetClassAttribute,
            getAssetList,
            getAttributes,
            clearAttributes,
            // addDashBoardLayout,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetDataEditElement);