/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { findResellerCodeByContract } from '../../../../redux/actions/inventoryActions'
import './InventoryItemModal.css';
import moment from 'moment';

const currencyoptions = [
    {value: "USD" , label: "USD"},
    {value: "CAD" , label: "CAD"}
];

class InventoryItemModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            validBillMonth: null,
            valideInitialMonth: null
        };
        this.props.findResellerCodeByContract(this.props.data['Contract']);
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show === true) {
            this.setState({
                showModal: true,
                validBillMonth: moment(moment(`${nextProps.data['Bill month']}-01`, "YYYY-MM-DD")).format("YYYY-MM-DD"),
                valideInitialMonth: moment(moment(`${nextProps.data['Initial month']}-01`, "YYYY-MM-DD")).format("YYYY-MM-DD"),
                validOrder: nextProps.data['Order'],
                validOrderExternalName: nextProps.data['Order external name'],
                validContract: nextProps.data['Contract'],
                validContractExternalName: nextProps.data['Contract external name'],
                validFeeItem: nextProps.data['Fee item'],
                validFeeItemOrigin: nextProps.data['Fee item origin'],
                validPricePerItem: nextProps.data['Price per item'],
                validNumberOfItems: nextProps.data['Number of items'],
                validCustomerFeeAmount: nextProps.data['Customer fee amount'],
                validCurrency: nextProps.data['Currency'],
            })
        }
        else if(nextProps.show === false){
            this.setState({
                showModal: false
            })
        }
    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onServicePlanInputChange = (event) => {
        console.log(`ServicePlan: ${event.target.value}`)
        const servicePlanItem = this.props.ServicePlanList
                                    .filter(servicePlan => servicePlan.itemName === event.target.value);
        console.log('servicePlanItem', servicePlanItem)

        let { validNumberOfItems, validPricePerItem, validCustomerFeeAmount } = this.state;

        // get number of items
        let numberOfItems = parseInt(validNumberOfItems)
        console.log('numberOfItems', numberOfItems)
        
        validPricePerItem = servicePlanItem[0].unitPrice
        console.log('validPricePerItem', servicePlanItem[0].unitPrice)

        validCustomerFeeAmount = numberOfItems * validPricePerItem
        
        validPricePerItem = validPricePerItem + ''
        validCustomerFeeAmount = validCustomerFeeAmount + ''
        
        console.log('validCustomerFeeAmount', validCustomerFeeAmount)

        // also change price per item
        // also change customer fee amount
        
        this.setState({
            [event.target.name]: event.target.value, 
            validFeeItemOrigin: servicePlanItem[0].itemDescription,
            validPricePerItem,
            validCustomerFeeAmount })
    };

    validateForm = () => {
        const { validBillMonth, valideInitialMonth, validOrder, 
            validOrderExternalName, validContract, validContractExternalName,
            validFeeItem, validFeeItemOrigin, validPricePerItem,
            validNumberOfItems, validCustomerFeeAmount, validCurrency } = this.state;

        if (validBillMonth && valideInitialMonth && validOrder && 
            validOrderExternalName && validContract && validContractExternalName &&
            validFeeItem && validFeeItemOrigin && validPricePerItem &&
            validNumberOfItems && validCustomerFeeAmount && validCurrency) {
            return true;
        }
        return false;
    }
    onSubmit = () => {
        const { validBillMonth, valideInitialMonth, validOrder, 
            validOrderExternalName, validContract, validContractExternalName,
            validFeeItem, validFeeItemOrigin, validPricePerItem,
            validNumberOfItems, validCustomerFeeAmount, validCurrency } = this.state;

        const { data, onSave, onClose } = this.props

        const inventoryData = {
            "Bill month": validBillMonth,
            "Initial month": valideInitialMonth,
            "Order": validOrder,
            "Order external name": validOrderExternalName,
            "Contract": validContract,
            "Contract external name": validContractExternalName,
            "Fee item": validFeeItem,
            "Fee item origin": validFeeItemOrigin,
            "Price per item": validPricePerItem,
            "Number of items": validNumberOfItems,
            "Customer fee amount": validCustomerFeeAmount,
            "Currency": validCurrency,
            "Index": data['Index'],
            "Operations": data['Operations'],
        }

        onSave(inventoryData);
        onClose();
    }

    render() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.props.onClose}>Edit Inventory Item</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Bill Month <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={10}>
                                <Input
                                    type="date"
                                    name='validBillMonth'
                                    id='validBillMonth'
                                    placeholder='Bill Month'
                                    // onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validBillMonth}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Initial Month <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={10}>
                                <Input
                                    type="date"
                                    name='valideInitialMonth'
                                    id='valideInitialMonth'
                                    placeholder='Initial month'
                                    //onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.valideInitialMonth}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Order <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={10}>
                                <Input
                                    name='validOrder'
                                    id='validOrder'
                                    placeholder='Order'
                                    //onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validOrder}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={3}>
                                <span>Order external name<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={9}>
                                <Input
                                    name='validOrderExternalName'
                                    id='validOrderExternalName'
                                    placeholder='Order external name'
                                    //onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validOrderExternalName}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Contract<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={10}>
                                <Input
                                    name='validContract'
                                    id='validContract'
                                    placeholder='Contract'
                                    //onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validContract}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={4}>
                                <span>Contract external name<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='validContractExternalName'
                                    id='validContractExternalName'
                                    placeholder='Contract'
                                    //onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validContractExternalName}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        {/* Changing fee item to a dropdown select */}
                        <Row>
                            <Col md={2}>
                                <span>Fee item<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={10}>
                                <Input
                                    type='select'
                                    name='validFeeItem'
                                    id='validFeeItem'
                                    placeholder='Fee item'
                                    onChange={this.onServicePlanInputChange}
                                    className="form-control"
                                    value={this.state.validFeeItem}
                                >
                                    {
                                        this.props.ServicePlanList 
                                            && this.props.ServicePlanList.length > 0
                                            && this.props.ServicePlanList.map((servicePlan, index) =>
                                            (<option 
                                                key={index} 
                                                value={servicePlan.itemName}>{servicePlan.itemName}
                                            </option>))
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={3}>
                                <span>Fee item origin<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={9}>
                                <Input
                                    disabled
                                    name='validFeeItemOrigin'
                                    id='validFeeItemOrigin'
                                    placeholder='Fee item origin'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validFeeItemOrigin}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={3}>
                                <span>Price per item<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={9}>
                                <Input
                                    name='validPricePerItem'
                                    id='validPricePerItem'
                                    placeholder='Price per item'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validPricePerItem}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={3}>
                                <span>Number of items<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={9}>
                                <Input
                                    name='validNumberOfItems'
                                    id='validNumberOfItems'
                                    placeholder='Number of items'
                                    //onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validNumberOfItems}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={3}>
                                <span>Customer fee amount<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={9}>
                                <Input
                                    name='validCustomerFeeAmount'
                                    id='validCustomerFeeAmount'
                                    placeholder='Customer fee amount'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validCustomerFeeAmount}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Currency<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={10}>
                                <Input
                                    type='select'
                                    name='validCurrency'
                                    id='validCurrency'
                                    placeholder='Currency'
                                    //onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.validCurrency}
                                    disabled
                                >
                                    {
                                        currencyoptions.map((currency, index) =>
                                            (<option 
                                                key={index} 
                                                value={currency.value}>{currency.label}
                                            </option>))
                                    }
                                </Input>
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='secondary' type="button" onClick={this.onSubmit} disabled={!this.validateForm()} >
                        submit
                    </Button>

                    <Button outline color='secondary' type="reset" onClick={this.props.onClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let { ServicePlanList } = state.user;
    let props = { ServicePlanList };
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ findResellerCodeByContract }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryItemModal);