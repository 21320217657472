/* eslint-disable */
import React, { Component } from 'react';
import { ChromePicker} from 'react-color';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { ENGINE_METHOD_DIGESTS } from 'constants';

class AttributeEditModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            isNew: true,
            btnDisable: false,
            nameDisable: false,
            typeDisable: false,
            tital: 'tetsstest',
            attributename: '',
            displayname: '',
            datatype: '',
            attribute: {},
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     alert('yes get');
    //     return({
    //         // currentDeviceTypeId : props.currentDeviceTypeId,
    //         // currentDeviceTypeName : props.currentDeviceTypeName,
    //         // currentDeviceId : props.currentDeviceId,
    //         // currentDeviceName : props.currentDeviceName,
    //     });
    // } 

    componentWillReceiveProps(nextProps){
        // alert('yes');
        if (nextProps.isNew){
            this.setState({
                isNew: nextProps.isNew,
                nameDisable: nextProps.isNew,
                typeDisable: nextProps.isNew,
                tital: 'New Attribute',
                attributename: '',
                displayname: '',
                attribute: nextProps.attribute
            });
        }else{
            this.setState({
                isNew: nextProps.isNew,
                nameDisable: nextProps.attribute.useradded,
                typeDisable: nextProps.attribute.useradded,
                tital: nextProps.attribute.attributename,
                attributename: nextProps.attribute.attributename,
                displayname: nextProps.attribute.displayname,
                datatype:  nextProps.attribute.attributetype,
                attribute: nextProps.attribute
            });
        }
    }

    // ---------------------------------------------------------------------------
    // handleNameOnchange =(e) => {
    //     // this.props.onNameChange(e);
    //     let btnDisable = (e.target.value && e.target.value.trim().length>0);
    //     this.setState({
    //         name: e.target.value,
    //         btnDisable: btnDisable
    //     });
    // }

    // handleDeviceTypeDropdownChange = (e) => {
    //     this.props.handleDeviceTypeDropdownChange(e);
    // }

    // handleDeviceDropdownChange = (e) => {
    //     this.props.handleDeviceDropdownChange(e);
    // }

    // handleDeviceTypeDropdownChange = (e) => {
    //     let selectedValue = e.target.value;
    //     let index = e.target.selectedIndex;
    //     let name = e.target.options[index].text;
    //     // alert(name);
    //     this.props.loadRuleDevice({devicetype: e.target.value});
    //     this.setState(
    //         {
    //             currentDeviceTypeId: selectedValue,
    //             currentDeviceTypeName: name                
    //         }
    //     )
    // }

    // handleDeviceDropdownChange = (e) => {
    //     alert('test');
    //     let selectedValue = e.target.value;
    //     let index = e.target.selectedIndex;
    //     let name = e.target.options[index].text;
    //     // alert(name);
    //     this.setState(
    //         {
    //             currentDeviceId: selectedValue,
    //             currentDeviceName: name                
    //         }
    //     )
    // }
    
    onAddClick = (e) => {
        let attriObj = this.state.attribute;
        attriObj.displayname = this.state.displayname;
        if (this.state.isNew){
            attriObj.attributename = this.state.attributename;
            attriObj.attributetype = this.state.datatype;
        }
        // this.props.onAddClick(this.state.attributename, this.state.displayname, this.state.datatype);
        this.props.onAddClick(attriObj);
        this.setState({
            name: '',
            btnDisable: false,
        });
        this.props.toggle();
    }

    onCancleClick = (e) =>{
        this.setState({
            name: '',
            btnDisable: false,
        });
        this.props.toggle();
    }

    onNameClick = (e) => {
        let btnDisable = (e.target.value && e.target.value.trim().length>0);
        this.setState({
            attributename: e.target.value,
            btnDisable: btnDisable,
        });
    }

    onDisplayNameClick = (e) => {
        let btnDisable = (e.target.value && e.target.value.trim().length>0);
        this.setState({
            displayname: e.target.value,
            btnDisable: btnDisable,
        });
    }

    onDataTypeChange = (e) => {
        this.setState({
            datatype: e.target.value,
        });
    }

    render(){
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle}>
                <ModalHeader toggle={this.toggle}>{this.state.tital}</ModalHeader>
                <ModalBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="rulename" >Attribute Name</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="text" id="attributename" name="attributename" width="90%" disabled={!this.state.nameDisable} value={this.state.attributename} onChange={this.onNameClick} >                                    
                                </Input>
                            </Col>
                    </Row>     

                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="rulename" >Display Name</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="text" id="displayname" name="displayname" width="90%" value={this.state.displayname} onChange={this.onDisplayNameClick} >                                    
                                </Input>
                            </Col>
                    </Row>     

                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="ruledivicetype" >Data Type</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="select" id="ruledivicetype" name="ruledivicetype" width="90%" disabled={!this.state.typeDisable} value={this.state.datatype} onChange={this.onDataTypeChange}>        
                                    {/* {this.props.currentDeviceTypeList && this.props.currentDeviceTypeList.map((type,i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))} */}
                                    <option value={'Int'}>Integer</option>
                                    <option value={'Double'}>Decimal</option>
                                    <option value={'Boolean'}>Boolean</option>
                                    <option value={'Date'}>Date</option>
                                    <option value={'String'}>String</option>
                                </Input>
                            </Col>
                    </Row>    
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="devicename" >Device</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="select" id="devicename" name="devicename" width="90%" value={this.props.currentDeviceId} onChange={this.handleDeviceDropdownChange} >             
                                    <option value = {0}>All</option>                
                                        {this.props.currentDeviceList.map((item,i) => {
                                            return(
                                                <option key={i} value={item.deviceid}>{item.devicename}</option>
                                            )
                                        })}                                  
                                </Input>
                            </Col>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button outline color="custom" onClick={this.onAddClick} disabled={!this.state.btnDisable}>Add</Button>{' '}
                    <Button outline color="secondary" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }
}

export default AttributeEditModal;

