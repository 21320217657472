/* eslint-disable */
import React, { Component, Fragment, useState, useEffect } from 'react';
import { Container, Card, CardBody, CardFooter, Row, Col, Button, Label, UncontrolledTooltip } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
// import BatteryWidget from './BatteryWidget';
import { TIME_ZONE, GREEN_COLOR, RED_COLOR, GREY_COLOR, ATTRIBUTE_ORDER, OPG_KEYS } from '../../../constants/dataConstants';
import { lightningColorMaper } from '../../../factories/weather';
import { data } from 'jquery';

const valueRowStyle = {
  margin: '5px 1px',
  padding: '2px 1px',
  justifyContent: 'center'
}

const AttributeListWidget = ({ alarmSet, data, isSmall, toggle, activeTab}) => {
  // if(asset.assetid != null) {
  //   let data = asset;
  //   let deviceid = null;
  //   // data.attributes['assetid'].sort((a, b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
  //   // asset.factorDeviceId = data.attributes['assetid'][0].deviceid;
  // }
  // console.log("===data.assetname", asset);
  let alarmObj = alarmSet ? alarmSet[asset.assetid] : null;
  const assetNameFormat = (cell, toggle) => {
    const handleDeviceOnClick = (e, assetname) => {
      e.preventDefault();
      toggle(true, cell);
    }
  }
  
  const messageIdFormat = (cell) => {
    if(cell) {
      return (
          <h5>
            Message ID:
            <br/>
            {cell.messageId}
          </h5>
          
  
      )
    } else {
      return (
        <h5>No Message Received</h5>
      )
    }
  }
  
  const beaconVisibleFormat = (cell) => {
    if(cell) {
      return (
        <h5>
          Beacon Visible:
          <br/>
          {cell.beaconVisible}
        </h5>
      )
    } else {
      return null
    }
  }
  
  const beaconIdFormat = (cell) => {
    if(cell) {
      return (
        <h5>
          Beacon ID:
          <br/>
          {cell.beaconId}
        </h5>
      )
    } else {
      return null
    }
  }
  
  const cn0OkFormat = (cell) => {
    if(cell) {
      return (
        <h5>
          CN0_OK:
          <br/>
          {cell.cn0_ok}
        </h5>
      )
    } else {
      return null
    }
  }
  
  const gpsLockFormat = (cell) => {
    if(cell) {
      return (
        <h5>
          GPS Lock:
          <br/>
          {cell.gpsLock}
        </h5>
      )
    } else {
      return null
    }
  }
  
  const batteryFormat = (cell) => {
    if(cell) {
      return (
        <h5>
          Battery:
          <br/>
          {cell.battery}%
        </h5>
      )
    } else {
      return null
    }
  }
  
  const hdopFormat = (cell) => {
    if(cell) {
      return (
        <h5>
          HDOP:
          <br/>
          {cell.hdop}
        </h5>
      )
    } else {
      return null;
    }
  }
  
  const moveDetectFormat = (cell) => {
    if(cell) {
      return (
        <h5>
          Move Detect:
          <br/>
          {cell.moveDetect}
        </h5>
      )
    } else {
      return null
    }
  }
  
  if(data){
    return (
      <div style={{ minWidth: '196px', maxWidth: '400px'}}>
        <Row style={valueRowStyle}>
            <h5 style={{fontSize: '1rem', fontWeight: "bold", lineHeight: '1rem', whiteSpace: 'nowrap', overflow: 'hidden'}}>{data.assetName}</h5>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{messageIdFormat(data)}</span>
            {/* <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{timestampFormat(asset.attributes.LastOccupancy[0].value)}</span> */}
        </Row>
        {<Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{data.messageId == "Beacon Visible" ? null : beaconVisibleFormat(data)}</span>
        </Row>}
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{data.messageId == "Beacon Visible" ? beaconIdFormat(data) : null}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{data.messageId == "Beacon Visible" ? null : cn0OkFormat(data)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{gpsLockFormat(data)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{data.messageId == "Beacon Visible" ? null :hdopFormat(data)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{moveDetectFormat(data)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{batteryFormat(data)}</span>
        </Row>
        {/* <BatteryWidget level={parseInt(batteryLevel(asset))} style={{ width: '23px', height: '45px' }}/> */}
      </div>
    )
  }
  else{
    return null
  }


}

export default AttributeListWidget;
