/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import { Card, CardBody, Col } from 'reactstrap';
import { Col, Container, Row, Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import { EditIcon } from 'mdi-react';
import {chunk} from '../../../factories/utils';
// import {getAssetsData, loadAssetList} from '../../../redux/actions/dashboardActions';
// import {getAssetsData} from '../../../redux/actions/mapActions';
// import FarmParamEditModal from './FarmParamEditModal';

class SubscribeValueWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataValue: 0,
        }
        this.eventSource = new EventSource("http://192.168.2.103:8080/sendRedisMessageTest/get");
         
    }

    componentDidMount() {
        // this.eventSource.onmessage = e =>
        //   this.updateFlightState(JSON.parse(e.data));
        // this.eventSource.onmessage = e =>
        //     { }

        this.eventSource.addEventListener("devicemessage", (e)=> {  this.updateFlightState(e.data)} )
    }

    componentWillUnmount(){
        this.stopUpdates();
    }

    updateFlightState(pubData) {
        // let newData = this.state.data.map(item => {
        //   if (item.flight === flightState.flight) {
        //     item.state = flightState.state;
        //   }
        //   return item;
        // });
    
        // this.setState(Object.assign({}, { data: newData }));
         
        this.setState({
            dataValue: pubData
        });
    }

    stopUpdates() {
        this.eventSource.close();
    }

    render(){
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                            {/* {this.props.dashName}  */}
                            Test
                            {/* {this.props.layout && 0 == this.props.layout.locked &&
                            <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>    
                            } */}
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                <CardBody style={{padding:15}}>  
                    <CardTitle>
                        <Row>
                            <Col md={8}>
                            {/* <span style={{textAlign: 'left', fontSize:'20px'}}>{label}</span> */}
                            <span style={{textAlign: 'left', fontSize:'20px'}}>Test</span>
                            </Col>
                            {/* <Col md={4}>
                            <span style={{textAlign: 'right', fontSize:'20px'}}>{sublabel}</span>
                            </Col> */}
                        </Row>
                    </CardTitle>
                    
                    <CardText style={{textAlign: 'center'}} ><span style={{fontSize:'50px'}}>{this.state.dataValue}</span></CardText>
                    {/* <CardText style={{textAlign: 'center'}} ><span style={{fontSize:'50px'}}>Test</span></CardText> */}
                    {/* <CardText style={{textAlign: 'right'}}>
                        <Col md={12}>
                        {cal ?
                            <span style={{fontSize:'20px'}}>{cal}</span>
                            : 
                            <br/>
                        }
                        
                        </Col>
                    </CardText> */}

                </CardBody>  
          </Card>
          </Fragment>
        );
    }

}

export default SubscribeValueWidget;