/* eslint-disable */
import React, { Component, Fragment, PureComponent } from 'react';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, FormText, } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { ALARM_META_ATTRI_OBJ, LIGHT_GREY_COLOR } from '../../../constants/dataConstants';
import Spinner from 'react-bootstrap/Spinner';
import { ThemeIcon } from 'mdi-react';
import { Callbacks } from 'jquery';
import moment from 'moment';

class WeatherMixChart extends Component {
    renderKey = 0;
    renderMixChart = () => {
        // const dataset1 = [
        //     {x:'2016-12-25T21:50:16.000+0000', y:20},
        //     {x:'2016-12-26T22:50:16.000+0000', y:30},
        //     {x:'2016-12-27T13:50:16.000+0000', y:50},
        //     {x:'2016-12-27T23:50:16.000+0000', y:50},
        //     {x:'2016-12-28T10:50:16.000+0000', y:40},
        //     {x:'2016-12-28T22:50:16.000+0000', y:40},
        //     {x:'2016-12-29T19:50:16.000+0000', y:10}
        // ];
        // const dataset2 = [
        //     {x:'2016-12-25', y:10},
        //     {x:'2016-12-26', y:40},
        //     {x:'2016-12-27', y:30},
        //     {x:'2016-12-28', y:20},
        //     {x:'2016-12-29', y:50}
        // ];
        // const chartdata1 = [
        //     {x:'2016-12-25', y:2},
        //     {x:'2016-12-26', y:3},
        //     {x:'2016-12-27', y:5},
        //     {x:'2016-12-28', y:4},
        //     {x:'2016-12-29', y:1},
        //     {x:'2016-12-30', y:6}
        // ];
        // const chartdata2 = [
        //     {x:'2016-12-25', y:1},
        //     {x:'2016-12-26', y:4},
        //     {x:'2016-12-27', y:3},
        //     {x:'2016-12-28', y:2},
        //     {x:'2016-12-29', y:5},
        //     {x:'2016-12-30', y:6}
        // ];
        // const chartdata3 = [
        //     {x:'2016-12-25', y:5},
        //     {x:'2016-12-26', y:3},
        //     {x:'2016-12-27', y:2},
        //     {x:'2016-12-28', y:1},
        //     {x:'2016-12-29', y:0},
        //     {x:'2016-12-30', y:6}
        // ];

        const { isSmall, isDays } = this.props;

        let datasets = []
        let isLightning = false;
        let labelRight = '';
        let labelLeft = '';
        let windAveA = '';
        let windMaxA = '';
        let windAveB = '';
        let windMaxB = '';
        if(this.props.attributea == "Wind_ave10") {
            windAveA = this.props.attributea;
        } else if(this.props.attributea == "Wind_max10") {
            windMaxA = this.props.attributea;
        };
        
        if(this.props.attributeb == "Wind_ave10") {
            windAveB = this.props.attributeb;
        } else if(this.props.attributeb == "Wind_max10") {
            windMaxB = this.props.attributeb;
        };


        if (this.props && this.props.attributea) {
            let attrDataSet = setChartData(this.props, this.props.attributea, 'left-y-axis'); //'rgba(255,99,132,1)'
            datasets = [...datasets, ...attrDataSet.dataSet];
            isLightning = attrDataSet.isLightning || isLightning;
            labelLeft = this.props.attributea;
        };


        if (this.props && this.props.attributeb) {
            if((windAveB || windMaxB) && (windAveA || windMaxA)) {
                let attrDataSet = setChartData(this.props, this.props.attributeb, 'left-y-axis'); //'rgba(99,132,255,1)'
                datasets = [...datasets, ...attrDataSet.dataSet];
                isLightning = attrDataSet.isLightning || isLightning;
                labelLeft = labelLeft + " + " + this.props.attributeb;
            } else{
                let attrDataSet = setChartData(this.props, this.props.attributeb, 'right-y-axis'); //'rgba(99,132,255,1)'
                datasets = [...datasets, ...attrDataSet.dataSet];
                isLightning = attrDataSet.isLightning || isLightning;
                labelRight = this.props.attributeb;
            }
        }
        datasets = datasets.filter((val) => {
            return val !== undefined
                && val !== null
                && typeof val === 'object'
                && val.hasOwnProperty('data');
        });


        // when switch on/off of two buttons several times (do not change to other buttons) there was a Getdataset -> _meta of undefined
        // did not know exact reason, but guess that may be because frequently update/change chartjs datasets, espacially, update multi dataset on both y-axis
        // so use {key} to force re-render <line> instead of updating. So far the issue is gone. 
        // this.renderKey = this.renderKey >= 10 ? 0 : this.renderKey + 1;


        const data = {
            // labels: chartlabel,
            datasets: datasets ? datasets : [],

            // datasets: [
            //     {
            //         label: this.props.attributea,
            //         // backgroundColor: 'rgba(255,99,132,0.2)',
            //         backgroundColor: 'white',
            //         borderColor: 'rgba(255,99,132,1)',
            //         pointRadius: 10,
            //         // borderWidth: 0.2,
            //         // barPercentage: 0.9,
            //         // categoryPercentage: 0.6,
            //         pointHoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //         pointHoverBorderColor: 'rgba(255,99,132,1)',
            //         yAxisID: 'left-y-axis',
            //         fill:  false,
            //         // data: chartdata1,
            //         data: dataset1,
            //     },
            //     // {
            //     //     label: this.props.attributeb,
            //     //     // backgroundColor: 'rgba(99,132,255,0.2)',
            //     //     backgroundColor: 'white',
            //     //     borderColor: 'rgba(99,132,255,1)',
            //     //     pointRadius: 0,
            //     //     // borderWidth: 0.2,
            //     //     // barPercentage: 0.9,
            //     //     // categoryPercentage: 0.6,
            //     //     pointHoverBackgroundColor: 'rgba(99,132,255,0.4)',
            //     //     pointHoverBorderColor: 'rgba(255,99,132,255,1)',
            //     //     yAxisID: 'right-y-axis',
            //     //     fill:  false,
            //     //     // data: chartdata1,
            //     //     data: dataset2,
            //     // },
            //     // {
            //     //     label: 'Alarm Orange',
            //     //     backgroundColor: 'rgba(99,255,100,0.2)',
            //     //     borderColor: 'rgba(255,99,132,1)',
            //     //     // borderWidth: 0.2,
            //     //     barPercentage: 0.9,
            //     //     categoryPercentage: 0.6,
            //     //     hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //     //     hoverBorderColor: 'rgba(255,99,132,1)',
            //     //     data: chartdata2
            //     // },
            //     // {
            //     //     label: 'Alarm Yellow',
            //     //     backgroundColor: 'rgba(99,255,255,0.2)',
            //     //     borderColor: 'rgba(255,99,132,1)',
            //     //     // borderWidth: 0.2,
            //     //     barPercentage: 0.9,
            //     //     categoryPercentage: 0.6,
            //     //     hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //     //     hoverBorderColor: 'rgba(255,99,132,1)',
            //     //     data: chartdata1
            //     // }
            //     {
            //         label: 'Alarm Red',
            //         // backgroundColor: 'rgba(255,99,132,0.2)',
            //         backgroundColor: 'rgba(255,0,0,0.7)',
            //         borderColor: 'rgba(255,99,132,1)',
            //         // borderWidth: 0.2,
            //         barPercentage: 0.9,
            //         categoryPercentage: 0.6,
            //         // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //         // hoverBorderColor: 'rgba(255,99,132,1)',
            //         type: 'bar',
            //         yAxisID: 'right-y-axis',
            //         data: chartdata1,
            //     },
            //     {
            //         label: 'Alarm Orange',
            //         // backgroundColor: 'rgba(99,255,100,0.2)',
            //         backgroundColor: 'rgba(255,155,0,0.5)',
            //         borderColor: 'rgba(255,99,132,1)',
            //         // borderWidth: 0.2,
            //         barPercentage: 0.9,
            //         categoryPercentage: 0.6,
            //         // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //         // hoverBorderColor: 'rgba(255,99,132,1)',
            //         type: 'bar',
            //         yAxisID: 'right-y-axis',
            //         data: chartdata2
            //     },
            //     {
            //         label: 'Alarm Yellow',
            //         // backgroundColor: 'rgba(99,255,255,0.2)',
            //         backgroundColor: 'rgba(255,255,0,0.5)',
            //         borderColor: 'rgba(255,99,132,1)',
            //         // borderWidth: 0.2,
            //         barPercentage: 0.9,
            //         categoryPercentage: 0.6,
            //         // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //         // hoverBorderColor: 'rgba(255,99,132,1)',
            //         type: 'bar',
            //         yAxisID: 'right-y-axis',
            //         data: chartdata3
            //     }
            // ]
        };
        const options = {
            aspectRatio: isSmall ? 1 : 3,
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                bounds: 'data',
                // bounds: 'ticks',
                // xAxes: [{
                //     // stacked: false,
                //     // type: 'linear',
                // }],
                xAxes: [{
                    type: 'time',
                    
                    afterBuildTicks: function (axis, ticks) {
                        
                        // max possible ticks
                        const totElements = 30;
                        if(ticks){
                            // console.log("===this.ticks", ticks);
                            if (ticks.length <= totElements) {
                                return ticks;
                            };
                            // this number is used to display a tick every n ticks
                            const density = Math.trunc(ticks.length / totElements);
                            
                            if (isNaN(density)) {
                                console.warn('Nan in ticks')
                                return ticks;
                            }
                            return ticks.filter((t, index) => {
                                // always show the first and the last tick
                                if (index === 0 || index === ticks.length - 1) {
                                    return t;
                                } else if (index % density === 0) {
                                    return t;
                                }
                            })
                        };
                    },
                    distribution: 'series',
                    // distribution: 'linear',
                    offset: isLightning,
                    bounds: 'ticks',
                    source: 'auto',
                    time: {
                        unit: (isLightning || isSmall) && isDays ? 'day' : 'hour',
                        // unit: 'hour',
                        // stepSize: 3,
                        // round: true,
                        // parser: 'MMM DD HH'
                        // parser: function (date) {
                        //     let test01 = moment(date).utcOffset('-0400');
                        //     let test02 = moment(date).utcOffset('+0400');
                        //     console.log("=======this.test01", test01);
                        //     console.log("====test02", test02);
                            
                        //     return moment(date).utcOffset('-0400');
                        // },
                        displayFormats: {
                            'hour': 'MMM-DD hA',
                        },

                        // parser: function (date) {
                        //     return moment(date).utcOffset("-1500");
                        // },
                    },
                    
                    
                    ticks: {
                        // min: '2016-12-24',
                        // max: '2016-12-30',
                        //maxTicksLimit: 60,
                        // sampleSize: 2,
                        autoSkip: true,
                        autoSkipPadding: 5,
                        bounds: 'data',
                        source: 'auto',
                        includeBounds: true,
                        //labelOffset: 10,
                    },
                }],
                yAxes: [{
                    // stacked: false,
                    // type: 'linear',
                    // offset: true,
                    id: 'left-y-axis',
                    position: 'left',
                    distribution: 'series',
                    display: this.props.attributea == "Select A Statistic" ? false : true,
                    ticks: {
                        // min: 0,
                        // max: 70,
                        display: !isSmall,
                        // callback: function(value, index, values) {
                        //     if(value % 1 == 0 && value >= 0) {
                        //         return value;
                        //     }
                        // },
                    },
                    scaleLabel: {
                        display: !isSmall,
                        labelString: labelLeft
                    }
                },
                {
                    id: 'right-y-axis',
                    // type: 'linear',
                    position: 'right',
                    distribution: 'series',
                    display: ((windAveA || windMaxA) && (windAveB || windMaxB)) || this.props.attributeb == "Select A Statistic"  ? false : true,
                    ticks: {
                        // min: 0,
                        // max: 70,
                        display: !isSmall,
                        // callback: function(value, index, values) {
                        //         if(value % 1 == 0 && value >= 0) {
                        //             return value;
                        //         }
                            
                        // },
                    },
                    scaleLabel: {
                        display: !isSmall,
                        labelString: labelRight
                    },
                    grid: {
                        drawOnChartArea: false, // only want the grid lines for one axis to show up
                    },
                }
                ]
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    fontSize: 12,
                    fontColor: 'rgb(255, 99, 132)'
                }
            }

        }
        // console.log("===options", options);

        return (
            <Fragment>
                <Line
                    key={this.renderKey}
                    width={null}
                    height={null}
                    data={data}
                    options={options}
                >
                </Line>
                {this.renderSpinner(isLightning)}
            </Fragment>
        );
    }

    renderSpinner = (isLightning) => {
        // historyLoading = {this.props.assetHistoryLoading}
        // countLoading = {this.props.assetCountLoading}

        let loading = false;
        if (isLightning) {
            loading = this.props.historyLoading || this.props.countLoading || this.props.hourlyCountLoading;

        } else {
            loading = this.props.historyLoading;

        }

        // let loading = this.props.historyLoading || this.props.countLoading;
        return (
            <Fragment>
                {loading &&
                    <div style={{
                        position: 'Absolute ',
                        height: '100%',
                        width: '100%',
                        top: '0px',
                        left: '0px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        backgroundColor: LIGHT_GREY_COLOR,
                        opacity: '0.6',
                    }}
                    >
                        <Spinner
                            className="spinner"
                            as="span"
                            animation="border"
                            variant="success"
                            // size="sm"
                            // role="status"
                            // aria-hidden="true"
                            style={{ display: 'inline-block', margin: 'auto' }}
                        />
                    </div>
                }
            </Fragment>
        );
    }

    render() {
        return (
            <Fragment>
                {/* <Card style={{padding: '0', border: '1px solid black'}}>
                <CardBody style={{padding: '20px 5px 10px 5px'}}>
                    {this.renderMixChart()}
                </CardBody>
            </Card> */}
                {this.renderMixChart()}
            </Fragment>
        );
    }
}

function setChartData(props, attribute, y_axis_id) {
    let dataSet = [];
    let isLightning = false;
    if (attribute) {
        if (ALARM_META_ATTRI_OBJ.attributename === attribute) {
            const { countData } = props;
            if (countData) {


                let bardatared = [];
                let bardataorange = [];
                let bardatayellow = [];
                countData.forEach((item, index) => {
                    let red = { x: item.date, y: item.red };
                    let orange = { x: item.date, y: item.orange };
                    let yellow = { x: item.date, y: item.yellow };
                    bardatared.push(red);
                    bardataorange.push(orange);
                    bardatayellow.push(yellow);
                });

                const barDataSet = [
                    { label: 'Alarm Red', backgroundColor: 'rgba(255,0,0,0.7)', data: bardatared },
                    { label: 'Alarm Orange', backgroundColor: 'rgba(255,155,0,0.5)', data: bardataorange },
                    { label: 'Alarm Yellow', backgroundColor: 'rgba(255,255,0,0.5)', data: bardatayellow }
                ];


                barDataSet.forEach(item => {
                    const dataObj = {
                        ...item,
                        // backgroundColor: 'rgba(255,99,132,0.2)',
                        //  backgroundColor: 'rgba(255,0,0,0.7)',
                        borderColor: 'rgba(255,99,132,1)',
                        // borderWidth: 0.2,
                        barPercentage: 0.9,
                        categoryPercentage: 0.6,
                        // hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        // hoverBorderColor: 'rgba(255,99,132,1)',
                        type: 'bar',
                        yAxisID: y_axis_id
                    }
                    dataSet.push(dataObj);
                });
                isLightning = true;
            }
        }
        else {
            const { chartData } = props;
            if (chartData) {
                // console.log("===chartData", chartData);
                let linedataset1 = null;
                // dataset1=this.props.chartData.filter((item)=>(item.attributename === this.props.attributea)).map((item)=>({x: item.attritime, y: item.value}));
                const assetAttribute1 = chartData.find((item) => (item.attributename === attribute));

                if (assetAttribute1) {
                    linedataset1 = assetAttribute1.data;
                    if (linedataset1) {
                        const dataObj = {
                            label: attribute,
                            // backgroundColor: 'rgba(255,99,132,0.2)',
                            backgroundColor: 'white',
                            borderColor: assetAttribute1.backgroundcolor,
                            pointRadius: 1,
                            // borderWidth: 0.2,
                            // barPercentage: 0.9,
                            // categoryPercentage: 0.6,
                            pointHoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            pointHoverBorderColor: 'rgba(255,99,132,1)',
                            yAxisID: y_axis_id,
                            fill: false,
                            // data: chartdata1,
                            data: assetAttribute1.data,
                        };
                        dataSet.push(dataObj);
                    }
                }
            };
        }
    }
    return { dataSet, isLightning };
}
export default WeatherMixChart;