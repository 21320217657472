/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import FileloaderModal from './UploaderModal';

class InventoryInvoice extends Component {
    constructor(props){
        super(props);
        this.state={
            modal: false,
            orderId: null,
            data: [
                {
                    invId: '001',
                    ordrId:'001',
                    vender:'Air Box Wifi',
                    totAmt:'200',
                    status:3,
                    orderDt:'2020-10-07',
                    invoiceDt:'2020-10-07',
                    invoiceReference: '001',
                },
                {
                    invId: '002',
                    ordrId:'002',
                    vender:'Sigfox',
                    totAmt:'500',
                    status:0,
                    orderDt:'2020-09-07',
                    invoiceDt:'2020-09-07',
                    invoiceReference: '',
                },
            ]
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleInvoiceClick = (invId) =>{}

    invoiceIdFormater = (cell, row) => {
        return (
            // <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleRowEditClick(row, true);}}>{cell}</a>
            <a href={'#'} onClick={(e) => { e.preventDefault(); this.handleInvoiceClick(cell);}}>{cell}</a>
        );
    }

    statusFormater = (cell, row) => {
        let statusStr = '';
        switch(cell){
            case 3 :
                statusStr='completed';
                break;
            default :
                statusStr='uncompleted'
        }
        return(
        <span>{statusStr}</span>
        );
    }

    actionClick = (invoiceObj) => {}

    onUploadClick = (e, invoiceObj) => {
        this.setState({
            orderId: invoiceObj.ordrId
        });
        this.toggle();
    }

    actionFiledFormater = (cell, row) => {
        return (
            <div>
                {row.status == 3 ?
                    <div>
                    <a href={'#'} onClick={(e) => { e.preventDefault(); this.actionClick(row);}} style={{marginRight: '5px'}}>Show</a> 
                    <a href={'#'} onClick={(e) => { e.preventDefault(); this.onUploadClick(e, row);}} style={{marginLeft: '5px'}}>Reload</a>
                    </div>
                  :
                    <Button variant="primary" color="custom" type="button" onClick={(e)=>{ this.onUploadClick(e, row) }} size='sm'>Load Invoice</Button>
                }
                
            </div>
           
        );
    }

    renderInvoiceList = () => {
        return (
            <BootstrapTable
                data={ this.state.data }  
                // tableHeaderClass = 'header_white' 
                pagination = {true} 
                tableHeaderClass = 'header_white' 
                  
                search
                bordered={ false } 
                // insertRow 
                ref='table' 
            >
                {/* <TableHeaderColumn dataField='invId' isKey={ true } dataFormat={this.invoiceIdFormater} dataAlign="center" width='8%' >Invoice Id</TableHeaderColumn> */}
                <TableHeaderColumn dataField='ordrId' isKey={ true } dataFormat={this.invoiceIdFormater} dataAlign="center" dataSort width='8%'>Order</TableHeaderColumn> 
                <TableHeaderColumn dataField='invoiceReference' dataAlign="center" width='15%'>Invoice</TableHeaderColumn>
                <TableHeaderColumn dataField='vender' dataAlign="center" dataSort width='10%'>Vender</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='provTax' dataAlign="center" dataSort width='8%' >Province Tax</TableHeaderColumn>
                <TableHeaderColumn dataField='fedTax' dataAlign="center" dataSort width='8%' >Federal Tax</TableHeaderColumn>
                <TableHeaderColumn dataField='discountAmt' dataAlign="center" dataSort width='8%' >Discount</TableHeaderColumn> */}
                <TableHeaderColumn dataField='totAmt' dataAlign="center" dataSort width='10%' >Total Amount</TableHeaderColumn>
                <TableHeaderColumn dataField='status' dataFormat={this.statusFormater} dataAlign="center" dataSort width='10%'>Status</TableHeaderColumn>
                <TableHeaderColumn dataField='orderDt' dataFormat={this.timeFormat} dataAlign="center" dataSort width='12%'>Order Date</TableHeaderColumn>
                <TableHeaderColumn dataField='invoiceDt' dataFormat={this.timeFormat} dataAlign="center" dataSort width='12%'>Invoice Date</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='invoiceReference' dataAlign="center" width='15%' >Invoice Reference</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='comment' dataAlign="center" width='15%' >Comment</TableHeaderColumn> */}
                <TableHeaderColumn dataField='action' dataFormat={this.actionFiledFormater} dataAlign="center"></TableHeaderColumn>
            </BootstrapTable>
        );
    }

    onSaveClick = (e, file, orderId) => {
        if (!orderId) {
            toast.error('Please select a Order');
            return;
        }

        if (!file) {
            toast.error('Please select a CSV files');
            return;
        }

        this.props.uploadFile(file, orderId);
        // this.toggle();
          
    }

    renderFileLoader = () => {
        return (
            <FileloaderModal
                modal={this.state.modal}
                toggle={this.toggle}
                headertitle='Upload Invoice'
                labeltitle='Order'
                labelvalue={this.state.orderId? this.state.orderId.toString(): ''}
                onSave={(e, file)=>{this.onSaveClick(e, file, this.state.orderId)}}
            />
        );
    }

    render() {
        return(
            <Card>
                <div className ='card-pginside' style={{padding: '0px'}}>
                    <Row>
                        <Col md={12}>
                            {this.renderInvoiceList()}
                            {this.renderFileLoader()}
                        </Col>
                    </Row>
                </div>
            </Card>
        );
    }
}
export default InventoryInvoice;