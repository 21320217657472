/* eslint-disable */
import React, { Component, PureComponent, Fragment } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Bar} from 'react-chartjs-2';
import {LIGHT_GREY_COLOR} from '../../../constants/dataConstants';
import Spinner from 'react-bootstrap/Spinner';

class SingleAlarmBarChartWidget extends PureComponent {
    constructor(props){
        super(props);
        this.state={}
    }
    renderBarChart = () => {
        const chartlabel = ['A', 'B', 'C', 'D', 'E'];
        // const chartdata1 = [20,30,50,40,10];
        // const chartdata2 = [10,40,30,20,30];
        // const chartdata1 = [
        //     {x:'2016-12-25', y:20},
        //     {x:'2016-12-26', y:30},
        //     {x:'2016-12-27', y:50},
        //     {x:'2016-12-28', y:40},
        //     {x:'2016-12-29', y:10}
        // ];
        // const chartdata2 = [
        //     {x:'2016-12-25', y:10},
        //     {x:'2016-12-26', y:40},
        //     {x:'2016-12-27', y:30},
        //     {x:'2016-12-28', y:20},
        //     {x:'2016-12-29', y:50}
        // ]
        // const chartdata1 = [
        //     {x:'2021-06-14', y:20},
        //     {x:'2021-06-15', y:30},
        //     {x:'2021-06-16', y:50},
        //     {x:'2021-06-17', y:40},
        //     {x:'2021-06-18', y:10}
        // ];
        // const chartdata2 = [
        //     {x:'2021-06-14', y:10},
        //     {x:'2021-06-15', y:40},
        //     {x:'2021-06-16', y:30},
        //     {x:'2021-06-17', y:20},
        //     {x:'2021-06-18', y:50}
        // ];
         
        let chartdatared = [];
        let chartdataorange = [];
        let chartdatayellow = [];
        if (this.props.countData){
            this.props.countData.forEach((item, index) => {
                let red = {x: item.date, y: item.red};
                let orange = {x: item.date, y: item.orange};
                let yellow = {x: item.date, y: item.yellow};
                chartdatared.push(red);
                chartdataorange.push(orange);
                chartdatayellow.push(yellow);
            });

        }
         
        const data = {
            // labels: chartlabel,
            datasets: [
                {
                    label: 'Alarm Red',
                    // backgroundColor: 'rgba(255,99,132,0.2)',
                    backgroundColor: 'rgba(255,0,0,0.7)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdatared,
                },
                {
                    label: 'Alarm Orenge',
                    // backgroundColor: 'rgba(99,255,100,0.2)',
                    backgroundColor: 'rgba(255,155,0,0.5)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdataorange,
                },
                {
                    label: 'Alarm Yellow',
                    // backgroundColor: 'rgba(99,255,255,0.2)',
                    backgroundColor: 'rgba(255,255,0,0.5)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdatayellow
                }
            ]
        };
         
        let unit = this.props.units ==='day' ? 'day': 'hour';
        let rationOption = this.props.isSmall ? {responsive: true, maintainAspectRatio: false,} : { aspectRatio: 2, responsive: true, maintainAspectRatio: true,} 
        const options = {
            // ...rationOption,
            aspectRatio: 2,
            responsive: true,
            maintainAspectRatio: true,
            showScale: false,
            scales: {
                // bounds: 'data',
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    offset: true,
                    time: {
                        // unit: 'day'
                        // unit: unit
                    },
                    ticks:{
                        // min: '2016-12-24',
                        // max: '2016-12-30',
                        source: 'data',
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            //  
                            if (index === 0) {
                                return 'Today';
                            } 
                            if (index === values.length - 1) {
                                //  
                                return '24 hrs';
                            }
                            return null;
                        }
                    },
                    gridLines: {
                        // display: false,
                        display: true,
                        // color: chartColor,
                        color: 'grey',
                        // color: "rgba(0, 0, 0, 0)",
                        drawBorder: true,
                        drawOnChartArea: false,
                        drawTicks: false,
                    }
                }],
                yAxes: [{
                    // stacked: false,
                    // type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        // min: 0,
                        display: false,
                    },
                    scaleLabel: {
                        display: true,
                        // labelString: '1k = 1000'
                    },
                    gridLines: {
                        display: true,
                        // color: chartColor,
                        color: 'grey',
                        // color: "rgba(0, 0, 0, 0)",
                        drawBorder: true,
                        drawOnChartArea: false,
                        drawTicks: false,

                    }
                }]
            },
            legend: {
                display: this.props.isSmall? false: true,
                // position: 'right',
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    fontSize: 12,
                    fontColor: 'rgb(255, 99, 132)'
                }
            }
        }
        return (
            <Fragment>
                <Bar
                    // key={this.props.isSmall}
                    data={data}
                    // width={60}
                    width={'200px'}
                    // width={null}
                    height={null}
                    // height={height.toString()} 
                    // height={300}
                    // height={'45vh'}
                    options={options}
                />
            </Fragment>
        );
    }

    renderSpinner = () => {
        let loading = this.props.loading ? this.props.loading : false;
        // historyLoading = {this.props.assetHistoryLoading}
        // countLoading = {this.props.assetCountLoading}
        //  
        // let loading = false;
        // if (isLightning) {
        //     loading = this.props.historyLoading || this.props.countLoading || this.props.hourlyCountLoading;
        //      
        // } else{
        //     loading = this.props.historyLoading;
        //      
        // }

        // let loading = this.props.historyLoading || this.props.countLoading;
        return (
            <Fragment>
            { loading && 
                <div style={{
                                position: 'Absolute ', 
                                height: '100%', 
                                width: '100%', 
                                top: '0px', 
                                left: '0px', 
                                display: 'flex', 
                                textAlign: 'center', 
                                alignItems: 'center',
                                backgroundColor: LIGHT_GREY_COLOR,
                                opacity: '0.6',
                            }}
                >
                    <Spinner
                        className="spinner"
                        as="span"
                        animation="border"
                        variant="success"
                        // size="sm"
                        // role="status"
                        // aria-hidden="true"
                        style={{display: 'inline-block', margin: 'auto' }}
                    /> 
                </div>
            } 
            </Fragment>
        );
    }

    render(){
        return(
            <Card>
                <CardBody style={{padding: '20px 30px', border: '1px solid black', backgroundColor: 'white',}}>
                <Row style= {{margin: '0px 0px 10px 0px'}}>
                    <h4>
                        Daily Occupancy
                    </h4>
                </Row>
                <Row>
                    {this.renderBarChart()}
                    {this.renderSpinner()}
                </Row>
                </CardBody>
            </Card>
        );
    }
}

export default SingleAlarmBarChartWidget;