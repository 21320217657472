/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import NotificationTemplateList from './components/TemplateList';

const ReportsPage = () => {
    return(
        <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h2 className="page-title">Template Management</h2>
              <hr />
      </Col>

    </Row>
    <Row>
    <NotificationTemplateList />

    </Row>
        </Container>
    );
}

export default ReportsPage;
