/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from 'redux'
import {connect} from "react-redux";
import ReactDOM from 'react-dom';
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline} from 'google-maps-react';
import {Container, Row, Col, Button, Table, Input, Label} from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import MapInfoWindow from './MapInfoWindow';
import {loadAllGeoFences} from '../../../redux/actions/fenceAction';
import {generateSelectOptions} from '../../../factories/utils';
import {getCurrentUser} from '../../../factories/auth';
import MarkerClusterer from "@google/markerclusterer";
// import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
import {style} from '../../DashBoard/components/DashboardMap'



export class MapContainer extends Component{
    constructor(props){
        super(props);
        this.initMap = this.initMap.bind(this);
        this.myMap = React.createRef();
        // this.state = {
        //     showingInfoWindow: false,
        //     activeMarker: {},
        //     selectedPlace: {},
        //     markers: [
        //                 {lat:-1.2884,lng:36.8233,info:'test1'}, 
        //                 {lat:-1.2684,lng:36.8033,info:'test2'}, 
        //                 {lat:-1.3084,lng:36.8433,info:'test3'}
        //             ]
        // };
        this.state={
            msg:'',
            // shape: {
            //     type: "circle",
            //     data: {
            //             "center":{"lat":43.81889879849262,"lng":-79.51485120725812},
            //             "radius":582.5477246873462
            //           }
            // }
        };
        this.userObj = getCurrentUser();
        if (!this.userObj) {
            toast.error('Can not fetch login user information, Please re-login.');
            this.props.history.push('/log_out');
        }else{
             
             
        }
         
         
        // this.initMap = this.initMap.bind(this);
    }

    componentDidMount(){
        this.props.loadAllGeoFences();
    }

    componentWillReceiveProps(nextProps){
        let fenceOptions = [];
        if (nextProps.allfences){
            fenceOptions = generateSelectOptions(nextProps.allfences, 'fencename', 'fenceid');
        }
        fenceOptions.unshift({
            label: "None", 
            value: 0,
        });
        this.setState({
            fenceOptions: fenceOptions,
        });
    }
    // -----------------------------------------------------------------
        // initMap(mapProps, map) {
        // var self = this;
        // const {google} = mapProps;
    
        // const drawingManager = new google.maps.drawing.DrawingManager({
        //   drawingMode: null,
        //   drawingControl: true,
        //   drawingControlOptions: {
        //     position: google.maps.ControlPosition.TOP_CENTER,
        //     drawingModes: [
              
        //       google.maps.drawing.OverlayType.POLYGON,
        //       google.maps.drawing.OverlayType.CIRCLE,
        //       google.maps.drawing.OverlayType.RECTANGLE
        //     ]
        //   },
        //   map: map
        // });
        // }
    // -----------------------------------------------------------------
    // onMarkerClick = (props, marker, e)=> {
    //     //  
    //     // this.setState({
    //     //     selectedPlace: props,
    //     //     activeMarker: marker,
    //     //     showingInfoWindow: true
    //     // });
    //     this.props.onMarkerClick(props, marker, e);
    //     //  
    // };

    // onClose = props => {
    //     // if (this.state.showingInfoWindow) {
    //     //     this.setState({
    //     //       showingInfoWindow: false,
    //     //       activeMarker: null
    //     //     });
    //     //   }
    //       this.props.handleMapInfoWinClick(props); 
    // };
    //--------------------------------------------------------------------
    setSelection = (shape) => {        
        this.selectedShape = shape;
    }
    deleteSelectedShape = () => {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
        }
    }
    showShapOnMap = (shape) => {
        if (shape && this.myMap && this.myMap.current){
            // alert('yes');
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            this.deleteSelectedShape();
            this.setShap(google, map, shape);
        }else{
            this.deleteSelectedShape();
        }
    }
    setShap = (google, map, shape) => {
        // let shape = this.props.shape;

        // alert(shape.data.center);
        let self = this;
        let newCenter;
        let bounds;
        if (shape) {
            // self.deleteSelectedShape();
            switch(shape.type) {
                case 'circle':
                    const initCircle=new google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        center: shape.data.center,
                        radius: shape.data.radius,
                        editable: false
                      });
                    // google.maps.event.addListener(initCircle, 'radius_changed', function (event) {
                    //     // alert('circle radius changed');
                    //     self.setCircleData(initCircle);     
                    //      
                    //     //  
                    // });
                
                    // google.maps.event.addListener(initCircle, 'center_changed', function (event) {               
                    //     // alert('circle center changed');
                    //     // alert(initCircle.center);
                    //     self.setCircleData(initCircle);     
                    //      
                    //     //  
                    // });
                    bounds = initCircle.getBounds();
                    newCenter = initCircle.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initCircle);
                    // this.setCircleData(initCircle); 
                    break;
                case 'rectangle':
                    const initRectangle=new google.maps.Rectangle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        bounds: shape.data,
                        editable: false
                      });
                    //   google.maps.event.addListener(initRectangle, 'bounds_changed', () => {
                    //       this.setRectangleData(initRectangle);
                    //        
                    //   });
                      bounds = initRectangle.getBounds();
                      newCenter = bounds.getCenter();
                      map.setCenter(newCenter);
                      map.fitBounds(bounds);
                      this.setSelection(initRectangle);
                    //   this.setRectangleData(initRectangle); 
                    break;
                case 'polygon':
                    google.maps.Polygon.prototype.getBoundingBox = function(){
                        var bounds = new google.maps.LatLngBounds();
                        this.getPath().forEach(function(element,index) {
                          bounds.extend(element)
                        });
                        return(bounds);
                    }
                    const initPolygon=new google.maps.Polygon({
                        paths: shape.data,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        editable: false
                    });
                    
                    // let paths = initPolygon.getPaths();
                    // // let pType = typeof paths;
                    // // alert(pType);
                    // // alert(Array.isArray(paths));
                    // // if (Array.isArray(paths)) {
                    // //     alert('true');
                    // // }
                    // paths && paths.forEach((path) => {
                    //     google.maps.event.addListener(path, 'insert_at', () => {
                    //         // alert('insert_at');
                    //         this.setPloygonData(initPolygon);
                    //          
                    //     });
                    //     google.maps.event.addListener(path, 'remove_at', () => {
                    //         // alert('remove_at');
                    //         this.setPloygonData(initPolygon);
                    //          
                    //     });
                    //     google.maps.event.addListener(path, 'set_at', () => {
                    //         // alert('set_at');
                    //         this.setPloygonData(initPolygon);
                    //          
                    //     });
                    // });
                    bounds = initPolygon.getBoundingBox();
                    newCenter = bounds.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initPolygon);
                    // this.setPloygonData(initPolygon); 
                    break;
            }
        }
    }

    //--------------------------------------------------------------------
    setMapBounds = (mapData) => {
        if (this.myMap && this.myMap.current){
             
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            if (google, map) {
                // let bounds = this.getMapBounds(google, map);
                let bounds = null;
                if (mapData && mapData.length > 0) {
                    bounds = new google.maps.LatLngBounds();
                    let assetClass = mapData[0];
                    assetClass && assetClass.asset.forEach((location) => {
                        bounds.extend(
                          new google.maps.LatLng(location.locationlat, location.locationlng),
                        );
                    });
                }
                if (bounds) {
                    map.fitBounds(bounds);   
                }
            }
        }
    }
    getMapBounds = (google, map) => {
        let bounds = null;
        let assetClass;

        if (this.props.pMapData && this.props.pMapData.length > 0) {
            bounds = new google.maps.LatLngBounds();
            assetClass = this.props.pMapData[0];
            assetClass.asset.forEach((location) => {
                bounds.extend(
                  new google.maps.LatLng(location.locationlat, location.locationlng),
                );
            });
        }
      
       
        return bounds;
    };

    getCompanyBounds = (google, map) => {
         
        let bounds = null;
        if (this.userObj.company && this.userObj.company.businessArea){
             
            let points = JSON.parse(this.userObj.company.businessArea);
            bounds = new google.maps.LatLngBounds();
            points.forEach((point,index) => {
                bounds.extend(
                  new google.maps.LatLng(point.lat, point.lng),
                );
            });
        }
        return bounds;
    }

    getFirstOne = (google, map) => {
        let mylocation = null;
        let assetClass;

        if (this.props.mapData && this.props.mapData.length > 0) {
            // bounds = new google.maps.LatLngBounds();
            assetClass = this.props.mapData[0];
            assetClass.asset.forEach((location) => {
                // bounds.extend(
                //   new google.maps.LatLng(location.locationlat, location.locationlng),
                // );
                 
                mylocation =  new google.maps.LatLng(location.locationlat, location.locationlng);
                 
            });
        }
      
       
        return mylocation;
    };
    //--------------------------------------------------------------------
    initMap(mapProps, map) {
        // alert('onready');
        var self = this;
        const {google} = mapProps;

        // --------------------------------------------
        // let bounds = this.getMapBounds(google, map);
        // if (bounds){
        //     map.fitBounds(bounds);       
        //     // map.panToBounds(bounds);  
        // }
        let bounds = this.getCompanyBounds(google, map);
        //  
        if (bounds){
            map.fitBounds(bounds);       
            // map.panToBounds(bounds);  
        }

        // let location = this.getFirstOne(google, map);
        //  
        //  
        // if (location){
        //     map.setCenter(location);       
        //     // map.panToBounds(bounds);  
        // }
               
        self.setShap(google, map, self.state.shape);
        // --------------------------------------------
            var input = ReactDOM.findDOMNode(self.refs.inputTest);
            var searchBox = new google.maps.places.SearchBox(input);
            // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            map.addListener('bounds_changed', function() {
                searchBox.setBounds(map.getBounds());
            });
            searchBox.addListener('places_changed', () => {
                // alert('test');
                var places = searchBox.getPlaces();
                // alert(places.length);
                if (places.length == 0) {
                    return;
                }

                // this.markers.forEach(function(marker) {
                //     marker.setMap(null);
                // });
                // this.markers = [];

                // for (var i = 0; i < places.length; i++) 
                // {
                //  //   createMarker(results[i]);
                //    var marker = new google.maps.Marker({
                //      map: map,
                //      position: places[i].geometry.location
                //    });
                //    this.markers.push(marker);
                // }
                map.setCenter(places[0].geometry.location);
            });

        //     var request = {
        //         query: '235 Yorkland Blvd, North York, ON M2J 4Y8',
        //         fields: ['name', 'geometry'],
        //       };

        //     let service = new google.maps.places.PlacesService(map);

        //     service.findPlaceFromQuery(request, function(results, status) {
        //         alert('test');
        //         alert(status);
        //       if (status === google.maps.places.PlacesServiceStatus.OK) {
        //         alert(results.length);  
        //         for (var i = 0; i < results.length; i++) {
        //         //   createMarker(results[i]);
        //           var marker = new google.maps.Marker({
        //             map: map,
        //             position: results[i].geometry.location
        //           });
        //         }
    
        //         map.setCenter(results[0].geometry.location);
        //       }
        //     });
          
    
        //   const createMarker = (place) => {
        //     var marker = new google.maps.Marker({
        //       map: map,
        //       position: place.geometry.location
        //     });
        // }
        // --------------------------------------------
    
            // google.maps.event.addListener(drawingManager, 'circlecomplete', function(circle) {
            //     var radius = circle.getRadius();
            //     alert(radius);
            // });
    }
    //--------------------------------------------------------------------
    handleFenceChange = (val) => {
        let id = val.value;        
        let fence = this.props.allfences.find((e, i) => (e.fenceid == id));
        let currentPolygonData = null;
        let currentRectangleData = null;
        let currentCircleData = null;
        let selectedShape = null;
        let type = '' ;
        if (fence) {
            switch(fence.shape){
                case 0:
                    type = 'circle';
                    currentCircleData = JSON.parse(fence.geodata);
                    break;
                case 1:
                    type = 'rectangle';
                    currentRectangleData = JSON.parse(fence.geodata);
                    break;
                case 2:
                    type = 'polygon';
                    currentPolygonData = JSON.parse(fence.geodata);
                    break;
            }
    
            selectedShape = {type: type, data: JSON.parse(fence.geodata)};
        }    
         
        this.setState({
            shape: selectedShape,
        });

        this.showShapOnMap(selectedShape)

        // this.selectedFenceId = fence.fenceid;
        // this.selectedFenceName = fence.fencename;

        // this.currentShapeData = {type: type, data: JSON.parse(fence.geodata)};

        // this.currentShapeType = type;
        // this.currentShapeTypeId = fence.shape;
        // this.currentPolygonData = currentPolygonData;
        // this.currentRectangleData = currentRectangleData;
        // this.currentCircleData = currentCircleData;
        // this.setState({
        //     selectedFenceOption: val,
        //     selectedShape: {type: type, data: JSON.parse(fence.geodata)},
        // });
    }
    //--------------------------------------------------------------------
    render(){
        const mapStyle = {
            width: '100%',
            height: '45vh',
            position : 'static',
            paddingTop: 10
        };  
        const mapContainerStyle = {
            width: '100%',
            position: 'relative',
        };
        let v = this.props.values.selectedPlace;
        let lat = this.props.values.lat;
        let lng = this.props.values.lng;
        
        // let lat = pos.lat;

        var icon = {
            path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0", //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: '#FF0000',
            fillOpacity: .6,
            anchor: new google.maps.Point(0,0),
            strokeWeight: 0,
            scale: 1
        };

        const defaultIconPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0";
        const defaultColor = "#FF0000";

        const generateIcon = (color, path)=>{
            let myPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0"; //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0"
            if (path && path.length > 0){
                myPath = path;
            }
            return (
                {
                    //path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0", //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                    // path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
                    //path: "m 640,128 q 0,52 -38,90 -38,38 -90,38 -52,0 -90,-38 -38,-38 -38,-90 0,-52 38,-90 38,-38 90,-38 52,0 90,38 38,38 38,90 z M 256,640 H 640 V 896 H 482 q -13,0 -22,-9 L 265,692 q -9,-9 -9,-22 V 640 z M 1536,128 q 0,52 -38,90 -38,38 -90,38 -52,0 -90,-38 -38,-38 -38,-90 0,-52 38,-90 38,-38 90,-38 52,0 90,38 38,38 38,90 z m 256,1088 V 192 q 0,-15 -4,-26.5 -4,-11.5 -13.5,-18.5 -9.5,-7 -16.5,-11.5 -7,-4.5 -23.5,-6 -16.5,-1.5 -22.5,-2 -6,-0.5 -25.5,0 -19.5,0.5 -22.5,0.5 0,-106 -75,-181 -75,-75 -181,-75 -106,0 -181,75 -75,75 -75,181 H 768 q 0,-106 -75,-181 -75,-75 -181,-75 -106,0 -181,75 -75,75 -75,181 h -64 q -3,0 -22.5,-0.5 -19.5,-0.5 -25.5,0 -6,0.5 -22.5,2 Q 105,131 98,135.5 91,140 81.5,147 72,154 68,165.5 64,177 64,192 q 0,26 19,45 19,19 45,19 v 320 q 0,8 -0.5,35 -0.5,27 0,38 0.5,11 2.5,34.5 2,23.5 6.5,37 4.5,13.5 14,30.5 9.5,17 22.5,30 l 198,198 q 19,19 50.5,32 31.5,13 58.5,13 h 160 v 192 q 0,26 19,45 19,19 45,19 h 1024 q 26,0 45,-19 19,-19 19,-45 z",
                    path: myPath,
                    fillColor: color,
                    fillOpacity: .6,
                    anchor: new google.maps.Point(0,0),
                    strokeWeight: 0,
                    scale: 1,
                }
            )                            
        };

        // if (this.props.values.selectedPlace.attr){
        //      
        //     console.log(this.props.values.selectedPlace.attr)
        // }else{
        //      
        // }

        const renderInfoWin = ()=>{
            if (this.props.values.selectedPlace.attr){
                 
                console.log(this.props.values.selectedPlace.attr)
                return (
                    Object.keys(this.props.values.selectedPlace.attr).map((key,i)=>(
                        key != 'Lat' && key !='Lng' &&                        
                        <tr>
                            <td>{key}</td>
                            <td>{this.props.values.selectedPlace.attr[key].value}</td>
                        </tr>
                    ))
                )
            }else{
                 
                return null;
            }
        }

        // "locationlat": 11.7638337,
        // "locationlng": 27.6988928,
        // 43.6905867,
        // -79.4751292,
        //  
        let pathArr = [];
        // let mapData = processAssetData(this.props.mapData, pathArr);
        // let mapData = processAssetDataMsg(this.props.mapData, pathArr, this.props.selectedAttriId);
        let mapData = postProcessAssetDataMsg(this.props.pMapData, pathArr, this.props.selectedAttriId, this.props.multiAssets);
         
        this.setMapBounds(mapData);
        //  
        //  

        // let lineSymbol = {
        //     path: this.props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        // };

        let lineSymbol = {
            // path: 'M0,0 L4,2 0,4',
            // path: 'M-2,4 L0,0 L2,4',
            path: 'M-2,4 0,0 M2,4 0,0',
            // path:'M -2,2 0,0 M 0,0 -2,-2',
            // path: 'M1,2 L10,5 L0,5',
            strokeOpacity: 1,
            scale: 2,
        };

        return(
            <div style={{position: 'relative'}}>
                                        {/* <div style={{padding: 20, backgroundColor: 'white'}}>          */}
                <br/>
                {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Input id="pac-input" class="controls" type="text" placeholder="Search Box" ref='inputTest' style={{width:'30%'}}/>     
                </div>   */}
                <Row>
                    <Col md={5}>
                        {/* <Label   style={{ display: 'inline'}}>Fence</Label> */}
                        <Select
                            styles={style}
                            value= {this.state.selectedFenceOption}           
                            // options={this.props.allFenceOptions} fenceOptions
                            options={this.state.fenceOptions}
                            onChange={this.handleFenceChange}
                            placeholder={'Select Geofence...'}
                        />
                    </Col>
                    <Col md={2}></Col>
                    <Col md={5}>
                        <Input 
                            id="pac-input" 
                            class="controls" 
                            type="text" 
                            placeholder="Search by Address" 
                            ref='inputTest' 
                            style={{width:'100%'}}/>     
                    </Col>
                </Row>
                <Map
                    ref={this.myMap}
                    google={this.props.google}
                    zoom={11}
                    onReady={this.initMap}
                    containerStyle={mapStyle}
                    style={mapStyle}
                    // initialCenter={{lat:43.8148024, lng:-79.5509472}}
                    // initialCenter={{lat:43.6905867, lng:-79.4751292}}
                    initialCenter={{lat:43.6907917, lng:-79.4761116}}
                    >
                    {/* {this.props.mapData && this.props.mapData.map((m,i)=>{
                        return(
                            <Marker onClick={this.props.onMarkerClick} name={m.devicename} attr={m.msg} position={{lat:m.locationlat,lng:m.locationlng}} key={i} icon={generateIcon(m.color,m.asseticonsvg)}>
                            </Marker>
                    );})} */}
                    {/* <Marker
                        name = {'teste'}
                        position={{lat:43.6907917,lng:-79.4761116}}
                    >
                    </Marker> */}

                     <ClusterWrapper
                        mapData={mapData}
                        defaultIconPath = {defaultIconPath}
                        defaultColor = {defaultColor}
                        generateIcon = {generateIcon}
                        onMarkerClick = {this.props.onMarkerClick}
                    />

                    {/* {mapData && mapData.map((item,index)=>(

                        item.asset && Array.isArray(item.asset) && item.asset.map((m, i) => {
                            if (m.locationlat == 0 && m.locationlng == 0){
                                return null;
                            }
                             
                             
                            let path = defaultIconPath;
                            let color = defaultColor;
                            if (item.assetIcon && item.assetIcon.iconsvg){
                                path = item.assetIcon.iconsvg;
                            }
                            if (m.color){
                                color = m.color;
                            }
                            //  
                            return(
                                <Marker
                                    onClick={this.props.onMarkerClick}
                                    name={m.assetname}
                                    // attr={m.msg}
                                    device={m.device}
                                    position={{lat:m.locationlat,lng:m.locationlng}}
                                    key={i}
                                    icon={generateIcon(color,path)}
                                >
                                </Marker>
                            );
                        })
                    )
                    )} */}

                     {pathArr && pathArr.length > 0 && pathArr.map((item, index) =>(
                        <Polyline
                        path={item}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2}
                        icons={[{
                            // icon: lineSymbol,
                            // offset: '100%',
                            icon: lineSymbol,
                            offset: '70%',
                            // repeat: '100px'
                        }]}
                        />
                    ))}
                    <InfoWindow marker={this.props.values.activeMarker}
                                visible={this.props.values.showingInfoWindow}
                                // conClick={this.props.handleMapInfoWinClick}
                                // onClick={this.props.onInfoWinClick}
                                onClose={this.props.handleMapInfoWinClose}
                    >
                        <div >
                            <h4><b>{this.props.values.selectedPlace.name}</b></h4>
                            <br/>
                            {/* <Table>
                                <tbody> */}
                                    {/* <tr>
                                        <td>attr</td>
                                        <td>{this.props.values.selectedPlace.name}</td>
                                    </tr> */}
                                    {/* {
                                        Object.keys(this.props.values.selectedPlace.attr).map((key,i)=>{

                                            <tr>
                                                <td>{key}</td>
                                                <td>{this.props.values.selectedPlace.attr[key]}</td>
                                            </tr>
                                        })
                                    } */}
                                    {/* {renderInfoWin()} */}
                                {/* </tbody>
                            </Table> */}
                            <MapInfoWindow device = {this.props.values.selectedPlace.device}/>
                        </div>
                    </InfoWindow>
                    {/* {this.state.markers.map((m,i)=>{
                         
                         
                        return(
                            <Marker onClick={this.onMarkerClick} name={m.info} position={{lat:m.lat,lng:m.lng}} key={i}>
                                <InfoWindow visible={this.state.showingInfoWindow} >
                                    <div>
                                        <h4>{m.info}</h4>
                                    </div>
                                </InfoWindow>
                            </Marker>
                        );})} */}
                    {/* <Marker onClick={this.onMarkerClick} name={'Map Test'} position={{lat: -1.2884, lng: 36.8233}}/> */}
                        {/* <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow} conClick={this.state.onClose}>
                            <div>
                                <h4>{this.state.selectedPlace.name}</h4>
                            </div>
                        </InfoWindow> */}

                    {/* <Marker onClick={this.onMarkerClick} name={'Map Test'} position={{lat: -1.2684, lng: 36.8033}}>
                        <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow} conClick={this.state.onClose}>
                            <div>
                                <h4>{this.state.selectedPlace.name}</h4>
                            </div>
                        </InfoWindow>
                    </Marker> */}
                </Map>
                
                {this.props.loading && 
                    <div style={{position: 'Absolute ', height: '100%', width: '100%', top: '0px', left: '0px', display: 'flex', textAlign: 'center', alignItems: 'center'}}>
                        <Spinner 
                            as="span"
                            animation="border"
                            variant="primary"
                            // size="sm"
                            // role="status"
                            // aria-hidden="true"
                            style={{display: 'inline-block', margin: 'auto' }}
                        /> 
                    </div>
                } 
            </div>
        )
    }
}

// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
//     libraries: ['drawing','places'],
// })(MapContainer);

function processAssetDataMsg(mapData, pointArr, selectedAttriId){
    let reMapData = JSON.parse(JSON.stringify(mapData));
    if (reMapData && reMapData.length ===1 && reMapData[0].asset && reMapData[0].asset.length===1){
        let asset = reMapData[0].asset.pop();
        if (asset.device) {
            for (let i = 0 ; i < asset.device.length; i++){
                if (asset.device[i].trackable){
                    if (asset.device[i].msgs && asset.device[i].msgs.length > 0){
                        let messages = asset.device[i].msgs;
                        let cloneasset = asset;
                        for (let mi = 0 ; mi < messages.length; mi++) {
                            // let newAsset = JSON.parse(JSON.stringify(asset));
                            let newAsset = JSON.parse(JSON.stringify(cloneasset));
                            for(let j = 0 ; j < newAsset.device.length; j++){
                                let msgItem; 
                                let newMsg = newAsset.device[j].msgs;
                                //  
                                if (j === i) {
                                    msgItem = JSON.parse(JSON.stringify(messages[mi]));
                                    // msgItem = {...newMsg[0], ...JSON.parse(JSON.stringify(messages[mi]))};
                                    // msgItem.msg = {...newMsg[0].msg, ...msgItem.msg}
                                }else{
                                    let otherMessages = asset.device[j].msgs;
                                    msgItem = JSON.parse(JSON.stringify(otherMessages[mi]));
                                    // msgItem = {...newMsg[0], ...JSON.parse(JSON.stringify(otherMessages[mi]))};
                                    // msgItem.msg = {...newMsg[0].msg, ...msgItem.msg}
                                }

                                msgItem.msg = {...newMsg[0].msg, ...msgItem.msg}
                                 

                                newAsset.device[j].msgs = [];
                                // newAsset.device[j].msgs.push(JSON.parse(JSON.stringify(messages[mi])));
                                newAsset.device[j].msgs.push(msgItem);
                                if (selectedAttriId) {
                                    // set asset color
                                    if (msgItem.msg){
                                        Object.keys(msgItem.msg).forEach((key)=>{
                                            //  
                                            //  
                                            //  
                                            if (msgItem.msg[key].id == selectedAttriId){
                                                //  
                                                let colorValue = msgItem.msg[key].themeattributevalue;
                                                if (msgItem.msg[key].display !== false && colorValue && colorValue.length >0){
                                                    //  
                                                    // color = colorValue ;
                                                    newAsset['color'] = colorValue;
                                                }
                                            }else{
                                                // delete msg.msg[key];
                                            }
                                        });
                                    }               
                                }
                            }
                            if (messages[mi].msg && messages[mi].msg.Lng && messages[mi].msg.Lat){
                                newAsset.locationlat = messages[mi].msg.Lat.value;
                                newAsset.locationlng = messages[mi].msg.Lng.value;
                            }      
                            cloneasset = newAsset;
                            reMapData[0].asset.push(newAsset);
                        }
                    }
                    break;
                }
            }
        }
        if ( reMapData[0].asset.length === 0 ){
            reMapData[0].asset.push(asset);
        }

        reMapData[0].asset.forEach((item, index) => {
            if (item.device) {
                let timestampe = new Date();
                item.device.forEach((d, i) =>{
                    if (d.trackable && d.msgs && d.msgs.length === 1) {
                        timestampe = new Date(d.msgs[0].timestamp);
                        //  
                    }
                });

                item.device.forEach((d, i) => {
                    if (!d.trackable || d.msgs.length  > 1){
                        //  
                        let foundIndex = -1;
                        for (let m = 0 ; m < d.msgs.length ; m++){
                            if (d.msgs[m].timestamp <= timestampe){
                                foundIndex = m;
                                // break;
                            }else{
                                break;
                            }
                        }
                        if (foundIndex >= 0){
                            d.msgs=[d.msgs[foundIndex]];
                        }else{
                            d.msgs=[d.msgs[0]];
                        }
                        
                    }
                });
            }
        });
         
        //  
        pathPoints(reMapData, pointArr);
         

    }else if (reMapData && reMapData.length >=1 && selectedAttriId) {
         
        reMapData.forEach((ac, acIndex)=>{
             
            ac.asset && ac.asset.forEach((at, atIndex)=>{
                 
                at.device && at.device.forEach((dv, dvIndex) => {
                     
                    dv.msgs && dv.msgs.length > 0 && dv.msgs[0].msg && Object.keys(dv.msgs[0].msg).forEach((key)=>{
                         
                        if (dv.msgs[0].msg[key].id == selectedAttriId){
                            let colorValue = dv.msgs[0].msg[key].themeattributevalue;
                             
                            if (dv.msgs[0].msg[key].display !== false && colorValue && colorValue.length >0){
                                at['color'] = colorValue;
                            }
                        }
                    });
                });
            });            
        });

    }

    return reMapData;
}

function postProcessAssetDataMsg(mapData, pointArr, selectedAttriId, multiAssets){
     
     
    let reMapData = JSON.parse(JSON.stringify(mapData));
    reMapData = filterZeroPosition(reMapData);
    if (!multiAssets) {
        pathPoints(reMapData, pointArr);
    }
    // pathPoints(reMapData, pointArr);
     
    if (reMapData && selectedAttriId) {
        //  
        reMapData.forEach((ac, acIndex)=>{
            //  
            ac.asset && ac.asset.forEach((at, atIndex)=>{
                //  
                at.device && at.device.forEach((dv, dvIndex) => {
                    //  
                    dv.msgs && dv.msgs.length > 0 && dv.msgs[0].msg && Object.keys(dv.msgs[0].msg).forEach((key)=>{
                        //  
                        if (dv.msgs[0].msg[key].id == selectedAttriId){
                            let colorValue = dv.msgs[0].msg[key].themeattributevalue;
                            //  
                            if (dv.msgs[0].msg[key].display !== false && colorValue && colorValue.length >0){
                                at['color'] = colorValue;
                            }
                        }
                    });
                });
            });            
        });
    }

    return reMapData;
}

function filterZeroPosition(mapData) {
    mapData && mapData.forEach((assetclass, acindex)=>{
        if(assetclass.asset) {
            let filteredAsset = assetclass.asset.filter((asset, atindex)=>(asset.locationlat != 0 && asset.locationlng != 0));
            assetclass.asset = filteredAsset;
        }
    });
     
    return mapData;
}

function processAssetData(mapData, pointArr) {
    let reMapData = JSON.parse(JSON.stringify(mapData));
    if (reMapData && reMapData.length ===1 && reMapData[0].asset && reMapData[0].asset.length===1){
        let asset = reMapData[0].asset.pop();
        // let newAssetArr = [];
        if (asset.device){
            for (let i=0; i< asset.device.length; i++) {
                if (asset.device[i].trackable){                   
                    if (asset.device[i].msgs && asset.device[i].msgs.length > 0 ){
                        // asset.device[i].msgs.forEach((item, index)=>{
                        //     let newAsset = JSON.parse(JSON.stringify(asset));
                        //     // let newMsgs = [];
                        //     // newMsgs.push(JSON.parse(JSON.stringify(item)));
                        //     //  
                        //     newAsset.device[i].msgs = [];
                        //     newAsset.device[i].msgs.push(JSON.parse(JSON.stringify(asset.device[i].msgs[index])));
                        //      
                        //     if (item.msg && item.msg.Lng && item.msg.Lat){
                        //         newAsset.locationlat = item.msg.Lat.value;
                        //         newAsset.locationlng = item.msg.Lng.value;
                        //     }                          
                        //     reMapData[0].asset.push(newAsset);
                        // });

                        let messages = asset.device[i].msgs;
                        for (let mi = 0 ; mi < messages.length; mi++) {
                            let newAsset = JSON.parse(JSON.stringify(asset));
                            // let newMsgs = [];
                            // newMsgs.push(JSON.parse(JSON.stringify(item)));
                            // debugger;
                            //  
                            //  
                            newAsset.device[i].msgs = [];
                            newAsset.device[i].msgs.push(JSON.parse(JSON.stringify(messages[mi])));
                            //  
                            if (messages[mi].msg && messages[mi].msg.Lng && messages[mi].msg.Lat){
                                newAsset.locationlat = messages[mi].msg.Lat.value;
                                newAsset.locationlng = messages[mi].msg.Lng.value;
                            }                          
                            reMapData[0].asset.push(newAsset);
                        }
                    }
                    // else{
                    //     mapData[0].asset.push(asset);
                    // }
                    break;
                };
            }
        }
        if ( reMapData[0].asset.length === 0 ){
            reMapData[0].asset.push(asset);
        }

        //  

        reMapData[0].asset.forEach((item, index) => {
            if (item.device) {
                let timestampe = new Date();
                item.device.forEach((d, i) =>{
                    if (d.trackable && d.msgs && d.msgs.length === 1) {
                        timestampe = new Date(d.msgs[0].timestamp);
                        //  
                    }
                });

                item.device.forEach((d, i) => {
                    if (!d.trackable || d.msgs.length  > 1){
                        //  
                        let foundIndex = -1;
                        for (let m = 0 ; m < d.msgs.length ; m++){
                            if (d.msgs[m].timestamp <= timestampe){
                                foundIndex = m;
                                break;
                            }
                        }
                        if (foundIndex >= 0){
                            d.msgs=[d.msgs[foundIndex]];
                        }
                        
                    }
                });
            }
        });
         
        //  
        pathPoints(reMapData, pointArr);
         
    }

    return reMapData;
}

function pathPoints(mapData, pathPoints){
    // let pathPoints = null;
    if (mapData && mapData.length ===1 && mapData[0].asset && mapData[0].asset.length > 1) {
        let assets = mapData[0].asset; 
        // pathPoints = [];
        for (let i = 0 ; i < assets.length - 1 ; i++){
            // if (mapData[i].msg && mapData[i].msg.Lat && mapData[i].msg.Lat.value && mapData[i].msg.Lng && mapData[i].msg.Lng.value){
            //     mapData[i].locationlat = mapData[i].msg.Lat.value;
            //     mapData[i].locationlng = mapData[i].msg.Lng.value;
            // }
            let points =  [
                    {lat: Number(assets[i].locationlat), lng: Number(assets[i].locationlng)},
                    {lat: Number(assets[i+1].locationlat), lng: Number(assets[i+1].locationlng)},
            ];
            pathPoints.push(points);
        }
    }
    //  
    // return pathPoints; 
}


function mapStateToProps(state) {   
    const allfences = state.fence.fenceData;
    const fenceObj = state.fence.fenceObj;
    const fenceStatus = state.fence.type;
    const props = {allfences, fenceObj, fenceStatus};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAllGeoFences,}, dispatch);
};
// export default connect(mapStateToProps, mapDispatchToProps)(StepCondition);

const enhance = compose(
    GoogleApiWrapper({
        // apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
        // apiKey: 'AIzaSyDXBuKv70EhwKMyHzrj6wc7_qPQFwmdyB4',
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['drawing','places'],
    }),
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MapContainer);

// --------------------------------------------------------

class ClusterWrapper extends Component {
    constructor(props){
        super(props);
         
        this.markers = [];
    }

    componentDidMount(){
         
        // this.renderCluster();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.google !== this.props.google || prevProps.mapData !== this.props.mapData) {
           
          if (this.clusterer) {
            this.clusterer.clearMarkers();
          }
          this.renderCluster();
        }
    }

    componentWillReceiveProps(nextProps){
         
    }


    renderCluster = () => {
        let {google, map} = this.props;
        this.markers = [];
        if (this.props.mapData) {
            // this.markers = this.props.mapData.map((m,i)=>{
            //     const ref = {
            //         map: map,
            //         name: m.assetname, 
            //         device: m.device,
            //         position: {lat:m.locationlat,lng:m.locationlng}, 
            //         icon: this.props.generateIcon(m.color,m.asseticonsvg)
            //     }

                
                
            //     let marker = new google.maps.Marker(ref);
            //      
            //     marker.addListener('click', (e)=> {this.props.onMarkerClick(ref, marker, e)});

            //     return marker;
            // })

            // this.clusterer = new MarkerClusterer(map, this.markers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
            // // return clusterer.clearMarkers();
            // // return this.markers;
            // ----------------------------------------------------------------------------------------
            const options = { legWeight: 3, minZoomLevel: 18 }; // Just an example of options - please set your own if necessary
            const oms = new OverlappingMarkerSpiderfier(map, options);
            // ----------------------------------------------------------------------------------------
            let that = this;
            this.props.mapData.forEach((item, index) => {
                // let that = this;
                if (item.asset  && Array.isArray(item.asset)) {
                    item.asset.forEach((m, i) =>{
                        if (m.locationlat !== 0 || m.locationlng !== 0) {
                            let path = that.props.defaultIconPath;
                            let color = that.props.defaultColor;
                            if (item.assetIcon && item.assetIcon.iconsvg){
                                path = item.assetIcon.iconsvg;
                            }
                            if (m.color){
                                color = m.color;
                            }

                            //  
                            //  
                             
                            const ref = {
                                map: map,
                                name: m.assetname, 
                                device: m.device,
                                position: {lat: Number(m.locationlat),lng:Number(m.locationlng)}, 
                                icon: that.props.generateIcon(color,path)
                            }
                            let marker = new google.maps.Marker(ref);
                             
                            // marker.addListener('click', (e)=> {this.props.onMarkerClick(ref, marker, e)});
                            // marker.addListener('spider_click', (e)=> {this.props.onMarkerClick(ref, marker, e)});
                            marker.addListener('spider_click', (e)=> {that.props.onMarkerClick(ref, marker, e)});
                            oms.addMarker(marker);

                            this.markers.push(marker);
                        }
                    });
                }
            });

            this.clusterer = new MarkerClusterer(map, this.markers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', maxZoom: 18});
            //  
            // return this.markers;
            // return this.clusterer;
        }
        
        return null;
        
    }

    // render(){
    //     return (
    //         <Fragment>
    //             {this.props.children ?
    //                 React.Children.only(
    //                     React.cloneElement(
    //                         this.props.children, 
    //                         // { marker: this.marker,
    //                         //     google: this.props.google,
    //                         //     map: this.props.map}
    //                         {...this.props}
    //                     )
    //                 ) : null
    //             }
    //         </Fragment>
    //     )
    // }

    render() {
        // const currentPlayingTitle = 'Mad Max: Fury Road';
        // const childrenWithExtraProp = React.Children.map(this.props.children, child => {
        //   return React.cloneElement(child, {
        //     ...this.props
        //   });
        // });
    
        return (
          <Fragment >
            {/* {childrenWithExtraProp} */}
            {this.props.children}
          </Fragment>      
        );
      }
}
