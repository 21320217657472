/* eslint-disable */
import { IPC_PASSWORD } from '../../../../../constants/appConstants';
import xml2js from 'xml2js'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import { NVR_PROXY } from '../../../../../constants/appConstants';
import { xmlToJson } from '../cameraXMLRequestUtils';
import SparkMD5 from 'spark-md5';
import Cookies from 'js-cookie';

const xmlHeader = "<?xml version=\"1.0\" encoding=\"utf-8\" ?>";
const protocolVer = "1.0"
const systemType = "NVMS-9000"
const urlBase = 'http://localhost:8001/api/' // http请求base
const SERVER_TYPE = 'nvr' // 设备类型 nvr dvr
const SERVER_IP = '192.168.2.155' // 设备IP

export const webSession = (key, value) => {
    if (value) {
        return sessionStorage.setItem(key, value)
    } else {
        return sessionStorage.getItem(key)
    }
}

export const clearNVRSession = () => {
    sessionStorage.setItem("nonce", null)
    sessionStorage.setItem("token", null)
    sessionStorage.setItem("sessionId", null)
}

const getEmptyRequest = (nvrType) => {
    let token = webSession(nvrType+'token', null)
    return xmlHeader + "<request version=\"" + protocolVer + "\"  systemType=\"" + systemType + "\" clientType=\"WEB\">" + "<token>" + token + "</token></request>";
}

const getRequestStart = (nvrType) => {
    let token = webSession(nvrType+'token', null)
    return xmlHeader + "<request version=\"" + protocolVer + "\"   systemType=\"" + systemType + "\" clientType=\"WEB\">" + "<token>" + token + "</token>";
}

const getRequestEnd = () => {
    return "</request>";
}


export const asyncAxiosXMLNVR = async (criteria, command, data, timeout) => {
    try {
        let config = {
            headers: { 'Content-Type': 'text/xml' },
            withCredentials: true,
            validateStatus: null
        };
        let uninterceptedAxios = axios.create({
            timeout: timeout ? timeout : null
        });
        let queryString = '';
        if (criteria != null) {
            queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
            queryString = "?" + queryString;
        }
        let url = `${NVR_PROXY}${command}${queryString}`;

        //let url = `${NVR_PROXY}${command}?ip=${nvrIP}`
        // let url = `${NVR_PROXY}${command}`
        let res = await uninterceptedAxios.post(url, data, config)
        return res

    } catch (err) {
        console.log(err)
        return null
    }
}

const getChlList = async (options, nvrIP, HttpPort, nvrType) => {
    var sendXml = getRequestStart(nvrType) +
        "<types>" +
        "<nodeType>" +
        "<enum>chls</enum>" +
        "<enum>sensors</enum>" +
        "<enum>alarmOuts</enum>" +
        "</nodeType>" +
        "</types>";
    if (options.pageIndex != undefined && options.pageIndex != null) {
        sendXml += "  <pageIndex>" + options.pageIndex + "</pageIndex>";
    }
    if (options.pageSize != undefined && options.pageSize != null) {
        sendXml += "  <pageSize>" + options.pageSize + "</pageSize>";
    }
    sendXml += "  <nodeType type=\"nodeType\">" + (options.nodeType || "chls") + "</nodeType>" +
        "  <condition>";
    if (options.chlName) {
        sendXml += "<name><![CDATA[" + options.chlName + "]]></name>";
    }
    if (options.isSupportPtz) {
        sendXml += "<supportPtz/>";
    }
    if (options.isSupportPtzGroupTraceTask) {
        sendXml += "<supportPTZGroupTraceTask/>";
    }
    if (options.isSupportTalkback) {
        sendXml += "<supportTalkback/>";
    }
    if (options.isSupportOsc) {
        sendXml += "<supportOsc/>";
    }
    if (options.isSupportSnap) {
        sendXml += "<supportSnap/>";
    }
    if (options.isSupportVfd) {
        sendXml += "<supportVfd/>";
    }
    if (options.isSupportBackEndVfd) {
        sendXml += "<supportBackEndVfd/>";
    }
    if (options.isSupportCpc) {
        sendXml += "<supportCpc/>";
    }
    if (options.isSupportCdd) {
        sendXml += "<supportCdd/>";
    }
    if (options.isSupportIpd) {
        sendXml += "<supportIpd/>";
    }
    if (options.isSupportAvd) {
        sendXml += "<supportAvd/>";
    }
    if (options.isSupportPea) {
        sendXml += "<supportPea/>";
    }
    if (options.isSupportTripwire) {
        sendXml += "<supportTripwire/>";
    }
    if (options.isSupportImageRotate) {
        sendXml += "<supportImageRotate/>";
    }
    if (options.isSupportFishEye) {
        sendXml += "<supportFishEye/>";
    }
    if (options.isSupportMotion) {
        sendXml += "<supportMotion/>";
    }
    if (options.isSupportOsd) {
        sendXml += "<supportOsd/>";
    }
    if (options.isSupportAudioSetting) {
        sendXml += "<supportAudioSetting/>";
    }
    if (options.isSupportMaskSetting) {
        sendXml += "<supportMaskSetting/>";
    }
    if (options.isSupportImageSetting) {
        sendXml += "<supportImageSetting/>";
    }
    if (options.isSupportWhiteLightAlarmOut) {
        sendXml += "<supportWhiteLightAlarmOut/>";
    }
    if (options.isSupportAudioAlarmOut) {
        sendXml += "<supportAudioAlarmOut/>";
    }
    if (options.isSupportAudioDev) {
        sendXml += "<supportAudioDev/>"
    }
    if (options.isSupportAOIEntry) {
        sendXml += "<supportAOIEntry/>";
    }
    if (options.isSupportAOILeave) {
        sendXml += "<supportAOILeave/>";
    }
    if (options.isSupportPassLine) {
        sendXml += "<supportPassLine/>";
    }
    if (options.isSupportVehiclePlate) {
        sendXml += "<supportVehiclePlate/>";
    }
    if (options.chlType) {
        sendXml += "<chlType type=\"chlType\">" + options.chlType + "</chlType>";
    }
    if (options.ignoreNdChl) {
        sendXml += "<ignoreNdChl/>";
    }
    sendXml += "</condition>" +
        "<requireField>" +
        "	<name/>" +
        "	<chlIndex/>" +
        "	<chlType/>";
    if (options.requireField) {
        options.requireField.forEach((el) => {
            sendXml += "	<" + el + "/>";
        });
    }
    sendXml += "</requireField>" + getRequestEnd();
    // return RequestUtil.Request({
    //     url: "queryNodeList",
    //     data: sendXml
    // });
    let criteria = {
        "ip": nvrIP,
        "port": HttpPort
    }
    return asyncAxiosXMLNVR(criteria, "queryNodeList", sendXml, null);

}

export const getNVRChannelList = async (nvrIP, HttpPort, nvrType) => {
    let response = await getChlList({
        nodeType: "chls",
        requireField: ["name", "winIndex", "chlIndex", "chlType"]
    }, nvrIP, HttpPort, nvrType)
    let channelListObj = {};
    let channelListObjArr = [];
    if (response && response.data) {
        let chlData = xmlToJson(response.data)
        // console.log('JSON CDATA', chlData)
        let chlDataResponse = chlData.response ? chlData.response.content ? chlData.response.content[0] : null : null;
        if (chlDataResponse) {
            let chlArr = chlDataResponse.item
            if (chlArr && chlArr.length > 1) {
                chlArr.reduce((acc, curr) => {
                    let devId = curr.devID[0]
                    let name = curr.name[0]
                    let poeIndex = curr.poeIndex ? curr.poeIndex[0] : null
                    let winIndex = curr.winIndex[0]
                    let channelNum = poeIndex ? poeIndex : parseInt(winIndex) + 1
                    let channelInfo = {
                        devId: devId,
                        name: name,
                        // poeIndex: poeIndex,
                        // winIndex: winIndex,
                        // channelNum: channelNum
                    }
                    channelListObjArr.push(channelInfo);
                    return acc
                }, channelListObjArr)
                // console.log("CHLList", channelListObj)
            } else {
                console.error("NVR have no channels")
                return null
            }
        }
    } else {
        console.error("Channel request failed or return no data..")
        return null
    }
    // console.log("CHANNEL LIST RES", channelListObjArr)
    return channelListObjArr
}

// dvr_login
const dvrLogin = async (userName, password, cb) => {
    var auInfo = CharsetHelper.Encode64(userName.trim() + ":" + password);
    await dvrDoLogin(auInfo, {
        userName: userName,
        password: CharsetHelper.Encode64(password)
    }, cb);
}

// dvr_doLogin
const dvrDoLogin = async (auInfo, userInfo, cb) => {
    let sendXml = getRequestStart(nvrType) +
        "<content><userName><![CDATA[" + userInfo.userName + "]]></userName>" +
        "<password><![CDATA[" + userInfo.password + "]]></password></content>" +
        getRequestEnd();
    let criteria = {
        "ip": ""
    }
    let res = await asyncAxiosXMLNVR(criteria, "doLogin", sendXml, null).then(res => {

        // console.log("dvrDoLogin, ", res, xmlToJson(res))
        // if ($("response>status", result).text() == "success") {
        //     sessionId = $("response>content>sessionId", result).text();
        //     if( sessionId.indexOf("{") !== -1 ||  sessionId.lastIndexOf("}") !== -1 ){
        //         sessionId = sessionId.substring(sessionId.indexOf("{")+1,sessionId.indexOf("}"))
        //     }
        //     webSession('auInfo_N9K', auInfo);
        //     webSession('sessionId',sessionId, true);
        //     $.cookie("sessionId", sessionId);
        //     webSession("userId", $("response>content>userId", result).text());
        //     if (cb) cb()
        // } else if ($("response>status", result).text() == "fail") {
        //     var errorCode = $("response>errorCode", result).text()
        //     alert('登录失败, 错误码：' + errorCode)
        // }
    });
}

const extractData = (data) => {
    // console.log("RAW DATA", data)
    let response = data.response
    if (response && response.content) {
        let content = response.content.length > 0 ? response.content[0] : null;
        if (content) {
            let returnObj = Object.create(content)
            for (let key in returnObj) {
                let data = content[key].length > 0 ? content[key][0] : content[key]
                if (key == 'sessionId' && data.substring(0, 1) == "{" && data.substring(data.length - 1) == "}") {
                    let trimmedData = data.replace(/{/g, "").replace(/}/g, "")
                    returnObj[key] = trimmedData
                } else {
                    returnObj[key] = data
                }
            }
            // console.log("TRIMMED", returnObj)
            returnObj.status = response.status
            return returnObj
        }
    }
    return response
}

// nvr_login including reqLogin and doLogin 
export const nvrLoginXml = async (userName, password, nvrIP, HttpPort, nvrType) => {
    var auInfo = CharsetHelper.Encode64(userName.trim() + ":")
    try {
        let criteria = {
            "ip": nvrIP,
            "port": HttpPort
        }
        let loginRes = await asyncAxiosXMLNVR(criteria, "reqLogin", getEmptyRequest(nvrType), 3000).then(async (res) => {
            // console.log("reqLogin,", res, xmlToJson(res.data))
            try {
                let extractedResponse = extractData(xmlToJson(res.data))
                // console.table(extractedResponse)
                if (extractedResponse.status && extractedResponse.status[0] == 'success') {
                    let { nonce, sessionId, softwareVersion, token } = extractedResponse
                    webSession(nvrType+"nonce", nonce)
                    webSession(nvrType+"token", token)
                    webSession(nvrType+"sessionId", sessionId)
                    // $.cookie("sessionId", sessionId)
                    let sendXml = getRequestStart(nvrType) +
                        "<content>" +
                        "<userName><![CDATA[" + userName + "]]></userName>" +
                        "<password><![CDATA[" + CryptoJS.SHA512(IPC_PASSWORD + "#" + nonce) + "]]></password></content>" +
                        getRequestEnd();
                    let criteria = {
                        "ip": nvrIP,
                        "port": HttpPort
                    }
                    let doLoginRes = await asyncAxiosXMLNVR(criteria, "doLogin", sendXml, null).then(res => {
                        let jsonRes = xmlToJson(res.data)
                        let doLoginData = extractData(jsonRes);
                        if (doLoginData.status && doLoginData.status[0] == 'success') {
                            let { userId } = doLoginData;
                            webSession('auInfo_N9K', auInfo)
                            webSession('userId', userId)
                            webSession("adminName", doLoginData.adminName ? doLoginData.adminName : "admin")
                            // if(cb){
                            //     cb();
                            // }
                            return {
                                success: true,
                                message: ""
                            }
                        } else {
                            let errorCode = doLoginData.errorCode[0]
                            let msg = "Error login to NVR. Error code is"+ errorCode
                            console.error(msg)
                            return {
                                success: false,
                                message: msg
                            }
                        }
                    })
                    return doLoginRes
                } else {
                    console.error("Request login NVR failed. Check connectivity")
                }
            } catch (err) {
                console.error(err)
                return {
                    success: false,
                    message: "error "+err
                }
            }
        }).catch(err => {
            console.error(err)
            return {
                success: false,
                message: "error "+err
            }
        })
        return loginRes
    } catch (err) {
        console.error(err)
        return {
            success: false,
            message: "error "+err
        }
    }

}

// sha512加密
const encryptSha512 = (pwd) => {
    let password = SparkMD5.hash(pwd)
    let nonce = webSession("nonce", null)
    nonce = nonce ? nonce : "";
    var hexHash = CryptoJS.SHA512(password + "#" + nonce)
    return hexHash
}







const CharsetHelper = {

    keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

    // 将Ansi编码的字符串进行Base64编码
    Encode64: function (input) {
        let output = "";
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;

        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }

            output = output +
                CharsetHelper.keyStr.charAt(enc1) +
                CharsetHelper.keyStr.charAt(enc2) +
                CharsetHelper.keyStr.charAt(enc3) +
                CharsetHelper.keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = null;
            enc1 = enc2 = enc3 = enc4 = null;
        } while (i < input.length);

        return output;
    },

    Decode64: function (input) {
        let output = "";
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;

        if (input.length % 4 != 0) {
            return "";
        }
        var base64test = /[^A-Za-z0-9\+\/\=]/g;
        if (base64test.exec(input)) {
            return "";
        }

        do {
            enc1 = CharsetHelper.keyStr.indexOf(input.charAt(i++));
            enc2 = CharsetHelper.keyStr.indexOf(input.charAt(i++));
            enc3 = CharsetHelper.keyStr.indexOf(input.charAt(i++));
            enc4 = CharsetHelper.keyStr.indexOf(input.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64) {
                output += String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output += String.fromCharCode(chr3);
            }

            chr1 = chr2 = chr3 = null;
            enc1 = enc2 = enc3 = enc4 = null;

        } while (i < input.length);

        return output;
    }
}

// queryDatesExistRec
export const queryDatesExistRec = async (nvrIp, devId, HttpPort, nvrType) => {
    let sendXml = getRequestStart(nvrType) +
        "<condition><chlId>" + devId + "</chlId></condition>" +
        getRequestEnd();
    let criteria = {
        "ip": nvrIp,
        "port": HttpPort
    }
    const res = await asyncAxiosXMLNVR(criteria, "queryDatesExistRec", sendXml, null);
    if (res && res.data) {
        let extractedResponse = extractData(xmlToJson(res.data))
        if (extractedResponse.status && extractedResponse.status[0] === 'success') {
            return extractedResponse.$;
        }
    } else {
        console.log(`queryDatesExistRec failed, nvrIp is: ${nvrIp}, devId is: ${devId}`);
    }
}