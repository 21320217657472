/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getCurrentUser } from '../../factories/auth';
import ClientBoard from './components/ClientBoard';


class ClientPage extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let userObj = getCurrentUser();
        return (
            <Container className='dashboard'>
                <Row>
                    <Col md={12}>
                        <Col md={12}>
                            <ClientBoard
                                userObj={userObj}
                            />
                        </Col>
                    </Col>
                </Row>
            </Container>
        );
    }

}
export default ClientPage;