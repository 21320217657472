/* eslint-disable */
import React, { Component } from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink,Card, CardBody, Col, Row, Button, Container, Input} from 'reactstrap';
import {connect } from 'react-redux';
import {bindActionCreators } from 'redux';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import {Modal, ModalHeader , ModalBody,ModalFooter} from 'reactstrap';
import {Trans} from "react-i18next";
import {addMultiUser, getCompanyList, getRoles} from '../../../redux/actions/userAction';
import UserAccessForm from './userAccessForm';

const backdropStyle = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    overflow: 'auto',
};

const modalStyle = function() {
    const top = 5;
    const left = 5;

    return {
        position: 'fixed',
        width: 800,
        height: 650,
        zIndex: 1040,
        top: top + '%',
        left: left + '%',
        border: '0px solid #ffffff',
        backgroundColor: 'white',
        // boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflow: 'auto',
        resize: 'both'
    };
};

class UserAddMultiModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            file:null,
        };
    }

    componentDidMount(){   
        this.props.getCompanyList()
        this.props.getRoles();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // alert(nextProps.modules.groupType);
        if (nextProps.show === true) {
            this.setState({
                showModal: true,
                file:null
            });
        }
        if (nextProps.companyList && nextProps.companyList.length > 0){
            this.setState({
                company: nextProps.companyList[0].code,
                
            });
        }
        if (nextProps.roleList && nextProps.roleList.length > 0){
            this.setState({
                role: nextProps.roleList[0].name,
            });
        }
    }
    renderBackdrop=(props) => {
        return <div {...props} style={backdropStyle} />;
    };


    onFileChangeHandler = (e) => {
        this.setState({ file: e.target.files[0] });
    }

    onSubmit = () => {

        if (!sessionStorage.getItem('companyCode') || !sessionStorage.getItem('currentUser') ){ 
            return;
        }
        if (this.validateForm()){
            var formdata = new FormData();
            formdata.append("file", this.state.file);
            formdata.append("userType", "pp");
            formdata.append("status", "A");
            formdata.append("companyCode",this.state.company ? this.state.company : sessionStorage.getItem('companyCode') );
            formdata.append("department","");
            formdata.append("businessUnit","");
            formdata.append("creator",sessionStorage.getItem('currentUser'));
            formdata.append("createTime", new Date().toISOString().split('.')[0].replace('T', ' '));
            this.props.addMultiUser(formdata);
            this.onClose();
        }else{
            alert('v no');
        }
    };

    onClose = () => {
        this.setState({
            showModal: false
        });
    };

    validateForm = () => {
        return this.state.file!=null;
    }

    render() {
        let labalStyle = {textAlign: 'right'} ;
        return(
            // <Modal
            //     // onHide={this.onClose}
            //     style={modalStyle()}
            //     aria-labelledby="modal-label"
            //     show={this.state.showModal}
            //     renderBackdrop={this.renderBackdrop}
            // >
            <Modal
                // style={modalStyle()}
                isOpen={this.state.showModal} 
                toggle={this.props.toggle}
                renderBackdrop={this.renderBackdrop}
                size = 'lg'
            >
                 {/* <div> */}
                {/* <ModalHeader toggle={this.props.toggle}>New User</ModalHeader > */}
                <ModalHeader toggle={this.onClose}>New User</ModalHeader >
                 <ModalBody>
                        <Col md={12}>
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Upload File</span>
                                </Col>
                                <Col md={8}>
                                <div style={{ float: "left" }}>
                                    <p style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> Only accept .csv files</p>
                                    <input type="file" name="file" accept=".csv" onChange={this.onFileChangeHandler} />
                                </div>
                                </Col>
                            </Row>
                            </Col>
                        </ModalBody>
                        <ModalFooter>
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled = {!this.validateForm()} >
                            Submit
                        </Button>
                        <Button outline color='secondary' type="reset" onClick={this.onClose}>
                            Close
                        </Button>
                        </ModalFooter>
                {/* </div> */}
            </Modal>
        )
    }
}

// export default UserAddModal;

const mapStateToProps = (state) => {
    return state.user;
  };
  
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({addMultiUser, getCompanyList, getRoles}, dispatch);
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(UserAddMultiModal);