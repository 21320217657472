/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Col, Container, Row } from 'reactstrap';
import { VIDEO_WS_ROOT } from '../../../constants/appConstants';

// const WS_ROOT = 'wss://wssdemo.itsp-inc.com';
const WS_ROOT = 'ws://192.168.161.9';

class VideoMpegPlayer extends Component {
    constructor(props){
        super(props);
        this.myPlayer = React.createRef();
        this.handleLoad = this.handleLoad.bind(this);
        this.state = {
            videoSrc: ""
        }
    }
    componentDidMount(){
        // this.ws = new WebSocket('ws://192.168.2.63:8081/webSocketService');
        // // this.ws = new WebSocket(this.props.url);
        // this.ws.onopen = (e) => {
        //     console.log('web socket connection established!')
        // }
        // this.ws.onmessage = (e) => {
        //     var data = JSON.parse(e.data);
        //     if (data.code === 0) {
        //         console.log(e.data)
        //     } else if (data.code === 201) {
        //         // $("#show_video").attr("src", "data:image/*;base64," + data.data)
        //         this.setState({
        //             videoSrc:  "data:image/*;base64," + data.data
        //         });
        //     }
        // }

        // const canvas = this.myPlayer.current;
        // const ctx = canvas.getContext("2d");
        // ctx.scale(1, 1);

        // this.player = new JSMpeg.Player('ws://localhost:9999', {
        //     // canvas: document.getElementById('canvas') // Canvas should be a canvas DOM element
        //     // canvas: ReactDOM.findDOMNode(this.refs.player)
        //     canvas: this.myPlayer.current 
        //     // canvas: this.MyCanvas 
        // });

        // this.player = new JSMpeg.Player('wss://wssdemo.itsp-inc.com:8088', {
        //     // canvas: document.getElementById('canvas') // Canvas should be a canvas DOM element
        //     // canvas: ReactDOM.findDOMNode(this.refs.player)
        //     canvas: this.myPlayer.current 
        //     // canvas: this.MyCanvas 
        // });

        // this.player = new JSMpeg.Player('ws://192.168.161.9:8098', {
        //     // canvas: document.getElementById('canvas') // Canvas should be a canvas DOM element
        //     // canvas: ReactDOM.findDOMNode(this.refs.player)
        //     canvas: this.myPlayer.current 
        //     // canvas: this.MyCanvas 
        // });

        // let wsUrl = `${WS_ROOT}:${this.props.port}`;
        // console.log('chid - video player',this.props.chid)
        let ch = this.props.ch ? this.props.ch : 'nvr';
        // let wsUrl = `ws://localhost:8099/socketSecure/ch/${this.props.chid}`;
        let wsUrl = `${VIDEO_WS_ROOT}/socketSecure/${ch}/${this.props.chid}`;
        // console.log("===============VIDEO LINK ==============", wsUrl)
        this.player = new JSMpeg.Player(wsUrl, {
            // canvas: document.getElementById('canvas') // Canvas should be a canvas DOM element
            // canvas: ReactDOM.findDOMNode(this.refs.player)
            canvas: this.myPlayer.current 
            // canvas: this.MyCanvas 
        });


        // window.addEventListener('load', this.handleLoad);

        // var c = this.refs.player;
        // var ctx = c.getContext("2d");
        // ctx.scale(0.5, 0.5);
        // const canvas = this.myPlayer.current
        // // const canvas = this.MyCanvas
        // console.log("get canvas", canvas)

        // to scale the <canvas> element itself
        // canvas.width = 500;
        // canvas.height = 500;

        // to scale the drawings, use the context
        // const ctx = canvas.getContext("webgl");
        //  
        // // ctx.scale(1, 1);
        // ctx.viewport(0, 0, canvas.width, canvas.height);
    }

    // componentDidUpdate(){
    //     // this.myPlayer = React.createRef();
    //     console.log('chid - video player',this.props.chid)
    //     let ch = this.props.ch ? this.props.ch : 'nvr';
    //     // let wsUrl = `ws://localhost:8099/socketSecure/ch/${this.props.chid}`;
    //     let wsUrl = `${VIDEO_WS_ROOT}/socketSecure/${ch}/${this.props.chid}`;
    //     console.log("===============VIDEO LINK ==============", wsUrl)
    //     this.player = new JSMpeg.Player(wsUrl, {
    //         // canvas: document.getElementById('canvas') // Canvas should be a canvas DOM element
    //         // canvas: ReactDOM.findDOMNode(this.refs.player)
    //         canvas: this.myPlayer.current 
    //         // canvas: this.MyCanvas 
    //     });
    // }

    componentWillUnmount(){
        try{
            if (this.player) {
                this.player.destroy();
                 
            }
        }catch(e){
             
        }
       
    }
// ---------------------------------------
handleLoad() {
    console.log("windows load")
    const canvas = this.myPlayer.current;
    const ctx = canvas.getContext("2d");
    console.log("ctx", ctx)
    ctx.scale(0.5, 0.5);
}
// ---------------------------------------
    renderMpegPlayer = () => {
        return(
            <Row>
                {/* <Col md={12} style={{textAlign: "center"}}> */}
                <Col md={12}>
                    {/* <canvas id="canvas" ref='player' width="300" height="300"></canvas> */}
                    <canvas id="canvas" ref={this.myPlayer} style={{"width": "700px", "height": "500px"}}></canvas>
                    {/* <canvas id="canvas" ref={this.myPlayer} style={{"width": "100%", "height": "100%"}}></canvas> */}
                    {/* <canvas id="canvas" ref={node => this.MyCanvas = node} width="100" height="100"></canvas> */}
                </Col>
            </Row>
        )
    }

    render(){
        return (
            <Container style={{padding: "20px"}}>
                {this.props.chid && this.renderMpegPlayer()}
            </Container>
        );
    }
}

export default VideoMpegPlayer