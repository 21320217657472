/*eslint-disable*/
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import AddContactForm from "./AddContactForm";
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { toast } from "react-toastify";
import { FaUserPlus } from 'react-icons/fa';


const ContactModal = props => {
  // const { buttonLabel, className } = props;
  const userObj = getCurrentUser();
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isEmailChecked, setIsEmailChecked] = useState(true);
  const [isPhoneChecked, setIsPhoneChecked] = useState(true);

  const toggle = () => {
    setModal(!modal)
    setName('')
    setEmail('')
    setPhone('')
  };


  const validatePhoneNumber = (phoneNum) => {
    var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return (phoneNum && regex.test(phoneNum));
  }

  const validateEmail = (email) => {
    // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
    // return  (this.state.email && this.state.email.match(regex) !== null);
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (email && regex.test(email));
  }

  const handleSubmit = () => {
    if (userObj === null || userObj.userName.length === 0) {
      // this.props.history.push('/log_out');
      toast.error('Can NOT get current user information, Please re login');
      return;
    }

    if (!name || name.length === 0) {
      toast.error('Name can NOT be empty');
      return;
    }

    if (!validateEmail(email)) {
      toast.error('Email address is not valid');
      return;
    }

    if (!validatePhoneNumber(phone)) {
      toast.error('Phone number is not valid');
      return;
    }

    let contactObj = {
      id: 0, //0 or not exist, will create new contact
      name: name, //if id not exist  but name exist, update
      email: email,
      phone: phone,
      emailenable: isEmailChecked,
      phoneenable: isPhoneChecked,
      owner: userObj.userName,
      companycode: userObj.companyCode,
      createdtime: null
    };

    props.upsertContact(contactObj);
    toggle();

  };
  const handleNameChange = e => {

    setName(e.target.value);
  };

  const handlePhoneChange = e => {

    setPhone(e.target.value);
  };

  const handleEmailChange = e => {

    setEmail(e.target.value);
  }

  const handleIsEmailEnableChange = e => {

    setIsEmailChecked(e.target.checked);
  }

  const handleIsPhoneEnableChange = e => {


    setIsPhoneChecked(e.target.checked);
  }

  const onClose = () => {
    this.setState({
      showModal: false
    });
  };

  return (
    <div>
      <Button id="ButtonAdd" style={{ color: "white", padding: "7px 22px" }} /*className="btn btn-success"*/ /*color="custom"*/ onClick={toggle}>
        {/* {props.label} */}
        {/* <i class="fas fa-user-plus fa-lg"></i> */}
        <FaUserPlus/>
      </Button>
      <UncontrolledTooltip placement="top" target="ButtonAdd">
        Add a Receiver
      </UncontrolledTooltip>
      <Modal
        isOpen={modal}
        toggle={toggle}
      // className={className}
      >
        <ModalHeader toggle={toggle}>Add a New Receiver</ModalHeader>
        <ModalBody>
          <AddContactForm
            name={name}
            email={email}
            phone={phone}
            isEmailChecked={isEmailChecked}
            isPhoneChecked={isPhoneChecked}
            onFormSubmit={handleSubmit}
            onNameChange={handleNameChange}
            onPhoneChange={handlePhoneChange}
            onEmailChange={handleEmailChange}
            onEmailEnableChange={handleIsEmailEnableChange}
            onPhoneEnableChange={handleIsPhoneEnableChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button outline color="custom" onClick={handleSubmit}>
            Submit
          </Button>{" "}
          <Button outline type="reset" color="secondary" onClick={toggle}>
            Cancel 
          </Button> 
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ContactModal;
