/* eslint-disable */
import axios from 'axios';
import PropTypes from 'prop-types';
// import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
import {
    POWER_GET_ASSET_HISTORY_START,
    POWER_GET_ASSET_HISTORY_SUCCEED,
    POWER_GET_ASSET_HISTORY_CLEAR,
    WEATHER_GET_ASSET_DATA_SUCCEED,
    WEATHER_GET_ASSET_DATA_FAILED,
    WEATHER_GET_ASSET_DATA_CLEAR,
    WEATHER_SET_STORE_ASSET_DATA,
    WEATHER_GET_ALARM_COUNT_SUCCEED,
    WEATHER_GET_ALARM_COUNT_FAILED ,
    WEATHER_GET_ALARM_COUNT_CLEAR,
    WEATHER_GET_ASSET_HISTORY_START,
    WEATHER_GET_ASSET_HISTORY_SUCCEED,
    WEATHER_GET_ASSET_HISTORY_FAILED,
    WEATHER_GET_ASSET_HISTORY_CLEAR,
    WEATHER_SET_STORE_ASSET_HISTORY_DATA,
    WEATHER_SUB_DATA_UPDATED,
    WEATHER_GET_ALL_CONFIG_SUCCEED,
    WEATHER_GET_ALL_CONFIG_FAILED, 
    WEATHER_POST_CONFIG_SUCCEED, 
    WEATHER_POST_CONFIG_FAILED,
    WEATHER_GET_HOURLYALARM_COUNT_SUCCEED,
    WEATHER_GET_HOURLYALARM_COUNT_FAILED,
    WEATHER_UPDATE_ASSET_HISTORY_SUCCEED,
    WEATHER_GET_HOURLYALARM_COUNT_CLEAR,
    WEATHER_GET_24_HISTORY_SUCCEED,
    WEATHER_GET_24_HISTORY_FAILED,
    WEATHER_GET_1_MONTH_HISTORY_SUCCEED,
    WEATHER_GET_1_MONTH_HISTORY_FAILED,
    WEATHER_UPDATE_24_HISTORY_SUCCEED,
    WEATHER_GET_24_HISTORY_CLEAR,
    WEATHER_UPDATE_24_HISTORY,
    WEATHER_GET_ASSET_DATA_START,
    WEATHER_GET_ALARM_COUNT_START,
    WEATHER_GET_HOURLYALARM_COUNT_START,
    WEATHER_GET_24HYALARM_COUNT_START, 
    WEATHER_GET_24HALARM_COUNT_SUCCEED,
    WEATHER_GET_24HALARM_COUNT_FAILED, 
    WEATHER_GET_24HALARM_COUNT_CLEAR,
    WEATHER_GET_MONTHLYALARM_COUNT_START,
    WEATHER_GET_MONTHLYALARM_COUNT_SUCCEED,
    WEATHER_GET_MONTHLYALARM_COUNT_FAILED,
    WEATHER_GET_MONTHLYALARM_COUNT_CLEAR,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_START,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_SUCCEED,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_FAILED,
    WEATHER_GET_QUARTERMONTHALARM_COUNT_CLEAR,
    WEATHER_GET_6MONTHSALARM_COUNT_START,
    WEATHER_GET_6MONTHSALARM_COUNT_SUCCEED,
    WEATHER_GET_6MONTHSALARM_COUNT_FAILED,
    WEATHER_GET_6MONTHSALARM_COUNT_CLEAR,
    WEATHER_GET_ALARM_COUNT_LIST_START,
    WEATHER_GET_ALARM_COUNT_LIST_SUCCEED,
    WEATHER_GET_ALARM_COUNT_LIST_FAILED,
    WEATHER_GET_ALARM_COUNT_LIST_CLEAR,
    WEATHER_GET_ROLLING24HALARM_COUNT_SUCCEED,
    WEATHER_GET_ROLLING24HALARM_COUNT_FAILED,
    WEATHER_GET_ROLLING24HALARM_COUNT_CLEAR,
    WEATHER_GET_ROLLING24HALARM_COUNT_START,
} from '../../constants/actionTypes';
import {
    WEB_SERVICE_ROOT,
    WEB_SERVICE_GET_GROUP_ASSETS_DATA,
    // WEB_SERVICE_GET_GROUP_ASSETS_DATA3,
    WEB_SERVICE_GET_COMPANY_ASSETCLASS,
    WEB_SERVICE_WEATHER_ALARM_COUNT,
    WEB_SERVICE_WEATHER_ALARM_COUNT_LIST,
    WEB_SERVICE_WEATHER_MONTHLYALARM_COUNT,
    WEB_SERVICE_WEATHER_GROUP_DATA,
    WEB_SERVICE_WEATHER_CONFIG_GETALL,
    WEB_SERVICE_WEATHER_CONFIG_POST,
    WEB_SERVICE_WEATHER_GET_TIME_POINT_DATA,
    WEB_SERVICE_WEATHER_HOURLYALARM_COUNT,
    WEB_SERVICE_WEATHER_CONFIG_GETBYAPPNAME,
} from '../../constants/appConstants';
// import {
//     ATTRIBUTE_TYPE,
// } from '../../constants/dataConstants';
import { 
    axiosGet, 
    axiosPost, 
    processAssetData,
    expandAssetAttributes,
} from '../../factories/utils';
import moment from 'moment';


export function getWeatherAssetData(criteria){ 
    let paramObj = {
        // url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_GROUP_ASSETS_DATA}`,
        // url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_GROUP_DATA}`,
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_GET_TIME_POINT_DATA}`,
        criteria: criteria,
        logText: 'Get Weather Asset Data Url ',
        startType: WEATHER_GET_ASSET_DATA_START,
        // sccessType: WEATHER_GET_ASSET_DATA_SUCCEED,
        failedType: WEATHER_GET_ASSET_DATA_FAILED,
        errorMessage: 'Failed to get weather asset data.',
        successCallback: setStoreAssetModifiedData,
    };
    // 
    return(axiosPost(paramObj));
}

export function getAssetDataWithTimeRange(params){

}

// export function getWeatherAssetData(creteria){ 
//     let paramObj = {
//         url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_GET_TIME_POINT_DATA}`,
//         criteria: creteria,
//         logText: 'Get Weather Asset Data Url ',
//         sccessType: '',
//         failedType: WEATHER_GET_ASSET_DATA_FAILED,
//         errorMessage: 'Failed to get weather asset data.',
//         callback: setStoreAssetModifiedData,
//     };

//     return(axiosGet(paramObj));
// }

export function getWeatherAssetDataFun(creteria, startType, successType, failedType, successCallback){ 
    console.log("CALLBACK ", successCallback)
    let paramObj = {
        // url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_GROUP_ASSETS_DATA}`,
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_GROUP_DATA}`,
        criteria: creteria,
        logText: 'Get Weather Asset Data Url ',
        startType: startType,
        sccessType: successType,
        failedType: failedType,
        errorMessage: 'Failed to get weather asset data.',
        successCallback: successCallback,
        passedParameters: creteria,
    };

    return(axiosPost(paramObj));
}

function getPowerAssetDataFun(creteria, startType, successType, failedType, successCallback){ 
    let paramObj = {
        // url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_GROUP_ASSETS_DATA}`,
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_GROUP_DATA}`,
        criteria: creteria,
        logText: 'Get Power Asset Data Url ',
        startType: startType,
        sccessType: successType,
        failedType: failedType,
        errorMessage: 'Failed to get power asset data.',
        successCallback: successCallback,
        passedParameters: creteria,
    };

    return(axiosPost(paramObj));
}

function setStoreAssetModifiedData(data){
    console.log("setStoreAssetModifiedData data", data)

    let modifiedData = processAssetData(data);
    console.log("modifiedData", modifiedData)
    let expandedData = expandAssetAttributes(modifiedData);
    console.log("expandedData", expandedData)
    return {
        type: WEATHER_SET_STORE_ASSET_DATA,
        payloada: data,
        payloadb: modifiedData,
        payloadc: expandedData,
    }
}

// export function getWeatherAssetData(creteria){
//     return(
//         getWeatherAssetDataFun(creteria, WEATHER_GET_ASSET_DATA_SUCCEED, WEATHER_GET_ASSET_DATA_FAILED, setStoreAssetModifiedData)
//     )
// }

export function updateWeatherAssetData (assetData, subData){
    let updatedData = updatePubSubDevice(assetData, subData);
    let expantedData = expandAssetAttributes(updatedData);
     
     
    return(dispatch => {
        dispatch({
            type: WEATHER_SUB_DATA_UPDATED,
            payloada: updatedData,
            payloadb: expantedData,
        });
    });
}

function updatePubSubDevice(assetData, subData){
    //  
    //  
    //  
    //console.log("Data here")
    // console.log(assetData)
    let newAssetData = JSON.parse(JSON.stringify(assetData));
    // const subKeys = {battery: 'BatteryVoltage', internalTemp: 'InternalTemperature', alarmStatus: 'CurrentDigitalInputState', levelColor: 'Alarm'};
    const subKeys = {battery: 'BatteryVoltage', internalTemp: 'InternalTemperature', alarmStatus: 'CurrentDigitalInputState', levelColor: 'Alarm', lastOccupancy:'LastOccupancy',lstMsgTime:'LastMessageTime', occupancyBattery: 'OccupancyBattery', averageData: 'AverageData', level1:'Level1', level2:'Level2', level3:'Level3', levelTotal:'LevelTotal',averageTemperature: 'AverageTemperature',

    averageLODLast: 'AverageLODLast',
    temperature:'Temperature',
    current: 'Current',
    vibration: 'Vibration'};
    if (subData && subData.device) {
        let updateAsset = newAssetData.find((item, index)=>(item.assetid === subData.device.assetid));
        //  
        if (updateAsset && updateAsset.attributes) {
            //console.log(updateAsset)
            updateAsset.timestamp = subData.lstMsgTime;
            Object.entries(subKeys).forEach(([key, value], index)=>{
                 
                let foundAttr = updateAsset.attributes.find((attribute)=>(attribute.attributename === value && attribute.deviceid==subData.device.deviceid));
                if(updateAsset.grouping=='parking'){
                    //console.log('true1')
                    foundAttr = updateAsset.attributes.find((attribute)=>(attribute.attributename === value));
                }
                if (foundAttr) {
                    
                    foundAttr.value = subData[key];
                    foundAttr.attritime = subData.lstMsgTime;
                    if (foundAttr.msg) {
                        foundAttr.msg.msgtimestamp = subData.lstMsgTime;
                    } 
                } else{
                     
                    let newAttr = {
                        attributeid: null,
                        attributename: value,
                        displayname: value,
                        value: subData[key],
                        display: true,
                        theme: null,
                        type: "device",
                        deviceid: subData.device.deviceid,
                        msg: {msgid: null, msgtimestamp: subData.lstMsgTime,},
                        attritime: subData.lstMsgTime,
                        datatype: null
                    }
                    updateAsset.attributes.push(newAttr);
                }
            });
        }

    }
    //  
    return newAssetData;
}

export function updatePubSubAssetData (assetData, deviceObj){
    let updatedData = updatePubSubDeviceMessage(assetData, deviceObj);
    let expantedData = expandAssetAttributes(updatedData);
     
     
    return(dispatch => {
        dispatch({
            type: WEATHER_SUB_DATA_UPDATED,
            payloada: updatedData,
            payloadb: expantedData,
        });
    });
}

function updatePubSubDeviceMessage(assetData, deviceObj){
    let newAssetData = JSON.parse(JSON.stringify(assetData));
    if (newAssetData, deviceObj) {
        let assetObj = newAssetData.find((item) =>(item.assetid === deviceObj.assetid));
        if (assetObj.attributes && deviceObj.msgs && deviceObj.msgs.length && deviceObj.msgs[deviceObj.msgs.length - 1].msg) {
            let msgObj = deviceObj.msgs[deviceObj.msgs.length - 1].msg;
            Object.entries(msgObj).forEach(([key, value]) => {
                let foundAttr = assetObj.attributes.find((attribute)=>(attribute.attributename === key));
                if (foundAttr) {
                    foundAttr.value = value.value;
                    foundAttr.attritime = deviceObj.lastmessagetime;
                    if (foundAttr.msg) {
                        foundAttr.msg.msgtimestamp = deviceObj.lastmessagetime;
                    } 
                }
                else{
                     
                    let newAttr = {
                        attributeid: null,
                        attributename: key,
                        displayname: value.displayname,
                        value: value.value,
                        display: true,
                        theme: null,
                        type: "device",
                        deviceid: deviceObj.deviceid,
                        msg: {msgid: null, msgtimestamp: deviceObj.lastmessagetime,},
                        attritime: deviceObj.lastmessagetime,
                        datatype: null
                    }
                    assetObj.attributes.push(newAttr);
                }
            });
        }
    }
    return newAssetData;
}
function setStoreAssetModifiedHistoryData(data){
    let modifiedData = processAssetData(data);
    console.log('modifiedData', modifiedData)
    let expantedData = expandAssetAttributes(modifiedData);    
    console.log('expantedData', expantedData) 
    return {
        type: WEATHER_SET_STORE_ASSET_HISTORY_DATA,
        payloada: data,
        // payloadb: modifiedData,
        payloadb: expantedData,
    }
}

const historyCreteria = {
    entity: PropTypes.number.isRequired,
    starttime: PropTypes.string.isRequired,
    endtime: PropTypes.string.isRequired,
}

export function getWeatherAssetHistoryData(historyCreteria){ 
    let creteria = {
        ...historyCreteria,
        entitygroup: [],
        latest: '0',
        themeid: ''
    };
    return(
        // getWeatherAssetDataFun(creteria, WEATHER_GET_ASSET_HISTORY_START, WEATHER_GET_ASSET_HISTORY_SUCCEED, WEATHER_GET_ASSET_HISTORY_FAILED, setStoreAssetModifiedHistoryData)
        getWeatherAssetDataFun(creteria, WEATHER_GET_ASSET_HISTORY_START, WEATHER_GET_ASSET_HISTORY_SUCCEED, WEATHER_GET_ASSET_HISTORY_FAILED, setStoreAssetModifiedHistoryData)
    )
}

export function getAssetHistoryData(historyParams){
    console.log("getAssetHistoryData called")
    // debugger;
    return(
        getWeatherAssetDataFun(historyParams, WEATHER_GET_ASSET_HISTORY_START, WEATHER_GET_ASSET_HISTORY_SUCCEED, WEATHER_GET_ASSET_HISTORY_FAILED, setStoreAssetModifiedHistoryData)
    )
}

export function getPowerAssetHistoryData(historyCreteria){
    let creteria = {
        ...historyCreteria,
        entitygroup: [],
        latest: '0',
        themeid: ''
    };
    return(
        getPowerAssetDataFun(creteria, POWER_GET_ASSET_HISTORY_START, POWER_GET_ASSET_HISTORY_SUCCEED, WEATHER_GET_ASSET_HISTORY_FAILED, null)
    )
}

export function clearPowerAssetHistoryData(){
    return(
        dispatch=>{
            dispatch(
                {
                    type: POWER_GET_ASSET_HISTORY_CLEAR,
                    message: 'Clear All Power Asset History Data',
                }
            );
        }
    )
}

export function pubsubUpdateWeatherAssetHistoryData(pubsubData, startDateMoment, endDateMoment) {
    return(
        dispatch=>{
            dispatch(
                {
                    type: WEATHER_UPDATE_ASSET_HISTORY_SUCCEED,
                    payload: pubsubData,
                    startDateMoment: startDateMoment,
                    endDateMoment: endDateMoment,
                }
            );
        }
    )
}

export function getWeatherAsset24HData(assetCreteria) {
    const now = moment();
     
    const before24 = moment().subtract(1, 'days');
     
    const creteria = {
        ...assetCreteria,
        entitygroup: [],
        // starttime: now.utc().format('YYYY-MM-DDThh:mm:ss'),
        // endtime: before24.utc().format('YYYY-MM-DDThh:mm:ss'),
        starttime: before24.utc().valueOf(),
        endtime: now.utc().valueOf(),
        latest: '0',
        themeid:'',
    }
    return(
        getWeatherAssetDataFun(creteria, null, WEATHER_GET_24_HISTORY_SUCCEED, WEATHER_GET_24_HISTORY_FAILED, null)
    )
}

/* Get One Month Data */
export function getWeatherAsset1MonthData(assetCreteria) {
    const now = moment();
     
    const before24 = moment().subtract(1, 'month');
     
    const creteria = {
        ...assetCreteria,
        entitygroup: [],
        // starttime: now.utc().format('YYYY-MM-DDThh:mm:ss'),
        // endtime: before24.utc().format('YYYY-MM-DDThh:mm:ss'),
        starttime: before24.utc().valueOf(),
        endtime: now.utc().valueOf(),
        latest: '0',
        themeid:'',
    }
    return(
        getWeatherAssetDataFun(creteria, 
            null, WEATHER_GET_1_MONTH_HISTORY_SUCCEED, 
            WEATHER_GET_1_MONTH_HISTORY_FAILED, null)
    )
}

export function pubsubUpdateWeatherAsset24HData(pubsubData) {
    const now = moment();
    const before24 = moment().subtract(1, 'days');

    return(
        dispatch=>{
            dispatch(
                {
                    type: WEATHER_UPDATE_24_HISTORY_SUCCEED,
                    payload: pubsubData,
                    startDateMoment: before24,
                    endDateMoment: now,
                }
            );
        }
    )
}

export function getCompanyAssetClass(){
    // return dispatch => axios({
    //     url:  `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_ASSETCLASS}`,
    //     method: 'get'
    //  }).then(res => {
    //     return res;
    //  }).catch(err => {
    //      
    //  })

    return axios({
        url:  `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_ASSETCLASS}`,
        method: 'get'
    });
}

export function clearWeatherAssetData(){
    return(
        dispatch=>{
            dispatch(
                {
                    type: WEATHER_GET_ASSET_DATA_CLEAR,
                    message: 'Clear All Weather Asset Data',
                }
            );
        }
    )
}

export function clearWeatherAssetHistoryData(){
    return(
        dispatch=>{
            dispatch(
                {
                    type: WEATHER_GET_ASSET_HISTORY_CLEAR,
                    message: 'Clear All Weather Asset History Data',
                }
            );
        }
    )
}

const weatherCountCreteria = {
    assetid: PropTypes.number.isRequired,
    starttime: PropTypes.string.isRequired,
    endtime: PropTypes.string.isRequired,
}

export function getWeatherAlarmCount(weatherCountCreteria){
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_ALARM_COUNT}`,
        criteria: weatherCountCreteria,
        logText: 'Get Weather Alarm Count Url ',
        startType: WEATHER_GET_ALARM_COUNT_START,
        sccessType: WEATHER_GET_ALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_ALARM_COUNT_FAILED,
        errorMessage: 'Failed to get weather alarm count data.',
        // successCallback: setStoreAssetModifiedData,
    };

    return(axiosGet(paramObj));
}

export function getWeatherAlarmCountList(weatherCountCriteria) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_ALARM_COUNT_LIST}`,
        criteria: weatherCountCriteria,
        logText: 'Get Weather Alarm Count List Url ',
        startType: WEATHER_GET_ALARM_COUNT_LIST_START,
        sccessType: WEATHER_GET_ALARM_COUNT_LIST_SUCCEED,
        failedType: WEATHER_GET_ALARM_COUNT_LIST_FAILED,
        errorMessage: 'Failed to get weather alarm count list data.',
    };

    return(axiosGet(paramObj));
}

export function getWeather6MonthsAlarmCount(weatherCountCriteria) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_ALARM_COUNT}`,
        criteria: weatherCountCriteria,
        logText: 'Get Weather 6 Months Alarm Count Url.',
        startType: WEATHER_GET_6MONTHSALARM_COUNT_START,
        sccessType: WEATHER_GET_6MONTHSALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_6MONTHSALARM_COUNT_FAILED,
        errorMessage: 'Failed to get Weather 6 Months Alarm Count Data.'
    };

    return (axiosGet(paramObj));
}
export function getWeatherQuarterMonthAlarmCount(weatherCountCreteria) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_ALARM_COUNT}`,
        criteria: weatherCountCreteria,
        logText: 'Get Weather Quarter Month Alarm Count Url.',
        startType: WEATHER_GET_QUARTERMONTHALARM_COUNT_START,
        sccessType: WEATHER_GET_QUARTERMONTHALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_QUARTERMONTHALARM_COUNT_FAILED,
        errorMessage: 'Failed to get Weather Quarter Month Alarm Count Data.'
    };

    return(axiosGet(paramObj));
}
export function geteWeatherMonthlyAlarmCount(weatherCountCreteria) {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_ALARM_COUNT}`,
        criteria: weatherCountCreteria,
        logText: 'Get Weather Monthly Alarm Count Url.',
        startType: WEATHER_GET_MONTHLYALARM_COUNT_START,
        sccessType: WEATHER_GET_MONTHLYALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_MONTHLYALARM_COUNT_FAILED,
        errorMessage: 'Failed to get Weather Monthly Alarm Count Data.'
    };

    return(axiosGet(paramObj))
}

export function getWeatherHourlyAlarmCount(weatherCountCreteria){
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_HOURLYALARM_COUNT}`,
        criteria: weatherCountCreteria,
        logText: 'Get Weather Alarm Count Url ',
        startType: WEATHER_GET_HOURLYALARM_COUNT_START,
        sccessType: WEATHER_GET_HOURLYALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_HOURLYALARM_COUNT_FAILED,
        errorMessage: 'Failed to get weathe alarm count data.',
        // successCallback: setStoreAssetModifiedData,
        passedParameters: weatherCountCreteria,
    };

    return(axiosGet(paramObj));
}
export function getSnowRolling24HAlarmCount(assetid, cntHours = 1) {
    const startDateStr = moment().subtract(1, "day").utc().format('YYYY-MM-DD');

    const momentHour = moment().toDate().getHours();
    const criteria = {
        assetid: assetid ? assetid : 0,
        starttime: `${startDateStr}:${momentHour + 1}:24`,
        cntHours:cntHours,
    }
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_HOURLYALARM_COUNT}`,
        criteria: criteria,
        logText: 'Get Weather Rolling 24H Alarm Count Url',
        startType:  WEATHER_GET_ROLLING24HALARM_COUNT_START,
        sccessType: WEATHER_GET_ROLLING24HALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_ROLLING24HALARM_COUNT_FAILED,
        errorMessage: 'Failed to get weather rolling 24h alarm count data.',
        // successCallback: setStoreAssetModifiedData,
        passedParameters: criteria,
    };

    return(axiosGet(paramObj));
}

export function getSnow24HAlarmCount(assetid, cntHours = 1){
    const startDateStr = moment().utc().format('YYYY-MM-DD');
    const momentHour = moment().toDate().getHours();
    const criteria = {
        assetid: assetid ? assetid : 0, //1118,
        starttime: `${startDateStr}:00:${momentHour + 1}`, //`${startDateStr}:${startTime.value}:${endTime.value}`,
        cntHours: cntHours, //1,//'2020-02-27',
    }
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_HOURLYALARM_COUNT}`,
        criteria: criteria,
        logText: 'Get Weather 24H Alarm Count Url ',
        startType:  WEATHER_GET_24HYALARM_COUNT_START,
        sccessType: WEATHER_GET_24HALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_24HALARM_COUNT_FAILED,
        errorMessage: 'Failed to get weathe 24h alarm count data.',
        // successCallback: setStoreAssetModifiedData,
        passedParameters: criteria,
    };
    return(axiosGet(paramObj));
}

export function getLightning24HAlarmCount(assetid, cntHours = 1){
    const startDateStr = moment().utc().format('YYYY-MM-DD');
    const criteria = {
        assetid: assetid ? assetid : 0, //1118,
        starttime: `${startDateStr}:00:24`, //`${startDateStr}:${startTime.value}:${endTime.value}`,
        cntHours: cntHours, //1,//'2020-02-27',
    }
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_HOURLYALARM_COUNT}`,
        criteria: criteria,
        logText: 'Get Weather 24H Alarm Count Url ',
        startType:  WEATHER_GET_24HYALARM_COUNT_START,
        sccessType: WEATHER_GET_24HALARM_COUNT_SUCCEED,
        failedType: WEATHER_GET_24HALARM_COUNT_FAILED,
        errorMessage: 'Failed to get weathe 24h alarm count data.',
        // successCallback: setStoreAssetModifiedData,
        passedParameters: criteria,
    };
    return(axiosGet(paramObj));
}

export function clearWeatherAlarmCount(){
    return(dispatch=>{
        dispatch({
            type: WEATHER_GET_ALARM_COUNT_CLEAR,
            message: 'Clear All Weather Alarm count.',
        });
    });
}

export function clearWeatherSixMonthsAlarmCount() {
    return (dispatch => {
        dispatch({
            type: WEATHER_GET_6MONTHSALARM_COUNT_CLEAR,
            message: 'Clear All Weather 6 Months Alarm count.',
        });
    });
}

export function clearWeatherQuarterMonthAlarmCoutn() {
    return (dispatch => {
        dispatch({
            tpye: WEATHER_GET_QUARTERMONTHALARM_COUNT_CLEAR,
            message: 'Clear All Weather Quater Month Alarm count.',
        });
    });
}

export function clearWeatherMonthlyAlarmCount() {
    return (dispatch => {
        dispatch({
            type: WEATHER_GET_MONTHLYALARM_COUNT_CLEAR,
            message: 'Clear All Weather Monthly Alarm Count.',
        });
    });
}

export function clearWeatherHourlyAlarmCount(){
    return(dispatch=>{
        dispatch({
            type: WEATHER_GET_HOURLYALARM_COUNT_CLEAR,
            message: 'Clear All Weather Alarm count',
        });
    });
}

export function getAllConfig(){
     
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_CONFIG_GETALL}`,
        criteria: null,
        logText: 'Get Weather Config Url ',
        sccessType: WEATHER_GET_ALL_CONFIG_SUCCEED,
        failedType: WEATHER_GET_ALL_CONFIG_FAILED,
        errorMessage: 'Failed to get config .',
    }

    return(axiosGet(paramObj));
}

export function getConfigByAppName(obj){
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_CONFIG_GETBYAPPNAME}`,
        criteria: obj,
        logText: 'Get Weather Config Url',
        sccessType: WEATHER_GET_ALL_CONFIG_SUCCEED,
        failedType: WEATHER_GET_ALL_CONFIG_FAILED,
        errorMessage: 'Failed to get config.',
    }

    return(axiosGet(paramObj));
}

export function updateConfig(configObj, appname = null){
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_WEATHER_CONFIG_POST}`,
        criteria: configObj,
        logText: 'Update Weather Config Url ',
        // sccessType: WEATHER_POST_CONFIG_SUCCEED,
        // failedType: WEATHER_POST_CONFIG_FAILED,
        errorMessage: 'Failed to update weather config data.',
        successCallback: (response)=>{
            if (appname != null){
                return(getConfigByAppName({"appname":appname, "modulename":"alarm"}))

            } else {
                return(getAllConfig())
            }
        }
    };

    return(axiosPost(paramObj));
}
// [
// 	{
// 		assetid:,
// 		assetname:,
// 		classid:,
// 		classname:,
// 		timestamp:
// 		locationlat:,
// 		locationlng:,
// 		devices:[
// 			{deviceid:, devicename:,}
// 		],
//      "assetIcon": {
//          "iconid": 1,
//          "iconname": "Circle",
//          "iconscale": 1,
//          "iconsvg": "M2,10a8,8 0 1,0 16,0a8,8 0 1,0 -16,0"
//      },
// 		attributes:[
// 			{
// 				id: ,
// 				attributename:,
// 				displayname:,
// 				value:,
// 				display:,
// 				theme,
// 				type: (device|meta),
// 				//device:{deviceid:, devicename:},
// 				deviceid:,
// 				//meta:,
// 				msg: {msgid:,msgtimestamp:},
// 				attritime;,
// 				datatype:,
				
// 			}
// 		]
// 	}, ...
// ]

// let assetObj = Object.keys(asset)
// .filter(key =>(assetPickKyes.includes(key)))
// .reduce((obj, item)=>{
//     let key = item;
//     if (key === 'description'){
//         key = 'assetdeccription';
//     }
//     obj[key] = asset[item];
//     return obj;
// }, {});
