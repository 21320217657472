/* eslint-disable */
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import {getCurrentUser} from "../../../factories/auth";

class TopbarLanguage extends PureComponent {
  constructor(props) {
    super(props);
    this.languages = [
      { value: 'en', label: 'English' },
      { value: 'fr', label: 'French' },
    ];
    this.state = {
      selectedLan: this.languages[0],
    };
    this.userObj = getCurrentUser();
    if( this.userObj.detail==null){
      return;
    }
    this.state.customStyles = {
      option: (provided, state) => ({
        ...provided,
        '&:active': {backgroundColor: this.userObj.detail.headerButtonHoverColor},
        color:  "black",
        backgroundColor:  state.isFocused ? this.userObj.detail.headerButtonHoverColor: "white",
      }),
      control: (base,selectState) => ({
        ...base,
        // height: 35,
        // minHeight: 35
        height: 35,
        minHeight: 35,
        borderColor: selectState.isFocused ? this.userObj.detail.headerButtonHoverColor: base.borderColor,
        // This line disable the blue border
        boxShadow: selectState.isFocused ? 0 : 0,
        '&:hover': {borderColor: selectState.isFocused ? this.userObj.detail.headerButtonHoverColor: base.borderColor}
      }),
      indicatorsContainer:  (base,selectState)  => ({
        ...base,
        padding: "0",
        //'&:hover': {backgroudcolor: selectState.isFocused ? this.userObj.detail.headerButtonHoverColor: base.borderColor}

      }),
    }
  }

    handleLanguageChange = (val) => {
       
      this.props.i18n.changeLanguage(val.value);
      this.setState({
        selectedLan: val,
      });
    }

    render() {
      return (
        <div className="topbar__language">
          <Select
            className="topbar__language__"
            value={this.state.selectedLan}
            options={this.languages}
            onChange={this.handleLanguageChange}
            styles={this.state.customStyles}
          />
        </div>
      );
    }
}

export default  withTranslation('common')(TopbarLanguage);
