/* eslint-disable */
import PropTypes from "prop-types";
import axios from "axios";
import { toast } from "react-toastify";
import {
  WEB_SERVICE_ROOT,
  WEB_SERVICE_MESSAGE_DECODE_GROUP,
} from "../../constants/appConstants";
import {
  MESSAGE_DECODE_LOAD_LIST_SUCCEED,
  MESSAGE_DECODE_LOAD_LIST_FAILED,
  MESSAGE_DECODE_UPDATE_ONE_MESSAGE_DECODE,
  MESSAGE_DECODE_LOAD_GROUP_SUCCEED,
  MESSAGE_DECODE_LOAD_GROUP_FAILED,
} from "../../constants/actionTypes";
import {
  axiosGet,
  axiosPost,
  axiosDelete,
  getCurrentUser,
  base64Encode,
  getJwttoken,
} from "../../factories/utils";
import { USER_GET_FAILED } from "../../constants/actionTypes";
import { fromPairs } from "lodash";
import { dispatch } from "d3";

/**
 * Create Group
 * POST http://localhost:8080/MessageDecode/Group
 * {
 *    "name":"name323"
 * }
 *
 * Update Group
 * POST http://localhost:8080/MessageDecode/Group
 * {
 *    "groupId":2,
 *    "name":"name211"
 * }
 *
 * Get Group
 * GET http://localhost:8080/MessageDecode/Group?groupId=2
 *
 * Delete Group
 * DELETE http://localhost:8080/MessageDecode/Group?groupId=3
 *
 *
 */

axios.interceptors.request.use(function(config) {
  const token = getJwttoken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const messageDecodeRoute = `${WEB_SERVICE_ROOT}MessageDecode`;

export function loadMessageDecodeGroup() {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, please re-login.",
      });
    };
  }
  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  return (dispatch) =>
    axios({
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_MESSAGE_DECODE_GROUP}`,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        uTk: base64Encode(JSON.stringify(userTokenObj)),
      },
    })
      .then((res) => {
        dispatch({
          type: MESSAGE_DECODE_LOAD_GROUP_SUCCEED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_DECODE_LOAD_GROUP_FAILED,
          payload: err.message,
        });
      });
}
//=================================Message Decode List==============

export function reloadMessageDecode() {
  let criteria;
}
export function loadMessageDecode() {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, please re-login",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  return (dispatch) =>
    axios({
      url: messageDecodeRoute,
      method: "get",
      headers: {
        "Content-Type": "application/json",
        uTk: base64Encode(JSON.stringify(userTokenObj)),
      },
    })
      .then((res) => {
        dispatch({
          type: MESSAGE_DECODE_LOAD_LIST_SUCCEED,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MESSAGE_DECODE_LOAD_LIST_FAILED,
          payload: err.message,
        });
      });
}

export function postMessageDecode(data) {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0) {
    return (dispatch) => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, please re-login.",
        message: "Failed to get current user, please re-login.",
      });
    };
  }

  let { userName, companyCode, role } = userObj;
  let userTokenObj = { userName, companyCode, role };

  // console.log("===messageDecode-data", data);
  return (dispatch) =>
    axios({
      url: messageDecodeRoute,
      headers: {
        "Content-Type": "application/json",
        uTk: base64Encode(JSON.stringify(userTokenObj)),
      },
      method: "post",
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(loadMessageDecode());
        }
        return res;
      })
      .catch((err) => {
        console.error(err);
        return err.response;
      });
}

const messageDecodeTypeParams = {
  deviceTypeId: PropTypes.string,
  messageLength: PropTypes.number,
  encryptionMethod: PropTypes.string,
  decryptionKey: PropTypes.string,
  bodyMessage: PropTypes.string,
  assetclassid: PropTypes.number,
  status: PropTypes.number,
  calculateRules: PropTypes.arrayOf(PropTypes.object),
  module: PropTypes.string,
};

export const addToMessageDecode = (payload) => (dispatch) =>
  dispatch({ type: MESSAGE_DECODE_ADD, payload });

addToMessageDecode.propTypes = {
  payload: PropTypes.shape(messageDecodeTypeParams),
};

export const updateOneMessageDecode = (payload) => (dispatch) =>
  dispatch({ type: MESSAGE_DECODE_UPDATE_ONE_MESSAGE_DECODE, payload });
updateOneMessageDecode.propTypes = {
  payload: PropTypes.shape({
    id: PropTypes.number.isRequired,
    data: PropTypes.shape(messageDecodeTypeParams),
  }),
};
