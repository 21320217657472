/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import {Modal} from "react-overlays";
import { Col, Row, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createReseller, getCountries, getCarrierList } from '../../../redux/actions/userAction';
import { languages } from '../../../constants/dataConstants'
import styles from './ClientAddModal.module.css';
import { getCurrentUser } from '../../../factories/auth';

//import UserAccessForm from './userAccessForm';
import './ClientAddModal.css';

const backdropStyle = {
  position: 'fixed',
  zIndex: 1040,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#000',
  opacity: 0.5,
  overflow: 'auto',
};

class ClientAddModal extends Component {
  constructor(props) {
    super(props);
    this.userObj = getCurrentUser();
    this.state = {
      showModal: false,
      data: [],
      email: '',
      fullName: '',
      clientCode: "",
      userName: '',
      companyCode: "",
      newUser: true,
      activeTab: '1',
      // groupdata: [],
      passConfirmed: true,
      // passconfirmValid: false,
      isSubmit: false,
      statesList: []
    };
    this.userObj = getCurrentUser();
  }

  async componentDidMount() {
    this.props.getCarrierList({ companyCode: this.props.companyCode });

    this.setState({
      statesList: (this.props.countryList.length > 0)?this.props.provinceMap.get(this.props.countryList[0].value):[]
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.show === true) {
      this.setState({
        statesList: this.props.provinceMap.get(this.props.countryList[0].value)
      }, () => {
        this.setState({
          showModal: true,
          clientName: "",
          clientCode: "",
          companyCode: this.props.companyCode,
          identifier: "",
          carrierId: "",
          industry: "",
          clientKey: "",
          language: "",
          contactFirstName: "",
          contactLastName: "",
          phone: "",
          phoneExt: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: this.state.statesList[0].abbrev,
          country: this.props.countryList[0].value,
          zipCode: "",
          //language: "",
          isSubmit: false,
        });
      })
    }
  }

  renderBackdrop = (props) => {
    return <div {...props} style={backdropStyle} />;
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onCountryChange = (event) => {
    this.setState({
      country: event.target.value,
      statesList: this.props.provinceMap.get(event.target.value)
    })
  }

  onSubmit = () => {
    if (!sessionStorage.getItem('companyCode') || !sessionStorage.getItem('currentUser')) {
      // alert ('no');
      return;
    }
    let phoneNo = this.state.phone;
    if (phoneNo) {
      phoneNo = phoneNo.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
      this.setState({
        phone: phoneNo
      })
    }
    if (this.validateForm()) {
      // alert ('v yes');
      let clientObj = {

        isnew: true,
        resellerName: this.state.clientName,
        resellerCode: this.state.clientCode,
        companyCode: this.state.companyCode,
        mvno: this.state.identifier,
        carrierId: this.state.carrierId,
        industry: this.state.industry,
        resellerkey: this.clientKey,
        language: this.state.language,
        contactFirstName: this.state.contactFirstName,
        contactLastName: this.state.contactLastName,
        phone: this.state.phone,
        phoneExt: this.state.phoneExt,
        email: this.state.email,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        city: this.state.city,
        zipCode: this.state.zipCode,
        state: this.state.state,
        country: this.state.country,
        createUser: sessionStorage.getItem('currentUser'),
        createDate: new Date().toISOString().split('.')[0]
      }
      this.props.createReseller(clientObj);


      this.onClose();
    } else {
      // alert('v no');
      this.setState({
        isSubmit: true
      })
    }
  };

  onClose = () => {
    this.setState({
      showModal: false
    });
  };



  validateCode = () => {
    var regex = /^[a-zA-Z0-9]+$/;
    return this.state.clientCode != "" && this.state.clientCode.length < 10 && regex.test(this.state.clientCode);
  }
  validateUserId = () => {
    return (this.state.clientName && this.state.clientName !== '' && this.state.clientName.trim());
  }

  validatePhoneNumber = () => {
    var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return (this.state.phone && regex.test(this.state.phone));
  }
  validateExtension = () => {
    var regex = /^[0-9]*$/;
    return (regex.test(this.state.phoneExt))
  }

  validateEmail = () => {
    // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
    // return  (this.state.email && this.state.email.match(regex) !== null);
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (this.state.email && regex.test(this.state.email));
  }

  validateLangauge = () => {
    return this.state.language != '' && this.state.language.trim();
  }

  validateContactFirstName = () => {
    return this.state.contactFirstName !== '';
  }

  validateContactLastName = () => {
    return this.state.contactLastName !== '';
  }


  validateForm = () => {

    const { contactFirstName, contactLastName,
      addressLine1, city, zipCode,
      country, state } = this.state

    return this.validateUserId()
      && this.validateCode()
      && this.validateLangauge()
      && contactFirstName && contactFirstName.trim()
      && contactLastName && contactLastName.trim()
      && this.validatePhoneNumber()
      && this.validateExtension()
      && this.validateEmail()
      && addressLine1 && addressLine1.trim()
      && city && city.trim()
      && zipCode && zipCode.trim()
      && country && country.trim()
      && state && state.trim();
  }
  render() {
    //let labalStyle = {textAlign: 'right'} ;
    let labalStyle = { textAlign: 'right', verticalAlign: 'middle', marginTop: 'auto', marginBottom: 'auto' };

    const { clientName, clientCode, identifier, industry,
      language, contactFirstName, contactLastName, phone,
      phoneExt, email, addressLine1, addressLine2,
      city, zipCode, country, state, isSubmit } = this.state

    const { pageContentList } = this.props

    let textDisplay = [];
    if (pageContentList.includes("Reseller")) {
      textDisplay = ['Reseller'];
    } else {
      textDisplay = ['Client'];
    }

    return (
      // <Modal
      //     // onHide={this.onClose}
      //     style={modalStyle()}
      //     aria-labelledby="modal-label"
      //     show={this.state.showModal}
      //     renderBackdrop={this.renderBackdrop}
      // >
      <Modal
        style={{ maxWidth: '800px' }}
        isOpen={this.state.showModal}
        toggle={this.props.toggle}
        renderBackdrop={this.renderBackdrop}
        size='lg'
        contentClassName="custom-modal-style"
      >
        {/* <div> */}
        {/* <ModalHeader toggle={this.props.toggle}>New User</ModalHeader > */}
        <ModalHeader toggle={this.onClose}>New {textDisplay[0]}</ModalHeader >
        <ModalBody style={{ fontSize: '14px', paddingBottom: '5px' }} >
          <Col md={12}>
            <Row>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="clientName"><span> Name <span style={{ color: "red" }}>*</span></span></label>
                <Input
                  name='clientName'
                  id='clientName'
                  placeholder='Name'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={clientName}
                  // disabled={this.props.userName !== ''}
                  invalid={isSubmit && !this.validateUserId()}
                />
                <span style={{ fontSize: '12px', textAlign: "left", color: "#999" }}><b>Note:</b> The name that represents the business</span>
              </Col>
              <Col md={6}>
                {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                <label style={{ marginBottom: '3px' }} for="clientCode"><span>{textDisplay[0]} Code <span style={{ color: "red" }}>*</span></span></label>
                <Input
                  name='clientCode'
                  id='clientCode'
                  placeholder={`${textDisplay[0]} Code`}
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={clientCode}
                  invalid={isSubmit && !this.validateCode()}
                />
                <span style={{ fontSize: '12px', textAlign: "left", color: "#999" }}><b>Note:</b> No special characters</span>
              </Col>
            </Row>
            <br />

            <Row>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="identifier"><span>Identifier</span></label>
                <Input
                  name='identifier'
                  id='identifier'
                  placeholder='Identifier'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={identifier}
                />
              </Col>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="industry"><span>Industry</span></label>
                <Input
                  name='industry'
                  id='industry'
                  placeholder='Industry'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={industry}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="language"><span>Language <span style={{ color: "red" }}>*</span></span></label>
                <Input
                  type='select'
                  name='language'
                  id='language'
                  placeholder='language'
                  className={`form-control ${(isSubmit && !language) ? styles['is-invalid'] : ''}`}
                  style={{ fontSize: '14px' }}
                  onChange={this.onInputChange}
                  invalid={isSubmit && !this.validateLangauge()}
                >
                  <option key={-1} value={''}>None Selected</option>
                  {languages.map((language, index) =>
                    <option key={index} value={language.value}>{language.label}</option>
                  )}
                </Input>
              </Col>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="email">
                  <span>Email <span style={{ color: "red" }}>*</span></span>
                </label>
                <Input
                  name='email'
                  id="email"
                  placeholder='Email'
                  onChange={this.onInputChange}
                  pattern={'^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={email}
                  invalid={isSubmit && !this.validateEmail()}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="contactFirstName">
                  <span>Contact First Name <span style={{ color: "red" }}>*</span></span>
                </label>
                <Input
                  name='contactFirstName'
                  id="contactFirstName"
                  placeholder='Contact First Name'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={contactFirstName}
                  invalid={isSubmit && (!contactFirstName || !contactFirstName.trim())}
                />
              </Col>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="contactLastName">
                  <span>Contact Last Name <span style={{ color: "red" }}>*</span></span>
                </label>
                <Input
                  name='contactLastName'
                  id="contactLastName"
                  placeholder='Contact Last Name'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={contactLastName}
                  invalid={isSubmit && (!contactLastName || !contactLastName.trim())}
                />
              </Col>
            </Row>
            <br />


            <Row>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="phone">
                  <span>Phone Number <span style={{ color: "red" }}>*</span></span>
                </label>
                <Input
                  name='phone'
                  id="phone"
                  placeholder='Phone Number'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={phone}
                  invalid={isSubmit && !this.validatePhoneNumber()}
                />
              </Col>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="phoneExt">
                  <span>Ext.</span>
                </label>
                <Input
                  name='phoneExt'
                  id="phoneExt"
                  placeholder='Extension'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={phoneExt}
                  invalid={isSubmit && !this.validateExtension()}
                />
              </Col>
            </Row>
            <br />

          </Col>
          <hr style={{ marginTop: '0' }} />

          <Col md={12}>
            <Row>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="addressLine1">
                  <span>Address Line 1 <span style={{ color: "red" }}>*</span></span>
                </label>
                <Input
                  name='addressLine1'
                  id="addressLine1"
                  placeholder='Address Line 1'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={addressLine1}
                  invalid={isSubmit && (!addressLine1 || !addressLine1.trim())}
                />
              </Col>

              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="addressLine2">
                  <span>Address Line 2</span>
                </label>
                <Input
                  name='addressLine2'
                  id="addressLine2"
                  placeholder='Address Line 2'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={addressLine2}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="city">
                  <span>City <span style={{ color: "red" }}>*</span></span>
                </label>
                <Input
                  name='city'
                  id="city"
                  placeholder='City'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={city}
                  invalid={isSubmit && (!city || !city.trim())}
                />
              </Col>
              <Col md={6}>
                <label style={{ marginBottom: '3px' }} for="zipCode">
                  <span>Zip/Postal Code <span style={{ color: "red" }}>*</span></span>
                </label>
                <Input
                  name='zipCode'
                  id="zipCode"
                  placeholder='Code'
                  onChange={this.onInputChange}
                  className="form-control"
                  style={{ fontSize: '14px' }}
                  value={zipCode}
                  invalid={isSubmit && (!zipCode || !zipCode.trim())}
                />
              </Col>
            </Row>
            <br />

            <Row>
              <Col md={6}>
                <label style={{ marginBottom: "3px" }} for="country">
                  <span>
                    Country <span style={{ color: "red" }}>*</span>
                  </span>
                </label>
                <Input
                  type="select"
                  name="country"
                  id="country"
                  placeholder="Country"
                  onChange={this.onCountryChange}
                  className={`form-control ${isSubmit && !country ? styles["is-invalid"] : ""
                    }`}
                  style={{ fontSize: "14px" }}
                  value={country ? country : ""}
                  invalid={isSubmit && (!country || !country.trim())}
                >

                  {this.userObj.companyCode &&
                    this.props.countryList &&
                    this.props.countryList.map((option, index) => {
                      //   if (option.value != "USA") {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                      //   }
                    })}
                </Input>
              </Col>
              <Col md={6}>
                <label style={{ marginBottom: "3px" }} for="state">
                  <span>
                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" ? 'State/City' : 'State/Province'} <span style={{ color: "red" }}>*</span>
                  </span>
                </label>
                <Input
                  type="select"
                  name="state"
                  id="state"
                  placeholder="State"
                  onChange={this.onInputChange}
                  className={`form-control ${isSubmit && !state ? styles["is-invalid"] : ""
                    }`}
                  style={{ fontSize: "14px" }}
                  value={state ? state : ""}
                  invalid={isSubmit && (!state || !state.trim())}
                >
                  {/* <option value={''}>{'No selection'}</option> */}
                  {this.userObj.companyCode &&
                    this.state.statesList &&
                    this.state.statesList.map((option, index) => {
                      //   if (
                      //     this.userObj.companyCode &&
                      //     this.userObj.companyCode !== "TEISA"
                      //   ) {
                      return (
                        <option key={index} value={option.abbrev}>
                          {option.name}
                        </option>
                      );
                      //   }
                      //   if (
                      //     this.userObj.companyCode &&
                      //     this.userObj.companyCode == "TEISA"
                      //   ) {
                      //     return (
                      //       <option key={index} value={"Paraguay"}>
                      //         {option.label}
                      //       </option>
                      //     );
                      //   }
                    })}
                </Input>
              </Col>
            </Row>
          </Col>
          <br />
          {/* <hr/> */}
        </ModalBody>
        <ModalFooter style={{ padding: "0", borderTop: "0" }}>
          <Col md={12}>
            <Row>
              <Col md={12} className={"text-right"}>
                {this.userObj.companyCode &&
                  this.userObj.companyCode == "TEISA" && (
                    <Button
                      className="btn-withhover"
                      outline
                      color="custom"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Submit
                    </Button>
                  )}
                {this.userObj.companyCode &&
                  this.userObj.companyCode !== "TEISA" && (
                    <Button
                      outline
                      color="custom"
                      type="button"
                      onClick={this.onSubmit}
                    >
                      Submit
                    </Button>
                  )}
                <Button
                  outline
                  color="secondary"
                  type="reset"
                  onClick={this.onClose}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </Col>
        </ModalFooter>
        {/* </div> */}
      </Modal>
    );
  }
}

// export default UserAddModal;

const mapStateToProps = (state) => {
  return state.user;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createReseller, getCountries, getCarrierList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientAddModal);