/* eslint-disable */
import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Col, Row, Button, Container, Table } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import {Modal} from "react-overlays";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MultiSelectionComponent, { SimMultiSelection } from '../../../Asset/components/SelectionComponents';

import { CheckIcon } from 'mdi-react';
import '../ClientAddModal.css';

const backdropStyle = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    overflow: 'auto',
};

const modalStyle = function () {
    const top = 5;
    const left = 5;

    return {
        position: 'fixed',
        width: 800,
        height: 650,
        zIndex: 1040,
        top: top + '%',
        left: left + '%',
        border: '0px solid #ffffff',
        backgroundColor: 'white',
        // boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflow: 'auto',
        resize: 'both'
    };
};

class PlanSelectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selectedPlan: null,
            isAllSelected: false

        };
    }
    componentDidMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        var isAllSelected = false;
        if (nextProps.showModal === true) {
            if (nextProps.mapSimToPlan != null) {
                if (nextProps.mapSimToPlan[nextProps.selectedPlan.itemId] != null) {
                    if (nextProps.mapSimToPlan[nextProps.selectedPlan.itemId].selectedSim != null) {
                        console.log(nextProps.mapSimToPlan[nextProps.selectedPlan.itemId].selectedSim)
                        if (nextProps.mapSimToPlan[nextProps.selectedPlan.itemId].selectedSim.length == nextProps.cart.length) {
                            isAllSelected = true
                        }
                    }
                }
            }
            this.setState({
                showModal: true,
                selectedPlan: nextProps.selectedPlan,
                isAllSelected: isAllSelected
            });
        }

    }
    priceFormatter = (price, billCycle) => {
        var per = "";
        if (billCycle == 30) {
            per = "/mo"
        }
        else if (billCycle == 365) {
            per = "/yr"
        }
        return `$ ${price.toFixed(2)}${per}`;
    }
    dataFormatter = (data) => {
        if (data < 1024) {
            return (`${data} MB`);
        }
        if (data >= 1024) {
            var gb = (data / 1024).toFixed(2)
            return (`${gb} GB`);
        }
    }
    AutoRenewCheck = (check) => {
        if (check != 0) {
            return <CheckIcon />
        }
    }
    PAYGCheck = (check) => {
        if (check === "PAYG") {
            return <CheckIcon />
        }
    }

    renderBackdrop = (props) => {
        return <div {...props} style={backdropStyle} />;
    };

    onClose = () => {
        this.props.onClose()
    }
    render() {
        let selectedSim = []
        if (this.props.selectedPlan != null && this.props.mapSimToPlan != null) {
            if (this.props.mapSimToPlan[this.props.selectedPlan.itemId] != null) {
                selectedSim = this.props.mapSimToPlan[this.props.selectedPlan.itemId].selectedSim
            }
        }
        let labalStyle = { textAlign: 'right', verticalAlign: 'middle', marginTop: 'auto', marginBottom: 'auto' };
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.props.showModal}
                renderBackdrop={this.renderBackdrop}
                size='lg'

                contentClassName="custom-modal-style"
            >
                {/* <div> */}
                {/* <ModalHeader toggle={this.props.toggle}>New User</ModalHeader > */}
                <ModalHeader toggle={this.onClose}>{(this.props.selectedPlan != null) ? `Pair ${this.props.selectedPlan.itemName} to SIMs` : `loading...`}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Plan Details</span>
                            </Col>
                            <Col md={9}>
                                {(this.props.selectedPlan) ?
                                    <Table style={{ textAlign: "center" }}>
                                        <thead>
                                            <tr>
                                                <th>Price</th>
                                                <th>Data Usage</th>
                                                <th>AutoRenew</th>
                                                <th>Pay As You Go</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.priceFormatter(this.props.selectedPlan.unitPrice, this.props.selectedPlan.billCycle)}</td>
                                                <td>{this.dataFormatter(this.props.selectedPlan.planDataVolume)}</td>
                                                <td style={{ color: "green" }}>{this.AutoRenewCheck(this.props.selectedPlan.autoRenewFlag)}</td>
                                                <td style={{ color: "green" }}>{this.PAYGCheck(this.props.selectedPlan.itemPayType)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    :
                                    `loading...`
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>SIM Cards</span>
                            </Col>
                            <Col md={7}> 
                                <SimMultiSelection
                                    options={this.props.cart.filter((sim) => sim.servicePlan == null)}
                                    onChange={this.props.handlePlanMultiSelectionChange}
                                    defaultValue={selectedSim}
                                />
                            </Col>
                            <Col md={3} >
                                <Button color='primary' disabled={this.state.isAllSelected} onClick={this.props.handlePlanSelectAll}>
                                    Select All
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <br />
                </ModalBody>
                <ModalFooter>
                    <Button outline color='secondary' type="reset" onClick={this.onClose}>
                        Close
                        </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
const mapStateToProps = (state) => {
    return state.user;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelectModal);