/* eslint-disable */
import React, { Component } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import check_mark_circle from '../../../shared/img/icon/check_mark_circle.svg'; 
import icon_edit from '../../../shared/img/icon/edit.png';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_add from '../../../shared/img/icon/add.png';

class RuleListTable extends Component {

    constructor(props){
        super(props);
        this.state={           
        }
    }

    handleRowClick = (row) => {
        //  alert(`You click row id: ${row.id}`);
         this.props.handleRowClick(row);
        // alert('yes');
    }

    handleRowDeleteClick = (row) => {
        this.props.handleRowDeleteClick(row);
    }
    handleAddNewClick = (e) => {
        this.props.handleAddNew(e);
    }
    
    // options = {
    //     onRowClick: this.handleRowClick,
    //     onRowDoubleClick: function(row) {
    //         // alert(`You double click row id: ${row.id}`);
    //     }
    // };


    editButtonFormatter = (cell, row) => {
        let path = `/pages/device/${cell}`;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                <img src={icon_edit} style={{width: 20, height: 20}} alt={"Edit"} onClick={(e)=>{this.handleRowClick(row);}}/>{' '}
                <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} onClick={(e)=>{this.handleRowDeleteClick(row);}} />
            </div>
        )
    }

    render(){
         
        const options = {
            page: 1,  // which page you want to show as default
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: 'All', value:  this.props.rules.length
            } ], // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
            // onRowClick: this.handleRowClick,
            // onRowDoubleClick: function(row) {
            //     // alert(`You double click row id: ${row.id}`);
            // }
          };
        return(
            <Col md={12}>
            <Card>
                <CardBody>
                    <div style={{textAlign:'right',paddingRight:30}}>
                        <img src={icon_add} style={{width: 30, height: 30}} alt={"Delete"} onClick={(e)=>{this.handleAddNewClick(e);}} />
                        {/* <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleAddNewClick}>
                            New Rule
                        </Button> */}
                    </div>
                    <br/>
                    <BootstrapTable data={ this.props.rules } tableHeaderClass = 'header_white' pagination = {true} options={ options }    bordered={ false } >
                        <TableHeaderColumn dataField='ruleid' isKey={ true } dataAlign="center" >Rule ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='rulename' dataAlign="center" dataSort >Rule Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='devicetypename' dataAlign="center" dataSort >Deice Type Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='devicename' dataAlign="center" dataSort >Device Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='createtime' dataAlign="center" dataSort >Create Time</TableHeaderColumn>
                        {/* <TableHeaderColumn dataField='shape' >Shape</TableHeaderColumn>  */}
                        <TableHeaderColumn dataFormat={ this.editButtonFormatter } ></TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        </Col>
        )   
    }
}
export default RuleListTable;