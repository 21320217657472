/* eslint-disable */
import React, { Component, Fragment } from "react";
import {
  Card,
  Col,
  Row,
} from "reactstrap";
import Spinner from "react-bootstrap/Spinner";
import { SingleSelection } from "../../Asset/components/SelectionComponents";
import { AttributeInfo } from "../../Asset/components/ClassDisplayDetail";
import Select from "react-select";
import PowerMgntDeviceBoard from "./PowerMgntDeviceBoard";
import {
  getPowerAssetHistoryData,
  getPowerAssetData,
} from "../../../redux/actions/powerAction";
import { getDeviceAttributes } from "../../../redux/actions/mapActions";
import { getWeatherAssetHistoryData, clearPowerAssetHistoryData } from "../../../redux/actions/weatherAction";
import { LIGHT_GREY_COLOR } from "../../../constants/dataConstants";
import moment from "moment";
import { HttpsIcon } from "mdi-react";

class PowerManagementBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetSelector: false,
      assetClick: {},
      assetName: [],
      assetList: [],
      deviceList: [],
      historyDataObj: null,
      selectedAssetId: null,
      selectedAssetInfo: null,
      selectedDeviceId: "",
      selectedDeviceInfo: null,
      subDeviceList: null,
      showDeviceList: false,
      statusData: null,
      attributea: null,
      attributeb: null,
    };
  }

  componentDidMount() {}

  handelAssetChange = () => {
    this.setState({
      selectedAssetId: options,
    });
  };

  loadAssetData = (assetclassId) => {
    // for(let i = 0; i != assetclassId.length; i++) {
    //   assetclassList.push(assetclassId[i]);
    // }
    let params = {
      entitygroup: [assetclassId],
      entity: "",
      starttime: "",
      endtime: "",
      latest: "1",
      themeid: "",
    };
    getPowerAssetData(params);
  };

  getHistoryData = (assetId) => {
    const assetCriteria = {
      entity: assetId,
    };
    let endTime = moment();
    let startTime = moment(endTime).subtract(30, "days");
    let startTimestamp = startTime.utc().valueOf();
    let endTimestamp = moment()
      .utc()
      .valueOf();
    const historyCriteria = {
      ...assetCriteria,
      // starttime: 1658142000,
      // endtime: 1658199600,
      starttime: startTimestamp,
      endtime: endTimestamp,
    };
    // getPowerAssetHistoryData(historyCriteria);
    // getWeatherAssetHistoryData(historyCriteria);
    this.props.updateAttributeHistoryData(historyCriteria);
  };
  selectAsset = (asset) => {
    const assetInfo = this.props.assetList.find(
      ({ assetid }) => assetid == parseInt(asset.target.value)
    );

    const statusData = this.props.statusData.find(
      ({ assetid }) => assetid == parseInt(asset.target.value)
    );

    if (asset.target.value != "firstOpt") {
      const assetClassInfo = this.props.assetClassList.find(
        ({ id }) => id == assetInfo.assetclassid
      );
      this.setState({
        selectedAssetClassInfo: assetClassInfo,
        selectedAssetClassId: assetClassInfo.id,
        selectedAssetId: parseInt(asset.target.value),
        selectedAssetInfo: assetInfo,
        selectedDeviceId: "",
        selectedDeviceInfo: null,
        deviceList: null,
        subDeviceList: null,
        showDeviceList: true,
        statusData,
      });

      this.getHistoryData(parseInt(asset.target.value));
      this.loadAssetData(assetClassInfo.id);
      // this.state.historyDataObj = this.props.historyData(parseInt(asset.target.value))
    } else {
      this.setState({
        selectedAssetClassInfo: null,
        selectedAssetClassId: null,
        selectedAssetId: null,
        selectedAssetInfo: null,
        selectedDeviceId: null,
        selectedDeviceInfo: null,
        deviceList: null,
        subDeviceList: [],
        showDeviceList: false,
      });
    }

    // let startTimestamp = startDateMoment ? startDateMoment.utc().valueOf() : 0;
    // let endTimestamp = endDateMoment ? endDateMoment.utc().valueOf() : moment().utc().valueOf();
  };

  selectDevice = (device) => {
    const deviceInfo = this.props.deviceList.find(
      ({ deviceid }) => deviceid == device.target.value
    );

    if (device.target.value == "firstOpt") {
      this.setState({
        selectedDeviceId: device.target.value,
        selectedDeviceInfo: null,
      });
    } else {
      this.setState({
        selectedDeviceId: device.target.value,
        selectedDeviceInfo: deviceInfo,
      });
      
      clearPowerAssetHistoryData();
      getDeviceAttributes(device.target.value);
      this.loadAssetData(this.state.selectedAssetClassId);
      this.props.setDeviceid(device.target.value);
    }

    let subDevice = this.props.deviceList.filter(
      (d) => d.parentdeviceid == this.state.selectedDeviceId
    );

    let subDeviceList = subDevice.map((d) => ({
      deviceid: d.deviceid,
      devicename: d.devicename,
    }));
    this.setState({
      subDeviceList: subDeviceList,
    });
  };

  renderSpinner = () => {
    return (
      <Fragment>
        {this.props.assetDataLoading && (
          <div
            style={{
              position: "Absolute ",
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px",
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              backgroundColor: LIGHT_GREY_COLOR,
              opacity: "1.0",
            }}
          >
            <Spinner
              className="spinner"
              as="span"
              animation="border"
              variant="success"
              // size="sm"
              // role="status"
              // aria-hidden="true"
              style={{ display: "inline-block", margin: "auto" }}
            />
          </div>
        )}
      </Fragment>
    );
  };

  render() {
    // if(this.props.historyData){
    //   console.log("===this.props.historyData", this.props.historyData);
    // }
    if (this.props.assetList) {
      const assetList = this.props.assetList.map((a) => ({
        id: a.assetid,
        name: a.assetname,
      }));
      const firstOption = { id: "firstOpt", name: "- select an asset-" };
      assetList.unshift(firstOption);
      this.state.assetList = assetList;
    }

    if (this.state.selectedAssetInfo) {
      const selectedAsset = this.props.assetList.find(
        ({ assetid }) => assetid == this.state.selectedAssetId
      );
      if (selectedAsset) {
        const deviceList = selectedAsset.devices.map((d) => ({
          id: d.deviceid,
          name: d.devicenamealias,
        }));
        const firstOption = { id: "firstOpt", name: "- select a device -" };
        deviceList.unshift(firstOption);
        this.state.deviceList = deviceList;
      }
    }

    this.state.subDeviceList = this.props.deviceList.filter(
      (d) => d.parentdeviceid == this.state.selectedDeviceId
    );

    if (this.state.selectedDeviceInfo) {
      let deviceAttr = this.state.selectedDeviceInfo.attributes.map((info) => ({
        attmetaid: info.attmetaid,
        value: info.value,
      }));

      var deviceAttributes = deviceAttr.map((att) => ({
        deviceid: this.state.selectedDeviceId,
        attmetaid: att.attmetaid.attributemetaid,
        attmetaname: att.attmetaid.name,
        type: att.attmetaid.type,
        value: att.value,
      }));
    }


    var filteredPowerAttrList = this.props.powerAttrList.filter(attr => attr.deviceid === this.state.selectedDeviceId);

    return (
      <Card>
        <Row md={12} class="d-flex flex-row">
          <Col md={3} class="p-2" style={{ marginRight: "-8%" }}>
            <AttributeInfo name="Asset">
              <SingleSelection
                // key={this.props.assetInfo.assetid}
                isClearable={true}
                options={this.state.assetList}
                onChange={this.selectAsset}
                defaultValue={this.state.selectedAssetId}
                value={this.state.selectedAssetId}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
                style={{ width: "70%" }}
              />
            </AttributeInfo>
          </Col>
          <Col md={3} class="p-2">
            {this.state.showDeviceList == true ? (
              <AttributeInfo name="Device">
                <SingleSelection
                isClearable={true}
                  options={this.state.deviceList}
                  onChange={this.selectDevice}
                  defaultValue={this.state.deviceList}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  isdisabled={true}
                  style={{ width: "70%" }}
                />
              </AttributeInfo>
            ) : null}
          </Col>
        </Row>
        <br />
        <Row md={12}>
          <Col md={12}>
            {/* <Collapse
              isOpen={
                this.state.selectedDeviceInfo != undefined &&
                this.state.selectedDeviceInfo != null
              }
            > */}
            {this.state.selectedDeviceInfo &&
            this.state.selectedDeviceInfo != undefined &&
            this.state.selectedDeviceInfo != null ? (
              <PowerMgntDeviceBoard
                deviceInfo={this.state.selectedDeviceInfo}
                assetInfo={this.state.selectedAssetInfo}
                assetData={
                  this.state.selectedAssetId
                    ? this.props.assetData.find(
                        ({ assetid }) => assetid == this.state.selectedAssetId
                      )
                    : this.props.assetData
                }
                deviceAttrList={this.props.deviceAttrList}
                selectedDeviceAttr={deviceAttributes}
                statusData={this.state.statusData}
                currentAssetData={this.props.currentAssetData}
                messageDecodeList={this.props.messageDecodeList}
                messageDecodeGroup={this.props.messageDecodeGroup}
                historyDataObj={this.props.historyDataObj}
                powerAttrList={filteredPowerAttrList}
              />
            ) : null}
            {/* </Collapse> */}
          </Col>
        </Row>
        {this.renderSpinner()}
      </Card>
    );
  }
}

export default PowerManagementBoard;
