/* eslint-disable */
import React, { Component } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {EditTextButton, DeleteTextButton, PlusIconButton} from '../../../shared/components/Button/IconButton';

class DeviceMasterList extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    componentDidMount(){
        // this.props.getDeviceMasterList(this.props.upccode);
    }
// ----------------------------------------------------------------------------------------
    deviceIDFormatter = (cell, row) => {
        // const onLinkClick = (e) => {
        //     // alert('okay-e');
        //     this.props.handleDeviceClicked(cell);
        // } 
        // return(
        //     <div>
        //         <a href='javascript:;' onClick={onLinkClick} ><span>{cell}</span></a>
        //     </div>
        // )

        let path = `/pages/devicemaster/${cell}`;
        return (
            // <a href={path} onClick={() => {
            //     // alert(cell); alert(row['devicename']);
            // }}>{cell}</a>
            <Link to={path}>{cell}</Link> 
        )
    }

    handleRowClick = (row) => {

    }

    editButtonFormatter = (cell, row) => {
        // let path = `/pages/device/${cell}`;
        let path = ``;
        return(
            <div>
                <EditTextButton label={'Detail'} onClick={(e)=>{this.handleRowClick(row);}}/>{' '}
                {/* <PlusIconButton label={'Activate'} onClick={(e)=>{}}/> */}
            </div>
        )
    }
    onBackClick = (e) => {
        this.props.handleDeviceListBackClick();
    }

    render(){
        return(
            <Col md={12}>
            <Card>
            <CardBody>
                <Button style={{float:'left'}} onClick={this.onBackClick}> {'< Back'} </Button> 
                <BootstrapTable 
                    data={ this.props.deviceMasterList }  
                    tableHeaderClass = 'header_white' 
                    pagination = { false } 
                      
                     
                    bordered={ false } 
                    search 
                    // insertRow 
                    ref='table' 
                >
                  <TableHeaderColumn dataField='device_ID' isKey={ true } dataAlign="center" width='10%' dataFormat={this.deviceIDFormatter} >Device ID</TableHeaderColumn>
                  {/* <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='5%' >UPC</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='mfg' dataAlign="center" dataSort width='10%' >MFG</TableHeaderColumn>
                  <TableHeaderColumn dataField='mfg_SKU' dataAlign="center" dataSort >SKU</TableHeaderColumn>  
                  <TableHeaderColumn dataField='mfg_SERIAL_NUM' dataAlign="center" dataSort  >Serial Number</TableHeaderColumn>   
                  <TableHeaderColumn dataField='mac_ADDRESS' dataAlign="center" dataSort width='7%' hidden >MAC</TableHeaderColumn>                      
                  <TableHeaderColumn dataField='special_STOCK' dataAlign="center" dataSort width='10%' hidden >Special Stock</TableHeaderColumn>             
                  <TableHeaderColumn dataField='company' dataAlign="center" dataSort width='10%' >Company</TableHeaderColumn> 
                  <TableHeaderColumn dataField='network_SUPPORTED' dataAlign="center" dataSort >Network</TableHeaderColumn>  
                  <TableHeaderColumn dataField='refurbished' dataAlign="center" dataSort hidden >Refurbished</TableHeaderColumn>  
                  <TableHeaderColumn dataField='old_DEVICE_ID' dataAlign="center" dataSort hidden >Old ID</TableHeaderColumn>  
                  <TableHeaderColumn dataField='create_TIME' dataAlign="center" dataSort hidden >Create Time</TableHeaderColumn>  
                  <TableHeaderColumn dataField='update_TIME' dataAlign="center" dataSort hidden >Update Time</TableHeaderColumn>  
                  <TableHeaderColumn dataField='updated_BY' dataAlign="center" dataSort hidden >Update By</TableHeaderColumn>  
                  <TableHeaderColumn dataField='customer_ID' dataAlign="center" dataSort width='10%' >Customer</TableHeaderColumn>      
                  <TableHeaderColumn dataField='device_STATUS' dataAlign="center" dataSort width='10%' >Status</TableHeaderColumn>   
                  {/* <TableHeaderColumn dataField='edit' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn> */}
              </BootstrapTable>
              </CardBody>
              </Card>
            </Col>
        );
    }
}

export default DeviceMasterList