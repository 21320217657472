/* eslint-disable */
import React, {Component} from 'react';
import { Card, CardImg, CardBody, CardTitle, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import WidgetChart1 from './widgets/widget_chart_1';
import WidgetChart2 from './widgets/widget_chart_2';

import WidgetCircle1 from './widgets/widget_circle_1';
import WidgetCircle2 from './widgets/widget_circle_2';
import WidgetCircle3 from './widgets/widget_circle_3';
import WidgetCircle4 from './widgets/widget_circle_4';

import styles from './GarageBoard.module.css'

class GarageBoard extends Component {
  constructor(props){
    super(props);
    this.state = {
      statusData: [],
      assetData: [],
      images: []
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.statusData && nextProps.assetData) {

      this.setState({ 
        statusData: nextProps.statusData,
        assetData: nextProps.assetData,
        images: []
      }, () => {

        const { statusData, assetData, images } = this.state

        for (let s = 0; s < statusData.length; s++) {
          const status = statusData[s];
          let assetResult = assetData.filter((asset) => asset.assetid === status.assetid)
          
          if (assetResult.length > 0) {
            let imageResult = assetResult[0].attributes.filter((attribute) => attribute.attributename === "Image")

            if (imageResult.length > 0) {
              const imgResult = {
                assetid: status.assetid,
                src: imageResult[0].value
              }
              images.push(imgResult)
            }
          }
        }
        this.setState({ images })

      })

    }
  }

  totalOccupancy = (statusData) => {
    if(statusData){
      let alarm = statusData.attributes.Alarm;
      let total;
      let value;
  
      alarm.forEach(item => {
        if(item.value == "OFF"){
          value = 0;
          console.log(isNaN(value))
        }
        else 
          value = item.value;
        total += value;
      })

      return total;
    }
  }

  handleDeviceOnClick = (e, asset) => {
    e.preventDefault();
    this.props.toggle(true, asset);
  }

  render(){
    const {t} = this.props;

    const { statusData, images } = this.state;
    
    return(
        <Container className="dashboard">
          <Row>
          {
            statusData && statusData.map((g, index) => 
              <Col key={index} md={3} xl={3} lg={6} sm={12} xs={12}>
                <Card
                  onClick={(e) => {this.handleDeviceOnClick(e, g)}}
                  className={`${styles['nav-link-effort-pointer']}`}
                >
                  <CardBody>
                    {
                      images.length > 0 
                        && images.filter((img) => img.assetid === g.assetid).length > 0
                        &&  (
                          <CardImg
                            alt="Card image cap"
                            src={images.filter((img) => img.assetid === g.assetid)[0].src}
                            top
                            width="100%"
                            className={`${styles['nav-link-effort-pointer']}`}
                          />
                        )
                    }
                    <div style={{ height: '15px' }}></div>
                    <CardTitle tag="h5">
                      <div 
                          className={`text-center ${styles['nav-link-effort-pointer']}`} 
                      >
                        <a
                          onClick={(e) => {this.handleDeviceOnClick(e, g)}} 
                          href="#" className={`${styles['nav-link-effort']}`}>
                            {g.assetname}
                        </a>
                      </div>
                    </CardTitle>
                  </CardBody>
                </Card>
              </Col>
            )
          }
          </Row>
        </Container>
    )
  }
}


GarageBoard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(GarageBoard);
