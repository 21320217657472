/* eslint-disable */
import {
    ATTRI_THEME_GET_THEME_LIST_SUCCEED,
    ATTRI_THEME_GET_THEME_LIST_FAILED,
    ATTRI_THEME_GET_ASSETCLASS_THEME_SUCCEED,
    ATTRI_THEME_GET_ASSETCLASS_THEME_FAILED,
    ATTRI_THEME_UPSERT_THEME_SUCCEED,
    ATTRI_THEME_UPSERT_THEME_FAILED,
    ATTRI_THEME_REMOVE_THEME_SUCCEED,
    ATTRI_THEME_REMOVE_THEME_FAILED,
    ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_SUCCEED,
    ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_FAILED,
} from '../../constants/actionTypes';

const initState = {
    themeList: [],
    assetclassTheme:{},
    upserted: false,
    error: 0,
    message: '',
    type: ''
}

export default function (state = initState, action) {
    switch (action.type) {
        case ATTRI_THEME_GET_THEME_LIST_SUCCEED:
            return { 
                ...state, 
                themeList: action.payload,
                upserted: false,
                error: 0,
                message: '',
                type: action.type,
            };
        case ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_SUCCEED:
            return { 
                ...state, 
                themeList: action.payload,
                upserted: false,
                error: 0,
                message: '',
                type: action.type,
            };           
        case ATTRI_THEME_GET_ASSETCLASS_THEME_SUCCEED:
            return { 
                ...state, 
                assetclassTheme: action.payload,
                upserted: false,
                error: 0,
                message: '',
                type: action.type,
            };
        case ATTRI_THEME_UPSERT_THEME_SUCCEED:
            return { 
                ...state, 
                // assetclassTheme: action.payload,
                assetclassTheme:{},
                upserted: true,
                error: 0,
                message: '',
                type: action.type,
            };
        case ATTRI_THEME_REMOVE_THEME_SUCCEED:
            return { 
                ...state, 
                // assetclassTheme: action.payload,
                assetclassTheme:{},
                upserted: true,
                error: 0,
                message: '',
                type: action.type,
            };
        case ATTRI_THEME_REMOVE_THEME_FAILED:
        case ATTRI_THEME_UPSERT_THEME_FAILED:
        case ATTRI_THEME_GET_THEME_LIST_FAILED:
        case ATTRI_THEME_GET_ASSETCLASS_THEME_FAILED:
        case ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_FAILED:
            return { 
                ...state,
                upserted: false,
                error: 1,
                message: action.error,
                type: action.type,
            };
        default: 
            return { ...state };
    }
}
