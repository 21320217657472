/* eslint-disable */
import React, { Component } from "react";
// import { Col } from 'react-bootstrap';
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { hasRole } from "../../../factories/auth";
import {
  getCarrierList,
  getCountries
} from "../../../redux/actions/userAction";
import ClientEditAddress from "./ClientEditAddress";

class ClientEditBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusiness: true
    };
  }
  componentDidMount() {
    this.props.getCountries()
    this.props.getCarrierList({ companyCode: this.props.selectedRow.companyCode });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.selectedRow != null) {
      this.setState({
        clientName: nextProps.selectedRow.resellerName,
        accountNum: nextProps.selectedRow.accountNumber,
        taxExempt: nextProps.selectedRow.taxExempt,
        phoneNum: nextProps.selectedRow.phone,
        phoneExt: nextProps.selectedRow.phoneExt,
        email: nextProps.selectedRow.email,
        firstName: nextProps.selectedRow.contactFirstName,
        lastName: nextProps.selectedRow.contactLastName,
      }
      )
    }
  }
  validateForm = () => {
    return this.validatePhone() && this.validateEmail() && this.validateLastName() && this.validateFirstName() && this.validateResellerName();
  }
  validateFirstName = () => {
    return (this.state.firstName && this.state.firstName !== '');
  }
  validateLastName = () => {
    return (this.state.lastName && this.state.lastName !== '');
  }
  validateExtension = () => {
    var regex = /^[0-9]*$/;
    return (regex.test(this.state.phoneExt))
  }
  validatePhone = () => {
    var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return (this.state.phoneNum && regex.test(this.state.phoneNum));
  }
  validateEmail = () => {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (this.state.email && regex.test(this.state.email));
  }
  validateCarrier = () => {
    return (this.state.carrierId && this.state.carrierId !== '');
  }
  validateResellerName = () => {
    return (this.state.clientName && this.state.clientName !== '');
  }
  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onSubmit = () => {
    let data = {
      "isnew": false,
      "resellerName": this.state.clientName,
      "resellerCode": this.props.selectedRow.resellerCode,
      "taxExempt": this.state.taxExempt,
      "phone": this.state.phoneNum,
      "phoneExt": this.state.phoneExt,
      "email": this.state.email,
      "contactFirstName": this.state.firstName,
      "contactLastName": this.state.lastName,
      "carrierId": this.state.carrierId,
      "billingId": this.state.billingId
    }
    //
    this.props.handleEditUser(data);
  }

  render() {

    let labelStyle = { textAlign: 'right', verticalAlign: 'middle', marginTop: 'auto', marginBottom: 'auto' };

    return (
      <Card>
        <CardBody>
          <Col md={12}>
            <h3>Information</h3>
            <br />
            <Row>
              <Col md={2} style={labelStyle}>
                <span>Name <span style={{ color: 'red' }}>*</span></span>
              </Col>
              <Col md={8}>
                <Input
                  name='clientName'
                  placeholder='client Name'
                  onChange={this.onInputChange}
                  className="form-control"
                  value={this.state.clientName}
                  invalid={!this.validateResellerName()}
                />
              </Col>
            </Row>
            {(this.props.isAdmin) ?
              <>
                <br />
                <Row>
                  <Col md={2} style={labelStyle}>
                    <span>Tax Exempt</span>
                  </Col>
                  <Col md={8}>
                    <Input
                      type='select'
                      name='taxExempt'
                      id='taxExempt'
                      placeholder='taxExempt'
                      className="form-control"
                      onChange={this.onInputChange}
                      value={this.state.taxExempt}
                    >
                      <option key={1} value={0} >NO</option>
                      <option key={2} value={1} >YES</option>
                    </Input>
                  </Col>
                </Row>
              </>
              : null
            }

            <br />
           { (this.props.pageContentList.includes("Client Info")) ?
            <Row>
              <Col md={2} style={labelStyle}>
                <span>Account Number </span>
              </Col>
              <Col md={5}>
                <span style={{ fontSize: "1rem" }}>{this.state.accountNum}</span>
              </Col>
            </Row> : null
}
            <br />
            <Row>
              <Col md={2} style={labelStyle}>
                <span>Phone Number <span style={{ color: 'red' }}>*</span></span>
              </Col>
              <Col md={5}>
                <Input
                  name='phoneNum'
                  placeholder='Phone Number'
                  onChange={this.onInputChange}
                  className="form-control"
                  value={this.state.phoneNum}
                  invalid={!this.validatePhone()}
                />
              </Col>
              <Col md={1} style={labelStyle}>
                <span>Ext. </span>
              </Col>
              <Col md={2}>
                <Input
                  name='phoneExt'
                  placeholder='Extension'
                  onChange={this.onInputChange}
                  className="form-control"
                  value={this.state.phoneExt}
                // valid={this.validateExtension()}
                />
              </Col>
            </Row>

            <br />

            <Row>
              <Col md={2} style={labelStyle}>
                <span>Email <span style={{ color: 'red' }}>*</span></span>
              </Col>
              <Col md={8}>
                <Input
                  name='email'
                  placeholder='Email'
                  onChange={this.onInputChange}
                  className="form-control"
                  value={this.state.email}
                  invalid={!this.validateEmail()}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={2} style={labelStyle}>
                <span>Contact First Name <span style={{ color: 'red' }}>*</span></span>
              </Col>
              <Col md={8}>
                <Input
                  name='firstName'
                  placeholder='First Name'
                  onChange={this.onInputChange}
                  className="form-control"
                  value={this.state.firstName}
                  invalid={!this.validateFirstName()}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={2} style={labelStyle}>
                <span>Contact Last Name <span style={{ color: 'red' }}>*</span></span>
              </Col>
              <Col md={8}>
                <Input
                  name='lastName'
                  placeholder='Last Name'
                  onChange={this.onInputChange}
                  className="form-control"
                  value={this.state.lastName}
                  invalid={!this.validateLastName()}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={2} style={labelStyle}>
                <span>Default Billing <span style={{ color: 'red' }}>*</span></span>
              </Col>
              <Col md={8}>
                <Input
                  type='select'
                  name='billingId'
                  id='billingId'
                  placeholder='Billing'
                  className="form-control"
                  onChange={this.onInputChange}
                >
                  {this.props.addressList.map((address, index) =>
                    (address.addressType == "B") ? <option key={index} value={address.id} selected>{`Address ${index + 1} - ${address.addressLine1}`}</option> : <option key={index} value={address.id}>{`Address ${index + 1} - ${address.addressLine1}`}</option>
                  )}
                </Input>
              </Col>
            </Row>
            <br />
            <div style={{ textAlign: 'right' }}>
              {(hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ?
                <Button color='custom' type='button' onClick={this.onSubmit} disabled={!this.validateForm()}>
                  Save Changes
                </Button>
                : null}
            </div>

            {
              this.props.addressList.map((address, index) =>

                <ClientEditAddress
                  selectedRow={this.props.selectedRow}
                  address={address}
                  index={index}
                  setAddress={this.props.setAddress}
                  getAddress={this.props.getAddress}
                  provinceMap={this.props.provinceMap}
                  userObj={this.props.userObj}

                />

              )
            }
            {(hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ?
              <ClientEditAddress
                selectedRow={this.props.selectedRow}
                address={null}
                index={0}
                key='add'
                setAddress={this.props.setAddress}
                getAddress={this.props.getAddress}
                provinceMap={this.props.provinceMap}
                userObj={this.props.userObj}

              />
              :
              null}


          </Col>
          <br />

        </CardBody>
      </Card >



    )
  }
}

const mapStateToProps = (state) => {
  return state.user;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCountries, getCarrierList },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientEditBoard);
