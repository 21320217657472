/* eslint-disable */
import React, { Component, Fragment } from 'react';
import log from 'loglevel';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Select from 'react-select';
import FileloaderModal from './UploaderModal';
import { PlusIcon, EditIcon } from 'mdi-react';

const paytypeoptions = [
    {value: "P" ,label: "Payment"},
    {value: "R" ,label: "Refound"}
];
const supplieroptions = [
    {value: "Sigfox" ,label: "Sigfox"},
    {value: "wifi" ,label: "Airbox Wifi"}
];
const orderoptions = [
    {value: "001" ,label: "001"}
];
const methodoptions = [
    {value: "Visa",label:"Visa"},
    {value: "Master",label:"Master"},
    {value: "Debit",label:"Debit"},
    {value: "E Transfer",label:"E Transfer"},
];
const currencyoptions = [
    {value: "USD" , label: "USD"},
    {value: "CAD" , label: "CAD"}
];

class InventoryPayment extends Component {
    constructor(props){
        super(props);
        this.state = {
            modal: false,
            defaultValue: {},
            newValue: {},
            payments: [
                {
                  id: "p1234567",
                  order: "001",
                  method: "Visa",
                  type: "P",
                  status: "Completed",
                  date: "2020-03-19",
                  amount: "100",
                  currency: "CAD",
                  reference: "",
                  payto: "Sigfox",
                  refundfrom: "",
                },
                {
                  id: "p1234568",
                  order: "001",
                  method: "Master",
                  type: "R",
                  status: "Completed",
                  date: "2020-03-19",
                  amount: "100",
                  currency: "CAD",
                  reference: "",
                  payto: "",
                  refundfrom: "Sigfox",
                },
                {
                  id: "p1234569",
                  order: "001",
                  method: "Visa",
                  type: "P",
                  status: "Completed",
                  date: "2020-03-19",
                  amount: "100",
                  currency: "CAD",
                  reference: "",
                  payto: "Sigfox",
                  refundfrom: "",
                }
            ],
        }
    }

    componentWillUnmount(){
        this.setState({
            newValue: {},
        });
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            newValue: {},
        }));
    }

    handleAddNewButtonClick = (onClick) => {
        log.debug('add new button click', onClick);
        this.setState({
            defaultValue: {}
        });
        this.toggle();
    }

    addNewButton = (onClick) => {
        return (
            <InsertButton
              btnText='Add New'
              btnContextual='btn-success'
              // className='my-custom-class'
            //   style={{backgroundColor:'#ffffff'}}
              btnGlyphicon='glyphicon-edit'
              onClick={ () => this.handleAddNewButtonClick(onClick) }>
                  {/* <PlusCircleOutlineIcon size={100}/> */}
                  {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
                  <PlusIcon/> New Transaction
              </InsertButton>
          // <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
        );
    }

    onRowClick = (e, row) => {
        let paytype = paytypeoptions.find(item => item.value == row.type);
        let supplierValue = row.type == 'P' ? row.payto : (row.type == 'R' ? row.refundfrom : '');
        let supplier = supplieroptions.find(item => item.value == supplierValue);
        let order = orderoptions.find(item => item.value == row.order);
        let method = methodoptions.find(item => item.value == row.method);
        let currency = currencyoptions.find(item => item.value == row.currency);
        let amount = row.amount;
        this.setState({
            defaultValue: {
                paytype,
                supplier,
                order,
                method,
                currency,
                amount
            }
        }, ()=>{log.debug('on row click', this.state.defaultValue);});
        this.toggle();
    }

    actionFormatter = (cell, row) => {
        return (
            <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={(e) => {this.onRowClick(e, row)}}/>    
        );
    }

    renderPaymentList = () => {
        const options = {
            insertBtn: this.addNewButton,
        }
        return (
            <BootstrapTable 
                data={ this.state.payments }  
                // tableHeaderClass = 'header_white' 
                options={ options }
                pagination = {false} 
                tableHeaderClass = 'header_white'   
                 
                search
                bordered={ false } 
                insertRow 
                ref='table' 
            >
                <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='10%' hidden >Payment Id</TableHeaderColumn>
                <TableHeaderColumn dataField='date' dataAlign="center" dataSort width='10%' > Date</TableHeaderColumn> 
                <TableHeaderColumn dataField='order' dataAlign="center" dataSort width='10%' >Order</TableHeaderColumn>
                <TableHeaderColumn dataField='method' dataAlign="center" dataSort width='10%' > Method</TableHeaderColumn>
                <TableHeaderColumn dataField='amount' dataAlign="center" dataSort width='10%' > Amount</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='type' dataAlign="center" dataSort width='10%' > Type</TableHeaderColumn> */}
                <TableHeaderColumn dataField='payto' dataAlign="center" dataSort width='10%' >Receiver</TableHeaderColumn>
                <TableHeaderColumn dataField='refundfrom' dataAlign="center" dataSort width='10%' >Refund</TableHeaderColumn>
                <TableHeaderColumn dataField='status' dataAlign="center" dataSort width='10%' >Status</TableHeaderColumn>
                <TableHeaderColumn dataField='reference' dataAlign="center" dataSort width='10%' >Reference</TableHeaderColumn>
                <TableHeaderColumn dataField='action' dataFormat={this.actionFormatter} dataAlign="center" dataSort  width='5%'></TableHeaderColumn>
            </BootstrapTable>
        );
    }

    handleSelect = (value, target) => {
        // log.debug('handleSelect value', value);
        // log.debug('handleSelect target', target);
        this.setState({
            [target.name]: value,
        },()=>{log.debug('select update', this.state)});

        this.setState(prevState => ({
            newValue: {...prevState.newValue, ...{[target.name]: value}}
        }),()=>{log.debug('select update', this.state)});
    }

    handleInput = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        this.setState({
            [key]: value
        });

        this.setState(prevState => ({
            newValue: {...prevState.newValue, ...{ [key]: value}}
        }),()=>{log.debug('select update', this.state)});
    }

    onSaveClick = (e) => {

    }

    onCancelClick = (e) => {
        this.toggle();
    }

    renderFileloaderModal=()=>{
        // const { shippingAddress } = this.state; 
        // const paytypeoptions = [
        //     {value: "P" ,label: "Payment"},
        //     {value: "R" ,label: "Refound"}
        // ];
        // const supplieroptions = [
        //     {value: "sigfox" ,label: "Sigfox"},
        //     {value: "wifi" ,label: "Airbox Wifi"}
        // ];
        // const orderoptions = [
        //     {value: "001" ,label: "001"}
        // ];
        // const methodoptions = [
        //     {value: "Visa",label:"Visa"},
        //     {value: "Master",label:"Master"},
        //     {value: "Debit",label:"Debit"},
        //     {value: "E Transfer",label:"E Transfer"},
        // ];
        // const currencyoptions = [
        //     {value: "USD" , label: "USD"},
        //     {value: "CAD" , label: "CAD"}
        // ];

        return (
            <div>
                {/* <Button color="custom" onClick={this.toggle}>
                    Upload Received Devices
                </Button> */}
                <Modal isOpen={this.state.modal} toggle={this.toggle} size='lg'>
                    <ModalHeader  
                        toggle={this.toggle}
                        //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                    >
                        Insert Transaction
                    </ModalHeader>
                    <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                        {/* <Row>
                            
                            <Label style={{ display: 'inline'}}> <h4>Device :</h4> </Label>
                            
                            <Label style={{ display: 'inline', marginLeft:'10px'}}> <h4>{this.state.deviceId}</h4> </Label>
                        </Row> */}
                        <Row style={{marginTop: 10}}>
                            <Col md={4}>
                                <Label for="paytype">Type</Label>
                                <Select
                                    // isMulti
                                    name="paytype"
                                    options={paytypeoptions}
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    // value={this.state.paytype}
                                    // defaultValue={paytypeoptions? paytypeoptions.find(item => (item.value == this.state.defaultValue.paytype)) : null}
                                    defaultValue={ this.state.defaultValue.paytype }
                                    onChange={this.handleSelect}
                                />
                            </Col>
                            <Col md={4}>
                                <Label for="supplier">Supplier</Label>
                                <Select
                                    // isMulti
                                    name="supplier"
                                    options={supplieroptions}
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    // value={this.state.supplier}
                                    defaultValue={ this.state.defaultValue.supplier }
                                    onChange={this.handleSelect}
                                />
                            </Col>
                            <Col md={4}>
                                <Label for="order">Order</Label>
                                <Select
                                    // isMulti
                                    name="order"
                                    options={orderoptions}
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    // value={this.state.order}
                                    defaultValue={ this.state.defaultValue.order }
                                    onChange={this.handleSelect}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10}}>
                            <Col md={6}>
                                <Label for="method">Method</Label>
                                <Select
                                    // isMulti
                                    name="method"
                                    options={methodoptions}
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    // value={this.state.method}
                                    defaultValue={ this.state.defaultValue.method }
                                    onChange={this.handleSelect}
                                />
                            </Col>
                            <Col md={4}>
                                <Label for="amount">Amount</Label>
                                <Input 
                                    name="amount" 
                                    type='number' 
                                    // value = {this.state.amount} 
                                    defaultValue = {this.state.defaultValue.amount}
                                    onChange={this.handleInput}
                                />
                            </Col>
                            <Col md={2}>
                                <Label for="currency">Currency</Label>
                                <Select
                                    // isMulti
                                    name="currency"
                                    options={currencyoptions}
                                    // className="basic-multi-select"
                                    // classNamePrefix="select"
                                    // value={this.state.currency}
                                    defaultValue={ this.state.defaultValue.currency }
                                    onChange={this.handleSelect}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="custom" onClick={this.onSaveClick}>Save</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render() {
        return (
            <Card>
                <div className ='card-pginside'>
                    {this.renderPaymentList()}
                    {this.renderFileloaderModal()}
                </div>
            </Card>
        );
    }
}
export default InventoryPayment;