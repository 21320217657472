/* eslint-disable */
import React, { Component } from 'react';
// import { Col, Container, Row } from 'reactstrap';
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import MapContainer from './MapContainer';
import MapDeviceContainer from './MapDeviceContainer';
import InfoContainer from './InfoContainer';
import FilterContainer from './FilterContainer';
import {loadDevice, loadDeviceAttr, loadDivceMapData, bindFilterTheme, bindFilterMessageType, bindFilterDeviceType, bindFilterFrom, bindFilterTo, bindFilterAttribute, filterMapDataByAttribute, loadAllAssets, getAssetsData, loadAssetClass} from '../../../redux/actions/mapActions';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    MAP_DEVICE_ATTR_LOAD_SUCCEED, 
    MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED,
    MAP_DEVICE_MAP_LOAD_SUCCEED, 
    MAP_FILTER_ATTRIBUTE_CHANGE,
    MAP_GET_ASSETS_DATA_SUCCEED, 
    MAP_FILTER_ASSET_ATTRIBUTE_CHANGE,
    MAP_DEVICE_DATA,
    MAP_ASSET_DATA ,
} from '../../../constants/actionTypes'; 

class MapBoard extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentAssetId: 5,
            currentAssetClassid: 2,
            data:[
                {lat:-1.2884,lng:36.8233,info:'test1'}, 
                {lat:-1.2684,lng:36.8033,info:'test2'}, 
                {lat:-1.3084,lng:36.8433,info:'test3'}
            ],
            mapProps: {
                showingInfoWindow: false,
                activeMarker: {},
                selectedPlace: {attr:{}},
            },
            activeMap: '1',
        };
    }

    componentDidMount(){
        this.props.loadDevice();
        // this.props.loadDivceMapData({devicetype:this.props.currentDeviceTypeId});
        this.props.loadAllAssets();
        this.props.loadAssetClass();
        // this.props.loadDivceMapData({assetid:this.state.currentAssetId, latest:1});
        this.props.getAssetsData({assetclassid: this.props.currentAssetClassId, latest:1});
        this.props.loadDeviceAttr({devicetypeid: this.props.currentDeviceTypeId});
    }

    componentWillReceiveProps(nextProps){}

    handleMapMarkerClick = (props, marker, e) => {
         
        //  
         
        //  
        this.setState({
            mapProps: {
                showingInfoWindow: true,
                activeMarker: marker,
                selectedPlace: props,
                lat: props.position.lat,
                lng: props.position.lng,
            },
        });  
               
    }

    handleMapInfoWinClick = (props) => {
        if(this.state.mapProps.showingInfoWindow){
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    // onDeviceLoad = () =>{
    //     this.props.loadDevice();
    // }

    mapToggle = (tab) => {
        // this.setState({
        //     activeDevice:pageIndex,
        // });

        if (this.state.activeMap !== tab) {
            this.setState({
                activeMap: tab
            });
        }
    }

    renderMap=()=>{
        switch(this.props.dataType){
            case MAP_DEVICE_DATA:
                return(
                    <MapDeviceContainer onMarkerClick={this.handleMapMarkerClick} 
                        onInfoWinClick={this.handleMapInfoWinClick}
                        values = {this.state.mapProps}
                        mapData = {this.props.mapData}
                    />
                )
            case MAP_ASSET_DATA:
                return(
                    <MapContainer onMarkerClick={this.handleMapMarkerClick} 
                        onInfoWinClick={this.handleMapInfoWinClick}
                        values = {this.state.mapProps}
                        mapData = {this.props.assetData}
                    />
                )
        }
    }

    render(){
         
         
        return (
            <Container md = {12}>
                <Row>
                    {/* <div style={{backgroundColor: "#FF0000", width: "100%"}}>red</div> */}
                    <FilterContainer types = {this.props.types} 
                                    attr = {this.props.attr} 
                                    assets = {this.props.assets}
                                    assetClasses = {this.props.assetClass}
                                    // currentTheme = {this.props.currentThemeId} 
                                    // currentDeivecType = {this.props.currentDeviceTypeId}
                                    // currentMessageType = {this.props.currentMessageTypeId}
                                    // currentFromTime = {this.props.currentFromTime}
                                    // currentToTime = {this.props.currentToTime}
                                    // currentAttribute = {this.props.currentAttributeId}
                                    // bindFilterTheme = {this.props.bindFilterTheme}
                                    // bindFilterMessageType = {this.props.bindFilterMessageType}
                                    // bindFilterDeviceType = {this.props.bindFilterDeviceType}
                                    // bindFilterFrom = {this.props.bindFilterFrom}
                                    // bindFilterTo = {this.props.bindFilterTo}
                                    // bindFilterAttribute = {this.props.bindFilterAttribute}
                                    // filterMapDataByAttribute = {this.props.filterMapDataByAttribute}
                    />
                </Row>
                <Row>
                    {/* <Col  md={8}>
                        <MapContainer onMarkerClick={this.handleMapMarkerClick} 
                                onInfoWinClick={this.handleMapInfoWinClick}
                                data = {this.state.data}
                                values = {this.state.mapProps}
                                mapData = {this.props.mapData}
                        />
                          
                    </Col >
                    <Col  md={4}>
                        <InfoContainer data={this.state.data}  mapData = {this.props.mapData}/>
                    </Col> */}
                    <Card>
                    <CardBody > 
                     <Nav tabs>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeMap === '1' })}
                            onClick={() => { this.mapToggle('1'); }}
                            >
                                <h4><b>Map</b></h4>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                            className={classnames({ active: this.state.activeMap === '2' })}
                            onClick={() => { this.mapToggle('2'); }}
                            >
                               <h4><b>Data</b></h4>
                            </NavLink>
                        </NavItem>
                    </Nav>

                <TabContent activeTab={this.state.activeMap}>
                    <TabPane tabId="1">
                        <Row>
                        <Col sm="12">
                            {/* <MapContainer onMarkerClick={this.handleMapMarkerClick} 
                                    onInfoWinClick={this.handleMapInfoWinClick}
                                    // data = {this.state.data}
                                    values = {this.state.mapProps}
                                    // mapData = {this.props.mapData}
                                    mapData = {this.props.assetData}
                            /> */}
                            {this.renderMap()}
                        </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                        <Col sm="12">
                            <InfoContainer data={this.state.data}  mapData = {this.props.mapData} assetData = {this.props.assetData} dataType = {this.props.dataType}/>
                        </Col>
                        </Row>
                    </TabPane>
                </TabContent>
                </CardBody>          
                </Card>   

                </Row>
            </Container>                    
        );
    }
}

function mapStateToProps(state) {
     
     
    const types = state.mapboard.types;
    const groups = state.mapboard.groups;
    const status =state.mapboard.status;
    const attr = state.mapboard.attr;
    const mapData = state.mapboard.mapData;
    const assets = state.mapboard.assets;
    const assetData = state.mapboard.assetData;
    const assetClass = state.mapboard.assetClass;
    const dataType = state.mapboard.dataType;
     
     
    // const currentTheme = state.mapfilter.currentThemeId;
    const { currentDeviceTypeId, currentMessageTypeId, currentThemeId, currentFromTime, currentToTime, currentAttributeId, currentAssetClassId} = state.mapfilter;
    const props = {types, groups, status, attr, mapData, assets, assetData, assetClass, dataType, currentThemeId, currentDeviceTypeId, currentMessageTypeId, currentFromTime, currentToTime, currentAttributeId, currentAssetClassId};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            loadDevice, 
            loadDeviceAttr, 
            loadDivceMapData, 
            bindFilterTheme, 
            bindFilterMessageType, 
            bindFilterDeviceType, 
            bindFilterFrom, 
            bindFilterTo, 
            bindFilterAttribute, 
            filterMapDataByAttribute, 
            loadAllAssets,
            getAssetsData,
            loadAssetClass,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MapBoard);