/* eslint-disable */
import React, {Component, Fragment, useEffect, useState} from 'react';
import {
    // compose, 
    bindActionCreators
} from "redux";
import {connect} from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import {WEB_SUB_CHANNEL_WEATHER, WEB_SUB_CHANNEL_WEATHER_ALARM, WEB_SUB_EVENT_WEATERHR, WEB_SUB_EVENT_ALARM, WEB_SUB_CHANNEL_DEVICE,WEB_SUB_EVENT_DEVICE} from '../../../constants/appConstants';
import {OCCUPANCY_KEYS} from '../../../constants/dataConstants';
import moment from 'moment';
import {
    getWeatherAssetData,
    clearWeatherAssetData,
    getCompanyAssetClass,
    getWeatherAlarmCount,
    clearWeatherAlarmCount,
    getWeatherAssetHistoryData,
    getWeatherAsset24HData,
    clearWeatherAssetHistoryData,
    updateWeatherAssetData,
    getWeatherHourlyAlarmCount,
    updatePubSubAssetData,
    pubsubUpdateWeatherAssetHistoryData,
    pubsubUpdateWeatherAsset24HData,
    getLightning24HAlarmCount,
    geteWeatherMonthlyAlarmCount,
    getWeatherQuarterMonthAlarmCount,
    getWeatherAsset1MonthData,
    getWeather6MonthsAlarmCount,
    getWeatherAlarmCountList,
} from '../../../redux/actions/weatherAction';
import OccupancyMainBoard from './OccupancyMainBoard';
import HistoryBoard from './HistoryBoard';
import { toast } from 'react-toastify';
import { reformWeatherAssetData, reformAlertPubsubData, updateStatusAsset } from '../../../factories/weather';
import {ATTRIBUTE_NAME_ORDER, LIGHT_GREY_COLOR} from '../../../constants/dataConstants';
import Spinner from 'react-bootstrap/Spinner';
import { ThumbsDownIcon } from 'mdi-react';

const WEATHER_ASSETCLASS_NAME = 'WeatherStation';
const WEATHER_ASSETCLASS_NAME_ARRAY = ['OccupancyMonitor'];
import {WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT,WEB_SUB_WEBSOCKET_OCCUPANCY_DEVICEMESSAGE,WEB_SUB_WEBSOCKET_OCCUPANCY_ALERTMESSAGE,WEB_SUB_WEBSOCKET_OCCUPANCY_ALARMMESSAGE} from '../../../constants/appConstants';
import {getJwttoken } from '../../../factories/utils';
import { Client } from '@stomp/stompjs';
var stompClient;
class MainBoard extends Component{
    constructor(props){
        super(props);
        this.state={
            showHistory: false,
            sixMonthsStartMoment: null,
            sixMonthsEndMoment: null,
            quarterMonthStartMoment: null,
            quarterMonthEndMoment: null,
            monthlyStartMoment: null,
            monthlyEndMoment: null,
            startDateMoment: null,
            endDateMoment: null,
            startTime: null,
            endTime: null,
            isTimeDisabled: true,
            currentAsset: null, 
            assetList: [],
            assetAlarmList: [],
            alarmList: [],
            alarmSet: {},
            alarmSetObj: {},
            statusData: [],
            min: null,
        };
        this.weatherClassId = null;
        // this.eventSource = new EventSource(WEB_SUB_CHANNEL_WEATHER);
        // this.alertEventSource = new EventSource(WEB_SUB_CHANNEL_WEATHER_ALARM);
        // this.deviceEventSource = new EventSource(WEB_SUB_CHANNEL_DEVICE);
    }
    
    async componentDidMount(){
        // this.eventSource.addEventListener(
        //     WEB_SUB_EVENT_WEATERHR, 
        //     (e)=> {
                 
        //         this.refreshAssetData(e.data);
        //     } 
        // );
        // this.alertEventSource.addEventListener(
        //     WEB_SUB_EVENT_ALARM, 
        //     (e)=> {
                 
        //         this.refreshAlarm(e.data);
        //         this.refreshAlarmSetObjByAlert(e.data);
        //         this.updateStatusAssetByAlert(e.data);
        //     } 
        // );
        // this.deviceEventSource.addEventListener(
        //     WEB_SUB_EVENT_DEVICE, 
        //     (e)=> {
        //         this.refreshAttributeHistoryData(e.data);
        //     } 
        // );
        // --------------------------------------------
        await this.loadAssetByCompanyClass();
        this.onHistoryListShow(this.state.assetList);
        window.addEventListener("resize", this.resize);
        this.resize();
        this.connectSocket();
    }
        //------------------------------------------------------------------------------
        connectSocket=()=> { 
            if (stompClient !== null && stompClient!==undefined) {
                stompClient.deactivate();
                //stompClient = null;
                 
            }
            
            let query='?q='+getJwttoken();
            ///////////////////////////////////////////////////
            stompClient = new Client({
                brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query,
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,
                onConnect: this.connect_callback,
                onDisconnect: this.disconnect_callback
              });
          
              stompClient.activate();
            //////////////////////////////////////////////////
            //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
            //stompClient = Stomp.over(socket_URL); 
            //stompClient.reconnectDelay=5000; 
            //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;
        
         }
         connect_callback =()=> {
            // called back after the stompClient is connected and authenticated to the STOMP server
            stompClient.subscribe(WEB_SUB_WEBSOCKET_OCCUPANCY_DEVICEMESSAGE, this.callbackDeviceMessage);
            stompClient.subscribe(WEB_SUB_WEBSOCKET_OCCUPANCY_ALERTMESSAGE, this.callbackAlertMessage);
            stompClient.subscribe(WEB_SUB_WEBSOCKET_OCCUPANCY_ALARMMESSAGE, this.callbackAlarmMessage);
        }
        disconnect_callback =()=> {
            stompClient.deactivate();
            // called back after the stompClient is connected and authenticated to the STOMP server
            console.log('client disconnected')
        }

        callbackDeviceMessage =(message)=> {
            // called when the client receives a STOMP DeviceMessage from the server
            if (message.body) {
              //console.log("got DeviceMessage with body " + message.body)
              this.refreshAttributeHistoryData(message.body);
              
            } else {
                // 
        }
        }
        callbackAlertMessage =(message)=> {
            // called when the client receives a STOMP AlertMessage from the server
            
            if (message.body) {
                //console.log("got AlertMessage with body " + message.body)
                this.refreshAlarm(message.body);
                this.refreshAlarmSetObjByAlert(message.body);
                this.updateStatusAssetByAlert(message.body);
                
              } else {
                 //  
          }
        }
        callbackAlarmMessage =(message)=> {
            // called when the client receives a STOMP alarmMessage from the server      
            if (message.body) {
              //console.log("got AlarmMessage with body " + message.body)
              this.refreshAssetData(message.body);
            } else {
               //  
        }
        }
        //-----------------------------------------------------------------
    
    // componentDidUpdate() {
       
    // }

    componentWillUnmount(){
        this.clearAssetData();
        if (stompClient !== null && stompClient!==undefined) {
            stompClient.deactivate();
        }

        window.removeEventListener("resize", this.resize)
    }

    componentWillReceiveProps(nextProps){
        // console.log("===COMPONENT WILL RECEIVE NEXT PROPS 1111",nextProps)
        if(nextProps.assetData && nextProps.assetData.length > 0 && nextProps.assetData != this.props.assetData) {
            let statusData = reformWeatherAssetData(nextProps.assetData);
            if(statusData && statusData[0].grouping == "occupancy"){
                this.setState({
                    statusData,
                });
            }
        }
        this.setState({})
    }
    // ------------------------------------------------------------------------------------------
    resize = () => {
        let currentIsSmall = (window.innerWidth <= 1370);
        this.setState(prev=>{
            if (currentIsSmall !== prev.isSmall){
                return({
                    isSmall: currentIsSmall
                });
            }
        });
    }
    // ------------------------------------------------------------------------------------------
    // ----------- load helpers ------------------
    loadAssetByCompanyClass = async() => {
        try{
            if (this.weatherClassId == null) {
                let res = await getCompanyAssetClass();
                //  
                if(res.status === 200 && res.data){
                    this.weatherClassId = [];
                    for(let i=0; i< res.data.length; i++){

                        if (WEATHER_ASSETCLASS_NAME_ARRAY.includes(res.data[i].assetclassname)){
                            let assetclassId = res.data[i].assetclassid;
                            this.weatherClassId.push(assetclassId);
                            let assets = res.data[i].assets;
                            this.setState(prevState => {
                                if (Array.isArray(prevState.assetList) && prevState.assetList.length > 0) {
                                    return ({
                                        assetList: [...prevState.assetList, ...assets],
                                    });
                                } else {
                                    return({
                                        assetList: assets,
                                    });
                                }

                            })
                        }
                    }
                }else{
                    throw new Error('Failed to get Weather Asset Class!');
                }
            }
            if (this.weatherClassId != null) {
                this.loadAssetData(this.weatherClassId);
            }else{
                throw new Error('Could not find Weather Asset Class!');
            }
        }
        catch(err){
            console.error(err);
            toast.error(err);
        }
        
    }

    loadAssetData = (assetclassId) => {
        let params = {
            // entitygroup:[assetclassId],
            entitygroup: assetclassId,
            entity:'',
            starttime: "", 
            endtime: "",
            latest: "1",
            themeid:"", 
        };
        this.props.getWeatherAssetData(params);
    }

    // ------------------------------------------------------------------------------------------
    // ----------- update helplers ---------------
    refreshAssetData = (data) => {
        //  
        try{
            let subObj = JSON.parse(data);

            if(subObj){
                //this.props.assetData
                let isInclude = this.props.assetData.some((item) =>(item.assetid === subObj.device.assetid));
                let isDevice = Object.keys(OCCUPANCY_KEYS).some(key => Object.keys(subObj).includes(key))
                //  //false
                //  // true
                //console.log(subObj)
                //  
                //  
                if (isInclude && isDevice) {
                    this.props.updateWeatherAssetData(this.props.assetData, subObj);
                }
                //  
                //  
                //  
                // console.log(subObj.device.assetid)
                let isCurrent = (this.state.currentAsset && subObj.device && this.state.currentAsset.assetid === subObj.device.assetid);
                //  
                // console.log(isDevice)
                // console.log(isCurrent)
                if (this.state.showHistory && isCurrent && isDevice) {
                    this.updataHistoryData();
                }
            }
        } 
        catch(e){
            console.error('sub-error-alert', e);
        }
    }
    clearAssetData =() => {
        this.props.clearWeatherAlarmCount();
        this.props.clearWeatherAssetHistoryData();
    }

    refreshAlarm = (data) => {
        //  
        try{
            if (data) {
                let dataObj = JSON.parse(data);
                //  
                if (dataObj && dataObj.msgList) {
                    //  
                    this.setState({
                        alarmList: dataObj.msgList,
                        alarmObj: dataObj
                    });

                    this.setState(prevState => {
                        //  
                        for(let alarm of dataObj.msgList) {
                            //  
                            //  
                            //  
                            if (alarm.assetid && alarm.attrsMap){ 
                                //  
                                if (!prevState.alarmSet[alarm.assetid]){
                                    //  
                                    prevState.alarmSet[alarm.assetid] = {};
                                }
                                //  
                                for(let attrubute in alarm.attrsMap){
                                    prevState.alarmSet[alarm.assetid][attrubute] = {
                                        status: dataObj.status, 
                                        value: alarm.attrsMap[attrubute].value, 
                                        timestamp: alarm.attrsMap[attrubute].timestamp,
                                    }
                                }
                            }
                        }
                         
                        return({
                            alarmSet: prevState.alarmSet,
                        })
                    });
                }
            }
        }
        catch(e){
            console.error('sub-error-alarm', e);
        }
    };

    refreshAlarmSetObjByAlert = (alertPubsubData) => {
        this.setState(prevState => {
            let updateData = reformAlertPubsubData(alertPubsubData, prevState.alarmSetObj);
            return({
                alarmSetObj: updateData,
            });
        },()=>{
             
        });
    }

    updateStatusAssetByAlert = (alertPubsubData) => {
        console.log("UPDATE STATUS ASSET BY ALERT")
        this.setState(prevState => {
            let updateData = updateStatusAsset(alertPubsubData, prevState.statusData);
             
            return({
                statusData: updateData,
            });
        },()=>{
             
        })
    }

    refreshDeviceData = (data) => {
        //  
        try{
            if (data) {
                //  
                let dataObj = JSON.parse(data);
                //  
                if (dataObj && dataObj.msgs && dataObj.msgs.length > 0 && dataObj.msgs[dataObj.msgs.length-1].msg) {
                    let hasAssetObj = this.props.assetData.some((item) =>(item.assetid === dataObj.assetid));
                    //  
                    //  
                    //  
                    if (hasAssetObj){
                        let msgObj = dataObj.msgs[dataObj.msgs.length-1].msg;
                        let isInclude = !Object.keys(msgObj).some(item=>(Object.values(OCCUPANCY_KEYS).includes(item)));
                        //  
                        //  
                        if (isInclude) {
                            // updata asset data.
                            //  
                            // this.props.updatePubSubAssetData(this.props.assetData, dataObj);
                            this.loadAssetByCompanyClass();
                            let isCurrent = (this.state.currentAsset && this.state.currentAsset.assetid === dataObj.assetid);
                            if (this.state.showHistory && isCurrent) {
                                this.updataHistoryData()
                            }
                        }
                    }
                }
            }
        }catch(e){
            console.error('sub-error-device', e);
        }
    }
    // -------------------------------------------------------------------------------------------
    toggle = (toggle, asset) => {
         
        if (toggle != undefined && toggle != null && typeof toggle === 'boolean'){
        //   setShowHistory(toggle);
          this.setState({ 
                showHistory: toggle,
            },()=>{this.onShowHistoryChange(asset)});
        }else{
        //   setShowHistory(!showHistory);
          this.setState(prevState=>({
                showHistory: !prevState.showHistory,
            }),
            ()=>{this.onShowHistoryChange()}
          );
        }
        
    }
    
    handleFilterChange = (newState) => {
        this.setState(prevState => (
                {...newState}
            ),
            () => {
                this.updataHistoryData();
                this.updateAttributeHistoryData();
            }
        );
    }

    onShowHistoryChange = (asset) => {
        if (this.state.showHistory){
            this.onHistoryShow(asset);
        }else{
            this.onHistoryHide();
        }
    }

    // To display monthly access count on dashboard.
    onHistoryListShow = (assetList) => {
        let endTime = moment();
        let startTime = moment(endTime).startOf('month');

        // let assetList = this.state.assetList ? this.state.assetList : [];

        this.setState({
            assetList: assetList,
            monthlyStartMoment: startTime,
            monthlyEndMoment: endTime
        },
        () => {
            this.updateHistoryListData();
        });
    }
    onHistoryShow = (asset) => {
        // let endtime = new Date();
        let endtime = moment();
        let starttime = moment(endtime).subtract( 3, 'days' );
        let monthlyEndTime = moment();
        let monthlyStartTime = moment(monthlyEndTime).startOf('month');
        let quarterMonthEndTime = moment();
        let quarterMonthStartTime = moment().startOf('quarter');
        let sixMonthsEndTime = moment();
        let sixMonthsStartTime = moment(sixMonthsEndTime).subtract(180 , 'days');

        // let currentAsset = asset;
        let currentAsset = this.state.assetList.length > 0 ? this.state.assetList.find((item, index)=>(item.assetid === asset.assetid)) : [];
        if (!currentAsset) {
            currentAsset = this.state.assetList[0];
        }
        // let currentAsset = this.state.assetList[0];
        this.setState({
                currentAsset: currentAsset,
                startDateMoment: starttime,
                endDateMoment: endtime,
                monthlyStartMoment: monthlyStartTime,
                monthlyEndMoment: monthlyEndTime,
                quarterMonthStartMoment: quarterMonthStartTime,
                quarterMonthEndMoment: quarterMonthEndTime,
                sixMonthsStartMoment: sixMonthsStartTime,
                sixMonthsEndMoment: sixMonthsEndTime
            },
            () => {
                this.updateMonthlyHistoryData(); // Single asset Monthly Charts tab API call
                this.updataHistoryData(); // Single asset Details tab & Data tab API call
                this.updateAttributeHistoryData();
            }
        );
    }
    onHistoryHide = () => {
        this.clearAssetData();
    }
    
    updateHistoryListData = () => {
        let { assetList, monthlyStartMoment, monthlyEndMoment } = this.state;
        let monthlyStartStr = monthlyStartMoment ? monthlyStartMoment.utc().format('YYYY-MM-DD') : '';
        let monthlyEndStr = monthlyEndMoment ? monthlyEndMoment.utc().format('YYYY-MM-DD') : '';
        let assetidList = []
        
        for(let i = 0; i < assetList.length; i++) {
            assetidList.push(assetList[i].assetid)
        }

        let paramList = {
            assetidList: assetidList ? assetidList : 0,
            starttime: monthlyStartStr,
            endtime: monthlyEndStr,
        }

        this.props.getWeatherAlarmCountList(paramList);
    }

    //Separate Monthly Charts tab API call and Detail tab API call to prevent unnecessary API calls.
    //Monthly Charts tab API calls.
    updateMonthlyHistoryData  = () => { 
        let {currentAsset, monthlyStartMoment, monthlyEndMoment, quarterMonthStartMoment, quarterMonthEndMoment, sixMonthsStartMoment, sixMonthsEndMoment } = this.state;
        //  
        let monthlyStartStr = monthlyStartMoment ? monthlyStartMoment.utc().format('YYYY-MM-DD') : '';
        let monthlyEndStr = monthlyEndMoment ? monthlyEndMoment.utc().format('YYYY-MM-DD') : '';
        let quarterMonthStartStr = quarterMonthStartMoment ? quarterMonthStartMoment.utc().format('YYYY-MM-DD') : '';
        let quarterMonthEndStr = quarterMonthEndMoment ? quarterMonthEndMoment.utc().format('YYYY-MM-DD') : '';
        let sixMonthsStartStr = sixMonthsStartMoment ? sixMonthsStartMoment.utc().format('YYYY-MM-DD') : '';
        let sixMonthsEndStr = sixMonthsEndMoment ? sixMonthsEndMoment.utc().format('YYYY-MM-DD') : '';

        
        let paramMonthly = {
            assetid: currentAsset ? currentAsset.assetid : 0,
            starttime: monthlyStartStr,
            endtime: monthlyEndStr
        }

        this.props.geteWeatherMonthlyAlarmCount(paramMonthly);

        let paramQuarterMonth = {
            assetid: currentAsset ? currentAsset.assetid : 0,
            starttime: quarterMonthStartStr,
            endtime: quarterMonthEndStr
        }
        this.props.getWeatherQuarterMonthAlarmCount(paramQuarterMonth);

        let paramSixMonths = {
            assetid: currentAsset ? currentAsset.assetid : 0,
            starttime: sixMonthsStartStr,
            endtime: sixMonthsEndStr
        }
        this.props.getWeather6MonthsAlarmCount(paramSixMonths);

    }

    //Details Tab API calls
    updataHistoryData = () => { 
        let {currentAsset, startDateMoment, endDateMoment, startTime, endTime, isTimeDisabled } = this.state;
        //  
        let startDateStr = startDateMoment ? startDateMoment.utc().format('YYYY-MM-DD') : '';
        let endDateStr = endDateMoment ? endDateMoment.utc().format('YYYY-MM-DD') : ''; 
        
        

        if (startDateStr === '' || endDateStr === ''){
            return;
        }
        if (startDateStr === endDateStr) {
            if (isTimeDisabled){
                this.setState({
                    isTimeDisabled: false,
                });
            }
            if (startTime && endTime){
                let hourlyParam = {
                    assetid: currentAsset ? currentAsset.assetid : 0, //1118,
                    starttime: `${startDateStr}:${startTime.value}:${endTime.value}`,
                    cntHours: 1,//'2020-02-27',
                }
                this.props.getWeatherHourlyAlarmCount(hourlyParam);
            }
            
        }else{
            if (!isTimeDisabled){
                this.setState({
                    isTimeDisabled: true,
                });
            }
            let param = {
                assetid: currentAsset ? currentAsset.assetid : 0, //1118,
                // starttime: startDateMoment ? startDateMoment.utc().format('YYYY-MM-DD') : '',//'2020-02-20',
                // endtime: endDateMoment ? endDateMoment.utc().format('YYYY-MM-DD') : '',//'2020-02-27',
                starttime: startDateStr,
                endtime: endDateStr,
            }
            this.props.getWeatherAlarmCount(param);
        }
    }
    

    // For Details tab -> Data tab
    updateAttributeHistoryData = () => { // For Details tab -> Data tab
        let {currentAsset, startDateMoment, endDateMoment, startTime, endTime, isTimeDisabled} = this.state;
        let startTimestamp = startDateMoment ? startDateMoment.utc().valueOf(): 0;
        let endTimestamp = endDateMoment ? endDateMoment.utc().valueOf() : moment().utc().valueOf();

        if (startTimestamp === endTimestamp){
            if (isTimeDisabled){
                this.setState({
                    isTimeDisabled: false,
                });
            }
            if (startTimestamp != 0 && startTime && endTime){

                let startDateStr = startDateMoment.format('YYYY-MM-DD');
                if (startDateStr) {
                    startTimestamp = moment(`${startDateStr}T${startTime.value}`).utc().valueOf();
                    endTimestamp = moment(`${startDateStr}T${endTime.value}`).utc().valueOf();
                }
            }
        }
        const assetCriteria = {
            entity: currentAsset ? currentAsset.assetid : 0,
        }
        const historyCreteria = {
            ...assetCriteria,
            starttime: startTimestamp, //startDateMoment ? startDateMoment.utc().valueOf(): 0,
            endtime: endTimestamp, //endDateMoment ? endDateMoment.utc().valueOf() : moment().utc().valueOf(),
        }


        this.props.getWeatherAssetHistoryData(historyCreteria);
        this.props.getWeatherAsset24HData(assetCriteria);
        if(currentAsset){
            this.props.getLightning24HAlarmCount(currentAsset.assetid);
            // this.props.getWeatherAlarmCount(currentAsset.assetid);
        }
    }

    refreshAttributeHistoryData = (pubsubData) => {
        let {startDateMoment, endDateMoment,} = this.state;
        this.props.pubsubUpdateWeatherAssetHistoryData(pubsubData, startDateMoment, endDateMoment);
        this.props.pubsubUpdateWeatherAsset24HData(pubsubData);
    }
    // -------------------------------------------------------------------------------------------

    renderSpinner = () => {
        return (
            <Fragment >
                
                {this.props.assetDataLoading &&
                    <div style={{
                        position: 'Absolute ',
                        height: '100%',
                        width: '100%',
                        top: '0px',
                        left: '0px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        backgroundColor: LIGHT_GREY_COLOR,
                        opacity: '1.0',
                    }}
                    >
                        <Spinner
                            className="spinner"
                            as="span"
                            animation="border"
                            variant="success"
                            // size="sm"
                            // role="status"
                            // aria-hidden="true"
                            style={{ display: 'inline-block', margin: 'auto', }}
                        />
                    </div>
                }
            </Fragment>
        );
    }

    render(){
        let currentAssetData = null;
        let historyObjData = null
        let history24HObjData = null;
        if (this.state.currentAsset && this.props.assetData) {
            currentAssetData = this.props.assetData.find((item)=>(item.assetid == this.state.currentAsset.assetid)) ;
            //  
        }
        if(this.state.currentAsset && this.props.assetHistoryObj) {
            //  
            if (this.props.assetHistoryObj[this.state.currentAsset.assetid]) {
                historyObjData = Object.values(this.props.assetHistoryObj[this.state.currentAsset.assetid]);
                //  
            }
        }
        if (this.state.currentAsset && this.props.asset24HObj) {
            //
            if (this.props.asset24HObj[this.state.currentAsset.assetid]) {
                // history24HObjData = Object.values(this.props.asset24HObj[this.state.currentAsset.assetid]);
                history24HObjData = [];
                let dataList = Object.values(this.props.asset24HObj[this.state.currentAsset.assetid]);
                for (let attrname of ATTRIBUTE_NAME_ORDER){
                    let attr = dataList.find(item => (attrname == item.attributename));
                    if (attr){
                        history24HObjData.push(attr);
                    }
                }
            }
            //  
            //  
        }


        return (
            <Fragment>
                    {
                    this.state.showHistory ?
                    <HistoryBoard
                        currentAsset = {this.state.currentAsset}
                        // assetData = {this.props.assetData} 
                        currentAssetData = {currentAssetData}
                        startDateMoment = {this.state.startDateMoment}
                        endDateMoment = {this.state.endDateMoment}
                        startTime = {this.state.startTime}
                        endTime = {this.state.endTime}
                        isTimeDisabled = {this.state.isTimeDisabled}
                        assetList = {this.state.assetList}
                        asset1MonthObj = {this.props.asset1MonthObj}
                        countData = {this.props.alarmCounts}
                        alarmCountsList = {this.props.alarmCountsList}
                        alarmMonthlyCounts = {this.props.alarmMonthlyCounts}
                        alarmQuarterMonthCounts = {this.props.alarmQuarterMonthCounts}
                        alarmSixMonthsCounts = {this.props.alarm6MonthsCounts}
                        countUnit = {this.props.countUnit}
                        historyData = {this.props.assetHistoryData}
                        historyObjData = {historyObjData}
                        history24HObjData = {history24HObjData}
                        allAttriList = {this.props.allAttriList}
                        toggle={this.toggle}
                        handleFilterChange={this.handleFilterChange}
                        isSmall = {this.state.isSmall}
                        alarmSet = {this.state.alarmSet}
                        assetHistoryLoading = {this.props.assetHistoryLoading}
                        assetCountLoading = {this.props.assetCountLoading}
                        assetHourlyCountLoading = {this.props.assetHourlyCountLoading}
                        alarm24HCounts = {this.props.alarm24HCounts}
                        alarm24HCountUnit = {this.props.alarm24HCountUnit}
                        alarm24HCountLoading = {this.props.alarm24HCountLoading}
                        min = {this.state.startDateMoment}
                        updataHistoryData = {this.updataHistoryData}
                        updateAttributeHistoryData={this.updateAttributeHistoryData}
                    />
                    :
                    <OccupancyMainBoard 
                        userObj = {this.props.userObj} 
                        toggle={this.toggle}
                        assetDataLoading = {this.props.assetDataLoading}
                        assetData = {this.props.assetData} 
                        assetExpendData = {this.props.assetExpendData}
                        isSmall = {this.state.isSmall}
                        // alarmList = {this.state.alarmList}
                        alarmObj = {this.state.alarmObj}
                        alarmSet = {this.state.alarmSet}
                        statusData = {this.state.statusData}
                        alarmList = {this.state.alarmList}
                        alarmSetObj = {this.state.alarmSetObj}
                        // extra Monthly access count
                        alarmCountsList = {this.props.alarmCountsList}
                        
                        
                    />          
                    }
                <div>
                    {this.renderSpinner()}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    //this.props=state.weather
    return state.weather;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWeatherAssetData,
        clearWeatherAssetData,
        getWeatherAlarmCount,
        clearWeatherAlarmCount,
        getWeatherAssetHistoryData,
        getWeatherAsset24HData,
        clearWeatherAssetHistoryData,
        updateWeatherAssetData,
        getWeatherHourlyAlarmCount,
        updatePubSubAssetData,
        pubsubUpdateWeatherAssetHistoryData,
        pubsubUpdateWeatherAsset24HData,
        getLightning24HAlarmCount,
        getWeatherAsset1MonthData,
        geteWeatherMonthlyAlarmCount,
        getWeatherQuarterMonthAlarmCount,
        getWeather6MonthsAlarmCount,
        getWeatherAlarmCountList,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainBoard);