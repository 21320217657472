/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SchemaBoard from './components/SchemaBoard';

const AssetSchemaPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h2 className="page-title"><b>Asset Schema</b></h2>
      </Col>
    </Row>
    {/* <Row> */}
      <Col md={12}>
        <SchemaBoard />
      </Col>
    {/* </Row> */}
  </Container>
);

export default AssetSchemaPage;
