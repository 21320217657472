/* eslint-disable */
import React, {Component, Fragment} from 'react';
import {
    // compose,
    bindActionCreators
} from "redux";
import {connect} from "react-redux";
import axios from "axios";
import {WEB_SERVICE_ROOT} from "../../../constants/appConstants";
import {toast} from "react-toastify";



class OutlookCalendar extends Component{
    constructor(props){
        super(props);
        this.state={
            userInfo:null,
            calendarInfo:null,
            calendarInfo2:null
        };

    }

    componentDidMount(){


    }


    async clickShowMe() {
        let res = await axios.get(`${WEB_SERVICE_ROOT}opg/showme`, {});
        this.setState({
            userInfo: res.data
        })
    }



    async clickGetCalander() {
        let data={start: document.getElementById("c_start").value,end:document.getElementById("c_end").value}
        let res = await axios.post(`${WEB_SERVICE_ROOT}opg/getCalendar`, data);
        this.setState({
            calendarInfo: res.data.value[0].scheduleItems
        })
    }

    async clickGetCalander2() {

        let data={start: document.getElementById("start").value,end:document.getElementById("end").value}
        let res = await axios.post(`${WEB_SERVICE_ROOT}opg/getCalendar2`, data);
        this.setState({
            calendarInfo2: res.data
        })
    }


    render(){
        return (<div>
            <h1>Microsoft Outlook</h1>
            <h2>
                <a style={{color:"blue",cursor:"pointer"}} href={"https://login.microsoftonline.com/d5189ead-72d5-4d23-b57e-1a91ca41a8f6/oauth2/v2.0/authorize?"+encodeURI("client_id=b5b04774-9a9a-45dd-806c-dc8e86bfa007&response_type=code&redirect_uri=http://localhost:3000/pages/OpgApp&response_mode=query&scope=User.Read Calendars.ReadWrite&state=123")}>
                    click to auth
                </a>
            </h2>
            {/* <p>
              <button onClick={()=>this.postGetAccessToken()}>
                  click to get access token
              </button>
          </p>*/}
            <p>
                <button onClick={()=>this.clickShowMe()}>
                    click to show me
                </button>
            </p>
            {this.state.userInfo!=null?(<div>
                mail:{this.state.userInfo.mail}
            </div>):null}

            <p>
                start:<input id={"c_start"} type="text" defaultValue={"2022-05-01T21:00:00"}  />
                end:<input id={"c_end"} type="text" defaultValue={"2022-05-30T00:00:00"}  />
                <button onClick={()=>this.clickGetCalander()}>
                    click to get calendar
                </button>
            </p>
            {this.state.calendarInfo!=null?(<div>
                {
                    this.state.calendarInfo.map((item,index)=>{
                        return (<p>start:{item.start.dateTime}-end:{item.end.dateTime}-type:{item.subject}</p>)
                    })
                }
            </div>):null}

            <p>
                start:<input id={"start"} type="text" defaultValue={"2022-05-05T00:00:00"}  />
                end:<input id={"end"} type="text" defaultValue={"2022-05-10T00:00:00"}  />
                <button onClick={()=>this.clickGetCalander2()}>
                    click to get calendar2
                </button>
            </p>
            {this.state.calendarInfo2!=null?(<div>
                {
                    this.state.calendarInfo2.value.map((item,index)=>{
                        let  days=0;
                        if(item.recurrence&&item.recurrence.pattern&&item.recurrence.pattern.daysOfWeek){
                            return  item.recurrence.pattern.daysOfWeek.map((dayOfWeek,index)=>{
                                let date = new Date(item.recurrence.range.startDate);
                                let addDays=0;
                                //
                                switch(dayOfWeek){
                                    case "monday":
                                        addDays=addDays+1;
                                        break;
                                    case "tuesday":
                                        addDays=addDays+2;
                                        break;
                                    case "wednesday":
                                        addDays=addDays+3;
                                        break;
                                    case "thursday":
                                        addDays=addDays+4;
                                        break;
                                    case "friday":
                                        addDays=addDays+5;
                                        break;
                                }

                                date.setDate(date.getDate()+addDays)
                                let startTimes = item.start.dateTime.split("T")[1];
                                let endTimes = item.end.dateTime.split("T")[1];
                                let startDate=date.getFullYear()+"-"+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1)+"-"+(date.getDate()<10?("0"+date.getDate()):date.getDate())+"T"+startTimes;
                                let endDate=date.getFullYear()+"-"+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1)+"-"+(date.getDate()<10?("0"+date.getDate()):date.getDate())+"T"+endTimes;
                                return (<p>start:{startDate}-end:{endDate}-type:{item.type}</p>)
                            }).reverse()


                            /* let daysOfWeek=item.recurrence.pattern.daysOfWeek;
                             for (let i = 0; i < daysOfWeek.length; i++) {
                                 let date = new Date(item.recurrence.range.startDate);
                                 let dayOfWeek=daysOfWeek[i];
                                 //["monday", "tuesday", "wednesday", "thursday", "friday"]
                                 switch(dayOfWeek){
                                     case "monday":
                                         date.setDate(date.getDate()+1)
                                         break;
                                     case "tuesday":
                                         date.setDate(date.getDate()+2)
                                         break;
                                     case "wednesday":
                                         date.setDate(date.getDate()+3)
                                         break;
                                     case "thursday":
                                         date.setDate(date.getDate()+4)
                                         break;
                                     case "friday":
                                         date.setDate(date.getDate()+5)
                                         break;
                                 }
                                 let startTimes = item.start.dateTime.split("T")[1];
                                 let endTimes = item.end.dateTime.split("T")[1];
                                 let startDate=date.getFullYear()+"-"+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1)+"-"+(date.getDate()<10?("0"+date.getDate()):date.getDate())+"T"+startTimes;
                                 let endDate=date.getFullYear()+"-"+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1)+"-"+(date.getDate()<10?("0"+date.getDate()):date.getDate())+"T"+endTimes;
                                 return (<p>start:{startDate}-end:{endDate}-type:{item.type}</p>)
                             }*/
                        }else{
                            return (<p>start:{item.start.dateTime}-end:{item.end.dateTime}-type:{item.type}</p>)
                        }

                    })
                }
            </div>):null}
        </div>);
    }
}

function mapStateToProps(state) {

    return state.weather;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OutlookCalendar);

/*
Display name
:
test app
Application (client) ID:b5b04774-9a9a-45dd-806c-dc8e86bfa007
Object ID:47d13930-4d97-4b09-ad80-f57575ed4c59
Directory (tenant) ID:d5189ead-72d5-4d23-b57e-1a91ca41a8f6
Supported account types:All Microsoft account users


https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/quickStartType//sourceType/Microsoft_AAD_IAM/appId/b5b04774-9a9a-45dd-806c-dc8e86bfa007/objectId/47d13930-4d97-4b09-ad80-f57575ed4c59/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true
DOCUMENTATION:https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/quickStartType//sourceType/Microsoft_AAD_IAM/appId/b5b04774-9a9a-45dd-806c-dc8e86bfa007/objectId/47d13930-4d97-4b09-ad80-f57575ed4c59/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true
*/