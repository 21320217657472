/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Container, Card, CardBody, Row, Col, Button, Table, Input, Label } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { UserContext } from '../../../constants/myContexts';
import WeatherMap from './WeatherMap';
import WeatherBarChart from './WeatherBarChart';
import WeatherLineChart from './WeatherLineChart';
import TrafficLightWidget from './TrafficLightWidget';
import BatteryWidget from './BatteryWidget';
import BatteryIcon from './BatteryIcon';
import StatusBoard from './StatusBoard';
import StatusTable from './StatusTable';
import { DeleteCircleIcon, BatteryFullIcon, BatteryEmptyIcon, Battery20Icon, Battery50Icon, Battery80Icon } from 'mdi-react';
// import BatteryFullIcon from 'mdi-react/BatteryFullIcon';
import './blinking.scss';
import { weatherSeverity, hex2bin, splitStatusValue, mapWeatherSeverity } from '../../../factories/utils';
import { reformWeatherAssetData } from '../../../factories/weather';
import SettingPage from '../../Setting/index';


class WeatherMainBoard extends Component {
    // static contextType = UserContext;

    constructor(props) {
        super(props);
        
        let index = localStorage.getItem('activeTab');
        this.state = {
            mapProps: {
                showingInfoWindow: false,
                activeMarker: {},
                selectedPlace: { attr: {} },
            },
            activeTab: index ? index : '1',
        };
    }

    componentDidMount() {
        // this.user = this.context;
        /* perform a side-effect at mount using the value of MyContext */
    }

    //--------------------------------------------------------------------
    // reenderLegend = () => {
    //     let legendList = [
    //         {color: 'gray', label: 'No Activity'},          
    //         {color: 'red', label: 'Lightning near (<5km)'},
    //         {color: 'orange', label: 'Lightning approaching (5km to 15km)'},
    //         {color: 'yellow', label: 'Lightning Detected (+15km)'},
    //         {color: 'green', label: 'All Clear'},
    //     ];

    //     return (
    //         <div>
    //             {legendList.map((item, index)=>(
    //                 <div>
    //                     <svg width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
    //                         <circle key={index} id={`legend_${index}`} fill={item.color} stroke='black' cx="6" cy="6" r="5.5"></circle>
    //                     </svg>
    //                     <span style={{fontSize:'11px'}}>{item.label}</span>
    //                 </div>
    //             ))}
    //         </div>
    //     )
    // }
    renderLegend = () => {
        let legendList = [
            { color: 'gray', label: 'No Activity' },
            { color: 'red', label: 'Lightning near (<5km)' },
            { color: 'orange', label: 'Lightning approaching (5km to 15km)' },
            { color: 'yellow', label: 'Lightning Detected (+15km)' },
            { color: 'green', label: 'All Clear' },
        ];

        return (
            <div style={{ textAlign: 'center', width: '100%', margin: '30px' }}>
                {/* <div style={{width: '60%',  margin: '0 auto', borderRadius: '25px'}}> */}
                <table style={{ width: '60%', margin: '0 auto', borderRadius: '20px' }}>
                    <tr>
                        {legendList.map((item, index) => (
                            <td style={{ textAlign: 'center', width: '50px' }}>
                                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <circle key={index} id={`legend_${index}`} fill={item.color} stroke='black' stroke-width='0' cx="12" cy="12" r="10"></circle>
                                </svg>
                                {/* <span style={{fontSize:'11px'}}>{item.label}</span> */}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        {legendList.map((item, index) => (
                            <td style={{ textAlign: 'center' }}>
                                {/* <svg width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                            <circle key={index} id={`legend_${index}`} fill={item.color} stroke='black' cx="6" cy="6" r="5.5"></circle>
                        </svg> */}
                                <span style={{ fontSize: '11px' }}>{item.label}</span>
                            </td>
                        ))}
                    </tr>
                </table>
                {/* </div> */}
            </div>
        )
    }
    //--------------------------------------------------------------------

    handleMapMarkerClick = (props, marker, e) => {
        //  
        this.setState(prevState => {
            return ({
                mapProps: {
                    showingInfoWindow: true,
                    // showingInfoWindow: !prevState.mapProps.showingInfoWindow,
                    activeMarker: marker,
                    selectedPlace: props,
                    lat: props.position.lat,
                    lng: props.position.lng,
                },
            });
        });
    }

    handleMapInfoWinShow = (props, marker, e) => {

        this.setState(prevState => {
            if ((props.position.lat != prevState.mapProps.lat || props.position.lng != prevState.mapProps.lng)) { //!prevState.mapProps.showingInfoWindow && 
                return ({
                    mapProps: {
                        showingInfoWindow: true,
                        // showingInfoWindow: !prevState.mapProps.showingInfoWindow,
                        activeMarker: marker,
                        selectedPlace: props,
                        lat: props.position.lat,
                        lng: props.position.lng,
                    },
                });
            }
            // else {
            //     return null;
            // }
        });
    }

    // handleMapInfoWinClick = (props) => {
    //     if(this.state.mapProps.showingInfoWindow){
    //         this.setState({
    //             showingInfoWindow: false,
    //             activeMarker: null
    //         });
    //     }
    // }

    handleMapInfoWinClose = (props) => {
        this.setState((prevState) => ({
            mapProps: {
                ...prevState.mapProps,
                showingInfoWindow: false,
                // activeMarker: null
            }
        }));
    }

    tabToggle = (tab) => {
        localStorage.setItem('activeTab', tab);
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                mapShow: (tab == '3')
            }, () => {
                if (this.state.activeTab == '3') {
                    this.onMapShow();
                }
            });
        }
    }

    onMapShow = () => { }

    render() {

        let processedMapdata = [];
        let statusData = [];
        let mapData = [];

        if (this.props.assetData) {
            this.props.assetData.forEach((asset, index) => {
                // let statusItem = {
                //     assetid: asset.assetid, 
                //     assetname: asset.assetname, 
                //     timestamp: asset.timestamp, 
                //     alarm: 0, 
                //     battery: null, 
                //     temperature: null
                // };
                // if (asset.attributes){
                //     statusItem = asset.attributes.reduce((accum, attribute)=>{
                //         if (attribute.type != 'meta') {
                //              
                //             switch(attribute.attributename){
                //                 case 'CurrentDigitalInputState':
                //                      
                //                     let slicedValue = splitStatusValue(attribute.value);
                //                     accum = {
                //                         ...accum,
                //                         // alarm: weatherSeverity(attribute.value),
                //                         // hexAlarm: hex2bin(attribute.value),
                //                         alarm: slicedValue.status,
                //                         hexAlarm: hex2bin(slicedValue.alarm),
                //                     };
                //                      
                //                     break;
                //                 case "BatteryVoltage":
                //                     accum = {
                //                         ...accum,
                //                         battery: attribute.value,
                //                     };
                //                     break;
                //                 case "InternalTemperature":
                //                     accum = {
                //                         ...accum,
                //                         temperature: attribute.value,
                //                     };
                //                     break;
                //                 default : 
                //                     accum = {
                //                         ...accum,
                //                         [attribute.attributename]: attribute.value,
                //                     };
                //             }
                //         }
                //         return accum
                //     }, statusItem);
                // }
                // statusData.push(statusItem);
                // ---------------------------------------------------
                let assetObj = {
                    ...asset,
                    color: 'gray',
                    alarm: '0',
                };

                if (asset.attributes) {
                    asset.attributes.forEach((attribute, index) => {
                        if ('CurrentDigitalInputState' === attribute.attributename) {
                            let slicedValue = splitStatusValue(attribute.value);
                            // switch(weatherSeverity(attribute.value)){
                            switch (mapWeatherSeverity(slicedValue.alarm)) {
                                case 1:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'yellow',
                                        // alarm: weatherSeverity(attribute.value),
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                                case 2:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'orange',
                                        // alarm:  weatherSeverity(attribute.value),
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                                case 3:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'red',
                                        // alarm:  weatherSeverity(attribute.value),
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                                case 4:
                                    assetObj = {
                                        ...asset,
                                        status: slicedValue.status,
                                        color: 'green',
                                        // alarm:  weatherSeverity(attribute.value), 
                                        alarm: mapWeatherSeverity(slicedValue.alarm),
                                    };
                                    break;
                            }
                        }
                    });
                }
                mapData.push(assetObj);
            });
        }

        // let statusData2 = reformWeatherAssetData(this.props.assetData);

        //  
        //  
        //  
        return (
            <Fragment >
                <Nav tabs>
                    <NavItem style={{cursor:'pointer'}}>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.tabToggle('1'); }}
                        >
                            Weather Monitoring
                        </NavLink>
                    </NavItem>
                    <NavItem style={{cursor:'pointer'}}>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.tabToggle('2'); }}
                        >
                            Wind Monitoring
                        </NavLink>
                    </NavItem>
                    <NavItem style={{cursor:'pointer'}}>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.tabToggle('3'); }}
                        >
                            Weather Map
                        </NavLink>
                    </NavItem>
                    <NavItem style={{cursor:'pointer'}}>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '4' })}
                            onClick={() => { this.tabToggle('4'); }}
                        >
                            Notification
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        {/* <Row style={{width: '100%'}}>
                    <Col md={12}> */}
                        <Card>
                            <StatusBoard
                                activeTab={this.state.activeTab}
                                toggle={this.props.toggle}
                                loading={this.props.assetDataLoading}
                                statusData={statusData}
                                height={'400px'}
                                isSmall={this.props.isSmall}
                                // alarmList = {this.props.alarmList}
                                alarmObj={this.props.alarmObj}
                                alarmSet={this.props.alarmSet}
                                statusDataProp={this.props.statusData.filter(status => status.grouping === "weather")}
                                alarmSetObj={this.props.alarmSetObj}
                            />
                        </Card>
                        {/* </Col>
                        </Row> */}
                    </TabPane>
                    <TabPane tabId="2">
                        {/* <Row style={{width: '100%'}}>
                    <Col md={12}> */}
                        <Card>
                            <StatusBoard
                                activeTab={this.state.activeTab}
                                toggle={this.props.toggle}
                                loading={this.props.assetDataLoading}
                                statusData={statusData}
                                height={'400px'}
                                isSmall={this.props.isSmall}
                                // alarmList = {this.props.alarmList}
                                alarmObj={this.props.alarmObj}
                                alarmSet={this.props.alarmSet}
                                statusDataProp={this.props.statusData.filter(status => status.grouping === "wind" || status.grouping === "weather")} // need to edit this information
                                alarmSetObj={this.props.alarmSetObj}
                            />
                        </Card>
                        {/* </Col>
                        </Row> */}
                    </TabPane>
                    <TabPane tabId="3">
                        {/* <Row style={{width: '100%'}}>
                        <Col md={12}> */}
                        <Card>
                            <WeatherMap
                                userObj={this.props.userObj}
                                onMarkerClick={this.handleMapMarkerClick}
                                onMarkerHover={this.handleMapInfoWinShow}
                                // onInfoWinClick={this.handleMapInfoWinClick}
                                handleMapInfoWinClose={this.handleMapInfoWinClose}
                                values={this.state.mapProps}
                                mapData={this.props.assetGroupData}
                                pMapData={processedMapdata}
                                loading={this.props.assetDataLoading}
                                assetData={mapData}
                                statusData={this.props.statusData}
                                alarmSetObj={this.props.alarmSetObj}
                                toggle={this.props.toggle}
                                // mapShow={this.state.mapShow}
                                key={this.state.mapShow}
                            />
                        </Card>
                        {/* </Col>
                        </Row> */}
                    </TabPane>
                    <TabPane tabId="4">
                        {/* <Row style={{width: '100%'}}>
                        <Col md={12}> */}
                        <Card>
                        <SettingPage 
                            appname={"weather"}
                            companycode={"METRO"}
                        />
                        </Card>
                        {/* </Col>
                        </Row> */}
                    </TabPane>
                </TabContent>
                {/* <Row style={{width: '100%', marginBottom: '5px'}}> */}
                {/* <Col md={6}>
                    <StatusTable
                        toggle={this.props.toggle}
                        tableData={this.props.assetExpendData}
                    />
                </Col> */}
                {/* <Col md={12}>
                    <StatusBoard
                        toggle={this.props.toggle}
                        loading = {this.props.assetDataLoading}
                        statusData = {statusData}
                        height = {'400px'}
                        isSmall = {this.props.isSmall}
                        // alarmList = {this.props.alarmList}
                        alarmObj = {this.props.alarmObj}
                        alarmSet = {this.props.alarmSet}
                    />
                </Col> */}
                {/* <Col md={5}>
                    <StatusTable
                        toggle={this.props.toggle}
                        tableData={this.props.assetExpendData}
                        filterAttr={'Alarm'}
                        maxHeight = {300}
                    />
                </Col> */}
                {/* <Col md={12} style={{display: 'flex', alignItems: 'center'}}>
                    {this.renderLegend()}
                </Col> */}
                {/* </Row> */}
                {/* <Row style={{width: '100%'}}>
                <Col md={12}>
                <Card style={{padding: '0', border: '1px solid black'}}> */}
                {/* <CardBody style={{padding:'10px'}}> */}
                {/* <WeatherMap 
                            userObj = {this.props.userObj} 
                            onMarkerClick={this.handleMapMarkerClick} 
                            // onInfoWinClick={this.handleMapInfoWinClick}
                            handleMapInfoWinClose={this.handleMapInfoWinClose}
                            values = {this.state.mapProps}
                            mapData = {this.props.assetGroupData}
                            pMapData = {processedMapdata}
                            loading = {this.props.assetDataLoading}
                            assetData = {mapData} 
                        /> */}
                {/* </CardBody> */}
                {/* </Card>
                </Col>
            </Row> */}



                {/* <Row style={{width: '100%'}}>
                <Col md={2}>
                  
                   <BatteryIcon level={0}/>
                </Col>
                <Col md={2}>
                    <BatteryIcon level={1}/>
                </Col>
                <Col md={2}>
                    <BatteryIcon level={2}/>
                </Col>
                <Col md={2}>
                    <BatteryIcon level={3}/>
                </Col>
                <Col md={2}>
                    <BatteryIcon level={4}/>
                </Col>
            </Row> */}
                {/* <Row style={{width: '50%'}}>
                <StatusBoard/>
            </Row> */}
            </Fragment>
        );
    }

}

export default WeatherMainBoard;