/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from './CompanyAddGeneralLedgerCodeReasonModal.module.css';
import { getCurrentUser } from '../../../factories/auth';

class CompanyAddGeneralLedgerCodeReasonModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showModal: false,
            generalLedgerCodeReasonItemSelected: null,
        }
        this.onInputChange = this.onInputChange.bind(this)
        this.onCodeSelect = this.onCodeSelect.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onClose = this.onClose.bind(this)
    }

    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            showModal: nextProps.showModal
        })
        if (nextProps.generalLedgerCodeReasonItemSelected != null) {
            this.setState({
                isEditMode: nextProps.isEditMode,
                generalLedgerCodeReasonItemSelected: nextProps.generalLedgerCodeReasonItemSelected,
                generalLedgerCodes: nextProps.generalLedgerCodes,
                codeId: nextProps.generalLedgerCodeReasonItemSelected.codeId,
                reasonCode: nextProps.generalLedgerCodeReasonItemSelected.reasonCode,
                reason: nextProps.generalLedgerCodeReasonItemSelected.reason
            })
        }
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onCodeSelect = (codeStr) => {
        this.setState({
            generalLedgerCode: codeStr
        });
    }

    validateForm = () => {

        const { codeId, reasonCode, reason } = this.state

        if (parseInt(codeId) > 0 && (reasonCode && reasonCode.trim() && reasonCode.length <= 50)
            && (reason && reason.trim() && reason.length <= 100)) {
            return true
        }

        return false
    }

    onSubmit = () => {
        const { onSave, onClose } = this.props
        let { generalLedgerCodeReasonItemSelected,
            codeId, reasonCode, reason } = this.state

        generalLedgerCodeReasonItemSelected.codeId = codeId
        generalLedgerCodeReasonItemSelected.reasonCode = reasonCode.trim()
        generalLedgerCodeReasonItemSelected.reason = reason.trim()

        onSave(generalLedgerCodeReasonItemSelected)
        onClose()
    }

    onClose = () => {
        this.props.onClose()
    }

    render() {

        let { showModal, isEditMode, generalLedgerCodes,
            codeId, reasonCode, reason } = this.state

        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={showModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader
                    toggle={this.onClose}
                >
                    {
                        isEditMode ? 'Edit an Adjustment Reason' : 'Add an Adjustment Reason'
                    }
                </ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>GL Code <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    type="select"
                                    name='codeId'
                                    id='codeId'
                                    placeholder='General Ledger Code'
                                    onChange={this.onInputChange}
                                    className={`form-control ${parseInt(codeId) < 0 ? styles['is-invalid'] : ''}`}
                                    value={codeId}
                                    invalid={parseInt(codeId) < 0}
                                >
                                    <option key={-1} value={-1}>Select a General Ledger Code</option>
                                    {
                                        generalLedgerCodes && generalLedgerCodes.length > 0 &&
                                        generalLedgerCodes.map((glCode, idx) =>
                                        (<option
                                            key={glCode.id}
                                            value={glCode.id}
                                            onClick={() => {
                                                this.onCodeSelect(glCode.generalLedgerCode)
                                            }}
                                        >
                                            {glCode.generalLedgerCode} - {glCode.description}
                                        </option>))
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2}>
                                <span>Reason Code <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='reasonCode'
                                    id='reasonCode'
                                    placeholder='Reason Code'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={reasonCode}
                                    invalid={!reasonCode || reasonCode.trim() === "" || reasonCode.length > 50}
                                />
                                <div class="invalid-feedback">
                                    Reason code can have a maximum of 50 characters
                                </div>
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2}>
                                <span>Reason <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='reason'
                                    id='reason'
                                    placeholder='Reasons'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={reason}
                                    invalid={!reason || reason.trim() === "" || reason.length > 100}
                                />
                                <div class="invalid-feedback">
                                    Reason can have a maximum of 100 characters
                                </div>
                            </Col>
                        </Row>
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>

                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}>
                            Submit
                        </Button>}
                    
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}>
                            Submit
                        </Button>}
                    
                    <Button outline color='secondary' type="reset" onClick={this.onClose} >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default CompanyAddGeneralLedgerCodeReasonModal