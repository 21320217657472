/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Card, CardBody, Col, Row, Button, Container, Input } from 'reactstrap';
import { bindActionCreators } from 'redux';


import { getFullInventoryList } from '../../../../../redux/actions/inventoryActions';

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { Label } from 'react-bootstrap';
import { CSVLink, CSVDownload } from "react-csv";
import ClientUsage from './ClientUsage';
import DashboardGraph from '../DashboardGraph';
import { getClientUsage, getConsumerPlanUsage } from '../../../../../redux/actions/userAction';
class DashBoardActiveClientTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            refreshTime: null,   //the time this component gets freshed.
            data: null,
            loadingMessage: "Loading Data..."
        }
        this.expandComponent = this.expandComponent.bind(this);
        this.handleExpand = this.handleExpand.bind(this);
        this.expandedRows = {};
        this.clickedRow = null;
    }

    static getDerivedStateFromProps(newProps, prevState) {
        if (newProps.refreshTime && newProps.refreshTime !== prevState.refreshTime) {
            return ({
                refreshTime: newProps.refreshTime,
                loading: true,
                data: null
            });
        }
        return null;
    }
    componentDidMount() {
        this.props.getConsumerPlanUsage({
            companyCode: this.props.currentUserObj.companyCode,
            resellerCode: this.props.currentUserObj.resellerCode,
            roles: this.props.currentUserObj.role
        }).then(() => {
            if (this.props.consumerPlanUsageList.length == 0) {
                this.setState({
                    loadingMessage: "No data found"
                })
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        //state change 
        if (prevState.refreshTime !== this.state.refreshTime) {
            //call api
        }
    }

    expandComponent = (row) => {
        //#2
        //alert("test "+JSON.stringify(row));
        if (this.expandedRows[row.customerId] != undefined && this.expandedRows[row.customerId] != null && this.clickedRow == row.customerId) {
            this.clickedRow = null;
            return (
                <DashboardGraph
                    currentUserObj={row}
                />);

        }
        else if (this.expandedRows[row.customerId] != undefined && this.expandedRows[row.customerId] != null) {
            return (
                <DashboardGraph
                    currentUserObj={row}
                />);
        }

    }


    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? "(-)" : "(+)");
        } else {
            content = ' ';
        }
        return (
            <div> {content} </div>
        );
    }

    isExpandableRow(row) {
        if (row.customerId != null) return true;
        else return false;
    }


    customerNameFormater = (cell) => {
        if (cell != null) {
            return (
                <span>{cell.firstName + " " + cell.lastName}</span>
            );
        }
    }

    handleExpand(rowKey, isExpand) {
        //#1
        //alert(rowKey+isExpand);
        //this.expandedRows[rowKey] = isExpand;
        if (isExpand == true) {
            //expanding
            this.expandedRows[rowKey] = {
                rowKey: rowKey,
                refreshTime: new Date()
            };
            this.clickedRow = rowKey;

        }
    }
    dataFormater = (cell, row) => {

        return `${(cell / 1024).toFixed(2)} MB`;


    }


    render() {

        const options = {
            onExpand: this.handleExpand,
            noDataText: this.state.loadingMessage
        };
        return (
            <Card>
                <div className='card-pginside'>
                    <BootstrapTable
                        data={this.props.consumerPlanUsageList}
                        options={options}
                        tableHeaderClass='header_white'
                         
                
                        containerStyle={{ fontSize: "1vw" }}
                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        pagination
                        expandComponent={this.expandComponent}
                        expandableRow={this.isExpandableRow}
                        expandColumnOptions={{
                            expandColumnVisible: true,
                            expandColumnComponent: this.expandColumnComponent,
                            columnWidth: 50
                        }}
                    >
                        <TableHeaderColumn ref='customerId' isKey={true} dataField='customerId' dataAlign='center' hidden={true} >Phone</TableHeaderColumn>
                        <TableHeaderColumn dataField='customerName' dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }}>Customer Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='resellerName' dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }}>Reseller Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='periodUsage' dataAlign='center' filter={{ type: 'NumberFilter', placeholder: ' ' }} dataFormat={this.dataFormater.bind(this)} >Period Data Usage</TableHeaderColumn>
                        <TableHeaderColumn dataField='activeLines' dataAlign='center' filter={{ type: 'NumberFilter', placeholder: ' ' }} >Active Lines</TableHeaderColumn>

                    </BootstrapTable>
                </div>
            </Card>
        )
    }


}
const mapStateToProps = (state) => {
    let {
        activeInventoryList
    } = state.inventory;
    let {
        consumerPlanUsageList
    } = state.user;
    let props = {
        activeInventoryList, consumerPlanUsageList
    }
    return props;
}

export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getClientUsage, getConsumerPlanUsage
    }, dispatch);

}
export default connect(mapStateToProps, mapDispatchToProps)(DashBoardActiveClientTable);
