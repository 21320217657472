/* eslint-disable */
import axios from 'axios';
import { toast } from "react-toastify";
import { 
    FENCE_LOAD_DEVICE_SUCCEED,
    FENCE_LOAD_DEVICE_FAILD,
    FENCE_LOAD_FENCE_SUCCEED,
    FENCE_LOAD_FENCE_FAILED,
    FENCE_UPDATE_FENCE_FAILED,
    FENCE_UPDATE_FENCE_SUCCEED,
    FENCE_DELETE_FENCE_SUCCEED,
    FENCE_DELETE_FENCE_FAILED,
    FENCE_TOGGLE_ERROR,
    FENCE_TOGGLE_SUCCESS,
    FENCE_UPDATE_FENCE_OBJ_SUCCEED,
    FENCE_UPDATE_FENCE_OBJ_FAILED,
    USER_GET_FAILED,
} from '../../constants/actionTypes';
import {
    WEB_SERVICE_ROOT,
    WEB_SERVICE_GEOFENCE,
    WEB_SERVICE_GEOFENCE_OBJ,
} from '../../constants/appConstants';
import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
});

function loadGeoFencesSucceed(response){
     
     
    const action = {
        type: FENCE_LOAD_FENCE_SUCCEED,
        payload: response.data
    };

    return action;
}

function loadGeoFencesFailed(error){
    const action = {
        type: FENCE_LOAD_FENCE_FAILED,
        error: error.message
    };

    return action;
}

export function loadAllGeoFences(criteria){

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    }
    // const urlStr = `http://192.168.2.103:8080/Geofence?${queryString}`;
    // const urlStr = `http://192.168.2.7:8080/sigfox/Geofence?${queryString}`;
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GEOFENCE}?${queryString}`;
     
     
     
    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });
    return (
        (dispatch)=>{
            request.then((response)=>{dispatch(loadGeoFencesSucceed(response))})
                    .catch((error)=>{dispatch(loadGeoFencesFailed(error))})
        }
    );
}

function updateGeoFencesSucceed(response){
    // alert(response.data);
    const action = {
        type: FENCE_UPDATE_FENCE_SUCCEED,
        payload: response.data
    };

    return action;
}

function updateGeoFencesFailed(error){
    const action = {
        type: FENCE_UPDATE_FENCE_FAILED,
        error: error.message
    };

    return action;
}

export function updateGeofence(geoObj){

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
        toast.error("Failed to get current user, Please re-login", {
            autoClose: false
          });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    //Getfence
    // const urlStr = `http://192.168.2.103:8080/Geofence`;
    // const urlStr = `http://192.168.2.91:8080/Range`;
    // const urlStr = 'http://192.168.161.9:8080/sigfox/Geofence';
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GEOFENCE}`;
    const request = axios({
        url: urlStr,
        method: 'post',
        // headers: {'Accept': 'application/json'},
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        data: geoObj,
    });
    return (
        (dispatch)=>{
            request.then((response)=>{
                        dispatch(updateGeoFencesSucceed(response));
                        dispatch(loadAllGeoFences());
                        toast.success('Successfully Updated GeoFence');
                    })
                    .catch((error)=>{
                        dispatch(updateGeoFencesFailed(error));
                        toast.error('Failed to Update GeoFence :' +  error.message, {
                            autoClose: false
                          });
                    })
        }
    );
}

// -------------------------------------------------------------------------------
export function upsertGeofence(geoObj){

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    if (!geoObj) {
        return ((dispatch)=>{
            dispatch({
                type: FENCE_UPDATE_FENCE_OBJ_FAILED,
                error: 'Input Geofence was null.',
            });
            toast.error('Input Geofence was null.');
        })
    }
    // const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GEOFENCE_OBJ}`;
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GEOFENCE}`;
    const request = axios({
        url: urlStr,
        method: 'post',
        // headers: {'Accept': 'application/json'},
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        data: geoObj,
    });
    return ((dispatch)=> {
        request.then((response)=>{
            if(response.status === 200){
                dispatch({
                    type: FENCE_UPDATE_FENCE_OBJ_SUCCEED, 
                    payload: response.data,
                });
                dispatch(loadAllGeoFences());
                toast.success('Updated GeoFence Successfully');
            }else{
                dispatch({
                    type: FENCE_UPDATE_FENCE_OBJ_FAILED,
                    error: 'Upsert Geofence was failed.',
                });
                toast.error('Updating Geofence failed.');
            }
        }).catch((error)=>{
            dispatch({
                type: FENCE_UPDATE_FENCE_OBJ_FAILED,
                error: error.message,
            });
            toast.error(error.message);
        })
    });
}
// -------------------------------------------------------------------------------

function deleteGeoFencesSucceed(response){
    const action = {
        type: FENCE_DELETE_FENCE_SUCCEED,
        payload: response.data
    };

    return action;
}

function deleteGeoFencesFailed(error){
    const action = {
        type: FENCE_DELETE_FENCE_FAILED,
        error: error.message
    };

    return action;
}

export function deleteGeofence(geoObj){
    // const urlStr = `http://192.168.2.91:8080/Geofence`;
    // const urlStr = `http://192.168.2.7:8080/sigfox/Geofence`;
    const queryStr = `?fenceid=${geoObj.fenceid}`;
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GEOFENCE}${queryStr}`;
    const request = axios.delete( 
        urlStr, 
        { 
            headers: {'Accept': 'application/json'},
            data: geoObj,
        }
    );
    return (
        (dispatch)=>{
            request.then((response)=>{
                        dispatch(deleteGeoFencesSucceed(response));
                        dispatch(loadAllGeoFences());
                        toast.success('Successfully Deleted GeoFence');
                    })
                    .catch((error)=>{
                        dispatch(deleteGeoFencesFailed(error));
                        toast.error('Failed to Delete GeoFence :' +  error.message, {
                                        autoClose: false,
                                    });
                    })
        }
    );
}

export function toggleSuccessAlert(){
    return((dispatch) => {dispatch(
        {
            type: FENCE_TOGGLE_SUCCESS, 
            data: false
        }
    )}
    );
}

export function toggleErrorAlert(){
    return((dispatch) => {dispatch(
        {
            type: FENCE_TOGGLE_ERROR, 
            data: false
        }
    )}
    );
}