/* eslint-disable */
import React, { Component } from 'react';
import { ChromePicker} from 'react-color';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CustomInput, FormGroup } from 'reactstrap';
// import { ENGINE_METHOD_DIGESTS } from 'constants';

class FileInputModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            btnDisable: true,
            name: ''
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     return({
    //         // currentDeviceTypeId : props.currentDeviceTypeId,
    //         // currentDeviceTypeName : props.currentDeviceTypeName,
    //         // currentDeviceId : props.currentDeviceId,
    //         // currentDeviceName : props.currentDeviceName,
    //     });
    // } 

    // handleNameOnchange =(e) => {
    //     // this.props.onNameChange(e);
    //     let btnDisable = (e.target.value && e.target.value.trim().length>0);
    //     this.setState({
    //         name: e.target.value,
    //         btnDisable: btnDisable
    //     });
    // }

    // handleDeviceTypeDropdownChange = (e) => {
    //     this.props.handleDeviceTypeDropdownChange(e);
    // }

    // handleDeviceDropdownChange = (e) => {
    //     this.props.handleDeviceDropdownChange(e);
    // }

    // handleDeviceTypeDropdownChange = (e) => {
    //     let selectedValue = e.target.value;
    //     let index = e.target.selectedIndex;
    //     let name = e.target.options[index].text;
    //     // alert(name);
    //     this.props.loadRuleDevice({devicetype: e.target.value});
    //     this.setState(
    //         {
    //             currentDeviceTypeId: selectedValue,
    //             currentDeviceTypeName: name                
    //         }
    //     )
    // }

    // handleDeviceDropdownChange = (e) => {
    //     alert('test');
    //     let selectedValue = e.target.value;
    //     let index = e.target.selectedIndex;
    //     let name = e.target.options[index].text;
    //     // alert(name);
    //     this.setState(
    //         {
    //             currentDeviceId: selectedValue,
    //             currentDeviceName: name                
    //         }
    //     )
    // }
    
    OnAddClick = (e) => {
        // this.props.onAddRule(this.state.name);
        // this.setState({
        //     name: '',
        //     btnDisable: false,
        // });
        this.props.toggle();
    }

    OnCancleClick = (e) =>{
        // this.setState({
        //     name: '',
        //     btnDisable: false,
        // });
        this.props.toggle();
    }

    render(){
        return(
            <Modal transparent="fasle" isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle}>
                <ModalHeader toggle={this.toggle}>Upload File</ModalHeader>
                <ModalBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            {/* <Col md={3} style={{textAlign: 'left' }}>
                                <h5><Label for="fileinput" >Choose File</Label></h5>
                            </Col> */}
                            
                            <Col md={10}>
                                {/* <Input type="file" id="fileinput" name="fileinput" width="90%"  >                                    
                                </Input> */}
                                <FormGroup>
                                    <CustomInput
                                    type="file"
                                    id="fileinput"
                                    name="fileinput"
                                    label={'Choose an Excel File'}
                                    
                                     />
                                </FormGroup>
                            </Col>
                    </Row>     
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="ruledivicetype" >Device Type</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="select" id="ruledivicetype" name="ruledivicetype" width="90%" value={this.props.currentDeviceTypeId} onChange={this.handleDeviceTypeDropdownChange}>        
                                    {this.props.currentDeviceTypeList && this.props.currentDeviceTypeList.map((type,i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))}                            
                                </Input>
                            </Col>
                    </Row>     */}
                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="devicename" >Device</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="select" id="devicename" name="devicename" width="90%" value={this.props.currentDeviceId} onChange={this.handleDeviceDropdownChange} >             
                                    <option value = {0}>All</option>                
                                        {this.props.currentDeviceList.map((item,i) => {
                                            return(
                                                <option key={i} value={item.deviceid}>{item.devicename}</option>
                                            )
                                        })}                                  
                                </Input>
                            </Col>
                    </Row>                    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button outline color="custom" onClick={this.OnAddClick} disabled={!this.state.btnDisable}>Add</Button>{' '}
                    <Button outline color="secondary" onClick={this.OnCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }
}

export default FileInputModal;

