/* eslint-disable */
import React from 'react'

import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

export default function AlertModal({ modalVisible, toggleOff, msg }) {
    return (
        <Modal isOpen={modalVisible}
            toggle={toggleOff}>
            <ModalBody>
                <br /><br />
                <h4 className='text-center'>
                    {msg}
                </h4>
                <br /><br />
                <Row md="12" className="justify-content-center">
                    <Col md="6">
                        <Button color="secondary" style={{ width: '100%', marginLeft: 10 }} onClick={toggleOff}>Ok</Button>
                    </Col>
                    {/* <Col className='text-right' md="6">
                        <Button color="secondary" style={{ width: '100%', marginRight: 10 }} onClick={onConfirm}>Confirm</Button>
                    </Col> */}
                </Row>
            </ModalBody>
        </Modal>
    )
}