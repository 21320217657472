/* eslint-disable */
import React from 'react'
import makeAnimated from 'react-select/animated';
import Select from 'react-select'
import { Input } from 'reactstrap'
import { Modal, Button } from "react-bootstrap";

// Multiselect Components
const animatedComponents = makeAnimated();

export default function MultiSelectionComponent({ err, styles, controlStyles, ...props }) {
  const customStyles = {
    control: (provided, state) => ({ // Mimic reactstrap input styles
      ...provided,
      borderColor: err ? 'red' : state.isFocused && !state.isDisabled ? '#80bdff' : 'lightgray',
     /* boxShadow: state.isFocused && !state.isDisabled ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
      '&:hover': { borderColor: state.isFocused && '#80bdff' },*/
      ...controlStyles,
    }),
    ...styles,
  }
  let isMulti = true;
  if (props.isMulti) {
    isMulti = props.isMulti;
  }
  return (
    <Select isMulti = {isMulti}
      styles={customStyles}
      {...props}
    />
  )
}

export function AssetMultiSelection(props) {
  return (
    <MultiSelectionComponent
      getOptionLabel={({ assetname }) => assetname}
      getOptionValue={({ assetid }) => assetid}
      {...props}
    />
  )
}

export function DeviceMultiSelection(props) {
  return (
    <MultiSelectionComponent
      // getOptionLabel={({ name }) => name}
      getOptionLabel={({ id, name, namealias }) => id + ' - '+ namealias}
      getOptionValue={({ id }) => id}
      {...props}
    />
  )
}

export function SubDeviceMultiSelection (props) {
  // console.log("===this", props);
  return (
    <MultiSelectionComponent
      // getOptionLabel={({ name }) => name}
      getOptionLabel={({ deviceid, name, devicenamealias }) => deviceid + ' - '+ devicenamealias}
      getOptionValue={({ deviceid }) => deviceid}
      {...props}
    />
  )
}


export function ClassMultiSelection(props) {
  return (
    <MultiSelectionComponent
      // getOptionLabel={({ name }) => name}
      getOptionLabel={({ name }) => name}
      getOptionValue={({ id }) => id}
      {...props}
    />
  )
}
export function SimMultiSelection(props) {
  return (
    <MultiSelectionComponent
      // getOptionLabel={({ name }) => name}
      components={animatedComponents}
      getOptionLabel={({ itemName, identifierValue }) => itemName + ' - '+ identifierValue}
      getOptionValue={({ identifierValue }) => identifierValue}
      {...props}
    />
  )
}

export function DeviceAttrGroupSelection(props) {
  return (
    <MultiSelectionComponent
      getOptionLabel={({name}) => name}
      getOptionValue={({id}) => id}
      {...props}
    />
  )
}

// export function DeleteConfirmation({ showModal, hideModal, confirmModal, id, type, message }) {
//   return (
//     <Modal show={showModal} onHide={hideModal}>
//     <Modal.Header closeButton>
//       <Modal.Title>Delete Confirmation</Modal.Title>
//     </Modal.Header>
//     <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
//     <Modal.Footer>
//       <Button variant="default" onClick={hideModal}>
//         Cancel
//       </Button>
//       <Button variant="danger" onClick={() => confirmModal(type, id) }>
//         Delete
//       </Button>
//     </Modal.Footer>
//   </Modal>
// )
// }
// Single Selection Components

export function SingleSelection({ forIdName, onChange, value, options, getOptionLabel, getOptionValue, err, ...props }) {
  if (!getOptionLabel) getOptionLabel = ({ label }) => label
  if (!getOptionValue) getOptionValue = ({ value }) => value
  return (
    <Input type="select" width="90%"
      style={{ borderColor: err ? 'red' : '' }}
      id={forIdName}
      name={forIdName}
      onChange={onChange}
      value={value}
      {...props}
    >
      {!value && <option hidden disabled value=''>Select An Option...</option>}
      {options && options.map(o => ({ ...o, label: getOptionLabel(o), value: getOptionValue(o) }))
        .map(({ value, label }) => {
          // console.log(value)
          if (value)
            return (<option key={value} value={value}>{label}</option>)
          return
        })}
    </Input>
  )
}