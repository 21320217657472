/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../../shared/components/Table/tableElements';
import { EditTextButton } from '../../../../shared/components/Button/IconButton';

import { Button } from 'reactstrap';

class NotificationEventList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    componentDidMount() {

    }

    clientFormatter = (cell, row) => {
        if (cell != null) {
            return cell.resellerName
        }
    }

    operationFormatter = (cell, row) => {
        return (
            <div>
                <EditTextButton label={''} onClick={(e) => { this.props.onOpenAction(cell) }} />
            </div>
        );
    }

    render() {

        const options = {
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        };

        return (
            <BootstrapTable
                data={this.props.actionList}
                tableHeaderClass='header_white'
                bordered={false}
                options={options}
                pagination={true}
                exportCSV
                csvFileName='Notification_List.csv'
            >
                <TableHeaderColumn row='0' rowSpan='1' dataField='actionid' dataSort isKey={true} hidden={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} ></TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='actionname' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Name'>Name</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='actiondescription' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Description'>Description</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='reseller' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} dataFormat={this.clientFormatter} csvFormat={this.clientFormatter} csvHeader='Client'>Client</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='createdby' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Created By'>Created By</TableHeaderColumn>
                <TableHeaderColumn
                    row='0'
                    rowSpan='1'
                    dataField='actionid'
                    dataAlign='center'
                    width="10%"
                    dataFormat={this.operationFormatter}
                    export={false}
                >
                    Manage
                </TableHeaderColumn>
            </BootstrapTable>
        )
    }
}
export default NotificationEventList;