/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
    Container,
    Collapse,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input, ModalFooter
} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import '../../../scss/component/react-bs-table.scss'

import PlusIcon from 'mdi-react/PlusIcon';
import {EditTextButton, DeleteTextButton, PlusCircleIconButton} from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import {
    getNotificationTemplate,
    upsertNotificationTemplate,
    deleteNotificationTemplate,
    getContact,
    upsertContact,
    deleteContact, getDashBoardSettingList,
} from '../../../redux/actions/jobAction';
import { toast } from "react-toastify";
import moment from 'moment';
import NotificationTemplateModal from "../../NotificationTemplate/components/TemplateModal";
import DashBoardSettingAssignModal from "./DashBoardSettingAssignModal";
import {getUsers} from "../../../redux/actions/userAction";
import {
    deleteDashBoardTemplateById,
    newDashboardTemplateByName,
    postAssignDashBoardTemplate
} from "../../../redux/actions/dashboardTemplateAction";
import DashboardCard from "./DashBoardEdit/DashboardCard";
import DashboardTemplateNew from "./DashboardTemplateNew";


class DashBoardSettingList extends Component {
    inVal;
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            templateObj: null,
            showEdit: false,
            templateId: null,
            templateName: null,
            templateIdDeleted: null,
            showTemplateNew:null

        };


    }

    componentDidMount(){

        this.props.getDashBoardSettingList();


    }


    handleInputOnChange=(val)=>{
        console.log('handle input on change is', val.target.value)
        this.inVal = val.target.value

    };


// -------------------------------------------------------------------------------------
    handleRowEditClick = (row) => {
        // this.props.editContact(row.id);
        console.log('eidt is touch and row is :',row)


        //
        // if (this.props.notificationTemplateList){
        //     let selectedTemplate = this.props.notificationTemplateList.find((item, index) => (item.id == row.id));
        //     //  
        //     if (selectedTemplate) {
        //         this.setState({templateObj: selectedTemplate}, () => {this.toggle();});
        //     }
        // }



        this.setState(prevState => ({
            showEdit: !prevState.showEdit,
            templateId: row.id,
            templateName: row.name
        }))


    }

    handleRowEditClickAssign = (row) => {
        console.log ('row is',row)
        // this.props.editContact(row.id);
        if (this.props.job.dashBoardSettingList){
            let selectedTemplate = this.props.job.dashBoardSettingList.find((item, index) => (item.id == row.id));
             
            if (selectedTemplate) {
                this.setState({templateObj: selectedTemplate}, () => {this.toggle();});
            }
        }
    }
    handleRowDeleteClick = (row) => {
        // this.props.deleteContact(row.id);
        // this.props.deleteNotificationTemplate(row.id);
        console.log('delete template is hit and row id ', row.id)

        this.setState(()=>({templateIdDeleted: row.id}),
            ()=>{
                console.log('this.props.deleteDashBoardTemplateById is called')
                this.props.deleteDashBoardTemplateById(row.id)}
            )
    }

    tableActionColFormater = (cell, row) => {
        return (
            <div>
                <Fragment>
                    <EditTextButton label={''} onClick={(e) => { this.handleRowEditClick(row); }}/>{' '}

                    <ConfirmDeleteDialog
                        label={""}
                        onConfirm={(e)=>{this.handleRowDeleteClick(row);}}
                        msg={`Do you want to delete the select Template ${row.name} ?`}
                    />
                </Fragment>


                <PlusCircleIconButton label={''} onClick={(e) => { this.handleRowEditClickAssign(row); }}/>{' '}


            </div>
        )
    }

    handleAddNewButtonClick = (onClick) => {
        console.log('handle add new button click and onclick is',onClick)
        // let templateObj = {
        //     "id": 0,
        //     "contactid": '',
        //     "destination": '',           //String
        //     "title": '',//String
        //     "content": '',//String
        //
        //     "weekday": '', //String not use
        //     "starttime": '00:00:00',//Time not use
        //     "endtime": '00:00:00',//Time not use
        //
        //     "owner": '',//not changeable
        //     "companycode": '',//not changeable
        //     "createdtime": '' //not changeable
        // }
        // this.setState({templateObj}, () => {this.toggle();})

        this.setState({showTemplateNew: !this.state.showTemplateNew})
    }

    addNewUserButton = (onClick) => {
       // const check = this.props.userObj && hasRole(this.props.userObj, ['ADMIN']);
        //const check = true;
        console.log('add new template is hit and on click is ', onClick)
        return (

            <InsertButton
                //   btnText='Add Company'
                btnContextual='btn-success'
                btnGlyphicon='glyphicon-edit'
                //   color='success'
                onClick={ () => this.handleAddNewButtonClick(onClick) }
                //   disabled = {!check}
            >
                {/* <PlusCircleOutlineIcon size={100}/> */}
                {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
                <PlusIcon/> Add New Template
            </InsertButton>
        );
    }

    renderDashBoardSettingList = () => {
        // const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        const options = {
            insertBtn: this.addNewUserButton,
        };


        return (


            <Card>
                {!this.state.showEdit && <CardBody>
                    {/* <BootstrapTable
                        data={ this.props.job.dashBoardSettingList }
                        tableHeaderClass = 'header_white'
                        // tableHeaderClass = 'react-bs-table'
                        // tableBodyClass ='header_white'
                        pagination = {false}
                        options={ options }
                         
                         
                        search
                        insertRow = {true}
                        bordered={ false }
                        maxHeight={500}
                    > */}
                        {/* <TableHeaderColumn dataField='id' isKey={ true } hidden >ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='name' dataSort>Template Name</TableHeaderColumn> */}
                        {/*<TableHeaderColumn dataField='companyname' dataSort >Network</TableHeaderColumn>*/}
                        {/* <TableHeaderColumn dataField='companycode' dataSort  hidden>Company Code</TableHeaderColumn>
                        <TableHeaderColumn dataField='username' dataSort>user name</TableHeaderColumn>
                        <TableHeaderColumn dataField='type' dataSort>type</TableHeaderColumn> */}
                        {/*<TableHeaderColumn dataField='owner' dataSort >Created By</TableHeaderColumn>*/}
                        {/* <TableHeaderColumn dataField='createdtime' dataSort  >Created Time</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={ this.tableActionColFormater } >Edit</TableHeaderColumn>
                    </BootstrapTable> */}
                </CardBody>}
            </Card>
        )
    }




    toggle = () => {
        this.setState(prevState => ({
            showModal: ! prevState.showModal,
        }))
    }


    onHandleNewTemplateSubmit=(val)=>{

        console.log(' onHandleNewTemplateSubmit and val is ',val)
        this.props.newDashboardTemplateByName(this.inVal);

        this.setState({
            showTemplateNew: !this.state.showTemplateNew

        })
    };


    onHandleNewTemplateClose=()=>{
        this.setState({showTemplateNew: !this.state.showTemplateNew})

    }


    // handleInputChange = (e) => {
    //     //let val = e.target.value;
    //
    //     console.log('handle input change and value is',e)
    //     // let key = e.target.name;
    //     // this.setState(prevState => {
    //     //     if (prevState.templateObj){
    //     //         prevState.templateObj[key] = val
    //     //         return ({
    //     //             templateObj: prevState.templateObj,
    //     //         });
    //     //     }
    //     //     else{
    //     //         return null;
    //     //     }
    //     // });
    // }


    onReturn=(val)=>{
        console.log('on return is hit')
        this.setState({showEdit: false},()=>{
             
            this.props.getDashBoardSettingList();
        })


}


    onSubmit = (obj) => {
        console.log('obj',obj)

        if (obj.length>0){
             


            let finalobj = obj.filter(item=>item.changeStatus == true).map(
                item=>({username:item.username, templateId:item.templateId})
            );

            console.log('finalobj',finalobj)
            this.props.postAssignDashBoardTemplate(finalobj)
        }
        this.setState(prevState => ({
            showModal: ! prevState.showModal,
        }))

    }



    renderModal(){
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.state.showTemplateNew}
                   toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>New Dashboard Template</ModalHeader>
                <ModalBody>
                    {/*<Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>*/}
                    {/*    <Col md={3} style={{textAlign: 'left' }}>*/}
                    {/*        <Label >Asset Class</Label>*/}
                    {/*    </Col>*/}

                    {/*    <Col md={8}>*/}
                    {/*        <Label >{this.state.currentAssetClassName}</Label>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                        <Col md={3} style={{textAlign: 'left' }}>
                            <Label for="deviceType">Template Name</Label>
                        </Col>

                        <Col md={8}>
                            <Input type="text" id="deviceType" name="deviceType" width="90%" onChange={this.handleInputOnChange} >
                            </Input>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={this.onHandleNewTemplateSubmit}>Submit</Button>{' '}
                    <Button color="custom" onClick={this.onHandleNewTemplateClose}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }




    render() {


        console.log('this.props.job.dashBoardSettingList is:')
        console.log(this.props.job.dashBoardSettingList)
        console.log('the show template new is',this.state.showTemplateNew)
        if(this.props.dashboardTemplate.dashboardTemplateDeleteResult === this.state.templateIdDeleted){

            console.log('getDashBoardSettingList will be called again')

            this.props.getDashBoardSettingList();
            this.setState({templateIdDeleted: null})

        }

        console.log('this.props.dashboardTemplate.dashboardTemplateNewResult is',this.props.dashboardTemplate.dashboardTemplateNewResult)
        if(this.props.dashboardTemplate.dashboardTemplateNewResult){

            console.log('template id is ',this.props.dashboardTemplate.dashboardTemplateNewResult.id)
            console.log('template name is ',this.props.dashboardTemplate.dashboardTemplateNewResult.name)
            this.setState({
                showEdit : !this.state.showEdit,
                templateId: this.props.dashboardTemplate.dashboardTemplateNewResult.id,
                templateName: this.props.dashboardTemplate.dashboardTemplateNewResult.name
            })

            this.props.dashboardTemplate.dashboardTemplateNewResult = null
            // this.props.getDashBoardSettingList();
            // this.props.dashboardTemplate.dashboardTemplateNewResult = null;
         }





        return (



            <Col md={12}>

                {this.renderModal()}

                <Collapse isOpen={!this.state.showModal && !this.state.showEdit && !this.state.showTemplateNew}>
                    {/* <Card>
                    <CardBody> */}
                    {this.renderDashBoardSettingList()}
                    {/* </CardBody>
                </Card> */}
                </Collapse>
                <Collapse isOpen={this.state.showModal}>
                {this.state.showModal &&
                <DashBoardSettingAssignModal
                    searchResult={this.props.job.dashBoardSettingList}
                    showModal = {this.state.showModal}
                    toggle = {this.toggle}
                    contactList = {this.props.contactList}
                    upsertContact={this.props.upsertContact}
                    deleteContact = {this.props.deleteContact}
                    templateObj = {this.state.templateObj}
                    // handleContactChange = {this.handleContactChange}
                    // handleTimeInputChange = {this.handleTimeInputChange}
                    //handlePeriodsSelect = {this.handlePeriodsSelect}
                    // handleInputChange = {this.handleInputChange}
                    onSubmit = {this.onSubmit}
                />
                }



                </Collapse>

                {/*<Collapse isOpen={this.state.showTemplateNew}>*/}
                {/*    {this.state.showTemplateNew &&*/}
                {/*    <DashboardTemplateNew*/}
                {/*     onHandleNewTemplateSubmit = {this.onHandleNewTemplateSubmit}*/}
                {/*     onHandleNewTemplateClose = {this.onHandleNewTemplateClose}*/}
                {/*    />*/}
                {/*    }*/}
                {/*</Collapse>*/}
                <Container className="dashboard">

                        {this.state.showEdit &&
                        <DashboardCard
                        templateId={this.state.templateId}
                        templateName={this.state.templateName}
                        onReturn = {this.onReturn}
                        />

                        }


                </Container>


    </Col>
        )
    }
}

const mapStateToProps = state => {
    //return state.job;
    const job = state.job;
    const dashboardTemplate = state.dashboardTemplate
    const props = {job,dashboardTemplate};


    return props;
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getDashBoardSettingList,
        // getNotificationTemplate,
         postAssignDashBoardTemplate,
        deleteDashBoardTemplateById,
        newDashboardTemplateByName

    }, dispatch);
};

// const enhance = compose(
//     connect(mapStateToProps, mapDispatchToProps),
//     withStyles(styles),
//     // withRouter
// );


export default  connect(mapStateToProps, mapDispatchToProps)(DashBoardSettingList);
