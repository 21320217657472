/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Bar} from 'react-chartjs-2';
import {loadDeviceInfo} from '../../../redux/actions/mapActions';
import {groupBy} from '../../../factories/utils';
import BasicDashboadEditIcon from './BasicDashboadEditIcon';

class DeviceCountChartWidget extends Component {
    constructor(props){
        super(props);
        this.state={
        }
    }

    componentDidMount(){
        this.props.loadDeviceInfo();
    }

    componentWillReceiveProps(nextProps){}
    static getDerivedStateFromProps(props, state) {

        // function groupBy(list, keyGetter) {
        //   const map = new Map();
        //   list.forEach((item) => {
        //        const key = keyGetter(item);
        //        const collection = map.get(key);
        //        if (!collection) {
        //            map.set(key, [item]);
        //        } else {
        //            collection.push(item);
        //        }
        //   });
        //   return map;
        // }
    
        if (props.currentDeviceList){
          const grouped = groupBy(props.currentDeviceList , device => device.devicetypename);
        //    
        //    
          const keys = Array.from(grouped.keys());
        //    
          let values = [];
          keys.forEach((key) =>{
            values.push(grouped.get(key).length);
        });
          return ({
            chartlabel : keys,
            chartdata : values
          });
        }else{
          return state;
        }
      }



    renderDeviceTypeChart = () => {
        let h = JSON.parse(this.props.layout.position).h;
        let height = h * 30 - 20;

        const data = {
        labels: this.state.chartlabel,
        datasets: [
            {
            label: 'Number of Devices',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 0.5,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: this.state.chartdata
            }
        ]
        };
        return (
        // <Card>
        // <CardBody>
            <div>
            {/* <h5 className="bold-text">Device Type Usage</h5> */}
            <Bar
            key={h}
            data={data}
            width={60}
            height={height.toString()} // height={450}
            options={{
                responsive: true,
                maintainAspectRatio: false }}
            />
            </div>
        // </CardBody>          
        // </Card>
        );
    }

    render() {
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                            {this.props.dashName} 
                            {this.props.layout && 0 == this.props.layout.locked &&
                            <BasicDashboadEditIcon 
                                size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                layout={this.props.layout}
                                onEditSubmit = {this.props.onEditSubmit}
                            />    
                            }
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                <CardBody style={{padding:15}}>                     
                    {this.renderDeviceTypeChart()}
                </CardBody>  
          </Card>
          </Fragment>
        );
    }

}

function mapStateToProps(state) {    
    const {currentDeviceList} = state.asset;
    const props = {currentDeviceList};
    return props;
};
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDeviceInfo}, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DeviceCountChartWidget);