/* eslint-disable */
import React, { Component, Fragment } from "react";
import {
  Container,
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Table,
  Input,
  Label,
} from "reactstrap";
// import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@material-ui/lab/Pagination";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TrafficLightWidget from "./TrafficLightWidget";
import DonutWidget from "./DonutWidget";
import BatteryWidget from "./BatteryWidget";
import BatteryIcon from "./BatteryIcon";
import {
  ATTRIBUTE_TYPE,
  ATTRIBUTE_NAME,
  TIME_ZONE,
  WHITE_COLOR,
  GREEN_COLOR,
  RED_COLOR,
  ORENGE_COLOR,
  YELLOW_COLOR,
  GREY_COLOR,
  LIGHT_GREY_COLOR,
  ATTRIBUTE_LABEL,
  ATTRIBUTE_ORDER,
  ATTRIBUTE_WIND_ORDER,
  ALARM_META_ATTRI_OBJ,
  smallTextStyle,
  normalTextStyle,
  labelTextStyle,
} from "../../../constants/dataConstants";
import { SizePerPageDropDown } from "react-bootstrap-table";
import {
  LightningBoltIcon,
  WeatherPouringIcon,
  ThermometerIcon,
  WaterPercentIcon,
  WeatherSunnyIcon,
  WeatherWindyIcon,
  Battery70Icon,
  MessageOutlineIcon,
  DirectionsForkIcon,
  GaugeLowIcon,
  LightningBoltOutlineIcon,
} from "mdi-react";
import "./blinking.scss";
import "../../../scss/component/panel.scss"
import AttributeListWidget from "./AttributeListWidget";
import { attempt } from "lodash";
import { unique } from "jquery";

const styles = (theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
});

const PAGE_SIZE = 4;
const ICON_SIZE = 20;

const titleRowStyle = {
  margin: "5px 10px",
  padding: "5px",
  // justifyContent: 'flex-end',
  justifyContent: "space-between",
  lineHeight: "30px",
  height: "30px",
};

const valueRowStyle = {
  margin: "5px 10px",
  padding: "5px",
  justifyContent: "center",
  lineHeight: "30px",
  height: "30px",
};

class StatusBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // data: [
      //     {deviceId:'000001', devicename: 'sensor 1', timestamp: '2020-02-19', alarm: '1', battery: '4', temperature: '27'},
      //     {deviceId:'000002', devicename: 'sensor 2', timestamp: '2020-02-19', alarm: '2', battery: '1', temperature: '27'},
      //     {deviceId:'000003', devicename: 'sensor 3', timestamp: '2020-02-19', alarm: '3', battery: '3', temperature: '27'},
      //     {deviceId:'000004', devicename: 'sensor 4', timestamp: '2020-02-19', alarm: '1', battery: '2', temperature: '27'},
      // ],
      data: [],
      currentPage: 1,
      totalPages: 1,
      pageRange: 0,
      ifDisplay: false,
    };
    // this.resize = this.resize.bind(this);
  }

  //-----------------------------------------------------------------------------
  componentDidMount() {
    // window.addEventListener("resize", this.resize);
    // this.resize();
  }
  componentWillUnmount() {
    // window.removeEventListener("resize", this.resize);
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.statusData &&
      nextProps.statusData !== this.props.statusData
    ) {
      let totalPages = Math.ceil(nextProps.statusData.length / PAGE_SIZE);
      this.setPageData(nextProps.statusData, this.state.currentPage);
      this.setState({
        // data: nextProps.statusData,
        totalPages: totalPages,
      });
    }
  }
  //-----------------------------------------------------------------------------
  // resize = () => {
  //     //
  //     // this.setState({isSmall: window.innerWidth <= 760});
  //     let currentIsSmall = (window.innerWidth <= 760);
  //     // if (currentIsSmall !== this.state.isSmall) {
  //     //     this.setState({isSmall: currentIsSmall});
  //     // }
  //     this.setState(prev=>{
  //         if (currentIsSmall !== prev.isSmall){
  //             return({
  //                 isSmall: currentIsSmall
  //             });
  //         }
  //     });
  // }
  //-----------------------------------------------------------------------------
  handleClick(e, index) {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  }
  // renderPagin = () => {
  //     let pages = [1,2,3,4,5,6,7,8,9,10];
  //     let {currentPage} = this.state;
  //     return(
  //         <Pagination
  //             // size={'5'}
  //         >
  //             <PaginationItem disabled={currentPage <= 0}>
  //                 <PaginationLink
  //                     onClick={e => this.handleClick(e, currentPage - 1)}
  //                     previous
  //                     // href="#"
  //                 />
  //             </PaginationItem>
  //             {/* <Pagination.Ellipsis /> */}
  //             {pages && pages.map((item, index)=>(
  //                 <PaginationItem active={index === currentPage} key={index}>
  //                     <PaginationLink
  //                         onClick={e => this.handleClick(e, index)}
  //                         // href="#"
  //                     >
  //                         {index+1}
  //                     </PaginationLink>
  //                 </PaginationItem>
  //             ))}
  //             <PaginationItem disabled={currentPage >= this.pagesCount - 1}>
  //                 <PaginationLink
  //                     onClick={e => this.handleClick(e, currentPage + 1)}
  //                     next
  //                     // href="#"
  //                 />
  //             </PaginationItem>
  //         </Pagination>
  //     );
  // }

  handlePageChange = (event, value) => {
    event.preventDefault();

    this.setState({
      currentPage: value,
    });
    this.setPageData(this.props.statusData, value);
  };
  setPageData = (statusData, currentPage) => {
    let reData = [];
    if (statusData) {
      let startIndex = (currentPage - 1) * PAGE_SIZE;
      let endIndex = startIndex + PAGE_SIZE;
      let i = startIndex;
      while (i < endIndex && i < statusData.length) {
        reData.push(statusData[i]);
        i++;
      }
    }

    this.setState({
      data: reData,
    });
  };
  renderMUPagin = (pages) => {
    const { classes } = this.props;
    return (
      <div
        className={classes.root}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Pagination
          count={pages}
          shape="rounded"
          page={this.state.currentPage}
          onChange={this.handlePageChange}
        />
        {/* <Pagination count={10} variant="outlined" shape="rounded" />  */}
      </div>
    );
  };

  assetnameFormat = (cell) => {
    const handleDeviceOnClick = (e, assetname) => {
      e.preventDefault();

      // let deviceData = chooseDevice(this.props.assetData, deviceId);

      // let deviceData = cell.find((item, index) => (item.deviceid === deviceId));

      // this.setState({
      //     showDetails: true,
      //     curretnDeviceId: deviceId,
      //     currentDeviceData: deviceData,
      // });
      this.props.toggle(true, cell);
    };
    return (
      <a
        href={"#"}
        onClick={(e) => {
          handleDeviceOnClick(e, cell.assetname);
        }}
      >
        {cell.assetname}
      </a>
    );
  };

  timestampFormat = (cell) => {
    if (cell) {
      if (cell.charAt(0) == "+") {
        cell = new Date().getFullYear() + cell.substr(6);
      }
      let displayTime = new Date(cell).toLocaleString("en-US", {
        timeZone: TIME_ZONE,
      });
      return <span>{displayTime}</span>;
    } else {
      return null;
    }
  };

  renderAttributeIcons = (attributename) => {
    switch (attributename) {
      case "Temperature":
        return <ThermometerIcon size={ICON_SIZE} />;
      case "Temperature_max":
        return <ThermometerIcon size={ICON_SIZE} />;
      case "Temperature_min":
        return <ThermometerIcon size={ICON_SIZE} />;
      case "Pressure":
        return <GaugeLowIcon size={ICON_SIZE} />;
      case "Humidity":
        return <WaterPercentIcon size={ICON_SIZE} />;
      case "Irradiation":
        return <WeatherSunnyIcon size={ICON_SIZE} />;
      case "Battery":
        return <Battery70Icon size={ICON_SIZE} />;
      case "Rain":
        return <WeatherPouringIcon size={ICON_SIZE} />;
      case "Wind_ave10":
      case "Wind_max10":
        return <WeatherWindyIcon size={ICON_SIZE} />;
      case "Dir_ave10":
      case "Dir_max10":
        return <DirectionsForkIcon size={ICON_SIZE} />;
      case "Dir_ave10_txt":
      case "Dir_max10_txt":
        return <DirectionsForkIcon size={ICON_SIZE} />;
      case "hexAlarm":
        return <LightningBoltOutlineIcon size={ICON_SIZE} />;
      //case 'CO2':
      //  return <GaugeLowIcon size={ICON_SIZE} />;
      default:
        return <span></span>;
    }
  };

  renderStatus = () => {
    // const titleRowStyle = {
    //     margin: '5px 10px',
    //     padding:'5px',
    //     // justifyContent: 'flex-end',
    //     justifyContent: 'space-between',
    //     lineHeight: '30px',
    //     height: '30px'
    // }

    // const valueRowStyle = {
    //     margin: '5px 10px',
    //     padding:'5px',
    //     justifyContent: 'center',
    //     lineHeight: '30px',
    //     height: '30px'
    // }

    const { alarmSet } = this.props;
    //
    return (
      <Row style={{ padding: "5px" }}>
        {!this.props.isSmall && (
          <Fragment>
            <Col md="1" sm="0" xs="0" style={{ textAlign: "right" }}>
              {/* <Row style={{padding:'5px'}}>
                        <DonutWidget isHiden={true}/>
                    </Row>
                    <Row style={titleRowStyle}>
                    </Row>
                    <Row style={titleRowStyle}>
                        <MessageOutlineIcon size={ICON_SIZE}/>
                    </Row>   
                    <Row style={titleRowStyle}>
                        <ThermometerIcon size={ICON_SIZE}/>
                    </Row>    
                    <Row style={titleRowStyle}>
                        <GaugeLowIcon size={ICON_SIZE}/>
                    </Row>    
                    <Row style={titleRowStyle}>
                        <WaterPercentIcon size={ICON_SIZE}/>
                    </Row>     */}
              {/* <Row style={titleRowStyle}>
                        <span><h5>Dew Point:</h5></span>
                    </Row>    */}
              {/* <Row style={titleRowStyle}>
                        <WeatherSunnyIcon size={ICON_SIZE}/>
                    </Row>       
                    <Row style={titleRowStyle}>
                        <Battery70Icon size={ICON_SIZE}/>
                    </Row>  
                    <Row style={titleRowStyle}>
                        <WeatherPouringIcon size={ICON_SIZE}/>
                    </Row>    
                    <Row style={titleRowStyle}>
                        <WeatherWindyIcon size={ICON_SIZE}/>
                    </Row>         
                    <Row style={titleRowStyle}>
                        <DirectionsForkIcon size={ICON_SIZE}/>
                    </Row>      */}
            </Col>
            <Col md="2" sm="0" xs="0" style={{ textAlign: "right" }}>
              <Row style={{ padding: "5px" }}>
                <DonutWidget isHiden={true} />
              </Row>
              <Row style={titleRowStyle}>
                <span style={normalTextStyle}>
                  <h5>Station</h5>
                </span>
              </Row>
              <Row style={titleRowStyle}>
                <MessageOutlineIcon />
                <span style={normalTextStyle}>
                  <h5>Last Message</h5>
                </span>
              </Row>
              {/* <Row style={titleRowStyle}>
                        <ThermometerIcon/>
                        <span><h5>Temperature:</h5></span>
                    </Row>    
                    <Row style={titleRowStyle}>
                    <span></span>
                        <span><h5>Pressure:</h5></span>
                    </Row>    
                    <Row style={titleRowStyle}>
                        <span><h5>Humidity:</h5></span>
                    </Row>     */}
              {/* <Row style={titleRowStyle}>
                        <span><h5>Dew Point:</h5></span>
                    </Row>    */}
              {/* <Row style={titleRowStyle}>
                        <span><h5>Irradiation:</h5></span>
                    </Row>       
                    <Row style={titleRowStyle}>
                        <span><h5>Battery:</h5></span>
                    </Row>  
                    <Row style={titleRowStyle}>
                        <span><h5>Rain:</h5></span>
                    </Row>    
                    <Row style={titleRowStyle}>
                        <span><h5>Wind Avg:</h5></span>
                    </Row>         
                    <Row style={titleRowStyle}>
                        <span><h5>Wind Direction Avg:</h5></span>
                    </Row>      */}
              {ATTRIBUTE_ORDER &&
                ATTRIBUTE_ORDER.map((attr) => (
                  <Row style={titleRowStyle}>
                    {this.renderAttributeIcons(attr.attributename)}
                    <span style={normalTextStyle}>
                      <h5>{attr.displayname}</h5>
                    </span>
                  </Row>
                ))}
            </Col>
          </Fragment>
        )}
        {this.state.data &&
          this.state.data.map((item, index) => {
            // const classGnerator = (item, attributeName) => {
            //     let reClass = {color: 'black', fontWeight: 'normal'};
            //     if (this.props.alarmObj && this.props.alarmObj.msgList){
            //
            //         for (let alarm of this.props.alarmObj.msgList) {
            //             if (item.assetname == alarm.assetname && alarm.attributeList && alarm.attributeList.includes(attributeName) && this.props.alarmObj.status == true) {
            //
            //                 return ({
            //                     animation: 'fontBlink 1s infinite',
            //                 });
            //             }
            //         }
            //     }
            //     return reClass;
            // };
            // const rowClassGnerator = (item, attributeName, hasrule = false) => {
            //     let reClass = {
            //         // color: 'black',
            //         // fontWeight: 'normal',
            //         margin: '5px 10px',
            //         padding:'5px',
            //         justifyContent: 'center',
            //         alignContent: 'center',
            //         lineHeight: '30px',
            //         height: '30px',
            //         backgroundColor: hasrule ? GREEN_COLOR : GREY_COLOR,
            //         color: 'white'
            //     };

            //     // if (this.props.alarmObj && this.props.alarmObj.msgList){
            //     //     for (let alarm of this.props.alarmObj.msgList) {
            //     //         if (item.assetname == alarm.assetname && alarm.attributeList && alarm.attributeList.includes(attributeName) && this.props.alarmObj.status == true) {
            //     //             return ({
            //     //                 padding:'5px',
            //     //                 justifyContent: 'center',
            //     //                 alignContent: 'center',
            //     //                 lineHeight: '30px',
            //     //                 height: '30px',
            //     //                 animation: 'fontBlink 1s infinite',
            //     //             });
            //     //         }
            //     //     }
            //     // }
            //
            //     if (this.props.alarmSet && this.props.alarmSet[item.assetid] && this.props.alarmSet[item.assetid][attributeName] && this.props.alarmSet[item.assetid][attributeName].status == true){

            //         return ({
            //             padding:'5px',
            //             justifyContent: 'center',
            //             alignContent: 'center',
            //             lineHeight: '30px',
            //             height: '30px',
            //             animation: 'fontBlink 1s infinite',
            //         });
            //     }
            //     return reClass;
            // };
            // const updatedValue = (item, attributeName) => {
            //     let reValue = item[attributeName] ? item[attributeName] : '';
            //     // try{
            //     //     if (this.props.alarmSet && this.props.alarmSet[item.assetid] && this.props.alarmSet[item.assetid][attributeName] && this.props.alarmSet[item.assetid][attributeName].status == true){
            //     //         let itemTime = new Date(item.timestamp);
            //     //         let alarmTime = new Date(this.props.alarmSet[item.assetid][attributeName].timestamp);
            //     //
            //     //
            //     //         if (alarmTime > itemTime){
            //     //             reValue = this.props.alarmSet[item.assetid][attributeName].value;
            //     //
            //     //         }else{
            //     //             // reValue = item[attributeName] ? item[attributeName] : '';
            //     //         }

            //     //     }
            //     // }
            //     // catch(e){

            //     // }

            //     return reValue;
            // }
            return (
              <Col md={2} style={{ textAlign: "center" }}>
                <Row style={{ padding: "5px", justifyContent: "center" }}>
                  {/* <TrafficLightWidget status={Number(item.alarm)} statusHex={item.hexAlarm}/> */}
                  <DonutWidget
                    status={Number(item.alarm)}
                    statusHex={item.hexAlarm}
                  />
                </Row>
                <Row style={valueRowStyle}>
                  <span style={normalTextStyle}>
                    {this.assetnameFormat(item)}
                  </span>
                </Row>
                <Row style={valueRowStyle}>
                  <span style={normalTextStyle}>
                    {this.timestampFormat(item.timestamp)}
                  </span>
                </Row>
                {/* <Row style={rowClassGnerator(item, 'Temperature')}>
                                
                                <span >{updatedValue(item, 'Temperature')}</span>
                            </Row>
                            <Row style={rowClassGnerator(item, 'Pressure')}>
                                
                                <span >{updatedValue(item, 'Pressure')}</span>
                            </Row>
                            <Row style={rowClassGnerator(item, 'Humidity')}>
                                
                                <span >{updatedValue(item, 'Humidity')}</span>
                            </Row> */}
                {/* <Row style={valueRowStyle}>
                                <span >{''}</span>
                            </Row> */}
                {/* <Row style={rowClassGnerator(item, 'Irradiation')}>
                                
                                <span >{updatedValue(item, 'Irradiation')}</span>
                            </Row>
                            <Row style={rowClassGnerator(item, 'Battery')}>
                                
                                <span >{updatedValue(item, 'Battery')}</span>
                            </Row>
                            <Row style={rowClassGnerator(item, 'Rain')}>
                                
                                <span >{updatedValue(item, 'Rain')}</span>
                            </Row>
                            <Row style={rowClassGnerator(item, 'Wind_ave10')}>
                                
                                <span >{updatedValue(item, 'Wind_ave10')}</span>
                            </Row>
                            <Row style={rowClassGnerator(item, 'Dir_ave10')}>
                                
                                <span >{updatedValue(item, 'Dir_ave10')}</span>
                            </Row> */}

                {ATTRIBUTE_ORDER &&
                  ATTRIBUTE_ORDER.map((attr) => (
                    <Row
                      style={rowClassGnerator(
                        alarmSet,
                        item,
                        attr.attributename,
                        attr.hasrule,
                        this.props.isSmall
                      )}
                    >
                      {/* <span >{updatedValue(item, attr.attributename)}</span> */}
                      {/* {this.props.isSmall && this.renderAttributeIcons(attr.attributename)} */}
                      {attr.attributename === "Battery" ? (
                        // <BatteryIcon level={4}/>
                        <BatteryWidget
                          level={4}
                          style={{ width: "23px", height: "45px" }}
                        />
                      ) : (
                        <Fragment>
                          {this.props.isSmall && (
                            <span style={{ alignSelf: "flex-start" }}>
                              {this.renderAttributeIcons(attr.attributename)}
                            </span>
                          )}
                          <span style={normalTextStyle}>
                            {updatedValue(item, attr.attributename)}{" "}
                            {attr.units}
                          </span>
                        </Fragment>
                      )}
                    </Row>
                  ))}
              </Col>
            );
          })}

        {/* {this.props.statusDataProp && this.props.statusDataProp.map((item, index) => (
                    <Col md={2} style={{textAlign: 'center'}}>
                    <AttributeListWidget 
                    alarmSet={this.props.alarmSetObj} 
                    asset={item} 
                    isSmall={this.props.isSmall} 
                    toggle = {this.props.toggle}
                    />
                    </Col>
                ))} */}
      </Row>
    );
  };
  // --------------------------------------------------------------------------

  displayMore = (e) => {
    e.preventDefault();
    let attr = ATTRIBUTE_ORDER;
    let pressure;
    let humidity;
    let irradiation;
    if(attr){
      pressure = attr.findIndex(x => x.attributename === "Pressure");
      humidity = attr.findIndex(x => x.attributename === "Humidity");
      irradiation = attr.findIndex(x => x.attributename === "Irradiation");
    }


    if (this.state.ifDisplay == false) {
      attr[pressure].display = true;
      attr[humidity].display = true;
      attr[irradiation].display = true;
      this.setState({ ifDisplay: true });
    } else {
      attr[pressure].display = false;
      attr[humidity].display = false;
      attr[irradiation].display = false;
      this.setState({ ifDisplay: false });
    }
  };

  renderStatusWidget = () => {
    let statusData = [];
    let filteredStatusData = [];
    let subarray = [];
    let currentPage = 0;
    let pages = Math.ceil(this.props.statusDataProp.length / 8);
    let statusDataProp = this.props.statusDataProp;
    const removeDuplicates = (arr) => {
      arr.forEach((element) => {
        if (!filteredStatusData.includes(element)) {
          filteredStatusData.push(element);
        }
      });
      return filteredStatusData;
    };
    if (statusDataProp) {
      statusDataProp.sort(function(a, b) {
        const nameA = a.assetname.toUpperCase();
        const nameB = b.assetname.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 1;
      });
    }
    const handleChange = (event, value) => {
      this.setState({ pageRange: value - 1 });
    };
    return (
      <div style={{ alignItems: "self-end" }}>
        {this.state.ifDisplay == false && this.props.activeTab !== "2" ? (
          <div
            class="row"
            style={{
              margin: "5px 10px",
              padding: "5px",
              justifyContent: "center",
            }}
          >
            <Button
              // size="sm"
              variant="primary"
              color="custom"
              onClick={this.displayMore}
            >
              View More
            </Button>
          </div>
        ) : null}

        {this.state.ifDisplay == true && this.props.activeTab !== "2" ? (
          <div
            class="row"
            style={{
              margin: "5px 10px",
              padding: "5px",
              justifyContent: "center",
            }}
          >
            <Button
              // size="sm"
              variant="primary"
              color="custom"
              onClick={this.displayMore}
            >
              View Less
            </Button>
          </div>
        ) : null}
        <Col  style={{ padding: "5px" }} /* class="mr-auto p-2" */>
          {/* {!this.props.isSmall &&
                        <Fragment> */}
          {/* <Col md="1" sm="0" xs="0" style={{textAlign: 'right',}}>                  
                            </Col> */}

          {/* <Col sm="0" xs="0" style={{ textAlign: 'right', }}>
                                <div style={{ minWidth: '200px', width: '175px', maxWidth: '250px' }}> */}
          {/* <Row style={{ padding: '5px' }}>
                                        <DonutWidget isHiden={true} />
                                    </Row> */}
          {/* <br/>
                                    <Row style={titleRowStyle}>
                                        <span></span>
                                        <span style={normalTextStyle}><h4>Station</h4></span>
                                    </Row>
                                    <br/>
                                    <Row style={titleRowStyle}>
                                        <MessageOutlineIcon />
                                        <span style={normalTextStyle}><h5>Last Message</h5></span>
                                    </Row>
                                    {
                                        (this.props.activeTab == '1') ?
                                        
                                        
                                            ATTRIBUTE_ORDER && ATTRIBUTE_ORDER.filter(item => item.display).map(attr => (
                                                <Row style={titleRowStyle}>
                                                    {this.renderAttributeIcons(attr.attributename)}
                                                    {
                                                        attr.attributename === 'Temperature' ?
                                                            <span className="subsup" style={labelTextStyle}>
                                                                {attr.displayname}
                                                                <sup>{'Max'}</sup>
                                                                <sub className='shift'>{'Min'}</sub>
                                                            </span>
                                                            :
                                                            <span style={normalTextStyle}><h5>{attr.displayname}</h5></span>
                                                    }
                                                </Row>
                                            ))
                                            :
                                            ATTRIBUTE_WIND_ORDER && ATTRIBUTE_WIND_ORDER.filter(item => item.display).map(attr => (
                                                <Row style={titleRowStyle}>
                                                    {this.renderAttributeIcons(attr.attributename)}
                                                    {
                                                        attr.attributename === 'Temperature' ?
                                                            <span className="subsup" style={labelTextStyle}>
                                                                {attr.displayname}
                                                                <sup>{'Max'}</sup>
                                                                <sub className='shift'>{'Min'}</sub>
                                                            </span>
                                                            :
                                                            <span style={normalTextStyle}><h5>{attr.displayname}</h5></span>
                                                    }
                                                </Row>
                                            ))
                                    }
                                </div>

                                <br/>                                
                                <br/> */}

          {/* { <div style={{ minWidth: '200px', width: '175px', maxWidth: '250px' }}>
                                    
                                    {
                                        (this.props.activeTab == '1' && this.state.pageRange == 0) ?
                                                    
                                            ATTRIBUTE_LABEL && ATTRIBUTE_LABEL.filter(item => item.displayname == "Station").map(attr => (
                                                
                                                <Row style={titleRowStyle}>
                                                    <span></span>
                                                    <span style={normalTextStyle}><h4>Station</h4></span>
                                                </Row>
                                            ))
                                            :
                                                null
                                            } */}
          {/* <br/>
                                     {
                                        (this.props.activeTab == '1' && this.state.pageRange == 0) ?
                                                    
                                            ATTRIBUTE_LABEL && ATTRIBUTE_LABEL.filter(item => item.displayname == "Last Message" ).map(attr => (
                                                
                                                <Row style={titleRowStyle}>
                                                   <MessageOutlineIcon />                                                   
                                                   <span style={normalTextStyle}><h5>{attr.displayname}</h5></span>
                                                </Row>
                                            ))
                                            :
                                                null
                                            } */}

          {/* {
                                        (this.props.activeTab == '1' && this.state.pageRange == 0) ?
                                                    
                                            ATTRIBUTE_ORDER && ATTRIBUTE_ORDER.filter(item => item.display).map(attr => (
                                                  
                                                <Row style={titleRowStyle}>
                                                    {this.renderAttributeIcons(attr.attributename)}
                                                    {
                                                        attr.attributename === 'Temperature' ?
                                                            <span className="subsup" style={labelTextStyle}>
                                                                {attr.displayname}
                                                                <sup>{'Max'}</sup>
                                                                <sub className='shift'>{'Min'}</sub>
                                                            </span>
                                                            :
                                                            <span style={normalTextStyle}><h5>{attr.displayname}</h5></span>
                                                    }
                                                </Row>
                                            ))
                                            :
                                            
                                                null
                                            
                                            } }
                                </div> */}
          {/* </Col>
                        </Fragment>
                    } */}

          {/* {this.props.statusDataProp && this.props.statusDataProp.map((item, index) => (
                    <Col style={{textAlign: 'center'}}>
                    <AttributeListWidget 
                    alarmSet={this.props.alarmSetObj} 
                    asset={item} 
                    isSmall={this.props.isSmall} 
                    toggle = {this.props.toggle}
                    />
                    </Col>
                ))} */}
        <Row style={{ justifyContent: "center" }}/* class="box-container-weather" */>
          {statusDataProp.map((item, index) =>
            index >= this.state.pageRange * 9 &&
            index < (this.state.pageRange + 1) * 9 ? (
              <Col
                style={{ textAlign: "center", marginBottom: "35px" /* , maxWidth: "25%" */}}
                
              >
                <AttributeListWidget
                  alarmSet={this.props.alarmSetObj}
                  asset={item}
                  isSmall={true}
                  toggle={this.props.toggle}
                  activeTab={this.props.activeTab}
                />
              </Col>
            ) : null
          )}
         </Row>
          <div>{this.renderSpinner()}</div>
        </Col>
        {/* {(this.state.ifDisplay == false && this.props.activeTab !== "3")?
                                                <div class="row" style={{margin: '5px 10px', padding: '5px', justifyContent: 'center'}}>
                                                    <Button
                                                    // size="sm"
                                                    variant="primary" color="custom"
                                                    onClick={this.displayMore}
                                            
                                                >View More</Button>
                                                </div>
                                                
                                                : 
                                                null}
                                                

                                            {(this.state.ifDisplay == true && this.props.activeTab !== "3")?
                                            
                                                <div class="row" style={{margin: '5px 10px', padding: '5px', justifyContent: 'center'}}>
                                                    <Button
                                                        // size="sm"
                                                        variant="primary" color="custom"
                                                        onClick={this.displayMore}
                                                
                                                    >View Less</Button>
                                                </div>
                                                    : 
                                                null} */}
        <div
          class="p-2"
          style={{
            position: "relative",
            justifyContent: "center",
            margin: "auto",
            display: "flex",
          }}
        >
          <Pagination count={pages} onChange={handleChange} color="custom" />
        </div>
      </div>
    );
  };
  // --------------------------------------------------------------------------
  renderLegend = () => {
    let legendList = [
      { color: "gray", label: "No Activity" },
      { color: "red", label: "Lightning near (<5km)" },
      { color: "orange", label: "Lightning approaching (5km to 15km)" },
      { color: "yellow", label: "Lightning Detected (+15km)" },
      { color: "green", label: "All Clear" },
    ];
    if (!this.props.isSmall) {
      return (
        <div style={{ textAlign: "center", margin: "30px" }}>
          <table
            style={{
              width: "60%",
              margin: "0 auto",
              backgroundColor: "#F8F8F8",
              borderRadius: "20px",
            }}
          >
            <tr>
              {legendList.map((item, index) => (
                <td
                  style={{
                    textAlign: "center",
                    width: "50px",
                    padding: "10px 0",
                  }}
                >
                  <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      key={index}
                      id={`legend_${index}`}
                      fill={item.color}
                      stroke="black"
                      stroke-width="0"
                      cx="12"
                      cy="12"
                      r="10"
                    ></circle>
                  </svg>
                  {/* <span style={{fontSize:'11px'}}>{item.label}</span> */}
                </td>
              ))}
            </tr>
            <tr>
              {legendList.map((item, index) => (
                <td style={{ textAlign: "center", paddingBottom: "10px" }}>
                  {/* <svg width="15px" height="15px" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                                <circle key={index} id={`legend_${index}`} fill={item.color} stroke='black' cx="6" cy="6" r="5.5"></circle>
                            </svg> */}
                  <span style={{ fontSize: "11px" }}>{item.label}</span>
                </td>
              ))}
            </tr>
          </table>
        </div>
      );
    } else {
      return (
        <Row>
          <Col sm={{ size: 6, offset: 4 }} xs={{ size: 8, offset: 3 }}>
            {legendList.map((item, index) => (
              <Row style={{ justifyContent: "space-between" }}>
                <Col sm={2} xs={2}>
                  <svg
                    width="12px"
                    height="12px"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      key={index}
                      id={`legend_${index}`}
                      fill={item.color}
                      stroke="black"
                      stroke-width="0"
                      cx="6"
                      cy="6"
                      r="5"
                    ></circle>
                  </svg>
                </Col>
                <Col sm={10} xs={10}>
                  <span style={{ fontSize: "8px" }}>{item.label}</span>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      );
    }
  };

  renderSpinner = () => {
    return (
      <Fragment>
        {this.props.loading && (
          <div
            style={{
              position: "Absolute ",
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px",
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              backgroundColor: LIGHT_GREY_COLOR,
              opacity: "1.0",
            }}
          >
            <Spinner
              className="spinner"
              as="span"
              animation="border"
              variant="success"
              // size="sm"
              // role="status"
              // aria-hidden="true"
              style={{ display: "inline-block", margin: "auto" }}
            />
          </div>
        )}
      </Fragment>
    );
  };
  //--------------------------------------------------------------------
  render() {
    let height = "400px";
    if (this.props.height) {
      height = this.props.height;
    }
    return (
      <div>
        <Card style={{ padding: "0", border: "0px solid black" }}>
          <CardBody style={{ padding: "10px" }}>
            <div>
              {/* <Row style={{padding:'5px'}}>
                        <Col md={3} style={{textAlign: 'right',}}>
                            <span></span>
                        </Col>
                        {this.state.data && this.state.data.map((item, index) => (
                            <Col md={2} style={{textAlign: 'center',}}>
                                <TrafficLightWidget status={Number(item.alarm)} statusHex={item.hexAlarm}/>
                            </Col>
                        ))}
                    </Row>
                    <Row style={{padding:'5px'}}>
                        <Col md={3} style={{textAlign: 'right',}}>
                            <span><h4>Station:</h4></span>
                        </Col>
                        {this.state.data && this.state.data.map((item, index) => (
                            <Col md={2} style={{textAlign: 'center',}}>
                                <span>{this.assetnameFormat(item)}</span>
                            </Col>
                        ))}
                    </Row>
                    <Row style={{padding:'5px'}}>
                        <Col md={3} style={{textAlign: 'right',}}>
                            <span><h4>Last Message:</h4></span>
                        </Col>
                        {this.state.data && this.state.data.map((item, index) => (
                            <Col md={2} style={{textAlign: 'center',}}>
                                <span>{this.timestampFormat(item.timestamp)}</span>
                            </Col>
                        ))}
                    </Row> */}
              {/* {this.renderStatus()} */}
              {this.renderStatusWidget()}
              {/* <Row style={{padding:'5px'}}>
                        <Col md={3} style={{textAlign: 'right',}}>
                            <span><h4>Battery Leve:</h4></span>
                        </Col>
                        {this.state.data && this.state.data.map((item, index) => (
                            <Col md={2} style={{textAlign: 'center',}}>
                                {
                                    item.battery != undefined && item.battery != null ?
                                    <BatteryWidget level={Number(item.battery)}/>
                                    :
                                    <span style={{fontSize:'16px'}}>No data</span>
                                }

                            </Col>
                        ))}
                    </Row> */}
              {/* <Row style={{padding:'5px'}}>
                        <Col md={3} style={{textAlign: 'right',}}>
                            <span><h4>Interal Temp:</h4></span>
                        </Col>
                        {this.state.data && this.state.data.map((item, index) => (
                            <Col md={2} style={{textAlign: 'center',}}>
                                <div style={{backgroundColor: '#00ff00', padding: '1px', border: '2px solid black'}}>
                                    {
                                        item.temperature ? 
                                        <span style={{fontSize:'16px'}}>{item.temperature} &#xb0;C</span>
                                        :
                                        <span style={{fontSize:'16px'}}>No data</span>
                                    }
                                
                                </div>
                            </Col>
                        ))}
                    </Row> */}
              {/* <hr/> */}
            </div>
            {/* <hr/>
                    {this.renderMUPagin(this.state.totalPages)} */}
            {/* {this.renderLegend()} */}
            {this.renderSpinner()}
          </CardBody>

          {/* <CardFooter className="text-muted">
                    {this.renderMUPagin()}
                </CardFooter> */}
          {/* <CardFooter className="text-muted">
                    {this.renderLegend()}
                </CardFooter> */}
        </Card>
        {/* <hr/> */}
        {/* <div style={{textAlign: 'right',}}> */}
        {/* {this.renderPagin()} */}
        {/* {this.renderMUPagin()} */}
        {/* </div> */}
      </div>
    );
  }
}

function rowClassGnerator(
  alarmSet,
  item,
  attributeName,
  hasrule = false,
  isSmall = false
) {
  let backgroupdColor =
    attributeName === "Battery" ? "white" : hasrule ? GREEN_COLOR : GREY_COLOR;
  let horizontalAlign =
    attributeName != "Battery" && isSmall ? "space-between" : "space-evenly"; //'center';
  let reClass = {
    // color: 'black',
    // fontWeight: 'normal',
    margin: "5px 10px",
    padding: "5px 30px",
    justifyContent: horizontalAlign, //'center'
    alignContent: "center",
    lineHeight: "30px",
    height: "30px",
    backgroundColor: backgroupdColor, //hasrule ? GREEN_COLOR : GREY_COLOR,
    color: "black",
  };

  // if (this.props.alarmObj && this.props.alarmObj.msgList){
  //     for (let alarm of this.props.alarmObj.msgList) {
  //         if (item.assetname == alarm.assetname && alarm.attributeList && alarm.attributeList.includes(attributeName) && this.props.alarmObj.status == true) {
  //             return ({
  //                 padding:'5px',
  //                 justifyContent: 'center',
  //                 alignContent: 'center',
  //                 lineHeight: '30px',
  //                 height: '30px',
  //                 animation: 'fontBlink 1s infinite',
  //             });
  //         }
  //     }
  // }

  if (
    alarmSet &&
    alarmSet[item.assetid] &&
    alarmSet[item.assetid][attributeName] &&
    alarmSet[item.assetid][attributeName].status == true
  ) {
    return {
      margin: "5px 10px",
      padding: "5px 30px",
      justifyContent: horizontalAlign, //'center',
      alignContent: "center",
      lineHeight: "30px",
      height: "30px",
      animation: "fontBlink 1s infinite",
    };
  }
  return reClass;
}

function updatedValue(item, attributeName) {
  let reValue = item[attributeName] ? item[attributeName] : "";
  // try{
  //     if (this.props.alarmSet && this.props.alarmSet[item.assetid] && this.props.alarmSet[item.assetid][attributeName] && this.props.alarmSet[item.assetid][attributeName].status == true){
  //         let itemTime = new Date(item.timestamp);
  //         let alarmTime = new Date(this.props.alarmSet[item.assetid][attributeName].timestamp);
  //
  //
  //         if (alarmTime > itemTime){
  //             reValue = this.props.alarmSet[item.assetid][attributeName].value;
  //
  //         }else{
  //             // reValue = item[attributeName] ? item[attributeName] : '';
  //         }

  //     }
  // }
  // catch(e){

  // }

  return reValue;
}

// export default StatusBoard;
export default withStyles(styles)(StatusBoard);
