/*eslint-disable*/
import React, { Component } from "react";
import  Card from "react-credit-cards";
import uuid from "uuid/v4";
import AddPaymentModal from "./payment/addPaymentModal";
import {Row, Col, Card as Card2, CardBody,  CardGroup, Label, Input, Button, Table} from 'reactstrap';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { toast } from "react-toastify";
import { USAGE_PRICE } from '../../../constants/dataConstants';

class CompanyAccountPurchaseCheckoutComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
        cards: [
          {
            name: "Checkout1",
            number: "5434 4934 0134 3414",
            cvc: "434",
            expiry: "03/19",
            id: uuid()
          },
          {
            name: "Checkout2",
            number: "4934 4934 0134 3415",
            cvc: "435",
            expiry: "03/19",
            id: uuid()
          }
        ]
      };
    
      
  }

  manufactureOnChangeHandler = (e) => {
    let selectedValue = dropdownOnChangeHandler(e); //alert(selectedValue.id);
     
    // this.props.getAttributes(selectedValue.id);
    // this.props.loadAssets({devicetypeid: selectedValue.id});
    this.setState({
        currentDeviceTypeId : selectedValue.id,
        currentDeviceTypeName : selectedValue.name,
     });
    
}

orderNow = () => {
  if (this.props.currentPlanItem && this.props.account && Array.isArray(this.props.account) && this.props.account.length > 0) {
  let defaultData = {
      "customerId": this.props.customerObj.id,
      "accountId": this.props.account[0].id,
      "currency": "CAD",
      "comment": "1",
      "autoPay": this.props.autoPay ? 1 : 0,
      "billcycle": this.props.billCycle,
      "orderItemDataList": [
          {
              "itemId": this.props.currentPlanItem.itemId,
              "itemQty": 1,
              "promoCode": 1
          }
      ]
  
}
  // SPECIFY CORRECT ATTRIBUTES
  let data = {
    "accountId": this.props.account[0].id,
      "currency": "CAD",
      "comment": "1",
      "autoPay": this.props.autoPay ? 1 : 0,
      "orderItemDataList": [
          {
              "itemId": this.props.currentPlanItem.itemId,
              "itemQty": 1,
              "promoCode": 1
          }
      ]
}

  this.props.createOrder({ ...defaultData, ...data })
} else{
  toast.error('No Item selected');
}
}

payOrderNow = () => {
  if (this.props.purchaseOrder && this.props.purchaseOrder.orderData){
      let param = {
          id :  this.props.purchaseOrder.orderData.id
      };
      this.props.payOrder(param);
  }
  else{
      toast.error('No Item selected');
  }
}

  render() {

    
    const  plans  = this.state.plans;
    const aList = this.props.accountList;
     
    const cardsstyle ={
  
        'width': '100%',
       
      }

      const btn ={
        'float': 'right'
      }

     const company = this.props.userObj.company;

    //   
    //  const ac = this.props.account? this.props.account.filter(account => account.cardMask) : [];
     const ac = this.props.account.filter(account => account.cardMask);
     let price = ''; 
     if (this.props.itemType && this.props.itemType === '1' && this.props.currentPlanItem) {
      if (this.props.currentPlanItem.itemTypeId == 1) {
        price = USAGE_PRICE;
      }
      else if (this.props.currentPlanItem.itemTypeId == 2 && this.props.currentPlanItem.unitPrice) {
        price = `${this.props.currentPlanItem.unitPrice}`;
      }

    }

    // ------------------------------------------------------
    let deviceList = [];
    let TotalTax = '';
    let SubTotal='';
    let Total='';
    if (this.props.purchaseOrder){
        deviceList = this.props.purchaseOrder.deviceList;
        if (this.props.purchaseOrder.orderData){
            SubTotal = isNaN(this.props.purchaseOrder.orderData.ordrTtl) ? "" : Number(this.props.purchaseOrder.orderData.ordrTtl).toFixed(2);
            TotalTax = isNaN(this.props.purchaseOrder.orderData.ordrTtlTax) ? "" : Number(this.props.purchaseOrder.orderData.ordrTtlTax).toFixed(2);;
            Total = isNaN(this.props.purchaseOrder.orderData.ordrAmt) ? "" : Number(this.props.purchaseOrder.orderData.ordrAmt).toFixed(2);;
        }
    }else{
        toast.error('Failed to get order data, please try again')
    }
    // ------------------------------------------------------
    let usageList = [];
    if (this.props.itemType && this.props.itemType === '1' && this.props.currentPlanItem) {
      let price = '';
      let paymentperiod = 'one shot';
      if (this.props.currentPlanItem.itemTypeId == 1) {
        price = USAGE_PRICE;
        SubTotal = price;
        Total = price;
      }
      else if (this.props.currentPlanItem.itemTypeId == 2 && this.props.currentPlanItem.unitPrice) {
        price = `CAD$ ${this.props.currentPlanItem.unitPrice}`;
      }

      if (this.props.currentPlanItem && this.props.currentPlanItem.payFrequency) {
        paymentperiod = this.props.currentPlanItem.payFrequency.itemPayFreqName
      }
      let item = {
              "product": this.props.currentPlanItem.itemName,
              "quantity": 1,
              "price": price,
              "paymentperiod": paymentperiod,
              "autocharg": this.props.autoPay ? 'Yes': 'No',
              "card": 1000,
          };
      usageList.push(item)
    }
      
    return(
    <div className="App-cards-list">
        <div class="container">

      <div class="row">
        <div class="col-md-4 order-md-2 mb-4">
          <h4 class="d-flex justify-content-between align-items-center mb-3">
            <span class="text-muted">Your cart</span>
            <span class="badge badge-secondary badge-pill">1</span>
          </h4>
          <ul class="list-group mb-3">
            <li class="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 class="my-0">Items</h6>
                {/* <small class="text-muted">IOT - Basic</small> */}
              </div>
              <span class="text-muted">{price}</span>
              {/* <span class="text-muted">{SubTotal}</span> */}
            </li>
            {/* <li class="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 class="my-0">Second product</h6>
                <small class="text-muted">Brief description</small>
              </div>
              <span class="text-muted">$8</span>
            </li> */}
            
            <li class="list-group-item d-flex justify-content-between bg-light">
              <div class="text-success">
                <h6 class="my-0">Promo code</h6>
                <small>EXAMPLECODE</small>
              </div>
              <span class="text-success">-$0</span>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Total Tax(CAD)</span>
              <strong>{TotalTax}</strong>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <span>Total (CAD)</span>
              <strong>{price}</strong>
              {/* <strong>{Total}</strong> */}
            </li>
          </ul>

          <form class="card p-2">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Promo code"/>
              <div class="input-group-append">
                <button type="submit" class="btn btn-secondary">Redeem</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-8 order-md-1">
        {/* <Card2 >
        <CardBody> */}
        <div class="row mt-3">       
          <div class="col-md-6">
          <h4 class="mb-3">Billing address</h4>
          {/* <form class="needs-validation" novalidate> */}
            {/* <div class="row">
              <div class="col-md-6 mb-3">
                <label for="firstName">First name</label>
                <input type="text" class="form-control" id="firstName" placeholder="firstName" value="" />
                <div class="invalid-feedback">
                  Valid first name is required.
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="lastName">Last name</label>
                <input type="text" class="form-control" id="lastName" placeholder="lastName" value="" />
                <div class="invalid-feedback">
                  Valid last name is required.
                </div>
              </div>
            </div> */}

            {/* <div class="mb-3">
              <label for="username">Username</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">@</span>
                </div>
                <input type="text" class="form-control" id="username" placeholder="Username" required/>
                <div class="invalid-feedback" >
                  Your username is required.
                </div>
              </div>
            </div> */}

            {/* <div class="mb-3">
              <label for="email">Email <span class="text-muted">(Optional)</span></label>
              <input type="email" class="form-control" id="email" placeholder={company.email}/>
              <div class="invalid-feedback">
                Please enter a valid email address for shipping updates.
              </div>
            </div> */}

            {/* <div class="mb-3">
              <label for="address">Address</label>
              <input type="text" class="form-control" id="address" placeholder={company.addressline1} />
              <div class="invalid-feedback">
                Please enter your shipping address.
              </div>
            </div>

            <div class="mb-3">
              <label for="address2">Address 2 <span class="text-muted">(Optional)</span></label>
              <input type="text" class="form-control" id="address2" placeholder="Apartment or suite"/>
            </div>

            <div class="row">
              <div class="col-md-5 mb-3">
                <label for="country">City</label>
                <select class="custom-select d-block w-100" id="city" required>
                  <option>{company.city}</option>
                </select>
                 <input type="text" class="form-control" id="zip" placeholder={company.city} />
                <div class="invalid-feedback">
                  Please select a valid city.
                </div>
              </div>
              <div class="col-md-4 mb-3">
                <label for="state">Province</label>
                <select class="custom-select d-block w-100" id="state" required>               
                  <option>{company.province}</option>
                </select>
                 <input type="text" class="form-control" id="zip" placeholder={company.province} />
                <div class="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>
              <div class="col-md-5 mb-3">
                <label for="country">Country</label>
                <select class="custom-select d-block w-100" id="country" required>
                  <option>{company.country}</option>
                </select>
                 <input type="text" class="form-control" id="zip" placeholder={company.country} />
                <div class="invalid-feedback">
                  Please select a valid country.
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="zip">Zip</label>
                <input type="text" class="form-control" id="zip" placeholder={company.postcode} />
                <div class="invalid-feedback">
                  Zip code required.
                </div>
              </div>
            </div> */}

            <div class="mb-1">
              {company.addressline1 && <div><label>{company.addressline1}</label><br/></div>}
              {company.addressline2 && <div><label>{company.addressline2}</label><br/></div>}
              <label>{company.city + " " + company.province + " " + company.country + " " + company.postcode}</label>
            </div>
           
            {/* <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="same-address"/>
              <label class="custom-control-label" for="same-address">Shipping address is the same as my billing address</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="save-info"/>
              <label class="custom-control-label" for="save-info">Save this information for next time</label>
            </div> */}
           </div>
           <div class="col-md-6">
            <h4 class="mb-3">Payment</h4> 
            {/* <div class="d-block my-3">
              <div class="custom-control custom-radio">
                <input id="credit" name="paymentMethod" type="radio" class="custom-control-input" checked />
                <label class="custom-control-label" for="credit">Credit card</label>
              </div>
              <div class="custom-control custom-radio">
                <input id="debit" name="paymentMethod" type="radio" class="custom-control-input" />
                <label class="custom-control-label" for="debit">Debit card</label>
              </div>
              <div class="custom-control custom-radio">
                <input id="paypal" name="paymentMethod" type="radio" class="custom-control-input" />
                <label class="custom-control-label" for="paypal">Paypal</label>
              </div>
            </div> */}
            {ac && ac.map(card => (<div>
            <div class="row">
              {/* <div class="col-md-6 mb-3">
                <label for="cc-name">Name on card</label>
                <input type="text" class="form-control" id="cc-name" placeholder="" required/>
                <small class="text-muted">Full name as displayed on card</small>
                <div class="invalid-feedback">
                  Name on card is required
                </div>
              </div> */}
              <div class="col-md-4 mb-1">
                <Card 
                  number={card.cardMask}
                  customCardsSupport={true}
                  customCardsStyles={{'width': '50px', 'height': '30px'}}
                />
              </div>
              <div class="col-md-4 mb-1">                
                <label>{card.cardMask}</label>
                {/* <label for="cc-number">Credit card number</label>
                <input type="text" class="form-control" id="cc-number" placeholder={card.cardMask} /> */}
                {/* <div class="invalid-feedback">
                  Credit card number is required
                </div> */}
              </div>
            </div>
            {/* <div class="row">
              <div class="col-md-3 mb-3">
                <label for="cc-expiration">Expiration</label>
                <input type="text" class="form-control" id="cc-expiration" placeholder="" required/>
                <div class="invalid-feedback">
                  Expiration date required
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <label for="cc-expiration">CVV</label>
                <input type="text" class="form-control" id="cc-cvv" placeholder="" required/>
                <div class="invalid-feedback">
                  Security code required
                </div>
              </div>
            </div> */}
            </div>
            ))}
            </div>
          
            </div>
            {/* </CardBody>
            </Card2> */}
            <hr/>
            <div class="row mt-4 mb-5">
            <h4 class="mb-3">Order Summary</h4>
            {/* <div><h4>Order Summary</h4></div><br/> */}
              <BootstrapTable 
                  data={ usageList }  
                  // tableHeaderClass = 'header_white' 
                  pagination = {false} 
                    
                    
                  bordered={ false } 
                  // insertRow 
                  ref='table' 
              >

                <TableHeaderColumn dataField='product' isKey={ true } dataAlign="center" width='47%'>Item</TableHeaderColumn>
                <TableHeaderColumn dataField='quantity' dataAlign="center" dataSort width='10%' >Quantity</TableHeaderColumn> 
                <TableHeaderColumn dataField='price' dataAlign="center" dataSort width='18%' >Price</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='paymentperiod' dataAlign="center" dataSort width='10%' >Payment Period</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='autocharg' dataAlign="center" dataSort width='10%' >Auto Charge</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater}  dataAlign="center" dataSort width='10%' >Payment card</TableHeaderColumn> */}
                                      
              </BootstrapTable>
            </div>
            <br/>
            <button 
              class="btn btn-primary btn-lg btn-block" 
              type="submit" 
              onClick={() => {
                this.orderNow();
                // this.payOrderNow();
              }}
            >
              Confirm and Pay
            </button>
           
          {/* </form> */}
        </div>
      </div>

    </div> 
    </div>
    );
  }
}


export default CompanyAccountPurchaseCheckoutComp;

