/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ClientActiveSIMModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            selectedSIM: null,
            itemPlanId: null,
            planName: null,
            customerId: null,
            deviceName: null,
            status: null,
            consumerList: [],
            list: false,
            countryId: '',
            lobId: '',
            relationId: '',
            countryList: [],
            LOBList: [],
            divisionList: []

        }
    }
    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            showModal: nextProps.showModal
        })
        if (nextProps.selectedSIM != null) {
            if (nextProps.ServicePlanList.length > 0) {
                this.setState({
                    list: true,
                    selectedSIM: nextProps.selectedSIM,
                    status: nextProps.selectedSIM.status,
                    deviceName: nextProps.selectedSIM.devicename,
                    itemPlanId: nextProps.selectedSIM.planId,
                    customerId: nextProps.selectedSIM.customerId,
                    countryId: nextProps.selectedSIM.countryId,
                    lobId: nextProps.selectedSIM.lobId,
                    relationId: nextProps.selectedSIM.businessUnit,
                    countryList: [],
                    LOBList: [],
                    divisionList: []
                })
            }
            else {
                this.setState({
                    list: false,
                    selectedSIM: nextProps.selectedSIM,
                    status: nextProps.selectedSIM.status,
                    deviceName: nextProps.selectedSIM.devicename,
                    planName: nextProps.selectedSIM.planName,
                    customerId: nextProps.selectedSIM.customerId,
                    countryId: nextProps.selectedSIM.countryId,
                    lobId: nextProps.selectedSIM.lobId,
                    relationId: nextProps.selectedSIM.businessUnit,
                    countryList: [],
                    LOBList: [],
                    divisionList: []
                })
            }

        }
        var list = []
        if (nextProps.consumerList.length > 0) {
            list = nextProps.consumerList.filter(consumer => consumer.resellerCode == nextProps.selectedRow.resellerCode);
        }
        this.setState({
            consumerList: list
        })
    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onClose = () => {
        this.props.onClose()
    }
    onActivate = () => {
        var data = []
        if (this.state.itemPlanId != null) {
            let param = {
                id: this.state.selectedSIM.inventoryId,
                customerId: this.state.customerId,
                itemPlanId: this.state.itemPlanId,
                deviceName: this.state.deviceName,
                relationId: this.state.relationId,
                status: 1
            }
            data.push(param)
            this.props.onActivate(data)
        }
        else {
            toast.error("please select a plan")
        }
    }
    onTerminate = () => {
        var data = []
        let param = {
            id: this.state.selectedSIM.inventoryId,
            customerId: 0,
            itemPlanId: 0,
            deviceName: null,
            relationId: null,
            status: 3
        }
        data.push(param)
        this.props.onActivate(data)
    }
    onSuspend = () => {
        var data = []
        let param = {
            id: this.state.selectedSIM.inventoryId,
            customerId: 0,
            itemPlanId: 0,
            deviceName: this.state.deviceName,
            relationId: this.state.relationId,
            status: 2
        }
        data.push(param)
        this.props.onActivate(data)
    }
    onOperationCountryChange = (event) => {
        this.setState({
            countryId: event.target.value,
            lobId: '',
            relationId: '',
            LOBList: [],
            divisionList: []
        })
    }
    onLobChange = (event) => {
        this.setState({
            lobId: event.target.value,
            relationId: '',
            divisionList: []
        })
    }

    reduceBusinessUnitList = () => {
        if (this.props.BURelationshipList.length > 0 && this.state.resellerCode != '') {
            this.props.BURelationshipList.forEach((relation) => {
                //country
                if (!(this.state.countryList.map((country) => country.name)).includes(relation.country.name)) {
                    this.state.countryList.push(relation.country)
                }
                //LOB
                if (this.state.countryId != '') {
                    if (relation.country.countryId == parseInt(this.state.countryId)) {
                        if (!(this.state.LOBList.map((lineOfBusiness) => lineOfBusiness.name)).includes(relation.lineOfBusiness.name)) {
                            this.state.LOBList.push(relation.lineOfBusiness)
                        }
                    }
                }
                //Division
                if (this.state.countryId != '' && this.state.lobId != '') {
                    if (relation.country.countryId == parseInt(this.state.countryId)) {
                        if (relation.lineOfBusiness.lobId == parseInt(this.state.lobId)) {
                            if (!(this.state.divisionList.map((relation) => relation.division.name)).includes(relation.division.name)) {
                                this.state.divisionList.push(relation);
                            }
                        }
                    }
                }
            })
        }
    }

    render() {
        this.reduceBusinessUnitList()
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onClose}>{(this.state.selectedSIM) ? `Edit ${this.state.selectedSIM.identifierValue}` : "Loading..."}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        {this.props.pageContentList.includes("SIM Modal PP") &&  this.state.status == 1?
                            <>
                                <br />
                                <Row>
                                    <Col md={2}>
                                        <span>Pair Plan</span>
                                    </Col>

                                    <Col md={8}>
                                        {(this.state.list) ?
                                            <Input
                                                type='select'
                                                name='itemPlanId'
                                                id='itemPlanId'
                                                placeholder='item Names'
                                                className="form-control"
                                                //value={this.state.company}
                                                onChange={this.onInputChange}
                                                defaultValue={this.state.itemPlanId}
                                            >
                                                {(this.state.status != 1) ? <option key={-1} value={0}>No Selection</option> : null}
                                                {this.props.ServicePlanList.map((plan, index) => {
                                                    if (plan.resellerId != null) {
                                                        return <option key={index} value={plan.itemId}>{plan.itemName}</option>

                                                    }
                                                }
                                                )}
                                            </Input>
                                            :
                                            <div>
                                                <Input
                                                    name='planName'
                                                    id='planName'
                                                    placeholder='Plan Name'
                                                    className="form-control"
                                                    value={this.state.planName}
                                                />
                                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> can't change plan since no other plan found</span>
                                            </div>

                                        }
                                    </Col>
                                </Row>
                            </> : null}
                        {this.props.pageContentList.includes("SIM Modal BU") ?
                            <>
                                <br />
                                <Row>
                                    <Col md={2} >
                                        <span>Operation Country</span>
                                    </Col>
                                    <Col md={8}>
                                        <Input
                                            type='select'
                                            name='countryId'
                                            placeholder='Country'
                                            onChange={this.onOperationCountryChange}
                                            className="form-control"
                                            value={this.state.countryId ? this.state.countryId : ''}
                                        >
                                            {/* <option value={'CA'}>Canada</option>
                                        <option value={'USA'}>United States</option>} */}
                                            <option value={''}>No Selection</option>
                                            {this.state.countryList && this.state.countryList.map((countryItem, index) => (
                                                <option key={index} value={countryItem.countryId}>{countryItem.name}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>


                                {(this.state.countryId != '' && this.state.countryId != null) ?
                                    <div>
                                        <br />
                                        <Row>
                                            <Col md={2} >
                                                <span>Line of Business</span>
                                            </Col>
                                            <Col md={8}>
                                                <Input
                                                    type='select'
                                                    name='lobId'
                                                    placeholder='Lob'
                                                    onChange={this.onLobChange}
                                                    className="form-control"
                                                    value={this.state.lobId ? this.state.lobId : ''}
                                                >
                                                    {/* <option value={'CA'}>Canada</option>
                                        <option value={'USA'}>United States</option>} */}
                                                    <option value={''}>{'No Selection'}</option>
                                                    {this.state.LOBList && this.state.LOBList.map((lobItem, index) => (
                                                        <option key={index} value={lobItem.lobId}>{lobItem.name}</option>
                                                    ))}
                                                </Input>
                                            </Col>
                                        </Row>
                                    </div>

                                    :
                                    null
                                }
                                {
                                    (this.state.lobId != '' && this.state.countryId != '' && this.state.lobId != null && this.state.countryId != null) ?
                                        <div>
                                            <br />

                                            <Row>
                                                <Col md={2} >
                                                    <span>Department</span>
                                                </Col>
                                                <Col md={8}>
                                                    <Input
                                                        type='select'
                                                        name='relationId'
                                                        placeholder='Department'
                                                        onChange={this.onInputChange}
                                                        className="form-control"
                                                        value={this.state.relationId ? this.state.relationId : ''}
                                                    >
                                                        <option value={''}>{'No Selection'}</option>
                                                        {this.state.divisionList && this.state.divisionList.map((divisionItem, index) => (
                                                            <option key={index} value={divisionItem.id}>{divisionItem.division.name}</option>
                                                        ))}
                                                    </Input>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        null
                                }
                            </> : null}
                        {this.props.pageContentList.includes("SIM Modal PEU") ?

                            <>
                                <br />
                                <Row>
                                    <Col md={2}>
                                        <span>Pair End User</span>
                                    </Col>
                                    <Col md={8}>
                                        <Input
                                            type='select'
                                            name='customerId'
                                            id='customerId'
                                            placeholder='item Names'
                                            className="form-control"
                                            //value={this.state.company}
                                            onChange={this.onInputChange}
                                            defaultValue={this.state.customerId}
                                        >
                                            <option key={-1} value={0}>No Selection</option>
                                            {this.state.consumerList.map((consumer, index) => {
                                                if (consumer.customer != null) {
                                                    return <option key={index} value={consumer.customer.customerId}>{(consumer.customer.businessName != null && consumer.customer.businessName != "") ? consumer.customer.businessName : consumer.customer.firstName}</option>
                                                }
                                            }
                                            )}
                                        </Input>
                                    </Col>
                                </Row>
                            </> : null}
                        {this.props.pageContentList.includes("SIM Modal DN") ?

                            <>
                                <br />
                                <Row>
                                    <Col md={2}>
                                        <span>Device Name</span>
                                    </Col>
                                    <Col md={8}>
                                        <Input
                                            name='deviceName'
                                            id='deviceName'
                                            placeholder='Device Name'
                                            className="form-control"
                                            //value={this.state.company}
                                            onChange={this.onInputChange}
                                            value={this.state.deviceName}
                                        >
                                        </Input>
                                    </Col>
                                </Row>
                            </> : null}
                        <br />
                    </Col>

                </ModalBody>
                <ModalFooter>
                    {(this.props.pageContentList.includes("SIM Modal Sus") && this.state.status == 1) ?
                        <Button outline color='danger' type="button" onClick={this.onSuspend}>
                            Suspend
                        </Button> : null
                    }
                    {(this.props.pageContentList.includes("SIM Modal Term"))?
                    <Button outline color='warning' type="button" onClick={this.onTerminate} >
                        Terminate
                    </Button>
                    :null}
                    <Button outline color='custom' type="button" onClick={this.onActivate} disabled={!this.state.list}>
                        Update
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

}
export default ClientActiveSIMModal;