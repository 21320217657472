/* eslint-disable */


export const initWebsocketAndAssignRef =(url,binaryType,protocols,handleSocketOpen,handleSocketMessage,handleSocketClose,handleSocketError) => {
    
    let wsProtocol = (location.protocol === 'http:') ? 'ws:' : 'wss:';
    let host = `${url}`;
    let ws = null;
    if(protocols) {
        ws = new WebSocket(host, protocols);
    }else {
        ws = new WebSocket(host);
    }
    if(binaryType){
        ws.binaryType=binaryType
    }

    ws.onopen = (event) => {
        // console.log("Websocket opened")
        if(handleSocketOpen) {
            handleSocketOpen(event);
        }
    };

    ws.onmessage = (event) => {
        if(handleSocketMessage) {
            handleSocketMessage(event.data);
        }
    };

    ws.onclose = (event) => {
        // console.error("Socket closed");
        if(handleSocketClose) {
            handleSocketClose(event);
        }
    };

    ws.onerror = (event) => {
        // console.error('Socket error');
        if(handleSocketError) {
            handleSocketError(event);
        }
    };

    return ws
}

