/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import { sortCaret } from '../../../shared/components/Table/tableElements';

class CompanyActivityTrackingBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        this.props.GetUserActivityTracking({companyCode:this.props.userObj.companyCode})
    }
    dateFormatter = (cell, row) => {
        let currentDate = new Date(cell);
        let time = currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds();
        let cDay = currentDate.getDate();
        let cMonth = currentDate.getMonth() + 1;
        let cYear = currentDate.getFullYear();
        return (
            `${cYear}-${cMonth}-${cDay} ${time}`
        )
    }

    render() {
        let reportList = this.props.userActivityTrackingList.slice(0).reverse();
        const options = {
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        }

        return (
            <Card>
                <CardBody className="mainCardBody">

                    <BootstrapTable
                        data={reportList}
                        tableHeaderClass='header_white'
                        exportCSV
                        csvFileName='User_Activity.csv'
                        bordered={false}
                        pagination={true}
                        options={options}
                    >
                        <TableHeaderColumn row='0' rowSpan='1' dataField='id' dataSort isKey={true} dataAlign='center' hidden={true}>Id</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='username' dataSort  dataAlign='center' filter={{ type: 'TextFilter' }} caretRender={sortCaret} csvHeader='User' csvFormat={this.csvFormatter}>User</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='description' dataSort  dataAlign='center' filter={{ type: 'TextFilter' }} caretRender={sortCaret} csvHeader='Description' csvFormat={this.csvFormatter}>Description</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='date' dataSort  dataAlign='center' filterFormatted  filter={{ type: 'TextFilter' }} dataFormat={this.dateFormatter.bind(this)}  caretRender={sortCaret} csvHeader='Date' csvFormat={this.csvFormatter}>Date</TableHeaderColumn>

                    </BootstrapTable>
                </CardBody>
            </Card>

        )
    }
}
export default CompanyActivityTrackingBoard;