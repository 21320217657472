/* eslint-disable */
import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import {Container, Row, Col, Button, Table, Card, CardBody} from 'reactstrap';

export class GeoFenceMap extends Component{
    constructor(props){
        super(props);
        this.initMap = this.initMap.bind(this);
        // this.selectedShap;
        this.myMap = React.createRef();
        this.state={
            testValue: null,
            title: 'aaaa',
            testCode: [
                {lat: 43.822311404399926, lng: -79.56390763395996},
                {lat: 43.82020588194602, lng: -79.55043221586914},
                {lat: 43.812402415964726, lng: -79.5622768508789},
                {lat: 43.8135172592541, lng: -79.5758380996582}
            ],
            mapCenter: {lat:43.8148034, lng:-79.5509472},
            toggal1: true,
            toggal2: false,
        }
    }

    testUpdate = () => {
        alert('update');
        this.setState({
            testCode: [
                {lat: 25.774, lng: -80.190},
                {lat: 18.466, lng: -66.118},
                {lat: 32.321, lng: -64.757}
            ],
            title: 'bbbbbb',
            mapCenter: {lat:43.8248024, lng:-79.5509472},
           
        });
        // this.forceUpdate();
        // this.setState({
        //     title: 'bbbbbb',
        // });
    }

    testRef = () => {
        let mapComponent = this.myMap.current;
        let google = mapComponent.props.google;
        let map = mapComponent.map;

        var circle = new google.maps.Circle({
            fillColor: '#ffffff',
            fillOpacity: .6,
            strokeWeight: 1,
            strokeColor: '#ff0000',
            draggable: true,
            editable: true,
            map: map,
            center: {lat: 43.8135172592541, lng: -79.5758380996582},
            radius: 80
        });

    }

    setCircle = (google, map) => {
        const initCircle=new google.maps.Circle({
            strokeColor: '#ffffff',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: map,
            center: {lat: 43.8135172592541, lng: -79.5758380996582},
            radius:  80,
            draggable: true,
            editable: true
          });
        google.maps.event.addListener(initCircle, 'radius_changed', function (event) {

            alert('circle radius changed');
        });
    
        google.maps.event.addListener(initCircle, 'center_changed', function (event) {
    
            alert('circle center changed');
        });
    }

    selectedShape;
    currentPolygonData = null;
    currentRectangleData = null;
    currentCircleData = null;

    setSelection = (shape) => {        
        this.selectedShape = shape;
    }
    deleteSelectedShape = () => {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
        }
    }
    setPloygonData = (polygon) => {
        if (polygon.getPaths().getArray() && polygon.getPaths().getArray().length > 0) {
            this.currentPolygonData = [];
        }else{
            return;
        }
        
        polygon.getPaths().getArray().forEach((item,index) => {
            let path = [];
            item.getArray().forEach((item, index) => {
                //  
                path.push(item.toJSON());
            });
            this.currentPolygonData.push(path);
        });
    }
    setRectangleData = (rectangle) => {
        this.currentRectangleData = rectangle.getBounds().toJSON();
    }
    setCircleData = (circle) => {
        this.currentCircleData = {
            center: circle.getCenter().toJSON(),
            radius: circle.getRadius()
        }
    }

    initMap(mapProps, map) {
        alert('onready');
        var self = this;
        const {google} = mapProps;
    
        const drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: null,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              
              google.maps.drawing.OverlayType.POLYGON,
              google.maps.drawing.OverlayType.CIRCLE,
              google.maps.drawing.OverlayType.RECTANGLE
            ]
          },
          map: map
        });

        var triangleCoords = [
            {lat: 25.774, lng: -80.190},
            {lat: 18.466, lng: -66.118},
            {lat: 32.321, lng: -64.757}
        ];

        const bermudaTriangle = new google.maps.Polygon({
            paths: self.state.testCode,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
            map: map,
            editable: true
        });

        self.setCircle(google, map);

        // google.maps.event.addListener(drawingManager, 'circlecomplete', function(circle) {
        //     var radius = circle.getRadius();
        //     alert(radius);
        // });

        google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
            var newShape = event.overlay;
            self.deleteSelectedShape();
            self.setSelection(event.overlay);
            google.maps.event.addListener(newShape, 'click', function (e) {
                self.setSelection(newShape);
            });
            if (event.type == 'circle') {
                // var radius = newShape.getRadius();
                // alert(radius);       
                self.setCircleData(newShape);     
                 
                self.testUpdate();
            }
            if (event.type == 'rectangle'){
                //  
                self.setRectangleData(newShape);
                 
            }
            if (event.type == 'polygon'){
                //  
                //  
                self.setPloygonData(newShape);
                 
                alert('test');
            }
        });

    }

    render(){
        const mapStyle = {
            width: '100%',
            height: '50vh'
        };  
        return(
            <Col md={12}>
            <div onCkick={this.testUpdate}> <Button onClick={this.testRef}>test button</Button> </div >
            {/* <Card>
                <CardBody > */}
                    {this.state.title}
                <div style={mapStyle} >
                    {this.state.toggal1 &&
                    <Map ref={this.myMap} 
                        google={this.props.google} 
                        zoom={14}  
                        onReady={this.initMap}
                        myValue = {this.state.title}
                        initialCenter={this.state.mapCenter}
                        center={this.state.mapCenter}
                        >
                        {/* {this.initMap()} */}
                    </Map>
                    }
                     {/* {this.state.toggal2 &&
                    <Map google={this.props.google} 
                        zoom={14}  
                        onReady={this.initMap}
                        myValue = {this.state.title}
                        initialCenter={this.state.mapCenter}
                        center={this.state.mapCenter}
                        >

                    </Map>
                    } */}
                </div>
                {/* </CardBody>
            </Card> */}
            </Col>
        )
    }
}

// export default GeoFenceMap;
export default GoogleApiWrapper({
    // apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
    // apiKey: 'AIzaSyDXBuKv70EhwKMyHzrj6wc7_qPQFwmdyB4',
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['drawing'],
})(GeoFenceMap);