/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import 'bootstrap/scss/bootstrap';
import { DateRangePicker } from 'react-dates';
import Spinner from 'react-bootstrap/Spinner';
import { sortCaret } from '../../../shared/components/Table/tableElements';

class DeviceDetailInfo extends Component {
    constructor(props) {
        super(props);
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = yyyy + '-' + mm + '-' + dd;

        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        var mm1 = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var dd1 = String(yesterday.getDate()).padStart(2, '0');
        var yyyy1 = yesterday.getFullYear();

        yesterday = yyyy1 + '-' + mm1 + '-' + dd1;

        this.state = {
            // data: props.data
            startDateMoment: moment(yesterday),
            endDateMoment: moment(today),
            activeTab: null,
        }
    }

    componentWillUnmount() {
        this.props.clearDivceMapData();

    }

    static getDerivedStateFromProps(props, state) {
        // let data = [];
        // let keys = [];
        // let deviceId = '';
        // let deviceName = '';
        // let deviceTypeId = '';
        // let deviceTypeName = '';

        // props.data.map((item, i) => {
        //     if (i === 0){
        //         keys = Object.keys(item.msg);
        //         deviceId = item.deviceid;
        //         deviceName = item.devicename;
        //         deviceTypeId = item.devicetypeid;
        //         deviceTypeName = item.devicetypename;
        //     }
        //     let dateTime = new Date(item.timestamp).toISOString().split('.')[0];
        //     // let date = new Date(this.props.currentFromTime).toISOString().split('.')[0];
        //     let msg = {msgid: item.msgid, datetime: dateTime};
        //     keys.map((key, i) => {
        //         msg[key] = item.msg[key].value;
        //     });
        //     // let dataItem = Object.assign({}, {msgid: item.msgid, datetime: dateTime},item.msg);
        //     let dataItem = Object.assign({}, msg);
        //     data.push(dataItem);
        // });

        // let index ;
        // index = keys.indexOf('Lng');
        // if (index > 0){
        //     let element = keys.splice(index,1);
        //     keys.unshift(element);
        // }
        // index = keys.indexOf('Lat');
        // if (index > 0){
        //     let element = keys.splice(index,1);
        //     keys.unshift(element);
        // }

        // return({
        //     data: data,
        //     keys: keys,
        //     deviceId: deviceId,
        //     deviceName: deviceName,
        //     deviceTypeId: deviceTypeId,
        //     deviceTypeName: deviceTypeName,
        // });
    }

    // deviceIdLinkFormatter = (cell, row) => {
    //     let path = `/pages/device/${cell}`;
    //     return(
    //         <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
    //     )
    // }

    dataMomentValidation = (startMoment, endMoment) => {
        let reVal = false;
        if (startMoment && endMoment) {
            reVal = endMoment.isAfter(startMoment);
            if (!reVal) {
                toast.error('End date should be later than start date.');
                this.setState(prevState => {
                    return ({
                        startDateMoment: null,
                        endDateMoment: null,
                    })
                });
            }
        } else if (!startMoment && !endMoment) {
            reVal = true;
        }

        return reVal;
    }

    loadData = () => {
        if (!this.dataMomentValidation(this.state.startDateMoment, this.state.endDateMoment)) {
            return;
        }
        let params = {
            deviceid: this.props.deviceId
        };
        let latest = true;
        // if (this.state.currentFromTime || this.state.currentFromTime.length > 0 ){
        //     let date = new Date(this.state.currentFromTime).toISOString().split('.')[0];
        //     // alert(date);
        //     params = Object.assign(params, {starttime: date});
        //     latest = false;
        // }
        // if (this.state.currentToTime || this.state.currentToTime.length > 0 ){
        //     let date = new Date(this.state.currentToTime).toISOString().split('.')[0];
        //     // alert(date);
        //     params = Object.assign(params, {endtime: date});
        //     latest = false;
        // }

        if (this.state.startDateMoment) {
            let startHour = "00:00:00"
            let date = this.state.startDateMoment.format('YYYY-MM-DD') + "T" + startHour;
            params = { ...params, starttime: date };
            latest = false;
            // console.log("===startParams", params);
        }
        if (this.state.endDateMoment) {
            let endHour = "23:59:59"
            let date = this.state.endDateMoment.format('YYYY-MM-DD') + "T" + endHour;
            params = { ...params, endtime: date };
            latest = false;
            // console.log("===endParams", params);
        }

        // if(latest){
        //     params = Object.assign(params, {latest: 1});
        // }


        this.props.loadDivceMapData(params);
        // this.props.bindFilterAttribute('');
    }

    handleDateRangeChange = ({ startDate, endDate }) => {
        //  


        this.setState({
            startDateMoment: startDate,
            endDateMoment: endDate
        },
            this.loadData)
    }

    backOnClick = (e) => {
        window.location.href = '/#/pages/asset';
    }

    tbToolBar = props => {
        return (
            <Row>
                <Col md={4}>
                    <Button variant="primary" color="custom" onClick={() => history.back()}> {'< Back'} </Button>
                    {props.exportCSVBtn}
                </Col>
                {/* <Col md={2} style = {{textAlign: 'right'}}>
                <Label for="asset" style={{display: 'block', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                </Col> */}
                <Col md={5}>
                    {/* <Label for="asset" style={{display: 'inline', width: "100%"}} ><h5><b>Asset Class</b></h5></Label> */}
                    <DateRangePicker
                        isOutsideRange={() => false}
                        startDate={this.state.startDateMoment} // momentPropTypes.momentObj or null,
                        startDateId="device_detail_start_date" // PropTypes.string.isRequired,
                        endDate={this.state.endDateMoment} // momentPropTypes.momentObj or null,
                        endDateId="device_detail_end_date" // PropTypes.string.isRequired,
                        onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        showClearDates={true}
                        small={true}
                        block={true}
                    // disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
                    />
                </Col>
                {/* <Col md={6}></Col>
                <Col md={3}>
                    { props.searchField }
                    { props.components.searchPanel }
                </Col> */}
            </Row>
        )
    }

    dataTimeFormater = (cell, row) => {
        return (
            <span>
                {new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' })}
            </span>
        );
    }

    datatimeFormaterToronto = (cell, row) => {
        return (
            <span>
                {new Date(cell).toLocaleString('en-US', { timezone: 'America/Toronto' })}
            </span>
        )
    }

    normalFormater = (cell, row) => {
        return (
            <span>
                {cell}
            </span>
        );
    }

    renderDeviceList2 = () => {
        // let keys = ['id','name','timestamp'];
        let keys = ['id', 'name', 'timestamp', 'Lng', 'Lat', 'LocationRstTrigger', 'BatteryPercentage', 'BatteryVoltage', 'company', 'LastMessageTime'];
        let labels = { id: 'Message ID', name: 'Device', timestamp: 'Message Time', BatteryPercentage: 'Battery Percentage', Company: 'Company', LastMessageTime: 'Last Message Time' /*Lng:'Long',LocationRstTrigger:'Location RST Trigger', BatteryVoltage:'Battery Voltage'*/ };
        let renderData = [];
        if (this.props.data != undefined && this.props.data != null && Array.isArray(this.props.data) && this.props.data.length > 0) {
            // Object.keys(this.props.mapData[0].msg).map((key,i)=>{
            //     if(key !== 'Lng' && key !== 'Lat'){
            //         keys.push(key);
            //     }
            // });

            // Object.entries(this.props.mapData[0].msg).forEach(([attr, attrObj],mindex)=>{
            //     if (attr !== 'Lng' && attr !== 'Lat'){
            //         msgObj[attr] = attrObj.value;
            //         if (! keys.includes(attr) ){
            //             keys.push(attr);
            //             labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
            //         }
            //     }
            // });

            this.props.data.map((item, i) => {
                let dateTime = new Date(item.timestamp).toISOString().split('.')[0];
                // let dataitem = {id: item.msgid, name: item.devicename, timestamp: new Date(item.timestamp)};
                // let dataitem = {id: item.msgid, name: item.devicename, timestamp: new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' })};
                // let dataitem = {id: item.msgid, name: item.devicename, timestamp: new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'UTC' })};
                let dataitem = { id: item.msgid, name: item.devicename, timestamp: item.timestamp };
                // let msg = {msgid: item.msgid, datetime: dateTime};
                // keys.map((key, i) => {
                //     if (key != 'id' && key != 'name'){
                //         dataitem[key] = item.msg[key] ? item.msg[key].value : null; // item.msg[key] ? item.msg[key].value : null;
                //     }

                // });
                // let dataItem = Object.assign({}, msg);
                // --------------------------------------------------
                Object.entries(item.msg).forEach(([attr, attrObj], mindex) => {
                    // if (attr !== 'Lng' && attr !== 'Lat' && attrObj.display !== false){
                    if (attrObj.display !== false) {
                        dataitem[attr] = attrObj.value;
                        if (!keys.includes(attr)) {
                            keys.push(attr);
                            labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
                        } else {
                            if (!labels[attr]) {
                                labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
                            }
                        }
                    }
                });
                // --------------------------------------------------
                renderData.push(dataitem);
                // console.log("===this.props.data", this.props.data);
            });

        }

        // keys = keys.filter((item) => (Object.keys(labels).includes(item)));
        keys = keys.filter((item) => (Object.keys(labels).includes(item)));
        let keys2 = [];
        for (let i = 0; i < keys.length; i++) {
            if (
                keys[i] !== "Level1" &&
                keys[i] !== "Level2" &&
                keys[i] !== "Level3" &&
                keys[i] !== "LevelTotal" &&
                keys[i] !== "TotalVehicle" &&
                keys[i] !== "BatteryInVolts" &&
                keys[i] !== "BatteryPercentage"
            )
                keys2.push(keys[i]);
        };

        const options = {
            defaultSortName: 'timestamp',
            defaultSortOrder: 'desc',
            // pagination: true,
            exportCSVBtn: this.createCustomExportCSVButton,
            btnGroup: this.tbToolBar,
            noDataText: this.props.loading ? 'Loading...' : 'There is no data to display',
        };

        //   console.log("===DeviceDetailinfo.renderData", renderData);
        //   console.log("===keys2", keys2);
        return (

            <BootstrapTable responsive data={renderData} pagination={true} tableHeaderClass='header_white' options={options} csvFileName={'Device_Details.csv'} style={{ width: '100%', overflow: 'auto' }} exportCSV={true} keyField='id' search maxHeight='600' >
                {keys2.map((key, i) => {
                    if (keys2.includes("Vehicle")) {
                        return (
                            <TableHeaderColumn dataField={key} dataAlign="center" dataSort caretRender={sortCaret} dataFormat={key == 'timestamp' ? this.datatimeFormaterToronto : this.normalFormater} csvHeader={labels[key]} >{labels[key]}</TableHeaderColumn>
                        )
                    } else {
                        return (
                            <TableHeaderColumn dataField={key} dataAlign="center" dataSort caretRender={sortCaret} dataFormat={key == 'timestamp' ? this.dataTimeFormater : this.normalFormater} csvHeader={labels[key]} >{labels[key]}</TableHeaderColumn>
                        )
                    }
                })}
            </BootstrapTable>

        )

    }

    render() {

        // const options = {
        //     page: 2,  // which page you want to show as default
        //     sizePerPageList: [ {
        //       text: '5', value: 5
        //     }, {
        //       text: '10', value: 10
        //     }, {
        //       text: 'All', value: this.state.data.length
        //     } ], // you can change the dropdown list for size per page
        //     sizePerPage: 3,  // which size per page you want to locate as default
        //     pageStartIndex: 0, // where to start counting the pages
        //     paginationSize: 5,  // the pagination bar size.
        //     prePage: '<', // Previous page button text
        //     nextPage: '>', // Next page button text
        //     firstPage: '<<', // First page button text
        //     lastPage: '>>', // Last page button text
        //     // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
        //     // paginationPosition: 'top'  // default is bottom, top and both is all available
        //     // hideSizePerPage: true > You can hide the dropdown for sizePerPage
        //     // alwaysShowAllBtns: true // Always show next and previous button
        //     // withFirstAndLast: false > Hide the going to First and Last page button
        //   };

        return (
            <Col md={12}>
                {/* <Card>
                    <CardBody>
                        <BootstrapTable data={ this.state.data } tableHeaderClass = 'header_white' pagination = {false} options={options}   hover bordered={ false } height={500} >
                            <TableHeaderColumn dataField='msgid' isKey={true} dataAlign='center' >Message ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='datetime' dataSort dataAlign='center' >Time</TableHeaderColumn> 
                            <TableHeaderColumn dataField='devicetypeid' dataSort>Device Type ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='devicetypename' dataSort>Device Type Name</TableHeaderColumn>
                            <TableHeaderColumn dataField='timestamp'>Last Message Time</TableHeaderColumn>
                            <TableHeaderColumn dataField='data'>Original Data</TableHeaderColumn>
                            {this.state.keys.map((key, i) => {
                                return(
                                    <TableHeaderColumn dataField={key} dataAlign='center' >{key}</TableHeaderColumn>
                                )
                            })}
                        </BootstrapTable>
                    </CardBody>
                </Card> */}
                {this.renderDeviceList2()}
                {this.props.loading &&
                    <div style={{
                        position: 'Absolute',
                        height: '100%',
                        width: '100%',
                        top: '17%',
                        left: '0px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        opacity: '0.6'
                    }}
                    >
                        <Spinner
                            className="spinner"
                            as="span"
                            animation="border"
                            variant="primary"
                            // size="sm"
                            // role="status"
                            // aria-hidden="true"
                            style={{ display: 'inline-block', margin: 'auto' }}
                        />
                    </div>
                }
            </Col>
        );
    }
}

export default DeviceDetailInfo;