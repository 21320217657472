/* eslint-disable */
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    DASHBOARD_GET_LAYOUT_SUCCEED,
    DASHBOARD_GET_LAYOUT_FAILED, 
    DASHBOARD_GET_LAYOUT_LIST_SUCCEED,
    DASHBOARD_GET_LAYOUT_LIST_FAILED,
    DASHBOARD_GET_ASSET_MAP_SUCCEED,
    DASHBOARD_GET_ASSET_MAP_FAILED,
    DASHBOARD_GET_DEVICE_DATA_SUCCEED,
    DASHBOARD_GET_DEVICE_DATA_FAILED,
    DASHBOARD_UPDATE_LAYOUT_SUCCEED,
    DASHBOARD_UPDATE_LAYOUT_FAILED,
    DASHBOARD_GET_DEVICE_MSG_COUNT_SUCCEED,
    DASHBOARD_GET_DEVICE_MSG_COUNT_FAILED,
    DASHBOARD_GET_DEVICE_LIST_SUCCEED,
    DASHBOARD_GET_DEVICE_LIST_FAILED,
    DASHBOARD_GET_ASSETS_DATA_SUCCEED,
    DASHBOARD_GET_ASSETS_DATA_FAILED,
    DASHBOARD_GET_ASSETS_LIST_SUCCEED,
    DASHBOARD_GET_ASSETS_LIST_FAILED,
    DASHBOARD_GET_ASSETS_LIST_CLEAR,
    DASHBOARD_GET_THRESHOLD_SUCCEED,
    DASHBOARD_GET_THRESHOLD_FAILED,
    DASHBOARD_RECURSIVE_START,
    DASHBOARD_GET_RECURSIVE_DATA_SUCCEED,
    DASHBOARD_GET_RECURSIVE_DATA_FAILED,
    DASHBOARD_GET_ATTRIBUTE_CHART_SUCCEED,
    DASHBOARD_GET_ATTRIBUTE_CHART_FAILED,
    PUBSUB_UPDATE_DEVICE,
    USER_GET_FAILED,
} from '../../constants/actionTypes';

import {
    WEB_SERVICE_LOCAL,
    WEB_SERVICE_ROOT,
    WEB_SERVICE_ATTRIBUTES,
    WEB_SERVICE_DEVICETYPE,
    WEB_SERVICE_ASSET,
    WEB_SERVICE_ASSET_PURE,
    WEB_SERVICE_GET_ASSETS_DATA,
    WEB_SERVICE_GET_ASSETCLASS,
    WEB_SERVICE_GET_ASSETCLASS_ATTRIBUTES,
    WEB_SERVICE_GET_THEMES,
    WEB_SERVICE_LAYOUT,
    WEB_SERVICE_LAYOUT_LIST,
    WEB_SERVICE_LAYOUT_SET_DEFAULT,
    WEB_SERVICE_LAYOUT_DELET_WIDGET,
    WEB_SERVICE_MSG_COUNT,
    WEB_SERVICE_DEVICE,
    WEB_SERVICE_THRESHOLD,
    WEB_SERVICE_GET_RECURSIVE_ASSET_DATA,
    WEB_SERVICE_GET_GROUP_ASSETS_DATA,
} from '../../constants/appConstants';

// import {axiosGet} from '../../factories/utils';
import {axiosGet, axiosDelete, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
import { ConsoleIcon } from 'mdi-react';
//  
//  
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
});

// export function loadDashBoardLayout(userId){
//     const creteria = {
//         userid: userId,
//     }
//     let paramObj = {
//         url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_LAYOUT}`,
//         criteria: creteria,
//         logText: 'Get Dashboar Layout Url ',
//         sccessType: DASHBOARD_GET_LAYOUT_SUCCEED,
//         failedType: DASHBOARD_GET_LAYOUT_FAILED,
//         errorMessage: 'Failed to get dashboar layout.'
//     };

//     return(axiosGet(paramObj));
// }

export function loadDashBoardLayout(templateid){
     
    let creteria = null; 
    if (templateid!= undefined && templateid != null) {
        creteria = {
            templateid: templateid
        }
    }
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_LAYOUT}`,
        criteria: creteria,
        logText: 'Get Dashboar Layout Url ',
        sccessType: DASHBOARD_GET_LAYOUT_SUCCEED,
        failedType: DASHBOARD_GET_LAYOUT_FAILED,
        errorMessage: 'Failed to get dashboar layout.'
    };

    return(axiosGet(paramObj));
}

export function loadDashBoardLayoutList(){
    // const creteria = {
    //     userid: userId,
    // }
    const creteria = null;
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_LAYOUT_LIST}`,
        criteria: creteria,
        logText: 'Get Dashboar Layout List Url ',
        sccessType: DASHBOARD_GET_LAYOUT_LIST_SUCCEED,
        failedType: DASHBOARD_GET_LAYOUT_LIST_FAILED,
        errorMessage: 'Failed to get dashboar layout list.'
    };

    return(axiosGet(paramObj));
}

export function updateDashBoardLayout(layouts){
    console.log('dasboard Actions, ', layouts);
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    const request = axios({
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_LAYOUT}`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        data: layouts,
    });

    return (
        despatch => {
            request.then( response => {
                if (response.status == 200) {
                    console.log(response.data);
                    despatch({
                        type: DASHBOARD_UPDATE_LAYOUT_SUCCEED,
                        payload: response.data,
                    });
                    // despatch(loadDashBoardLayout());
                }else{
                    if (response.status == 406) {
                        toast.error('Component Name already exists');
                    }
                    despatch({
                        type: DASHBOARD_UPDATE_LAYOUT_FAILED,
                        error: "Failed to get the assets data",
                    });
                    console.error("Failed to get the assets data");
                }
            }).catch( error => {
                if (error.response && error.response.status === 406) {
                    toast.error('Component Name already exists');
                }
                despatch({
                    type: DASHBOARD_UPDATE_LAYOUT_FAILED,
                    error: error.message,
                });
                console.error(error.message);
            })
        }
    );
}

export function setDefaultLayout(templateid){
    let creteria = {
        templateid: templateid
    }
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_LAYOUT_SET_DEFAULT}`,
        criteria: creteria,
        logText: 'Set Default Dashboard Layout Url ',
        // sccessType: DASHBOARD_GET_LAYOUT_SUCCEED,
        // failedType: DASHBOARD_GET_LAYOUT_FAILED,
        callback: ()=>{return(loadDashBoardLayoutList());},
        errorMessage: 'Failed to set default dashboard layout.'
    };

    return(axiosGet(paramObj));
}
export function deleteDashBoardWidget(layoutid, templateid){
    const creteria = {
        layoutid: layoutid,
    }
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_LAYOUT_DELET_WIDGET}`,
        criteria: creteria,
        logText: 'Delete Dashboard Layout Widget Url ',
        // sccessType: DASHBOARD_GET_LAYOUT_LIST_SUCCEED,
        // failedType: DASHBOARD_GET_LAYOUT_LIST_FAILED,
        callback: ()=>{toast.success('Successfully deleted the selected widget.'); return(loadDashBoardLayout(templateid));},
        errorMessage: 'Failed to delete dashboard layout widget.'
    };

    return(axiosDelete(paramObj));
}

export function addDashBoardLayout (layout){
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    const request = axios({
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_LAYOUT}`,
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        data: layout
    });

    return (
        despatch => {
            request.then( response => {
                if (response.status == 200) {
                    // despatch({
                    //     type: DASHBOARD_UPDATE_LAYOUT_SUCCEED,
                    //     payload: response.data,
                    // });
                    despatch(loadDashBoardLayout());
                }else{
                    despatch({
                        type: DASHBOARD_UPDATE_LAYOUT_FAILED,
                        error: "Failed to get the assets data",
                    });
                    console.error("Failed to get the assets data");
                }
            }).catch( error => {
                despatch({
                    type: DASHBOARD_UPDATE_LAYOUT_FAILED,
                    error: error.message,
                });
                console.error(error.message);
            })
        }
    );
}

export function loadAssetMapData (assetclassid, dashname) {
    console.log('ASSET CLASS ID', assetclassid);
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    // let queryString = '';

    // if (criteria){
    //     queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    // }

    let queryString = `?assetclassid=${assetclassid}`;

    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETS_DATA}${queryString}`;
     

    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        }
    });

    return (
        despatch => {
            request.then( response => {
                if (response.status == 200) {
                    despatch({
                        type: DASHBOARD_GET_ASSET_MAP_SUCCEED,
                        payload: response.data,
                        name: dashname
                    });
                }else{
                    despatch({
                        type: DASHBOARD_GET_ASSET_MAP_FAILED,
                        error: "Failed to get the assets data",
                    });
                }
            }).catch( error => {
                despatch({
                    type: DASHBOARD_GET_ASSET_MAP_FAILED,
                    error: error.message,
                });
            })
        }
    );
}

export function loadDeviceData (devicetypeid, dashname){
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    let queryString = `?devicetype=${devicetypeid}`;
    // if (criteria){
    //     queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    // }
    // const urlStr = `http://13.88.246.7:8080/sigfox/Device${queryString}`;
    const urlStr = `${WEB_SERVICE_ROOT}Device${queryString}`;
    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        }
    });
   
    return (
        despatch => {
            request.then( response => {
                if (response.status == 200) {
                    despatch({
                        type: DASHBOARD_GET_DEVICE_DATA_SUCCEED,
                        payload: response.data,
                        name: dashname
                    });
                }else{
                    despatch({
                        type: DASHBOARD_GET_DEVICE_DATA_FAILED,
                        error: "Failed to get the device data",
                    });
                }
            }).catch( error => {
                despatch({
                    type: DASHBOARD_GET_DEVICE_DATA_FAILED,
                    error: error.message,
                });
            })
        }
    );
}

export function getMessageCount(deviceId){
    const creteria = {
        deviceid: deviceId,
    }
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_MSG_COUNT}`,
        criteria: creteria,
        logText: 'Get Dashboard message count Url ',
        sccessType: DASHBOARD_GET_DEVICE_MSG_COUNT_SUCCEED,
        failedType: DASHBOARD_GET_DEVICE_MSG_COUNT_FAILED,
        errorMessage: 'Failed to get dashboard message count data.'
    };
    return(axiosGet(paramObj));
}

export function getDevices(devicetype){
    const creteria = {
        devicetype: devicetype,
    }

    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_DEVICE}`,
        criteria: creteria,
        logText: 'Get Dashboard get device list Url ',
        sccessType: DASHBOARD_GET_DEVICE_LIST_SUCCEED,
        failedType: DASHBOARD_GET_DEVICE_LIST_FAILED,
        errorMessage: 'Failed to get dashboard device list.'
    };
    return(axiosGet(paramObj));
}

export function getAssetsData (criteria, dashname) {
    let queryString = '';
    // let LocalRoot = 'http://192.168.2.91:8080/';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    }  
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETS_DATA}?${queryString}`;
     

    const request = axios({
        url: urlStr,
        method: 'get'
    });

    return (
        despatch => {
            // despatch(loadingDataStart());
            request.then( response => {
                if (response.status == 200) {
                    //  
                    despatch({
                        type:DASHBOARD_GET_ASSETS_DATA_SUCCEED,
                        payload: response.data,
                        name: dashname
                    });
                }else{
                    //  
                    despatch({
                        type:DASHBOARD_GET_ASSETS_DATA_FAILED,
                        error: "Failed to get the assets data",
                    });
                }
            }).catch( error => {
                //  
                despatch({
                    type: DASHBOARD_GET_ASSETS_DATA_FAILED,
                    error: error.message,
                });
            })
        }
    );
}

export function getAttributeThreshold(assetid, attributeid, dashname){
    const criteria = {
        assetid: assetid,
        attributeid: attributeid,
    }
    
    // let paramObj = {
    //     url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_THRESHOLD}`,
    //     criteria: creteria,
    //     logText: 'Get Attribute Threshold Url ',
    //     sccessType: DASHBOARD_GET_THRESHOLD_SUCCEED,
    //     failedType: DASHBOARD_GET_THRESHOLD_FAILED,
    //     errorMessage: 'Failed to get Attribute Threshold.'
    // };
    // return(axiosGet(paramObj));

    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    }  
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_THRESHOLD}?${queryString}`;
     

    const request = axios({
        url: urlStr,
        method: 'get'
    });

    return (
        despatch => {
            // despatch(loadingDataStart());
            request.then( response => {
                if (response.status == 200) {
                    //  
                    despatch({
                        type:DASHBOARD_GET_THRESHOLD_SUCCEED,
                        payload: response.data,
                        name: dashname
                    });
                }else{
                    //  
                    despatch({
                        type:DASHBOARD_GET_THRESHOLD_FAILED,
                        error: "Failed to get Attribute Threshold.",
                    });
                }
            }).catch( error => {
                //  
                despatch({
                    type: DASHBOARD_GET_THRESHOLD_FAILED,
                    error: error.message,
                });
            })
        }
    );

}

export function updatePubSubDevice (data){
    return(
        despatch => {
            despatch({
                type: PUBSUB_UPDATE_DEVICE,
                payload: data,
            });
        }
    )
}

export function getAssetList (criteria) {
    // const creteria = {
    //     assetclassid: assetclassid,
    // }
    console.log('getAssetList, ', criteria)
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_ASSET}`,
        criteria: criteria,
        logText: 'Get Dashboard Asset List Url ',
        sccessType: DASHBOARD_GET_ASSETS_LIST_SUCCEED,
        failedType: DASHBOARD_GET_ASSETS_LIST_FAILED,
        errorMessage: 'Failed to get dashboard asset list.'
    };

    return(axiosGet(paramObj));
}
export function clearAssetList () {
    return dispatch => {
        dispatch({
          type: DASHBOARD_GET_ASSETS_LIST_CLEAR,
          message: "clear dashboard asset list",
        });
    }
}

export function getAssetRecursiveData(assetid, dashname){
    let criteria = {assetid: assetid};
    // let paramObj = {
    //     url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_RECURSIVE_ASSET_DATA}`,
    //     criteria: criteria,
    //     logText: 'Get Asset Recursive data url : ',
    //     startType: DASHBOARD_RECURSIVE_START,
    //     sccessType: DASHBOARD_GET_RECURSIVE_DATA_SUCCEED,
    //     failedType: DASHBOARD_GET_RECURSIVE_DATA_FAILED,
    //     errorMessage: 'Failed to get asset recursive data.',
    //     // successMessage: 'Successfully get asset recursive data.',

    // };
    // return(axiosGet(paramObj)); 
    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    }  
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_RECURSIVE_ASSET_DATA}?${queryString}`;
     

    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        }
    });

    return (
        despatch => {
            // despatch(loadingDataStart());
            request.then( response => {
                if (response.status == 200) {
                    despatch({
                        type: DASHBOARD_GET_RECURSIVE_DATA_SUCCEED,
                        payload: response.data,
                        name: dashname
                    });
                }else{
                    despatch({
                        type: DASHBOARD_GET_RECURSIVE_DATA_FAILED,
                        error: "Failed to get Dashboard Asset Recursive data.",
                    });
                }
            }).catch( error => {
                despatch({
                    type: DASHBOARD_GET_RECURSIVE_DATA_FAILED,
                    error: error.message,
                });
            })
        }
    );

}

// WEB_SERVICE_GET_GROUP_ASSETS_DATA
export function getAttributeChart(criteria, dashname){
    let queryString = '';
    // if (criteria){
    //     queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    // }  
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_GROUP_ASSETS_DATA}?${queryString}`;
     
    const request = axios({
        url: urlStr,
        method: 'post',
        data: criteria,
    });

    return (
        despatch => {
            // despatch(loadingDataStart());
            request.then( response => {
                if (response.status == 200) {
                    despatch({
                        type: DASHBOARD_GET_ATTRIBUTE_CHART_SUCCEED,
                        payload: response.data,
                        name: dashname
                    });
                }else{
                    despatch({
                        type: DASHBOARD_GET_ATTRIBUTE_CHART_FAILED,
                        error: "Failed to get the assets data",
                    });
                }
            }).catch( error => {
                despatch({
                    type: DASHBOARD_GET_ATTRIBUTE_CHART_FAILED,
                    error: error.message,
                });
            })
        }
    );
}