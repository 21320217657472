/* eslint-disable */
import axios from 'axios';
import {
    API_ADDR,
    API_ROOT_URL,
    GET_ALL_MODULES,
    GET_ALL_MODULES_STARTED,
    GET_ALL_MODULES_SUCCEED,
    GET_ALL_MODULES_FAILED,
    SUCCESS_MSG,
    GET_ALL_GROUPS_STARTED,
    GET_ALL_GROUPS_SUCCEED,
    GET_ALL_GROUPS_FAILED,
    USER_GET_FAILED,
} from '../../../constants/actionTypes';
import {WEB_SERVICE_ROOT} from '../../../constants/appConstants';

import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../../factories/utils';
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
});

function fetchModulesStart() {
    return {
        type: GET_ALL_MODULES_STARTED
    }
}

// -----------------------------------------------------
// function fetchModulesSucceed(res) {
//     if (res.data.message === SUCCESS_MSG) {
//         return {
//             type: GET_ALL_MODULES_SUCCEED,
//             payload: res
//         }
//     }
//     else {
//         return {
//             type: GET_ALL_MODULES_FAILED,
//             payload: res
//         }
//     }
// }

// function fetchModulesFailed(err) {
//     return {
//         type: GET_ALL_MODULES_FAILED,
//         payload: err
//     }
// }

// export function fetchAllModules() {
//     const url = `${API_ADDR}${API_ROOT_URL}${GET_ALL_MODULES}`;

//     const promise = axios({
//         url: url,
//         method: 'get',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         params: {
//             token: sessionStorage.getItem('authToken'),
//         }
//     });

//     return function (dispatch) {
//         dispatch(fetchModulesStart());
//         promise.then(
//             res => {
//                 dispatch(fetchModulesSucceed(res));
//             }
//         ).catch(
//             err => {
//                 dispatch(fetchModulesFailed(err));
//             }
//         );
//     }
// }

// ------------------------------------------------------------

function loadDeviceSucceed(response){
    let data = [];
    if (response.data){
        response.data.forEach((item,index)=>{
            let ele = {
                id: item.devicetypeid,
                name: item.devicetypename,
                user_role: 0
            };
            data.push(ele);
        });
    }
    const action = {
        type: GET_ALL_MODULES_SUCCEED,
        payload: data
    };

    return action;
}

function loadDeviceFailed(error){
    const action = {
        type: GET_ALL_MODULES_FAILED,
        payload: error.message,
        
    };

    return action;
}

export function fetchAllModules(){
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    // const urlStr = 'http://13.88.246.7:8080/sigfox/Devicetypes';
    const urlStr = `${WEB_SERVICE_ROOT}Devicetypes`;
     
    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ( (dispatch) => {
            dispatch(fetchModulesStart());
            request.then( 
                (response) => {  dispatch(loadDeviceSucceed(response))}
            ).catch( 
                (error) => {dispatch(loadDeviceFailed(error))}
            );
        }
    );
}

function fetchGroupsStart() {
    return {
        type: GET_ALL_GROUPS_STARTED
    }
}

function loadGroupsSucceed(response){
    let data = [];
    if (response.data){
        response.data.forEach((item,index)=>{
            let ele = {
                id: item.groupid,
                name: item.groupname,
                user_group: false
            };
            data.push(ele);
        });
    }
    const action = {
        type: GET_ALL_GROUPS_SUCCEED,
        payload: data
    };

    return action;
}

function loadGroupsFailed(error){
    const action = {
        type: GET_ALL_GROUPS_FAILED,
        payload: error.message,
        
    };

    return action;
}

export function fetchAllGroups(){
    const urlStr = 'http://192.168.2.7:8080/sigfox/Groups';
     
    const request = axios({
        url: urlStr,
        method: 'get',
    });

    return ( (dispatch) => {
            dispatch(fetchGroupsStart());
            request.then( 
                (response) => {  dispatch(loadGroupsSucceed(response))}
            ).catch( 
                (error) => {dispatch(loadGroupsFailed(error))}
            );
        }
    );
}