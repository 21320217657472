/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from "react-toastify";
import { getCurrentUser } from '../../../factories/auth';

class ClientInventoryAddDevice extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            file: null,
            showModal: false
        }
    }
    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({ showModal: nextProps.showModal })
    }
    onFileChangeHandler = (e) => {
        this.setState({ file: e.target.files[0] });
    }
    onDeviceSubmit = () => {
        var formdata = new FormData();
        formdata.append("file", this.state.file);
        formdata.append("companyId", this.props.selectedRow.companyId);
        formdata.append("resellerId", this.props.selectedRow.id);
        this.props.uploadInventory(formdata).then(res => {
            if (res.data === "success") {
                toast.success("Upload Complete")
                this.props.getFullSIMList({ "companyId": this.props.selectedRow.companyId, "resellerId": this.props.selectedRow.id, "customerId": null, "fullInventory": "false" });
            } else {
                toast.error(res.data);
            }
        });
        this.props.onDeviceClose();
    }
    validateForm = () => {
        if (this.state.file != null) {
            return true;
        }
    }
    render() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.props.onDeviceClose}>Upload Bulk Devices</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>File Uploader <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <div style={{ float: "left" }}>
                                    <p style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> Only accept .xlsx files</p>
                                    <input type="file" name="file" accept=".xlsx" onChange={this.onFileChangeHandler} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>

                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onDeviceSubmit} disabled={!this.validateForm}>
                            Submit
                        </Button>}
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onDeviceSubmit} disabled={!this.validateForm}>
                            Submit
                        </Button>}
                    <Button outline color='secondary' type="reset" onClick={this.props.onDeviceClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default ClientInventoryAddDevice;