/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Card, CardTitle, CardBody, Col, Row, Button, Container, Input, Label} from 'reactstrap';
// import {connect } from 'react-redux';
// import {bindActionCreators } from 'redux';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import {Modal, ModalHeader , ModalBody,ModalFooter} from 'reactstrap';
import {Trans} from "react-i18next";
import { toast } from "react-toastify";
import { 
    loadDevice, 
    // loadDeviceInfo 
} from '../../../redux/actions/mapActions';

class DeviceSignUpBoard extends Component {
    constructor(props){
        super(props);
        this.state={};
    }

    componentDidMount(){
        this.props.loadDevice();
    }

    manufactureOnChangeHandler = () => {}

    onInputChange = (event) => {
         
        // this.setState({
        //    [event.target.name]: event.target.value
        // });

        let key = event.target.name;
        let value = event.target.value;
        this.setState(prevState => {
            // prevState.companyObj[key] = value;
            return ({
                // companyObj: prevState.companyObj,
                [key]: value
            })
        });
    };

    onRenewalChange = () => {}

    onSubmit = () => {}

    onClose = () => {
        this.props.history.push(`/pages/inventory/`);
    }

    render() {
        let labalStyle = {textAlign: 'right'} ;
        // let title = 'New Company';
        // if (this.props.companyObj && this.props.companyObj.companyCode){
        //     title = 'Edit Company';
        // }
        return(
            <Col md={12}>
            <Card>
                <CardBody> 
                    {/* <CardTitle><h4><b>{title}</b></h4></CardTitle> */}
                    <br/>
                        <Col md={12}>
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Device Type</span>
                                </Col>   
                             <Col md={8}>
                                <Input 
                                    type="select" 
                                    id="deviceType" 
                                    name="deviceType" 
                                    width="60%" 
                                    value = {this.state.deviceType} 
                                    onChange={this.manufactureOnChangeHandler}
                                >
                                    {this.props.devicetypes && 
                                        this.props.devicetypes.map((type, i) => (
                                            <option key={type.id} value={type.id}>{type.name}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Identifier (hex)</span>
                                </Col>
                                <Col md={8}>
                                    <Input
                                            name='identifer'
                                            placeholder='Identifer'
                                            onChange={this.onInputChange}
                                            className="form-control"
                                            value={this.state.identifer}
                                            // disabled={this.props.userName !== ''}
                                            // valid = {this.validateCompanyName(this.state.companyObj.companyName)}
                                        /> 
                                   
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>Name</span>
                                </Col>
                                <Col md={8}>
                                    {/* <span>User Name</span> */}
                                    <Input
                                        name='name'
                                        placeholder='Device Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        value={this.state.name}
                                        // disabled={this.props.userName !== ''}
                                        // valid = {this.validateCompanyName(this.state.companyObj.companyName)}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>PAC</span>
                                </Col>
                                <Col md={8}>
                                    {/* <span><Trans i18nKey="fullName">Full Name</Trans></span> */}
                                    <Input
                                        name='pac'
                                        placeholder='PAC'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        value={this.state.pac}
                                        // valid={this.validatePhoneNumber(this.state.companyObj.phoneNum)}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col md={2} style={labalStyle} >
                                    <span>End Product Certificate</span>
                                </Col>
                                <Col md={8}>
                                    {/* <span>Email</span> */}
                                    <Input
                                        name='certificate'
                                        placeholder='Certificate'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        value={this.state.certificate}
                                        // valid = {this.validateEmail(this.state.companyObj.email)}
                                    />
                                </Col>
                            </Row>
                            <br/>
                        </Col>
                        <br/>
                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Latitude</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='lat'
                                    placeholder='Lat (-90 to +90)'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.lat}
                                    // value={this.state.companyObj.addressline1 ? this.state.companyObj.addressline1 : ''}
                                />
                            </Col>
                            <Col md={2} style={labalStyle} >
                                <span>Longitude</span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='lng'
                                    placeholder='Lng (-180 to +180)'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.lng}
                                    // value={this.state.companyObj.addressline2 ? this.state.companyObj.addressline2 : ''}
                                />
                            </Col>
                        </Row>
                        <br/>

                        <Row>
                            <Col md={2} style={labalStyle} >
                                <span>Subscription automatic renewal</span>
                            </Col>
                            {/* <Col md={2} style={labalStyle} >
                               
                            </Col> */}
                            <Col md={10}>
                                <Input
                                    type='checkbox'
                                    name='renewal'
                                    id='renewal'
                                    checked={this.state.renewal}
                                    onChange={this.onRenewalChange}
                                    // className="form-control"
                                >
                                </Input>
                                {/* <Label for="renewal" check>Subsciption automatic renewal</Label> */}
                            </Col>
                        </Row>
                        <div style={{textAlign:'right'}}>
                        <Button color='custom' type="button" onClick={this.onSubmit} >
                            Submit
                        </Button>
                        <Button outline color='secondary' type="reset" onClick={this.onClose}>
                            Close
                        </Button>
                        </div>
                        <hr/>
                        </CardBody>
                </Card>
            </Col>
            
        )
    }

}

// export default withRouter(DeviceSignUpBoard);
function mapStateToProps(state) {
     
     
    const devicetypes =  state.mapboard.types;
    // const currentDeviceList = state.asset.currentDeviceList;
    // const props = state.attributemgmt;
    const props = {devicetypes}
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadDevice, 
        // loadDeviceInfo
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceSignUpBoard));