/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {loadDevice, loadDeviceInfo, loadDivceMapData, clearDivceMapData} from '../../../redux/actions/mapActions'
import DeviceDetailInfo from './DeviceDetailInfo'
import moment from 'moment';
// import AssetList from './AssetList'

class DeviceDetailBoard extends Component{
    constructor(props){
        super(props);
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var endHour = "23:59:59";
        today = yyyy + '-' + mm + '-' + dd  +"T"+ endHour ;
        
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() -1);
        var mm1 = String(yesterday.getMonth() + 1).padStart(2, '0'); //January is 0!
        var dd1 = String(yesterday.getDate()).padStart(2, '0');
        var yyyy1 = yesterday.getFullYear();
        var startHour = "00:00:00";

        yesterday = yyyy1 + '-' + mm1 + '-' + dd1  +"T"+ startHour ;
        this.state={
            currentFromTime: moment(yesterday),
            currentToTime: moment(today),
        }
    }

    componentDidMount(){
        this.loadData();
        // this.props.loadDevice();
        // this.props.loadDeviceInfo();
         
         
    }

    componentWillUnmount(){
         
        if (this.interval){
            clearInterval(this.interval);
        }
    }

    // -------------------------------------------------------------

    onFromChange = (e) => {
        // let currentDate =  new Date(e.target.value).toISOString().split('.')[0];
        let currentDate =  e.target.value;
        this.setState({currentFromTime: currentDate});
    }
    onToChange = (e) => {
        // let currentDate =  new Date(e.target.value).toISOString().split('.')[0];
        let currentDate =  e.target.value;
        this.setState({currentToTime: currentDate});
    }
    onRefreshClick = (e) => {
        this.loadData();
    }

    loadData = () =>{
            let params = {deviceid:this.props.deviceId};
            // alert(this.props.currentFromTime);
            let latest = true;
            if (this.state.currentFromTime || this.state.currentFromTime.length > 0 ){
                let date = new Date(this.state.currentFromTime)
                let stringDate = date.toISOString().split('.')[0];
                params = Object.assign(params, {starttime: stringDate});
                latest = false;
            }
            if (this.state.currentToTime || this.state.currentToTime.length > 0 ){
                let date = new Date(this.state.currentToTime);
                let stringDate = date.toISOString().split('.')[0];
                params = Object.assign(params, {endtime: stringDate});
                latest = false;
            }
            // if(latest){
            //     params = Object.assign(params, {latest: 1});
            // }
             
             
            this.props.loadDivceMapData(params);
            if (this.interval){
                clearInterval(this.interval);
            }
            // this.interval = setInterval(() => { 
            // this.props.bindFilterAttribute('');
    }

    renderSearchCard(){
        return(
            <Col md={12}>
                <Card>
                    <CardBody>
                        <Form md={12}>
                            <Row form>
                                <Col md={3}>
                                    <FormGroup row >
                                        {/* <Label for="startTime" md={2} >From</Label> */}
                                        <Col md={12}>
                                            <Label for="startTime"  style={{display: 'inline'}} >From</Label>
                                            <Input type="date" id="startTime" name="startTime" width="70%"  style={{display: 'inline'}} value={this.state.currentFromTime} onChange={this.onFromChange}>
                                            </Input>     
                                        </Col>                   
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup row >
                                        {/* <Label for="endTime" md={2} >To</Label> */}
                                        <Col md={12}>
                                            <Label for="endTime"  style={{display: 'inline', width: "100%"}} >To</Label>
                                            <Input type="date" id="endTime" name="endTime" width="100%" value={this.state.currentToTime} onChange={this.onToChange}>
                                            </Input>  
                                        </Col>                      
                                    </FormGroup>
                                </Col>

                                <Col md={3} style={{ textAlign: 'right', justifyContent: 'center',alignItems: 'center' }}>
                                    <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                                    <Button variant="primary" color="custom" type="button" onClick={this.onRefreshClick}>
                                        Filter
                                    </Button>
                                </Col>
                                
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        );
    }
    render(){
        return (
            <div style={{width: '100%'}}>
                {/* {this.renderSearchCard()} */}
                <Col md={12}>
                    <Card>
                    <CardBody>
                        <DeviceDetailInfo 
                            deviceId = {this.props.deviceId}
                            data = {this.props.mapData} 
                            loadDivceMapData = {this.props.loadDivceMapData}
                            clearDivceMapData = {this.props.clearDivceMapData}
                            loading = {this.props.loading}
                        />
                    </CardBody>
                    </Card>
                </Col>
            </div>
        );
    }
}

// export default AssetBoard;

function mapStateToProps(state) {
     
     
    const types = state.mapboard.types;
    const groups = state.mapboard.groups;
    const status =state.mapboard.status;
    const attr = state.mapboard.attr;
    const mapData = state.mapboard.mapData;
    const error= state.mapboard.error;
    const message= state.mapboard.message;
    const loading= state.mapboard.loading;
   
    const props = {types, groups, mapData, attr, status, message, error, loading}
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDevice, loadDeviceInfo, loadDivceMapData, clearDivceMapData}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailBoard);

