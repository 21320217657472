/* eslint-disable */
import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {

    let userName = "Guest"
    let sessionUser = sessionStorage.getItem('currentUser');

    if (this.props.currentUser && this.props.currentUser.userName && this.props.currentUser.userName.length > 0) {
      userName = this.props.currentUser.userName;
    } else if (sessionUser && sessionUser.length > 0) {
      userName = sessionUser;
    }
    // console.log("===currentUser", this.props.currentUser);
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          {/* <img className="topbar__avatar-img" src={Ava} alt="avatar" /> */}
          <p className="topbar__avatar-name">{userName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu" onClick={this.toggle}>
            {/* <TopbarMenuLink title="Page one" icon="list" path="/pages/one" />
            <TopbarMenuLink title="Page two" icon="inbox" path="/pages/two" /> */}
            <TopbarMenuLink title="User Profile" icon="user" path="/pages/userprofile" />
            {/* //<TopbarMenuLink title="Setting" icon="cog" path="/pages/setting" /> */}
            <div className="topbar__menu-divider" />
            {/* <TopbarMenuLink title="Log Out" icon="exit" path="/" /> */}
            <TopbarMenuLink title="Log Out" icon="exit" path="/log_out" />
          </div>
        </Collapse>
      </div>
    );
  }
}

const mapStateToProps = state => (state.user);

export default connect(mapStateToProps, null)(TopbarProfile);
