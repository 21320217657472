/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { Col, Row, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup} from 'reactstrap';
import Select from 'react-select';

import { connect } from "react-redux";
import {
    addAsset
} from '../../../../../redux/actions/surveilenceActions'
import { select } from 'd3-selection';
import { TurnedInIcon } from 'mdi-react';

const AssetFilterModal = ({addAsset, assetList, modalOpen, toggleModal, assetClassList}) => {
    const [FilteredAssets, setFilteredAssets] = useState([]);
    const [AssetToShow, setAssetToShow] = useState(null);
    const [DisableShow, setDisableShow] = useState(true);

    const handleAssetClassChange = selectedClass => {
        console.log(selectedClass)
        setFilteredAssets(selectedClass.assets)
    }

    const handleAssetChange = asset => {
        console.log(asset)
        setAssetToShow(asset.assetid)
        setDisableShow(false)
    }

    const handleSubmit = () => {
        addAsset(AssetToShow)
        toggleModal();
    }

    return (
        <Modal isOpen={modalOpen}>
            <ModalHeader><b>Add Asset</b></ModalHeader>
            <ModalBody>
                <Col md={12}>
                    <Label for="assetClass" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                    
                    <Select
                        options={assetClassList}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        onChange={handleAssetClassChange}
                    />
                </Col>
                <Col md={12}>
                    <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset</b></h4></Label>

                    <Select
                        options={FilteredAssets}
                        getOptionLabel={({ assetname }) => assetname}
                        getOptionValue={({ assetid }) => assetid}
                        onChange={handleAssetChange}
                    />
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" disabled={DisableShow} onClick={handleSubmit}>Show</Button>
                <Button color="custom" onClick={toggleModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};
const mapToStateProps= (state)=>({
    assetClassList: state.asset.assetClassList,
})
export default connect(mapToStateProps, {addAsset})(AssetFilterModal);
