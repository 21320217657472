/* eslint-disable */
import React, { Component } from 'react';
import { Alert, UncontrolledAlert  } from 'reactstrap';

class AlertMessage extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            messagevisible: false,
            // message: 'test',
            // type: 'success',
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     // alert("yew timeout getDerivedStateFromProps");
    //     alert('get');
    //     if (props.messagevisible !== state.messagevisible){
    //         alert('test');
    //         return({
    //             messagevisible: props.messagevisible,
    //             // message: props.message,
    //             // type: props.messagetype,
    //         });
    //     }       
    // } 

    componentWillReceiveProps(nextProps) {
        // alert('msg props');
        if (nextProps.messagevisible == true && nextProps.error == 0){       
            window.setTimeout(()=>{
                this.toggle()
              },2000)
        }
        // if (nextProps.messagevisible !== this.state.messagevisible){
        //     this.setState({
        //         messagevisible: nextProps.messagevisible,
        //         message: nextProps.message,
        //         type: nextProps.messagetype,
        //     });
        // }
    }

    componentWillMount( nextProps,  nextState){        
    }

    componentDidMount(){      
    }

    componentDidUpdate(prevProps, prevState){
        // alert('did update');
        // if (this.state.messagevisible == true){
        //     // alert("yes timeout");
        //     window.setTimeout(()=>{
        //        this.toggle();
        //     },2000)
        // }
    }

    toggle = () => {        
        // this.setState({
        //     messagevisible: false
        // });
        this.props.toggle();
    }

    render(){
        
        return(
            <div>
            {/* <Alert color = {this.state.type} isOpen={this.state.messagevisible} toggle={this.toggle} >{this.state.message}</Alert> */}
            <Alert color = {this.props.messagetype} isOpen={this.props.messagevisible} toggle={this.toggle} >{this.props.message}</Alert>
            {/* <Alert color = "danger" isOpen={this.props.errorvisible} toggle={this.props.toggleErrorAlert}>{this.props.message}</Alert> */}
                {/* <UncontrolledAlert color="custom" >
                    {this.props.message}
                    testestestets
                </UncontrolledAlert> */}
            </div>
        );
    }
}

export default AlertMessage;