/* eslint-disable */
import React, { Component } from 'react';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class InventoryPurchaseSupplierList extends Component {
    constructor(props){
        super(props);
        this.state = {}
    }

    IdLinkFormatter = (cell, row) => {
        const onLinkClick = (e) => {
            // alert('okay-e');
            this.props.handleSupplierClicked(row);
        } 
        return(
            <div>
                {/* <Button color="link">{cell}</Button> */}
                <a href='javascript:;' onClick={onLinkClick} ><span>{cell}</span></a>
            </div>
        )
    }

    renerSupplierList=()=>{
        return(
            <BootstrapTable 
                data={ this.props.supplierList }  
                tableHeaderClass = 'header_white' 
                pagination = {false} 
                  
                 
                bordered={ false } 
                search 
                // insertRow 
                ref='table' 
            >
                  {/* <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='5%' >UPC</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='5%' hidden dataFormat={this.IdLinkFormatter} >ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='name' dataAlign="center" dataSort width='10%'dataFormat={this.IdLinkFormatter} >Supplier</TableHeaderColumn> 
                  <TableHeaderColumn dataField='email' dataAlign="center" dataSort width='15%' >Email</TableHeaderColumn>
                  <TableHeaderColumn dataField='contactNumber' dataAlign="center" dataSort  width='10%' >Contact</TableHeaderColumn>                     
                  <TableHeaderColumn dataField='contactPerson' dataAlign="center" dataSort width='10%' >Contact Person</TableHeaderColumn>  
                  <TableHeaderColumn dataField='createdDate' dataAlign="center" dataSort width='10%'>Create At</TableHeaderColumn>  
                  <TableHeaderColumn dataField='createdBy' dataAlign="center" dataSort width='10%' >Create By</TableHeaderColumn>  
                  <TableHeaderColumn dataField='updateDate' dataAlign="center" dataSort width='10%' >Update At</TableHeaderColumn>  
                  <TableHeaderColumn dataField='updateBy' dataAlign="center" dataSort width='10%' >Update By</TableHeaderColumn>  
                  {/* <TableHeaderColumn dataField='paymentInfo' dataAlign="center" >Payment</TableHeaderColumn> */}
            </BootstrapTable>
        )
    }

    render() {
        return(
            <Card>
                <div className='card-pginside' style={{padding: '0px'}}>
                    {this.renerSupplierList()}
                </div>
            </Card>
        );
    }
}

export default InventoryPurchaseSupplierList;