/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import withRouter from 'react-router/withRouter';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import DeviceDetailsMain from './components/DeviceDetailsMain';

const DeviceMasterDetails = props => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Device {props.match.params.id}</h3>
      </Col>
    </Row>
    <Row>
      <DeviceDetailsMain deviceId = {props.match.params.id}/>
    </Row>
  </Container>
);

export default withRouter(DeviceMasterDetails);
