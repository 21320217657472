/* eslint-disable */
import React, { Component } from 'react';
import {connect } from 'react-redux';
import {bindActionCreators } from 'redux';
import {Trans} from "react-i18next";
import {TabContent, TabPane, Nav, NavItem, NavLink,Card, CardBody, Col, Row, Button, Container, Input} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import {removeUser, getUserDevicetypeList, upsertUserDevicetypeList} from '../../../redux/actions/userAction';
import {groupBy} from '../../../factories/utils';

class CompanyAssetClassForm extends Component {
    constructor(props){
        super(props);
        this.state={

        }
    }

    componentDidMount(){

    }

    // static getDerivedStateFromProps(nextProps, prevState){}

    componentWillReceiveProps(nextProps) {
         
        if (nextProps.companyAssetClassList && nextProps.companyAssetClassList !== this.state.rawAssetClassList) {
             
            // let devicetypeMap = [];
            let assetClassGroup = groupBy(nextProps.companyAssetClassList, assetClass => assetClass.assetclassid);
           
            this.setState({
                rawCompanyObj: nextProps.companyObj,
                rawAssetClassList: nextProps.companyAssetClassList,
                assetClassGroup: assetClassGroup,
                companyAssetClasses: JSON.parse(JSON.stringify(nextProps.companyAssetClassList)),
            })
        }
    }
// --------------------------------------------------------------------------

onSelectChange = (e, row) => {
    e.preventDefault();
    let value = Number(e.target.value);
    let assetClassId = row.assetclassid
        // row.access = value;
        //  
    this.setState((preState)=>{
            //  
            //  

            // if (preState.assetClassGroup && this.props.companyObj) {
            //     let foundItem = preState.assetClassGroup.get(assetClassId);
            //     let foundCom = foundItem[0];
            //     if (value === 1) {
            //         // let foundCom = foundItem.find((value, index) => (value.companycode == this.props.companyObj.companyCode));
            //         // if (! foundCom){
            //         //     let newCom = {
            //         //         companycode: this.props.companyObj.companyCode,
            //         //         companyname: this.props.companyObj.companyName,
            //         //         assetclassid: row.assetclassid,
            //         //         assetclassname: row.assetclassname,
            //         //         description: null,
            //         //         iconid: null,
            //         //         iconname: null,
            //         //         iconsvg: null,
            //         //         createdby: null,
            //         //         assetclassattrmetadata: null,
            //         //         devicetypes: null,
            //         //         assets: null,
            //         //     }
            //         //     foundItem.push(newCom);
            //         // }
            //         if (foundCom) {
            //             foundCom.companycode = this.props.companyObj.companyCode;
            //             foundCom.companyname = this.props.companyObj.companyName;
            //         }
            //     } else if (value === 2) {
            //         // foundItem = foundItem.filter((value, index) => (value.companycode != this.props.companyObj.companyCode));
            //         // preState.assetClassGroup.set(deviceTypeId, foundItem);
            //         if (foundCom) {
            //             foundCom.companycode = "";
            //             foundCom.companyname = "";
            //         }
            //     }
            //     return({
            //         assetClassGroup: preState.assetClassGroup,
            //     });
            // }        
            
            if (preState.companyAssetClasses && this.props.companyObj) {
                let foundCom = preState.companyAssetClasses.find((item, index) => (item.assetclassid == assetClassId));
                if (foundCom) {
                    if (value === 1) {
                        foundCom.companycode = this.props.companyObj.companyCode;
                        foundCom.companyname = this.props.companyObj.companyName;
                    } 
                    else if (value === 2) {
                        foundCom.companycode = "";
                        foundCom.companyname = "";
                    }
                }

                return({
                    companyAssetClasses: preState.companyAssetClasses,
                });
            }
        }
    );
}

onSubmit = () => {
    // let companyAssetClassArr = [];
    // if (this.state.assetClassGroup){
    //     this.state.assetClassGroup.forEach((val, key, map)=>{
    //         companyAssetClassArr = [...companyAssetClassArr, ...val];
    //     });
    // }
    // let companyDeviiceTypeObj = companyAssetClassArr.filter((item,index)=>(item.companycode === this.props.companyObj.companyCode));


     
    // this.props.updateCompanyAssetClass(this.props.companyObj.companyCode, companyDeviiceTypeObj);
    this.props.toggle();
}

onClose = () => {
    this.props.toggle();
}

cellAuthSelection(cell, row, enumObject, index) {
    return(
        // <select
        //     name={`${index},user_role`}
        //     // value={this.state.data[index]['user_role']}
        //     onChange={(e) => this.onSelectChange(e, row)}
        //     value={cell}
        //     disabled = {true}
        // >
        //     <option value={1}>Assigned</option>
        //     <option value={2}>Not Assigned</option>
        // </select>
        <div>
        {
            row.disabled || cell == 1 ? 
                cell == 1 ? <span> Assigned </span> : <span> Not Assigned </span> 
            :
            <select
                name={`${index},user_role`}
                // value={this.state.data[index]['user_role']}
                onChange={(e) => this.onSelectChange(e, row)}
                value={cell}
                // disabled = {true}
            >
                <option value={1}>Assigned</option>
                <option value={2}>Not Assigned</option>
            </select>
        }
        </div>

    );
}

    render(){

        const options = {}
        let assetclassMap = [];
        // if(this.props.companyObj && this.state.assetClassGroup){
        //     this.state.assetClassGroup.forEach((val, key, map)=>{
        //         let assetClass = val[0];
        //         let companyCode = '';
        //         let companyName = '';
        //         let delimiter = '';
        //         let access = 2;
        //         let disabled = true;
        //         val.forEach((item, index) => {
        //             companyName +=  delimiter + item.companyname
        //             companyCode +=  delimiter + item.companycode
        //             delimiter = ', ';
        //             if (item.companycode == this.props.companyObj.companyCode){
        //                 access = 1;
        //             }
        //             if (item.assets && item.assets.length > 0){
        //                 disabled = false;
        //             }
        //         });

        //         let mapItem = {
        //             assetclassid : assetClass.assetclassid,
        //             assetclassname: assetClass.assetclassname,
        //             companycode: companyCode,
        //             companyname: companyName,
        //             access: access,
        //             disabled: disabled,
        //         };
        //         assetclassMap.push(mapItem);
        //     });
        // }

        if (this.props.companyObj && this.state.companyAssetClasses) {
            this.state.companyAssetClasses.forEach((item, index) => {
                let access = 2;
                let disabled = true;
                if (item.companycode == this.props.companyObj.companyCode){
                    access = 1;
                }
                if (item.assets && item.assets.length > 0){
                    disabled = false;
                }
                let mapItem = {
                    assetclassid : item.assetclassid,
                    assetclassname: item.assetclassname,
                    companycode: item.companycode,
                    companyname: item.companyname,
                    access: access,
                    disabled: disabled,
                };
                assetclassMap.push(mapItem);
            });
        }


        return (
            <div>
            <BootstrapTable data={assetclassMap}    options={options} height={400}>
                <TableHeaderColumn dataField='assetclassname'>
                    {/* <Trans i18nKey="system">System</Trans> */}
                    Asset Class Name
                </TableHeaderColumn>
                <TableHeaderColumn dataField='assetclassid' isKey={true}>
                    <Trans i18nKey="id">Asset Class ID</Trans>
                </TableHeaderColumn>
                <TableHeaderColumn dataField='companyname'>
                    Current Company Name
                </TableHeaderColumn>
                <TableHeaderColumn
                    // dataField='user_role'
                    dataField='access'
                    dataFormat={this.cellAuthSelection.bind(this)}
                >
                    <Trans i18nKey="action">Assigned</Trans>
                </TableHeaderColumn>
                {/* <TableHeaderColumn
                    dataField='user_group'
                    dataFormat={this.cellGroupSelection.bind(this)}
                >
                    <Trans i18nKey="action">Group</Trans>
                </TableHeaderColumn> */}
            </BootstrapTable>
             <br/>
             <hr/>
             
             <Button color='custom' type="button" onClick={this.onSubmit} disabled = {!this.state.assetClassGroup || this.state.assetClassGroup.length ===0} >
                 Submit
             </Button>
             <Button outline color='secondary' type="reset" onClick={this.onClose}>
                 Close
             </Button>
             </div>
        );
    }
}

export default CompanyAssetClassForm