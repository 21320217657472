/* eslint-disable */
import React, { Component } from 'react';
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Collapse } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { EditTextButton } from '../../../../shared/components/Button/IconButton';

import { Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';


import {
    getFullInventoryList,
    uploadFileSims,
    SetDeviceType
} from '../../../../redux/actions/inventoryActions';
import { formatDefaultLocale } from 'd3-format';

class InventoryInactiveBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showItemModal: false,
            simId: 0,
            simReseller: 0,
            simPrice: 0,
            simItemDescription: "",
            simItemName: "",

            showDeviceModal: false,
            file: null,
            carrierId: 0,
            itemId: 0,
            companyCode: 0
        };
    }

    componentDidMount() {
    }

    onItemOpen = () => {
        this.setState({
            showItemModal: true,
            simId: 0,
            simReseller: 0,
            simItemDescription: "",
            simPrice: "",
            simItemName: ""
        })
    }
    onEditItemOpen = (row) => {
        this.setState({
            showItemModal: true,
            simId: row.itemId,
            companyCode: this.props.currentUserObj.companyCode,
            simItemDescription: row.itemDescription,
            simPrice: row.unitPrice,
            simItemName: row.itemName
        })
    }
    onItemClose = () => {
        this.setState({
            showItemModal: false
        })
    }
    onDeviceClose = () => {
        this.setState({
            showDeviceModal: false
        });
    };
    onDeviceOpen = () => {

        this.setState({
            showDeviceModal: true,
            file: null,
            carrierId: this.props.carrierList[0].carrierId,
            itemId: 0,
            companyCode: this.props.currentUserObj.companyCode
        });
    };
    onFileChangeHandler = (e) => {
        this.setState({ file: e.target.files[0] });
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onInputCarrierChange = (event) => {
        if (event.target.value >= 0) {
            this.setState({
                itemCode: this.props.carrierPlanList[event.target.value].name,
                itemDescription: this.props.carrierPlanList[event.target.value].description
            });
        }
        else {
            this.setState({
                itemCode: "",
                itemDescription: ""
            });
        }
    }
    validateForm = (Management) => {
        if (Management == "device") {
            if (this.state.file != null && this.state.carrierId > 0 && this.state.itemId > 0 && this.state.companyCode != null && this.state.companyCode != "") {
                return true;
            }
        }
        else if (Management == "item") {
            if (this.state.simItemDescription != "" && this.state.simItemName != "" && !isNaN(this.state.simPrice)) {
                if (parseFloat(this.state.simPrice) >= 0) {
                    return true;
                }
            }
        }
        return false;

    }
    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 365) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }

    dataFormatter = (cell, row) => {
        if (cell < 1024) {
            return (`${cell} MB`);
        }
        if (cell >= 1024) {
            var gb = (cell / 1024).toFixed(2)
            return (`${gb} GB`);
        }
    }
    cellEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.onEditServiceOpen(row) }} />
            </div>
        );
    };
    cellItemEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.onEditItemOpen(row) }} />
            </div>
        );
    };

    statusFormatter = (cell, row) => {
        if (cell == 1) {
            return <span style={{ color: "green" }}>Active</span>
        }
        if (cell == 2) {
            return <span style={{ color: "red" }}>Suspend</span>
        }
    }


    customFormatter = (cell, row) => {
        if (cell != null) {
            return <span style={{ color: "green" }}>TRUE</span>;
        }
        return;
    }

    onDeviceSubmit = () => {
        var formdata = new FormData();
        formdata.append("file", this.state.file);
        formdata.append("carrierId", this.state.carrierId);
        formdata.append("itemId", this.state.itemId);
        formdata.append("companyCode", this.props.currentUserObj.companyCode);
        this.props.uploadFileSims(formdata, "company");
        this.onDeviceClose();
    }
    onItemSubmit = () => {
        var data = {};
        if (this.state.simId > 0) {
            data = {
                "id": this.state.simId,
                "companyCode": this.props.currentUserObj.companyCode,
                "itemName": this.state.simItemName,
                "itemDescription": this.state.simItemDescription,
                "unitPrice": this.state.simPrice
            }
        }
        else {
            data = {
                "companyCode": this.props.currentUserObj.companyCode,
                "itemName": this.state.simItemName,
                "itemDescription": this.state.simItemDescription,
                "unitPrice": this.state.simPrice
            }
        }
        this.props.SetDeviceType(data);
        this.onItemClose();
    }

    render() {
        return (
            <div>
                {this.renderItemModal()}
                {this.renderDeviceModal()}

                <h3>SIM Card Types</h3>
                <div>
                    <InsertButton
                        btnText='Add Device Types'
                        btnContextual='btn-success'
                        btnGlyphicon='glyphicon-edit'
                        onClick={this.onItemOpen}
                    >
                        Add New SIM Card Type
                    </InsertButton>
                </div>
                <BootstrapTable
                    data={this.props.resellerDeviceItems}
                    tableHeaderClass='header_white'
                    striped
                    hover
                    bordered={false}
                    height='100%'
                    bodyStyle={{ overflow: 'overlay' }}
                    containerStyle={{ fontSize: "1vw" }}
                >
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort >SIM Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Description' }} dataSort>SIM Description</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' dataFormat={this.priceFormatter.bind(this)} dataAlign='center' filter={{ type: 'NumberFilter', delay: 1000 }} dataSort>Price</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='Operations'
                        dataAlign="center"
                        dataFormat={this.cellItemEditButton.bind(this)}
                    >
                        Operations
                    </TableHeaderColumn>
                </BootstrapTable>

                {/*<h1>SIM Cards</h1>
                        <div>
                            <InsertButton
                                            btnText='Upload Devices'
                                            btnContextual='btn-success'
                                            btnGlyphicon='glyphicon-edit'
                                            onClick={this.onDeviceOpen}
                                        >
                                            Upload Devices
                            </InsertButton>
                        </div>
                        <BootstrapTable
                            data={this.props.inactiveInventoryList}
                            tableHeaderClass='header_white'
                            striped
                            hover

                            bordered={false}
                            height='100%'
                            bodyStyle={{ overflow: 'overlay' }}
                            containerStyle={{ fontSize: "1vw" }}

                            pagination
                        >
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} >SIM Name</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='identifierType' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Type' }}>SIM Identifier Type</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Value' }}>SIM Identifier Value </TableHeaderColumn>
                        </BootstrapTable>*/}
            </div>
        )


    }
    renderItemModal() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showItemModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onItemClose}>{(this.state.simId != 0) ? `Customize` : `Add New Device Type`}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Carrier <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrier'
                                    id='carrier'
                                    placeholder='Carrier'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    disabled={true}
                                    value={this.state.simItemDescription}
                                >
                                    {["None Selected", "SIM CARD"].map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simItemName'
                                    id='simItemName'
                                    placeholder='Item Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.simItemName}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Description <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                {(this.state.simId != 0) ?
                                    <Input
                                        type='select'
                                        name='simItemDescription'
                                        id='simItemDescription'
                                        placeholder='Item Type'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        disabled={true}
                                        value={this.state.simItemDescription}
                                    >
                                        {["None Selected", "SIM CARD"].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </Input>
                                    :

                                    <Input
                                        type='select'
                                        name='simItemDescription'
                                        id='simItemDescription'
                                        placeholder='Item Type'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        disabled={false}
                                        defaultValue={this.state.simItemDescription}
                                    >
                                        {["None Selected", "SIM CARD"].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </Input>
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Price <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simPrice'
                                    id='simPrice'
                                    placeholder='Price'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.simPrice}
                                />
                            </Col>
                        </Row>
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="reset" onClick={this.onItemSubmit} disabled={!this.validateForm("item")}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onItemClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
    renderDeviceModal() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showDeviceModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onDeviceClose}>Upload Bulk Devices</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>File Uploader <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <div style={{ float: "left" }}>
                                    <p style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> Only accept .csv files</p>
                                    <input type="file" name="file" accept=".csv" onChange={this.onFileChangeHandler} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Carriers <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrierId'
                                    id='carrierId'
                                    placeholder='Carrier'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    disabled={false}
                                    value={this.state.carrierId}
                                >
                                    {this.props.carrierList && this.props.carrierList.map((carrier, index) => (
                                        <option key={index} value={carrier.carrierId}>{carrier.carrierName}</option>
                                    ))}
                                </Input>
                            </Col>

                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Sim Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='itemId'
                                    id='itemId'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    disabled={false}
                                >
                                    <option key={"none"} value={-1}>None Selected</option>
                                    {(this.props.resellerDeviceItems != null) ? this.props.resellerDeviceItems.map((item, index) => (
                                        <option key={index} value={item.itemId}>{item.itemName}</option>
                                    )) : <option></option>}
                                </Input>
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="button" onClick={this.onDeviceSubmit} disabled={!this.validateForm("device")}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onDeviceClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}


function mapStateToProps(state) {
    let {
        resellerDeviceItems,
        inactiveInventoryList
    } = state.inventory;
    let props = {
        resellerDeviceItems,
        inactiveInventoryList
    }
    return props;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFullInventoryList,
        uploadFileSims,
        SetDeviceType
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryInactiveBoard);