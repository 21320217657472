/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import {Card, CardBody, Col, Row, Button, Container, Collapse, Table} from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import {ChevronLeftIcon} from 'mdi-react';
import {chunk} from '../../../factories/utils';
import {BackButton} from '../../../shared/components/Button/IconButton'; 

class DataList extends Component {
    constructor(props){
        super(props);
        this.state = {}
        // this.eventSource = new EventSource("http://13.88.246.7:8080/sigfox-farm/sendRedisMessageTest/get");
    }

    componentDidMount(){
         
         
        // this.eventSource.addEventListener("devicemessage", (e)=> { 
    }

    static getDerivedStateFromProps(nextProps, prevState){
        // 
        if (nextProps.assetObj && nextProps.assetObj !== prevState.originasset){

            return({
                asset: nextProps.assetObj,
                originasset: nextProps.assetObj,
            });

        }

        if (nextProps.updatedData){
            // 
            let dataObj = JSON.parse(nextProps.updatedData);
            if (dataObj) {
                // this.setState(prevState => {
                    if (prevState.asset && prevState.asset.device) {
                        for(let i = 0 ; i < prevState.asset.device.length; i++){
                            let deviceObj = prevState.asset.device[i];
                            if (dataObj.deviceid == deviceObj.deviceid) {
                                if (deviceObj.msgs && dataObj.msgs && dataObj.msgs.length > 0){
                                    let msgArr = deviceObj.msgs;
                                    let newMsgObj = dataObj.msgs[dataObj.msgs.length-1];
                                    let foundIndex = msgArr.findIndex((msg, mindex) => (msg.msgid == newMsgObj.msgId));
                                    if (foundIndex < 0 ){
                                        msgArr.unshift(newMsgObj);
                                        return ({
                                            asset: prevState.asset,
                                        });
                                    }
                                }
                                break;
                            }
                        }
            
                    }
                // });
            }
        }
    }

    // componentWillReceiveProps(nextProps){
    //     // alert('test');
    //      
    //     if (nextProps.assetObj && nextProps.assetObj !== this.props.assetObj){
    //         //  
    //         this.setState({
    //             asset: nextProps.assetObj,
    //             originasset: nextProps.assetObj,
    //         });
    //     }
    //     if (nextProps.updatedData && nextProps.updatedData !== this.props.updatedData){
    //         this.updateAssetValue(nextProps.updatedData)
    //     }
    // }
// ---------------------------------------------------------------------------------
    updateAssetValue(pubData) {
         
        // let dataObj = JSON.parse(JSON.stringify(pubData));
        let dataObj = JSON.parse(pubData);
        //  
        //  
        if (dataObj) {
            this.setState(prevState => {
                if (prevState.asset && prevState.asset.device) {
                    for(let i = 0 ; i < prevState.asset.device.length; i++){
                        let deviceObj = prevState.asset.device[i];
                        if (dataObj.deviceid == deviceObj.deviceid) {
                            if (deviceObj.msgs && dataObj.msgs && dataObj.msgs.length > 0){
                                let msgArr = deviceObj.msgs;
                                let newMsgObj = dataObj.msgs[dataObj.msgs.length-1];
                                let foundIndex = msgArr.findIndex((msg, mindex) => (msg.msgid == newMsgObj.msgId));
                                if (foundIndex < 0 ){
                                    msgArr.unshift(newMsgObj);
                                    return ({
                                        asset: prevState.asset,
                                    });
                                }
                            }
                            break;
                        }
                    }
        
                }
            });
            
            // if (dataObj.msgs && dataObj.msgs.length > 0) {
            //     let msgObj = dataObj.msgs[dataObj.msgs.length-1];
            //      
            //     Object.entries(msgObj.msg).forEach(([attr, attrObj],mindex)=>{
            //          
            //          
            //         // if (attrObj.id == this.props.parameter.attrid){
            //         if (attrObj.attributename == this.props.parameter.attrname){
            //             // this.setState({
            //             //     dataValue: attrObj.value,
            //             // });
            //             this.setValue(attrObj.value, this.state.threshold);
            //         }
            //     });
            // }
        }

    }
// ---------------------------------------------------------------------------------
    renderDeviceList = (assetObj) => {
        return (
            <Fragment>
                {this.props.loading && 
                    <div>
                        <span>Loading data...</span>
                    </div>
                }
                {assetObj && assetObj.device && assetObj.device.map((device, index) => (
                    <div key={index}>
                        {this.renderDeviceTable(device)}
                    </div>
                ))}
            </Fragment>
        )
    }

    renderDeviceTable = (deviceObj) => {
        let data = [];        
        if (deviceObj && deviceObj.msgs && deviceObj.msgs.length > 0) {
            let msgObj = deviceObj.msgs[0];
            if (msgObj.msg) {
                Object.entries(msgObj.msg).forEach(([key, value], index) => {
                    let attr = {
                        key: key,
                        id: value.id,
                        name: value.displayname,
                        value: value.value,
                        display: value.display,
                    }
                    data.push(attr);
                });
            }
        }
        let path = `/pages/device/${deviceObj.deviceid}`;
        return (
            <div>
                {/* <h3>{deviceObj.deviceid}</h3> */}
                <h3><Link to={path}>{deviceObj.deviceid}</Link></h3>
                <Row>
                    <Col md={12}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Attribute Name</th>
                                    <th>Attribute Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((attr, index) => {
                                        if (attr.display == undefined || attr.display == null || attr.display === true){
                                            return (
                                                <tr  key={index}>                                       
                                                    <td>{attr.id}</td>
                                                    <td>{attr.name}</td>
                                                    <td>{attr.value}</td>
                                                </tr>
                                            )
                                        }else{
                                            return null
                                        }
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
    renderBackButton = () => {
        return(
            <Row>
                <Col md={2}>
                    <BackButton 
                        onClick={this.props.backOnClick} 
                        label="Back"
                        size={30}
                        color = 'black'
                    />  
                </Col>
            </Row>
        );
    }
    render(){
        return (
            <Card style={{backgroundColor:'white', paddingTop:'5px'}}>
                {this.renderBackButton()}
                <CardBody>
                    
                    {this.renderDeviceList(this.state.asset)}
                    {this.props.loading && 
                        <div style={{position: 'Absolute ', height: '100%', width: '100%', top: '0px', left: '0px', display: 'flex', textAlign: 'center', alignItems: 'center', backgroundColor: '#000',opacity: 0.3}}>
                            <Spinner 
                                className="spinner"
                                as="span"
                                animation="border"
                                variant="primary"
                                // size="sm"
                                // role="status"
                                // aria-hidden="true"
                                style={{display: 'inline-block', margin: 'auto' }}
                            /> 
                        </div>
                    } 
                </CardBody>
            </Card>
        );
    }
}

export default withRouter(DataList);
