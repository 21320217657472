/* eslint-disable */
import React, { Fragment } from "react";

import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

const StepTemplate = ({
  classes,
  canGoBack,
  canGoForward,
  onBack,
  onNext,
  onSkip,
  onCancel,
  text,
  children
}) => (
  <Fragment>
    <Typography>{text}</Typography>
    {/* {children} */}

    <div className={classes.actionsContainer}>
        <div>
            {children}
        </div>
      <div style= {{textAlign: 'center'}}> 
        {/* {children} */}
        {canGoBack &&
        <Button
          disabled={!canGoBack}
          onClick={onBack}
          className={classes.button}
        >
          Back
          <ChevronUpIcon color = { !canGoBack ?'#d3d3d3': '#000000'}/>
        </Button>}
        <Button
            variant="outlined"
            color="custom"
        //   disabled={!canGoBack}
          onClick={canGoForward ? onSkip : onCancel}
          className={classes.button}
        >
          {/* Cancel */}
          {canGoForward ? "Next" : "Cancel"}
          {/* <ChevronUpIcon color = { !canGoBack ?'#d3d3d3': '#000000'}/> */}
          {canGoForward && <ChevronDownIcon color="#0000ff" />}
        </Button>
        <Button
          variant="contained"
          color="custom"
          onClick={onNext}
          className={classes.button}
        >
          {canGoForward ? "Save" : "Complete"}
          {canGoForward && <ChevronDownIcon color="#ffffff" />}
        </Button>
      </div>
    </div>
  </Fragment>
);

export default StepTemplate;

// class StepTemplate extends React.Component{

//     constructor(props){
//         super(props);
//     }

//     render(){
//         return(
//             <Fragment>
//             <Typography>{text}</Typography>
        
//             <div className={classes.actionsContainer}>
//               <div>
//                 {children}
        
//                 <Button
//                   disabled={!this.props.canGoBack}
//                   onClick={onBack}
//                   className={this.props.classes.button}
//                 >
//                   Back
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="custom"
//                   onClick={onNext}
//                   className={classes.button}
//                 >
//                   {this.props.canGoBack ? "Next" : "Finish"}
//                 </Button>
//               </div>
//             </div>
//           </Fragment>
//         );
//     }

// }

// export default StepTemplate


