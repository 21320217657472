/* eslint-disable */
import React, { Component } from 'react';
import { CardBody, Card, Button } from 'reactstrap';
import NotificationModal from './NotificationModal';
import NotificationEventList from './NotificationEventList';
import { toast } from 'react-toastify';
class NotificationPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            actionInfo: null
        }
    }

    componentDidMount() {

    }
    onOpen = () => {
        this.setState({
            showModal: true,
            actionInfo: null
        })
    }
    onOpenAction = (id) => {
        this.props.getTelecomActionsById(id).then((res) => {
            if (res.data != null) {
                if (res.data.notification != null && res.data.message === 'success') {
                    this.setState({
                        showModal: true,
                        actionInfo: res.data.notification
                    })
                } else {
                    toast.error(res.data.message);
                }
            }
        })
    }
    onClose = () => {
        this.setState({
            showModal: false,
            actionInfo: null
        })
    }
    onSubmit = (obj) => {
        this.props.setTelecomActions(obj).then(res => {
            if (res.data === "success" && res.status === 200) {
                toast.success(res.data)
            } else {
                toast.error(res.data)

            }
            this.props.getTelecomActions();
            this.onClose();
        })
    }
    render() {
        return (
            <Card>
                <CardBody>
                    <NotificationModal
                        userObj={this.props.userObj}
                        showModal={this.state.showModal}
                        actionInfo={this.state.actionInfo}
                        activeSIMList={this.props.activeSIMList}
                        resellerList={this.props.resellerList}
                        ServicePlanList={this.props.ServicePlanList}
                        onSubmit={this.onSubmit}
                        onClose={this.onClose}
                    />
                    <Button color='custom' type="button" onClick={this.onOpen} >
                        New Notification
                    </Button>
                    <NotificationEventList
                        onOpenAction={this.onOpenAction}
                        actionList={this.props.actionList}
                    />
                </CardBody>
            </Card>
        );
    }
}
export default NotificationPanel;