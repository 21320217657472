/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import AssetList from '../../Asset/components/AssetList';

class CompanyDeviceActive extends Component {
    render() {
        return (
            <Card>
                <CardBody  /*style={{padding:'30px 0px'}}*/>
                <Button variant="primary" color="custom" /*style={{marginLeft:'30px'}}*/ onClick={()=>{this.props.onBuyClick()}}>Buy New</Button>
                <AssetList deviceList={this.props.currentDeviceList} />
                </CardBody>
            </Card>
        )
    }
}

export default CompanyDeviceActive;