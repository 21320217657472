/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {uploadAttributeImage, loadAssets, postAssetAttribute} from '../../../../redux/actions/assetActions';
import {UploadImageAttribute} from '../../../Asset/components/ClassDisplayDetail'
import {Row} from 'reactstrap';
import { AttributeInfo, EditableAttribute, EditableText, multiSelectStyles } from '../../../Asset/components/ClassDisplayDetail';

const CameraCustomAttributes = ({loadAssets, postAssetAttribute, uploadAttributeImage, ...props})=> {
   const [CameraAttributeMetas, setCameraAttributeMetas] = useState(props.attrmetas.attributeMetas);

   const updateAssetAttribute = (data) => {
       
      postAssetAttribute(data).then(
            res => {loadAssets();}
      );
    }

   const updateImageAttribute = (assetid, attributeid, file) => {
        if (!this.isFileImage(file)) {
          toast.error('Input File is not a image');
          return;
        }
       //  else{
       //    toast.error('Input File is not a image');
       //     return;
       //  }

        uploadAttributeImage(assetid, attributeid, file).then(
           res => {
              if (res.status === 200) {
                loadAssets();
                toast.success('Successly Upload the image');
              }else{
                // nothing
                toast.error('Error ocurred during ploading the image');
              }
           }
        ).catch(
           err => {
              // nothing
              toast.error('Error ocurred during ploading the image');
           }
        );
     }


 
      return (

        <Row>
          {CameraAttributeMetas.map(attrmeta => {
            let attribute = props.attributes.find(({ attmetaid }) => attmetaid == attrmeta.attributemetaid)
            //  
            //  
            let type = type => {
              switch (type) {
                // Boolean and list need special case in EditableAttribute component
                //  WIP
                //      currently unimplemented
  
                // case 'boolean':
                //   return 'bool'
                // case 'list':
                //   return 'list'
                case 'image':
                  return 'image'
                case 'date':
                  return 'date'
                case 'num':
                  return 'number'
               //  case 'boolean':
               //    return 'checkbox'
                case 'string':
                default:
                  return 'text'
              }
            }
            if (attrmeta.type !== 'image') {
            //   console.log('no', attrmeta.type)
              return (
                <EditableAttribute key={attrmeta.attributemetaid}
                  
                  name={attrmeta.name + ': ' + attrmeta.type} defaultValue={attribute && attribute.value}
                  type={type(attrmeta.type)}
                  onSave={newVal => {
                    let id = attribute ? attribute.id : 0
                    // console.log(this.props.id)
                    // console.log(id)
                    // console.log(newVal)
                    if (attrmeta.type === 'num') {
                      newVal = Number(newVal);
                    }else if(attrmeta.type === 'boolean') {
                       newVal = newVal === 'true' ? true : false;
                    }
                  //   let data = attribute
                  //     ? {  
                  //       value: newVal
                  //     }
                  //     : {
                  //       assetid: 1,
                  //       attmetaid: attrmeta.attributemetaid,
                  //       attmetaname: attrmeta.name,
                  //       value: newVal,
                  //     };
                    let param =  {
                        assetid: props.cameraid,
                        attmetaid: attrmeta.attributemetaid,
                        value: newVal
                      };
                    // this.props.updateAssetAttribute({
                    //   id: this.props.id,
                    //   data: {
                    //     id,
                    //     data
                    //   }
                    // })
                    updateAssetAttribute(param);
                  }}
                />
              )}
            else{
              return (
                <UploadImageAttribute
                  name={attrmeta.name + ': ' + attrmeta.type} 
                  defaultValue={attribute && attribute.value}
                  type={type(attrmeta.type)}
                  onSave={(file) => {
                    let assetid = props.cameraid;
                    // let attributeid = attribute ? attribute.id : 0;
                    let attributeid = attrmeta.attributemetaid;
                    updateImageAttribute(assetid, attributeid, file);
                  }}
                />
              )}
            })}
         </Row> 
        // {this.props.attributes.map(attribute => (
        //       <EditableAttribute key={attribute.id} name={attribute.attmetaname} defaultValue={attribute.value}
        //         deleteAttribute={() => this.toggleDeleteModal(attribute.id)}
        //         onSave={newVal => {
        //           this.props.updateAssetAttribute({
        //             id: this.props.id,
        //             data: {
        //               id: attribute.id,
        //               value: newVal
        //             }
        //           })
        //         }}
        //       />
        //     ))}
      )
  }


  export default connect (null, {uploadAttributeImage, loadAssets, postAssetAttribute})(CameraCustomAttributes)