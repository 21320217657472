/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import AttributeSetCard from './AttributeSetCard' 
import AttributeThemeList from './AttributeThemeList'
import {loadDevice, loadDeviceAttr } from '../../../redux/actions/mapActions'
import {loadDeviceTypes, loadAssets, getAttributes, upsertAttributes, deleteAttributes, closeAlertOff} from '../../../redux/actions/attributeMgmtAction';
import {loadAssetClass, getAssetClassAttribute} from '../../../redux/actions/mapActions';
import {getThemeList, getAssetclassTheme, upsertAssetclassTheme, removeAssetclassTheme} from '../../../redux/actions/attributeThemeAction';
import {itemFinder, dropdownOnChangeHandler} from '../../../factories/utils';

import Notifications from '../../AlertComponent/components/Notification';

class AttributeBoard extends Component{
    constructor(props){
        super(props);
        this.state = {
            isNew: null,
            showEdieCard: false,
            modalVisible: false,
            currentThemeId: 1,
            currentThemeName:'theme1', //5cf828390499f50b995ae851
            currentDeviceTypeId:'5cf828390499f50b995ae851',
            currentMessageTypeId: 1,
            currentMessageTypeName: 'Message Type I', 
            currentAssetClassId: null,
            currentAssetClassName: '',
            themes: [
                        {
                            id:1, 
                            name: 'theme1',
                            attris: [
                                {display: true, name: 'Speed', type:0, setup:[{low:0,up:10,value:'#B53737'},{low:10,up:20,value:'#B53737'}]},
                                {display: false, name: 'Temp', type:0, setup:[]},
                                {display: true, name: 'Huli', type:1, setup:[{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#B53737'}]},
                                {display: true, name: 'Attr1', type:0, setup:[{low:0,up:10,value:'#B53737'}]},
                                {display: false, name: 'Attr2', type:0, setup:[]},
                            ] 
                        }, 
                        {
                            id:2, 
                            name: 'theme2',
                            attris: [
                                {display: true, name: 'Speed', type:0, setup:[{low:0,up:10,value:'#B53737'},{low:10,up:20,value:'#B53737'}]},
                                {display: false, name: 'Temp', type:0, setup:[]},
                                {display: false, name: 'Huli', type:1, setup:[{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#B53737'}]},
                                {display: true, name: 'Attr1', type:0, setup:[{low:0,up:10,value:'#B53737'}]},
                                {display: false, name: 'Attr2', type:0, setup:[]},
                            ]
                        }, 
                        {
                            id:3, 
                            name: 'theme3',
                            attris: [
                                {display: true, name: 'Speed', type:0, setup:[{low:0,up:10,value:'#B53737'},{low:10,up:20,value:'#B53737'}]},
                                {display: false, name: 'Temp', type:0, setup:[]},
                                {display: true, name: 'Huli', type:1, setup:[{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#B53737'}]},
                                {display: true, name: 'Attr1', type:0, setup:[{low:0,up:10,value:'#B53737'}]},
                                {display: true, name: 'Attr2', type:0, setup:[]},
                            ]
                        }
                    ],
            assetClassAttriSet: [
                {display: false, name: 'Speed', type:0, setup:[]},
                {display: false, name: 'Temp', type:0, setup:[]},
                {display: false, name: 'Huli', type:1, setup:[]},
                {display: false, name: 'Attr1', type:0, setup:[]},
                {display: false, name: 'Attr2', type:0, setup:[]},
            ],
            currentAttris:[],
        };

        this.currentInput = '';
        this.currentDeviceType = '5cf828390499f50b995ae851';
        
        this.toggle = this.toggle.bind(this);
        this.handleInputOnchang = this.handleInputOnchang.bind(this);
        this.handleInputClick= this.handleInputClick.bind(this);
        this.reenderEditCard = this.reenderEditCard.bind(this);
        this.handleThemeDropdownChange = this.handleThemeDropdownChange.bind(this);
        this.handleDeviceTypeDropdownChange = this.handleDeviceTypeDropdownChange.bind(this);
    }

    componentDidMount(){
        // this.props.loadDevice();
        // this.props.loadAssets({devicetypeid: this.state.currentDeviceType});
        this.props.loadAssetClass();
         
    }

    componentWillReceiveProps(nextProps){
        // load asset class attribute, ??? 
        // load theme data ???
        if(nextProps.assetClass && nextProps.assetClass != this.state.assetClass){
            // this.props.getAssetClassAttribute({assetclassid: value.id});
            if (nextProps.assetClass.length > 0 ){
                
                 
                this.setState({
                    currentAssetClassId: nextProps.assetClass[0].assetclassid,
                    currentAssetClassName: nextProps.assetClass[0].assetclassname,
                    assetClass: nextProps.assetClass,
                });
                this.props.getAssetClassAttribute({assetclassid: nextProps.assetClass[0].assetclassid});
                this.props.getThemeList(nextProps.assetClass[0].assetclassid);
            }
        }
        if (nextProps.assetclassTheme) {
             
             
            // let newattris = JSON.parse(JSON.stringify(nextProps.assetclassTheme.attris));
            this.setState({
                currentAttris: nextProps.assetclassTheme.attris,
            });
        }
        if (nextProps.upserted){
            this.setState({
                showEdieCard: false,
            });
        }
        if (nextProps.assetClassAttr){
            let assetClassAttriSet = [];
            // nextProps.assetClassAttr && Object.keys(nextProps.assetClassAttr).forEach((typeKey, tindex)=>{
            //     nextProps.assetClassAttr[typeKey] && Object.keys(nextProps.assetClassAttr[typeKey]).forEach((attributeKey, aindex)=>{
            //         let attribute =  nextProps.assetClassAttr[typeKey][attributeKey];
            //         if (attribute){
            //             let attrItem = {
            //                 display: false,
            //                 attributeid: attribute.attributeid,
            //                 attributename: attribute.attributename,
            //                 name: attribute.displayname,
            //                 type: attribute.attributetype ==="Boolean" ? 1 : 0,
            //                 setup: [],
            //             };
            //             assetClassAttriSet.push(attrItem);
            //         }
            //     });
            // });
            // -------------------------------------------------------------------------
            nextProps.assetClassAttr && nextProps.assetClassAttr.map((assetclass, aindex) => {
                assetclass.atrributes && assetclass.atrributes.map((attr, atindex) => {
                    let attrItem = {
                        display: false,
                        id: attr.attributeid,
                        name: attr.attributename,
                        displayname: attr.displayname,
                        type: attr.attributetype === "Boolean" ? 1 : 0,
                        setup: [],
                    };
                    assetClassAttriSet.push(attrItem);
                });
            });
            // -------------------------------------------------------------------------
            this.setState({
                assetClassAttriSet:assetClassAttriSet,
            });
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState){}
    // ---------------------------------------------------------------------------
    handleThemeDropdownChange(e){
        let selectedId = e.target.value;
        let selectedIndex = e.target.selectedIndex;
        let selectedValue = e.target.options[selectedIndex].text;
        this.setState(
            {
                currentThemeId : selectedId,
                currentThemeName: selectedValue,
                showEdieCard: true,
                isNew: false,
            }
        )
    }

    handleAssetClassChange = (e) => {
        let value = dropdownOnChangeHandler(e);
        this.props.getThemeList(value.id);
        this.setState( { 
            currentAssetClassId: value.id,
            currentAssetClassName: value.name,
        });
        // load asset class attribute here
        this.props.getAssetClassAttribute({assetclassid: value.id});
    }
    

    handleMessageDropdownChange=(e)=>{
        this.setState( { currentDeviceTypeId: e.target.value});
    }

    handleDeviceTypeDropdownChange(e){
        // let selecteValue = e.target.value;
        // this.currentDeviceType =  e.target.value;
        this.setState( { currentDeviceTypeId: e.target.value});
    }

    manufactureOnChangeHandler = (e) => {
        let selectedValue = dropdownOnChangeHandler(e); //alert(selectedValue.id);
        this.props.loadAssets({devicetypeid: selectedValue.id});
        this.setState({
            currentDeviceTypeId : selectedValue.id,
            currentDeviceTypeName : selectedValue.name,
         });
        
    }

    handleSearchClick = (e) => {
        this.props.getThemeList(this.state.currentAssetClassId);
        this.setState({
            showEdieCard: false,
            currentAttris: [],
        });
    }

    renderTop(){
        return(
            <Col md={12}>
                <Card>
                <CardBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>

                            {/* <Col md={1} style={{textAlign: 'right' }}>
                                <Label for="deviceType"   style={{ display: 'inline'}}>Device Type</Label>
                            </Col> */}
                            {/* <Col md={3}>
                                <Label for="deviceType"   style={{ display: 'inline'}}>Manufacture</Label>
                                <Input type="select" id="deviceType" name="deviceType" width="60%" value = {this.state.currentDeviceTypeId} onChange={this.manufactureOnChangeHandler}>
                                    {this.props.types && this.props.types.map((type,i) => (<option key={type.id} value={type.id}>{type.name}</option>))}
                                </Input>
                            </Col> */}
                            <Col md={3}>
                                <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                                <Input type="select" id="asset" name="asset" width="100%" value={this.state.currentAssetClassId} onChange={this.handleAssetClassChange} >                                    
                                    {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                                    {this.props.assetClass && this.props.assetClass.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                                </Input>
                            </Col>
                            <Col md={3}>
                            </Col>
                            <Col md={3}>
                            </Col>

                            {/* <Col md={3}>
                                <Label for="messageType"  style={{ display: 'inline'}} >Message Type</Label>
                                <Input type="select" id="messageType" name="messageType" width="100%" value={this.state.currentMessageTypeName} onChange={this.handleMessageDropdownChange} >
                                    <option value = {this.state.currentMessageTypeId}>{this.state.currentMessageTypeName}</option>
                                </Input>
                            </Col> */}

                            {/* <Col md={3}>
                                <Label for="deviceType"   style={{ display: 'inline'}}>Manufacture</Label>
                                <Input type="select" id="deviceType" name="deviceType" width="60%" value = {this.state.currentDeviceTypeId} onChange={this.manufactureOnChangeHandler}>
                                    {this.props.devicetypes && this.props.devicetypes.map((type, i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))}
                                    
                                </Input>
                            </Col> */}

                            {/* <Col md={3}>
                                <Label for="messageType"  style={{ display: 'inline'}} >Asset</Label>
                                <Input type="select" id="messageType" name="messageType" width="100%" value={this.state.currentAssetId} onChange={this.assetOnChangeHandler} > */}
                                    {/* {this.props.assets && this.props.assets.map((asset, i )=>(<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                                    {/* <option value = {this.state.currentMessageTypeId}>{this.state.currentMessageTypeName}</option> */}
                                    {/* <option value={'0'}>ALL</option>
                                    <option value={'1'}>Asset 1</option> */}
                                {/* </Input>
                            </Col> */}

                            {/* <Col md={1} style={{textAlign: 'right' }}>
                                <Label for="theme" style={{ display: 'inline'}} >Theme</Label>
                            </Col> */}
                            
                            {/* <Col md={3}>
                                <Label for="theme" style={{ display: 'inline'}} >Theme</Label>
                                <Input type="select" id="theme" name="theme" width="90%" onChange={this.handleThemeDropdownChange} >                      
                                    {this.state.themes.map((item,i) => {
                                        return(
                                            <option key={i} value={item.id}>{item.name}</option>
                                        )
                                    })}              
                                </Input>
                            </Col> */}
                            {/* <Col md={2}></Col> */}
                            <Col md={3} style={{ textAlign: 'left',   justifyContent: 'center',alignItems: 'center' }}>

                                <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleSearchClick} >
                                    Search
                                </Button>

                                {/* <Label for="theme" style={{ display: 'inline'}} >&nbsp;</Label>
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={() => {this.setModalVisible(true)}}>
                                    New Theme
                                </Button> */}
                            </Col>
                    </Row>
                </CardBody>
                </Card>
            </Col>
        )
    }

    // ----------------------------------------------------------------------------------------------

    handleRowClick = (row) => {
        // let selectedId = row.id;
        
        // let selectedValue = row.name;
        // let selectedattri = this.state.themes.find((item)=>{
        //     return(item.id === selectedId);
        // });
        // // alert(selectedattri.attris);
        
        // let attris = JSON.parse(JSON.stringify(selectedattri.attris));
        //  
        // this.setState(
        //     {
        //         currentThemeId : selectedId,
        //         currentThemeName: selectedValue,
        //         showEdieCard: true,
        //         isNew: false,
        //         currentAttris: attris
        //     }
        // )
        let selectedId = row.themeid;
        let selectedValue = row.themename;
         
        this.props.getAssetclassTheme(selectedId);
        this.setState({
            currentThemeId : selectedId,
            currentThemeName: selectedValue,
            showEdieCard: true,
            isNew: false,
        });
    }
    handleRowDeleteClick = (row) => {
        let id = row.id;
        this.props.removeAssetclassTheme(this.state.currentAssetClassId, row.themename, row.themeid);
        this.setState((prevState) => ({
            // themes: prevState.themes.filter((v,i,a)=>(v.id !== id)),
            currentThemeId: -1,
            currentAttris:[],
            currentThemeName: '',
            showEdieCard: false,
        }));
    }
    handleAddNew = () => {
        this.setModalVisible(true);
    }

    renderThemeList(){
        return(
            <AttributeThemeList themes = {this.props.themeList} 
                                assetClass = {this.props.assetClass}
                                currentAssetClassId = {this.state.currentAssetClassId}
                                handleAssetClassChange = {this.handleAssetClassChange}
                                handleRowClick = {this.handleRowClick} 
                                handleRowDeleteClick = {this.handleRowDeleteClick} 
                                handleAddNew = {this.handleAddNew}
            />
        );    
    }
    // ----------------------------------------------------------------------------------------------

    handleSubmitOnclick = ( themeId, name, attris) =>{
        // alert (deviceTypeId + "," + messageTypeId+ "," + themeId + ',' + name);
        // let themes = this.state.themes.slice();
        // let index = themes.findIndex((item) => (item.id === themeId));
        // if (index > -1) {
        //     themes[index].attris = attris;
        // }
        // else {
        //     let newTheme = {
        //         id: themes.length + 1, 
        //         name: name,
        //         attris: attris
        //     }
        //     themes.push(newTheme);
        // }
        // // TO DO save themes object.
        // this.setState({
        //     themes : themes,
        //     currentThemeId: -1,
        //     currentAttris:[],
        //     currentThemeName: '',
        //     showEdieCard: false,
        // })

        let themeObj = {
            assetclassid: this.state.currentAssetClassId,
            themename: this.state.currentThemeName,
            themeid: this.state.currentThemeId,
            attris: attris,
        };
         
         
        this.props.upsertAssetclassTheme(themeObj);
    }

    handleCancelOnclick = () => {
        this.setState({
            showEdieCard: false,
            currentAttris: [],
        });
    }

    reenderEditCard(){
        if(this.state.showEdieCard){
            return(
                <AttributeSetCard themeName = {this.state.currentThemeName} 
                                attris = {this.state.currentAttris}
                                isNew = {this.state.isNew} 
                                // deviceTypeId = {this.state.currentDeviceTypeId}
                                // messageTypeId = {this.state.currentMessageTypeId}
                                themeId = {this.state.currentThemeId}
                                handleSubmitOnclick = {this.handleSubmitOnclick}
                                handleCancelOnclick = {this.handleCancelOnclick}
                />
            )
        }else{
            return null;
        }
    }

    // ----------------------------------------------------------------------------------------------

    handleInputOnchang(e){
         
        this.currentInput = e.target.value;
    }

    handleInputClick(){
        // alert(this.currentInput);
        let value = this.currentInput
        let showCard = (value !== '');
        this.setState(prevState => ({
            modalVisible: !prevState.modalVisible,
            currentThemeId: 0,
            currentThemeName: value,
            showEdieCard: showCard,
            isNew: true,
            // currentAttris:[
            //     {display: false, name: 'Speed', type:0, setup:[]},
            //     {display: false, name: 'Temp', type:0, setup:[]},
            //     {display: false, name: 'Huli', type:1, setup:[]},
            //     {display: false, name: 'Attr1', type:0, setup:[]},
            //     {display: false, name: 'Attr2', type:0, setup:[]},
            // ],
            currentAttris: prevState.assetClassAttriSet,
        }));
    }

    setModalVisible(visible) {
        // alert(visible);
        this.setState({modalVisible: visible});
        // alert(this.state.modalVisible);
    }

    toggle() {
        this.setState(prevState => ({
            modalVisible: !prevState.modalVisible
        }));
    }

    renderModal(){
        return(
                <Modal animationType="slide" transparent={false} isOpen={this.state.modalVisible}
                        toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>New Theme</ModalHeader>
                    <ModalBody>
                        <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                                <Col md={3} style={{textAlign: 'left' }}>
                                    <Label >Asset Class</Label>
                                </Col>
                                
                                <Col md={8}>
                                <Label >{this.state.currentAssetClassName}</Label>
                                </Col>
                        </Row>
                        <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                                <Col md={3} style={{textAlign: 'left' }}>
                                    <Label for="deviceType" >Theme Name</Label>
                                </Col>
                                
                                <Col md={8}>
                                    <Input type="text" id="deviceType" name="deviceType" width="90%" onChange={this.handleInputOnchang} >                                    
                                    </Input>
                                </Col>
                        </Row>
                    </ModalBody>
                    
                    <ModalFooter>
                        <Button outline color="custom" onClick={this.handleInputClick}>Add</Button>{' '}
                        <Button outline color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
        )
    }


    // ----------------------------------------------------------------------------------------------
    render(){
        return(
            <div style={{width: '100%'}}>
                {this.renderModal()}
                {/* {this.renderTop()} */}
                {this.renderThemeList()}
                {this.reenderEditCard()}
                {/* <Notifications color={'danger'} title={'test'} message={'testestsaetsatew'}/> */}
            </div>
        )
    }
}

// export default AttributeBoard;

function mapStateToProps(state) {
    //  
    //  
    const types = state.mapboard.types;
    const attr = state.mapboard.attr;
    const assetClass = state.mapboard.assetClass;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const assets = state.attributemgmt.assets;
    const props = { ...state.attributetheme, types, attr, assets, assetClass, assetClassAttr};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDevice, loadDeviceAttr, loadAssets, loadAssetClass, getAssetClassAttribute, getThemeList, getAssetclassTheme, upsertAssetclassTheme, removeAssetclassTheme}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributeBoard);
