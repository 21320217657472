import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import AssetBoard from './components/AssetBoard';

const AssetPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Asset Management</h3>
      </Col>
    </Row>
    <Row>
      <AssetBoard />
    </Row>
  </Container>
);

export default AssetPage;
