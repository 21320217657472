/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
// import MapBoard from './components/MapBoard';
// import BillingBoard from './components/BillingBoard';
import TelecomBoard from '../TelecomApp/components/TelecomBoard';
import { getPageContent } from '../../redux/actions/userAction';

const BillingPage = (props) => {
  let [isLoaded, setIsLoaded] = useState(false)
  
  useEffect(() => {
      
/**
 * (TEISA Billing APP)
 * TODO: How will this be affected?
 */
      
    /* Change this to Billing App */
    // props.getPageContent({ "content": "Telecom App" })
    // .then(() => {
    //   setIsLoaded(true)
    // })

    // 

    const fetchPageContent = async () => {
      await props.getPageContent({ "content": "Telecom App" })
      await props.getPageContent({ "content": "Billing App" })
      setIsLoaded(true)
    }

    fetchPageContent()

  }, []);

  const { pageContentList } = props;

  return (
    <Container className="dashboard">
      <Row>
        {
          isLoaded && (pageContentList.includes("Sigfox Billing App") && 
          <Col md={12}>
            <h2 className="page-title">SIGFOX Billing App</h2>
          </Col>)
        }
      </Row>
      <Row>
        {/* Using the Telecom Board as a base */}
        <TelecomBoard />
      </Row>
    </Container>
  );

}

function mapStateToProps(state) {
  const { pageContentList } = state.user;
  const props = { pageContentList };
  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
     getPageContent
  }, dispatch);
};

export default (connect(mapStateToProps, mapDispatchToProps))(BillingPage);
