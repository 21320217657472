/* eslint-disable */
import React, { Component } from 'react';
import { Row, Button, Card, CardBody, Modal, ModalHeader, ModalBody, Col, Input, ModalFooter, } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { getPlanUsage, getResellersTree } from '../../../../redux/actions/userAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser } from '../../../../factories/auth';
import Spinner from "react-bootstrap/Spinner";

class DashboardGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowFilterTime1: false,
            filterStartTime1: null,
            filterEndTime1: null,
            type: "daily",
            isChangedType: false,
        }
        this.userObj = getCurrentUser()
    }
    componentDidMount() {
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && this.props.type != null) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${this.props.type[0].toUpperCase() + this.props.type.substring(1)} `}</p>
                    <p className="intro">{label}</p>
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <p className="desc">{`Usage : ${payload[0].value} MB`}</p>}
                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <p className="desc">{`Usage : ${payload[0].value} `}</p>}
                </div>
            );
        }

        return null;
    };

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };



    validateForm = (Management, startDate, endDate) => {
        if (Management == "timeFilter") {
            let now = new Date();
            if (!startDate || !endDate) {
                return true;
            }
            if ((endDate && Date.parse(endDate) > Date.parse(now)) || (startDate && Date.parse(startDate) > Date.parse(now))) {
                return false;
            }
            if (Date.parse(endDate) < Date.parse(startDate)) {
                return false;
            }
        }
        return true;
    }

    formatTime(time) {
        var temp_time = new Number(time);
        var temp_date = new Date(temp_time);
        var temp_year1 = "";
        var temp_month1 = "";
        var temp_day1 = "";
        temp_year1 = temp_date.getFullYear();
        temp_month1 = (temp_date.getMonth() + 1) > 9 ? (temp_date.getMonth() + 1) : "0" + (temp_date.getMonth() + 1);
        temp_day1 = (temp_date.getDate()) > 9 ? (temp_date.getDate()) : "0" + (temp_date.getDate());
        return temp_year1.toString() + "-" + temp_month1.toString() + "-" + temp_day1.toString();
    }

    renderFilterTime(startDateValName, endDateValName, onSubmitCallback) {
        let minDateMillTime = new Date().getTime() - (86400 * 365 * 1000);

        let minDateStr = this.formatTime(minDateMillTime)
        let maxStr = this.formatTime(new Date())
        return (
            <Row className="container center" style={{ minWidth: "700px" }}>

                <Col md={5}>
                    <Input
                        type="date"
                        name={startDateValName}
                        id={startDateValName}
                        placeholder='StartDate'
                        onChange={this.onInputChange}
                        className="form-control"
                        defaultValue={this.state[startDateValName]}
                        min={minDateStr}
                        max={this.state[endDateValName] ? this.state[endDateValName] : maxStr}

                    />
                </Col>

                <Col md={5}>
                    <Input
                        type="date"
                        name={endDateValName}
                        id={endDateValName}
                        placeholder='EndDate'
                        onChange={this.onInputChange}
                        className="form-control"
                        defaultValue={this.state[endDateValName]}
                        min={this.state[startDateValName] ? this.state[startDateValName] : minDateStr}
                        max={maxStr}
                    />
                </Col>
                <Col md={2}>
                    <Button
                        style={{ marginBottom: "0" }}
                        color='custom'
                        type="reset"
                        onClick={onSubmitCallback}
                        disabled={!this.validateForm("timeFilter", this.state[startDateValName], this.state[endDateValName])}
                    >
                        Submit
                    </Button>
                </Col>



            </Row>
        )
    }


    switchType(type, isClear) {
        if (this.props.loading) {
            return;
        }
        if (isClear) {
            this.state.filterStartTime1 = null;
            this.state.filterEndTime1 = null;
            this.setState({
                isChangedType: false,
                isShowFilterTime1: false,
                filterStartTime1: null,
                filterEndTime1: null,
            })
        } else {
            this.setState({
                isChangedType: true
            })
        }
        let startDate = this.state.filterStartTime1 ? new Date(this.state.filterStartTime1) : null;
        let endDate = this.state.filterEndTime1 ? new Date(this.state.filterEndTime1) : null;

        if (startDate != null) {
            startDate = startDate.setHours(startDate.getHours() + 5);
        }
        if (endDate != null) {
            endDate = endDate.setHours(endDate.getHours() + 13);
        }
        this.setState({ type: type })
        this.props.LoadUsageDate(type, { startTime: startDate, endTime: endDate, isClearFilterTime: isClear }, true)
    }

    renderSpinner = () => {
        return (
            <Spinner
                className="table-spinner"
                as="span"
                animation="border"
                // size="sm"
                // role="status"
                // aria-hidden="true"
                style={{ display: 'inline-block', margin: 'auto', top: "50%", left: '50%', position: "absolute", zIndex: "99999" }}
            />
        );
    }

    render() {
        let dayGap = 7;
        if (this.state.filterStartTime1 != null && this.state.filterEndTime1) {
            dayGap = (new Date(this.state.filterEndTime1).getTime() - new Date(this.state.filterStartTime1).getTime()) / 86400000;
        }
        return (
            <div
                style={{ position: "relative" }}
            >
                <Row>
                    <Row
                        className="container center"
                        style={{ marginTop: "20px", width: "100%" }}
                    >
                        <Button
                            color="custom"
                            className="box"
                            onClick={() => {
                                this.switchType("daily", false)
                            }}
                            disabled={this.props.type == "daily" && this.state.isChangedType}
                        >
                            Daily
                        </Button>
                        <Button
                            color="custom"
                            className="box"
                            onClick={() => {
                                this.switchType("weekly", false)
                            }}
                            disabled={(this.props.type == "weekly" || dayGap < 6) && this.state.isChangedType}
                        >
                            Weekly
                        </Button>
                        <Button
                            color="custom"
                            className="box"
                            onClick={() => {
                                this.switchType("monthly", false)
                            }}
                            disabled={(this.props.type == "monthly" || dayGap < 7) && this.state.isChangedType}
                        >
                            Monthly
                        </Button>
                        <Button
                            color="custom"
                            className="box"
                            onClick={() => {
                                if (this.props.loading) {
                                    return;
                                }
                                this.setState({
                                    isShowFilterTime1: true,
                                });
                            }}
                        >
                            Customize
                        </Button>
                        <Button
                            color="custom"
                            className="box"
                            onClick={(event) => {
                                if (this.props.loading) {
                                    return;
                                }
                                this.switchType("daily", true)
                            }}
                        >
                            Reset
                        </Button>
                        {/*TODO TEST RETREE !!!!!!!!!!!!!!!!!*/}
                        {/*{<Button color="custom" className="box" onClick={(event) => {
                            this.props.getResellersTree({isGetAll:true})
                        }}>getResellersTree</Button>}*/}
                    </Row>
                    {this.state.isShowFilterTime1 ? this.renderFilterTime("filterStartTime1", "filterEndTime1", () => {
                        if (this.props.loading) {
                            return;
                        }
                        if (this.state.filterStartTime1 != null || this.state.filterEndTime1 != null) {
                            this.state.isShowFilterTime1 = false
                            this.switchType("daily")
                        } else {
                            this.setState({
                                isShowFilterTime1: false
                            })
                        }

                    }) : null}
                    {this.props.loading ? this.renderSpinner() : null}
                    <ResponsiveContainer
                        height={250}
                        width="98%"
                        maxWidth={300}
                    >
                        <BarChart
                            data={this.props.dashboardPlanUsageDataInMB}
                            width={250}
                            height={230}
                            margin={{ top: 60, right: 35, left: 0, bottom: 15, }}
                        >
                            {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                                <XAxis
                                    dataKey="name"
                                    label={{
                                        value: `No. of Frames (${this.props.type})`,
                                        offset: "-12",
                                        position: "insideBottom",
                                    }}
                                />
                            }
                            {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                                <XAxis
                                    dataKey="name"
                                    label={{
                                        value: `${this.props.type.toUpperCase()} Data (in MB)`,
                                        offset: "-12",
                                        position: "insideBottom",
                                    }}
                                />
                            }
                            <YAxis />
                            <Tooltip
                                content={this.CustomTooltip}
                            />
                            <CartesianGrid
                                vertical={false}
                            />
                            <Tooltip />
                            <Bar
                                dataKey="amt"
                                className="graphColor"
                            />

                        </BarChart>
                    </ResponsiveContainer>
                </Row>

            </div>
        );

    }
}

function mapStateToProps(state) {
    let { dashboardPlanUsageData } = state.user;
    let dashboardPlanUsageDataInMB = structuredClone(dashboardPlanUsageData);

    for (let element of dashboardPlanUsageDataInMB) {
        element.amt = (element.amt / 1024).toFixed(2);
        element.amt = parseFloat(element.amt)
        element.unit = "mb";
    }

    const props = { dashboardPlanUsageData, dashboardPlanUsageDataInMB };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPlanUsage,
        getResellersTree
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardGraph);

