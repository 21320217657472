/*eslint-disable*/
import React, { Component } from "react";
import  Card from "react-credit-cards";
import uuid from "uuid/v4";
import AddPaymentModal from "./payment/addPaymentModal";
import {Row, Col, Card as Card2, CardBody,  CardGroup, Label, Input, Button, Table} from 'reactstrap';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {BackButton, NextButton} from '../../../shared/components/Button/IconButton'; 
import { USAGE_PRICE } from '../../../constants/dataConstants';

class CompanyAccountPurchaseCardComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
        cards: [
            {
                name: "Evan1",
                number: "5434 **** **** 3414",
                cvc: "434",
                expiry: "03/19",
                id: uuid()
              },
              {
                name: "Evan2",
                number: "4934 **** **** 3415",
                cvc: "435",
                expiry: "03/19",
                id: uuid()
              }
        ],
        currentAccount: null,
      };
    
  }

  manufactureOnChangeHandler = (e) => {
    let selectedValue = dropdownOnChangeHandler(e); //alert(selectedValue.id);
     
    // this.props.getAttributes(selectedValue.id);
    // this.props.loadAssets({devicetypeid: selectedValue.id});
    this.setState({
        currentDeviceTypeId : selectedValue.id,
        currentDeviceTypeName : selectedValue.name,
     });
    
}

handleChange=(event)=> {
     
   
  };

  tableActionColFormater = (cell, row) => {
    // const ac = this.props.account.filter(account => account.cardMask);
    const card = row;
    const cardwidth ={
  
      'width': '10px',
     
    }
    return (
        <div>
          
    <CardGroup >
      {/* {ac && ac.map((card,index) => (
        // {this.state.cards && this.state.cards.map(card => ( */}
        <Card2 >
          
            <Card 
           number={card.cardMask}
           customCardsSupport={true}
           customCardsStyles={cardwidth}
            />

            </Card2>
      {/* ))}  */}
    </CardGroup>
      
        </div>
    )
  }

  radioButtonFormater = (cell, row) => {
    return (
      <Input type="radio" name="radio1" onClick={()=>{this.onRadioClick(row)}} />
    )
  }
  onRadioClick = (value) => {
     
    this.props.handleSwitchAccount(value);
    // this.setState({
    //   currentAccount: value,
    // });
  }

  checkoutNow = () => {
    if (this.props.currentPlanItem && this.props.account && Array.isArray(this.props.account) && this.props.account.length > 0) {
      let defaultData = {
          "accountId": this.props.account[0].id,
          "currency": "CAD",
          "comment": "1",
          "autoPay": this.props.autoPay ? 1 : 0,
          "orderItemDataList": [
              {
                  "itemId": this.props.currentPlanItem.itemId,
                  "itemQty": 1,
                  "promoCode": 1
              }
          ]
      
    }
    // SPECIFY CORRECT ATTRIBUTES
    let data = {
          "customerId": this.props.customerObj.id,
          "accountId": this.props.account[0].id,
          "currency": "CAD",
          "comment": "1",
          "autoPay": this.props.autoPay ? 1 : 0,
          "orderItemDataList": [
              {
                  "itemId": this.props.currentPlanItem.itemId,
                  "itemQty": 1,
                  "promoCode": 1
              }
          ]
    }
    
      // this.props.createOrder({ ...defaultData, ...data });
      this.props.customerCheckout({ ...defaultData, ...data });
      this.props.handlePurchaseCheckoutClick();
    } else{
      toast.error('No Item/Account is selected');
    }
  }

  // onCheckoutClick = () => {
    
  // }

  render() {

    
    const  plans  = this.state.plans;

    const cardsstyle ={
  
        'width': '100%',
       
      }

      const btn ={
        'float': 'right'
      }

    
      const aList = this.props.accountList;
       

      // const usageList=  [
      //   {
      //       "product": 'IOT - Basic',
      //       "quantity": 1,
      //       "price": USAGE_PRICE,
      //       "card": 1000,
      //   }];
      
      let usageList = [];
      if (this.props.itemType && this.props.itemType === '1' && this.props.currentPlanItem) {
        let price = '';
        let paymentperiod = 'one shot';
        if (this.props.currentPlanItem.itemTypeId == 1) {
          price = USAGE_PRICE;
        }
        else if (this.props.currentPlanItem.itemTypeId == 2 && this.props.currentPlanItem.unitPrice) {
          price = `CAD $ ${this.props.currentPlanItem.unitPrice}`;
        }

        if (this.props.currentPlanItem && this.props.currentPlanItem.payFrequency) {
          paymentperiod = this.props.currentPlanItem.payFrequency.itemPayFreqName
        }
        let item = {
                "product": this.props.currentPlanItem.itemName,
                "quantity": 1,
                "price": price,
                "paymentperiod": paymentperiod,
                "autocharg": this.props.autoPay ? 'Yes': 'No',
                "card": 1000,
            };
        usageList.push(item)
      }

    return(
    <div className="App-cards-list" style={cardsstyle}>
        
     <div><h3>Order Summary</h3></div><br/>
        <BootstrapTable 
                data={ usageList }  
                // tableHeaderClass = 'header_white' 
                pagination = {false} 
                  
                  
                bordered={ false } 
                // insertRow 
                ref='table' 
            >

              <TableHeaderColumn dataField='product' isKey={ true } dataAlign="center" width='10%'>Product</TableHeaderColumn>
              <TableHeaderColumn dataField='quantity' dataAlign="center" dataSort width='10%' >Quantity</TableHeaderColumn> 
              <TableHeaderColumn dataField='price' dataAlign="center" dataSort width='10%' >Price</TableHeaderColumn>
              <TableHeaderColumn dataField='paymentperiod' dataAlign="center" dataSort width='10%' >Payment Period</TableHeaderColumn>
              <TableHeaderColumn dataField='autocharg' dataAlign="center" dataSort width='10%' >Auto Charge</TableHeaderColumn>
              {/* <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater}  dataAlign="center" dataSort width='10%' >Payment card</TableHeaderColumn> */}
                                    
          </BootstrapTable>
          <br/>
          <br/>
          <div><h3>Your Payments</h3></div><br/>
          <BootstrapTable
            data={ aList }  
            pagination = {false} 
              
              
            bordered={ false } 
          >
            <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" hidden >ID</TableHeaderColumn>
            <TableHeaderColumn dataField='' dataAlign="center"  width='5%' dataFormat={this.radioButtonFormater}></TableHeaderColumn> 
            <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater}  dataAlign="center" dataSort width='10%' ></TableHeaderColumn>
            <TableHeaderColumn dataField='cardMask' dataAlign="center" dataSort width='10%' >Card Number</TableHeaderColumn> 
            <TableHeaderColumn dataField='expDate' dataAlign="center" dataSort width='10%' >Expires on</TableHeaderColumn> 
          </BootstrapTable>
          <br/>
          <BackButton
                  onClick={this.props.handlePurchaseCardCompBackClick} 
                  label="Back"
                  size={30}
                  color = 'black'
                />
          <Button 
            style={btn} 
            variant="primary" 
            color="custom" 
            // onClick={this.props.handlePurchaseCheckoutClick} 
            onClick={this.checkoutNow} 
            checkoutNow
          >
            Check Out
          </Button> 
      
    </div>
    
    );
  }
}


export default CompanyAccountPurchaseCardComp;

