/* eslint-disable */

import {
    MAP_DEVICE_LOAD_SUCCEED, 
    MAP_DEVICE_LOAD_FAILED,
    MAP_DEVICE_ATTR_LOAD_SUCCEED, 
    MAP_DEVICE_ATTR_LOAD_FAILED,
    MAP_LOAD_DEVICE_ATTRIBUTE_SUCCEED,
    MAP_LOAD_DEVICE_ATTRIBUTE_FAILED,
    DEVICE_ADD_ATTR_DEVICE,
    DEVICE_UPDATE_ATTR_DEVICE,
    MAP_DEVICE_MAP_LOAD_SUCCEED,
    MAP_DEVICE_MAP_LOAD_FAILED,
    MAP_FILTER_ATTRIBUTE_CHANGE,
    MAP_LOAD_ASSETS_SUCCEED,
    MAP_LOAD_ASSETS_FAILED,
    MAP_LOAD_ASSET_ATTRIBUTE_SUCCEED,
    MAP_LOAD_ASSET_ATTRIBUTE_FAILED,
    MAP_GET_ASSETS_DATA_SUCCEED,
    MAP_GET_ASSETS_DATA_FAILED,
    MAP_LOAD_ASSETCLASS_SUCCEED,
    MAP_LOAD_ASSETCLASS_FAILED,
    MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED,
    MAP_GET_ASSETCLASS_ATTRIBUTE_FAILED,
    MAP_CLEAR_ASSETCLASS_ATTRIBUTE,
    MAP_GET_THEME_SUCCEED,
    MAP_GET_THEME_FAILED,
    MAP_FILTER_ASSET_ATTRIBUTE_CHANGE,
    MAP_DEVICE_DATA,
    MAP_ASSET_DATA ,
    MAP_GET_DEVICE_GROUP_DATA_SUCCEED,
    MAP_GET_DEVICE_GROUP_DATA_FAILED, 
    MAP_CLEAR_DEVICE_GROUP_DATA,
    MAP_GET_ASSET_GROUP_DATA_SUCCEED,
    MAP_GET_ASSET_GROUP_DATA_FAILED,
    MAP_CLEAR_ASSET_GROUP_DATA,
    MAP_FILTER_ASSET_GROUP_ATTRIBUTE_CHANGE,
    MAP_GET_DEVICE_BY_GROUP_SUCCEED,
    MAP_GET_DEVICE_BY_GROUP_FAILED,
    MAP_CLEAR_GROUP_DEVICES,
    MAP_REFRASH_STARTED,
    MAP_DEVICE_MAP_LOAD_CLEAR,
} from '../../constants/actionTypes'

const initState = {    
    types:[],
    groups:[],
    attr:[],    
    mapData:[],
    map:{},
    test: '',
    assets:[],
    assetAttr: [],
    assetData: [],
    assetClass: [],
    assetClassAttr: [],
    deviceList: [],
    deviceid: '',
    deviceAttrList: [],
    themes: [],
    deviceGroupData: [],
    assetGroupData: [],
    groupDevices: [],
    dataType: '',
    loading: false,

    error:'0',
    message:'',
    status: '',
    type:'',
}

export default function(state = initState, action){

    switch (action.type){
        case MAP_DEVICE_LOAD_SUCCEED:
            let data = action.payload.data;
            let newDevices = [];
            //  
            data && data.map((item,i) => { 
                newDevices.push({id: item.devicetypeid, name: item.devicetypename});
            });
            return Object.assign({}, state, {types: newDevices, status: action.type, error:'0'});
            
        case MAP_DEVICE_LOAD_FAILED:
            let error = action.error;
            return Object.assign({}, state, {error:'0', message: error, status: action.type});
        case MAP_DEVICE_ATTR_LOAD_SUCCEED:
            //  
            //  
            let attrData = action.data.data;
            //  
            return {
                ...state,
                error: '0',
                attr: attrData,
                status: action.type,
            }
        case MAP_DEVICE_ATTR_LOAD_FAILED:
            //  
            //  
            let attrError = action.error;
            return{
                ...state,
                error: '1',
                message: attrError
            }
        case MAP_LOAD_DEVICE_ATTRIBUTE_SUCCEED:
            return {
                ...state,
                deviceAttrList: action.payload,
                error: 0,
                type: action.type
            }
        case MAP_LOAD_DEVICE_ATTRIBUTE_FAILED:
            let deviceAttrError = action.error;
            return{
                ...state,
                error: '1',
                message: deviceAttrError
            }    
        case MAP_DEVICE_MAP_LOAD_SUCCEED:
            //  
            //  
            // let mapModifyData = [];
            // action.payload.data.map((item, i) => {
            //     let obj = Object.assign({},item,{attributename: '', color: '#0000ff'});
            //     mapModifyData.push(obj);
            // });
            return {
                ...state,
                error: '0',
                // mapData: action.payload.data
                // mapData: mapModifyData
                mapData: action.payload,
                status: action.type,
                dataType: MAP_DEVICE_DATA,
                loading: false,
            }
        case MAP_DEVICE_MAP_LOAD_CLEAR:
            return{
                ...state,
                error: '0',
                mapData: [],
                status: action.type,
                loading: false,
            }
        case MAP_DEVICE_MAP_LOAD_FAILED:
            return {
                ...state,
                mapData:[],
                error: '1',
                message: action.payload.error,
                status: action.type,
                loading: false,
            }
        case MAP_LOAD_ASSETS_SUCCEED:
            return{
                ...state,
                error: '0',
                assets: action.payload,
                status: action.type,
            }
        case MAP_LOAD_ASSETS_FAILED:
            return{
                ...state,
                error: '1',
                message: action.payload,
                status: action.type,
            }
        case MAP_LOAD_ASSET_ATTRIBUTE_SUCCEED:
            return{
                ...state,
                error: '0',
                assetAttr: action.payload,
                status: action.type,
            }
        case MAP_LOAD_ASSET_ATTRIBUTE_FAILED:
            return{
                ...state,
                error: '1',
                message: action.payload,
                status: action.type,
            }
        case "aaaa":
            return Object.assign({}, state, {test: action.data, status: action.type});

        case MAP_FILTER_ATTRIBUTE_CHANGE:
            return{
                ...state,
                mapData: action.payload,
                status: action.type,
                dataType: MAP_DEVICE_DATA,
                loading: false,
            }
        case MAP_REFRASH_STARTED:
            return{
                ...state,
                loading: true,
            }
        case MAP_GET_ASSETS_DATA_SUCCEED:
            return{
                ...state,
                assetData:  JSON.parse(JSON.stringify(action.payload)), 
                assetRawData: action.payload,
                error: '0',
                message:'',
                status: action.type,
                dataType: MAP_ASSET_DATA,
                loading: false,
            }
        case MAP_FILTER_ASSET_ATTRIBUTE_CHANGE:
            return{
                ...state,
                assetData: action.payload,
                status: action.type,
                dataType: MAP_ASSET_DATA,
            }
        case MAP_LOAD_ASSETCLASS_SUCCEED:
            return{
                ...state,
                assetClass: action.payload,
                error: '0',
                message:'',
                status: action.type,
            }
        case MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED:
            return{
                ...state,
                assetClassAttr: action.payload,
                error: '0',
                message:'',
                status: action.type,
            }   
        case MAP_CLEAR_ASSETCLASS_ATTRIBUTE:
            return{
                ...state,
                assetClassAttr: [],
                error: '0',
                message:'',
                status: action.type,
            }     
        case MAP_GET_THEME_SUCCEED:
            return{
                ...state,
                themes: action.payload,
                error: '0',
                message:'',
                status: action.type,
            }  
        case MAP_GET_DEVICE_GROUP_DATA_SUCCEED:
            return{
                ...state,
                deviceGroupData: action.payload,
                error: '0',
                message:'',
                status: action.type,
                loading: false,
            }  
        case MAP_CLEAR_DEVICE_GROUP_DATA:
            return{
                ...state,
                deviceGroupData: [],
                error: '0',
                message:action.message,
                status: action.type,
            }  
        case MAP_GET_ASSET_GROUP_DATA_SUCCEED:
            return{
                ...state,
                assetGroupData: action.payload,
                assetRawGroupData: JSON.parse(JSON.stringify(action.payload)), 
                error: '0',
                message:'',
                status: action.type,
                loading: false,
            }  
        case MAP_CLEAR_ASSET_GROUP_DATA:
            return{
                ...state,
                assetGroupData: [],
                assetRawGroupData: [],
                error: '0',
                message:action.message,
                status: action.type,
            }  
        case MAP_FILTER_ASSET_GROUP_ATTRIBUTE_CHANGE:
            return{
                ...state,
                assetGroupData: action.payload,
                status: action.type,
                dataType: MAP_ASSET_DATA,
            }
        case  MAP_GET_DEVICE_BY_GROUP_SUCCEED:
            return{
                ...state,
                groupDevices: action.payload,
                error: '0',
                message:'',
                status: action.type,
            }  
        case MAP_CLEAR_GROUP_DEVICES:
            return{
                ...state,
                groupDevices: [],
                error: '0',
                message:action.message,
                status: action.type,
            }  
        case MAP_GET_DEVICE_BY_GROUP_FAILED:
        case MAP_GET_DEVICE_GROUP_DATA_FAILED:
        case MAP_GET_ASSET_GROUP_DATA_FAILED:
        case MAP_GET_THEME_FAILED:
        case MAP_GET_ASSETS_DATA_FAILED:
        case MAP_LOAD_ASSETCLASS_FAILED:
        case MAP_GET_ASSETCLASS_ATTRIBUTE_FAILED:
            return{
                ...state,
                error: '1',
                message:action.error,
                status: action.type,
                loading: false,
            }

        case DEVICE_ADD_ATTR_DEVICE:
            return {
                ...state,
                deviceList: addOrUpdateAttribute([...state.deviceList], action.payload.id, action.payload.data),
                error: 0,
                message: 'Updated Custom Attribute in a Device',
                type: action.type

            }
        case DEVICE_UPDATE_ATTR_DEVICE:
            return{
                ...state,
                deviceList: addOrUpdateAttribute([...state.deviceList], action.payload.id, action.payload.data),
                error: 0,
                message: 'Update Custom Attribute in a Device',
                type: action.type
            }    
        default: 
          return Object.assign({}, state);
    }
}