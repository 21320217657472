/* eslint-disable */
import React, { Component } from 'react';
import { ChromePicker} from 'react-color';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { ENGINE_METHOD_DIGESTS } from 'constants';

class NewFenceModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            btnDisable: false,
            name: ''
        }
    }

    // handleLowValueOnchang =(e) => {
    //     this.props.onLowValueChange(e);
    // }

    componentDidMount(){
         
    }

    componentWillReceiveProps(nextProps){
         
         
        this.setState({
            name: ''
        });
    }

    handleNameOnchange =(e) => {
        // this.props.onNameChange(e);
        let btnDisable = (e.target.value && e.target.value.trim().length>0);
        this.setState({
            name: e.target.value,
            btnDisable: btnDisable
        });
    }

    // handleChangeComplete =(c,e) => {
    //     this.props.onColorValueChange(c,e)
    // }

    // handleShowColorClick = (e) => {
    //     e.stopPropagation();
    //     this.setState(
    //         (preState) =>(
    //             {showColorPicker: !preState.showColorPicker}
    //         )
    //     )
    // }

    OnAddClick = (e) => {
        this.props.onAddFence(this.state.name);
        this.props.toggle();
    }

    render(){
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle}>
                <ModalHeader toggle={this.toggle}>New Geo Fence</ModalHeader>
                <ModalBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="upinput" >Name</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="text" id="upinput" name="upinput" width="90%" value={this.state.name} onChange={this.handleNameOnchange} >                                    
                                </Input>
                            </Col>
                    </Row>                    
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.OnAddClick} disabled={!this.state.btnDisable}>Add</Button>{' '}
                    <Button color="custom" onClick={this.props.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }
}

export default NewFenceModal;

