/* eslint-disable */
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import {
  getCompanyAssetClass,
  getWeatherAssetData,
  clearWeatherAssetData,
  getWeatherAlarmCount,
  clearWeatherAlarmCount,
  updateWeatherAssetData,
  updatePubSubAssetData,
  pubsubUpdateWeatherAssetHistoryData,
  getAssetHistoryData
} from "../../../redux/actions/weatherAction";
import { reformWeatherAssetData, reformAlertPubsubData, updateStatusAsset } from "../../../factories/weather";
import {loadAssetClass, getAssetClassAttribute, clearAssetClassAttribute, filterMapDataByAttribute, getGroupAssetData, clearGroupAssetData, filterGroupAssetDataByAttribute} from '../../../redux/actions/mapActions';
import TortoiseMainBoard from "./TortoiseMainBoard";
import { connect } from "react-redux";
import axios from "axios";
import {
  WEB_SERVICE_ROOT,
  WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT,
  WEB_SUB_WEBSOCKET_OPG_DEVICEMESSAGE,
  WEB_SUB_WEBSOCKET_OPG_ALERTMESSAGE,
  WEB_SUB_WEBSOCKET_OPG_ALARMMESSAGE,
} from "../../../constants/appConstants";
import { LIGHT_GREY_COLOR, OPG_KEYS } from "../../../constants/dataConstants";
import { toast } from "react-toastify";
import Spinner from 'react-bootstrap/Spinner';
import { getJwttoken } from "../../../factories/utils";
import { Client } from "@stomp/stompjs";


var stompClient;

const OPG_ASSETCLASS_NAME = "OPGStation";
export const OPG_ASSETCLASS_NAME_ARRAY = ["OPG testing", "OPG tortoise", "opg", "OPG", "tortoise", "Tortoise", "OPG Sensor"];

class MainBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      calendarInfo: null,
      calendarInfo2: null,
      currentAsset: null,
      assetList: [],
      statusData: [],
      showHistory: false,
      isLoading: false,
      isSmall: false,
      startDate: null,
      endDate: null
    };
    this.opgClassId = null;
  }

  componentDidMount() {
    this.loadAssetByCompanyClass();
    window.addEventListener("resize", this.resize);
    this.resize();
    this.connectSocket();
  }

  connectSocket = () => {
    if (stompClient !== null && stompClient !== undefined) {
      stompClient.deactivate();
    }

    let query = "?q=" + getJwttoken();

    stompClient = new Client({
      brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT + query,
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      onConnect: this.connect_callback,
      onDisconnect: this.disconnect_callback,
    });

    stompClient.activate();
  };

  connect_callback = () => {
    stompClient.subscribe(
      WEB_SUB_WEBSOCKET_OPG_DEVICEMESSAGE,
      this.callbackDeviceMessage
    );
    stompClient.subscribe(
      WEB_SUB_WEBSOCKET_OPG_ALERTMESSAGE,
      this.callbackAlertMessage
    );
    stompClient.subscribe(
      WEB_SUB_WEBSOCKET_OPG_ALARMMESSAGE,
      this.callbackAlarmMessage
    );
  };

  disconnect_callback = () => {
    stompClient.deactivate();
    //console.log("Client disconnected.");
  };

  callbackDeviceMessage = (message) => {
    if (message.body) {
    } else {
    }
    // this.refreshAttributeHistoryData(message.body);
  };

  callbackAlertMessage = (message) => {
    if (message.body) {
    } else {
    }

    this.refreshAlarm(message.body);
    this.refreshAlarmSetObjByAlert(message.body);
    this.updateStatusAssetByAlert(message.body);
  };

  callbackAlarmMessage = (message) => {
    if (message.body) {
    } else {
    }

    this.refreshAssetData(message.body);
  };
  //------------------------------------------------------------------------------
  componentWillUnmount() {
    this.clearAssetData();

    if (stompClient !== null && stompClient !== undefined) {
      stompClient.deactivate();
    }

    window.removeEventListener("resize", this.resize);
  };

  componentWillReceiveProps(nextProps) {
    // console.log("===Component will receive", nextProps);
    if (
      nextProps.assetData &&
      nextProps.assetData.length > 0 &&
      nextProps.assetData != this.props.assetData
    ) {
      let statusData = reformWeatherAssetData(nextProps.assetData);
      if (statusData && statusData[0].grouping == "opg") {
        this.setState({
          statusData,
        });
      }
    }
    this.setState({});
  }

  resize = () => {
    let currentIsSmall = window.innerWidth <= 1370;
    this.setState(prev => {
      if (currentIsSmall !== prev.isSmall) {
        return {
          isSmall: currentIsSmall,
        };
      }
    });
  };

  loadAssetByCompanyClass = async () => {
    try {
      if (this.opgClassId == null) {
        let res = await getCompanyAssetClass();
        // console.log("===res", res);

        if (res.status === 200 && res.data) {
          this.opgClassId = [];
          for (let i = 0; i < res.data.length; i++) {
            if (
              res.data[i].assetclassname.includes("OPG Sensor")
            ) {
              let assetclassId = res.data[i].assetclassid;
              this.opgClassId.push(assetclassId);
              let assets = res.data[i].assets;
              this.setState((prevState) => {
                if (
                  Array.isArray(prevState.assetList) &&
                  prevState.assetList.length >= 0
                ) {
                  return ({
                    assetList: [...prevState.assetList, ...assets],
                  });
                } else {
                  return ({
                    assetList: assets,
                  });
                }
              });
            }
          }
        } else {
          throw new Error("Failed to get OPG Asset Class!");
        }
      }
      if (this.opgClassId != null) {
        this.loadAssetData(this.opgClassId);
      } else {
        throw new Error("Could not find OPG Asset Class!");
      }
    } catch (err) {
      console.error(err);
      toast.error(err);
    }
  };

  loadAssetData = (assetclassId) => {
    let params = {
      entitygroup: assetclassId,
      entity: "",
      starttime: "",
      endtime: "",
      latest: "1",
      themeid: "",
    };

    this.props.getWeatherAssetData(params);
  };


  //load asset data upon selecting time filter in map
  loadAssetHistoryData = () =>{
    if(this.opgClassId && this.state.startDate && this.state.endDate){
      let params = {
        entitygroup: this.opgClassId,
        entity: "",
        starttime: this.state.startDate,
        endtime: this.state.endDate,
        latest: "0",
        themeid: ""
      }

      this.props.getAssetHistoryData(params);
    }
  }

  //hanlde startDate and endDate change in opg map
  handleMapDateChange = ({startDate, endDate}) => {
    this.setState({
      ...this.state,
      startDate: startDate,
      endDate: endDate
    }, ()=>{
      this.loadAssetHistoryData()
    })
  }

  refreshAssetData = (data) => {
    try {
      let subObj = JSON.parse(data);

      if (subObj) {
        let isIncluded = this.props.assetData.some(
          (item) => item.assetid === subObj.device.assetid
        );
        let isDevice = Object.keys(OPG_KEYS).some((key) =>
          Object.key(subObj).includes(key)
        );

        if (isIncluded && isDevice) {
          this.props.updateWeatherAssetData(this.props.assetData, subObj);
        }

        // let isCurrent = (this.state.currentAsset && subObj.device && this.state.currentAsset.assetid === subObj.device.assetid);

        // if(this.state.showHistory && isCurrent && isDevice) {
        //   this.updateHistoryData();
        // };
      }
    } catch (e) {
      //console.log("sub-error-alert", e);
    }
  };

  clearAssetData = () => {};



  async clickShowMe() {
    let res = await axios.get(`${WEB_SERVICE_ROOT}opg/showme`, {});
    this.setState({
      userInfo: res.data,
    });
  }

  async clickGetCalander() {
    let res = await axios.get(`${WEB_SERVICE_ROOT}opg/getCalendar`, {});
    this.setState({
      calendarInfo: res.data,
    });
  }

  async clickGetCalander2() {
    let data = {
      start: document.getElementById("start").value,
      end: document.getElementById("end").value,
    };
    let res = await axios.post(`${WEB_SERVICE_ROOT}opg/getCalendar2`, data);
    this.setState({
      calendarInfo2: res.data,
    });
  }

  //   return (<div>
  //       <h1>Microsoft Outlook</h1>
  //       <h2>
  //           <a style={{color:"blue",cursor:"pointer"}} href={"https://login.microsoftonline.com/d5189ead-72d5-4d23-b57e-1a91ca41a8f6/oauth2/v2.0/authorize?"+encodeURI("client_id=b5b04774-9a9a-45dd-806c-dc8e86bfa007&response_type=code&redirect_uri=http://localhost:3000/pages/OpgApp&response_mode=query&scope=User.Read Calendars.ReadWrite&state=123")}>
  //               click to auth
  //           </a>
  //       </h2>
  //      {/* <p>
  //           <button onClick={()=>this.postGetAccessToken()}>
  //               click to get access token
  //           </button>
  //       </p>*/}
  //       <p>
  //           <button onClick={()=>this.clickShowMe()}>
  //               click to show me
  //           </button>
  //       </p>
  //       {this.state.userInfo!=null?(<div>
  //           mail:{this.state.userInfo.mail}
  //       </div>):null}

  //       <p>
  //           <button onClick={()=>this.clickGetCalander()}>
  //               click to get calendar
  //           </button>
  //       </p>
  //       {this.state.calendarInfo!=null?(<div>
  //           {/*<p>Meeting Name:</p>
  //           <p>Date:</p>
  //           <p>Start Time:</p>
  //           <p>End Time:</p>
  //           <p>Repeat:</p>
  //           <p>People:</p>*/}
  //           calendarInfo:{JSON.stringify(this.state.calendarInfo)}
  //       </div>):null}

  //       <p>
  //           start:<input id={"start"} type="text" defaultValue={"2022-05-05T00:00:00"}  />
  //           end:<input id={"end"} type="text" defaultValue={"2022-05-10T00:00:00"}  />
  //           <button onClick={()=>this.clickGetCalander2()}>
  //               click to get calendar2
  //           </button>
  //       </p>
  //       {this.state.calendarInfo2!=null?(<div>
  //           {/*<p>Meeting Name:</p>
  //           <p>Date:</p>
  //           <p>Start Time:</p>
  //           <p>End Time:</p>
  //           <p>Repeat:</p>
  //           <p>People:</p>*/}
  //           {/*calendarInfo2:{JSON.stringify(this.state.calendarInfo2)}*/}
  //           {
  //               this.state.calendarInfo2.value.map((item,index)=>{
  //                   return (<p>{item.start.dateTime}-{item.end.dateTime}</p>)
  //               })
  //           }
  //       </div>):null}
  //   </div>);

  renderSpinner = () => {
    return (
      <Fragment>
        {this.props.assetDataLoading && (
          <div
            style={{
              position: "Absolute ",
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px",
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              backgroundColor: LIGHT_GREY_COLOR,
              opacity: "0.6",
            }}
          >
            <Spinner
              className="spinner"
              as="span"
              animation="border"
              variant="success"
              style={{ display: "inline-block", margin: "auto" }}
            />
          </div>
        )}
      </Fragment>
    );
  };

  render() {
    let currentAssetData = null;
    let historyObjData = null;

    if (this.state.currentAsset && this.props.assetData) {
      currentAssetData = this.props.assetData.find(
        (item) => item.assetid == this.state.currentAsset.assetid
      );
    }

    if (this.state.currentAsset && this.props.assetHistoryObj) {
      if (this.props.assetHistoryObj[this.state.currentAsset.assetid]) {
        historyObjData = Object.values(
          this.props.assetHistoryObj[this.state.currentAssetData.assetid]
        );
      }
    }
    return (
      <Fragment>
        <TortoiseMainBoard
          userObj={this.props.userObj}
          toggle={this.toggle}
          currentAssetData={currentAssetData}
          assetDataLoading={this.props.assetDataLoading}
          assetData={this.props.assetData}
          assetExpendData={this.props.assetExpendData}
          assetHistoryData={this.props.assetHistoryData}
          statusData={this.state.statusData}
          isSmall={this.state.isSmall}
          handleMapDateChange = {this.handleMapDateChange}
        />
        <div>{this.renderSpinner()}</div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  // const assetGroupData = state.mapboard.assetGroupData;
  // const weatherData = state.weather;
  // return {assetGroupData, weatherData};
  return {
    assetDataLoading: state.weather.assetDataLoading,
    assetData: state.weather.assetData,
    assetExpendData: state.weather.assetExpendData,
    assetHistoryData: state.weather.assetHistoryData,
    assetList: state.asset.assetList

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCompanyAssetClass,
      getWeatherAssetData,
      clearWeatherAssetData,
      getWeatherAlarmCount,
      clearWeatherAlarmCount,
      updateWeatherAssetData,
      updatePubSubAssetData,
      pubsubUpdateWeatherAssetHistoryData,
      getAssetHistoryData
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainBoard);

/*
Display name
:
test app
Application (client) ID:b5b04774-9a9a-45dd-806c-dc8e86bfa007
Object ID:47d13930-4d97-4b09-ad80-f57575ed4c59
Directory (tenant) ID:d5189ead-72d5-4d23-b57e-1a91ca41a8f6
Supported account types:All Microsoft account users


https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/quickStartType//sourceType/Microsoft_AAD_IAM/appId/b5b04774-9a9a-45dd-806c-dc8e86bfa007/objectId/47d13930-4d97-4b09-ad80-f57575ed4c59/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true
DOCUMENTATION:https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/quickStartType//sourceType/Microsoft_AAD_IAM/appId/b5b04774-9a9a-45dd-806c-dc8e86bfa007/objectId/47d13930-4d97-4b09-ad80-f57575ed4c59/isMSAApp//defaultBlade/Overview/appSignInAudience/AzureADandPersonalMicrosoftAccount/servicePrincipalCreated/true
*/
