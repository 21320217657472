/* eslint-disable */
import React, { Component, Fragment } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup } from 'reactstrap';
import {
    Button,
    InputGroup,
    InputGroupAddon,
   } from 'reactstrap';
import Select from 'react-select';
import StepTemplate from './StepTemplate';
import AssetSelectModal from './AssetSelectModal';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';

import {loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff} from '../../../redux/actions/jobAction';
import {loadAllGeoFences} from '../../../redux/actions/fenceAction';
// import {getRuls} from '../../../redux/actions/ruleActions'; 
import {generateSelectOptions} from '../../../factories/utils'
import GeofenceModal from './GeofenceModal';


class StepAnalysisLogic extends Component{
    constructor(props){
        super(props);
        this.state={
            allAssetOptions: [
                {value: 1, label: 'Asset 1'},
                {value: 2, label: 'Asset 2'},
                {value: 3, label: 'Asset 3'}
            ],
            
            AssetDeviceOptions: [
                {value: 1, label: 'Device 1'},
                {value: 2, label: 'Device 2'},
                {value: 3, label: 'Device 3'}
            ],
            
            AssetAttributeOption: [
                {value: -1, label: 'Position'},
                {value: 1, label: 'Speed'},
                {value: 2, label: 'Temperaturn'},
                {value: 3, label: 'Valve'}
            ],

            TypeOption: [
                {value: 1, label: 'Map'},
                {value: 2, label: 'Table'},
                {value: 3, label: 'Chart'}
            ],

            OperationOption: [
                {value: 1, label: 'List'},
                {value: 2, label: 'Count'},
                {value: 3, label: 'Average'},
                {value: 4, label: 'Sum'},
                {value: 5, label: 'Max'},
                {value: 6, label: 'Min'},
            ],

            TimeOption: [
                {value: 1, label: '1 Minute'},
                {value: 1, label: '5 Minutes'},
                {value: 1, label: '30 Minutes'},
                {value: 1, label: '1 Hour'},
                {value: 1, label: '6 Hours'},
                {value: 1, label: '1 Day'},
            ],

            fenceOptions: [],
            
            selectedAssetOption: null,
            selectedDeviceOption: null,
            selectedAttributeOption: null,
            selecteTypeOption: null,
            selectedOperationOption: null,
            selectedTimeOption: null,
            currentFenceId: null,
            currentFenceName: '',
            useDevice: false,
            geofenceModal: false,
            currentDeivecTypeId: '5cf828390499f50b995ae851',
        }
    }

    //-----------------------------------------------------------------------------
    componentDidMount(){
        // this.props.loadAction({actionid:this.props.actionId});
        this.props.loadAllGeoFences({devicetypeid: this.state.currentDeivecTypeId});
        // this.props.getRuls();
    }
    componentWillReceiveProps(nextProps){
        let fenceOptions = [];
        if (nextProps.allfences){
            fenceOptions = generateSelectOptions(nextProps.allfences, 'fencename', 'fenceid');
        }
        this.setState({
            fenceOptions: fenceOptions,
            actionId : nextProps.actionId,
        });
    }
    //-----------------------------------------------------------------------------
    handleAssetChange = (val) => {
         
        // this.props.loadAssetDevice({assetid: val.value});
        // this.props.loadAssetAttribute({assetid: val.value});
        this.setState({
            selectedAssetOption: val,
            selectedDeviceOption: null,
            selectedAttributeOption: null,
        });
    }

    handleDeviceClick = (e) => {
        const value = e.target.checked;
       
        this.setState(preState => {
            const option = value ? preState.selectedDeviceOption : null;
            return ({
                useDevice: value,
                selectedDeviceOption: option,
                // btnDisable: true,
            })
        });
    }

    handleDeviceChange = (val) => {
        //  
        this.setState({
            selectedDeviceOption: val,
            // btnDisable: true,
        });
    }

    handleTypeChange = (val) => {
        this.setState({
            selecteTypeOption: val,
            // btnDisable: true,
        });
    }

    handleAttributeChange  = (val) => {
        //  
        this.setState({
            selectedAttributeOption: val,
            // btnDisable: true,
        });
    }

    OperationChange = (val) => {
        this.setState({
            selectedOperationOption: val,
            // btnDisable: true,
        });
    }

    TimeChange = (val) => {
        this.setState({
            selectedTimeOption: val,
            // btnDisable: true,
        });
    }

    geofenceToggle = () => {
        this.setState(prevState => ({
            geofenceModal: !prevState.geofenceModal,
        }));
    }

    handleGeofenceEditClick= (e) =>{
        // this.currentFenceId = ruleItem.geofenceid;
        this.geofenceToggle();
    }

    renderOperation = () => {
        return(
                <React.Fragment>
                {
                    this.state.selectedAttributeOption && this.state.selectedAttributeOption.value === -1 &&
                    <Col md={2}>
                        <Label style={{ display: 'inline'}} >Geofence</Label>
                        <InputGroup>                            
                            <Input placeholder="geofence..." value = {this.state.currentFenceName}/>
                            <InputGroupAddon addonType="append">
                                <Button color="custom" onClick = {(e) => this.handleGeofenceEditClick(e)} >
                                    <SquareEditOutlineIcon/>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                }

                <Col md={2}>
                    <Label  style={{ display: 'inline'}} >Operation</Label>
                    <Select
                        value= {this.state.selectedOperationOption}  
                        options={this.state.OperationOption}
                        onChange={this.OperationChange}
                    />
                </Col>

                 <Col md={2}>
                    <Label  style={{ display: 'inline'}} >Time</Label>
                    <Select
                        value= {this.state.selectedTimeOption}  
                        options={this.state.TimeOption}
                        onChange={this.TimeChange}
                    />
                </Col>
                
                </React.Fragment>
        )
    }

    renderLogic = ()=> {
        return(
            <div>
                <Row>
                    <Col md={5}>
                        <Label   style={{ display: 'inline'}}>Asset</Label>
                        <Select            
                            value= {this.state.selectedAssetOption}           
                            options={this.state.allAssetOptions}
                            onChange={this.handleAssetChange}
                        />
                    </Col>

                    <Col md={1}></Col>
                   
                    <Col md={5}>
                        <Label  style={{ display: 'inline'}} >
                            <Input type="checkbox" checked={this.state.useDevice} onClick={this.handleDeviceClick}/> Devices
                        </Label>
                        { this.state.useDevice &&
                            <Select
                                value= {this.state.selectedDeviceOption}  
                                options={this.state.AssetDeviceOptions}
                                onChange={this.handleDeviceChange}
                            />
                        }
                    </Col>
                    </Row>
                     <br/>   
                    <Row>
                        <Col md={2}>
                            <Label  style={{ display: 'inline'}} >Type</Label>
                            <Select
                                value= {this.state.selecteTypeOption}  
                                options={this.state.TypeOption}
                                onChange={this.handleTypeChange}
                            />
                        </Col>
                        <Col md={2}>
                            <Label  style={{ display: 'inline'}} >Attributes</Label>
                            <Select
                                value= {this.state.selectedAttributeOption}  
                                options={this.state.AssetAttributeOption}
                                onChange={this.handleAttributeChange}
                            />
                        </Col>
                        {
                            this.state.selectedAttributeOption &&
                            this.renderOperation()
                        }
                    </Row>
                    <br/>
            </div>
        )
    }

    handleMapOnAddClick = (selectedFenceOption) => {
        this.setState({
            currentFenceId: selectedFenceOption.value,
            currentFenceName: selectedFenceOption.label,
        });
    }

    render(){
        return(
            <StepTemplate
                classes = {this.props.classes}
                canGoBack = {this.props.canGoBack}
                canGoForward = {this.props.canGoForward}
                onBack = {this.props.onBack}
                onNext = {this.props.onNext}
                onCancel = {this.props.onCancel}
                text = {this.props.text}
            >
                {/* <div style={{textAlign:'left'}}>
                    <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' onClick={this.handleAddGroupClick} >
                        <PlusCircleOutlineIcon /> Add Group
                    </Button>
                </div> */}
                {/* {this.renderRuleSet()} */}
                {this.renderLogic()}
                {/* <AssetSelectModal 
                    modalSetVisible = {this.state.assetModal}
                    groupId = {this.currentGroupId}
                    ruleId = {this.currentRuleId}
                    ruleItem = {this.currentRule}
                    toggle = {this.modalToggle}
                    onAddClick = {this.handleAssetModalAddClick}
                /> */}
                <GeofenceModal
                    modalSetVisible = {this.state.geofenceModal}
                    toggle = {this.geofenceToggle}
                    allfences = {this.props.allfences}
                    fenceOptions = {this.state.fenceOptions}
                    fenceId = {this.currentFenceId}
                    handleOnAddClick = {this.handleMapOnAddClick}
                />
            </StepTemplate>
        );
    }
}

// export default StepAnalysisLogic;

function mapStateToProps(state) {
     
     
     
    const allfences = state.fence.fenceData;
    // const allrules = state.rule.rules;
    // const props = {...state.job, allfences, allrules};
    const props = {...state.job, allfences};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff, loadAllGeoFences}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StepAnalysisLogic);