/* eslint-disable */
import { axiosAsyncCall } from '../../factories/utils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { WEB_SERVICE_ROOT, WEB_SERVICE_GET_ASSETS_DATA, WEB_SERVICE_THRESHOLD } from '../../constants/appConstants';
import {surveilActionTypes} from '../../constants/actionTypes'


export function getLastMsgBySingleAssetAsync(assetid) {
    let param = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETS_DATA}`,
        method: 'get',
        queryData: {assetid: assetid, latest: 1}
    }
    return axiosAsyncCall(param);
}

export function getAttributeThreshold( assetid, attributeid) {
    let param = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_THRESHOLD}`,
        method: 'get',
        queryData: {assetid: assetid, attributeid: attributeid}
    }
    return axiosAsyncCall(param);
}

export function extractLastMsgs (deviceMsg) {
    let reVal = [];
    if (deviceMsg && deviceMsg.deviceid && deviceMsg.msgs){
        deviceMsg.msgs.forEach((msg, mid)=>{
            let msgObj = null;
            msg.msg && Object.entries(msg.msg).forEach(([key, value])=>{
                if (typeof value === 'object'){
                    let newKey = `${key}-${value.id}`;
                    let newValue = {deviceid: deviceMsg.deviceid, timestamp: msg.timestamp, ...value}
                    if (!msgObj){
                        msgObj = {};
                    }
                    msgObj[newKey] = newValue;
                }              
            });   
            if (msgObj){
                reVal.push(msgObj);
            }         
        });
    }
    return reVal.pop();
} 

export function extractSingleAssetLastMsg (assetData, assetId){
    let reVal = null;
    Array.isArray(assetData) && assetData.every(assetclass => {
        let classContinue = true;
        
            Array.isArray(assetclass.asset) && assetclass.asset.every(asset => {
                let assetContinue = true;
                if (asset.assetid == assetId){
                    
                    Array.isArray(asset.device) && asset.device.forEach(device => {
                        let deviceData = extractLastMsgs(device);
                        
                        if (deviceData) {
                            if (reVal){
                                reVal = {...reVal, ...deviceData}
                            }else{
                                reVal = deviceData;
                            }
                        }
                    });
                    assetContinue = false;
                    classContinue = false;
                }
                return assetContinue;
            });
        return classContinue;
    });
    return reVal;
}

export function extractSingleAssetMetaData (assetData, assetId){
    let reVal = null;
    Array.isArray(assetData) && assetData.every(assetclass => {
        let classContinue = true;
        Array.isArray(assetclass.asset) && assetclass.asset.every(asset => {
            let assetContinue = true;
            if (asset.assetid == assetId){
                reVal = asset.assetattributes;
                assetContinue = false;
                classContinue = false;
            }
            return assetContinue;
        });
        return classContinue;
    });
    return reVal;
}

export function getAttributeUnits (attributname){
    let reVal = '';
    switch (attributname) {
        case 'temp':
            return '\u00b0C';
        case 'hum':
            return '%';
        case 'lum':
            return 'lm'
        // case 'voc':
        case 'pm25':
        case 'smoke':
        case 'CO':
        case 'CO2':
            return 'PPM';
        case 'bd':
            return 'dB'
        default:
            return '';
    }
}

export const addAsset = (assetid) => dispatch => {
    try {
        dispatch ({
            type: surveilActionTypes.ADD_ASSET,
            payload: assetid
        })

    }catch (error) {
        console.log(error)
    }
}

export const loadAssetsSurveillance = () => dispatch => {
    try {
        const assetList = JSON.parse(sessionStorage.getItem("tempList") || "[]")
        // console.log(assetList)
        dispatch ({
            type: surveilActionTypes.LOAD_ASSETS,
            payload: assetList
        })

    }catch (error) { 
        console.log(error)
    }
}

export const clearAssetsSurveillance = () => dispatch => {
    try{
        console.log('CLEARING ASSETS')
        dispatch({
            type: surveilActionTypes.CLEAR_ASSETS,
        })
    }catch(error){
        console.log(error)
    }
}

export const loadLicensePlateRecords = () =>async dispatch => {
    console.log('loading license plates...')
    try{
        const res = await axios.get(`${WEB_SERVICE_ROOT}licensePlateRecord`)
        dispatch({
            type: surveilActionTypes.LOAD_LPR_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        toast.error(e);
        dispatch({
            type: surveilActionTypes.LOAD_LPR_FAILED,
            payload: console.log(e)
        })
    }
}

export const loadFaceRecognitionRecords = () =>async dispatch => {
    console.log('loading face recognition...')
    try{
        const res = await axios.get(`${WEB_SERVICE_ROOT}faceRecognitionRecords`)
        dispatch({
            type: surveilActionTypes.LOAD_FACE_RECOGNITION_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        toast.error(e);
        dispatch({
            type: surveilActionTypes.LOAD_FACE_RECOGNITION_FAILED,
            payload: console.log(e)
        })
    }
}

export const loadFaceDetectionRecords = () =>async dispatch => {
    console.log('loading face detection...')
    try{
        const res = await axios.get(`${WEB_SERVICE_ROOT}faceDetectionRecords`)
        dispatch({
            type: surveilActionTypes.LOAD_FACE_DETECTION_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        toast.error(e);
        dispatch({
            type: surveilActionTypes.LOAD_FACE_DETECTION_FAILED,
            payload: console.log(e)
        })
    }
}

// export const loadPeopleCountRecords = (date) =>async dispatch => {
//     // console.log('loading people count records...')
//     try{
//         const res = await axios.get(
//             `${WEB_SERVICE_ROOT}peopleCountRecordsByDate`,  
//             {
//                  params: {
//                      date: date
//                 } 
//             }
//             )
//         // console.log('PPC DATA', res.data);
//         dispatch({
//             type: surveilActionTypes.LOAD_PEOPLE_COUNT_SUCCEED,
//             payload: res.data
//         })
//     }
//     catch(e){
//         toast.error(e);
//         dispatch({
//             type: surveilActionTypes.LOAD_PEOPLE_COUNT_FAILED,
//             payload: console.log(e)
//         })
//     }
// }
export const loadPeopleCountRecordsByBuildingAndDate = (building, date) =>async dispatch => {
    // console.log('loading people count records...')
    try{
        const res = await axios.get(
            `${WEB_SERVICE_ROOT}peopleCountRecordsByBuildingAndDate`,  
            {
                 params: {
                     building: building,
                     date: date
                } 
            }
            )
        // console.log('PPC DATA', res.data);
        dispatch({
            type: surveilActionTypes.LOAD_PEOPLE_COUNT_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        toast.error(e);
        dispatch({
            type: surveilActionTypes.LOAD_PEOPLE_COUNT_FAILED,
            payload: console.log(e)
        })
    }
}
export const loadCarCountRecordsByBuildingAndDate = (building, date) =>async dispatch => {
    // console.log('loading people count records...')
    try{
        const res = await axios.get(
            `${WEB_SERVICE_ROOT}carCountRecordsByBuildingAndDate`,  
            {
                 params: {
                     building: building,
                     date: date
                } 
            }
            )
        // console.log('PPC DATA', res.data);
        dispatch({
            type: surveilActionTypes.LOAD_CAR_COUNT_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        toast.error(e);
        dispatch({
            type: surveilActionTypes.LOAD_CAR_COUNT_FAILED,
            payload: console.log(e)
        })
    }
}


export const loadCarCountRecords = (date) =>async dispatch => {
    // console.log('loading car count records...')
    try{
        const res = await axios.get(`${WEB_SERVICE_ROOT}carCountRecordsByDate`, 
            {
                params: {
                    date: date
                }
            })
        // console.log('CarC DATA', res.data);
        dispatch({
            type: surveilActionTypes.LOAD_CAR_COUNT_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        toast.error(e);
        dispatch({
            type: surveilActionTypes.LOAD_CAR_COUNT_FAILED,
            payload: console.log(e)
        })
    }
}

//get list of all nvrs
export const loadNVRs = () => async dispatch => {
    try{
        const res = await axios.get(`${WEB_SERVICE_ROOT}getNvrList`)
        dispatch({
            type: surveilActionTypes.LOAD_NVR_LIST_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        toast.error(e);
        dispatch({
            type: surveilActionTypes.LOAD_NVR_LIST_FAILED,
            payload: console.log(e)
        })
    }
}

//add new nvr
export const addNVR = (nvr) => async dispatch => {
    try{
        const res = await axios.post(`${WEB_SERVICE_ROOT}nvr/add`, nvr)
        toast.success('NVR added successfully')

        dispatch({
            type: surveilActionTypes.ADD_NVR_SUCCEED,
            payload: res.data
        })
    }
    catch(e){
        let errorMessage = e.response?.data? e.response?.data : 'Failed to add NVR';
        dispatch({
            type: surveilActionTypes.ADD_NVR_FAILED,
            payload: errorMessage
        })
    }
}

