/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class DeviceTable extends Component {

    constructor(props) {
        super(props);
        this.state = {

            showDeviceModal: false,
            file: null,
            carrierId: 0,
            itemId: 0,
            companyCode:0
        };
    }

    onDeviceClose = () => {
        this.setState({
            showDeviceModal: false
        });
    };
    onDeviceOpen = () => {
         
        this.setState({
            showDeviceModal: true,
            file: null,
            carrierId: this.props.carrierList[0].carrierId,
            itemId: 0,
            companyCode:this.props.currentUserObj.companyCode
        });
    };

    onFileChangeHandler = (e) => {
        this.setState({ file: e.target.files[0] });
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onInputCarrierChange = (event) => {
        if (event.target.value >= 0) {
            this.setState({
                itemCode: this.props.carrierList[event.target.value].name,
                itemDescription: this.props.carrierList[event.target.value].description
            });
        }
        else {
            this.setState({
                itemCode: "",
                itemDescription: ""
            });
        }
    }
    validateForm = (Management) => {
        if (Management == "device") {
            if (this.state.file != null && this.state.carrierId > 0 && this.state.itemId > 0 && this.state.companyCode != null && this.state.companyCode != "") {
                return true;
            }
        }
        
        return false;

    }

    customFormatter = (cell, row) => {
        if (cell != null) {
            return <span style={{ color: "green" }}>TRUE</span>;
        }
        return;
    }

    onDeviceSubmit = () => {
        var formdata = new FormData();
        formdata.append("file", this.state.file);
        formdata.append("carrierId", this.state.carrierId);
        formdata.append("itemId", this.state.itemId);
        formdata.append("companyCode",this.props.currentUserObj.companyCode);
        this.props.uploadFileSims(formdata, "company");
        this.onDeviceClose();
    }

    renderDeviceModal() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showDeviceModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onDeviceClose}>Upload Bulk Devices</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>File Uploader <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <div style={{ float: "left" }}>
                                    <p style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> Only accept .csv files</p>
                                    <input type="file" name="file" accept=".csv" onChange={this.onFileChangeHandler} />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Carriers <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrierId'
                                    id='carrierId'
                                    placeholder='Carrier'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    disabled={false}
                                    value={this.state.carrierId}
                                >
                                    {this.props.carrierList&&this.props.carrierList.map((carrier, index) => (
                                        <option key={index} value={carrier.carrierId}>{carrier.carrierName}</option>
                                    ))}
                                </Input>
                            </Col>

                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Sim Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='itemId'
                                    id='itemId'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    disabled={false}
                                >
                                    <option key={"none"} value={-1}>None Selected</option>
                                    {(this.props.SIMTypeList!=null)?this.props.SIMTypeList.map((item, index) => (
                                        <option key={index} value={item.itemId}>{item.itemName}</option>
                                    )): <option></option>}
                                </Input>
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="button" onClick={this.onDeviceSubmit} disabled={!this.validateForm("device")}>
                        Submit
                        </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onDeviceClose} >
                        Close
                        </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        return (
            <div>
                {this.renderDeviceModal()}
                <InsertButton
                    btnText='Upload Devices'
                    btnContextual='btn-success'
                    btnGlyphicon='glyphicon-edit'
                    onClick={this.onDeviceOpen}
                >
                    Upload Devices
                </InsertButton>
                <BootstrapTable
                        data={this.props.inactiveSIMList}
                        tableHeaderClass='header_white'
                        striped
                        hover

                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        containerStyle={{ fontSize: "1vw" }}

                        pagination
                    >
                        <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} >SIM Name</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='identifierType' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Type' }}>SIM Identifier Type</TableHeaderColumn>
                        <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Value' }}>SIM Identifier Value </TableHeaderColumn>
                    </BootstrapTable>
            </div >
        )
    }
}
export default DeviceTable;