/* eslint-disable */
export const Countries = {
    'CA': {
        'name': 'Canada',
        'provinces': [
            {'abbrev':'AB', 'name':'Alberta'},
            {'abbrev':'BC', 'name':'British Columbia'},
            {'abbrev':'MB', 'name':'Manitoba'},
            {'abbrev':'NB', 'name':'New Brunswick'},
            {'abbrev':'NL', 'name':'Newfoundland and Labrador'},
            {'abbrev':'NS', 'name':'Nova Scotia'},
            {'abbrev':'ON', 'name':'Ontario'},
            {'abbrev':'PE', 'name':'Prince Edward Island'},
            {'abbrev':'QC', 'name':'Quebec'},
            {'abbrev':'SK', 'name':'Saskatchewan'},
            {'abbrev':'NT', 'name':'Northwest Territories'},
            {'abbrev':'YT', 'name':'Yukon'},
            {'abbrev':'NU', 'name':'Nunavut'},
        ],
    },
    'USA': {
        'name': 'USA',
        'provinces': [
            {'abbrev':'AL','name':'Alabama'},
            {'abbrev':'AK','name':'Alaska'},
            {'abbrev':'AS','name':'American Samoa'},
            {'abbrev':'AZ','name':'Arizona'},
            {'abbrev':'AR','name':'Arkansas'},
            {'abbrev':'CA','name':'California'},
            {'abbrev':'CO','name':'Colorado'},
            {'abbrev':'CT','name':'Connecticut'},
            {'abbrev':'DE','name':'Delaware'},
            {'abbrev':'DC','name':'District of Columbia'},
            {'abbrev':'FM','name':'Federated States of Micronesia'},
            {'abbrev':'FL','name':'Florida'},
            {'abbrev':'GA','name':'Georgia'},
            {'abbrev':'GU','name':'Guam'},
            {'abbrev':'HI','name':'Hawaii'},
            {'abbrev':'ID','name':'Idaho'},
            {'abbrev':'IL','name':'Illinois'},
            {'abbrev':'IN','name':'Indiana'},
            {'abbrev':'IA','name':'Iowa'},
            {'abbrev':'KS','name':'Kansas'},
            {'abbrev':'KY','name':'Kentucky'},
            {'abbrev':'LA','name':'Louisiana'},
            {'abbrev':'ME','name':'Maine'},
            {'abbrev':'MH','name':'Marshall Islands'},
            {'abbrev':'MD','name':'Maryland'},
            {'abbrev':'MA','name':'Massachusetts'},
            {'abbrev':'MI','name':'Michigan'},
            {'abbrev':'MN','name':'Minnesota'},
            {'abbrev':'MS','name':'Mississippi'},
            {'abbrev':'MO','name':'Missouri'},
            {'abbrev':'MT','name':'Montana'},
            {'abbrev':'NE','name':'Nebraska'},
            {'abbrev':'NV','name':'Nevada'},
            {'abbrev':'NH','name':'New Hampshire'},
            {'abbrev':'NJ','name':'New Jersey'},
            {'abbrev':'NM','name':'New Mexico'},
            {'abbrev':'NY','name':'New York'},
            {'abbrev':'NC','name':'North Carolina'},
            {'abbrev':'ND','name':'North Dakota'},
            {'abbrev':'MP','name':'Northern Mariana Islands'},
            {'abbrev':'OH','name':'Ohio'},
            {'abbrev':'OK','name':'Oklahoma'},
            {'abbrev':'OR','name':'Oregon'},
            {'abbrev':'PW','name':'Palau'},
            {'abbrev':'PA','name':'Pennsylvania'},
            {'abbrev':'PR','name':'Puerto Rico'},
            {'abbrev':'RI','name':'Rhode Island'},
            {'abbrev':'SC','name':'South Carolina'},
            {'abbrev':'SD','name':'South Dakota'},
            {'abbrev':'TN','name':'Tennessee'},
            {'abbrev':'TX','name':'Texas'},
            {'abbrev':'UT','name':'Utah'},
            {'abbrev':'VT','name':'Vermont'},
            {'abbrev':'VI','name':'Virgin Islands'},
            {'abbrev':'VA','name':'Virginia'},
            {'abbrev':'WA','name':'Washington'},
            {'abbrev':'WV','name':'West Virginia'},
            {'abbrev':'WI','name':'Wisconsin'},
            {'abbrev':'WY','name':'Wyoming'},
            {'abbrev':'AE','name':'Armed Forces Africa'},
            {'abbrev':'AA','name':'Armed Forces Americas (except Canada)'},
            {'abbrev':'AE','name':'Armed Forces Canada'},
            {'abbrev':'AE','name':'Armed Forces Europe'},
            {'abbrev':'AE','name':'Armed Forces MiddleEast'},
            {'abbrev':'AP','name':'Armed Forces Pacific'},
        ],
    },
    'Paraguay': {
        'name':'Paraguay',
        'provinces':[
            {'abbrev':'Areguá','name':'Areguá'},
            {'abbrev':'Asunción','name':'Asunción'},
            {'abbrev':'Ayolas','name':'Ayolas'},
            {'abbrev':'Caacupé','name':'Caacupé'},
            {'abbrev':'Caaguazú','name':'Caaguazú'},
            {'abbrev':'Capiatá','name':'Capiatá'},
            {'abbrev':'Ciudad del Este','name':'Ciudad del Este'},
            {'abbrev':'Concepción','name':'Concepción'},
            {'abbrev':'Coronel Oviedo','name':'Coronel Oviedo'},
            {'abbrev':'Encarnación','name':'Encarnación'},
            {'abbrev':'Fernando de la Mora','name':'Fernando de la Mora'},
            {'abbrev':'Hernandarias','name':'Hernandarias'},
            {'abbrev':'Itá','name':'Itá'},
            {'abbrev':'Itauguá','name':'Itauguá'},
            {'abbrev':'Lambaré','name':'Lambaré'},
            {'abbrev':'Limpio','name':'Limpio'},
            {'abbrev':'Luque','name':'Luque'},
            {'abbrev':'Mariano Roque Alonso','name':'Mariano Roque Alonso'},
            {'abbrev':'Mariscal Estigarribia','name':'Mariscal Estigarribia'},
            {'abbrev':'Minga Guazú','name':'Minga Guazú'},
            {'abbrev':'Ñemby','name':'Ñemby'},
            {'abbrev':'Pedro Juan Caballero','name':'Pedro Juan Caballero'},
            {'abbrev':'Pilar','name':'Pilar'},
            {'abbrev':'Presidente Franco','name':'Presidente Franco'},
            {'abbrev':'San Antonio','name':'San Antonio'},
            {'abbrev':'San Estanislao','name':'San Estanislao'},
            {'abbrev':'San Ignacio','name':'San Ignacio'},
            {'abbrev':'San Lorenzo','name':'San Lorenzo'},
            {'abbrev':'Villa Elisa','name':'Villa Elisa'},
            {'abbrev':'Villa Hayes','name':'Villa Hayes'},
            {'abbrev':'Villarrica','name':'Villarrica'},
            {'abbrev':'Villeta','name':'Villeta'},
            ]
    }
    /*'Paraguay': {
        'name':'Paraguay',
        'provinces':[
            {'abbrev':'Asunción','name':'Asunción'},
            {'abbrev':'Ciudad del Este','name':'Ciudad del Este'},
            {'abbrev':'San Lorenzo','name':'San Lorenzo'},
            {'abbrev':'Luque','name':'Luque'},
            {'abbrev':'Capiatá','name':'Capiatá'},
            {'abbrev':'Lambaré','name':'Lambaré'},
            {'abbrev':'Fernando de la Mora','name':'Fernando de la Mora'},
            {'abbrev':'Limpio','name':'Limpio'},
            {'abbrev':'Ñemby','name':'Ñemby'},
            {'abbrev':'Encarnación','name':'Encarnación'},
            {'abbrev':'Mariano Roque Alonso','name':'Mariano Roque Alonso'},
            {'abbrev':'Pedro Juan Caballero','name':'Pedro Juan Caballero'},
            {'abbrev':'Villa Elisa','name':'Villa Elisa'},
            {'abbrev':'Caaguazú','name':'Caaguazú'},
            {'abbrev':'Coronel Oviedo','name':'Coronel Oviedo'},
            {'abbrev':'Hernandarias','name':'Hernandarias'},
            {'abbrev':'Presidente Franco','name':'Presidente Franco'},
            {'abbrev':'Itauguá','name':'Itauguá'},
            {'abbrev':'Concepción','name':'Concepción'},
            {'abbrev':'Villarrica','name':'Villarrica'},
            {'abbrev':'San Antonio','name':'San Antonio'},
            {'abbrev':'Pilar','name':'Pilar'},
            {'abbrev':'Caacupé','name':'Caacupé'},
            {'abbrev':'Itá','name':'Itá'},
            {'abbrev':'Mariscal Estigarribia','name':'Mariscal Estigarribia'},
            {'abbrev':'Villa Hayes','name':'Villa Hayes'},
            {'abbrev':'Minga Guazú','name':'Minga Guazú'},
            {'abbrev':'San Ignacio','name':'San Ignacio'},
            {'abbrev':'San Estanislao','name':'San Estanislao'},
            {'abbrev':'Ayolas','name':'Ayolas'},
            {'abbrev':'Villeta','name':'Villeta'},
            {'abbrev':'Areguá','name':'Areguá'},
        ]
    }*/
}

export const USAGE_PRICE = 'Usage Based';

export const languages = [
{ value: 'en', label: 'English' },
{ value: 'fr', label: 'French' }];

////////// Weather APP


export const ATTRIBUTE_NAME = {
    LAT: 'latitude',
    LNG: 'longitude',
    GRP: 'grouping'
}

export const ALARM_META_ATTRI_OBJ = {
    attributename: 'Lightning',
    displayname: 'Lightning',
    display: true,
    selected: true,
    backgroundcolor: '#186df5',
    timestamp: '',
    value: 'Alarm',
    units: ''
}
export const ATTRIBUTE_LABEL = [
    {
        labelname:'Station',
        displayname:'Station',
    },
    {
        labelname:'Last_Massage',
        displayname:'Last Message'
    }
];
export const ATTRIBUTE_NAME_ORDER = ['Temperature_max', 'Temperature','Temperature_min','Rain','Humidity','Irradiation','Wind_ave10','Wind_max10','Pressure'];
export const ATTRIBUTE_NAME_ORDER_PARKING = ['LevelTotal', 'Level1', 'Level2', 'Level3'];
// export const ATTRIBUTE_NAME_ORDER_PARKING = ['TotalVehicle', 'Level1', 'Level2', 'Level3'];
export const ATTRIBUTE_ORDER = [
    {
        attributename: 'hexAlarm',
        displayname: 'Lightning',
        display: true,
        selected: false,
        hasrule: true,
        backgroundcolor: '#186df5',
        units: ''
    },
    {
        attributename: 'rotorFault',
        displayname: 'Lightning',
        display: false,
        selected: false,
        hasrule: true,
        backgroundcolor: '#186df5',
        units: ''
    },
    {
        attributename: 'Temperature',
        displayname: 'Temperature',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#C18500',
        units: '\u00b0C',
        group: [
            {
                attributename: 'Temperature_min',
                units: '\u00b0C',
            },
            {
                attributename: 'Temperature',
                units: '\u00b0C',
            },
            {
                attributename: 'Temperature_max',
                units: '\u00b0C',
            },
            // {
                //     attributename: 'T_min',
                //     units: '\u00b0C',
                // },
            // {
                //     attributename: 'Temperature',
                //     units: '\u00b0C',
                // },
                // {
            //     attributename: 'T_max',
            //     units: '\u00b0C',
            // },
        ],
    },
    {
        attributename: 'Rain',
        displayname: 'Rain',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: 'black',
        units: 'mm'
    },
    {
        attributename: 'Humidity',
        displayname: 'Humidity',
        display: false,
        selected: true,
        hasrule: true,
        backgroundcolor: '#009E73',
        units: '%'
    },
    {
        attributename: 'Irradiation',
        displayname: 'Irradiation',
        display: false,
        selected: true,
        hasrule: true,
        backgroundcolor: '#AF1F70',
        units: 'W/m2'
    },
    {
        attributename: 'Pressure',
        displayname: 'Pressure',
        display: false,
        selected: true,
        hasrule: true,
        backgroundcolor: '#56B4E9',
        units: 'hPa'
    },
    {
        attributename: 'Temperature_max',
        displayname: 'Temp Max',
        display: false,
        selected: true,
        hasrule: true,
        backgroundcolor: '#926500',
        units: '\u00b0C'
    },
    {
        attributename: 'Temperature_min',
        displayname: 'Temp Min',
        display: false,
        selected: true,
        hasrule: true,
        backgroundcolor: '#FFBF00',
        units: '\u00b0C'
    },
    // {
        //     attributename: 'Temperature',
        //     backgroundcolor: 'red',
        // },
        /*
        {
            attributename: 'CO2',
            displayname: 'CO2',
            display: true,
            selected: true,
            hasrule: true,
            backgroundcolor: '#FF50B1',
            units: 'PPM'
    },*/
    {
        attributename:'Wind_ave10',
        displayname: 'Wind Avg',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#0072B2',
        units: 'km/h'
        // units: 'm/s'
    },
    {
        attributename:'Dir_ave10_txt',
        displayname: 'Dir Avg',
        display: true,
        selected: false,
        hasrule: true,
        backgroundcolor: '#blue',
        units: ''
    },
    {
        attributename:'Wind_max10',
        displayname: 'Wind Max',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#F24599',
        units: 'km/h'
        // units: 'm/s'
    },
    {
        attributename:'Dir_max10_txt',
        displayname: 'Dir Max',
        display: false, // Hided display on WeatherApp page.
        selected: false,
        hasrule: true,
        backgroundcolor: 'blue',
        units: ''
    },
    {
        attributename: 'Battery',
        displayname: 'Weather',
        display: true,
        selected: false,
        hasrule: true,
        backgroundcolor: 'green',
        units: 'V'
    },
    {
        attributename: 'Battery',
        displayname: 'Wind',
        display: true,
        selected: false,
        hasrule: true,
        backgroundcolor: 'green',
        units: 'V'
    },

];
export const ATTRIBUTE_TYPE = {
    META:'meta',
    DEVICE: 'device',
}
export const ATTRIBUTE_WIND_ORDER = [
    {
        attributename:'Dir_ave10_txt',
        displayname: 'Dir Avg',
        display: true,
        selected: false,
        hasrule: true,
        backgroundcolor: '#blue',
        units: ''
    },
    {
        attributename:'Wind_ave10',
        displayname: 'Wind Avg',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#0072B2',
        units: 'km/h'
        // units: 'm/s'
    },
    {
        attributename:'Wind_max10',
        displayname: 'Wind Max',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#F24599',
        units: 'km/h'
        // units: 'm/s'
    },
    // {
    //     attributename:'Dir_max10_txt',
    //     displayname: 'Dir Max',
    //     display: true,
    //     selected: false,
    //     hasrule: true,
    //     backgroundcolor: 'blue',
    //     units: ''
    // },
    {
        attributename: 'Battery',
        displayname: 'Battery',
        display: true,
        selected: false,
        hasrule: true,
        backgroundcolor: 'green',
        units: 'V'
    }

];
export const POWER_MANAGEMENT_DEVICETYPE_ID = '62b065d5a1a7dd5f0f03f7af';
export const POWER_ATTRIBUTE_ORDER = [
    {
        attributename:'Sensor01',
        displayname: 'Sensor01',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#blue',
        units: ''
    },
    {
        attributename:'Battery',
        displayname: 'Battery',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#0072B2',
        units: 'volts'
    },
    {
        attributename:'In1',
        displayname: 'Input1',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#56B4E9',
        units: ''
    },
    {
        attributename:'In2',
        displayname: 'Input2',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#0072B2',
        units: ''
        // units: 'm/s'
    },
    {
        attributename:'In3',
        displayname: 'Input3',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#F24599',
        units: ''
        // units: 'm/s'
    },
    {
        attributename:'PS',
        displayname: 'PowerSave',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#C18500',
        units: ''
        // units: 'm/s'
    },
        {
        attributename:'Alm',
        displayname: 'Alarm',
        display: true,
        selected: true,
        hasrule: true,
        backgroundcolor: '#AF1F70',
        units: ''
        // units: 'm/s'
    },
];

export const WHITE_COLOR = '#ffffff';
export const GREEN_COLOR = '#00cc00';
export const RED_COLOR = '#ff0000';
export const ORENGE_COLOR = '#ffa500';
export const YELLOW_COLOR = '#ffff00';
export const GREY_COLOR = 'grey';
export const FAULT_COLOR = '#808080';
export const LIGHT_GREY_COLOR = '#F8F8F8';
export const LEGEND_LIST = [
    /* {value: 'OFF', color: GREY_COLOR, label: 'No Activity'},
    {value: 'RED', color: RED_COLOR, label: 'Lightning near (<5km)'},
    {value: 'ORANGE', color: ORENGE_COLOR, label: 'Lightning approaching (5km to 15km)'},
    {value: 'YELLOW', color: YELLOW_COLOR, label: 'Lightning Detected (+15km)'},
    {value: 'GREEN', color: GREEN_COLOR, label: 'All Clear'}, */

    // Client Sam.Kennedy asked for new logic of Lightning label below:
    {fault: false, value: 'RED', color: RED_COLOR, label: 'Lightning Danger Close (<5 km)'},
    {fault: false, value: 'ORANGE', color: ORENGE_COLOR, label: 'Lightning Near (5 to 10 km)'},
    {fault: false, value: 'YELLOW', color: YELLOW_COLOR, label: 'Lightning Detected (10 to 32 km)'},
    {fault: false, value: 'OFF', color: GREY_COLOR, label: 'Missed Message'},
    {fault: false, value: 'GREEN', color: GREEN_COLOR, label: 'All Clear'},
    // To keep recent alarm and display rotor fault if next alarm is fault. (label: recent Alarm + "!!");
    // If recent alarm is fault, display Rotor Fault.
    // {fault: true, value: 'RED', color: RED_COLOR, label: 'Lightning Danger Close (<5 km)'},
    // {fault: true, value: 'ORANGE', color: ORENGE_COLOR, label: 'Lightning Near (5 to 10 km)'},
    // {fault: true, value: 'YELLOW', color: YELLOW_COLOR, label: 'Lightning Detected (10 to 32 km)'},
    // {fault: true, value: 'OFF', color: GREY_COLOR, label: 'Missed Message'},
    // {fault: true, value: 'GREEN', color: GREEN_COLOR, label: 'All Clear'},
    // {fault: true, value: 'FAULT', color: FAULT_COLOR, label: 'Rotor Fault'},
    // {value: 'FAULT', color: FAULT_COLOR, label: 'Rotor Fault'},
];

export const LIGHTNING_ROTOR_FAULT = [
    {fault: true, value: 'FAULT', color: FAULT_COLOR, label: 'Rotor Fault'},
    {fault: false, value: 'FAULT', color: GREEN_COLOR, label: 'Rotor Normal'},
    {fault: false, value: 'OFF', color: GREY_COLOR, label: 'Rotor OFF'},
]
    
export const LIGHTING_KEYS = {
    battery: 'BatteryVoltage',
    internalTemp: 'InternalTemperature',
    alarmStatus: 'CurrentDigitalInputState',
    levelColor: 'Alarm'
};
export const HOUR_OF_DAY = 24;
export const TIME_OPTION = [
    {label: '00:00', value:'00', index: 0},
    {label: '01:00', value:'01', index: 1},
    {label: '02:00', value:'02', index: 2},
    {label: '03:00', value:'03', index: 3},
    {label: '04:00', value:'04', index: 4},
    {label: '05:00', value:'05', index: 5},
    {label: '06:00', value:'06', index: 6},
    {label: '07:00', value:'07', index: 7},
    {label: '08:00', value:'08', index: 8},
    {label: '09:00', value:'09', index: 9},
    {label: '10:00', value:'10', index: 10},
    {label: '11:00', value:'11', index: 11},
    {label: '12:00', value:'12', index: 12},
    {label: '13:00', value:'13', index: 13},
    {label: '14:00', value:'14', index: 14},
    {label: '15:00', value:'15', index: 15},
    {label: '16:00', value:'16', index: 16},
    {label: '17:00', value:'17', index: 17},
    {label: '18:00', value:'18', index: 18},
    {label: '19:00', value:'19', index: 19},
    {label: '20:00', value:'20', index: 20},
    {label: '21:00', value:'21', index: 21},
    {label: '22:00', value:'22', index: 22},
    {label: '23:00', value:'23', index: 23},
    {label: '24:00', value:'24', index: 24},
];
export const TIME_RANGE_OPTION = [
    {label: '1hr', value: 1},
    {label: '2hr', value: 2},
    {label: '3hr', value: 3},
    {label: '4hr', value: 4},
    {label: '5hr', value: 5},
    {label: '6hr', value: 6},
];
// export const TIME_ZONE = 'EST';
export const TIME_ZONE = 'America/New_York';
export const labelTextStyle = {
    fontSize: '13.1px',
    lineHeight: '13.1px',
}
export const normalTextStyle = {
    fontSize: '1.7vh',
    lineHeight: '1.7vh',
}
export const xSmallTextStyle = {
    fontSize: '1.2vh',
    lineHeight: '1.3vh',
}
export const smallTextStyle = {
    fontSize: '1.5vh',
    lineHeight: '1.5vh',
}

export const OCCUPANCY_KEYS = {
    battery: 'BatteryVoltage',
    levelColor: 'Alarm',
    lastOccupancy:'LastOccupancy',
    lstMsgTime:'LastMessageTime',
    averageData:'AverageData'
};

export const PARKING_KEYS = {
    battery: 'BatteryVoltage',
    levelColor: 'Alarm',
    lstMsgTime:'LastMessageTime',
    level1:'Level1',
    level2:'Level2',
    level3:'Level3',
    levelTotal:'LevelTotal'
}
export const SNOW_KEYS = {
    battery: 'BatteryVoltage',
    levelColor: 'Alarm',
    lastOccupancy:'LastOccupancy',
    lstMsgTime:'LastMessageTime',
    averageData:'AverageData',
    averageTemperature: 'AverageTemperature',
    averageLODLast: 'AverageLODLast',
    temperature:'Temperature',
    current: 'Current',
    vibration: 'Vibration'
};

export const OPG_KEYS = {
    messageId: 'MessageId',
    battery: 'Battery',
    moveDetect: 'MoveDetect',
    beaconVisible: 'BeaconVisible',
    gpsLock: 'GPSLock',
    gpsUsed: 'GPSUsed',
    hdop: 'HDOP',
    cnOk: 'C/N0_OK',
    beaconId: 'BeaconId',
    latitude: 'Latitude',
    longitude: 'Longitude',
    zoneId: 'ZoneId',
};

export const ATTRIBUTE_STATUSDATA=['Alarm','CO2','Dir_ave10','Dir_ave10','Dir_hi10','Dir_lo10','Dir_max10','Dir_max10_txt','Dir_ave10_txt','hexAlarm','Humidity','Irr_max','Irradiation','Pressure','Rain','Rain-Raw','T_max','T_min','Temperature','Temperature_max','Temperature_min','Type','Wind_ave10','Wind_max10','Wind_min10']
export const PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3=[255,73,83,99]