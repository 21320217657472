/* eslint-disable */

import React, {useEffect, useState, useRef} from 'react'
import JSMpeg from "@cycjimmy/jsmpeg-player";
import {VIDEO_WS_ROOT} from '../../../constants/appConstants'

function ReactPlayer({ch, chid, height, width, type}) {
    const playerPPCRef = useRef(null)
    const playerCCRef = useRef(null)
    const playerPPC = useRef(null)
    const playerCC = useRef(null)
    useEffect(() => {
        if(ch && chid && type){
            // console.log('RENDER COUNTING PLAYER', ch, chid)
            let url = `${VIDEO_WS_ROOT}/socketSecure/${ch}/${chid}`
            if(type=="ppc"){
                playerPPC.current = new JSMpeg.VideoElement("#ppc-canvas", url)
            }else{
                playerCC.current = new JSMpeg.VideoElement("#cc-canvas", url)
            }
        }
        // console.log(player);
        return ()=>{
            if(playerPPC.current){
                try{
                    playerPPC.current.destroy();
                    playerPPC.current = null
                }catch(err){
                    console.error(err);
                }
            }
            if(playerCC.current){
                try{
                    playerCC.current.destroy();
                    playerCC.current = null
                }catch(err){
                    console.error(err);
                }
            }
        }
    }, [ch, chid])
    if(ch&&chid){
        if(type =="ppc"){
            return (
                    <div id="ppc-canvas" style={{ height: `${height}`, width: `${width}` }}></div>
                )
            }else{
                return (
                    <div id="cc-canvas" style={{ height: `${height}`, width: `${width}` }}></div>
            )
        }
    }else{
        return null
    }
}

export default ReactPlayer
