/* eslint-disable */
import React, { Component } from 'react';
import {
    Col, Container, Row, Card, CardBody, Label,
    Input, Button, Modal, ModalHeader, ModalBody,
    ModalFooter, Form, FormGrou, UncontrolledDropdown,
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DeviceTypeTableStyles from './DeviceTypeTable.module.css';
import './DeviceTypeTableStyles.css';
import { EditTextButton } from '../../../../shared/components/Button/IconButton';
import DatePicker from 'react-date-picker';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { ExcelRenderer, OutTable } from 'react-excel-renderer';
import MonthYearPicker from 'react-month-year-picker';
import ShowTableModal from './ShowTableModal';
import { EmojiObjects } from '@material-ui/icons';
import { getCurrentUser } from '../../../../factories/auth';
import { take, takeRight } from 'lodash'

const billingFiles = [
    { fileName: "Headers" },
    { fileName: "Details" },
    { fileName: "Special Lines" },
];

const options = {
    paginationPosition: 'bottom'
};

class DeviceTypeTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showItemModal: false,
            // simId: 0,
            // simReseller: 0,
            // simPrice: 0,
            // simItemDescription: "",
            // simItemName: "",
            // carrier:-1

            date: "",
            dataFormat: ".xlsx",
            downloadFlag: false,
            month: (new Date().getMonth()),     // this maps to the previous month
            year: 1900 + (new Date().getYear()),
            billingMonthYear: -1,
            lastTwelveMonths: [],
            cols: [''],
            rows: [''],
            file: null,
            showTable: true,
            showTable1: false,
            showTable2: false,
            showTable3: false,
            showFilePreview: false,
            isFetchingBilling: false,
            billingFilePreviewSelected: false,
            billingFilePreviewIdx: 0,
            sheetData: {},
            inPost: [],
            data1: {},
            data2: {},
            data3: {},
            userObj: getCurrentUser(),
            datasheetList:[]
        };
        this.onChangeBillingFilePreview = this.onChangeBillingFilePreview.bind(this)
        this.onChangeMonthYear = this.onChangeMonthYear.bind(this);
        this.onItemSubmit = this.onItemSubmit.bind(this);
    }

    componentDidMount() {
        let { lastTwelveMonths } = this.state
        let today = new Date();
        let nameOfMonths = new Array("January", "February", "March",
            "April", "May", "June",
            "July", "August", "September",
            "October", "November", "December");

        let lastMonth = new Date(today.getFullYear(), today.getMonth()/* - 1*/);
        for (let m = 0; m <= 11; m++) {
            let monthYearObj = {
                year: lastMonth.getFullYear(),
                month: nameOfMonths.indexOf(nameOfMonths[lastMonth.getMonth()]) + 1,
                label: (nameOfMonths[lastMonth.getMonth()] + ' ' + lastMonth.getFullYear())
            }
            lastTwelveMonths.push(monthYearObj)
            lastMonth.setMonth(lastMonth.getMonth() - 1);
        }
        this.setState({ lastTwelveMonths });
    }

    componentWillReceiveProps() {
    }

    onChangeMonthYear = (event) => {
        console.log(`MonthYear: ${event.target.value}`)
        let { showFilePreview, billingMonthYear, lastTwelveMonths, year, month, } = this.state
        billingMonthYear = parseInt(event.target.value)
        showFilePreview = false
        if (billingMonthYear !== -1) {
            year = lastTwelveMonths[billingMonthYear].year
            month = lastTwelveMonths[billingMonthYear].month
        }
        this.setState({ showFilePreview, billingMonthYear, year, month })
    }

    onChangeBillingFilePreview = (idx) => {
        console.log('onChangeBillingFilePreview', idx)
        let { billingFilePreviewIdx, billingFilePreviewSelected } = this.state;
        billingFilePreviewIdx = idx;
        billingFilePreviewSelected = false
        this.setState({ billingFilePreviewIdx, billingFilePreviewSelected })
        // setTimeout(() => {
        //     this.setState({ billingFilePreviewSelected: true })
        // }, 500)



        /* work on data here */
        let fileObj;
        if (this.props.dFile != null && this.props.dFile != undefined) {
            if (this.props.dFile.file != null && this.props.dFile.file != undefined) {
                fileObj = this.props.dFile.file[idx];
                ExcelRenderer(fileObj, (err, resp) => {
                    if (err) {
                        console.log(err);
                    } else {

                        let colData = resp.cols
                        let rowData = resp.rows

                        let data = {}
                        let columns = []
                        let rows = []

                        for (let i = 0; i < colData.length; i++) {
                            columns.push(
                                {
                                    label: colData[i].name,
                                    field: colData[i].name,
                                    sort: 'asc',
                                    width: 150
                                }
                            )
                        }


                        for (let j = 0; j < rowData.length; j++) {
                            var localrow = rowData[j]
                            var obj = {};
                            for (let i = 0; i < colData.length; i++) {
                                obj[colData[i].name] = localrow[i]
                            }
                            rows.push(obj)
                        }

                        // setup headers
                        let alphabeticHeaders = []
                        for (const [key, value] of Object.entries(rows[0])) {
                            alphabeticHeaders.push(key);
                        }

                        let specialHeadersA = []
                        for (const [key, value] of Object.entries(rows[0])) {
                            specialHeadersA.push(value);
                        }

                        let specialHeadersB = []
                        for (const [key, value] of Object.entries(rows[1])) {
                            specialHeadersB.push(value);
                        }

                        data = {
                            columns,
                            rows,
                            alphabeticHeaders,
                            specialHeadersA,
                            specialHeadersB
                        }


                        setTimeout(() => {
                            this.setState({ sheetData: data, billingFilePreviewSelected: true })
                        }, 500)
                    }
                });
            }
        }
    }

    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 365) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }

    cellItemEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.onEditItemOpen(row) }} />
            </div>
        );
    };

    onItemOpen = () => {
        var date = new Date();
        date.setMonth(new Date().getMonth() - 1)
        // var month = date.getMonth();
        // var options = { month: 'long'};
        // var showMonth=new Intl.DateTimeFormat('en-US', options).format(date);
        this.setState({
            showItemModal: true,
            // simId: 0,
            // simReseller: 0,
            // simItemDescription: "",
            // simPrice: "",
            // simItemName: "",
            // carrier:-1
            date: date,
            dataFormat: ".csv"
        })
        //console.log(this.state.date)
    }

    onEditItemOpen = (row) => {

        this.setState({
            showItemModal: true,
            simId: row.itemId,
            companyCode: this.props.currentUserObj.companyCode,
            simItemDescription: row.itemDescription,
            simPrice: row.unitPrice,
            simItemName: row.itemName,
            carrier: row.carrier.carrierId
        })

    }
    onItemClose = () => {
        this.setState({
            showItemModal: false
        })
    }

    onInputChange = (event) => {
        //console.log(event)
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onDateChange = (event) => {

        this.setState({
            date: event

        });
    };


    validateForm = (Management) => {

        if (this.state.date != "" && this.state.dataFormat != "") {

            return true;

        }

        return false;

    }

    onItemSubmit = () => {
        let { isFetchingBilling } = this.state;
        isFetchingBilling = true;
        this.setState({ isFetchingBilling })

        let data = {};

        let fromDate = new Date()
        fromDate.setDate(1);
        fromDate.setMonth(this.state.month - 1)
        fromDate.setYear(this.state.year)

        let toDate = new Date(fromDate)
        toDate.setMonth(fromDate.getMonth() + 1, 1)
        toDate.setDate(toDate.getDate() - 1);

        if (this.validateForm()) {
            data = {
                "companyId": this.props.currentUserObj.companyId,    //currently it is null use companyCode
                "companyCode": this.props.currentUserObj.companyCode,
                "fromDate": fromDate,
                "toDate": toDate,
                "dataFormat": this.state.dataFormat,
            }
        }
        else {
            data = {
                "companyId": this.props.currentUserObj.companyId,  //currently it is null
                "companyCode": this.props.currentUserObj.companyCode,
                "fromDate": fromDate,
                "toDate": toDate,
                "dataFormat": this.state.dataFormat,
            }
        }
        this.props.getSAPCSV(data).then((response) => {
            console.log('getSAPCSV', response)
            if (response.data && response.data.message
                && response.data.message !== "There is no data for the selected month.") {

                let { isFetchingBilling } = this.state;
                isFetchingBilling = false;
                this.setState({ showTable: true, showFilePreview: true, datasheetList:response.data.datasheetList, isFetchingBilling })
            } else {
                let { isFetchingBilling } = this.state;
                isFetchingBilling = false;
                this.setState({ isFetchingBilling })
            }
        }).catch((error) => {
            let { isFetchingBilling } = this.state;
            isFetchingBilling = false;
            this.setState({ isFetchingBilling })
        });
    }


    onFileDownload = () => {
        var fileName = ''
        var fileExtension = '.csv'
        if (this.props.dFile != null && this.props.dFile != undefined) {
            if (this.props.dFile.file != null && this.props.dFile.file != undefined) {
                // new download for csv
                var sheetList = this.state.datasheetList
                var out1 = ""
                var data1 = sheetList[0]

                for (let i = 1; i <= Object.keys(data1).length; i++) {
                    var row_i = data1[i]
                    out1 += row_i.join(",") + "\n"
                }


                var out2 = ""
                var data2 = sheetList[1]

                for (let i = 1; i <= Object.keys(data2).length; i++) {
                    var row_i2 = data2[i]
                    out2 += row_i2.join(",") + "\n"
                }


                var out3=""
                var data3 =sheetList[2]

                for (let i = 1; i <= Object.keys(data3).length; i++) {
                    var row_i3=data3[i]
                    out3+=row_i3.join(",")+"\n"
                }

                var contentType = 'csv';
                var csvFile1 = new Blob([out1], {type: contentType});
                var csvFile2 = new Blob([out2], {type: contentType});
                var csvFile3 = new Blob([out3], {type: contentType});
                // FileSaver.saveAs(this.props.dFile.file[3], fileName + fileExtension);
                FileSaver.saveAs(csvFile1, fileName+"Headers" + fileExtension);
                FileSaver.saveAs(csvFile2, fileName+"Details" + fileExtension);
                FileSaver.saveAs(csvFile3, fileName+"Special Lines" + fileExtension);
                /*FileSaver.saveAs(this.props.dFile.file[0], fileName + fileExtension);
                FileSaver.saveAs(this.props.dFile.file[1], fileName + fileExtension);
                FileSaver.saveAs(this.props.dFile.file[2], fileName + fileExtension);*/
            }
            else {
                toast.error('No data for this time period')
            }
        }
        else {

            toast.error('No data for this time period')
        }
    }

    renderItemModal() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showItemModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onItemClose}>Download File</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Month and Year <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <DatePicker
                                    name='date'
                                    id='date'
                                    placeholder='Month and Year'
                                    className="form-control"
                                    onChange={this.onDateChange}
                                    value={this.state.date}
                                    required={true}
                                />

                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>File Format <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='dataFormat'
                                    id='dataFormat'
                                    placeholder='File Format'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.dataFormat}
                                    required={true}
                                //disabled={true}
                                >
                                    <option key={1} value='.csv'>CSV File</option>
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        {/* <Row>
                            <Col md={2} >
                                <span>Description <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                {(this.state.simId != 0) ?
                                    <Input
                                        type='select'
                                        name='simItemDescription'
                                        id='simItemDescription'
                                        placeholder='Item Type'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        disabled={true}
                                        value={this.state.simItemDescription}
                                    >
                                        {["None Selected", "SIM CARD"].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </Input>
                                    :

                                    <Input
                                        type='select'
                                        name='simItemDescription'
                                        id='simItemDescription'
                                        placeholder='Item Type'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        disabled={false}
                                        defaultValue={this.state.simItemDescription}
                                    >
                                        {["None Selected", "SIM CARD"].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </Input>
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Price <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simPrice'
                                    id='simPrice'
                                    placeholder='Price'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.simPrice}
                                />
                            </Col>
                        </Row> */}
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="reset" onClick={this.onItemSubmit} disabled={!this.validateForm("item")}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onItemClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        const { pageContentList, manualBillingStatus } = this.props

        const { billingMonthYear, showFilePreview, showTable,
            isFetchingBilling, billingFilePreviewSelected, billingFilePreviewIdx,
            sheetData } = this.state

        return (
            <div>
                <Col md={12}>
                    <Row style={{ paddingTop: '20px' }}>
                        <Col md={3} className={"p-0"}>
                            <label for="billingMonthYear">Billing Month</label>
                            <Input
                                type='select'
                                name='billingMonthYear'
                                id='billingMonthYear'
                                placeholder='Select month and year'
                                onChange={(event) => {
                                    !isFetchingBilling && this.onChangeMonthYear(event)
                                }}
                                className="form-control float-left"
                                disabled={isFetchingBilling}
                                value={this.state.billingMonthYear}
                            >
                                <option key={-1} value={-1}>Select a Billing Month</option>
                                {
                                    this.state.lastTwelveMonths
                                    && this.state.lastTwelveMonths.length > 0
                                    && this.state.lastTwelveMonths.map((billMonth, index) =>
                                    (<option
                                        key={index}
                                        value={index}>{billMonth.label}
                                    </option>))
                                }
                            </Input>
                        </Col>
                        <Col md={2}>
                            <div className="form-group row" style={{ marginLeft: '0px' }}>
                                <label for="dataFormat">File Format</label>
                                <div className="p-0 col-md-12">
                                    <Input
                                        //type='select'
                                        name='dataFormat'
                                        id='dataFormat'
                                        placeholder='File Format'
                                        disabled={isFetchingBilling}
                                        onChange={(event) => {
                                            !isFetchingBilling && this.onInputChange(event)
                                        }}
                                        className="form-control"
                                        defaultValue='CSV File'
                                        required={true}
                                        disabled={true}
                                    >
                                        {/*<option key={1} value='.csv'>CSV File</option>*/}
                                    </Input>
                                </div>
                            </div>
                        </Col>
                        {
                            !showFilePreview && billingMonthYear >= 0 &&
                            (<Col md={2}>
                                <div className="form-group row" style={{ marginLeft: '0px' }}>
                                    <label for="get-files">Options</label>
                                    <div className="p-0 col-md-12">

                                        {<button type="button"
                                            id="billingApp"
                                            className={`btn btn-secondary ${DeviceTypeTableStyles['integration-button']}`}
                                            disabled={isFetchingBilling || manualBillingStatus}
                                            onClick={() => {
                                                !isFetchingBilling && this.onItemSubmit()
                                            }}
                                        >
                                            Get Files
                                        </button>}

                                        {/*
                                                <InsertButton
                                                    id={"billingApp-get-files"}
                                                    btnText='Get Files'
                                                    btnContextual='btn-success'
                                                    btnGlyphicon='glyphicon-edit'
                                                    disabled={isFetchingBilling}
                                                    onClick={() => {
                                                        !isFetchingBilling && this.onItemSubmit()
                                                    }}
                                                />
                                                */}
                                    </div>
                                </div>
                            </Col>)
                        }
                        {
                            showFilePreview &&
                            (<div className="col-auto">
                                <div className="form-group row" style={{ marginLeft: '0px' }}>
                                    <label for="files">Files</label>
                                    <div className="p-0 col-md-12">


                                        {/*
                                        <div className="dropdown">
                                            <button className="btn btn-success dropdown-toggle" type="button" id="preview" data-toggle="dropdown" aria-expanded="false">
                                                Preview Files from Billing
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="preview">
                                                <a className="dropdown-item" href="#">Action</a>
                                                <a className="dropdown-item" href="#">Another action</a>
                                                <a className="dropdown-item" href="#">Something else here</a>
                                            </div>
                                        </div>
                                        */}

                                        {/*


                                            <UncontrolledDropdown id={"preview"}>
                                                <DropdownToggle color="success" caret>
                                                    Preview Files from Billing
                                                </DropdownToggle>
                                                <div className="dropdown-menu" aria-labelledby="preview">
                                                    <a className="dropdown-item" href="#">Action</a>
                                                    <a className="dropdown-item" href="#">Another action</a>
                                                    <a className="dropdown-item" href="#">Something else here</a>
                                                </div>
                                            </UncontrolledDropdown>

                                            */}

                                        {
                                            <UncontrolledDropdown id={"preview"}>
                                                <DropdownToggle color="secondary" caret>
                                                    Preview Files from Billing
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {billingFiles.map((fileDetail, index) => (
                                                        <DropdownItem key={index} onClick={() => {
                                                            this.onChangeBillingFilePreview(index)
                                                        }}>{fileDetail.fileName}</DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        }

                                    </div>
                                </div>
                            </div>)
                        }
                        {
                            showFilePreview &&
                            (<div className="p-0 col-md-2">
                                <div className="form-group row" style={{ marginLeft: '0px' }}>
                                    <label for="download-files">Options</label>
                                    <div className="p-0 col-md-12">

                                        {/*
                                            <InsertButton
                                                id={"download-files"}
                                                btnText='Download Files'
                                                btnContextual='btn-success'
                                                btnGlyphicon='glyphicon-edit'
                                                onClick={this.onFileDownload.bind(this)}
                                                pagination
                                            />
                                            */}

                                        {<button type="button"
                                            id="billingApp"
                                            className={`btn btn-secondary ${DeviceTypeTableStyles['integration-button']}`}
                                            disabled={isFetchingBilling}
                                            onClick={this.onFileDownload.bind(this)}
                                        >
                                            Download Files
                                        </button>}
                                    </div>
                                </div>
                            </div>)
                        }
                    </Row>
                </Col>
                <div>
                    {
                        showFilePreview
                        && showTable
                        && billingFilePreviewSelected
                        && `Currently selected billing sheet: ${billingFiles[billingFilePreviewIdx].fileName}`
                    }
                </div>
                {
                    showFilePreview
                    && showTable
                    && billingFilePreviewSelected
                    && (<BootstrapTable
                        ref={`sheetPreviewTable${billingFilePreviewIdx}`}
                        data={takeRight(sheetData.rows, sheetData.rows.length - 2)}
                        options={options}
                        tableHeaderClass='header_white'
                        striped
                        hover
                        containerStyle={{ fontSize: "1vw" }}
                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        pagination
                        exportCSV={pageContentList.includes("DeviceTypeTable ExportCSV *NO*") ? false : true}
                    >
                        {
                            sheetData.alphabeticHeaders.map((header, index) => {
                                return <TableHeaderColumn
                                    row='0'
                                    isKey={header === 'A'}
                                    width='150'
                                    key={index}
                                    ref={header + 'sheet'}
                                    dataField={header}
                                    dataAlign='center'
                                    export={false}
                                    csvHeader={header}
                                >
                                    {header}
                                </TableHeaderColumn>
                            })
                        }
                        {
                            sheetData.alphabeticHeaders.map((header, index) => {
                                return <TableHeaderColumn
                                    row='1'
                                    width='150'
                                    key={index}
                                    ref={header + 'sheet'}
                                    dataField={header}
                                    dataAlign='center'
                                    export={false}
                                    csvHeader={header}
                                >
                                     {sheetData.specialHeadersA[index]}
                                </TableHeaderColumn>
                            })
                        }
                         {
                            sheetData.alphabeticHeaders.map((header, index) => {
                                return <TableHeaderColumn
                                    row='2'
                                    width='150'
                                    key={index}
                                    ref={header + 'sheet'}
                                    dataField={header}
                                    dataAlign='center'
                                    export={false}
                                    csvHeader={header}
                                >
                                    {sheetData.specialHeadersB[index]}
                                </TableHeaderColumn>
                            })
                        }
                    </BootstrapTable>)
                }
                <div style={{ height: '150px' }}></div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let {
        manualBillingStatus
    } = state.inventory;

    return { manualBillingStatus };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypeTable);