/* eslint-disable */
import React, { Component } from 'react';
import { CardBody, Card } from 'reactstrap';
import '../../Company/components/smallcard.scss';
import { sortCaret } from '../../../shared/components/Table/tableElements';

import PaymentBoard from "../../PaymentModule/component/PaymentBoard"

class ClientPaymentBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountList: [],
            defaultAccount: null,
            number: "",
            name: "",
            expiry: "",
            cvc: "",
            issuer: "",
            focused: "",
            formData: null
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.accountList.length > 0) {
            this.setState({
                accountList: nextProps.accountList
            })
        }
    }
    onRadioClick = (card) => {
        if (this.props.isAdmin) {
            var cardList = [...this.state.accountList];
            var defaultAccount = null;
            for (var payment of cardList) {
                if (payment.accountId === card.id) {
                    payment.defaultPayment = "Y";
                    defaultAccount = payment;

                }
                else {
                    payment.defaultPayment = "N";
                }
            }
            this.setState({
                accountList: cardList,
                defaultAccount: defaultAccount
            }, () => {
                if (this.state.defaultAccount != null) {
                    this.props.setDefaultPaymentMethod(this.state.defaultAccount).then(() => {
                        this.props.getPaymentAccounts(this.props.selectedRow.id)
                    });
                }
            })
        }
    }

    onSaveCreditCard = (card) => {
        if (this.props.selectedRow != null) {
            var card = {
                "resellerCode": this.props.selectedRow.resellerCode,
                "account": {
                    "accountId": card.id,
                    "accountName": card.name,
                    "optionalName": card.nickName,
                    "accountType": "CC",
                    "cardMask": card.number,
                    "companyCode": this.props.selectedRow.companyCode,
                    "defaultPayment": "N",
                    "expdate": card.expiry,
                    "paymentMethod": "CC",
                    "resellerId": this.props.selectedRow.id,
                    "status": card.status,
                    "issuerId": card.issuer,
                    "code": card.cvc
                }
            }
            this.props.updatePaymentMethod(card).then(() => {
                this.props.getPaymentAccounts(this.props.selectedRow.id)
            });
        }
    }
    priceFormater = (cell, row) => {
        let price = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
        }
        return (
            <span>{`$${price}`}</span>
        )
    }
    csvPriceFormater = (cell, row) => {
        let price = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
        }
        return (`$${price}`)
    }
    dateFormatter = (cell, row) => {
        if (cell != null) {
            var date = new Date(cell)
            var str = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            return (`${str}`);
        }
    }
    nameFormatter = (cell, row) => {
        var name = ""
        if (cell != null) {
            name = cell;
        } else {
            name = row.cardMask;
        }
        return (`${name}`);
    }
    render() {
        var cardList = this.state.accountList.map((card) => {
            return {
                id: card.accountId,
                type: card.accountType,
                name: card.accountName,
                number: card.cardMask,
                selected: card.defaultPayment,
                nickName: card.optionalName,
                cvc: "",
                expiry: card.expdate,
                issuer: card.issuerId
            }
        });
        let list = [];
        if (this.props.transactionHistory != null) {
            if (Array.isArray(this.props.transactionHistory.historyList)) {
                list = this.props.transactionHistory.historyList.slice(0).reverse();
            }

        }

        const options = {
            sizePerPageList: [ {
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            } ],
        };


        return (
            <Card>
                <CardBody className="mainCardBody">
                    <PaymentBoard
                        list={cardList}
                        isAdmin={this.props.isAdmin}
                        onRadioClick={this.onRadioClick}
                        onSave={this.onSaveCreditCard}
                        RadioLabel={"Set as default"}
                    />
                    <h3>Payment History</h3>
                    <br />
                    <BootstrapTable
                        data={list}
                        tableHeaderClass='header_white'
                        bordered={false}
                        pagination={true}
                        options={options}
                        exportCSV
                        csvFileName={'Payment_History.csv'}

                    >
                        <TableHeaderColumn dataField='transId' caretRender={sortCaret} isKey={true} dataAlign="center" width='10%' hidden >Transaction Id</TableHeaderColumn>
                        <TableHeaderColumn dataField='optionalName' caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} dataAlign="center" dataSort width='10%' dataFormat={this.nameFormatter.bind(this)} csvFormat={this.nameFormatter} csvHeader='Name' >Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='transAmount' caretRender={sortCaret} filter={{ type: 'NumberFilter', delay: 1000, placeholder: ' ' }} dataAlign="center" dataFormat={this.priceFormater.bind(this)} csvFormat={this.csvPriceFormater} dataSort width='10%' csvHeader='Amount' >Amount</TableHeaderColumn>
                        <TableHeaderColumn dataField='transDate' caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} dataAlign="center" dataFormat={this.dateFormatter.bind(this)} csvFormat={this.dateFormatter} dataSort width='10%' csvHeader='Initial Date' >Initial</TableHeaderColumn>
                        <TableHeaderColumn dataField='updateDate' caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} dataAlign="center" dataFormat={this.dateFormatter.bind(this)} csvFormat={this.dateFormatter} dataSort width='10%' csvHeader='Update Date' >Update</TableHeaderColumn>
                        <TableHeaderColumn dataField='statusDesc' caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} dataAlign="center" dataSort width='10%' csvHeader='Status' >Status</TableHeaderColumn>
                        <TableHeaderColumn dataField='msg' caretRender={sortCaret} filter={{ type: 'TextFilter', placeholder: ' ' }} dataAlign="center" dataSort width='10%' csvHeader='Message' >Message</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card >

        );
    }

}
export default ClientPaymentBoard;