/* eslint-disable */
import React,{Component} from 'react';


class SelectAssign extends Component {
    constructor(props) {


        super(props);
        this.state = {

            value:this.props.value
        };

        this.selectChange = this.onSelectChange.bind(this);

    }

    onSelectChange(){
        let response = {username:'',templateId:'',value:'',changeStatus:false}

        this.setState((preState)=>{
            response.username = this.props.row.username;
            response.templateId = this.props.id;
            response.value = !preState.value;
            if(response.value != this.props.value){response.changeStatus = true}
            this.props.getSelectChange(response);
                return({
                    value: !preState.value,
                });
            }
        );

        this.props.getSelectChange()

    }



    render() {

        console.log('this.state.value',this.state.value)
        return(
            <div>
                <select
                    onChange={this.selectChange}
                    value={this.state.value}
                >
                    <option value={true}>Assigned</option>
                    <option value={false}>Unassigned</option>
                </select>
             </div>
        )
    }
}

export default SelectAssign;
