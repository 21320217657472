/* eslint-disable */
import React, {useContext} from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { hasRole } from '../../factories/auth';
import { axiosGet, getCurrentUser, base64Encode, getJwttoken } from '../../factories/utils';
import { UserContext } from '../../constants/myContexts';

import LogIn from '../LogIn/index';
import LogOut from '../Logout/index';
import ExamplePageOne from '../Example/index';
import ExamplePageTwo from '../ExampleTwo/index';
import ExamplePageThree from '../ExampleThree/index';
import ExamplePageFour from '../ExampleFour/index';
import MapPage from '../Map/index';
import AttributePage from '../Attribute/index';
import SearchUser from '../AccountMgmt/index';
import EventPage from '../Event/index';
import AssetPage from '../Asset/index';
//import DevMangagePage from '../DeviceManage/index';
import DevicesPage from '../SurveillanceDevicesManagement/index';
import ReportsPage from '../Reports/index';
import GeofencePage from '../GeoFence/index';
import DeviceDetail from '../DeviceDetail/index';
import DashBoardIndex from '../DashBoard/index';
import AttributeMgmtPage from '../AttributeMgmt/index';
import ActionPage from '../Action/index';
import ActionNotificationPage from '../ActionNotification';
import ActionEditPage from '../ActionEdit/index';
import ActionNotificationEditPage from '../ActionNotificationEdit';
import Notifications from '../AlertComponent/index';
import BasicLayout from '../AlertComponent/components/LayoutTest';
import CompanyPage from '../Company/index';
import MainBoard from '../Map/components/MainBoard';
import MapBoard from '../Map/components/MapBoard';
import InventoryPage from '../Inventory/index';
import DeviceMasterDetail from '../DeviceMasterDetail/index';
import DeviceSigfoxSignUp from '../DeviceSigfoxSignUp/index';
import AssetSchemaPage from '../Schema/index';
import UserProfile from '../Profile/index';
import NotificationTemplate from '../NotificationTemplate/index';
import DashBoardSetting from '../DashBoardSetting/index';
import DeviceConfigPage from '../Device/index';
import SupportPage from '../Support/index';
import SurveillancePage from '../Surveillance/index';
import PaymentGateway from '../Company/index';
import TelecomPage from '../TelecomApp/index';
import BillingPage from '../BillingApp/index'
import ClientPage from '../Client/index';
import ConsumerPage from '../Consumer/index';
//import WeatherBoard from '../WeatherApp/index';
import WeatherBoard from '../Weather/index';
import SettingPage from '../Setting/index';
import OccupancyBoard from '../Occupancy/index';
import SnowBoard from '../Snow/index';
import OpgBoard from '../Opg/index';
import TemplateBoard from '../Template/index';
import TemplatePageBoard from '../TemplatePage/index';
import GarageBoard from '../Garage/index';
import ForgotPasswordForm from '../LogIn/components/ForgotPasswordForm';
import ResetPasswordForm from '../LogIn/components/ResetPasswordForm';
import ThemeSwitcher from 'react-css-vars';
import PowerManagementBoard from '../PowerMgmt/index';
import { bindActionCreators } from 'redux';
import SidebarLink from "../Layout/sidebar/SidebarLink";
import TimedOutPromptModal from './TimedOutPromptModal';
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';

const Pages = () => {
    const contextValue = useContext(UserContext);
    let currentUser = contextValue.userObj;


    return (
        <>
            <Switch>
                {currentUser.sidebarContent.includes("Dashboard") && <Route path="/pages/dashboard" component={DashBoardIndex} />}
                {/*<Route path="/pages/one" component={ExamplePageOne} />*/}
                {/*<Route path="/pages/two" component={ExamplePageTwo} />*/}
                {/*<Route path="page/three" component={ExamplePageThree} />*/}
                {/*<Route path="page/three" component={ExamplePageFour} />*/}
                <Route path="/pages/map" component={MapPage} />
                {currentUser.sidebarContent.includes("Attribute Theme") && <Route path="/pages/attribute/setting" component={AttributePage} />}
                {currentUser.sidebarContent.includes("Users") && <Route path="/pages/accountmgmt" component={SearchUser} />}
                {currentUser.sidebarContent.includes("Asset") && <Route path="/pages/asset" component={AssetPage} />}
                {currentUser.sidebarContent.includes("Reporting") && <Route path="/pages/reports" component={ReportsPage} />}
                {/*<Route path="/pages/event" component={EventPage} />*/}
                {currentUser.sidebarContent.includes("Geofence") && <Route path="/pages/geofence" component={GeofencePage} />}
                <Route path="/pages/device/:id" component={DeviceDetail} />
                <Route path="/pages/devicemaster/:id" component={DeviceMasterDetail} />
                {currentUser.sidebarContent.includes("Attribute Setting") && <Route path="/pages/attribute/management" component={AttributeMgmtPage} />}
                {currentUser.sidebarContent.includes("Event") && <Route path="/pages/action/" component={ActionPage} />}
                {currentUser.sidebarContent.includes("actionNotification") && <Route path="/pages/actionNotification/" component={ActionNotificationPage} />}
                <Route path="/pages/actionedit/:id" component={ActionEditPage} />
                <Route path="/pages/actionnotificationedit/:id" component={ActionNotificationEditPage} />
                {currentUser.sidebarContent.includes("Company") && <Route path="/pages/company" component={CompanyPage} />}
                {currentUser.sidebarContent.includes("Device Config") && <Route path="/pages/deviceconfig" component={DeviceConfigPage} />}
                {currentUser.sidebarContent.includes("Client") && <Route path="/pages/client" component={ClientPage} />}
                {currentUser.sidebarContent.includes("Consumer") && <Route path="/pages/consumer" component={ConsumerPage} />}
                {/* <Route path="/pages/notification/test" component={BasicLayout} /> */}
                {/* <Route path="/pages/notification/test" component={MainBoard} /> */}
                {/*<Route path="/pages/notification/test" component={MapBoard} />*/}
                {currentUser && hasRole(currentUser, ['SUPERADMIN']) && <Route path="/pages/inventory" component={InventoryPage} />}
                <Route path="/pages/devicesignup/:id" component={DeviceSigfoxSignUp} />
                <Route path="/pages/assetschema" component={AssetSchemaPage} />

                <Route path="/pages/userprofile" component={UserProfile} />
                {/*<Route path="/pages/setting" component={SettingPage} />*/}
                {currentUser.sidebarContent.includes("Template") && <Route path="/pages/notificationtemplate" component={NotificationTemplate} />}
                {currentUser.sidebarContent.includes("Dashboard Setting") && <Route path="/pages/dashboardsetting" component={DashBoardSetting} />}
                <Route path="/pages/support" component={SupportPage} />
                {currentUser.sidebarContent.includes("Smart Building") && <Route path="/pages/surveillance" component={SurveillancePage} />}
                {/*{currentUser.sidebarContent.includes("Smart Building") && <Route path="/pages/devicemanageorigin" component={DevMangagePage} />}*/}
                {currentUser.sidebarContent.includes("Smart Building") && <Route path="/pages/devicemanage" component={DevicesPage} />}

                {/*<Route path="/pages/paymentgateway" component={PaymentGateway} />*/}
                {currentUser.sidebarContent.includes("Telecom App") && <Route path="/pages/TelecomApp" component={TelecomPage} />}
                {currentUser.sidebarContent.includes("Billing App") && <Route path="/pages/BillingApp" component={BillingPage} />}
                {currentUser.sidebarContent.includes("Weather App") && <Route path="/pages/WeatherApp" component={WeatherBoard} key={Math.random()}/>}
                {currentUser.sidebarContent.includes("Occupancy App") && <Route path="/pages/OccupancyApp" component={OccupancyBoard} key={Math.random()}/>}
                {currentUser.sidebarContent.includes("Garage App") && <Route path="/pages/ParkingApp" component={GarageBoard} />}
                {currentUser.sidebarContent.includes("Snow App") && <Route path="/pages/SnowApp" component={SnowBoard} key={Math.random()}/>}
                {currentUser.sidebarContent.includes("Opg App") && <Route path="/pages/OpgApp" component={OpgBoard} />}
                {currentUser.sidebarContent.includes("Power Management") && <Route path="/pages/PowerManagement" component={PowerManagementBoard} />}

                {/* ------- Waiv new layout  ------- */}
                {currentUser.sidebarContent.includes("Template App") && <Route path="/pages/TemplateApp" component={TemplateBoard} />}
                {currentUser.sidebarContent.includes("Template Page App") && <Route path="/pages/TemplatePageApp" component={TemplatePageBoard} />}

            </Switch >
        <TimedOutPromptModal/>
      </>
    )
}


export default Pages