/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Container, Card, CardBody, Row, Col, Button, Table, Input, Label } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import {
    sortCaret,
    AdjustableTableHeaderColumn,
    AdjustableTableHeaderColumn2,
    // AdjustableDiv 
} from '../../../shared/components/Table/tableElements';
// import { Resizable, ResizableBox } from 'react-resizable';
import { ATTRIBUTE_TYPE, ATTRIBUTE_NAME, TIME_ZONE } from '../../../constants/dataConstants';
import { weatherSeverity, mapWeatherSeverity } from '../../../factories/utils';
import { groupBy } from '../../../factories/utils';
import { data } from 'jquery';

const ATTRIBUTE_ALARM = 'CurrentDigitalInputState';
const ATTRIBUTE_BATTERY = 'BatteryVoltage';

class StatusTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    msgid: 1,
                    assetname: 'sensor 1',
                    timestamp: '2020-02-19',
                    msgtype: 'alarm',
                    msgvalue: '1',
                },
                {
                    msgid: 2,
                    assetname: 'sensor 2',
                    timestamp: '2020-02-19',
                    msgtype: 'alarm',
                    msgvalue: '2',
                },
                {
                    msgid: 3,
                    assetname: 'sensor 3',
                    timestamp: '2020-02-19',
                    msgtype: 'alarm',
                    msgvalue: '3',
                },
                {
                    msgid: 4,
                    assetname: 'sensor 4',
                    timestamp: '2020-02-19',
                    msgtype: 'alarm',
                    msgvalue: '1',
                },
                {
                    msgid: 5,
                    assetname: 'sensor 1',
                    timestamp: '2020-02-19',
                    msgtype: 'battery',
                    msgvalue: '4',
                },
                {
                    msgid: 6,
                    assetname: 'sensor 2',
                    timestamp: '2020-02-19',
                    msgtype: 'battery',
                    msgvalue: '1',
                },
                {
                    msgid: 7,
                    assetname: 'sensor 3',
                    timestamp: '2020-02-19',
                    msgtype: 'battery',
                    msgvalue: '3',
                },
                {
                    msgid: 8,
                    assetname: 'sensor 4',
                    timestamp: '2020-02-19',
                    msgtype: 'battery',
                    msgvalue: '2',
                },
                {
                    msgid: 9,
                    assetname: 'sensor 1',
                    timestamp: '2020-02-19',
                    msgtype: 'temperature',
                    msgvalue: '27',
                },
                {
                    msgid: 10,
                    assetname: 'sensor 2',
                    timestamp: '2020-02-19',
                    msgtype: 'temperature',
                    msgvalue: '27',
                },
                {
                    msgid: 11,
                    assetname: 'sensor 3',
                    timestamp: '2020-02-19',
                    msgtype: 'temperature',
                    msgvalue: '27',
                },
                {
                    msgid: 12,
                    assetname: 'sensor 4',
                    timestamp: '2020-02-19',
                    msgtype: 'temperature',
                    msgvalue: '27',
                },
            ],
        };
    }

    assetnameFormat = (cell, row) => {
        const handleDeviceOnClick = (e, assetname) => {
            e.preventDefault();

            // let deviceData = chooseDevice(this.props.assetData, deviceId);

            // let deviceData = cell.find((item, index) => (item.deviceid === deviceId));

            // this.setState({
            //     showDetails: true,
            //     curretnDeviceId: deviceId,
            //     currentDeviceData: deviceData,
            // });
            this.props.toggle(true, row);
        }
        return (
            <a href={'#'} onClick={(e) => { handleDeviceOnClick(e, cell); }}>{cell}</a>
        )
    }

    timestampFormat = (cell, row) => {
        if (cell) {
            if (cell.charAt(0) == "+") {
                cell = new Date().getFullYear() + cell.substr(6);
            }
            let displayTime = new Date(cell).toLocaleString('en-US', { timeZone: TIME_ZONE });
            return (<span>{displayTime}</span>);
        } else {
            return null
        }
    }

    valueFormat = (cell, row) => {
        let attr = row.msgtype;
        if ((ATTRIBUTE_ALARM === attr && 3 == mapWeatherSeverity(cell)) || (ATTRIBUTE_BATTERY === attr && 1 == cell)) { // weatherSeverity

            return <span style={{ backgroundColor: 'red', color: 'white' }}>{cell}</span>;
        }
        else {
            return <span style={{ color: 'black' }}>{cell}</span>;
        }
    }

    valueClassFormat = (cell, row, rowIdx, colIdx) => {
        let attr = row.msgtype;
        if ((attr === 'alarm' && cell == 3) || (attr === 'battery' && cell == 1)) {

            return 'td-column-alarm';
        }
        else {
            return null;
        }
    }

    createCustomExportCSVButton = (onClick) => {
        return (
            <ExportCSVButton
                btnText='Down CSV'
                onClick={() => this.handleExportCSVButtonClick(onClick)} />
        );
    }
    handleExportCSVButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        onClick();
    }

    renderAttrTable = () => {
        let cols = [
            {
                field: 'assetid',
                displayName: 'Asset ID',
                isKey: true,
                hidden: true
            },
            {
                field: 'assetname',
                displayName: 'Sensor',
                dataSort: false,
                hidden: true,
            },
            {
                field: 'attritime',
                displayName: 'Timestamp',
                dataFormat: this.timestampFormat,
                dataSort: true,
            },
        ];
        let colsFields = cols.map((item) => (item.field));
        let data = this.props.tableData ?
            this.props.tableData.filter((item, index) => (
                item.type !== ATTRIBUTE_TYPE.META &&
                item.display !== false &&
                // item.assetname !=='Alarm' 
                item.displayname !== 'Alarm' &&
                item.displayname !== 'CO2'
                // item.timestamp !== item.attritime
            )) : [];


        // let group = {};
        // for (let item of data) {
        //     const {assetid, assetname, attritime} = item;
        //     let key = JSON.stringify({assetid, assetname, attritime})
        //     if (group[key] && Array.isArray(group[key])){
        //         group[key].push(item);
        //     }else{
        //         group[key]=[item];
        //     }
        // }
        //  
        let dataMap = groupBy(data, item => item.attritime);


        // let tableData = [{assetid: '1', assetname: 'test', attritime: '2020'}]; 
        // for (let key in group){
        //     let assetObj = JSON.parse(key);
        //     for (let value of group[key]) {
        //         assetObj[value.attributename] = value.value;
        //         cols.push({
        //             field: value.attributename,
        //             displayName: value.displayname,
        //             dataSort: true,
        //         });
        //     }
        //     tableData.push(assetObj);
        // }
        let tableData = [];
        for (const map of dataMap) {
            for (const [key, value] of Object.entries(map)) {
                const { assetid, assetname, attritime } = value[0];
                let assetObj = { assetid, assetname, attritime };
                for (let item of value) {
                    if (!Object.keys(assetObj).includes(item.attributename)) {
                        assetObj[item.attributename] = item.value;
                    }
                    if (!colsFields.includes(item.attributename)) {
                        cols.push({
                            field: item.attributename,
                            displayName: item.displayname,
                            dataSort: true,
                        });
                        // colsFields = cols.map((item)=>(item.field));
                        colsFields.push(item.attributename);
                    }
                }
                tableData.push(assetObj);
            }
        }
        //  
        //  
        //  

        let maxHeight = 400;
        let options = {
            defaultSortName: 'attritime',  // default sort column name
            defaultSortOrder: 'desc',  // default sort order
            exportCSVBtn: this.createCustomExportCSVButton
        };
        return (
            <Fragment>
                {/* <Card style={{padding: '0', border: '1px solid black'}}>
                <CardBody style={{padding: '10px'}}> */}
                <BootstrapTable
                    // data={this.state.data} 
                    data={tableData}
                    tableHeaderClass='header_white'
                    pagination={true}
                    options={options}

                    hover
                    bordered={true}
                    //maxHeight={maxHeight}
                    // containerStyle={{overflowY: 'auto'}}
                    tableStyle={{ minWidth: '900px' }}
                    printable
                    exportCSV
                //bodyStyle={{overflow: 'overlay'}}
                >
                    {cols && cols.map((col, cindex) => (
                        <TableHeaderColumn
                            dataField={col.field}
                            isKey={col.isKey ? true : false}
                            hidden={col.hidden ? true : false}
                            dataSort={col.dataSort ? true : false}
                            caretRender={col.dataSort ? sortCaret : undefined}
                            dataFormat={col.dataFormat ? col.dataFormat : undefined}
                            // {...(col.dataSort ? {caretRender: {sortCaret}} : {})}
                            // {...(col.dataFormat ?  {dataFormat: {col.dataFormat}} : {})}
                            dataAlign='center'
                        >
                            {col.displayName}
                        </TableHeaderColumn>
                    ))}

                </BootstrapTable>
                {/* </CardBody>
            </Card> */}
            </Fragment>
        )
    }

    renderAlarmTable() {

        let data = this.props.tableData ? this.props.tableData.filter((item, index) => (item.type !== ATTRIBUTE_TYPE.META && item.display !== false)) : [];
        console.log(data)
        let DataDisplay = 'Data';
        let AttrHidden = false;
        let maxHeight = 400;
        if (this.props.filterAttr) {
            data = data.filter((item) => (item.displayname === this.props.filterAttr));
            data = data.filter((item, index, self) => (
                index === self.findIndex((t) => (t.timestamp === item.timestamp))
            )
            )
            DataDisplay = this.props.filterAttr;
            AttrHidden = true;
        }
        if (this.props.maxHeight) {
            maxHeight = this.props.maxHeight;
        }
        let options = {
            defaultSortName: 'timestamp',  // default sort column name
            defaultSortOrder: 'desc',  // default sort order
            exportCSVBtn: this.createCustomExportCSVButton

        };
        return (
            <Fragment>
                {/* <Card style={{padding: '0', border: '1px solid black'}}>
                <CardBody style={{padding: '10px'}}> */}
                <BootstrapTable
                    // data={this.state.data} 
                    data={data}
                    tableHeaderClass='header_white'
                    pagination={true}
                    options={options}

                    hover
                    bordered={true}
                    //maxHeight={maxHeight}
                    //containerStyle={{overflowY: 'auto'}}
                    tableStyle={{ minWidth: '300px' }}
                    printable
                    exportCSV
                //bodyStyle={{overflow: 'overlay'}}
                >
                    <TableHeaderColumn dataField='assetid' isKey={true} hidden={true} dataAlign='center' >Asset ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='assetname' dataAlign='center' dataFormat={this.assetnameFormat} dataSort caretRender={sortCaret} >Sensor</TableHeaderColumn>
                    <TableHeaderColumn dataField='timestamp' dataAlign='center' caretRender={sortCaret} dataFormat={this.timestampFormat} dataSort  >Timestamp </TableHeaderColumn>
                    <TableHeaderColumn dataField='displayname' dataAlign='center' dataSort caretRender={sortCaret} hidden={AttrHidden} >Message Type </TableHeaderColumn>
                    <TableHeaderColumn dataField='value' dataAlign='center' columnClassName={this.valueClassFormat} dataSort caretRender={sortCaret}>{DataDisplay} </TableHeaderColumn>
                </BootstrapTable>
                {/* </CardBody>
            </Card> */}
            </Fragment>
        );
    }

    render() {
        // 
        return (
            <Fragment>

                {
                    (this.props.currentAsset != null) ?
                        (this.props.currentAsset.grouping != "wind") ?
                            <div>
                                <h4>Occupancy Alarm</h4>
                                <br />
                                {this.renderAlarmTable()}
                            </div>
                            :
                            <span></span>
                        :
                        <span></span>
                }


                <br />
                <br />
                <div>
                    <h4>Station Attributes</h4>
                    <br />
                    {this.renderAttrTable()}
                </div>
            </Fragment>
        );
    }
}

export default StatusTable;