/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Card, CardBody, Input, Row, Col, Collapse } from 'reactstrap';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { BackButton, NextButton } from '../../../shared/components/Button/IconButton';
import PaymentBoard from "../../PaymentModule/component/PaymentBoard"
import { toast } from "react-toastify";

import OrderSummary from './PurchaseSIM/OrderSummary';

class ClientPurchaseBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cart: [],
            orderSummary: [],
            step: 1
        }
    }
    componentDidMount() {

    }
    handleNext = () => {
        if (this.state.step == 1) {
            if (this.state.cart <= 0) {
                toast.error("Please add item to cart");
                return;
            }
            for (let item of this.state.cart) {
                if (item.quantity <= 0) {
                    toast.error("item quantity less than 0");
                    return;
                }
            }
            this.buildOrderSummary();
        }
        this.setState(prevState => ({
            step: prevState.step + 1,
        }));
    }
    /**
     * 
     */
    handleBack = () => {
        this.setState(prevState => {
            return {
                step: (prevState.step - 1) > 0 ? prevState.step - 1 : 1,
            }
        });
    }
    onInputChange = (event) => {
        var list = [...this.state.cart];
        for (var index = 0; index < list.length; index++) {
            if (list[index].id === event.target.id) {
                if (event.target.value === null || event.target.value === "") {
                    list.splice(index, 1);
                } else {
                    list[index].quantity = event.target.value;
                }
                this.setState({ cart: list });
                return;
            }
        } if (event.target.value > 0) {
            list.push({ id: event.target.id, item: JSON.parse(event.target.name), quantity: event.target.value });
            this.setState({ cart: list });
        }
        return;
    }
    priceFormatter = (cell, row) => {
        let price = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
        }
        return (
            <span>{`$${price}`}</span>
        )
    }
    quantityFormatter = (cell, row) => {
        return (
            <Input
                id={row.itemId}
                type="number"
                name={JSON.stringify(row)}
                placeholder='Quantity'
                onChange={this.onInputChange}
                className="form-control"
            />
        )
    }
    buildOrderSummary = () => {
        if (this.state.cart.length > 0) {
            this.setState({
                orderSummary: this.state.cart.map(sim => {
                    return {
                        itemId: sim.id,
                        sku: sim.item.itemName,
                        qty: sim.quantity,
                        unitPrice: sim.item.unitPrice,
                        promoteId: null,
                        totalPrice: sim.item.unitPrice * sim.quantity,
                        itemType: "device",
                    }
                })
            });

        }
    }
    clearOrderSummary = () => {
        this.setState({ orderSummary: [] })
    }
    setDiscount = (itemId, value) => {
        var tempOrderSummary = [...this.state.orderSummary]
        tempOrderSummary.forEach(item => {
            if (item.itemId == itemId) {
                item.promoteId = value
            }
        })
        this.setState({ orderSummary: tempOrderSummary })
        toast.success("discount set")
    }
    render() {
        var cardList = this.props.accountList.map((card) => {
            return {
                id: card.accountId,
                type: card.accountType,
                name: card.accountName,
                number: card.cardMask,
                selected: card.defaultPayment,
                cvc: "",
                expiry: card.expdate,
                issuer: card.issuerId
            }
        })
        return (
            <Card>
                <CardBody className="mainCardBody">
                    <Collapse isOpen={this.state.step == 1}>
                        <BootstrapTable
                            data={this.props.SIMTypeList}
                            tableHeaderClass='header_white'
                            bordered={false}
                            height='100%'
                            pagination
                        >
                            <TableHeaderColumn row='0' rowSpan='1' isKey={true} dataField='itemName' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} width="11%" editable={false}>Name</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} width="11%" editable={false}>Description</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} dataFormat={this.priceFormatter} width="11%" editable={false}>Price</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='Operations' dataAlign='center' dataFormat={this.quantityFormatter.bind(this)} width="11%" editable={false}>Quantity</TableHeaderColumn>
                        </BootstrapTable>
                        <Row>
                            <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>

                            </Col>
                            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            </Col>
                            <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NextButton
                                    onClick={this.handleNext}
                                    label="Next"
                                    size={30}
                                    color='black'
                                />
                            </Col>
                        </Row>
                    </Collapse>
                    <Collapse isOpen={this.state.step == 2}>
                        <OrderSummary
                            orderSummary={this.state.orderSummary}
                            clear={this.clearOrderSummary}
                            discountList={this.props.discountList}
                            setDiscount={this.setDiscount}
                            onBack={this.handleBack}
                            onNext={this.handleNext}
                        />
                        <br />
                        <PaymentBoard
                            list={cardList}
                            onRadioClick={this.onRadioClick}
                        />
                    </Collapse>
                    <Collapse isOpen={this.state.step == 3}>
                        <Row>
                            <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <BackButton
                                    onClick={this.handleBack}
                                    label="Back"
                                    size={30}
                                    color='black'
                                />
                            </Col>
                        </Row>
                    </Collapse>
                </CardBody>
            </Card>
        )
    }
}
export default ClientPurchaseBoard;