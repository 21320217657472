/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody, Button, Collapse } from 'reactstrap';

import ClientSpecialLineModal from './ClientSpecialLineModal';
import ClientSpecialLineList from './ClientSpecialLineList';
import PlusIcon from 'mdi-react/PlusIcon';

import { PowerIcon, ThumbDownIcon } from 'mdi-react';

class ClientSpecialLineBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSpecialLine: null,
            show: false
        }
    }
    componentDidMount() {

    }

    onPlanOpen = (SpecialLine) => {
        this.setState({
            selectedSpecialLine: SpecialLine,
            show: true
        })
    }
    onGeneralOpen = () => {
        this.setState({
            show: true
        })
    }
    onClose = () => {
        this.setState({
            selectedSpecialLine: null,
            show: false
        })
    }

    render() {

        return (
            <Card>
                <CardBody className="mainCardBody">
                    <ClientSpecialLineModal
                        selectedSpecialLine={this.state.selectedSpecialLine}
                        selectedRow={this.props.selectedRow}
                        show={this.state.show}
                        getSpecialLine={this.props.getSpecialLine}
                        SetSpecialLine={this.props.SetSpecialLine}
                        DeleteSpecialLine={this.props.DeleteSpecialLine}
                        specialLineList={this.props.specialLineList}
                        onClose={this.onClose}
                    />
                    {(this.props.isAdmin) ? <Button color="secondary" onClick={this.onGeneralOpen}><PlusIcon style={{ margin: "0px 5px", width: "20px", height: "20px", fill: "white" }} size={'large'} /> Add Special Line </Button> : null}
                    <h3>Special Line</h3>
                    <br />
                    <ClientSpecialLineList
                        specialLineList={this.props.specialLineList}
                        isAdmin={this.props.isAdmin}
                        onPlanOpen={this.onPlanOpen}
                    />
                </CardBody>
            </Card>
        )
    }

}
export default ClientSpecialLineBoard;