/* eslint-disable */
// import { combineReducers, createStore } from 'redux';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { reducer as reduxFormReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  sidebarReducer,
  themeReducer,
  mapReducer,
  mapFileterReducer,
  assetReducer,
  fenceReducer,
  ruleReducer,
  allModulesReducer,
  fetchDetailReducer,
  fetchUsersReducer,
  saveDetailReducer,
  attributeMgmtReducer,
  jobReducer,
  userReducer,
  dashboardTemplateReducer,
  attributeThemeReducer,
  dashboardReducer,
  reportReducer,
  inventoryReducer,
  recursiveReducer,
  surveillanceReducer,
  weatherReducer,
  messagedecodeReducer,
  powerReducer,
} from '../../redux/reducers/index';
import { USER_LOGOUT, STORE_RESET } from '../../constants/actionTypes';

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  sidebar: sidebarReducer,
  mapboard: mapReducer,
  mapfilter: mapFileterReducer,
  asset: assetReducer,
  fence: fenceReducer,
  rule: ruleReducer,
  users: fetchUsersReducer,
  detail: fetchDetailReducer,
  modules: allModulesReducer,
  saveDetail: saveDetailReducer,
  attributemgmt: attributeMgmtReducer,
  job: jobReducer,
  user: userReducer,
  dashboardTemplate: dashboardTemplateReducer,
  attributetheme: attributeThemeReducer,
  dashboard: dashboardReducer,
  report: reportReducer,
  inventory: inventoryReducer,
  recursive: recursiveReducer,
  surveillance: surveillanceReducer,
  weather: weatherReducer,
  messagedecode: messagedecodeReducer,
  // powerManagement: powerReducer,
});

const rootReducer = (state, action) => {
  //  
  let passState;
  if (action.type === USER_LOGOUT || action.type === STORE_RESET) {
    console.log('STORE RESET', state)
    // state = undefined;
    passState = undefined; 
    console.log(passState)
  } else {
    passState = state;
  }
  //  
  // return reducer(state, action);
  return reducer(passState, action);
};

// const store = createStore(reducer);

// const store = createStore(reducer, compose(applyMiddleware(thunk)));
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));


export default store;
