/* eslint-disable */
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PaymentForm from "./paymentForm";
// import 'react-credit-cards/es/styles-compiled.css';
// import '../regularcard.scss';

const AddPaymentModal = props => {
  const { className, addCard, acountInfo } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  let div;
    if (acountInfo == '') {
      div =<div><i class="fas fa-plus"></i> Add A Payment Method</div>;
    } else {
      div =<div><i class="fas fa-plus"></i> Edit Payment Method</div>;
    }

  return (
    <div style={{ float: "right" }}>
      <Button
        outline
        style={{
          borderColor: "black",
          border: "dashed",
          borderRadius: "20px",
          textAlign: "right"
        }}
        onClick={toggle}
      >
       {div}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Add A Payment Method</ModalHeader>
        <ModalBody>
          <PaymentForm addCard={addCard} acountInfo={acountInfo} toggle={toggle}></PaymentForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddPaymentModal;
