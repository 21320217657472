/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Line} from 'react-chartjs-2';

class WeatherLineChart extends Component {
    constructor(props){
        super(props);
        this.state={};
    }

    renderLineChart = () => {
        const chartlabel = ['A', 'B', 'C', 'D', 'E'];
        const chartdata1 = [
            {x:'2016-12-25', y:20},
            {x:'2016-12-26', y:30},
            {x:'2016-12-27', y:50},
            {x:'2016-12-28', y:40},
            {x:'2016-12-29', y:10}
        ];
        const chartdata2 = [
            {x:'2016-12-25', y:10},
            {x:'2016-12-26', y:40},
            {x:'2016-12-27', y:30},
            {x:'2016-12-28', y:20},
            {x:'2016-12-29', y:50}
        ];

        let dataset1 = [];
        if (this.props.chartData && this.props.attributea){
            // dataset1=this.props.chartData.filter((item)=>(item.attributename === this.props.attributea)).map((item)=>({x: item.attritime, y: item.value}));
            const assetAttribute1 = this.props.chartData.find((item)=>(item.attributename === this.props.attributea));
            if (assetAttribute1) {
                dataset1 = assetAttribute1.data;
                 
            }
        };

        let dataset2 = [];
        if (this.props.chartData && this.props.attributeb){
            // dataset2=this.props.chartData.filter((item)=>(item.attributename === this.props.attributeb)).map((item)=>({x: item.attritime, y: item.value}));
            const assetAttribute2 = this.props.chartData.find((item)=>(item.attributename === this.props.attributeb));
            if (assetAttribute2) {
                dataset2 = assetAttribute2.data;
            }
        };


        const data = {
            // labels: chartlabel,
            datasets: [
                {
                    label: this.props.attributea,
                    // backgroundColor: 'rgba(255,99,132,0.2)',
                    backgroundColor: 'white',
                    borderColor: 'rgba(255,99,132,1)',
                    pointRadius: 0,
                    // borderWidth: 0.2,
                    // barPercentage: 0.9,
                    // categoryPercentage: 0.6,
                    pointHoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    yAxisID: 'left-y-axis',
                    fill:  false,
                    // data: chartdata1,
                    data: dataset1,
                },
                {
                    label: this.props.attributeb,
                    // backgroundColor: 'rgba(99,132,255,0.2)',
                    backgroundColor: 'white',
                    borderColor: 'rgba(99,132,255,1)',
                    pointRadius: 0,
                    // borderWidth: 0.2,
                    // barPercentage: 0.9,
                    // categoryPercentage: 0.6,
                    pointHoverBackgroundColor: 'rgba(99,132,255,0.4)',
                    pointHoverBorderColor: 'rgba(255,99,132,255,1)',
                    yAxisID: 'right-y-axis',
                    fill:  false,
                    // data: chartdata1,
                    data: dataset2,
                },
                // {
                //     label: 'Alarm Orenge',
                //     backgroundColor: 'rgba(99,255,100,0.2)',
                //     borderColor: 'rgba(255,99,132,1)',
                //     // borderWidth: 0.2,
                //     barPercentage: 0.9,
                //     categoryPercentage: 0.6,
                //     hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                //     hoverBorderColor: 'rgba(255,99,132,1)',
                //     data: chartdata2
                // },
                // {
                //     label: 'Alarm Yellow',
                //     backgroundColor: 'rgba(99,255,255,0.2)',
                //     borderColor: 'rgba(255,99,132,1)',
                //     // borderWidth: 0.2,
                //     barPercentage: 0.9,
                //     categoryPercentage: 0.6,
                //     hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                //     hoverBorderColor: 'rgba(255,99,132,1)',
                //     data: chartdata1
                // }
            ]
        };

        const options = {
            aspectRatio: 3,
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                bounds: 'data',
                // bounds: 'ticks',
                // xAxes: [{
                //     // stacked: false,
                //     // type: 'linear',
                // }],
                xAxes: [{
                    type: 'time',
                    // distribution: 'series',
                    distribution: 'linear',
                    // offset: true,
                    time: {
                        unit: 'hour',
                        // stepSize: 3,
                        // round: true,
                        // parser: 'MMM DD HH'
                        displayFormats: {
                            'hour': 'MMM DD HH',
                         }
                    },
                    ticks:{
                        // min: '2016-12-24',
                        // max: '2016-12-30',
                        // maxTicksLimit: 10,
                        // sampleSize: 2,
                        autoSkip: true,
                        autoSkipPadding: 10,
                        bounds: 'data',
                        source: 'auto',
                    }
                }],
                yAxes: [{
                            // stacked: false,
                            // type: 'linear',
                            // offset: true,
                            label: 'test left',
                            id: 'left-y-axis',
                            position: 'left',
                            ticks: {
                                // min: 0,
                                // max: 70,
                            }
                        },
                        {
                            label: 'test right',
                            id: 'right-y-axis',
                            // type: 'linear',
                            position: 'right',
                        }
                    ]
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 10,
                    fontSize: 12,
                    fontColor: 'rgb(255, 99, 132)'
                }
            }
        }
        return(
            <Line
                width={null}
                height={null} 
                data={data} 
                options={options}
            >    
            </Line>
        )
    }

    render(){
        return(
            <Card style={{padding: '0', border: '1px solid black'}}>
                <CardBody style={{padding: '20px 5px 10px 5px'}}>
                    {this.renderLineChart()}
                </CardBody>
            </Card>
        );
    }
}

export default WeatherLineChart;