/* eslint-disable */
import React, { Component } from 'react';
import {Button} from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { getPageContent } from '../../../redux/actions/userAction';
import { getCurrentUser, hasRole } from '../../../factories/auth';

import RegisterDeviceModal from './RegisterDeviceModal';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { toast } from "react-toastify";
import { sortCaret } from '../../../shared/components/Table/tableElements';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ClientActiveDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showActivateDeviceModal: false,
        }
        this.userObj = getCurrentUser();
    }

    componentDidMount() {
        // console.log("ClientActiveDevice > SIMTypeList: ", this.props.SIMTypeList);
    }

    componentWillReceiveProps(nextProps) {
        
    }

    onOpenActivateDeviceModal = () => {
        this.setState({
            showActivateDeviceModal: true
        })
    }

    onCloseActivateDeviceModal = () => {
        this.setState({
            showActivateDeviceModal: false
        })
    }

    renderRegisterModal() {
        return (
            <RegisterDeviceModal
                SIMTypeList={this.props.SIMTypeList}
                registerDevice={this.props.registerDevice}
                showActivateDeviceModal={this.state.showActivateDeviceModal}
                onCloseModal={this.onCloseActivateDeviceModal}
            />
        )
    }

    tableActionColFormaterDelete = (cell, row) => {
        return (
            <div>
                <ConfirmDeleteDialog
                    onConfirm={(e) => { this.disableDevice(row); }}
                    msg={'Do you want to disable the select Device? '}
                />
            </div>
        )
    }

    disableDevice = (row) => {

        const obj = {
            id: row.itemId,
            customerId: row.customerId,
            resellerId: row.resellerId,
            deviceName: row.deviceId,
            action: "suspend"
        }

        this.props.ChangeDeviceStatus(obj).then(res => {
            if (res && res.status == 200) {
                
                if (res.data.statusCode == 200) {
                    toast.success(res.data.message)
                    return;
                }
                
                toast.error(res.data.message)
            }
        })

        // console.log("deleted", obj)
    }

    render() {
        const options = {
            // insertBtn: this.props.addNewUserButton,
            noDataText: 'No Device Found',
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
            defaultSortName: 'deviceId',
            defaultSortOrder: 'asc',
        };

        return (
            <div>
                {this.renderRegisterModal()}
                <h3>Active Devices</h3>
                <br/>
                <Button color='custom' type="button" onClick={this.onOpenActivateDeviceModal}>
                    Activate Device(s)
                </Button>
                <BootstrapTable
                    data={this.props.clientActiveDeviceList}
                    tableHeaderClass='header_white'
                    options={options}
                    bordered={false}
                    height='100%'
                    pagination={true}
                    exportCSV
                    csvFileName='Active_Devices.csv'
                >
                    {/* Device Id, Device Name, Device Type Name, Contract Id, and Group Id, Disable */}
                    <TableHeaderColumn row='0' rowSpan='1' dataField='deviceId' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Device ID'>Device ID</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='deviceName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Device Name'>Device Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='typeName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Device Type Name'>Device Type Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='contractId' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Contract ID'>Contract ID</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='groupId' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Group ID'>Group ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='action' dataAlign="center" width="10%" dataFormat={this.tableActionColFormaterDelete} export={false}>Disable</TableHeaderColumn>
                </BootstrapTable>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // const { pageContentList } = state.user
    // const props = { pageContentList }

    const {SIMTypeList} = state.inventory

    let props = {SIMTypeList}
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        // getPageContent
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientActiveDevice);