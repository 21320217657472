/* eslint-disable */
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { FaBatteryFull } from "react-icons/fa";
import { DeviceAttrGroupSelection } from "../../Asset/components/SelectionComponents";
import { POWER_ATTRIBUTE_ORDER } from "../../../constants/dataConstants";
import Select from "react-select";
import GroupAttributeBoard from "./GroupAttributeBoard";
import PowerChart from "./PowerChart";
// import PowerChartMainBoard from "./PowerChartMainBoard";

const customStyles = {
  control: (base) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: "0",
  }),
};
class PowerMgntDeviceInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attrGroupsDetail: null,
      attrGroupsList: null,
      msgDecodeGroupList: null,
      defaultSelectedGroups: null,
      selectedGroups: [],
      selectedGroupName: null,
      selectedGroupId: null,
      deviceDetail: null,
      statusData: null,
      dataList: null,
      showGroup: false,
      attributea: "",
      attributeb: "",
      attributeData: null,
      optionsOne: [],
      optionsTwo: [],
      historyDataObj: null,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextPorps) {
    if (nextPorps.deviceInfo) {
      if (
        JSON.stringify(nextPorps.deviceInfo) !==
        JSON.stringify(this.props.deviceInfo)
      ) {
        this.setState({
          selectedGroups: null,
          selectedGroupName: null,
          selectedGroupId: null,
          attributea: "",
          attributeb: "",
        });
      }
    }
  }
  createGroupAttr = (deviceDetail, msgDecodeGroupList, attrGroupsList) => {
    let groupMapping = {};
    if (deviceDetail != null && Object.keys(deviceDetail).length == 1) {
      let firstKey = Object.keys(deviceDetail)[0];
      for (let group of msgDecodeGroupList) {
        groupMapping[group.name] = {};
        if (attrGroupsList[group.groupId]) {
          for (let attr of attrGroupsList[group.groupId]) {
            if (deviceDetail[firstKey][attr.name] != null) {
              groupMapping[group.name][attr.name] =
                deviceDetail[firstKey][attr.name];
            }
          }
        }
      }
    }
    this.state.dataList = groupMapping;

    return groupMapping;
  };

  mapMsgDecodeGroupList = (props) => {
    let list = [];
    let i = 0;
    while (i != props.length) {
      list.push(props[i++]);
    }

    this.state.msgDecodeGroupList = list;
  };

  deviceFormat = (statusData) => {
    if (statusData) {
      for (let value of Object.keys(statusData)) {
        if (value == "attributes") {
          var map1 = new Map();
          var set1 = new Set();
          for (let key of Object.keys(statusData[value])) {
            for (let obj of Object.keys(statusData[value][key])) {
              map1.set(
                statusData[value][key][obj].deviceid + ":" + key,
                statusData[value][key][obj].value
              );
              set1.add(statusData[value][key][obj].deviceid);
            }
          }
        }
      }
      let deviceDetail = [];
      for (const device of set1) {
        this.device = {};
        for (const [key, value] of map1) {
          if (key.startsWith(device)) {
            var loca = key.split(":")[1];
            this.device[loca] = value;
          }
        }
        var pushObj = {};
        pushObj[device] = this.device;
        deviceDetail.push(pushObj);
      }

      for (let i = 0; i != deviceDetail.length; i++) {
        if (
          Object.keys(deviceDetail[i]) ==
          this.props.deviceInfo.deviceid.toString()
        ) {
          this.state.deviceDetail = deviceDetail[i];
        }
      }
    }
  };
  mapCalculateRuleList = (props) => {
    let list = [];
    let i = 0;
    while (i != props.length) {
      list.push(props[i++]);
    }
    return list;
  };
  optionsMaker = (array, object, data) => {
    let options = [];
    let i = 0;
    while (i != array.length) {
      if (this.state.msgDecodeGroupList[i].name == Object.keys(data)[i]) {
        options.push({
          groupid: parseInt(Object.keys(object)[i]),
          groupname: Object.keys(data)[i],
          groupvalue: Object.values(data)[i],
        });
      }
      i++;
    }
    return options;
  };

  selectGroup = (selected, action) => {
    this.setState({
      selectedGroups: selected,
    });
    if (selected) {
      this.state.selectedGroupName = selected.map((a) => a.groupname);
      this.state.selectedGroupId = selected.map((a) => a.groupid);
    }
    var updatedNameList = this.state.selectedGroupName;
    var updatedIdList = this.state.selectedGroupId;
    var updatedGroupList = this.state.selectedGroups;

    if (action.action == "remove-value") {
      let i = 0;
      while (i != updatedNameList.length) {
        if (updatedNameList[i] === action.removedValue.groupname)
          updatedNameList[i] = null;

        if (updatedIdList[i] === action.removedValue.groupid)
          updatedIdList[i] = null;

        if (updatedGroupList[i] == action.removedValue.groupvalue)
          updatedGroupList[i] = null;

        i++;
      }

      this.state.selectedGroups = updatedGroupList;
    }
  };

  groupBy = (array, key) => {
    if (array) {
      if (array.length == 0) return [];
      else {
        return array.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(
            currentValue
          );
          this.state.attrGroupsDetail = result;
          return result;
        }, {});
      }
    }
  };

  setAttrGroupsName = (array, key) => {
    if (array) {
      if (array.length == 0) return [];
      else {
        return array.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push({
            attributeid: currentValue.attributeId,
            name: currentValue.name,
            messageDecodeGroupId: currentValue.messageDecodeGroupId,
          });
          this.state.attrGroupsList = result;
          return result;
        }, {});
      }
    }
  };

  disabledOption = (data) => {
    const filteredOptions = data.map((obj) => {
      if (
        obj.attributename == this.state.attributea ||
        obj.attributename == this.state.attributeb
      ) {
        return { ...obj, isdisabled: true };
      }
      return obj;
    });

    const options = filteredOptions.map((obj) => {
      if (obj.value == null) {
        return {
          ...obj,
          label: `No data available - ${obj.label}`,
          isdisabled: true,
        };
      }
      return obj;
    });
    return options;
  };
  handleValueSelectOne = (data, action) => {
    if (data) {
      this.setState({
        attributea: data.attributename,
      });
    } else {
      this.setState({
        attributea: "",
      });
    }
  };

  handleValueSelectTwo = (data) => {
    if (data) {
      this.setState({
        attributeb: data.attributename,
      });
    } else {
      this.setState({
        attributeb: "",
      });
    }
  };
  singleValueOptionsOne = (attrData) => {
    // let placeHolder = (attr) => {
    //   return attr == "Battery" ? "Battery" : "-Select a statistic-";
    // };
    // let defaultValue = placeHolder(this.state.attributea);
    var dataArray = [
      { value: 0, label: "-Select a statistic-", isdisabled: true },
    ];

    var noDataArray = [
      {value:0, label: "No data available", isdisabled: true}
    ]

    if (attrData !=null && attrData != undefined) {
      let i = 0;
      while (i != attrData.length) {
        dataArray.push({
          value: attrData[i].value,
          label: attrData[i].displayname,
          attributename: attrData[i].attributename,
          color: attrData[i].backgroundcolor,
          isdisabled: false,
        });
        i++;
      }

      var optionsOne = this.disabledOption(dataArray);
      this.state.optionsOne = optionsOne;
    } else var optionsOne = noDataArray;
    return (
      <Select
        styles={customStyles}
        isClearable={this.state.attributea == "" ? false : true}
        options={optionsOne}
        isOptionDisabled={(options) => options.isdisabled}
        onChange={this.handleValueSelectOne}
        placeholder={"-Select a statistic-"}
        value={
          this.state.attributea == ""
            ? dataArray[0]
            : dataArray.find(
                (attr) => attr.attributename == this.state.attributea
              )
        }
        autoFocus={false}
      />
    );
  };

  singleValueOptionsTwo = (attrData) => {
    // let placeHolder = (attr) => {
    //   return attr == "Alm" ? "Alm" : "-Select a statistic-";
    // };
    // let defaultValue = placeHolder(this.state.attributeb);
    // console.log("===defaultValueTwo", defaultValue);
    var dataArray = [
      { value: 0, label: "-Select a statistic-", isdisabled: true },
    ];

    var noDataArray = [
      {value:0, label: "No data available", isdisabled: true}
    ];
    if (attrData != null && attrData != undefined) {
      let i = 0;
      while (i != attrData.length) {
        dataArray.push({
          value: attrData[i].value,
          label: attrData[i].displayname,
          attributename: attrData[i].attributename,
          color: attrData[i].backgroundcolor,
          isdisabled: false,
        });
        i++;
      }
      var optionsTwo = this.disabledOption(dataArray);
      this.state.optionsTwo = optionsTwo;
    } else optionsTwo = noDataArray;

    return (
      <Select
        styles={customStyles}
        isClearable={this.state.attributeb == "" ? false : true}
        options={optionsTwo}
        isOptionDisabled={(options) => options.isdisabled}
        onChange={this.handleValueSelectTwo}
        placeholder={"-Select a statistic-"}
        value={
          this.state.attributeb == ""
            ? dataArray[0]
            : dataArray.find(
                (attr) => attr.attributename == this.state.attributeb
              )
        }
        autoFocus={false}
      />
    );
  };

  render() {
    const { selectedGroups, defaultSelectedGroups } = this.state;

    if (this.props.assetData) {
      this.state.attributeData = setCurrentAttribute(
        this.props.assetData,
        this.props.deviceInfo
      );
    }
    if (this.props.statusData && this.props.deviceInfo) {
      this.deviceFormat(this.props.statusData);
      this.state.statusData = this.props.statusData;
    }

    if (
      this.props.messageDecodeCalculateRuleList.length != 0 &&
      this.props.messageDecodeGroup
    ) {
      this.mapMsgDecodeGroupList(this.props.messageDecodeGroup);

      const calculateRules = this.mapCalculateRuleList(
        this.props.messageDecodeCalculateRuleList
      );

      this.setAttrGroupsName(
        calculateRules[0].messageDecodeCalculateRule,
        "messageDecodeGroupId"
      );

      const groupedRules = this.groupBy(
        calculateRules[0].messageDecodeCalculateRule,
        "messageDecodeGroupId"
      );

      const calculateGroups = Object.values(groupedRules);

      let dataList = this.createGroupAttr(
        this.state.deviceDetail,
        this.state.msgDecodeGroupList,
        this.state.attrGroupsList
      );

      var calculateGroupsOptions = this.optionsMaker(
        calculateGroups,
        groupedRules,
        dataList
      );

      this.state.defaultSelectedGroups = calculateGroupsOptions;
    }
    // console.log("===this.state.powerMgntDeviceInfo", this.state.selectedGroups.length);
    var length = 0;
    if (this.state.selectedGroups) {
      length = this.state.selectedGroups.length * 3;
    } else length = 0;

    var defaultSM = 0;
    if(this.state.defaultSelectedGroups.length) {
      defaultSM = this.state.defaultSelectedGroups.length
    }

    var colLength = 0;
    if (this.state.selectedGroups) {
      colLength = this.state.selectedGroups.length * 3;
    } else colLength = 0;
    // console.log("===this.state", this.state);
    return (
      <Col>
        <br />
        <Row>
          <Col
            sm={{
              offset: 0,
              size: "auto",
            }}
          >
            <h4 style={{ fontWeight: "bold", marginTop: "2%" }}>
              {this.props.deviceInfo
                ? this.props.deviceInfo.devicenamealias
                : null}
            </h4>
          </Col>
          <Col
            sm={{
              size: "auto",
            }}
            style={{ minWidth: "15%" }}
          >
            <DeviceAttrGroupSelection
              isClearable={false}
              options={calculateGroupsOptions}
              value={this.state.selectedGroups}
              // defaultValue={this.state.defaultSelectedGroups}
              getOptionLabel={({ groupname }) => groupname}
              getOptionValue={({ groupid }) => groupid}
              placeholder={"- Filter Groups -"}
              onChange={this.selectGroup}
              autoFocus={false}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md={{
              offset: 2,
              size: colLength,
            }}
            sm="9"
          >
            <GroupAttributeBoard
              data={this.state}
              selectedGroups={this.state.selectedGroups ? this.state.selectedGroups : []}
              defaultGroups={this.state.defaultSelectedGroups ? this.state.defaultSelectedGroups : []}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col style={{ minWidth: "15%" }} sm={{ offset: 0, size: "auto" }}>
            {this.singleValueOptionsOne(this.state.attributeData)}
          </Col>
          <Col style={{ minWidth: "15%" }} sm={{ offset: 0, size: "auto" }}>
            {this.singleValueOptionsTwo(this.state.attributeData)}
          </Col>
        </Row>
        <br />
        <Row style={{ minWidth: "1400px", height: "100%" }}>
          <Col
            md={{
              offset: 0,
              size: 12,
            }}
            sm="12"
          >
            <PowerChart
              chartData={
                this.props.historyDataObj ? this.props.historyDataObj : []
              }
              attributea={this.state.attributea}
              attributeb={this.state.attributeb}
              deviceInfo={this.props.deviceInfo}
            />
          </Col>
        </Row>
        {/* <Row style={{ minWidth: "1400px", height: "100%" }}>
          <PowerChartMainBoard
            historyDataObj={this.props.historyDataObj}
            assetData={this.props.assetData}
            deviceInfo={this.props.deviceInfo}
            powerAttrList={this.props.powerAttrList}
            attributea={this.state.attributea}
            attributeb={this.state.attributeb}
            data={this.state}
          />
        </Row> */}
      </Col>
    );
  }
}

export default PowerMgntDeviceInfo;

function setCurrentAttribute(assetData, deviceInfo) {
  let reData = [];
  if (!assetData) {
    return reData;
  } else if (assetData && assetData.attributes) {
    for (let attr of POWER_ATTRIBUTE_ORDER) {
      if (!attr.selected) {
        continue;
      }
      if (deviceInfo) {
        var attrObj = assetData.attributes.find(
          (item) =>
            attr.attributename == item.attributename &&
            item.deviceid == deviceInfo.deviceid
        );
      }
      if (attrObj) {
        let currentValue = attrObj.value;
        let timestamp = assetData.timestamp;
        let attrDataObj = {
          ...attr,
          value: currentValue,
          timestamp: timestamp,
        };
        reData.push(attrDataObj);
      }
    }
    return reData;
  }
}
{
  /* <FaCircle
style={{
  fill: alarmColor,
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Operating status
:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<br />
<FaFacebookMessenger
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Last message received
:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
<br />
<MdOutlineMoreTime
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Uptime today
:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
<br />
<FaClock
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Total Uptime this month
:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
<br />
<FaTemperatureHigh
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Current blower Temperature
:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
&#8451;
<br />
<FaTemperatureLow
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Average blower Temperature(past month) :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
&#8451;
<br />
<HiOutlineLightningBolt
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Current Amperage :
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
Amps
<br />
<MdVibration
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
High Vibration:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
<br />
<MdOutlineAvTimer
style={{
  height: "1.5em",
  width: "2em",
}}
/>{" "}
Last operation duration
:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<br /> */
}
