/* eslint-disable */
import React, { Component, Fragment  } from 'react';
import {Form, FormGroup, Row, Col, Label, Input, Button} from 'reactstrap';
import {Card, CardHeader , CardBody, CardFooter} from 'reactstrap';
import Select from 'react-select';
import ContactModal from "../../Contact/components/CreateNewContactModal";
import EditContactModal from '../../Contact/components/EditContactModal';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';

class StationAlarmForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            weekOption :  [
                { value: 1, label: 'Mon' },
                { value: 2, label: 'Tue' },
                { value: 3, label: 'Wed' },
                { value: 4, label: 'Thu' },
                { value: 5, label: 'Fri' },
                { value: 6, label: 'Sat' },
                { value: 7, label: 'Sun' },
            ],
        };
    }
// ----------------------------------------------------------------------------------
    componentDidMount(){
         
    }
    
    static getDerivedStateFromProps(nextProps, prevState){
         
        if (nextProps.actionObj){ //  && nextProps.notificationTemplateList
            if (nextProps.actionObj.actionid != prevState.actionid) {              
                // let templateObj = nextProps.notificationTemplateList.find((item)=>(item.actionid == nextProps.actionObj.actionid));
                // nextProps.getActionRule({actionid: nextProps.actionObj.actionid});
                return({
                    // templateObj: templateObj,
                    actionid: nextProps.actionObj.actionid,
                });
            }
           
        }
    }
    // UNSAFE_componentWillReceiveProps(nextProps, nextContext){
    //      
    // }
    // componentWillReceiveProps(nextProps, nextContext){
    //     alert('yes');
    //      
    //     if (nextProps.actionObj && nextProps.notificationTemplateList){
           
    //         if (nextProps.actionObj.actionid != this.state.actionid) {
                
    //             let templateObj = nextProps.notificationTemplateList.find((item)=>(item.actionid == nextProps.actionObj.actionid));
    //             this.props.getActionRule({actionid: nextProps.actionObj.actionid});
    //             this.setState({
    //                 templateObj: templateObj,
    //                 actionid: nextProps.actionObj.actionid,
    //             });
    //         }
           
    //     }
    // }
// ----------------------------------------------------------------------------------
    renderContact = () => {
        let selectedContacts = [];
        if(this.props.contactList && this.props.templateObj && this.props.templateObj.contactid){
            // let contactIds = this.props.templateObj.contactid.split(',');
            let contactIds = JSON.parse(this.props.templateObj.contactid);
             
            selectedContacts = this.props.contactList.filter((item, index) => (contactIds.includes(item.id)));
        }
        return (
            <Form>
                <span className="title">Receiver's List</span>
                <Row>
                    <Col md={6}>
                        <Select
                            isMulti
                            value={selectedContacts}
                            options={this.props.contactList}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            onChange={this.props.handleContactChange}
                        />      
                        <br />
                    </Col>
                    {/* {this.props.userObj && hasRole(this.props.userObj,['ADMIN', 'USER', 'ENDUSER']) && */}
                        <Col md={2}>
                            <ContactModal 
                                label={'Add Receiver'}
                                className={'container'}
                                upsertContact={this.props.upsertContact}
                            ></ContactModal>
                        </Col>
                    {/* } */}
                    <Col md={2}>
                        <EditContactModal
                            label={'Edit Receiver'}
                            contactList={this.props.contactList}
                            upsertContact={this.props.upsertContact}
                            deleteContact = {this.props.deleteContact}
                        />
                    </Col>
                </Row>
            </Form>
        );
      };
  
// --------------------------------------------------------------------------------------------------------
    // onTimeInputChange = (e) => {
    //     this.props.handleTimeInputChange(e);
    // }

    // handlePeriodsSelect = (value) => {
    //     let weekday = [];
    //     if (value){
    //         value.forEach((item, index)=>{
    //             weekday.push(item.label);
    //         });
    //     }
    //     this.props.handlePeriodsSelect(JSON.stringify(weekday));
    // }

    // renderTimePeriod = () => {
    //     let fromTime = '';
    //     let toTime = '';
    //     let selectedPeriod = [];

    //     if (this.props.templateObj){
    //         let alertPart = this.props.templateObj;
    //         fromTime = alertPart.starttime ? alertPart.starttime: '';
    //         toTime = alertPart.endtime ? alertPart.endtime: '';
    //         let weekdayArr = alertPart.weekday ? JSON.parse(alertPart.weekday) : [];
    //         weekdayArr.forEach((item, index)=>{
    //             let selectedItem = this.state.weekOption.find((day, dindex) => (day.label == item));
    //             if (selectedItem) {
    //                 selectedPeriod.push(selectedItem);
    //             }
    //         });
    //     }
    //     return(
    //         <Form>
    //             <Row>
    //             <Col md={12}>
    //                 <FormGroup row >                        
    //                         <Col md={3}>
    //                             <Label for="period">Time periods</Label>
    //                             <Select
    //                                 isMulti
    //                                 id = "period"
    //                                 name="weekday"
    //                                 options={this.state.weekOption}
    //                                 className="basic-multi-select"
    //                                 classNamePrefix="select"
    //                                 value={selectedPeriod}
    //                                 onChange={this.handlePeriodsSelect}
    //                             />
    //                         </Col>
    //                         <Col md={3}>
    //                             <Label for="fromTime">From</Label>
    //                             <Input
    //                                 type="time"
    //                                 name="starttime"
    //                                 id="fromTime"
    //                                 placeholder="time placeholder"
    //                                 // onChange={(e) => {this.onTimeInputChange(e)}} 
    //                                 onChange={(e) => {this.onInputChange(e)}} 
    //                                 value={fromTime}
    //                             />
    //                         </Col>
    //                         <Col md={3}>
    //                             <Label for="fromTime">To</Label>
    //                             <Input
    //                                 type="time"
    //                                 name="endtime"
    //                                 id="toTime"
    //                                 placeholder="time placeholder"
    //                                 // onChange={(e) => {this.onTimeInputChange(e)}} 
    //                                 onChange={(e) => {this.onInputChange(e)}} 
    //                                 value={toTime}
    //                             />
    //                         </Col>                        
    //                 </FormGroup>
    //                 </Col>
    //             </Row>
    //         </Form>
    //     );
    // }
// ------------------------------------------------------------------------------------------

onInputChange = (event, alertType) => {
     
    this.props.handleInputChange(event, alertType);
};

renderEmailNotification = () => {
    let title = '';
    let content = '';
    
    if (this.props.templateObj) {
        title = this.props.templateObj.title;
        content = this.props.templateObj.content;
    }
   
    return(
        <Form>
            <Row>
                <Col md={8}>
                    <FormGroup row >
                        <Col md={12}>
                            <Label for="startTime"  style={{display: 'inline', width: "100%"}} >Subject</Label>
                            <Input 
                                type="text" 
                                id="subject" 
                                name="title" 
                                width="100%" 
                                onChange={(e) => {this.onInputChange(e)}} 
                                value={title}
                            >
                            </Input>     
                        </Col>                   
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <FormGroup row >
                        <Col md={12}>
                            <Label for="emailMessage"  style={{display: 'inline', width: "100%"}} >Message</Label>
                            <Input 
                                type="textarea" 
                                id="emailMessage" 
                                name="content" 
                                width="100%" 
                                onChange={(e) => {this.onInputChange(e)}} 
                                value={content}
                            >
                            </Input>  
                        </Col>                      
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
}

    renderName = () => {
        let name = '';
        let value = '';
        if (this.props.actionObj) {
            name = this.props.actionObj.actionname;
        }
        // if (this.props.actionRules && this.props.actionRules.length>0 && this.props.actionRules[0].ruleitems && this.props.actionRules[0].ruleitems.length > 0) {
        //     value = this.props.actionRules[0].ruleitems[0].value;
        // }
        return (
            <Form>
                <Row>
                    <Col md={6}>
                            <Label for="emailMessage"  style={{display: 'inline', width: "100%"}} >Alert Name</Label>
                            <Input 
                                type="text" 
                                id="emailMessage" 
                                name="name" 
                                width="100%" 
                                // onChange={(e) => {this.onInputChange(e)}} 
                                value={name}
                                disabled
                            >
                            </Input>
                    </Col>
                    {/* <Col md={3}>
                        <Label  style={{ display: 'inline'}} >Default Value</Label>

                        <EditableText
                            type='text'

                            defaultValue={value}  
                            exitOnEnterKeyPress={false}
                            onSave={newVal => {
                                         
                                        // this.handleEditInputChange(newVal);
                                    }}
                        />
                    </Col> */}
                </Row>
            </Form>
        )
    }

    renderRule=()=>{
        let attribute = '';
        let operator = '';
        let value = '';
        if (this.props.actionRules && this.props.actionRules.length>0 && this.props.actionRules[0].ruleitems && this.props.actionRules[0].ruleitems.length > 0) {
            value = this.props.actionRules[0].ruleitems[0].value;
            attribute = this.props.actionRules[0].ruleitems[0].attributename;
            let oprId = this.props.actionRules[0].ruleitems[0].operator;
            switch (oprId){
                case 1 : 
                    operator = '='; 
                    break;
                case 2 : 
                    operator = '>'; 
                    break;
                case 3 : 
                    operator = '>='; 
                    break;
                case 4 : 
                    operator = '<'; 
                    break;
                case 5 : 
                    operator = '<='; 
                    break;
                case 6 : 
                    operator = '<>'; 
                    break;
                default:
                    operator = ' ';
            }
        }
        return(
        <Form>
        <Row>
            <Col md={3}>
                    <Label for="emailMessage"  style={{display: 'inline', width: "100%"}} >Attribute</Label>
                    <Input 
                        type="text" 
                        id="emailMessage" 
                        name="name" 
                        width="100%" 
                        // onChange={(e) => {this.onInputChange(e)}} 
                        value={attribute}
                        disabled
                    >
                    </Input>
            </Col>
            {/* <Col md={1}>
                    <Label for="emailMessage"  style={{display: 'inline', width: "100%"}} >Operator</Label>
                    <Input 
                        type="text" 
                        id="emailMessage" 
                        name="name" 
                        width="100%" 
                        // onChange={(e) => {this.onInputChange(e)}} 
                        value={operator}
                        disabled
                    >
                    </Input>
            </Col> */}

            <Col md={2}>
                    <Label for="emailMessage"  style={{display: 'inline', width: "100%"}} >Operator</Label>
                    <Input 
                        type="text" 
                        id="emailMessage" 
                        name="name" 
                        width="100%" 
                        // onChange={(e) => {this.onInputChange(e)}} 
                        value={operator}
                        disabled
                    >
                    </Input>
            </Col>

            <Col md={3}>
                <Label  style={{ display: 'inline'}} >Thrashold Value</Label>
                {/* <Input type='text' value = {this.state.geofencename}/> */}
                {/* <InputGroup> */}
                <EditableText
                    type='text'
                    //  defaultValue={this.props.layout ? this.props.layout.name : ''} 
                    //  defaultValue={this.state.selectedRuleValue}
                    defaultValue={value}  
                    exitOnEnterKeyPress={false}
                    onSave={newVal => {
                                 
                                // this.handleEditInputChange(newVal);
                                this.props.handleRuleValueChange(newVal, 0, 0);
                            }}
                />
                {/* </InputGroup> */}
            </Col>
        </Row>
    </Form>
    )
    }

    // ----------------------------------------------------------------------------
    onSubmit = (e) => {
        this.props.onSubmit();
    }
    onClose = (e) => {
        this.props.toggle();
    }
    render() {
        return (
            <Card>                
                <CardBody>
                    {this.renderName()}
                    <br/>
                    {this.renderRule()}
                    <br/>
                    {this.renderContact()}
                    {/* {this.renderTimePeriod()} */}
                    {this.renderEmailNotification()}
                    <hr/>
                    <div style={{textAlign:'right'}}>
                        <Button 
                            outline color='custom' 
                            type="button" 
                            onClick={this.onSubmit} 
                            // disabled = {!this.validateForm()} 
                        >
                            Save
                        </Button>
                        <Button 
                            outline 
                            color='secondary' 
                            type="reset" 
                            onClick={this.onClose}
                        >
                            Cancel
                        </Button>
                    </div>                
                </CardBody>
            </Card>
        )
    }
}

export default StationAlarmForm;