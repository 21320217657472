/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { 
    LightningBoltIcon, 
    WeatherPouringIcon, 
    ThermometerIcon,
    WaterPercentIcon, 
    WeatherSunnyIcon, 
    WeatherWindyIcon, 
    Battery70Icon, 
    MessageOutlineIcon,
    DirectionsForkIcon,
    GaugeLowIcon,
    LightningBoltOutlineIcon,
} from 'mdi-react';
import Tooltip from '@material-ui/core/Tooltip';

const ICON_SIZE = 24;
const AttributeIcon = ({attributeName}) => {
    switch (attributeName) {
        case 'timestamp': 
            return <MessageOutlineIcon size={ICON_SIZE}/>;
        case 'Temperature':
            return (
                <Tooltip title={attributeName}>
                    <div>
                    <ThermometerIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Pressure':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <GaugeLowIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Humidity':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <WeatherSunnyIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Irradiation':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <WeatherSunnyIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Battery':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <Battery70Icon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Rain':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <WeatherPouringIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Wind_ave10':
            return  (
                <Tooltip title="Wind Avg">
                    <div>
                    <WeatherWindyIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Wind_max10':
            return  (
                <Tooltip title="Wind Max">
                    <div>
                    <WeatherWindyIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Dir_ave10':
        case 'Dir_max10':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <DirectionsForkIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Dir_ave10_txt':
            return  (
                <Tooltip title="Dir Avg">
                    <div>
                    <DirectionsForkIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'Dir_max10_txt':
            return  (
                <Tooltip title="Dir Max">
                    <div>
                    <DirectionsForkIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
        case 'hexAlarm':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <LightningBoltOutlineIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);
                /*
        case 'CO2':
            return  (
                <Tooltip title={attributeName}>
                    <div>
                    <GaugeLowIcon size={ICON_SIZE}/>
                    </div>
                </Tooltip>);*/
        default: 
            return <span></span>;
    }
}

export default AttributeIcon;