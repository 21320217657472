/* eslint-disable */
import React, { Component } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Collapse } from 'reactstrap';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import InventoryPurchaseSupplierList from './InventoryPurchaseSupplierList';
import InventoryPurchaseItemList from './InventoryPurchaseItemList';
import InventoryPurchaseBilling from './InventoryPurchaseBilling';
import InventoryPurchaseSummary from './InventoryPurchaseSummary';

class InventoryPurchaseBoard extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedCollapseId: '1',
            currentSupplier: null,
            itemList: [],
            shippingAddress:{
                name: '',
                phone: '',
                address: '',
                address2: '',
                city: '',
                province: '',
                country: '',
                zip: ''
            },
        }
    }

    componentDidMount(){
        this.props.getSupllierList()
    }

    toggleCollaps = (tagId) => {
        this.setState({
            selectedCollapseId: tagId,
        })
    }

    handleSupplierClicked = (obj) => {
         
        this.props.getSupllierItemList(obj.id);
        this.setState({
            currentSupplier: obj,
        });
        this.toggleCollaps('2');
    }

    handlePurchaseItemBack = () => {
        this.setState({
            itemList: [],
        })
        this.toggleCollaps('1');
    }

    handlePurchaseItemNext = () => {
        this.toggleCollaps('3');
    }

    handleInputValueChange = (value, obj) => {
        this.setState(prevState => {
          var foundItem = prevState.itemList.find(ele => (ele.sku == obj.sku))
          if (foundItem) {
            foundItem.qty = value
          }else{
            prevState.itemList.push({sku: obj.sku, qty: value})
          }
          return {
            itemList: prevState.itemList,
          }
        }, () => {
           
        });
    }

    onItemExited = () => {
        // this.setState({
        //     itemList: [],
        // })
    }

    handleInputChange = ({target}) => {
        this.setState(prevState => {
            return({
                shippingAddress: {...prevState.shippingAddress, ...{[target.name]:target.value}}
            });
        }, ()=>{
             
        });
    }

    handleDefaultChange = ({target}) => {
         
        if (target.checked){
            this.useDefaultAddress();
        }
        else{
            this.clearShippingAddress();
        }
    }

    useDefaultAddress = () =>{
        const company = this.props.userObj.company;
         
        if (company){
            this.setState({
                shippingAddress: {
                    name: '',
                    phone: '',
                    address: company.addressline1,
                    address2: company.addressline2,
                    city: company.city,
                    province: company.province,
                    country: company.country,
                    zip: company.postcode
                }
            });
        }
    }

    clearShippingAddress =() => {
        this.setState({
            shippingAddress: {
                name: '',
                phone: '',
                address: '',
                address2: '',
                city: '',
                province: '',
                country: '',
                zip: ''
            }
        });
    }

    handleAddressBack = () => {
        this.toggleCollaps('2');
    }
    handleAddressNext = () => {
        this.toggleCollaps('4');
    }
    handleSummaryBack = () => {
        this.toggleCollaps('3');
    }
    handleCheckout = () => {
        this.setState({
            selectedCollapseId: '1',
            currentSupplier: null,
            itemList: [],
            shippingAddress:{
                name: '',
                phone: '',
                address: '',
                address2: '',
                city: '',
                province: '',
                country: '',
                zip: ''
            },
        });
    }
    render() {
        return (
            <div>
                <Collapse 
                    isOpen={'1' === this.state.selectedCollapseId}
                    // onEntering={this.onDeviceListEntering}
                    // onExited={this.onDeviceListExited} 
                >
                    <InventoryPurchaseSupplierList
                        supplierList = {this.props.supplierList}
                        handleSupplierClicked = {this.handleSupplierClicked}
                    /> 
                </Collapse>
                <Collapse 
                    isOpen={'2' === this.state.selectedCollapseId}
                    // onEntering={this.onDeviceListEntering}
                    onExited={this.onItemExited} 
                >
                    {/* <DeviceMasterList
                        // upccode = {this.state.selectedInventory}
                        deviceMasterList = {this.props.deviceMasterList}
                        getDeviceMasterList = {this.props.getDeviceMasterList}
                        handleDeviceClicked = {this.handleDeviceClicked}
                        handleDeviceListBackClick = {this.handleDeviceListBackClick}
                    /> */}
                    <InventoryPurchaseItemList
                        supplierItemList={this.props.supplierItemList}
                        onPurchaseItemBack={this.handlePurchaseItemBack}
                        onPurchaseItemNext={this.handlePurchaseItemNext}
                        onInputValueChange={this.handleInputValueChange}
                    />
                </Collapse>
                <Collapse 
                    isOpen={'3' === this.state.selectedCollapseId}
                    onEntering={this.onDeviceDetailEntering}
                    onExited={this.onDeviceDetailExited}
                >
                    <InventoryPurchaseBilling
                        userObj = {this.props.userObj}
                        shippingAddress = {this.state.shippingAddress}
                        onInputChange = {this.handleInputChange}
                        onDefaultChange = {this.handleDefaultChange} 
                        onBackClick = {this.handleAddressBack}
                        onNextClick = {this.handleAddressNext}
                    />
                </Collapse>
                <Collapse 
                    isOpen={'4' === this.state.selectedCollapseId}
                    onEntering={this.onDeviceDetailEntering}
                    onExited={this.onDeviceDetailExited}
                >
                    <InventoryPurchaseSummary
                        userObj = {this.props.userObj}
                        shippingAddress = {this.state.shippingAddress}
                        currentSupplier = {this.state.currentSupplier}
                        itemList = {this.state.itemList}
                        supplierItemList={this.props.supplierItemList}
                        onBackClick = {this.handleSummaryBack}
                        sendOrder = {this.props.sendOrder}
                        onCheckout = {this.handleCheckout}
                    />
                </Collapse>
            </div>
        );
    }
}

export default InventoryPurchaseBoard;