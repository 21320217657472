/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_view from '../../../shared/img/icon/view.svg';
import '../../../scss/component/table.scss';

class RuleSection extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedRuleId: null,
            selectedRuleName:'',
        }
    }

    onRowSelect = (row, isSelected, e) => {
        let id = row.ruleid;
        let name = row.rulename;
        // alert(id);
         
        // let fence = this.props.allfences.find((e, i) => (e.fenceid == id));

        this.setState({
            selectedRuleId: id,
            selectedRuleName: name,
            // currentFenceName: fence.fencename,
            // currentFenceId: fence.fenceid,
            // fenceDeviceTypeName: fence.devicetypename,
            // fenceDeviceName: fence.devicename
        });
    }

    onRuleAddClick = () => {
        this.props.onRuleAddClick(this.state.selectedRuleId);
    }

    ruleFormatter = (cell, row) => {
        let ruleStr = '' ;
        let rule = row.rule;
        if (Array.isArray(rule)){
            for (let i=0 ; i < rule.length; i++){
                let item = rule[i];
                let relationOpr = '';
                let opr = ' ';
                switch (item.operator){
                    case 1 : 
                        opr = '='; 
                        break;
                    case 2 : 
                        opr = '>'; 
                        break;
                    case 3 : 
                        opr = '>='; 
                        break;
                    case 4 : 
                        opr = '<'; 
                        break;
                    case 5 : 
                        opr = '<='; 
                        break;
                    case 6 : 
                        opr = '<>'; 
                        break;
                    default:
                        opr = ' ';
                }
                if (i == 0){
                    if (item.relation != 0){
                        ruleStr = '' ;
                        break;
                    }
                    ruleStr += ` ${item.attributename} ${opr} ${item.value}`;
                }else{
                    if (item.relation == 0){
                        ruleStr = '' ;
                        break;
                    }
                    switch (item.relation){
                        case 1: 
                            relationOpr = 'AND'; 
                            break;
                        case 2: 
                            relationOpr = 'OR'; 
                            break;
                        case 3: 
                            relationOpr = 'NOT'; 
                            break;
                        default:
                            opr = ' ';
                    }
                    ruleStr += ` ${relationOpr} ${item.attributename} ${opr} ${item.value}`;
                }
            }
        }
        return ruleStr;
    }

    renderAllRules(){
        const options = {};
        const selectRowProp = {
            mode: 'radio',
            bgColor: 'pink', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
            hideSelectColumn: true,  // enable hide selection column.
            clickToSelect: true,  // you should enable clickToSelect, otherwise, you can't select column.
            onSelect: this.onRowSelect,
        };
        return(
            <div>
            <Row>
            <Col md={12}>
                <Card>
                    <CardBody>                   
                        <Label  style={{ display: 'inline'}} ><h4>All Rules</h4></Label> 
                        <BootstrapTable data={ this.props.allrules }  options={ options }     search bordered={ false } selectRow={ selectRowProp } height='300' >
                            <TableHeaderColumn dataField='ruleid' isKey={ true } dataAlign="center" hidden >Rule ID</TableHeaderColumn>
                            <TableHeaderColumn dataField='rulename' dataAlign="center" dataSort >Rule Name</TableHeaderColumn> 
                            <TableHeaderColumn dataField='rule' dataAlign="center" dataFormat = {this.ruleFormatter} >Rule</TableHeaderColumn> 
                            {/* <TableHeaderColumn dataFormat={ this.showButtonFormatter } ></TableHeaderColumn> */}
                        </BootstrapTable>
                    </CardBody>
                </Card>
            </Col>
            </Row>
            <Row>
                <Col md={12} style={{ textAlign: 'right' }}>
                    <br/>
                    {/* <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleCancleClick}>
                        Cancel
                    </Button> {' '} */}
                    <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.onRuleAddClick} >
                        Add
                    </Button>
                </Col>
            </Row>
            </div>
        );
    }
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    handleRowDeleteClick = (row) => {
        this.props.handleRowDeleteClick(row);
    }
    
    editButtonFormatter = (cell, row) => {
        let path = `/pages/device/${cell}`;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                {/* <img src={icon_view} style={{width: 23, height: 23}} alt={"View"} onClick={(e)=>{this.handleRowViewClick(row);}}/> */}
                <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} onClick={(e)=>{this.handleRowDeleteClick(row);}} />
            </div>
        )
    }

    renderActionRule() {
        const options = {};
        return(
            <Col md={12}>
            <Card>
                <CardBody>
                    <BootstrapTable data={ this.props.actionrule }  tableHeaderClass = 'header_white' options={ options }     >
                        <TableHeaderColumn dataField='ruleid' isKey={ true } dataAlign="center" hidden >Rule ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='rulename' dataAlign="center" dataSort >Rule Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='rule' dataAlign="center" dataFormat = {this.ruleFormatter}>Rule</TableHeaderColumn> 
                        <TableHeaderColumn dataAlign="center" dataFormat={ this.editButtonFormatter } ></TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        </Col>
        )   
    }
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    render(){
        return(
            <div>
                {this.renderAllRules()}
                <hr style={{width: '90%'}} />
                {this.renderActionRule()}
                {/* {this.renderFenceMap()} */}
            </div>
        );
    }
}

export default RuleSection;
