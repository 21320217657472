/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import DashBoardSettingList from './components/DashBoardSettingList';
import {getCurrentUser} from "../../factories/auth";

const ReportsPage = () => {
    let userObj = getCurrentUser();
    // if (userObj === null || userObj.userName.length === 0){
    //     this.props.history.push('/log_in');
    // }
     
     
    return(

        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h2 className="page-title">Dashboard Template</h2>
                    <hr />
                </Col>

            </Row>
            <Row>
                <DashBoardSettingList userObj = {userObj} />

            </Row>
        </Container>
    );
}

export default ReportsPage;
