/* eslint-disable */
import axios from 'axios';
import https from 'https';
import {toast} from "react-toastify";
import {
    // USER_LOGIN_SUCESS,
    // USER_LOGIN_FAILED,
    // USER_LOGOUT,
    USER_ADD_SUCCESS,
    USER_ADD_FAILED,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILED,
    USER_REMOVE_SUCCESS,
    USER_REMOVE_FAILED,
    USER_GET_FAILED,
    // USER_ADD_ASSETCLASS_LIST_SUCCESS,
    // USER_ADD_ASSETCLASS_LIST_FAILED,
    // USER_CHECK_ASSETCLASS_LIST_SUCCESS,
    // USER_CHECK_ASSETCLASS_LIST_FAILED,
    USER_ADD_DEVICETYPE_LIST_SUCCESS,
    USER_ADD_DEVICETYPE_LIST_FAILED,
    USER_CHECK_DEVICETYPE_LIST_SUCCESS,
    USER_CHECK_DEVICETYPE_LIST_FAILED,
    // USER_GET_COMPANY_LIST_SUCCESS,
    // USER_GET_COMPANT_LIST_FAILED,
    // USER_GET_ROLES_SUCCESS,
    // USER_GET_ROLES_FAILED,
    // USER_GET_COMPANY_DEVICE_TYPES_SUCCESS,
    // USER_GET_COMPANY_DEVICE_TYPES_FAILED,
    // USER_GET_COMPANY_ASSET_CLASS_SUCCESS,
    // USER_GET_COMPANY_ASSET_CLASS_FAILED,
    // USER_GET_COMPANY_OBJ_SUCCESS,
    // USER_GET_COMPANY_OBJ_FAILED,
    // USER_UPDATE_COMPANY_OBJ_SUCCESS,
    // USER_UPDATE_COMPANY_OBJ_FAILED,
    // USER_DELETE_COMPANY_OBJ_SUCCESS,
    // USER_DELETE_COMPANY_OBJ_FAILED,
    // USER_GET_ALL_COMPANIES_SUCCESS,
    // USER_GET_ALL_COMPANIES_FAILED,
    // USER_GET_COUNTY_LIST_SUCCESS,
    // USER_GET_PROVINCE_LIST_SUCCESS,
    USER_GET_ACCOUNT_LIST_SUCCESS,
    USER_GET_ACCOUNT_LIST_FAILED,
    // USER_GET_PLAN_ITEMS_SUCCESS,
    // USER_GET_PLAN_ITEMS_FAILED,
    // STORE_RESET,
    // CARD_ADD_FAILED,
    // CARD_ADD_SUCCESS,
    // ACCOUNT_ADD_ACCOUNT,
    // ACCOUNT_DELETE_ACCOUNT,
    // GET_ACCOUNT_CARDS_SUCCESS,
    // GET_ACCOUNT_CARDS_FAILED,
    DASHBOARDTEMPLATE_USERLIST_GET_SUCCESS,
    DASHBOARDTEMPLATE_LAYOUT_GET_SUCCESS,
    DASHBOARDTEMPLATE_LAYOUT_UPDATE_FAIL,
    DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS,
    DASHBOARDTEMPLATE_DELETE_SUCCESS,
    DASHBOARDTEMPLATE_NEW_SUCCESS,
    DASHBOARD_UPDATE_LAYOUT_FAILED,
    DASHBOARDTEMPLATE_LAYOUT_UPDATE_SUCCESS
} from '../../constants/actionTypes';

import {
    WEB_SERVICE_ROOT,
    WEB_SERVICE_GET_COMPANY_DEVICETYPE,
    // WEB_SERVICE_GET_COMPANY_ASSETCLASS,
    // WEB_SERVICE_GET_COMPANY,
    // WEB_SERVICE_GET_ROLES,
    WEB_SERVICE_USER_ROOT,
    //WEB_SERVICE_USER_CHECK,
    WEB_SERVICE_USER_ADD,
    WEB_SERVICE_USER_UPDATE,
    WEB_SERVICE_USER_REMOVE,
    // WEB_SERVICE_USER_GET,
    // WEB_SERVICE_USER_ADD_ASSETCLASS_LIST,
    // WEB_SERVICE_USER_ADD_ASSETCLASS_USER,
    // WEB_SERVICE_USER_CHECK_ASSETCLASS_LIST,
    // WEB_SERVICE_USER_CHECK_DEVICETYPE_USER_LIST,
    WEB_SERVICE_USER_CHECK_DEVICETYPE_LIST,
    WEB_SERVICE_USER_ADD_DEVICETYPE_LIST,
    WEB_SERVICE_USER_SUCCESS_CODE,
    // WEB_SERVICE_CHANGE_COMPANY_DEVICETYPE,
    // WEB_SERVICE_CHANGE_COMPANY_ASSETCLASS,
    // WEB_SERVICE_ROOT_DashBoardSetting,
    WEB_SERVICE_DASHBOARDTEMPLATE_USER_GET,
    WEB_SERVICE_GET_DASHBOARDTEMPLATE_LAYOUT_BY_ID,
    WEB_SERVICE_ROOT_DashBoard_Template_Setting,
    WEB_SERVICE_DASHBOARDTEMPLATE_ASSIGN_POST,
    WEB_SERVICE_UPDATE_DASHBOARDTEMPLATE_LAYOUT,
    WEB_SERVICE_DELETE_DASHBOARDTEMPLATE_BYID, WEB_SERVICE_NEW_DASHBOARDTEMPLATE_BY_NAME, WEB_SERVICE_LAYOUT,

} from '../../constants/appConstants';
// import {Countries} from '../../constants/dataConstants';
import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
import {loadDashBoardLayout} from "./dashboardActions";
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

// export function handleSubmit(values) {
//      
//      
//     // alert(values);
//     userLogin(values.name, values.password);
// }

// export function userLogin(userid, password) {
//     // alert('log in');
//     if ((!userid || userid.length === 0) || (!password || password.length === 0)) {
//         // alert('yes');
//         return dispatch => {
//             // alert('okay');
//             dispatch({
//                 //    type: USER_LOGIN_SUCESS,
//                 type: USER_LOGIN_FAILED,
//                 message: 'user name or password is empty'
//             })
//         }
//     }
//
//     let loginInfo = {
//         userName: userid,
//         password: password
//     };
//
//     const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_CHECK}`;
//      
//
//     const request = axios({
//         url: urlStr,
//         method: 'post',
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         data: loginInfo,
//     });
//
//     return ((dispatch) => {
//             dispatch({
//                 type: STORE_RESET,
//             })
//             request.then((response) => {
//                     if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         // sessionStorage.setItem('currentUser', response.data.userDetails);
//                         dispatch(
//                             {
//                                 type: USER_LOGIN_SUCESS,
//                                 payload: response.data.userDetails,
//                                 token: response.data.jwttoken,
//
//                             }
//                         )
//                     } else {
//                         dispatch(
//                             {
//                                 type: USER_LOGIN_FAILED,
//                                 // message: response.data.error,
//                                 message: 'Log In Failed',
//
//                             }
//                         )
//                     }
//
//                 }
//             ).catch((error) => {
//                     dispatch(
//                         {
//                             type: USER_LOGIN_FAILED,
//                             message: error.message,
//
//                         }
//                     )
//                 }
//             )
//         }
//     );
// }

// export function userLogout() {
//     return (dispatch => {
//         dispatch(
//             {
//                 type: USER_LOGOUT,
//             }
//         )
//     })
// }

export function getDashBoardTemplateUserLists() {



    let urlStr = `${WEB_SERVICE_ROOT_DashBoard_Template_Setting}${WEB_SERVICE_DASHBOARDTEMPLATE_USER_GET}`;
     


    const request = axios({
        url: urlStr,
        method: 'get',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
         params: {
            token: sessionStorage.getItem('authToken'),
        //     userName: criteria.userName || undefined
         },
        //data: obj,
    });

    return ((dispatch) => {
            request.then((response) => {
                console.log('********** response is:' , response)
                console.log('**********get user response data is:' , response.data)
                console.log('**********get user status is:' , response.status)
                    if (response.status === 200) {
                        dispatch(
                            {
                                type: DASHBOARDTEMPLATE_USERLIST_GET_SUCCESS,
                                payload: response.data,

                            }
                        );
                    } else {
                        let message = 'Getting Users Failed';
                        console.error(message);
                        dispatch(
                            {
                                type: USER_GET_FAILED,
                                message: message,

                            }
                        )
                    }

                }
            ).catch((error) => {
                    console.error(error.message);
                    dispatch(
                        {
                            type: USER_GET_FAILED,
                            message: error.message,

                        }
                    )
                }
            )
        }
    );


}


export function deleteDashBoardTemplateEntryById(id){
    let urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_UPDATE_DASHBOARDTEMPLATE_LAYOUT}`;
     

    const request = axios({
        url: urlStr,
        method: 'delete',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            token: sessionStorage.getItem('authToken'),
            dashboardTemplateid: id
        },
        //data: obj,
    });


    return ((dispatch) => {
            request.then((response) => {
                    console.log('update response is:' , response)
                    if (response.status === 200) {
                        let message = 'Delete Dashboard Template widget Success';
                        dispatch(
                            {
                                type: DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS,
                                payload: id,

                            }

                        );
                        toast.success(message);
                    } else {
                        let message = 'Delete Dashboard Template widget Failed';
                        console.error(message);
                        // dispatch(
                        //     {
                        //         type: DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS,
                        //         message: message,
                        //
                        //     }
                        // )
                        toast.error(message)
                    }

                }
            ).catch((error) => {
                    console.error(error.message);

                }
            )
        }
    );
}

export function newDashboardTemplateByName(name){
    let urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_NEW_DASHBOARDTEMPLATE_BY_NAME}`;
     
    const request = axios({
        url: urlStr,
        method: 'get',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            token: sessionStorage.getItem('authToken'),
            templatename: name
        },
        //data: obj,
    });




    return ((dispatch) => {
            request.then((response) => {
                    console.log('update response is:' , response)
                    if (response.status === 200) {
                        let message = 'New Dashboard Template Success';
                        dispatch(
                            {
                                type: DASHBOARDTEMPLATE_NEW_SUCCESS,
                                payload: response.data,

                            }

                        );
                        toast.success(message);
                    } else {
                        let message = 'New Dashboard Template Failed';
                        console.error(message);
                        // dispatch(
                        //     {
                        //         type: DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS,
                        //         message: message,
                        //
                        //     }
                        // )
                        toast.error(message);
                    }

                }
            ).catch((error) => {
                    console.error(error.message);

                }
            )
        }
    );
}

export function addDashBoardTemplateEntry (layout){


    const request = axios({
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_UPDATE_DASHBOARDTEMPLATE_LAYOUT}`,
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },

        params: {
            token: sessionStorage.getItem('authToken'),
        },
        data: layout,
    });

    return (
        despatch => {
            request.then( response => {
                if (response.status == 200) {
                    let message = 'Add Dashboard Template Entry Success';
                    // despatch({
                    //     type: DASHBOARD_UPDATE_LAYOUT_SUCCEED,
                    //     payload: response.data,
                    // });
                    despatch(getDashBoardTemplateLayoutById(layout.templateid));

                    toast.success(message)
                }else{
                    let message = 'Add Dashboard Template Entry Failed';
                    toast.error(message);
                }
            }).catch( error => {

                console.error(error.message);
            })
        }
    );
}




export function deleteDashBoardTemplateById(id){
    let urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_DELETE_DASHBOARDTEMPLATE_BYID}`;
     

    const request = axios({
        url: urlStr,
        method: 'delete',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            token: sessionStorage.getItem('authToken'),
            dashboardTemplateid: id
        },
        //data: obj,
    });


    return ((dispatch) => {
            request.then((response) => {
                    console.log('update response is:' , response)
                    if (response.status === 200) {
                        let message = 'Delete Dashboard Template Success';
                        dispatch(
                            {
                                type: DASHBOARDTEMPLATE_DELETE_SUCCESS,
                                payload: id,

                            }

                        );
                        toast.success(message);
                    } else {
                        let message = 'Delete Dashboard Template Failed';
                        console.error(message);
                        // dispatch(
                        //     {
                        //         type: DASHBOARDTEMPLATE_LAYOUT_DELETE_SUCCESS,
                        //         message: message,
                        //
                        //     }
                        // )
                        toast.error(message);
                    }

                }
            ).catch((error) => {
                    console.error(error.message);

                }
            )
        }
    );
}

export function updateDashBoardTemplateLayout(obj,id){
    let urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_UPDATE_DASHBOARDTEMPLATE_LAYOUT}`;
     
    console.log('obj in updateDashBoardTemplateLayout is ',obj)

    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            token: sessionStorage.getItem('authToken'),
            //templateid: templateId
        },
        data: obj,
    });

    return (
        despatch => {
            request.then( response => {
                if (response.status == 200) {
                    let message = 'Update Dashboard Template Entry Success';
                    // despatch({
                    //     type: DASHBOARD_UPDATE_LAYOUT_SUCCEED,
                    //     payload: response.data,
                    // });
                    despatch(getDashBoardTemplateLayoutById(id));

                    toast.success(message)
                }else{
                    let message = 'Update Dashboard Template Entry Failed';
                    toast.error(message);
                }
            }).catch( error => {

                console.error(error.message);
            })
        }
    );
    // return ((dispatch) => {
    //         request.then((response) => {
    //                 console.log('update response is:' , response)
    //                 if (response.status === 200) {
    //                     dispatch(
    //                         {
    //                             type: DASHBOARDTEMPLATE_LAYOUT_UPDATE_SUCCESS,
    //                             payload: response.data,
    //
    //                         }
    //                     );
    //                 } else {
    //                     let message = 'UPDATE DASHBOARDTEMPLATE_LAYOUT Failed';
    //                     console.error(message);
    //                     dispatch(
    //                         {
    //                             type: DASHBOARDTEMPLATE_LAYOUT_UPDATE_FAIL,
    //                             message: message,
    //
    //                         }
    //                     )
    //                 }
    //
    //             }
    //         ).catch((error) => {
    //                 console.error(error.message);
    //
    //             }
    //         )
    //     }
    // );
}



export function getDashBoardTemplateLayoutById(templateId) {



    let urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_DASHBOARDTEMPLATE_LAYOUT_BY_ID}`;
     


    const request = axios({
        url: urlStr,
        method: 'get',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            token: sessionStorage.getItem('authToken'),
            templateid: templateId
        },
        //data: obj,
    });

    return ((dispatch) => {
            request.then((response) => {
                    console.log('********** response is:' , response)
                    if (response.status === 200) {
                        dispatch(
                            {
                                type: DASHBOARDTEMPLATE_LAYOUT_GET_SUCCESS,
                                payload: response.data,

                            }
                        );
                    } else {
                        let message = 'Getting DASHBOARDTEMPLATE_LAYOUT Failed';
                        console.error(message);
                        dispatch(
                            {
                                type: USER_GET_FAILED,
                                message: message,

                            }
                        )
                    }

                }
            ).catch((error) => {
                    console.error(error.message);

                }
            )
        }
    );


}



export function postAssignDashBoardTemplate(obj) {

    console.log('post assign dashboard template')

    let urlStr = `${WEB_SERVICE_ROOT_DashBoard_Template_Setting}${WEB_SERVICE_DASHBOARDTEMPLATE_ASSIGN_POST}`;
     


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            token: sessionStorage.getItem('authToken'),
            //     userName: criteria.userName || undefined
        },
        data: obj,
    });

    return ((dispatch) => {
            request.then((response) => {
                    console.log('********** response is:' , response)
                    console.log('**********get user response data is:' , response.data)
                    console.log('**********get user status is:' , response.status)
                    // if (response.status === 200) {
                    //     dispatch(
                    //         {
                    //             type: DASHBOARDTEMPLATE_USERLIST_GET_SUCCESS,
                    //             payload: response.data,
                    //
                    //         }
                    //     );
                    // } else {
                    //     let message = 'Getting Users Failed';
                    //     console.error(message);
                    //     dispatch(
                    //         {
                    //             type: USER_GET_FAILED,
                    //             message: message,
                    //
                    //         }
                    //     )
                    // }

                }
            ).catch((error) => {
                    console.error('got an error',error.message);
                    // dispatch(
                    //     {
                    //         type: USER_GET_FAILED,
                    //         message: error.message,
                    //
                    //     }
                    // )
                }
            )
        }
    );


}


export function addUser(userObj, reLoad = false) {
    if (!userObj || userObj === null) {
        return dispatch => {
            dispatch({
                type: USER_ADD_FAILED,
                message: 'user input is not valid'
            })
        }
    }

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_ADD}`;
     

    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userObj,
    });

    return ((dispatch) => {
            request.then((response) => {
                    if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                        dispatch(
                            {
                                type: USER_ADD_SUCCESS,
                                payload: response.data.userDetails,

                            }
                        );
                        let userObje = response.data.userDetails;
                        if (userObje.role.includes('ADMIN')) {
                             
                            dispatch(setUserCompanyDevceType(userObj));
                        }
                        if (reLoad) {
                            dispatch(getUsers());
                        }
                        toast.success("Successfully Added User " + userObj.userName);
                    } else {
                        dispatch(
                            {
                                type: USER_ADD_FAILED,
                                message: 'Adding User Failed',

                            }
                        )
                        console.error(response.data.error);
                        toast.error("Failed To Add User " + userObj.userName + ": " + response.data.errorInfo);
                    }

                }
            ).catch((error) => {
                    dispatch(
                        {
                            type: USER_ADD_FAILED,
                            message: error.message,

                        }
                    )
                    console.error(error.message);
                    toast.error("Error occurred when Adding User " + userObj.userName + ": " + error.message);
                }
            )
        }
    );
}

export function setUserCompanyDevceType(userObj) {
    if (userObj === null || userObj.userName.length === 0) {
         
         
        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }
    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    return dispatch => axios({
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_DEVICETYPE}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    }).then(res => {
        if (res.status === 200) {
            if (res.data && Array.isArray(res.data)) {
                 
                let devicetypeidList = res.data.map((item, index) => (item.devicetypeid));
                 
                let devicetypeMapObj = {
                    userId: userName,
                    devicetypeid: devicetypeidList,
                };
                dispatch(upsertUserDevicetypeList(devicetypeMapObj));
            }
        } else {
            toast.error(res.data);
        }

    }).catch(err => {
        console.log(err)
        toast.error(err.message);
    })
}

// export function getUserCompanyDeviceTypes() {
//     let paramObj = {
//         url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_DEVICETYPE}`,
//         criteria: null,
//         logText: 'Get user company device type url ',
//         sccessType: USER_GET_COMPANY_DEVICE_TYPES_SUCCESS,
//         failedType: USER_GET_COMPANY_DEVICE_TYPES_FAILED,
//         errorMessage: 'Failed to get company device.'
//     };
//
//     return (axiosGet(paramObj));
// }
//
// export function getUserCompanyAssetClass() {
//     let paramObj = {
//         url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_ASSETCLASS}`,
//         criteria: null,
//         logText: 'Get user asset class type url ',
//         sccessType: USER_GET_COMPANY_ASSET_CLASS_SUCCESS,
//         failedType: USER_GET_COMPANY_ASSET_CLASS_FAILED,
//         errorMessage: 'Failed to get asset class.'
//     };
//
//     return (axiosGet(paramObj));
// }

export function updateUser(userObj, reLoad = false, isCurrent = false, callBack = null) {
    if (!userObj || userObj === null) {
        return dispatch => {
            dispatch({
                type: USER_UPDATE_FAILED,
                message: 'user input is not valid'
            })
        }
    }

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_UPDATE}`;
     

    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userObj,
    });

    return ((dispatch) => {
            request.then((response) => {
                    if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                        // dispatch(
                        //     {
                        //         type: USER_UPDATE_SUCCESS,
                        //         payload: response.data.userDetails,
                        //     }
                        // );
                        if (response.status == 200 && isCurrent) {
                            dispatch(
                                {
                                    type: USER_UPDATE_SUCCESS,
                                    payload: response.data.userDetails,
                                }
                            );
                        }
                        if (response.status == 200 && reLoad) {
                            dispatch(getUsers());
                        }
                        if (callBack) {
                            callBack(response.data.userDetails);
                        }
                        toast.success("Successfully Updated User " + userObj.userName);
                    } else {
                        dispatch(
                            {
                                type: USER_UPDATE_FAILED,
                                message: 'Updating User Failed',
                            }
                        )
                        console.error(response.data.error);
                        toast.error("Failed To Update User " + userObj.userName);
                    }

                }
            ).catch((error) => {
                    dispatch(
                        {
                            type: USER_UPDATE_FAILED,
                            message: error.message,
                        }
                    )
                    console.error(error.message);
                    toast.error("Error occurred when Updating User " + userObj.userName);
                }
            )
        }
    );
}

export function removeUser(userid, reLoad = false) {
    if (!userid || userid.length === 0) {
        return dispatch => {
            dispatch({
                type: USER_REMOVE_FAILED,
                message: 'user id is empty'
            })
        }
    }

    // const params = `?userid=${uerid}`;
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_REMOVE}`;
     

    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'text/plain'
        },
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        // data: userObj,
        data: userid
    });

    return ((dispatch) => {
            request.then((response) => {
                    if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                        dispatch(
                            {
                                type: USER_REMOVE_SUCCESS,
                                payload: response.data.userDetails,
                            }
                        );
                        if (response.status == 200 && reLoad) {
                            dispatch(getUsers());
                        }
                        toast.success("Successfully Removed User " + userid);
                    } else {
                        dispatch(
                            {
                                type: USER_REMOVE_FAILED,
                                message: 'Removing User Failed',
                            }
                        )
                        console.error(response.data.error);
                        toast.error("Failed to remove User " + userid);
                    }

                }
            ).catch((error) => {
                    dispatch(
                        {
                            type: USER_REMOVE_FAILED,
                            message: error.message,
                        }
                    )
                    console.error(error.message);
                    toast.error("Error occurred when removing User " + userid);
                }
            )
        }
    );
}

// export function getUserAssetclassList(userid) {
//     if (!userid || userid.length === 0) {
//         return dispatch => {
//             dispatch({
//                 type: USER_CHECK_ASSETCLASS_LIST_FAILED,
//                 message: 'user id is empty'
//             })
//         }
//     }
//
//     // const params = `?userID =${userid}`;
//     const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_CHECK_ASSETCLASS_LIST}`;
//      
//
//     const request = axios({
//         url: urlStr,
//         method: 'post',
//         headers: {
//             'Content-Type': 'text/plain'
//         },
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         data: userid,
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         dispatch(
//                             {
//                                 type: USER_CHECK_ASSETCLASS_LIST_SUCCESS,
//                                 payload: response.data.classMap,
//                             }
//                         );
//                     } else {
//                         dispatch(
//                             {
//                                 type: USER_CHECK_ASSETCLASS_LIST_FAILED,
//                                 message: 'Fetching User Assetclass List Failed',
//                             }
//                         )
//                     }
//
//                 }
//             ).catch((error) => {
//                     dispatch(
//                         {
//                             type: USER_CHECK_ASSETCLASS_LIST_FAILED,
//                             message: error.message,
//                         }
//                     )
//                 }
//             )
//         }
//     );
// }

// export function upsertUserAssetclassList(assetMapObj) {
//     const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_ADD_ASSETCLASS_LIST}`;
//      
//      
//     const request = axios({
//         url: urlStr,
//         method: 'post',
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         data: assetMapObj,
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         dispatch(
//                             {
//                                 type: USER_ADD_ASSETCLASS_LIST_SUCCESS,
//                                 payload: response.data.classMap,
//                             }
//                         );
//                         dispatch(getUserAssetclassList(assetMapObj.userId));
//                         toast.success("Successfully Updated the asset classes access for the user " + assetMapObj.userId);
//                     } else {
//                         dispatch(
//                             {
//                                 type: USER_ADD_ASSETCLASS_LIST_FAILED,
//                                 message: 'Updating User Asset classes access setting Failed',
//                             }
//                         );
//                         toast.error("Failed to Update the asset classes access for the user " + assetMapObj.userId);
//                     }
//
//                 }
//             ).catch((error) => {
//                     dispatch(
//                         {
//                             type: USER_ADD_ASSETCLASS_LIST_FAILED,
//                             message: error.message,
//                         }
//                     )
//                     toast.error("Error occurred when updating the asset classes access for the user " + assetMapObj.userId);
//                 }
//             )
//         }
//     );
//
// }

// ------------------------------------------------------------------------------------------------------------------------
export function getUserDevicetypeList(userid) {
    if (!userid || userid.length === 0) {
        return dispatch => {
            dispatch({
                type: USER_CHECK_DEVICETYPE_LIST_FAILED,
                message: 'user id is empty'
            })
        }
    }

    // const params = `?userID =${userid}`;
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_CHECK_DEVICETYPE_LIST}`;
     

    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'text/plain'
        },
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userid,
    });

    return ((dispatch) => {
            request.then((response) => {
                    if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                        dispatch(
                            {
                                type: USER_CHECK_DEVICETYPE_LIST_SUCCESS,
                                payload: response.data.devicetypesMap,
                            }
                        );
                    } else {
                        dispatch(
                            {
                                type: USER_CHECK_DEVICETYPE_LIST_FAILED,
                                message: 'Fetching User Devictype List Failed',
                            }
                        )
                    }

                }
            ).catch((error) => {
                    dispatch(
                        {
                            type: USER_CHECK_DEVICETYPE_LIST_FAILED,
                            message: error.message,
                        }
                    )
                }
            )
        }
    );
}

// --------------------------------------------
export function upsertUserDevicetypeList(devicetypeMapObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_ADD_DEVICETYPE_LIST}`;
     
     
    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: devicetypeMapObj,
    });

    return ((dispatch) => {
            request.then((response) => {
                    if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                        dispatch(
                            {
                                type: USER_ADD_DEVICETYPE_LIST_SUCCESS,
                                payload: response.data.devicetypesMap,
                            }
                        );
                        dispatch(getUserDevicetypeList(devicetypeMapObj.userId));
                        toast.success("Successfully Updated the Device Type access for the user " + devicetypeMapObj.userId);
                    } else {
                        dispatch(
                            {
                                type: USER_ADD_DEVICETYPE_LIST_FAILED,
                                message: 'Adding/Updating User Device Types access Failed',
                            }
                        );
                        toast.error("Failed to Update the Device Type access for the user " + devicetypeMapObj.userId);
                    }

                }
            ).catch((error) => {
                    dispatch(
                        {
                            type: USER_ADD_DEVICETYPE_LIST_FAILED,
                            message: error.message,
                        }
                    )
                    toast.error("Error occurred when updating the assetclas list assigned to the user " + devicetypeMapObj.userId);
                }
            )
        }
    );

}

// export function getCompanyList() {
//     let userObj = getCurrentUser();
//     if (userObj === null || userObj.userName.length === 0) {
//          
//          
//         return dispatch => {
//             dispatch({
//                 type: USER_GET_FAILED,
//                 error: "Failed to get current user, Please re-login",
//                 message: "Failed to get current user, Please re-login",
//             });
//         }
//     }
//
//     let {userName, companyCode, role} = userObj;
//     let userTokenObj = {userName, companyCode, role};
//
//     const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY}`;
//     // const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;
//
//      
//
//     const request = axios({
//         url: urlStr,
//         method: 'get',
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         headers: {
//             'Content-Type': 'application/json',
//             'uTk': base64Encode(JSON.stringify(userTokenObj)),
//         },
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.status === 200) {
//                         dispatch(
//                             {
//                                 type: USER_GET_COMPANY_LIST_SUCCESS,
//                                 payload: response.data,
//                             }
//                         );
//                     } else {
//                         dispatch(
//                             {
//                                 type: USER_GET_COMPANT_LIST_FAILED,
//                                 message: 'Getting compang list Failed',
//                             }
//                         );
//                         console.error('Getting compang list Failed');
//                     }
//
//                 }
//             ).catch((error) => {
//                     dispatch(
//                         {
//                             type: USER_GET_COMPANT_LIST_FAILED,
//                             message: error.message,
//                         }
//                     );
//                     console.error(error.message);
//                 }
//             )
//         }
//     );
//
// }

// export function getAllCompanies() {
//     const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;
//     let paramObj = {
//         url: urlStr,
//         criteria: null,
//         logText: 'Get all company Url ',
//         sccessType: USER_GET_ALL_COMPANIES_SUCCESS,
//         failedType: USER_GET_ALL_COMPANIES_FAILED,
//         errorMessage: 'Failed to get all company.'
//     };
//
//     return (axiosGet(paramObj));
// }

// export function getAllPlanItems() {
//     const urlStr = 'http://192.168.2.58:8080/item';
//     let paramObj = {
//         url: urlStr,
//         criteria: null,
//         logText: 'Get all plan items Url ',
//         sccessType: USER_GET_PLAN_ITEMS_SUCCESS,
//         failedType: USER_GET_PLAN_ITEMS_FAILED,
//         errorMessage: 'Failed to get all plan item.'
//     };
//
//     return (axiosGet(paramObj));
// }
//
// export function getOneCompanyByCode(code) {
//     if (!code) {
//         let emptyCompany = [
//             {
//                 "companyName": null,
//                 "businessArea": null,
//                 "phoneNum": null,
//                 "postcode": null,
//                 "city": null,
//                 "province": "ON",
//                 "country": "CA",
//                 "addressline1": null,
//                 "addressline2": null,
//                 "email": null,
//                 "status": "A",
//                 "isnew": true,
//             }
//         ];
//         return ((dispatch) => {
//             dispatch({
//                 type: USER_GET_COMPANY_OBJ_SUCCESS,
//                 payload: emptyCompany,
//             });
//         })
//     }
//     const criteria = {code: code}
//     let paramObj = {
//         url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`,
//         criteria: criteria,
//         logText: 'Get One company Url ',
//         sccessType: USER_GET_COMPANY_OBJ_SUCCESS,
//         failedType: USER_GET_COMPANY_OBJ_FAILED,
//         errorMessage: 'Failed to get One company.'
//     };
//
//     return (axiosGet(paramObj));
// }
//
// export function updateCompany(companyObj) {
//
//     let userObj = getCurrentUser();
//     if (userObj === null || userObj.userName.length === 0) {
//          
//          
//         return dispatch => {
//             dispatch({
//                 type: USER_GET_FAILED,
//                 error: "Failed to get current user, Please re-login",
//                 message: "Failed to get current user, Please re-login",
//             });
//         }
//     }
//
//     let {userName, companyCode, role} = userObj;
//     let userTokenObj = {userName, companyCode, role};
//
//     const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;
//      
//      
//     const request = axios({
//         url: urlStr,
//         method: 'post',
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         data: companyObj,
//         headers: {
//             'Content-Type': 'application/json',
//             'uTk': base64Encode(JSON.stringify(userTokenObj)),
//         },
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         dispatch(
//                             {
//                                 type: USER_UPDATE_COMPANY_OBJ_SUCCESS,
//                                 payload: response.data.company,
//                             }
//                         );
//                         // dispatch(getCompanyList());
//                         dispatch(getAllCompanies());
//                         toast.success("Successfully Updated the company " + companyObj.companyName);
//                     } else {
//                         dispatch(
//                             {
//                                 type: USER_UPDATE_COMPANY_OBJ_FAILED,
//                                 message: response.data.errorInfo,
//                             }
//                         );
//                         console.error("Add/Update Company :" + response.data.errorInfo);
//                         toast.error("Failed to Update the company " + companyObj.companyName);
//                     }
//
//                 }
//             ).catch((error) => {
//                     dispatch(
//                         {
//                             type: USER_UPDATE_COMPANY_OBJ_FAILED,
//                             message: error.message,
//                         }
//                     )
//                     console.error("Add/Update Company :" + error.message);
//                     toast.error("Error occurred when updating the the company " + companyObj.companyName);
//                 }
//             )
//         }
//     );
// }
//
// export function deleteCompany(companyObj) {
//
//     let userObj = getCurrentUser();
//     if (userObj === null || userObj.userName.length === 0) {
//          
//          
//         return dispatch => {
//             dispatch({
//                 type: USER_GET_FAILED,
//                 error: "Failed to get current user, Please re-login",
//                 message: "Failed to get current user, Please re-login",
//             });
//         }
//     }
//
//     let {userName, companyCode, role} = userObj;
//     let userTokenObj = {userName, companyCode, role};
//
//     let queryStr = `?code=${companyObj.companyCode}`
//     const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}${queryStr}`;
//      
//      
//     const request = axios({
//         url: urlStr,
//         method: 'delete',
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         // data: companyObj,
//         headers: {
//             'Content-Type': 'application/json',
//             'uTk': base64Encode(JSON.stringify(userTokenObj)),
//         },
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         dispatch(
//                             {
//                                 type: USER_DELETE_COMPANY_OBJ_SUCCESS,
//                                 // payload: response.data.company,
//                             }
//                         );
//                         // dispatch(getCompanyList());
//                         // dispatch(updateCompanyDeviceList(companyObj.companyCode,[]));
//                         dispatch(deleteCompanyAssociatedItems(companyObj));
//                         dispatch(getAllCompanies());
//                         toast.success("Successfully Delete the company " + companyObj.companyName);
//                     } else {
//                         dispatch(
//                             {
//                                 type: USER_DELETE_COMPANY_OBJ_FAILED,
//                                 message: 'Deleting User Company Failed',
//                             }
//                         );
//                         toast.error("Failed to Delete the company " + companyObj.companyName);
//                     }
//
//                 }
//             ).catch((error) => {
//                     dispatch(
//                         {
//                             type: USER_DELETE_COMPANY_OBJ_FAILED,
//                             message: error.message,
//                         }
//                     )
//                     console.error(error.message)
//                     toast.error("Error occurred when deleting the the company " + companyObj.companyName);
//                 }
//             )
//         }
//     );
// }
//
// export function getCountries() {
//     let reContryList = [];
//     Object.entries(Countries).forEach(([key, value], index) => {
//         reContryList.push({
//             label: value.name,
//             value: key,
//         });
//     });
//
//     return ((dispatch) => {
//         dispatch({
//             type: USER_GET_COUNTY_LIST_SUCCESS,
//             payload: reContryList,
//         })
//     })
// }
//
// export function getProvinces(countyKey) {
//     let reProvList = [];
//     let selectedCountry = Countries[countyKey];
//     if (selectedCountry && selectedCountry.provinces) {
//         selectedCountry.provinces.forEach((element, index) => {
//             reProvList.push({
//                 label: element.name,
//                 value: element.abbrev,
//             });
//         });
//     }
//
//     return ((dispatch) => {
//         dispatch({
//             type: USER_GET_PROVINCE_LIST_SUCCESS,
//             payload: reProvList,
//         })
//     })
//
// }
//
// export function getRoles() {
//
//     let paramObj = {
//         url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ROLES}`,
//         criteria: null,
//         logText: 'Get Roles Url ',
//         sccessType: USER_GET_ROLES_SUCCESS,
//         failedType: USER_GET_ROLES_FAILED,
//         errorMessage: 'Failed to get User Roles.'
//     };
//
//     return (axiosGet(paramObj));
// }

export function getAccounts(companyCode) {

    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        url: `http://192.168.2.58:8080/accountbycompany?id=${companyCode}`,
        criteria: null,
        logText: 'Get Accounts Url ',
        sccessType: USER_GET_ACCOUNT_LIST_SUCCESS,
        failedType: USER_GET_ACCOUNT_LIST_FAILED,
        errorMessage: 'Failed to get Accounts.'
    };

    return (axiosGet(paramObj));
}

// export function getCards(id) {
//
//     let paramObj = {
//         // url: 'http://192.168.2.58:8080/account?id=1',
//         url: 'http://192.168.2.58:8080/card?id=' + id,
//         criteria: null,
//         logText: 'Get cards Url ',
//         sccessType: GET_ACCOUNT_CARDS_SUCCESS,
//         failedType: GET_ACCOUNT_CARDS_FAILED,
//         errorMessage: 'Failed to get cards.'
//     };
//
//     return (axiosGet(paramObj));
// }

// export function createCard(cardObj, companyCode) {
//     console.log("debug createCard cardObj " + cardObj.accId)
//     console.log("debug createCard companyCode ", companyCode)
//     return dispatch => axios({
//         url: 'http://192.168.2.58:8080/card',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         method: 'post',
//         data: cardObj
//     }).then(res => {
//         if (res.status === 200) {
//             dispatch(getCards(cardObj.accId));
//             dispatch(getAccounts(companyCode));
//         }
//          
//         return res;
//     }).catch(err => {
//         console.error(err)
//         return err.response;
//     })
//
//     // const urlStr = 'http://192.168.2.58:8080/card';
//     //  
//     //  
//
//     // const request = axios({
//     //     url: urlStr,
//     //     method: 'post',
//     //     // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//     //     data: cardObj,
//     // });
//
//     // return ( (dispatch) => {
//     //     request.then( (response) => {
//     //             if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE){
//     //                 dispatch(
//     //                     {
//     //                         type: CARD_ADD_SUCCESS,
//     //                         payload: response.data,
//
//     //                     }
//     //                 );
//     //                 let cardObje = response.data;
//     //                 if (reLoad) {
//     //                     dispatch(getAccounts());
//     //                 }
//
//     //                 toast.success("Successfully Added credit card " + cardObje.pan);
//     //             }else{
//     //                 dispatch(
//     //                     {
//     //                         type: CARD_ADD_FAILED,
//     //                         message: 'Adding Card Failed',
//
//     //                     }
//     //                 )
//
//
//     //             }
//
//     //         }
//     //     ).catch( (error) => {
//     //             dispatch(
//     //                 {
//     //                     type: CARD_ADD_FAILED,
//     //                     message: error.message,
//
//     //                 }
//     //             )
//     //             console.error("debug add card error: " + error.message);
//     //           //  toast.error("Error occurred when Adding card " + userObj.pan + ": " + error.message);
//     //         }
//     //     )
//     // }
//     // );
// }

// export const addToAccounts = (payload) => dispatch => dispatch({type: ACCOUNT_ADD_ACCOUNT, payload})

export function postAccount(data) {
    console.log(data)
    return dispatch => axios({
        url: 'http://192.168.2.58:8080/account',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        data: data
    }).then(res => {
        if (res.status === 200 && res.data && res.data.account) {
            dispatch(getAccounts(res.data.account.companyCode));
        }
         
        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })
}

export function deleteAccount(data) {
     
    return dispatch => axios({
        url: 'http://192.168.2.58:8080/account?id=' + data.id,
        headers: {
            'Content-Type': 'application/json',
            //  "Access-Control-Allow-Origin": "*",
            //     'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
            //     "Access-Control-Allow-Headers": "X-Requested-With, Content-Type",
        },
        method: 'DELETE',
    }).then(res => {
        if (res.status === 200) {
            dispatch(getAccounts(data.companyCode));
        }
         
        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })
}


// export function postOrder(data) {
//     console.log("debug postOrder  " + data)
//     return dispatch => axios({
//         url: 'http://192.168.2.58:8080/order',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         method: 'post',
//         data: data
//     }).then(res => {
//         if (res.status === 200) {
//             // dispatch(getAccounts());
//         }
//         return res;
//     }).catch(err => {
//         console.error(err)
//         return err.response;
//     })
// }


// WEB_SERVICE_CHANGE_COMPANY_DEVICETYPE,
// WEB_SERVICE_CHANGE_COMPANY_ASSETCLASS,
// export function updateCompanyDeviceList(companycode, companyDeviceListArr) {
//
//     const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_CHANGE_COMPANY_DEVICETYPE}`;
//      
//
//     const request = axios({
//         url: urlStr,
//         method: 'post',
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         data: companyDeviceListArr,
//         headers: {
//             'Content-Type': 'application/json',
//             'companycode': companycode,
//         },
//
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         // dispatch(
//                         //     {
//                         //         type: USER_UPDATE_COMPANY_OBJ_SUCCESS,
//                         //         payload: response.data.company,
//                         //     }
//                         // );
//                         dispatch(getUserCompanyDeviceTypes());
//                         toast.success("Successfully Updated the company device list ");
//                     } else {
//                         // dispatch(
//                         //     {
//                         //         type: USER_UPDATE_COMPANY_OBJ_FAILED,
//                         //         message: response.data.errorInfo,
//                         //     }
//                         // );
//                         console.error("Add/Update Company : error ");
//                         toast.error("Failed to Update the company device list ");
//                     }
//
//                 }
//             ).catch((error) => {
//                     // dispatch(
//                     //     {
//                     //         type: USER_UPDATE_COMPANY_OBJ_FAILED,
//                     //         message: error.message,
//                     //     }
//                     // )
//                     console.error("Add/Update Company :" + error.message);
//                     toast.error("Error occurred when updating the the company device list ");
//                 }
//             )
//         }
//     );
// }
//
// export function updateCompanyAssetClass(companycode, companyAssetClassArr) {
//
//     const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_CHANGE_COMPANY_ASSETCLASS}`;
//      
//
//     const request = axios({
//         url: urlStr,
//         method: 'post',
//         data: companyAssetClassArr,
//         headers: {
//             'Content-Type': 'application/json',
//             'companycode': companycode,
//         },
//
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         dispatch(getUserCompanyAssetClass());
//                         toast.success("Successfully Updated the company asset class ");
//                     } else {
//                         console.error("Add/Update Company asset class: error");
//                         toast.error("Failed to Update the company asset class ");
//                     }
//
//                 }
//             ).catch((error) => {
//                     console.error("Add/Update Company asset class:" + error.message);
//                     toast.error("Error occurred when updating the the company asset class ");
//                 }
//             )
//         }
//     );
// }
//
// export function deleteCompanyAssociatedItems(companyObj) {
//
//     let queryStr = `?companycode=${companyObj.companyCode}`
//     const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY}${queryStr}`;
//      
//      
//     const request = axios({
//         url: urlStr,
//         method: 'delete',
//         // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
//         // data: companyObj,
//         headers: {
//             'Content-Type': 'application/json',
//             // 'uTk': base64Encode(JSON.stringify(userTokenObj)),
//         },
//     });
//
//     return ((dispatch) => {
//             request.then((response) => {
//                     if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
//                         dispatch(getUserCompanyDeviceTypes());
//                         toast.success("Successfully Delete the company associated items " + companyObj.companyName);
//                     } else {
//                         toast.error("Failed to Delete the company " + companyObj.companyName);
//                     }
//
//                 }
//             ).catch((error) => {
//                     console.error(error.message)
//                     toast.error("Error occurred when deleting the the company " + companyObj.companyName);
//                 }
//             )
//         }
//     );
// }

