/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import { Card, CardBody, Col, Row, Button, Container, Input } from 'reactstrap';

import { bindActionCreators } from 'redux';
import { getSimUsage, getPageContent, getActivatedSimDashboard } from '../../../../../redux/actions/userAction';
import { getCurrentUser, hasRole } from '../../../../../factories/auth';
import ReactFileReader from 'react-file-reader';
import { addMultiDevicesInventory } from '../../../../../redux/actions/inventoryActions';
import {
    getFullSIMList
} from '../../../../../redux/actions/inventoryActions';
// import SimUsage from './SimUsage';
import IdentifierUsage from './IdentifierUsage';
import { sortCaret } from "../../../../../shared/components/Table/tableElements";
import Spinner from 'react-bootstrap/Spinner';
import { LIGHT_GREY_COLOR } from "../../../../../constants/dataConstants";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

class DashBoardActiveSimTable extends Component {
    constructor(props) {
        super(props);

        this.userObj = getCurrentUser()
        this.state = {
            test: false,
            userObj: getCurrentUser(),
            numOfDevice: 0,
            csvFileName: null,

            filterClientName: "",
            filterPlanName: "",
            filterStatus: 1,
            filterDeviceName: "",
            filterIdentifierValue: "",
            filterPlanCode: "",
            filterDataUsage: 2,
            statusFilterType: [
                { value: 0, label: 'Inactive' },
                { value: 1, label: 'Active' },
                { value: 2, label: 'Suspend' }
            ],
            isShowFilter: false,
            sortName: undefined,
            sortOrder: undefined,
            csvData: { ...this.props.dashboardIdentifierUsageData },
            donwloading: false,
            refreshTime: new Date().getTime()

        }
        this.expandComponent = this.expandComponent.bind(this);
        this.handleExpand = this.handleExpand.bind(this);
        this.expandedRows = {};
        this.clickedRow = null;
        this.onSortChange = this.onSortChange.bind(this);


    }

    onSortChange(sortName, sortOrder) {
        this.setState({
            sortName,
            sortOrder
        });
    }
    expandComponent = (row) => {
        //#2
        if (this.expandedRows[row.identifierValue] != undefined && this.expandedRows[row.identifierValue] != null && this.clickedRow == row.identifierValue) {
            this.clickedRow = null;
            return (
                <IdentifierUsage
                    refreshTime={this.state.refreshTime}
                    type={this.props.type}
                    filterStartTime={this.props.filterStartTime}
                    filterEndTime={this.props.filterEndTime}
                    rowData={row}
                    LoadUsageDate={this.props.LoadUsageDate}
                    isAdmin={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false}
                />);

        }
        else if (this.expandedRows[row.identifierValue] != undefined && this.expandedRows[row.identifierValue] != null) {
            return (
                <IdentifierUsage
                    refreshTime={this.state.refreshTime}
                    type={this.props.type}
                    filterStartTime={this.props.filterStartTime}
                    filterEndTime={this.props.filterEndTime}
                    rowData={row}
                    LoadUsageDate={this.props.LoadUsageDate}
                    isAdmin={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false}
                />);
        }
    }
    componentWillReceiveProps(nextProps) {

        this.setState({
            filterIdentifierValue: nextProps.filterIdentifierValueSort,
            filterDeviceName: nextProps.filterDeviceNameSort,
            filterDataUsage: nextProps.filterDataUsageSort,
            filterStatus: nextProps.filterStatusSort,
            filterClientName: nextProps.filterClientNameSort,
        })
    }


    isExpandableRow(row) {
        if (row.itemName != null) return true;
        else return false;
    }

    handleExpand(rowKey, isExpand) {

        //#1
        this.expandedRows[rowKey] = isExpand;
        if (isExpand == true) {
            //expanding
            this.expandedRows[rowKey] = {
                rowKey: rowKey,
                refreshTime: new Date()
            };
            this.clickedRow = rowKey;
        }


    }



    csvStatusFormatter(cell, row) {
        if (cell == 1) {
            return `Active`
        }
        else if (cell == 0) {
            return `Inactive`
        }
        else if (cell == 2) {
            return `Suspend`
        }
        else {
            return `Error`
        }
    }
    checkVisibility() {
        const { pageContentList } = this.props
        let value;
        if (pageContentList.includes("Visibility *!check*")/* TEISA */) {
            value = false;
        }
        else {
            value = true;
        }
        return value;
    }

    csvFormatter = (cell, row) => {
        return cell + '\t';
    }

    csvUsageFormatter = (cell, row) => {
        return cell + null;
    }

    csvDataFormatter(cell, row) {
        if (cell > 0) {
            if (cell < 1048576 && cell > 1024) {
                return `${(cell / 1024).toFixed(2)} MB`
            }
            if (cell >= 1048576) {
                return `${(cell / 1048576).toFixed(2)} GB`
            }
            else {
                return `${(cell).toFixed(2)} KB`
            }
        }
        else {
            return `0 KB`
        }
    }

    csvDataFormatterT = (cell, row) => {
        return cell + ' Frame'
    }

    onClose = () => {
        this.setState({
            showModal: false,
            selectedSIM: null
        })
    }
    handleLoadFileClick = files => {
        if (!sessionStorage.getItem('companyCode') || !sessionStorage.getItem('currentUser')) {
            return;
        }
        if (true) {
            var formdata = new FormData();
            formdata.append("file", files[0]);
            formdata.append("userType", "pp");
            formdata.append("status", "A");
            //formdata.append("companyCode",this.state.company ? this.state.company : sessionStorage.getItem('companyCode') );
            formdata.append("companyCode", this.state.userObj.companyCode);

            formdata.append("department", "");
            formdata.append("businessUnit", "");
            formdata.append("creator", sessionStorage.getItem('currentUser'));
            formdata.append("createTime", new Date().toISOString().split('.')[0].replace('T', ' '));
            this.props.addMultiDevicesInventory(formdata);

        } else {
            alert('v no');
        }
        //getFullSIMList({ "fullInventory": true });
    }

    afterColumnFilter = (filterConds, result) => {
        if (result.length != this.state.numOfDevice) {
            this.setState({ numOfDevice: result.length });
        }
    }
    onSearch = (isClear) => {
        if (this.props.dashboardActivatedSimListLoading) {
            return;
        }
        if (isClear) {
            this.setState({
                filterClientName: "",
                filterStatus: 1,
                filterDeviceName: "",
                filterIdentifierValue: "",
                filterDataUsage: 2
            }, () => {
                this.props.LoadUsageDate(null, {
                    simListPage: 1,
                    simListPageSize: this.props.pageSize,
                    filterClientName: this.state.filterClientName,
                    filterStatus: this.state.filterStatus,
                    filterDeviceName: this.state.filterDeviceName,
                    filterIdentifierValue: this.state.filterIdentifierValue,
                    filterDataUsage: this.state.filterDataUsage,
                    simListLoading: true
                });
            })
        } else {
            this.props.LoadUsageDate(null, {
                simListPage: 1,
                simListPageSize: this.props.pageSize,
                filterClientName: this.state.filterClientName,
                filterStatus: this.state.filterStatus,
                filterDeviceName: this.state.filterDeviceName,
                filterIdentifierValue: this.state.filterIdentifierValue,
                filterDataUsage: this.state.filterDataUsage,
                simListLoading: true
            });
        }

    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            //resetpage

        });

    };

    renderSpinner = () => {
        //let loading = this.props.loading ? this.props.loading : false;
        let loading = true

        return (
            <Fragment>
                {loading &&
                    <div style={{
                        position: 'Absolute ',
                        height: '100%',
                        width: '100%',
                        top: '50px',
                        left: '0px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        opacity: '1',
                    }}
                    >
                        <div style={{
                            width: "200px",
                            height: "40px",
                            margin: "0 auto"
                        }}>
                            <Spinner
                                className="table-spinner"
                                as="span"
                                animation="border"
                                // size="sm"
                                // role="status"
                                // aria-hidden="true"
                                style={{ display: 'inline-block', margin: 'auto' }}
                            />
                        </div>
                    </div>
                }
            </Fragment>
        );
    }

    renderDownloadSpinner = () => {
        //let loading = this.props.loading ? this.props.loading : false;

        return (
            <Fragment>
                {this.state.donwloading &&
                    <div style={{
                        position: 'Absolute ',
                        height: '100%',
                        width: '100%',
                        top: '0px',
                        left: '0px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        opacity: '1',
                    }}
                    >
                        <div style={{
                            width: "200px",
                            height: "40px",
                            margin: "7px auto 0 auto"
                        }}>
                            <Spinner
                                className="table-spinner"
                                as="span"
                                animation="border"
                                // size="sm"
                                // role="status"
                                // aria-hidden="true"
                                style={{ display: 'inline-block', margin: 'auto' }}
                            />
                        </div>
                    </div>
                }
            </Fragment>
        );
    }

    toggleShowFilter() {
        this.setState({
            isShowFilter: !this.state.isShowFilter
        })
    }

    async onExportEmail() {

        let dataList = await this.props.exportSimListData()
        var _that = this
        const period = this.periodFormatter(this.props.filterStartTime, this.props.filterEndTime)
        if (dataList == null) {
            dataList = []
        }
        let newData = dataList.map(item => {
            item['currentPeriod'] = period
            if (this.props.type == "monthly") {
                item['usageUpdateDate'] = item['usageUpdateDate'].replace("-01", "\t")
            } else if (this.props.type == "weekly") {
                item['usageUpdateDate'] = "For the week of " + item['usageUpdateDate'];
            }
            return item
        })

        this.setState({
            donwloading: false,
        })

        {
            this.userObj.companyCode && this.userObj.companyCode !== "TEISA" && toast.success(
                `The CSV file has been sent to your email`
            );
            null
        }


        {
            this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                JSonToCSV.setDataConver({
                    data: newData,
                    fileName: this.state.csvFileName,
                    columns: {
                        title: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['Date', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'Identifier Value', 'Plan Name', 'Contract ID', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Usage'] : ['Time', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'Identifier Value', 'Plan Name', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Usage'],
                        key: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['usageUpdateDate', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'identifierValue', 'planName', 'planCode', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'] : ['currentPeriod', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'identifierValue', 'planName', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'],
                        formatter: function (n, v) {
                            if (n === 'identifierValue') return "\t" + v.toString();
                            if (n === 'periodDataUsage') return _that.csvDataFormatterT(v);
                            if (n === 'status') return _that.state.statusFilterType[v].label;

                        }
                    }
                });
        }

    }

    async onExportExcel() {
        if (this.props.dashboardActivatedSimListLoading) {
            return;
        }
        this.setState({
            donwloading: true,
        })
        let dataList = await this.props.exportSimListData()
        var _that = this
        const period = this.periodFormatter(this.props.filterStartTime, this.props.filterEndTime)
        if (dataList == null) {
            dataList = []
        }
        let newData = dataList.map(item => {
            item['currentPeriod'] = period
            if (this.props.type == "monthly") {
                item['usageUpdateDate'] = item['usageUpdateDate'].replace("-01", "\t")
            } else if (this.props.type == "weekly") {
                item['usageUpdateDate'] = "For the week of " + item['usageUpdateDate'];
            }
            return item
        })

        this.setState({
            donwloading: false,
        })

        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
        JSonToCSV.setDataConver({
            /*data: [
                {name: 'zhangsan', amont: '323433.56', proportion: 33.4},
                {name: 'lisi', amont: '545234.43', proportion: 55.45}
            ],*/
            data: newData,
            fileName: this.state.csvFileName,
            columns: {
                title: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['Date', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'IMEI', '', 'Identifier Value', 'Plan Name', 'Plan Code', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Data in MB'] : ['Time', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'IMEI', '', 'Identifier Value', 'Plan Name', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Data Usage'],
                key: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['usageUpdateDate', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'deviceImei', '', 'identifierValue', 'planName', 'planCode', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'] : ['currentPeriod', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'deviceImei', '', 'identifierValue', 'planName', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'],
                formatter: function (n, v) {
                    /*if(n === 'amont' && !isNaN(Number(v))) {
                        v = v + '';
                        v = v.split('.');
                        v[0] = v[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
                        return v.join('.');
                    }*/
                    //if(n === 'identifierValue') return v + ' ';
                    if (n === 'identifierValue') return "\t" + v.toString();
                    //if(n === '') return "\t"+ v.toString();
                    if (n === 'periodDataUsage') return _that.csvDataFormatter(v);
                    if (n === 'status') return _that.state.statusFilterType[v].label;
                }
            }
        })
        }

        {
            this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
            JSonToCSV.setDataConver({
                /*data: [
                    {name: 'zhangsan', amont: '323433.56', proportion: 33.4},
                    {name: 'lisi', amont: '545234.43', proportion: 55.45}
                ],*/
                data: newData,
                fileName: this.state.csvFileName,
                columns: {
                    title: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['Date', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'IMEI', '', 'Identifier Value', 'Plan Name', 'Plan Code', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Data in MB'] : ['Time', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'IMEI', '', 'Identifier Value', 'Plan Name', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Data Usage'],
                    key: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['usageUpdateDate', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'deviceImei', '', 'identifierValue', 'planName', 'planCode', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'] : ['currentPeriod', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'deviceImei', '', 'identifierValue', 'planName', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'],
                    formatter: function (n, v) {
                        /*if(n === 'amont' && !isNaN(Number(v))) {
                            v = v + '';
                            v = v.split('.');
                            v[0] = v[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
                            return v.join('.');
                        }*/
                        //if(n === 'identifierValue') return v + ' ';
                        if (n === 'identifierValue') return "\t" + v.toString();
                        //if(n === '') return "\t"+ v.toString();
                        if (n === 'periodDataUsage') return _that.csvDataFormatter(v);
                        if (n === 'status') return _that.state.statusFilterType[v].label;

                    }
                }
            });
        }

        {
            this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                JSonToCSV.setDataConver({
                    data: newData,
                    fileName: this.state.csvFileName,
                    columns: {
                        title: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['Date', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'Identifier Value', 'Plan Name', 'Contract ID', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Usage'] : ['Time', 'Client Name', 'Customer Name', 'Device Name', 'Device Type', 'Status', 'Identifier Value', 'Plan Name', 'Address 1', 'Address 2', 'City', 'Zip Code', 'State', 'Country', 'Usage'],
                        key: (this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? ['usageUpdateDate', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'identifierValue', 'planName', 'planCode', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'] : ['currentPeriod', 'resellerName', 'customerName', 'deviceName', 'deviceType', 'status', 'identifierValue', 'planName', 'addressLine1', 'addressLine2', 'city', 'zipcode', 'state', 'country', 'periodDataUsage'],
                        formatter: function (n, v) {
                            //if(n === 'identifierValue') return v + ' ';
                            if (n === 'identifierValue') return "\t" + v.toString();
                            //if(n === '') return "\t"+ v.toString();
                            if (n === 'periodDataUsage') return _that.csvDataFormatterT(v);
                            if (n === 'status') return _that.state.statusFilterType[v].label;

                        }
                    }
                });
        }

    }



    periodFormatter = (startAt, endAt) => {
        if (startAt && endAt)
            return (new Date(startAt)).toLocaleDateString() + ' - '
                + (new Date(endAt)).toLocaleDateString()

        if (startAt)
            return (new Date(startAt)).toLocaleDateString() + ' - '
                + (new Date()).toLocaleDateString()

        if (endAt)
            return ''
                + (new Date(endAt)).toLocaleDateString()

        return ''
            + (new Date()).toLocaleDateString();
    }

    /*renderCustomHeader() {
        return(
            <p onClick={() => console.log('clicked!')}>
                Custom header
                <div>
                    <input type="text"/>
                </div>
            </p>

        );
    }*/

    renderPaginationPanel = (props) => {
        let total = this.props.page * this.props.pageSize;
        if (total > this.props.dashboardActivatedSimTotalElement) {
            total = this.props.dashboardActivatedSimTotalElement
        }
        let beginning = (this.props.page - 1) * this.props.pageSize + 1

        return (
            <>
                {
                    this.props.dashboardActivatedSimListLoading ?
                        null
                        : <>
                            <Col md={6} xs={6} sm={6} lg={6}>
                                {props.components.sizePerPageDropdown}
                            </Col>
                            <Col md={6} xs={6} sm={6} lg={6} style={{ display: "block", fontSize: "14px" }}>

                                <span style={{ float: "right" }}>{props.components.pageList}</span>
                                <span style={{ float: "right", fontSize: "14px", verticalAlign: "text-top", marginRight: "10px", marginTop: "9px" }}> {beginning} - {total} of {this.props.dashboardActivatedSimTotalElement}</span>
                            </Col>

                        </>

                }
            </>
        );
    }

    render() {
        var _that = this
        let { pageContentList } = this.props
        const options = {
            sortName: this.state.sortName,
            sortOrder: this.state.sortOrder,
            onSortChange: this.onSortChange,
            onlyOneExpanding: true,
            onExpand: this.handleExpand,
            paginationPanel: this.renderPaginationPanel,

            /*exportCSVBtn: this.createCustomExportCSVButton,*/
            afterColumnFilter: this.afterColumnFilter,
            /*onSizePerPageList:(pageSize)=>{
                this.state.pageSize=pageSize;
            },*/
            onPageChange: (page, sizePerPage) => {

                this.props.LoadUsageDate(null, {
                    simListPage: page,
                    simListPageSize: sizePerPage,
                    filterClientName: this.state.filterClientName,
                    filterStatus: this.state.filterStatus,
                    filterDeviceName: this.state.filterDeviceName,
                    filterIdentifierValue: this.state.filterIdentifierValue,
                    filterDataUsage: this.props.filterDataUsage,
                    simListLoading: true
                });
            },
            page: this.props.page,
            sizePerPage: this.props.pageSize,
            sizePerPageList: [{
                text: 10, value: 10
            }, {
                text: 50, value: 50
            }, {
                text: 100, value: 100
            }, {
                text: 500, value: 500
            }]
        };
        const statusType = {
            0: 'Inactive',
            1: 'Active',
            2: 'Suspend'
        };


        const dataUsageFilterType = [
            { value: 1, label: 'Ascending' },
            { value: 2, label: 'Descending' }
        ]




        let textDisplay;
        let variableName;
        if (pageContentList.includes("Order Name")/* TEISA */
            && pageContentList.includes("Item Description")
            && pageContentList.includes("Order External Name")) {
            textDisplay = ['Order Name', 'itemDescription', 'Order External Name']

            /* Order external name  -- data from this column */
            /* verify that this does not conflict with HELIX */
            /* Helix shows their column and Teisa their column */
        } else {
            textDisplay = ['SIM Identifier Value', 'identifierValue', 'Identifier Value']
        }

        let resellerClientName = pageContentList.includes("Reseller Name")/* TEISA */ ? 'Reseller Name'
            : 'Client Name'
        let orderDeviceName = pageContentList.includes("Order")/* TEISA */ ? 'Order'
            : 'Device Name'

        //add key 'currentPeriod'(filter period) for each element


        // <<<<<<< HEAD
        //         const period = this.periodFormatter(this.props.filterStartTime, this.props.filterEndTime)
        //         let newData = this.props.dashboardActivatedSimList.map(item => {
        //             item['currentPeriod'] = period
        //             return item
        //         })
        // =======
        //         let newData = []

        //         if (this.props.dashboardActivatedSimList && this.props.dashboardActivatedSimList.length > 0) {
        //             newData = this.props.dashboardActivatedSimList.map(item => {
        //                 item['currentPeriod'] = period
        //                 return item
        //             })
        //         }

        //         const csvFileName=`${period}_Period_Report.csv`
        // >>>>>>> feature/teisaYearlyBilling

        let newData = []
        const period = this.periodFormatter(this.props.filterStartTime, this.props.filterEndTime)


        if (this.props.dashboardActivatedSimList && this.props.dashboardActivatedSimList.length > 0) {
            newData = this.props.dashboardActivatedSimList.map(item => {
                item['currentPeriod'] = period
                return item
            })
        }

        let labalStyle = { textAlign: 'center' };
        const csvFileName = `${period}_Period_Report.csv`
        this.state.csvFileName = csvFileName;
        let headerFontColor = this.state.userObj && this.state.userObj.detail ? this.state.userObj.detail.headerFontColor : "white";
        let appFontColor = this.state.userObj && this.state.userObj.detail ? this.state.userObj.detail.appFontColor : "white";

        return (

            <Card>
                {<Row>
                    {this.props.dashboardActivatedSimListLoading ? this.renderSpinner() : null}
                </Row>}
                {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                    <ReactFileReader handleFiles={this.handleLoadFileClick} fileTypes={'.csv'}>
                        {(hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN']))
                            && pageContentList.includes("Upload Input CSV")/* TEISA */ ?
                            <Button variant="primary" color="success" type="button" style={{ marginLeft: "19px" }} >Upload Input CSV</Button> : null}
                    </ReactFileReader>}


                <div className='card-pginside'>
                    {/*Number of Device : {this.state.numOfDevice}*/}
                    <Row style={{ justifyContent: "left", marginTop: "30px" }}>

                         <div onClick={() => {
                            this.onExportEmail()
                        }} className="btn btn-secondary"
                            style={{ marginLeft: "15px", marginRight: "15px", fontSize: "14px", display: "inline-block", borderRadius: "5px", textAlign: "center", cursor: "pointer"/*, display: "block"*/ }}>
                            {/*filename={`${this.props.rowData.identifierValue}_usage.csv`} data={this.state.usageCsvData} headers={this.state.usageCsvHeaders}*/}
                             <i className={"fa glyphicon glyphicon-export fa-download"}></i>
                            &ensp;Download CSV
                            {this.renderDownloadSpinner()} 


                        </div>
                        
                        
                        <Button color='custom' type="button" /*style={{ color: appFontColor }}*/ onClick={(event) => {
                            this.onSearch(false)
                        }} >
                            Search
                        </Button>
                        <Button color='custom' type="button" /*style={{ color: appFontColor }}*/ onClick={(event) => {
                            this.onSearch(true)
                        }}>
                            Reset
                        </Button>

                        {/*<Col md={3} >
                            <div  onClick={()=>{
                                this.toggleShowFilter()
                            }} style={{ width: "150px", height: "40px", fontSize: "14px", color: "black", display: "inline-block", borderRadius: "5px", backgroundColor: '#F5EBCF', padding: "10px", textAlign: "center",cursor:"pointer" }} >
                                filename={`${this.props.rowData.identifierValue}_usage.csv`} data={this.state.usageCsvData} headers={this.state.usageCsvHeaders}
                                Filter
                            </div>
                        </Col>*/}

                    </Row>


                    {/*<Row style={{justifyContent:"center",display:this.state.isShowFilter?'flex':'none'}}>
                        <Col md={2} style={labalStyle} >
                            Client Name
                        </Col>
                        <Col md={2} style={labalStyle} >
                            Device Name
                        </Col>
                        <Col md={2} style={labalStyle} >
                            Status
                        </Col>
                        <Col md={2} style={labalStyle} >
                            Identifier Value
                        </Col>
                        <Col md={2} style={labalStyle} >
                            Data Usage
                        </Col>
                    </Row>*/}
                    {/*<Row  style={{justifyContent:"start",display:this.state.isShowFilter?'flex':'none'}}>
                    </Row>*/}
                    <BootstrapTable
                        ref={"dashboardActivatedSimTable"}
                        data={this.props.dashboardActivatedSimList}
                        //data={newData}
                        options={options}
                        remote={true}
                        fetchInfo={{ dataTotalSize: this.props.dashboardActivatedSimTotalElement }}
                        tableHeaderClass='header_white'
                        containerStyle={{ fontSize: "1vw" }}
                        bordered={false}
                        height='100%'
                        bodyStyle={{ overflow: 'overlay' }}
                        pagination={true}
                        // exportCSV
                        csvFileName={csvFileName}
                        expandComponent={this.expandComponent}
                        expandableRow={this.isExpandableRow}
                        expandColumnOptions={{
                            expandColumnVisible: true,
                            columnWidth: 60,
                        }}
                    >
                        <TableHeaderColumn
                            ref='currentPeriod'
                            dataField='currentPeriod'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='Time'
                        >
                            Time
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            ref='resellerName'
                            dataField='resellerName'
                            dataAlign='center'
                            hidden={(this.state.userObj.companyCode && this.state.userObj.companyCode == "TEISA")}
                            export={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false}
                            csvHeader='Client Name'
                        >
                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Client Name
                            </p>
                            <Input
                                name='filterClientName'
                                placeholder="Search"
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.filterClientName}
                            />
                        </TableHeaderColumn>

                        {/*<TableHeaderColumn ref='resellerName' dataField='resellerName' dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} export={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false} csvHeader='Client Name'>Client Name</TableHeaderColumn>*/}
                        <TableHeaderColumn
                            ref='deviceName'
                            dataField='deviceName'
                            dataAlign='center'
                            csvHeader='Device Name'
                        >
                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Device Name
                            </p>
                            <Input
                                name='filterDeviceName'
                                placeholder='Search'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.filterDeviceName}
                            />
                        </TableHeaderColumn>
                        {/*<TableHeaderColumn ref='deviceName' dataField='deviceName' dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Device Name'>Device Name</TableHeaderColumn>*/}

                        <TableHeaderColumn
                            ref='planName'
                            dataField='planName'
                            dataAlign='center'
                            // export={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false}
                            csvHeader='Plan Name'
                            hidden={!(this.state.userObj.companyCode && this.state.userObj.companyCode == "TEISA")}
                        >
                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Plan Name
                            </p>
                            <Input
                                name='filterPlanName'
                                placeholder="Search"
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.filterPlanName}
                            />
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            ref='planCode'
                            dataField='planCode'
                            dataAlign='center'
                            // export={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false}
                            csvHeader='Contract ID'
                            hidden={!(this.state.userObj.companyCode && this.state.userObj.companyCode == "TEISA")}
                        >
                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Contract ID
                            </p>
                            <Input
                                name='filterPlanCode'
                                placeholder="Search"
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.filterPlanCode}
                            />
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            ref='nameType'
                            dataField='deviceType'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='Device Type'
                        >
                            Device Type
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='customerName'
                            dataField='customerName'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='Device Group'
                        >
                            Device Group
                        </TableHeaderColumn>
                        {/*<TableHeaderColumn ref='status' dataField='status' dataAlign='center' export={true} csvHeader='Status' formatExtraData={statusType} filter={{ type: 'SelectFilter', options: statusType, defaultValue: 1 }} dataFormat={this.csvStatusFormatter.bind(this)} csvFormat={this.csvStatusFormatter} >Status</TableHeaderColumn>*/}
                        <TableHeaderColumn
                            ref='status'
                            dataField='status'
                            dataAlign='center'
                            export={true}
                            csvHeader='Status'
                            formatExtraData={statusType}
                            filter={{ options: statusType, defaultVaule: 1 }}
                            dataFormat={this.csvStatusFormatter.bind(this)}
                            csvFormat={this.csvStatusFormatter} >
                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Status
                            </p>
                            <Input
                                type='select'
                                name='filterStatus'
                                //placeholder='Postal Code'
                                onChange={this.onInputChange}
                                className="form-control"
                                // value={this.state.postalCode}
                                value={this.state.filterStatus}
                            >
                                <option
                                    value={''}
                                >
                                    {'No selection'}
                                </option>
                                {this.state.statusFilterType.map((option, index) => (
                                    <option
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </Input>
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='deviceImei'
                            dataField='deviceImei'
                            hidden={true}
                            export={!(this.state.userObj.companyCode && this.state.userObj.companyCode == "TEISA")}
                            dataAlign='center'
                            csvHeader='IMEI'
                        >
                            IMEI
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref=''
                            dataField=''
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            filter={{ type: 'TextFilter', placeholder: ' ' }}
                            csvFormat={this.csvFormatter}
                            csvHeader='' />
                        {/* <TableHeaderColumn ref={textDisplay[1]} isKey={true} dataField={textDisplay[1]} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Enter Value' }} csvHeader={textDisplay[2]}>{textDisplay[0]}</TableHeaderColumn>*/}
                        <TableHeaderColumn
                            ref='identifierValue'
                            isKey={true}
                            dataField='identifierValue'
                            dataAlign='center'
                            csvFormat={this.csvFormatter}
                            csvHeader='Identifier Value'
                        >
                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Identifier Value
                            </p>
                            <Input
                                name='filterIdentifierValue'
                                placeholder='Search'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.filterIdentifierValue}
                            />
                        </TableHeaderColumn>
                        {/*<TableHeaderColumn ref='identifierValue' isKey={true} dataField='identifierValue' dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} csvFormat={this.csvFormatter} csvHeader='Identifier Value'>Identifier Value</TableHeaderColumn>*/}
                        <TableHeaderColumn
                            ref='planName'
                            dataField='planName'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            filter={{ type: 'TextFilter', placeholder: ' ' }}
                            csvHeader='Plan Name'
                        >
                            Plan Name
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='planCode'
                            dataField='planCode'
                            dataAlign='center'
                            hidden={true}
                            export={(this.state.userObj && hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN'])) ? true : false}
                            filter={{ type: 'TextFilter', placeholder: 'Enter Value' }}
                            csvHeader='Plan Code'
                        >
                            Plan Code
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='addressLine1'
                            dataField='addressLine1'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='Address 1'
                        >
                            Address Line 1
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='addressLine2'
                            dataField='addressLine2'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='Address 2'
                        >
                            Address Line 2
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='city'
                            dataField='city'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='City'
                        >
                            City
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='zipcode'
                            dataField='zipcode'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='Zip Code'
                        >
                            Zipcode
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='state'
                            dataField='state'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='State'
                        >
                            State
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            ref='country'
                            dataField='country'
                            dataAlign='center'
                            hidden={true}
                            export={true}
                            csvHeader='Country'
                        >
                            Country
                        </TableHeaderColumn>
                        {/*<TableHeaderColumn ref='periodDataUsage' dataSort caretRender={sortCaret} dataField='periodDataUsage' dataAlign='center' export={true} filterFormatted filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Data Usage' dataFormat={this.csvDataFormatter.bind(this)} csvFormat={this.csvDataFormatter}>Data Usage</TableHeaderColumn>*/}

                        <TableHeaderColumn
                            ref='periodDataUsage'
                            dataField='periodDataUsage'
                            dataAlign='center'
                            export={true}
                            hidden={(this.state.userObj.companyCode && this.state.userObj.companyCode == "TEISA")}
                            filterFormatted
                            csvHeader='Data Usage'
                            dataFormat={this.csvDataFormatter.bind(this)} csvFormat={this.csvDataFormatter}
                        >

                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Data Usage
                            </p>

                            <Input
                                type='select'
                                name='filterDataUsage'
                                //placeholder='Data Usage'
                                onChange={this.onInputChange}
                                className="form-control"
                                // value={this.state.postalCode}
                                value={this.state.filterDataUsage}
                            >
                                <option
                                    value={''}
                                >
                                    {'No selection'}
                                </option>
                                {dataUsageFilterType.map((option, index) => (
                                    <option
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </Input>

                        </TableHeaderColumn>

                        <TableHeaderColumn
                            ref='periodDataUsage'
                            dataField='periodDataUsage'
                            dataAlign='center'
                            export={this.state.userObj.companyCode && this.state.userObj.companyCode == "TEISA"}
                            filterFormatted
                            hidden={!(this.state.userObj.companyCode && this.state.userObj.companyCode == "TEISA")}
                            csvHeader='Usage'
                            // dataFormat={this.csvDataFormatter.bind(this)}
                            csvFormat={this.csvUsageFormatter}
                        >

                            <p
                                style={{ color: headerFontColor, fontSize: "15px" }}
                            >
                                Usage
                            </p>

                            <Input
                                type='select'
                                name='filterDataUsage'
                                //placeholder='Data Usage'
                                onChange={this.onInputChange}
                                className="form-control"
                                // value={this.state.postalCode}
                                value={this.state.filterDataUsage}
                            >
                                <option
                                    value={''}
                                >
                                    {'No selection'}
                                </option>
                                {dataUsageFilterType.map((option, index) => (
                                    <option
                                        value={option.value}
                                    >
                                        {option.label}
                                    </option>
                                ))}
                            </Input>

                        </TableHeaderColumn>

                    </BootstrapTable >
                </div >
            </Card >
        )
    }

}
const mapStateToProps = (state) => {
    // let items = state.fetchInventoryItemsReducer
    // if(undefined === items){
    //     items = []
    // }
    // return{items}

    const { pageContentList, dashboardActivatedSimTotalElement, dashboardIdentifierUsageData } = state.user

    let {
        activeInventoryList
    } = state.inventory;
    let props = {
        pageContentList,
        dashboardActivatedSimTotalElement,
        activeInventoryList,
        dashboardIdentifierUsageData
    }
    return props;
}

var JSonToCSV = {
    setDataConver: function (obj) {
        var bw = this.browser();
        if (bw['ie'] < 9) return;
        var data = obj['data'],
            ShowLabel = typeof obj['showLabel'] === 'undefined' ? true : obj['showLabel'],
            fileName = (obj['fileName'] || 'UserExport') + '.csv',
            columns = obj['columns'] || {
                title: [],
                key: [],
                formatter: undefined
            };
        var ShowLabel = typeof ShowLabel === 'undefined' ? true : ShowLabel;
        var row = "", CSV = '', key;

        if (ShowLabel) {

            if (columns.title.length) {
                columns.title.map(function (n) {
                    row += n + ',';
                });
            } else {

                for (key in data[0]) row += key + ',';
            }
            row = row.slice(0, -1);
            CSV += row + '\r\n';
        }

        data.map(function (n) {
            row = '';
            if (columns.key.length) {
                columns.key.map(function (m) {
                    row += '"' + (typeof columns.formatter === 'function' ? columns.formatter(m, n[m]) || n[m] : n[m]) + '",';
                });
            } else {
                for (key in n) {
                    row += '"' + (typeof columns.formatter === 'function' ? columns.formatter(key, n[key]) || n[key] : n[key]) + '",';
                }
            }
            row.slice(0, row.length - 1);
            CSV += row + '\r\n';
        });
        if (!CSV) return;
        this.SaveAs(fileName, CSV);
    },
    SaveAs: function (fileName, csvData) {
        var bw = this.browser();
        if (!bw['edge'] || !bw['ie']) {
            var alink = document.createElement("a");
            alink.id = "linkDwnldLink";
            alink.href = this.getDownloadUrl(csvData);
            document.body.appendChild(alink);
            var linkDom = document.getElementById('linkDwnldLink');
            linkDom.setAttribute('download', fileName);
            linkDom.click();
            document.body.removeChild(linkDom);
        }
        else if (bw['ie'] >= 10 || bw['edge'] == 'edge') {
            var _utf = "\uFEFF";
            var _csvData = new Blob([_utf + csvData], {
                type: 'text/csv'
            });
            navigator.msSaveBlob(_csvData, fileName);
        }
        else {
            var oWin = window.top.open("about:blank", "_blank");
            oWin.document.write('sep=,\r\n' + csvData);
            oWin.document.close();
            oWin.document.execCommand('SaveAs', true, fileName);
            oWin.close();
        }
    },
    getDownloadUrl: function (csvData) {
        var _utf = "\uFEFF";
        if (window.Blob && window.URL && window.URL.createObjectURL) {
            var csvData = new Blob([_utf + csvData], {
                type: 'text/csv'
            });
            return URL.createObjectURL(csvData);
        }
        // return 'data:attachment/csv;charset=utf-8,' + _utf + encodeURIComponent(csvData);
    },
    browser: function () {
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.indexOf('edge') !== - 1 ? Sys.edge = 'edge' : ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
            (s = ua.match(/msie ([\d.]+)/)) ? Sys.ie = s[1] :
                (s = ua.match(/firefox\/([\d.]+)/)) ? Sys.firefox = s[1] :
                    (s = ua.match(/chrome\/([\d.]+)/)) ? Sys.chrome = s[1] :
                        (s = ua.match(/opera.([\d.]+)/)) ? Sys.opera = s[1] :
                            (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;
        return Sys;
    }
};


export const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getSimUsage, addMultiDevicesInventory, getFullSIMList, getPageContent, getActivatedSimDashboard
    }, dispatch);

}
// export default connect(mapStateToProps, {actFetchInventoryItems, })(DashBoardSearchFunctionTable);
export default connect(mapStateToProps, mapDispatchToProps)(DashBoardActiveSimTable);
