/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import AssetList from '../../Asset/components/AssetList';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';

class CompanyActiveSIMList extends Component {
    iccidFormatter = (cell, row) => {
        if (cell) {
            return `${cell}`;
        }
    }
    planFormatter = (cell, row) => {
        if (cell != null) {
            for (let plan of this.props.resellerServicePlanItems) {
                if (plan.itemId == cell) {
                    return `${plan.itemName}`;
                }
            }
        }
        else {
            return <span>no plan</span>;
        }
    }
    render() {
        return (
            <div>
                {/* <div style={{ padding: "30px 30px 0px 30px" }}> */}
                {/* </div> */}
                <Card>
                    <CardBody>
                        <h3 style={{ marginBottom: '10px' }} >Devices</h3>

                        <BootstrapTable
                            data={this.props.activeSIMList}
                            tableHeaderClass='header_white'

                            bordered={false}
                            height='100%'
                            bodyStyle={{ overflow: 'overlay' }}
                            containerStyle={{ fontSize: "1vw" }}
                        >
                            <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: 'Description' }}>SIM Description</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' isKey={true} dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: 'Value' }}>SIM Identifier Value </TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='iccid' dataFormat={this.iccidFormatter.bind(this)} dataAlign='center' dataSort filter={{ type: 'TextFilter', placeholder: 'ICCID' }} >ICCID</TableHeaderColumn>
                            <TableHeaderColumn row='0' rowSpan='1' dataField='planId' dataFormat={this.planFormatter.bind(this)} dataAlign='center' dataSort width="12%">Data Plan</TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='Operations'
                                dataAlign="center"
                                //dataFormat={this.cellButton.bind(this)}
                                width="15%"
                            >
                                Operations
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
export default CompanyActiveSIMList;