/* eslint-disable */
import { result } from "lodash";
import React, { Component, Fragment } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

class GroupAttributeBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  // createColumn = (selectedGroup) => {
  //   if (selectedGroup) {
  //     return `${Object.keys(selectedGroup.groupvalue)} ==== ${Object.values(
  //       selectedGroup.groupvalue
  //     )}`;
  //   }
  // };
  // attrData = (selectedGroup) => {
  //   if (selectedGroup) {
  //     let i = 0;
  //     while (i != selectedGroup.length) {
  //       // console.log("===group[i].groupid", selectedGroup[i]);
  //       // console.log(
  //       //   "===this.state.selectedGroupId",
  //       //   this.state.selectedGroupId
  //       // );
  //       if (this.state.selectedGroupId.includes(selectedGroup[i].groupid)) {
  //         return (
  //           <Card>
  //             <CardBody>
  //               <Col>
  //                 <Row>
  //                   <h3>{selectedGroup[i].groupname}</h3>
  //                 </Row>
  //                 <Row>
  //                   {`${Object.keys(
  //                     selectedGroup[i].groupvalue
  //                   )} ==== ${Object.values(selectedGroup[i].groupvalue)}`}
  //                 </Row>
  //               </Col>
  //             </CardBody>
  //           </Card>
  //         );
  //       }

  //       i++;
  //     }
  //   }
  //   return result;
  // };

  attrGroupNew = (group) => {
    var keyList = [];
    if (group.groupvalue !== null && group.groupvalue !== {}) {
      keyList = Object.keys(group.groupvalue);
      if (keyList.length != 0) {
        return (
          <Col>
            <Row>
              <h3>{group.groupname}</h3>
            </Row>
            {keyList.map((key) => (
              <Row style={{ marginRight: "50px" }}>
                {key} : {group.groupvalue[key]}
              </Row>
            ))}
          </Col>
        );
      } else {
        return (
          <Col>
            <Row>
              <h3>{group.groupname}</h3>
            </Row>
            <Row style={{ marginRight: "50px" }}>
              <h4>No Data available.</h4>
            </Row>
          </Col>
        );
      }
    }
  };
  render() {
    if (this.props.selectedGroups && this.props.defaultGroups) {
      this.state = this.props.data;
      return (
        <Row>
          {this.props.selectedGroups.length != 0 && this.props.defaultGroups
            ? this.props.selectedGroups.map((group) => this.attrGroupNew(group))
            : this.props.defaultGroups.map((group) => this.attrGroupNew(group))}
        </Row>
      );
    } else
      return (
        <Row>
          <h3>No data available.</h3>
        </Row>
      );
  }
}

export default GroupAttributeBoard;
