/* eslint-disable */
import React, { Component, Fragment } from "react";
import {
  Container,
  Form,
  FormGroup,
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Table,
  Input,
  Label,
} from "reactstrap";
import MainBoard from "./MainBoard";
import AutorenewIcon from "mdi-react/AutorenewIcon";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import WeatherBarChart from "./WeatherBarChart";
import WeatherLineChart from "./WeatherLineChart";
import WeatherMixChart from "./WeatherMixChart";
import SingleVarLineChartWidget from "./SingleVarLineChartWidget";
import SingleVarValueWidget from "./SingleVarValueWidget";
import SingleAlarmBarChartWidget from "./SingleAlarmBarChartWidget";
import StatusTable from "./StatusTable";
import { DateRangePicker } from "react-dates";
// import {ChevronLeftIcon} from 'mdi-react';
import { BackButton } from "../../../shared/components/Button/IconButton";
import {
  TIME_OPTION,
  TIME_RANGE_OPTION,
  ATTRIBUTE_ORDER,
  ALARM_META_ATTRI_OBJ,
  TIME_ZONE,
} from "../../../constants/dataConstants";
import { groupBy } from "../../../factories/utils";
import moment from "moment";

const customStyles = {
  control: (base) => ({
    ...base,
    // height: 35,
    // minHeight: 35
    height: 35,
    minHeight: 35,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: "0",
  }),
  //   container: (base) => ({
  //     ...base,
  //     display:'inline-block',
  //     width: 500,
  //     height: 20,
  // }),
  // valueContainer: (base) => ({
  //     ...base,
  //     minHeight: 20,
  //     height: 10,
  // })
};

class HistoryBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDateMoment: null,
      endDateMoment: null,
      selectedAsset: null,
      assets: [
        // {assetid: 1, assetname: 'sensor 1'},
        // {assetid: 2, assetname: 'sensor 2'},
        // {assetid: 3, assetname: 'sensor 3'},
        // {assetid: 4, assetname: 'sensor 4'},
      ],
      startTimeOptions: TIME_OPTION.filter((item, index) => index !== 24),
      endTimeOptions: TIME_OPTION.filter((item, index) => index !== 0),
      timeRangeOptions: TIME_RANGE_OPTION,
      activeTab: "1",
      attributea: "Wind_ave10",
      attributeb: "Wind_max10",
      activeChartTab: "3",
    };

  }

  componentWillReceiveProps(nextProps, nextContext) {
    //
    //
    //
    // let attrubuteChartData = setAttributeChartData(nextProps.historyData);
    //
    // console.log("qqqqqqqqqqqqqqqqqq",nextContext)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log("cccccccccccccc",snapshot)
  }

  // ---------------------------------------------------------------------------------------------------------------

  // onRefreshClick = () => {

  // }
  tabToggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  chartTabToggle = (tab) => {
    this.setState((prevState) => {
      if (prevState.activeChartTab !== tab) {
        return {
          activeChartTab: tab,
        };
      } else {
        return null;
      }
    });
  };

  handleValueClick = (data) => {
    const val = data.attributename;
    if (this.state.attributea && val == this.state.attributea) {
      this.setState((prevState) => ({
        attributea: prevState.attributeb ? prevState.attributeb : null,
        attributeb: null,
        // attributea: null,
      }));
    } else if (this.state.attributeb && val == this.state.attributeb) {
      this.setState({
        attributeb: null,
      });
    } else if (!this.state.attributea) {
      this.setState({
        attributea: val,
      });
    } else if (!this.state.attributeb) {
      this.setState({
        attributeb: val,
      });
    } else if (val != this.state.attributea && val != this.state.attributeb) {
      // else{
      this.setState((prevState) => ({
        attributea: prevState.attributeb,
        attributeb: val,
      }));
    }
    // this.setState({
    //     attributea: val,
    // });
  };

  handleValueSelectOne = (data) => {
    let val;
    if (data) {
      val = data.attributename;
      this.setState({
        attributea: val,
      });
    } else {
      this.setState({
        attributea: "Select A Statistic",
      })
    }
  };

  handleValueSelectTwo = (data) => {
    let val;
    if (data) {
      val = data.attributename;
      this.setState({
        attributeb: val,
      });
    } else {
      this.setState({
        attributeb: "Select A Statistic",
      })
    }
  };

  handleAssetChange = (value) => {
    //
    // this.setState({
    //     selectedAsset: value,
    // });

    this.props.handleFilterChange({
      currentAsset: value,
      // startTime: null,
      // endTime: null,
    });
  };

  handleDateRangeChange = (value, action) => {
    this.setState({
      startTimeOptions: TIME_OPTION.filter((item, index) => index !== 24),
      endTimeOptions: TIME_OPTION.filter((item, index) => index !== 0),
    });
    // let newStartTime = moment(value.startDate).utcOffset('-1200').toLocaleString('en-US');
    this.props.handleFilterChange({
        // startDateMoment: value.startDate,
        startDateMoment: value.startDate,
        endDateMoment: value.endDate,
        startTime: TIME_OPTION[0],
        endTime: TIME_OPTION[24],
        // startTime: null,
        // endTime: null,
    });
    
  };

  handleStartTimeChange = (value) => {
    if (!value) {
      this.setState({
        endTimeOptions: TIME_OPTION,
      });
    } else {
      let selectedIndex = value.index;
      this.setState({
        endTimeOptions: TIME_OPTION.filter(
          (item, index) => index > selectedIndex
        ),
      });
    }
    this.props.handleFilterChange({
      startTime: value,
      endTime: null,
    });
  };

  handleEndTimeChange = (value) => {
    this.props.handleFilterChange({
      endTime: value,
    });
  };

  backOnClick = (e) => {
    this.props.toggle();
  };

  renderFilter = () => {
    return (
      <div style={{ width: "100%", margin: "20px" }}>
        {/* <Form md={12}> */}
        <Form md={12}>
          <Row form>
            <Col md={2}>
              <Button
                variant="primary"
                color="custom"
                onClick={this.backOnClick}
              //color='black'
              >
                {" "}
                {"< Back"}{" "}
              </Button>
            </Col>
            <Col md={6}></Col>
            <Col md={3}>
              {/* <FormGroup row>*/}
              {/* <Col md={12}> */}
              {/* <Label for="deviceType" style={{display: 'inline', width: "100%"}} ><h4><b>Asset</b></h4></Label> */}
              <Select
                isClearable={false}
                isSearchable
                // options = {this.state.assets} //assetList
                options={this.props.assetList}
                getOptionLabel={({ assetname }) => assetname}
                getOptionValue={({ assetid }) => assetid}
                placeholder={"Select A Station ..."}
                value={this.props.currentAsset}
                // defaultValue={}
                onChange={this.handleAssetChange}
                styles={{ minWidth: "500px" }}
              />
              <SingleVarValurList />
            </Col>
          </Row>

          {/* <Col md={3}>
                            <DateRangePicker
                                    isOutsideRange={() => false}
                                    startDate={this.props.startDateMoment} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.props.endDateMoment} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    minimumNights={0}
                                    onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                    showClearDates = {true}
                                    small = {true}
                                    block = {true}
                                    // disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
                            />
                        </Col> */}
          {/* <Col md={3}>
                            <Row  style={{padding: '0px 10px'}}>
                                <Col md={6} style={{padding: '0px 5px'}}>
                                    <Select
                                        isClearable
                                        isDisabled = {this.props.isTimeDisabled}
                                        options = {this.state.startTimeOptions}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                        placeholder={'Start...'}
                                        value = {this.props.startTime}
                                        onChange = {this.handleStartTimeChange}
                                        styles={customStyles}
                                    />
                                </Col>
                                <Col md={6} style={{padding: '0px 5px'}}>
                                    <Select
                                        isClearable
                                        isDisabled = {this.props.isTimeDisabled}
                                        options = {this.state.endTimeOptions}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ value }) => value}
                                        placeholder={'End...'}
                                        value = {this.props.endTime}
                                        onChange = {this.handleEndTimeChange}
                                        styles={customStyles}
                                    />
                                </Col>
                            </Row>
                        </Col> */}
          {/* <Col md={2}>
                            <Select
                                isClearable
                                options = {this.state.timeRangeOptions}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ value }) => value}
                                // placeholder={'End...'}
                                onChange = {this.handleTimeRangeChange}
                            />
                        </Col> */}
        </Form>
      </div>
    );
  };

  renderTimeFilter = () => {
    return (
      <div style={{ width: "65%", margin: "2px 0% 0" /* , margin: "20px"  */ }}>
        <Form md={12}>
          <Row form>
            {/* <Col md={5}></Col> */}
            <Col md={3}>
              <DateRangePicker
                isOutsideRange={day => (moment().diff(day) < 0)}
                startDate={this.props.startDateMoment} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.props.endDateMoment} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                minimumNights={0}
                onDatesChange={this.handleDateRangeChange} //{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                } // PropTypes.func.isRequired,
                showClearDates={false}
                small={true}
                block={true}
              // disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
              />
            </Col>
            <Col md={3}>
              <Row style={{ width: '125%', padding: "0px 10px" }}>
                <Col md={6} style={{ padding: "0px 5px" }}>
                  <Select
                    isClearable
                    isDisabled={this.props.isTimeDisabled}
                    options={this.state.startTimeOptions}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    placeholder={"Start..."}
                    value={this.props.startTime}
                    onChange={this.handleStartTimeChange}
                    styles={customStyles}
                  />
                </Col>
                <Col md={6} style={{ padding: "0px 5px" }}>
                  <Select
                    isClearable
                    isDisabled={this.props.isTimeDisabled}
                    options={this.state.endTimeOptions}
                    getOptionLabel={({ label }) => label}
                    getOptionValue={({ value }) => value}
                    placeholder={"End..."}
                    value={this.props.endTime}
                    onChange={this.handleEndTimeChange}
                    styles={customStyles}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  renderSingalValue = (currentAttributeData) => {
    // console.log("===currentAttributeData",currentAttributeData)

    return (
      <Row
        style={{
          width: "100%",
          padding: "0px 15px 0px 15px",
          justifyContent: "center",
        }}
      >
        {currentAttributeData &&
          currentAttributeData.map((item) => {
            let isBlink = false;
            let updatedValue = null;
            // if (this.props.currentAsset && this.props.alarmObj && this.props.alarmObj.msgList) {
            //     for (let alarm of this.props.alarmObj.msgList) {
            //         if (this.props.currentAsset.assetname === alarm.assetname && alarm.attributeList && alarm.attributeList.includes(item.attributename) && this.props.alarmObj.status == true){
            //             isBlink = true;
            //             break;
            //         }
            //     }
            // }

            if (this.props.currentAsset && this.props.alarmSet) {
              let assetid = this.props.currentAsset.assetid;

              if (
                this.props.alarmSet[assetid] &&
                this.props.alarmSet[assetid][item.attributename]
              ) {
                //
                isBlink = this.props.alarmSet[assetid][item.attributename]
                  .status;
                // let itemTime = new Date(item.timestamp);
                // let alarmTime = new Date(this.props.alarmSet[assetid][item.attributename].timestamp);
                //
                //
                // if (alarmTime > itemTime){
                //     updatedValue = this.props.alarmSet[assetid][item.attributename].value;
                //
                // }
              }
            }
            // -------------------------------------
            if (this.props.currentAsset && this.props.alarmSetObj) {
              let assetid = this.props.currentAsset.assetid;
              this.props.alarmSetObj[assetid] &&
                Object.entries(this.props.alarmSetObj[assetid]).forEach(
                  ([key, value], index) => {
                    if (value[item.attributename]) {
                      isBlink = isBlink || value[item.attributename].status;
                    }
                  }
                );
            }
            // --------------------------------------
            return (
              <Col md="auto" sm={3} xs={6} style={{ padding: "0px 5px" }}>
                <SingleVarValueWidget
                  assetData={item}
                  attributea={this.state.attributea}
                  attributeb={this.state.attributeb}
                  handleValueClick={this.handleValueClick}
                  isBlink={isBlink}
                  updatedValue={updatedValue}
                />
              </Col>
            );
          })}
      </Row>
    );
  };
  
  disableOption = (dataArray) => {
    const options = dataArray.map(obj => {
      if(obj.attributename == this.state.attributea || obj.attributename == this.state.attributeb || obj.value == null) {
        return {...obj, isdisabled: true};
      }

      return obj;
    });

    return options;
  }

  

  renderSingleValueListOne = (currentAttributeData) => {
    let data = currentAttributeData;
    const dataArray = [];
    const placeHolder = () => {
      return this.state.attributea == "Wind_ave10" ? "Wind Ave" : "-Select-"
    }

    for (let i = 0; i < data.length; i++) {
      dataArray.push({ value: data[i].value, label: data[i].displayname, attributename: data[i].attributename, color: data[i].backgroundcolor ,isdisabled: false });
    }
    
    const options = this.disableOption(dataArray);

    return (
      <Select
        styles={customStyles}
        isClearable={true}
        options={options}
        isOptionDisabled={(options) => options.isdisabled}
        onChange={this.handleValueSelectOne}
        placeholder={placeHolder()}
        autoFocus={true}
      />
    );
  };

  renderSingleValueListTwo = (currentAttributeData) => {
    let data = currentAttributeData;
    const dataArray = [];

    for (let i = 0; i < data.length; i++) {
      dataArray.push({ value: data[i].value, label: data[i].displayname, attributename: data[i].attributename, isdisabled: false });
    }
    
    const options = this.disableOption(dataArray);

    const placeHolder = () => {
      return this.state.attributeb == "Wind_max10" ? "Wind Max" : "-Select-"
    }
    return (
      <Select
        isClearable={true}
        options={options}
        isOptionDisabled={(options) => options.isdisabled}
        onChange={this.handleValueSelectTwo}
        placeholder={placeHolder()}
        autoFocus={true}
      />
    );
  };

  renderBackButton = () => {
    return (
      <Row>
        <Col>
          <Button onClick={() => this.backOnClick()}>{"< Back"}</Button>
        </Col>
      </Row>
    );
  };

  onRefresh = () => {
    this.props.updataHistoryData();
    this.props.updateAttributeHistoryData();
  };

  render() {
    const tabPaneStyle = { backgroundColor: "#F8F8F8", padding: "15px" };
    const setTabStyle = (isActive) => {
      return {
        backgroundColor: isActive ? "#F8F8F8" : "white",
      };
    };

    const setChartTabStyle = (isActive) => {
      return {
        backgroundColor: isActive ? "white" : "#F8F8F8",
      };
    };


    // let filtedHistoryData = filterHistoryData(this.props.historyData);

    let filteredHistoryData = this.props.allAttriList;
    // console.log("===filtedHistoryData", filtedHistoryData);

    // let attrubuteChartData = setAttributeChartData(filtedHistoryData);
    let attrubuteChartData = this.props.history24HObjData;
    if (attrubuteChartData) {
      attrubuteChartData = attrubuteChartData.filter(
        (item) => item.attributename != "Battery"
      );
    }
    let currentAttributeData = setCurrentAttribute(this.props.currentAssetData);
    if (this.props.currentAssetData) {
      if (this.props.currentAssetData.grouping != "wind") {
        currentAttributeData.push(ALARM_META_ATTRI_OBJ);
      }
    }

    // alphabetical Older
    if (this.props.assetList) {
      this.props.assetList.sort(function (a, b) {
        const nameA = a.assetname.toUpperCase();
        const nameB = b.assetname.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 1;
      });
    }
    // }
    // let attrubuteChartData = setAttributeChartData(this.props.historyData);
    return (
      <Fragment>
        <Col>
          <Col
            style={{
              maxWidth: "20%",
              minWidth: "20%",
              float: "right",
              maxHeight: "0px",
              marginRight: "-14px",
            }}
          >
            <Select
              isClearable={false}
              isSearchable
              // options = {this.state.assets} //assetList
              options={this.props.assetList}
              getOptionLabel={({ assetname }) => assetname}
              getOptionValue={({ assetid }) => assetid}
              placeholder={"Select A Station ..."}
              value={this.props.currentAsset}
              // defaultValue={}
              onChange={this.handleAssetChange}
            // style={{ minWidth: "500px" }}
            />
          </Col>
          {/* {this.renderFilter()} */}
          <Row
            style={{ margin: "0% 0% -2% 0%", marginRight: "2%", float: "left" }}
          >
            <Button
              variant="primary"
              color="custom"
              onClick={this.backOnClick}
              style={{ fontSize: "12px" }}
            //   size="sm"
            //color='black'
            >
              {" "}
              {"< Back"}{" "}
            </Button>
          </Row>
          <Nav style={{ marginLeft: "1px" }} tabs>
            <Row style={{ marginRight: "60%" }}>
              <NavItem style={{ cursor: "pointer" }}>
                <NavLink
                  className={classnames({
                    active: this.state.activeChartTab === "3",
                  })}
                  // style={setChartTabStyle(this.state.activeChartTab === '3')}
                  onClick={() => {
                    this.chartTabToggle("3");
                  }}
                >
                  24H Charts
                </NavLink>
              </NavItem>
              <NavItem style={{ cursor: "pointer" }}>
                <NavLink
                  className={classnames({
                    active: this.state.activeChartTab === "4",
                  })}
                  // style={setChartTabStyle(this.state.activeChartTab === '4')}
                  onClick={() => {
                    this.chartTabToggle("4");
                  }}
                >
                  Details
                </NavLink>
              </NavItem>
            </Row>
          </Nav>
        </Col>

        <TabContent activeTab={this.state.activeChartTab}>
          <TabPane tabId="3">
            <div>
              <Card>
                <CardBody>
                  <Row style={{ width: "100%", padding: "0px 15px 0px 15px" }}>
                    {attrubuteChartData &&
                      attrubuteChartData.map((item) => {
                        if (item.attributename != "CO2") {
                          // console.log("===attrubuteChartData", attrubuteChartData);
                          return (
                            <Col md={3} style={{ padding: "0px 5px" }}>
                              <SingleVarLineChartWidget assetData={item} />
                            </Col>
                          );
                        }
                      })}
                    {this.props.currentAssetData != null ? (
                      this.props.currentAssetData.grouping === "weather" ? (
                        <Col md={3} style={{ padding: "0px 5px" }}>
                          <SingleAlarmBarChartWidget
                            loading={this.props.alarm24HCountLoading}
                            countData={this.props.alarm24HCounts}
                            units={this.props.alarm24HCountUnit}
                            isSmall={true}
                          />
                        </Col>
                      ) : (
                        <span></span>
                      )
                    ) : (
                      <span></span>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </div>
          </TabPane>
          {/* <div>
                <Card>
                    <CardBody>
                        <Row style={{width: '100%',  padding: '0px 15px 0px 15px', justifyContent:'center'}}>
                                {currentAttributeData && currentAttributeData.map((item)=>{
                                     
                                    let isBlink = false;
                                    let updatedValue = null;
                                    // if (this.props.currentAsset && this.props.alarmObj && this.props.alarmObj.msgList) {
                                    //     for (let alarm of this.props.alarmObj.msgList) {
                                    //         if (this.props.currentAsset.assetname === alarm.assetname && alarm.attributeList && alarm.attributeList.includes(item.attributename) && this.props.alarmObj.status == true){
                                    //             isBlink = true;
                                    //             break;
                                    //         }
                                    //     }                                      
                                    // }

                                    if (this.props.currentAsset && this.props.alarmSet) {
                                         
                                        let assetid = this.props.currentAsset.assetid;
                                         
                                        if (this.props.alarmSet[assetid] && this.props.alarmSet[assetid][item.attributename]){
                                            //  
                                            isBlink = this.props.alarmSet[assetid][item.attributename].status;
                                            // let itemTime = new Date(item.timestamp);
                                            // let alarmTime = new Date(this.props.alarmSet[assetid][item.attributename].timestamp);
                                            //  
                                            //  
                                            // if (alarmTime > itemTime){
                                            //     updatedValue = this.props.alarmSet[assetid][item.attributename].value;
                                            //      
                                            // }
                                        } 
                                    }
                                    // -------------------------------------
                                    if (this.props.currentAsset && this.props.alarmSetObj) {
                                        let assetid = this.props.currentAsset.assetid;
                                        this.props.alarmSetObj[assetid] && Object.entries(this.props.alarmSetObj[assetid]).forEach(([key, value], index) => {
                                            if (value[item.attributename]) {
                                                isBlink = isBlink || value[item.attributename].status;
                                            }
                                        });
                                    }
                                    // --------------------------------------
                                     
                                    return(
                                        <Col md = {1} sm = {3} xs={6} style={{padding: '0px 5px'}}> 
                                            <SingleVarValueWidget
                                                assetData={item}
                                                attributea={this.state.attributea}
                                                attributeb={this.state.attributeb}
                                                handleValueClick={this.handleValueClick}
                                                isBlink = {isBlink}
                                                updatedValue = {updatedValue}
                                            />
                                        </Col>
                                    )
                                })}
                            </Row>
                    </CardBody>
                </Card>
                </div> */}
          <TabPane tabId="4">
            <div>
              <Card style={{ width: "100%" }}>
                <CardBody>
                  {/* {this.renderTimeFilter()} */}
                  {/* {this.renderSingalValue(currentAttributeData)} */}
                  <Nav tabs>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        style={setTabStyle(this.state.activeTab === "1")}
                        onClick={() => {
                          this.tabToggle("1");
                        }}
                      >
                        Charts
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        style={setTabStyle(this.state.activeTab === "2")}
                        onClick={() => {
                          this.tabToggle("2");
                        }}
                      >
                        Data
                      </NavLink>
                    </NavItem>
                    {this.state.activeTab == "1" ? (
                      <Col style={{ maxWidth: "12%", marginLeft: "1%" }}>{this.renderSingleValueListOne(currentAttributeData)}</Col>
                    ) : null}
                    {this.state.activeTab == "1" ? (
                      <Col style={{ maxWidth: "12%" }}>{this.renderSingleValueListTwo(currentAttributeData)}</Col>
                    ) : null}
                    {this.state.activeTab == "1" ? (
                      this.renderTimeFilter()
                    ) : <Col> {this.renderTimeFilter()}</Col>}
                    {/* <Col style={{ maxWidth: "12%" }}>
                        {this.renderSingleValueListOne(currentAttributeData)}
                      </Col>
                      <Col style={{ maxWidth: "12%" }}>
                        {this.renderSingleValueListTwo(currentAttributeData)}
                      </Col>
                    {this.renderTimeFilter()} */}
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1" style={tabPaneStyle}>
                      <button
                        className="panel__btn"
                        style={{ float: "right", marginRight: '2%' }}
                        onClick={this.onRefresh}
                      >
                        <AutorenewIcon />
                      </button>
                      <br />
                      {/* <Row style={{width: '100%'}}>
                                <Col md={3}>
                                <SingleVarLineChartWidget
                                    attribute={'Temperature'}
                                    value={'22.3'}
                                    units={'\u00b0C'}
                                />
                                </Col>
                            </Row> */}
                      {/* <Row style={{width: '100%',  padding: '0px 15px 0px 15px'}}>
                                {attrubuteChartData && attrubuteChartData.map((item)=>(
                                    <Col md = {3} style={{padding: '0px 5px'}}> 
                                        <SingleVarLineChartWidget
                                            assetData={item}
                                        />
                                    </Col>
                                ))}
                            </Row> */}
                      {/* <Row style={{width: '100%', height: '135px', padding: '0px 15px 0px 15px'}}>
                                {attrubuteChartData && attrubuteChartData.map((item)=>(
                                    <Col md = {1} style={{padding: '0px 5px'}}> 
                                    <SingleVarValueWidget
                                        assetData={item}
                                    />
                                    </Col>
                                ))}
                            </Row> */}
                      {/* <Row style={{width: '100%', marginBottom:'10px', justifyContent:'center'}}>
                                <Col md={12}>
                                    <WeatherBarChart
                                        countData = {this.props.countData}
                                        units = {this.props.countUnit}
                                        isSmall = {this.props.isSmall}
                                    />
                                </Col>
                            </Row> */}
                      {/* <br/> */}
                      {/* <Row style={{width: '100%', height: '135px', padding: '0px 15px 0px 15px'}}>
                                {currentAttributeData && currentAttributeData.map((item)=>{
                                     
                                    let isBlink = false;
                                    let updatedValue = null;
                                    // if (this.props.currentAsset && this.props.alarmObj && this.props.alarmObj.msgList) {
                                    //     for (let alarm of this.props.alarmObj.msgList) {
                                    //         if (this.props.currentAsset.assetname === alarm.assetname && alarm.attributeList && alarm.attributeList.includes(item.attributename) && this.props.alarmObj.status == true){
                                    //             isBlink = true;
                                    //             break;
                                    //         }
                                    //     }                                      
                                    // }

                                    if (this.props.currentAsset && this.props.alarmSet) {
                                         
                                        let assetid = this.props.currentAsset.assetid;
                                         
                                        if (this.props.alarmSet[assetid] && this.props.alarmSet[assetid][item.attributename]){
                                            //  
                                            isBlink = this.props.alarmSet[assetid][item.attributename].status;
                                            // let itemTime = new Date(item.timestamp);
                                            // let alarmTime = new Date(this.props.alarmSet[assetid][item.attributename].timestamp);
                                            //  
                                            //  
                                            // if (alarmTime > itemTime){
                                            //     updatedValue = this.props.alarmSet[assetid][item.attributename].value;
                                            //      
                                            // }
                                        } 
                                    }
                                     
                                    return(
                                        <Col md = {1} style={{padding: '0px 5px'}}> 
                                        <SingleVarValueWidget
                                            assetData={item}
                                            attributea={this.state.attributea}
                                            attributeb={this.state.attributeb}
                                            handleValueClick={this.handleValueClick}
                                            isBlink = {isBlink}
                                            updatedValue = {updatedValue}
                                        />
                                        </Col>
                                    )
                                })}
                            </Row> */}
                      {/* <Row style={{width: '100%'}}>
                                <Col md={12}>
                                    <WeatherLineChart
                                        // chartData={filtedHistoryData}
                                        chartData={this.props.historyObjData}
                                        attributea={this.state.attributea}
                                        attributeb={this.state.attributeb}
                                    />
                                </Col>
                            </Row> */}
                      <br />
                      {/* <Row style={{width: '100%', marginBottom:'10px', justifyContent:'center'}}>
                                <Col md={12}>
                                    <WeatherBarChart
                                        countData = {this.props.countData}
                                        units = {this.props.countUnit}
                                        isSmall = {this.props.isSmall}
                                    />
                                </Col>
                            </Row> */}
                      <div>
                        {/* <Row style={{width: '100%', marginBottom:'10px', justifyContent:'center'}}>
                                <Col md={12}> */}
                        <WeatherMixChart
                          chartData={this.props.historyObjData}
                          attributea={this.state.attributea}
                          attributeb={this.state.attributeb}
                          countData={this.props.countData}
                          units={this.props.countUnit}
                          isSmall={this.props.isSmall}
                          historyLoading={this.props.assetHistoryLoading}
                          countLoading={this.props.assetCountLoading}
                          hourlyCountLoading={
                            this.props.assetHourlyCountLoading
                          }
                          isDays={this.props.isTimeDisabled}
                        />
                        {/* </Col>
                            </Row> */}
                      </div>
                    </TabPane>
                    <TabPane tabId="2" style={tabPaneStyle}>
                      <br />
                      {/* <Row style={{width: '100%', justifyContent:'center'}}>
                                <Col md={12}> */}
                      <StatusTable
                        historyLoading={this.props.assetHistoryLoading}
                        currentAsset={this.props.currentAssetData}
                        toggle={this.props.toggle}
                        // tableData = {this.props.historyData}
                        tableData={filteredHistoryData}
                        filterAttr={"Alarm"}
                      />
                      {/* </Col>
                            </Row>         */}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </div>
          </TabPane>
        </TabContent>
        {/* <Row style={{width: '100%'}}>
                <Col md={3}>
                <SingleVarLineChartWidget
                    attribute={'Temperature'}
                    value={'22.3'}
                    units={'\u00b0C'}
                />
                </Col>
            </Row>
            <Row style={{width: '100%', height: '180px', padding: '0px 15px 0px 15px'}}>
                {attrubuteChartData && attrubuteChartData.map((item)=>(
                    <Col md = {2} style={{padding: '0px'}}> 
                    <SingleVarValueWidget
                        assetData={item}
                    />
                    </Col>
                ))}
            </Row>
            <Row style={{width: '100%', marginBottom:'10px', justifyContent:'center'}}>
                <Col md={12}>
                    <WeatherBarChart
                        countData = {this.props.countData}
                        units = {this.props.countUnit}
                        isSmall = {this.props.isSmall}
                    />
                </Col>
            </Row> */}
        {/* <Row style={{width: '100%'}}>
                <Col md={12}>
                    <WeatherLineChart/>
                </Col>
            </Row> */}
        {/* <br/> */}
        {/* <Row style={{width: '100%', justifyContent:'center'}}>
                <Col md={12}>
                    <StatusTable 
                        toggle={this.props.toggle}
                        tableData = {this.props.historyData}
                        filterAttr={'Alarm'}
                    />
                </Col>
            </Row> */}
      </Fragment>
    );
  }
}

export default HistoryBoard;

// const ATTRIBUTE_ORDER = [
//     {
//         attributename: 'Temperature',
//         backgroundcolor: '#b20000',
//         units: '\u00b0C'
//     },
//     {
//         attributename: 'Pressure',
//         backgroundcolor: 'purple',
//         units: 'hPa'
//     },
//     {
//         attributename: 'Humidity',
//         backgroundcolor: '#3EA99F', // blue green
//         units: '%'
//     },
//     // {
//     //     attributename: 'Temperature',
//     //     backgroundcolor: 'red',
//     // },
//     {
//         attributename: 'Irradiation',
//         backgroundcolor: 'orange',
//         units: 'W/m2'
//     },
//     {
//         attributename: 'Battery',
//         backgroundcolor: 'green',
//         units: 'V'
//     },
//     {
//         attributename: 'Rain',
//         backgroundcolor: 'blue',
//         units: 'mm'
//     }
// ]

function filterHistoryData(historyData) {
  let reData = [];
  if (!historyData) {
    return reData;
  }
  let attributeArr = ATTRIBUTE_ORDER.map((item) => item.attributename);
  attributeArr.push("Alarm");
  reData = historyData.filter(
    (item) =>
      item.type === "device" && attributeArr.includes(item.attributename)
  );
  return reData;
}

function setAttributeChartData(historyData) {
  let reData = [];
  // if ( !historyData){
  //     return reData;
  // }
  let now = new Date();
  let before24 = new Date(now.getTime() - 24 * 3600 * 1000);
  if (historyData) {
    const data24 = historyData.filter((item, index) => {
      // const itemTime = new Date(item.timestamp);
      const itemTime = new Date(item.attritime);
      return (
        // item.type === 'device' &&
        itemTime >= before24 && itemTime <= now
      );
    });

    const dataMap = groupBy(data24, (asset) => asset.attributename);
    for (let attr of ATTRIBUTE_ORDER) {
      let attrData = dataMap.get(attr.attributename);
      if (attrData) {
        let currentValue = attrData[attrData.length - 1].value;
        let timestamp = attrData[attrData.length - 1].attritime;
        let dataList = attrData.map((item) => ({
          x: item.attritime,
          y: item.value,
        }));
        let attrDataObj = {
          ...attr,
          value: currentValue,
          timestamp: timestamp,
          data: dataList,
        };
        reData.push(attrDataObj);
      }
    }
  } else {
    for (let attr of ATTRIBUTE_ORDER) {
      if (attrData) {
        let dataList = [
          { x: before24.toString(), y: 0 },
          { x: now.toString(), y: 0 },
        ];
        let attrDataObj = { ...attr, value: 0, timestamp: null, data: null };
        reData.push(attrDataObj);
      }
    }
  }
  return reData;
}

function setCurrentAttribute(currentAssetData) {
  let reData = [];
  if (!currentAssetData) {
    return reData;
  }
  if (currentAssetData && currentAssetData.attributes)
    for (let attr of ATTRIBUTE_ORDER) {
      if (!attr.selected) {
        continue;
      }
      let attrObj = currentAssetData.attributes.find(
        (item) =>
          attr.attributename == item.attributename &&
          item.attributename != "Battery"
      );
      if (attrObj) {
        let currentValue = attrObj.value;
        let timestamp = currentAssetData.timestamp;
        // let dataList = attrData.map((item) => ({x: item.attritime, y: item.value}));
        let attrDataObj = {
          ...attr,
          value: currentValue,
          timestamp: timestamp,
        };
        reData.push(attrDataObj);
      }
    }
  return reData;
}
