/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Input } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../../shared/components/Table/tableElements';

import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';

class OrderSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderSummary: []
        }
    }
    componentDidMount() {

    }

    priceFormatter = (cell, row) => {
        let price = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
        }
        return (
            <span>{`$${price}`}</span>
        )
    }
    discountPriceFormatter = (cell, row) => {
        let price = 0;
        let discountAmt = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
            if (row.promoteId != null) {
                var discount = this.props.discountList.find(discount => discount.promoteId === parseInt(row.promoteId));
                if (discount.promoteType == "percentage") {
                    discountAmt = price * (discount.promoteValue / 100)
                }
                else if (discount.promoteType == "dollar") {
                    discountAmt = discount.promoteValue * row.qty
                }
            }
            price = price - discountAmt;
            if (price < 0) {
                price = 0
            }

        }
        return (
            <span>{`$${price.toFixed(2)}`}</span>
        )

    }

    onDiscountChange = (event, row) => {

        if (event.target.value == '') {
            this.props.setDiscount(row.itemId, null)

        }
        else {
            this.props.setDiscount(row.itemId, event.target.value)
        }
    }
    discountFormatter = (cell, row) => {
        return (
            <Input
                type='select'
                name='discount'
                placeholder='discount'
                className="form-control"
                onChange={(event) => this.onDiscountChange(event, row)}
                defaultValue={cell}
            >
                <option key={-1} value={''}>No discount</option>
                {this.props.discountList.map((discount, index) =>
                    <option key={index} value={discount.promoteId}>{discount.promoteName}</option>
                )}
            </Input>
        )
    }

    handleOnBack = () => {
        this.props.clear();
        this.props.onBack();
    }
    handleOnNext = () => {
        this.props.onNext();
    }
    render() {
        const options = {
            noDataText: "No cart Found",
            sizePerPage: 10,  // which size per page you want to locate as default
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '100', value: 100
            }, {
                text: '1000', value: 1000
            }, {
                text: 'All', value: this.state.orderSummary.length
            }],
        }
        const orderList = new Map(); // shrink the order summary list based on SKU
        this.props.orderSummary.forEach(item => {
            if (orderList.get(item.itemId) == undefined) {
                var obj = {
                    itemId: item.itemId,
                    sku: item.sku,
                    qty: item.qty,
                    unitPrice: item.unitPrice,
                    promoteId: item.promoteId,
                    totalPrice: item.totalPrice
                }
                orderList.set(item.itemId, obj);
            }
            else {
                orderList.get(item.itemId).qty += 1;
                orderList.get(item.itemId).totalPrice = orderList.get(item.itemId).qty * orderList.get(item.itemId).unitPrice;
            }
        })

        return (
            <div>
                <Row>
                    <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <BackButton
                            onClick={this.handleOnBack}
                            label="Back"
                            size={30}
                            color='black'
                        />
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NextButton
                            onClick={this.handleOnNext}
                            label="Next"
                            size={30}
                            color='black'
                        />
                    </Col>
                </Row>
                <h3>Order Summary</h3>
                <br />
                <BootstrapTable
                    data={Array.from(orderList.values())}
                    tableHeaderClass='header_white'
                    bordered={false}
                    height='100%'
                    options={options}
                    pagination
                >
                    <TableHeaderColumn row='0' rowSpan='1' dataField='sku' dataAlign="center" isKey={true} dataSort filter={{ type: 'TextFilter', placeholder: 'SKU' }} caretRender={sortCaret}>SKU</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='qty' dataAlign="center" dataSort caretRender={sortCaret}  >QTY</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' dataAlign="center" filter={{ type: 'NumberFilter', numberComparators: ['=', '>', '<'], placeholder: ' ' }} dataSort dataFormat={this.priceFormatter} caretRender={sortCaret} >Unit Price</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='promoteId' dataAlign="center" dataFormat={this.discountFormatter}  >Discount Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='totalPrice' dataAlign="center" filter={{ type: 'NumberFilter', numberComparators: ['=', '>', '<'], placeholder: ' ' }} dataSort dataFormat={this.discountPriceFormatter} caretRender={sortCaret}>Total</TableHeaderColumn>
                </BootstrapTable>

            </div>
        )
    }

}
export default OrderSummary;