/* eslint-disable */

import {
    MAP_FILTER_THEME_INPUT_BIND, 
    MAP_FILTER_MESSAGE_TYPE_INPUT_BIND,
    MAP_FILTER_DEVICE_TYPE_INPUT_BIND, 
    MAP_FILTER_FROM_INPUT_BIND,
    MAP_FILTER_TO_INPUT_BIND, 
    MAP_FILTER_ATTRIBUTE_INPUT_BIND,
    MAP_FILTER_ASSETCLASS_INPUT_BIND
} from '../../constants/actionTypes';

const initState = {    
    // currentDeviceTypeId:'5cf828390499f50b995ae851',
    // currentDeviceTypeId: "0",
    currentMessageTypeId:'',
    currentThemeId: 0,
    currentFromTime:'',
    currentToTime:'',
    currentAttributeId:'',   
    currentAssetClassId: 2, 
}

export default function(state = initState, action){
    // alert('reducer out : ' + action.data);
    switch (action.type){
        case MAP_FILTER_THEME_INPUT_BIND:
            // alert('reducer in: ' + action.data);
             
             
            return {
                ...state,
                currentThemeId: action.data,
            }
        case MAP_FILTER_MESSAGE_TYPE_INPUT_BIND:
            return {
                ...state,
                currentMessageTypeId: action.data,
            }
        case MAP_FILTER_DEVICE_TYPE_INPUT_BIND:
            return {
                ...state,
                currentDeviceTypeId: action.data,
            }
        case MAP_FILTER_FROM_INPUT_BIND:
            return {
                ...state,
                currentFromTime: action.data,
            }
        case MAP_FILTER_TO_INPUT_BIND:
            return {
                ...state,
                currentToTime: action.data,
            }
        case MAP_FILTER_ATTRIBUTE_INPUT_BIND:
            return {
                ...state,
                currentAttributeId: action.data,
            }
        case MAP_FILTER_ASSETCLASS_INPUT_BIND:
            return{
                ...state,
                currentAssetClassId: action.data,
            }
        default: 
            return Object.assign({}, state);
    }
}