/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import {ExcelRenderer, OutTable} from 'react-excel-renderer';
class ShowTableModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            showItemModal: this.props.showItemModal,
            cols:this.props.cols,
            rows:this.props.rows,
            file:null,
            showTable:false,
            inPost:[]
        };
    }
    
    onItemClose = () => {
        this.setState({
            showItemModal: false
        })
    }
    render() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showItemModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onItemClose}>File Data</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                        <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
       
                        </Row>
                        <br/>
                    </Col>
                </ModalBody>
                <ModalFooter>
                    
                    <Button outline color='secondary' type="reset" onClick={this.onItemClose} >
                        Close
                        </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default ShowTableModal;