/* eslint-disable */
import React, { Component } from 'react';
import {Container, Row, Col, Button, Label, } from 'reactstrap';
import TrafficLightWidget from './TrafficLightWidget';
import DonutWidget from './DonutWidget';
import BatteryWidget from './BatteryWidget';
import AttributeListWidget from './AttributeListWidget';
import {TIME_ZONE} from '../../../constants/dataConstants';
import {weatherSeverity, hex2bin, splitStatusValue} from '../../../factories/utils';

const WeatherMapInfo = ({props, toggle, alarmSetObj }) => {
    let alarm = 0;
    let hexAlarm = null;
    let activeTab = 1;
    if (props.asset!=null){
        if(props.asset.grouping == "wind"){
            activeTab = 2;
        }
    }
    // let battery = null;
    // let temperature = null;
    // if (props.attributes){
    //     for (let i = 0; i < props.attributes.length; i++) {
    //         let attribute = props.attributes[i];
    //         switch(attribute.attributename){ 
    //             case 'CurrentDigitalInputState':
    //             case 'hexAlarm':
    //                  
    //                 let slicedValue = splitStatusValue(attribute.value);
    //                 // alarm = weatherSeverity(attribute.value);
    //                 // hexAlarm = hex2bin(attribute.value);
    //                 alarm = slicedValue.status;
    //                 hexAlarm = hex2bin(slicedValue.alarm);
    //                 break;
    //             // case "BatteryVoltage":
    //             //     battery = attribute.value;
    //             //     break;
    //             // case "InternalTemperature":
    //             //     temperature = attribute.value;
    //             //     break;
    //         }
    //     }
    // }
    const tableTimeColoumnFormater = (cell) => {
        if (cell) {
            try{
                let displayTime = new Date(cell).toLocaleString('en-US', { timeZone: TIME_ZONE });  
                return(
                    <span>{displayTime}</span>
                );
            }
            catch(e){
                return null;
            }
        }else{
            return null;
        }
        
    }
     
    const asset = props && props.asset ? props.asset : {};
    return(
        // <div style={{width: '300px', height: '500px'}}>
        <div style={{width: '200px', height: '300px'}}>
        {/* <div style={{width: '20vw', height: '10vh'}}> */}
        {/* <h4><b>{props.name}</b></h4> */}
        {/* <br/> */}
        {/* <span>{props.timestamp}</span> */}
        {/* {tableTimeColoumnFormater(props.timestamp)} */}
        {/* <hr/> */}
        <Row style={{margin: '0px'}}>
            <Col md={12} sm={12} style={{textAlign: 'center', paddingRight: '0', paddingLeft: '0', overflow: 'hidden'}}>
                {/* <TrafficLightWidget status={Number(alarm)} statusHex={hexAlarm} style={{width: '50', height: '100'}}/> */}
                {/* <DonutWidget status={Number(alarm)} statusHex={hexAlarm} style={{width: '50', height: '50'}}/> */}
                {/* <div style={{width: '92%'}}> */}
                
                <div style={{width: '50%'}} >
                <AttributeListWidget 
                    alarmSet={alarmSetObj} 
                    asset={asset} 
                    isSmall={true} 
                    toggle = {toggle}
                    activeTab={activeTab}
                    style={{height: '150px'}}
                />
                </div>
            </Col>
            {/* <Col md={6} >
                <Row>
                    <Col md={12} style={{textAlign: 'center'}}>
                    {
                        battery != undefined && battery != null ?
                        <BatteryWidget level={Number(battery)}/>
                        :
                        <span style={{fontSize:'16px'}}>No data</span>
                    }
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                    <div style={{backgroundColor: '#00ff00', padding: '0px', border: '2px solid black', width:'85px', textAlign: 'center'}}>
                        {
                            temperature ? 
                            <span style={{fontSize:'16px'}}>{temperature} &#xb0;C</span>
                            :
                            <span style={{fontSize:'16px'}}>No data</span>
                        }
                    
                    </div>
                    </Col>
                </Row>
            </Col> */}
        </Row>
        <br/>
    </div>
    )
}
export default WeatherMapInfo