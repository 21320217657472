/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import { Card, CardBody, Col, Button, Collapse } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { EditTextButton } from '../../../shared/components/Button/IconButton'
import { getCurrentUser, hasRole } from '../../../factories/auth';
import uuid from "uuid/v4";
import { cloneDeep } from 'lodash'
import {
    getGeneralLedgerCodesByCompanyCode,
    addGeneralLedgerCodeByCompanyCode,
    modifyGeneralLedgerCodeByCompanyCode
} from '../../../redux/actions/userAction'
import CompanyAddEditGeneralLedgerCodeModal from './CompanyAddEditGeneralLedgerCodeModal'

class CompanyAddGeneralLedgerCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userObj: getCurrentUser(),
            showGeneralLedgerCodeAddEditModal: false,
            isGeneralLedgerCodeEditable: false,
            generalLedgerCodeListWithIndexOperation: []
        }

        this.onUpdateCompanyAddGeneralLedgerCode = this.onUpdateCompanyAddGeneralLedgerCode.bind(this)
        this.onCloseCompanyAddGeneralLedgerCodeModal = this.onCloseCompanyAddGeneralLedgerCodeModal.bind(this)
        this.onOpenCompanyAddGeneralLedgerCodeModal = this.onOpenCompanyAddGeneralLedgerCodeModal.bind(this)
        this.addNewGeneralLedgerCodeButton = this.addNewGeneralLedgerCodeButton.bind(this)
        this.cellGeneralLedgerCodeEditButton = this.cellGeneralLedgerCodeEditButton.bind(this)
    }

    componentDidMount() {
        this.props.getGeneralLedgerCodesByCompanyCode(this.state.userObj.companyCode)
    }

    componentDidUpdate(prevProps) {
        let { generalLedgerCodes } = this.props

        if (generalLedgerCodes !== prevProps.generalLedgerCodes) {
            let generalLedgerCodesCopy = cloneDeep(generalLedgerCodes)

            for (let gl = 0; gl < generalLedgerCodesCopy.length; gl++) {
                const element = generalLedgerCodesCopy[gl];
                element.operationsEdit = true
            }

            this.setState({
                generalLedgerCodeListWithIndexOperation: generalLedgerCodesCopy
            })
        }
    }

    onUpdateCompanyAddGeneralLedgerCode = async (generalLedgerCodeItemTemplate) => {

        const { userObj, isGeneralLedgerCodeEditable } = this.state
        const { addGeneralLedgerCodeByCompanyCode,
            modifyGeneralLedgerCodeByCompanyCode,
            getGeneralLedgerCodesByCompanyCode } = this.props

        const generalLedgerCodePayload = {
            id: 0,
            description: generalLedgerCodeItemTemplate.description,
            code: generalLedgerCodeItemTemplate.generalLedgerCode,
            companyCode: userObj.companyCode,
            userName: userObj.userName
        }

        if (isGeneralLedgerCodeEditable) {
            generalLedgerCodePayload.id = generalLedgerCodeItemTemplate.id
            await modifyGeneralLedgerCodeByCompanyCode(generalLedgerCodePayload)
        } else {
            await addGeneralLedgerCodeByCompanyCode(generalLedgerCodePayload)
        }
        await getGeneralLedgerCodesByCompanyCode(userObj.companyCode)
    }

    onCloseCompanyAddGeneralLedgerCodeModal = (generalLedgerCodeItemTemplate) => {

        this.setState({
            showGeneralLedgerCodeAddEditModal: false
        })

    }

    onOpenCompanyAddGeneralLedgerCodeModal = (generalLedgerCodeItemTemplate) => {

        this.setState({
            showGeneralLedgerCodeAddEditModal: true,
            generalLedgerCodeItemSelected: generalLedgerCodeItemTemplate
        })

    }

    addNewGeneralLedgerCodeButton = () => {

        /* create a sample row item */
        const generalLedgerCodeItemTemplate = {
            listItemIndex: 0,
            operationsIndex: uuid(),
            operationsEdit: true,
            operationsDelete: true,
            description: "",
            generalLedgerCode: ""
        }
        if(hasRole(this.props.userObj,["SUPERADMIN","ADMIN"])){
            return (
                <InsertButton
                    btnText='Add a General Ledger Code'
                    btnContextual='btn-success'
                    btnGlyphicon='glyphicon-edit'
                    className='btn-add'
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            isGeneralLedgerCodeEditable: false
                        }, () => {
                            this.onOpenCompanyAddGeneralLedgerCodeModal(generalLedgerCodeItemTemplate)
                        });
                    }}
                >
                    <PlusIcon /> Add a General Ledger Code
                </InsertButton>
            );
        }
    }

    cellGeneralLedgerCodeEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton
                    label={''}
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            isGeneralLedgerCodeEditable: true
                        }, () => {
                            this.onOpenCompanyAddGeneralLedgerCodeModal(cloneDeep(row))
                        });

                    }} />
            </div>
        )
    }

    render() {

        const options = {
            paginationPosition: 'bottom',
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        };

        const { showGeneralLedgerCodeAddEditModal,
            isGeneralLedgerCodeEditable,
            generalLedgerCodeItemSelected,
            generalLedgerCodeListWithIndexOperation } = this.state

        return (
            <>
                <CompanyAddEditGeneralLedgerCodeModal
                    showModal={showGeneralLedgerCodeAddEditModal}
                    generalLedgerCodeItemSelected={generalLedgerCodeItemSelected}
                    onClose={this.onCloseCompanyAddGeneralLedgerCodeModal}
                    onSave={this.onUpdateCompanyAddGeneralLedgerCode}
                    isEditMode={isGeneralLedgerCodeEditable}
                />
                <Card>
                    <CardBody className="mainCardBody">
                        {/* <div style={{ marginBottom: '20px' }}> */}
                        {/* <h3>General Ledger Codes</h3> */}
                        {/* <div style={{ textAlign: 'left', marginTop: '20px' }}> */}
                        {this.addNewGeneralLedgerCodeButton()}
                        {/* </div> */}
                        {/* </div> */}
                        <BootstrapTable
                            data={generalLedgerCodeListWithIndexOperation}
                            options={options}
                            tableHeaderClass='header_white'
                            bordered={false}
                            height='100%'
                            pagination={true}
                            // exportCSV={false}
                            exportCSV
                            csvFileName='Company_GL_Code.csv'
                        >
                            <TableHeaderColumn dataField='id' isKey={true} hidden={true}>
                                Index
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='createdAtFormatted' dataSort
                                dataAlign="center" caretRender={sortCaret}
                                hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                                csvHeader='Date'
                            >
                                Date
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='generalLedgerCode' dataSort
                                dataAlign="center" caretRender={sortCaret}
                                hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                                csvHeader='GL Code'
                            >
                                GL Code
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='description' dataSort
                                dataAlign="center" caretRender={sortCaret}
                                hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                                csvHeader='Description'
                            >
                                Decription
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='operationsEdit'
                                dataAlign="center"
                                dataFormat={this.cellGeneralLedgerCodeEditButton}
                                width="10%"
                                editable={false}
                                export={false}
                                hidden = {(!hasRole(this.props.userObj,["SUPERADMIN","ADMIN"]))?true:false}
                            >
                                Edit
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </CardBody>
                </Card>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    let { generalLedgerCodes } = state.user;
    let props = { generalLedgerCodes }

    return props
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getGeneralLedgerCodesByCompanyCode,
        addGeneralLedgerCodeByCompanyCode,
        modifyGeneralLedgerCodeByCompanyCode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAddGeneralLedgerCode);