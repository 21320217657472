/* eslint-disable */
import { faDoorClosed } from '@fortawesome/free-solid-svg-icons';
import {
    ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED,
    ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
    INVENTORY_GET_DEVICE_MASTER_SUCCEED,
    INVENTORY_GET_DEVICE_MASTER_FAILED,
    INVENTORY_GET_INVENTORY_SUCCEED,
    INVENTORY_GET_INVENTORY_FAILED,
    INVENTORY_GET_ONE_DEVICE_MASTER_SUCCEED,
    INVENTORY_GET_ONE_DEVICE_MASTER_FAILED,
    WEB_SERVICE_INVENTORY_SUPLLIER_SUCCESS,
    WEB_SERVICE_INVENTORY_SUPLLIER_ITEM_SUCCESS,
    INVENTORY_GET_CONSUMER_SIM_SUCCEED,
    INVENTORY_GET_CONSUMER_SIM_FAILED,
    WEB_SERVICE_GET_SAP_CSV_SUCCESS,
    WEB_SERVICE_GET_SAP_CSV_FAILED,
    NEW_RESELLER_GET_DEVICE_INVENTORY_SUCCEED,
    NEW_RESELLER_GET_DEVICE_INVENTORY_FAILED,
    ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_SUCCEED,
    ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_FAILED,
    RESELLER_GET_SERVICE_INVENTORY_SUCCEED,
    RESELLER_GET_SERVICE_INVENTORY_FAILED,
    CARRIER_GET_PLANS_SUCCESS,
    CARRIER_GET_PLANS_FAILED,
    CARRIER_UPDATE_INFO_SUCCESS,
    CARRIER_UPDATE_INFO_FAILED,
    USER_CLEAR_CARRIER_PLAN_LIST_SUCCESS,
    USER_CLEAR_CARRIER_PLAN_LIST_FAILED,
    COMPANY_GET_ACTIVITY_REPORT_SUCCESS,
    COMPANY_GET_ACTIVITY_REPORT_FAILED,
    GET_ACTIVATED_SIM_DASHBOARD_SUCCESS,
    GET_ACTIVATED_SIM_DASHBOARD_FAILED,
    SIM_SET_NAME_SUCCESS,
    SIM_SET_NAME_FAILED,
    CLEAR_CLIENT_DATA,
    INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_FAILED,
    INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_SUCCEED,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_START,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_FAILED,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_FAILED,
    GET_DEVICE_LIST_SUCCESS,
    GET_DEVICE_LIST_FAILED,
    CHANGE_DEVICE_STATUS_SUCCESS,
    CHANGE_DEVICE_STATUS_FAILED,
    REGISTER_DEVICE_SUCCESS,
    REGISTER_DEVICE_FAILED,
} from '../../constants/actionTypes';

import { generateSelectOptions } from '../../factories/utils'

const initState = {
    actions: [],
    DeviceList: [],
    inventoryList: [],
    deviceMasterList: [],
    supplierList: [],
    supplierItemList: [],
    SIMTypeList: [],
    typeList: [],
    itemType:[],
    activeSIMList: [],
    inactiveSIMList: [],
    suspendedSIMList: [],
    companySIMList: [],
    resellerDeviceItems: [],
    resellerServicePlanInventory: [],
    resellerServicePlanItems: [],
    carrierPlanList: [],
    activityReportList: [],
    dFile:null,
    googleMapInfo:{},
    manualBillingStatus: false,
    clientActiveDeviceList: [],
    timestamp: 0
}

export default function (state = initState, action) {
    switch (action.type) {
        case COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_SUCCESS:
                
            return {
                ...state,
                error: 0,
                message: 'MANUAL BILLING SUCCESSFULLY FETCHED',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
                manualBillingStatus: action.payload.manualBillingStatus
            }
        case COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_FAILED:

            console.log(COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_FAILED, action)

            return {
                ...state,
                error: 1,
                message: 'FETCHING MANUAL BILLING FAILED',
                type: action.type,
                messagevisible: false,
                messagetype: 'fail',
                manualBillingStatus: false
            }
        case COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_START:
            return {
                ...state,
                error: 0,
                message: 'STARTING MANUAL BILLING',
                type: action.type,
                manualBillingStatus: true
            }
        case COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_SUCCESS:
            return {
                ...state,
                error: 0,
                message: 'MANUAL BILLING SUCCESSFULLY COMPLETE',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
                manualBillingStatus: false
            }
        case COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_FAILED:
            return {
                ...state,
                error: 1,
                message: 'MANUAL BILLING FAILED',
                type: action.type,
                messagevisible: false,
                messagetype: 'fail',
                manualBillingStatus: false
            }
        case ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_SUCCEED:
            return {
                ...state,
                error: 0,
                message: 'SIM LIST SUCCESS UPLOAD ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_FAILED:
            return {
                ...state,
                error: 1,
                message: 'SIM LIST FAIL UPLOAD ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'fail',
            }
        case ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED:
            return {
                ...state,
                DeviceList: action.payload,
                error: 0,
                message: 'GET DEVICE LIST SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED:
            return {
                ...state,
                error: 1,
                message: 'DEVICE LIST FAIL UPLOAD ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'fail',
            }
        case RESELLER_GET_SERVICE_INVENTORY_SUCCEED:
            return {
                ...state,
                resellerServicePlanInventory: action.inventory,
                resellerServicePlanItems: action.items,
                error: 0,
                message: '',
            }
        case RESELLER_GET_SERVICE_INVENTORY_FAILED:
            return {
                ...state,
                resellerServicePlanInventory: [],
                resellerServicePlanItems: [],
                error: 1,
                message: action.message,
            }
        case INVENTORY_GET_INVENTORY_SUCCEED:
            //  
            return {
                ...state,
                inventoryList: action.payload,
                type: action.type,
                error: 0,
                message: '',
            }

        case INVENTORY_GET_INVENTORY_FAILED:
            return {
                ...state,
                inventoryList: [],
                type: action.type,
                error: 1,
                message: '',
            }
        case INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_SUCCEED:
            let tmpMapInfo = { ...state.googleMapInfo }
            let res = action.payload.resultResponse;
            if (res && res.iccid != null) {
                tmpMapInfo[res.iccid] = res
            } 
            else if (res && res.eid != null){
                tmpMapInfo[res.eid] = res
            }
            return {
                ...state,
                googleMapInfo: tmpMapInfo,
                type: action.type,
                error: 0,
            }
        case INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_FAILED:
            return {
                ...state,
                type: action.type,
                error: 1,
            }
        case CARRIER_GET_PLANS_SUCCESS:

            let carrierPlanListData = []

            if (action.payload
                && action.payload.resultResponse
                && action.payload.resultResponse.servicePlans) {

                carrierPlanListData
                    = action.payload.resultResponse.servicePlans
            }

            return {
                ...state,
                carrierPlanList: carrierPlanListData,
                message: action.payload.message
            }
        case CARRIER_GET_PLANS_FAILED:
            return {
                ...state,
                carrierPlanList: []
                //carrierPlanList: [{name:"5GB",},{description:"5 GB Desc"}]
            }

        case INVENTORY_GET_DEVICE_MASTER_SUCCEED:
            return {
                ...state,
                deviceMasterList: action.payload,
                type: action.type,
                error: 0,
                message: '',
            }
        case INVENTORY_GET_DEVICE_MASTER_FAILED:
            return {
                ...state,
                deviceMasterList: [],
                type: action.type,
                error: 1,
                message: action.error,
            }
        case INVENTORY_GET_ONE_DEVICE_MASTER_SUCCEED:
            return {
                ...state,
                deviceMaster: action.payload,
                type: action.type,
                error: 0,
                message: '',
            }
        case INVENTORY_GET_ONE_DEVICE_MASTER_FAILED:
            return {
                ...state,
                deviceMaster: {},
                type: action.type,
                error: 1,
                message: '',
            }
        case WEB_SERVICE_INVENTORY_SUPLLIER_SUCCESS:

            return {
                ...state,
                supplierList: action.payload,
                type: action.type,
                error: 0,
                message: '',
            }
        case WEB_SERVICE_INVENTORY_SUPLLIER_ITEM_SUCCESS:

            return {
                ...state,
                supplierItemList: action.payload,
                type: action.type,
                error: 0,
                message: '',
            }
        case NEW_RESELLER_GET_DEVICE_INVENTORY_SUCCEED:
            return {
                ...state,
                resellerDeviceItems: action.item,
                activeInventoryList: action.active,
                inactiveInventoryList: action.inactive,

            }
        case NEW_RESELLER_GET_DEVICE_INVENTORY_FAILED:
            return {
                ...state,
                message: action.message
            }

        case INVENTORY_GET_CONSUMER_SIM_SUCCEED:
            if (action.timestamp > state.timestamp) {
                return {
                    ...state,
                    SIMTypeList: action.deviceTypes,
                    typeList: action.deviceDefinition,
                    activeSIMList: action.active,
                    inactiveSIMList: action.inactive,
                    suspendedSIMList: action.suspended,
                    companySIMList: action.company,
                    itemType:action.itemType,
                    timestamp: action.timestamp

                }
            } else {
                return {
                    ...state
                }
            }
        case INVENTORY_GET_CONSUMER_SIM_FAILED:
            if (action.reset == true){
                return {
                    ...state,
                    message: action.message,
                    SIMTypeList: [],
                    typeList: [],
                    activeSIMList: [],
                    inactiveSIMList: [],
                    suspendedSIMList: [],
                    companySIMList: [],
                    itemType:[]

                }
            } else {
                return {
                    ...state,
                    message: action.message,
                    SIMTypeList: [],
                    typeList: [],
                    activeSIMList: [],
                    inactiveSIMList: [],
                    suspendedSIMList: [],
                    companySIMList: [],
                    itemType:[],
                    timestamp: action.timestamp
                }
            }
        case WEB_SERVICE_GET_SAP_CSV_SUCCESS:

            //console.log(action.data)
            return {
                ...state,
                dFile: action.data,
                message: action.message
            }
        case WEB_SERVICE_GET_SAP_CSV_FAILED:

            return {
                ...state,
                dFile: null,
                message: action.message
            }
        case COMPANY_GET_ACTIVITY_REPORT_SUCCESS:
            return {
                ...state,
                activityReportList: action.payload
            }
        case COMPANY_GET_ACTIVITY_REPORT_FAILED:
            return {
                ...state,
                activityReportList: []
            }
        case USER_CLEAR_CARRIER_PLAN_LIST_SUCCESS:
            return {
                ...state,
                carrierPlanList: action.payload
            }
        case CLEAR_CLIENT_DATA:
            return {
                ...state,
                SIMTypeList: [],
                typeList: [],
                inactiveSIMList: [],
                activeSIMList: [],
                suspendedSIMList: [],
                companySIMList: [],
                carrierPlanList: []

            }
        case GET_DEVICE_LIST_SUCCESS:
            return {
                ...state,
                clientActiveDeviceList: action.payload.itemDeviceInfoList,
            }
        case GET_DEVICE_LIST_FAILED:
            return {
                ...state,
                message: action.message,
                clientActiveDeviceList: [],
            }
        case CHANGE_DEVICE_STATUS_SUCCESS:
            return {
                ...state,
                message: action.message,
            }
        case CHANGE_DEVICE_STATUS_FAILED:
            return {
                ...state,
                message: action.message,
            }
        case REGISTER_DEVICE_SUCCESS:
            return {
                ...state,
                message: action.message,
            }
        case REGISTER_DEVICE_FAILED:
            return {
                ...state,
                message: action.message,
            }
        case CARRIER_UPDATE_INFO_SUCCESS:
        case CARRIER_UPDATE_INFO_FAILED:
        default:
            return state;
    }
}