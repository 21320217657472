/* eslint-disable */
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../../shared/components/Table/tableElements';
import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';


class SelectCompanyInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            companySIMList:[]
        }
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps) {
        let selectedKey = [];
        if (nextProps.cart.length > 0) {
            selectedKey = nextProps.cart.map(inv => inv.identifierValue);      
        }
        if (nextProps.cart.length == 0) {
            selectedKey = [];
        }

        //filter SIM by carrier
        let filteredSim = [];
        if(nextProps.selectedCarrier>-1&&nextProps.companySIMList!=null){
           
            filteredSim = nextProps.companySIMList.filter(obj => {
                if(obj.carrier!=null){
                    return obj.carrier.carrierId == nextProps.selectedCarrier
                }
                else{
                    return false;
                }
            });
        }
        else{
            filteredSim = nextProps.companySIMList;
        }
        this.setState({ 
            selected: selectedKey,
            companySIMList: filteredSim
        });

    }
    priceFormater = (cell, row) => {
        let price = 0;
        if (cell >= 0) {
            price = parseFloat(cell).toFixed(2);
        }
        return (
            <span>{`$${price}`}</span>
        )
    }
    handleOnNext = () => {
        this.props.onNext()
    }
    handleOnBack = () => {
        this.props.onBack();
    }

    render() {
        const options = {
            noDataText: "No Inventory Found",
            sizePerPage: 50,  // which size per page you want to locate as default
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '1000', value: 1000
            }, {
                text: 'All', value: (this.state.companySIMList != null) ? this.state.companySIMList.length : 0
            }],
        }
        const selectRowProp = {
            mode: 'checkbox',
            bgColor: '#F5EBCF',
            showOnlySelected: true,
            columnWidth: '40px',
            selected: this.state.selected,
            onSelect: this.props.handleSelectInventoryRow,
            onSelectAll: this.props.handleSelectInventoryAll,
        }
        return (
            <div>
                <Row>
                    <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <BackButton
                            onClick={this.handleOnBack}
                            label="Back"
                            size={30}
                            color='black'
                        />
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NextButton
                            onClick={this.handleOnNext}
                            label="Next"
                            size={30}
                            color='black'
                        />
                    </Col>
                </Row>
                <h3>Buy SIM(s)</h3>
                <br />
                <h4># of selected SIMs: <b>{(this.state.selected.length) ? this.state.selected.length : 0}</b></h4>
                <BootstrapTable
                    data={this.state.companySIMList}
                    tableHeaderClass='header_white'
                     
                     
                    bordered={false}
                    height='100%'

                    selectRow={selectRowProp}
                    options={options}
                    pagination
                >
                    <TableHeaderColumn dataField='itemName' dataAlign="center" dataSort filter={{ type: 'TextFilter', placeholder: 'Name' }} caretRender={sortCaret}>Name</TableHeaderColumn>
                    <TableHeaderColumn dataField='identifierValue' isKey={true} dataSort dataAlign="center" filter={{ type: 'TextFilter', placeholder: 'Value' }} caretRender={sortCaret}>Identifier Value</TableHeaderColumn>
                    <TableHeaderColumn dataField='itemDescription' dataAlign="center" dataSort filter={{ type: 'TextFilter', placeholder: 'Description' }} caretRender={sortCaret} >Description</TableHeaderColumn>
                    <TableHeaderColumn dataField='unitPrice' dataAlign="center" dataSort dataFormat={this.priceFormater} filter={{ type: 'NumberFilter', delay: 1000, numberComparators: ['=', '>', '<'], placeholder: 'Price' }} caretRender={sortCaret}>Price</TableHeaderColumn>
                </BootstrapTable>

            </div>
        )
    }
}
export default SelectCompanyInventory;