/* eslint-disable */
import React, { Component } from 'react';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { toast } from 'react-toastify';

class InventoryReturn extends Component {
    constructor(props){
        super(props);
        this.state={
            deviceId: null,
            modal: false,
            data: [
                {"mfg":"109","updated_BY":"admin","upc":"5324210000530","company":"ITSP","mfg_SKU":"2","device_STATUS":"S","special_STOCK":null,"refurbished":"N","mac_ADDRESS":"43:5D:54:43:5D:54","customer_ID":null,"device_ID":"Iot-435D53","old_DEVICE_ID":null,"create_TIME":"2019-01-24T05:00:00.000+0000","update_TIME":null,"network_SUPPORTED":"Sigfox","mfg_SERIAL_NUM":"435D53"},
                {"mfg":"109","updated_BY":"admin","upc":"5324210000530","company":"ITSP","mfg_SKU":"2","device_STATUS":"S","special_STOCK":null,"refurbished":"N","mac_ADDRESS":"43:5D:54:43:5D:54","customer_ID":null,"device_ID":"Iot-435D54","old_DEVICE_ID":null,"create_TIME":"2019-01-24T05:00:00.000+0000","update_TIME":null,"network_SUPPORTED":"Sigfox","mfg_SERIAL_NUM":"435D54"},
                {"mfg":"109","updated_BY":"admin","upc":"5324210000530","company":"ITSP","mfg_SKU":"2","device_STATUS":"H-156","special_STOCK":null,"refurbished":"N","mac_ADDRESS":"43:5D:54:43:5D:50","customer_ID":null,"device_ID":"Iot-435D55","old_DEVICE_ID":null,"create_TIME":"2019-01-24T05:00:00.000+0000","update_TIME":null,"network_SUPPORTED":"Sigfox","mfg_SERIAL_NUM":"435D55"},
                {"mfg":"109","updated_BY":"admin","upc":"5324210000530","company":"ITSP","mfg_SKU":"2","device_STATUS":"A","special_STOCK":null,"refurbished":"N","mac_ADDRESS":"43:5D:54:43:5D:51","customer_ID":null,"device_ID":"Iot-435D56","old_DEVICE_ID":null,"create_TIME":"2019-01-24T05:00:00.000+0000","update_TIME":null,"network_SUPPORTED":"Sigfox","mfg_SERIAL_NUM":"435D56"},
                {"mfg":"109","updated_BY":"admin","upc":"5324210000530","company":"ITSP","mfg_SKU":"2","device_STATUS":"R","special_STOCK":null,"refurbished":"N","mac_ADDRESS":"43:5D:54:43:5D:52","customer_ID":null,"device_ID":"Iot-435D57","old_DEVICE_ID":null,"create_TIME":"2019-01-24T05:00:00.000+0000","update_TIME":null,"network_SUPPORTED":"Sigfox","mfg_SERIAL_NUM":"435D57"}
            ],
            shippingAddress: {
                name:"Sigfox",
                address:"address 1",
                address2:"",
                city: "Toronto", 
                province:"ON",
                country:"Canada",
                zip:"M4G 0G7",
                phone:"4166290000",
            }
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onUploadClick = (e, deviceObj) => {
        this.setState({
            deviceId: deviceObj.device_ID
        });
        this.toggle();
    }

    actionFiledFormater = (cell, row) => {
        return (
            <div>
                {cell == 'R'? 
                    <span>Returned</span>
                :
                    <Button variant="primary" color="custom" type="button" onClick={(e)=>{ this.onUploadClick(e, row) }} size='sm'>Return</Button>
                }
                
            </div>
        );
    }


    renderDeviceList = () => {
        let products = [];
        if (Array.isArray(this.state.data)){
            this.state.data.forEach((device, index)=>{
                let newItem = {...device};
                newItem.orderId = '001';
                newItem.vender = 'Sigfox';
                products.push(newItem);
            });
        }
        return (
            <BootstrapTable 
                //   data={ this.props.deviceMasterList }  
                  data={ products }  
                //   tableHeaderClass = 'header_white' 
                  pagination = { false } 
                  tableHeaderClass = 'header_white'   
                   
                  bordered={ false } 
                  search 
                  // insertRow 
                  ref='table' 
            >
                <TableHeaderColumn dataField='device_ID' isKey={ true } dataAlign="center" width='10%' dataFormat={this.deviceIDFormatter} >Device ID</TableHeaderColumn>
                <TableHeaderColumn dataField='orderId' dataAlign="center" width='10%'>Order</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='mfg' dataAlign="center" dataSort width='10%' >MFG</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='mfg_SKU' dataAlign="center" dataSort >SKU</TableHeaderColumn>   */}
                <TableHeaderColumn dataField='mfg_SERIAL_NUM' dataAlign="center" dataSort hidden >Serial Number</TableHeaderColumn>   
                <TableHeaderColumn dataField='mac_ADDRESS' dataAlign="center" dataSort width='10%' hidden >MAC</TableHeaderColumn>                      
                <TableHeaderColumn dataField='special_STOCK' dataAlign="center" dataSort width='10%' hidden >Special Stock</TableHeaderColumn>             
                <TableHeaderColumn dataField='vender' dataAlign="center" dataSort width='10%' >Vender</TableHeaderColumn> 
                <TableHeaderColumn dataField='network_SUPPORTED' dataAlign="center" dataSort >Network</TableHeaderColumn>  
                <TableHeaderColumn dataField='refurbished' dataAlign="center" dataSort hidden >Refurbished</TableHeaderColumn>  
                <TableHeaderColumn dataField='old_DEVICE_ID' dataAlign="center" dataSort hidden >Old ID</TableHeaderColumn>  
                <TableHeaderColumn dataField='create_TIME' dataAlign="center" dataSort  >Create Time</TableHeaderColumn>  
                <TableHeaderColumn dataField='update_TIME' dataAlign="center" dataSort  >Update Time</TableHeaderColumn>  
                <TableHeaderColumn dataField='updated_BY' dataAlign="center" dataSort hidden >Update By</TableHeaderColumn>  
                {/* <TableHeaderColumn dataField='customer_ID' dataAlign="center" dataSort width='10%' >CUSTOMER</TableHeaderColumn>       */}
                <TableHeaderColumn dataField='device_STATUS' dataFormat={this.actionFiledFormater} dataAlign="center" dataSort width='15%' >Status</TableHeaderColumn>   
                {/* <TableHeaderColumn dataField='edit' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='action' dataFormat={this.actionFiledFormater} dataAlign="center"  ></TableHeaderColumn> */}
            </BootstrapTable>
        );
    }

    onCancelClick = () => {
        this.toggle();
    }

    onSaveClick = () => {

    }

    renderFileloaderModal=()=>{
        const { shippingAddress } = this.state; 
        return (
            <div>
                {/* <Button color="custom" onClick={this.toggle}>
                    Upload Received Devices
                </Button> */}
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader  
                        toggle={this.toggle}
                        //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                    >
                        Record Return Item
                    </ModalHeader>
                    <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                        <Row>
                            {/* <Col></Col> */}
                            <Label style={{ display: 'inline'}}> <h4>Device :</h4> </Label>
                            {/* <Col></Col> */}
                            <Label style={{ display: 'inline', marginLeft:'10px'}}> <h4>{this.state.deviceId}</h4> </Label>
                        </Row>
                        <Row style={{marginTop: 10}}>
                            <Col md={10}>
                                <h4 class="mb-3">Return address</h4>
                                <div class="mb-1">
                                    {shippingAddress.name && <div><label>{shippingAddress.name}</label><br/></div>}
                                    {shippingAddress.address && <div><label>{shippingAddress.address}</label><br/></div>}
                                    {shippingAddress.address2 && <div><label>{shippingAddress.address2}</label><br/></div>}
                                    <label>{shippingAddress.city + " " + shippingAddress.province + " " + shippingAddress.country + " " + shippingAddress.zip}</label>
                                    {shippingAddress.phone && <div><label>{"Tel: " + shippingAddress.phone}</label><br/></div>}
                                </div>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10}}>
                            <Col md={10}>
                                <h4 class="mb-3">Comments</h4>
                                <textarea  
                                    type="text" 
                                    class="form-control" 
                                    id="comments" 
                                    // placeholder="Apartment, suite" 
                                    name="comments" 
                                    rows="4"
                                    // cols="60"
                                    // value={shippingAddress.address2} 
                                    // onChange={this.handleInputChange}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="custom" onClick={this.onSaveClick}>Return</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    render(){
        return (
            <Row>
                <Col md={12}>
                {this.renderDeviceList()}
                {this.renderFileloaderModal()}
                </Col>
            </Row>
        );
    }

}
export default InventoryReturn