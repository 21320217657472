/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { getCurrentUser } from '../../factories/auth';

import ConsumerBoard from './components/ConsumerBoard';

class ConsumerPage extends Component {
    constructor(props) {
        super(props);
        this.userObj = null;
        this.state = {
            userObj: null,
            companyCode: null,
        };
        this.companycode = null;
    }
    render() {
        let userObj = getCurrentUser();
        return (
            <Container className='dashboard'>
                <Row>
                    <Col md={12}>
                        <ConsumerBoard
                        userObj = {userObj} />
                    </Col>
                </Row>
            </Container>
        );
    }

}
export default ConsumerPage;