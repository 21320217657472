/* eslint-disable */
import React, {useState, useRef, useEffect, useContext} from 'react'
import {useHistory} from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import { UserContext } from '../../constants/myContexts';
import { TIMEOUT, PROMPT_BEFORE_TIMEOUT, TIMEOUT_COMPANIES } from '../../constants/appConstants';
import { bindActionCreators } from 'redux';
import { useIdleTimer } from 'react-idle-timer';
import { userLogout } from '../../redux/actions/userAction'
import { set } from 'lodash';

const TimedOutPromptModal = () => {
    const contextValue = useContext(UserContext);
    const history = useHistory()
    const [modalVisible, setModalVisible] = useState(false)
    const [remaining, setRemaining] = useState(TIMEOUT)
    let currentUser = contextValue.userObj;

    const handleIdle = () =>{
        setModalVisible(false)
        logout()
    }

    const onActive = () =>{
        setModalVisible(false)
    }

    const onPrompt = () =>{
        setModalVisible(true)
    }

    const logout = ()=>{
      sessionStorage.clear()
      userLogout()
      document.getElementsByTagName("html").item(0).style = "";
      history.push('/log_in');
    }

    const handleStillHere = ()=>{
        activate()
    }

    const {getRemainingTime, activate, pause} = useIdleTimer({
        onIdle: handleIdle,
        timeout: TIMEOUT,
        onActive,
        onPrompt,
        disabled: !TIMEOUT_COMPANIES.find(c=>c==currentUser.companyCode),
        promptBeforeIdle: PROMPT_BEFORE_TIMEOUT})


    useEffect(() => {
        const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
        clearInterval(interval)
        }
    })

    const timeTillPrompt = Math.max(remaining - PROMPT_BEFORE_TIMEOUT / 1000, 0)
    const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

    return (
        <>
            <Modal isOpen={modalVisible}
                toggle={handleStillHere}>
                <ModalBody>
                    <br /><br />
                    <h4 className='text-center'>
                    You have been idle for a while. Please confirm to continue the session or you will be logged out in {remaining} {seconds}
                    </h4>
                    <br /><br />
                    <Row md="12">
                    <Col className='text-right' md="6">
                        <Button color="secondary" style={{ width: '100%', marginRight: 10 }} onClick={handleStillHere}>Confirm</Button>
                    </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
  
}


const mapStateToProps = (state) =>({
    isAuthenticated: state.user.isAuthenticated
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
      userLogout
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TimedOutPromptModal)