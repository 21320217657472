/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody, Button, Collapse } from 'reactstrap';
import moment from 'moment';

import ClientInactiveSIMList from './ClientInactiveSIMList';
import SelectCompanyInventory from './PurchaseSIM/SelectCompanyInventory';
import OrderSummary from './PurchaseSIM/OrderSummary';
import SelectPayment from './PurchaseSIM/SelectPayment';
import ConfirmPay from './PurchaseSIM/ConfirmPay'
import SelectClientInventory from './ActivationSIM/SelectClientInventory';
import PlanSelectList from './PlanSelectWizard/PlanSelectList';
import PlanSelectModal from './PlanSelectWizard/PlanSelectModal';
import SelectEndUser from './ActivationSIM/SelectEndUser';
import ClientSelectSKU from './ClientSelectSKU';
import NameDevice from './NameDevice/NameDevice';
import ClientInventoryAddDevice from './ClientInventoryAddDevice';
import { toast } from "react-toastify";
import { hasRole } from '../../../factories/auth';

import { PowerIcon, CartPlusIcon } from 'mdi-react';

class ClientInactiveSIMBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClick: false,
            step: 1,
            cart: [],
            selectedPlan: null,
            showPlanModal: false,
            mapSimToPlan: {},
            payByOption: "",
            comment: "",
            isPaidOff: false,
            selectedPaymentAccount: null,
            checkoutResult: null,
            orderSummary: [],
            SKU_type: -1,
            SKU: null,
            showModal: false

        }
    }
    componentDidMount() {
    }
    // Upload =================================================================================
    onDeviceOpen = () => {
        this.setState({
            showModal: true
        })
    }
    onDeviceClose = () => {
        this.setState({
            showModal: false
        })
    }
    // Activation Steps========================================================================

    /**
     * Start Activation Process
     */
    handleActivateClick = () => {
        this.setState({
            isClick: "activation",
            step: 1
        })
    }
    // Buy Steps==============================================================================

    /**
     * Start Activation Process
     * 
     */
    /*
    handleBuyClick = () => {
        this.setState({
            isClick: "purchase",
            step: 1
        })
    }
    */
    // Shared Steps==========================================================================

    /**
     * handle step 1 Activation/Purchase select row
     */
    handleSelectInventoryRow = (row, isSelected, e) => {
        var tempCart = this.state.cart;
        if (isSelected) {
            tempCart.push({ ...row });
            this.setState({ cart: tempCart });
        }

        else {
            for (var index = 0; index < tempCart.length; index++) {
                if (tempCart[index].inventoryId == row.inventoryId) {
                    tempCart.splice(index, 1);
                    break;
                }
            }
            this.setState({ cart: tempCart });
        }
    }

    /**
     * Handle step 1 Activation/Purchase select all
     * @param {*} isSelected 
     * @param {*} rows 
     */
    handleSelectInventoryAll = (isSelected, rows) => {
        if (isSelected) {
            this.setState({ cart: [...rows] });
        }
        else {
            this.setState({ cart: [] });
        }
    }

    MapCartToPlan = () => {
        var tempMapSimToPlan = {};
        this.state.cart.forEach(sim => {
            if (sim.selectedPlan != null) {
                if (tempMapSimToPlan[sim.selectedPlan.itemId] != null) {
                    tempMapSimToPlan[sim.selectedPlan.itemId].selectedSim.push(sim)
                }
                else {
                    var tempSelectedPlan = { ...this.state.selectedPlan.selectedSim }; //must make a shadow clone to not affect original Object
                    tempSelectedPlan.selectedSim = sim;
                    tempMapSimToPlan[sim.selectedPlan.itemId] = tempSelectedPlan
                }
            }
        })
        this.setState({
            mapSimToPlan: tempMapSimToPlan
        })

    }
    ClearPlansFromCart = () => {
        this.state.cart.forEach(sim => sim.servicePlan = null)
        this.setState({
            mapSimToPlan: {}
        });
    }
    /**
     * Open 
     * @param {*} plan 
     */
    onOpenPlanModal = (plan) => {
        this.setState({
            selectedPlan: plan,
            showPlanModal: true
        })
    }

    /**
     * Close Plan Modal
     */
    onClosePlanModal = () => {
        this.setState({
            selectedPlan: null,
            showPlanModal: false
        })
    }

    /**
     * 
     */
    handlePlanSelectAll = () => {
        this.state.cart.forEach(sim => sim.servicePlan = null)
        this.state.mapSimToPlan = {};
        var tempCart = [...this.state.cart]
        var tempSelectedPlan = { ...this.state.selectedPlan };
        tempSelectedPlan.selectedSim = tempCart;
        this.state.mapSimToPlan[this.state.selectedPlan.itemId] = tempSelectedPlan;
        this.state.cart.forEach(sim => sim.servicePlan = this.state.selectedPlan);
        this.onClosePlanModal();
    }

    /**
     * 
     * @param {*} selected 
     * @param {*} action 
     */
    handlePlanMultiSelectionChange = (selected, action) => {
        var tempSelectedPlan = { ...this.state.selectedPlan };
        tempSelectedPlan.selectedSim = selected;
        this.state.mapSimToPlan[this.state.selectedPlan.itemId] = tempSelectedPlan;
        if (this.state.mapSimToPlan[this.state.selectedPlan.itemId].selectedSim == null) {
            this.state.mapSimToPlan[this.state.selectedPlan.itemId] = null;
        }

        if (action.action == "select-option") {
            for (var s = 0; s < this.state.cart.length; s++) {
                if (this.state.cart[s].inventoryId == action.option.inventoryId) {
                    this.state.cart[s].servicePlan = this.state.selectedPlan;
                    break;
                }
            }
        }
        else if (action.action == "remove-value") {
            for (var s = 0; s < this.state.cart.length; s++) {
                if (this.state.cart[s].inventoryId == action.removedValue.inventoryId) {
                    this.state.cart[s].servicePlan = null;
                    break;
                }
            }
        }
        else if (action.action == "clear") {
            for (var s = 0; s < this.state.cart.length; s++) {
                if (this.state.cart[s].servicePlan != null) {
                    if (this.state.cart[s].servicePlan.itemId == this.state.selectedPlan.itemId) {
                        this.state.cart[s].servicePlan = null;
                    }
                }
            }
            this.state.mapSimToPlan[this.state.selectedPlan.itemId] = null;
        }
    }

    /**
     * 
     */
    handleNext = () => {
        if (this.state.step == 1 && this.state.SKU_type <= 0) {

            toast.error("Can not proceed without a SKU")
        }
        else if (this.state.step == 2 && this.state.cart.length == 0) {
            toast.error("A SIM Card must be selected")
        }
        else if (this.state.step == 3 && this.state.isClick === "activation") { //when Activing SIM(s), all sims must be activated
            var AllSimsActive = true
            for (var sim of this.state.cart) {
                if (sim.servicePlan == null) {
                    AllSimsActive = false
                    break;
                }
            }
            if (AllSimsActive == false) {
                toast.error("All SIM(s) must have a plan")
            }
            else {
                this.setState(prevState => ({
                    step: prevState.step + 1,
                }));
            }
        }
        else {
            this.setState(prevState => ({
                step: prevState.step + 1,
            }));
        }
    }

    /**
     * 
     */
    handleBack = () => {
        this.setState(prevState => {
            return {
                step: (prevState.step - 1) > 0 ? prevState.step - 1 : 1,
            }
        });
    }

    /**
     * 
     */
    handleMainBack = () => {
        this.setState({
            isClick: false,
            step: 1,
            cart: [],
            SKU_type: -1,
            SKU: null
        });
    }


    /**
     * 
     */
    handlePaymentOptionChange = (payByOption, comment, isPaidOff, selectedPaymentAccount) => {
        this.setState({
            payByOption: payByOption,
            comment: comment,
            isPaidOff: isPaidOff,
            selectedPaymentAccount: selectedPaymentAccount
        });
    }

    /**
     * 
     */
    handleSelectSKU = (SKU_type) => {

        var SKU = this.props.SIMTypeList.find(type => type.itemId == SKU_type);

        this.setState(
            {
                SKU_type: SKU_type,
                SKU: SKU
            }
        )
    }

    /**
     * 
     */
    handleCheckout = () => {
        //check to make sure 1. payment method is selected. 2. any sim is selected to purchase
        let checkedResult = false;
        if (this.state.payByOption === 'CC') {
            //make sure account is selected
            if (this.state.selectedPaymentAccount != null && this.state.selectedPaymentAccount.id != "") {
                checkedResult = true;
            }
            else {
                toast.error("Please select a payment account.");
            }
        }
        else if (this.state.payByOption === 'CHK' || this.state.payByOption === 'ACH') {
            checkedResult = true;
        }
        else {
            toast.error("Please select a payment method.");
        }

        //pass validation 
        if (checkedResult == true) {
            let orderList = [];
            let ordrAmt = 0;
            let selectedAccountId = null;
            if (this.state.payByOption == "CC") {
                selectedAccountId = this.state.selectedPaymentAccount.id;
            }
            this.state.orderSummary.forEach((order, index) => {
                let eachOrder = {
                    "id": 0,
                    "itemId": order.itemId,
                    "itemQty": order.qty,
                    "promoteId": order.promoteId,
                    "itemPrice": order.unitPrice,     //temporarily set to unit price
                    "inventoryId": order.inventoryId,
                    "planItemId": order.planItemId,
                }
                ordrAmt = ordrAmt + order.unitPrice;
                orderList.push(eachOrder);
            })


            let checkoutObj = {
                "ordrAmt": ordrAmt,
                "id": 0,
                "accountId": selectedAccountId,
                "billcycle": 0,
                "payFrequencyId": 1,
                "resellerId": this.props.selectedRow.id,
                "payByOption": this.state.payByOption,
                orderItemDataList: orderList
            }

            //call checkout api 
            this.props.clientCheckout(checkoutObj).then(res => {
                if (res && res.status === 200 && res.data) {
                    // let storeData = res.data[0];
                    if (res.data.error != "ERROR") {
                        //toast.success(res.data.message);
                        this.setState({
                            checkoutResult: res.data,
                        });
                        //go to next page
                        this.handleNext();
                    } else {
                        toast.error(res.data.message)
                    }
                } else if (!res || res.status !== 200) {
                    toast.error(res.data.message)


                }
            });


        }
    }

    //handles confirm and pay
    handleConfirmPay = (orderObj) => {
        //this.props.customerPayOrderViaPEED(orderObj)
        this.props.clientPurchaseOrder(orderObj)
            .then(res => {

                if (res && res.status === 200 && res.data) {
                    // let storeData = res.data[0];
                    if (res.data.statusCode != "500") {
                        toast.success('Order created successfully.');
                        //this.props.getDiscountList({ "companyId": this.props.selectedRow.companyId, "resellerId": this.props.selectedRow.id })
                        this.props.getFullSIMList({ "companyId": this.props.selectedRow.companyId, "resellerId": this.props.selectedRow.id, "customerId": null, "fullInventory": "false" });
                        this.props.getServicePlanItem({ "companyCode": this.props.selectedRow.companyCode, "resellerCode": this.props.selectedRow.resellerCode })
                        this.props.getCustomerInvoiceHistory({ "objId": this.props.selectedRow.id, "fromDate": moment().subtract(1, 'years'), "toDate": moment() })
                        //this.props.getAccountsByEntity({ "companyCode": this.props.selectedRow.companyCode, "resellerId": this.props.selectedRow.id, "customerId": 19 });

                        //clean and return to initial screen
                        this.setState({
                            isClick: false,
                            step: 1,
                            cart: [],
                            selectedPlan: null,
                            showPlanModal: false,
                            mapSimToPlan: {},
                            payByOption: "",
                            comment: "",
                            isPaidOff: false,
                            selectedPaymentAccount: null,
                            checkoutResult: null,
                            selectedCarrier: null
                        });

                    } else {
                        toast.error(res.data.statusDetail)
                    }
                } else if (!res || res.status !== 200) {

                    toast.error('Create invoice error. web service error code: ' + res.status);

                }
            })
    }

    handleActivating = (endUser) => {
        var data = []
        for (var sim of this.state.cart) {
            let req = {
                id: sim.inventoryId,
                customerId: (endUser != null) ? endUser.customer.customerId : null,
                itemPlanId: sim.servicePlan.itemId,
                status: 1,
                deviceName: sim.deviceName,
                promoteId: sim.promoteId
            }
            data.push(req);
        }
        this.props.activateMultipleSIM({ "sims": data }).then(() => {
            this.props.getFullSIMList({ "companyId": this.props.selectedRow.companyId, "resellerId": this.props.selectedRow.id, "customerId": null, "fullInventory": "false" });
        });
        this.setState({
            isClick: false,
            step: 1,
            SKU_type: -1,
            cart: [],
            showPlanModal: false,
            mapSimToPlan: {},
            selectedCarrier: null
        })

    }

    buildOrderSummary = () => {
        if (this.state.cart.length > 0) {
            var tempList = [];
            this.state.cart.forEach(sim => {
                var tempSIM = {
                    itemId: sim.itemId,
                    inventoryId: sim.inventoryId,
                    sku: sim.itemName,
                    description: sim.identifierValue,
                    qty: 1,
                    unitPrice: sim.unitPrice,
                    promoteId: null,
                    totalPrice: sim.unitPrice,
                    itemType: "device",
                    planItemId: (sim.servicePlan != null) ? sim.servicePlan.itemId : null
                }
                tempList.push(tempSIM);
            })
            for (var key in this.state.mapSimToPlan) {
                if (this.state.mapSimToPlan[key] != null) {
                    this.state.mapSimToPlan[key].selectedSim.forEach(pairing => {
                        var tempPlan = {
                            itemId: this.state.mapSimToPlan[key].itemId,
                            inventoryId: pairing.inventoryId,
                            sku: this.state.mapSimToPlan[key].itemName,
                            description: this.state.mapSimToPlan[key].itemDescription,
                            qty: 1,
                            unitPrice: this.state.mapSimToPlan[key].unitPrice,
                            promoteId: null,
                            totalPrice: this.state.mapSimToPlan[key].unitPrice,
                            itemType: "service",
                        };
                        tempList.push(tempPlan);
                    })

                }
            }
            this.setState({ orderSummary: tempList })
        }
    }

    clearOrderSummary = () => {
        this.setState({ orderSummary: [] })
    }

    addDeviceName = (row, cellName, cellValue) => {
        var cart = [...this.state.cart]
        cart.forEach(sim => {
            if (sim.inventoryId == row.inventoryId) {
                sim.deviceName = cellValue
            }
        })
        this.setState({ cart: cart });
    }
    addMultiDeviceName = (name) => {
        var cart = [...this.state.cart]
        cart.forEach(sim => {
            sim.deviceName = name
        })
        this.setState({ cart: cart });

    }
    clearDeviceName = () => {
        var cart = [...this.state.cart]
        cart.forEach(sim => {
            sim.deviceName = null
        })
        this.setState({ cart: cart })
    }

    setMultiDiscount = (id) => {
        var cart = [...this.state.cart]
        cart.forEach(sim => {
            sim.promoteId = id
        })
        this.setState({ cart: cart })
    }
    setDiscount = (itemId, value) => {
        var tempOrderSummary = [...this.state.orderSummary]
        tempOrderSummary.forEach(item => {
            if (item.itemId == itemId) {
                item.promoteId = value
            }
        })
        this.setState({ orderSummary: tempOrderSummary })
        toast.success("discount set")
    }
    uploadInventory = () => {

    }
    render() {
        let options = {
            noDataText: "No Inventory Found"
        }
        return (
            <Card>
                <CardBody className="mainCardBody">

                    <Collapse isOpen={this.state.isClick == false}>
                        {(this.props.isAdmin || hasRole(this.props.userObj,["CLIENTADMIN"])) ?
                            <Button color="custom" onClick={() => { this.handleActivateClick() }}>Activate Device(s)</Button>
                            :
                            null
                        }
                        {(this.props.isAdmin) ?
                            <Button color="custom" onClick={() => { this.onDeviceOpen() }}>Upload Device(s)</Button>
                            :
                            null
                        }
                        {/*<Button color="custom" onClick={() => { this.handleBuyClick() }}><CartPlusIcon style={{ margin: "0px 5px", width: "20px", height: "20px", fill: "white" }} /> Buy Devices(s)</Button>*/}
                        <br />
                        <ClientInventoryAddDevice
                            showModal={this.state.showModal}
                            onDeviceClose={this.onDeviceClose}
                            uploadInventory={this.props.uploadInventory}
                            selectedRow={this.props.selectedRow}
                            getFullSIMList={this.props.getFullSIMList}

                        />
                        <ClientInactiveSIMList
                            isAdmin={this.props.isAdmin}
                            inactiveSIMList={this.props.inactiveSIMList}
                            setSimName={this.props.setSimName}
                            userObj={this.props.userObj}
                            uploadInventory={this.uploadInventory}
                        />
                    </Collapse>

                    <Collapse isOpen={(this.state.isClick === "purchase" || this.state.isClick === "activation") && this.state.step == 1}>
                        <ClientSelectSKU
                            SIMTypeList={this.props.SIMTypeList}
                            inactiveSIMList={this.props.inactiveSIMList}
                            SKU_type={this.state.SKU_type}
                            handleSelectSKU={this.handleSelectSKU}
                            onNext={this.handleNext}
                            onBack={this.handleMainBack}
                        />
                    </Collapse>

                    <Collapse isOpen={this.state.isClick === "activation" && this.state.step == 2}>
                        <SelectClientInventory
                            inactiveSIMList={this.props.inactiveSIMList.filter(sim => sim.itemId == this.state.SKU_type)}
                            selectedCarrier={this.state.selectedCarrier}
                            cart={this.state.cart}
                            handleSelectInventoryRow={this.handleSelectInventoryRow}
                            handleSelectInventoryAll={this.handleSelectInventoryAll}
                            onNext={this.handleNext}
                            onBack={this.handleBack}
                        />
                    </Collapse>

                    <Collapse isOpen={this.state.isClick === "purchase" && this.state.step == 2}>
                        <SelectCompanyInventory
                            companySIMList={this.props.companySIMList}
                            selectedCarrier={this.state.selectedCarrier}
                            cart={this.state.cart}
                            handleSelectInventoryRow={this.handleSelectInventoryRow}
                            handleSelectInventoryAll={this.handleSelectInventoryAll}
                            onNext={this.handleNext}
                            onBack={this.handleBack}
                        />
                    </Collapse>

                    <Collapse isOpen={this.state.step == 3}>
                        <PlanSelectModal
                            onClose={this.onClosePlanModal}
                            showModal={this.state.showPlanModal}
                            cart={this.state.cart} //current shopping cart 
                            selectedPlan={this.state.selectedPlan} //current selected Plan
                            mapSimToPlan={this.state.mapSimToPlan}
                            handlePlanMultiSelectionChange={this.handlePlanMultiSelectionChange}
                            handlePlanSelectAll={this.handlePlanSelectAll}
                        />
                        <PlanSelectList
                            onOpen={this.onOpenPlanModal}
                            ServicePlanList={(this.props.ServicePlanList != null) ? this.props.ServicePlanList.filter(plan => (plan.carrier != null) ? plan.carrier.carrierId == ((this.state.SKU != null) ? this.state.SKU.carrier.carrierId : 0) : false) : []}
                            selectedCarrier={this.state.selectedCarrier}
                            mapSimToPlan={this.state.mapSimToPlan}
                            cart={this.state.cart}
                            isAdmin={this.props.isAdmin}
                            isClick={this.state.isClick}
                            onBack={this.handleBack}
                            clear={this.ClearPlansFromCart}
                            buildOrderSummary={this.buildOrderSummary}
                            onNext={this.handleNext}
                        />
                    </Collapse>
                    <Collapse isOpen={this.state.step == 4}>
                        <NameDevice
                            cart={this.state.cart}
                            addDeviceName={this.addDeviceName}
                            addMultiDeviceName={this.addMultiDeviceName}
                            clearDeviceName={this.clearDeviceName}
                            onNext={this.handleNext}
                            onBack={this.handleBack}
                            isAdmin={this.props.isAdmin}
                            discountList={this.props.discountList}
                            setMultiDiscount={this.setMultiDiscount}
                        />
                    </Collapse>

                    {/* When Consumers are implemented, use this code below */}
                    <Collapse isOpen={this.state.isClick === "activation" && this.state.step == 5}>
                        <SelectEndUser
                            selectedRow={this.props.selectedRow}
                            consumerList={this.props.consumerList}
                            onBack={this.handleBack}
                            onNext={this.handleActivating}

                        />
                    </Collapse>

                    <Collapse isOpen={this.state.isClick === "purchase" && this.state.step == 5}>
                        <OrderSummary
                            orderSummary={this.state.orderSummary}
                            clear={this.clearOrderSummary}
                            discountList={this.props.discountList}
                            setDiscount={this.setDiscount}
                            onBack={this.handleBack}
                            onNext={this.handleCheckout}
                        />
                        <br />
                        <SelectPayment
                            selectedRow={this.props.selectedRow}
                            cart={this.state.cart}
                            accountList={this.props.accountList}
                            handlePaymentOptionChange={this.handlePaymentOptionChange}
                            selectedPaymentAccount={this.state.selectedPaymentAccount}

                        />
                    </Collapse>
                    <Collapse isOpen={this.state.isClick === "purchase" && this.state.step == 6}>
                        <ConfirmPay
                            cart={this.state.cart}
                            mapSimToPlan={this.state.mapSimToPlan}
                            ServicePlanList={this.props.ServicePlanList}
                            onBack={this.handleBack}
                            handleConfirmPay={this.handleConfirmPay}
                            selectedPaymentAccount={this.state.selectedPaymentAccount}
                            purchaseOrder={[]}
                            checkOutResult={this.state.checkoutResult}
                            payByOption={this.state.payByOption}
                            isPaidOff={this.state.isPaidOff}
                            comment={this.state.comment}
                        />

                    </Collapse>
                </CardBody>
            </Card>
        )
    }

}
export default ClientInactiveSIMBoard;