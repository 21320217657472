/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { MapInteractionCSS } from 'react-map-interaction';
import './ZoomableMap.css';

const zoomOut = () => (
  <FontAwesomeIcon icon={faMinus} />
);
const zoomIn = () => (
  <FontAwesomeIcon icon={faPlus} />
);

const Zoomable = props => (
  <MapInteractionCSS showControls={props.controls} disableZoom={props.disableZoom} disablePan={props.disablePan} btnClass="zoomBtn" plusBtnContents={zoomIn()} minusBtnContents={zoomOut()} value={props.value}>
    {props.children}
  </MapInteractionCSS>
);

Zoomable.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Zoomable;

