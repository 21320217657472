/* eslint-disable */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { getCurrentUser, hasRole } from '../../factories/auth';


import { changeThemeToDark, changeThemeToLight, changeThemeToCustom, changeThemeToDefault } from '../../redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/sidebarActions';
import { SidebarProps } from '../../shared/prop-types/ReducerProps';

class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,

  };

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };
  changeToCustom = () => {
    this.props.dispatch(changeThemeToCustom());
  };
  changeToDefault = () => {
    this.props.dispatch(changeThemeToDefault());
  };

  render() {
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': this.props.sidebar.collapse,
    });

    let userObj = getCurrentUser();
    // console.log("===userObj", userObj);
    var route = "";
    var numOfApps = 0;
    if (userObj) {
      //console.log('route',route)
      if (!userObj.sidebarContent.includes("Dashboard")) {
        if(userObj.sidebarContent.includes("Power Management")) {
          numOfApps += 1;
          route = "/PowerManagement";
        }
        if(userObj.sidebarContent.includes("Opg App")) {
          numOfApps += 1;
          route = "/OpgApp";
        }
        if (userObj.sidebarContent.includes("Snow App")) {
          numOfApps += 1;
          route = "/SnowApp";
        }
        if (userObj.sidebarContent.includes("Garage App")) {
          numOfApps += 1;
          route = "/ParkingApp";
        }
        if (userObj.sidebarContent.includes("Occupancy App")) {
          numOfApps += 1;
          route = "/OccupancyApp";
        }
        if (userObj.sidebarContent.includes("Weather App")) {
          numOfApps += 1;
          route = "/WeatherApp";
        }
        if (userObj.sidebarContent.includes("Telecom App")) {
          numOfApps += 1;
          route = "/TelecomApp";
        }
        if (userObj.sidebarContent.includes("Smart Building")) {
          numOfApps += 1;
          route = "/surveillance";
        }
        if (userObj.sidebarContent.includes("Billing App")) {
          numOfApps += 1;
          route = "/BillingApp";
        }
      } else {
        numOfApps +=2
        route= "/Dashboard"
      }
    }
    return (
      <div className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          changeSidebarVisibility={this.changeSidebarVisibility}
          changeToCustom={this.changeToCustom}
          changeToDefault={this.changeToDefault}
          route={route}
        />
        <Sidebar
          sidebar={this.props.sidebar}
          changeToDark={this.changeToDark}
          changeToLight={this.changeToLight}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          route={route}
          numOfApps = {numOfApps}
        />
      </div>
    );
  }
}

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
}))(Layout));
