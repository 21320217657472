/* eslint-disable */

import {   
    FENCE_LOAD_DEVICE_SUCCEED,
    FENCE_LOAD_DEVICE_FAILED,
    FENCE_LOAD_FENCE_SUCCEED,
    FENCE_LOAD_FENCE_FAILED,
    FENCE_UPDATE_FENCE_FAILED,
    FENCE_UPDATE_FENCE_SUCCEED,
    FENCE_DELETE_FENCE_SUCCEED,
    FENCE_DELETE_FENCE_FAILED,
    FENCE_TOGGLE_ERROR,
    FENCE_TOGGLE_SUCCESS,
    FENCE_MSG_TOGGLE_OFF,
    FENCE_MSG_TOGGLE_ON,
    FENCE_UPDATE_FENCE_OBJ_SUCCEED,
    FENCE_UPDATE_FENCE_OBJ_FAILED,
} from '../../constants/actionTypes';

const initState = {    
    currentDeviceTypeId: '',
    currentDeviceId:'',
    currentDeviceTypeList:[],
    currentDeviceList:[],
    
    currentAssetList: [],
    currentAssetGroupList: [],

    fenceData: [],
    fenceObj: {},
    error: 0,
    message: '',
    type: '',
    successvisible: false,
    errorvisible: false,
    messagevisible: false,
    messagetype: '',
}

export default function(state = initState, action){
    //  
    switch(action.type){
        case FENCE_LOAD_DEVICE_SUCCEED:
            console.log("Fence Device")
            console.log(action.payload)
            return {
                ...state,
                currentDeviceList: action.payload,
                error:0,
                message: 'SUCCESS',
                type: action.type
            }
        case FENCE_LOAD_DEVICE_FAILED:
            return{
                ...state,
                error: 1,
                message: action.payload,
                type: action.type
            }
        case FENCE_LOAD_FENCE_SUCCEED:
         
         
            return{
                ...state,
                fenceData: action.payload,
                type: action.type,
                error: 0,
                message: 'SUCCESS',
            }
        case FENCE_LOAD_FENCE_FAILED:
            return{
                ...state,
                error: 1,
                message: action.error,
                type: action.type
            }
        case FENCE_UPDATE_FENCE_SUCCEED:
            return{
                ...state,
                // fenceData: action.payload,
                type: action.type,
                error: 0,
                message: 'SUCCESS ---',
                successvisible: true,
                messagevisible: true,
                messagetype: 'success',
            }
        case FENCE_UPDATE_FENCE_FAILED:
            return{
                ...state,
                error: 1,
                message: action.error,
                type: action.type,
                errorvisible: true,
                messagevisible: true,
                messagetype: 'danger',
            }
        case FENCE_DELETE_FENCE_SUCCEED:
            return{
                ...state,
                // fenceData: action.payload,
                type: action.type,
                error: 0,
                message: 'SUCCESS',
                successvisible: true,
            }
        case FENCE_UPDATE_FENCE_OBJ_SUCCEED:
             
             
             
            return{
                ...state,
                fenceObj: action.payload,
                type: action.type,
                error: 0,
                message: 'SUCCESS',
                successvisible: false,
            }
        case FENCE_UPDATE_FENCE_OBJ_FAILED: 
            return{
                ...state,
                fenceObj: {},
                error: 1,
                message: action.error,
                type: action.type,
                errorvisible: false,
            }
        case FENCE_DELETE_FENCE_FAILED:
            return{
                ...state,
                error: 1,
                message: action.error,
                type: action.type,
                errorvisible: true,
            }
        case FENCE_TOGGLE_ERROR:
            return{
                ...state,
                errorvisible: false,
            }
        case FENCE_TOGGLE_SUCCESS:
            return{
                ...state,
                successvisible: false,
            }
        case FENCE_MSG_TOGGLE_OFF:
            return{
                ...state,
                messagevisible: false,
            }
        case FENCE_MSG_TOGGLE_ON:
            return{
                ...state,
                messagevisible: true,
            }
        default:
            return {...state};
    }
}