/* eslint-disable */
import React, { Component, Fragment } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { getCurrentUser } from "../../../factories/auth";
import Select from "react-select";
import DeviceConfigMainBoard from "./DeviceConfigMainBoard";
import { type } from "jquery";
import { loadClasses, loadAssets } from "../../../redux/actions/assetActions";
import {
  loadDevice,
  loadDeviceInfo,
  getAssetClassAttributeAjax,
} from "../../../redux/actions/mapActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCompanyList } from "../../../redux/actions/userAction";
import DeviceConfigList from "./DeviceConfigList";
import {
  loadMessageDecode,
  postMessageDecode,
  addToMessageDecode,
  updateOneMessageDecode,
} from "../../../redux/actions/decoderActions";
import { toast } from "react-toastify";
import classNames from "classnames";

class MainBoard extends Component {
  constructor(props) {
    super(props);
    let index = localStorage.getItem('activeTab');
    this.state = {
      currentDeviceName: "",
      activeTab: index ? index : "1",
    };
    this.userObj = getCurrentUser();
  }

  componentDidMount() {
    this.props.loadClasses();
    this.props.loadDevice();
    this.props.loadDeviceInfo();
    this.props.loadAssets();
    this.props.getCompanyList();
    this.props.loadMessageDecode();
  }

  fetchDevicesOfType = (type) =>
    this.props.currentDeviceList
      .filter((device) => device.devicetype === type)
      .map((device) => {
        return {
          id: device.deviceid,
          name: device.devicename,
          namealias: device.devicenamealias,
        };
      });

  onMapShow = () => {};

  toggleTab = (tab) => {
    localStorage.setItem("activeTab", tab);
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          mapShow: tab == "2",
        },
        () => {
          if (this.state.activeTab == "2") {
            this.onMapShow();
          }
        }
      );
    }
  };
  addMessageDecodeToStore = (data) => {
    this.props.postMessageDecode(data).then((res) => {
      if (res && res.status === 200 && res.data && res.data.length > 0) {
        let storeData = res.data[0];
        this.props.addToMessageDecode(storeData);
        this.props.loadMessageDecode();
      } else if (!res || res.status !== 200) {
        toast.error("The message decode cannot be added.");
      }
    });
  };

  updateMessageDecodeOnStore = (data, successCallback, errorCallback) => {
    let postData = {
      ...this.props.messageDecodeList.find(
        ({ messageDecodeItemId }) =>
          messageDecodeItemId == data.messageDecodeItemId
      ),
      ...data.data,
    };
    this.props.postMessageDecode(postData).then((res) => {
      if (res && res.status === 200 && res.data && res.data.length > 0) {
        let resData = res.data[0];
        this.props.updateOneMessageDecode({
          messageDecodeItemId: resData.messageDecodeItemId,
          data: resData,
        });
        this.props.loadMessageDecode();
        if (successCallback) {
          successCallback();
        }
      } else {
        if (res && res.stats === 406) {
          toast.error("Error 406");
        } else {
          toast.error("Errors occured during deleting the message decode.");
        }
        if (errorCallback) {
          errorCallback();
        }
      }
    });
  };

  render() {
    // console.log("===this.props", this.props.asset);
    return (
      <Col md={12}>
        <NavTabs activeTab={this.state.activeTab} toggleTab={this.toggleTab} />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <DeviceConfigList
              messageDecodeList={this.props.messageDecodeList}
              assetClassList={this.props.asset.assetClassList}
              deviceList={this.props.asset.currentDeviceList}
              addMessageDecodeToStore={this.addMessageDecodeToStore}
              updateMessageDecodeOnStore={this.updateMessageDecodeOnStore}
              isLoading={this.props.messageDecodeLoading}
            />
          </TabPane>
        </TabContent>
        <CardBody></CardBody>
      </Col>
    );
  }
}

function NavTabs({ activeTab, toggleTab }) {
  return (
    <Nav tabs style={{ border: "none" }}>
      <NavItem style={{ cursor: "pointer" }}>
        <NavLink
          className={classNames({ active: activeTab === "1" })}
          onClick={() => {
            toggleTab("1");
          }}
        >
          <h4>Message Decode Itmes</h4>
        </NavLink>
      </NavItem>
    </Nav>
  );
}

function mapStateToProps(state) {
  const deviceTypes = state.mapboard.types;
  const companyList = state.user.companyList;
  const asset = state.asset;
  const props = {asset, companyList, ...state.messagedecode};
  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCompanyList,
      loadMessageDecode,
      postMessageDecode,
      loadClasses,
      loadAssets,
      loadDevice,
      loadDeviceInfo,
      getAssetClassAttributeAjax,
      updateOneMessageDecode,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainBoard);
