/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Card, CardBody, Col, Row, Button, Container, Input } from 'reactstrap';

import { bindActionCreators } from 'redux';
//import { getFullInventoryList } from '../../../../../redux/actions/inventoryActions';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,LabelList } from 'recharts';
import { Label } from 'react-bootstrap';
import { CSVLink, CSVDownload } from "react-csv";
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
class ClientUsage extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            refreshTime: null,   //the time this component gets freshed.
            renderOption: "Monthly",
            data:null
        }
    }
    static getDerivedStateFromProps(newProps, prevState){
        if (newProps.refreshTime && newProps.refreshTime !== prevState.refreshTime){    
            return ({
                refreshTime: newProps.refreshTime,
                loading: true,
                data:null
            });
        }
        return null;
    }

    componentDidMount() {
        //alert("did mount");
        //load data, api call initial mount
        this.getClientUsage();
    }

  
    
    componentDidUpdate(prevProps, prevState) {
        //state change 
        if (prevState.refreshTime !== this.state.refreshTime) {
            console.log('pokemons state has changed.')
            //refresh time changed. call api to load data
            this.getClientUsage();
        }
    }

    componentWillUnmount(){
        //reset
        //alert("un mount");
        
    }

    getClientUsage=()=>{
        debugger;
        //calculate from date 12 month (including current month will be 13 month)
     
        let startDate = moment([this.props.refreshTime.getFullYear(), this.props.refreshTime.getMonth(), 1]).subtract(13, 'months'); 
         
        //this.prop.getSimUsage
        //startDate,enDate,resellerCode,simIdentifier,simIdentifierValue,groupBy
        this.props.getClientUsage({
            customerId: this.props.rowData.customerId
        })
        .then(res => {
            if (res && res.status === 200 && res.data ){
                if (res.data.message == "Ok") {
                    //toast.success(res.data.message);
                    this.setState({
                        data:res.data.clientUsage,
                        loading:false,

                    });
                
                }else{
                    //toast.error(res.data.message)
                     
                    this.setState({
                        data:[]
                    });
                }               
            }else if (!res || res.status !== 200){
                 
                this.setState({
                    data:[]
                });
            }           
        });
    }
    formatXAxis = (tickItem) => { 

        return moment(tickItem).format("YYYY-MM")
     }

     usageFormatter = (usageNum)=>{
        debugger;
        let formatedNum = 0;
        if(usageNum!=null){
          formatedNum = usageNum;
          if(usageNum>=1024){
            formatedNum = (usageNum/1024).toFixed(2);
            if(formatedNum>=1024){
              formatedNum = formatedNum/1024;
              if(formatedNum>=1000){
                formatedNum = (formatedNum/1000).toFixed(2)+"TB";
              }
              else{
                formatedNum = formatedNum+"GB";
              }
            }
            else{
              formatedNum = formatedNum+"MB";
            }
          }
          else{
            formatedNum = formatedNum+"KB";
          }
          return formatedNum;
        }
        return "";
      }

    renderTableGraph=()=> {        

        if(this.state.data==undefined||this.state.data==null){
            return <div style={{textAlign:"center"}}>nothing available.</div>;
        }
        let plotData = [];
        let tableData = {};
        plotData=[];
        if(this.state.data!=null){
            tableData["totalCurrentMonthUsage"] = this.usageFormatter(this.state.data["totalCurrentMonthUsage"]);
            tableData["totalLast3MonthUsage"] = this.usageFormatter(this.state.data["totalLast3MonthUsage"]);
            tableData["totalLast6MonthUsage"] = this.usageFormatter(this.state.data["totalLast6MonthUsage"]);
            tableData["totalLast12MonthUsage"] = this.usageFormatter(this.state.data["totalLast12MonthUsage"]);
            tableData["totalLifetime"] = this.usageFormatter(this.state.data["totalLifetime"]);
            plotData=[
                {
                    "name":"Current Month",
                    "Usage":this.state.data["totalCurrentMonthUsage"],
                    "text":this.usageFormatter(this.state.data["totalCurrentMonthUsage"])
                },
                {
                    "name":"3 Months",
                    "Usage":this.state.data["totalLast3MonthUsage"],
                    "text":this.usageFormatter(this.state.data["totalLast3MonthUsage"])
                },
                {
                    "name":"6 Months",
                    "Usage":this.state.data["totalLast6MonthUsage"],
                    "text":this.usageFormatter(this.state.data["totalLast6MonthUsage"])
                },
                {
                    "name":"12 Months",
                    "Usage":this.state.data["totalLast12MonthUsage"],
                    "text":this.usageFormatter(this.state.data["totalLast12MonthUsage"])
                },
                {
                    "name":"Life Time",
                    "Usage":this.state.data["totalLifetime"],
                    "text":this.usageFormatter(this.state.data["totalLifetime"])
                }
            ];
        }
        tableData=[tableData];
        let activeIndex = 1;
        const aspect = 15 / 3
        return (
            
            <div style={{textAlign:"center"}}>
            <BootstrapTable data={ tableData }>
            <TableHeaderColumn dataField='totalCurrentMonthUsage' dataAlign='center' >Current Month</TableHeaderColumn>
                <TableHeaderColumn dataField='totalLast3MonthUsage' dataAlign='center' isKey={ true }>3 Months</TableHeaderColumn>
                <TableHeaderColumn dataField='totalLast6MonthUsage' dataAlign='center'>6 Months</TableHeaderColumn>
                <TableHeaderColumn dataField='totalLast12MonthUsage' dataAlign='center'>12 Months</TableHeaderColumn>
                <TableHeaderColumn dataField='totalLifetime' dataAlign='center'>Life Time</TableHeaderColumn>
            </BootstrapTable>
    
            <ResponsiveContainer height={250} width="98%" maxWidth={300}   >
                <AreaChart
                    title={"Data Usage Chart Per Client"}
                    data={plotData}
                    width={250} height={230}
                    margin={{
                    top: 17, right: 35, left: 0, bottom: 15,
                    }}
                >
                    {/* <XAxis dataKey="name" tickFormatter={this.formatXAxis} tickLine={false}  label={{value:"Data Usage Over The Time",offset:"-12",position:"insideBottom"}}> */}
                    <XAxis dataKey="name" label={{value:"Data Usage Over The Time",offset:"-12",position:"insideBottom"}}>
                    </XAxis>
                    <YAxis  />
                    <CartesianGrid vertical={false} />
                    <Tooltip />

                    <Area type="monotone" dataKey="Usage" stroke="#D5B56E" fill="#F5EBCF" fillOpacity={0.8} >
                        <LabelList dataKey="text" position="top" />
                    </Area>
                </AreaChart>
              </ResponsiveContainer>
              <CSVLink data={tableData} filename={"Usage Over The Time.csv"}>Download CSV</CSVLink>
            
            </div>
            
        );
    }

    

  

    render() {
        //load 
        if(this.state.loading==true&&this.state.data==null){
            return (
                <div style={{textAlign:"center"}}>
                <Spinner 
                    as="span"
                    animation="border"
                    className="spinnerHelix" 
                    //variant="primary"  
                    // size="sm"
                    // role="status"
                    // aria-hidden="true"
                    style={{display: 'inline-block', margin: 'auto', color:"#D5B56E!important" }}
                
                /> 
                </div>
            );
        }
        else{
            return (this.renderTableGraph());
        }

        
    }


}

export default ClientUsage;
