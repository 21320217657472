/* eslint-disable */
import { image } from "d3-fetch";
import React, {useEffect} from "react";
const IconElement = (props) => {
    useEffect(() => {
        if(props.data){
            console.log('Icon DATA: ', props.data)
        }
    }, []);
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox={`0 0 ${props.width} ${props.height}`} aria-labelledby="title">
            {props.path && props.data == null && <path d={props.path}/>}
            {props.data && <image href={props.data} width={props.width} />}
        </svg>
    );
}
export default IconElement;
