import React from 'react';
import { Container } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import DashboardCard from './components/DashboardCard';

const DashBoardIndex = () => (
  <Container className="dashboard">
    {/* <Row>
      <Col md={12}>
        <h3 className="page-title">Dashboard</h3>
      </Col>
    </Row> */}
    {/* <Row> */}
    <DashboardCard />
    {/* </Row> */}
  </Container>
);

export default DashBoardIndex;
