/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import RuleSetCard from './RuleSetCard'; 
import RuleListTable from './RuleListTable';
import NewRuleModal from './NewRuleModal';
import {loadRuleDeviceType, loadRuleDevice, getRuls, upsertRule, deleteRule} from '../../../redux/actions/ruleActions';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class RuleBoard extends Component{
    constructor(props){
        super(props);
        this.state = {
            isNew: null,
            showEdieCard: false,
            modalVisible: false,
            showRuleEdieCard: false,
            currentDeviceTypeId : '5cf828390499f50b995ae851',
            currentDeviceTypeName: 'Oyster',
            currentDeviceId : '',
            currentDeviceName:'',
            devices: [{id:1, name: 'device1'}, {id:2, name: 'device2'}, {id:3, name: 'device3'}],
            rules: [
                {
                    ruleid:1,
                    rulename:'rule 1',
                    devicetypeid:'',
                    devicetypename:'',
                    deviceid:'',
                    devicename:'',
                    createtime: new Date(),
                    rule:[
                        {
                            index:0,
                            attributeid: 1,
                            attributename:'Speed',
                            operator:1,
                            value:10,
                            relation: null
                        },
                        {
                            index:1,
                            attributeid: 2,
                            attributename:'Temperature',
                            operator:2,
                            value:3,
                            relation: 1
                        }
                    ]
                }
            ],
            ruleattributes:[
                                {attributeid:1, attributename:'Speed'},
                                {attributeid:2, attributename:'Temperature'},
                                {attributeid:3, attributename:'Hold'}
                            ],
            currentrule:  {
                
            },
            currentruleid: 0,
        };

        this.currentInput = '';
        
        this.toggle = this.toggle.bind(this);
        // this.handleInputOnchang = this.handleInputOnchang.bind(this);
        // this.handleInputClick= this.handleInputClick.bind(this);
        // this.reenderEditCard = this.reenderEditCard.bind(this);
        // this.handleThemeDropdownChange = this.handleThemeDropdownChange.bind(this);
    }

    componentDidMount(){
        this.props.loadRuleDeviceType();
        this.props.loadRuleDevice({devicetype: this.state.currentDeviceTypeId}); 
        this.props.getRuls({devicetypeid: this.state.currentDeviceTypeId}); 
        // this.props.loadDivceMapData({devicetype:this.props.currentDeviceTypeId});
        // this.props.loadDeviceAttr({devicetypeid:this.props.currentDeviceTypeId});
    }
    static getDerivedStateFromProps(props, state){
        return({
            rules: props.rules,
        });
    }

    // -------------------------------------------------------------------------------
    handleDeviceTypeDropdownChange = (e) => {
        let selectedValue = e.target.value;
        let index = e.target.selectedIndex;
        let name = e.target.options[index].text;
        // alert(name);
        this.props.loadRuleDevice({devicetype: e.target.value});
        this.setState(
            {
                currentDeviceTypeId: selectedValue,
                currentDeviceTypeName: name                
            }
        )
    }

    handleDeviceDropdownChange = (e) => {
        let selectedValue = e.target.value;
        let index = e.target.selectedIndex;
        let name = e.target.options[index].text;
        // alert(name);
        this.setState(
            {
                currentDeviceId: selectedValue,
                currentDeviceName: name                
            }
        )
    }

    handllSearchOnClick = (e) => {
        this.props.getRuls({devicetypeid: this.state.currentDeviceTypeId, deviceid: this.state.currentDeviceId}); 
    }

    renderTop(){
        return(
            <Col md={12}>
                <Card>
                <CardBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>

                            {/* <Col md={1} style={{textAlign: 'right' }}>
                                <Label for="deviceType"   style={{ display: 'inline'}}>Device Type</Label>
                            </Col> */}
                            <Col md={3}>
                                <Label for="deviceType"   style={{ display: 'inline'}}>Device Type</Label>
                                <Input type="select" id="deviceType" name="deviceType" width="60%" value = {this.state.currentDeviceTypeId} onChange={this.handleDeviceTypeDropdownChange}>
                                    {this.props.currentDeviceTypeList && this.props.currentDeviceTypeList.map((type,i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))}
                                </Input>
                            </Col>

                            <Col md={3}>
                                <Label for="messageType"  style={{ display: 'inline'}} >Message Type</Label>
                                <Input type="select" id="messageType" name="messageType" width="100%" value={this.state.currentMessageTypeName} >
                                    <option value = {this.state.currentMessageTypeName}>{this.state.currentMessageTypeName}</option>
                                </Input>
                            </Col>

                            {/* <Col md={1} style={{textAlign: 'right' }}>
                                <Label for="theme" style={{ display: 'inline'}} >Theme</Label>
                            </Col> */}
                            
                            <Col md={3}>
                                <Label for="theme" style={{ display: 'inline'}} >Devices</Label>
                                <Input type="select" id="theme" name="theme" width="90%" value = {this.state.currentDeviceId} onChange={this.handleDeviceDropdownChange} >      
                                    <option value = {0}>All</option>                
                                    {this.props.currentDeviceList.map((item,i) => {
                                        return(
                                            <option key={i} value={item.deviceid}>{item.devicename}</option>
                                        )
                                    })}              
                                </Input>
                            </Col>
                            <Col md={2} style={{ textAlign: 'left',   justifyContent: 'center',alignItems: 'center' }}>
                                <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                                {/* <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={() => {this.setModalVisible(true)}}>
                                    New Theme
                                </Button> */}
                                 <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handllSearchOnClick} >
                                    Search
                                </Button>
                            </Col>
                    </Row>
                </CardBody>
                </Card>
            </Col>
        )
    }

    // ----------------------------------------------------------------------------------------------
    handleRuleListRowClick = (row) => {
    //   alert(row.ruleid);
        let id = row.ruleid;
        let selectedRule = this.state.rules.find((item) => {
            return(item.ruleid === id);
        });
        // alert(selectedRule.rulename);
        let currentrule = Object.assign({},selectedRule);
        // currentrule.rule = currentrule.rule.slice();
        currentrule.rule = JSON.parse(JSON.stringify(currentrule.rule));
        this.setState({
            currentrule: currentrule,
            currentruleid: id,
            showRuleEdieCard: true,
        });
    }

    handleRowDeleteClick = (row) => {
        let id = row.ruleid;
        // this.setState((prevState) => ({
        //     rules: prevState.rules.filter((v,i,a)=>(v.ruleid !== id)),
        //     currentrule: {},
        //     currentruleid: 0,
        //     showRuleEdieCard: false,
        // }));
        let selectedRule = this.state.rules.find((item) => {
            return(item.ruleid === id);
        });
        this.props.deleteRule(selectedRule);
        this.setState({
            currentrule: {},
            showRuleEdieCard: false,
            currentruleid: 0,
        });
    }

    handleRuleAddnewClick = (e) => {
        this.setState({
            modalVisible: true
        });
    }
    renderRuleList(){
        return(
            <RuleListTable rules = {this.state.rules} 
                            handleRowClick = {this.handleRuleListRowClick} 
                            handleAddNew = {this.handleRuleAddnewClick}
                            handleRowDeleteClick = {this.handleRowDeleteClick}
            />
        )
    }
    // ----------------------------------------------------------------------------------------------
    handleAddRuleAttr = (e, name) => {
        // alert(e.target.value);
        // alert(name);
        let relation = null;
        if (this.state.currentrule.rule && this.state.currentrule.rule.length > 0){
            relation = 1;
        }
        let newattr = {
            index:0,
            attributeid: e.target.value,
            attributename: name,
            operator:1,
            value:0,
            relation: relation
        };

        this.setState(prevState => {
            let currentrule = prevState.currentrule;
            let length = currentrule.rule.push(newattr);
            newattr.index = length -1;
            return({
                currentrule: currentrule
            })
        });

    }

    handleAttrRemoveClick = (e) => {
        let index = e.target.value;
        // alert(index);
        this.setState(prevState => {
            let currentrule = prevState.currentrule;
            let removed = currentrule.rule.splice(index,1);
            if (currentrule.rule.length>0){
                currentrule.rule[0].relation = null;
            }
            return({
                currentrule: currentrule
            })
        });
    }

    handleRuleSetSubmit = (e) => {
        this.setState(prevState => {
            let newrules = prevState.rules.slice();
            let currentrule = prevState.currentrule;
            let index = newrules.findIndex((item) => (item.ruleid === currentrule.ruleid));
            // if (index > -1) {
            //     newrules[index] = currentrule;
            // }
            // else {
            //     currentrule.ruleid = newrules.length + 1;
            //     newrules.push(currentrule);
            // }

            this.props.upsertRule(currentrule);
            return({
                rules: newrules,
                currentrule: {},
                showRuleEdieCard: false,
                currentruleid: 0,
            });
        });
    }
    handleRuleSetCancle = (e) => {
        this.setState(prevState => {
            let selectedRule = prevState.rules.find((item) => {
                return(item.ruleid === prevState.currentruleid);
            });
            let currentrule;
            if (selectedRule){
                currentrule = Object.assign({},selectedRule);
                // currentrule.rule = currentrule.rule.slice();
                currentrule.rule = JSON.parse(JSON.stringify(currentrule.rule));
            }
            else{
                currentrule =  {
                    ruleid: 0,
                    rulename:name,
                    devicetypeid:'',
                    devicetypename:'',
                    deviceid:'',
                    devicename:'',
                    createtime: new Date(),
                    rule:[],
                };
            }
            

            return({
                currentrule:currentrule,
            });
        });
    }

    handleValueChange = (e, ruleItem, index) => {
        // ruleItem.value = e.target.value;
        // alert(index);
        // alert(this.state.currentrule.rule[index].value);
        let value =  e.target.value;
        this.setState(prevState => {
            let currentrule = prevState.currentrule;
             
             
            // alert(value);
            currentrule.rule[index].value = value;
            return ({
                currentrule: currentrule,
            })
        })
    }

    handleOperatorChange = (e, ruleItem) => {
        ruleItem.operator = e.target.value;
        this.setState(prevState => ({
            currentrule: prevState.currentrule,
        }))
    }

    handleRelationChange = (e, ruleItem) => {
        ruleItem.relation = e.target.value;
        this.setState(prevState => ({
            currentrule: prevState.currentrule,
        }))
    }

    renderRuleEditCard(){
        if(this.state.showRuleEdieCard){
            // alert('yes');
            return(
                <RuleSetCard rule = {this.state.currentrule}
                            ruleattributes = {this.state.ruleattributes}
                            handleAddRuleAttr = {this.handleAddRuleAttr}
                            handleAttrRemoveClick = {this.handleAttrRemoveClick}
                            handleRuleSetSubmit = {this.handleRuleSetSubmit}
                            handleRuleSetCancle = {this.handleRuleSetCancle}
                            handleValueChange = {this.handleValueChange}
                            handleOperatorChange = {this.handleOperatorChange}
                            handleRelationChange = {this.handleRelationChange}
                />
            )
        }else{
            return null;
        }
    }

    // ----------------------------------------------------------------------------------------------

    // handleInputOnchang(e){
    //      
    //     this.currentInput = e.target.value;
    // }

    // handleInputClick(){
    //     // alert(this.currentInput);
    //     let value = this.currentInput
    //     let showCard = (value !== '');
    //     this.setState(prevState => ({
    //         modalVisible: !prevState.modalVisible,
    //         currentThemeName: value,
    //         showEdieCard: showCard
    //     }));
    // }

    // setModalVisible(visible) {
    //     // alert(visible);
    //     this.setState({modalVisible: visible});
    //     // alert(this.state.modalVisible);
    // }



    // renderModal(){
    //     return(
    //             <Modal animationType="slide" transparent={false} isOpen={this.state.modalVisible}
    //                     toggle={this.toggle}>
    //                 <ModalHeader toggle={this.toggle}>New Theme</ModalHeader>
    //                 <ModalBody>
    //                     <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
    //                             <Col md={3} style={{textAlign: 'left' }}>
    //                                 <Label for="deviceType" >Theme Name</Label>
    //                             </Col>
                                
    //                             <Col md={8}>
    //                                 <Input type="text" id="deviceType" name="deviceType" width="90%" onChange={this.handleInputOnchang} >                                    
    //                                 </Input>
    //                             </Col>
    //                     </Row>
    //                 </ModalBody>
                    
    //                 <ModalFooter>
    //                     <Button color="custom" onClick={this.handleInputClick}>Add</Button>{' '}
    //                     <Button color="custom" onClick={this.toggle}>Cancel</Button>
    //                 </ModalFooter>
    //             </Modal>
    //     )
    // }
    //-----------------------------------------------------------------------------------------------
    toggle() {
        this.setState(prevState => ({
            modalVisible: !prevState.modalVisible
        }));
    }
    onAddClick = (name) =>{
        // alert(name);
        this.setState({
            currentrule:  {
                ruleid: 0,
                rulename:name,
                devicetypeid:this.state.currentDeviceTypeId,
                devicetypename:this.state.currentDeviceTypeName,
                deviceid:this.state.currentDeviceId,
                devicename:this.state.currentDeviceName,
                createtime: new Date().toISOString(),
                rule:[],
            },
            currentruleid: 0,
            showRuleEdieCard: true,
        });
    }
    
    renderNewModal() {
        return(
            <NewRuleModal 
                modalSetVisible={this.state.modalVisible}
                currentDeviceTypeList={this.props.currentDeviceTypeList} 
                currentDeviceList={this.props.currentDeviceList}
                currentDeviceTypeId = {this.state.currentDeviceTypeId}
                // currentDeviceTypeName = {this.state.currentDeviceTypeName}
                currentDeviceId = {this.state.currentDeviceId} 
                // currentDeviceName = {this.state.currentDeviceName}
                // loadRuleDevice = {this.props.loadRuleDevice}
                toggle={this.toggle} 
                handleDeviceTypeDropdownChange = {this.handleDeviceTypeDropdownChange}
                handleDeviceDropdownChange = {this.handleDeviceDropdownChange}
                onAddRule={this.onAddClick}/>
        )
    }

    // ----------------------------------------------------------------------------------------------
    render(){
        return(
            <div style={{width: '100%'}}>
                {this.renderNewModal()}
                {this.renderTop()}
                {this.renderRuleList()}
                {this.renderRuleEditCard()}
            </div>
        )
    }
}

// export default RuleBoard;

function mapStateToProps(state) {
    //  
    //  

    const {currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, error, message, type, rules} = state.rule;
    const props = {currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, error, message, type, rules} ;
        
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadRuleDeviceType, loadRuleDevice, getRuls, upsertRule, deleteRule}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleBoard);
