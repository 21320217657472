/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { EditTextButton } from '../../../../shared/components/Button/IconButton';
import PlusIcon from 'mdi-react/PlusIcon';
import { hasRole, getCurrentUser } from '../../../../factories/auth';
import { sortCaret } from '../../../../shared/components/Table/tableElements';

class DeviceTypeTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showItemModal: false,
            simResellerid: null,
            simId: 0,
            groupId: null,
            contractId: null,
            simReseller: 0,
            simPrice: 0,
            itemTypeId:0,
            simItemName: "",
            carrier: -1,
            user: getCurrentUser()
        };
    }

    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 365) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }

    cellItemEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={''} onClick={(e) => { this.onEditItemOpen(row) }} />
            </div>
        );
    };

    onItemOpen = () => {
        this.setState({
            showItemModal: true,
            simId: 0,
            simResellerid: null,
            simReseller: 0,
            groupId: null,
            contractId: null,
            itemTypeId:0,
            simPrice: "",
            simItemName: "",
            carrier: -1
        })
    }

    onEditItemOpen = (row) => {

        this.setState({
            showItemModal: true,
            simId: row.itemId,
            companyCode: this.props.currentUserObj.companyCode,
            simResellerid: row.resellerId,
            itemTypeId: row.itemTypeId,
            simPrice: row.unitPrice,
            simItemName: row.itemName,
            carrier: row.carrier.carrierId,
            contractId: row.itemCode,
            groupId:row.serialNum
        })

    }
    onItemClose = () => {
        this.setState({
            showItemModal: false
        })
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    validateForm = (Management) => {
        if (Management == "item") {
            if (this.state.itemTypeId != null && this.state.simItemName != "" && this.state.simItemName.length < 41) {
                if (parseFloat(this.state.carrier) >= 0 && this.state.itemTypeId != 0) {
                    return true;
                }
            }
        }
        return false;

    }

    onItemSubmit = () => {
        var data = {};
        if (this.state.simId > 0) {
            data = {
                "id": this.state.simId,
                "companyCode": this.props.currentUserObj.companyCode,
                "resellerId": this.state.simResellerid,
                "itemName": this.state.simItemName,
                "itemTypeId": this.state.itemTypeId,
                "unitPrice": null,
                "carrierId": this.state.carrier,
                "contractId":this.state.contractId,
                "groupId": this.state.groupId,
                "itemTypeId": this.state.itemTypeId
            }
        }
        else {
            data = {
                "companyCode": this.props.currentUserObj.companyCode,
                "resellerId": this.state.simResellerid,
                "itemName": this.state.simItemName,
                "itemTypeId": this.state.itemTypeId,
                "unitPrice": null,
                "carrierId": this.state.carrier,
                "contractId":this.state.contractId,
                "groupId": this.state.groupId,
                "itemTypeId":this.state.itemTypeId
            }
        }
        //console.log(data);
        this.props.SetSimType(data);
        this.onItemClose();
    }
    renderPaginationPanel = (props) => {
        let dropdown = props.components.sizePerPageDropdown;
        let beginning;
        let totalData;
        beginning = (props.currPage - 1) * dropdown.props.currSizePerPage + 1;
        totalData = (parseInt(beginning) + parseInt(dropdown.props.currSizePerPage)) - 1;
        return (
            <>
                {
                    (this.props.typeList.length == 0) ?
                        <>
                            <Col md={12} xs={12} sm={12} lg={12} style={{ display: "block", fontSize: "14px" }}>
                                <span style={{ float: "right" }}> 0 - 0 of 0</span>
                            </Col>
                        </>
                        : <>
                            <Col md={6} xs={6} sm={6} lg={6}>
                                {props.components.sizePerPageDropdown}
                            </Col>
                            <Col md={6} xs={6} sm={6} lg={6} style={{ display: "block", fontSize: "14px" }}>

                                <span style={{ float: "right" }}>{props.components.pageList}</span>
                                <span style={{ float: "right", fontSize: "14px", verticalAlign: "text-top", marginRight: "10px", marginTop: "9px" }}> {beginning} - {totalData} of {this.props.typeList.length}</span>
                            </Col>

                        </>

                }
            </>
        );
    }
    renderItemModal() {
        var itemDescription = [{"itemTypeDesc":"None Selected", "id":0}];
        if (this.props.itemType && this.props.itemType.length > 0){
            itemDescription = itemDescription.concat(this.props.itemType);
        }
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showItemModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onItemClose}>{(this.state.simId != 0) ? `Customize` : `Add New`}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Carrier <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrier'
                                    id='carrier'
                                    placeholder='Carrier'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    value={this.state.carrier}
                                >
                                    <option key={-1} value={-1}>None Selected</option>
                                    {this.props.carrierList.map((carrier, index) => (
                                        (this.state.carrier == carrier.carrierId) ? <option key={index} value={carrier.carrierId}>{carrier.carrierName}</option> : <option key={index} value={carrier.carrierId}>{carrier.carrierName}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simItemName'
                                    id='simItemName'
                                    placeholder='Item Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.simItemName}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>

                            </Col>
                        </Row>
                        {(this.props.pageContentList.includes("Inventory Reseller Input")) ?
                            <>
                                <br />
                                <Row>
                                    <Col md={2} >
                                        <span>Reseller <span style={{ color: "red" }}>*</span></span>
                                    </Col>
                                    <Col md={8}>
                                        <Input
                                            type="select"
                                            name='simResellerid'
                                            id='simResellerid'
                                            className="form-control"
                                            onChange={this.onInputChange}
                                            value={this.state.simResellerid}
                                        >
                                            {[{ resellerName: "Not Selected", id: null }, ...this.props.resellerList].map((reseller, index) => (
                                                (this.state.simResellerid == reseller.id) ? <option key={index} value={reseller.id} selected>{reseller.resellerName}</option> : <option key={index} value={reseller.id}>{reseller.resellerName}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                            </>
                            : null
                        }
                        {(this.props.pageContentList.includes("Inventory Contract Input")) ?
                            <>
                                <br />
                                <Row>
                                    <Col md={2} >
                                        <span>Contract ID <span style={{ color: "red" }}>*</span></span>
                                    </Col>
                                    <Col md={8}>
                                        <Input
                                            name='contractId'
                                            id='contractId'
                                            placeholder='Contract'
                                            onChange={this.onInputChange}
                                            className="form-control"
                                            defaultValue={this.state.contractId}
                                        />
                                        <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>

                                    </Col>
                                </Row>
                            </>
                            : null
                        }
                        {(this.props.pageContentList.includes("Inventory Group Input")) ?
                            <>
                                <br />
                                <Row>
                                    <Col md={2} >
                                        <span>Group ID <span style={{ color: "red" }}>*</span></span>
                                    </Col>
                                    <Col md={8}>
                                        <Input
                                            name='groupId'
                                            id='groupId'
                                            placeholder='Group'
                                            onChange={this.onInputChange}
                                            className="form-control"
                                            defaultValue={this.state.groupId}
                                        />
                                        <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>

                                    </Col>
                                </Row>
                            </>
                            : null
                        }
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Description <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type="select"
                                    name='itemTypeId'
                                    id='itemTypeId'
                                    placeholder='Item Type'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    disabled={this.state.simId != 0}
                                    value={this.state.itemTypeId}
                                >
                                    {itemDescription.map((item, index) => (
                                        <option key={index} value={item.id}>{item.itemTypeDesc}</option>
                                    ))}
                                </Input>

                            </Col>
                        </Row>
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="reset" onClick={this.onItemSubmit} disabled={!this.validateForm("item")}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onItemClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    carrierFormatter = (cell, row) => {
        if (row.carrier != null) {
            return row.carrier.carrierName;
        }
    }

    render() {
        const options = {
            sizePerPageList: [{
                text: 10, value: 10
            }, {
                text: 50, value: 50
            }, {
                text: 100, value: 100
            }, {
                text: 500, value: 500
            }],
            paginationPanel: this.renderPaginationPanel

        };

        return (
            <div>
                {this.renderItemModal()}

                {
                    (hasRole(this.state.user, ['SUPERADMIN', 'ADMIN'])) ?
                        <Button color='custom' type="button" onClick={this.onItemOpen}>
                            <PlusIcon /> Add New
                        </Button>
                        :
                        null
                }

                <BootstrapTable
                    data={this.props.typeList}
                    tableHeaderClass='header_white'

                    hover

                    bordered={false}
                    height='100%'
                    exportCSV
                    csvFileName='SIM_Card_Types.csv'
                    pagination
                    options={options}
                >
                    <TableHeaderColumn row='0' rowSpan='1' dataField='Carrier' hidden={true} export={(this.state.user && hasRole(this.state.user, ['SUPERADMIN', 'ADMIN'])) ? true : false} csvFormat={this.carrierFormatter.bind(this)} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort caretRender={sortCaret} csvHeader='Carrier'>Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort caretRender={sortCaret} csvHeader='Name'>Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Description' }} dataSort caretRender={sortCaret} csvHeader='Description'>Description</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='Operations'
                        dataAlign="center"
                        dataFormat={this.cellItemEditButton.bind(this)}
                        export={false}
                        hidden={!hasRole(this.state.user, ['SUPERADMIN', 'ADMIN']) ? true : false}

                    >
                        Operations
                    </TableHeaderColumn>
                </BootstrapTable>
            </div >
        )
    }
}
export default DeviceTypeTable;