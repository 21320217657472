/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Card, CardTitle, CardBody } from 'reactstrap';
import {
    addDashBoardLayout,
} from '../../../redux/actions/dashboardActions';
import AssetAttrEditform from './AssetAttrEditform';

class AssetAttrAddElement extends Component {
    constructor(props){
        super(props);
    }

    // componentDidMount(){
    //     const  {userObj, updateUserObj} = this.context;
    //      
    //     // this.props.loadAssetClass(null, false);
    // }
// ----------------------------------------------------------
    handleCancle = (e) => {
        this.props.toggle();
    }

    handleOk = (userObj, name, parameter) => {
       
        let position = {w:3,h:7,x:8,y:8};
        let layout = {
            name: name,
            type: "AttributeChart",
            width: 9,
            locked: 0,
            display: 1,
            position: JSON.stringify(position),
            parameter: parameter,
            username: userObj.userName,
            createdby: userObj.userName,
            companycode: userObj.companyCode,
            selected: 1,
            // createdtime: "2019-02-04T03:28:50.000+0000",
            createdtime: new Date().toUTCString(),
        }
         
        layout = {...this.props.currentLayout, ...layout};
        this.props.addDashBoardLayout(layout);
        this.props.toggle();
        this.props.parentToggle();
    }

    render(){
        return (
            <Card>
                <CardBody>
                    <AssetAttrEditform
                        // name = {this.props.layout.name}
                        // parameter={this.props.layout.parameter}
                        handleOk = {this.handleOk}
                        handleCancle = {this.handleCancle}
                    />
                </CardBody>
            </Card>
        );
    }
}

// function mapStateToProps(state) {
//     // const types = state.mapboard.types;
//     const assets = state.dashboard.assetList;
//     const assetClasses = state.mapboard.assetClass;
//     const assetClassAttr = state.mapboard.assetClassAttr;
//     const attributes = state.attributemgmt.attributes;
//     const props = {
//         // types,  
//         assetClasses,
//         assetClassAttr,
//         assets,
//         attributes,
//     };
//     return props;
// };

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            // loadDevice, 
            // loadAssetClass,
            // getAssetClassAttribute,
            // clearAssetClassAttribute,
            // getAssetList,
            // getAttributes,
            // clearAttributes,
            addDashBoardLayout,
    }, dispatch);
};
// export default AssetAttrAddElement;
export default connect(null, mapDispatchToProps)(AssetAttrAddElement);