/* eslint-disable */

import React, { Component } from 'react';
import { Card, CardBody, Button, Collapse, Row, Col, Input, CardGroup } from 'reactstrap';
import { BackButton, NextButton } from '../../../shared/components/Button/IconButton';

class ClientSelectSKU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SKU_type: -1

        }
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ SKU_type: nextProps.SKU_type });
    }
    handleOnNext = () => {
        this.props.onNext()
    }
    handleOnBack = () => {
        this.props.handleSelectSKU(-1);
        this.props.onBack();
    }

    onInputChange = (event) => {
        let value = event.target.value;
        if (value > -1) {

        }
        else {
            value = null;
        }
        this.props.handleSelectSKU(value);
    }

    render() { 
        var inactiveSIMTypeList = [...new Set(this.props.inactiveSIMList.map(item => item.itemId))]
        var SKUList = this.props.SIMTypeList.filter(type => inactiveSIMTypeList.includes(type.itemId));
        return (
            <div>
                <Row>
                    <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <BackButton
                            onClick={this.handleOnBack}
                            label="Back"
                            size={30}
                            color='black'
                        />
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NextButton
                            onClick={this.handleOnNext}
                            label="Next"
                            size={30}
                            color='black'
                        />
                    </Col>
                </Row>
                <h3>Select SKU</h3>
                <br />
                <Row>
                    <Col md={2} >
                        <h4>SKU <span style={{ color: "red" }}>*</span></h4>
                    </Col>
                    <Col md={8}>

                        <Input
                            type='select'
                            name='SKU_type'
                            id='SKU_type'
                            placeholder='SKU_type'
                            className="form-control"
                            onChange={this.onInputChange}
                        >
                            <option key={-1} value={-1}>None Selected</option>
                            { SKUList && SKUList.map((item, index) =>
                                (item.itemId == this.state.SKU_type) ? <option key={index} value={item.itemId} selected>{item.itemName}</option> : <option key={index} value={item.itemId}>{item.itemName}</option>) }
                            {/*this.props.inactiveSIMList && this.props.inactiveSIMList.map((item, index) =>
                                (item.itemId == this.state.SKU_type) ? <option key={index} value={item.itemId} selected>{item.itemName}</option> : <option key={index} value={item.itemId}>{item.itemName}</option>
                            )*/}

                        </Input>
                        <br />
                    </Col>
                </Row>
            </div>

        );
    }


}

export default ClientSelectSKU;