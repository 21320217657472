/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import SupportMain from './components/SupportMain';

const SupportPage = () => (
  <Container className="dashboard">
    {/* <Row>
      <Col md={12}>
        <h2 className="page-title"><b>Support</b></h2>
      </Col>
    </Row> */}
    {/* <Row> */}
      <Col md={12}>
        <SupportMain />
      </Col>
    {/* </Row> */}
  </Container>
);

export default SupportPage;