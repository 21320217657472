/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { withRouter } from "react-router-dom";
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {EditableText, EditableAttribute} from '../../Asset/components/ClassDisplayDetail';
import { 
    getOneDeviceMaster,
    clearOneDeviceMaster,
} from '../../../redux/actions/inventoryActions';

class DeviceDtailsMain extends Component {
    constructor(props){
        super(props);
        this.state={}
    }

    componentDidMount(){
        this.props.getOneDeviceMaster(this.props.deviceId);
    }

    componentWillUnmount(){
        this.props.clearOneDeviceMaster();
    }

// ----------------------------------------------------------------------

    renderInfoLine = (key, value, eol) =>{
        return(
            <Fragment>
                <Row>
                    <Col md={4} style={{textAlign:'center'}}>
                        <h4><b>{key}</b></h4>
                    </Col>
                    <Col md={4}>
                        {key === 'Device Name' ? 
                            <EditableText defaultValue={""}
                                onSave={name => {
                                //   updateClassOnStore({ id: details.id, data: { name: name } })
                                }}
                                textStyles={{ fontSize: '14px', marginLeft: '-5px' }}
                                inputStyles={{ fontSize: '18px', marginLeft: '-5px' }}
                            />
                            // <EditableAttribute name="" defaultValue={''} md={12}
                            //                     onSave={newVal => {
                            //                     // console.log(newVal)
                            //                     // props.updateAssetOnStore({ assetid: props.assetInfo.assetid, data: { assetname: newVal } })
                            //                     }}
                            // />
                            : value
                        }
                    </Col>
                </Row>
                { !eol && <hr/> }
            </Fragment>
        );
    }

    onActive = (e) => {
    
    }

    onSignUp = (e) => {
        this.props.history.push(`/pages/devicesignup/` + this.props.deviceId);
    }

    onCancel = (e) => {
        this.props.history.push(`/pages/inventory/`);
    }

    render(){
        let eol = false;
        let signedUp = false;
        if (this.props.deviceMaster && this.props.deviceMaster.device_STATUS){
            signedUp = true;
        }
        let keys = ['device_ID', 'device_NAME', 'old_DEVICE_ID', 'mac_ADDRESS', 'upc', 'network_SUPPORTED', 'customer_ID', 'device_STATUS'];
        let labels = {
            device_ID: 'Device ID', 
            device_NAME: 'Device Name',
            old_DEVICE_ID: 'Old ID', 
            mac_ADDRESS: 'MAC Address', 
            upc: 'UPC', 
            network_SUPPORTED: 'Network', 
            customer_ID: 'Customer', 
            device_STATUS: 'Status'
        };
        return (
            <div style={{width: '100%'}}>
                <Col md={12}>
                    <Card>
                    <CardBody>
                        {this.props.deviceMaster && 
                            // Object.entries(this.props.deviceMaster).map(([key, value], index) => (
                            //     this.renderInfoLine(key, value, eol)
                            // ))
                            keys.map(key => {
                                let val = '';
                                if (this.props.deviceMaster[key]){
                                    val = this.props.deviceMaster[key];
                                }
                                return(
                                    (Object.keys(this.props.deviceMaster).includes(key) || key === 'device_NAME') &&
                                    this.renderInfoLine(labels[key], val, eol)
                                )
                            })
                        }
                        <div style={{textAlign:'right'}}>
                            <Button color='custom' type="button" onClick={this.onCancel} >
                                Cancel
                            </Button>
                            <Button color='custom' type="button" onClick={this.onActive} disabled = {!signedUp} >
                                Active
                            </Button>
                            <Button outline color='secondary' type="reset" onClick={this.onSignUp} disabled = {signedUp}>
                                Sign Up
                            </Button>
                        </div>
                    </CardBody>
                    </Card>
                </Col>
            </div>
        );
    }
}

// export default DeviceDetailsMain;

function mapStateToProps(state) {
    const props = state.inventory;
    return props;
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
                getOneDeviceMaster,
                clearOneDeviceMaster, 
            }, dispatch);
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceDtailsMain));