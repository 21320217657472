/* eslint-disable */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';
import { axiosGet, getCurrentUser, base64Encode, getJwttoken } from '../../../factories/utils';
import { WEB_SERVICE_LOGO } from '../../../constants/appConstants'

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;

    let currentUser = getCurrentUser();

    if (currentUser != null) {
      if (currentUser.detail != null) {
        var brandingLogo = currentUser.detail.headerLogoURL
        this.props.changeToCustom();
      }
      else{
        var brandingLogo = "Waivlogo.png"
        this.props.changeToDefault();

      }
    }

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            {/* <Link className="topbar__logo" to="/dashboard_default" /> */}
            <Link className="topbar__logo" to={"/pages"+this.props.route} style={{ backgroundImage: `url(${WEB_SERVICE_LOGO + (brandingLogo)})` }} />
          </div>
          <div className="topbar__right">
            {/*<TopbarLanguage />*/}
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
