/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip } from 'reactstrap';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_add from '../../../shared/img/icon/add.png';
import icon_edit from '../../../shared/img/icon/edit.png';
import AttributeEditModal from './AttributeEditModal';
import {EditTextButton, DeleteTextButton} from '../../../shared/components/Button/IconButton';

class AttriburesList extends Component {
    constructor(props){
        super(props);

        this.state = {
            tooltipOpen: false,
            modalVisible: false,
            btnDisable: false,            
        }
    }

     //-----------------------------------------------------------------------------------------------
    //  toggle = () => {
    //     this.setState(prevState => ({
    //         modalVisible: !prevState.modalVisible
    //     }));
    // }
    // onAddClick = (name) =>{
    //     // alert(name);
    //     // this.setState({
    //     //     currentrule:  {
    //     //         ruleid: 0,
    //     //         rulename:name,
    //     //         devicetypeid:this.state.currentDeviceTypeId,
    //     //         devicetypename:this.state.currentDeviceTypeName,
    //     //         deviceid:this.state.currentDeviceId,
    //     //         devicename:this.state.currentDeviceName,
    //     //         createtime: new Date().toISOString(),
    //     //         rule:[],
    //     //     },
    //     //     currentruleid: 0,
    //     //     showRuleEdieCard: true,
    //     // });
    // }
    
    // renderEditModal() {
    //     return(
    //         <AttributeEditModal 
    //             modalSetVisible={this.state.modalVisible}
    //             toggle={this.toggle} 
    //             handleDeviceTypeDropdownChange = {this.handleDeviceTypeDropdownChange}
    //             handleDeviceDropdownChange = {this.handleDeviceDropdownChange}
    //             onAddRule={this.onAddClick}/>
    //     )
    // }

    // ----------------------------------------------------------------------------------------------

    handleRowEditClick = (row) => {
         this.props.handleRowEditClick(row);
    }

    handleRowDeleteClick = (row) => {
        this.props.handleRowDeleteClick(row);
    }
    handleAddNewClick = () => {
        this.props.handleAddNew();
        // this.props.toggle();
    }

    tipToggle = () => {
        this.setState({
          tooltipOpen: !this.state.tooltipOpen
        });
    }

    editButtonFormatter = (cell, row) => {
        // let path = `/pages/device/${cell}`;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                <div id={'Tooltip-' + row.attributeid} style = {{display:'inline'}}>
                {/* <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={(e)=>{this.handleRowEditClick(row);}} size='sm'>
                    <img src={icon_edit} style={{width: 20, height: 20}} alt={"Edit"}/>
                </Button> */}
                <EditTextButton label={'Edit'} onClick={(e)=>{this.handleRowEditClick(row);}}/>
                </div>
                {' '}
                {/* <div id={'Tooltip-' + row.attributeid} style = {{display:'inline'}}>
                    <Button outline  variant="primary" color="danger" type="button" style={{margin:'auto'}} size='sm'  disabled = {!row.useradded} onClick={(e)=>{this.handleRowDeleteClick(row);}}>
                        <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} />
                    </Button>
                </div> */}
                { !row.useradded &&
                <UncontrolledTooltip placement={"right"}  target={'Tooltip-' + row.attributeid} >
                    Edit Arribute Dislay Name
                </UncontrolledTooltip>
                }
            </div>
        )
    }
    // --------------------------------------------------------------------------------------------------
    handleInsertButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick        
        // this.props.history.push('/pages/geofence');
        // return <Redirect  to='/pages/asset/' />
        // this.props.history.push('/pages/actionedit/0');
        this.handleAddNewClick();
        // onClick();
      }
      createCustomInsertButton = (onClick) => {
        return (
          <InsertButton
            btnText='Add New'
            btnContextual='btn-primary'
            // className='my-custom-class'
            style={{backgroundColor:'#ffffff'}}
            btnGlyphicon='glyphicon-edit'
            onClick={ () => this.handleInsertButtonClick(onClick) }>
                {/* <PlusCircleOutlineIcon size={100}/> */}
                <img src={icon_add} style={{width: 30, height: 30}} />
            </InsertButton>
        // <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
        );
      }
    // --------------------------------------------------------------------------------------------
     // --------------------------------------------------------------------------------------------
     tbToolBar = props => {
        return (
            <Row>
                {/* <Col md={2}>
                    {props.insertBtn}
                </Col> */}
                <Col md={2} style = {{textAlign: 'right'}}>
                <Label for="deviceType" style={{display: 'block', width: "100%"}} ><h4><b> Device Type </b></h4></Label>
                </Col>
                <Col md={5}>
                    {/* <Label for="deviceType"   style={{ display: 'inline'}}><h4><b>Manufacture</b></h4></Label> */}
                    <Input type="select" id="deviceType" name="deviceType" width="60%" value = {this.props.currentDeviceTypeId} onChange={this.props.manufactureOnChangeHandler}>
                        {this.props.devicetypes && this.props.devicetypes.map((type, i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))}
                        {/* <option value={'0'}>ALL</option> */}
                    </Input>
                </Col>
                {/* <Col md={6}></Col>
                <Col md={3}>
                    { props.searchField }
                    { props.components.searchPanel }
                </Col> */}
            </Row>
        )
    }
    // --------------------------------------------------------------------------------------------

    renderAttribuesList = () => {
        const datetimeFormatter = (cell, row) => {
            if (cell && cell.length > 0){
                return cell.split(".")[0].replace('T', ' ');
            }else{
                return null
            }
        }
        const options = {
            // page: 1,  // which page you want to show as default
            // sizePerPageList: [ {
            //   text: '5', value: 5
            // }, {
            //   text: '10', value: 10
            // } ], // you can change the dropdown list for size per page
            // sizePerPage: 5,  // which size per page you want to locate as default
            // pageStartIndex: 1, // where to start counting the pages
            // paginationSize: 5,  // the pagination bar size.
            // prePage: '<', // Previous page button text
            // nextPage: '>', // Next page button text
            // firstPage: '<<', // First page button text
            // lastPage: '>>', // Last page button text
            // insertBtn: this.createCustomInsertButton,
            btnGroup: this.tbToolBar,
        };
        return (
            <Col md={12}>
            <Card>
                <CardBody>
                    {/* <div style={{textAlign:'right',paddingRight:30}}>
                        <img src={icon_add} style={{width: 30, height: 30}} alt={"Add"} onClick={(e)=>{this.handleAddNewClick();}} /> */}
                        {/* <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleAddNewClick}>
                            New Rule
                        </Button> */}
                    {/* </div>
                    <br/> */}
                    <BootstrapTable data={ this.props.attributes } tableHeaderClass = 'header_white' pagination = {false} options={ options }     search bordered={ false } maxHeight={500}>
                        <TableHeaderColumn dataField='attributeid' isKey={ true } dataAlign="center"  >Attribute ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='assetname' dataAlign="center" hidden >Asset</TableHeaderColumn> 
                        <TableHeaderColumn dataField='attributename' dataAlign="center" dataSort >Attribute Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='displayname' dataAlign="center" dataSort >Attribute Display Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='attributetype' dataAlign="center" dataSort >Attribute Data Type</TableHeaderColumn>          
                        <TableHeaderColumn dataField='createdby' dataAlign="center" dataSort >Created By</TableHeaderColumn>      
                        <TableHeaderColumn dataField='createdtime' dataAlign="center" dataFormat={datetimeFormatter} >Created Time</TableHeaderColumn>                    
                        <TableHeaderColumn dataAlign="center" dataFormat={ this.editButtonFormatter } ></TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        </Col>
        );
    }
    

    render(){
        return (
            <div>
                {this.renderAttribuesList()}
                {/* {this.renderEditModal()} */}
            </div>
        );
    }
}

export default AttriburesList;