/* eslint-disable */
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';

import {searchUsers} from '../../../redux/actions/AccountMgmt/fetchUsersAction';
import {getUsers,getPageContent} from '../../../redux/actions/userAction';

// import {SEARCH_USER_SUCCEED} from '../../../redux/const';
import {SEARCH_USER_SUCCEED} from '../../../constants/actionTypes';

import SearchResult from './result';
import DetailModal from './userDetailModal';
import UserAddModal from './userAddModal';
import UserAddMultiModal from './userAddMultiModal';

class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      fullName: '',
      userName: '',
      submitted: true,
      showModal: false,
      showAddModal: false,
      showMultiModal: false,
    };
  }

  componentDidMount(){
    this.props.getPageContent({ "content": "Users" });
    this.props.getUsers();
  }

  // componentWillReceiveProps(nextProps) {
  //     if (nextProps.type === SEARCH_USER_SUCCEED) {
  //         this.props.result = nextProps.result;
  //     }
  // }

  onInputChange = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value
    });
  };

  onFormSubmit = (event) => {
    event.preventDefault();
    this.props.searchUsers({
      email: this.state.email,
      fullName: this.state.fullName,
      userName: this.state.userName,
    });
    this.setState({
      submitted: true,
    });
  };

  onReset = () => {
    this.setState({
      email: '',
      fullName: '',
      userName: '',
      submitted: false,
      showModal: false,
    });
  };

  // renderSearchResult = () => {
  //     return (
  //         <SearchResult searchResult={this.props.result} />
  //     );
  // };

  renderAddModal = () => {
    this.setState({
      showModal: true,
    });
    setTimeout(() => {
      this.setState({showModal: false});
    }, 1);
  };

  renderAddNewModal = () => {
    this.setState(preState => ({
      showAddModal: !preState.showAddModal,
    }));

    setTimeout(() => {
      this.setState({showAddModal: false});
    }, 1);
  }

  renderAddMultiModal = () => {
    this.setState(preState => ({
        showMultiModal: !preState.showMultiModal,
      }));
      setTimeout(() => {
        this.setState({ showMultiModal: false });
      }, 1);
    }

  render() {


    return (
      <Col md={12}>
        {/* <Card>
          <CardBody>
            <form onSubmit={this.onFormSubmit} className="form-group">
              <Row>
                <Col md={4}>
                  <span>User Name</span>
                  <input
                    name="userName"
                    placeholder="User Name"
                    onChange={this.onInputChange}
                    className="form-control"
                    value={this.state.userName}
                  />
                </Col>
                <Col md={4}>
                  <span>Email</span>
                  <input
                    name="email"
                    placeholder="Email"
                    onChange={this.onInputChange}
                    pattern={'^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'}
                    className="form-control"
                    value={this.state.email}
                  />
                </Col>
                <Col md={4}>
                  <span>Full Name</span>
                  <input
                    name='fullName'
                    placeholder='Full Name'
                    onChange={this.onInputChange}
                    pattern={'^([a-zA-Z0-9]+|[a-zA-Z0-9]+\\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\\s{1}[a-zA-Z0-9]{3,}\\s{1}[a-zA-Z0-9]{1,})$'}
                    className="form-control"
                    value={this.state.fullName}
                  />
                </Col>

              </Row>
              <hr/>
              <div className='text-center'>
                <Button color='custom' type="submit">
                  Submit
                </Button>
                <Button outline color='secondary' type="reset" onClick={this.onReset}>
                  Reset
                </Button>
                <Button outline color='secondary' type="button" onClick={this.renderAddModal}>
                <Button outline color='secondary' type="button" onClick={this.renderAddNewModal}>
                  Add New User
                </Button>
              </div>
            </form>
          </CardBody>
          </Card> */}
          
          {/* {this.state.submitted === true && <SearchResult searchResult={this.props.payload}/>} */}
          {this.state.submitted === true && <SearchResult pageContentList={this.props.pageContentList} searchResult={this.props.userList} addNewToggle = {this.renderAddNewModal} addMultiToggle={this.renderAddMultiModal} userObj = {this.props.userObj} />}

          <DetailModal userEmail={''} show={this.state.showModal} fullName={''} userName={''} />

          <UserAddModal show={this.state.showAddModal} userObj = {this.props.userObj} toggle={this.renderAddNewModal}/>

          <UserAddMultiModal show={this.state.showMultiModal} toggle={this.renderAddMultiModal} next={this.onNext}/>
          {/*<hr/>*/}
        {/* </Card> */}
      </Col>
    );
  }
}



const mapStateToProps = (state) => {
  return state.user;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({searchUsers, getUsers, getPageContent}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
