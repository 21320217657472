/* eslint-disable */
import React, {Component, Fragment, useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import { Card, CardBody, Col } from 'reactstrap';
import {Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Form, FormGroup} from 'reactstrap';
import {EditIcon} from 'mdi-react';
import DashboardMap from './DashboardMap';
import AssetClassEditModal from './AssetClassEditModal';
import {loadAssetMapData} from '../../../redux/actions/dashboardActions';
import WeatherWidget from "./WeatherComponent/WeatherWidget";
//import testData from './WeatherComponent/testData.json';
import  jQuery from 'jquery'
import axios from "axios";
import Select from "react-select";
const cities = [
    {city: 'taipei', label: '🇹🇼 Taipei'},
    {city: 'tokyo', label: '🇯🇵 Tokyo'},
    {city: 'moscow', label: '🇷🇺 Moscow'},
    {city: 'sydney', label: '🇦🇺 Sydney'},
    {city: 'london', label: '🇬🇧 London'},
    {city: 'paris', label: '🇫🇷 Paris'},
    {city: 'mexico', label: '🇲🇽 Mexico'},
    {city: 'seattle', label: '🇺🇸 Seattle'},
    {city: 'washington', label: '🇺🇸 Washington'},
    {city: 'beijing', label: '🇨🇳 Beijing'},
];

const AssetWeatherWidget = () => {
    const params = new URLSearchParams(window.location.search);
    const city = params.get('city_index');

    const [cityIndex, setCityIndex] = useState(city || 0);
    const [forecast, setForecast] = useState([]);
    const [error, setError] = useState('');

    getLocation().then((res) => {
       // console.log(res);
    });
    function getTemp(degress) {
        return parseFloat(((degress - 32) * 5 / 9).toFixed(1));
    }
    window.onLoadWeatherData=async (weathertData) => {
        const transformData = await weathertData.map((data) => ({
            dt: new Date(data.timestamp).getTime()/1000,
            temp:getTemp(data.temp),
            temp_min: getTemp(data.temp),
            temp_max: getTemp(data.temp),
            humidity: data.relHum,
            icon: "",
            desc: "",
            clouds: data.cldCvr,
            wind: data.windSpd,
            windDir:data.windDir,
            precip:data.precip,
        }));
        setForecast(transformData);
    }

    const fetchWeatherAsync = async (cityId) => {
        try {

            jQuery.ajax({
                url: 'https://api.weathersource.com/v1/346ad4c3fb93125bbd18/postal_codes/N2T%202T8,ca/forecast.json?period=hour&fields=all',
                //N2T%202T8,ca
                dataType: 'jsonp',
                jsonpCallback: 'onLoadWeatherData',
                jsonp: '_callback',
            });

           /* const transformData = await response.data.list.map((data) => ({
                dt: data.dt,
                temp: data.main.temp,
                temp_min: data.main.temp_min,
                temp_max: data.main.temp_max,
                humidity: data.main.humidity,
                icon: data.weather[0].icon,
                desc: data.weather[0].description,
                clouds: data.clouds.all,
                wind: data.wind.speed,
            }));
            setForecast(transformData);*/
            /*const response = await axios.get(
               'https://api.weathersource.com/v1/346ad4c3fb93125bbd18/postal_codes/22222,us/forecast.json?period=hour&fields=all',
               {
                   /!*params: {
                       period: hour,
                       lang: 'zh_tw',
                       appid: OPEN_WEATHER_MAP_KEY,
                       units: 'metric',
                   },*!/
               },
           );*/
        } catch (err) {
            setError('');
        }
    };

    useEffect(() => {
        fetchWeatherAsync(cities[cityIndex].city);
    }, []);

    return (
        <Fragment>
            <Card>
                <CardHeader className={'dragBar'}>
                    {/* <Row>
                    <Col> */}
                    <h5 className="bold-text">
                       {/* {this.props.dashName}
                        {this.props.layout && 0 == this.props.layout.locked &&
                            <EditIcon size={17} style={{float: 'right', display: 'inline', cursor: 'pointer'}}
                                      onClick={this.toggle}/>
                        }*/}
                        {"Weather"}

                    </h5>
                    {/* </Col>  
                    <Col>     */}
                    {/* <EditIcon style={{float: 'right', display:'inline'}}/>     */}
                    {/* </Col>
                    </Row>        */}
                </CardHeader>
                <CardBody style={{padding: 15,overflow:"hidden"}}>
                    {/* <div className="card__title">
                        <h5 className="bold-text">{this.props.dashName} </h5>                      
                    </div> */}
                    <Row>
                        <Col lg={7} style={{paddingBottom: '7px'}}>
                            <Input id="pac-input" class="controls" type="text" placeholder="Search by Address"/>
                        </Col>
                    </Row>
                    <div>
                        <WeatherWidget
                            config={{
                                location: "Waterloo, Ontario, Canada",
                                unit: 'metric',
                                locale: 'zh-tw',

                            }}
                            forecast={forecast}
                        />
                    </div>
                </CardBody>
            </Card>

        </Fragment>
    );


}

function getLocation() {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                resolve(position);
            }, () => {
                resolve(null);
            });
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    });
}
// export default AssetMapWidget;

function mapStateToProps(state) {
    const props = {...state.dashboard};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAssetMapData}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetWeatherWidget);