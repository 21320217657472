/* eslint-disable */
import {
    WHITE_COLOR, 
    GREEN_COLOR, 
    RED_COLOR, 
    ORENGE_COLOR, 
    YELLOW_COLOR, 
    GREY_COLOR,
    FAULT_COLOR,
    LIGHT_GREY_COLOR,
    ATTRIBUTE_ORDER,
    LEGEND_LIST
} from '../constants/dataConstants';
import {weatherSeverity, hex2bin, splitStatusValue, mapWeatherSeverity} from './utils';

export function lightningColorMaper (statusHex){
     
    let lightColor = GREY_COLOR ; //WHITE_COLOR;
    if (statusHex) {
        if(statusHex == 'FAULT' || statusHex.charAt(0) === '0' && statusHex.charAt(1) === '0' && statusHex.charAt(2) === '0' && statusHex.charAt(3) === '0') {
            lightColor = FAULT_COLOR;
            LEGEND_LIST.label = "Rotor Fault";
        }else if (statusHex.charAt(3) === '0' || statusHex == "GREEN"){
            lightColor = GREEN_COLOR;
        }else {
            if (statusHex.charAt(0) === '0' || statusHex == "RED") {
                lightColor = RED_COLOR;
            } 
            else if (statusHex.charAt(1) === '0' || statusHex == "ORANGE") {
                lightColor = ORENGE_COLOR;
            } 
            else if (statusHex.charAt(2) === '0') {
                lightColor = YELLOW_COLOR;
            }
            else if (statusHex.charAt(0) === '1' && statusHex.charAt(1) === '1' && statusHex.charAt(2) === '1') {
                lightColor = GREY_COLOR;
            }
            else if(statusHex == "OFF") {
                lightColor = GREY_COLOR;
            }
        }
    }
    // console.log("===statusHex", statusHex);
    // console.debug('lightningColorMaper output', lightColor);
    return lightColor;
}

export function reformWeatherAssetData (assetData) {
    // console.log('assetData',assetData)
    let statusData = [];
    assetData && assetData.forEach((asset, assetindex) => {
        let mapColor = GREEN_COLOR;
        let mapStatus = false;
        let mapAlarm = 0;
        let attributesObj = null;
        if (asset.attributes) {            
            asset.attributes.forEach((attr, attrindex) => {
                if (attr.type === 'device') {
                    let key = attr.attributename;
                    let value = attr.value;
                    let status = false;
                    if (key === 'CurrentDigitalInputState'){
                        // console.log("===attr", attr);
                        key = 'hexAlarm';
                        let slicedValue = splitStatusValue(attr.value);
                        status = slicedValue.status == 1 ? true : false;
                        value = hex2bin(slicedValue.alarm);
                        mapStatus = status;
                        mapColor = lightningColorMaper(value);
                        mapAlarm = mapWeatherSeverity(value)
                    }
                    const attrItem = {
                        deviceid: attr.deviceid,
                        devicename: attr.devicename,
                        value: value,
                        status: status,
                    }
                    if (attributesObj === null) {
                        attributesObj = {};
                    }
                    if (attributesObj[key]){
                        attributesObj[key].push(attrItem);
                    } else {
                        attributesObj[key]= [ attrItem ];
                    }
                }
            });
        }
        // console.log("===asset=",asset)
        const assetObj = {
            assetid:asset.assetid,
            assetname:asset.assetname,
            locationlat:asset.locationlat,
            locationlng:asset.locationlng,
            grouping: asset.grouping,
            timestamp:asset.timestamp,
            status:mapStatus,
            alarm: mapAlarm,
            color: mapColor,
            attributes: attributesObj,
            devices: (asset.devices)?asset.devices:[]
        };

        statusData.push(assetObj);
    });

    return statusData;
}

export function reformAlertPubsubData(data, pubsubObj) {
    try{
    // let pubsubObj = null;
    if (data){
        let pubsubData = JSON.parse(data);
        if (pubsubObj == null){
            pubsubObj = {};
        }
        
        const status = pubsubData.status;
        if (Array.isArray(pubsubData.msgList)){
            for (let msg of pubsubData.msgList){
                let attrObj = {};
                for (let attrKey in msg.attrsMap) {
                    attrObj[attrKey] = {
                        ...msg.attrsMap[attrKey],
                        status
                    }
                }
                // msg.deviceid;
                // msg.assetid;
                if (msg.assetid && pubsubObj[msg.assetid]){
                    if (pubsubObj[msg.assetid][msg.deviceid]){
                        pubsubObj[msg.assetid][msg.deviceid] = {
                            ...pubsubObj[msg.assetid][msg.deviceid],
                            ...attrObj
                        };
                    }else{
                        pubsubObj[msg.assetid][msg.deviceid] = attrObj;
                    }
                }
                else if (msg.assetid){
                    pubsubObj[msg.assetid] = {
                        [msg.deviceid] : attrObj,
                    }
                }

                // ----------------------------------
            }
        }
    }
    return pubsubObj;
    }catch(e) {
        console.error('sub-error-device', e);
    }
};

export function updateStatusAsset(data, statusAsset) {
    try{
        let newStatusAsset = statusAsset;
         
        if (data) {
            let pubsubData = JSON.parse(data);
            newStatusAsset = JSON.parse(JSON.stringify(statusAsset));
            Array.isArray(pubsubData.msgList) && pubsubData.msgList.forEach((msg, index) => {
                //  
                let updateAsset = newStatusAsset.find(item => (item.assetid === msg.assetid));
                if (updateAsset) {
                    //  
                    Object.entries(msg.attrsMap).forEach(([key, updateValue],index) => {
                        if (updateAsset.attributes[key]){
                            let updateAttribute = updateAsset.attributes[key].find(item => (item.deviceid === msg.deviceid));
                            if (updateAttribute) {
                                updateAttribute.value = updateValue.value;
                                updateAsset.timestamp = updateValue.timestamp;
                            }
                        }
                    });
                }
            });
        }
         
        return newStatusAsset;
    }catch(e){
        console.error('sub-error-device', e);
    }

    // return newStatusAsset
}

export function setDonut(alarmObj, attributes, mapcolor = null){
     
     
    let reValue = {status: 0, color: 'black'};
    if (attributes && attributes['Alarm'] && attributes['Alarm'].length > 0) {
        const color = lightningColorMaper(attributes['Alarm'][0].value);
        //  
        const status = attributes['Alarm'][0].status ? 1 : 0 ;
        reValue = {
            ...reValue, 
            status, 
            color,
        }
    }
    if (attributes && attributes['hexAlarm'] && attributes['hexAlarm'].length > 0) {
        const color = mapcolor ? mapcolor : lightningColorMaper(attributes['hexAlarm'][0].value);
        const status = attributes['hexAlarm'][0].status ? 1 : 0 ;
        reValue = {
            ...reValue, 
            status, 
            color,
        }
    }
    if (attributes) {
        ATTRIBUTE_ORDER && ATTRIBUTE_ORDER.filter(item=>item.display && item.attributename != 'hexAlarm').forEach(attr => {
            let attributename = attr.attributename;
            Array.isArray(attributes[attributename]) && attributes[attributename].forEach(vattr => {
                if (vattr.status) {
                    reValue = {
                        ...reValue, 
                        status: 1,
                        color: RED_COLOR,
                    }
                }
            });
        });
    }
    if (alarmObj) {
        for (let key in alarmObj) {
            for (let attr of ATTRIBUTE_ORDER){
                if ( attr.hasrule == true && alarmObj[key][attr.attributename] && alarmObj[key][attr.attributename].status == true ){
                    reValue = {
                        ...reValue, 
                        status: 1, //0, 
                        color: RED_COLOR,
                    }
                }
            }
        }
    }
     
    return reValue
}