/* eslint-disable */
import React, { Component } from 'react'

import { Col, Row, Card, CardBody, Label, Button, Input, FormGroup } from 'reactstrap';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { DeleteIcon, CloseIcon, PlusCircleIcon, PlusBoxIcon, PlusOutlineIcon, PlusIcon } from 'mdi-react'

import MultiSelectionComponent, { DeviceMultiSelection, SingleSelection, DeleteConfirmation } from './SelectionComponents'

import IconElement from './IconElement';

import AssetIconEditModal from './AssetIconEditModal';

import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { EditTextButton, DeleteTextButton } from '../../../shared/components/Button/IconButton';

import {
  AttributeInfo, EditableAttribute, EditableText, multiSelectStyles, UploadImageAttribute
} from './ClassDisplayDetail'
import { resetCalculateAttribute } from '../../../redux/actions/assetActions';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { ExitTextButton } from '../../../shared/components/Button/IconButton';

export default function EditAssetPanel(props) {
  const deviceDefaultValue = props.assetInfo.devices.map(d => ({ id: d.deviceid, name: d.devicename, namealias: d.devicenamealias, parentdeviceid: d.parentdeviceid }))
  const [selectedDevices, setDevices] = React.useState(deviceDefaultValue)
  const [selectedDevicesBuf, setDevicesBuf] = React.useState(deviceDefaultValue);
  // custom styles
  const userObj = getCurrentUser();

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      '&:active': { backgroundColor: userObj.detail.appButtonColorHover },
      backgroundColor: state.isFocused ? userObj.detail.appButtonColorHover : "white",
    }),
    control: (base, selectState) => ({
      ...base,
      height: 35,
      minHeight: 35,
      borderColor: selectState.isFocused ? userObj.detail.appButtonColorHover : base.borderColor,
      // This line disable the blue border
      boxShadow: selectState.isFocused ? 0 : 0,
      '&:hover': { borderColor: selectState.isFocused ? userObj.detail.appButtonColorHover : base.borderColor }
    }),
    indicatorsContainer: (base, selectState) => ({
      ...base,
      padding: "0",
    }),
  };

  const updateDeviceOnBlur = () => {
    if (selectedDevices != props.assetInfo.devices)
      props.updateAssetOnStore({
        assetid: props.assetInfo.assetid,
        data: { devices: selectedDevices }
      },
        () => {

          setDevicesBuf(selectedDevices);
        },
        () => {

          setDevices(selectedDevicesBuf);
        },
      )
  }

  // James: To make "reset" button only appears in Effort Trust asset management
  const effortSquareInfo = {
    assetClassName: "ParkingGarage",
    assetName: "Effort Square",
    companyCode: "ET",
    devicetype: "6112bd40988a377cbd85c680",
  };

  // const companyCode = props.as

  let assetClassInfo = props.assetClassList.find(ac => ac.id == props.assetInfo.assetclassid)
  // console.log("===assetClassList", props.assetClassList);
  let parentAssetOptions = [];
  if (assetClassInfo) {
    parentAssetOptions = props.assetList.filter((item, index) => (item.assetclassid == assetClassInfo.parentclass));
  }
  const parentDefaultValues = parentAssetOptions.find(({ assetid }) => (assetid == props.assetInfo.parentasset));
  const [selectedParentAsset, setParentAsset] = React.useState(parentDefaultValues);
  const updateParentAssetOnBlur = () => {
    if (selectedParentAsset && selectedParentAsset.assetid != props.assetInfo.parentasset) {
      props.updateAssetOnStore({
        assetid: props.assetInfo.assetid,
        data: { parentasset: selectedParentAsset.assetid }
      })
    } else if (!selectedParentAsset && parentDefaultValues) {
      props.updateAssetOnStore({
        assetid: props.assetInfo.assetid,
        data: { parentasset: null }
      })
    }
  }



  const groupedDevices = dtList => dtList.map(dt => ({
    label: dt.name,
    options: props.devices.filter(d => d.devicetype == dt.id)
  }))
  // console.log("===this.props", deviceDefaultValue);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (

    <div>
      <Card>
        {/* <Button onClick={() => console.log(this.state)}>State</Button> */}
        <CardBody>
          <h3>Edit Asset <div style={{ float: "right" }}>
            <ExitTextButton label={''} onClick={() => props.closePanel()} />{' '}
          </div>
          </h3>
          <br />
          <Row>
            <Col md={4} style={{ padding: 0 }}>
              <EditableAttribute name="Name" defaultValue={props.assetInfo.assetname} md={12}
                onSave={newVal => {
                  // console.log(newVal)
                  props.updateAssetOnStore({ assetid: props.assetInfo.assetid, data: { assetname: newVal } })
                }}
              />
              <EditableAttribute name="Description" inputStyles={{ height: 100, paddingTop: 8 }} md={12}
                type='textarea'
                defaultValue={props.assetInfo.description} exitOnEnterKeyPress={false}
                onSave={newVal => props.updateAssetOnStore({ assetid: props.assetInfo.assetid, data: { description: newVal } })}
              />
            </Col>
            <Col md={8}>
              {/* TODO: Make it so that changing assetclass would also reset custom attributes */}
              <AttributeInfo name="Asset Class">
                <SingleSelection
                  key={props.assetInfo.assetid}
                  disabled={props.assetInfo.devices && props.assetInfo.devices.length > 0}
                  forIdName="assetClass"
                  onChange={(value) => props.updateAssetOnStore({ assetid: props.assetInfo.assetid, data: { assetclassid: value } })}
                  options={props.assetClassList}
                  value={props.assetInfo.assetclassid}
                  getOptionLabel={({ name }) => name}
                  getOptionValue={({ id }) => id}
                  styles={multiSelectStyles}

                />
              </AttributeInfo>
              <AttributeInfo name="Devices">
                <DeviceMultiSelection
                  key={props.assetInfo.assetid}
                  styles={multiSelectStyles}
                  // styles={customStyles}
                  onBlur={updateDeviceOnBlur}
                  // options={props.devices}
                  options={assetClassInfo ? groupedDevices(
                    props.deviceTypes.filter(dt =>
                      assetClassInfo.allowedDeviceTypes.some(dT => dT.id == dt.id))
                  ) : null}
                  value={selectedDevices}
                  onChange={options => setDevices(options || [])}
                  defaultValue={deviceDefaultValue}
                />
              </AttributeInfo>
              <AttributeInfo name="Parent Asset">
                <MultiSelectionComponent
                  options={parentAssetOptions}
                  isSingle={true}
                  getOptionLabel={({ assetname }) => assetname}
                  getOptionValue={({ assetid }) => assetid}
                  defaultValue={parentDefaultValues}
                  onChange={(val) => { setParentAsset(val) }}
                  onBlur={updateParentAssetOnBlur}
                  styles={multiSelectStyles}
                  // isDisabled= {details.assets && details.assets.length > 0}
                  isClearable
                />
              </AttributeInfo>

            </Col>
          </Row>
          <hr />
          <Row>
            {/* <Col> */}
            <Col className="iconHover">
              <span style={{ fontSize: 18, color: '#646777' }}>Custom Attributes</span>
              {/* <a onClick={() => console.log('helo')}><PlusCircleIcon size={25} style={{ marginTop: '-5px' }} /></a> */}
            </Col>
          </Row>
          <br />
          <CustomAttributes id={props.assetInfo.assetid}
            attributes={props.assetInfo.assetattributes}
            attrmetas={assetClassInfo ? assetClassInfo.attributeMetas : null}
            addAssetAttribute={props.addAssetAttribute}
            updateAssetAttribute={props.updateAssetAttribute}
            updateImageAttribute={props.updateImageAttribute}
            deleteAssetAttribute={props.deleteAssetAttribute}
          />
          {/*
          <Row>
            <Col className="iconHover">
              <span style={{ fontSize: 18, color: '#646777' }}>Calculation Attributes</span>
              <CalculationAttributes
                calculationAttributes={props.calculatedAttributes}
                updateBatchCalculateAttribute={props.updateBatchCalculateAttribute}
                getCalculateAttribute={props.getCalculateAttribute}
                assetId={props.assetInfo.assetid}
                assetClassId={props.assetInfo.assetclassid}
              />
            </Col>
          </Row>
                    */}
          <br />
          {

          }

          <Row>
            <Col className='text-right'>
              {props.assetInfo && props.assetInfo.companycode == effortSquareInfo.companyCode ?
                <ConfirmDeleteDialog
                  outline={false}
                  label={'Reset'}
                  noIcon={true}
                  onConfirm={() => resetCalculateAttribute(props.assetInfo.assetid)}
                  msg={'This action will reset the Level wise and total parking counts, please confirm if you wish to proceed.'}
                  style={{ marginBottom: 0 }}
                  size='m'
                />
                :
                null}
              <Button color="custom" style={{ marginBottom: 0 }} onClick={() => props.closePanel()}>Close</Button>
              {/* <Button color="custom" style={{ marginBottom: 0 }} onClick={scrollToTop}>Back to Top</Button> */}
            </Col>
          </Row>
          {/* <Row className='float-right'>
            <Button onClick={resetButtonClick}>Reset</Button>
            <Button variant="primary" color="custom"
              onClick={cancelButtonClick}>
              Cancel
              </Button>
            <Button variant="primary" color="custom"
              onClick={saveButtonClick}>
              Submit
              </Button>
          </Row> */}
        </CardBody>
      </Card>
    </div >
  )
}
class CalculationAttributeNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attribute: {
        assetclassid: 0,
        assetid: 0,
        createdby: "",
        createdtime: "",
        displayname: "",
        expression: "",
        id: 0,
        name: "",
        status: 1,
        vars: ""
      }
    }
  }
  componentDidMount() {
    this.setState({
      attribute: this.props.calculatedAttribute
    })
  }
  componentWillReceiveProps(nextProps, nextContent) {
    this.setState({
      attribute: nextProps.calculatedAttribute
    })
  }
  onInputChange = (event) => {
    if (event.target.name === "displayname") {
      this.state.attribute["name"] = event.target.value;

    }
    this.state.attribute[event.target.name] = event.target.value;
    this.props.onAttributeChange(this.state.attribute, this.state.position);

  };
  render() {
    return (
      <div style={{ marginBottom: "10px" }}>
        <Row>
          <Col md={2}>
            <Input
              name="displayname"
              id="displayname"
              placeholder='name'
              className="form-control"
              defaultValue={this.state.attribute.name}
              onChange={this.onInputChange}

            />
          </Col>
          <Col md={8}>
            <Input
              type="textarea"
              name="expression"
              id="expression"
              placeholder='Expression'
              className="form-control"
              defaultValue={this.state.attribute.expression}
              onChange={this.onInputChange}

            />
          </Col>
          <Col md={2}>
            <Button outline color='custom' onClick={() => this.props.deleteAttribute(this.props.index)}>
              remove
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}
class CalculationAttributes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calculationAttributes: [],
      assetId: 0,
      assetClassId: 0,
    }
  }
  componentDidMount() {
    this.setState({
      calculationAttributes: this.props.calculationAttributes,
      assetId: this.props.assetId,
      assetClassId: this.props.assetClassId
    })
  }
  deleteAttribute = (index) => {
    var calAttributesTemp = [...this.state.calculationAttributes]
    if (calAttributesTemp[index].id > 0) {
      calAttributesTemp[index].status = 0;
    } else {
      calAttributesTemp.splice(index, 1);
    }
    this.setState({ calculationAttributes: calAttributesTemp });
  }
  addAttribute = () => {
    var calAttributesTemp = [...this.state.calculationAttributes]
    calAttributesTemp.push({
      assetclassid: this.state.assetClassId,
      assetid: this.state.assetId,
      createdby: "",
      createdtime: "",
      displayname: "",
      expression: "",
      id: 0,
      name: "",
      status: 1,
      vars: ""
    })
    this.setState({ calculationAttributes: calAttributesTemp });
  }
  onAttributeChange = (attribute, position) => {
    var calAttributesTemp = [...this.state.calculationAttributes]
    calAttributesTemp[position] = attribute
    this.setState({ calculationAttributes: calAttributesTemp });

  }
  onAttributeSave = () => {
    var obj = {
      list: this.state.calculationAttributes
    }
    this.props.updateBatchCalculateAttribute(obj).then(() => {
      this.props.getCalculateAttribute({ "assetid": this.state.assetId })
    })
  }
  render() {
    return (
      <div>
        {/* {console.log(this.state.calculationAttributes)} */}
        {
          this.state.calculationAttributes.map((ca, index) => {
            if (ca.status > 0) {
              return (
                <CalculationAttributeNode
                  calculatedAttribute={ca}
                  index={index}
                  deleteAttribute={this.deleteAttribute}
                  onAttributeChange={this.onAttributeChange}
                />
              )
            }
          })
        }
        <Row>
          <Button outline color='custom' onClick={() => this.addAttribute()}>
            Add Calculation Attribute
          </Button>
          <Button outline color='custom' onClick={() => this.onAttributeSave()}>
            Save Calculation Attributes
          </Button>
        </Row>
      </div>
    )
  }
}

class CustomAttributes extends Component {
  constructor(props) {
    super(props);
  }



  render() {
    return (
      <Row>
        {this.props.attrmetas && this.props.attrmetas.map(attrmeta => {
          let attribute = this.props.attributes.find(({ attmetaid }) => attmetaid == attrmeta.attributemetaid)
          //  
          let type = type => {
            switch (type) {
              // Boolean and list need special case in EditableAttribute component
              //  WIP
              //      currently unimplemented

              // case 'boolean':
              //   return 'bool'
              // case 'list':
              //   return 'list'
              case 'image':
                return 'image'
              case 'date':
                return 'date'
              case 'num':
                return 'number'
              case 'string':
              default:
                return 'text'
            }
          }
          if (attrmeta.type !== 'image') {
            return (
              <EditableAttribute key={attrmeta.attributemetaid}

                name={attrmeta.name + ': ' + attrmeta.type} defaultValue={attribute && attribute.value}
                type={type(attrmeta.type)}
                onSave={newVal => {
                  let id = attribute ? attribute.id : 0
                  // console.log(this.props.id)
                  // console.log(id)
                  // console.log(newVal)
                  if (attrmeta.type === 'num') {

                    newVal = Number(newVal);
                  }
                  let data = attribute
                    ? {
                      value: newVal
                    }
                    : {
                      assetid: 1,
                      attmetaid: attrmeta.attributemetaid,
                      attmetaname: attrmeta.name,
                      value: newVal,
                    };
                  let param = {
                    assetid: this.props.id,
                    attmetaid: attrmeta.attributemetaid,
                    value: newVal
                  };
                  // this.props.updateAssetAttribute({
                  //   id: this.props.id,
                  //   data: {
                  //     id,
                  //     data
                  //   }
                  // })
                  this.props.updateAssetAttribute(param);
                }}
              />

            )
          }
          else {
            return (
              <UploadImageAttribute
                name={attrmeta.name + ': ' + attrmeta.type}
                defaultValue={attribute && attribute.value}
                type={type(attrmeta.type)}
                onSave={(file) => {
                  let assetid = this.props.id;
                  // let attributeid = attribute ? attribute.id : 0;
                  let attributeid = attrmeta.attributemetaid;
                  this.props.updateImageAttribute(assetid, attributeid, file);
                }}
              />
            )
          }
        })}
        {/* {this.props.attributes.map(attribute => (
            <EditableAttribute key={attribute.id} name={attribute.attmetaname} defaultValue={attribute.value}
              deleteAttribute={() => this.toggleDeleteModal(attribute.id)}
              onSave={newVal => {
                this.props.updateAssetAttribute({
                  id: this.props.id,
                  data: {
                    id: attribute.id,
                    value: newVal
                  }
                })
              }}
            />
          ))} */}
      </Row>
    )
  }
}

function LabelSelectionInput(props) {
  return (
    <FormGroup row>
      <Col md={3} style={{ textAlign: 'left' }}>
        <Label htmlFor={props.forIdName}>{props.label}</Label>
      </Col>
      <Col md={8}>
        <SingleSelection
          {...props} />
      </Col>
    </FormGroup>
  )
}

function LabelTextInput({ forIdName, onChange, label, value, big }) {
  return (
    <FormGroup row>
      <Col md={3} style={{ textAlign: 'left' }}>
        <Label htmlFor={forIdName}>{label}</Label>
      </Col>
      <Col md={8}>
        <Input type={`text${big ? 'area' : ''}`} width="90%"
          // style={{ borderColor: err ? 'red' : '' }}
          value={value}
          id={forIdName}
          name={forIdName}
          onChange={onChange} />
      </Col>
    </FormGroup>
  )
}

const formatSelVals = [
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'List', value: 'list' },
  { label: 'Text', value: 'string' },
  { label: 'Number', value: 'num' }]
