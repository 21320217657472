/* eslint-disable */
import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row } from 'reactstrap';
import { getPageContent } from '../../../../redux/actions/userAction';
import { getCurrentUser } from '../../../../factories/auth';
import Spinner from "react-bootstrap/Spinner";

class DashboardInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            today: new Date()
        }
        this.userObj = getCurrentUser()
    }
    componentDidMount() {
        this.props.getPageContent({ "content": "Telecom App" });
    }

    dataFormater = (cell) => {
        if (isNaN(cell)) {
            return 'N/A'
        }
        else {
            cell = cell / 1024;
        if(cell > 900){
            return `${(cell / 1024).toFixed(1)} GB`;

        } else {
            return `${(cell).toFixed(1)} MB`;
        }
        }


        // number = NaN
        // if (isNaN(number)) number = 'N/A';


    }
    renderSpinner() {
        return <Spinner
            className="table-spinner"
            as="span"
            animation="border"
            // variant="success"
            // size="sm"
            // role="status"
            // aria-hidden="true"
            style={{ display: 'inline-block', margin: 'auto', position: "absolute" }}
        />;
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    render() {
        const { pageContentList } = this.props
        var textDisplay;
        let newSIM = 0;
        /*let identyToStatusAndAct = {};//identy=>{status:...,act:......}*/
        let activityReportList = this.props.activityReportList;
        if (activityReportList && activityReportList.length > 0) {
            for (let i = 0; i < activityReportList.length; i++) {
                if(activityReportList[i].activity === "ACTIVATION"
                    && activityReportList[i].status === "success"
                    && new Date(activityReportList[i].updateDate).getMonth() === this.state.today.getMonth()
                    && new Date(activityReportList[i].updateDate).getFullYear() === this.state.today.getFullYear()){
                    newSIM+=1;
                }
            }
        }
        /*if (this.props.activeSIMList && this.props.activeSIMList.length > 0) {
            newSIM = this.props.activeSIMList.filter(
                (sim) =>
                (new Date(sim.activeDate).getMonth() ===
                    this.state.today.getMonth() &&
                    new Date(sim.activeDate).getFullYear() ===
                    this.state.today.getFullYear()
                    && identyToStatusAndAct[sim.identifierValue]
                    && identyToStatusAndAct[sim.identifierValue].status == "success"
                    && identyToStatusAndAct[sim.identifierValue].act == "ACTIVATION"
                )
            ).length;
        }*/


        if (pageContentList.includes("Total Active Devices")/* TEISA */) {
            textDisplay = 'Total Active Devices'
        } else {
            textDisplay = 'Total Active Connections'
        }
        return (
            <Row
                className="box-container"
            >

                {this.userObj.companyCode && this.userObj.companyCode == "TEISA" ?
                    <div
                        className="box"
                    >
                        {this.props.loading ? this.renderSpinner() : null}
                        {textDisplay}
                        <Row>
                            <h2>
                                {(this.props.activeSIMList != null && !this.props.loading) ? <span>{this.props.activeSIMList.length}</span> : <span>0</span>}
                            </h2>
                        </Row>
                    </div>
                    : <div
                        className="box"
                    >
                        {this.props.loading ? this.renderSpinner() : null}
                        {textDisplay}
                        <Row>
                            <p>
                                Active:
                                {
                                    (this.props.activeSIMList != null && !this.props.loading) ? <span style={{ fontWeight: "bold" }}
                                >
                                    {this.props.activeSIMList.length}
                                </span>
                                    :
                                    <span
                                        style={{ fontWeight: "bold" }}
                                    >
                                        0
                                    </span>
                                }
                            </p>
                        </Row>
                        <Row>
                            <p>Inactive : {(this.props.inactiveSIMList != null && !this.props.loading) ? <span style={{ fontWeight: "bold" }}>{this.props.inactiveSIMList.length}</span> : <span style={{ fontWeight: "bold" }}>0</span>}</p>
                        </Row>
                        <Row>
                            <p>Suspend : {(this.props.suspendedSIMList != null && !this.props.loading) ? <span style={{ fontWeight: "bold" }}>{this.props.suspendedSIMList.length}</span> : <span style={{ fontWeight: "bold" }}>0</span>}</p>
                        </Row>
                    </div>}

                {this.userObj.companyCode && this.userObj.companyCode == "TEISA" ?
                    <div
                        className="box"
                    >
                        Total No. of Frames
                        {this.props.loading ? this.renderSpinner() : null}
                        <h2>
                            {(this.props.totalUsage!=null && !this.props.loading) ? (this.props.totalUsage) : 0}
                        </h2>
                        {/* if more than 5 numbers, convert to GB */}
                    </div>
                    :
                    <div
                        className="box"
                    >
                        Current Period Usage
                        {this.props.loading ? this.renderSpinner() : null}
                        <h2>
                            {!this.props.loading ? this.dataFormater(this.props.totalUsage) : 0}
                        </h2>
                        {/* if more than 5 numbers, convert to GB */}
                    </div>}

                {/* {this.userObj.companyCode && this.userObj.companyCode == "TEISA" ?
                    null : <div className="box">
                        Added This Period
                        {this.props.loading ? this.renderSpinner() : null}
                        <h2>{!this.props.loading ? newSIM : 0}</h2>
                    </div>} */}
                {/* {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&  style={ display: "none" }} */}
                {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                    <div
                        className="box"
                    >
                        Added This Period
                        {this.props.loading ? this.renderSpinner() : null}
                        <h2>
                            {!this.props.loading ? newSIM : 0}
                        </h2>
                    </div>
                }
            </Row>
        );

    }
}

function mapStateToProps(state) {
    const { pageContentList, totalUsage } = state.user
    /*let { activityReportList } = state.inventory;*/
    const props = { pageContentList, totalUsage };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getPageContent
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardInfo);
