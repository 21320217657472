/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Col, Row, Button, Container, Input } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { removeUser, getUserDevicetypeList, upsertUserDevicetypeList } from '../../../redux/actions/userAction';
import { groupBy } from '../../../factories/utils';
import log from 'loglevel';

class CompanyDeviceTypeForm extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    // static getDerivedStateFromProps(nextProps, prevState){}

    componentWillReceiveProps(nextProps) {

        if (nextProps.companyDevicetypeList && nextProps.companyDevicetypeList !== this.state.rawDevicetypeList) {

            let devicetypeMap = [];
            let deviceTypeGroup = groupBy(nextProps.companyDevicetypeList, devicetype => devicetype.devicetypeid);
            // deviceTypeGroup.forEach((val, key, map)=>{
            //     let deviceType = val[0];
            //     let companyCode = '';
            //     let companyName = '';
            //     let delimiter = '';
            //     let access = 2;
            //     val.forEach((item, index) => {
            //         companyName +=  delimiter + item.companyname
            //         companyCode +=  delimiter + item.companycode
            //         delimiter = ', ';
            //         if (nextProps.companyObj && item.companycode == nextProps.companyObj.companyCode){
            //             access = 1;
            //         }
            //     });

            //     let mapItem = {
            //         devicetypeid : deviceType.devicetypeid,
            //         devicetypename: deviceType.devicetypename,
            //         companycode: companyCode,
            //         companyname: companyName,
            //         access: access,
            //     };
            //     devicetypeMap.push(mapItem);
            // });

            this.setState({
                rawCompanyObj: nextProps.companyObj,
                rawDevicetypeList: nextProps.companyDevicetypeList,
                deviceTypeGroup: deviceTypeGroup,
                // devicetypeMap: devicetypeMap,
            })
        }
    }

    // ----------------------------------------------------------------------------------------------

    onSelectChange = (e, row) => {
        e.preventDefault();
        let value = Number(e.target.value);
        let deviceTypeId = row.devicetypeid
        // row.access = value;
        //  
        this.setState((preState) => {
            //  
            //  
            // log.debug('device type select', deviceTypeId);
            if (preState.deviceTypeGroup && this.props.companyObj) {
                let foundItem = preState.deviceTypeGroup.get(deviceTypeId);
                if (value === 1) {
                    let foundCom = foundItem.find((value, index) => (value.companycode == this.props.companyObj.companyCode));
                    // log.debug('device type select foundCom', foundItem);
                    if (!foundCom) {
                        let newCom = {
                            id: 0,
                            companycode: this.props.companyObj.companyCode,
                            companyname: this.props.companyObj.companyName,
                            devicetypeid: row.devicetypeid,
                            devicetypename: row.devicetypename,
                            createTime: null,
                            creator: null,
                        }
                        foundItem.push(newCom);
                    }
                } else if (value === 2) {
                    foundItem = foundItem.filter((value, index) => (value.companycode != this.props.companyObj.companyCode));
                    // log.debug('device type select foundItem', foundItem);
                    preState.deviceTypeGroup.set(deviceTypeId, foundItem);
                }
                return ({
                    deviceTypeGroup: preState.deviceTypeGroup,
                });
            }
        }
        );

        // row.access = e.target.checked;
        // this.setState((preState)=>{
        //         //  
        //         //  
        //         return({
        //             devicetypeMap: preState.devicetypeMap,
        //         });
        //     }
        // );
    }

    onSubmit = () => {
        let companyDeviceTypeArr = [];
        if (this.state.deviceTypeGroup) {
            this.state.deviceTypeGroup.forEach((val, key, map) => {
                companyDeviceTypeArr = [...companyDeviceTypeArr, ...val];
            });
        }
        let companyDeviiceTypeObj = companyDeviceTypeArr.filter((item, index) => (item.companycode === this.props.companyObj.companyCode));

        this.props.updateCompanyDeviceList(this.props.companyObj.companyCode, companyDeviiceTypeObj);
        this.props.toggle();
    }

    onClose = () => {
        this.props.toggle();
    }

    cellAuthSelection(cell, row, enumObject, index) {
        return (
            <select
                name={`${index},user_role`}
                // value={this.state.data[index]['user_role']}
                onChange={(e) => this.onSelectChange(e, row)}
                value={cell}
            >
                <option value={1}>Assigned</option>
                <option value={2}>Not Assigned</option>
            </select>

            // <Input type="checkbox"
            //     onClick={(e) => {this.onSelectChange(e, row)}} 
            // />
        );
    }

    render() {
        const options = {}
        let devicetypeMap = [];
        if (this.props.companyObj && this.state.deviceTypeGroup) {
            this.state.deviceTypeGroup.forEach((val, key, map) => {
                let deviceType = val[0];
                let companyCode = '';
                let companyName = '';
                let delimiter = '';
                let access = 2;
                val.forEach((item, index) => {
                    if (item.companycode && item.companycode.length > 0) {
                        companyName += delimiter + item.companyname
                        companyCode += delimiter + item.companycode
                        delimiter = ', ';
                    }
                    if (item.companycode == this.props.companyObj.companyCode) {
                        access = 1;
                    }
                });
                // log.debug('deviceTypeGroup debug', val);
                // log.debug('deviceTypeGroup debug', deviceType);
                if (deviceType) {
                    let mapItem = {
                        devicetypeid: deviceType.devicetypeid,
                        devicetypename: deviceType.devicetypename,
                        companycode: companyCode,
                        companyname: companyName,
                        access: access,
                    };
                    devicetypeMap.push(mapItem);
                }
            });
            //  
            //  
            //  
        }


        return (
            <div style={{ textAlign: 'right' }}>
                <br />
                <BootstrapTable
                    data={devicetypeMap}
                    options={options}
                    height={400}
                    tableHeaderClass='header_white'>

                    <TableHeaderColumn dataField='devicetypename'>
                        {/* <Trans i18nKey="system">System</Trans> */}
                        Device Type Name
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='devicetypeid' isKey={true}>
                        <Trans i18nKey="id">Device Type ID</Trans>
                    </TableHeaderColumn>
                    <TableHeaderColumn dataField='companyname'>
                        Current Company Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        // dataField='user_role'
                        dataField='access'
                        dataFormat={this.cellAuthSelection.bind(this)}
                    >
                        <Trans i18nKey="action">Assigned</Trans>
                    </TableHeaderColumn>
                    {/* <TableHeaderColumn
                    dataField='user_group'
                    dataFormat={this.cellGroupSelection.bind(this)}
                >
                    <Trans i18nKey="action">Group</Trans>
                </TableHeaderColumn> */}
                </BootstrapTable>
                <br />
                <br />
                {/* <hr /> */}
                <Button color='custom' type="button" onClick={this.onSubmit} disabled={!this.state.deviceTypeGroup || this.state.deviceTypeGroup.length === 0} >
                    Submit
                </Button>
                <Button outline color='secondary' type="reset" onClick={this.onClose}>
                    Close
                </Button>
            </div>
        );
    }
}

export default CompanyDeviceTypeForm;