/* eslint-disable */
import axios from 'axios';
import {
    API_ADDR,
    API_ROOT_URL,
    SAVE_USER_DATA,
    SAVE_USER_DATA_STARTED,
    SAVE_USER_DATA_SUCCEED,
    SAVE_USER_DATA_FAILED,
    SUCCESS_MSG
} from '../../../constants/actionTypes';

function saveDetailStarted() {
    return {
        type: SAVE_USER_DATA_STARTED
    }
}

function saveDetailSucceed(res) {
    if (res.data.status === SUCCESS_MSG) {
        return {
            type: SAVE_USER_DATA_SUCCEED,
            payload: res
        }
    }
    else {
        return {
            type: SAVE_USER_DATA_FAILED,
            payload: res
        }
    }
}

function saveDetailFailed(err) {
    return {
        type: SAVE_USER_DATA_FAILED,
        payload: err
    }
}

export function saveDetail(criteria) {
    const url = `${API_ADDR}${API_ROOT_URL}${SAVE_USER_DATA}`;

    const promise = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            email: criteria.email,
            fullName: criteria.fullName,
            data: criteria.data,
            userName: criteria.userName,
            token: sessionStorage.getItem('authToken')
        }
    });

    return function (dispatch) {
        dispatch(saveDetailStarted());
        promise.then(
            res => {
                dispatch(saveDetailSucceed(res));
            }
        ).catch(
            err => {
                dispatch(saveDetailFailed(err));
            }
        );
    }
}