/* eslint-disable */
import React, { Component } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Collapse } from 'reactstrap';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import DeviceSetCard from './DeviceSetCard'; 
import DeviceList from './DeviceList'; 
import Uploader from './Uploader' ;
import InventoryList from './InventoryList';
import DeviceMasterList  from './DeviceMasterList';
import InventoryPurchaseBoard from './InventoryPurchaseBoard';
import InventoryReceive from './InventoryReceive';
import InventoryInvoice from './InventoryInvoice';
import InventoryReturn from './InventoryReturn';
import InventoryPayment from './InventoryPayment';
import { 
    uploadFile, 
    getInventoryList,    
    getDeviceMasterList, 
    clearDeviceMasterList,
    getSupllierList,
    getSupllierItemList,
    sendOrder,
} from '../../../redux/actions/inventoryActions';

class InventoryBoard extends Component{
    constructor(props){
        super(props);
        this.state = {
            showDetailCard: false,
            selectedCollapseId: '1',
            selectedInventory: null, 
            selectedDevice: null,
            activeTab: '1',
        };
    }

    componentDidMount(){
        this.props.getInventoryList()
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
           this.setState({ activeTab: tab })
        }
     }

    handleRowClick = (row) => {
        let selectedId = row.upc;
        let company = row.company;
        this.setState({
            showDetailCard: true,
            upc : selectedId,
            company : company,
        });
    }

    handleCancelOnclick = () => {
        this.setState({
            showDetailCard: false,
        });
    }

    renderUploader(){
        return(
            <Uploader />
        );    
    }

    renderDetailCard(){
        if(this.state.showDetailCard){
            return(
                <DeviceSetCard  handleCancelOnclick = {this.handleCancelOnclick}
                             upc = {this.state.upc}
                             company = {this.state.company}/>
            )
        }else{
            return null;
        }
    }

    renderDeviceList(){
        return(
            <DeviceList handleRowClick = {this.handleRowClick}/>
        );    
    }

    // render(){
    //     return(
    //         <div style={{width: '100%'}}>
    //             {this.renderUploader()}
    //             {this.renderDeviceList()}
    //             {this.renderDetailCard()}
    //         </div>
    //     )
    // }

    toggleCollaps = (tagId) => {
        this.setState({
            selectedCollapseId: tagId,
        })
    }

    handleInventroyClicked = (upccode) => {
        // this.setState({
        //     selectedInventory: upccode,
        // });
         
        this.props.getDeviceMasterList(upccode)
        this.toggleCollaps('2');
    }

    handleDeviceClicked = (deviceid) => {
        this.toggleCollaps('3');
    }
    handleDeviceListBackClick = () => { 
        this.toggleCollaps('1');
    }


    onDeviceListEntering = () => {

    }

    onDeviceListExited = () => {

    }

    onDeviceDetailEntering = () => {

    }

    onDeviceDetailExited = () => {
        this.props.clearDeviceMasterList();
    }

    renderInventoryList(){
         
        return (
            <div>
                <Collapse 
                    isOpen={'1' === this.state.selectedCollapseId}
                >
                    {/* {this.renderUploader()} */}
                    <InventoryList
                        inventoryList = {this.props.inventoryList}
                        handleInventroyClicked = {this.handleInventroyClicked}
                    />
                </Collapse>
                <Collapse 
                    isOpen={'2' === this.state.selectedCollapseId}
                    onEntering={this.onDeviceListEntering}
                    onExited={this.onDeviceListExited} 
                >
                    <DeviceMasterList
                        // upccode = {this.state.selectedInventory}
                        deviceMasterList = {this.props.deviceMasterList}
                        getDeviceMasterList = {this.props.getDeviceMasterList}
                        handleDeviceClicked = {this.handleDeviceClicked}
                        handleDeviceListBackClick = {this.handleDeviceListBackClick}
                    />
                </Collapse>
                <Collapse 
                    isOpen={'3' === this.state.selectedCollapseId}
                    onEntering={this.onDeviceDetailEntering}
                    onExited={this.onDeviceDetailExited}
                >
                </Collapse>
            </div>
        );
    }

    renderInventoryPurchase = () => {
        return (
            <InventoryPurchaseBoard
                userObj = {this.props.userObj}
                supplierList = {this.props.supplierList}
                supplierItemList = {this.props.supplierItemList}
                getSupllierList = {this.props.getSupllierList}
                getSupllierItemList = {this.props.getSupllierItemList}
                sendOrder = {this.props.sendOrder}
            />
        )
    }

    renderInventoryReceive = () => {
        return (
            <InventoryReceive/>
        );
    }

    renderInventoryInvoice = () => {
        return (
            <InventoryInvoice/>
        );
    }

    renderInventoryReturn = () => {
        return(
            <InventoryReturn/>
        );
    }

    renderInventoryPayment = () => {
        return(
            <InventoryPayment/>
        );
    }

    render() {
        let assetList = this.props.assetList
        return (
           <Col md={12} >
              {/* <RenderTestingButtons props={this.props} state={this.state} /> */}
              < NavTabs activeTab={this.state.activeTab} toggleTab={this.toggleTab} />
              <TabContent activeTab={this.state.activeTab}>
                 <TabPane tabId="1" >
                    <Card>
                       <div className ='card-pg'>
                           {this.renderInventoryList()}
                       </div>
                    </Card>
                 </TabPane>
                 <TabPane tabId="2">
                    <Card>
                       <div className ='card-pg'>
                           {this.renderInventoryPurchase()}
                       </div>
                    </Card>
                 </TabPane>
                 <TabPane tabId="3">
                    <Card>
                       <div className ='card-pg'>
                           {/* Nothing here */}
                           {this.renderInventoryReceive()}
                       </div>
                    </Card>
                 </TabPane>
                 <TabPane tabId="4">                    
                    <Card>
                       <div className ='card-pg'>
                           {/* Nothing here */}
                           {this.renderInventoryInvoice()}
                       </div>
                    </Card>
                 </TabPane>
                 <TabPane tabId="5">                    
                    <Card>
                       <div className ='card-pg'>
                           {/* Nothing here */}
                           {/* Coming soon .... */}
                           {this.renderInventoryPayment()}
                       </div>
                    </Card>
                 </TabPane>
                 <TabPane tabId="6">                    
                    <Card>
                       <div className ='card-pg'>
                           {/* Nothing here */}                           
                           {this.renderInventoryReturn()}
                       </div>
                    </Card>
                 </TabPane>
              </TabContent>
           </Col>
        );
     }

}


function NavTabs({ activeTab, toggleTab }) {
    return (
       <Nav tabs style={{
          border: 'none'
       }}>
          <NavItem style={{ cursor: 'pointer' }}>
             <NavLink /*style={{
                border: 'none'
             }}*/
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggleTab('1'); }}>
                <h4><b>Inventory</b></h4>
             </NavLink>
          </NavItem>
          <NavItem style={{ cursor: 'pointer' }}>
             <NavLink /*style={{
                border: 'none'
             }}*/
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggleTab('2'); }}>
                <h4><b>Purchase</b></h4>
             </NavLink>
          </NavItem>
          <NavItem style={{ cursor: 'pointer' }}>
             <NavLink /*style={{
                border: 'none'
             }}*/
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggleTab('3'); }}>
                <h4><b>Receive</b></h4>
             </NavLink>
          </NavItem>
          <NavItem style={{ cursor: 'pointer' }}>
             <NavLink /*style={{
                border: 'none'
             }}*/
                className={classnames({ active: activeTab === '4' })}
                onClick={() => { toggleTab('4'); }}>
                <h4><b>Invoice</b></h4>
             </NavLink>
          </NavItem>
          <NavItem style={{ cursor: 'pointer' }}>
             <NavLink /*style={{
                border: 'none'
             }}*/
                className={classnames({ active: activeTab === '5' })}
                onClick={() => { toggleTab('5'); }}>
                <h4><b>Payment</b></h4>
             </NavLink>
          </NavItem>
          <NavItem style={{ cursor: 'pointer' }}>
             <NavLink /*style={{
                border: 'none'
             }}*/
                className={classnames({ active: activeTab === '6' })}
                onClick={() => { toggleTab('6'); }}>
                <h4><b>Return</b></h4>
             </NavLink>
          </NavItem>
       </Nav>
    )
 }

// export default InventoryBoard;

function mapStateToProps(state) {
    const props = state.inventory;
    return props;
}
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ 
                uploadFile, 
                getInventoryList,
                getDeviceMasterList,    
                clearDeviceMasterList, 
                getSupllierList,
                getSupllierItemList,
                sendOrder,
            }, dispatch);
}
  
export default connect(mapStateToProps, mapDispatchToProps)(InventoryBoard);