/* eslint-disable */
import React, { Component, Fragment } from "react";
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup } from 'reactstrap';
import Select from 'react-select';
import StepTemplate from './StepTemplate';
import {getActionById, upsertAction} from '../../../redux/actions/jobAction';

class BasicAlertInfoForm extends Component {
    constructor(props){
        super(props);
        this.state={
            triggerid: 0,
            weekOption :  [
                { value: 1, label: 'Mon' },
                { value: 2, label: 'Tue' },
                { value: 3, label: 'Wed' },
                { value: 4, label: 'Thu' },
                { value: 5, label: 'Fri' },
                { value: 6, label: 'Sat' },
                { value: 7, label: 'Sun' },
            ],
            eventName: null,
            selectedPeriod: null,
        }
    }

    // ---------------------------------------------------------
    componentDidMount(){
        // this.props.getActionById(this.props.actionPassId);
        // if (this.props.actionId != 0 ) {
        //     this.props.getActionById(this.props.actionId);
        // }       
    };
    componentWillReceiveProps(nextProps){
         
         
        if (nextProps.actionInfo && nextProps.actionInfo.length > 0 && nextProps.actionId > 0 && nextProps.actionId !== this.props.actionId){
        }
    }
    // ---------------------------------------------------------

    // handleTriggerChange = (e) => {
    //     let value = Number(e.target.value);
    //     this.setState({
    //         triggerid : value,
    //     });
    // }

    handleInputChange = (e) => {
         
         
        // this.setState({
        //     [e.target.name]: e.target.value
        //  });
        //  
        this.props.handleInputChange(e);
    }

    // handlePeriodsSelect = (value) =>{
    //      
    //      
    //     this.setState({
    //         selectedPeriod: value,
    //     });
    // }

    renderActionForm=()=>{
        let eventName = this.props.actionInfo ? this.props.actionInfo.actionname : '';
        return(
            <Form>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="eventName"  style={{display: 'inline', width: "100%"}} >Event Name</Label>
                                <Input 
                                    type="text" 
                                    id="eventName" 
                                    name="actionname" 
                                    width="100%" 
                                    value={eventName} 
                                    onChange={this.handleInputChange} 
                                    valid = {eventName }
                                    invalid = {!eventName}
                                    required={'required'}
                                >
                                </Input>     
                            </Col>                   
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label for="description"  style={{display: 'inline', width: "100%"}} >Description</Label>
                                <Input 
                                    type="textarea" 
                                    id="description" 
                                    name="actiondescription" 
                                    width="100%" 
                                    value={this.props.actionInfo ? this.props.actionInfo.actiondescription : ''} 
                                    onChange={this.handleInputChange} 
                                >
                                </Input>  
                            </Col>                      
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    renderNotificationForm = () => {

    }

    updateActionInfo = () => {

    }

    // handleBack = () => {
    //     this.props.onBack();
    // }

    // handleNext = () => {
    //     // if (this.updateActionInfo()) {
    //     //     this.props.handleNext();
    //     // }else{
    //     //     // todo alert notification;
    //     // };  
    //     // alert(this.state.triggerTime);   
    //     if (! this.validate()){
    //         return;
    //     }
    //     if (sessionStorage.getItem('currentUserObj')){
    //         let createDate =new Date().toISOString().split('.')[0]; //.replace('T', ' ');
    //         let weekday = [];
    //         let actiontime = '00:00:00';
    //         if(this.state.selectedPeriod){
    //             this.state.selectedPeriod.forEach((item, index)=>{
    //                 weekday.push(item.label);
    //             });
    //         }
    //         let timeRegex = /^\d{2}[:]\d{2}$/;
    //         if (this.state.triggerTime && timeRegex.test(this.state.triggerTime)){
    //              
    //              
    //             actiontime = this.state.triggerTime + ':00';
    //         }
            
    //         try{
    //             let userObj = JSON.parse(sessionStorage.getItem('currentUserObj'));
    //             let actionObj = {
    //                 "actionid": this.props.actionPassId,
    //                 "actionname": this.state.eventName,
    //                 "actiondescription": this.state.description,
    //                 "triggertype": this.state.triggerid,
    //                 "weekday": JSON.stringify(weekday),
    //                 "actiontime": actiontime,
    //                 "createtime": createDate,
    //                 "createdby_user_id": userObj.userName,
    //                 "company_code": userObj.department,
    //                 "active" : true,
    //                 "action": "Alert",
    //             }
    //              
    //             this.props.upsertAction(actionObj);
               
    //         }catch(e){
    //             console.error(e);
    //         }   
    //     }
    // }

    // validate = () => {
    //     if (! this.state.eventName || this.state.eventName.length === 0) {
    //         toast.error('Please input a event name.');
    //         return false;
    //     } 

    //     if (this.state.triggerid === '1' && (!this.state.selectedPeriod || this.state.selectedPeriod.length === 0)) {
    //         toast.error('Please choose time.');
    //         return false;
    //     }

    //     return true;
    // }

    render(){
        return(
            // <StepTemplate
            //     classes = {this.props.classes}
            //     canGoBack = {this.props.canGoBack}
            //     canGoForward = {this.props.canGoForward}
            //     onBack = {this.handleBack}
            //     onNext = {this.handleNext}
            //     onSkip = {this.props.onNext}
            //     onCancel = {this.props.onCancel}
            //     text = {this.props.text}
            // >
            //     {this.renderActionForm()}
            // </StepTemplate>
            <Fragment>
                {this.renderActionForm()}
            </Fragment>
        );
    }
}

export default BasicAlertInfoForm;

// const mapStateToProps = (state) => {
//     return state.job;
// };

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({getActionById, upsertAction}, dispatch);
// };

// export default connect(mapStateToProps, mapDispatchToProps)(BasicAlertInfoForm);