/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../../shared/components/Table/tableElements';

import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';

class SelectClientInventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            inactiveSIMList: []
        }
    }
    componentDidMount() {
    }
    componentWillReceiveProps(nextProps) {
        let selectedKey = [];
        if (nextProps.cart.length > 0) {
            selectedKey = nextProps.cart.map(inv => inv.identifierValue);
        }
        if (nextProps.cart.length == 0) {
            selectedKey = [];
        }
         //filter SIM by carrier
         this.setState({ 
            selected: selectedKey,
            inactiveSIMList: nextProps.inactiveSIMList
         });
    }
    handleOnNext = () => {
        this.props.onNext()
    }
    handleOnBack = () => {
        this.props.onBack();
    }
    render() {
        const selectRowProp = {
            mode: 'checkbox',
            columnWidth: '40px',
            selected: this.state.selected,
            onSelect: this.props.handleSelectInventoryRow,
            onSelectAll: this.props.handleSelectInventoryAll,
        }
        const options = {
            noDataText: "No Inventory Found",
            sizePerPage: 50,  // which size per page you want to locate as default
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '1000', value: 1000
            }, {
                text: 'All', value: this.state.inactiveSIMList.length
            }],
        }
        return (
            <div>
                <Row>
                    <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <BackButton
                            onClick={this.handleOnBack}
                            label="Back"
                            size={30}
                            color='black'
                        />
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    </Col>
                    <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NextButton
                            onClick={this.handleOnNext}
                            label="Next"
                            size={30}
                            color='black'
                        />
                    </Col>
                </Row>
                <h3>Activate SIM(s)</h3>
                <br />
                <h4># of selected SIMs: <b>{(this.state.selected.length) ? this.state.selected.length : 0}</b></h4>
                <BootstrapTable
                    data={this.state.inactiveSIMList}
                    tableHeaderClass='header_white'
                    pagination={true}
                    bordered={false}
                    ref='table'
                    selectRow={selectRowProp}
                    options={options}
                >
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} caretRender={sortCaret}>SIM Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='identifierType' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Type' }} caretRender={sortCaret}>SIM Identifier Type</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Value' }} caretRender={sortCaret}>SIM Identifier Value </TableHeaderColumn>


                </BootstrapTable>

            </div >
        );
    }
}
export default SelectClientInventory;