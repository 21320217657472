/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Select from 'react-select';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { toast } from "react-toastify";
import log from 'loglevel';

class CompanyPaymentInvoice extends Component {
    constructor(props){
        super(props);
        this.state={
            currentAccount: null,
            startDateMoment: moment("2020-11-01"),
            endDateMoment: moment("2020-11-30"),
            invoicehtml: '<div>No Invoice data</div>',
            showDetails: false,
        }
    }
    componentDidMount(){
        //  
    }
    componentWillReceiveProps(nextProps, nextContext){
        //  
        if (nextProps.activeTab && nextProps.activeTab != "8" && this.state.clear == true){
            //  
            this.props.clearCustomerInvoiceHistory();
            this.setState({
                currentAccount: null,
                startDateMoment: moment("2020-11-01"),
                endDateMoment: moment("2020-11-30"),
                clear: false
            });
        } else {
            this.setState({
                clear: true
            });
        }
    }
    componentWillUnmount(){
        //  
        this.props.clearCustomerInvoiceHistory();
        this.setState({
            currentAccount: null,
            startDateMoment: moment("2020-11-01"),
            endDateMoment: moment("2020-11-30"),
        })
    }
    // -------------------------------------------------
    toggle = () => {
        this.setState(prevState => ({
            showDetails: !prevState.showDetails
        }));
    }
    // -------------------------------------------------
    loadPaymentHistory = () => {
        let startDate = null;
        let endDate = null;
        if (this.state.startDateMoment && this.state.endDateMoment){
          startDate = this.state.startDateMoment.format('YYYY-MM-DD');   
          endDate = this.state.endDateMoment.format('YYYY-MM-DD');             
        }else{
          log.error('Have to choose start and end date');
          toast.error('Have to choose start and end date');
          return 
        }     
    
        if (this.state.currentAccount) {
          var param = {
            // "accId": this.state.currentAccount.id, 
            "objId": this.state.currentAccount.id,
            "fromDate": startDate,
            "toDate": endDate
            // "fromDate": "2020-04-30",
            // "toDate":"2020-05-10"
          };
          this.props.getCustomerInvoiceHistory(param);
        }else{
          log.error('No account is selected');
          toast.error('No account is selected');
          return 
        }
       
    }
    handleAccountChange = (val) => {
        if (val) {
        }
        log.debug('value', val);
        this.setState({
          currentAccount: val    
        }, () => {
          this.loadPaymentHistory();
        });
     }
     renderAccountDropdown = () => {
        return (
          <Select 
            // isClearable
            // isSearchable
            options = {this.props.accountList}
            getOptionLabel={({ cardMask }) => cardMask}
            getOptionValue={({ id }) => id}
            value={this.state.currentAccount}
            // // defaultValue={}
            onChange = {this.handleAccountChange}
            // styles={customStyles}
            // {...props}
          />
        );
      }
    handleDateRangeChange = ({ startDate, endDate }) => {
        //  
        //  
        this.setState({
            startDateMoment: startDate,
            endDateMoment: endDate
        }, this.loadPaymentHistory)
    }
    renderTimeRange = () => {
        return (
          <DateRangePicker
            isOutsideRange={() => false}
            startDate={this.state.startDateMoment} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={this.state.endDateMoment} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            showClearDates = {true}
            small = {true}
            block = {true}
            // disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
          />
                                                   
        );
    }

    invoiceIdFormater = (cell, row) => {
        return (
            // <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleRowEditClick(row, true);}}>{cell}</a>
            <a href={'#'} onClick={(e) => { e.preventDefault(); this.handleInvoiceClick(cell);}}>{cell}</a>
        );
    }

    handleInvoiceClick=(cell)=> {
        this.props.getCustomerInvoiceDetails(cell)
        this.toggle();
    }

    renderInvoiceDetails = () => {
        let htmlStr = this.state.invoicehtml;
        if (this.props.paymentInvoiceDetails && this.props.paymentInvoiceDetails.invDetail){
            htmlStr = this.props.paymentInvoiceDetails.invDetail
        }
        return(
            <Col md={12}>
                <div dangerouslySetInnerHTML={{ __html: htmlStr }} />
                <div style={{textAlign:'right'}}>
                    <Button onClick={this.toggle}>Back</Button>
                </div>
            </Col>
            
        );
    }

    timeFormat = (cell, row) => {
        // let dataStr ='';
        // if (row){
        //   dataStr = `${row.trxDt} ${row.trxTime}`
        // }
        let UTCTime = new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' })
        return(
            <span>{UTCTime}</span>
        );
    }
    // -------------------------------------------------
    renderInvocieList(){
        let data = [];
        let message = '';
        if (this.props.paymentInvoice && Array.isArray(this.props.paymentInvoice.historyList) && this.props.paymentInvoice.historyList.length > 0) {
          let firstItem = this.props.paymentInvoice.historyList[0];
          if (typeof firstItem === 'string' || firstItem instanceof String) {
            message = firstItem;
          }else{
            data = this.props.paymentInvoice.historyList;
          }
        }
        return(
            <Col md={12}>
                <Card>
                    <CardBody>
                        <Row style={{marginBottom:'15px'}}>
                            <Col md={6}>
                            {this.renderAccountDropdown()}
                            </Col>
                            <Col md={6}>
                            {this.renderTimeRange()}
                            </Col>
                        </Row>
                        <Row mt={3}>
                            <Col md={12}>
                                <BootstrapTable
                                     data={ data }  
                                     tableHeaderClass = 'header_white' 
                                     pagination = {true} 
                                       
                                       
                                     bordered={ false } 
                                     // insertRow 
                                     ref='table' 
                                >
                                    <TableHeaderColumn dataField='invId' isKey={ true } dataFormat={this.invoiceIdFormater} dataAlign="center" width='8%' >Invoice ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField='ordrId' dataAlign="center" dataSort width='8%' >Order ID</TableHeaderColumn> 
                                    <TableHeaderColumn dataField='invTtl' dataAlign="center" dataSort width='8%' >Amount</TableHeaderColumn>
                                    <TableHeaderColumn dataField='provTax' dataAlign="center" dataSort width='8%' >Province Tax</TableHeaderColumn>
                                    <TableHeaderColumn dataField='fedTax' dataAlign="center" dataSort width='8%' >Federal Tax</TableHeaderColumn>
                                    <TableHeaderColumn dataField='discountAmt' dataAlign="center" dataSort width='8%' >Discount</TableHeaderColumn>
                                    <TableHeaderColumn dataField='invAmt' dataAlign="center" dataSort width='8%' >Total Amount</TableHeaderColumn>
                                    <TableHeaderColumn dataField='createDt' dataFormat={this.timeFormat} dataAlign="center" dataSort width='12%' >Create Date</TableHeaderColumn>
                                    <TableHeaderColumn dataField='createDt' dataFormat={this.timeFormat} dataAlign="center" dataSort width='12%' >Update Date</TableHeaderColumn>
                                    <TableHeaderColumn dataField='comment' dataAlign="center" dataSort width='10%' >Comment</TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        );
    }

    render(){
        return(
            <div>
                {/* {this.state.showDetails? 
                    this.renderInvoiceDetails()
                    :
                    this.renderInvocieList()
                } */}
                {/* {this.renderInvocieList()} */}
                <Collapse
                    isOpen = {!this.state.showDetails}
                    onEntering = {()=>{}}
                    onExited = {()=>{}}
                >
                    {this.renderInvocieList()}
                </Collapse>
                <Collapse
                    isOpen = {this.state.showDetails}
                    onEntering = {()=>{}}
                    onExited = {()=>{}}
                >
                    {this.renderInvoiceDetails()}
                </Collapse>
            </div>
        );
    }
}
export default CompanyPaymentInvoice;
