/* eslint-disable */
import React, {Component} from 'react';
import { PieChart, Pie, ResponsiveContainer } from 'recharts';
import { Card, Col } from 'reactstrap'; // import { Card, CardBody, Col } from 'reactstrap';
import WalkIcon from 'mdi-react/WalkIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3} from '../../../../constants/dataConstants';
class Steps extends Component {
  constructor(props){
    super(props);
  }

  render(){
    const {t} = this.props;

    let statusData = this.props.statusData;

    let totalOccupied = 0;
    let data = [];

    if(statusData){

      if (statusData[0] 
        && statusData[0].attributes 
        && statusData[0].attributes.Level2
        && statusData[0].attributes.Level2.length > 0) {

          const level = statusData[0].attributes.Level2[0];
          if (level.value === null) {
            totalOccupied += 0;
          } else {
            totalOccupied += parseInt(level.value)
          }

        }

      data = [{value: parseInt(this.props.latestCalculateResult.Level2), fill: '#f0c7ce' }, {value: PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[2] - parseInt(this.props.latestCalculateResult.Level2), fill: '#efdde3'}];
    }

    let percentage = Math.floor((parseInt(this.props.latestCalculateResult.Level2) / (PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[2].toFixed(1))) * 100);

    return (
      <Col md={6} xl={3} lg={6} sm={12} xs={12}>
        <Card>
          <div className="card-w dashboard__health-chart-card">
            <div className="card__title">
              <h5 className="bold-text">{t('Level 2 Lot Occupancy')}</h5>
            </div>
            <div className="dashboard__health-chart">
              <ResponsiveContainer height={180}>
                <PieChart>
                  <Pie data={data} dataKey="value" cy={85} innerRadius={78} outerRadius={90} />
                </PieChart>
              </ResponsiveContainer>
              <div className="dashboard__health-chart-info">
                <div style={{height: '15px'}}></div>
                <p style={{color:'#f0c7ce'}} className="dashboard__health-chart-number">{percentage}%</p>
                <p className="dashboard__health-chart-units">{this.props.latestCalculateResult.Level2}/{PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[2]}</p>
                <p className="dashboard__health-chart-units">lots occupied</p>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    )
  }
}

Steps.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Steps);