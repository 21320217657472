/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import {Container, Row, Col, Button, Label, } from 'reactstrap';
import AttributeListWidget from './AttributeListWidget';
import {TIME_ZONE} from '../../../constants/dataConstants';

const TortoiseMapInfo = ({ props, toggle, alarmSetObj, statusDataProp }) => {
  let activeTab = "3";
  const asset = props && props.asset ? props.asset : {};
  useEffect(() => {
    if(props){
      console.log("PROPS INFO WINDOW", props)
    }
  }, [props])
  return (
    <div style={{ width: '200px', height: 'fit-content', blockSize: 'fit-content'}}>
      <Row style={{ margin: '0px' }}>
        <Col md={12} sm={12} style={{ textAlign: 'center', paddingRight: '0', paddingLeft: '0', overflow: 'hidden' }}>
          <div style={{width: '50%'}}>
            <AttributeListWidget
              alarmSet={alarmSetObj}
              data={props ? props.attributes : null}
              isSmall={true}
              toggle={toggle}
              activeTab={activeTab}
              style={{height: '150px'}}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TortoiseMapInfo
