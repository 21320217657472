/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row, Button, Input } from 'reactstrap';
import { BackButton, NextButton } from '../../../shared/components/Button/IconButton';


class ClientSpecialLineList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }


    onModalOpen = (plan) => {
        this.props.onPlanOpen(plan)
    }

    dataFormatter = (cell, row) => {
        if (cell != null) {
            return <Button type="button" color='custom' onClick={() => this.onModalOpen(row)} >Edit</Button>

        }
    }

    // lineNumFormatter = (cell, row) => {
    //     if (cell != null) {
    //         return '1'
    //     }
    // }

    dataFormatterPosition = (cell, row) => {
        var specialLinePositionLocal = "Not Applicable"
        if (cell != null) {

            if (cell == 'S') {
                specialLinePositionLocal = 'Start'
            }
            else if (cell == 'E') {
                specialLinePositionLocal = 'End'
            }
        }
        return specialLinePositionLocal;
    }
    render() {

        if (this.props.specialLineList != null) {
            return (

                // <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                //     </div>
                <BootstrapTable
                    data={this.props.specialLineList}
                    striped
                    hover
                    tableHeaderClass='header_white'
                >
                    <TableHeaderColumn row='0' rowSpan='1' dataAlign='center' width="50%" isKey dataField='specialLine'>Special Line</TableHeaderColumn>

                    <TableHeaderColumn row='0' rowSpan='1' dataAlign='center' width="15%" dataField='specialLinePosition' dataFormat={this.dataFormatterPosition.bind(this)} >Special Line Position</TableHeaderColumn>

                    <TableHeaderColumn row='0' rowSpan='1' dataAlign='center' width="15%" dataField='lineNum' /*dataFormat={this.lineNumFormatter}*/>Line Number</TableHeaderColumn>

                    <TableHeaderColumn row='0' rowSpan='1' dataAlign='center' width="20%" dataField='id' dataFormat={this.dataFormatter.bind(this)}>Modify</TableHeaderColumn>
                </BootstrapTable>
            )
            // /* {this.props.specialLineList.map((plan, index) => {
            //     let buttonText = "Edit";
            //     let buttonColor = "primary";

            //     return (



            //                 <div style={{ display: "inline-block", margin: "0px 15px 15px 20px" }}>
            //                     <Card className="pricing-card pricing-card--info" style={{"box-shadow": "0px 3px 12px 4px rgba(0, 0, 0, 0.16)"}}>
            //                         <CardBody className="pricing-card__body">
            //                             <h3 className="pricing-card__plan" style={{ fontWeight: "bold" }}>{plan.specialLine}</h3>
            //                             <hr />
            //                             {(this.props.isAdmin)?<Button className="pricing-card__button" color={buttonColor} onClick={() => this.onModalOpen(plan)}>{buttonText}</Button>:null}
            //                         </CardBody>
            //                     </Card>
            //                 </div>




            //     )

            // )




        }
        else {
            return (
                <div>Loading...</div>
            )
        }
    }


}
export default ClientSpecialLineList;