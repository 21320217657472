/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import {connect } from 'react-redux';
// import {bindActionCreators } from 'redux';
import { Card, CardTitle, CardBody, Col, CardImg, CardGroup, CardDeck, Collapse, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container, Input } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from "prop-types";
// import {Modal} from "react-overlays";
import { Modal, ModalHeader , ModalBody,ModalFooter } from 'reactstrap';
import { Trans } from "react-i18next";
import { toast } from "react-toastify";
import AddPaymentModal from "./payment/addPaymentModal";
import CompanyAccountPaymentComp from './CompanyAccountPaymentComp';
import payment_img from '../../../shared/img/payment-icon.png';
import history_img from '../../../shared/img/history-icon.jpg';
import purchase_img from '../../../shared/img/purchase-icon_m.jpg';


class CompanyAccountOptionsBoard extends Component {
    constructor(props){
        super(props);
        this.state={
            activeTab : '1',
            showPaymentModal: false,
            backClicked: false,
            isPaymentClicked: false,
            hoverPayment: false,
            hoverHistory: false,
            hoverPurchase: false
        }

        
    }

    renderAddModal = () => {
        this.setState({
            showPaymentModal: true,
        });
      }

    onBackClick = (e) => {
        this.props.handleBackClick();
    }

    // handlePaymentClick = (e) => {
    //     this.setState({
    //         showPaymentModal: true,
    //     });
    // }


    handlePaymentClick = () => {
        this.state.isPaymentClicked ?
          this.setState({
            isPaymentClicked: !this.state.isPaymentClicked,
          }) :
          this.setState({
            isPaymentClicked: !this.state.isPaymentClicked,
          })

         
    }
    
    toggleHoverPayment=(e) =>{
        this.setState({hoverPayment: !this.state.hoverPayment}) 
      }

      toggleHoverHistory=(e) =>{
        this.setState({hoverHistory: !this.state.hoverHistory}) 
      }

      toggleHoverPurchase=(e) =>{
        this.setState({hoverPurchase: !this.state.hoverPurchase}) 
      }

    renderPlan = () => {
        
        const styles = {
            middle: {
                transition: '.5s ease',
                opacity: '0',
                position: 'absolute',
                top: '40%',
                left: '40%',
            },

            middle2: {
                transition: '.5s ease',
                opacity: '1',
                position: 'absolute',
                top: '40%',
                left: '40%',
            },
        };
       
        return(
            <div>
                 <Row>
                  <Col md={12}>
                    <Collapse isOpen={!this.state.collapse || this.state.isDetailCollapse || !this.state.isPaymentClicked}
                        onEntering={this.onEntering}
                        onExited={this.onExited}>
                             
                           <CardDeck>
                     <Card  style={{border:'1px solid black', padding: '0px'}}> 
                        
                        {/* <CardImg style={this.state.hoverPayment ? {"opacity": '0.3'}: {"opacity": '1'}}  top width="100%"  src={payment_img} alt="Card image cap" /> */}
                        <CardBody onMouseEnter={this.toggleHoverPayment} onMouseLeave={this.toggleHoverPayment} style={{padding: '0px'}} >
                        {/* <CardImg style={this.state.hoverPayment ? {"opacity": '0.3'}: {"opacity": '1'}}  top width="100%"  src={payment_img} alt="Card image cap" /> */}
                        {/* <Button  style={this.state.hoverPayment ? styles.middle2: styles.middle}  variant="primary" color="link" onClick={this.props.handlePaymentClick}>Payment</Button>  */}
                        <Row>
                            <Col md={8}>
                                <img src={payment_img} alt="Card image cap" width="100%" height='190' style={this.state.hoverPayment ? {"opacity": '0.3'}: {"opacity": '1'}}></img>
                            </Col>
                            <Col md={4} style={{display: 'flex', alignItems: 'center', padding: '0px 15px 0px 0px'}}>
                                {/* <Button color="link" onClick={this.props.handlePaymentClick}><h4>Payment</h4></Button>  */}
                                <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handlePaymentClick()}}><span style={{fontSize:'18px'}}><b>Payment methods</b></span></a>
                            </Col>
                        </Row>
                        {/* <Button color="link" onClick={this.props.handlePaymentClick}><h4>Payment</h4></Button>  */}
                   
                        </CardBody>
                    </Card>
                    <Card style={{border:'1px solid black', padding: '0px'}}>
       
                        <CardBody  onMouseEnter={this.toggleHoverHistory} onMouseLeave={this.toggleHoverHistory} style={{padding: '0px'}}> 
                        {/* <CardImg style={this.state.hoverHistory ? {"opacity": '0.3'}: {"opacity": '1'}}  top width="100%"  src={history_img} alt="Card image cap" />
                    
                            <Button style={this.state.hoverHistory ? styles.middle2: styles.middle} variant="primary" color="custom" onClick={this.props.handleHistoryClick}>History</Button>  */}
                            <Row>
                                <Col md={8}>
                                    <img src={history_img} alt="Card image cap" width="100%" height='190' style={this.state.hoverPayment ? {"opacity": '0.3'}: {"opacity": '1'}}></img>
                                </Col>
                                <Col md={4} style={{display: 'flex', alignItems: 'center', padding: '0px 15px 0px 0px'}}>
                                    {/* <Button color="link" onClick={this.props.handlePaymentClick}><h4>Payment</h4></Button>  */}
                                    <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleHistoryClick()}}><span style={{fontSize:'18px'}}><b>Transactions</b></span></a>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card style={{border:'1px solid black', padding: '0px'}}>
                 
                        <CardBody onMouseEnter={this.toggleHoverPurchase} onMouseLeave={this.toggleHoverPurchase} style={{padding: '0px'}}> 

                        {/* <CardImg style={this.state.hoverPurchase ? {"opacity": '0.3'}: {"opacity": '1'}} top width="100%" src={purchase_img} alt="Card image cap" />
                    
                            <Button style={this.state.hoverPurchase ? styles.middle2: styles.middle} variant="primary" color="custom" onClick={this.props.handlePurchaseClick} >Purchase</Button>  */}
                            <Row>
                                <Col md={8}>
                                    <img src={purchase_img} alt="Card image cap" width="100%" height='190' style={this.state.hoverPayment ? {"opacity": '0.3'}: {"opacity": '1'}}></img>
                                </Col>
                                <Col md={4} style={{display: 'flex', alignItems: 'center', padding: '0px 15px 0px 0px'}}>
                                    {/* <Button color="link" onClick={this.props.handlePaymentClick}><h4>Payment</h4></Button>  */}
                                    <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handlePurchaseClick()}}><span style={{fontSize:'18px'}}><b>Purchases</b></span></a>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    </CardDeck>
                    </Collapse>
                     </Col>
                </Row>
               
            </div>
        )
    }

    render(){
        return (
            <Col md={12}>
               
                <Card>
                    <CardBody> 
                        {this.renderPlan()}

                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default CompanyAccountOptionsBoard;