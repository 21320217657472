/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse } from 'reactstrap';
import check_mark_circle from '../../../shared/img/icon/check_mark_circle.svg';
import icon_edit from '../../../shared/img/icon/edit.png';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_add from '../../../shared/img/icon/add.png';

import { AssetCreateModal } from './AssetCreateModal'
import { AutoRenderAssetTable } from './RenderAssetTable'
import ConfirmDeleteDialog from './ConfirmDeleteDialog'
import EditAssetPanel from './EditAssetPanel';
import FileInputModal from './FileInputModal';

class AssetDisplayList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetToEdit: null,
      assetToDelete: null,
      confirmDialogOpen: null,
      modalVisible: false,
    }
  }

  toggleDialog = () => {
    this.setState({ confirmDialogOpen: !this.state.confirmDialogOpen })
  }
  promptDelete = (id) => {
    this.setState({ assetToDelete: id }, this.toggleDialog)

  }
  deleteAsset = () => {
    this.closePanel()
    this.props.deleteAsset(this.state.assetToDelete)
    this.toggleDialog()
  }

  editSelectedAsset = async (id) => {
    this.setState({ assetToEdit: id }, async () => {
      await this.props.getCalculateAttribute({ "assetid": id })
    })
  }
  closePanel = async () => {
    await this.props.reloadData("asset").then(() => this.setState({ assetToEdit: null }));
  }

  // updateAssetAttribute = (data) => {
  //   console.log('updateAssetAttribute')
  //    
  // }

  toggle = () => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible
    }));
  }

  handleLoadFileClick = (e) => {
    // alert('yes');
    this.setState({
      modalVisible: true
    });
  }


  render() {
    return (
      <>
        <FileInputModal
          modalSetVisible={this.state.modalVisible}
          toggle={this.toggle}
        />
        <ConfirmDeleteDialog
          modalVisible={this.state.confirmDialogOpen}
          onConfirm={this.deleteAsset}
          toggleOff={this.toggleDialog}
          msg="Are you sure you want to delete this Asset?"
        />
        <Collapse isOpen={this.state.assetToEdit == undefined || this.state.assetToEdit == null}>
          <Card>
            <div className='card-pg'>
              <Row style={{ float: 'left' }} >

                <AssetCreateModal
                  assetClassList={this.props.assetClassList}
                  devices={this.props.deviceList}
                  deviceTypes={this.props.deviceTypes}
                  saveAssetToStore={this.props.saveAssetToStore}
                  assetList={this.props.assetList}
                />

                <Button variant="primary" color="custom" style={{ position: 'relative' }} type="button" onClick={this.handleLoadFileClick}>Load Data</Button>

              </Row>

              <AutoRenderAssetTable
                keyField='assetid'
                editable={true}
                // editable={this.state.groupEditable}
                assetClassList={this.props.assetClassList}
                assetList={this.props.assetList}
                handleEditClick={this.editSelectedAsset}
                handleDeleteClick={this.promptDelete}
              />

              {/* <RenderAssetTable 
              assetList={this.props.assetList}
              handleEditClick={this.editSelectedAsset}
            /> */}
            </div>
          </Card>
        </Collapse>
        <Collapse isOpen={this.state.assetToEdit != undefined && this.state.assetToEdit != null}>
          {/* {console.log("===this.props.assetList", this.props.assetList)} */}
          {this.state.assetToEdit &&
            <EditAssetPanel
              updateBatchCalculateAttribute={this.props.updateBatchCalculateAttribute}
              getCalculateAttribute={this.props.getCalculateAttribute}
              calculatedAttributes={this.props.calculatedAttributes}
              assetInfo={this.props.assetList.find(({ assetid }) => assetid == this.state.assetToEdit)}
              assetClassList={this.props.assetClassList}
              deviceTypes={this.props.deviceTypes}
              devices={this.props.deviceList}
              addAssetAttribute={this.props.addAssetAttribute}
              updateAssetAttribute={this.props.updateAssetAttribute}
              updateImageAttribute={this.props.updateImageAttribute}
              deleteAssetAttribute={this.props.deleteAssetAttribute}
              resetCalculateAttribute={this.props.resetCalculateAttribute}
              closePanel={this.closePanel}
              key={this.state.assetToEdit}
              updateAssetOnStore={this.props.updateAssetOnStore}
              assetList={this.props.assetList}
            />
          }
        </Collapse>
      </>
    )
  }
}
export default AssetDisplayList;