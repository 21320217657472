/* eslint-disable */
import axios from 'axios';
import { toast } from "react-toastify";
import {
    ATTRI_THEME_GET_THEME_LIST_SUCCEED,
    ATTRI_THEME_GET_THEME_LIST_FAILED,
    ATTRI_THEME_GET_ASSETCLASS_THEME_SUCCEED,
    ATTRI_THEME_GET_ASSETCLASS_THEME_FAILED,
    ATTRI_THEME_UPSERT_THEME_SUCCEED,
    ATTRI_THEME_UPSERT_THEME_FAILED,
    ATTRI_THEME_REMOVE_THEME_SUCCEED,
    ATTRI_THEME_REMOVE_THEME_FAILED,
    ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_SUCCEED,
    ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_FAILED,
    USER_GET_FAILED,

} from '../../constants/actionTypes';
import {
    WEB_SERVICE_ROOT,
    WEB_SERVICE_GET_ASSETCLASS_THEME,
    WEB_SERVICE_GET_THEME_LIST,
    WEB_SERVICE_GET_DEVICE_TYPE_THEME,
    WEB_SERVICE_REMOVE_ASSETCLASS_THEME,
} from '../../constants/appConstants';
// import {axiosGet} from '../../factories/utils';
import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
});

// ---------------------------------------------
export function getThemeList(assetclassid){
    // if (!assetclassid) {
    //     return null;
    // }
    if (assetclassid === 0 || assetclassid === '0') {
         
        return ((dispatch) => {
            dispatch(
                {
                    type: ATTRI_THEME_GET_THEME_LIST_SUCCEED,
                    payload: [],

                }
            );
        })
    }
    let creteria = {assetclassid:assetclassid};
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_THEME_LIST}`,
        criteria: creteria,
        logText: 'Get AssetClass Theme List Url ',
        sccessType: ATTRI_THEME_GET_THEME_LIST_SUCCEED,
        failedType: ATTRI_THEME_GET_THEME_LIST_FAILED,
        errorMessage: 'Failed to get assetclass theme list.'
    };

    return(axiosGet(paramObj));
}

export function getDeviceTypeThemeList(devicetypeid){
    if (!devicetypeid) {
        return null;
    }
    let creteria = {devicetypeid:devicetypeid};
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_DEVICE_TYPE_THEME}`,
        criteria: creteria,
        logText: 'Get Device Type Theme List Url ',
        sccessType: ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_SUCCEED,
        failedType: ATTRI_THEME_GET_DEVICE_TYPE_THEME_LIST_FAILED,
        errorMessage: 'Failed to get devicetype theme list.'
    };

    return(axiosGet(paramObj));
}

export function getAssetclassTheme(themeid){
    if (!themeid) {
        return null;
    }
    let creteria = {themeid:themeid};
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETCLASS_THEME}`,
        criteria: creteria,
        logText: 'Get AssetClass Theme Url ',
        sccessType: ATTRI_THEME_GET_ASSETCLASS_THEME_SUCCEED,
        failedType: ATTRI_THEME_GET_ASSETCLASS_THEME_FAILED,
        errorMessage: 'Failed to get assetclass theme.'
    };

    return(axiosGet(paramObj));
}

export function upsertAssetclassTheme(themeObj){

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){
       
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    if (!themeObj) {
        return dispatch => {           
            dispatch({
               type: ATTRI_THEME_UPSERT_THEME_FAILED,
               message: 'theme input is empty',
            });
            toast.error('Theme input was empty');
         }
    }
    let url =`${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ASSETCLASS_THEME}`;

    const request = axios({
        url: url,
        method: 'post',
        // headers: {
        //     'Content-Type': 'application/json'
        // },
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
        data: themeObj,
    });

    return ( (dispatch) => {
        request.then( (response) => {
                if (response.status == 200){
                    dispatch(
                        {
                            type: ATTRI_THEME_UPSERT_THEME_SUCCEED,
                            payload: response.data,

                        }
                    );
                    dispatch(getThemeList(themeObj.assetclassid));
                    toast.success('Successfully Updated the Theme ' + themeObj.themename);
                }else{
                    let message = 'Upserting Theme Failed';
                    console.error(message);
                    dispatch(
                        {
                            type: ATTRI_THEME_UPSERT_THEME_FAILED,
                            message: message,

                        }
                    )
                    toast.error("Failed to update the theme " + themeObj.themename);
                }
               
            }
        ).catch( (error) => {
                console.error(error.message);
                dispatch(
                    {
                        type: ATTRI_THEME_UPSERT_THEME_FAILED,
                        message: error.message,

                    }
                )
                toast.error("Error occurred when updating the theme " + themeObj.themename);
            }
        )
    }
    );

}

export function removeAssetclassTheme(assetclassid, themename, themeid){

    if (!themeid) {
        return dispatch => {           
            dispatch({
               type: ATTRI_THEME_REMOVE_THEME_SUCCEED,
               message: 'theme input is empty',
            });
            toast.error('theme input is empty');
         }
    }
    let queryStr = `?themeid=${themeid}`
    let url =`${WEB_SERVICE_ROOT}${WEB_SERVICE_REMOVE_ASSETCLASS_THEME}${queryStr}`;
     
    const request = axios.delete( 
        url, 
        { 
            headers: {'Accept': 'application/json'},
            // data: themeObj,
        }
    );

    return (
        (dispatch)=>{
            request.then((response)=>{
                     
                     
                    if (response.status == 200 && response.data == true){
                        dispatch(
                            {
                                type: ATTRI_THEME_REMOVE_THEME_SUCCEED,
                                // message: response.data,        
                            }
                        )
                        dispatch(getThemeList(assetclassid));
                        toast.success('Successfully Removed the Theme ' + themename);
                    }else{
                        let message = 'Removing Theme Failed';
                        console.error(message);
                        dispatch(
                            {
                                type: ATTRI_THEME_REMOVE_THEME_FAILED,
                                message: message,
        
                            }
                        )
                        toast.error("Failed to remove the theme " + themename);
                    }
                   
                }
            ).catch((error)=>{
                    dispatch(
                        {
                            type: ATTRI_THEME_REMOVE_THEME_FAILED,
                            message: error.message,
    
                        }
                    )
                    console.error('Remove theme error : ' + error.message)
                    toast.error("Error occurred when removing theme " + themename);
                }
            )
        }
    );
}

