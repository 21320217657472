/* eslint-disable */
import axios from 'axios';
import {
    API_ADDR,
    API_ROOT_URL,
    DELETE_USER_DATA,
    DELETE_USER_DATA_STARTED,
    DELETE_USER_DATA_SUCCEED,
    DELETE_USER_DATA_FAILED,
    SUCCESS_MSG
} from '../../../constants/actionTypes';

function deleteDetailStarted() {
    return {
        type: DELETE_USER_DATA_STARTED
    }
}

function deleteDetailSucceed(res) {
    if (res.data.status === SUCCESS_MSG) {
        return {
            type: DELETE_USER_DATA_SUCCEED,
            payload: res
        }
    }
    else {
        return {
            type: DELETE_USER_DATA_FAILED,
            payload: res
        }
    }
}

function deleteDetailFailed(err) {
    return {
        type: DELETE_USER_DATA_FAILED,
        payload: err
    }
}

function criteriaToFormData(criteria) {
    let payload = new FormData();

    payload.set('userName', criteria.userName);
    payload.set('jToken', sessionStorage.getItem('authToken'));

    return payload;
}

export function deleteDetail(criteria) {
    const url = `${API_ADDR}${API_ROOT_URL}${DELETE_USER_DATA}`;

    const payload = criteriaToFormData(criteria);

    const promise = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: payload
    });

    return function (dispatch) {
        dispatch(deleteDetailStarted());
        promise.then(
            res => {
                dispatch(deleteDetailSucceed(res));
            }
        ).catch(
            err => {
                dispatch(deleteDetailFailed(err));
            }
        );
    }
}