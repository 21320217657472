/* eslint-disable */
import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import {SingleSelection, MultiSelectionComponent} from '../../Asset/components/SelectionComponents';
import {groupBy} from '../../../factories/utils';
import { toast } from "react-toastify";

import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import 'bootstrap/scss/bootstrap';
import { DateRangePicker } from 'react-dates';

class DeviceFilter extends Component{
    constructor(props){
        super(props);
        this.state={
            startDateMoment: null,
            endDateMoment: null,
        }
    }

    // ---------------------------------------------------------------------------
    componentWillUnmount(){
         
        this.distructor();
    }

    componentWillUnmount(){
         
        if (this.interval){
            clearInterval(this.interval);
        }
    }
    // ---------------------------------------------------------------------------
    distructor = () => {
        this.setState({
            selectedDeviceType: null,
            selectedDevice: null,
            startTime: '',
            endTime: '',
            startDateMoment: null,
            endDateMoment: null,
        });
        this.props.clearGroupDevices();
        this.props.clearGroupDeviceData();
    }
    // ---------------------------------------------------------------------------
    handleDeviceTypeChange = selectedOption => {
         
        if (selectedOption){
            const deviceTypeList = selectedOption.map((item, index) => (item.id));
            this.props.getDevicesByGroup(deviceTypeList);
        }else{
            this.props.clearGroupDevices();
        }
        this.setState(prevState => {           
            return ({
                selectedDeviceType: selectedOption,
                selectedDevice: null,
                startTime: '',
                endTime: '',
                startDateMoment: null,
                endDateMoment: null,
            })            
        }, this.onRefreshClick);
    };

    handleTimeChange = event => {
        let key = event.target.name;
        let value = event.target.value;
        this.setState({
            [key]: value
        })
    }

    handleDeviceChange = selectedDevice => {
         
        this.setState({
            selectedDevice: selectedDevice,
            startTime: '',
            endTime: '',
            startDateMoment: null,
            endDateMoment: null,
        }, this.onRefreshClick);
    }

    handleDateRangeChange = ({ startDate, endDate }) => {
        //  
        //  
        this.setState({
            startDateMoment: startDate,
            endDateMoment: endDate
        }, this.onRefreshClick)
    }

    dataTimeValidation = (startTime, endTime) =>{
        let reVal = true;

        if(startTime && startTime.length > 0 && endTime && endTime.length > 0){
            let startdate = new Date(startTime).toISOString().split('.')[0];        
            let enddate = new Date(endTime).toISOString().split('.')[0];   
            reVal = enddate >= startdate;

        }else if((!startTime || startTime.length === 0) && (!endTime || endTime.length === 0) ) {
            reVal = true;
        }else{
            reVal = false;
        }

        return reVal;
    }

    dataMomentValidation = (startMoment, endMoment) => {
        let reVal = false;
        if (startMoment && endMoment){
            reVal = endMoment.isAfter(startMoment);
            if (!reVal) {
                toast.error('End date should be later than start date.');
            }
        }else if (!startMoment && !endMoment){
            reVal = true;
        }

        return reVal;
    }

    onRefreshClick = () => {
        // let params = {devicetype:this.props.currentDeivecType};
        // params = Object.assign(params, {assetid: this.state.currentAssetId});
        // let params = {assetid: this.state.currentAssetId, assetclassid: this.props.currentAssetClassId};
        let params = {themeid:"", starttime: "", endtime: ""};
        let entitygroup = [];
        let entity = '';
        if (this.state.selectedDeviceType && this.state.selectedDeviceType.length > 0){
            entitygroup = this.state.selectedDeviceType.map((item, index)=>(item.id))
        }else{
            this.props.clearGroupDeviceData();
            return
        }
        // params = {...params, entitygroup};

        if (this.state.selectedDevice){
            entity = this.state.selectedDevice.deviceid;
            entitygroup = [];            
        }
        params = {...params, entitygroup, entity};

        // if (!this.dataTimeValidation(this.state.startTime, this.state.endTime)){
        //     toast.error('End date should be later than start date.');
        //     this.setState(prevState => {           
        //         return ({
        //             startTime: '',
        //             endTime: '',
        //         })            
        //     });
        // }

        if (!this.dataMomentValidation(this.state.startDateMoment, this.state.endDateMoment)){
            // toast.error('End date should be later than start date.');
            // this.setState(prevState => {           
            //     return ({
            //         startDateMoment: null,
            //         endDateMoment: null,
            //     })            
            // });
            return;
        }
  
        let latest = true;
        // if (this.state.startTime && this.state.startTime.length > 0 ){
        //     let date = new Date(this.state.startTime).toISOString().split('.')[0];           
        //     params = {...params, starttime: date};
        //     latest = false;
        // }
        // if (this.state.endTime && this.state.endTime.length > 0 ){
        //     let date = new Date(this.state.endTime).toISOString().split('.')[0];       
        //     params = {...params, endtime: date};
        //     latest = false;
        // }

        if (this.state.startDateMoment){
            let date = this.state.startDateMoment.format('YYYY-MM-DD');           
            params = {...params, starttime: date};
            latest = false;
        }
        if (this.state.endDateMoment ){
            let date = this.state.endDateMoment.format('YYYY-MM-DD');     
            params = {...params, endtime: date};
            latest = false;
        }

        if(latest){
            params = {...params, latest: "1"};
        }else{
            params = {...params, latest: "0"};
        }
         
        // this.props.loadDivceMapData(params);
        this.props.getGroupDeviceData(params);
        if (this.interval){
            clearInterval(this.interval);
        }
        // this.interval = setInterval(() => { 
        // this.props.getAssetsData(params);
        // this.props.bindFilterAttribute('');
    }
    render(){

        let deviceList = [];
        if (this.props.groupDevices){
            const deviceGroup = groupBy(this.props.groupDevices, device => device.devicetypename); 
            if (deviceGroup){
                deviceGroup.forEach((value, key, map) => {
                    deviceList.push({
                        label: key,
                        options: value
                    });
                });
            }
        }
        let refrashDisabled = !this.state.selectedDeviceType || this.state.selectedDeviceType.length === 0;
        return (
            <div style={{ width: "100%"}}>
            <Form md={12}>
                <Row form>
                    <Col md={3}>
                        <FormGroup row>
                            <Col md={12}>
                                <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Device Type</b></h4></Label>
                                {/* <Input type="select" id="asset" name="asset" width="100%" value={this.props.currentAssetClassId} onChange={this.onAssetClassChange} >                                    
                                    {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))}
                                    <option value={0}>NONE</option> 
                                    {this.props.assetClasses && this.props.assetClasses.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                                </Input> */}
                                <Select isMulti
                                    options = {this.props.types}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                    onChange={this.handleDeviceTypeChange}
                                    // value={}
                                    // defaultValue={}
                                    // onChange = {}

                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup row>                            
                            <Col md={12}>
                                <Label for="deviceType" style={{display: 'inline', width: "100%"}} ><h4><b>Device</b></h4></Label>
                                {/* <Input type="select" id="deviceType" name="deviceType" width="100%" value={this.props.currentDeviceTypeId} onChange={this.onDeviceTypeChange} disabled={this.state.deviceTypeDisable} >      
                                    
                                    {this.state.showEmpgy && <option value={'0'}> No Selection </option>}  
                                    {this.props.types && this.props.types.map((type,i) => (<option key={type.id} value={type.id}>{type.name}</option>))}
                                </Input> */}
                                <Select 
                                    isClearable
                                    isSearchable
                                    options = {deviceList}
                                    getOptionLabel={({ devicename }) => devicename}
                                    getOptionValue={({ deviceid }) => deviceid}
                                    value={this.state.selectedDevice}
                                    // defaultValue={}
                                    onChange = {this.handleDeviceChange}

                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    {/* <Col md={2}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="startTime"  style={{display: 'inline', width: "100%"}} ><h4><b>From</b></h4></Label>
                                <Input 
                                    type="date" 
                                    id="startTime" 
                                    name="startTime" 
                                    width="100%" 
                                    value={this.state.startTime} 
                                    onChange={this.handleTimeChange}
                                    disabled = {!this.state.selectedDevice || this.state.selectedDevice.length == 0}
                                >
                                </Input>     
                            </Col>                   
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="endTime"  style={{display: 'inline', width: "100%"}} ><h4><b>To</b></h4></Label>
                                <Input 
                                    type="date" 
                                    id="endTime" 
                                    name="endTime" 
                                    width="100%" 
                                    value={this.state.endTime} 
                                    onChange={this.handleTimeChange}
                                    disabled = {!this.state.selectedDevice || this.state.selectedDevice.length == 0}
                                >
                                </Input>  
                            </Col>                      
                        </FormGroup>
                    </Col> */}
                    {/* <Col md={1}></Col> */}
                    <Col md={3}>
                    <FormGroup row >
                            <Col md={12}>
                                <Label for="endTime"  style={{display: 'inline', width: "100%"}} ><h4><b>From -> To</b></h4></Label>
                                <DateRangePicker
                                    isOutsideRange={() => false}
                                    startDate={this.state.startDateMoment} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.state.endDateMoment} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                    small = {true}
                                    block = {true}
                                    disabled = {!this.state.selectedDevice || this.state.selectedDevice.length == 0}
                                    />
                            </Col>                      
                        </FormGroup>
                    </Col>
                    {/* <Col md={2} style={{ textAlign: 'center', justifyContent: 'center',alignItems: 'center' }}>
                        <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                        <Button 
                            variant="primary" 
                            color="custom" 
                            type="button" 
                            onClick={this.onRefreshClick} 
                            disabled = {this.props.loading || refrashDisabled}
                        >
                            {this.props.loading && 
                                <Spinner 
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> 
                            }
                            Refresh
                        </Button>
                    </Col> */}
                </Row>
        </Form>
        </div>
        );
    }
}

export default DeviceFilter;