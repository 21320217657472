/* eslint-disable */
import React, {Component, Fragment } from 'react';
import {Container, Row, Col, Button, Table} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import {chunk} from '../../../factories/utils';
import DeviceDetailBoard from '../../DeviceDetail/components/DeviceDetailBoard';
import {ChevronLeftIcon} from 'mdi-react';
import {
    MAP_DEVICE_DATA,
    MAP_ASSET_DATA ,
} from '../../../constants/actionTypes'; 
// import $ from 'jquery';
// import 'http://netdna.bootstrapcdn.com/bootstrap/3.0.2/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';


class InfoContainer extends Component{
    constructor(props){
        super(props);
        this.state = {
            // types: [{name:'test1'},{name:'test2'},{name:'test3'}],
            keys : [],
            renderData : [],
            showDetails: false,
            curretnDeviceId: '',
            columnWidth: '15',
        }
    }

    // static getDerivedStateFromProps(props, state){
    //     let keys = [];
    //     let renderData = [];
    //     if (props.mapData != undefined && props.mapData != null && Array.isArray(props.mapData) && props.mapData.length > 0){
    //         Object.keys(props.mapData[0].msg).map((key,i)=>{
    //             if(key !== 'Lng' && key !== 'Lat'){
    //                 keys.push(key);
    //             }
    //         });

    //         props.mapData.map((item, i) => {
    //             let dataitem = {id: item.msgid, name: item.devicename};
    //             // let msg = {msgid: item.msgid, datetime: dateTime};
    //             keys.map((key, i) => {
    //                 dataitem[key] = item.msg[key].value; // item.msg[key] ? item.msg[key].value : null;
    //             });
    //             // let dataItem = Object.assign({}, msg);
    //             renderData.push(dataitem);
    //         });
    //     }

    //     return({
    //         renderData: renderData,
    //         keys: keys,
    //     });

    // }

    componentDidMount(){
        // $(".table-header .table th:eq(2)" ).resizable({
        //     minWidth: 28,
        //         handles: 'e',
        //         resize: (event, ui) => {
        //           this.setState({ columnWidth: ui.size.width });
        //         } 
        // });
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.assetData) {
            this.setState({
                showDetails: false,
            });
        }
    }

    renderDeviceList(){
        let keys = [];
        if (this.props.mapData != undefined && this.props.mapData != null && Array.isArray(this.props.mapData) && this.props.mapData.length > 0){
            Object.keys(this.props.mapData[0].msg).map((key,i)=>{
                if(key !== 'Lng' && key !== 'Lat'){
                    keys.push(key);
                }
            });
        }
         
         

        const renderHeader = () => {
            return(
                keys.map((key,i) => {
                    return( <th key={i}>{key}</th>)
                })
            );
        };
        
        return(
            <Table   bordered hover>
                <thead>
                    <tr>
                        {/* <th>lng</th>
                        <th>lat</th>
                        <th>info</th> */}
                        <th>name</th>
                        {renderHeader()}
                    </tr>
                </thead>
                <tbody>
                    {this.props.mapData.slice(0,10).map((m,i) => {
                        return(
                            <tr key={i}>
                                {/* <td>{m.lng}</td>
                                <td>{m.lat}</td>
                                <td>{m.info}</td> */}
                                <td>{m.devicename}</td>
                                {
                                    keys.map((key,i)=>(<td key={i}>{m.msg[key].value}</td>))
                                }
                            </tr>
                        );

                    })}
                </tbody>
            </Table>
        );
    }

    // createCustomExportCSVButton = (onClick) => {
    //     return (
    //       <button style={ { color: 'red' } } onClick={ onClick }>Custom Export CSV Btn</button>
    //     );
    //   }

    createCustomExportCSVButton = (onClick) => {
        return (
          <ExportCSVButton
            btnText='Export Data'
            btnContextual='btn-success'
            className='my-custom-class'
            btnGlyphicon='glyphicon-edit'
            onClick={ e => this.handleExportCSVButtonClick(onClick) }/>
        );}

        handleExportCSVButtonClick = (onClick) => {
            // Custom your onClick event here,
            // it's not necessary to implement this function if you have no any process before onClick
             
            onClick();
          }

    renderDeviceList2(){
        let keys = ['id','name','timestamp'];
        let labels = {id : 'Message ID', name: 'Device', timestamp: 'Message Time'};
        let renderData = [];
        if (this.props.mapData != undefined && this.props.mapData != null && Array.isArray(this.props.mapData) && this.props.mapData.length > 0){
            // Object.keys(this.props.mapData[0].msg).map((key,i)=>{
            //     if(key !== 'Lng' && key !== 'Lat'){
            //         keys.push(key);
            //     }
            // });

            // Object.entries(this.props.mapData[0].msg).forEach(([attr, attrObj],mindex)=>{
            //     if (attr !== 'Lng' && attr !== 'Lat'){
            //         msgObj[attr] = attrObj.value;
            //         if (! keys.includes(attr) ){
            //             keys.push(attr);
            //             labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
            //         }
            //     }
            // });

            this.props.mapData.map((item, i) => {
                // let dataitem = {id: item.msgid, name: item.devicename, timestamp: new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' })};
                let dataitem = {id: item.msgid, name: item.devicename, timestamp: new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'UTC' })};                
                // let msg = {msgid: item.msgid, datetime: dateTime};
                // keys.map((key, i) => {
                //     if (key != 'id' && key != 'name'){
                //         dataitem[key] = item.msg[key] ? item.msg[key].value : null; // item.msg[key] ? item.msg[key].value : null;
                //     }
                    
                // });
                // let dataItem = Object.assign({}, msg);
                // --------------------------------------------------
                Object.entries(item.msg).forEach(([attr, attrObj],mindex)=>{
                    // if (attr !== 'Lng' && attr !== 'Lat' && attrObj.display !== false){
                    if ( attrObj.display !== false){
                        dataitem[attr] = attrObj.value;
                        if (! keys.includes(attr) ){
                            keys.push(attr);
                            labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
                        }
                    }
                });
                // --------------------------------------------------
                renderData.push(dataitem);
            });

        }

        if (keys.includes('Lng') && keys.includes('Lat')) {
            keys = keys.filter((val, idx, arr) => ( val !== 'Lng' && val !=='Lat'));               
            keys.push('Lat');
            keys.push('Lng');
        }

        const options = {
            // page: 2,  // which page you want to show as default
            // sizePerPageList: [ {
            //   text: '5', value: 5
            // }, {
            //   text: '10', value: 10
            // }, {
            //   text: 'All', value: renderData.length
            // } ], // you can change the dropdown list for size per page
            // sizePerPage: 10,  // which size per page you want to locate as default
            // pageStartIndex: 1, // where to start counting the pages
            // paginationSize: 5,  // the pagination bar size.
            // prePage: '<', // Previous page button text
            // nextPage: '>', // Next page button text
            // firstPage: '<<', // First page button text
            // lastPage: '>>', // Last page button text
            // // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // // paginationPosition: 'top'  // default is bottom, top and both is all available
            // // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // // alwaysShowAllBtns: true // Always show next and previous button
            // // withFirstAndLast: false > Hide the going to First and Last page button
            exportCSVBtn: this.createCustomExportCSVButton
          };

        return(
            <BootstrapTable data={ renderData } tableHeaderClass = 'header_white'  options={ options } csvFileName={ 'export_data' } exportCSV={ true } keyField='id'    search height='600' >
                {/* <TableHeaderColumn keyField='id' hidden >Message ID</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='name' dataSort>Device Name</TableHeaderColumn>  */}
                {/* <TableHeaderColumn dataField='devicetypeid' dataSort>Device Type ID</TableHeaderColumn>
                <TableHeaderColumn dataField='devicetypename' dataSort>Device Type Name</TableHeaderColumn>
                <TableHeaderColumn dataField='timestamp'>Last Message Time</TableHeaderColumn>
                <TableHeaderColumn dataField='data'>Original Data</TableHeaderColumn> */}
                {keys.map((key, i) => {
                    return(
                        <TableHeaderColumn dataField={key} 
                            dataAlign="center"  
                            // width={`${this.state.width}px`} 
                            dataSort = {key === 'timestamp' ? true : false }
                        >
                            {labels[key] }
                        </TableHeaderColumn>
                    )
                })}
            </BootstrapTable>
        )

    }

    expandMessageComponent = (row) => {
        
        let data = [];
        let keys = ['id', 'timestamp', 'rawdata'];
        let labels = {id : 'Message ID', timestamp: 'Message Time', rawdata: 'Raw Data'};
        if (row.msgs && Array.isArray(row.msgs) && row.msgs.length>0){
            
            if (row.msgs[0].msg){
                Object.keys(row.msgs[0].msg).map((key,i)=>{
                    if(key !== 'Lng' && key !== 'Lat' && row.msgs[0].msg[key].display !== false){
                        keys.push(key);
                    }
                });
            }
            
            row.msgs.map((item, index) => {
                let dataitem = {
                        id: item.msgid, 
                        timestamp: item.timestamp, 
                        rawdata: item.rawdata
                    };
                keys.map((key, i) => {
                    if (key !== 'id' && key !== 'timestamp' && key !== 'rawdata'){
                        if (item.msg && item.msg[key]){
                            dataitem[key] = item.msg[key].value; // item.msg[key] ? item.msg[key].value : null;
                            if (!labels[key]){
                                labels[key] = item.msg[key].displayname; // item.msg[key] ? item.msg[key].displayname : null;
                            }                           
                        }else{
                            // dataitem[key] = item.msg[key].value;
                        }
                          
                    }
                    
                });
                // let dataItem = Object.assign({}, msg);
                data.push(dataitem);
            });

            keys.map((key, i) => {
                if (!labels[key]){
                    labels[key] = key;
                }        
            });
        }
        return (
            <BootstrapTable 
                data = {data}
                tableHeaderClass = 'header_white' 
                keyField='id'
                  
                 
                search 
                maxHeight = {'300'} 
            >
                {/* <TableHeaderColumn dataField={'id'} dataAlign="center" isKey > Message ID </TableHeaderColumn> */}
                {
                    keys.map((key,i) => {
                        return(
                            <TableHeaderColumn dataField={key} dataAlign="center" >{labels[key]}</TableHeaderColumn>
                        )
                    })
                }
            </BootstrapTable>
        )
    }

    expandDeviceComponent = (row) => {
        //  
        //  
        return (
            <BootstrapTable 
                data={row.device}
                tableHeaderClass = 'header_white' 
                expandableRow={ this.isExpandableRow }
                expandComponent={ this.expandMessageComponent }
                expandColumnOptions={ { expandColumnVisible: true, expandColumnComponent: this.expandColumnComponent, columnWidth: 50 } }
                  
                 
                search 
                maxHeight = {'300'} 
            >
                 <TableHeaderColumn dataField='deviceid' dataAlign="center" isKey={true} hidden >Device ID</TableHeaderColumn>
                 <TableHeaderColumn dataField='devicename' dataAlign="center" >Device</TableHeaderColumn>
                 <TableHeaderColumn dataField='devicetypename' dataAlign="center" >Device Type</TableHeaderColumn>
                 <TableHeaderColumn dataField='batteryexpiredate' dataAlign="center" >Battery Expire Time</TableHeaderColumn>
                 <TableHeaderColumn dataField='lastmessagetime' dataAlign="center" dataSort>Last Message Time</TableHeaderColumn>
                 <TableHeaderColumn dataField='lastseqnumber' dataAlign="center" >Last Sequence Number</TableHeaderColumn>
                 <TableHeaderColumn dataField='msgs' hidden >Messages</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    isExpandableRow = (row) => {
        // if (row.id < 3) return true;
        // else return false;
        return true;
    }

    expandColumnComponent({ isExpandableRow, isExpanded }) {
        let content = '';    
        if (isExpandableRow) {
        //   content = (isExpanded ? '(-)' : '(+)' ); MinusIcon
          content = (isExpanded ? <MinusIcon/> : <PlusIcon/> ); 
        } else {
          content = ' ';
        }
        return (
          <div> { content } </div>
        );
      }

    renderAssetTb  = () => {
        let data = [];
         
        this.props.assetData.map((item, index) => {
             
            item.asset.map((a, i) => {
                let newItem = {
                    ...a,
                    asssetClassId: item.asssetClassId,
                    className: item.className,
                }
                data.push(newItem);
            });
        });
        const options = {
            // expandRowBgColor: 'rgb(242, 255, 163)'
            expandRowBgColor: '#ffff00'
          };
        return(
            <BootstrapTable 
                data = {data} 
                tableHeaderClass = 'header_white' 
                option = {options} 
                expandableRow={ this.isExpandableRow }
                expandComponent={ this.expandDeviceComponent }
                expandColumnOptions={ { expandColumnVisible: true, expandColumnComponent: this.expandColumnComponent, columnWidth: 50 } }
                //   
                 
                search 
                maxHeight = {'500'} 
            >
                <TableHeaderColumn dataField='assetid' dataAlign="center" isKey={true} hidden >Asset ID</TableHeaderColumn>
                <TableHeaderColumn dataField='assetname' dataAlign="center"  >Asset Name</TableHeaderColumn>
                <TableHeaderColumn dataField='className' dataAlign="center" >Asset Class Name</TableHeaderColumn>    
                <TableHeaderColumn dataField='description' dataAlign="center"  >Descrition</TableHeaderColumn>
                <TableHeaderColumn dataField='device' hidden  >Devices</TableHeaderColumn>
            </BootstrapTable>
        )
    }
    // ----------------------------------------------------------------
    deviceColumnFormat = (cell, row) => {
        let colspan = 2;
        //  
        const handleDeviceOnClick = (e, deviceId) => {
            e.preventDefault();
            // alert('deviceId');
            // return(<div>{this.renderDetailsTb(deviceId)}</div>)
            let deviceData = cell.find((item, index) => (item.deviceid === deviceId));
            //  
            //  
            this.setState({
                showDetails: true,
                curretnDeviceId: deviceId,
                currentDeviceData: deviceData,
            });
        }
        //  
        //  
        if (cell){
            let chunkedDevices = chunk(cell, colspan); 
            return (
                <table border='0'>
                    {chunkedDevices.map((dviceRow, rindex) => (
                        <tr>
                            {dviceRow.map((device, dindex)=>(
                                <td>
                                    {/* <a href = {`/pages/device/${device.deviceid}`}  onClick={()=>{}}>{device.deviceid}</a> */}
                                    <a href = {'#'}  onClick={(e)=>{handleDeviceOnClick(e, device.deviceid);}}>{device.deviceid}</a>
                                </td>
                            ))}
                        </tr>
                    ))}
                </table>
            );
        }
       else{
           return null;
       }

    }

    renderAssetsTb2 = (assetclass) => {
            let data = [];
        // this.props.assetData.forEach((assetclass, acindex)=>{
            let assetClassObj = {};
            let keys = ['assetid', 'assetname', 'className', 'description'];
            let labals = {assetid: 'Asset ID', assetname: 'Asset Name', className: 'Asset Class Name', description: 'Descriptions' };
            Object.entries(assetclass).forEach(([key, value],index) => {
                if (!Array.isArray(value)){
                    assetClassObj[key] = value;
                }
            });

            assetclass.asset.forEach((asset, asindex) => {
                let assetObj = {...assetClassObj, ...asset};
                //  
                //  
                if (asset.assetattributes) {
                    asset.assetattributes.forEach((attr, attrindex) => {
                        if (attr.attmetaname) {
                            if (!keys.includes(attr.attmetaname)){
                                keys.push(attr.attmetaname);
                                labals[attr.attmetaname] = attr.displayname ? attr.displayname : attr.attmetaname;
                            }   
                            assetObj[attr.attmetaname]=  attr.value ? attr.value : '';
                            // {
                            //     label: attr.displayname ? attr.displayname : attr.attmetaname,
                            //     value: attr.value ? attr.value : '',
                            //     id: attr.id ? attr.value: -1,
                            // };
                        }                       
                    });
                }
                // let assetObj = {...assetObj, ...asset};
                data.push(assetObj);
            });
            keys.push('device');
            labals.device = 'Devices';

            data.forEach((item, index) => {
                keys.forEach((key, kindex) => {
                    if (item[key] === undefined || item[key] === null) {
                        item[key] = '';
                    }
                });
            });
        // });
            let that = this;
        return (
            <BootstrapTable 
            data = {data} 
            tableHeaderClass = 'header_white' 
            // option = {options}   
            keyField='assetid'          
              
             
            search 
            maxHeight = {'500'} 
        >
            {/* <TableHeaderColumn dataField='assetid' dataAlign="center" hidden >Asset ID</TableHeaderColumn>
            <TableHeaderColumn dataField='assetname' dataAlign="center"  >Asset Name</TableHeaderColumn>
            <TableHeaderColumn dataField='className' dataAlign="center" >Asset Class Name</TableHeaderColumn>    
            <TableHeaderColumn dataField='description' dataAlign="center"  >Description</TableHeaderColumn> */}
            {keys && labals && keys.map((key, index) => (
                key !== 'device' ?
                <TableHeaderColumn dataField={key} dataAlign="center" >{labals[key]}</TableHeaderColumn>
                :
                <TableHeaderColumn dataField={key} dataAlign="center" dataFormat={that.deviceColumnFormat} >{labals[key]}</TableHeaderColumn>
            ))}
            {/* <TableHeaderColumn dataField='device' dataFormat={this.deviceColumnFormat}  >Devices</TableHeaderColumn> */}
        </BootstrapTable>
        );
    }

    renderAssetClassTbs = () => {
        return (
            <div>
                {
                    this.props.assetData && this.props.assetData.map((assetclass, acindex)=>(            
                        <Fragment>{this.renderAssetsTb2(assetclass)}</Fragment>  
                    ))
                }
            </div>
        );
        
    }

    renderDetailsTb = (deviceId) => {
        const backOnClick = (e) => {
            e.preventDefault();
            // alert('deviceId');
            // return(<div>{this.renderDetailsTb(deviceId)}</div>)
            this.setState({
                showDetails: false,
                // curretnDeviceId: deviceId,
            });
        }
        return(
            <div>
                <div>
                    <Button onClick={backOnClick}>
                        <ChevronLeftIcon/> Back
                    </Button>
                </div>
                {/* <DeviceDetailBoard deviceId = {deviceId}/> */}
                {this.renderMessageTb(deviceId)}
            </div>
        )
    }

    renderMessageTb = (deviceId) => {
        // let currentDeviceData = cell.find((item, index) => (item.deviceid === deviceId));
        if (this.state.currentDeviceData){
        // if (currentDeviceData){
            let currentDeviceData = this.state.currentDeviceData;
            let data = [];
            let deviceObj = {};
            let keys = [];
            let labels = {};
            Object.entries(currentDeviceData).forEach(([key, value], index) => {
                if (! Array.isArray(value)){
                    deviceObj[key] = value;
                    // keys.push(key);
                }
            });

            keys = ['msgid', 'devicename', 'devicetypename', 'lastmessagetime'];
            labels = {msgid : 'Message Id', devicename : 'Device Name', devicetypename : 'Device Type', lastmessagetime : 'Last Message Time'}

            if (currentDeviceData.msgs) {
                currentDeviceData.msgs.forEach((msg, index) => {
                    let msgObj = {...deviceObj,...msg};
                     
                    // msgObj.lastmessagetime = new Date( msgObj.lastmessagetime).toLocaleString('en-US', { timeZone: 'America/New_York' });
                    msgObj.lastmessagetime = new Date( msgObj.lastmessagetime).toLocaleString('en-US', { timeZone: 'UTC' });
                    delete msgObj[msg];
                    // keys = [...keys, ...Object.keys(msgObj)];

                    if (msg.msg){
                         
                         
                        Object.entries(msg.msg).forEach(([attr, attrObj],mindex)=>{
                            // if (attr !== 'Lng' && attr !== 'Lat'  && attrObj.display !== false ){
                             
                            if (attrObj.display !== false ){
                                msgObj[attr] = attrObj.value;                                
                                if (! keys.includes(attr) ){
                                    keys.push(attr);
                                    labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
                                }
                            }
                        });
                    }
                    data.push(msgObj);
                });
            }
             
             

            if (keys.includes('Lng') && keys.includes('Lat')) {
                keys = keys.filter((val, idx, arr) => ( val !== 'Lng' && val !=='Lat'));               
                keys.push('Lat');
                keys.push('Lng');
            }

            return (
                <BootstrapTable 
                    data = {data}
                    tableHeaderClass = 'header_white' 
                    keyField='msgid'
                      
                     
                    search 
                    maxHeight = {'600'} 
                >
                    {/* <TableHeaderColumn dataField={'id'} dataAlign="center" isKey > Message ID </TableHeaderColumn> */}
                    {
                        keys.map((key,i) => {
                            return(
                                <TableHeaderColumn 
                                    dataField={key} 
                                    dataAlign="center" 
                                    dataSort = {key === 'lastmessagetime' ? true : false }
                                >
                                    {labels[key]}
                                </TableHeaderColumn>
                            )
                        })
                    }
                </BootstrapTable>
            );
        }
        else{
            return null;
        }
    }
    // ----------------------------------------------------------------
    renderInfoTb=()=>{
        switch(this.props.dataType){
            case MAP_DEVICE_DATA:
                return(
                    <Fragment> {this.renderDeviceList2()} </Fragment>
                )
            case MAP_ASSET_DATA:
                return(
                    <Fragment> {this.state.showDetails ? this.renderDetailsTb(this.state.curretnDeviceId) : this.renderAssetClassTbs()} </Fragment>
                )
        }
    }
    // ----------------------------------------------------------------
    render(){
        return (
            <Container style={{paddingTop:'20px'}}>
                <Row>
                    {/* {this.renderDeviceList2()} */}
                    {/* {this.renderAssetTb()} */}
                    {/* {this.renderAssetClassTbs()} */}
                    {/* {this.state.showDetails ? this.renderDetailsTb(this.state.curretnDeviceId) : this.renderAssetClassTbs()} */}
                    {this. renderInfoTb()}
                </Row>
                {/* <Row>
                    <div  style = {{textAlign: 'right' , width: '100%'}}>
                    <Button  type="button">
                        Export Data
                    </Button>
                    </div>
                </Row> */}
            </Container>
        );
    }

}

export default InfoContainer;