/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import { Card, CardBody, Col } from 'reactstrap';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import { EditIcon } from 'mdi-react';
import DashboardMap from './DashboardMap';
import AssetClassEditModal from './AssetClassEditModal';
import {loadAssetMapData} from '../../../../redux/actions/dashboardActions';
import ConfirmDeleteDialog from "../../../../shared/components/Dialog/ConfirmationDialog";
import DeleteIcon from "mdi-react/DeleteIcon";

class AssetMapWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            mapProps: {
                showingInfoWindow: false,
                activeMarker: {},
                selectedPlace: {attr:{}},
              },
            modal: false,
        }
    }

    componentDidMount(){
        // const assetclassid = this.props.assetclassId;
        // const dashname = this.props.dashName;
        // this.props.loadAssetMapData(assetclassid, dashname);
    }

    componentWillReceiveProps(nextProps){
         
        if (nextProps.assetclassId && nextProps.dashName && (nextProps.assetclassId !== this.state.assetclassId || nextProps.dashName !== this.state.dashName)) {
            this.setState({
                assetclassId: nextProps.assetclassId,
                dashName: nextProps.dashName,
            });
             
            this.props.loadAssetMapData(nextProps.assetclassId, nextProps.dashName);
        }
    }

    // static getDerivedStateFromProps(props, state) {}

    handleMapMarkerClick = (props, marker, e) => {
        //  
        this.setState({
            mapProps: {
                showingInfoWindow: true,
                activeMarker: marker,
                selectedPlace: props,
                lat: props.position.lat,
                lng: props.position.lng,
            },
        });  
        //        
    }
      
    handleMapInfoWinClick = (props) => {
      if(this.state.mapProps.showingInfoWindow){
          this.setState({
              showingInfoWindow: false,
              activeMarker: null
          });
      }
    }

    renderMap = () => {
      // let data = this.props.currentDeviceList.filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
      let dashname = this.props.dashName;
      let data = this.props.assetmapdata[dashname]; //filter((v,i,a)=>(v.devicetype === "5cf828390499f50b995ae851"));
      return(
        <DashboardMap 
          onMarkerClick={this.handleMapMarkerClick} 
          onInfoWinClick={this.handleMapInfoWinClick}
          values = {this.state.mapProps}
          mapData = {data}
          layout = {this.props.layout}
        />
      )
    }

    toggle = () => {
        // alert('yes');
        console.log('this is edit in widget')
        this.setState((prevState) => ({
            modal : !prevState.modal
        }))
    }
    renderModal = () => {
        return(
            <AssetClassEditModal
                modal = {this.state.modal}
                toggle = {this.toggle}
                layout = {this.props.layout}
                onEditSubmit = {this.props.onEditSubmit}
            />
        )
    }


    deleteWidget = (val) => {
        console.log ('val is',val)
        console.log('id in delete is ',this.props.layout.id)

        this.props.onDeleteSubmit(this.props.layout.id);

        // this.setState(prevState=>({
        //     modal: ! prevState.modal,
        // }));
    }
    render() {
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <Row>
                    <Col> */}
                    <h5 className="bold-text">
                        {this.props.dashName}

                        {/* <div style={{float: 'right', display:'inline', cursor: 'pointer'}}>
                            {this.props.layout.locked == 0 && <ConfirmDeleteDialog
                                // label={""}
                                icon = {(props)=>(<DeleteIcon size={15} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} {...props} />)}
                                style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                onConfirm={(e)=>{this.deleteWidget()}}
                                msg={'Do you want to delete the selected widget ? '}
                            />}
                        </div> */}

                        {/* {this.props.layout.display === 0 && */}
                        <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>
                        {/* } */}
                    </h5>     
                    {/* </Col>  
                    <Col>     */}
                    {/* <EditIcon style={{float: 'right', display:'inline'}}/>     */}
                    {/* </Col>
                    </Row>        */}
                </CardHeader>
                <CardBody style={{padding:15}} >  
                    {/* <div className="card__title">
                        <h5 className="bold-text">{this.props.dashName} </h5>                      
                    </div> */}
                    {this.renderMap()}
                    
                </CardBody>  
          </Card>
          {this.renderModal()}
          </Fragment>
        );
    }

}

// export default AssetMapWidget;

function mapStateToProps(state) { 
  const props = {...state.dashboard};
  return props;
};
  
function mapDispatchToProps(dispatch) {
  return bindActionCreators({loadAssetMapData}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetMapWidget);
