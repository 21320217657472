/* eslint-disable */
import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import SurveillanceMain from './components/Main';
import {getPageContent} from '../../redux/actions/userAction';
import { bindActionCreators } from 'redux';
class SurveillancePage extends Component {
  constructor(props){
    super(props);
  }
  async componentDidMount(){
    // if(this.props){
    //   console.log('PROPS', this.props);
    // }
    await this.props.getPageContent({ "content": "Smart Building" });
  }
  render(){
    return(
      <Container className="dashboard">
      <Row>
        {/* <Col md={12}>
          <h2 className="page-title">Camera Management</h2>
        </Col> */}
      </Row>
      <Row>
        <Col md={12}>
        <SurveillanceMain selectedPlace={this.props.location && this.props.location.state ? this.props.location.state.selectedPlace : null}
                            selectedFloor={this.props.location && this.props.location.state ? this.props.location.state.selectedFloor: null}/>
        </Col>
      </Row>
      </Container>

    )
  }
}

const mapDispatchToProps = (dispatch) =>{
  return bindActionCreators({
    getPageContent
  }, dispatch);
}
  
export default connect(null, mapDispatchToProps)(SurveillancePage);