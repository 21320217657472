/* eslint-disable */
import { webSession } from "./nvr/nvrXMLRequestsUtils"
// Get websocket handshake url
// function getWebsocketOpenUrl () {
//     let wsProtocol = 'ws'
//     return wsProtocol + '://' + SERVER_IP + '/requestWebsocketConnection?sessionID=' + webSession('sessionId')
// }

/**
 * Generate basic data
 */
function getBasic() {
    let id = webSession('requestBasicId') * 1
    let newId = id && id < Number.MAX_SAFE_INTEGER ? id + 1 : 1
    return {
        ver: '1.0',
        time: new Date().getTime(),
        id: newId,
        nonce: Math.floor(Math.random() * 2147483646 + 1)
    }
}

function getRandomGUID () {
    let str = '0123456789abcdef'
    let temp = '00000000-0000-0000-0000-000000000000'
    let ret = ''
    for (let i = 0; i < temp.length; i++) {
        if (temp[i] === '-') {
            ret += '-'
            continue
        }
        ret += str[Math.floor(Math.random() * str.length)]
    }
    return '{' + ret + '}'
}

/**
 * Start live preview
 * @param {Object} option
 *      @property {String} chlID 通道id
 *      @property {Number} streamType Code stream type 1 main code stream 2 auxiliary code stream 3 sub-code stream
 *      @property {Boolean} audio 是否携带音频
 */
export function CMD_PREVIEW(channelID, streamType, audio) {
    // console.log(channelID)
    return {
        url: '/device/preview/open',
        basic: getBasic(),
        data: {
            task_id: getRandomGUID(),
            channel_id: channelID,
            stream_index: streamType ? streamType : 2,
            audio: !!audio
        }
    }
}

/**
 * 关闭现场预览
 * @param {String} guid 对应预览下发的guid
 */
export function CMD_STOP_PREVIEW(guid) {
    return {
        url: '/device/preview/close',
        basic: getBasic(),
        data: {
            task_id: guid
        }
    }
}