/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Card, CardBody, Input, Button } from 'reactstrap';
import Select from 'react-select';
import { toast } from "react-toastify";
import ChipGrouping from './ChipGrouping'
import MultiSelectTable from './MultiSelectTable';
import { hasRole } from '../../../factories/auth';

class ClientBusinessUnitBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            country: {},
            lob: {},
            newLob: "",
            division: {},
            newDivision: ""

        }
    }
    componentDidMount() {

    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    isDisabledLob = () => {
        return this.state.newLob != null;
    }
    isDisabledDivision = () => {
        return this.state.newDivision != null;
    }
    //adding a new business unit
    OnSubmitUpdate = (grouping) => {
        var name = "";
        if (grouping === "lob") {
            name = this.state.newLob;
        }
        else if (grouping === "division") {
            name = this.state.newDivision;
        }
        if (name != "") {
            var obj = {
                type: grouping,
                companyId: this.props.selected.companyId,
                resellerId: this.props.selected.id,
                name: name,
                status: 1,
            }
        }
        this.props.updateBusinessUnit(obj).then(res => {
            if (res.status == 200) {
                toast.success(res.data.result);
                if (grouping === "lob") {
                    this.setState({
                        newLob: ""
                    })
                }
                else if (grouping === "division") {
                    this.setState({
                        newDivision: ""
                    })
                }
            } else {
                toast.error("error")
            }
        });
    }
    onSubmitBusinessUnit = () => {
        var obj = {
            id: 0,
            companyId: this.props.selected.companyId,
            resellerId: this.props.selected.id,
            country: this.state.country,
            lineOfBusiness: this.state.lob,
            division: this.state.division,
            status: 1
        }
        this.props.UpdateBusinessUnitRelations(obj).then(res => {
            if (res.status == 200) {
                toast.success(res.data.result);
            } else {
                toast.error("error")
            }
        });
    }

    onCountryChange = (event) => {
        if (event != null) {
            this.setState({
                country: event.value
            });
        } else {
            this.setState({
                country: {}
            });
        }
    }
    onChipChange = (item, grouping) => {
        this.setState({
            [grouping]: item
        })
    }
    render() {
        return (
            <Card>
                <CardBody className="mainCardBody">
                    <h4 style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span>Country:</h4>
                    <Row>
                        <Col md={8}>
                            <Select
                                classNamePrefix="select"
                                onChange={this.onCountryChange}
                                isClearable={true}
                                isSearchable={true}
                                name="country"
                                isDisabled={!hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])}
                                options={
                                    this.props.BUCountryList.map((country) => {
                                        var value = { "value": country, "label": country.name }
                                        return value;
                                    })
                                }
                            />
                        </Col>
                    </Row>
                    <br />
                    <h4 style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span>Line of Business:</h4>
                    <Row style={{ marginTop: "10px" }}>
                        <Col md={8}>
                            <Input
                                name='newLob'
                                placeholder='Line of Business'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.newLob}
                            />
                        </Col>
                        <Col md={2}>
                            {(hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ?
                                <Button color='custom' type="button" onClick={() => this.OnSubmitUpdate("lob")} disabled={this.state.newLob == "" || this.props.BULOBList.filter((lob) => lob.name == this.state.newLob).length > 0}>
                                    Add
                                </Button>
                                : null
                            }
                        </Col>
                    </Row>
                    <ChipGrouping
                        list={(this.props.BULOBList != null) ? (this.state.newLob != "") ? this.props.BULOBList.filter((lob) => lob.name.includes(this.state.newLob)) : this.props.BULOBList : []}
                        update={this.props.updateBusinessUnit}
                        onChipChange={this.onChipChange}
                        grouping={"lob"}
                        user={this.props.selected}

                    />

                    <br />
                    <h4 style={{ fontWeight: "bold" }}><span style={{ color: "red" }}>*</span>Department:</h4>
                    <Row style={{ marginTop: "10px" }}>
                        <Col md={8}>
                            <Input
                                name='newDivision'
                                placeholder='Department'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.newDivision}
                            />
                        </Col>
                        <Col md={2}>
                            {(hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ?
                                <Button color='custom' type="button" onClick={() => this.OnSubmitUpdate("division")} disabled={this.state.newDivision == "" || this.props.BUDivisionList.filter((division) => division.name == this.state.newDivision).length > 0}>
                                    Add
                                </Button>
                                : null
                            }
                        </Col>
                    </Row>
                    <ChipGrouping
                        list={(this.props.BUDivisionList != null) ? (this.state.newDivision != "") ? this.props.BUDivisionList.filter((division) => division.name.includes(this.state.newDivision)) : this.props.BUDivisionList : []}
                        update={this.props.updateBusinessUnit}
                        onChipChange={this.onChipChange}
                        grouping={"division"}
                        user={this.props.selected}

                    />

                    <br />
                    {(hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ?
                        <Button color='custom' type="button" onClick={() => this.onSubmitBusinessUnit()} disabled={_.isEmpty(this.state.country) || _.isEmpty(this.state.lob) || _.isEmpty(this.state.division)}>
                            Save Business Unit
                        </Button>
                        : null
                    }
                    <br />
                    <h3>Business Unit List </h3>
                    <br />
                    {this.props.BURelationshipList.map((relationship) =>
                        <MultiSelectTable
                            relationship={relationship}
                            BUCountryList={this.props.BUCountryList}
                            BULOBList={this.props.BULOBList}
                            BUDivisionList={this.props.BUDivisionList}
                            user={this.props.selected}
                            userObj = {this.props.userObj}
                            UpdateBusinessUnitRelations={this.props.UpdateBusinessUnitRelations}
                        />
                    )}
                </CardBody>
            </Card>
        );
    }
}
export default ClientBusinessUnitBoard;