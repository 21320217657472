/* eslint-disable */
import React, { Component } from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink,Card, CardBody, Col, Row, Button, Container} from 'reactstrap';
import {connect } from 'react-redux';
import {bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

import {Modal} from "react-overlays";
// import {css} from "@emotion/core";
import {Trans} from "react-i18next";
import {fetchDetail} from "../../../redux/actions/AccountMgmt/fetchDetailAction";
import {fetchAllModules, fetchAllGroups} from "../../../redux/actions/AccountMgmt/allModulesAction";
import {saveDetail} from '../../../redux/actions/AccountMgmt/saveDetailAction';
import {addDetail} from '../../../redux/actions/AccountMgmt/addDetailAction';
// import {GET_ALL_MODULES_SUCCEED, GET_USER_DATA_SUCCEED} from "../../../redux/const"; 
import {GET_ALL_MODULES_SUCCEED, GET_USER_DATA_SUCCEED, GET_ALL_GROUPS_STARTED, GET_ALL_GROUPS_SUCCEED, GET_ALL_GROUPS_FAILED,} from "../../../constants/actionTypes";
import classnames from 'classnames';

// const override = css`
//     display: block;
//     margin: 0 auto;
//     border-color: red;
// `;

const backdropStyle = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    overflow: 'auto',
};

const modalStyle = function() {
    const top = 5;
    const left = 5;

    return {
        position: 'fixed',
        width: 800,
        height: 650,
        zIndex: 1040,
        top: top + '%',
        left: left + '%',
        border: '0px solid #ffffff',
        backgroundColor: 'white',
        // boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflow: 'auto',
        resize: 'both'
    };
};

class DetailModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            data: [],
            email: '',
            fullName:'',
            userName: '',
            newUser: true,
            activeTab: '1',
            groupdata: [],
        };
    }

    componentDidMount() {
        // alert("did mount");
        if (this.props.userName !== '') {
            this.props.fetchDetail({userName: this.props.userName});
        }
        else {
            this.props.fetchAllModules();
            this.props.fetchAllGroups();
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // alert(nextProps.modules.groupType);
        if (nextProps.show === true) {
            this.setState({
                showModal: true
            });
        }
        if (nextProps.detail.type === GET_USER_DATA_SUCCEED && this.props.userName !== '') {
            this.setState({
                data: nextProps.detail.payload
            })
        }
        if (nextProps.modules.type === GET_ALL_MODULES_SUCCEED && this.props.userName === '') {
            this.setState({
                data: nextProps.modules.payload
            });
        }
        if (nextProps.modules.groupType === GET_ALL_GROUPS_SUCCEED && this.props.userName === '') {
            // alert("yes");
            this.setState({
                groupdata: nextProps.modules.groupPayload
            });
        }
        if (nextProps.userEmail !== '') {
            this.setState({
                email: nextProps.userEmail
            });
        }
        if (nextProps.fullName !== '') {
            this.setState({
                fullName: nextProps.fullName
            });
        }
        if (nextProps.userName !== ''){
            this.setState({
                userName: nextProps.userName,
                newUser: false
            });
        }
    }


    renderBackdrop=(props) => {
        return <div {...props} style={backdropStyle} />;
    };

    cellAuthSelection(cell, row, enumObject, index) {
        return(
            <select
                name={`${index},user_role`}
                value={this.state.data[index]['user_role']}
                onChange={this.onSelectChange}
            >
                {/*<option value='RO'><Trans i18nKey='read-access'>Read</Trans></option>*/}
                {/*<option value='RW'><Trans i18nKey='rw-access'>Read and Write</Trans></option>*/}
                {/* <option value=''>None Selected</option>
                <option value='RO'>Read</option>
                <option value='RW'>Read and Write</option> */}
                <option value={0}>None Selected</option>
                <option value={1}>Allow</option>
                <option value={2}>Deny</option>
            </select>
        );
    }

    cellGroupSelection = (cell, row, enumObject, index) => {
        
        return (
            // <select
            //     name={`${index},user_group`}
            //     value={this.state.groupdata[index]['user_group']}
            //     onChange={this.onSelectChange}
            // >
            //     {/*<option value='NO'><Trans i18nKey='headquarter'>HQ</Trans></option>*/}
            //     {/*<option value=''><Trans i18nKey='none-selected'>None Selected</Trans></option>*/}
            //     <option value=''>None Selected</option>
            //     <option value='NO'>HQ</option>
            // </select>

            <input className='react-bs-select-all'
            type='checkbox'
            // name={`${index},user_group`}
            name={`${row.id},user_group`}
            id={ 'checkbox' + this.props.rowIndex }
            // checked={this.state.groupdata[index]['user_group']}
            checked={cell}
            onChange={this.onGroupSelectChange} />

        );
    }

    onInputChange = (event) => {
        this.setState({
           [event.target.name]: event.target.value
        });
    };

    onSelectChange = (event) => {
        let index = parseInt(event.target.name.split(',')[0]);
        let section = event.target.name.split(',')[1];
        let modData = [...this.state.data];
        modData[index][section] = event.target.value;
        this.setState({
            ...this.state,
            data: modData
        });
    };

    onGroupSelectChange = (event) => {
        // let index = parseInt(event.target.name.split(',')[0]);
        let index = event.target.name.split(',')[0];
        // alert(index);
        let section = event.target.name.split(',')[1];
        let modData = [...this.state.groupdata];
        // alert(event.target.value);
        // alert(event.target.checked);
        // modData[index]['user_group'] = event.target.checked;

        let selectedItem = modData.find((element)=>(element.id == index));
        selectedItem['user_group'] = event.target.checked;
        this.setState({
            ...this.state,
            groupdata: modData
        });
    }

    onSubmit = () => {
        if (this.props.userName !== '') {
            this.props.saveDetail({
                email: this.state.email,
                fullName: this.state.fullName,
                data: this.state.data,
                userName: this.state.userName
            });
        }
        else {
            this.props.addDetail({
                email: this.state.email,
                fullName: this.state.fullName,
                data: this.state.data,
                userName: this.state.userName
            });
        }
    };

    onClose = () => {
        this.setState({
            showModal: false
        });
    };

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    renderGroupTab() {
        let data = [];
        data = this.props.modules.groupPayload;
        const options = {
            page: 1,  // which page you want to show as default
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: '15', value: 15
            } ], // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
          };
        return(
            <BootstrapTable data={data} pagination     options={options}>
            <TableHeaderColumn dataField='name'>
                {/* <Trans i18nKey="system">System</Trans> */}
                Group
            </TableHeaderColumn>
            <TableHeaderColumn dataField='id' isKey={true}>
                <Trans i18nKey="id">ID</Trans>
            </TableHeaderColumn>
            {/* <TableHeaderColumn
                dataField='user_role'
                dataFormat={this.cellAuthSelection.bind(this)}
            >
                <Trans i18nKey="action">Access</Trans>
            </TableHeaderColumn> */}
            <TableHeaderColumn
                dataField='user_group'
                dataFormat={this.cellGroupSelection.bind(this)}
            >
                <Trans i18nKey="action">Assigned</Trans>
            </TableHeaderColumn>
        </BootstrapTable>
        );
    }

    render() {
        // TODO: Add Scrollbar for Modal
        const { userName } = this.props;
        let data = [];
        data = this.props.modules.payload;
        // if (userName !== '')
        //     data = this.props.detail.payload;
        // else {
        //     data = this.props.modules.payload;
        // }

        //------------------------------------------------------
        const options = {
            page: 1,  // which page you want to show as default
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: '15', value: 15
            } ], // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
            // onRowClick: this.handleRowClick,
            // onRowDoubleClick: function(row) {
            //     // alert(`You double click row id: ${row.id}`);
            // }
          };
        //------------------------------------------------------
        return (
            <div>
                <Modal
                    onHide={this.onClose}
                    style={modalStyle()}
                    aria-labelledby="modal-label"
                    show={this.state.showModal}
                    renderBackdrop={this.renderBackdrop}
                >
                    <div>
                        <Col md={12}>
                            <Row>
                                <Col md={6}>
                                    <span>User Name</span>
                                    <input
                                        name='userName'
                                        placeholder='User Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        value={this.state.userName}
                                        disabled={this.props.userName !== ''}
                                    />
                                </Col>
                                <Col md={6}>
                                    <span><Trans i18nKey="fullName">Full Name</Trans></span>
                                    <input
                                        name='fullName'
                                        placeholder='Full Name'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        value={this.state.fullName}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <span>Email</span>
                                    <input
                                        name='email'
                                        placeholder='Email'
                                        onChange={this.onInputChange}
                                        pattern={'^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'}
                                        className="form-control"
                                        value={this.state.email}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <hr/>

                        <Nav tabs>
                            <NavItem style={{ cursor:'pointer'}}>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                                >
                                Device Types
                                </NavLink>
                            </NavItem>
                            <NavItem style={{ cursor:'pointer'}}>
                                <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                                >
                                Groups
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                        <BootstrapTable data={data} pagination     options={options}>
                            <TableHeaderColumn dataField='name'>
                                {/* <Trans i18nKey="system">System</Trans> */}
                                Type
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField='id' isKey={true}>
                                <Trans i18nKey="id">ID</Trans>
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField='user_role'
                                dataFormat={this.cellAuthSelection.bind(this)}
                            >
                                <Trans i18nKey="action">Access</Trans>
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                dataField='user_group'
                                dataFormat={this.cellGroupSelection.bind(this)}
                            >
                                <Trans i18nKey="action">Group</Trans>
                            </TableHeaderColumn> */}
                        </BootstrapTable>
                        </TabPane>
                        <TabPane tabId="2">
                            {this.renderGroupTab()}
                        </TabPane>
                        </TabContent>
                        <hr/>
                        <Button outline color='custom' type="button" onClick={this.onSubmit}>
                            Submit
                        </Button>
                        <Button outline color='secondary' type="reset" onClick={this.onClose}>
                            Close
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

DetailModal.propTypes ={
    userEmail: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    fullName: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = (dispatch) =>{
    return bindActionCreators({ fetchDetail, fetchAllModules, saveDetail, addDetail, fetchAllGroups }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailModal);