/* eslint-disable */
import React, { Component, Fragment,  useState   } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {View, TextInput,styles } from "react";
import DeviceTable from './DeviceTable';
import DeviceTypeTable from './DeviceTypeTable';

import {
    getCarriers,
    getResellerList
} from '../../../../redux/actions/userAction';
import {
    getFullSIMList,
    uploadFileSims,
    SetDeviceType
} from '../../../../redux/actions/inventoryActions';
import InventoryInactiveBoard from './InventoryInactiveBoard';
import StaticData from './StaticData';

class IntegrationFileBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showItemModal: false,
            simId: 0,
            simReseller: 0,
            simPrice: 0,
            simItemDescription: "",
            simItemName: "",

            showDeviceModal: false,
            file: null,
            carrierId: 0,
            itemId: 0
        };
        
    }
    componentDidMount() {
         /*
        this.props.getCarriers();
        this.props.getResellerList();
        this.props.getFullSIMList({ "fullInventory": true });
        */
    }

    /* render() {
        return (
            <div>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <InventoryInactiveBoard currentUserObj={this.props.currentUserObj} carrierList = {this.props.carrierList} resellerList={this.props.resellerList}/>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        )


    } */
    
    render() {
        var inputValue="here";
        return (
            <Fragment>
                <Card>
                    <CardBody>
                        <h4><b>Import Billing Data</b></h4>
                       <DeviceTypeTable
                            carrierList={this.props.carrierList}
                            currentUserObj={this.props.currentUserObj}
                            SIMTypeList={this.props.SIMTypeList}
                            SetDeviceType={this.props.SetDeviceType}
                            getFullSIMList={this.props.getFullSIMList}
                        />
                        <br />
                        <br />

                        {/*<h4><b>SIM Cards</b></h4>
                        <DeviceTable 
                            currentUserObj={this.props.currentUserObj} 
                            carrierList = {this.props.carrierList} 
                            SIMTypeList={this.props.SIMTypeList} 
                            inactiveSIMList={this.props.inactiveSIMList} 
                            uploadFileSims={this.props.uploadFileSims}
                        />*/}
                    </CardBody>
                </Card>
            </Fragment>
        )
    }
}
function mapStateToProps(state) {
    let {
        SIMTypeList,
        activeSIMList,
        inactiveSIMList,
        suspendedSIMList,
        manualBillingStatus
    } = state.inventory;
    let {
        carrierList,
        resellerList
    } = state.user;
    let props = {
        SIMTypeList,
        activeSIMList,
        inactiveSIMList,
        suspendedSIMList,
        resellerList,
        carrierList,
        manualBillingStatus
    }

    return props;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFullSIMList,
        getCarriers,
        getResellerList,
        uploadFileSims,
        SetDeviceType
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationFileBoard);