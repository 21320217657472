/*eslint-disable*/
import React, { Component } from "react";
import Card from "react-credit-cards";
import { Row, Col, Card as Card2, CardBody, CardGroup, Label, Input, Button, Table } from 'reactstrap';
import { BackButton, NextButton } from '../../../shared/components/Button/IconButton';
import { toast } from "react-toastify";
import { USAGE_PRICE } from '../../../constants/dataConstants';

class CompanyDevicePayment extends Component {
  constructor(props) {
    super(props);
  }

  orderNow = () => {
    //   if (this.props.currentPlanItem && this.props.account && Array.isArray(this.props.account) && this.props.account.length > 0) {
    if (Array.isArray(this.props.purchaseItemList) && this.props.purchaseItemList.length > 0 && Array.isArray(this.props.account) && this.props.account.length > 0) {
      let orderList = [];
      this.props.purchaseItemList.forEach((item, index) => {
        let qty = 1;
        if (Array.isArray(this.props.purchaseDeviceList)) {
          let devices = this.props.purchaseDeviceList.filter(device => (device.upc == item.itemDescription));
          qty = devices.length > 0 ? devices.length : 1;
        }
        let obj = {
          "itemId": item.itemId,
          "itemQty": qty,
          "promoCode": 0
        };
        orderList.push(obj);
      });
      let defaultData = {
        "customerId": this.props.customerObj.id,
        "accountId": this.props.account[0].id,
        "currency": "CAD",
        "comment": "1",
        "autoPay": this.props.autoPay ? 1 : 0,
        "orderItemDataList": orderList

      }
      // SPECIFY CORRECT ATTRIBUTES
      let data = {
        // "accountId": this.props.account[0].id,
        // "currency": "CAD",
        // "comment": "1",
        // "autoPay": this.props.autoPay ? 1 : 0,
        // "orderItemDataList": orderList
      }

      //   this.props.customerCheckout({ ...defaultData, ...data }, ()=>{this.props.onNextClick();});
      this.props.customerCheckout({ ...defaultData, ...data }, null);
      this.props.onNextClick();
    } else {
      toast.error('No Item selected');
    }
  }

  priceFormater = (cell, row) => {
    return (
      <span>{`CAD$ ${cell}`}</span>
    )
  }

  tableActionColFormater = (cell, row) => {
    // const ac = this.props.account.filter(account => account.cardMask);
    const card = row;
    const cardwidth = {
      'width': '10px',
    }
    return (
      <div>
        <CardGroup >
          {/* {ac && ac.map((card,index) => (
                // {this.state.cards && this.state.cards.map(card => ( */}
          <Card2 >
            <Card
              number={card.cardMask}
              customCardsSupport={true}
              customCardsStyles={cardwidth}
            />
          </Card2>
          {/* ))}  */}
        </CardGroup>
      </div>
    )
  }

  radioButtonFormater = (cell, row) => {
    return (
      <Input type="radio" name="radio1" onClick={() => { this.onRadioClick(row) }} />
    )
  }
  onRadioClick = (value) => {

    this.props.handleSwitchAccount(value);
    // this.setState({
    //   currentAccount: value,
    // });
  }

  handleCheckout = () => {
    this.orderNow();
    // this.props.onNextClick();
  }

  render() {
    const cardsstyle = {
      'width': '100%',
      'padding': '30px',
    }

    const btn = {
      'float': 'right'
    }
    const aList = this.props.accountList;

    // let usageList = [];
    // if ( Array.isArray(this.props.purchaseDeviceList) ) {
    //     let price = '';
    //     let paymentperiod = 'one shot';
    //     if (this.props.currentPlanItem.itemTypeId == 1) {
    //     price = USAGE_PRICE;
    //     }
    //     else if (this.props.currentPlanItem.itemTypeId == 2 && this.props.currentPlanItem.unitPrice) {
    //     price = `CAD $ ${this.props.currentPlanItem.unitPrice}`;
    //     }

    //     if (this.props.currentPlanItem && this.props.currentPlanItem.payFrequency) {
    //     paymentperiod = this.props.currentPlanItem.payFrequency.itemPayFreqName
    //     }
    //     let item = {
    //             "product": this.props.currentPlanItem.itemName,
    //             "quantity": 1,
    //             "price": price,
    //             "paymentperiod": paymentperiod,
    //             "autocharg": this.props.autoPay ? 'Yes': 'No',
    //             "card": 1000,
    //         };
    //     usageList.push(item)
    //     this.props.purchaseDeviceList.forEach((item, index) => {
    //         let item = {
    //             "product": item.itemName,
    //             "deviceId": item.deviceId,
    //             "networkSupported": item.networkSupported,
    //             "price": item.unitPrice
    //         }
    //     });
    // }
    return (
      <div className="App-cards-list" style={cardsstyle}>
        <div><h3>Order Summary</h3></div><br />
        <BootstrapTable
          data={this.props.purchaseDeviceList}
          // tableHeaderClass = 'header_white' 
          pagination={false}


          bordered={false}
          ref='table'
        >
          <TableHeaderColumn dataField='upc' dataAlign="center" dataSort width='10%' >UPC</TableHeaderColumn>
          <TableHeaderColumn dataField='itemName' dataAlign="center" width='10%'>Product</TableHeaderColumn>
          <TableHeaderColumn dataField='deviceId' isKey={true} dataAlign="center" dataSort width='10%' >Device</TableHeaderColumn>
          <TableHeaderColumn dataField='networkSupported' dataAlign="center" dataSort width='10%' >Network</TableHeaderColumn>
          <TableHeaderColumn dataField='unitPrice' dataFormat={this.priceFormater} dataAlign="center" dataSort width='10%' >Price</TableHeaderColumn>

          {/* <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater}  dataAlign="center" dataSort width='10%' >Payment card</TableHeaderColumn> */}

        </BootstrapTable>
        <br />
        <br />
        <div><h3>Your Payments</h3></div><br />
        <BootstrapTable
          data={aList}
          pagination={false}


          bordered={false}
        >
          <TableHeaderColumn dataField='id' isKey={true} dataAlign="center" hidden >ID</TableHeaderColumn>
          <TableHeaderColumn dataField='' dataAlign="center" width='5%' dataFormat={this.radioButtonFormater}></TableHeaderColumn>
          <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater} dataAlign="center" dataSort width='10%' ></TableHeaderColumn>
          <TableHeaderColumn dataField='cardMask' dataAlign="center" dataSort width='10%' >Card Number</TableHeaderColumn>
          <TableHeaderColumn dataField='expDate' dataAlign="center" dataSort width='10%' >Expires on</TableHeaderColumn>
        </BootstrapTable>
        <br />
        <BackButton
          onClick={this.props.onBackClick}
          label="Back"
          size={30}
          color='black'
        />
        <Button style={btn} variant="primary" color="custom" onClick={() => { this.handleCheckout() }} >Check Out</Button>
      </div>
    );
  }
}

export default CompanyDevicePayment;