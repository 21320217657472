/* eslint-disable */
import axios from "axios";
import { WEB_SERVICE_ROOT } from "../../constants/appConstants";
export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';
export const CHANGE_THEME_TO_CUSTOM = 'CHANGE_THEME_TO_CUSTOM';
export const CHANGE_THEME_TO_DEFAULT = 'CHANGE_THEME_TO_DEFAULT';
export const LOAD_LOGIN_THEME = 'LOAD_LOGIN_THEME';
export const LOAD_LOGIN_THEME_FAILED = 'LOAD_LOGIN_THEME_FAILED';

export function changeThemeToDark() {
  return {
    type: CHANGE_THEME_TO_DARK,
  };
}

export function changeThemeToLight() {
  return {
    type: CHANGE_THEME_TO_LIGHT,
  };
}
export function changeThemeToCustom() {
  return {
    type: CHANGE_THEME_TO_CUSTOM,
  };
}
export function changeThemeToDefault() {
  return {
    type: CHANGE_THEME_TO_DEFAULT,
  };
}

export function loadLoginThemes() {
  return dispatch => axios({
    url: `${WEB_SERVICE_ROOT}loginThemes`,
    method: 'get'
  }).then(res=>{
    console.log(res.data);
    // debugger;
    dispatch({
      type: LOAD_LOGIN_THEME,
      payload: res.data
    })
  }).catch(err=>{
    dispatch({
      type: LOAD_LOGIN_THEME_FAILED,
      payload: err.message
    })
  })
}
