/* eslint-disable */
import React, { Component, useEffect } from 'react';
import BootstrapTableNext from 'react-bootstrap-table-next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const { SearchBar } = Search;
import paginationFactory from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

import { Col, Row, Label, Input, Button } from 'reactstrap';

import { EditIcon, DeleteIcon, EmailIcon, FileDocumentBoxMultipleIcon, PlusCircleIcon, TrashCanOutlineIcon } from 'mdi-react';
import { EditTextButton, DeleteTextButton } from '../../../shared/components/Button/IconButton';
import {
   sortCaret,
   AdjustableTableHeaderColumn,
   AdjustableTableHeaderColumn2
   // AdjustableDiv
} from '../../../shared/components/Table/tableElements';
import icon_edit from '../../../shared/img/icon/edit.png';
import icon_delete from '../../../shared/img/icon/delete.png';
import { TableHeaderColumn } from 'react-bootstrap-table';

export function RenderAssetTable({ assetList, handleEditClick, handleDeleteClick }) {
   // Render nothing if pass in undefined
   // assetList = []
   const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      totalSize: assetList ? assetList.length : 0
   };
   const editButtonFormatter = (cell, row) => {
      let path = `/pages/device/${cell}`;
      return (
         // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
         <div>
            <img src={icon_edit} style={{ width: 20, height: 20 }} alt={"Edit"} onClick={() => handleEditClick(row)} />{' '}
            <img src={icon_delete} style={{ width: 20, height: 20 }} alt={"Delete"} onClick={() => handleDeleteClick(row.assetid)} />
         </div>
      )
   }

   let columns = [
      { dataField: 'assetid', text: 'Asset Id', hidden: true },
      { dataField: 'assetname', text: 'Asset Name' },
      { dataField: 'devicetypename', text: 'Device Type' },
      { dataField: 'assettype', text: 'Asset Type' },
      { dataField: 'description', text: 'Description' },
      { dataField: 'geofence', text: 'Geofence', hidden: true },
      { dataField: 'devices', text: 'Devices', formatter: (cell) => cell.length + ' Devices' },
      { dataField: 'fillerDataField', text: 'Edit', formatter: editButtonFormatter, style: { width: '70px' } },
   ];
   if (!assetList) {
      return (
         <BootstrapTableNext keyField='assetid'
            data={assetList}
            columns={columns}
            pagination={true} bordered={false} />
      )
      // return (
      //    <>Loading...</>
      // )
   }
   return (
      <BootstrapTableNext keyField='assetid'
         data={assetList}
         columns={columns}
         options={options} />
   )
}

// More for easy reuse and testing. Define more specific table when columns are determined
export function AutoRenderAssetTable({ keyField, assetClassList, assetList, editable, handleDeleteClick, handleEditClick }) {
   // Render nothing if pass in undefined
   if (!assetList || assetList.length == 0) {
      return (
         //    <>Loading...</>
         <>No data display ...</>
      )
   }

   useEffect(() => {
   }, [assetClassList]);


   const options = {
      page: 1,
      sizePerPageList: [
         { text: '5', value: 5 },
         { text: '10', value: 10 },
         { text: 'All', value: assetList.length }
      ],
      sizePerPage: 5, pageStartIndex: 1, paginationSize: 5,
      prePage: '<', nextPage: '>', firstPage: '<<', lastPage: '>>',
   };

   // const editFormatter = (cell, row) => (
   //    <div className='actions' style= {{cursor:'pointer'}}>
   //       <a onClick={() => handleEditClick(row.assetid)}><EditIcon color = '#0066cc' /></a>
   //       <a onClick={() => handleDeleteClick(row.assetid)}><TrashCanOutlineIcon color = '#830300' /></a>
   //    </div>
   // )

   const editFormatter1 = (cell, row) => (
      <div className='actions' style={{ cursor: 'pointer' }}>
         <a onClick={() => handleEditClick(row.assetid)}><EditIcon color='#0066cc' /></a>
      </div>
   )

   const editFormatter = (cell, row) => (
      <div className='actions' style={{ cursor: 'pointer' }}>
         <a onClick={() => handleDeleteClick(row.assetid)}><TrashCanOutlineIcon color='#830300' /></a>
      </div>
   )

   const expandRow = {
      // onlyOneExpanding: true,
      renderer: row => {
         if (row.devices && row.devices.length > 0) {
            let col = [
               { dataField: 'deviceid', text: 'Device Id' },
               { dataField: 'devicenamealias', text: 'Device Name' },
               { dataField: 'devicetypename', text: 'Device Type' },
            ]
            return <BootstrapTableNext key={'expand_' + row} keyField='deviceid' data={row.devices} columns={col} dataSort caretRender={sortCaret} pagination={paginationFactory()} />
            // return <TableHeaderColumn key={'expand_' + row} keyField='deviceid' data={row.devices} columns={col} dataSort caretRender={ sortCaret } />
         }
         return (<p>There is no device here</p>)
      }
   };

   let ignoreKeys = ['assetid', 'assetclassid', 'geofence', 'iconid', 'iconscale', 'iconsvg'];
   let option = {
      sortIndicator: true,
      noDataText: 'Loading...',
   };
   let columns = Object.keys(assetList[0]).map(key => {
      let ret = {
         //    dataField: key, text: key.substring(0, 1).toUpperCase() + key.substring(1),
         dataField: key, text: columnHeader(key),
         sort: true
      }
      if (key === 'devices')
         ret.formatter = (cell) => (cell ? cell.length : 0) + ' Devices'
      if (key === 'assetattributes')
         ret.formatter = (cell) => (cell ? cell.length : 0) + ' Attributes'
      if (ignoreKeys.includes(key))
         ret.hidden = true
      ret.headerClasses = 'header_white';
      if (key === 'assetclassname') {
         ret.headerStyle = { width: '180px' };
      }
      if (key === 'parentasset') {
         ret.headerStyle = { width: '190px' };
      }
      return ret
   })

   columns.push({
      dataField: 'editAssetClass', text: 'Edit',
      formatter: editFormatter1, headerStyle: () => ({ width: '100px' }),
      headerClasses: 'header_white',

   })

   columns.push({
      dataField: 'editAssetClass', text: 'Delete',
      formatter: editFormatter, headerStyle: () => ({ width: '100px' }),
      headerClasses: 'header_white',
   })

   return (
      <ToolkitProvider
         keyField={keyField}
         data={assetList}
         columns={columns}
         search={{ searchFormatted: true }}
         dataSort
         caretRender={sortCaret}
      >
         {props => (
            <div class='deviceContainer'>
               <div style={{ float: 'right' }}>
                  <SearchBar {...props.searchProps} style={{ margin: 'auto', width: '100%' }} />
                  {/* <SearchBar {...props.searchProps} style={{width: '200px'}}/>   */}
               </div>
               {/* <SearchBar {...props.searchProps} />
               <hr /> */}
               <BootstrapTableNext
                  {...props.baseProps}
                  expandRow={expandRow}
                  style={{ cursor: 'pointer' }}
                  options={option}
                  sort={{ sortCaret }}
                  datasort
                  striped
                  bordered={false}
                  pagination={paginationFactory()}
                  noDataIndication={ 'There is no data to display' }
               />
            </div>
         )}
      </ToolkitProvider>
   )
}

export function AutoRenderDeviceTable({
   keyField,
   deviceList,
   handleEditClick,
}) {
   if (!deviceList || deviceList.length == 0) {
      return (
         <>No data to display...</>
      )
   };

   useEffect(() => { }, [deviceList]);

   const editFormatter = (cell, row) => (
      <div>
         <a onClick={() => handleEditClick(row.deviceid)}><EditIcon color="#0066cc" /></a>
      </div>
   );


   const expandRow = {
      renderer: row => {
         if (row.devices && row.devices.length.length > 0) {
            let col = [
               { dataField: 'deviceid', text: 'Device ID' },
               { dataField: 'devicenamealias', text: 'Device Name', style: () => ({ cursor: 'pointer' }) },
               { dataField: 'devicetypename', text: 'Device Type' },
            ]
            return <BootstrapTableNext key={'expand_' + row} keyField="deviceid" data={row.devices} columns={col} datasort caretRender={sortCaret} pagination={paginationFactory()} />
         }

         return (<p> There is no device here</p>)
      }
   };

   let ignoreKeys = ["devicename", "assetid", "assetclassid", "geofence", "iconid", "iconscale", "iconsvg", "state", "comstate", "locationlat", "locationlng", "devicetype", "devicetypename", "devicegroup", "lqi", "activationtime", "creationtime", "modemcertificate", "prototype", "automaticrenewal", "automaticrenewalstatus", "createdby", "lasteditiontime", "lasteditedby", "activable", "lastmessagetime", "lastseqnumber", "batteryexpiredate", "companycode"];
   let option = {
      sortIndicator: true,
      noDataText: "Loading...",
   };

   let columns = Object.keys(deviceList[0]).map(key => {
      // console.log("===key", key);
      let ret = {
         dataField: key, text: columnHeader(key),
         sort: true
      }
      if (key === "devices")
         ret.formatter = (cell) => (cell ? cell.length : 0) + "Devices";
      if (key === "deviceattributes")
         ret.formatter = (cell) => (cell ? cell.length : 0) + "Attributes"
      if (ignoreKeys.includes(key))
         ret.hidden = true;

      ret.headerClasses = 'header_white';

      return ret;
   })

   columns.push({
      dataField: 'editDevice', text: 'Edit',
      formatter: editFormatter, headerStyle: () => ({ width: "100px" }),
      headerClasses: "header_white",
   })

   return (
      <ToolkitProvider
         keyField={keyField}
         data={deviceList}
         columns={columns}
         search={{ searchFormatted: true }}
         datasort
         caretRender={sortCaret}
      >
         {props => (
            <div>
               <div style={{ float: "right" }}>
                  <SearchBar {...props.searchProps} style={{ margin: "auto", width: "100%" }} />
               </div>
               <BootstrapTableNext
                  {...props.baseProps}
                  expandRow={expandRow}
                  //  style={{cursor: "pointer"}}
                  options={option}
                  datasort
                  pagination={paginationFactory()}
               />
            </div>
         )}

      </ToolkitProvider>
   )
}

export function RenderAssetClassTable({ assetClasses, tableActions }) {
   if (!assetClasses) {
      return (
         //    'Loading...'
         'No data display ...'
      )
   }
   const { onRowClick, onEditClick, onDeleteClick } = tableActions
   const editFormatter1 = (cell, row) => (
      <div className='actions' style={{ cursor: "pointer" }}>
         {/* <a onClick={() => fillerFunction(row)}><FileDocumentBoxMultipleIcon /></a>
         <a onClick={() => fillerFunction(row)}><PlusCircleIcon /></a> */}
         <a onClick={() => onEditClick(row.id)}><EditIcon color='#0066cc' /></a>
      </div>
   )

   const editFormatter = (cell, row) => (
      <div className='actions' style={{ cursor: "pointer" }}>
         {/* <a onClick={() => fillerFunction(row)}><FileDocumentBoxMultipleIcon /></a>
         <a onClick={() => fillerFunction(row)}><PlusCircleIcon /></a> */}
         <a onClick={(e) => onDeleteClick(e, row.id)}><TrashCanOutlineIcon color='#830300' /></a>
      </div>
   )

   let columns = [
      { dataField: 'id', text: 'Class Id', hidden: true, searchable: false, caretRender: sortCaret },
      { dataField: 'name', text: 'Class Name', caretRender: sortCaret, style: () => ({ cursor: 'pointer' }) },
      { dataField: 'description', text: 'Description', caretRender: sortCaret },
      {
         dataField: 'allowedDeviceTypes', text: 'NO. of Device Types',
         formatter: (cell) => cell ? cell.length : '',
         headerStyle: () => ({ width: '190px' }),
      },
      {
         dataField: 'assets', text: 'NO. of Assets',
         formatter: (cell) => cell ? cell.length : '',
         headerStyle: () => ({ width: '150px' }),
      }
      // { dataField: 'tags', text: 'Tags' },
   ]
   for (let i = 0; i < columns.length; i++) {
      columns[i].headerClasses = 'header_white'
      columns[i].sort = true

   }
   columns.push({
      dataField: 'editAssetClass', text: 'Edit',
      formatter: editFormatter1, headerStyle: () => ({ width: '100px' }),
      headerClasses: 'header_white',
   })

   columns.push({
      dataField: 'editAssetClass', text: 'Delete',
      formatter: editFormatter, headerStyle: () => ({ width: '100px' }),
      headerClasses: 'header_white',
   })

   return (
      <ToolkitProvider
         keyField="id"
         data={assetClasses}
         columns={columns}
         search={{ searchFormatted: true }}
      >
         {props => (
            <div class='deviceContainer'>
               {/* <div style={{display:'flex', justifyContent: 'flex-end'}}> */}
               <div style={{ float: 'right' }}>
                  <SearchBar {...props.searchProps} style={{ margin: 'auto', width: '100%' }} />
               </div>
               {/* <SearchBar {...props.searchProps} />
               <hr /> */}

               <BootstrapTableNext
                  {...props.baseProps}
                  rowEvents={{ onClick: (e, row) => onRowClick(row.id) }}
                  caretRender={sortCaret}
                  pagination={paginationFactory()}
                  sort={{ sortCaret }}
                  striped
                  bordered={false}
                  noDataIndication={ 'There is no data to display' }
               />
            </div>
         )}
      </ToolkitProvider>
   )
}

// -----------------------------------------------------------------------------
function columnHeader(text) {

   switch (text) {
      case 'assetname':
         return 'Asset Name';
      case 'assetclassname':
         return 'Asset Class Name';
      case 'assetattributes':
         return 'Asset Attributes';
      case 'companycode':
         return 'Company Code';
      case 'createdby':
         return 'Created By';
      case 'parentasset':
         return 'NO. of Parent Assets';
      default:
         return text.substring(0, 1).toUpperCase() + text.substring(1);
   }

}