/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import Select from 'react-select';
import {EditableText} from '../../../Asset/components/ClassDisplayDetail';
import {
    // loadDevice, 
    loadAssetClass,   
    getAssetClassAttribute,
    clearAssetClassAttribute
} from '../../../../redux/actions/mapActions';
import {
    getAssetList,
    addDashBoardLayout,
} from '../../../../redux/actions/dashboardActions';
import {
    getAttributes,
    clearAttributes,
} from '../../../../redux/actions/attributeMgmtAction';
import { toast } from "react-toastify";
import {UserContext} from '../../../../constants/myContexts';
import { isStringEmpty, groupBy } from '../../../../factories/utils';
import {addDashBoardTemplateEntry} from "../../../../redux/actions/dashboardTemplateAction";

class AssetDataAddElement extends Component {
    static contextType = UserContext;
    constructor(props){
        super(props);
        this.state={
            currentAssetClassId: null,
            currentAssetId: null,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
            devices: [],
        }
    }

    componentDidMount(){
        const  {userObj, updateUserObj} = this.context;
         
        this.props.loadAssetClass();
    }
// --------------------------------------------------------------
    handleInputChange =(newVal) => {
        this.setState((prevState)=>{
            return ({
                currentName: newVal,
                // layout: prevState.layout,
            });
        }, ()=>{
             
             
        });
    }
    renderTitle =()=> {
        return(
            
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{display: 'inline'}} ><h4><b> Name :</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                        type='text'
                        //  defaultValue={this.props.layout ? this.props.layout.name : ''} 
                        defaultValue={this.state.currentName ? this.state.currentName : ''} 
                        exitOnEnterKeyPress={false}
                        onSave={newVal => {this.handleInputChange(newVal)}}
                    />
                </Col>
            </Row>           
        )
    }
// --------------------------------------------------------------
    onAssetClassChange = (e) => {
         
        let assetClassId = e.target.value;
        this.setState({
            currentAssetClassId: assetClassId,
            currentAssetId: null,
            devices:[],
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
        });
        this.props.getAssetList({assetclassid: assetClassId});
        this.props.getAssetClassAttribute({assetclassid: assetClassId});
        //  
        // this.props.clearAttributes();
    }
    renderAssetClass = () => {
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="assetclass" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="assetclass" name="assetclass" width="100%" value={this.state.currentAssetClassId} onChange={this.onAssetClassChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={0}>NONE</option> 
                        {this.props.assetClasses && this.props.assetClasses.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }
// --------------------------------------------------------------
    onAssetChange = (e) => {
        let assetId = e.target.value;
        this.setState({
            currentAssetId: assetId,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
        }, () => {
            // this.setDeviceList(this.props.assets);
        });
        // this.props.clearAttributes();
    }
    renderAsset = () => {
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Input type="select" id="asset" name="asset" width="100%" value={this.state.currentAssetId} onChange={this.onAssetChange} >                                    
                        {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                        <option value={0}>NONE</option> 
                        {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))}
                    </Input>
                </Col>
            </Row>
        )
    }
// --------------------------------------------------------------
    // onAttrChange = (e) => {
    //      
    //     let index = e.target.selectedIndex
    //     this.setState({
    //         currentAttrId: e.target.value,
    //         currentAttrName: e.target.options[index].text
    //     });
    // }
    onAttrChange = selectedOption => {
        let currentAttrList = selectedOption.map(item=>({attrid: item.attributeid, attributename:item.attributename}));
        this.setState({
            currentAttrList: currentAttrList
        });
    }

    renderAttribute = () => {
         
        let attributeOptions = [];
        if (this.props.assetClassAttr && this.props.assetClassAttr.length > 0 && this.props.assetClassAttr[0].atrributes) {
            let attributeMap = groupBy(this.props.assetClassAttr[0].atrributes, attr => attr.devicetypename);
            for (let [key, value] of attributeMap) {
                let group = {
                    label: key,
                    options: value,
                }
                attributeOptions.push(group);
            }
        }
        return (
            <Row>
                <Col md={3} style={{textAlign: 'left' }}>
                    <Label for="attribute" style={{display: 'inline', width: "100%"}} ><h4><b>Attribute</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Input type="select" id="attribute" name="attribute" width="100%" value={this.state.currentAttrId} onChange={this.onAttrChange} >                                    
                        <option value={0}>NONE</option> 
                        {this.props.attributes && this.props.attributes.map((attr, i) => (<option key={attr.attributeid} value={attr.attributeid}>{attr.attributename}</option>))}
                    </Input> */}
                    <Select 
                        isMulti
                        isClearable
                        isSearchable
                        options = {attributeOptions}
                        getOptionLabel={({ attributename }) => attributename}
                        getOptionValue={({ attributeid }) => attributeid}
                        onChange={this.onAttrChange}
                    />
                </Col>
            </Row>
        )
    }
// --------------------------------------------------------------
    onCancleClick = (e) => {
        this.props.toggle();
    }

    validateInput(){
        // let isValidated = true;
        let hasUser = this.context != undefined && this.context != null && this.context.userObj != undefined && this.context.userObj != null;
        if (!hasUser){
             
            toast.error("Can not get user infomation");
        }
        let isInput = !isStringEmpty(this.state.currentName) 
        let isSelect = this.state.currentAssetId != null && this.state.currentAssetId != 0 && this.state.currentAssetClassId != null && this.state.currentAssetClassId != 0;
        let isTooLong = this.state.currentAttrList != undefined && this.state.currentAttrList != null && this.state.currentAttrList.length > 4;
        if (!isInput || !isSelect){
             
            toast.error("Input is not valid");
        }
        if (isTooLong) {
             
            toast.error("Max attributes are 4");
        }
         
         
        return hasUser && isInput && isSelect && !isTooLong;
    }
    onOkClick = (e) => {
        //  
        if (!this.validateInput()) {
            //  
            return;
        }
        //  
        let position = {w:3,h:7,x:8,y:8};
        let parameter = {
            assetclassid: this.state.currentAssetClassId,
            assetid:this.state.currentAssetId, 
            attributes: this.state.currentAttrList ? this.state.currentAttrList : [],
        };
        let layout = {
            id: 0,
            templateid: this.props.templateId,
            templatename: this.props.templateName,
            name: this.state.currentName,
            type: "AssetElement",
            width: 9,
            locked: 0,
            display: 0,
            position: JSON.stringify(position),
            parameter: JSON.stringify(parameter),
            username: this.context.userObj.userName,
            createdby: this.context.userObj.userName,
            companycode: this.context.userObj.companyCode,
            // createdtime: "2019-02-04T03:28:50.000+0000",
            createdtime: new Date().toUTCString(),
        }
         
        this.props.addDashBoardTemplateEntry(layout);
        this.props.toggle();
        this.props.parentToggle();
    }

// --------------------------------------------------------------
    render(){
        return (
            <Card>
                <CardBody>
                    {/* <CardTitle>Set Attribute Value</CardTitle> */}
                    {this.renderTitle()}
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAssetClass()}
                        </Col>
                    </Row>
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAsset()}
                        </Col>
                    </Row>
                    {/* <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderDevice()}
                        </Col>
                    </Row> */}
                    <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderAttribute()}
                        </Col>
                    </Row>
                    <hr/>
                    <div style={{textAlign: 'right'}}>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                    </div>
                </CardBody>
            </Card>
        ); 
    }
}
function mapStateToProps(state) {
    // const types = state.mapboard.types;
    const assets = state.dashboard.assetList;
    const assetClasses = state.mapboard.assetClass;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const attributes = state.attributemgmt.attributes;
    const props = {
        // types,  
        assetClasses,
        assetClassAttr,
        assets,
        attributes,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            // loadDevice, 
            loadAssetClass,
            getAssetClassAttribute,
            clearAssetClassAttribute,
            getAssetList,
            getAttributes,
            clearAttributes,
            addDashBoardLayout,
        addDashBoardTemplateEntry
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetDataAddElement);
