/* eslint-disable */
import axios from 'axios';
import {WEB_SERVICE_ROOT, WEB_SERVICE_SEND_EMAIL, SUPPORT_EMAIL} from '../../constants/appConstants';
import { axiosGet, axiosPost, getCurrentUser, base64Encode, getJwttoken } from '../../factories/utils';
import { CONTACT_US_EMAIL_SEND_SUCCEED, CONTACT_US_EMAIL_SEND_FAILED } from '../../constants/actionTypes';

export function sendEmail(val) {
    const {email, phone, subject, message} = val;
    let title = 'IOT support-' + phone + ' - ' + subject;

    const data = {
        "to": SUPPORT_EMAIL,
        "content": message,
        "subject":title,
        "msgfrom":email
    }


    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_SEND_EMAIL}`,
        //dataObject: templateObj,
        criteria: data,
        logText: 'Send email Url ',
        errorMessage: 'Failed to sent a email.',
        successMessage: 'Successfully sent a email.',
        // successCallback: () => { return(getNotificationTemplate())}, 
        // errorCallback: (msg) => {toast.error(msg)}
        sccessType: CONTACT_US_EMAIL_SEND_SUCCEED,
        failedType: CONTACT_US_EMAIL_SEND_FAILED,
        responseKeyGetter: (response) => (response.data.status),
        successContent: "Succeed",
    };

    return (axiosPost(paramObj));
}