/* eslint-disable */
import PropTypes from 'prop-types'
import axios from 'axios'
import { toast } from "react-toastify";
import {
  USER_GET_FAILED,
  REPORT_LOAD_ALL_REPORTS_SUCCESS,
  REPORT_LOAD_ALL_REPORTS_FAILED, 
  REPORT_DELETE_ONE_REPORT_SUCCESS,
  REPORT_DELETE_ONE_REPORT_FAILED, 
  REPORT_GET_ONE_REPORT_SUCCESS,
  REPORT_GET_ONE_REPORT_FAILED ,
  REPORT_LOAD_ONE_REPORT,
  REPORT_ADD_NEW_REPORT,
  REPORT_UPDATE_EXISTING_REPORT,
  REPORT_UPSERT_ONE_REPORT_SUCCESS,
  REPORT_UPSERT_ONE_REPORT_FAILED,   
} from '../../constants/actionTypes';
import {WEB_SERVICE_ROOT} from '../../constants/appConstants';
import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
  const token = getJwttoken();
  config.headers.Authorization =  `Bearer ${token}`;
  return config;
});

// const reportRoute = 'http://13.88.246.7:8080/sigfox/Report';
const reportRoute = `${WEB_SERVICE_ROOT}Report`;

// ?assetid = ... (optional)
export function loadReports() {
  //  const mockReportsData = [
  //     { id: 1, reportList },
  //  ]
  //  let res = mockReportsData
  //  return dispatch => {
  //     dispatch({
  //        type: ASSET_LOAD_GROUP_SUCCEED,
  //        payload: res
  //     })
  //  }

    let userObj = getCurrentUser();
    if (userObj === null){
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }
    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

   return dispatch => axios({
      url: reportRoute,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'uTk': base64Encode(JSON.stringify(userTokenObj)),
    },
   }).then(res => { 
      dispatch({
         type: REPORT_LOAD_ALL_REPORTS_SUCCESS,
         payload: res.data
      })
   }).catch(err => {
      dispatch({
         type: REPORT_LOAD_ALL_REPORTS_FAILED,
         error: err.message
      })
   })
}
// ----------------------------------------------------------
export function deleteOneReportAjex(reportid) {
  let userObj = getCurrentUser();
  if (userObj === null || userObj.userName.length === 0){
     
     
    return dispatch => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    }
  }
  let {userName, companyCode, role} = userObj;
  let userTokenObj = {userName, companyCode, role};

  return dispatch => axios({
          url: reportRoute + '?reportid=' + reportid,
          method: 'delete',
          headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
          },
        }).then(res => {
            if (res.status === 200) {
              dispatch(loadReports());
              toast.success('Successfully Deleted the Report');
            }else{
              toast.error('Failed to delete the report');
            }
            // return res.status;
        }).catch(err => {
            console.error(err);
            toast.error('Failed to delete the report');
        })
}
// ----------------------------------------------------------
// export function deleteOneReport (reportid) {
//   deleteOneReportAjex(reportid).then(
//     res => {loadReports()}
//   );
// }
// ----------------------------------------------------------
export function deactiveOneReportAjex(reportid) {
  let userObj = getCurrentUser();
  if (userObj === null){
    return dispatch => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    }
  }
  let {userName, companyCode, role} = userObj;
  let userTokenObj = {userName, companyCode, role};

  return dispatch => axios({
    url: reportRoute + '/deactive?reportid=' +  reportid,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'uTk': base64Encode(JSON.stringify(userTokenObj)),
    },
  }).then(res => {
    if (res.status === 200) {
      dispatch(loadReports());
      // return res;
    }else{
      throw new Error("failed deactive the reports");
    }
  }).catch(err => {
       
  })
}
// ----------------------------------------------------------
// export function deactiveOneReport (reportid) {
//   deactiveOneReportAjex(reportid).then(res => {
//     loadReports();
//   });
// }
// ----------------------------------------------------------
export function activeOneReportAjex(reportid) {
  let userObj = getCurrentUser();
  if (userObj === null){
    return dispatch => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    }
  }
  let {userName, companyCode, role} = userObj;
  let userTokenObj = {userName, companyCode, role};

  return dispatch => axios({
    url: reportRoute + '/active?reportid=' +  reportid,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'uTk': base64Encode(JSON.stringify(userTokenObj)),
    },
  }).then(res => {
    if (res.status === 200) {
      dispatch(loadReports());
      // return res;
    }else{
      throw new Error("failed active the reports");
    }
  }).catch(err => {
       
  })
}
// ----------------------------------------------------------
// export function activeOneReport (reportid) {
//   activeOneReportAjex(reportid).then(res => {
//     loadReports();
//   });
// }
// -----------------------------------------------------------
export function getOneReport (reportid) {
  let userObj = getCurrentUser();
  if (userObj === null){
    return dispatch => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    }
  }
  let {userName, companyCode, role} = userObj;
  let userTokenObj = {userName, companyCode, role};

  if (!reportid || reportid.length === 0){
    let newReportData =  {
                              "reportname": "",
                              "timezone": "America/Toronto",
                              "format": "excel",
                              "subject": "",
                              "msgbody": "",
                              "receiver": "",
                              "header": "",
                              "datetime": 10,
                              "status": "Active",
                              "sectionList": [
                                  {
                                      "headstr": "",
                                      "headsize": 1,
                                      "templateList": [
                                          {
                                              "type": 1,
                                              "name": "",
                                              "ruleList": [
                                                {
                                                  "assetclassid": "",
                                                  "assetclassname": "",
                                                  "assetid": "",
                                                  "assetname": "",
                                                  "colname": "",
                                                  "operation": "",
                                                  "time": ""
                                              }
                                              ]
                                          }
                                      ]
                                  }
                              ]
                          };
    return dispatch => {
      dispatch({
        type: REPORT_GET_ONE_REPORT_SUCCESS,
        payload: newReportData,
        message: "Create a New Report",
      });
    }
  } 

 return dispatch => axios({
    url: reportRoute + '?reportid=' + reportid,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'uTk': base64Encode(JSON.stringify(userTokenObj)),
    },
 }).then(res => { 
   if (res.status === 200 && res.data && res.data.length > 0){
      dispatch({
        type: REPORT_GET_ONE_REPORT_SUCCESS,
        payload: res.data[0]
    })
   }else{
    dispatch({
        type: REPORT_GET_ONE_REPORT_FAILED,
        error: 'Failed to get report details'
    })
   }
   
 }).catch(err => {
    dispatch({
       type: REPORT_GET_ONE_REPORT_FAILED,
       error: err.message
    })
 })
}
// -------------------------------------------------------------
export function upsertOneReport(reportObj){
  let userObj = getCurrentUser();
  if (userObj === null){
    return dispatch => {
      dispatch({
        type: USER_GET_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    }
  }
  let {userName, companyCode, role} = userObj;
  let userTokenObj = {userName, companyCode, role};

  if (!reportObj) {
    return dispatch => {
      dispatch({
        type: REPORT_UPSERT_ONE_REPORT_FAILED,
        error: "Failed to get current user, Please re-login",
        message: "Failed to get current user, Please re-login",
      });
    }
  }

  return dispatch => axios({
    url: reportRoute,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'uTk': base64Encode(JSON.stringify(userTokenObj)),
    },
    data: reportObj,
 }).then(res => { 
    if (res.status === 200){
      dispatch({
        type: REPORT_UPSERT_ONE_REPORT_SUCCESS,
        payload: res.data
     })
     dispatch(loadReports());
     toast.success('Successfully Updated the Report');
    }else{
      dispatch({
        type: REPORT_UPSERT_ONE_REPORT_FAILED,
        error: 'failed upsert report',
     })
     toast.error('Failed to update the report');
    }
   
 }).catch(err => {
    dispatch({
       type: REPORT_UPSERT_ONE_REPORT_FAILED,
       error: err.message
    })
    toast.error('Failed to update the report');
 })


}

/////////
// WIP //
/////////
