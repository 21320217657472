/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Card, CardBody, Input, Button } from 'reactstrap';
class ClientCarrierPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            password: "",
            identifier: ""
        };
    };
    componentDidMount() {

    };
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onSave = () => {
        var obj = {
            carrierId: this.props.id,
            name: this.state.name,
            password: this.state.password,
            identifier: this.state.identifier
        }
        this.props.onSave(obj);
        this.setState({
            name: "",
            password: "",
            identifier: ""
        })
    }
    validateForm = () => {
        return (this.state.name && this.state.name !== '') && (this.state.password && this.state.password !== '') && (this.state.identifier && this.state.identifier !== '') != "";
    }
    render() {
        return (
            <div>
                <Row style={{ borderRadius: "5px", padding: "10px", borderStyle: "solid" }}>
                    <Col md={3}>
                        <p> Name : <p style={{ "font-weight": "bold" }}>{this.props.name}</p></p>
                    </Col>
                    <Col md={3}>
                        <p>Username :
                            <Input
                                name='name'
                                placeholder='Name'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.name}
                            />
                        </p>
                    </Col>
                    <Col md={3}>
                        <p>Password :
                            <Input
                                name='password'
                                placeholder='password'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.password}
                            />
                        </p>
                    </Col>
                    <Col md={3}>
                        <p>Identifier :
                            <Input
                                name='identifier'
                                placeholder='identifier'
                                onChange={this.onInputChange}
                                className="form-control"
                                value={this.state.identifier}
                            />
                        </p>
                    </Col>

                    <Col md={3}>
                        <Button color='custom' type="button" style={{ marginTop: "10px" }} disabled={!this.validateForm()} onClick={() => this.onSave()}>
                            Save
                        </Button>
                    </Col>

                </Row>
                <br />
            </div>
        );
    };
};
export default ClientCarrierPanel;