/* eslint-disable */
import axios from 'axios';
import { ConsoleLineIcon } from 'mdi-react';
import { toast } from "react-toastify";
import {
    ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED,
    ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,

    INVENTORY_GET_DEVICE_MASTER_SUCCEED,
    INVENTORY_GET_DEVICE_MASTER_FAILED,
    INVENTORY_GET_INVENTORY_SUCCEED,
    INVENTORY_GET_INVENTORY_FAILED,
    INVENTORY_GET_ONE_DEVICE_MASTER_SUCCEED,
    INVENTORY_GET_ONE_DEVICE_MASTER_FAILED,
    USER_GET_FAILED,
    WEB_SERVICE_INVENTORY_SUPLLIER_SUCCESS,
    WEB_SERVICE_INVENTORY_SUPLLIER_ITEM_SUCCESS,
    INVENTORY_GET_CONSUMER_SIM_SUCCEED,
    INVENTORY_GET_CONSUMER_SIM_FAILED,
    NEW_RESELLER_GET_DEVICE_INVENTORY_SUCCEED,
    NEW_RESELLER_GET_DEVICE_INVENTORY_FAILED,
    RESELLER_SET_ITEM_SUCCEED,
    RESELLER_SET_ITEM_FAILED,
    ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_SUCCEED,
    ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_FAILED,
    CARRIER_GET_PLANS_SUCCESS,
    CARRIER_GET_PLANS_FAILED,
    CARRIER_UPDATE_INFO_SUCCESS,
    CARRIER_UPDATE_INFO_FAILED,
    USER_CLEAR_CARRIER_PLAN_LIST_SUCCESS,
    USER_CLEAR_CARRIER_PLAN_LIST_FAILED,
    COMPANY_GET_ACTIVITY_REPORT_SUCCESS,
    COMPANY_GET_ACTIVITY_REPORT_FAILED,
    SIM_SET_NAME_SUCCESS,
    SIM_SET_NAME_FAILED,
    WEB_SERVICE_GET_SAP_CSV_SUCCESS,
    WEB_SERVICE_GET_SAP_CSV_FAILED,
    CLEAR_CLIENT_DATA,
    INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_SUCCEED,
    INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_FAILED,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_START,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_FAILED,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_FAILED,
    GET_DEVICE_LIST_SUCCESS,
    GET_DEVICE_LIST_FAILED,
    CHANGE_DEVICE_STATUS_SUCCESS,
    CHANGE_DEVICE_STATUS_FAILED,
    REGISTER_DEVICE_SUCCESS,
    REGISTER_DEVICE_FAILED,
} from '../../constants/actionTypes';

import {
    WEB_SERVICE_ROOT,
    // WEB_SERVICE_ATTRIBUTES,
    // WEB_SERVICE_DEVICETYPE,
    // WEB_SERVICE_ASSET,
    // WEB_SERVICE_ACTION,
    // WEB_SERVICE_DEVICE,
    // WEB_SERVICE_GEOFENCE,
    // WEB_SERVICE_RULE,
    // WEB_SERVICE_ACTION_RULE,
    // WEB_SERVICE_ACTION_ALERT,
    // WEB_SERVICE_ACTION_LOG,
    // WEB_SERVICE_ACTION_TEMPLATE,
    WEB_SERVICE_POST_INVENTORY_DEVICE,
    WEB_SERVICE_GET_ALL_INVENTORY,
    WEB_SERVICE_GET_DEVICE_MASTER,
    WEB_SERVICE_GET_ONE_DEVICE_MASTER,
    WEB_SERVICE_SEND_EMAIL,
    WEB_SERVICE_SET_SIM_NAME,
    WEB_SERVICE_GET_CARRIER_PLANS,
    WEB_SERVICE_UPDATE_CARRIER_PLANS,
    WEB_SERVICE_GET_ACTIVITY_REPORT,
    WEB_SERVICE_TELELCOM_BACKEND_ROOT,
    WEB_SERVICE_PAYMENT_ORDER_ROOT,
    WEB_SERVICE_GET_SIM_INVENTORY,
    WEB_SERVICE_GET_CONSUMER_INVENTORY,
    WEB_SERVICE_GET_RESELLER_FULL_INVENTORY_AND_ITEM,
    WEB_SERVICE_SET_DEVICE_TYPE,
    WEB_SERVICE_DELETE_DEVICE_TYPE,
    WEB_SERVICE_UPLOAD_DEVICE,
    WEB_SERVICE_GET_RESELLER_ITEM_AND_INVENTORY,
    WEB_SERVICE_IOTCellAPI_USER_ROOT,
    WEB_SERVICE_IOTCellAPI_GET_AVAILABLE_SERVICE_PLAN,
    WEB_SERVICE_ACTIVATE_SIM,
    WEB_SERVICE_UPLOAD_INVENTORY,
    WEB_SERVICE_GET_SAP_CSV,
    WEB_SERVICE_LOAD_CUSTOMER_INVENTORY_RECORDS,
    WEB_SERVICE_GET_SIM_LAST_KNOWN_LOCATION,
    WEB_SERVICE_INVENTORY_LOAD_RESELLER_BY_CONTRACT,
    WEB_SERVICE_INVENTORY_RUN_MANUAL_BILLING,
    WEB_SERVICE_INVENTORY_FETCH_MANUAL_BILLING_STATUS,
    WEB_SERVICE_SET_SIM_TYPE,
    WEB_SERVICE_GET_DEVICE_LIST,
    WEB_SERVICE_CHANGE_DEVICE_STATUS,
    WEB_SERVICE_REGISTER_DEVICE

} from '../../constants/appConstants';
import { axiosGet, axiosPost, getCurrentUser, base64Encode, getJwttoken, ExportCSV, fileHandler } from '../../factories/utils';
import { getActivatedSimDashboard, getServicePlanItem } from './userAction';
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

function readFileDataAsBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsDataURL(file);
    });
}
export function getSimLastKnownLocation(data) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_SIM_LAST_KNOWN_LOCATION}`,
        criteria: data,
        logText: 'Get Inventory LAST LOCATION Url ',
        sccessType: INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_SUCCEED,
        failedType: INVENTORY_GET_SIM_LAST_KNOWN_LOCATION_FAILED,
        errorMessage: 'Failed to get LOCATION.'
    };
    return (axiosPost(paramObj));
}

export function getSAPCSV(filterObj) {
    const url = `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_GET_SAP_CSV}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            return request.then((response) => {
                //console.log('response ')
                //console.log(response)
                if (response.status == 200 && response.data) {
                    var file;
                    const fileObj = {
                        file: ExportCSV(response.data)
                    };
                    //console.log(response.data)
                    //console.log( typeof(response.data))
                    response.data = Object.assign(response.data, fileObj)
                    console.log(response.data)
                    dispatch({
                        type: WEB_SERVICE_GET_SAP_CSV_SUCCESS,
                        data: response.data,
                        message: response.data.message,
                        file: file
                    });

                }
                else {
                    dispatch({
                        type: WEB_SERVICE_GET_SAP_CSV_FAILED,
                        message: response.data.message
                    });
                }

                return response;
            })
        })
}

/* Manage manual billing */

/* Get manual billing status */
export function getManualBillingStatus() {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_INVENTORY_FETCH_MANUAL_BILLING_STATUS}`,
        criteria: {},
        logText: `Fetchin manual billing status...`,
        errorMessage: `Failed to fetch manual billing status.`,
        successMessage: `Successfully fetched manual billing status.`,
        sccessType: COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_SUCCESS,
        //failedType: COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_STATUS_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosGet(paramObj));
}

/* Set an async flag to indicate manual billing is starting */
export function startManualBilling() {
    return (
        (dispatch) => {
            dispatch({
                type: COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_START
            });
        }
    )
}

/* Run manual billing */
export function runManualBilling() {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_INVENTORY_RUN_MANUAL_BILLING}`,
        criteria: {},
        logText: `Running manual billing...`,
        errorMessage: `Failed to run manual billing.`,
        successMessage: `Successfully ran manual billing.`,
        sccessType: COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_MANUAL_BILLING_FAILED,
        responseKeyGetter: (response) => (response.data),
        successContent: '200',
    };

    return (axiosGet(paramObj));
}
export function resetFullSIMList(){
    return (
        (dispatch) => {
            dispatch({
                type: INVENTORY_GET_CONSUMER_SIM_FAILED,
                reset: true
            });
        }
    )
}
export function getFullSIMList(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_SIM_INVENTORY}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    dispatch({
                        type: INVENTORY_GET_CONSUMER_SIM_SUCCEED,
                        timestamp: response.data.timestamp,
                        deviceTypes: response.data.deviceTypes,
                        active: response.data.activeSims,
                        inactive: response.data.inActiveSims,
                        suspended: response.data.suspendedSims,
                        company: response.data.companySims,
                        deviceDefinition: response.data.deviceDefinition,
                        itemType:response.data.itemTypes
                    });

                }
                else {
                    dispatch({
                        type: INVENTORY_GET_CONSUMER_SIM_FAILED,
                        timestamp: response.data.timestamp,
                        message: response.data.message
                    });
                }

                return response;
            })
        })
}
export function activateMultipleSIM(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_ACTIVATE_SIM}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            return (
                request.then((response) => {
                    if (response.status == 200 && response.data) {
                        if (response.data.includes("Error")) {
                            toast.error(response.data)
                        } else {
                            toast.success(response.data)
                        }

                    }
                    else {
                        toast.error(response.data)
                    }

                    return response;
                })
            )
        })
}
export function addMultiDevicesInventory(formdata) {
    let userObj = getCurrentUser();
    if (!formdata || formdata === null) {
        return dispatch => {
            dispatch({
                type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
                message: 'device data input is not valid'
            })
        }
    }

    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_LOAD_DEVICE_INVENTORY}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: formdata,
    })

    return ((dispatch) => {

        return (

            request.then((response) => {

                if (response.status === 200) {
                    console.log(response)
                    dispatch(
                        {
                            type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED,
                            message: response.data.message,
                        }
                    );

                    if (response.data === "success") {

                        toast.success(response.data);
                    }
                    else {

                        toast.error(response.data);
                    }

                    dispatch(getActivatedSimDashboard({
                        "type": "monthly",
                        "companyCode": userObj.companyCode,
                        "resellerCode": userObj.resellerCode,
                        "customerId": null //change once customerId is included in checkuser
                    }));


                } else {
                    dispatch(

                        {
                            type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
                            message: response.data.message,
                        }

                    );

                    toast.error(response.data);

                }
                return response.data;

            }
            ))

    }

    );

}
export function findResellerCodeByContract(contract) {
    let userObj = getCurrentUser();
    console.log('companyCode', userObj.companyCode)
    console.log('contract', contract)
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_INVENTORY_LOAD_RESELLER_BY_CONTRACT}?contract=${encodeURIComponent(contract)}`;

    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'text/plain;charset=UTF-8',
        },
        data: null,
    })

    return ((dispatch) => {
        return request.then((response) => {
            if (response.status === 200) {
                dispatch(getServicePlanItem({ "companyCode": userObj.companyCode, "resellerCode": response.data }))
            } else {
                toast.error('');
            }
        })
    });

}
export function addMultiCustomersInventoryRecords(records) {
    console.log('addMultiCustomersInventoryRecords', records)
    let userObj = getCurrentUser();

    const payload = {
        companyCode: userObj.companyCode,
        records
    }

    console.log('payload', payload)

    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_LOAD_CUSTOMER_INVENTORY_RECORDS}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: payload,
    })

    return ((dispatch) => {
        return (
            request.then((response) => {
                if (response.status === 200) {
                    console.log(response)
                    if (response.data === "success") {
                        toast.success("Inventory items processed successfully");
                    }
                    else {
                        toast.error(response.data);
                    }
                } else {
                    toast.error(response.data);
                }
                return response.data;
            })
        )
    });
}
export function addMultiCustomersInventoryFiles(formdata) {
    let userObj = getCurrentUser();
    // if (!formdata || formdata === null) {
    //     return dispatch => {
    //         dispatch({
    //             type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
    //             message: 'device data input is not valid'
    //         })
    //     }
    // }

    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_LOAD_CUSTOMER_INVENTORY}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: formdata,
    })

    return ((dispatch) => {
        return (
            request.then((response) => {

                if (response.status === 200) {
                    console.log(response)
                    // dispatch(
                    //     {
                    //         type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED,
                    //         message: response.data.message,
                    //     }
                    // );

                    if (response.data === "success") {

                        toast.success("Inventory items processed successfully");
                    }
                    else {

                        toast.error(response.data);
                    }

                    // dispatch( getActivatedSimDashboard({
                    //     "companyCode": userObj.companyCode,
                    //     "resellerCode": userObj.resellerCode,
                    //     "customerId": null //change once customerId is included in checkuser
                    //  }));


                } else {
                    // dispatch(

                    //     {
                    //         type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
                    //         message: response.data.message,
                    //     }

                    // );

                    toast.error(response.data);

                }
                return response.data;
            })
        )
    });
}
export function uploadFile(file) {
    const formData = new FormData();
    let message = '';
    // formData.append('file', file);
    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_POST_INVENTORY_DEVICE}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: file,
    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    message = 'Upload file successfully.';
                    dispatch({
                        type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_SUCCEED,
                        payload: response.data,
                    });
                    dispatch(getInventoryList());
                    toast.success(message);
                } else {
                    message = 'Faild to upload file ';
                    dispatch({
                        type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
                        message: message,
                    });
                    console.error(message);
                    toast.error(message);
                }

            }).catch((error) => {
                message = 'Errors occurred when upload file ';
                dispatch({
                    type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
                    message: error.message,
                });
                console.error('upload action error(s) : ' + error.message);
                toast.error(message);
            });
        }
    );

}
export function getInventoryList() {
    // const data = [
    //     {
    //         upc: '4210000526',
    //         company: 'ITSP ',
    //         mfg: 2100,
    //         group: 'ITSP goup',
    //         oursku: 'our sku',
    //         mfgsku: 'mfg sku',
    //         quantity: 100,
    //         soldquantity: 11,
    //         network: 'sigfox',
    //     },
    // ];
    // return  (
    //     dispatch => {
    //         dispatch({
    //             type: INVENTORY_GET_INVENTORY_SUCCEED,
    //             payload: data,
    //         });
    //     }
    // )

    let creteria = null;
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ALL_INVENTORY}`,
        criteria: creteria,
        logText: 'Get Inventory Url ',
        sccessType: INVENTORY_GET_INVENTORY_SUCCEED,
        failedType: INVENTORY_GET_INVENTORY_FAILED,
        errorMessage: 'Failed to get Inventory.'
    };

    return (axiosGet(paramObj));
}

export function getDeviceMasterList(UPCcode) {
    // const data = [
    //     {
    //         deviceId: 'des0000001',
    //         upc: '4210000526',
    //         mfg: 'MFGTEST',
    //         mfgsku: '001',
    //         mfgserialnum: '0000001',
    //         macaddress: 'AA:BB:CC:00:00:00',
    //         devicestatus: null,
    //         specialstock: 'N',
    //         customerid: null,
    //         company: 124,
    //         networksupported: 'sigfox',
    //         refurbished:'N',
    //         olddeviceId: null,
    //         createtime: '2020-01-08 00:00:00',
    //         updatetime: '2020-01-08 00:00:00',
    //         updateby: 'user001',          
    //     },
    // ]
    // return(
    //     dispatch => {
    //         dispatch({
    //             type: INVENTORY_GET_DEVICE_MASTER_SUCCEED,
    //             payload: data,
    //         })
    //     }
    // )
    let creteria = { upc: UPCcode };
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_DEVICE_MASTER}`,
        criteria: creteria,
        logText: 'Get Device Master Url ',
        sccessType: INVENTORY_GET_DEVICE_MASTER_SUCCEED,
        failedType: INVENTORY_GET_DEVICE_MASTER_FAILED,
        errorMessage: 'Failed to get Device Master.'
    };

    return (axiosGet(paramObj));
}

export function getOneDeviceMaster(id) {


    let creteria = { id: id };
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ONE_DEVICE_MASTER}`,
        criteria: creteria,
        logText: 'Get Device One Master Url ',
        sccessType: INVENTORY_GET_ONE_DEVICE_MASTER_SUCCEED,
        failedType: INVENTORY_GET_ONE_DEVICE_MASTER_FAILED,
        errorMessage: 'Failed to get One Device Master.'
    };

    return (axiosGet(paramObj));
}

export function clearDeviceMasterList() {
    return (
        dispatch => {
            dispatch({
                type: INVENTORY_GET_DEVICE_MASTER_SUCCEED,
                payload: [],
            })
        }
    )
}
export function clearOneDeviceMaster() {
    return (
        dispatch => {
            dispatch({
                type: WEB_SERVICE_GET_ONE_DEVICE_MASTER,
                payload: {},
            })
        }
    )
}

const SUPPLIER_MOCK = [
    {
        id: 1,
        type: '',
        category: '',
        name: 'Sigfox',
        email: 'xinqing.liao@itsp-inc.com',
        contactNumber: '1234567',
        contactPerson: 'Mete',
        address1: '',
        address2: '',
        province: '',
        contry: '',
        postalCode: '',
        createdDate: '2019-07-05',
        createdBy: 'admin',
        updateDate: '2019-07-05',
        updateBy: 'admin',
        paymentInfo: '',
        paymentCurrency: 'CAD',
        itemList: [
            {
                sku: '001',
                name: 'Alpse sensor',
                type: '',
                network: 'sigfox',
                description: '',
                vender: 'Alpse',
                modal: '',
                price: '35',
                currency: 'CAD',
                startDate: '',
                endDate: '',
                promotion: '',
            }
        ]
    },
    {
        id: 2,
        type: '',
        category: '',
        name: 'Airbox',
        email: 'xinqing.liao@itsp-inc.com',
        contactNumber: '1234567',
        contactPerson: 'Liang',
        address1: '',
        address2: '',
        province: '',
        contry: 'CHINA',
        postalCode: '',
        createdDate: '2020-05-05',
        createdBy: 'admin',
        updateDate: '2019-07-05',
        updateBy: 'admin',
        paymentInfo: '',
        paymentCurrency: 'CAD',
        itemList: [
            {
                sku: '002',
                name: 'airbox_wifi',
                type: '',
                network: 'wifi',
                description: '',
                vender: 'Airbox',
                modal: 'airbox_wifi_v2',
                price: '20',
                currency: 'CAD',
                startDate: '',
                endDate: '',
                promotion: '',
            }
        ]
    },
];

export function getSupllierList() {
    console.log('getSupllierList call')
    return (
        dispatch => {
            dispatch({
                type: WEB_SERVICE_INVENTORY_SUPLLIER_SUCCESS,
                payload: SUPPLIER_MOCK,
            })
        }
    )
}

export function getSupllierItemList(sid) {
    // console.log('getSuplierItemList call id', sid)
    let sObj = SUPPLIER_MOCK.find((item, index) => { return (item.id == sid) });
    // console.log('getSuplierItemList call obj', sObj)
    return (
        dispatch => {
            dispatch({
                type: WEB_SERVICE_INVENTORY_SUPLLIER_ITEM_SUCCESS,
                payload: sObj ? sObj.itemList : [],
            })
        }
    )
}

export function sendOrder(val) {
    const { to, from, subject, message } = val;

    const data = {
        "to": to,
        "content": message,
        "subject": subject,
        "msgfrom": from
    }

    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_SEND_EMAIL}`,
        //dataObject: templateObj,
        criteria: data,
        logText: 'Send email Url ',
        errorMessage: 'Failed to sent a email.',
        successMessage: 'Successfully sent a email.',
        // successCallback: () => { return(getNotificationTemplate())}, 
        // errorCallback: (msg) => {toast.error(msg)}
        responseKeyGetter: (response) => (response.data.status),
        successContent: "Sucess",
    };

    return (axiosPost(paramObj));
}





export function getFullInventoryList(filterObj) {
    console.log("filterObj", filterObj)
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_RESELLER_FULL_INVENTORY_AND_ITEM}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    dispatch({
                        type: NEW_RESELLER_GET_DEVICE_INVENTORY_SUCCEED,
                        item: response.data.deviceTypes,
                        active: response.data.activeSims,
                        inactive: response.data.inActiveSims
                    });
                    toast.success(response.data.message)

                }
                else {
                    dispatch({
                        type: NEW_RESELLER_GET_DEVICE_INVENTORY_FAILED,
                        message: response.data.message
                    });
                    toast.error(response.data.message)
                }

                return response;
            })
        })


}
export function DeleteDeviceType(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_DELETE_DEVICE_TYPE}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    if (response.data.includes("SUCCESS")) {
                        dispatch({
                            type: RESELLER_SET_ITEM_SUCCEED,
                            payload: response.data
                        });
                        toast.success(response.data);
                    }
                    else {
                        toast.error(response.data);
                    }

                }
                else {
                    toast.error(response.data);
                    dispatch({
                        type: RESELLER_SET_ITEM_FAILED,
                        payload: response.data
                    });
                }
                dispatch(getFullSIMList({ "fullInventory": false, "companyId": filterObj.companyId, "resellerId": filterObj.resellerId }));
                return response;
            })
        })
}
export function SetDeviceType(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SET_DEVICE_TYPE}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    if (response.data.includes("SUCCESS")) {
                        dispatch({
                            type: RESELLER_SET_ITEM_SUCCEED,
                            payload: response.data
                        });
                        toast.success(response.data);
                    }
                    else {
                        toast.error(response.data);
                    }

                }
                else {
                    toast.error(response.data);
                    dispatch({
                        type: RESELLER_SET_ITEM_FAILED,
                        payload: response.data
                    });
                }
                dispatch(getFullSIMList({ "fullInventory": false, "companyId": filterObj.companyId, "resellerId": filterObj.resellerId }));
                return response;
            })
        })
}

export function SetSimType(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SET_SIM_TYPE}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    if (response.data.includes("SUCCESS")) {
                        dispatch({
                            type: RESELLER_SET_ITEM_SUCCEED,
                            payload: response.data
                        });
                        toast.success(response.data);
                    }
                    else {
                        toast.error(response.data);
                    }

                }
                else {
                    toast.error(response.data);
                    dispatch({
                        type: RESELLER_SET_ITEM_FAILED,
                        payload: response.data
                    });
                }
                dispatch(getFullSIMList({ "fullInventory": true }));
                return response;
            })
        })
}

export function uploadInventory(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_UPLOAD_INVENTORY}`,
        criteria: obj,
        logText: 'upload inventory ',
        sccessType: ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_SUCCEED,
        failedType: ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_FAILED,
        errorMessage: 'upload inventory.'
    };
    return (axiosPost(paramObj));
}
export function uploadFileSims(filterObj, level) {

    let message = '';
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_UPLOAD_DEVICE}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });

    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    if (response.data.includes("SUCCESS")) {
                        dispatch({
                            type: ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_SUCCEED,
                            payload: response.data,
                        });
                        toast.success(response.data);
                    }
                    else {
                        toast.error(response.data);
                    }
                    if (level === "company") {
                        dispatch(getFullInventoryList({ "fullInventory": true }));
                    }

                } else {
                    message = 'Faild to upload file ';
                    dispatch({
                        type: ACTION_POST_INVENTORY_SIM_UPLOAD_ACTIONS_FAILED,
                        message: message,
                    });
                    toast.error(message);
                }

            }).catch((error) => {
                message = 'Errors occurred when upload file ';
                dispatch({
                    type: ACTION_POST_INVENTORY_UPLOAD_ACTIONS_FAILED,
                    message: error.message,
                });
                console.error('upload action error(s) : ' + error.message);
                toast.error(message);
            });
        }
    );
}


export function getActivityReport(filterObj) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_ACTIVITY_REPORT}`,
        criteria: filterObj,
        logText: 'Get activity report Url ',
        sccessType: COMPANY_GET_ACTIVITY_REPORT_SUCCESS,
        failedType: COMPANY_GET_ACTIVITY_REPORT_FAILED,
        errorMessage: 'Failed to get activity report list.'
    };
    return (axiosGet(paramObj));
}
export function updateCarrierInfo(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_UPDATE_CARRIER_PLANS}`,
        criteria: obj,
        logText: 'update carrier Url ',
        sccessType: CARRIER_UPDATE_INFO_SUCCESS,
        failedType: CARRIER_UPDATE_INFO_FAILED,
        errorMessage: 'update to get carrier list.'
    };
    return (axiosPost(paramObj));
}
export function getCarrierPlans(filterObj) {
    let creteria = filterObj;
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_CARRIER_PLANS}`,
        criteria: creteria,
        logText: 'Get carrier Url ',
        sccessType: CARRIER_GET_PLANS_SUCCESS,
        failedType: CARRIER_GET_PLANS_FAILED,
        errorMessage: 'Failed to get carrier list.'
    };

    return (axiosGet(paramObj));
}

export function ClearCarrierPlans() {
    return (
        dispatch => {
            dispatch({
                type: USER_CLEAR_CARRIER_PLAN_LIST_SUCCESS,
                payload: []
            })
        }
    );
}

export function setSimName(filterObj) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SET_SIM_NAME}`,
        criteria: filterObj,
        logText: 'Set SIM name Url ',
        sccessType: SIM_SET_NAME_SUCCESS,
        failedType: SIM_SET_NAME_FAILED,
        errorMessage: 'Failed to set SIM name.'
    };

    return (axiosPost(paramObj));
}
export function ClearClientInventoryInformation() {
    return ((dispatch) =>
        dispatch(
            {
                type: CLEAR_CLIENT_DATA,
            }
        ))
}

export function getDeviceList(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_DEVICE_LIST}`,
        criteria: obj,
        logText: 'get device list',
        sccessType: GET_DEVICE_LIST_SUCCESS,
        failedType: GET_DEVICE_LIST_FAILED,
        errorMessage: 'failed to get device list',
    }
    return (axiosPost(paramObj))
}

export function ChangeDeviceStatus(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_CHANGE_DEVICE_STATUS}`,
        criteria: obj,
        logText: 'change device status',
        sccessType: CHANGE_DEVICE_STATUS_SUCCESS,
        failedType: CHANGE_DEVICE_STATUS_FAILED,
        errorMessage: 'failed to change device status',
    }
    return (axiosPost(paramObj))
}

export function registerDevice(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_REGISTER_DEVICE}`,
        criteria: obj,
        logText: 'register device',
        sccessType: REGISTER_DEVICE_SUCCESS,
        failedType: REGISTER_DEVICE_FAILED,
        errorMessage: 'failed to register device',
    }
    return (axiosPost(paramObj))
}