/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, Col, Input, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { getCurrentUser, hasRole } from "../../../factories/auth";
import ConfirmDeleteDialog from "../../../shared/components/Dialog/ConfirmationDialog";

class ClientEditAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      name: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      code: "",
      title: "",
      statesList: [],
      isNew: false,
    };
    this.userObj = getCurrentUser();
  }
  async componentDidMount() {
    if (this.props.address) {
      this.setState({
        id: this.props.address.id,
        name: this.props.address.name,
        addressLine1: this.props.address.addressLine1,
        addressLine2: this.props.address.addressLine2,
        city: this.props.address.city,
        state: this.props.address.state,
        country: this.props.address.country,
        code: this.props.address.zipCode,
        title: `Address ${this.props.index + 1}`,
        isNew: false,
      });
    } else {
      this.setState({
        id: 0,
        name: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: this.props.provinceMap.get(this.props.countryList[0].value),
        country: this.props.countryList[0].value,
        code: "",
        title: "Add New Address",
        isNew: true,
      });
    }

    let cval = this.props.address ? this.props.address.country : this.props.countryList[0].value;

    if (cval) {
      this.setState({
        statesList: this.props.provinceMap.get(cval)
      })
    }
  }

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  onCountryChange = async (event) => {
    let stateList = this.props.provinceMap.get(event.target.value);
    this.setState({
      country: event.target.value,
      state: stateList.abbrev,
      statesList: stateList
    })
  };


  onRemove = () => {
    var data = {
      companyId: this.props.selectedRow.companyId,
      resellerId: this.props.selectedRow.id,
      addressId: this.state.id,
      isNew: false,
      isDelete: true,
    };
    this.props.setAddress(data).then((res) => {
      if (res.data === "success") {
        this.props.getAddress({
          companyId: this.props.selectedRow.companyId,
          resellerId: this.props.selectedRow.id,
          customerId: null,
          userId: null,
        });
        toast.success("success");
      } else {
        this.props.getAddress({
          companyId: this.props.selectedRow.companyId,
          resellerId: this.props.selectedRow.id,
          customerId: null,
          userId: null,
        });
        toast.error("error");
      }
    });
  };
  onSubmit = () => {
    var data;
    if (this.state.isNew) {
      data = {
        companyId: this.props.selectedRow.companyId,
        resellerId: this.props.selectedRow.id,
        isNew: this.state.isNew,
        name: this.state.name,
        address1: this.state.addressLine1,
        address2: this.state.addressLine2,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        code: this.state.code,
      };
    } else {
      data = {
        addressId: this.state.id,
        isNew: this.state.isNew,
        name: this.state.name,
        address1: this.state.addressLine1,
        address2: this.state.addressLine2,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        code: this.state.code,
      };
    }
    this.props.setAddress(data).then((res) => {
      if (res.data === "success") {
        this.props.getAddress({
          companyId: this.props.selectedRow.companyId,
          resellerId: this.props.selectedRow.id,
          customerId: null,
          userId: null,
        });
        toast.success("success");
        if (this.state.isNew) {
          this.setState({
            id: 0,
            name: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: this.props.provinceMap.get(this.props.countryList[0].value),
            country: this.props.countryList[0].value,
            code: "",
            title: "Add New Address",
            isNew: true,
          });
        }
      } else {
        this.props.getAddress({
          companyId: this.props.selectedRow.companyId,
          resellerId: this.props.selectedRow.id,
          customerId: null,
          userId: null,
        });
        toast.error("error");
      }
    });
  };

  validateAddress = () => {
    return this.state.addressLine1 != "" && this.state.addressLine1;
  };

  validateCity = () => {
    return this.state.city != "" && this.state.city;
  };

  validateCode = () => {
    return this.state.code != "" && this.state.code;
  };

  validateForm = () => {
    return this.validateAddress() && this.validateCity() && this.validateCode();
  };
  render() {
    let labelStyle = {
      textAlign: "right",
      verticalAlign: "middle",
      marginTop: "auto",
      marginBottom: "auto",
    };
    return (
      <div>
        <hr style={{ borderTop: "3px dotted  #8c8b8b" }} />
        <h3>{this.state.title}</h3>
        <br />
        <Row>
          <Col md={2} style={labelStyle}>
            <span>Name <span style={{ color: 'red' }}>*</span></span>
          </Col>
          <Col md={8}>
            <Input
              name='name'
              placeholder='ATTN'
              onChange={this.onInputChange}
              className="form-control"
              value={this.state.name}

            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={2} style={labelStyle}>
            <span>
              Address Line 1 <span style={{ color: "red" }}>*</span>
            </span>
          </Col>
          <Col md={8}>
            <Input
              name="addressLine1"
              placeholder="Address Line 1"
              onChange={this.onInputChange}
              className="form-control"
              value={this.state.addressLine1}
              invalid={!this.validateAddress()}
            />
          </Col>
        </Row>
        <br />

        <Row>
          <Col md={2} style={labelStyle}>
            <span>Address Line 2</span>
          </Col>
          <Col md={8}>
            <Input
              name="addressLine2"
              placeholder="Address Line 2"
              onChange={this.onInputChange}
              className="form-control"
              value={this.state.addressLine2}
            />
          </Col>
        </Row>
        <br />

        <Row>
          <Col md={2} style={labelStyle}>
            <span>
              City <span style={{ color: "red" }}>*</span>
            </span>
          </Col>
          <Col md={3}>
            <Input
              name="city"
              placeholder="City"
              onChange={this.onInputChange}
              className="form-control"
              value={this.state.city}
              invalid={!this.validateCity()}
            />
          </Col>
        </Row>
        <br />

        <Row>
          <Col md={2} style={labelStyle}>
            <span>
              Zip Code <span style={{ color: "red" }}>*</span>
            </span>
          </Col>
          <Col md={3}>
            <Input
              name="code"
              placeholder="Zip Code"
              onChange={this.onInputChange}
              className="form-control"
              value={this.state.code}
              invalid={!this.validateCode()}
            />
          </Col>
        </Row>
        <br />

        <Row>
          <Col md={2} style={labelStyle}>
            <span>
              {this.userObj.companyCode && this.userObj.companyCode !== "TEISA"
                ? "State"
                : "State/City"}{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
          </Col>
          <Col md={3}>
            <Input
              type="select"
              name="state"
              placeholder="State"
              onChange={this.onInputChange}
              className="form-control"
              value={this.state.state}
            >
              {this.userObj.companyCode &&
                this.state.statesList &&
                this.state.statesList.map((option, index) => (
                  <option key={index} value={option.abbrev}>
                    {option.name}
                  </option>
                ))}
            </Input>
          </Col>
          <Col md={2} style={labelStyle}>
            <span>
              Country <span style={{ color: "red" }}>*</span>
            </span>
          </Col>
          <Col md={3}>
            <Input
              type="select"
              name="country"
              placeholder="Country"
              onChange={this.onCountryChange}
              className="form-control"
              value={this.state.country}
            >
              {/* <option value={'CA'}>Canada</option>
                                    <option value={'USA'}>United States</option>} */}
              {this.userObj.companyCode &&
                this.props.countryList &&
                this.props.countryList.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}

              {/*{this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                            <option>Paraguay</option>
                            }*/}
            </Input>
          </Col>
        </Row>
        <br />
        <br />
        <div style={{ textAlign: "right" }}>
          {this.state.isNew ||
            !hasRole(this.props.userObj, [
              "SUPERADMIN",
              "ADMIN",
              "CLIENTADMIN",
            ]) ? null : (
            <Button outline color="danger" type="button">
              <ConfirmDeleteDialog
                label={"Remove"}
                onConfirm={(e) => {
                  this.onRemove();
                }}
                msg={`Do you want to delete the ${this.state.title}? `}
              />
            </Button>
          )}

          {hasRole(this.props.userObj, [
            "SUPERADMIN",
            "ADMIN",
            "CLIENTADMIN",
          ]) ? (
            <Button
              color="custom"
              type="button"
              onClick={this.onSubmit}
              disabled={!this.validateForm()}
            >
              Save
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.user;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientEditAddress);
