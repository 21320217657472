/* eslint-disable */
import React, { Component, PureComponent, Fragment } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Bar} from 'react-chartjs-2';
import Select from "react-select";
import {LIGHT_GREY_COLOR} from '../../../constants/dataConstants';
import Spinner from 'react-bootstrap/Spinner';

class SingleAlarmBarChartWidget extends PureComponent {
    constructor(props){
        super(props);
        this.state={
            selectedChart: "Please select a time range.",
            showMonthly: false,
            showQuarter: false,
            showSixMonths: false,
        }
    }
    
    renderBarChartList = () => {
        const options = [
            { value:"Calendar Month", label:"Calendar Month"},
            { value:"Quarter Month", label:"Quarter Month"},
            { value:"180 Days", label:"180 Days"},
        ];

        
        return(
            <Select
            isClearable={false}
            options={options}
            onChange={this.handleValueSelect}
            placeholder={this.state.selectedChart}
            autoFocus={true}
            />
        )

    }
    handleValueSelect = (options) => {
        if(options.label) {
            this.setState({
                selectedChart: options.label,
            })
        } else {
            this.setState({
                selectedChart: "Please select a time range."
            })
        }

    }

    renderBarChart24H = () => {
        const chartlabel = ['A', 'B', 'C', 'D', 'E'];
        // const chartdata1 = [20,30,50,40,10];
        // const chartdata2 = [10,40,30,20,30];
        // const chartdata1 = [
        //     {x:'2016-12-25', y:20},
        //     {x:'2016-12-26', y:30},
        //     {x:'2016-12-27', y:50},
        //     {x:'2016-12-28', y:40},
        //     {x:'2016-12-29', y:10}
        // ];
        // const chartdata2 = [
        //     {x:'2016-12-25', y:10},
        //     {x:'2016-12-26', y:40},
        //     {x:'2016-12-27', y:30},
        //     {x:'2016-12-28', y:20},
        //     {x:'2016-12-29', y:50}
        // ]
        // const chartdata1 = [
        //     {x:'2021-06-14', y:20},
        //     {x:'2021-06-15', y:30},
        //     {x:'2021-06-16', y:50},
        //     {x:'2021-06-17', y:40},
        //     {x:'2021-06-18', y:10}
        // ];
        // const chartdata2 = [
        //     {x:'2021-06-14', y:10},
        //     {x:'2021-06-15', y:40},
        //     {x:'2021-06-16', y:30},
        //     {x:'2021-06-17', y:20},
        //     {x:'2021-06-18', y:50}
        // ];
         
        let chartdatared = [];
        let chartdataorange = [];
        let chartdatayellow = [];
        
        // if(this.props.monthlyCountData) {
        // }
        if (this.props.countData){
            this.props.countData.forEach((item, index) => {
                let red = {x: item.date, y: item.red};
                let orange = {x: item.date, y: item.orange};
                let yellow = {x: item.date, y: item.yellow};
                chartdatared.push(red);
                chartdataorange.push(orange);
                chartdatayellow.push(yellow);
            });

        }

        // if(this.props.countData1Month){
        //     console.log("===1month", this.props.countData1Month);
        // }
         
        const data = {
            // labels: chartlabel,
            datasets: [
                {
                    label: 'Occupancy (per date)',
                    // backgroundColor: 'rgba(255,99,132,0.2)',
                    backgroundColor: 'rgba(255,0,0,0.7)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdatared,
                },
                {
                    label: 'Alarm Orenge',
                    // backgroundColor: 'rgba(99,255,100,0.2)',
                    backgroundColor: 'rgba(255,155,0,0.5)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdataorange,
                },
                {
                    label: 'Alarm Yellow',
                    // backgroundColor: 'rgba(99,255,255,0.2)',
                    backgroundColor: 'rgba(255,255,0,0.5)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartdatayellow
                }
            ]
        };
         
        let unit = this.props.units ==='day' ? 'day': 'hour';
        let rationOption = this.props.isSmall ? {responsive: true, maintainAspectRatio: false,} : { aspectRatio: 2, responsive: true, maintainAspectRatio: true,} 
        const options = {
            // ...rationOption,
            aspectRatio: 2,
            responsive: true,
            maintainAspectRatio: true,
            showScale: false,
            scales: {
                // bounds: 'data',
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    offset: false,
                    time: {
                        // unit: 'day'
                        // unit: unit
                    },
                    ticks:{
                        // min: '2016-12-24',
                        // max: '2016-12-30',
                        source: 'data',
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            //  
                            if (index === 0) {
                                return 'Today';
                            } 
                            if (index === values.length - 1) {
                                //  
                                return '24 hrs';
                            }
                            return null;
                        }
                    },
                    gridLines: {
                        // display: false,
                        display: true,
                        // color: chartColor,
                        color: 'grey',
                        // color: "rgba(0, 0, 0, 0)",
                        drawBorder: true,
                        drawOnChartArea: false,
                        drawTicks: false,
                    }
                }],
                yAxes: [{
                    // stacked: false,
                    // type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        source: 'data',
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            if(Math.floor(value) === value) return value;
                        },
                    },
                    scaleLabel: {
                        display: true,
                        // labelString: '1k = 1000'
                    },
                    gridLines: {
                        display: true,
                        // color: chartColor,
                        color: 'grey',
                        // color: "rgba(0, 0, 0, 0)",
                        drawBorder: true,
                        drawOnChartArea: false,
                        drawTicks: false,

                    }
                }]
            },
            legend: {
                display: this.props.isSmall? false : true,
                // position: 'right',
                position: 'bottom',
                labels: {
                    boxWidth: 15,
                    fontSize: 12,
                    fontColor: 'rgb(255, 99, 132)'
                }
            }
        }

        
        return (
            <Fragment>
                <Bar
                    // key={this.props.isSmall}
                    data={data}
                    // width={60}
                    width={'200px'}
                    // width={null}
                    height={null}
                    // height={height.toString()} 
                    // height={300}
                    // height={'45vh'}
                    options={options}
                />
            </Fragment>
        );
    };
    
    renderBarChartMonthly = () => {
        let chartDataRed = [];
        if(this.props.monthlyCountData) {
            // console.log("===this.props.monthlyAlarmCounts", this.props.monthlyCountData);
            this.props.monthlyCountData.forEach((item, index) => {
                let red = {x: item.date, y: item.red};
                chartDataRed.push(red);
            });
        }

        const data = {
            datasets: [
                {
                    label: 'Occupancy (per date)',
                    backgroundColor: 'rgba(255, 0, 0, 0.7)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255, 99, 132, 0.4)',
                    hoverBorderColor: 'rgba(255, 99, 132, 1)',
                    data: chartDataRed,
                }
            ]
        };

        let unit = this.props.monnthlyUnits;
        let rationOption = this.props.isSmall ? {responsive: true, maintainAspectRatio: false} : {aspectRatio: 2, responsive: true, maintainAspectRatio: true};
        const options = {
            title: {
                    display: true,
                    text: 'Number Of Occupants',
                    position: 'left',
                    padding: 2,
                },
            aspectRatio: 2,
            responsive: true,
            maintainAspectRatio: true,
            showScale: false,
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    offset: true,
                    time: {
                        unit: "day"
                    },
                    ticks: {
                        autoSkip: true,
                        autoSkipPadding: 10,
                        source: 'data',
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            return value;
                        }
                    },
                    gridLines: {
                        display: true,
                        color: 'rgb(230, 232, 234)',
                        // tickColor:'grey',
                        drawBorder: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                    },
                }],

                yAxes: [{
                    dispay: true,
                    padding: {top: 20, left: 0, right: 0, bottom: 0},
                    position: 'left',
                    ticks: {
                        min: 0,
                        autoSkip: true,
                        source: 'data',
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            if(Math.floor(value) === value) return value;
                        },
                    },
                    scaleLabel: {
                        display: true,
                        text: 'Date'
                    },
                    gridLines: {
                        display: true,
                        color: 'rgb(230, 232, 234)',
                        drawBorder: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        tickBorderDash:5 ,
                        tickBorderDashOffset:5,
                    }
                }]
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 15,
                    fontSize: 12,
                    fontColor: 'rgb(255, 99, 132)'
                }
            }
        }

        return (
            <Fragment>
                <Bar
                    data = {data}
                    width = {'180px'}
                    height = {'55%'}
                    options = {options}
                />
            </Fragment>
        );
    };

    renderBarChartQuarterMonth = () => {
        let chartDataRed = [];
        if(this.props.quarterMonthCountData) {
            this.props.quarterMonthCountData.forEach((item, index) => {
                let red = {x: item.date, y: item.red};
                chartDataRed.push(red);
            });
        }

        const data = {
            datasets: [
                {
                    label: 'Occupancy (per date)',
                    // backgroundColor: 'rgba(255,99,132,0.2)',
                    backgroundColor: 'rgba(255,0,0,0.7)',
                    borderColor: 'rgba(255,99,132,1)',
                    // borderWidth: 0.2,
                    barPercentage: 0.9,
                    categoryPercentage: 0.6,
                    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    hoverBorderColor: 'rgba(255,99,132,1)',
                    data: chartDataRed,
                },
            ]
        };

        const options = {
            title: {
                display: true,
                text: 'Number Of Occupants',
                position: 'left',
                padding: 2,
            },
            aspectRatio: 2,
            responsive: true,
            maintainAspectRatio: true,
            showScale: false,
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    offset: true,
                    time: {
                        unit: "day"
                    },
                    ticks: {
                        autoSkip: true,
                        autoSkipPadding: 10,
                        source: 'data',
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            return value;
                        }
                    },
                    gridLines: {
                        display: true,
                        color: 'rgb(230, 232, 234)',
                        drawBorder: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        offset: true,
                    }
                }],
                yAxes: [{
                    display: true,
                    position: 'left',
                    ticks: {
                        min: 0,
                        autoSkip: true,
                        source: 'data',
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            if(Math.floor(value) === value) return value;
                        },
                    },
                    scaleLabel: {
                        display: true,
                    },
                    gridLines: {
                        display: true,
                        color: 'rgb(230, 232, 234)',
                        drawBorder: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                    }
                }]
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    boxWidth: 15,
                    fontSize: 12,
                    fontColor: 'rgb(255, 99, 132)'
                }
            }
        }

        return (
            <Fragment>
                <Bar
                    data = {data}
                    width = {'180px'}
                    height = {'55%'}
                    options = {options}
                />
            </Fragment>
        )
    };

    renderBarChartSixMonths = () => {
            let chartDataRed = [];
            if(this.props.sixMonthsCountData) {
                this.props.sixMonthsCountData.forEach((item, index) => {
                    let red = {x: item.date, y: item.red};
                    chartDataRed.push(red);
                });

            const data = {
                datasets: [
                    {
                        label: 'Occupancy (per date)',
                        // backgroundColor: 'rgba(255,99,132,0.2)',
                        backgroundColor: 'rgba(255,0,0,0.7)',
                        borderColor: 'rgba(255,99,132,1)',
                        // borderWidth: 0.2,
                        barPercentage: 0.9,
                        categoryPercentage: 0.6,
                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                        hoverBorderColor: 'rgba(255,99,132,1)',
                        data: chartDataRed,
                    },
                ]
            };

            const options = {
                title: {
                    display: true,
                    text: 'Number Of Occupants',
                    position: 'left',
                    padding: 2,
                },
                aspectRatio: 2,
                responsive: true,
                maintainAspectRatio: true,
                showScale: false,
                offset: true,
                scales: {
                    xAxes: [{
                        type: 'time',
                        distribution: 'series',
                        offset: true,
                        time: {
                            unit: 'month',
                        },
                        ticks: {
                            source: 'data',
                            maxRotation: 0,
                            padding: 10,
                            callback: function(value, index, values) {
                                if(index === values.length - 1) {
                                    return value;
                                }
                                if(index % 30 == 0 && index !== values.length - 1) {
                                    return value;
                                }
                                // return value;
                        
                            },
                        },
                        gridLines: {
                            display: true,
                            color: 'rgb(230, 232, 234)',
                            drawBorder: true,
                            drawOnChatArea: false,
                            drawTicks: false,
                            offset: true,
                        }
                    }],
                    yAxes: [{
                        display: true,
                        position: 'left',
                        offset: false,
                        ticks: {
                            min: 0,
                            autoSkip: true,
                            source: 'data',
                            maxRotation: 0,
                            padding: 10,
                            showLabelBackdrop: false,
                            callback: function(value, index, values) {
                                if(Math.floor(value) === value) return value;
                            },
                        },
                        scaleLabel: {
                            display: true,
                        },
                        gridLines: {
                            display: true,
                            color: 'rgb(230, 232, 234)',
                            drawBorder: true,
                            drawOnChartArea: true,
                            drawTicks: false,
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 15,
                        fontSize: 12,
                        fontColo: 'rgb(255, 99, 132)'
                    }
                }
            }
            return (
                <Fragment>
                    <Bar
                        data = {data}
                        width = {'180px'}
                        height = {'55%'}
                        options = {options}
                    />
                </Fragment>
            )
        }

    };

    renderSpinner = () => {
        let loading = this.props.loading ? this.props.loading : false;
        // historyLoading = {this.props.assetHistoryLoading}
        // countLoading = {this.props.assetCountLoading}
        //  
        // let loading = false;
        // if (isLightning) {
        //     loading = this.props.historyLoading || this.props.countLoading || this.props.hourlyCountLoading;
        //      
        // } else{
        //     loading = this.props.historyLoading;
        //      
        // }

        // let loading = this.props.historyLoading || this.props.countLoading;
        return (
            <Fragment>
            { loading && 
                <div style={{
                                position: 'Absolute ', 
                                height: '100%', 
                                width: '100%', 
                                top: '0px', 
                                left: '0px', 
                                display: 'flex', 
                                textAlign: 'center', 
                                alignItems: 'center',
                                backgroundColor: LIGHT_GREY_COLOR,
                                opacity: '0.6',
                            }}
                >
                    <Spinner
                        className="spinner"
                        as="span"
                        animation="border"
                        variant="success"
                        // size="sm"
                        // role="status"
                        // aria-hidden="true"
                        style={{display: 'inline-block', margin: 'auto' }}
                    /> 
                </div>
            } 
            </Fragment>
        );
    }

    render(){
        return(
            <Card style={{ marginLeft: '15px'}}>
                {/* <Row>
                <CardBody style={{padding: '20px 30px', border: '1px solid black', backgroundColor: 'white', marginBottom: '10%'}}>
                <Row style= {{margin: '0px 0px 10px 0px'}}>
                    <h4>
                        Daily Occupancy
                    </h4>
                </Row>
                <Row>
                    {this.renderBarChart24H()}
                    {this.renderSpinner()}
                </Row>
                </CardBody>
                </Row> */}
                <Col style={{ width: '35%', marginLeft: '-15px'}}>
                {this.renderBarChartList()}
                </Col>
                {this.state.selectedChart && this.state.selectedChart == "Calendar Month" ?
                <Row>
                <CardBody style={{padding: '20px 30px', border: '1px solid black', backgroundColor: 'white'}}>
                <Row style= {{margin: '0px 0px 10px 0px'}}>
                    <h4>
                        Calendar Month Occupancy
                    </h4>
                </Row>
                <Row style={{ height: '60%'}}>
                    {this.renderBarChartMonthly()}
                    {this.renderSpinner()}
                </Row>
                </CardBody>
                </Row>

                 : null}
                
                {this.state.selectedChart && this.state.selectedChart == "Quarter Month" ? 
                <Row>
                <CardBody style={{padding: '20px 30px', border: '1px solid black', backgroundColor: 'white'}}>
                <Row style= {{margin: '0px 0px 10px 0px'}}>
                    <h4>
                        Quarter Month Occupancy
                    </h4>
                </Row>
                <Row style={{ height: '60%'}}>
                    {this.renderBarChartQuarterMonth()}
                    {this.renderSpinner()}
                </Row>
                </CardBody>
                </Row>
                : 
                null}

                {this.state.selectedChart && this.state.selectedChart == "180 Days" ? 
                <Row>
                <CardBody style={{padding: '20px 30px', border: '1px solid black', backgroundColor: 'white',}}>
                <Row style= {{margin: '0px 0px 10px 0px'}}>
                    <h4>
                        180 Days Occupancy
                    </h4>
                </Row>
                <Row style={{ height: '60%'}}>
                    {this.renderBarChartSixMonths()}
                    {this.renderSpinner()}
                </Row>
                </CardBody>
                </Row>

                : null}
            </Card>
        );
    }
}

export default SingleAlarmBarChartWidget;