/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import ClientCarrierPanel from './ClientCarrierPanel';
import { toast } from 'react-toastify';

class ClientCarrierBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resellerId:0
        }
    }
    componentDidMount(){
        if(this.props.selectedRow != null){
            this.setState({resellerId:this.props.selectedRow.id})
        }
    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    onSave = (payload) => {
        var obj = {
            companyId:this.props.selectedRow.companyId,
            resellerId:this.props.selectedRow.id,
            carrierId: payload.carrierId,
            name:payload.name,
            password:payload.password,
            identifier: payload.identifier
        }
        this.props.updateCarrierInfo(obj).then(res=> {
            if(res.data === "success"){
                toast.success("Info Updated");
                this.props.getAllResellers({ resellerCode: this.props.selectedRow.resellerCode })
            } else if(res.data === "error"){
                toast.error("Update Failed")
            }
        });
    }
    render() {
        return (
            <Card>
                <CardBody className="mainCardBody">
                    {this.props.carrierList.map((carrier) =>
                        <ClientCarrierPanel
                            name={carrier.carrierName}
                            id={carrier.carrierId}
                            onSave = {this.onSave}
                        />
                    )
                    }
                </CardBody>
            </Card>
        )
    }
}
export default ClientCarrierBoard;