/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardTitle, CardBody } from 'reactstrap';
import Select from 'react-select';
import { EditableText } from '../../Asset/components/ClassDisplayDetail';
import {
    // loadDevice, 
    loadAssetClass,
    getAssetClassAttribute,
    clearAssetClassAttribute
} from '../../../redux/actions/mapActions';
import {
    getAssetList,
    addDashBoardLayout,
} from '../../../redux/actions/dashboardActions';
import {
    getAttributes,
    clearAttributes,
} from '../../../redux/actions/attributeMgmtAction';
import { toast } from "react-toastify";
import { UserContext } from '../../../constants/myContexts';
import { isStringEmpty, groupBy } from '../../../factories/utils';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { multiSelectStyles } from '../../Asset/components/ClassDisplayDetail';

class AssetDataAddElement extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            currentAssetClassId: null,
            currentAssetId: null,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
            devices: [],
            customStyles: {}
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }

    componentDidMount() {
        const { userObj, updateUserObj } = this.context;

        this.props.loadAssetClass();
    }
    // --------------------------------------------------------------
    handleInputChange = (newVal) => {
        this.setState((prevState) => {
            return ({
                currentName: newVal,
                // layout: prevState.layout,
            });
        }, () => {


        });
    }
    renderTitle = () => {
        return (

            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{ display: 'inline' }} ><h4><b> Name</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                        type='text'
                        //  defaultValue={this.props.layout ? this.props.layout.name : ''} 
                        defaultValue={this.state.currentName ? this.state.currentName : ''}
                        exitOnEnterKeyPress={false}
                        onSave={newVal => { this.handleInputChange(newVal) }}
                    />
                </Col>
            </Row>
        )
    }
    // --------------------------------------------------------------
    onAssetClassChange = (selectedOption) => {
        const assetClassId = selectedOption ? selectedOption.value : null;
        this.setState({
            currentAssetClassId: assetClassId,
            currentAssetId: null,
            currentAttrList: [],
        });
        this.props.getAssetList({ assetclassid: assetClassId });
        this.props.getAssetClassAttribute({ assetclassid: assetClassId });
    }
    renderAssetClass = () => {
        const assetClassOptions = [
            { value: null, label: 'None' },
            ...(this.props.assetClasses.map(assetclass => ({
                value: assetclass.assetclassid,
                label: assetclass.assetclassname
            })))
        ];
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="assetclass" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        id="assetclass"
                        name="assetclass"
                        options={assetClassOptions}
                        value={assetClassOptions.find(option => option.value === this.state.currentAssetClassId)}
                        onChange={this.onAssetClassChange}
                    />
                </Col>
            </Row>
        )
    }
    // --------------------------------------------------------------
    onAssetChange = (selectedOption) => {
        const assetId = selectedOption ? selectedOption.value : null;
        this.setState({
            currentAssetId: assetId,
        });
    }
    renderAsset = () => {
        const assetOptions = [
            { value: null, label: 'None' },
            ...(this.props.assets.map(asset => ({
                value: asset.assetid,
                label: asset.assetname
            })))
        ];
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="asset" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        id="asset"
                        name="asset"
                        options={assetOptions}
                        value={assetOptions.find(option => option.value === this.state.currentAssetId)}
                        onChange={this.onAssetChange}
                    />
                </Col>
            </Row>
        )
    }
    // --------------------------------------------------------------
    // onAttrChange = (e) => {
    //      
    //     let index = e.target.selectedIndex
    //     this.setState({
    //         currentAttrId: e.target.value,
    //         currentAttrName: e.target.options[index].text
    //     });
    // }
    onAttrChange = selectedOption => {
        if (!selectedOption) {
            // Handle case when selectedOption is null (no options selected)
            this.setState({
                currentAttrList: []
            });
        } else {
            let currentAttrList = selectedOption.map(item => ({
                attrid: item.value, // Access value instead of attributeid
                attributename: item.label // Access label instead of attributename
            }));
            this.setState({
                currentAttrList: currentAttrList
            });
        }
    }


    renderAttribute = () => {

        let attributeOptions = [];
        if (this.props.assetClassAttr && this.props.assetClassAttr.length > 0 && this.props.assetClassAttr[0].atrributes) {
            let attributeMap = groupBy(this.props.assetClassAttr[0].atrributes, attr => attr.devicetypename);
            for (let [key, value] of attributeMap) {
                let group = {
                    label: key,
                    options: value,
                }
                attributeOptions.push(group);
            }
        }
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="attribute" style={{ display: 'inline', width: "100%" }} ><h4><b>Attribute</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Input type="select" id="attribute" name="attribute" width="100%" value={this.state.currentAttrId} onChange={this.onAttrChange} >                                    
                        <option value={0}>NONE</option> 
                        {this.props.attributes && this.props.attributes.map((attr, i) => (<option key={attr.attributeid} value={attr.attributeid}>{attr.attributename}</option>))}
                    </Input> */}
                    <Select
                        styles={this.state.customStyles}
                        isMulti
                        isClearable
                        isSearchable
                        options={attributeOptions}
                        getOptionLabel={({ displayname }) => displayname}
                        getOptionValue={({ attributeid }) => attributeid}
                        onChange={this.onAttrChange}
                    />
                </Col>
            </Row>
        )
    }
    // --------------------------------------------------------------
    onCancleClick = (e) => {
        this.props.toggle();
    }

    validateInput() {
        // let isValidated = true;
        let hasUser = this.context != undefined && this.context != null && this.context.userObj != undefined && this.context.userObj != null;
        if (!hasUser) {

            toast.error("Can not get user infomation");
        }
        let isInput = !isStringEmpty(this.state.currentName)
        let isSelect = this.state.currentAssetId != null && this.state.currentAssetId != 0 && this.state.currentAssetClassId != null && this.state.currentAssetClassId != 0;
        let isTooLong = this.state.currentAttrList != undefined && this.state.currentAttrList != null && this.state.currentAttrList.length > 4;
        if (!isInput || !isSelect) {

            toast.error("Input is not valid");
        }
        if (isTooLong) {

            toast.error("Max attributes are 4");
        }


        return hasUser && isInput && isSelect && !isTooLong;
    }
    onOkClick = (e) => {
        //  
        if (!this.validateInput()) {
            //  
            return;
        }
        //  
        let position = { w: 3, h: 7, x: 8, y: 8 };
        let parameter = {
            assetclassid: this.state.currentAssetClassId,
            assetid: this.state.currentAssetId,
            attributes: this.state.currentAttrList ? this.state.currentAttrList : [],
        };
        let layout = {
            name: this.state.currentName,
            type: "AssetElement",
            width: 9,
            locked: 0,
            display: 1,
            position: JSON.stringify(position),
            parameter: JSON.stringify(parameter),
            username: this.context.userObj.userName,
            createdby: this.context.userObj.userName,
            companycode: this.context.userObj.companyCode,
            selected: 1,
            // createdtime: "2019-02-04T03:28:50.000+0000",
            createdtime: new Date().toUTCString(),
        }

        layout = { ...this.props.currentLayout, ...layout };
        this.props.addDashBoardLayout(layout);
        this.props.toggle();
        this.props.parentToggle();
    }

    // --------------------------------------------------------------
    render() {
        return (
            <Card>
                <CardBody>
                    {/* <CardTitle>Set Attribute Value</CardTitle> */}
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderTitle()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderAssetClass()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderAsset()}
                        </Col>
                    </Row>
                    {/* <Row style={{marginBottom: '10px'}}>
                        <Col md={12}>
                            {this.renderDevice()}
                        </Col>
                    </Row> */}
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderAttribute()}
                        </Col>
                    </Row>
                    <hr />
                    <div style={{ textAlign: 'right' }}>
                        <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                        <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                    </div>
                </CardBody>
            </Card>
        );
    }
}
function mapStateToProps(state) {
    // const types = state.mapboard.types;
    const assets = state.dashboard.assetList;
    const assetClasses = state.mapboard.assetClass;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const attributes = state.attributemgmt.attributes;
    const props = {
        // types,  
        assetClasses,
        assetClassAttr,
        assets,
        attributes,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // loadDevice, 
        loadAssetClass,
        getAssetClassAttribute,
        clearAssetClassAttribute,
        getAssetList,
        getAttributes,
        clearAttributes,
        addDashBoardLayout,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetDataAddElement);