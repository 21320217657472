/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import ConfirmDeleteDialog from '../../../../shared/components/Dialog/ConfirmationDialog';
import BseseWidgetEditDislog from './BaseWidgetEditDialog';
import { EditIcon, WindowCloseIcon } from 'mdi-react';
import { toast } from "react-toastify";

const BaseWidget = ({dashName, layout, pubsubData, renderEditForm, renderContent, editHandler, removeHandler}) => {

    const onDeleteWidget = () => {
        if (layout){
            const {layoutid, templateid} = layout
            removeHandler(layoutid, templateid);
        }else{
            toast.error('Could not get the layout ID! Please try again.');
        }
    }
    
    return(
        <Fragment>
        <Card >
            <CardHeader className={'dragBar'}> 
                {/* <div className="card__title"> */}
                    <h5 className="bold-text">
                        {/* {dashName}  */}
                        {layout && layout.name? layout.name : 'Widget'}
                        {layout && 0 == layout.locked &&
                        <>
                        {removeHandler && 
                            <ConfirmDeleteDialog 
                                icon = {(props)=>(<WindowCloseIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} {...props}/>)}
                                style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                onConfirm={(e)=>{onDeleteWidget()}}
                                msg={'Do you want to delete the selected widget ? '}
                            />
                        }
                        {/* <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={toggle}/>     */}
                        <BseseWidgetEditDislog
                            icon={props=><EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} {...props}/>}
                            renderForm={renderEditForm}
                            defaultLayout={layout}
                            onEditSubmit={editHandler}
                        />
                        </>
                        }
                    </h5>                      
                {/* </div> */}
            </CardHeader>
            <CardBody style={{padding:15}}>  
                {renderContent(layout, pubsubData)}
            </CardBody>  
        </Card>
      {/* <AttrParamEditModal            
        // assetList = {this.props.assetList}
        layout = {this.props.layout}
        parameter = {this.props.parameter}
        modal={this.state.modal}
        toggle={this.toggle}
        onEditSubmit={this.props.onEditSubmit}
      /> */}
      {/* <AssetDataEditElement            
        // assetList = {this.props.assetList}
        layout = {this.props.layout}
        parameter = {this.props.parameter}
        modal={this.state.modal}
        toggle={this.toggle}
        onEditSubmit={this.props.onEditSubmit}
      /> */}
      </Fragment>
    );
}

export default BaseWidget