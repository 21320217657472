/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Select from 'react-select';
// import 'react-select/dist/react-select.css';
import icon_delete from '../../../shared/img/icon/delete.png';
import {loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff} from '../../../redux/actions/jobAction';
import '../../../scss/component/table.scss';

class DeviceSetSection extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedAssetId : null,
            selectedAssetName: null,
            selectedAssetOption: null,
            selectedDeviceId : null,
            selectedDeviceOption: null,
            actions: [],
        }
    }
        // -----------------------------------
        componentDidMount(){
            this.props.loadAssetOptions();
            // this.props.loadAction({actionid:this.props.actionId});
        }
        componentWillReceiveProps(nextProps){
            // this.setState({
            //     actions: JSON.parse(JSON.stringify(nextProps.actions))
            // });
        }
        // static getDerivedStateFromProps(props, state) {}
        // -----------------------------------

    deleteButtonFormatter = (cell, row) => {
        let path = `/pages/device/${cell}`;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                {/* <img src={icon_edit} style={{width: 20, height: 20}} alt={"Edit"} onClick={(e)=>{this.handleRowClick(row);}}/>{' '} */}
                <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} onClick={(e)=>{this.handleRowDeleteClick(row);}} />
            </div>
        )
    }

    handleRowDeleteClick = (row) =>{
        const deviceid = row.deviceid;
        const assetid = row.assetid;
        // alert(deviceid);
        this.props.handleRowDeleteClick(assetid, deviceid);
    }
    
    renderActionDeviceTb() {
        const deviceIdLinkFormatter = (cell, row) => {
            // let path = `/pages/device/${cell}`;
            let path = ``;
            return(
                <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            )
          }
      
        const options = {
            // page: 1,  // which page you want to show as default
            // sizePerPageList: [ {
            //   text: '5', value: 5
            // }, {
            //   text: '10', value: 10
            // }, 
            // ], // you can change the dropdown list for size per page
            // sizePerPage: 5,  // which size per page you want to locate as default
            // pageStartIndex: 1, // where to start counting the pages
            // paginationSize: 5,  // the pagination bar size.
            // prePage: '<', // Previous page button text
            // nextPage: '>', // Next page button text
            // firstPage: '<<', // First page button text
            // lastPage: '>>', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
            // insertBtn: this.createCustomInsertButton,
            // clearSearch: true,
        }
         
         
        return(
            <Col md={12}>
            <Card>
            <CardBody>
              <BootstrapTable data={ this.props.actionList } tableHeaderClass = 'header_white'  options={ options }      bordered={ false }  ref='table' >
                  {/* <TableHeaderColumn dataField='actionid' isKey={ true } hidden >Device ID</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='deviceid' isKey={ true } dataAlign="center" >Device ID</TableHeaderColumn>
                  {/* <TableHeaderColumn dataField='devicename' dataSort  >Device Name</TableHeaderColumn>  */}
                  <TableHeaderColumn dataField='assetid' dataAlign="center"  >Asset ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='assetname' dataAlign="center"  dataSort  >Asset</TableHeaderColumn> 
                  {/* <TableHeaderColumn dataField='geofencename' dataSort  >Associated GeoFence</TableHeaderColumn> */}
                  {/* <TableHeaderColumn dataField='rulename'>Associated Rule</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='operation' dataAlign="center"  dataFormat={this.deleteButtonFormatter}></TableHeaderColumn>
              </BootstrapTable>
              </CardBody>
              </Card>
        </Col>
        );
    }

    handleAssetChange = (val) => {
         
        this.props.loadAssetDevice({assetid: val.value});
        this.setState({
            selectedAssetId : val.value,
            selectedAssetName: val.label,
            selectedDeviceId : null,
            selectedAssetOption: val,
            selectedDeviceOption: null,
        });
    }

    handleDeviceChange = (val) => {
         
        this.setState({
            selectedDeviceId : val.value,
            selectedDeviceOption: val,
        });
    }

    handleAddOnClick = () => {
        this.props.handleAddOnClick(this.state.selectedAssetId, this.state.selectedAssetName, this.state.selectedDeviceId);
    }

    render(){
        const options = [
            // ...
            { value: 'Stanford University', label: 'Stanford' },
            // ...
        ];
        return(
            <div>
                 <Card>
                <CardBody>
                <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>

                <Col md={3}>
                    <Label for="deviceType"   style={{ display: 'inline'}}>Asset</Label>
                    <Select            
                        value= {this.state.selectedAssetOption}           
                        options={this.props.assets}
                        onChange={this.handleAssetChange}
                    />
                </Col>

                <Col md={4}>
                    <Label for="messageType"  style={{ display: 'inline'}} >Devices</Label>
                    <Select
                        value= {this.state.selectedDeviceOption}  
                        options={this.props.assetdevices}
                        onChange={this.handleDeviceChange}
                    />
                </Col>

                <Col md={2} style={{ textAlign: 'left',   justifyContent: 'center',alignItems: 'center' }}>
                    <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                    <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} >
                        Reset
                    </Button>  {' '}
                    <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick = {this.handleAddOnClick} >
                        Add
                    </Button>
                </Col>
                </Row>
                <hr style={{width: '90%'}} />
                <Row>
                    {this.renderActionDeviceTb()}
                </Row>
                </CardBody>
            </Card>
            </div>
        );
    }
}

// export default DeviceSetSection;

function mapStateToProps(state) {
     
     
    
    const props = state.job;
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSetSection);