/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Collapse, Button, CardBody, Card, CardHeader } from 'reactstrap';
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
// import { CardHeader } from '@material-ui/core';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssetFilter from './AssetFilter';
import AssetTable from './AssetTable';
import MapContainer from './MapContainer';
import {loadAssetClass, getAssetClassAttribute, clearAssetClassAttribute, filterMapDataByAttribute, getGroupAssetData, clearGroupAssetData, filterGroupAssetDataByAttribute} from '../../../redux/actions/mapActions';
import {getThemeList} from '../../../redux/actions/attributeThemeAction';

const styles = (theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
      backgroundColor: 'rgba(0, 0, 0, .05)',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  }));

class AssetClassBoard extends Component {
    constructor(props){
        super(props);
        this.state={
            panela: true,
            panelb: true,
            isOpenTag1: true,
            isOpenTag2: true,
            mapProps: {
                showingInfoWindow: false,
                activeMarker: {},
                selectedPlace: {attr:{}},
            },
        }
    }
// -------------------- lifecycl methods ---------------------
    componentDidMount(){
        this.props.loadAssetClass();
    }

    componentWillReceiveProps(nextProps){
         
        if (nextProps.assetClass) {
             
        }
    }

    componentWillUnmount(){
         
        this.props.clearGroupAssetData();
    }

// -------------------- customer methods ----------------------
    handleMapMarkerClick = (props, marker, e) => {    
        //  
        
        this.setState(prevState => {
            return ({
                mapProps: {
                    // showingInfoWindow: true,
                    showingInfoWindow: !prevState.mapProps.showingInfoWindow,
                    activeMarker: marker,
                    selectedPlace: props,
                    lat: props.position.lat,
                    lng: props.position.lng,
                },
            });
        });      
    }

    handleMapInfoWinClick = (props) => {
        if(this.state.mapProps.showingInfoWindow){
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    }

    handleMapInfoWinClose = (props) => {
         
        // if(this.state.mapProps.showingInfoWindow){
            this.setState((prevState) => ({
                mapProps: {
                    ... prevState.mapProps,
                    showingInfoWindow: false,
                    // activeMarker: null
                }                
            }));
        // }
    }
// ------------------------------------------------------------
    handleChange = panel => (event, newExpanded) => {
         
         
         
        this.setState({[panel] : newExpanded});
      };

    renderExpandPanel = () => {
        const { classes } = this.props;
        return(
            <div>
                <ExpansionPanel expanded={this.state.panela}  onChange={this.handleChange('panela')}>
                    <ExpansionPanelSummary
                        // expandIcon={<ExpandMoreIcon />} expanded={state.panela} 
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.heading}
                    >
                        <Typography >Map</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>

                <ExpansionPanel expanded={this.state.panelb}  onChange={this.handleChange('panelb')}>
                    <ExpansionPanelSummary
                        // expandIcon={<ExpandMoreIcon />} expanded={state.panela} 
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.heading}
                    >
                        <Typography >Data</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                            <AssetTable
                                // assetData = {this.props.assetData}
                                assetData = {this.props.assetGroupData}
                            />
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }

    toggle = tagname => () => {
        this.setState(prevState => ({
            [tagname]: !prevState[tagname]
        }));
    }

    renderCollapsCard=()=>{    
        // let processedMapdata = processAssetDataMsg(this.props.assetGroupData);
        //{reMapData, multiAssets}
        let {reMapData: processedMapdata, multiAssets} = processAssetDataMsg(this.props.assetGroupData);
         
        return (
            <Fragment>
            <Card>
                <CardHeader style={{cursor: 'pointer'}} onClick={this.toggle('isOpenTag1')}>
                    <div>
                        Map
                    </div>
                </CardHeader>
                <CardBody style={{padding:0}}>
                    <Collapse isOpen={this.state.isOpenTag1}> 
                        <MapContainer 
                            onMarkerClick={this.handleMapMarkerClick} 
                            onInfoWinClick={this.handleMapInfoWinClick}
                            handleMapInfoWinClose={this.handleMapInfoWinClose}
                            values = {this.state.mapProps}
                            mapData = {this.props.assetGroupData}
                            pMapData = {processedMapdata}
                            multiAssets = {multiAssets}
                            loading = {this.props.loading}
                            selectedAttriId = {this.state.selectedAttriId}
                            clearGroupAssetData = {this.props.clearGroupAssetData}
                        />
                    </Collapse>
                </CardBody>
            </Card>

            <Card>
                <CardHeader className={'dragBar'} style={{cursor: 'pointer'}} onClick={this.toggle('isOpenTag2')}>
                    <div>
                        Data
                    </div>
                </CardHeader>
                <CardBody style={{padding:0}}>
                    <Collapse isOpen={this.state.isOpenTag2}> 
                        <div style={{padding:15}}>
                            <AssetTable
                                assetData = {this.props.assetGroupData}
                                // assetData = {processedMapdata}
                            />
                        </div>
                    </Collapse>
                </CardBody>
            </Card>
            </Fragment>
        );
    }

    filterGroupAssetDataByAttribute = (attriId)=>{
        // this.props.filterGroupAssetDataByAttribute(attriId);
        this.setState({
            selectedAttriId: attriId,            
        });
    }

    renderFilter = () => {
        return (
            <Fragment>
                <Card style={{padding:0}}>
                    <CardBody style={{paddingBottom:0}}>
                        <AssetFilter
                            assetClass = {this.props.assetClass}
                            themes = {this.props.themeList}
                            assetClassAttr = {this.props.assetClassAttr}
                            getThemeList = {this.props.getThemeList}
                            getAssetClassAttribute = {this.props.getAssetClassAttribute}
                            clearAssetClassAttribute = {this.props.clearAssetClassAttribute}
                            getGroupAssetData = {this.props.getGroupAssetData}
                            clearGroupAssetData = {this.props.clearGroupAssetData}
                            filterGroupAssetDataByAttribute = {this.filterGroupAssetDataByAttribute}
                            loading = {this.props.loading}
                        />
                    </CardBody>
                </Card>
            </Fragment>
        )
    }

    render() {
        return (
            <Fragment>
            {this.renderFilter()}
            <hr/>
            {/* {this.renderExpandPanel()}
            <br/> */}
            {this.renderCollapsCard()}
            </Fragment>
        );
    }
}

function processAssetDataMsg(mapData){
    // deep clone of source data
    let reMapData = JSON.parse(JSON.stringify(mapData));
    let multiAssets = true;
    // deal with one asset class and one asset situation 
    if (reMapData && reMapData.length ===1 && reMapData[0].asset && reMapData[0].asset.length===1){
        multiAssets = false;
        // clear asset array and get the asset element object
        let asset = reMapData[0].asset.pop();
        if (asset.device) {
            for (let i = 0 ; i < asset.device.length; i++){
                if (asset.device[i].trackable){
                    if (asset.device[i].msgs && asset.device[i].msgs.length > 0){
                        let messages = asset.device[i].msgs;
                        let cloneasset = asset;
                        for (let mi = 0 ; mi < messages.length; mi++) {
                            // create new asset object based on the privous asset
                            // let newAsset = JSON.parse(JSON.stringify(asset));
                            let newAsset = JSON.parse(JSON.stringify(cloneasset));
                            for(let j = 0 ; j < newAsset.device.length; j++){
                                let msgItem; 
                                let newMsg = newAsset.device[j].msgs;
                                if (j === i) {
                                    msgItem = JSON.parse(JSON.stringify(messages[mi]));
                                    // msgItem = {...newMsg[0], ...JSON.parse(JSON.stringify(messages[mi]))};
                                    // msgItem.msg = {...newMsg[0].msg, ...msgItem.msg}
                                }else{
                                    let otherMessages = asset.device[j].msgs;
                                    msgItem = JSON.parse(JSON.stringify(otherMessages[mi]));
                                    // msgItem = {...newMsg[0], ...JSON.parse(JSON.stringify(otherMessages[mi]))};
                                    // msgItem.msg = {...newMsg[0].msg, ...msgItem.msg}
                                }

                                // merge msg attributes, if some attributes do not exist, use the attributes in the previous msg(asset)
                                msgItem.msg = {...newMsg[0].msg, ...msgItem.msg}
                                //  

                                newAsset.device[j].msgs = [];
                                // newAsset.device[j].msgs.push(JSON.parse(JSON.stringify(messages[mi])));
                                newAsset.device[j].msgs.push(msgItem);
                                // if (selectedAttriId) {
                                //     // set asset color
                                //     if (msgItem.msg){
                                //         Object.keys(msgItem.msg).forEach((key)=>{
                                //             //  
                                //             //  
                                //             //  
                                //             if (msgItem.msg[key].id == selectedAttriId){
                                //                 //  
                                //                 let colorValue = msgItem.msg[key].themeattributevalue;
                                //                 if (msgItem.msg[key].display !== false && colorValue && colorValue.length >0){
                                //                     //  
                                //                     // color = colorValue ;
                                //                     newAsset['color'] = colorValue;
                                //                 }
                                //             }else{
                                //                 // delete msg.msg[key];
                                //             }
                                //         });
                                //     }               
                                // }
                            }
                            if (messages[mi].msg && messages[mi].msg.Lng && messages[mi].msg.Lat){
                                newAsset.locationlat = messages[mi].msg.Lat.value;
                                newAsset.locationlng = messages[mi].msg.Lng.value;
                            }      
                            cloneasset = newAsset;
                            reMapData[0].asset.push(newAsset);
                        }
                    }
                    // skip devices except the first trackable device (the device with gps location)
                    break;
                }
            }
        }
        if ( reMapData[0].asset.length === 0 ){
            reMapData[0].asset.push(asset);
        }

        // // pick up the right time message for each device. 
        // reMapData[0].asset.forEach((item, index) => {
        //     if (item.device) {
        //         // find trackable (gps) device message time as stander
        //         let timestampe = new Date();
        //         item.device.forEach((d, i) =>{
        //             if (d.trackable && d.msgs && d.msgs.length === 1) {
        //                 timestampe = new Date(d.msgs[0].timestamp);
        //                 //  
        //             }
        //         });

        //         // choose the device message whose time is right before or equal to the gps device first message
        //         item.device.forEach((d, i) => {
        //             if (!d.trackable || d.msgs.length  > 1){
        //                 //  
        //                 let foundIndex = -1;
        //                 for (let m = 0 ; m < d.msgs.length ; m++){
        //                     if (d.msgs[m].timestamp <= timestampe){
        //                         foundIndex = m;
        //                         // break;
        //                     }else{
        //                         break;
        //                     }
        //                 }
        //                 if (foundIndex >= 0){
        //                     d.msgs=[d.msgs[foundIndex]];
        //                 }else{
        //                     d.msgs=[d.msgs[0]];
        //                 }
                        
        //             }
        //         });
        //     }
        // });
         
        //  
        // pathPoints(reMapData, pointArr);
        //  

    }
    // else if (reMapData && reMapData.length >=1 && selectedAttriId) {
    //      
    //     reMapData.forEach((ac, acIndex)=>{
    //          
    //         ac.asset && ac.asset.forEach((at, atIndex)=>{
    //              
    //             at.device && at.device.forEach((dv, dvIndex) => {
    //                  
    //                 dv.msgs && dv.msgs.length > 0 && dv.msgs[0].msg && Object.keys(dv.msgs[0].msg).forEach((key)=>{
    //                      
    //                     if (dv.msgs[0].msg[key].id == selectedAttriId){
    //                         let colorValue = dv.msgs[0].msg[key].themeattributevalue;
    //                          
    //                         if (dv.msgs[0].msg[key].display !== false && colorValue && colorValue.length >0){
    //                             at['color'] = colorValue;
    //                         }
    //                     }
    //                 });
    //             });
    //         });            
    //     });

    // }

    return {reMapData, multiAssets};
}

function mapStateToProps(state) {
    const mapData = state.mapboard.mapData;
    const assets = state.mapboard.assets;
    const assetData = state.mapboard.assetData;
    const assetClass = state.mapboard.assetClass;
    const themeList = state.attributetheme.themeList;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const assetGroupData = state.mapboard.assetGroupData;
    const loading = state.mapboard.loading;

    const props = { mapData, assets, assetData, assetClass, themeList, assetClassAttr, assetGroupData, loading };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
            loadAssetClass,
            getAssetClassAttribute,
            clearAssetClassAttribute,
            getThemeList,
            filterMapDataByAttribute,
            getGroupAssetData, 
            clearGroupAssetData,
            filterGroupAssetDataByAttribute,
    }, dispatch);
};

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    // withRouter
);

export default enhance(AssetClassBoard);