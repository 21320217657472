/* eslint-disable */
import React from "react";
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label
} from "reactstrap";

const AddContactForm = (props) => (
  <form>
    {/* <div className="form-group class-sm-3">
			    <label htmlFor="fullName">First Name</label>
			    <input type="name" class="form-control" name="name" onChange={onInputChange} placeholder="" />
			</div>
			 */}
    <div className="form-group class-sm-3">
      {/* <label htmlFor="fullName">Name</label>
      <input
        type="name"
        class="form-control"
        name="surname"
        onChange={onInputChange}
        placeholder=""
      /> */}
      <InputGroup >
        <InputGroupAddon addonType="prepend">
          <InputGroupText>Name:</InputGroupText>
        </InputGroupAddon>
    <Input 
      name='name'
			value={props.name}
			onChange={props.onNameChange}
			placeholder=""

			/>
      </InputGroup>
    </div>
    <div className="form-group class-sm-3">
      <InputGroup>
        <InputGroupAddon  addonType="append">
          {/* <label htmlFor="emailAddress">Email address</label> */}
          <InputGroupText>
            <Input
              addon
              type="checkbox"
              aria-label="Checkbox for following text input"
              class="form-control"
              onClick={props.onEmailEnableChange}
              checked={props.isEmailChecked}
              name='emailenable'
            />
            Email
          </InputGroupText>
        </InputGroupAddon>
          <Input placeholder="example@example.com" 
              onChange={props.onEmailChange}
              name='email'
              value={props.email} 
          />
      </InputGroup>
      <br />
      <InputGroup>
        <InputGroupAddon addonType="subpend">
          <InputGroupText>
            <Input
              addon
              type="checkbox"
              aria-label="Checkbox for following text input"
              class="form-control"
              onClick={props.onPhoneEnableChange}
              checked={props.isPhoneChecked}
              name='phoneenable'
            />
            Phone
          </InputGroupText>
        </InputGroupAddon>
        <Input placeholder="xxx-xxx-xxxx" 
          name='phone'
          value={props.phone}
          onChange={props.onPhoneChange}/>
      </InputGroup>
    </div>
    <div>
      <p style={{fontSize:'14px'}}>
        <b>Note:</b> Please check if you prefer receiving notifications by phone, email or both.
        {/* <br /> */}
      </p>{" "}
    </div>
	{/* <button>send</button> */}
  </form>
);

export default AddContactForm;
