/* eslint-disable */
import React, { Component } from "react";
import Card from "react-credit-cards";
import { toast } from "react-toastify";
import { Row, Input, Button } from 'reactstrap';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

/**
 * @param {*} card : the credit card that will be edited
 * @param {*} onSave : the function for after the credit card is saved
 */
class PaymentEditWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            name: "",
            nickName: "",
            number: "",
            expiry: "",
            cvc: "",
            focused: "",
            issuer: "",
            selected: "N",
            isValid: false,
            disable: false
        }
    }
    componentDidMount() {
        this.clearDocument();
        if (this.props.card != null) {
            this.setState({
                id: this.props.card.id,
                name: this.props.card.name,
                nickName: this.props.nickName,
                number: this.props.card.number,
                expiry: this.props.card.expiry,
                cvc: this.props.card.cvc,
                selected: this.props.card.selected,
                focused: "",
                issuer: "",
                isValid: true,
                disable: false
            })
        } else {
            this.setState({
                id: 0,
                name: "",
                nickName: "",
                number: "",
                expiry: "",
                cvc: "",
                focused: "",
                issuer: "",
                selected: "N",
                isValid: true,
                disable: true
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        this.clearDocument();
        if (nextProps.card != null) {
            this.setState({
                id: nextProps.card.id,
                name: nextProps.card.name,
                number: nextProps.card.number,
                expiry: nextProps.card.expiry,
                cvc: nextProps.card.cvc,
                selected: nextProps.card.selected,
                focused: "",
                issuer: "",
                nickName: nextProps.card.nickName,
                isValid: true,
                disable: false
            })
        }
        else {
            this.setState({
                id: 0,
                name: "",
                number: "",
                nickName: "",
                expiry: "",
                cvc: "",
                selected: "N",
                focused: "",
                issuer: "",
                isValid: true,
                disable: true
            })
        }
    }
    clearDocument = () => {
        if(document.getElementById("nickName") != null){
            document.getElementById("nickName").value = "";
        }
        if(document.getElementById("number") != null){
            document.getElementById("number").value = "";
        }
        if(document.getElementById("name") != null){
            document.getElementById("name").value = "";
        }
        if(document.getElementById("expiry") != null){
            document.getElementById("expiry").value = "";
        }
        if(document.getElementById("cvc") != null){
            document.getElementById("cvc").value = "";
        }
    }
    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleCallback = (type, isValid) => {
        this.setState({
            issuer: type.issuer,
            isValid: isValid
        })
    }
    handleInputFocus = (event) => {
        this.setState({ focused: event.target.name })
    }
    isDateValid = () => {
        var regex = /^[0-9]{2}[\/]{0,1}([0-9]{2}|[0-9]{4})$/;
        return this.state.expiry && regex.test(this.state.expiry);
    }
    isCVCValid = () => {
        var regex = /^\d{3,4}$/
        return this.state.cvc && regex.test(this.state.cvc);

    }
    isCreditCardValid = () => {
        return this.state.isValid && this.isDateValid() && this.isCVCValid();
    }
    onSave = () => {
        var regex = /^[0-9]{2}\/([0-9]{2}|[0-9]{4})$/;
        var expire = this.state.expiry;
        if (regex.test(expire)) {
            expire = expire.substring(0, 2) + expire.substring(expire.length - 2, expire.length);
        }
        var card = {
            id: this.state.id,
            type: "CC",
            name: this.state.name,
            nickName: this.state.nickName,
            number: this.state.number,
            expiry: expire,
            selected: this.state.selected,
            status: 1,
            cvc: this.state.cvc,
            issuer: this.state.issuer
        }
        this.setState({
            id: 0,
            type: "CC",
            name: "",
            nickName: "",
            number: "",
            expiry: "",
            selected: "N",
            status: 1,
            cvc: "",
            issuer: ""
        }, () => {
            this.props.onSave(card);
            document.getElementById("nickName").value = "";
            document.getElementById("number").value = "";
            document.getElementById("name").value = "";
            document.getElementById("expiry").value = "";
            document.getElementById("cvc").value = "";
        })
    }
    onDelete = () => {
        if (this.state.selected === "Y") {
            toast.error("change default payment")
            return null;
        }
        var card = {
            id: this.state.id,
            type: "CC",
            nickName: this.state.nickName,
            name: this.state.name,
            number: this.state.number,
            expiry: this.state.expiry,
            selected: this.state.selected,
            status: 0,
            cvc: this.state.cvc,
        }
        this.setState({
            id: 0,
            type: "CC",
            name: "",
            nickName: "",
            number: "",
            expiry: "",
            selected: "N",
            status: 1,
            cvc: "",
        }, () => {
            this.props.onSave(card);
        })
    }
    render() {
        return (
            <div>
                {(this.props.card != "") ?
                    <div key="Payment">
                        <div className="App-payment">
                            <div className="display-wallet">
                                <Card
                                    number={this.state.number}
                                    name={this.state.name}
                                    expiry={this.state.expiry}
                                    cvc={this.state.cvc}
                                    focused={this.state.focused}
                                    callback={this.handleCallback}
                                />
                            </div>
                            <br />
                            {
                                (this.state.id > 0 && this.props.onSave != null) ?
                                    <div style={{ marginBottom: "10px", "text-align": "right" }}>
                                        <ConfirmDeleteDialog
                                            label={"Remove Payment"}
                                            noIcon={true}
                                            onConfirm={(e) => { this.onDelete() }}
                                            msg={'Do you want to delete payment ? '}
                                        />
                                    </div>
                                    :
                                    null
                            }
                            {
                                (this.props.onSave != null) ?
                                    <div>
                                        NOTE: Re-enter all information to edit a Card
                                        <Input
                                            type="text"
                                            name="nickName"
                                            id="nickName"
                                            className="form-control"
                                            placeholder="Nick Name (Optional)"
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <br />
                                        <Input
                                            type="tel"
                                            name="number"
                                            id="number"
                                            className="form-control"
                                            placeholder="Card Number"
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <br />
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className="form-control"
                                            placeholder="Name"
                                            onChange={this.handleInputChange}
                                            onFocus={this.handleInputFocus}
                                        />
                                        <br />
                                        <Row>
                                            <div className="col-6">
                                                <Input
                                                    type="tel"
                                                    name="expiry"
                                                    id="expiry"
                                                    className="form-control"
                                                    placeholder="Valid Thru"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Input
                                                    type="tel"
                                                    name="cvc"
                                                    id="cvc"
                                                    className="form-control"
                                                    placeholder="CVC"
                                                    onChange={this.handleInputChange}
                                                    onFocus={this.handleInputFocus}
                                                />
                                            </div>
                                        </Row>
                                        <br />
                                        <div>
                                            <Button className="btn-custom btn-block" disabled={!this.isCreditCardValid()} onClick={() => { this.onSave() }}>Submit</Button>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}
export default PaymentEditWallet;