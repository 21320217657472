/* eslint-disable */
import React, { Component } from "react";
import { Col, Row, Button, Input, Label, FormGroup, Form } from 'reactstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import AtIcon from 'mdi-react/AtIcon';
import LinkVariantIcon from 'mdi-react/LinkVariantIcon';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}
class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    renderEmailNotification = () => {
        return (
            <Form>
                <Row>
                    <Col md={6}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="info" style={{ display: 'inline', width: "100%" }} >Email to</Label>
                                <Input
                                    type="text"
                                    id="info"
                                    name="info"
                                    width="100%"
                                    onChange={this.props.onInputChange}
                                    value={this.props.info}
                                //valid={this.validateEmail()}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="subject" style={{ display: 'inline', width: "100%" }} >Subject</Label>
                                <Input type="text"
                                    id="subject"
                                    name="subject"
                                    width="100%"
                                    onChange={this.props.onInputChange}
                                    value={this.props.subject}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label for="message" style={{ display: 'inline', width: "100%" }} >Message</Label>
                                <Input type="textarea" id="message" name="message" width="100%" onChange={this.props.onInputChange} value={this.props.message}>
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    renderMsmNotification = () => {
        return (
            <Form>
                <Row>
                    <Col md={6}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="info" style={{ display: 'inline', width: "100%" }} >Phone number</Label>
                                <Input
                                    type="text"
                                    id="info"
                                    name="info"
                                    width="100%"
                                    onChange={this.props.onInputChange}
                                    value={this.props.info}
                                //valid={this.validatePhoneNumber()}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label for="message" style={{ display: 'inline', width: "100%" }} >Message</Label>
                                <Input type="textarea" id="message" name="message" width="100%" onChange={this.props.onInputChange} value={this.props.message}>
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        )
    }

    renderTab = () => {

        return (
            <div >
                <Tabs value={this.props.callbackStep} onChange={this.props.handleChange} aria-label="simple tabs example">
                    <Tab icon={<AtIcon />} aria-label="email" />
                    <Tab icon={<MessageTextOutlineIcon />} aria-label="message" />
                </Tabs>
                <TabPanel value={this.props.callbackStep} index={0}>
                    {this.renderEmailNotification()}
                </TabPanel>
                <TabPanel value={this.props.callbackStep} index={1}>
                    {this.renderMsmNotification()}
                </TabPanel>
            </div>
        );
    }
    render() {
        return (
            <Row>
                <Col md={12}>
                    <Row>
                        <Col md={12}>
                            {this.renderTab()}

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button outline color="secondary" onClick={() => { this.props.handleBack(2) }}>Back</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default Notification;


