import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ActivityChart from './components/ActivityChart';
import ActivityRating from './components/ActivityRating';
import FatBurning from './components/FatBurning';
// import HeartRate from './components/HeartRate';
// import CaloriesBurn from './components/CaloriesBurn';
import Steps from './components/Steps';
import Distance from './components/Distance';
// import TodayRunningMap from './components/TodayRunningMap';
// import MyCompetitors from './components/MyCompetitors';
import SalesStatisticBar from './components/SalesStatistisBar';
import BasicTable from './components/BasicTable';

const TemplateBoard = ({ t }) => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">{t('Title Dashboard')}</h3>
      </Col>
    </Row>
    <Row>
      <SalesStatisticBar />
      <Steps />
      <Distance />
      {/* <Steps />
      <Distance /> */}
    </Row>
    <Row>
      <Steps />
      <Distance />
      <BasicTable />
    </Row>
    <Row>
      <ActivityChart />
      {/* <TodayRunningMap />  */}
      {/* <MyCompetitors /> */}
      <FatBurning />
      <FatBurning />
      <ActivityRating />
      <ActivityRating />
    </Row>
  </Container>
);

TemplateBoard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TemplateBoard);
