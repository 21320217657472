/* eslint-disable */
import React, { Component } from 'react';
// import {Row, Col, Card, CardBody, Label, Input, Button} from 'reactstrap';
import {Row, Col, Card, CardBody, Label, Input, Button, Table} from 'reactstrap';
import icon_delete from '../../../shared/img/icon/delete.png';

class RuleSetCard extends Component{

    constructor(props){
        super(props);
        this.state={
           
        }
    }

    //-----------------------------------------------------------------------------------------------------------
    handleAttrAddClick = (e, name) =>{
        this.props.handleAddRuleAttr(e, name);
    }
    renderAttributeList() {
        // alert(this.props.ruleattributes.length);
        return(
            <div>
                {
                    this.props.ruleattributes && 
                    <Table>
                        <thead>
                            <tr>
                                <th>Rule Attributes</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {this.props.ruleattributes.map((attr, i)=>{
                                    return(
                                        <tr  key={i}>                                    
                                            <td>{attr.attributename}</td>
                                            <td>
                                                <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' value={attr.attributeid} onClick={(e) => {this.handleAttrAddClick(e, attr.attributename)}}>Add</Button>
                                            </td>                                        
                                        </tr>
                                    )
                                   
                                })}
                            </tbody>
                    </Table>
                }
            </div>
        )
    }
    //-----------------------------------------------------------------------------------------------------------
    handleAttrRemoveClick = (e) => {
        this.props.handleAttrRemoveClick(e);
    }

    handleValueChange = (e, ruleItem, index) => {
        this.props.handleValueChange(e, ruleItem, index);
    }

    handleOperatorChange = (e, ruleItem) => {
        this.props.handleOperatorChange(e, ruleItem);
    }

    handleRelationChange = (e, ruleItem) => {
        this.props.handleRelationChange(e, ruleItem);
    }

    renderRuleLine(ruleItem, index){
        return (
            <div key = {index}>            
                {( ruleItem.relation > 0) && 
                    <Row>
                        <Col md={3}>
                        <Input type="select" value={ruleItem.relation} width="20%" onChange={(e) => {this.handleRelationChange(e, ruleItem)}} >
                            <option value={1}>{'AND'}</option>
                            <option value={2}>{'OR'}</option>
                            <option value={3}>{'NOT'}</option>
                        </Input>
                        </Col>
                     </Row>
                }                         
                <Row>
                    <Col md={3} style={{textAlign: 'right', verticalAlign: 'middle'}}>
                        <Label style={{ display: 'inline'}}><h5>{ruleItem.attributename} :</h5></Label>
                    </Col>
                    <Col md={3}>
                    <Input type="select" value={ruleItem.operator}  width="20%" onChange={(e) => {this.handleOperatorChange(e, ruleItem)}} >
                        <option value={1}>{'='}</option>
                        <option value={2}>{'>'}</option>
                        <option value={3}>{'>='}</option>
                        <option value={4}>{'<'}</option>
                        <option value={5}>{'<='}</option>
                        <option value={6}>{'<>'}</option>
                    </Input>
                    </Col>
                    <Col md={3}>
                    <Input type="text" value={ruleItem.value} width="30%" onChange={(e) => {this.handleValueChange(e, ruleItem, index)}}></Input>
                    </Col>
                    <Col md={3}>
                        {/* <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} /> */}
                        <Button outline variant="primary" color="danger" type="button" style={{margin:'auto'}} size='sm' value={index} onClick = {this.handleAttrRemoveClick} > 
                                    Remove
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    renderRuleSet(){
        return(
            <div>
                {
                    this.props.rule && this.props.rule.rule && 
                    this.props.rule.rule.map((item, i) =>{
                        return(
                            this.renderRuleLine(item, i)
                        )
                    })
                }
            </div>
        )
    }
    //-----------------------------------------------------------------------------------------------------------
    handleRuleSetSubmit = (e) => {
        this.props.handleRuleSetSubmit(e);
    }
    handleRuleSetCancle = (e) => {
        this.props.handleRuleSetCancle(e);
    }
    render(){
        return(
            <Col md={12}>
                <Card>
                    <CardBody>
                        <h3>{this.props.rule.rulename}</h3>
                        <br/>
                        <Row>
                            <Col md={5}>
                                {this.renderAttributeList()} 
                            </Col>   
                            <Col md={1}></Col>  
                            <Col  md={5}>
                                {this.renderRuleSet()}
                            </Col>                      
                        </Row>
                        <Row>
                           <Col md={12} style={{ textAlign: 'right' }}>
                                <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick = {this.handleRuleSetCancle}>
                                    Cancel
                                </Button> {' '}
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick = {this.handleRuleSetSubmit}>
                                    Submit
                                </Button>
                           </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default RuleSetCard;