/* eslint-disable */
import React, {Component, Fragment } from 'react';
import {Container, Row, Col, Button, Table} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import {chunk} from '../../../factories/utils';
import DeviceDetailBoard from '../../DeviceDetail/components/DeviceDetailBoard';
import {ChevronLeftIcon} from 'mdi-react';
import {groupBy} from '../../../factories/utils';

class DeviceTable extends Component{
    constructor(props){
        super(props);
    }
// -------------------------------------------------------
    createCustomExportCSVButton = (onClick) => {
        return (
        <ExportCSVButton
            btnText='Export Data'
            btnContextual='btn-success'
            className='my-custom-class'
            btnGlyphicon='glyphicon-edit'
            onClick={ e => this.handleExportCSVButtonClick(onClick) }/>
    );}

    handleExportCSVButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
         
        onClick();
    }

    timeColumnFormatter = (cell, row) => {
        return (
            <span>
                {new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' })}; 
            </span>
        );
    }

    timeCSVFormatter = (cell, row) => {
        return new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' });
    }

    basicCSVFormatter = (cell, row) => {
       return (cell ? cell : '');
    }

    renderDeviceList2(mapData){
        let keys = ['id','name','timestamp'];
        let labels = {id : 'Message ID', name: 'Device', timestamp: 'Message Time'};
        let renderData = [];
        if (mapData != undefined && mapData != null && Array.isArray(mapData) && mapData.length > 0){
            // Object.keys(this.props.mapData[0].msg).map((key,i)=>{
            //     if(key !== 'Lng' && key !== 'Lat'){
            //         keys.push(key);
            //     }
            // });

            // Object.entries(this.props.mapData[0].msg).forEach(([attr, attrObj],mindex)=>{
            //     if (attr !== 'Lng' && attr !== 'Lat'){
            //         msgObj[attr] = attrObj.value;
            //         if (! keys.includes(attr) ){
            //             keys.push(attr);
            //             labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
            //         }
            //     }
            // });

            mapData.map((item, i) => {
                // let dataitem = {id: item.msgid, name: item.devicename, timestamp: new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' })};    
                // let dataitem = {id: item.msgid, name: item.devicename, timestamp: new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'UTC' })};     
                let dataitem = {id: item.msgid, name: item.devicename, timestamp: item.timestamp};                
                // let msg = {msgid: item.msgid, datetime: dateTime};
                // keys.map((key, i) => {
                //     if (key != 'id' && key != 'name'){
                //         dataitem[key] = item.msg[key] ? item.msg[key].value : null; // item.msg[key] ? item.msg[key].value : null;
                //     }
                    
                // });
                // let dataItem = Object.assign({}, msg);
                // --------------------------------------------------
                Object.entries(item.msg).forEach(([attr, attrObj],mindex)=>{
                    // if (attr !== 'Lng' && attr !== 'Lat' && attrObj.display !== false){
                    if ( attrObj.display !== false){
                        dataitem[attr] = attrObj.value;
                        if (! keys.includes(attr) ){
                            keys.push(attr);
                            labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
                        }
                    }
                });
                // --------------------------------------------------
                renderData.push(dataitem);
            });

        }

        if (keys.includes('Lng') && keys.includes('Lat')) {
            keys = keys.filter((val, idx, arr) => ( val !== 'Lng' && val !=='Lat'));               
            keys.push('Lat');
            keys.push('Lng');
        }

        const options = {
            exportCSVBtn: this.createCustomExportCSVButton
        };

        return(
            <BootstrapTable data={ renderData } tableHeaderClass = 'header_white'  options={ options } csvFileName={ 'export_data.csv' } exportCSV={ true } keyField='id'    search maxHeight='350' >
                {/* <TableHeaderColumn keyField='id' hidden >Message ID</TableHeaderColumn> */}
                {/* <TableHeaderColumn dataField='name' dataSort>Device Name</TableHeaderColumn>  */}
                {/* <TableHeaderColumn dataField='devicetypeid' dataSort>Device Type ID</TableHeaderColumn>
                <TableHeaderColumn dataField='devicetypename' dataSort>Device Type Name</TableHeaderColumn>
                <TableHeaderColumn dataField='timestamp'>Last Message Time</TableHeaderColumn>
                <TableHeaderColumn dataField='data'>Original Data</TableHeaderColumn> */}
                {keys.map((key, i) => {
                    return(
                        <TableHeaderColumn dataField={key} 
                            dataAlign="center"  
                            // width={`${this.state.width}px`} 
                            dataSort = {key === 'timestamp' ? true : false }
                            {...(key === 'timestamp' ? {dataFormat: this.timeColumnFormatter, csvFormat: this.timeCSVFormatter}: {csvFormat: this.basicCSVFormatter})}
                        >
                            {labels[key] }
                        </TableHeaderColumn>
                    )
                })}
            </BootstrapTable>
        )
    }
// -------------------------------------------------------
    render(){
        let groupedDevice = null;
        if (this.props.mapData){
            groupedDevice = groupBy(this.props.mapData, device => device.devicetypeid); 
        }
        return (
            <Container style={{paddingTop:'20px'}}>
                <Row>
                    {/* {this.renderDeviceList2()} */}
                    {   groupedDevice && 
                        <Fragment>
                            {Array.from(groupedDevice).map( ([key, value]) => this.renderDeviceList2(value) )}
                            <br/>
                        </Fragment>
                    }
                </Row>
            </Container>
        );
    }
}

export default DeviceTable;