/* eslint-disable */
import React, { Component } from 'react';
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {BootstrapTable, TableHeaderColumn} from "react-bootstrap-table";

import {
    getDashBoardTemplateUserLists,
    postAssignDashBoardTemplate
} from "../../../redux/actions/dashboardTemplateAction";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import SelectAssign from "./SelectAssign";
const updateBody = []
class DashBoardSettingAssignModal extends Component {

    constructor(props){
        super(props);


        this.state = {

        };


    }


        componentDidMount()
        {

            console.log("before running get user in assign modal")
            this.props.getDashBoardTemplateUserLists();
            console.log('getUsers inside  assign modal')
            console.log(this.props.dashboardUserList)
        }


    onClose = () => {
        this.props.toggle();
    }



    onSubmit = () => {

        this.props.onSubmit(updateBody)

    }


    handleSelectChange (e) {
        e.preventDefault();
        console.log('e.target')
         
        let optionDom = e.target.options[e.target.selectedIndex];
        console.log('optionDom')
         
        let v = optionDom.value,
            t = optionDom.text;
        let newSelect = {
            ls: this.state.select.ls,
            value: v,
            text: t
        }
        this.setState({
            select: newSelect
        })
    }



    onSelectChange = (e, row) => {
        e.preventDefault();
        console.log('e',e)
        console.log('row',row)
        //this.setState({value:0})
        console.log('e.target.value',e.target.value)
        row.access = Number(e.target.value);

        this.setState((preState)=>{
                return({
                    vaule: 0,
                });
            }
        );
    }


    getSelectChange = (val) => {
        console.log('val is :', val)
        if (val != undefined) {
            if (updateBody.length > 0  ) {
                let tmp,indexArray ;
                updateBody.forEach((item,index) => {
                    if (item.username !== val.username) {
                        tmp = true
                        indexArray = index
                    } else {
                        tmp = false
                        indexArray = index
                    }
                })
               if(tmp){updateBody.push(val)}else{updateBody[indexArray]=val}
            }else{
                updateBody.push(val)
            }
            console.log('final updateBody ', updateBody)
        }



    }


    cellAuthSelectionAssign(cell, row, enumObject, index) {


        console.log ('cell, row, enumObject, index', cell, row, enumObject, index);


       let valueOption = false;

        console.log ('this.props.templateObj.id', this.props.templateObj.id);

        row.templates.forEach(
            (item)=>{
                if(item.templateId === 301){
                    valueOption = true;
                }
            }
        )






        return(
            <div>


                <SelectAssign
                    value = {valueOption}
                    num = {index}
                    row={row}
                    id={this.props.templateObj.id}
                    getSelectChange = {this.getSelectChange.bind(this)}
                >

                </SelectAssign>





            </div>
        );
    }

    render(){
        // let modalTitle = '';
        // if (this.props.templateObj){
        //     modalTitle = this.props.templateObj.id == 0 ? 'Add New Template' : 'Edit Template';
        // }

        console.log(' madol this.props',this.props)


        return(
            <Card>
                <CardBody>
                    <BootstrapTable data={this.props.dashboardUserList} tableHeaderClass = 'header_white' maxHeight={700}>

                        {/* <TableHeaderColumn dataField='userId' dataAlign="center" >
                            <Trans i18nKey="user_id">User ID</Trans>
                        </TableHeaderColumn> */}
                        <TableHeaderColumn dataField='username' dataAlign="center" isKey >
                            User Name
                        </TableHeaderColumn>


                        <TableHeaderColumn
                            dataField='assign'
                            dataAlign="center"
                            dataFormat={this.cellAuthSelectionAssign.bind(this)}
                        >
                            Assign
                        </TableHeaderColumn>





                    </BootstrapTable>
                    <br/>
                    <div style={{textAlign:'right'}}>
                        <Button
                            outline color='custom'
                            type="button"
                            onClick={this.onSubmit}
                            // disabled = {!this.validateForm()}
                        >
                            save
                        </Button>
                        <Button
                            outline
                            color='secondary'
                            type="reset"
                            onClick={this.onClose}
                        >
                            close
                        </Button>
                    </div>
                    {/*<UserEditModal*/}
                    {/*    userEmail={this.state.userEmail}*/}
                    {/*    show={this.state.show}*/}
                    {/*    fullName={this.state.fullName}*/}
                    {/*    userName={this.state.userName}*/}
                    {/*    userId = {this.state.userName}*/}
                    {/*    userObj = {this.state.selectedUser}*/}
                    {/*    toggle={this.modalToggle}*/}
                    {/*    currentUserObj = {this.props.userObj}*/}
                    {/*/>*/}
                </CardBody>
            </Card>
                );
    }
}


const mapStateToProps = state => {
    return state.dashboardTemplate;
}

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getDashBoardTemplateUserLists,
        postAssignDashBoardTemplate
        }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardSettingAssignModal);
