/* eslint-disable */
import React, { Component } from 'react';
import {Row, Col, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const FileloaderModal=({modal, toggle, headertitle, labeltitle, labelvalue, onSave, onCancel })=>{
    const [file, setFile] = React.useState(null);

    const onFileChangeHandler = (e) => {
        setFile( e.target.files[0]);
    }

    const onSaveClick = (e) => {
        if (onSave) {
            onSave(e, file);
        }
    }
    const onCancelClick = (e) => {
        if (onCancel) {
            onCancel(e);
        }
        toggle();
    }
    return (
        <div>
            {/* <Button color="custom" onClick={this.toggle}>
                Upload Received Devices
            </Button> */}
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader  
                    toggle={toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {headertitle}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    <Row>
                        {/* <Col></Col> */}
                        <Label style={{ display: 'inline'}}> <h4>{labeltitle}</h4> </Label>
                        {/* <Col></Col> */}
                        <Label style={{ display: 'inline', marginLeft:'10px'}}> <h4>{labelvalue}</h4> </Label>
                    </Row>
                    <Row style={{marginTop: 10}}>
                        <Col md={10}>
                            <div className="form-group files color">
                                {/* <label htmlFor="firstName">
                                    <input type="file" className="form-control" name="file" onChange={this.onFileChangeHandler} />
                                </label> */}
                                <input type="file" name="file" class="custom-file-input" id="inputGroupFile01"
                                    aria-describedby="inputGroupFileAddon01" onChange={onFileChangeHandler}/>
                                <label class="custom-file-label" for="inputGroupFile01">Choose a CSV file</label>
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button outline color="custom" onClick={onSaveClick}>Save</Button>
                    <Button outline color="secondary" onClick={onCancelClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default FileloaderModal;