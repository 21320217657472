/* eslint-disable */
import { ThreeSixtyOutlined } from '@material-ui/icons';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class RegisterDeviceModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    onInputChange = (event) => {
        let key = event.target.name
        let value = event.target.value

        if (key == 'device_type') {
            this.setState({[key]: value})
        } else {
            if (value.length <= 40) {
                this.setState({[key]: value})
            } else {
               return value;
            }
        }
    }

    validateUserInput = () => {
        return (
            this.state.device_type && this.state.device_type !== '-1' && this.state.device_type.trim()
            && this.state.name && this.state.name !== '' && this.state.name.trim()
            && this.state.certificate && this.state.certificate !== '' && this.state.certificate.trim()
            && this.state.prefix && this.state.prefix !== '' && this.state.prefix.trim()
            && this.state.device_id && this.state.device_id !== '' && this.state.device_id.trim()
            && this.state.pac && this.state.pac !== '' && this.state.pac.trim()
        );
    }

    onSubmit = () => {
        let SfdeviceInfo = {
            id: this.state.device_id,
            pac: this.state.pac,
            name: this.state.name,
            lat: 0,
            lng: 0,
            automaticRenewal: true,
            activable: true,
        }

        let obj = {
            deviceTypeId: this.state.device_type,
            productCertificate: { key: this.state.certificate },
            prefix: this.state.prefix,
            prototype: false,
            data: [SfdeviceInfo]
        }
        
        this.props.registerDevice(obj).then(res => {
            if (res && res.status == 200) {
                if (res.data.statusCode == 200) {
                    toast.success(res.data.message)
                    return;
                }
            }

            toast.error(res.data.message)
        })

        // console.log("Register Device Modal > submit > request body", obj)
        this.onClose()
    }

    onClose = () => {
        this.setState({
            device_type: '-1',
            name: '',
            certificate: '',
            prefix: '',
            device_id: '',
            pac: '',
        }, () => {
            this.props.onCloseModal()
        })
    }

    render() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.props.showActivateDeviceModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onClose}>Add Device</ModalHeader>

                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2}>
                                <span>Type</span><span style={{color:'red'}}>*</span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='device_type'
                                    id='device_type'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.device_type}
                                    disabled={this.props.SIMTypeList.length==0}
                                >
                                    {
                                        this.props.SIMTypeList.length == 0 ? 
                                        <option key={-1} value={'-1'} selected>No available device type.</option> :
                                        <option key={-1} value={'-1'} selected>Please select device type.</option>
                                    }
                                    {
                                        this.props.SIMTypeList.map((item, index) => (
                                            <option key={index} value={item.itemCode}>{item.itemName}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <Row><Col><br/></Col></Row>
                        <Row>
                            <Col md={2}>
                                <span>Name</span><span style={{color:'red'}}>*</span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='name'
                                    id='name'
                                    placeholder='Item Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.name}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <Row><Col><br/></Col></Row>
                        <Row>
                            <Col md={2}>
                                <span>Certificate</span><span style={{color:'red'}}>*</span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='certificate'
                                    id='certificate'
                                    placeholder='Certificate'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.certificate}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <Row><Col><br/></Col></Row>
                        <Row>
                            <Col md={2}>
                                <span>Prefix</span><span style={{color:'red'}}>*</span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='prefix'
                                    id='prefix'
                                    placeholder='Prefix'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.prefix}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <Row><Col><br/></Col></Row>
                        <Row>
                            <Col md={2}>
                                <span>Device ID</span><span style={{color:'red'}}>*</span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='device_id'
                                    id='device_id'
                                    placeholder='Device ID'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.device_id}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                        <Row><Col><br/></Col></Row>
                        <Row>
                            <Col md={2}>
                                <span>PAC</span><span style={{color:'red'}}>*</span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='pac'
                                    id='pac'
                                    placeholder='PAC'
                                    onChange={this.onInputChange} 
                                    className="form-control"
                                    value={this.state.pac}
                                />
                                <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>

                <ModalFooter>
                    <Button className="btn-withhover" outline color='custom' type="button" 
                    disabled={!this.validateUserInput()} 
                    onClick={this.onSubmit}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

}

export default RegisterDeviceModal;