/* eslint-disable */
import React from 'react';

import { Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import MainBoard from './components/MainBoard';

import {UserContext} from '../../constants/myContexts';

const SnowPage = (props) => {
  let {userObj} = React.useContext(UserContext);
  if (!userObj) {
    toast.error('Can not fetch login user information, Please re-login.');
    props.history.push('/log_out');
  } else {
     
  }

  return (
    <div className="dashboard" style={{padding: '10px', margin:'10px'}}>
      <Row>
        <Col md={12}>
          <h2 className="page-title">Snow Clearing Device Monitoring</h2>
        </Col>
      </Row>
      
      <MainBoard userObj = {userObj}/>
    </div>
  );
};

export default withRouter(SnowPage);

