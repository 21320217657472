/* eslint-disable */
import React, { Component } from 'react';
import {compose, bindActionCreators} from "redux";
import {connect} from "react-redux";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {EditTextButton, DeleteTextButton, PlusIconButton} from '../../../shared/components/Button/IconButton';

class DeviceList extends Component {
    constructor(props){
        super(props);
        this.state={
            data: [
                {
                    actionid: 1,
                }
            ]
        }
    }

    render(){
        return(
            <div> 
               {this.renderInventoryList()}
            </div>
        );
    }

    renderInventoryList(){
        return(
            <Col md={12}>
            <Card>
            <CardBody>
               <BootstrapTable data={ products }  tableHeaderClass = 'header_white' pagination = {false}    bordered={ false } search 
                    // insertRow 
                    ref='table' >
                  {/* <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='5%' >UPC</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='5%' dataFormat={this.UPCLinkFormatter} >UPC</TableHeaderColumn>
                  <TableHeaderColumn dataField='company' dataAlign="center" dataSort width='5%' >COMPANY</TableHeaderColumn> 
                  <TableHeaderColumn dataField='mfg' dataAlign="center" dataSort width='5%' >MFG</TableHeaderColumn>
                  <TableHeaderColumn dataField='group' dataAlign="center" dataSort  >GROUP</TableHeaderColumn>                     
                  <TableHeaderColumn dataField='oursku' dataAlign="center" dataSort width='7%' >OUR_SKU</TableHeaderColumn>  
                  <TableHeaderColumn dataField='mfgsku' dataAlign="center" dataSort >MFG_SKU</TableHeaderColumn>  
                  <TableHeaderColumn dataField='quantity' dataAlign="center" dataSort width='7%' >QUANTITY</TableHeaderColumn>  
                  <TableHeaderColumn dataField='soldquantity' dataAlign="center" dataSort width='10%' >SOLD QUANTITY</TableHeaderColumn>  
                  <TableHeaderColumn dataField='network' dataAlign="center" dataSort >NETWORK</TableHeaderColumn>  
                  <TableHeaderColumn dataField='edit' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn>
              </BootstrapTable>
              </CardBody>
              </Card>
        </Col>
        );
    }

    renderDeviceMasterList(){
        return(
            <Col md={12}>
            <Card>
            <CardBody>
               <BootstrapTable data={ this.props.devicemaster }  tableHeaderClass = 'header_white' pagination = {false}    bordered={ false } search 
                    // insertRow 
                    ref='table' >
                  {/* <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='5%' >UPC</TableHeaderColumn> */}
                  <TableHeaderColumn dataField='deviceId' isKey={ true } dataAlign="center" width='5%' >Device ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='5%' >UPC</TableHeaderColumn>
                  <TableHeaderColumn dataField='mfg' dataAlign="center" dataSort width='5%' >MFG</TableHeaderColumn>
                  <TableHeaderColumn dataField='mfgsku' dataAlign="center" dataSort >MFG_SKU</TableHeaderColumn>  
                  <TableHeaderColumn dataField='mfgserialnum' dataAlign="center" dataSort  >MFG SERIAL NUMBER</TableHeaderColumn>   
                  <TableHeaderColumn dataField='macaddress' dataAlign="center" dataSort width='7%' >MAC</TableHeaderColumn>  
                  <TableHeaderColumn dataField='devicestatus' dataAlign="center" dataSort width='7%' >STATUS</TableHeaderColumn>  
                  <TableHeaderColumn dataField='specialstock' dataAlign="center" dataSort width='10%' >SPECIAL STOCK</TableHeaderColumn>  
                  <TableHeaderColumn dataField='customerid' dataAlign="center" dataSort width='10%' >CUSTOMER ID</TableHeaderColumn>  
                  <TableHeaderColumn dataField='company' dataAlign="center" dataSort width='5%' >COMPANY</TableHeaderColumn> 
                  <TableHeaderColumn dataField='networksupported' dataAlign="center" dataSort >NETWORK</TableHeaderColumn>  
                  <TableHeaderColumn dataField='refurbished' dataAlign="center" dataSort >REFURBISHED</TableHeaderColumn>  
                  <TableHeaderColumn dataField='olddeviceId' dataAlign="center" dataSort >OLD ID</TableHeaderColumn>  
                  <TableHeaderColumn dataField='createtime' dataAlign="center" dataSort >CREATE TIME</TableHeaderColumn>  
                  <TableHeaderColumn dataField='updatetime' dataAlign="center" dataSort >UPDATE TIME</TableHeaderColumn>  
                  <TableHeaderColumn dataField='updateby' dataAlign="center" dataSort >UPDATE BY</TableHeaderColumn>                   
                  {/* <TableHeaderColumn dataField='edit' dataAlign="center" dataFormat={this.editButtonFormatter}></TableHeaderColumn> */}
              </BootstrapTable>
              </CardBody>
              </Card>
        </Col>
        );
    }

    UPCLinkFormatter = (cell, row) => {
        const onLinkClick = (e) => {
            // alert('okay-e');
        } 
        return(
            <div>
                {/* <Button color="link">{cell}</Button> */}
                <a href='javascript:;' onClick={onLinkClick} ><span>{cell}</span></a>
            </div>
        )
    }

    handleRowClick = (row) => {
        this.props.handleRowClick(row);
    }

    editButtonFormatter = (cell, row) => {
        // let path = `/pages/device/${cell}`;
        let path = ``;
        return(
            <div>
                <EditTextButton label={'Detail'} onClick={(e)=>{this.handleRowClick(row);}}/>{' '}
                {/* <PlusIconButton label={'Activate'} onClick={(e)=>{}}/> */}
            </div>
        )
    }
}

const products = [];

function addProducts(quantity) {
  const startId = products.length;
  for (let i = 0; i < quantity; i++) {
    let network = 'Sigfox';
    if (i > 3){
        network = 'Google'
    }
    const id = startId + i;
    products.push({
      upc: id,
      company: 'ITSP ' + id,
      mfg: 2100 + i,
      group: 'ITSP goup ' + id,
      oursku: 'our sku ' + id,
      mfgsku: 'mfg sku ' + id,
      quantity: 100,
      soldquantity: 10 + id,
      network: network,
    });
  }
}

addProducts(5);

export default DeviceList;