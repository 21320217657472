/* eslint-disable */
import React, { Component } from 'react';
import { ChromePicker} from 'react-color';
import { toast } from "react-toastify";
import { Col, Container, Row, Card, CardBody, CardTitle, CardText, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Select from 'react-select';
// import { ENGINE_METHOD_DIGESTS } from 'constants';
// import GeoFenceMapSearch from '../../MapComponents/GeoFenceMapSearch';
import BasicGeoFenceMapSearch from '../../MapComponents/BasicGeoFenceMapSearch';

class BasicGeofenceModal extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedFenceOption: null,
            selectedShape: null,
            btnDisable: true,
            activeTab: '1',
            newFenceName: this.props.fenceDefaultName ? this.props.fenceDefaultName : '',
        };
    }
    //------------------------------------------------------------
    componentWillReceiveProps(nextProps){
        //  
        //  
        //  
        //  
        if (nextProps.fenceDefaultName !== this.props.fenceDefaultName) {
            //  
            //  
            //  
            this.setState({
                newFenceName: nextProps.fenceDefaultName,
            });
        }
        if (nextProps.allfences && nextProps.fenceId){
            let id = nextProps.fenceId
            let fence = nextProps.allfences.find((e, i) => (e.fenceid == id));
            if (!fence){
                return ;
            }
            let val = {value: id, label: fence.fencename};
            this.setCurrentFence(val, fence);
        }

        

        
                    //fenceOptions = {this.state.fenceOptions}
                    
    }
    //------------------------------------------------------------

    selectedFenceId = -1;
    selectedFenceName = '';
        
    currentShapeType = '';
    currentShapeTypeId = -1;
    currentPolygonData = null;
    currentRectangleData = null;
    currentCircleData = null;
    currentShapeData = null;
    isChange = false;

    // handleAssetChange = (val) => {
    //     let id = val.value;        
    //     let fence = this.props.allfences.find((e, i) => (e.fenceid == id));
    //     let currentPolygonData = null;
    //     let currentRectangleData = null;
    //     let currentCircleData = null;

    //     let type = '' ;
    //     switch(fence.shape){
    //         case 0:
    //             type = 'circle';
    //             currentCircleData = JSON.parse(fence.geodata);
    //             break;
    //         case 1:
    //             type = 'rectangle';
    //             currentRectangleData = JSON.parse(fence.geodata);
    //             break;
    //         case 2:
    //             type = 'polygon';
    //             currentPolygonData = JSON.parse(fence.geodata);
    //             break;
    //     }
    //     this.selectedFenceId = fence.fenceid;
    //     this.selectedFenceName = fence.fencename;

    //     this.currentShapeData = {type: type, data: JSON.parse(fence.geodata)};

    //     this.currentShapeType = type;
    //     this.currentShapeTypeId = fence.shape;
    //     this.currentPolygonData = currentPolygonData;
    //     this.currentRectangleData = currentRectangleData;
    //     this.currentCircleData = currentCircleData;
    //     this.setState({
    //         selectedFenceOption: val,
    //         selectedShape: {type: type, data: JSON.parse(fence.geodata)},
    //     });
    // }

    handleNewFenceNameChange = (e) => {
        const value = e.target.value;
        this.setState({
            newFenceName: value,
        });
    }

    setCurrentFence = (val, fence) => {
        if (fence) {
            let currentPolygonData = null;
            let currentRectangleData = null;
            let currentCircleData = null;
    
            let type = '' ;
            switch(fence.shape){
                case 0:
                    type = 'circle';
                    currentCircleData = JSON.parse(fence.geodata);
                    break;
                case 1:
                    type = 'rectangle';
                    currentRectangleData = JSON.parse(fence.geodata);
                    break;
                case 2:
                    type = 'polygon';
                    currentPolygonData = JSON.parse(fence.geodata);
                    break;
            }
            this.selectedFenceId = fence.fenceid;
            this.selectedFenceName = fence.fencename;
    
            this.currentShapeData = {type: type, data: JSON.parse(fence.geodata)};
    
            this.currentShapeType = type;
            this.currentShapeTypeId = fence.shape;
            this.currentPolygonData = currentPolygonData;
            this.currentRectangleData = currentRectangleData;
            this.currentCircleData = currentCircleData;
            this.setState({
                selectedFenceOption: val,
                selectedShape: {type: type, data: JSON.parse(fence.geodata)},
            });
        }
    }

    // onAddClick = (e) => {
    //     if (this.state.activeTab === '1' && !this.isChange) {
    //         if ( !this.state.selectedFenceOption) {
    //             toast.error('please select a fence.');
    //             return;
    //         }
    //         this.props.handleOnAddClick(this.state.selectedFenceOption);
    //     }else { //if (this.state.activeTab === '2')
    //          
    //         let data = "";
    //         switch(this.currentShapeTypeId){
    //             case 0:
    //                 data = JSON.stringify(this.currentCircleData);
    //                 break;
    //             case 1:
    //                 data = JSON.stringify(this.currentRectangleData);
    //                 break;
    //             case 2:
    //                 data = JSON.stringify(this.currentPolygonData);
    //                 break;
    //         }
    //         let shapeObj;
    //         // let shapeObj = {
    //         //     // deviceid: this.props.deviceid,
    //         //     // devicename: this.props.devicename,
    //         //     // devicetypeid: this.props.devicetypeid,
    //         //     // devicetypename: this.props.devicetypename,
    //         //     fenceid: 0, // this.props.fenceid,
    //         //     fencename: this.state.newFenceName,
    //         //     geodata: data,
    //         //     shape: this.currentShapeTypeId
    //         // }
    //         //  
    //         //  
    //         //  
    //         if (this.state.activeTab === '1') {
    //             if ( this.selectedFenceId < 1) {
    //                 toast.error('please select a fence.');
    //                 return;
    //             }
    //             shapeObj = {
    //                 // deviceid: this.props.deviceid,
    //                 // devicename: this.props.devicename,
    //                 // devicetypeid: this.props.devicetypeid,
    //                 // devicetypename: this.props.devicetypename,
    //                 fenceid: this.selectedFenceId, // this.props.fenceid,
    //                 fencename: this.selectedFenceName,
    //                 geodata: data,
    //                 shape: this.currentShapeTypeId
    //             }
    //         }else if (this.state.activeTab === '2') {
    //             if ( data === "") {
    //                 toast.error('please draw a fence.');
    //                 return;
    //             }
    //             if ( !this.state.newFenceName || this.state.newFenceName.length === 0) {
    //                 toast.error('please input a fence name.');
    //                 return;
    //             }
    //             shapeObj = {
    //                 // deviceid: this.props.deviceid,
    //                 // devicename: this.props.devicename,
    //                 // devicetypeid: this.props.devicetypeid,
    //                 // devicetypename: this.props.devicetypename,
    //                 fenceid: 0, // this.props.fenceid,
    //                 fencename: this.state.newFenceName,
    //                 geodata: data,
    //                 shape: this.currentShapeTypeId
    //             }
    //         }else{
    //             return
    //         }
    //         this.props.handleOnAddNewClick(shapeObj);
    //     }
    //     // this.props.handleOnAddClick(this.state.selectedFenceOption);
    //     this.props.toggle();
    // }

    onAddClick = (e) => {
            //  
            let data = "";
            switch(this.currentShapeTypeId){
                case 0:
                    data = JSON.stringify(this.currentCircleData);
                    break;
                case 1:
                    data = JSON.stringify(this.currentRectangleData);
                    break;
                case 2:
                    data = JSON.stringify(this.currentPolygonData);
                    break;
            }
            let shapeObj;

            if ( data === "") {
                toast.error('please draw a fence.');
                return;
            }
            if ( !this.state.newFenceName || this.state.newFenceName.length === 0) {
                toast.error('please input a fence name.');
                return;
            }
            shapeObj = {
                // deviceid: this.props.deviceid,
                // devicename: this.props.devicename,
                // devicetypeid: this.props.devicetypeid,
                // devicetypename: this.props.devicetypename,
                fenceid: 0, // this.props.fenceid,
                fencename: this.state.newFenceName,
                geodata: data,
                shape: this.currentShapeTypeId
            }
            this.props.handleOnAddNewClick(shapeObj);
        
        // this.props.handleOnAddClick(this.state.selectedFenceOption);
        this.props.toggle();
    }

    onCancleClick = (e) => {
        this.props.toggle();
    }

    setSelection = (shape) => {}

    setPloygonData = ( currentShapeType, currentPolygonData) => {
        let type = 'polygon' ;
        this.isChange = true;
       
        this.currentShapeType = type;
        this.currentShapeTypeId = currentShapeType; 
        this.currentPolygonData = currentPolygonData;
        this.currentShapeData = {type: type, data: currentPolygonData};
        // this.setState({
        //     currentShapeType: type,
        //     currentShapeTypeId: currentShapeType, 
        //     currentPolygonData: currentPolygonData,
        // });
    }

    setRectangleData = ( currentShapeType, currentRectangleData) => {
        let type = 'rectangle' ;
        this.isChange = true;

        this.currentShapeType = type;
        this.currentShapeTypeId = currentShapeType;
        this.currentRectangleData = currentRectangleData;
        this.currentShapeData = {type: type, data: currentRectangleData};
        // this.setState({
        //     currentShapeType: type,
        //     currentShapeTypeId: currentShapeType, 
        //     currentRectangleData: currentRectangleData,
        // });

    }
    
    setCircleData = ( currentShapeType, currentCircleData) => {
        let type = 'circle' ;
        this.isChange = true;

        this.currentShapeType = type;
        this.currentShapeTypeId = currentShapeType;
        this.currentCircleData = currentCircleData;
        this.currentShapeData = {type: type, data: currentCircleData};
        // this.setState({
        //     currentShapeType: type,
        //     currentShapeTypeId: currentShapeType, 
        //     currentCircleData: currentCircleData,
        // });

    }

    // toggle(tab) {
    //     // alert(tab);
    //     let data = null;
    //     if (tab === '1') {
    //         // alert('yes');
    //         data = this.currentShapeData;
    //     }
    //     if (this.state.activeTab !== tab) {
    //       this.setState({
    //         activeTab: tab,
    //         selectedShape: data,
    //       });
    //     }
    //   }

    // renderTopSection(){
    //     return(
    //         <div>

    //             <Nav tabs>
    //             <NavItem>
    //                 <NavLink
    //                     className={classnames({ active: this.state.activeTab === '1' })}
    //                     onClick={() => { this.toggle('1'); }}
    //                 >
    //                     Browse
    //                 </NavLink>
    //             </NavItem>
    //             <NavItem>
    //                 <NavLink
    //                     className={classnames({ active: this.state.activeTab === '2' })}
    //                     onClick={() => { this.toggle('2'); }}
    //                 >
    //                     New
    //                 </NavLink>
    //             </NavItem>
    //             </Nav>

    //             <TabContent activeTab={this.state.activeTab}>
    //                 <TabPane tabId="1">
    //                     <Row>
    //                         <Col md={6}>
    //                             <Label   style={{ display: 'inline'}}>Fence</Label>
    //                             <Select            
    //                                 value= {this.state.selectedFenceOption}           
    //                                 // options={this.props.allFenceOptions} fenceOptions
    //                                 options={this.props.fenceOptions}
    //                                 onChange={this.handleAssetChange}
    //                             />
    //                         </Col>
    //                     </Row>
    //                 </TabPane>
    //                 <TabPane tabId="2">
    //                     <Row>
    //                         <Col md={6}>
    //                             <Label   style={{ display: 'inline'}}>New Fence</Label>
    //                             <Input type='text' value={this.state.newFenceName} onChange = {this.handleNewFenceNameChange}/>
    //                         </Col>
    //                     </Row>
    //                 </TabPane>
    //             </TabContent>

               
    //         </div>
    //     )
    // }

    renderTopSection = () => {
        return(
            <div>              
               <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Col md={2} style={{textAlign: 'right'}}>
                        <Label   style={{ display: 'inline'}}><h4>Fence Name</h4></Label>
                    </Col>
                    <Col md={10}>
                        {/* <Label   style={{ display: 'inline'}}>Fence Name</Label> */}
                        <Input type='text' value={this.state.newFenceName} onChange = {this.handleNewFenceNameChange}/>
                    </Col>
                </Row>
            </div>
        )
    }

    render(){
        return(
            <Modal animationType="slide" 
                    transparent={false} 
                    isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle} size='lg'>
                <ModalHeader toggle={this.props.toggle}>{this.state.tital}</ModalHeader>
                <ModalBody>
                    {/* <Row>
                    <Col md={10}>
                        <Label   style={{ display: 'inline'}}>Fence</Label>
                        <Select            
                            value= {this.state.selectedFenceOption}           
                            // options={this.props.allFenceOptions} fenceOptions
                            options={this.props.fenceOptions}
                            onChange={this.handleAssetChange}
                        />
                    </Col>
                    </Row> */}
                    {this.renderTopSection()}
                    <hr/>
                        <BasicGeoFenceMapSearch 
                                width='100%' 
                                height='50vh' 
                                shape = {this.state.selectedShape}
                                setSelection = {this.setSelection}
                                setPloygonData = {this.setPloygonData}
                                setRectangleData = {this.setRectangleData}
                                setCircleData = {this.setCircleData}
                        />
                    {/* <Row>
                    <Col md={10}>
                       
                    </Col>
                    </Row> */}
                   
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onAddClick} disabled={!this.state.btnDisable}>Add</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }

}

export default BasicGeofenceModal;