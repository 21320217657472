/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import withRouter from 'react-router/withRouter';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import DeviceSignUpBoard from './components/DeviceSignUpBoard';

const DeviceSigfoxSignUp = props => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Device Sign Up {props.match.params.id}</h3>
      </Col>
    </Row>
    <Row>
      <DeviceSignUpBoard deviceId = {props.match.params.id}/>
    </Row>
  </Container>
);

export default withRouter(DeviceSigfoxSignUp);
