/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Form, FormGroup, Row, Col, Label, Input, Button } from 'reactstrap';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import Select from 'react-select';
import ContactModal from "../../Contact/components/CreateNewContactModal";
import EditContactModal from '../../Contact/components/EditContactModal';

class SMSTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weekOption: [
                { value: 1, label: 'Mon' },
                { value: 2, label: 'Tue' },
                { value: 3, label: 'Wed' },
                { value: 4, label: 'Thu' },
                { value: 5, label: 'Fri' },
                { value: 6, label: 'Sat' },
                { value: 7, label: 'Sun' },
            ],
        };
    }

    // ----------------------------------------------------------------------------------
    renderContact = () => {
        let selectedContacts = [];
        if (this.props.contactList && this.props.templateObj && this.props.templateObj.contactid) {
            // let contactIds = this.props.templateObj.contactid.split(',');
            let contactIds = JSON.parse(this.props.templateObj.contactid);

            selectedContacts = this.props.contactList.filter((item, index) => (contactIds.includes(item.id)));
        }
        return (
            <Form>
                <span className="title">Receiver's List</span>
                <Row>
                    <Col md={6}>
                        <Select
                            isMulti
                            isClearable={true}
                            value={selectedContacts}
                            options={this.props.contactList}
                            getOptionLabel={({ name }) => name}
                            getOptionValue={({ id }) => id}
                            onChange={this.props.handleContactChange}
                        />
                        <br />
                    </Col>
                    {/* {this.props.userObj && hasRole(this.props.userObj,['ADMIN', 'USER', 'ENDUSER']) && */}
                    <Col md={1}>
                        <ContactModal
                            label={'Add Receiver'}
                            className={'container'}
                            upsertContact={this.props.upsertContact}
                        ></ContactModal>
                    </Col>
                    {/* } */}
                    <Col md={1}>
                        <EditContactModal
                            label={'Edit Receiver'}
                            contactList={this.props.contactList}
                            upsertContact={this.props.upsertContact}
                            deleteContact={this.props.deleteContact}
                        />
                    </Col>
                </Row>
            </Form>
        );
    };

    // --------------------------------------------------------------------------------------------------------
    // onTimeInputChange = (e) => {
    //     this.props.handleTimeInputChange(e);
    // }

    // handlePeriodsSelect = (value) => {
    //     let weekday = [];
    //     if (value){
    //         value.forEach((item, index)=>{
    //             weekday.push(item.label);
    //         });
    //     }
    //     this.props.handlePeriodsSelect(JSON.stringify(weekday));
    // }

    // renderTimePeriod = () => {
    //     let fromTime = '';
    //     let toTime = '';
    //     let selectedPeriod = [];

    //     if (this.props.templateObj){
    //         let alertPart = this.props.templateObj;
    //         fromTime = alertPart.starttime ? alertPart.starttime: '';
    //         toTime = alertPart.endtime ? alertPart.endtime: '';
    //         let weekdayArr = alertPart.weekday ? JSON.parse(alertPart.weekday) : [];
    //         weekdayArr.forEach((item, index)=>{
    //             let selectedItem = this.state.weekOption.find((day, dindex) => (day.label == item));
    //             if (selectedItem) {
    //                 selectedPeriod.push(selectedItem);
    //             }
    //         });
    //     }
    //     return(
    //         <Form>
    //             <Row>
    //             <Col md={12}>
    //                 <FormGroup row >                        
    //                         <Col md={3}>
    //                             <Label for="period">Time periods</Label>
    //                             <Select
    //                                 isMulti
    //                                 id = "period"
    //                                 name="weekday"
    //                                 options={this.state.weekOption}
    //                                 className="basic-multi-select"
    //                                 classNamePrefix="select"
    //                                 value={selectedPeriod}
    //                                 onChange={this.handlePeriodsSelect}
    //                             />
    //                         </Col>
    //                         <Col md={3}>
    //                             <Label for="fromTime">From</Label>
    //                             <Input
    //                                 type="time"
    //                                 name="starttime"
    //                                 id="fromTime"
    //                                 placeholder="time placeholder"
    //                                 // onChange={(e) => {this.onTimeInputChange(e)}} 
    //                                 onChange={(e) => {this.onInputChange(e)}} 
    //                                 value={fromTime}
    //                             />
    //                         </Col>
    //                         <Col md={3}>
    //                             <Label for="fromTime">To</Label>
    //                             <Input
    //                                 type="time"
    //                                 name="endtime"
    //                                 id="toTime"
    //                                 placeholder="time placeholder"
    //                                 // onChange={(e) => {this.onTimeInputChange(e)}} 
    //                                 onChange={(e) => {this.onInputChange(e)}} 
    //                                 value={toTime}
    //                             />
    //                         </Col>                        
    //                 </FormGroup>
    //                 </Col>
    //             </Row>
    //         </Form>
    //     );
    // }
    // ------------------------------------------------------------------------------------------

    onInputChange = (event, alertType) => {

        this.props.handleInputChange(event, alertType);
    };

    renderEmailNotification = () => {
        let title = '';
        let content = '';

        if (this.props.templateObj) {
            title = this.props.templateObj.title;
            content = this.props.templateObj.content;
        }

        return (
            <Form>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="startTime" style={{ display: 'inline', width: "100%" }} >Subject</Label>
                                <Input
                                    type="text"
                                    id="subject"
                                    name="title"
                                    width="100%"
                                    onChange={(e) => { this.onInputChange(e) }}
                                    value={title}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="emailMessage" style={{ display: 'inline', width: "100%" }} >Message</Label>
                                <Input
                                    type="textarea"
                                    id="emailMessage"
                                    name="content"
                                    width="100%"
                                    onChange={(e) => { this.onInputChange(e) }}
                                    value={content}
                                >
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }

    renderName = () => {
        let name = '';

        if (this.props.templateObj) {
            name = this.props.templateObj.name;
        }
        return (
            <Form>
                <Row>
                    <Col md={6}>
                        <Label for="emailMessage" style={{ display: 'inline', width: "100%" }} >Name</Label>
                        <Input
                            type="text"
                            id="emailMessage"
                            name="name"
                            width="100%"
                            // onChange={(e) => {this.onInputChange(e)}} 
                            value={name}
                            disabled
                        >
                        </Input>
                    </Col>
                </Row>
            </Form>
        )
    }

    // ----------------------------------------------------------------------------
    onSubmit = (e) => {
        this.props.onSubmit();
    }
    onClose = (e) => {
        this.props.toggle();
    }
    render() {
        return (
            <Card>
                <CardBody>
                    {this.renderName()}
                    <br />
                    {this.renderContact()}
                    {/* {this.renderTimePeriod()} */}
                    {this.renderEmailNotification()}
                    <hr />
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            outline color='custom'
                            type="button"
                            onClick={this.onSubmit}
                        // disabled = {!this.validateForm()} 
                        >
                            Save
                        </Button>
                        <Button
                            outline
                            color='secondary'
                            type="reset"
                            onClick={this.onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </CardBody>
            </Card>
        )
    }
}

export default SMSTemplateForm;