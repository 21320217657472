/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody, Form, CustomInput, Row, Col, Button } from 'reactstrap';
import NumericIcon from 'mdi-react/NumericIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ImageIcon from 'mdi-react/ImageIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import AttrParamAddElement from './AttrParamAddElement';
import AssetDataAddElement from './AssetDataAddElement';
import AssetAttrAddElement from './AssetAttrAddElement';

class DashboardAddmodal extends Component {
    constructor(props){
        super(props);
        this.state = {
            tital: 'Add Custom Widget',
            showModal: false,
            modalList: [
                {name: 'Attribute', type:'AttributeValue',},
                {name: 'Asset', type:'AssetElement',},
                {name: 'Attribute Chart', type:'AttributeChart',}
            ],
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            showModal: !prevState.showModal,
        }));
    }

    onIconClick = (e, layout, index) => {
        console.log('in on icon click e is',e, '  layout is',layout,'  index is',index)
        this.setState({
            selectedWidge: layout.type,
        });
    }

    renderIcon = (layout, index) => {
        let icon = null;
        switch (layout.type) {
            case 'map':
            case 'AssetMap':
                return <GoogleMapsIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'chart':
            case 'DeviceChart':
            case 'DviceUsage':
            case 'MsgCountChart':
            case 'AttributeChart':
                return <ChartBarIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'table':
            case 'AlertTable':
            case 'ActionTable':
            case 'DeviceTable':
            case 'AssetTable':
                return <TableLargeIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'AttributeValue':
                return <NumericIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
            case 'AssetElement':
                return <ImageIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
            default: 
                return null;
        }

        // return {icon};
    }

    renderCancelButton = (props) => {
        return (
            <Card>
                <CardBody>
                <hr/>
                <div style={{textAlign: 'right'}}>
                    <Button color="custom" onClick={this.toggle}>Cancel</Button>
                </div>
                </CardBody>
            </Card>
        );
    }

    renderInput = () => {
        console.log('this.state.selectedWidge is in render input is ',this.state.selectedWidge)
        console.log('props in render input is',this.props)
        switch(this.state.selectedWidge) {
            case 'AttributeValue':
                return (
                    <Fragment>
                        <hr/>
                        <AttrParamAddElement
                            templateId={this.props.templateId}
                            templateName = {this.props.templateName}
                            toggle={this.toggle}
                            parentToggle = {this.props.parentToggle}
                        >
                        </AttrParamAddElement>
                    </Fragment>
                )
            case 'AssetElement':
                return(
                    <Fragment>
                        <hr/>
                        <AssetDataAddElement
                            templateId={this.props.templateId}
                            templateName = {this.props.templateName}
                            toggle={this.toggle}
                            parentToggle = {this.props.parentToggle}
                        >
                        </AssetDataAddElement>
                    </Fragment>
                )
            case 'AttributeChart':
                return(
                    <Fragment>
                        <hr/>
                        <AssetAttrAddElement
                            templateId={this.props.templateId}
                            templateName = {this.props.templateName}
                            toggle={this.toggle}
                            parentToggle = {this.props.parentToggle}
                        >
                        </AssetAttrAddElement>
                    </Fragment>
                )
            default:
                return (<this.renderCancelButton/>);
        }
         
    }

    renderModal=()=>{
        return(
            <Modal 
                animationType="slide" 
                transparent={false} 
                isOpen={this.state.showModal}
                toggle={this.toggle} size='lg'
            >
                <ModalHeader toggle={this.props.toggle}>{this.state.tital}</ModalHeader>
                <ModalBody>
                    {/* <hr/> */}
                    <Row>
                        {/* <Col md={12}>
                            <Row style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                                <Col md={3} style={{ textAlign: 'left' }}>
                                    <Label htmlFor='react-select-2-input'>{this.props.assetName}</Label>
                                </Col>
                                <Col md={9}>
                                    <IconElement width={32} height={32} path={this.state.iconPath}/>
                                </Col>
                            </Row>
                        </Col> */}
                        {this.state.modalList && this.state.modalList.map((item, index)=>{
                            // let checked = item.display == 1 ? true : false
                            return(
                                <Col md={4} key={index}>
                                <Card>
                                    {/* <CardTitle>{item.title}</CardTitle> */}
                                    <CardBody>
                                    <Row>
                                    {/* {item.title} */}
                                    {item.name}
                                    </Row>
                                        {this.renderIcon(item, index)}
                                </CardBody>
                                </Card>
                                </Col>
                            );
                        })}
                    </Row>
                    {/* <hr/>    */}
                    {this.renderInput()}
                </ModalBody>
                
                {/* <ModalFooter>
                    <Button color="custom" onClick={this.onOkayClick} >Okay</Button>{' '}
                    <Button color="custom" onClick={this.toggle}>Close</Button>
                </ModalFooter> */}
            </Modal>
        )
    }

    handleOnClick=(e)=>{
        // this.props.parentToggle();
        console.log('add is hit and e is',e)
        this.toggle();
    }

    render(){

        console.log('props list in render is', this.props)

        return(
            <Fragment>
                <button 
                    style={{ border: 0, background: 'none', }}
                    onClick={this.handleOnClick}
                >
                    <PlusCircleOutlineIcon color='#cdcdcd' size={50}/>
                </button>
                {this.renderModal()}
            </Fragment>
        );
    }
}

export default DashboardAddmodal
