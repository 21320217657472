/* eslint-disable */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {
    loadClasses,
    loadAssets,
    addAssetAttribute,
    updateAssetAttribute,
    deleteAssetAttribute,
    postAsset,
    updateOneAsset,
} from '../../../../../redux/actions/assetActions';

import {
    loadDevice,   
    loadDeviceInfo, 
    getAssetClassAttributeAjax 
} from '../../../../../redux/actions/mapActions';

import { Col, Row, Card, CardBody, Label, Button, Input, FormGroup } from 'reactstrap';
import { useFormControl } from '@material-ui/core/FormControl';
import CameraCustomAttributes from '../CameraCustomAttributes';


const CameraEditPanel = ({assetInfo, assetClassList, cameraAttributes, addAssetAttribute, updateAssetAttribute, updateOneAsset, deleteAssetAttribute, assetList, postAsset, loadAssets, loadClasses, ...props}) => {
    const [CameraData, setCameraData] = useState('');
    useEffect(() => {
        loadClasses();
    }, []);
    useEffect(() => {
        let editCamData = assetList.filter(c=> c.assetid == props.cameraToEdit) 
         
        setCameraData(editCamData[0])
    }, [props.cameraToEdit, assetList]);

    
    return (
        <div>
            <Card>
                <CardBody>
                    {CameraData && <h3>Edit Camera {CameraData.assetid}</h3>}
                    <br/>
                    <Row>
                        {CameraData &&
                        //  <Col md={4} style={{ padding: 0 }}>
                        //     <EditableAttribute name="Name" defaultValue={CameraData.assetname} md={12}
                        //         onSave={newVal => {
                        //         // console.log(newVal)
                        //         updateCameraOnStore({assetid: CameraData.assetid, data: { assetname: newVal } })
                        //         }}
                        //     />
                        //     <EditableAttribute name="Description" inputStyles={{ height: 100, paddingTop: 8 }} md={12}
                        //         type='textarea'
                        //         defaultValue={CameraData.description} exitOnEnterKeyPress={false}
                        //         onSave={newVal => updateCameraOnStore({ assetid: CameraData.assetid, data: { description: newVal } })}
                        //     />
                        // </Col>
                        <CameraCustomAttributes cameraid={CameraData.assetid}
                            attributes={CameraData.assetattributes}
                            attrmetas={cameraAttributes}
                        />
                        } 

                    </Row>
                    <Row>
                        <Col className='text-right'> 
                            <Button color="custom" style={{ marginBottom: 0 }} onClick={() => props.closePanel()}>Close</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

const mapToStateProps = (state) => ({
    assetList: state.asset.assetList,
    cameraAttributes: state.asset.assetClassList.find(c => c.name == 'Camera_Base'),
})

export default connect (mapToStateProps, {
    addAssetAttribute,
    updateAssetAttribute,
    deleteAssetAttribute,
    postAsset,
    updateOneAsset,
    loadDeviceInfo,
    loadClasses
}) (CameraEditPanel);