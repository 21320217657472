/* eslint-disable */
import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import {SingleSelection, MultiSelectionComponent} from '../../Asset/components/SelectionComponents';
import { toast } from "react-toastify";

import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import 'bootstrap/scss/bootstrap';
import { DateRangePicker } from 'react-dates';
import {getCurrentUser} from "../../../factories/auth";

class AssetFilter extends Component{
    constructor(props){
        super(props);
        this.state={
            startDateMoment: null,
            endDateMoment: null,
        }
        this.userObj = getCurrentUser();
        if( this.userObj.detail==null){
            return;
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color:  "black",
                '&:active': {backgroundColor: this.userObj.detail.appButtonColorHover},
                backgroundColor:  state.isFocused ? this.userObj.detail.appButtonColorHover: "white",
            }),
            control: (base,selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': {borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover: base.borderColor}
            }),
            indicatorsContainer:  (base,selectState)  => ({
                ...base,
                padding: "0",
            }),
        }

    }

    componentDidMount(){}

    componentWillReceiveProps(nextProps){
         
        if (nextProps.assetClass){
             
             
        }
    }

    componentWillUnmount(){
         
        if (this.interval){
            clearInterval(this.interval);
        }
    }
    // ------------------------------------------------------

    handleAssetClassChange = selectedOption => {
         
        let assets = [];
        if (selectedOption && selectedOption.length > 0){
            assets = selectedOption.map((item, index) => ({label: item.assetclassname, options:item.assets }));
             
            this.props.getThemeList(selectedOption[0].assetclassid);
            this.props.clearAssetClassAttribute();
        } else {
            // theme list , attri list
             
            assets = [];
            this.props.getThemeList(0);
            this.props.clearAssetClassAttribute();
        }
        this.setState(prevState => {           
            return ({
                selectedAssetClass: selectedOption,
                selectedAsset: null,
                selectedTheme: null,
                selectedAttri: null,
                startTime: '',
                endTime: '',
                startDateMoment: null,
                endDateMoment: null,
                assets: assets,
            })            
        }, this.onRefreshClick);
    };

    handleAssetChange = selectedAsset => {
         
        this.setState({
            selectedAsset: selectedAsset,
            startTime: '',
            endTime: '',
            startDateMoment: null,
            endDateMoment: null,
        }, this.onRefreshClick);
    }

    onTimeChange = event => {
        let key = event.target.name;
        let value = event.target.value;
         
        this.setState({
            [key]: value
        })
    }

    handleDateRangeChange = ({ startDate, endDate }) => {
        //  
        //  
        this.setState({
            startDateMoment: startDate,
            endDateMoment: endDate
        }, this.onRefreshClick)
    }

    handleThemeChange = selectedTheme => {
         
        if (this.state.selectedAssetClass && this.state.selectedAssetClass.length > 0 && selectedTheme){
            this.props.getAssetClassAttribute({assetclassid: this.state.selectedAssetClass[0].assetclassid, themeid: selectedTheme.themeid});
            this.loadAssetData(this.state.selectedAssetClass, this.state.selectedAsset, this.state.startTime, this.state.endTime, selectedTheme);
        }else if (!selectedTheme){
            this.props.clearAssetClassAttribute()
            this.loadAssetData(this.state.selectedAssetClass, this.state.selectedAsset, this.state.startTime, this.state.endTime, null);
        }
        this.setState({
            selectedTheme: selectedTheme,
            selectedAttri: null,
        });
    }

    handleAttriChange = selectedAttri => {
         
        let attriId = null; 
        if (selectedAttri) {
            attriId = selectedAttri.attributeid; 
        }
        this.props.filterGroupAssetDataByAttribute(attriId);
        this.setState({
            selectedAttri: selectedAttri,            
        });
    }

    dataTimeValidation = (startTime, endTime) =>{
        let reVal = true;

        if(startTime && startTime.length > 0 && endTime && endTime.length > 0){
            let startdate = new Date(startTime).toISOString().split('.')[0];        
            let enddate = new Date(endTime).toISOString().split('.')[0];   
            reVal = enddate >= startdate;

        }else if((!startTime || startTime.length === 0) && (!endTime || endTime.length === 0) ) {
            reVal = true;
        }else{
            reVal = false;
        }

        return reVal;
    }

    dataMomentValidation = (startMoment, endMoment) => {
        let reVal = false;
        if (startMoment && endMoment){
            reVal = endMoment.isAfter(startMoment);
            if (!reVal) {
                toast.error('End date should be later than start date.');
            }
        }else if (!startMoment && !endMoment){
            reVal = true;
        }

        return reVal;
    }

    onRefreshClick = () => {
         // let params = {devicetype:this.props.currentDeivecType};
        // params = Object.assign(params, {assetid: this.state.currentAssetId});
        // let params = {assetid: this.state.currentAssetId, assetclassid: this.props.currentAssetClassId};
        let params = {themeid:"", starttime: "", endtime: ""};
        let entitygroup = [];
        let entity = '';
        if (this.state.selectedAssetClass && this.state.selectedAssetClass.length > 0){
            entitygroup = this.state.selectedAssetClass.map((item, index)=>(item.assetclassid))
        }else{
            this.props.clearGroupAssetData();
            return
        }
        // params = {...params, entitygroup};

        if (this.state.selectedAsset){
            entity = this.state.selectedAsset.assetid;
            entitygroup = [];            
        }
        params = {...params, entitygroup, entity};

        if (this.state.selectedTheme){
            let themeid = this.state.selectedTheme.themeid;
            params = {...params, themeid};
        }

        // if (!this.dataTimeValidation(this.state.startTime, this.state.endTime)){
        //     toast.error('End date should be later than start date.');
        //     this.setState(prevState => {           
        //         return ({
        //             startTime: '',
        //             endTime: '',
        //         })            
        //     });
        // }

        if (!this.dataMomentValidation(this.state.startDateMoment, this.state.endDateMoment)){
            // toast.error('End date should be later than start date.');
            // this.setState(prevState => {           
            //     return ({
            //         startDateMoment: null,
            //         endDateMoment: null,
            //     })            
            // });
            return;
        }
  
        let latest = true;
        // if (this.state.startTime && this.state.startTime.length > 0 ){
        //     let date = new Date(this.state.startTime).toISOString().split('.')[0];           
        //     params = {...params, starttime: date};
        //     latest = false;
        // }
        // if (this.state.endTime && this.state.endTime.length > 0 ){
        //     let date = new Date(this.state.endTime).toISOString().split('.')[0];       
        //     params = {...params, endtime: date};
        //     latest = false;
        // }

        if (this.state.startDateMoment){
            let date = this.state.startDateMoment.format('YYYY-MM-DDTHH:mm');       
            console.log("START DATE", Date.parse(date))    
            params = {...params, starttime: date};
            latest = false;
        }
        if (this.state.endDateMoment ){
            let date = this.state.endDateMoment.format('YYYY-MM-DDTHH:mm');     
            params = {...params, endtime: date};
            latest = false;
        }

        if(latest){
            params = {...params, latest: "1"};
        }else{
            params = {...params, latest: "0"};
        }
         
        // this.props.loadDivceMapData(params);
        this.props.getGroupAssetData(params);
        if (this.interval){
            clearInterval(this.interval);
        }
        // this.interval = setInterval(() => { 
        // this.props.getAssetsData(params);
        // this.props.bindFilterAttribute('');
    }

    loadAssetData = (selectedAssetClass, selectedAsset, startTime, endTime, selectedTheme) => {
        let params = {themeid:"", starttime: "", endtime: ""};
        let entitygroup = [];
        let entity = '';
        if (selectedAssetClass && selectedAssetClass.length > 0){
            entitygroup = selectedAssetClass.map((item, index)=>(item.assetclassid))
        }else{
            return;
        }
        // params = {...params, entitygroup};

        if (selectedAsset){
            entity = selectedAsset.assetid;
            entitygroup = [];            
        }
        params = {...params, entitygroup, entity};

        if (selectedTheme){
            let themeid = selectedTheme.themeid;
            params = {...params, themeid};
        }

        // if (!this.dataTimeValidation(this.state.startTime, this.state.endTime)){
        //     toast.error('End date should be later than start date.');
        //     this.setState(prevState => {           
        //         return ({
        //             startTime: '',
        //             endTime: '',
        //         })            
        //     });
        // }

        if (!this.dataMomentValidation(this.state.startDateMoment, this.state.endDateMoment)){
            // toast.error('End date should be later than start date.');
            // this.setState(prevState => {           
            //     return ({
            //         startDateMoment: null,
            //         endDateMoment: null,
            //     })            
            // });
            return;
        }
  
        let latest = true;
        // if (startTime && startTime.length > 0 ){
        //     let date = new Date(startTime).toISOString().split('.')[0];           
        //     params = {...params, starttime: date};
        //     latest = false;
        // }
        // if (endTime && endTime.length > 0 ){
        //     let date = new Date(endTime).toISOString().split('.')[0];       
        //     params = {...params, endtime: date};
        //     latest = false;
        // }
        if (this.state.startDateMoment){
            let date = this.state.startDateMoment.format('YYYY-MM-DDTHH:mm');
            console.log("START DATE", Date.parse(date))           
            params = {...params, starttime: date};
            latest = false;
        }
        if (this.state.endDateMoment ){
            let date = this.state.endDateMoment.format('YYYY-MM-DDTHH:mm');     
            params = {...params, endtime: date};
            latest = false;
        }

        if(latest){
            params = {...params, latest: "1"};
        }else{
            params = {...params, latest: "0"};
        }
         
        this.props.getGroupAssetData(params);
    }

    render(){
        let attrData = [];
        if (this.props.assetClassAttr){
             
            attrData =  this.props.assetClassAttr.map((assetclass, aindex) => {
                            return ({
                                label: assetclass.assetclassname,
                                options: assetclass.atrributes
                            })
                        });
        }
        let refrashDisabled = !this.state.selectedAssetClass || this.state.selectedAssetClass.length === 0;
        return (
            <div style={{ width: "100%"}}>
            <Form md={12}>
                <Row form>
                    <Col md={3}>
                        <FormGroup row>
                            <Col md={12}>
                                <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                                {/* <Input type="select" id="asset" name="asset" width="100%" value={this.props.currentAssetClassId} onChange={this.onAssetClassChange} >                                    
                                    {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))}
                                    <option value={0}>NONE</option> 
                                    {this.props.assetClasses && this.props.assetClasses.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                                </Input> */}
                                <Select isMulti
                                    options = {this.props.assetClass}
                                    getOptionLabel={({ assetclassname }) => assetclassname}
                                    getOptionValue={({ assetclassid }) => assetclassid}
                                    onChange={this.handleAssetClassChange}
                                    styles={this.state.customStyles}
                                    // value={}
                                    // defaultValue={}
                                    //onChange = {}

                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup row>                            
                            <Col md={12}>
                                <Label for="deviceType" style={{display: 'inline', width: "100%"}} ><h4><b>Asset</b></h4></Label>
                                {/* <Input type="select" id="deviceType" name="deviceType" width="100%" value={this.props.currentDeviceTypeId} onChange={this.onDeviceTypeChange} disabled={this.state.deviceTypeDisable} >      
                                    
                                    {this.state.showEmpgy && <option value={'0'}> No Selection </option>}  
                                    {this.props.types && this.props.types.map((type,i) => (<option key={type.id} value={type.id}>{type.name}</option>))}
                                </Input> */}
                                <Select 
                                    isClearable
                                    isSearchable
                                    options = {this.state.assets}
                                    getOptionLabel={({ assetname }) => assetname}
                                    getOptionValue={({ assetid }) => assetid}
                                    value={this.state.selectedAsset}
                                    // defaultValue={}
                                    onChange = {this.handleAssetChange}

                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    {/* <Col md={2}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="startTime"  style={{display: 'inline', width: "100%"}} ><h4><b>From</b></h4></Label>
                                <Input 
                                    type="date" 
                                    id="startTime" 
                                    name="startTime" 
                                    width="100%" 
                                    value={this.state.startTime} 
                                    onChange={this.onTimeChange}
                                    disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
                                >
                                </Input>     
                            </Col>                   
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="endTime"  style={{display: 'inline', width: "100%"}} ><h4><b>To</b></h4></Label>
                                <Input 
                                    type="date" 
                                    id="endTime" 
                                    name="endTime" 
                                    width="100%" 
                                    value={this.state.endTime} 
                                    onChange={this.onTimeChange}
                                    disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
                                >
                                </Input>  
                            </Col>                      
                        </FormGroup>
                    </Col> */}
                    <Col md={3}>
                    <FormGroup row >
                            <Col md={12}>
                                <Label for="endTime"  style={{display: 'inline', width: "100%"}} ><h4><b>From ~ To</b></h4></Label>
                                <DateRangePicker
                                    isOutsideRange={() => false}
                                    startDate={this.state.startDateMoment} // momentPropTypes.momentObj or null,
                                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                    endDate={this.state.endDateMoment} // momentPropTypes.momentObj or null,
                                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                    onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                    showClearDates = {true}
                                    small = {true}
                                    block = {true}
                                    disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
                                    />
                            </Col>                      
                        </FormGroup>
                    </Col>
                    {/* <Col md={2} style={{ textAlign: 'center', justifyContent: 'center',alignItems: 'center' }}>
                        <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                        <Button 
                            variant="primary" 
                            color="custom" 
                            type="button" 
                            onClick={this.onRefreshClick} 
                            disabled = {this.props.loading || refrashDisabled}
                        >
                            {this.props.loading && 
                                <Spinner 
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> 
                            }
                            Refresh
                        </Button>
                    </Col> */}
                {/* </Row>
                <Row form> */}
                    <Col md={2}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="theme" style={{display: 'inline', width: "100%"}}><h4><b>Theme</b></h4></Label>
                                {/* <Input 
                                    type="select" 
                                    id="theme" 
                                    name="theme" 
                                    width="100%" 
                                    // value={this.props.currentThemeId} 
                                    // onChange={this.onThemeChange} 
                                    // disabled={this.state.themeDisable}
                                >
                                    <option value={0}>NONE</option>
                                    {this.props.themes && this.props.themes.map((theme, i) => (<option key={theme.themeid} value={theme.themeid}>{theme.themename}</option>))}
                                    {this.props.themeList && this.props.themeList.map((theme, i) => (<option key={theme.themeid} value={theme.themeid}>{theme.themename}</option>))}
                                </Input> */}
                                <Select 
                                    isClearable
                                    isSearchable
                                    options = {this.props.themes}
                                    getOptionLabel={({ themename }) => themename}
                                    getOptionValue={({ themeid }) => themeid}
                                    value={this.state.selectedTheme}
                                    // defaultValue={}
                                    onChange = {this.handleThemeChange}
                                    isDisabled = {this.state.selectedAssetClass && this.state.selectedAssetClass.length > 1}

                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup row >
                            <Col md={12}>
                                <Label for="attribute" style={{display: 'inline', width: "100%"}}><h4><b>Attribute</b></h4></Label>
                                {/* <Input type="select" id="attribute" name="attribute" width="100%" value={this.props.currentAttributeId} onChange={this.onAttributeChange} >
                                    <option value={0}>All</option>
                                    { attrData.map(
                                        (item,i) =>(item.name != 'Lat' && item.name != 'Lng' && <option key={i} value={item.id}>{item.name}</option>)
                                    )}
                                </Input> */}
                                <Select 
                                    isClearable
                                    isSearchable
                                    options = {attrData}
                                    getOptionLabel={({ displayname }) => displayname}
                                    getOptionValue={({ attributeid }) => attributeid}
                                    value={this.state.selectedAttri}
                                    // defaultValue={}
                                    onChange = {this.handleAttriChange}
                                    isDisabled = {!this.state.selectedTheme }
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
        </Form>
        </div>
        );
    }
}

export default AssetFilter