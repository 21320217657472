/* eslint-disable */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import ReportsBoard from './components/ReportsBoard';

const ReportsPage = () => (
  <Container className="dashboard">
    <ReportsBoard />
  </Container>
);

export default ReportsPage;
