/* eslint-disable */
import React, {useState, useEffect} from 'react'
import './SmartBuilding.css'
import {renderSpinner} from './PeopleCountBoard'
import {groupByArray} from '../../../../factories/utils'
import { Row, Card, CardBody} from 'reactstrap';


function PeopleCountInfo({localData, handleStayCountChange, handleCamListChange, camNum}) {
    const [EntranceCount, setEntranceCount] = useState(null);
    const [ExitCount, setExitCount] = useState(null);
    const [Stay, setStay] = useState(null)
    const [CamNum, setCamNum] = useState(null)
    

    useEffect(() => {
        if(localData && localData.length > 0) {
            let entranceTotal = 0
            let exitTotal = 0
            let camNum = 0
            //loop through localData, get sum of entrance and exit count of each camera in locaData
            localData.forEach(camData => {
                if(camData.data.length > 0){
                    let lastData = camData.data[camData.data.length - 1]
                    entranceTotal += lastData.entranceCount
                    exitTotal += lastData.exitCount
                    camNum+=1
                }
            });
            setEntranceCount(entranceTotal)
            setExitCount(exitTotal)
            setStay(entranceTotal - exitTotal < 0 ? 0 : entranceTotal - exitTotal)
            setCamNum(camNum)
        }
    }, [localData]);

    //Grouping array by key
    if(!localData || localData.length === 0){
        return(
            <>
                <Card>
                    <CardBody>
                        <Row>
                            <span className="noData"> There is no data for the specified date at this location</span>
                        </Row>
                    </CardBody>
                </Card>
            </>
        )
    }else{
        return (
            <>
                <div className="box" >
                    <p>Entrance Count</p>
                    <p className="stat">{EntranceCount? EntranceCount: 0}</p>

                </div>
                <div className="box">
                    <p>NO. of People at Premises</p>
                    {/* {this.props.loading?this.renderSpinner():null} */}
                    {<p className="stat total">{Stay ? Stay : 0}</p>}
                </div>
                <div className="box">
                    <p>NO. of Cameras Used</p>
                    {/* {this.props.loading?this.renderSpinner():null} */}
                    <p className="stat">{CamNum ? CamNum : 'N/A'}</p>
                </div>
            </>
        )
    }
}

export default PeopleCountInfo

