/* eslint-disable */
import { IPC_PASSWORD } from '../../../../constants/appConstants';
import xml2js from 'xml2js'
import CryptoJS from 'crypto-js'
import axios from 'axios'
import {CAMERA_PROXY} from '../../../../constants/appConstants';

const xmlHeader = "<?xml version=\"1.0\" encoding=\"utf-8\" ?>";
// export const camIP = "192.168.2.57"; //设备IP


export const xmlToJson = (xml) => {
    let result = null;
    xml2js.parseString(xml, (err, data) => {
      if (!err) {
        result = data;
      } else {
        console.error(err);
        return null
      }
    });
    return result;
}


//This function processes XML data return from camera request
export const extractFromResponseJSONData = (fields, xmlData) =>{
    let returnData = {}
    let JSONData = xmlData ? xmlToJson(xmlData) : null
    let succeed = true
    if(JSONData && JSONData.config){
        let config = JSONData.config
        // console.log("JSONDATA config ", config)
        if(fields && fields.length > 0){
            fields.forEach((field, index)=>{
                // console.log("Getting ", field)
                //special case for Port field
                if(field === 'port'){
                    if(config[field]){
                        let portData = config[field]
                        if(portData && portData[0].wsPort){
                            let wsPortArr = portData[0].wsPort
                            if(wsPortArr && wsPortArr.length > 0){
                                let wsPortData = wsPortArr[0]._
                                // let wsPortDataObj = {field: wsPortData}
                                sessionStorage.setItem([field], wsPortData)
                                returnData = {...returnData, [field]: wsPortData}
                            }else{
                                console.error("Failed to get ws port from camera");
                                succeed = false
                            } 
                        }else{
                            succeed = false
                        }
                    }else{
                        succeed = false
                    }
                }else{
                    let fieldData = config[field]
                    if(fieldData && fieldData[0]){
                        if(fieldData[0]._){
                            fieldData = fieldData[0]._
                            // let fieldObject = {field: fieldData}
                            sessionStorage.setItem([field],fieldData)
                            returnData = {...returnData, [field]: fieldData}
                        }else{
                            // console.log("Could not find, ", field, " in response data _")
                            succeed = false
                        }
                    }else{
                        // console.log("Could not find, ", field, " in response data 0")
                        succeed = false    
                    }
                }
                // console.log("Return data ", returnData)
            })
        }else{
            console.error("Target fields array is empty")
            succeed = false
        }
    }else{
        succeed = false
    }
    if(!succeed){
        console.error("Unchecked XML format. xmlToJson function need to add more cases")
    }

    return returnData
}

//Get IPC current user session info
export const getUserInfo = async (camIP, callback) => {
    // console.log("Get user info")
    let userInfo = [];
    let url = `${CAMERA_PROXY}/GetSessionInformation?ip=${camIP}`
    try {
        let res = await asyncAxiosXMLHttpPost(url, "", true, null)
        if(res && res.status == 200){
            // let data = xmlToJson(res.data)
            // console.log(data)
            let {userName, loginInfomation, password} = extractFromResponseJSONData(["userName","loginInfomation","password"],res.data);
            // console.log(userName, loginInfomation, password)
            userInfo.push(userName)
            if(loginInfomation){
                userInfo.push(aesDecryptBase64(loginInfomation, sessionStorage.getItem("sessionId")))
            }else{
                userInfo.push(password)
            }
            // console.log(userInfo);
            callback(userInfo)
        }
    } catch (ex) {
        console.error(ex)
    }
}

/**
 * AES解密Base64格式算法
 * @param {string} str string to be decrypted
 * @param {string} key secret key
 */
export const aesDecryptBase64 = (str, key) => {
    let secret = key + "0000000000000000";
    let aesKey = CryptoJS.enc.Utf8.parse(secret.slice(0, 16));
    let encryptedHexStr = CryptoJS.enc.Base64.parse(str);
    let encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decryptedData = CryptoJS.AES.decrypt(encryptedBase64Str, aesKey, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.ZeroPadding
    });
    let decryptedStr = decryptedData.toString(CryptoJS.enc.Utf8);
    return decryptedStr.slice(0, 32);
}

//Login to IPC using xml request
export const loginCameraXml = async (camIP, macAddress) => {
    let curTime = new Date()
    let pcTime = stringFormat(curTime.getUTCFullYear(),4) + "-" +
        stringFormat(curTime.getUTCMonth() + 1, 2) + "-" +
        stringFormat(curTime.getUTCDate(), 2) + " " +
        stringFormat(curTime.getUTCHours(), 2) + ":" +
        stringFormat(curTime.getUTCMinutes(), 2) + ":" +
        stringFormat(curTime.getUTCSeconds(), 2);
        //no MAC address
    let resToken, resSessionId, resNonce, resPort;
    let sendData = "<macInfo>" + "<address type=\"string\"><![CDATA["+ macAddress + "]]></address>" + "</macInfo>" +
    "<checkInfo>" + "<pcTime type=\"string\"><![CDATA[" + pcTime + "]]></pcTime>" + "</checkInfo>" +
    "<hostName type=\"string\"><![CDATA[]]></hostName>";
    if(!sessionStorage.getItem("token")) {
        sendData += "<Authentication type=\"authenticationMode\">Token</Authentication>";
    }else{
        sendData += "<username type=\"string\"><![CDATA[admin]]></username>";        
        let password = CryptoJS.SHA512(IPC_PASSWORD + sessionStorage.getItem("nonce"));
        sendData += "<password type=\"string\"><![CDATA[" + password + "]]></password>";
    }
    try {
        let url = `${CAMERA_PROXY}/DoLogin?ip=${camIP}`
        let res = await asyncAxiosXMLHttpPost(url, sendData, true, null)
        // console.log(res)
        if(res.status==401){
            if(res.data){
                // console.log("RESPONSE DATA FAILED, ", res.data)
                let {sessionId,token,nonce} = extractFromResponseJSONData(["sessionId","token","nonce"],res.data)
                resNonce = nonce
                let recursiveRes = await loginCameraXml(camIP, macAddress);
                // console.log("Recursive login, ", recursiveRes)
                resToken = recursiveRes.resToken
                resSessionId = recursiveRes.resSessionId
                resPort = recursiveRes.resPort

            }
        }else if(res.status == 200){
            if(res.data){
                // console.log("RESPONSE DATA SUCCEED", res.data)
                let {sessionId,token} = extractFromResponseJSONData(["sessionId","token"] ,res.data)
                resSessionId = sessionId
                resToken = token
                
                resPort = await getPortConfigCameraXml(camIP)
                // console.log("RES PORT", resPort)
            }
        }
    } catch (ex) {
        console.error(ex);
    }

    return {resNonce, resPort, resSessionId, resToken}
}

export const getPortConfigCameraXml = async(camIP) =>{
    let wsPort
    try{
        let sendData = ""
        let url = `${CAMERA_PROXY}/GetPortConfig?ip=${camIP}`
        let res = await asyncAxiosXMLHttpPost(url, sendData, true, null).then((res)=>{
            if(res.status==200 && res.data){
                let jsonData = xmlToJson(res.data)
                // console.log("Get port  JSON", jsonData)
                wsPort = extractFromResponseJSONData(["port"], res.data)
                
            }
        })
    }catch(err){
        console.error("Error getting camera ws Port")
    }
    return wsPort ? wsPort.port : wsPort
}


//Customized axios http request sender for IPC
export const asyncAxiosXMLHttpPost = async(url,data, addSessionIdAndToken, timeout)=>{
    let config = {
        headers: {'Content-Type': 'text/xml'},
        validateStatus: null
    };
    data = xmlHeader + data
    if(addSessionIdAndToken){
        if (sessionStorage.getItem("authType") === "Token" && sessionStorage.getItem("token")) {
            data += "<token type=\"string\"><![CDATA[" + sessionStorage.getItem("token") + "]]></token>";
        }
        if (sessionStorage.getItem("sessionId")) {
            data += "<sessionId type=\"string\"><![CDATA[" + sessionStorage.getItem("sessionId") + "]]></sessionId>";
        }
    }
    let uninterceptedAxios = axios.create({
        timeout: timeout ? timeout : null
    }); 
    let res = await uninterceptedAxios.post(url, data, config)
    // console.log("AXIOS RES", res)
    if(res.status==401){
        sessionStorage.setItem("authType", "Token")
    }
    return res
}


export const stringFormat = (val, len, format) => {
    var format =  format || "000000000000000000";
    if (typeof(val) != "string")
        val = String(val);
    return (format.substring(0, len - val.length)) + val;
}