/* eslint-disable */
import React, { Component, Fragment } from 'react';
// import {connect } from 'react-redux';
// import {bindActionCreators } from 'redux';
import { Card, CardBody, Col, Row, TabContent, TabPane, Nav, NavItem, NavLink, Button, Container, Input } from 'reactstrap';
import classnames from 'classnames';
import { ExitToAppIcon } from 'mdi-react';
import { ExitTextButton } from '../../../shared/components/Button/IconButton';

class ConsumerNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    goToPlan = () => {
        this.setState({
            activeTab: '5',
        })
    }

    tabToggle = (tab) => {
        if (this.state.activeTab !== tab) {

            this.setState({
                activeTab: tab
            });
        }
    }


    renderTabs = () => {
        return (
            <Fragment>
                <div align="right">
                    <ExitTextButton label={''} onClick={(e) => { this.props.onExitClient() }} />{' '}
                </div>
                <Nav tabs>
                    {this.props.tabObjs && this.props.tabObjs.map((item, index) => (
                        <TabLabel
                            tabId={item.tabId}
                            activeTab={this.state.activeTab}
                            disabled={false}
                            tabToggle={this.tabToggle}
                        >
                            {item.label}
                        </TabLabel>
                    ))}
                </Nav>

                <TabContent
                    activeTab={this.state.activeTab}
                >
                    {this.props.tabObjs && this.props.tabObjs.map((item, index) => (
                        <TabPane tabId={item.tabId}>
                            {item.renderTab({ goToPlan: this.goToPlan, activeTab: this.state.activeTab })}
                        </TabPane>
                    ))}
                </TabContent>
            </Fragment>
        )
    }

    render() {
        return (

            <Card>
                <CardBody>
                    {/* {this.renderTab()} */}
                    {this.renderTabs()}

                </CardBody>
            </Card>

        );
    }
}

const TabLabel = (props) => {
    return (
        <NavItem>
            <NavLink
                className={classnames({ active: props.activeTab === props.tabId })}
                onClick={() => { props.tabToggle(props.tabId); }}
            // disabled={props.disabled}
            >
                <h4><b>{props.children}</b></h4>
            </NavLink>
        </NavItem>
    )
}

export default ConsumerNavigation;