/* eslint-disable */
import axios from 'axios';
import { 
    RULE_LOAD_DEVICE_TYPE_SUCCEED,
    RULE_LOAD_DEVICE_TYPE_FAILED,
    RULE_LOAD_DEVICE_SUCCEED,
    RULE_LOAD_DEVICE_FAILED,
    RULE_GET_RULE_SUCCEED,
    RULE_GET_RULE_FAILED,
    RULE_UPSERT_RULE_SUCCEED,
    RULE_UPSERT_RULE_FAILED,
    RULE_DELETE_RULE_SUCCEED,
    RULE_DELETE_RULE_FAILED,
} from '../../constants/actionTypes';

const initState = {    
    currentDeviceTypeId: '',
    currentDeviceId:'',
    currentDeviceTypeList:[],
    currentDeviceList:[],
    
    currentAssetList: [],
    currentAssetGroupList: [],
    
    error: 0,
    message: '',
    type: '',
    rules: [],
}

export default function(state = initState, action){
    //  
    //  
    //  
    switch(action.type){
        case RULE_LOAD_DEVICE_TYPE_SUCCEED:           
            return {
                ...state,
                currentDeviceTypeList: action.payload,
                error: 0,
                message: '',
                type: action.type
            }
        case RULE_LOAD_DEVICE_TYPE_FAILED:
            return{
                ...state,
                error: 1,
                message: action.payload,
                type: action.type
            }
        case RULE_LOAD_DEVICE_SUCCEED:
             
            console.log(action.payload)
            return {
                ...state,
                currentDeviceList: action.payload,
                error: 0,
                message: '',
                type: action.type
            }
        case RULE_LOAD_DEVICE_FAILED:
            return{
                ...state,
                error: 2,
                message: action.payload,
                type: action.type
            }
        case RULE_GET_RULE_SUCCEED:
            return{
                ...state,
                rules: action.payload,
                error: 0, 
                meessage: '', 
                type: action.type
            }
        case RULE_GET_RULE_FAILED:
            return{
                ...state,
                rules: [],
                error: 1, 
                meessage: action.error, 
                type: action.type
            }

        case RULE_UPSERT_RULE_SUCCEED:
            return{
                ...state,
                rules: action.payload,
                error: 0, 
                meessage: '', 
                type: action.type
            }
        case RULE_UPSERT_RULE_FAILED:
            return{
                ...state,
                rules: [],
                error: 1, 
                meessage: action.error, 
                type: action.type
            }

        case RULE_DELETE_RULE_SUCCEED:
            return{
                ...state,
                rules: action.payload,
                error: 0, 
                meessage: '', 
                type: action.type
            }
        case RULE_DELETE_RULE_FAILED:
            return{
                ...state,
                rules: [],
                error: 1, 
                meessage: action.error, 
                type: action.type
            }
        default:
            return {...state};
    }
}