/* eslint-disable */
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row, Button, Input, Label } from 'reactstrap';
import { sortCaret } from '../../../../shared/components/Table/tableElements';
import styles from './SelectQuantity.module.css';


class SelectQuantity extends Component {
    
    constructor(props) {
        super(props);
    }

    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 365) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }

    SetQuantityButton =  (cell, row, enumObject, index) => {
        let quantity = "";
        if(this.props.deviceQuantityRequest && this.props.deviceQuantityRequest.size>0) {
            const requests = Array.from(this.props.deviceQuantityRequest.values());
            const request = requests.filter((req) =>req.id == row.itemId)
            // console.log("request:", request);
            if(request!= null && request.length>0)
                quantity = request[0].quantity;
        } 
        // console.log("SetQuantityButton", cell, row, enumObject, index);
        return (
            <div>
            <Input 
                name="quantity" 
                id="quantity" 
                placeholder="" 
                className="form-control"
                value={quantity}
                onChange={(e)=>this.props.onQuantityChange(e, cell, row, enumObject, index)}
            />
            </div>
        )
    }

    render() {
        const options = {
            defaultSortOrder: 'asc',
        };

        return (
            <Row>
                <Col md={12}>
                    <BootstrapTable
                        data={this.props.SIMTypeList}
                        tableHeaderClass='header_white'
                        bordered={false}
                        height='100%'
                        options={options}
                    >
                        <TableHeaderColumn 
                            isKey={true} row='0' rowSpan='1' 
                            dataField='itemName' dataAlign='center' dataSort
                            filter={{ type: 'TextFilter', placeholder: 'Name' }} 
                            caretRender={sortCaret}
                            className={styles.simpurchaseth}
                            width='30%'>Name</TableHeaderColumn>
                        <TableHeaderColumn 
                            row='0' rowSpan='1' 
                            dataField='itemDescription' dataAlign='center' dataSort
                            filter={{ type: 'TextFilter', placeholder: 'Description' }} 
                            caretRender={sortCaret}
                            className={styles.simpurchaseth}
                            width='30%'>Description</TableHeaderColumn>
                        <TableHeaderColumn 
                            row='0' rowSpan='1' 
                            dataField='unitPrice' dataAlign='center' dataSort
                            dataFormat={this.priceFormatter.bind(this)}  
                            filter={{ type: 'NumberFilter', delay: 1000 }}
                            caretRender={sortCaret}
                            className={styles.simpurchaseth}
                            width='40%'>Price</TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='quantity'
                            dataAlign="center"
                            dataFormat={this.SetQuantityButton.bind(this)}
                            width='20%'
                            className={styles.simpurchaseth}
                        >
                            Quantity
                        </TableHeaderColumn>

                    </BootstrapTable>
    
                </Col>
                
                <Col md={12}><br/></Col>
            
                <Col md={12}>
                    <Button outline color='secondary' type="reset" 
                    onClick={this.props.onClose}
                    >
                        Cancel
                    </Button>
                    <Button outline color='custom' type="button"
                        onClick={() => { this.props.handleNext() }} 
                        disabled={!this.props.validateSelectQuantity()}
                    >
                        Continue
                    </Button>
                </Col>
                
            </Row>
        )
    }
}
export default SelectQuantity;