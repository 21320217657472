/* eslint-disable */
import { ConsoleIcon } from "mdi-react";
import React from "react";
import { Col, Row, Button, Input, Label } from 'reactstrap';

const LogicCondition = (props) => {
    var planList = []
    if (props.ServicePlanList!=null && props.ServicePlanList.length > 0){
        planList = props.ServicePlanList.filter((plan)=>plan.billCycle === 30);
    }
    return (
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={6}>
                        <Label
                            for="resellerCode"
                        >
                            <span style={{ fontWeight: "bold" }}>Client</span>
                        </Label>
                        <Input
                            type='select'
                            name='resellerId'
                            id='resellerId'
                            className="form-control"
                            disabled = {!props.isAdmin}
                            onChange={props.onInputChange}
                            value={props.resellerId}
                        >
                             <option key={-1} value={0}>All Clients</option>
                            {props.resellerList.map((reseller, index) =>
                                <option key={index} value={reseller.id}>{reseller.resellerName}</option>
                            )}
                        </Input>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={4}>
                        <Label
                            for="planId"
                        >
                            <span style={{ fontWeight: "bold" }}>Service</span>
                        </Label>
                        <Input
                            type='select'
                            name='planId'
                            id='planId'
                            className="form-control"
                            disabled={!(props.resellerId > 0)}
                            onChange={props.onInputChange}
                            value={props.planId}
                        >
                            <option key={-1} value={0}>none selected</option>
                            {planList.filter((item) => item.resellerId == props.resellerId).map((plan, index) =>
                                <option key={index} value={plan.itemId}>{plan.itemName}</option>
                            )}
                        </Input>
                    </Col>
                    <Col md={4}>
                        <Label
                            for="inventoryId"
                        >
                            <span style={{ fontWeight: "bold" }}>Device</span> (Optional)
                        </Label>
                        <Input
                            type='select'
                            name='inventoryId'
                            id='inventoryId'
                            className="form-control"
                            disabled={!(props.planId > 0)}
                            onChange={props.onInputChange}
                            value={props.inventoryId}
                        >
                            <option key={-1} value={0}>none selected</option>
                            {props.activeSIMList.filter((item) => item.planId == props.planId).map((inventory, index) =>
                                <option key={index} value={inventory.inventoryId}>{inventory.identifierValue}</option>
                            )}
                        </Input>
                    </Col>
                    <Col md={3}>
                        <Label
                            for="logicValue"
                        >
                            <span style={{ fontWeight: "bold" }}>Usage (MB)</span> <span style={{ color: "red" }}>*</span>
                        </Label>
                        <Input
                            id="logicValue"
                            name='logicValue'
                            placeholder='MB'
                            className="form-control"
                            onChange={props.onInputChange}
                            value={props.logicValue}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={12}>
                        <Button className="btn-withhover" outline color="custom" onClick={() => { props.handleNext(1) }} disabled={!props.validateLogicCondition()}>Continue</Button>
                        <Button outline color="secondary" onClick={() => { props.handleBack(1) }}>Back</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default LogicCondition;