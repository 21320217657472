/* eslint-disable */
import React, { Component } from "react";
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse } from 'reactstrap';
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import FileInputModal from "./FileInputModal";
import { AutoRenderDeviceTable } from './RenderAssetTable';
import EditDevicePanel from "./EditDevicePanel";


class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceToEdit: null,
      deviceToDelete: null,
      confirmDialogOpen: null,
      modalVisible: false,
    };
  }

  toggleDialog = () => {
    this.setState({ confirmDialogOpen: !this.state.confirmDialogOpen })
  };

  promptDelete = (id) => {
    this.setState({ deviceToDelete: id}, this.toggleDialog)
  };

  deleteDevice = () => {
    this.closePanel()
    this.props.deleteDevice(this.state.deviceToDelete)
    this.toggleDialog()
  };

  editSelectedDevice = async (id) => {
    this.setState({ deviceToEdit: id}, async () => {
      await this.props.getCalculateAttribute({ "deviceid": id})
    })
  };

  closePanel = () => this.setState({ deviceToEdit: null });

  toggle = () => {
    this.setState(prevState => ({
      modalVisible: !prevState.modalVisible
    }))
  };

  handleLoadFileClick = (e) => {
    this.setState({
      modalVisible: true 
    });
  };

  render() {
    return(
      <>
        <FileInputModal
          modalSetVisible={this.state.modalVisible}
          toggle={this.toggle}
        />
        <ConfirmDeleteDialog
          modalVisible={this.state.confirmDialogOpen}
          onConfirm={this.deleteDevice}
          toggleOff={this.toggleDialog}
          msg="Are you sure you want to delete this device?"
        />
        <Collapse isOpen={this.state.deviceToEdit == undefined || this.state.deviceToEdit == null}>
          <Card>
            <div className="card-pg">
              <AutoRenderDeviceTable
                keyField="deviceid"
                editable={true}
                assetList={this.props.assetList}
                deviceList={this.props.deviceList}
                handleEditClick={this.editSelectedDevice}
              />
            </div>
          </Card>
        </Collapse>
        <Collapse isOpen={this.state.deviceToEdit != undefined && this.state.deviceToEdit != null}>
          {this.state.deviceToEdit &&
            <EditDevicePanel
              assetInfo={this.props.assetList}
              devicesTypes={this.props.devicesTypes}
              devices={this.props.deviceList}
              addDeviceAttribute={this.props.addDeviceAttribute}
              updateDeviceAttribute={this.props.updateDeviceAttribute}
              closePanel={this.closePanel}
              key={this.state.deviceToEdit}
              updateDeviceOnStore={this.props.updateDeviceOnStore}
              assetList={this.props.assetList}
            />
          }
        </Collapse>
      </>
    )
  }
}

export default DeviceList;
