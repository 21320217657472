/* eslint-disable */
import React, { Component } from "react";
import { Col, Row, Button, Input, Label } from 'reactstrap';

import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';

class AdditionalCharges extends Component {

    constructor(props) {
        super(props);
    }
    
    onInputChange =  (event, index) => {
        if(event.target.type=='checkbox') {
            this.props.onAdditionalChargesChange(index, event.target.name, event.target.checked);
        }
        else {
            this.props.onAdditionalChargesChange(index, event.target.name, event.target.value);
        }
    }

    render () {
        return (
            <Row>
                <Col md={12}>

                {this.props.additionalChargesList.map((item, index) => {
                    return (
                        <Row style={{marginTop:'20px'}}>
                        <Col md={4} >
                            <Label for="description"><span>Description</span></Label>
                            <Input
                                name='description'
                                id='description'
                                placeholder='Enter Description'
                                className="form-control"
                                onChange={(e)=>this.onInputChange(e, index)}
                                value={item.description}
                            />
                            <span style={{ textAlign: "left", color: "#999" }}><b>NOTE:</b> 40 Character Limit</span>
                        </Col>

                        <Col md={3} >
                            <Label for="quantity"><span>Quantity</span></Label>
                            <Input
                                name='quantity'
                                id='quantity'
                                placeholder='Enter Quantity'
                                className="form-control"
                                onChange={(e)=>this.onInputChange(e, index)}
                                value={item.quantity}
                        />
                        </Col>

                        <Col md={3} >
                            <Label for="unitPrice"><span>Unit Price</span></Label>
                            <Input
                                name='unitPrice'
                                id='unitPrice'
                                placeholder='Enter Unit Price'
                                className="form-control"
                                onChange={(e)=>this.onInputChange(e, index)}
                                value={item.price}
                        />
                        </Col>

                        <Col md={1}>
                            <Label for="tax"><span>Tax</span></Label>
                            <Input
                                type="checkbox"
                                name='tax'
                                id='tax'
                                className="form-control"
                                style={{ 
                                    accentColor: '#000000',
                                    fontSize: '12px',
                                    marginTop: '2px',
                                    marginLeft: '-12px'
                                }}
                                onChange={(e)=>this.onInputChange(e, index)}
                                checked={item.tax}
                            />
                        </Col>

                        <Col md={1}>
                            <div style={{display:'flex',flexDirection: 'column', alignItems:'center', paddingTop:'33px', paddingLeft:'16px'}}>

                            {(index===this.props.additionalChargesList.length-1)?

                                <Button onClick={()=>{this.props.onAddCharge()}} style={{borderRadius:'70%', padding:' 3px 3px 3px 3px'}}>
                                    <PlusIcon  style={{ 'width':'20px', 'height':'20px'}}/>
                                </Button>
                                :
                                <Button onClick={()=>{this.props.onDeleteCharge(index)}} style={{borderRadius:'70%', padding:' 3px 3px 3px 3px'}}>
                                    <MinusIcon  style={{ 'width':'20px', 'height':'20px'}}/>
                                </Button>
                            }
                            </div>

                        </Col>
                        </Row>
                        )})}

                    <Row>
                        <Col md={12}>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Button outline color="secondary" onClick={() => { this.props.handleBack() }}>Back</Button>
                            <Button className="btn-withhover" outline color="custom" onClick={() => { this.props.handleNext() }} disabled={!this.props.validateAdditionalCharges()}>Continue</Button>
                        </Col>
                    </Row>

                </Col>
            </Row>
        )         
    }
}

export default AdditionalCharges;