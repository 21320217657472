/* eslint-disable */
import React, { Component } from 'react';
import {bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { GOOGLE_API_KEY } from "../../../constants/appConstants";
import { getSimLastKnownLocation } from "../../../redux/actions/inventoryActions";

const mapStyles = {
    width: '100%',
    height: '100%',
};

class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: null,
            lng: null,
            loading: true,
            renderMap: false
        }
    }

    componentDidMount() {
        this.props.getSimLastKnownLocation({
            simIdentifier: this.props.rowData.identifierType,
            simIdentifierValue: this.props.rowData.identifierValue,
            resellerId: this.props.rowData.resellerId
        }
        ).then(() => {
            let mapInfo = this.props.googleMapInfo[this.props.rowData.identifierValue];
            this.setState({
                lat: mapInfo ? mapInfo.latitude : null,
                lng: mapInfo ? mapInfo.longitude : null,
                loading: false,
                renderMap: mapInfo ? true : false

            })
        })
    }

    render() {
        if (this.state.loading == true) {
            return (
                <div>Loading Data...</div>
            );
        }
        else if (this.state.loading == false) {
            if (this.state.renderMap) {
                return (
                    this.state.lat != null ?
                        <div style={{ position: "relative", height: "300px", marginTop: "10px" }}>
                            <Map

                                google={this.props.google}
                                zoom={14}
                                style={{ width: '100%', height: '100%' }}
                                initialCenter={
                                    {
                                        lat: this.state.lat,
                                        lng: this.state.lng
                                    }
                                }
                            >
                                <Marker
                                    name={'lastknown location'}
                                />
                            </Map>
                        </div> : <div></div>

                );
            }
            else {
                return <div>no location found</div>
            }
        }
    }
}

function mapStateToProps(state) {
    let { googleMapInfo } = state.inventory;
    const props = { googleMapInfo };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getSimLastKnownLocation
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: GOOGLE_API_KEY
})(GoogleMap));
