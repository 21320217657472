/*eslint-disable*/
import React, { Component, Fragment } from "react";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {BackButton, NextButton} from '../../../shared/components/Button/IconButton'; 
import {itemFinder, dropdownOnChangeHandler} from '../../../factories/utils';
import { USAGE_PRICE } from '../../../constants/dataConstants';

class CompanyPurchasePlans extends Component {
    constructor(props){
        super(props);
        this.state={
            currentPlanItemId: null,
            currentPlanItem: null,
            autoPay: false,
        }
    }
    componentDidMount() {
        this.props.getAllPlanItems();
    }
    // --------------------------------------------

    handlePlanChange = (e) => {
        let value = dropdownOnChangeHandler(e);
        // const planItem = this.props.planItemsList.filter(item => (item.itemId == value.id));
        const planItem = this.props.planItemsList.find(item => (item.itemId == value.id));
        this.setState( { 
            currentPlanItemId: value.id,
            currentPlanItem: planItem,
        });          
    }
  
    handleAutoPayClick = (e) => {
      let checked = e.target.checked;
      this.setState({
        autoPay: checked
      });
    }

    renderPlans = () => {
        let planName = this.state.currentPlanItem ? this.state.currentPlanItem.itemName: null;  
        let planDescription = this.state.currentPlanItem ? this.state.currentPlanItem.itemDescription: '';
        let payFrequency = this.state.currentPlanItem && this.state.currentPlanItem.payFrequency ? this.state.currentPlanItem.payFrequency.itemPayFreqName: '';
        // let payPrice = this.state.currentPlanItem && this.state.currentPlanItem.unitPrice ? `CAD $ ${this.state.currentPlanItem.unitPrice}`: '';
        let payPrice = '';
        if (this.state.currentPlanItem && this.state.currentPlanItem.itemTypeId == 1) {
          payPrice = USAGE_PRICE;
        }
        else if (this.state.currentPlanItem && this.state.currentPlanItem.itemTypeId == 2 && this.state.currentPlanItem.unitPrice) {
          payPrice = `CAD $ ${this.state.currentPlanItem.unitPrice}`;
        }
        let showAuto = this.state.currentPlanItem && this.state.currentPlanItem.itemPayFrequencyId != 3;
        let currentPlanItemRateList = this.state.currentPlanItem ? this.state.currentPlanItem.itemRateList : []
        return (
          <Fragment>
            <Row style={{flexGrow: 1, justifyContent: 'left', alignItems: 'left'}}>
                  <Col md={1} style={{display:'flex', justifyContent: 'flex-end', padding: '0px'}}>
                      <Label for="item"   style={{ display: 'inline'}}><h4><b>Plans </b></h4></Label>
                  </Col>
                  <Col md={4}>
                      <Input type="select" id="itemId" name="itemId" value = {this.state.currentPlanItemId} onChange={this.handlePlanChange} style={{ display: 'inline'}}>
                          <option value={null}>No selection</option>
                          { this.props.planItemsList   && this.props.planItemsList.map((type, i) => (<option key={type.itemId} value={type.itemId}>{type.itemId} - {type.itemName}</option>))}
                      </Input> 
                  </Col>
                  
              </Row>
              <br/>
                <br/>
                {planName && <Row> <Col md={12}>
                  <Row>
                      <h2>{planName}</h2>
                  </Row>
                  <br/>
                  <Row>
                      <Col md={6}>
                        <Row>
                          <span style={{fontSize:'28px'}}><b>{payFrequency}</b></span>
                        </Row>
                        {showAuto && 
                          <Row>
                            <Col md={12}>
                              <Label  style={{ display: 'inline'}} >
                                  <Input type="checkbox" checked={this.state.autoPay} onClick={this.handleAutoPayClick}/> 
                                  Allow to pay the plan automatically
                              </Label>
                            </Col>
                          </Row>
                        }
                      </Col>
                      <Col md={6} style={{display:'flex', justifyContent: 'flex-end'}}>
                        <span style={{fontSize:'36px'}}>{payPrice}</span>
                      </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <br/>
                    <h4>Plan's Details</h4>
                  </Row>
                  <Row>
                    <div style={{padding:'5px 10px', fontSize: '16px'}}><p>{planDescription}</p></div>
                  </Row>
                  <br/>
                  {currentPlanItemRateList && currentPlanItemRateList.length > 0 && 
                  <Row>
                    <br/>
                    <h4>Plan's Rate</h4>
                    <br/>
                    <BootstrapTable 
                        data={ currentPlanItemRateList}  
                        // tableHeaderClass = 'header_white' 
                        pagination = {false} 
                          
                          
                        bordered={ false } 
                        // insertRow 
                        ref='table' 
                    >
    
                      <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='10%'>Id</TableHeaderColumn>
                      <TableHeaderColumn dataField='itemId' dataAlign="center" dataSort width='10%' >Item Id</TableHeaderColumn> 
                      <TableHeaderColumn dataField='startPoint' dataAlign="center" dataSort width='10%' >Start Point</TableHeaderColumn>
                      <TableHeaderColumn dataField='endPoint' dataAlign="center" dataSort width='10%' >End Point</TableHeaderColumn>
                      <TableHeaderColumn dataField='rate' dataAlign="center" dataSort width='10%' >Rate ($)</TableHeaderColumn>
                                    
                
                  </BootstrapTable>
                  </Row>
                  }
                  </Col>
                  </Row>
                }
          </Fragment>
        );
    } 

    handlePurchaseCardClick = () => {
        // todo
         
    }
    render() {
        return (
        <Col md={12}>
            <Card>
                <CardBody style={{padding:'30px 30px'}}>
                    {this.renderPlans()}
                </CardBody>
            </Card>
        
            <Row>
                <Col md={6} style={{display:'flex', justifyContent:'flex-end'}}>
                    <NextButton
                        onClick={this.handlePurchaseCardClick} 
                        label="Next"
                        size={30}
                        color = 'black'
                    />
                </Col>
            </Row>
        </Col>
        )
    }
}

export default CompanyPurchasePlans