/*eslint-disable*/
import React, { Component, Fragment } from "react";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardFooter } from 'reactstrap';
import {itemFinder, dropdownOnChangeHandler} from '../../../factories/utils';
import { USAGE_PRICE } from '../../../constants/dataConstants';
import log from 'loglevel';
import { toast } from "react-toastify";

class CompanyActivityChangePlan extends Component {
    constructor(props){
        super(props);
        this.state={
            currentPlanItemId: null,
            currentPlanItem: null,
            autoPay: false,
            billCycle: 30,
        }
    }

    handleItemChange=(e)=>{
        let value = dropdownOnChangeHandler(e);
        const planItem = this.props.planItemsList.find(item => (item.itemId == value.id));
        // const planItem = this.props.planItemsAll.find(item => (item.itemId == value.id));
        log.debug('plan change selector', planItem);
        this.setState( { 
            currentPlanItemId: value.id,
            currentPlanItem: planItem,
        });      
    }

    handBillCycleChange = (e) =>{
        let value = e.target.value
        this.setState({
            billCycle: value
          },()=>{
            // log.debug('billCycle', this.state.billCycle);
          }
        );
    }

    handleAutoPayClick = (e) => {
        let checked = e.target.checked;
        this.setState({
          autoPay: checked
        });
    }

    handleSaveClick = (e) => {
        if (!this.props.orderObj) {
            toast.error('Failed to update order. Please try again.');
            this.props.toggle();
            return
        }

        let itemParam = null;
        const {currentPlanItem} = this.state;
         
        if (currentPlanItem){
            itemParam = {
                "itemId": currentPlanItem.itemId,
                "itemQty": 1,
                "promoCode": currentPlanItem.promoteId,
            }
        }
        let orderItemDataList = [];
        if (this.props.orderObj.orderItemDataList){
            this.props.orderObj.orderItemDataList.forEach((item, index)=>{
                if(itemParam && itemParam.itemId != item.itemId){
                    orderItemDataList.push(itemParam);
                }else{
                    orderItemDataList.push({
                        "itemId": item.itemId,
                        "itemQty": item.itemQty,
                        "promoCode": item.promoCode,
                    });
                }
            });
        }else{
            if (itemParam) {
                orderItemDataList.push(itemParam);
            }
        }

        let  data = {
            "accountId": this.props.orderObj.accountId,
            "currency": this.props.orderObj.currency,
            "comment": this.props.orderObj.comment,
            "billcycle": this.state.billCycle ? this.state.billCycle : this.props.orderObj.billcycle,
            "autoPay": this.state.autoPay ? 1 : 0, // this.props.orderObj.autoPay,
            "orderItemDataList": orderItemDataList
        }
        this.props.changePlan(this.props.orderObj.id, data);
        this.props.toggle();
    }

    handleCancelClick = (e) => {
        this.props.toggle();
    }

    renderSelector(){
        return (
        <Row style={{flexGrow: 1, justifyContent: 'left', alignItems: 'left'}}>
          <Col md={2} style={{display:'flex', justifyContent: 'flex-start', padding: '0px'}}>
              <Label for="item"   style={{ display: 'inline'}}><h4><b>Select New Plan </b></h4></Label>
          </Col>
          <Col md={4}>
              <Input type="select" id="itemId" name="itemId" value = {this.state.currentPlanItemId} onChange={this.handleItemChange} style={{ display: 'inline'}}>
                  <option value={null}>No selection</option>
                  { this.props.planItemsList   && this.props.planItemsList.map((type, i) => (<option key={type.itemId} value={type.itemId}>{type.itemId} - {type.itemName}</option>))}
              </Input> 
          </Col>              
        </Row>
        );
    }

    renderPlans({planItem}){
        let planName = planItem ? planItem.itemName: null;  
        let planDescription = planItem ? planItem.itemDescription: '';
        let payFrequency = planItem && planItem.payFrequency ? planItem.payFrequency.itemPayFreqName: '';
        // let payPrice = this.props.currentPlanItem && this.props.currentPlanItem.unitPrice ? `CAD $ ${this.props.currentPlanItem.unitPrice}`: '';
        if (typeof payFrequency === 'string' && payFrequency.length > 0) {
          payFrequency = payFrequency.charAt(0).toUpperCase() + payFrequency.slice(1);
        }
        let payPrice = '';
        if (planItem && planItem.itemTypeId == 1) {
          payPrice = USAGE_PRICE;
        }
        else if (planItem && planItem.itemTypeId == 2 && planItem.unitPrice) {
          payPrice = `CAD $ ${planItem.unitPrice}`;
        }
        let showAuto = planItem && planItem.itemPayFrequencyId != 3;
        let currentPlanItemRateList = planItem ? planItem.itemRateList : []
        return(
            <Fragment>
                {planName && <Row> <Col md={12}>
                <Row>
                    <h2>{planName}</h2>
                </Row>
                <br/>
                <Row>
                    <Col md={6}>
                        <Row>
                        <span style={{fontSize:'28px'}}><b>{payFrequency}</b></span>
                        </Row>
                        <Row style={{marginTop: '10px'}}>
                            <Col  md={3} style={{padding: '0px'}}>
                            <Label style={{ display: 'inline'}}>
                                <span style={{fontSize:'18px'}}>Billing Cycle</span>
                            </Label>
                            </Col>
                            <Col md={3}>
                            <Input type='number' min="0" step="1" max={"365"} defaultValue={this.state.billCycle} onChange={(e)=>{this.handBillCycleChange(e)}} ></Input>                          
                            </Col>
                            <Col md={3}>
                            <span style={{fontSize:'18px'}}>day(s)</span>
                            </Col>
                            
                        </Row>
                        {showAuto && 
                        <Row style={{marginTop: '10px'}}>
                            <Col md={12}>
                            <Label  style={{ display: 'inline'}} >
                                <Input type="checkbox" checked={this.state.autoPay} onClick={this.handleAutoPayClick}/> 
                                Allow to pay the plan automatically
                            </Label>
                            </Col>
                        </Row>
                        }
                    </Col>
                    <Col md={6} style={{display:'flex', justifyContent: 'flex-end'}}>
                        <span style={{fontSize:'36px'}}>{payPrice}</span>
                    </Col>
                </Row>
                {/* <Row>
                    <Col  md={2}>
                        <Label style={{ display: 'inline'}}>
                        <span style={{fontSize:'18px'}}>Bill Cycle</span>
                        </Label>
                    </Col>
                    <Col md={1}>
                        <Input type='number' min="0" step="1" max={"365"} defaultValue='0' onChange={(e)=>{}} ></Input>
                    </Col>
                    
                </Row> */}
                <hr/>
                <Row>
                    <br/>
                    <h4>Plan's Details</h4>
                </Row>
                <Row>
                    <div style={{padding:'5px 10px', fontSize: '16px'}}><p>{planDescription}</p></div>
                </Row>
                <br/>
                {currentPlanItemRateList && currentPlanItemRateList.length > 0 && 
                <Row>
                    <br/>
                    <h4>Plan's Rate</h4>
                    <br/>
                    <BootstrapTable 
                        data={ currentPlanItemRateList}  
                        // tableHeaderClass = 'header_white' 
                        pagination = {false} 
                          
                          
                        bordered={ false } 
                        ref='table' 
                    >

                    <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='10%'>Id</TableHeaderColumn>
                    <TableHeaderColumn dataField='itemId' dataAlign="center" dataSort width='10%' >Item Id</TableHeaderColumn> 
                    <TableHeaderColumn dataField='startPoint' dataAlign="center" dataSort width='10%' >Start Point</TableHeaderColumn>
                    <TableHeaderColumn dataField='endPoint' dataAlign="center" dataSort width='10%' >End Point</TableHeaderColumn>
                    <TableHeaderColumn dataField='rate' dataAlign="center" dataSort width='10%' >Rate ($)</TableHeaderColumn>
                                    
                
                </BootstrapTable>
                </Row>
                }
                </Col>
                </Row>
                }
            </Fragment>
        );
    }

    render(){
        return (
            <Card>
                <CardBody>
                    {this.renderSelector()}
                    {this.renderPlans({planItem: this.state.currentPlanItem})}
                </CardBody>
                <CardFooter style={{textAlign: 'right', backgroundColor: 'white'}}>
                    <Button onClick={this.handleSaveClick} size='sm'>Save</Button>
                    <Button onClick={this.handleCancelClick} size='sm'>Cancel</Button>
                </CardFooter>
            </Card>
        );
    }
}
export default CompanyActivityChangePlan