/* eslint-disable */
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FacebookIcon from 'mdi-react/FacebookIcon';
import GooglePlusIcon from 'mdi-react/GooglePlusIcon';
import LogInForm from './components/LogInForm';
import { loadLoginThemes } from '../../redux/actions/themeActions';
import { WEB_SERVICE_ROOT, WEB_SERVICE_LOGO } from '../../constants/appConstants';
import ThemeSwitcher from 'react-css-vars';
import { changeThemeToCustom } from '../../redux/actions/themeActions';
import { LoginIcon } from 'mdi-react';
// import { handleSubmit } from '../../redux/actions/userAction';


const LogIn = ({ loginThemeList, changeThemeToCustom }) => {
  const [loginTheme, setloginTheme] = useState(null)
  const [Logo, setLogo] = useState(null)
  const [Custom, setCustom] = useState(false)
  var x = document.URL
  useEffect(() => {
    console.log('LOGIN PAGE')
    fetch(`${WEB_SERVICE_ROOT}BrandingLogin?url=${x}`).then(response => response ? response.json() : {}).then(data => {
      if (data.length > 0) {
        let companyTheme = data[0]
        //set logo
        if (companyTheme) {
          changeThemeToCustom();
          setCustom(true)
          if (companyTheme.loginLogo) {
            setLogo(`${WEB_SERVICE_LOGO}${companyTheme.loginLogo}`)
          }
          setloginTheme(companyTheme)
        }

      }
    }).catch(e => console.error(e.message));
  }, []);
  useEffect(() => {
    if (loginTheme && Logo)
      console.log(loginTheme, Logo)
  }, [loginTheme, Logo]);
  // if (x.includes("/Helix")) {
  //   return (
  //     <div className="account" style={{ backgroundColor: "white", color: "Black" }}>
  //       <div className="account__wrapper">
  //         <div className="account__card">
  //           <img src="https://waiviot-uat.itsp-inc.com:8443/upload/logo/Helix_Login.png" alt="Logo" style={{ borderRadius: "25px", marginBottom: "20px" }} />
  //           <LogInForm
  //             custom={true}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // } else {
  return (
    <ThemeSwitcher theme={loginTheme}>
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card login-card">
            {!Logo && <div className="account__head">
              <h3 className="account__title">Welcome to
                {/* <span className="account__logo"> WuKong IoT */}
                <span className="account__logo"> WAiV IoT
                  <span className="account__logo-accent"> Platform</span>
                </span>
              </h3>
              {/* <h4 className="account__subhead subhead">Start your business easily</h4> */}
            </div>}
            {Custom && loginTheme && Logo &&
              <div className='login-logo-container'>
                <img src={`${WEB_SERVICE_LOGO}${loginTheme.loginLogo}`} alt="Logo" style={{ borderRadius: "25px", marginBottom: "20px", maxWidth: "280px" }} /></div>}
            <LogInForm
            />
            {/* <div className="account__or">
        <p>Or Easily Using</p>
      </div>
      <div className="account__social">
        <Link
          className="account__social-btn account__social-btn--facebook"
          to="/pages/one"
        ><FacebookIcon />
        </Link>
        <Link
          className="account__social-btn account__social-btn--google"
          to="/pages/one"
        ><GooglePlusIcon />
        </Link>
      </div> */}
          </div>
        </div>
      </div>
    </ThemeSwitcher>
  )
}

const mapToStateProps = (state) => ({
  loginThemeList: state.theme.loginThemes
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ loadLoginThemes, changeThemeToCustom }, dispatch)
}
export default connect(mapToStateProps, mapDispatchToProps)(LogIn);

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
