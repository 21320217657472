
/* eslint-disable */
import { proxy } from 'jquery';
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, FormText, FormFeedback } from 'reactstrap';
import { addNVR, loadNVRs } from '../../../redux/actions/surveilenceActions';
import { add } from 'lodash';

function AddDeviceModal({type, modalVisible, toggleModal, addNVR}) {
    const [Title, setTitle] = useState(null);
    const [Password, setPassword] = useState('');
    const [ConfirmPasswordValid, setConfirmPasswordValid] = useState(false);
    const [internalIpValid, setinternalIpValid] = useState(null);
    const [publicIpValid, setpublicIpValid] = useState(null);
    const [NVRObject, setNVRObject] = useState({
        nvrName: '',
        nvrType: 'plaza',
        direction: 'NA',
        center: '',
        nvrIp: '',
        nvrIpPublic: '',
        httpPort: '',
        httpPortPublic: '',
        proxyPort: '',
        adminPass: ''
    });

    useEffect(() => {
        if(type==='nvr'){
            setTitle('Add New NVR');
        }   
    }, [type]);

    useEffect(() => {
        if(NVRObject.nvrIp !== ''){
            setinternalIpValid(validateIP(NVRObject.nvrIp));
        }
        if(NVRObject.nvrIpPublic !== ''){
            setpublicIpValid(validateIP(NVRObject.nvrIpPublic));
        }       
    }, [NVRObject]);

    
    const handleAddDeviceSubmit = (e) => {
        e.preventDefault();
        if(Password && ConfirmPasswordValid){
            let nvrObjectWithPassword = {...NVRObject, adminPass: Password};
            setNVRObject(nvrObjectWithPassword);
            addNVR(nvrObjectWithPassword);
            toggleModal();
            loadNVRs();
        }
    }


    //validate IP addess function
    const validateIP = (ip) => {
        // console.log('IP', ip);
        let ipPattern = new RegExp('^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
        let ipArray = ip.split('.');
        if(ipArray.length === 4){
            for(let i=0; i<ipArray.length; i++){
                if(!ipPattern.test(ipArray[i])){
                    return false;
                }
            }
            return true;
        }else{
            return false;
        }
    }
    

    //IP validation
    const renderAddNVRForm = () => {
        return (
            <Form onSubmit={handleAddDeviceSubmit}>
                <FormGroup>
                    <Label for='nvrName'>Name</Label>
                    <Input type='text' name='name' value={NVRObject.nvrName} onChange={(e)=>setNVRObject({...NVRObject, nvrName: e.target.value})} id='nvrName' placeholder='Enter NVR Name...' />
                </FormGroup>
                <FormGroup>
                    <Label for='direction'>Direction</Label>
                    <Input type='select' onChange={e=>setNVRObject({...NVRObject, direction: e.target.value})} name='direction' id='direction' placeholder='Select Incoming Traffic...'>
                        <option value='NA'>N/A</option>
                        <option value='West'>West</option>
                        <option value='East'>East</option>
                        <option value='North'>North</option>
                        <option value='South'>South</option>
                    </Input>
                    <FormText>Incoming highway lane direction</FormText>
                </FormGroup>
                <FormGroup>
                    <Label for='nvrType'>Type</Label>
                    <Input type='select' onChange={e=>setNVRObject({...NVRObject, nvrType: e.target.value})} name='nvrType' id='nvrType' placeholder='Select NVR Type...'>
                        <option value='plaza'>Plaza</option>
                        <option value='drive-through'>Drive-Through</option>
                        <option value='car counting'>Car Counting</option>
                        <option value='development'>development</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for='nvrCenter'>Center</Label>
                        <Input type='text' onChange={e=>setNVRObject({...NVRObject, center: e.target.value})} name='nvrCenter' id='nvrCenter' placeholder='Service Center...'>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for='nvrIp'>Internal IP</Label>
                    <Input type='text' invalid={NVRObject.internalIp !== '' && !internalIpValid} onChange={e=>setNVRObject({...NVRObject, nvrIp: e.target.value})} name='nvrIp' id='nvrIp' placeholder='Enter Internal IP...' />
                    {!internalIpValid && NVRObject.internalIp !== '' ? <FormFeedback>Invalid IP Address</FormFeedback>: ''}
                </FormGroup>
                <FormGroup>
                    <Label for='nvrPublicIp'>Public IP</Label>
                    <Input type='text' invalid={NVRObject.publicIp !== '' && !publicIpValid} onChange={e=>setNVRObject({...NVRObject, nvrIpPublic: e.target.value})} name='nvrPublicIp' id='nvrPublicIp' placeholder='Enter Public IP...' />
                    {!publicIpValid && NVRObject.publicIp !== '' ? <FormFeedback>Invalid IP Address</FormFeedback>: ''}
                </FormGroup>
                <FormGroup>
                    <Label for='httpPort'>Http Port</Label>
                    <Input type='number' onChange={e=>setNVRObject({...NVRObject, httpPort: e.target.value})} name='httpPort' id='httpPort' placeholder='Enter HTTP Port' />
                </FormGroup>
                <FormGroup>
                    <Label for='httpPortPublic'>Http Port Public</Label>
                    <Input type='number' onChange={e=>setNVRObject({...NVRObject, httpPortPublic: e.target.value})} name='httpPortPublic' id='httpPortPublic' placeholder='Enter HTTP Port Public' />
                </FormGroup>
                <FormGroup>
                    <Label for='proxyPort'>Proxy Port</Label>
                    <Input type='number' onChange={e=>setNVRObject({...NVRObject, proxyPort: e.target.value})} name='proxyPort' id='proxyPort' placeholder='Enter Proxy Port' />
                    <FormText>NGinx reverse proxy port for Web client access</FormText>
                </FormGroup>
                <FormGroup>
                    <Label for='nvrPassword'>Password</Label>
                    <Input type='password' name='nvrPassword' id='nvrPassword' onChange={(e)=>{setPassword(e.target.value)}} placeholder='Enter NVR Password' />
                </FormGroup>
                <FormGroup>
                    <Label for='confirmPassword'>Confirm Password</Label>
                    <Input type='password' name='confirmPassword' id='confirmPassword' onChange={(e)=>{setConfirmPasswordValid(e.target.value===Password)}} placeholder='Confirm NVR Password' invalid={!ConfirmPasswordValid} />
                </FormGroup>
                <Button outline color="custom" type='submit'>Add</Button>
                <Button outline color="secondary" type='cancel' onClick={toggleModal}>Cancel</Button>
            </Form>            
        )
    }
    return (
        <Modal
        isOpen={modalVisible}
        toggle={toggleModal}>
            <ModalHeader>{Title}</ModalHeader>
            <ModalBody>{type==='nvr'? renderAddNVRForm(): ''}</ModalBody>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addNVR: (nvr) => dispatch(addNVR(nvr)),
        loadNVRs: () => dispatch(loadNVRs())
    }
}



export default connect(null, mapDispatchToProps)(AddDeviceModal);