/* eslint-disable */
import {
    ACTION_MGMT_MSG_TOGGLE_OFF,
    ACTION_MGMT_LOAD_ASSET_SUCCEED,
    ACTION_MGMT_LOAD_ASSET_FAILED,
    ACTION_MGMT_LOAD_ASSET_DEVICE_SUCCEED,
    ACTION_MGMT_LOAD_ASSET_DEVICE_FAILED,
    ACTION_MGMT_LOAD_ACTION_SUCCEED,
    ACTION_MGMT_LOAD_ACTION_FAILED,
    ACTION_GET_ACTIONS_SUCCEED,
    ACTION_GET_ACTIONS_FAILED,
    ACTION_SET_TELECOM_SUCCEED,
    ACTION_SET_TELECOM_FAILED,
    ACTION_GET_ACTION_RULE_SUCCEED,
    ACTION_GET_ACTION_RULE_FAILED,
    ACTION_CLEAR_ACTION_RULE,
    ACTION_GET_ACTION_BY_ID_SUCCEED,
    ACTION_GET_ACTIONS_BY_ID_FAILED,
    ACTION_GET_TELECOM_ACTION_BY_ID_SUCCEED,
    ACTION_GET_TELECOM_ACTION_BY_ID_FAILED,
    ACTION_CLEAR_ACTIONS_BY_ID,
    ACTION_UPSERT_ACTIONS_SUCCEED,
    ACTION_UPSERT_ACTIONS_FAILED,
    ACTION_UPSERT_ACTION_RULE_SUCCEED,
    ACTION_UPSERT_ACTION_RULE_FAILED,
    ACTION_GET_ACTION_ALERT_SUCCEED,
    ACTION_GET_ACTION_ALERT_FAILED,
    ACTION_CLEAR_ACTION_ALERT,
    ACTION_UPSERT_ACTION_ALERT_SUCCEED,
    ACTION_UPSERT_ACTION_ALERT_FAILED,
    ACTION_SET_ACTION_ID_SUCCEED,
    ACTION_DELETE_ACTIONS_SUCCEED,
    ACTION_DELETE_ACTIONS_FAILED,
    ACTION_GET_ACTION_LOG_SUCCEED,
    ACTION_GET_ACTION_LOG_FAILED,
    ACTION_GET_TEMPLATE_SUCCEED,
    ACTION_GET_TEMPLATE_FAILED,
    STEP_FORWARD,
    STEP_BACKWARD,
    STET_RESET,
    ACTION_GET_CONTACT_LIST_SUCCEED,
    ACTION_GET_CONTACT_LIST_FAILED,
    ACTION_GET_NOTIFICTION_TEMPLATE_LIST_SUCCEED,
    ACTION_GET_NOTIFICTION_TEMPLATE_LIST_FAILED,
    ACTION_GET_DASHBOARDSETTING_LIST_SUCCEED,
    ACTION_GET_DASHBOARDSETTING_LIST_FAILED,
} from '../../constants/actionTypes';

import { generateSelectOptions } from '../../factories/utils'

const initState = {
    actions: [],
    assets: [],
    assetdevices: [],
    actionLogs: [],
    templateList: [],
    error: 0,
    message: '',
    type: '',
    messagevisible: false,
    messagetype: '',
    activeStep: 0,
    actionId: 0,
    contactList: [],
    notificationTemplateList: [],
    dashBoardSettingList: [],
    actionInfo: [],
    actionAlert: [],
}

export default function (state = initState, action) {

    switch (action.type) {
        case ACTION_MGMT_MSG_TOGGLE_OFF:
            return {
                ...state,
                type: action.type,
                messagevisible: false,
                messagetype: '',
            }
        case ACTION_MGMT_LOAD_ASSET_SUCCEED:

            let assetOptions = generateSelectOptions(action.payload, 'assetname', 'assetid');
            return {
                ...state,
                assets: assetOptions,
                error: 0,
                message: 'GET ASSET SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_MGMT_LOAD_ASSET_FAILED:

            return {
                ...state,
                error: 1,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case ACTION_MGMT_LOAD_ASSET_DEVICE_SUCCEED:

            let deviceOptions = generateSelectOptions(action.payload, 'devicename', 'deviceid');
            return {
                ...state,
                assetdevices: deviceOptions,
                error: 0,
                message: 'GET ASSET DEVICES SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_MGMT_LOAD_ASSET_DEVICE_FAILED:
            return {
                ...state,
                error: 2,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case ACTION_MGMT_LOAD_ACTION_SUCCEED:
            return {
                ...state,
                actions: action.payload,
                error: 0,
                message: 'GET ACTIONS SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_MGMT_LOAD_ACTION_FAILED:
            return {
                ...state,
                error: 3,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case ACTION_GET_ACTIONS_SUCCEED:
            return {
                ...state,
                actionList: action.payload,
                error: 0,
                message: 'GET ACTION LIST SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_ACTION_BY_ID_SUCCEED:
            return {
                ...state,
                actionInfo: action.payload,
                error: 0,
                message: 'GET ACTION INFO SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_TELECOM_ACTION_BY_ID_SUCCEED:
        case ACTION_GET_TELECOM_ACTION_BY_ID_FAILED:
            return {
                ...state,
                message: action.payload.message,
            }
        case ACTION_SET_TELECOM_SUCCEED:
        case ACTION_SET_TELECOM_FAILED:
            return {
                ...state,
                message: action.payload,

            }
        case ACTION_CLEAR_ACTIONS_BY_ID:
            return {
                ...state,
                actionInfo: [],
                error: 0,
                message: 'CLEAR ACTION INFO SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_ACTION_RULE_SUCCEED:
            return {
                ...state,
                actionRules: action.payload,
                error: 0,
                message: 'GET ACTION RULE SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_CLEAR_ACTION_RULE:
            return {
                ...state,
                actionRules: [],
                error: 0,
                message: 'CLEAR ACTION RULE ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_UPSERT_ACTIONS_SUCCEED:
            return {
                ...state,
                activeStep: state.activeStep + 1,
                actionId: action.payload.actionid,
                error: 0,
                message: 'UPSERT ACTION SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_UPSERT_ACTION_RULE_SUCCEED:
            return {
                ...state,
                activeStep: state.activeStep + 1,
                // actionId: action.payload.actionid,
                error: 0,
                message: 'UPSERT ACTION RULE SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_ACTION_ALERT_SUCCEED:
            return {
                ...state,
                actionAlert: action.payload,
                // actionId: action.payload.actionid,
                error: 0,
                message: 'UPSERT ACTION RULE SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_CLEAR_ACTION_ALERT:
            return {
                ...state,
                actionAlert: [],
                // actionId: action.payload.actionid,
                error: 0,
                message: 'UPSERT ACTION RULE SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_UPSERT_ACTION_ALERT_SUCCEED:
            return {
                ...state,
                activeStep: state.activeStep + 1,
                // actionId: action.payload.actionid,
                error: 0,
                message: 'UPSERT ACTION ALERT SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_SET_ACTION_ID_SUCCEED:
            return {
                ...state,
                actionId: action.payload,
                error: 0,
                message: 'SET ACTION ID SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_DELETE_ACTIONS_SUCCEED:
            return {
                ...state,
                // actionList: action.payload,
                error: 0,
                message: 'DELETE ACTION LIST SUCCESS ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_ACTION_LOG_SUCCEED:
            return {
                ...state,
                actionLogs: action.payload,
                error: 0,
                message: 'GET ACTION LOG SUCCEED ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_TEMPLATE_SUCCEED:
            return {
                ...state,
                templateList: action.payload,
                error: 0,
                message: 'GET RULE TEMPLATES SUCCEED ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_CONTACT_LIST_SUCCEED:
            return {
                ...state,
                contactList: action.payload, //add the payload to the contactList 
                error: 0,
                message: 'GET RULE CONTACT LIST SUCCEED ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_NOTIFICTION_TEMPLATE_LIST_SUCCEED:
            return {
                ...state,
                notificationTemplateList: action.payload,
                error: 0,
                message: 'GET NOTIFICATION TEMPLATE LIST SUCCEED ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_DASHBOARDSETTING_LIST_SUCCEED:
            return {
                ...state,
                dashBoardSettingList: action.payload,
                error: 0,
                message: 'GET DASH BOARD SETTING TEMPLATE LIST SUCCEED ---',
                type: action.type,
                messagevisible: false,
                messagetype: 'success',
            }
        case ACTION_GET_NOTIFICTION_TEMPLATE_LIST_FAILED:
        case ACTION_GET_DASHBOARDSETTING_LIST_FAILED:
        case ACTION_GET_CONTACT_LIST_FAILED:
        case ACTION_GET_TEMPLATE_FAILED:
        case ACTION_GET_ACTION_LOG_FAILED:
        case ACTION_DELETE_ACTIONS_FAILED:
        case ACTION_UPSERT_ACTION_ALERT_FAILED:
        case ACTION_GET_ACTION_ALERT_FAILED:
        case ACTION_UPSERT_ACTION_RULE_FAILED:
        case ACTION_UPSERT_ACTIONS_FAILED:
        case ACTION_GET_ACTIONS_BY_ID_FAILED:
        case ACTION_GET_ACTIONS_FAILED:
        case ACTION_GET_ACTION_RULE_FAILED:
            return {
                ...state,
                error: 3,
                message: action.error,
                type: action.type,
                messagevisible: true,
                messagetype: 'danger',
            }
        case STEP_FORWARD:
            return {
                ...state,
                activeStep: state.activeStep + 1,
            }
        case STEP_BACKWARD:
            return {
                ...state,
                activeStep: state.activeStep - 1,
            }
        case STET_RESET:
            return {
                ...state,
                activeStep: 0,
            }
        default:
            return state;
    }
}
