/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Card, CardBody, Col, Row, Button, Container, Input } from 'reactstrap';
import GoogleMap from '../../../../Inventory/components/GoogleMap';
import { bindActionCreators } from 'redux';

import { Bar, BarChart, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { getIdentifierUsage } from '../../../../../redux/actions/userAction';
import { CSVLink } from "react-csv";
import { getCurrentUser } from '../../../../../factories/auth';
import { LIGHT_GREY_COLOR } from "../../../../../constants/dataConstants";
import Spinner from "react-bootstrap/Spinner";

class IdentifierUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            loading: true,
            data: null,
            filterStartTime: null,
            filterEndTime: null,
            isShowFilterTime1: false,
            usageCsvData: [],
            usageCsvHeaders: [
            ],
            isChangedType: true,
            refreshTime: new Date().getTime()
        }
        this.userObj = getCurrentUser()
    }

    async componentDidMount() {
        let res = await this.props.getIdentifierUsage({
            type: this.props.type,
            identifierType: this.props.rowData.identifierType,
            identifierValue: this.props.rowData.identifierValue,
            startTime: this.props.filterStartTime,
            endTime: this.props.filterEndTime
        });

        const labelsTeisa = [
            { label: "Date", key: "tname" },
            { label: "Client Name", key: "client" },
            { label: "Customer Name", key: "customerName" },
            { label: "Device Name", key: "deviceName" },
            { label: "Device Type", key: "deviceType" },
            { label: "Status", key: "status" },
            { label: "Identifier Value", key: "identifierValue" },
            { label: "Plan Name", key: "planName" },
            { label: "Contract ID", key: "planCode" },
            { label: "Address 1", key: "address1" },
            { label: "Address 2", key: "address2" },
            { label: "City", key: "city" },
            { label: "Zip Code", key: "zipcode" },
            { label: "State", key: "state" },
            { label: "Country", key: "country" },
            { label: "Usage", key: "usage" },
        ]

        let labelsNotTeisa = []
        // var labels = []
        if (this.props.isAdmin === true) {
            labelsNotTeisa = [
                { label: "Date", key: "tname" },
                { label: "Client Name", key: "client" },
                { label: "Customer Name", key: "customerName" },
                { label: "Device Name", key: "deviceName" },
                { label: "Status", key: "status" },
                { label: "IMEI", key: "imei" },
                { label: "Identifier Type", key: "identifierType" },
                { label: "Identifier Value", key: "identifierValue" },
                { label: "Plan Name", key: "planName" },
                { label: "Plan Code", key: "planCode" },
                { label: "Address 1", key: "address1" },
                { label: "Address 2", key: "address2" },
                { label: "City", key: "city" },
                { label: "Zip Code", key: "zipcode" },
                { label: "State", key: "state" },
                { label: "Country", key: "country" },
                { label: "Data in MB", key: "usage" },
            ]
        } else {
            labelsNotTeisa = [
                { label: "Date", key: "tname" },
                { label: "Client Name", key: "client" },
                { label: "Customer Name", key: "customerName" },
                { label: "Device Name", key: "deviceName" },
                { label: "Status", key: "status" },
                { label: "IMEI", key: "imei" },
                { label: "Identifier Type", key: "identifierType" },
                { label: "Identifier Value", key: "identifierValue" },
                { label: "Plan Name", key: "planName" },
                // { label: "Plan Code", key: "planCode" },
                { label: "Address 1", key: "address1" },
                { label: "Address 2", key: "address2" },
                { label: "City", key: "city" },
                { label: "Zip Code", key: "zipcode" },
                { label: "State", key: "state" },
                { label: "Country", key: "country" },
                { label: "Data in MB", key: "usage" },
            ]
        }

        const labels = this.userObj.companyCode && this.userObj.companyCode == "TEISA" ? labelsTeisa : labelsNotTeisa

        /*this.setState({ usageCsvHeaders: labels });
        let csvData = [...this.props.dashboardIdentifierUsageData]*/

        let dataInMB = structuredClone(res.data);
        for(let i=0;i<dataInMB.length;i++) {
            dataInMB[i].amt = (dataInMB[i].amt / 1024).toFixed(2);
        }

        // this.state.data = res.data
        this.state.data = dataInMB
        let csvData = [];
        if(!res.data.error)
            csvData = [...res.data]
        this.setState({ usageCsvHeaders: labels });

        for (let i = 0; i < csvData.length; i++) {
            if (this.state.type == "monthly") {
                csvData[i].tname = csvData[i].name.replace("-01", "\t");
            } if (this.state.type == "daily") {
                csvData[i].tname = csvData[i].name;
            } else if (this.state.type == "weekly") {
                csvData[i].tname = "For the week of " + csvData[i].name;

            }
            /*csvData[i].name=this.props.rowData.name;*/
            csvData[i].client = this.props.rowData.resellerName;
            csvData[i].deviceName = this.props.rowData.deviceName;
            // csvData[i].deviceType = this.props.rowData.deviceType ? this.props.rowData.deviceType : "null";
            csvData[i].customerName = this.props.rowData.customerName,
                csvData[i].identifierType = this.props.rowData.identifierType
            csvData[i].identifierValue = this.props.rowData.identifierValue
            csvData[i].planName = this.props.rowData.planName
            if (this.props.isAdmin === true) {
                csvData[i].planCode = this.props.rowData.planCode
            }
            csvData[i].address1 = this.props.rowData.addressLine1
            csvData[i].address2 = this.props.rowData.addressLine2
            csvData[i].city = this.props.rowData.city
            csvData[i].zipcode = this.props.rowData.zipcode
            csvData[i].state = this.props.rowData.state
            csvData[i].country = this.props.rowData.country
            if( this.userObj.companyCode !== "TEISA"){
                if (csvData[i].amt && this.userObj.companyCode){
                    csvData[i].usage = (csvData[i].amt / 1024).toFixed(2)
                } else {
                    csvData[i].usage = (csvData[i].amt / 1024).toFixed(2)
                }
            } 

            if(this.userObj.companyCode == "TEISA") {
                if (csvData[i].amt && this.userObj.companyCode) {
                    csvData[i].usage = csvData[i].amt + " Frame"
                } else {
                    csvData[i].usage = csvData[i].amt + " Frame"
                }
            }

            if (this.props.rowData.status == 1) {
                csvData[i].status = `Active`
            } else if (this.props.rowData.status == 0) {
                csvData[i].status = `Inactive`
            }
            else if (this.props.rowData.status == 2) {
                csvData[i].status = `Suspend`
            }
            else {
                csvData[i].status = `Error`
            }

        }
        this.setState({ usageCsvData: csvData })

    }

    LoadUsageDate = (type, startTime, endTime) => {
        this.setState({
            type: type,
            data: []
        }, () => this.props.getIdentifierUsage({
            "type": type,
            identifierType: this.props.rowData.identifierType,
            identifierValue: this.props.rowData.identifierValue,
            startTime: startTime,
            endTime: endTime
        }).then(() => {
            let csvData = [];
            let resData = {}
            for (let i = 0; i < this.props.dashboardIdentifierUsageDataInMB.length; i++) {
                let data = this.props.dashboardIdentifierUsageDataInMB[i];
                resData[data.name] = data;
            }
            this.state.data = this.props.dashboardIdentifierUsageDataInMB;
            if (this.state.filterStartTime1 && this.state.filterEndTime1) {
                csvData = []
                let tmpDateStartDate = new Date(this.state.filterStartTime1).getTime() + 86400000;
                let tmpDateEndDate = new Date(this.state.filterEndTime1).getTime() + 86400000 + 50400000;
                do {
                    csvData.push({ name: this.formatTime(tmpDateStartDate) })
                    tmpDateStartDate += 86400000;
                } while ((tmpDateStartDate < tmpDateEndDate))
            } else {
                csvData = [...this.props.dashboardIdentifierUsageDataInMB]
            }
            for (let i = 0; i < csvData.length; i++) {
                csvData[i].amt = resData[csvData[i].name] ? resData[csvData[i].name].amt : null
                if (this.state.type == "monthly") {
                    csvData[i].tname = csvData[i].name.replace("-01", "\t");
                } if (this.state.type == "daily") {
                    csvData[i].tname = csvData[i].name;
                } else if (this.state.type == "weekly") {
                    csvData[i].tname = "For the week of " + csvData[i].name;
                }
                /*csvData[i].name=this.props.rowData.name;*/
                csvData[i].client = this.props.rowData.resellerName;
                csvData[i].deviceName = this.props.rowData.deviceName;
                csvData[i].customerName = this.props.rowData.customerName,
                    csvData[i].identifierType = this.props.rowData.identifierType
                csvData[i].identifierValue = this.props.rowData.identifierValue
                csvData[i].planName = this.props.rowData.planName
                csvData[i].planCode = this.props.rowData.planCode
                csvData[i].addressLine1 = this.props.rowData.addressLine1
                csvData[i].addressLine2 = this.props.rowData.addressLine2
                csvData[i].city = this.props.rowData.city
                csvData[i].zipcode = this.props.rowData.zipcode
                csvData[i].state = this.props.rowData.state
                csvData[i].country = this.props.rowData.country
                if (csvData[i].amt) {
                    csvData[i].usage = csvData[i].amt 
                } else {
                    csvData[i].usage = csvData[i].amt ? csvData[i].amt : 0
                }

                if (this.props.rowData.status == 1) {
                    csvData[i].status = `Active`
                } else if (this.props.rowData.status == 0) {
                    csvData[i].status = `Inactive`
                }
                else if (this.props.rowData.status == 2) {
                    csvData[i].status = `Suspend`
                }
                else {
                    csvData[i].status = `Error`
                }

            }
            this.setState({ usageCsvData: csvData })

        })

        )

    }

    /* componentWillReceiveProps(nextProps){
 
         if (nextProps.filterStartTime != this.state.filterStartTime || nextProps.filterEndTime != this.state.filterEndTime||nextProps.refreshTime!=this.state.refreshTime) {
             this.setState({
                 filterStartTime: nextProps.filterStartTime,
                 filterEndTime: nextProps.filterEndTime,
                 refreshTime:nextProps.refreshTime
             }, () => {
                 this.props.getIdentifierUsage({
                     type: this.props.type,
                     identifierType: nextProps.rowData.identifierType,
                     identifierValue: nextProps.rowData.identifierValue,
                     startTime: nextProps.filterStartTime,
                     endTime: nextProps.filterEndTime
                 }).then(() => {
                     let csvData = [...nextProps.dashboardIdentifierUsageData]
                     for (let i = 0; i < csvData.length; i++) {
                         csvData[i].client = nextProps.rowData.resellerName;
                         csvData[i].deviceName = nextProps.rowData.deviceName;
                         csvData[i].deviceType = nextProps.rowData.deviceType ? nextProps.rowData.deviceType : "null";
                         csvData[i].customerName = this.props.rowData.customerName,
                         csvData[i].iccid = nextProps.rowData.iccid
                         csvData[i].identifierValue = nextProps.rowData.identifierValue
                         csvData[i].planName = nextProps.rowData.planName
                         if (nextProps.isAdmin === true) {
                             csvData[i].planCode = nextProps.rowData.planCode
                         }
                         csvData[i].address1 = nextProps.rowData.addressLine1
                         csvData[i].address2 = nextProps.rowData.addressLine2
                         csvData[i].city = nextProps.rowData.city
                         csvData[i].zipcode = nextProps.rowData.zipcode
                         csvData[i].state = nextProps.rowData.state
                         csvData[i].country = nextProps.rowData.country
                         if (csvData[i].amt) {
                             csvData[i].usage = csvData[i].amt + " MB"
                         } else {
                             csvData[i].usage = csvData[i].amt + " KB"
                         }
 
                         if (nextProps.rowData.status == 1) {
                             csvData[i].status = `Active`
                         } else if (nextProps.rowData.status == 0) {
                             csvData[i].status = `Inactive`
                         }
                         else if (nextProps.rowData.status == 2) {
                             csvData[i].status = `Suspend`
                         }
                         else {
                             csvData[i].status = `Error`
                         }
 
                     }
                     this.setState({ usageCsvData: csvData })
 
                 })
             })
         }
     }*/

    createCustomExportCSVButton = (onClick) => {
        return (
            <ExportCSVButton
                btnText='Download CSV'
                onClick={() => this.handleExportCSVButtonClick(onClick)} />
        );
    }

    handleExportCSVButtonClick = (onClick) => {
        // Custom your onClick event here,
        // it's not necessary to implement this function if you have no any process before onClick
        onClick();
    }

    CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length && this.props.type != null) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`${this.props.type[0].toUpperCase() + this.props.type.substring(1)} `}</p>
                    <p className="intro">{label}</p>
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <p className="desc">{`Usage : ${payload[0].value} MB`}</p>}
                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <p className="desc">{`Usage : ${payload[0].value} `}</p>}
                </div>
            );
        }

        return null;
    };

    switchType(type) {
        this.setState({
            isChangedType: true,
            type: type
        })
        this.LoadUsageDate(type, this.props.filterStartTime, this.props.filterEndTime)
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };


    render() {
        //load
        let dayGap = 7;
        if (this.props.filterStartTime != null && this.props.filterEndTime) {
            dayGap = (new Date(this.props.filterEndTime).getTime() - new Date(this.props.filterStartTime).getTime()) / 86400000;
        }
        const options = {
            exportCSVBtn: this.createCustomExportCSVButton,
        };
        let headerFontColor = this.state.userObj && this.state.userObj.detail ? this.state.userObj.detail.headerFontColor : "white";
        let appFontColor = this.state.userObj && this.state.userObj.detail ? this.state.userObj.detail.appFontColor : "white";
        return (
            <div>
                <ResponsiveContainer
                    height={250}
                    width="98%"
                    maxWidth={300}
                >
                    <BarChart
                        data={this.state.data}
                        width={250} height={230}
                        margin={{ top: 17, right: 35, left: 0, bottom: 15, }}>

                        {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                            <XAxis
                                dataKey="name"
                                label={{ value: `No. of Frames (${this.state.type})`, offset: "-12", position: "insideBottom" }}
                            />}
                        {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                            <XAxis
                                dataKey="name"
                                label={{ value: `${this.state.type} data (in MB)`, offset: "-12", position: "insideBottom" }}
                            />}
                        {/*<XAxis  hide={true} dataKey="name" label={{ value: `${this.props.type} data (in MB)`, offset: "-12", position: "insideBottom" }}>*/}
                        <YAxis />
                        <Tooltip content={this.CustomTooltip} />
                        <CartesianGrid vertical={false} />
                        <Tooltip />
                        <Bar dataKey="amt" className="graphColor" />

                    </BarChart>

                </ResponsiveContainer>
                <Row className="container center" style={{ marginTop: "5px", width: "100%" }}>
                    <Button
                        color="custom"
                        className="box"
                        onClick={() => { this.switchType("daily") }}
                        disabled={(this.state.type == "daily")}
                    >
                        Daily
                    </Button>
                    <Button
                        color="custom"
                        className="box"
                        onClick={() => { this.switchType("weekly") }} disabled={(this.state.type == "weekly" || this.props.type == "daily") || dayGap < 6}
                    >
                        Weekly
                    </Button>
                    <Button
                        color="custom"
                        className="box"
                        onClick={() => { this.switchType("monthly") }}
                        disabled={(this.state.type == "monthly" || this.props.type == "weekly" || this.props.type == "daily") || dayGap < 7}
                    >
                        Monthly
                    </Button>

                    <CSVLink
                        className="btn btn-secondary"
                        color="custom"
                        filename={`${this.props.rowData.identifierValue}_usage.csv`}
                        style={{ color: "custom", fontSize: "14px", display: "block" }}
                        data={this.state.usageCsvData}
                        headers={this.state.usageCsvHeaders}
                    >
                        <i
                            className={"fa glyphicon glyphicon-export fa-download"}
                        >
                        </i>
                        &ensp;Download CSV
                    </CSVLink>

                </Row>

                <GoogleMap rowData={this.props.rowData}></GoogleMap>


            </div>
        );

    }


}
function mapStateToProps(state) {
    let { dashboardIdentifierUsageData } = state.user;

    let dashboardIdentifierUsageDataInMB = structuredClone(dashboardIdentifierUsageData);

    for (let element of dashboardIdentifierUsageDataInMB) {
        element.amt = (element.amt / 1024).toFixed(2);
        element.unit = "mb"
    }
    
    const props = { dashboardIdentifierUsageData, dashboardIdentifierUsageDataInMB };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getIdentifierUsage,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentifierUsage);

