/* eslint-disable */
import React, { Component } from 'react';
import {Row, Col, Card, CardBody, Label, Input, Button, Table} from 'reactstrap';
import { ChromePicker} from 'react-color';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AttributeSetModal from './AttributeSetModal';
import check_mark_circle from '../../../shared/img/icon/check_mark_circle.svg' 
import { ContentSaveSettingsOutlineIcon, OneTwoThreeIcon } from 'mdi-react';
import {EditTextButton, DeleteTextButton, PlusIconButton} from '../../../shared/components/Button/IconButton';

class AttributeSetCard extends Component{

    constructor(props){
        super(props);
        this.state={
           attris: [
                        // {display: true, name: 'Speed', type:0, setup:[{low:0,up:10,value:'#B53737'},{low:10,up:20,value:'#B53737'}]},
                        // {display: false, name: 'Temp', type:0, setup:[]},
                        // {display: true, name: 'Huli', type:1, setup:[{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#B53737'}]},
                        // {display: true, name: 'Attr1', type:0, setup:[{low:0,up:10,value:'#B53737'}]},
                        // {display: false, name: 'Attr2', type:0, setup:[]},
                    ],
           currentAttrId: -1,
           currentAttrName: '',
           currentAttrType: -1,
           attriSetup:[],

           currentEditCell:null,
           currentAttr: '',
           
           currentIsSwitch: false,
           currentColor: '#B53737',
           showColorPicker: false,
           modalSetVisible: false,

           currentIndex: -1,
           currentLowValue:0,
           currentUpValue:0,
           currentEditColor: '#ff0000'
        }
        this.renderAttributesTb = this.renderAttributesTb.bind(this);
        this.removeAtrr = this.removeAtrr.bind(this);
        // this.handleAttrRemoveClick = this.handleAttrRemoveClick.bind(this);
    }
    //--------------------------------------------------------------------
    static getDerivedStateFromProps(props, state){
        // if(props.isNew){
        //     return(
        //         {attris: [
        //             {display: false, name: 'Speed', type:0, setup:[]},
        //             {display: false, name: 'Temp', type:0, setup:[]},
        //             {display: false, name: 'Huli', type:1, setup:[]},
        //             {display: false, name: 'Attr1', type:0, setup:[]},
        //             {display: false, name: 'Attr2', type:0, setup:[]},
        //         ],}
        //     )
        // }else{
        //     return(
        //         // {attris: [
        //         //     {display: true, name: 'Speed', type:0, setup:[{low:0,up:10,value:'#B53737'},{low:10,up:20,value:'#B53737'}]},
        //         //     {display: false, name: 'Temp', type:0, setup:[]},
        //         //     {display: true, name: 'Huli', type:1, setup:[{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#B53737'}]},
        //         //     {display: true, name: 'Attr1', type:0, setup:[{low:0,up:10,value:'#B53737'}]},
        //         //     {display: false, name: 'Attr2', type:0, setup:[]},
        //         // ],}
        //         {attris: props.attris}
        //     )
        // }
        
        let currentAttrName = state.currentAttrName;
        let currentAttrId = state.currentAttrId;
        if (props.themeName !== state.themeName){    
            currentAttrId = -1;        
            currentAttrName = '';
        }
        return({
            attris: props.attris, 
            themeName: props.themeName, 
            currentAttrId: currentAttrId,
            currentAttrName: currentAttrName
        });
    }
    //--------------------------------------------------------------------
    onLowValueChange = (e) => {
        this.setState({
            currentLowValue: e.target.value
        });
    }

    onUpValueChange = (e) => {
        this.setState({
            currentUpValue: e.target.value
        });
    }

    onColorvalueChange = (e) => {
        this.setState({
            currentEditColor: e.target.value
        });
    }

    modalSetToggle = () => {
        this.setState(prevState => ({
            modalSetVisible: !prevState.modalSetVisible
        }));
    }

    setModalVisible = (visible) => {
        // alert(visible);
        this.setState({modalSetVisible: visible});
        // alert(this.state.modalVisible);
    }

    onColorChangeComplete = (color, event) => {
        this.setState({ currentEditColor: color.hex });
      };

    clearCurrentEditAttrSet = () => {
        this.setState({
            currentLowValue:0,
            currentUpValue:0,
            currentEditColor: '#ff0000'
        });
    };

    getAttributeSet = (i) => {
        if (this.state.attriSetup && Array.isArray(this.state.attriSetup) && i < this.state.attriSetup.length ){
            this.setState({
                currentLowValue: this.state.attriSetup[i].low,
                currentUpValue: this.state.attriSetup[i].up,
                currentEditColor: this.state.attriSetup[i].value
            })
        }
    }
    //--------------------------------------------------------------------
    // removeAtrr(attrName){
    //     let attrList = this.state.attris.slice();        
    //     attrList.map((item,i) => {
    //         if (item.name === attrName.trim()){
    //             item.display = false;
    //             item.setup = [];
    //         }
    //     });
    //     this.setState({
    //         attris: attrList,
    //         currentAttrName: '',
    //         currentAttrType: -1,
    //         attriSetup:[],
    //         currentLowValue:0,
    //         currentUpValue:0,
    //         currentEditColor: '#ff0000'
    //     })
    // };

    removeAtrr(attrId){
        // let attrList = this.state.attris.slice();        
        // attrList.map((item,i) => {
        //     if (item.name === attrName.trim()){
        //         item.display = false;
        //         item.setup = [];
        //     }
        // });
        
        this.setState(prevState => {
            let attrList = prevState.attris.map((item,i) => {
                if (item.id == attrId){
                    item.display = false;
                    item.setup = [];
                }
            });
             
             
            return({
                attris: attrList,
                currentAttrId: -1,
                currentAttrName: '',
                currentAttrType: -1,
                attriSetup:[],
                currentLowValue:0,
                currentUpValue:0,
                currentEditColor: '#ff0000'
            })          
        })
    };


    // editAtrr = (attrName) =>{
    //     let attr = this.state.attris.find((e, i) => (e.name === attrName));
    //     // attr.display = true;
    //     this.setState({
    //         currentAttrName: attr.name,
    //         currentAttrType: attr.type,
    //         attriSetup:attr.setup,
    //         currentIndex: -1,
    //         currentLowValue:0,
    //         currentUpValue:0,
    //         currentEditColor: '#ff0000'
    //     })
    // }

    editAtrr = (attrId) =>{
        
        // attr.display = true;
        this.setState(prevState => {
            let attr = prevState.attris.find((e, i) => (e.id == attrId));
             
             
            return ({
                currentAttrId: attr.id,
                currentAttrName: attr.name,
                currentAttrType: attr.type,
                attriSetup:attr.setup,
                currentIndex: -1,
                currentLowValue:0,
                currentUpValue:0,
                currentEditColor: '#ff0000'
            })            
        })
    }

    // addAtrr = (attrName) => {
    //     // alert(attrName);
    //     let attrList = this.state.attris.slice();
    //     //  
    //     let attr = attrList.find((e, i) => (e.name === attrName));
    //     //  
    //     if (!attr.display){
    //         attr.display = true;
    //         //  
    //         //  
    //         //  
    //         if (attr.type === 0){
    //             // alert("0");
    //             this.setState({
    //                 attris: attrList,
    //                 currentAttrName: attr.name,
    //                 currentAttrType: attr.type,
    //                 attriSetup: attr.setup,
    //                 currentLowValue:0,
    //                 currentUpValue:0,
    //                 currentEditColor: '#ff0000'
    //             })
    //         }
    //         else if (attr.type === 1){
    //             // alert("1");
    //             this.setState({
    //                 attris: attrList,
    //                 currentAttrName: attr.name,
    //                 currentAttrType: attr.type,
    //                 attriSetup:[{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#B53737'}],
    //                 currentLowValue:0,
    //                 currentUpValue:0,
    //                 currentEditColor: '#ff0000'
    //             })
    //         }
    //     }
    // }

    addAtrr = (attrId) => {
        
        this.setState(prevState => {
            let attr = prevState.attris.find((e, i) => (e.id == attrId));
             
             
            if (!attr.display){
                attr.display = true;
                if (attr.type === 0){
                    attr.setup = [];
                    return({
                        attris: prevState.attris,
                        // currentAttrId: attr.id,
                        // currentAttrName: attr.name,
                        // currentAttrType: attr.type,
                        // attriSetup: attr.setup,
                        // currentLowValue:0,
                        // currentUpValue:0,
                        // currentEditColor: '#ff0000'

                        currentAttrId: -1,
                        currentAttrName: '',
                        currentAttrType: -1,
                        attriSetup:[],
                        currentLowValue:0,
                        currentUpValue:0,
                        currentEditColor: '#ff0000'
                    })
                }
                else if (attr.type === 1){
                    attr.setup = [{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#1414C4'}];
                    return({
                        attris: prevState.attris,
                        // currentAttrId: attr.id,
                        // currentAttrName: attr.name,
                        // currentAttrType: attr.type,
                        // // attriSetup:[{low:0,up:0,value:'#B53737'},{low:1,up:1,value:'#B53737'}],
                        // attriSetup: attr.setup,
                        // currentLowValue:0,
                        // currentUpValue:0,
                        // currentEditColor: '#ff0000'

                        currentAttrId: -1,
                        currentAttrName: '',
                        currentAttrType: -1,
                        attriSetup:[],
                        currentLowValue:0,
                        currentUpValue:0,
                        currentEditColor: '#ff0000'
                    })
                }
                else{
                    return null;
                }
            }
        });
    }


    // renderAttributeSet(){
    //     return( null )
    // }

    // handleAttrEditClick =(e)=>{
    //     //  
    //     this.editAtrr(e.target.value);
    // }
    // handleAttrRemoveClick(e){
    //     this.removeAtrr(e.target.value);
    // }

    // handleAttrAddClick =(e) => {
    //     this.addAtrr(e.target.value);
    // }

    handleAttrEditClick2 =(value)=>{
        //  
        this.editAtrr(value);
    }
    handleAttrRemoveClick2(value){
        this.removeAtrr(value);
    }

    handleAttrAddClick2 =(value) => {
        this.addAtrr(value);
    }

    renderAttributesTb(){
         
        if (this.state.attris){
            return (
                this.state.attris.map((attr, i)=>{
                    if(attr.display){      
                        return(                
                            <tr  key={i}>
                                <td>
                                <img src={check_mark_circle} style={{width: 20, height: 30}} alt={"YES"}/>
                                </td>
                                <td>{attr.id}</td>
                                <td>{attr.name}</td>
                                <td>{attr.displayname}</td>
                                <td>
                                    {/* <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' value={attr.name} onClick={this.handleAttrEditClick}>Edit</Button> */}
                                    <EditTextButton label={'Edit'} value={attr.name} onClick={() => {this.handleAttrEditClick2(attr.id)}}/>
                                </td>
                                <td>
                                    {/* <Button outline variant="primary" color="danger" type="button" style={{margin:'auto'}} size='sm' value={attr.name} onClick={this.handleAttrRemoveClick}>Remove</Button> */}
                                    <DeleteTextButton  label={'Remove'} onClick={() => {this.handleAttrRemoveClick2(attr.id)}}/>
                                </td>
                            </tr>
                        )
                    }else{         
                        return(               
                            <tr  key={i}>
                                <td>
                                    {/* <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' value={attr.name} onClick={this.handleAttrAddClick} >Add</Button> */}
                                    <PlusIconButton  label={'Add'} onClick={()=> {this.handleAttrAddClick2(attr.id)}}/>
                                </td>
                                <td>{attr.id}</td>
                                <td>{attr.name}</td>
                                <td>{attr.displayname}</td>
                                <td>
                                    
                                </td>
                                <td>
                                    {/* <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' >Add</Button> */}
                                </td>
                            </tr>
                        )
                    }
                })
            )          
        }else{
            return null;
        }
    }
    //-------------------------------------------------------------------------------------------------------------------------------------------------
    // onResetClick = (event) => {
        
    //     if (this.state.currentAttrName){
    //         // alert(this.state.currentAttrName);
    //         let attr = this.state.attris.find((e, i) => (e.name === this.state.currentAttrName));
    //          
    //         if (attr) {
    //             this.setState({
    //                 attriSetup: attr.setup
    //             });
    //         }
    //     }
    // }

    onResetClick = (event) => {
        
        this.setState(prevState => {
            if (prevState.currentAttrName && prevState.currentAttrId) {
                let attr = this.state.attris.find((e, i) => (e.id == prevState.currentAttrId));
                if (attr) {
                    return({
                        attriSetup: attr.setup,

                        currentAttrId: -1,
                        currentAttrName: '',
                        currentAttrType: -1,
                        attriSetup:[],
                        currentLowValue:0,
                        currentUpValue:0,
                        currentEditColor: '#ff0000'
                    });
                }
            }
        });

    }

    // onOkClick = (e) => {
    //     this.setState((preState)=>{
    //         let attr = preState.attris.find((e, i) => (e.name === preState.currentAttrName));
    //         attr.setup = preState.attriSetup;
    //         return(
    //             {
    //                 attris: preState.attris,
    //                 currentAttrName: '',
    //                 currentAttrType: -1,
    //                 attriSetup:[],
    //                 currentIndex: -1,
    //                 currentLowValue:0,
    //                 currentUpValue:0,
    //                 currentEditColor: '#ff0000'

    //             }
    //         )
    //     })

    // }


    onOkClick = (e) => {
        this.setState((preState)=>{
            let attr = preState.attris.find((e, i) => (e.id == preState.currentAttrId));
            if (attr){
                attr.setup = preState.attriSetup;
                return(
                    {
                        attris: preState.attris,
                        currentAttrId: -1,
                        currentAttrName: '',
                        currentAttrType: -1,
                        attriSetup:[],
                        currentIndex: -1,
                        currentLowValue:0,
                        currentUpValue:0,
                        currentEditColor: '#ff0000'
    
                    }
                )
            }          
        })
    }

    // ----------------------------------------------------------------------------------------
    handleChangeComplete = (color, event) => {
        this.setState({ currentColor: color.hex });
      };

    handleShowColorClick = () => {
        this.setState(
            (preState) =>(
                {showColorPicker: !preState.showColorPicker}
            )
        )
    }

    closeColorPicker = () => {
        // alert('pass');
        this.setState(
            (preState) =>{
                if (preState.showColorPicker){
                    return({showColorPicker: false});
                }
            }
        )
    }

    handleRowColorClick = (e,i) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(
            (preState) =>(
                {
                    showColorPicker: !preState.showColorPicker,
                    currentIndex: i
                }
            )
        )
    }

    handleChangeComplete = (color, event, index) => {
        // alert('color');
        let curColor = color.hex;
        let curSetup = this.state.attriSetup.slice();
        let setObject = Object.assign({},curSetup[index])
        setObject.value= curColor;
        curSetup[index]= setObject;
        this.setState({ attriSetup: curSetup });
    };

    handleColorOnClick = (event) => { 
        // alert('click event');
        event.preventDefault();
        event.stopPropagation();
    }

    renderSwitchAttrSet(){
        return(
            <div>
                <h4>{this.state.currentAttrName}</h4>
                <Table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Color</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>
                            <td>Switch ON</td>
                            <td >
                                <input type='text' style={{float: 'left'}} value={this.state.currentColor} onClick={this.handleShowColorClick}></input>
                                <div style={{backgroundColor: this.state.currentColor, width: 20, height: 20, float: 'left'}} onClick={this.handleShowColorClick} ></div>
                                <div  style={{position:'relative'}}>
                                <div style={{position:'absolute',top:20, zIndex:100}}>
                                {this.state.showColorPicker &&
                                <ChromePicker
                                    color={this.state.currentColor}
                                    onChangeComplete={ this.handleChangeComplete }                                                                       
                                />}
                                </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Switch OFF</td>
                            <td>

                            </td>
                        </tr> */}
                        
                        {
                            this.state.attriSetup && 
                            this.state.attriSetup.map((item,i) => {
                                let td1;
                                let error = false;
                                if(item.low === item.up && item.up === 0) {
                                    td1 = 'Switch ON';
                                } 
                                else if (item.low === item.up && item.up === 1) {
                                    td1 = 'Switch OFF';
                                }else{
                                    error = true;
                                }
                                return(
                                    <tr key={i}>
                                        <td>{td1}</td>
                                        <td >
                                            
                                            {/* <input type='text' style={{float: 'left'}} value={item.value} onClick={(e) => {this.handleRowColorClick(e,i)}}></input> */}
                                            <div style={{backgroundColor: item.value, width: 20, height: 20, float: 'left'}} onClick={(e) => {this.handleRowColorClick(e,i)}}></div>
                                            <div style={{position:'relative'}}>
                                                <div style={{position:'absolute',top:20, zIndex:100}}>
                                                    {this.state.showColorPicker && i===this.state.currentIndex &&
                                                    <div onClick = {(event) => {this.handleColorOnClick(event)}} >
                                                    <ChromePicker
                                                        color={item.value}
                                                        onChangeComplete={ (color, event) => {this.handleChangeComplete(color, event, i)} }                                                                                                                              
                                                    />
                                                    </div>}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </Table>
                <div style={{textAlign: 'right', zIndex: -1}}>                   
                    <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' onClick={this.onResetClick}>Cancel</Button>{' '}
                    <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' onClick={this.onOkClick} >Save</Button>
                </div>
            </div>
        )
    }

    //----------------------------------------------------------
    handleCellOnclick = (e, i, name) =>{
        // let row = i;
        // let col = name;
        e.stopPropagation();
         
         
        this.setState({
            currentEditCell: {id:i,name:name}
        });
    } 
    handleRowOnclick = (e, i) => {
        e.stopPropagation();
        if (this.state.attriSetup && Array.isArray(this.state.attriSetup) && i < this.state.attriSetup.length ){
            this.setState({
                modalSetVisible: true,
                currentIndex: i,
                currentLowValue: this.state.attriSetup[i].low,
                currentUpValue: this.state.attriSetup[i].up,
                currentEditColor: this.state.attriSetup[i].value
            });
        }
    }

    handleNewSetClick = (visible) => {
        let lowValue = 0 ;
        if (this.state.attriSetup ) {
            lowValue = Math.max.apply(Math, this.state.attriSetup.map( (o) => { return o.up; }));
        }
        
        this.setState({
            modalSetVisible: visible,
            currentIndex: -1,
            currentLowValue: lowValue,
            currentUpValue: lowValue,
            currentEditColor: '#ff0000'
        });
        
    }

    handleRowRemoveButClick = (e) => {
        e.stopPropagation();
        let i = e.target.value;
        let newSetups ; //= this.state.attriSetup.slice();
        // alert(i);
        // newSetups.splice(i, 1);
        //  
        //  
        //  
        if (!isNaN(i) && this.state.attriSetup && Array.isArray(this.state.attriSetup) && i < this.state.attriSetup.length) {
            newSetups = this.state.attriSetup.slice();
            newSetups.splice(i, 1);
            this.setState({
                attriSetup: newSetups,
                currentLowValue:0,
                currentUpValue:0,
                currentEditColor: '#ff0000'
            });
            //  
        }

    }
    handleRowAddbutClick = (e) => {
        e.stopPropagation();
        // let i = e.target.value;
        let newSetups = this.state.attriSetup ? this.state.attriSetup.slice() : [];
        newSetups.push({low: this.state.currentLowValue, up: this.state.currentUpValue, value:this.state.currentEditColor});
        this.setState({
            attriSetup: newSetups,
            currentLowValue:0,
            currentUpValue:0,
            currentEditColor: '#ff0000'
        });
    }
    handleRowSaveButClick = (e) => {
        e.stopPropagation();
        // alert(this.state.currentUpValue);
        // alert(this.state.currentIndex);

        if(!this.validateValue()){
            toast.error('The value input was not number or Upper nubmer was smaller than Lower number');
            return;
        }

        let newSetups = this.state.attriSetup ? this.state.attriSetup.slice() : [];
        let newSet = {low: Number(this.state.currentLowValue), up: Number(this.state.currentUpValue), value:this.state.currentEditColor};
        let i = this.state.currentIndex;

        if (!this.validateRange(newSet, i)){
            toast.error('The input range can not overlap with other defined ranges');
            return;
        }

        // alert(i && !isNaN(i) && i < newSetups.length);
        if (i !== undefined && i!== null && !isNaN(i) && i < 0){
            newSetups.push(newSet);
        }
        else if(i !== undefined && i!== null && !isNaN(i) && i < newSetups.length)
        {
    
            newSetups[i] = newSet;
        }
        // alert(newSetups);
        //  
        newSetups.sort((a,b) => ( (a.low > b.low) ? 1 : (b.low > a.low) ? -1 : 0));
        this.setState({
            attriSetup: newSetups,
            currentLowValue:0,
            currentUpValue:0,
            currentEditColor: '#ff0000'
        });


    }
    renderAttrSetUpTbRow(item,i){
        const isEditModeLow = (this.state.currentEditCell && i === this.state.currentEditCell.id &&  'low' === this.state.currentEditCell.name);
        const isEditModeUp = (this.state.currentEditCell && i === this.state.currentEditCell.id &&  'up' === this.state.currentEditCell.name);
        // const isEditModeLow = false;
        // const isEditModeUp = false;


        return (
            <tr key={i} onClick={(e) => {this.handleRowOnclick(e, i)}}>
                <td onClick={(e)=>{false && this.handleCellOnclick(e, i, 'low')}}>
                    {
                        isEditModeLow ? (
                            <input type='text' col='low' row={i} value={item.low}></input>
                        ):(
                            <Label col='low' row={i}>{item.low}</Label>
                        )
                    }
                </td>
                <td onClick={(e)=>{false && this.handleCellOnclick(e, i, 'up')}}>
                    {
                        isEditModeUp ? (
                            <input type='text' col='up' row={i} value={item.up}></input>
                        ):(
                            <Label col='up' row={i}>{item.up}</Label>
                        )
                    }
                </td>
                <td>
                    <div style={{backgroundColor: item.value, width: 20, height: 20 }} ></div>
                </td>
                <td>
                    <Button outline variant="primary" color="danger" type="button" style={{margin:'auto'}} size='sm' value={i} onClick={this.handleRowRemoveButClick} >Remove</Button>
                </td>
            </tr>
        )
    }

    // onResetClick = (e) => {
    //     if (this.state.currentAttrName){
    //         let attr = this.state.attris.find((e, i) => (e.name === this.state.currentAttrName));
    //         if (attr) {
    //             this.setState({
    //                 attriSetup: attr.setup
    //             });
    //         }
    //     }
    // }

    renderValueAttrSet(){
         
         
        const isEditModeLow = false;
        return(
            <div>
            <h4>{this.state.currentAttrName}</h4>
            {/* <br/> */}
            <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} size='sm' onClick={() => {this.handleNewSetClick(true)}} >New Entry</Button>
            <Table>
                <thead>
                    <tr>
                        <th>Low Value</th>
                        <th>Up Value</th>
                        <th>Color</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.attriSetup && 
                        this.state.attriSetup.map((item,i) => (
                            this.renderAttrSetUpTbRow(item,i)
                        ))
                    }
                </tbody>
            </Table>
            <div style={{textAlign: 'right' }} >
                <Button outline variant="primary" color="custom" style={{margin:'auto'}} size='sm'onClick={this.onResetClick} >Reset</Button>{' '}
                <Button color="secondary" style={{margin:'auto'}} size='sm' onClick={this.onOkClick} >Save</Button>
                
            </div>
            </div>
        )
    }
    //----------------------------------------------------------
    renderAttributeSet(){
        if (!this.state.currentAttrName || 0 === this.state.currentAttrName.length){
            return null;
        }
        if (this.state.currentAttrType === 1){
             
            return(this.renderSwitchAttrSet());
            
        }else{
             
            return(this.renderValueAttrSet());
        }
    }
    //----------------------------------------------------------
    handelCardOnclick = () => {
        this.setState({
            currentEditCell: null
        });
    }

    handleSubmitOnclick = (e) => {
        this.props.handleSubmitOnclick( this.props.themeId, this.props.themeName, this.state.attris);
    }

    handleCancelOnclick = (e) => {
        this.props.handleCancelOnclick();
    }

    render(){
        return(
            <div onClick = {this.closeColorPicker}>
            <Col md={12}>
                <Card onClick={this.handelCardOnclick}>
                    <CardBody>
                        <h3>{this.props.themeName}</h3>
                        <br/>
                        <Row>
                            {/* <Col md={4}>
                                <Label for="deviceType" md={3}  >Device Type</Label>
                                <Input type="select" id="deviceType" name="deviceType" width="100%" ></Input>
                            </Col> */}
                            {/* <Col md={4}>
                                <Label for="messageType" md={3} style={{ width: "100%"}} >Message Type</Label>
                                <Input type="select" id="messageType" name="messageType" width="100%" ></Input>
                            </Col> */}
                            {/* <Col md={3}>
                                <Label for="attribute" md={3} style={{ width: "100%"}} >Attribute</Label>
                                <Input type="select" id="attribute" name="attribute" width="100%" ></Input>
                            </Col> */}
                            {/* <Col md={1} style={{ textAlign:'left',   flex: 1, justifyContent:'end', alignItems:'end', verticalAlign: 'bottom' }}>
                                <Button color="custom"  style={{margin:'auto'}} >Add</Button>
                            </Col> */}
                            <Col md={5}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>IsShown</th>
                                            <th>ID</th>
                                            <th>Attributes</th>
                                            <th>Display Name</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderAttributesTb()}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={5}>
                                {/* {this.renderSwitchAttrSet()} */}
                                {/* {this.renderValueAttrSet()} */}
                                {this.renderAttributeSet()}
                            </Col>
                        </Row>
                        <Row>
                           <Col md={12} style={{ textAlign: 'right' }}>
                                <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleCancelOnclick}>
                                    Cancel
                                </Button>{' '}
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleSubmitOnclick}>
                                    Submit
                                </Button>
                           </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <AttributeSetModal  modalSetVisible={this.state.modalSetVisible} 
                                lowvalue={this.state.currentLowValue} 
                                upvalue={this.state.currentUpValue} 
                                colorvalue={this.state.currentEditColor}
                                onLowValueChange={this.onLowValueChange}
                                onUpValueChange={this.onUpValueChange}
                                onColorValueChange={this.onColorChangeComplete}
                                toggle={this.modalSetToggle}
                                onSaveAttrSet = {this.handleRowSaveButClick}
            />
            </div>
        )
    }

    validateRange(newSet , i){

        let reVal = false;

        if (i !== undefined && i!== null && !isNaN(i) && i < 0){
            let newSetups = this.state.attriSetup ? this.state.attriSetup.slice() : [];
            reVal = this.validateRangeSet(newSetups, newSet); 
        }
        else if (i !== undefined && i!== null && !isNaN(i) && i < this.state.attriSetup.length)
        {
            let filteredSetups = this.state.attriSetup.filter((element, index) => (index !== i));
            reVal = this.validateRangeSet(filteredSetups, newSet); 
        }
        return reVal;
    }

    validateRangeSet (setList , set){
        setList.sort((a,b) => ( (a.low > b.low) ? 1 : (b.low > a.low) ? -1 : 0));
        for (let i = 0 ; i < setList.length; i++) {
            if ( setList[i].low === setList[i].up && set.low === set.up && set.low === setList[i].low){
                 
                return false;
            }
            if (set.low >= setList[i].low && set.low < setList[i].up){
                 
                return false;
            }
        }

        // for (let i = 0 ; i < setList.length; i++) {
        //     if (set.up >= setList[i].low && set.up < setList[i].up){
        //         return false;
        //     }
        // }

        for (let i = 0 ; i < setList.length-1 ; i++) {
            if (set.low >= setList[i].up && set.low < setList[i+1].low) {
                if (set.up > setList[i+1].low){
                     
                    return false;
                }
            }
        }

        return true;
    }

    validateValue = () => {
        if (isNaN(this.state.currentLowValue)){
            return false;
        }
        if (isNaN(this.state.currentUpValue)){
            return false;
        }
        let low = Number(this.state.currentLowValue);
        let up = Number(this.state.currentUpValue);
        // return (low <= up);
        return (low < up);
    }

}

export default AttributeSetCard;