/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { loadPeopleCountRecordsByBuildingAndDate } from '../../../../redux/actions/surveilenceActions'
import { postAssetAttribute, loadAssets } from '../../../../redux/actions/assetActions';
import { Row, Col, Label, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse } from 'reactstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import PeopleCountInfo from './PeopleCountInfo';
import PeopleCountGraph from './PeopleCountGraph';
import IndividualCamChart from './IndividualCamChart';
import './SmartBuilding.css'
import { EditIcon } from 'mdi-react';
import MultipleValueTextInput from 'react-multivalue-text-input';
import { LabelTextInput } from '../cameraList/CameraCreateModal';
import { Client } from '@stomp/stompjs';
import { WEB_SUB_SOCKET } from '../../../../constants/appConstants';
import { getJwttoken } from '../../../../factories/utils';
import { sendSMS, sendEmail } from '../../../../factories/utils';



export const renderSpinner = () => {
    return <Spinner
        className="spinner"
        as="span"
        animation="border"
        variant="success"
        // size="sm"
        // role="status"
        // aria-hidden="true"
        style={{ display: 'inline-block', margin: 'auto', position: "absolute" }}
    />;
}
const PeopleCountBoard = ({ location, peopleCountRecords, loadPeopleCount, postAssetAttribute, loadAssets, nvrIP, nvrChannelList, httpPort, mainNVR }) => {
    const [LastUpdateTime, setLastUpdateTime] = useState(null)
    const [FilteredDate, setFilteredDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
    const [CurrentLocationData, setCurrentLocationData] = useState(null)
    const [hourlyData, sethourlyData] = useState(null);
    const [LimitModal, setLimitModal] = useState(false)
    const [PeopleLimit, _setPeopleLimit] = useState(null)
    const [PeopleLimitInput, setPeopleLimitInput] = useState(null)
    const [alertReceivers, setalertReceivers] = useState([])
    const [stompClient, setstompClient] = useState(null)
    const [locationName, setlocationName] = useState('')
    const [stayCount, _setstayCount] = useState(null)
    const [alreadySend, setalreadySend] = useState(false)
    const [camNum, setCamNum] = useState(null)
    const [camList, setCamList] = useState(null);
    const PeopleLimitRef = useRef(PeopleLimit);
    const stayCountRef = useRef(stayCount);
    const setPeopleLimit = (data) => {
        PeopleLimitRef.current = data;
        _setPeopleLimit(data);
    }
    const setstayCount = (data) => {
        stayCountRef.current = data;
        _setstayCount(data);
    }



    useEffect(() => {
        // console.log('LOAD PEOPLE COUNT RECORDS.....')
        if (location) {
            // console.log("PC location,",location.assetname)
            let capacityLimit = location.assetattributes.find(el => el.attmetaname == 'capacity')
            if (capacityLimit) {
                // console.log('CAPACITY LIMIT: ',capacityLimit)
                setPeopleLimit(parseInt(capacityLimit.value))
                setPeopleLimitInput(parseInt(capacityLimit.value))
            }
            let lName
            if (location.assetname.includes("F1") || location.assetname.includes("F2")) {
                if (location.assetname.includes("F1")) {
                    lName = location.assetname.replace("F1", "").trim();
                } else {
                    lName = location.assetname.replace("F2", "").trim();
                }
                setlocationName(lName)
            } else {
                setlocationName(location.assetname)
            }
            // setlocationName(location.assetname.includes("Newcastle Main Plaza")? "Newcastle Main Plaza" : location.assetname)
        }
    }, [location])
    
    useEffect(() => {
        // console.log('LOAD PEOPLE COUNT RECORDS.....', locationName, FilteredDate.toString())
        if(locationName && locationName.length > 0){
            loadPeopleCount(locationName, FilteredDate.toString())
        }
    }, [locationName, FilteredDate])

    // useEffect(() => {
    //     loadPeopleCount();
    //     // setInterval(() => {
    //     //     loadPeopleCount()
    //     // }, 30000);
    //     connectSocket();
    // }, [])

    useEffect(() => {
        // console.log('PEOPLE LIMIT: ', PeopleLimit)
        // console.log('STAY COUNT: ', stayCount)
        if ((PeopleLimit !== null) && (stayCount !== null) && (stayCount >= PeopleLimit)) {
            // console.log('SHOULD SEND SMS', PeopleLimit, stayCount)
            // sendSMS(['6475511693'], `Warning: A capacity limit of ${PeopleLimit} has been reached at the ${plazaLocation}`)
            // sendSMS(["416-371-6616", "416-402-9449", "416-402-4558", "647293-4022"], `“You have reached the store capacity of 3 – action is required`)
            // sendSMS(alertReceivers, `You have reached the store capacity of ${PeopleLimit}– action is required`)
            // sendEmail(alertReceivers, `You have reached the store capacity of ${PeopleLimit}– action is required`, "Capacity limit reached at ITSP Office")
            // setPeopleLimit(null)
        }
        setalreadySend(true);
    }, [PeopleLimit, stayCount])

    useEffect(() => {
        if (stompClient) {
            // console.log('PPCount SOCKET CONNECTED', stompClient);
            stompClient.onConnect = connect_callback;
            stompClient.onDisconnect = disconnect_callback;
            stompClient.activate();
        }
    }, [stompClient])

    useEffect(() => {
        if (peopleCountRecords && peopleCountRecords.length > 0) {
            let dataByDate = peopleCountRecords.find(i => i.date === FilteredDate.toString());
            let localDataByDate = dataByDate?.data?.length > 0 ? dataByDate.data.find(i => i.building.includes(locationName)) : null;
            //Get last row of any cam's data to get last updated time
            if (localDataByDate && localDataByDate.data.length > 0) {
                let anyCam = localDataByDate.data[0]
                let lastRow = anyCam.data[anyCam.data.length - 1]
                setCurrentLocationData(localDataByDate.data);
                setLastUpdateTime(moment(lastRow.timestamp).local().format('YYYY-MM-DD HH:mm:ss'))
            }else{
                setCurrentLocationData(null)
                setLastUpdateTime(null)
            }
        }
    }, [peopleCountRecords, locationName]);


    const connectSocket = () => {
        if (stompClient !== null && stompClient !== undefined) {
            stompClient.deactivate();
            //stompClient = null;
        }

        let query = '?q=' + getJwttoken();
        ///////////////////////////////////////////////////
        setstompClient(new Client({
            // brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query,
            brokerURL: `${WEB_SUB_SOCKET}` + query,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000
        }));


        //////////////////////////////////////////////////
        //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
        //stompClient = Stomp.over(socket_URL);
        //stompClient.reconnectDelay=5000;
        //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;

    }

    const connect_callback = () => {
        //console.log('connected to websoket');
        // called back after the stompClient is connected and authenticated to the STOMP server
        // stompClient.subscribe('/topic/alarmLinePassing', callbackDeviceMessage);
        stompClient.subscribe('/topic/PPCountMessage', callbackDeviceMessage);
    }
    const disconnect_callback = () => {
        stompClient.deactivate();
        // called back after the stompClient is connected and authenticated to the STOMP server
        //console.log('client disconnected')

    }
    const callbackDeviceMessage = (message) => {
        // console.log('PPC DETECTED--------------------------')
        let messageBodyString = JSON.parse(JSON.stringify(message.body))
        let bodyObj = JSON.parse(messageBodyString)
        // console.log("BEFORE PARSED,", messageBodyString)
        // console.log("AFTER PARSED")
        // console.table(bodyObj)
        // console.log(messageObj["type"])
        // console.log('PEOPLE LIMIT', PeopleLimitRef.current, 'STAYING PPL',bodyObj.data.enterPersonCount - bodyObj.data.leavePersonCount)
        if (PeopleLimitRef.current && bodyObj && bodyObj.data.enterPersonCount && (bodyObj.data.enterPersonCount - bodyObj.data.leavePersonCount) == PeopleLimitRef.current) {
            // console.log('SHOULD SEND EMAIL')
            // sendSMS(['6475511693'], `Warning: A capacity limit of ${PeopleLimit} has been reached at the ${plazaLocation}`)
            // sendSMS(["416-371-6616", "416-402-9449", "416-402-4558", "647293-4022"], `You have reached the store capacity of 3 – action is required`)
            // sendSMS(alertReceivers, `You are currently at building capacity – action required`)
            sendEmail(alertReceivers, `You have reached the building capacity – action is required`, "Capacity limit reached at ITSP Office")

            // setPeopleLimit(null)
        }
        // loadPeopleCount();
    }


    const toggleCountLimit = () => {
        setLimitModal(!LimitModal)
    }

    const handleAddAlertReceiver = (phone) => {
        // console.log("=======ITEM ADDED", phone);
        let tempArr = alertReceivers;
        tempArr.push(phone);
        setalertReceivers(tempArr)
    }

    const handleDeleteReceiver = (phone) => {
        // console.log("ITEM DELETED",phone);
        let tempAlertReceivers = alertReceivers;
        if (tempAlertReceivers) {
            const index = tempAlertReceivers.indexOf(phone);
            if (index > -1) {
                tempAlertReceivers.splice(index, 1)
            }
            setalertReceivers(tempAlertReceivers)
        }
    }

    const handlePeopleLimitChange = e => {
        setPeopleLimitInput(e.target.value);
    }

    const handleStayCountChange = value => {
        setstayCount(value);
    }

    const handleLimitSubmit = () => {
        setPeopleLimit(PeopleLimitInput);
        if (location) {
            let attribute = location.assetattributes.find(el => el.attmetaname == 'capacity')
            if (attribute) {
                let param = {
                    assetid: attribute.assetid,
                    attmetaid: attribute.attmetaid,
                    value: PeopleLimitInput
                }

                // console.log('PARAM', param)

                updateCapacity(param)

            }
        }
        setLimitModal(false);
    }

    const handleDateChange = (date) => {
        if(date.toString()!== FilteredDate.toString()){
            setFilteredDate(date);
        }
    }


    const updateCapacity = (data) => {
        //console.log('UPDATE CAPACITY')

        postAssetAttribute(data).then(
            res => { loadAssets() }
        );
    }


        return (
            <>
                <Card>
                    <CardBody> 
                        {CurrentLocationData && <Row>
                            <Col md={6}>
                                <span style={{ marginTop: "50px" }}>
                                    <span style={{ fontWeight: "bold", fontSize: "1.5em", display: "inline" }}>
                                        Last Updated Date:
                                    </span>
                                    <p style={{ fontSize: "1.2rem", display: "inline" }}>{LastUpdateTime ? ' ' + LastUpdateTime : 'N/A'}</p>
                                </span>
                            </Col>
                        </Row>}
                        {CurrentLocationData && <Row className="box-container-ai">
                            <PeopleCountInfo localData={CurrentLocationData}/>
                        </Row>}
                        {!CurrentLocationData && <Row>
                                <span className="noData"> There is no data for the specified date at this location</span>
                            </Row>}
                        <Row className="box-container-graph">
                            {/* hidden cause it has not been implemented yet */}
                            {/* <Col md={4}>
                                <span className="capacity">
                                    Capacity Limit:
                                    <span className="limit" style={{ margin: '5px', padding: '10px' }}>{PeopleLimit}</span>
                                    <a type="button" className="limit-edit" onClick={toggleCountLimit}><EditIcon /></a>
                                </span>
                            </Col> */}
                            <PeopleCountGraph
                                localData={CurrentLocationData}
                                limit={PeopleLimit}
                                loadPeopleCount={loadPeopleCount}
                                camNum={camNum}
                                camList={camList}
                                handleDateChange = {handleDateChange}
                                filterDate = {FilteredDate}
                            />
                        </Row>
                        <IndividualCamChart
                            localData={CurrentLocationData}
                            loadPeopleCount={loadPeopleCount}
                            nvrIP={nvrIP}
                            nvrChannelList={nvrChannelList}
                            httpPort={httpPort}
                            mainNVR={mainNVR}
                        />
                    </CardBody>
                </Card>

                <Modal toggle={toggleCountLimit} isOpen={LimitModal}>
                    <ModalHeader toggle={toggleCountLimit} tag="h3">Set Building Capacity Limit</ModalHeader>
                    <ModalBody>
                        <LabelTextInput tag="h5" forIdName="peopleLimit" label="Number of People Allowed" value={PeopleLimitInput} type="text" onChange={handlePeopleLimitChange} />
                        {/* <FormGroup row>
                            <Col md={3}>
                                <Label tag="h5" htmlFor={"alertReceiver"}>Alert Receiver(s)</Label>
                            </Col>
                            <Col md={9}>
                                <MultipleValueTextInput
                                    name="receivers-input"
                                    values={alertReceivers}
                                    onItemAdded={(item, allItems) => handleAddAlertReceiver(item)}
                                    onItemDeleted={(item, allItems) => handleDeleteReceiver(item)}
                                />
                            </Col>
                        </FormGroup> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleLimitSubmit}>Set Limit</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
}


const mapStateToProps = (state) => ({
    peopleCountRecords: state.surveillance.peopleCountRecords

})

const mapDispatchToProps = (dispatch) => {
    return {
        loadPeopleCount: (building, date) => dispatch(loadPeopleCountRecordsByBuildingAndDate(building, date)),
        postAssetAttribute: (data) => dispatch(postAssetAttribute(data)),
        loadAssets: () => dispatch(loadAssets()),
        dispatch,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PeopleCountBoard)

