/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row, Button, Input } from 'reactstrap';
import { BackButton, NextButton } from '../../../shared/components/Button/IconButton';


class ClientPlanList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }

    priceFormatter = (price, billCycle) => {
        var per = "";
        if (billCycle == 30) {
            per = "/mo"
        }
        else if (billCycle == 365) {
            per = "/yr"
        }
        return `$ ${price.toFixed(2)}${per}`;
    }

    dataFormatter = (data) => {
        if (data < 1024) {
            return (`${data} MB`);
        }
        if (data >= 1024) {
            var gb = (data / 1024).toFixed(2)
            return (`${gb} GB`);
        }
    }
    onModalOpen = (plan) => {
        this.props.onPlanOpen(plan)
    }
    render() {
        if (this.props.ServicePlanList != null) {
            return (
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                    {this.props.ServicePlanList.map((plan, index) => {
                        let buttonText = "Edit";
                        let buttonColor = "custom";
                        return (
                            <div className="itemList">
                                {(plan.resellerId == null) ?
                                    <div style={{ display: "inline-block", margin: "0px 15px 15px 20px" }}>
                                        <Card className="pricing-card" style={{ "box-shadow": "0px 3px 12px 4px rgba(0, 0, 0, 0.16)" }}>
                                            <CardBody className="pricing-card__body">
                                                <h3 className="pricing-card__plan" style={{ fontWeight: "bold" }}>{plan.itemName}</h3>
                                                <hr />
                                                {(this.props.isAdmin) ? <p className="pricing-card__feature">{(plan.carrier != null) ? plan.carrier.carrierName : ""}</p> : null}
                                                <p className="pricing-card__feature">{this.priceFormatter(plan.unitPrice, plan.billCycle)}</p>
                                                <p className="pricing-card__feature">{this.dataFormatter(plan.planDataVolume)}</p>
                                                {(plan.extensions.length > 0) ?
                                                    plan.extensions.filter((item, index) => (item.type == "activationFee" && item.status == 1)).map((feature, index) =>
                                                        < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Activation, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                    )
                                                    : ``}
                                                {(plan.extensions.length > 0) ?
                                                    plan.extensions.filter((item, index) => (item.type == "feature" && item.status == 1)).map((feature, index) =>
                                                        < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Feature, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                    )
                                                    : ``}
                                                {(plan.extensions.length > 0) ?
                                                    plan.extensions.filter((item, index) => (item.type == "overage" && item.status == 1)).map((feature, index) =>
                                                        < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Overage, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                    )
                                                    : ``}
                                                {(plan.extensions.length > 0) ?
                                                    plan.extensions.filter((item, index) => (item.type == "sms" && item.status == 1)).map((feature, index) =>
                                                        < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`SMS, ${feature.description}, $${!feature.price ? '0.00' : parseFloat(feature.price).toFixed(2)}`}</p>
                                                    )
                                                    : ``}
                                                {(plan.itemPayType === "PAYG") ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Pay As You Go</p> : ``}
                                                {(plan.autoRenewFlag === 1) ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>AutoRenewal</p> : ``}
                                                {(this.props.isAdmin) ? <Button className="pricing-card__button" color={buttonColor} onClick={() => this.onModalOpen(plan)}>{buttonText}</Button> : null}
                                            </CardBody>
                                        </Card>
                                    </div>
                                    :
                                    <div style={{ display: "inline-block", margin: "0px 15px 15px 20px" }}>
                                        <Card className="pricing-card" style={{ "box-shadow": "0px 3px 12px 4px rgba(0, 0, 0, 0.16)" }}>
                                            <CardBody className="pricing-card__body">
                                                <h3 className="pricing-card__plan" style={{ fontWeight: "bold"}}>{plan.itemName}</h3>
                                            <hr />
                                            {(this.props.isAdmin) ? <p className="pricing-card__feature">{(plan.carrier != null) ? plan.carrier.carrierName : ""}</p> : null}
                                            <p className="pricing-card__feature">{this.priceFormatter(plan.unitPrice, plan.billCycle)}</p>
                                            <p className="pricing-card__feature">{this.dataFormatter(plan.planDataVolume)}</p>
                                            {(plan.extensions.length > 0) ?
                                                plan.extensions.filter((item, index) => (item.type == "activationFee" && item.status == 1)).map((feature, index) =>
                                                    < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Activation, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                )
                                                : ``}
                                            {(plan.extensions.length > 0) ?
                                                plan.extensions.filter((item, index) => (item.type == "feature" && item.status == 1)).map((feature, index) =>
                                                    < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Feature, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                )
                                                : ``}
                                            {(plan.extensions.length > 0) ?
                                                plan.extensions.filter((item, index) => (item.type == "overage" && item.status == 1)).map((feature, index) =>
                                                    < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`Overage, ${feature.description}, $${feature.price.toFixed(2)}`}</p>
                                                )
                                                : ``}
                                            {(plan.extensions.length > 0) ?
                                                plan.extensions.filter((item, index) => (item.type == "sms" && item.status == 1)).map((feature, index) =>
                                                    < p className="pricing-card__feature" style={{ fontWeight: "bold" }}>{`SMS, ${feature.description}, $${!feature.price ? '0.00' : parseFloat(feature.price).toFixed(2)}`}</p>
                                                )
                                                : ``}
                                            {(plan.itemPayType === "PAYG") ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Pay As You Go</p> : ``}
                                            {(plan.autoRenewFlag === 1) ? <p className="pricing-card__feature" style={{ fontWeight: "bold", color: "green" }}>Auto-Renew</p> : ``}
                                            {(this.props.isAdmin) ? <Button className="pricing-card__button" color={buttonColor} onClick={() => this.onModalOpen(plan)}>{buttonText}</Button> : null}
                                        </CardBody>
                                    </Card>
                                    </div>
                                }
                            </div>
            )

        })
    }


                </div>
            );
        }
        else {
    return (
        <div>Loading...</div>
    )
}
    }


}
export default ClientPlanList;