/* eslint-disable */
import React, {Component} from 'react';
 import {Form, Label, Row, Col, Input, Button, CardBody} from "reactstrap";



class DashboardTemplateNew extends Component {
    inputVal;
    constructor(props) {
        super(props);

        console.log('this is inside DashboardTemplateNew constructor')
    }


    onSubmit=()=>{
         
        this.props.onHandleNewTemplateSubmit(this.inputVal);
        this.inputVal = null;
}

    onClose=()=>{
        this.props.onHandleNewTemplateClose();
    }
    onInputChange=(val)=>{

        //this.props.handleInputChange(val.target.value)
        this.inputVal = val.target.value
        console.log('on input change and this.inputVal', this.inputVal)
    }


    render() {
        return (
            <div>
                <Form>
                    <Row>
                        <Col md={6}>
                            <Label for="emailMessage" style={{display: 'inline', width: "100%"}}>Template Name</Label>
                            <Input
                                type="text"
                                id="emailMessage"
                                name="name"
                                width="100%"
                                onChange={(e) => {
                                    this.onInputChange(e)
                                }}
                                // value={name}

                            >
                            </Input>
                        </Col>
                    </Row>
                </Form>
                <br/>
                <div style={{textAlign:'left'}}>
                    <Button
                        outline color='custom'
                        type="button"
                        onClick={this.onSubmit}
                        // disabled = {!this.validateForm()}
                    >
                        Submit
                    </Button>
                    <Button
                        outline
                        color='secondary'
                        type="button"
                        onClick={this.onClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}



export default DashboardTemplateNew
