/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Container, Card, CardBody, CardFooter, Row, Col, Button, Label, UncontrolledTooltip } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import DonutWidget from './DonutWidget';
import BatteryWidget from './BatteryWidget';
import AttribureIcon from './AttributeIcon';
import {
    ATTRIBUTE_TYPE,
    ATTRIBUTE_NAME,
    TIME_ZONE,
    WHITE_COLOR,
    GREEN_COLOR,
    RED_COLOR,
    ORENGE_COLOR,
    YELLOW_COLOR,
    GREY_COLOR,
    LIGHT_GREY_COLOR,
    ATTRIBUTE_ORDER,
    ATTRIBUTE_WIND_ORDER,
    ALARM_META_ATTRI_OBJ,
    LEGEND_LIST,
    xxSmallTextStyle,
    xSmallTextStyle,
    smallTextStyle,
    normalTextStyle,
} from '../../../constants/dataConstants';
import { lightningColorMaper } from '../../../factories/weather';
import styled, { keyframes, css } from "styled-components";
import './blinking.scss';
import { ConsoleIcon, RelativeScaleIcon } from 'mdi-react';

const valueRowStyle = {
    margin: '5px 10px',
    padding: '5px',
    justifyContent: 'center',
    // lineHeight: '30px',
    // minHeight: '30px'
}

const rowClass = {
    // color: 'black', 
    // fontWeight: 'normal',
    margin: '5px 10px',
    padding: '5px 30px',
    justifyContent: 'space-evenly', //'center'
    alignContent: 'center',
    lineHeight: '30px',
    height: '30px',
    // backgroundColor: GREEN_COLOR, //hasrule ? GREEN_COLOR : GREY_COLOR,
    color: 'white',
    position: 'relative',
    // animation: 'alertBlink 1s infinite',
}
const backClass = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: GREEN_COLOR, //hasrule ? GREEN_COLOR : GREY_COLOR,
    animation: 'alertBlink 1s infinite',
}
const testClass = {
    color: 'black',
    position: 'absolute',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    fontSize: '2.5vh',
    lineHeight: '2.5vh'
}

// const smallTextStyle = {
//     fontSize: '1.5vh',
//     lineHeight: '1.5vh',
// }

// const normalTextStyle = {
//     fontSize: '2vh',
//     lineHeight: '2vh',
// }


const AttributeListWidget = ({ alarmSet, asset, isSmall, toggle, activeTab }) => {
    // let valArray = null;
    // let asset = asset ? asset : null
    let alarmObj = alarmSet ? alarmSet[asset.assetid] : null;
    let donutData = setDonut(alarmObj, asset.attributes)

    // return <div style={{ minWidth: '195px', maxWidth: '400px' }}>
    return <div style={{ minWidth: '196px', maxWidth: '400px' }}>
        <Row style={{ padding: '5px', justifyContent: 'center' }}>
            <DonutWidget status={donutData.status} color={donutData.color} />
        </Row>
        <Row style={valueRowStyle}>
            {/* <span style={normalTextStyle}>{assetnameFormat(asset, toggle)}</span> */}
            <span style={{fontSize: '1rem', lineHeight: '1rem', whiteSpace: 'nowrap', overflow: 'hidden'}}>{assetnameFormat(asset, toggle)}</span>
        </Row>
        <Row style={valueRowStyle}>
            {/* <span style={smallTextStyle}>{timestampFormat(asset.timestamp)}</span> */}
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{timestampFormat(asset.timestamp)}</span>
        </Row>
        {(activeTab == 1) ?
            ATTRIBUTE_ORDER && ATTRIBUTE_ORDER.filter(item => item.display).map(attr => (
                <AttributeDisplayElement
                    alarmObj={alarmObj}
                    attr={attr}
                    asset={asset}
                    isSmall={isSmall}
                />
            ))
            :
            ATTRIBUTE_WIND_ORDER && ATTRIBUTE_WIND_ORDER.filter(item => item.display).map(attr => (
                <AttributeDisplayElement
                    alarmObj={alarmObj}
                    attr={attr}
                    asset={asset}
                    isSmall={isSmall}
                />
            ))
    
    }

    </div>
}

const AlarmDisplayElement = ({ attr, val, isSmall }) => {
    const key = attr.attributename;
    // const val = key === 'hexAlarm' ? asset[key] : null ;
    const colorVal = lightningColorMaper(val ? val.value : null);
    const status = val ? val.status : false;
    const displayObj = LEGEND_LIST.find(item => (item.color === colorVal));

    // return <Row style={rowClass}>
    //     {/* <Col md={12} > */}
    //     <div style={backClass}></div>
    //     <div style={testClass}>
    //         {isSmall && 
    //             <span style={{alignSelf: 'flex-start'}}>
    //                 <AttribureIcon attributeName={key}/>
    //             </span>
    //         }
    //         <span>{displayObj.label}{' '}{attr.units}</span>
    //     </div>
    //     {/* </Col> */}
    // </Row>
    return <Row style={rowClassGnerator(status, null, key, colorVal, true, isSmall)}>
        {isSmall &&
            <span style={{ alignSelf: 'flex-start' }}>
                <AttribureIcon attributeName={key} />
            </span>
        }
        {/* <span style={{ ...(displayObj && displayObj.label && displayObj.label.length < 15 ? smallTextStyle : xSmallTextStyle), width: isSmall ? '80%' : '100%', textAlign: isSmall ? 'right' : 'center' }}> */}
        <span style={{ ...(displayObj && displayObj.label && displayObj.label.length < 15 ? smallTextStyle : xSmallTextStyle), width: isSmall ? '80%' : '100%', textAlign: isSmall ? 'right' : 'center', fontSize: '1rem', lineHeight: '1rem' }}>
            {displayObj ? displayObj.label : ''}
            {/* {' '}{attr.units} */}
        </span>
    </Row>
}

const BatteryDisplayElement = ({ alarmObj, attributeName, valArray, isSmall }) => {
    return (
        <Row style={rowClassGnerator(false, null, attributeName, 'red', true, isSmall)}>
            {valArray && valArray.map((val, index) => {
                var deviceIdRegNero = new RegExp("1B30D[a-zA-Z0-9]{2}|NS[0-9]{8}");
                if (!deviceIdRegNero.test(val.deviceid + val.devicename)) {
                    let value = parseFloat(val.value);
                    const id = `battery_${index}`;
                    if (alarmObj && alarmObj[val.deviceid] && alarmObj[val.deviceid][attributeName]) {
                        value = alarmObj[val.deviceid][attributeName].value;
                    }
                    let level = 0
                    let percentage = "0%";
                    if (Number.isNaN(value)) {
                        level = -1;
                        percentage = "No Battery Detected";
                    }
                    if (!Number.isNaN(value) && value > 3.6 && value < 4.3) {
                        //full battery: 4.3 | Empty battery 3.6
                        level = Math.round((((value - 3.6) / .7) * 5) - .5);
                        percentage = Math.round(((value - 3.6) / .7) * 100) + "%"
                    }
                    if (value >= 4.3) {
                        level = 4;
                        percentage = "100%"
                    }
                    // 
                    let tooltiptext = !val.devicename || val.devicename.length == 0 ? 'No device name - ' + val.deviceid+" - "+percentage : val.devicename + " - "+ val.deviceid+" - " + percentage;
                    return (
                        <Fragment>
                            <Tooltip title={tooltiptext}>
                                <div id={id}>
                                    <BatteryWidget level={level} style={{ width: '23px', height: '45px' }} />
                                </div>
                            </Tooltip>
                            {/* <UncontrolledTooltip placement="auto" target={id}>
                            {val.devicename}
                        </UncontrolledTooltip> */}
                        </Fragment>
                    )
                }
            })}

        </Row>
    )
}

const AttributeDisplayElement = ({ alarmObj, attr, asset, isSmall }) => {
    const attributeName = attr.attributename;
    let valArray = null;
    let status = false;
    let groupData = null;

    if (asset.attributes && Array.isArray(asset.attributes[attributeName]) && asset.attributes[attributeName].length > 0) {
        valArray = asset.attributes[attributeName];
        if (attr.group) {
            groupData = []

            attr.group.forEach(item => {
                let valObj = {
                    ...item,
                    displayVal: null,
                };
                if (asset.attributes[item.attributename] && asset.attributes[item.attributename].length > 0) {
                    valObj = {
                        ...item,
                        displayVal: asset.attributes[item.attributename][0],
                    }
                }
                groupData.push(valObj);
            });
        }

    }
    //  
    let displayVal = valArray ? valArray[0] : null;

    // if (displayVal && alarmObj && alarmObj[displayVal.deviceid] && alarmObj[displayVal.deviceid][attributeName] ){
    //     status = alarmObj[displayVal.deviceid][attributeName].status ? true : false;
    // }

    if (attributeName === 'hexAlarm') {
        return (
            <AlarmDisplayElement attr={attr} val={displayVal} isSmall={isSmall} />
        )
    }
    else if (attributeName === 'Battery') {
        return (
            <BatteryDisplayElement alarmObj={alarmObj} attributeName={attributeName} valArray={valArray} isSmall={isSmall} />
        )
    } else {
        if (attributeName != "CO2") {
            status = displayVal ? displayVal.status : false;
            if (displayVal && alarmObj && alarmObj[displayVal.deviceid] && alarmObj[displayVal.deviceid][attributeName]) {
                status = alarmObj[displayVal.deviceid][attributeName].status ? true : false;
            }
            let groupVal = '';
            let temp = '';
            let templow = '';
            let temphigh = '';
            if (attributeName === 'Temperature' && groupData && groupData.length === 3) {

                groupData.forEach((val, index) => {
                    if (val.displayVal && val.displayVal.value) {
                        groupVal = groupVal + val.displayVal.value + val.units;
                        groupVal = groupVal + (index == 0 ? ' - ' : index == 1 ? ' + ' : ' ');
                    }
                });
                temp = groupData[1].displayVal.value;
                templow = groupData[0].displayVal.value;
                temphigh = groupData[2].displayVal.value;

                if(temp == null){
                    templow = temp;
                    temphigh = temp;
                }
            }
            // ------------- style test -----------------------
            let alarmColor = 'red';
            let blink = keyframes`
            from {background-color: white; color: black; font-weight: bold;}
            to {background-color: ${alarmColor}; color: white; font-weight: bold;}
        `;

            // animation: css`${blink} 1s infinite`,
            // const animation = props =>
            // css`
            //     ${pulse} ${props.animationLength} infinite alternate;
            // `
            // const StyledRow = styled(Row)`
            // animation: ${blink} 1s infinite;
            // `

            // ------------------------------------------------

            return (

                <Row style={rowClassGnerator(status, null, attributeName, (displayVal ? (displayVal.value ? GREEN_COLOR : GREY_COLOR) : GREY_COLOR), attr.hasrule, isSmall)}>
                    {isSmall &&
                        <span style={{ alignSelf: 'flex-start' }}>
                            <AttribureIcon attributeName={attributeName} />
                        </span>
                    }
                    {attributeName === 'Temperature' && groupData && groupData.length === 3 ?
                        // groupData.map(val => (
                        //     <span style={{fontSize:'x-small'}}>{val.displayVal ? val.displayVal.value : ''}{' '}{val.units}</span>
                        // ))
                        // <span class="subsup" style={smallTextStyle}>
                        // <span class="subsup" style={{fontSize: '1.5vh', lineHeight: '1.5vh', position: 'relative'}}>
                        <span class="subsup" style={{fontSize: '0.9rem', lineHeight: '0.9rem'}}>
                            {temp ? temp : ''}{' '}{attr.units}
                            <sup>{temphigh}{temp ? attr.units : ""}</sup>
                            <sub>{templow}{temp ? attr.units : ""}</sub>
                        </span>
                        :
                        // <span style={normalTextStyle}>{displayVal ? displayVal.value : ''}{' '}{attr.units}</span>
                        <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{displayVal ? displayVal.value : ''}{' '}{attr.units}</span>
                    }
                    {/* {groupVal && groupVal.length > 0 ?
                    <span style={{fontSize:'x-small'}}>{groupVal}</span>
                    :
                    <span>{displayVal ? displayVal.value : ''}{' '}{attr.units}</span>
                } */}
                    {/* <span>{displayVal ? displayVal.value : ''}{' '}{attr.units}</span> */}
                </Row>

            )
        } else {
            return (<span></span>);
        }
    }
}

const assetnameFormat = (cell, toggle) => {
    const handleDeviceOnClick = (e, assetname) => {
        // alert('yes');
        //  
        e.preventDefault();
        toggle(true, cell);
    }
    return (
        <a href={'#'} onClick={(e) => { handleDeviceOnClick(e, cell.assetname); }}>{cell.assetname}</a>
    )
}

const timestampFormat = (cell) => {
    if (cell) {
        if(cell.charAt(0)=="+"){
            cell=new Date().getFullYear()+cell.substr(6);
        }
        let displayTime = new Date(cell).toLocaleString('en-US', { timeZone: TIME_ZONE });
        // return (<span style={{fontSize:'x-small'}}>{displayTime}</span>);
        return displayTime;
    } else {
        return null;
    }
}

function rowClassGnerator(status, item, attributeName, color = GREEN_COLOR, hasrule = false, isSmall = false) {
    let backgroupdColor = attributeName === 'Battery' ? 'white' : hasrule ? color : GREY_COLOR;
    let horizontalAlign = attributeName != 'Battery' && isSmall ? 'space-between' : 'space-evenly'; //'center';
    let alarmColor = backgroupdColor;

    if (attributeName !== 'hexAlarm') {
        alarmColor = RED_COLOR;
    }

    // var blink = keyframes`
    //     from {background-color: white; color: black; font-weight: bold;}
    //     to {background-color: ${alarmColor}; color: white; font-weight: bold;}
    // `;
    // `
    // 0% { transform: rotate(0deg); }
    // 100% { transform: rotate(360deg); }
    // `
    let reClass = {
        // color: 'black', 
        // fontWeight: 'normal',
        margin: '5px 10px',
        // padding: '5px 30px',
        padding: '6px 30px',
        justifyContent: horizontalAlign, //'center'
        alignContent: 'center',
        // lineHeight: '30px',
        // height: '30px',
        lineHeight: isSmall ? null : '30px',
        height: isSmall ? null : '30px',
        // height: '3vh',
        backgroundColor: backgroupdColor, //hasrule ? GREEN_COLOR : GREY_COLOR,
        // backgroundColor: YELLOW_COLOR, //hasrule ? GREEN_COLOR : GREY_COLOR,
        color: 'black',
        // position: 'relative',
        // position: 'static',
        // position: 'absolute',
        // '&::after':{
        //     content: '',
        //     width: '100%', 
        //     height: '100%',
        //     backgroundColor: backgroupdColor, 
        //     position: 'absolute',
        //     top: '0',
        //     left: '0',
        //     zIndex: '-1',
        // }
    };

    if (status == true) {
        return ({
            ...reClass,
            // animation: `${blink} 1s infinite`,
            backgroundColor: alarmColor,
            animation: 'alertBlink 1s infinite',
        });
    } else {
        return ({
            ...reClass,
            // backgroundColor: backgroupdColor, //hasrule ? GREEN_COLOR : GREY_COLOR,
            // color: 'white',
        })
    }

    // testing 
    // return ({
    //     ...reClass,
    //     // '::after':{
    //     //     content: '',
    //     //     width: '100%', 
    //     //     height: '100%',
    //     //     backgroundColor: backgroupdColor, 
    //     //     position: 'absolute',
    //     //     top: '0',
    //     //     left: '0',
    //     //     zIndex: '-1',
    //     //     animation: 'alertBlink 1s infinite',
    //     // }
    //     animation: 'alertBlink 1s infinite',
    //  });
}

// function rowClassGnerator(alarmSet, item, attributeName,  hasrule = false, isSmall = false) {
//     let backgroupdColor = attributeName === 'Battery' ? 'white': hasrule ? GREEN_COLOR : GREY_COLOR;
//     let horizontalAlign = attributeName != 'Battery' && isSmall ? 'space-between': 'space-evenly'; //'center';
//     let reClass = {
//         // color: 'black', 
//         // fontWeight: 'normal',
//         margin: '5px 10px',
//         padding:'5px 30px', 
//         justifyContent: horizontalAlign, //'center'
//         alignContent: 'center',
//         lineHeight: '30px',
//         height: '30px',
//         backgroundColor: backgroupdColor, //hasrule ? GREEN_COLOR : GREY_COLOR,
//         color: 'white'
//     };

//     // if (this.props.alarmObj && this.props.alarmObj.msgList){
//     //     for (let alarm of this.props.alarmObj.msgList) {
//     //         if (item.assetname == alarm.assetname && alarm.attributeList && alarm.attributeList.includes(attributeName) && this.props.alarmObj.status == true) {
//     //             return ({
//     //                 padding:'5px', 
//     //                 justifyContent: 'center',
//     //                 alignContent: 'center',
//     //                 lineHeight: '30px',
//     //                 height: '30px',
//     //                 animation: 'fontBlink 1s infinite',
//     //             });
//     //         }
//     //     }
//     // } 
//      
//     if (alarmSet && alarmSet[item.assetid] && alarmSet[item.assetid][attributeName] && alarmSet[item.assetid][attributeName].status == true){

//         return ({
//             margin: '5px 10px',
//             padding:'5px 30px', 
//             justifyContent: horizontalAlign, //'center',
//             alignContent: 'center',
//             lineHeight: '30px',
//             height: '30px',
//             animation: 'fontBlink 1s infinite',
//         });
//     }
//     return reClass;
// }

function setDonut(alarmObj, attributes) {


    let reValue = { status: 0, color: 'white' };
    if (attributes && attributes['hexAlarm'] && attributes['hexAlarm'].length > 0) {
        const color = lightningColorMaper(attributes['hexAlarm'][0].value);
        /*
        if(attributes['hexAlarm'][0].value !== "1111"){
            for(let order in attributes){
                if (attributes[order][0].value === null){
                    color = lightningColorMaper("1111");
                    break;
                }
            }
        }
        */
        const status = attributes['hexAlarm'][0].status ? 1 : 0;
        reValue = {
            ...reValue,
            status,
            color,
        }
    }
    if (attributes) {
        ATTRIBUTE_ORDER && ATTRIBUTE_ORDER.filter(item => item.display && item.attributename != 'hexAlarm').forEach(attr => {
            let attributename = attr.attributename;
            Array.isArray(attributes[attributename]) && attributes[attributename].forEach(vattr => {
                if (vattr.status) {
                    reValue = {
                        ...reValue,
                        status: 1,
                        color: RED_COLOR,
                    }
                }
            });
        });
    }
    if (alarmObj) {
        for (let key in alarmObj) {
            for (let attr of ATTRIBUTE_ORDER) {
                if (attr.hasrule == true && alarmObj[key][attr.attributename] && alarmObj[key][attr.attributename].status == true) {
                    reValue = {
                        ...reValue,
                        status: 1, //0, 
                        color: RED_COLOR,
                    }
                }
            }
        }
    }

    return reValue
}

export default AttributeListWidget;