/* eslint-disable */
import axios from 'axios';
import {
    RULE_LOAD_DEVICE_TYPE_SUCCEED,
    RULE_LOAD_DEVICE_TYPE_FAILED,
    RULE_LOAD_DEVICE_SUCCEED,
    RULE_LOAD_DEVICE_FAILED,
    RULE_GET_RULE_SUCCEED,
    RULE_GET_RULE_FAILED,
    RULE_UPSERT_RULE_SUCCEED,
    RULE_UPSERT_RULE_FAILED,
    RULE_DELETE_RULE_SUCCEED,
    RULE_DELETE_RULE_FAILED,
    USER_GET_FAILED,
} from '../../constants/actionTypes';
import {WEB_SERVICE_ROOT} from '../../constants/appConstants';
import {axiosGet, getCurrentUser, base64Encode, getJwttoken} from '../../factories/utils';
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    let url=document.URL;
    let split = url.split("\/");
    if(split[3]=='#') {
        localStorage.setItem("com_name","null")
    }else{
        localStorage.setItem("com_name",split[3])
    }
    const token = getJwttoken();
    config.headers.Authorization =  `Bearer ${token}`;
    return config;
});

function loadDeviceTypeSucceed(response){
    const action = {
        type: RULE_LOAD_DEVICE_TYPE_SUCCEED,
        payload: response.data
    };

    return action;
}

function loadDeviceTypeFailed(error){
    const action = {
        type: RULE_LOAD_DEVICE_TYPE_FAILED,
        payload: error.message
    };

    return action;
}

export function loadRuleDeviceType(){
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){


      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    // const urlStr = 'http://13.88.246.7:8080/sigfox/Devicetypes';
    const urlStr = `${WEB_SERVICE_ROOT}Devicetypes`;

    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ( (dispatch) => {
            request.then( (response) => {  dispatch(loadDeviceTypeSucceed(response))})
                   .catch( (error) => {dispatch(loadDeviceTypeFailed(error))})
        }
    );
}

// -------------------------------------------------------------------------------------
function loadRuleDeviceSucceed(response){
    const action = {
        type: RULE_LOAD_DEVICE_SUCCEED,
        payload: response.data
    };

    return action;
}

function loadRuleDeviceFailed(error){
    const action = {
        type: RULE_LOAD_DEVICE_FAILED,
        payload: error.message
    };

    return action;
}
export function loadRuleDevice(criteria){
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0){


      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }

    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};
    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    }
    // const urlStr = `http://13.88.246.7:8080/sigfox/Device?${queryString}`;
    const urlStr = `${WEB_SERVICE_ROOT}Device?${queryString}`;

    const request = axios({
        url: urlStr,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return (
        (dispatch)=>{
            request.then((response)=>{ dispatch(loadRuleDeviceSucceed(response))})
                    .catch((error)=>{ dispatch(loadRuleDeviceFailed(error))})
        }
    );
}

// ------------------------------------------------------------------------------------------------
function getRuleSucceed(response){
    const action = {
        type: RULE_GET_RULE_SUCCEED,
        payload: response.data
    };

    return action;
}

function getRuleFailed(error){
    const action = {
        type: RULE_GET_RULE_FAILED,
        error: error.message
    };

    return action;
}

export function getRuls(criteria){
    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
    }
    const urlStr = `${WEB_SERVICE_ROOT}Rule?${queryString}`;
    // const urlStr = `http://13.88.246.7:8080/sigfox/Rule?${queryString}`;
    // const urlStr = `http://192.168.2.91:8080/Rule?${queryString}`;

    const request = axios({
        url: urlStr,
        method: 'get',
    });

    return(
        (dispatch) => {
            request.then((response) => {dispatch(getRuleSucceed(response))})
            .catch((error) => {dispatch(getRuleFailed(error))})
        }
    );
}

// ------------------------------------------------------------------------

function upsertRuleSucceed(response){
    const action = {
        type: RULE_UPSERT_RULE_SUCCEED,
        payload: response.data
    };

    return action;
}

function upsertRuleFailed(error){
    const action = {
        type: RULE_UPSERT_RULE_FAILED,
        error: error.message
    };

    return action;
}


export function upsertRule(ruleObj){




    // const urlStr = 'http://192.168.2.91:8080/Rule';
    // const urlStr = 'http://13.88.246.7:8080/sigfox/Rule';
    const urlStr = `${WEB_SERVICE_ROOT}Rule`;
    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {'Accept': 'application/json'},
        data: ruleObj,
    });

    return(
        (dispatch) => {
            request.then((response)=>{dispatch(upsertRuleSucceed(response))})
            .catch((error)=>{dispatch(upsertRuleFailed(error))})
        }
    );
}

// -----------------------------------------------------------------------

function deleteRuleSucceed(response){
    const action = {
        type: RULE_DELETE_RULE_SUCCEED,
        payload: response.data
    };

    return action;
}

function deleteRuleFailed(error){
    const action = {
        type: RULE_DELETE_RULE_FAILED,
        error: error.message
    };

    return action;
}

export function deleteRule(ruleObj){
    // const urlStr = `http://192.168.2.91:8080/Geofence`;
    let typeid = ruleObj.devicetypeid;
    let ruleid = ruleObj.ruleid;
    // const urlStr = `http://192.168.2.91:8080/Rule?devicetypeid=${typeid}&ruleid=${ruleid}`;
    // const urlStr = `http://13.88.246.7:8080/sigfox/Rule?devicetypeid=${typeid}&ruleid=${ruleid}`;
    const urlStr = `${WEB_SERVICE_ROOT}Rule?devicetypeid=${typeid}&ruleid=${ruleid}`;
    const request = axios.delete(
        urlStr,
        {
            headers: {'Accept': 'application/json'},
            data: ruleObj,
        }
    );
    return (
        (dispatch)=>{
            request.then((response)=>{dispatch(deleteRuleSucceed(response))})
                    .catch((error)=>{dispatch(deleteRuleFailed(error))})
        }
    );
}