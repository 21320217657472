/* eslint-disable */
import React, { Component, useState } from 'react';
import {WHITE_COLOR, GREEN_COLOR, RED_COLOR, ORENGE_COLOR, YELLOW_COLOR, GREY_COLOR} from '../../../constants/dataConstants';
import './blinking.scss';

const DonutWidget = (props) => {
    let {status, statusHex, style, isHiden, color} = props;

    let width= style && style.width ? style.width: "80";
    let height= style && style.height ? style.height: "80";
    // let viewBox= style && style.viewBox ? style.viewBox: "0 0 140.66001 407.84";
    let viewBox= style && style.viewBox ? style.viewBox: "0 0 150 150";
    const cx = 75;
    const cy = 75;
    // const ri = 35;
    const ri = 0;
    // const ro = 70;
    const ro = 50;
    const strokeWidth = ro - ri;
    const strokeR = ri + (strokeWidth/2);
    // const pathStr = "M 50 10 A 40 40 0 1 0 50 90 A 40 40 0 1 0 50 10 Z M 50 30 A 20 20 0 1 1 50 70 A 20 20 0 1 1 50 30 Z";   
    // const pathStr = `M ${cx-ro} ${cy} A ${ro} ${ro} 0 1 0 ${cx+ro} ${cy} A ${ro} ${ro} 0 1 0 ${cx-ro} ${cy} Z M ${cx-ri} ${cy} A ${ri} ${ri} 0 1 1 ${cx+ri} ${cy} A ${ri} ${ri} 0 1 1 ${cx-ri} ${cy} Z`;

    // const pathStr = 'M100 100m-75,0a75,75,0 1,0 150,0a 75,75 0 1,0 -150,0zM100 100m-46,0a46,46,0 0,1 92,0a 46,46 0 0,1 -92,0z';
    const pathStr = `M ${cx-ro} ${cy} a ${ro} ${ro} 0 1 0 ${2*ro} 0 a ${ro} ${ro} 0 1 0 ${-2*ro} 0 z M ${cx-ri} ${cy} a ${ri} ${ri} 0 1 1 ${2*ri} 0 a ${ri} ${ri} 0 1 1 ${-2*ri} 0 z`;

    const pulseClass = 'pulse'
    let lightColor = WHITE_COLOR;
    let lightBlink = null;
    lightColor = isHiden ? WHITE_COLOR : "#000000";
   
    if (statusHex) {
         
        lightColor = WHITE_COLOR;
        lightBlink = null;

        if (statusHex.charAt(3) === '0'){
            lightColor = GREEN_COLOR;
            lightBlink = null;
        }else {
            if (statusHex.charAt(0) === '0') {
                lightColor = RED_COLOR;
                lightBlink = status==1 ? pulseClass: null;
            } 
            else if (statusHex.charAt(1) === '0') {
                lightColor = ORENGE_COLOR;
                lightBlink = status==1 ? pulseClass: null;
            } 
            else if (statusHex.charAt(2) === '0') {
                lightColor = YELLOW_COLOR;
                lightBlink = status==1 ? pulseClass: null;
            }
            else if (statusHex.charAt(0) === '1' && statusHex.charAt(1) === '1' && statusHex.charAt(2) === '1') {
                lightColor = GREY_COLOR;
                lightBlink = null;
            }
        }
    }

    if (color) {
        lightColor = color;
        lightBlink = status==1 ? pulseClass: null;
    }

    return (
        <svg
            id="svg2"
            // viewBox="0 0 140.66001 307.84"
            // // viewBox="0 0 1406.6001 3078.4"
            viewBox = {viewBox}
            preserveAspectRatio="xMidYMid meet"
            version="1.0"
            width = {width}
            height = {height}
        >
            {/* <circle 
                class="donut-ring" 
                cx={cx} 
                cy={cy} 
                r={strokeR} 
                fill="transparent" 
                // stroke="#d2d3d4" 
                stroke={lightColor}
                stroke-width={strokeWidth}
            >
                {
                    lightBlink === pulseClass &&
                    <animate 
                        attributeType="CSS" 
                        attributeName="stroke-opacity" 
                        from="1" 
                        to="0" 
                        dur="1s" 
                        repeatCount="indefinite" 
                    />
                }
            </circle> */}

            <path 
                d={pathStr} 
                // fill="#0000dd" 
                stroke={lightColor}
                stroke-width="0" 
                fill={lightColor}
                // stroke="black"
                // className= {lightBlink} // css animation
            >
                {
                    lightBlink === pulseClass &&
                    <animate 
                        attributeType="CSS" 
                        attributeName="opacity" 
                        from="1" 
                        to="0" 
                        dur="1s" 
                        repeatCount="indefinite" 
                    />
                }
            </path>
        </svg>
    );
}

export default DonutWidget;