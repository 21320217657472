/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Col, Row, Label, Input, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import { DeviceMultiSelection, SingleSelection } from '../../../Asset/components/SelectionComponents';
import { connect } from "react-redux";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { postAsset, loadAssets, addAssetAttribute, updateOneAsset, postAssetAttribute, loadClasses } from '../../../../redux/actions/assetActions';
import { getCurrentUser } from '../../../../factories/utils'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
// import './slider.css';

const CameraCreateModal = ({ toggleModalVisible, modalVisible, postAsset, loadAssets, postAssetAttribute, updateOneAsset, cameraClass, deviceTypes, deviceList }) => {
    const [CameraAttributes, setCameraAttributes] = useState(cameraClass);
    const [CameraClass, setCamerClass] = useState(cameraClass);
    const [DeviceTypes, setDeviceTypes] = useState(deviceTypes);
    // const [DeviceList, setDeviceList] = useState(deviceList);
    useEffect(() => {
        if (cameraClass !== null && cameraClass !== undefined) {

            setCameraAttributes(cameraClass.attributeMetas);
            setCamerClass(cameraClass);
        }
    }, [cameraClass]);
    useEffect(() => {
        if (deviceTypes !== null && deviceTypes !== undefined) {

            setDeviceTypes(deviceTypes);
        }
    }, [deviceTypes]);
    useEffect(() => {
        //Get default information from current user
        let user = JSON.parse(sessionStorage.getItem('currentUserObj'));
        setCameraData({ ...CameraData, companyname: user.company.companyName, companyaddress: user.company.addressline1 })
    }, []);
    const [DefaultData, setDefaultData] = useState({
        assetid: 0, //create new camera
        assetname: "Camera",
        assetclassid: 158, // 212,
        assetclassname: "Camera_Base",
        description: "",
        devices: [],
        parentasset: null
    });
    const [CameraData, setCameraData] = useState({
        name: '',
        companyname: '',
        contactemail: '',
        contactphone: '',
        camnum: '',
        camdesc: '',
        camlocat: '',
        camstat: false,
        startdate: '',
        companyaddress: '',
        // posx: '',
        // posy: '',
        pos: '',
        user: ''
    });

    const { name, companyname, contactemail, contactphone, camnum, camdesc, camlocat, camstat, startdate, companyaddress } = CameraData;
    const { devices } = DefaultData
    //Create single attribute for the camera
    //Param includes assetid, attmetaid and value
    const createSingleAttribute = (attrData) => {
        postAssetAttribute(attrData).then(res => { loadAssets() });
    }
    const createCamera = () => {

        // console.log(postData)
        // console.log(data)  
        postAsset(DefaultData)
            .then(res => {
                //  
                if (res && res.status === 200 && res.data && res.data.length > 0) {
                    let resData = res.data[0]
                    // this.props.loadClasses()
                    //  
                    updateOneAsset({ assetid: resData.assetid, data: resData });
                    let user = JSON.parse(sessionStorage.getItem('currentUserObj'));
                    for (const attribute in CameraData) {
                        let data;
                        if (attribute == 'name') {
                            let attid = CameraAttributes.find(att => att.name == 'name').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'companyname') {
                            let attid = CameraAttributes.find(att => att.name == 'company').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: user.company.companyName
                            }
                        }
                        if (attribute == 'contactemail') {
                            let attid = CameraAttributes.find(att => att.name == 'contact email').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'contactphone') {
                            let attid = CameraAttributes.find(att => att.name == 'contact phone').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'camnum') {
                            let attid = CameraAttributes.find(att => att.name == 'camera number').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'camdesc') {
                            let attid = CameraAttributes.find(att => att.name == 'camera description').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'camlocat') {
                            let attid = CameraAttributes.find(att => att.name == 'camera location').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'camstat') {
                            let attid = CameraAttributes.find(att => att.name == 'status').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'startdate') {
                            let attid = CameraAttributes.find(att => att.name == 'updated date').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'companyaddress') {
                            let attid = CameraAttributes.find(att => att.name == 'company address').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        // if(attribute == 'posx'){
                        //     let attid = CameraAttributes.find(att=> att.name == 'position_x').attributemetaid
                        //     data = {
                        //         assetid: resData.assetid,
                        //         attmetaid: attid,
                        //         value: CameraData[attribute]
                        //     }
                        // }
                        // if(attribute == 'posy'){
                        //     let attid = CameraAttributes.find(att=> att.name == 'position_y').attributemetaid
                        //     data = {
                        //         assetid: resData.assetid,
                        //         attmetaid: attid,
                        //         value: CameraData[attribute]
                        //     }
                        // }
                        if (attribute == 'pos') {
                            let attid = CameraAttributes.find(att => att.name == 'position').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: CameraData[attribute]
                            }
                        }
                        if (attribute == 'user') {
                            let attid = CameraAttributes.find(att => att.name == 'updated by').attributemetaid
                            data = {
                                assetid: resData.assetid,
                                attmetaid: attid,
                                value: user.userName
                            }
                        }
                        createSingleAttribute(data)
                    }
                    // let userdata = JSON.parse(getCurrentUser());
                    // let userattrmetaid = CameraAttributes.find(att=> att.name == 'updated by').attributemetaid;
                    // let data = {
                    //     assetid: resData.assetid,
                    //     attmetaid: userattrmetaid,
                    //     value: userdata.userName
                    // }
                    // createSingleAttribute(data)
                    // loadAssets();
                    // if (successCallback) {
                    //    successCallback();
                    // }
                } else {
                    if (res && res.status === 406) {
                        toast.error('There are alerts/reports that associate with the Asset, you can not delete it.');
                    } else {
                        toast.error('Errors occured during deleting the asset');
                    }
                    //  if(errorCallback){
                    //     errorCallback();
                    //  }
                }
                // let resData = res.data[0]
                // // this.props.loadClasses()
                // this.props.updateOneAsset({ assetid: resData.assetid, data: resData });
                // this.props.loadDeviceInfo();
                //   loadAssets();
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        createCamera();
        toggleModalVisible();
    }

    const handleChangeField = name => e => {
        if (name == 'name') {
            setDefaultData({ ...DefaultData, assetname: e.target.value })
            //console.log('Default data changed, ', DefaultData)
        }
        setCameraData({ ...CameraData, [name]: name == 'camstat' ? e.target.checked : e.target.value })
    }

    const handleSelectChange = selectedOptions => {
        console.log('select device', { ...DefaultData, devices: selectedOptions || [] })
        setDefaultData({ ...DefaultData, devices: selectedOptions || [] })
    }

    const groupedDevices = dtList => dtList.map(dt => ({
        label: dt.name,
        options: deviceList.filter(d => d.devicetype == dt.id)
    }))

    return (
        <>
        <Modal
           isOpen={modalVisible}
           toggle={toggleModalVisible}
        >
            <ModalHeader toggle={toggleModalVisible}>New Camera</ModalHeader>
            <ModalBody>
                <LabelTextInput forIdName="name" label="Name" value={name} type="text" onChange={handleChangeField('name')}/>
                <LabelTextInput disabled={true} forIdName="companyname" label="Company Name" value={companyname} type="text" onChange={handleChangeField('companyname')}/>
                <LabelTextInput forIdName="contactemail" label="Contact Email" value={contactemail} type="text" onChange={handleChangeField('contactemail')}/>
                <LabelTextInput forIdName="contactphone" label="Phone" value={contactphone} type="text" onChange={handleChangeField('contactphone')}/>
                <LabelMultiSelectInput cameraClass={cameraClass} deviceTypes={deviceTypes} groupedDevices={groupedDevices} onChange={handleSelectChange} value={devices}/>
                <LabelTextInput forIdName="camnum" label="Cam Num" value={camnum} type="number" onChange={handleChangeField('camnum')}/>
                <LabelTextInput forIdName="camdesc" label="Cam Description" value={camdesc} type="textarea" onChange={handleChangeField('camdesc')}/>
                <LabelTextInput forIdName="camlocat" label="Cam Location" value={camlocat} type="text" onChange={handleChangeField('camlocat')}/>
                <LabelTextInput forIdName="camstat" label="Status ON" value={camstat} type="checkbox" onChange={handleChangeField('camstat')}/>
                <LabelTextInput forIdName="startdate" label="Start Date" value={startdate} type="date" onChange={handleChangeField('startdate')}/>
                <LabelTextInput disabled={true} forIdName="companyaddress" label="Company Address" value={companyaddress} type="textarea" onChange={handleChangeField('companyaddress')}/>
                {/* <LabelTextInput forIdName="posx" label="Postion X" value={posx} type="number" onChange={handleChangeField('posx')}/> */}
                {/* <LabelTextInput forIdName="posy" label="Position Y" value={posy} type="number" onChange={handleChangeField('posy')}/> */}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={handleSubmit}>Add Camera</Button>
                <Button color="custom" onClick={toggleModalVisible}>Cancel</Button>
            </ModalFooter>
        </Modal>
     </>
    );
};

const mapToStateProps = (state) => ({
    cameraClass: state.asset.assetClassList.find(c => c.name == 'Camera_Base'),
    deviceList: state.asset.currentDeviceList.filter((item) => (item.assetid == null || item.assetid == 0)).map(d => ({ id: d.deviceid, name: d.devicename, devicetype: d.devicetype, devicetypename: d.devicetypename })),
    deviceTypes: state.mapboard.types
})
export default connect(mapToStateProps, {
    postAsset,
    loadAssets,
    postAssetAttribute,
    updateOneAsset
})(CameraCreateModal);

export const LabelTextInput = ({ forIdName, type, onChange, label, tag, value, err, disabled }) => {
    return (
        <FormGroup row>
            <Col md={3} style={{ fontSize: '14px', textAlign: 'left' }}>
                <Label style={{fontSize:'14px'}} tag={tag} htmlFor={forIdName}>{label}</Label>
            </Col>
            <Col md={8}>
                {type !== 'checkbox' && <Input type={type} disabled={disabled} width="90%" style={{ fontSize: '14px', marginBottom: 5, borderColor: err ? 'red' : '' }}
                    value={value}
                    id={forIdName}
                    name={forIdName}
                    onChange={onChange}
                />
                }
                {type === 'checkbox' && <Input type={type} style={{ fontSize: '14px', marginLeft: 5, borderColor: err ? 'red' : '' }}
                    checked={value}
                    id={forIdName}
                    name={forIdName}
                    onChange={onChange}
                />}
                {/* {type === 'checkbox' && 
                    <span class="slider round"></span>
                } */}
            </Col>
        </FormGroup>
    )
}

const LabelMultiSelectInput = ({ cameraClass, deviceTypes, groupedDevices, onChange, value }) => {
    return (
        <FormGroup row>
            <Col md={3} style={{ textAlign: 'left' }}>
                <Label htmlFor='react-select-2-input'>Devices</Label>
            </Col>
            <Col md={8} style={{ fontSize: '14px', textAlign: 'left' }}>
                <DeviceMultiSelection
                    // options={this.props.groupedDevices}
                    options={groupedDevices(
                        deviceTypes.filter(dt =>
                            cameraClass.allowedDeviceTypes.some(dT => dT.id == dt.id))
                    )}
                    onChange={onChange}
                    value={value}
                />
            </Col>
        </FormGroup>
    )
}