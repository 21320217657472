/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Card, CardBody, Col, Row, Button, Container } from 'reactstrap';
import { Trans } from "react-i18next";
// import { connect } from 'react-redux';
// import { withRouter } from "react-router-dom";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
// import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import PlusIcon from 'mdi-react/PlusIcon';
import { EditTextButton, DeleteTextButton } from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
// import {getCurrentUser, hasRole} from '../../../factories/auth';



class ContactList extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        // this.props.getCompanyList();
    }

    handleRowEditClick = (row) => {
        // this.props.handleRowEditClick(row);
        this.props.editContact(row.id);
    }

    handleRowDeleteClick = (row) => {
        // let code = row.companyCode; 
        // let selectedCompany = this.props.companyList.find((element,index) => (element.companyCode === code));
        // if (selectedCompany) {
        //     this.props.deleteCompany(selectedCompany);
        // }
        // this.props.handleRowDeleteClick(row);
        this.props.deleteContact(row.id);
    }

    tableActionColFormater = (cell, row) => {
        // const check1 =  this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN']);
        // const check2 = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        // const check2 = this.props.userObj && this.props.userObj.userName === row.userName;
        // const disabled = !(check1 || check2);
        return (
            <div>
                {/* { check1 && */}
                <Fragment>
                    <EditTextButton label={''} onClick={(e) => { this.handleRowEditClick(row); }} />{' '}
                    {/* <DeleteTextButton label={"Remove"} onClick={(e) => { this.handleRowDeleteClick(row); }}/> */}
                    {/* { check2 && */}
                    {/* <ConfirmDeleteDialog 
                                label={""} 
                                onConfirm={(e)=>{this.handleRowDeleteClick(row);}}
                                msg={`Do you want to delete the select Contact ${row.name} ?`}
                            /> */}
                    {/* } */}
                </Fragment>
                {/* } */}
            </div>
        )
    }

    tableDeleteColFormater = (cell, row) => {
        return (
            <div>
                <Fragment>
                    <ConfirmDeleteDialog
                        label={""}
                        onConfirm={(e) => { this.handleRowDeleteClick(row); }}
                        msg={`Do you want to delete the select Contact ${row.name} ?`}
                    />
                </Fragment>
            </div>
        )
    }

    // tableAddressFormater = (cell, row) => {

    //     return (
    //         <p>
    //             {row.addressline1} <br/>
    //             {row.addressline2 ? row.addressline2 : ''}{' '} 
    //             {row.city ? row.city : ''}{' '}
    //             {row.province ? row.province : ''}{' '}
    //             {row.postcode ? row.postcode : ''}{' '}
    //             {row.country ? row.country : ''}
    //         </p>
    //     )
    // }

    // handleAddNewButtonClick = (onClick) => {
    //     this.props.handleAddNewClick();

    // }

    // addNewUserButton = (onClick) => {
    //     const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
    //     return (

    //         <InsertButton
    //         //   btnText='Add Company'
    //           btnContextual='btn-success'
    //           btnGlyphicon='glyphicon-edit'
    //         //   color='success'
    //           onClick={ () => this.handleAddNewButtonClick(onClick) }
    //         //   disabled = {!check}
    //           >
    //               {/* <PlusCircleOutlineIcon size={100}/> */}
    //               {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
    //               <PlusIcon/> Add Company
    //           </InsertButton>
    //       );
    // }

    renderCompanyList = () => {
        // const check = this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN']);
        const options = {
            // insertBtn: this.addNewUserButton,
        };
        return (
            <BootstrapTable
                data={this.props.contactList}
                tableHeaderClass = 'header_white' 
                // tableBodyClass = 'header_white'
                pagination={false}
                options={options}


                search
                // insertRow = {check}
                bordered={false}
                maxHeight={500}
            >
                <TableHeaderColumn dataField='id' isKey={true} hidden >ID</TableHeaderColumn>
                <TableHeaderColumn dataField='name' dataSort  >Name</TableHeaderColumn>
                <TableHeaderColumn dataField='phone' dataSort  >Phone</TableHeaderColumn>
                <TableHeaderColumn dataField='email' dataSort   >Email</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='owner' dataAlign="center" dataSort >Create By</TableHeaderColumn>      */}
                <TableHeaderColumn dataAlign="center"
                    width="15%" dataFormat={this.tableActionColFormater} >Edit</TableHeaderColumn>
                <TableHeaderColumn dataAlign="center"
                    width="15%" dataFormat={this.tableDeleteColFormater} >Delete</TableHeaderColumn>
            </BootstrapTable>
        )
    }

    render() {
        return (
            <Col md={12}>
                <Card>
                    {/* <CardBody> */}
                    {this.renderCompanyList()}
                    {/* </CardBody> */}
                </Card>
            </Col>
        )
    }
}

export default ContactList
