/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from 'redux'
import {connect} from "react-redux";
import ReactDOM from 'react-dom';
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline} from 'google-maps-react';
import {Container, Row, Col, Button, Table, Input} from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import MapInfoWindow from './MapInfoWindow';
import {loadAllGeoFences} from '../../../redux/actions/fenceAction';
import {generateSelectOptions, groupBy, getMaxItem} from '../../../factories/utils';
import {getCurrentUser} from '../../../factories/auth';
import MarkerClusterer from "@google/markerclusterer";

export class MapDeviceContainer extends Component{
    constructor(props){
        super(props);
        this.initMap = this.initMap.bind(this);
        this.myMap = React.createRef();
        // this.state = {
        //     showingInfoWindow: false,
        //     activeMarker: {},
        //     selectedPlace: {},
        //     markers: [
        //                 {lat:-1.2884,lng:36.8233,info:'test1'}, 
        //                 {lat:-1.2684,lng:36.8033,info:'test2'}, 
        //                 {lat:-1.3084,lng:36.8433,info:'test3'}
        //             ]
        // };
        this.state={
            msg:''
        };
        this.userObj = getCurrentUser();
        if (!this.userObj) {
            toast.error('Can not fetch login user information, Please re-login.');
            this.props.history.push('/log_out');
        }else{
             
             
        }
         
         
        // this.initMap = this.initMap.bind(this);
    }

    componentDidMount(){
        this.props.loadAllGeoFences();

        // if (this.myMap && this.myMap.current){
        //     let mapComponent = this.myMap.current;
        //     // let google = mapComponent.props.google;
        //      
           
        // }
    }

    componentWillReceiveProps(nextProps){
        let fenceOptions = [];
        // if (nextProps.mapData){
        //     this.showPathOnMap(nextProps.mapData) ;
        // }
        if (nextProps.allfences){
            fenceOptions = generateSelectOptions(nextProps.allfences, 'fencename', 'fenceid');
        }
        fenceOptions.unshift({
            label: "None", 
            value: 0,
        });
        this.setState({
            fenceOptions: fenceOptions,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Typical usage (don't forget to compare props):
        // if (this.props.userID !== prevProps.userID) {
        //   this.fetchData(this.props.userID);
        // }
        // if (this.props.mapData){
        //     this.showPathOnMap(this.props.mapData) ;
        // }
        // if (this.myMap && this.myMap.current){
        //     let mapComponent = this.myMap.current;
        //     // let google = mapComponent.props.google;
        //      
           
        // }
      }
    // -----------------------------------------------------------------
        // initMap(mapProps, map) {
        // var self = this;
        // const {google} = mapProps;
    
        // const drawingManager = new google.maps.drawing.DrawingManager({
        //   drawingMode: null,
        //   drawingControl: true,
        //   drawingControlOptions: {
        //     position: google.maps.ControlPosition.TOP_CENTER,
        //     drawingModes: [
              
        //       google.maps.drawing.OverlayType.POLYGON,
        //       google.maps.drawing.OverlayType.CIRCLE,
        //       google.maps.drawing.OverlayType.RECTANGLE
        //     ]
        //   },
        //   map: map
        // });
        // }
    // -----------------------------------------------------------------
    // onMarkerClick = (props, marker, e)=> {
    //     //  
    //     // this.setState({
    //     //     selectedPlace: props,
    //     //     activeMarker: marker,
    //     //     showingInfoWindow: true
    //     // });
    //     this.props.onMarkerClick(props, marker, e);
    //     //  
    // };

    // onClose = props => {
    //     // if (this.state.showingInfoWindow) {
    //     //     this.setState({
    //     //       showingInfoWindow: false,
    //     //       activeMarker: null
    //     //     });
    //     //   }
    //       this.props.handleMapInfoWinClick(props); 
    // };
    //--------------------------------------------------------------------
    //--------------------------------------------------------------------
    setSelection = (shape) => {        
        this.selectedShape = shape;
    }
    deleteSelectedShape = () => {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
        }
    }
    showShapOnMap = (shape) => {
        if (shape && this.myMap && this.myMap.current){
            // alert('yes');
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            this.deleteSelectedShape();
            this.setShap(google, map, shape);
        }else{
            this.deleteSelectedShape();
        }
    }
    //-----------------------------------------------------
    showPathOnMap = (mapData) => {
         
        if (mapData && this.myMap && this.myMap.current){
             
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;

            // if (google){
            //      
            // }else{
            //      
            // }

            // if (map){
            //     console.log('map yes', map)
            // }else{
            //     console.log('map no')
            // }

            let groupedDevice = null;
            groupedDevice = groupBy(mapData, device => device.deviceid); 
            if (groupedDevice) {
                groupedDevice.forEach((value, key, map) => {
                    if (value.length > 0) {
                        let points = value.map((item, index) => ({lat: Number(item.locationlat), lng: Number(item.locationlng)}))
                        this.setPolyLines(google, map, points);
                    }
                });
            }
        }        
    }

    initialPathOnMap = (google, map, mapData) => {
         
        if (mapData){
             
            let groupedDevice = null;
            groupedDevice = groupBy(mapData, device => device.deviceid); 
            if (groupedDevice) {
                groupedDevice.forEach((value, key, map) => {
                    if (value.length > 0) {
                        let points = value.map((item, index) => ({lat: item.locationlat, lng: item.locationlng}))
                        this.setPolyLines(google, map, points);
                    }
                });
            }
        }        
    }
    //-----------------------------------------------------
    setShap = (google, map, shape) => {
        // let shape = this.props.shape;

        // alert(shape.data.center);
        let self = this;
        let newCenter;
        let bounds;
        if (shape) {
            // self.deleteSelectedShape();
            switch(shape.type) {
                case 'circle':
                    const initCircle=new google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        center: shape.data.center,
                        radius: shape.data.radius,
                        editable: false
                      });
                    // google.maps.event.addListener(initCircle, 'radius_changed', function (event) {
                    //     // alert('circle radius changed');
                    //     self.setCircleData(initCircle);     
                    //      
                    //     //  
                    // });
                
                    // google.maps.event.addListener(initCircle, 'center_changed', function (event) {               
                    //     // alert('circle center changed');
                    //     // alert(initCircle.center);
                    //     self.setCircleData(initCircle);     
                    //      
                    //     //  
                    // });
                    bounds = initCircle.getBounds();
                    newCenter = initCircle.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initCircle);
                    // this.setCircleData(initCircle); 
                    break;
                case 'rectangle':
                    const initRectangle=new google.maps.Rectangle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        bounds: shape.data,
                        editable: false
                      });
                    //   google.maps.event.addListener(initRectangle, 'bounds_changed', () => {
                    //       this.setRectangleData(initRectangle);
                    //        
                    //   });
                      bounds = initRectangle.getBounds();
                      newCenter = bounds.getCenter();
                      map.setCenter(newCenter);
                      map.fitBounds(bounds);
                      this.setSelection(initRectangle);
                    //   this.setRectangleData(initRectangle); 
                    break;
                case 'polygon':
                    google.maps.Polygon.prototype.getBoundingBox = function(){
                        var bounds = new google.maps.LatLngBounds();
                        this.getPath().forEach(function(element,index) {
                          bounds.extend(element)
                        });
                        return(bounds);
                    }
                    const initPolygon=new google.maps.Polygon({
                        paths: shape.data,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        editable: false
                    });
                    
                    // let paths = initPolygon.getPaths();
                    // // let pType = typeof paths;
                    // // alert(pType);
                    // // alert(Array.isArray(paths));
                    // // if (Array.isArray(paths)) {
                    // //     alert('true');
                    // // }
                    // paths && paths.forEach((path) => {
                    //     google.maps.event.addListener(path, 'insert_at', () => {
                    //         // alert('insert_at');
                    //         this.setPloygonData(initPolygon);
                    //          
                    //     });
                    //     google.maps.event.addListener(path, 'remove_at', () => {
                    //         // alert('remove_at');
                    //         this.setPloygonData(initPolygon);
                    //          
                    //     });
                    //     google.maps.event.addListener(path, 'set_at', () => {
                    //         // alert('set_at');
                    //         this.setPloygonData(initPolygon);
                    //          
                    //     });
                    // });
                    bounds = initPolygon.getBoundingBox();
                    newCenter = bounds.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initPolygon);
                    // this.setPloygonData(initPolygon); 
                    break;
            }
        }
    }
// -------------------------------------------------------------------
    setPolyLines = (google, map, paths) => {
        let lineSymbol = {
            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
          };
           
          // Create the polyline and add the symbol via the 'icons' property.
        const flightPlanCoordinates = [
            {lat: 37.772, lng: -122.214},
            {lat: 21.291, lng: -157.821},
            {lat: -18.142, lng: 178.431},
            {lat: -27.467, lng: 153.027}
          ];
        let line = new google.maps.Polyline({
            // path: [{lat: 22.291, lng: 153.027}, {lat: 18.291, lng: 153.027}],
            path: flightPlanCoordinates,
            icons: [{
              icon: lineSymbol,
              offset: '100%'
            }],
            map: map
            // setMap: map,
          });
        // line.setMap(map);
    }
//--------------------------------------------------------------------
    setMapBounds = () => {
        if (this.myMap && this.myMap.current){
             
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            if (google, map) {
                let bounds = this.getMapBounds(google, map);
                if (bounds) {
                    map.fitBounds(bounds);   
                }
            }
        }
    }
    getMapBounds = (google, map) => {
        let bounds = null;
        let assetClass;

        if (this.props.mapData && this.props.mapData.length > 0) {
            bounds = new google.maps.LatLngBounds();
            // assetClass = this.props.mapData[0];
            this.props.mapData.forEach((location) => {
                bounds.extend(
                  new google.maps.LatLng(location.locationlat, location.locationlng),
                );
            });
        }
      
       
        return bounds;
    };

    getCompanyBounds = (google, map) => {
         
        let bounds = null;
        if (this.userObj.company && this.userObj.company.businessArea){
             
            let points = JSON.parse(this.userObj.company.businessArea);
            bounds = new google.maps.LatLngBounds();
            points.forEach((point,index) => {
                if(index === 0){
                    this.lat = point.lat;
                    this.lng = point.lng;
                }
                bounds.extend(
                  new google.maps.LatLng(point.lat, point.lng),
                );
            });
        }
        return bounds;
    }

    getCompanyCenter = () => {
        if (this.userObj && this.userObj.company && this.userObj.company.businessArea) {
            let points = JSON.parse(this.userObj.company.businessArea);
            if (points && points.length > 0 ) {
                this.lat = points[0].lat;
                this.lng = points[0].lng;
            }
        }
    }

    getFirstOne = (google, map) => {
        let mylocation = null;
        let assetClass;

        if (this.props.mapData && this.props.mapData.length > 0) {
            // bounds = new google.maps.LatLngBounds();
            // assetClass = this.props.mapData[0];
            this.props.mapData.forEach((location) => {
                // bounds.extend(
                //   new google.maps.LatLng(location.locationlat, location.locationlng),
                // );
                 
                mylocation =  new google.maps.LatLng(location.locationlat, location.locationlng);
                 
            });
        }
      
       
        return mylocation;
    };
    //--------------------------------------------------------------------
    //--------------------------------------------------------------------
    initMap(mapProps, map) {
         
        var self = this;
        const {google} = mapProps;

         // --------------------------------------------
        //  let bounds = this.getMapBounds(google, map);
        //  if (bounds){
        //      map.fitBounds(bounds);       
        //      // map.panToBounds(bounds);  
        //  }

        // let bounds = this.getCompanyBounds(google, map);
        //  
        // if (bounds){
        //     map.fitBounds(bounds);       
        //     // map.panToBounds(bounds);  
        // }
 
        //  let location = this.getFirstOne(google, map);
        //   
        //   
        //  if (location){
        //      map.setCenter(location);       
        //      // map.panToBounds(bounds);  
        //  }
               
        self.setShap(google, map, self.state.shape);
        // --------------------------------------------
        // self.setPolyLines(google, map, null);
        // let mapData = filterMapData(this.props.mapData);
        // this. initialPathOnMap(google, map, mapData);
        // --------------------------------------------
            var input = ReactDOM.findDOMNode(self.refs.inputTest);
            var searchBox = new google.maps.places.SearchBox(input);
            // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            map.addListener('bounds_changed', function() {
                searchBox.setBounds(map.getBounds());
            });
            searchBox.addListener('places_changed', () => {
                // alert('test');
                var places = searchBox.getPlaces();
                // alert(places.length);
                if (places.length == 0) {
                    return;
                }

                // this.markers.forEach(function(marker) {
                //     marker.setMap(null);
                // });
                // this.markers = [];

                // for (var i = 0; i < places.length; i++) 
                // {
                //  //   createMarker(results[i]);
                //    var marker = new google.maps.Marker({
                //      map: map,
                //      position: places[i].geometry.location
                //    });
                //    this.markers.push(marker);
                // }
                map.setCenter(places[0].geometry.location);
            });

        //     var request = {
        //         query: '235 Yorkland Blvd, North York, ON M2J 4Y8',
        //         fields: ['name', 'geometry'],
        //       };

        //     let service = new google.maps.places.PlacesService(map);

        //     service.findPlaceFromQuery(request, function(results, status) {
        //         alert('test');
        //         alert(status);
        //       if (status === google.maps.places.PlacesServiceStatus.OK) {
        //         alert(results.length);  
        //         for (var i = 0; i < results.length; i++) {
        //         //   createMarker(results[i]);
        //           var marker = new google.maps.Marker({
        //             map: map,
        //             position: results[i].geometry.location
        //           });
        //         }
    
        //         map.setCenter(results[0].geometry.location);
        //       }
        //     });
          
    
        //   const createMarker = (place) => {
        //     var marker = new google.maps.Marker({
        //       map: map,
        //       position: place.geometry.location
        //     });
        // }
        // --------------------------------------------
    
            // google.maps.event.addListener(drawingManager, 'circlecomplete', function(circle) {
            //     var radius = circle.getRadius();
            //     alert(radius);
            // }); 
    }
    //--------------------------------------------------------------------
    handleFenceChange = (val) => {
        let id = val.value;        
        let fence = this.props.allfences.find((e, i) => (e.fenceid == id));
        let currentPolygonData = null;
        let currentRectangleData = null;
        let currentCircleData = null;
        let selectedShape = null;
        let type = '' ;
        if (fence) {
            switch(fence.shape){
                case 0:
                    type = 'circle';
                    currentCircleData = JSON.parse(fence.geodata);
                    break;
                case 1:
                    type = 'rectangle';
                    currentRectangleData = JSON.parse(fence.geodata);
                    break;
                case 2:
                    type = 'polygon';
                    currentPolygonData = JSON.parse(fence.geodata);
                    break;
            }
    
            selectedShape = {type: type, data: JSON.parse(fence.geodata)};
        }    
         
        this.setState({
            shape: selectedShape,
        });

        this.showShapOnMap(selectedShape)

        // this.selectedFenceId = fence.fenceid;
        // this.selectedFenceName = fence.fencename;

        // this.currentShapeData = {type: type, data: JSON.parse(fence.geodata)};

        // this.currentShapeType = type;
        // this.currentShapeTypeId = fence.shape;
        // this.currentPolygonData = currentPolygonData;
        // this.currentRectangleData = currentRectangleData;
        // this.currentCircleData = currentCircleData;
        // this.setState({
        //     selectedFenceOption: val,
        //     selectedShape: {type: type, data: JSON.parse(fence.geodata)},
        // });
    }
    //--------------------------------------------------------------------
    render(){
        const mapStyle = {
            width: '100%',
            height: '50vh',
            position : 'static',
            paddingTop: 10
        };  
        let v = this.props.values.selectedPlace;
        let lat = this.props.values.lat;
        let lng = this.props.values.lng;
        
        // let lat = pos.lat;

        var icon = {
            path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0", //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: '#FF0000',
            fillOpacity: .6,
            anchor: new google.maps.Point(0,0),
            strokeWeight: 0,
            scale: 1
        };

        const defaultIconPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0";
        const defaultColor = "#FF0000";

        const generateIcon = (color, path)=>{
            let myPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0"; //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0"
            if (path && path.length > 0){
                myPath = path;
            }
            let showColor = color ? color : defaultColor;
            return (

                {
                    //path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0", //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                    // path: "M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z",
                    //path: "m 640,128 q 0,52 -38,90 -38,38 -90,38 -52,0 -90,-38 -38,-38 -38,-90 0,-52 38,-90 38,-38 90,-38 52,0 90,38 38,38 38,90 z M 256,640 H 640 V 896 H 482 q -13,0 -22,-9 L 265,692 q -9,-9 -9,-22 V 640 z M 1536,128 q 0,52 -38,90 -38,38 -90,38 -52,0 -90,-38 -38,-38 -38,-90 0,-52 38,-90 38,-38 90,-38 52,0 90,38 38,38 38,90 z m 256,1088 V 192 q 0,-15 -4,-26.5 -4,-11.5 -13.5,-18.5 -9.5,-7 -16.5,-11.5 -7,-4.5 -23.5,-6 -16.5,-1.5 -22.5,-2 -6,-0.5 -25.5,0 -19.5,0.5 -22.5,0.5 0,-106 -75,-181 -75,-75 -181,-75 -106,0 -181,75 -75,75 -75,181 H 768 q 0,-106 -75,-181 -75,-75 -181,-75 -106,0 -181,75 -75,75 -75,181 h -64 q -3,0 -22.5,-0.5 -19.5,-0.5 -25.5,0 -6,0.5 -22.5,2 Q 105,131 98,135.5 91,140 81.5,147 72,154 68,165.5 64,177 64,192 q 0,26 19,45 19,19 45,19 v 320 q 0,8 -0.5,35 -0.5,27 0,38 0.5,11 2.5,34.5 2,23.5 6.5,37 4.5,13.5 14,30.5 9.5,17 22.5,30 l 198,198 q 19,19 50.5,32 31.5,13 58.5,13 h 160 v 192 q 0,26 19,45 19,19 45,19 h 1024 q 26,0 45,-19 19,-19 19,-45 z",
                    path: myPath,
                    fillColor: showColor,
                    fillOpacity: .6,
                    anchor: new google.maps.Point(0,0),
                    strokeWeight: 0,
                    scale: 1,
                }
            )                            
        };

        if (this.props.values.selectedPlace.attr){
             
            console.log(this.props.values.selectedPlace.attr)
        }else{
             
        }

        const renderInfoWin = ()=>{
            if (this.props.values.selectedPlace.attr){
                console.log('attr is : ')
                console.log(Object.keys(this.props.values.selectedPlace.attr))


                return (

                    Object.keys(this.props.values.selectedPlace.attr).map((key,i)=>(
                         //key != 'Lat' && key !='Lng' && // display lat and lng in Device Location Page

                        <tr>
                            <td>{key}</td>
                            <td>{this.props.values.selectedPlace.attr[key].value}</td>
                        </tr>

                    ))
                )
            }else{
                 
                return null;
            }
        }

        // "locationlat": 11.7638337,
        // "locationlng": 27.6988928,
        // 43.6905867,
        // -79.4751292,

        // let mapData = filterMapData(this.props.mapData);
        // let pathObj = {showPath: false};
        // const defaultColor = '#ff0000';
        let pathArr = [];
        let mapData = filterMapData(this.props.mapData, pathArr);
        this.setMapBounds();
        // ------------------------------------------------
        // let lineSymbol = {
        //     path: this.props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
        // };
        let lineSymbol = {
            // path: 'M0,0 L4,2 0,4',
            // path: 'M-2,4 L0,0 L2,4',
            path: 'M-2,4 0,0 M2,4 0,0',
            // path:'M -2,2 0,0 M 0,0 -2,-2',
            // path: 'M1,2 L10,5 L0,5',
            strokeOpacity: 1,
            scale: 2,
        };
        // const flightPlanCoordinates = [
        //     {lat: 37.772, lng: -122.214},
        //     {lat: 21.291, lng: -157.821},
        //     {lat: -18.142, lng: 178.431},
        //     {lat: -27.467, lng: 153.027}
        //   ];

        // const triangleCoords = [
        //     {lat: 25.774, lng: -80.190},
        //     {lat: 18.466, lng: -66.118},
        //     {lat: 32.321, lng: -64.757},
        //     {lat: 25.774, lng: -80.190}
        //   ];
        // this.showPathOnMap(mapData) ;
        // ------------------------------------------------
        this.getCompanyCenter();
        return(
            <div style={{position: 'relative'}} >
                                        {/* <div style={{padding: 20, backgroundColor: 'white'}}>          */}
                <br/>
                {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Input id="pac-input" class="controls" type="text" placeholder="Search Box" ref='inputTest' style={{width:'30%'}}/>     
                </div>   */}
                <Row>
                    <Col md={5}>
                        {/* <Label   style={{ display: 'inline'}}>Fence</Label> */}
                        <Select            
                            value= {this.state.selectedFenceOption}           
                            // options={this.props.allFenceOptions} fenceOptions
                            options={this.state.fenceOptions}
                            onChange={this.handleFenceChange}
                            placeholder={'Select Geofence...'}
                        />
                    </Col>
                    <Col md={2}></Col>
                    <Col md={5}>
                        <Input id="pac-input" class="controls" type="text" placeholder="Search by Address" ref='inputTest' style={{width:'100%'}}/>     
                    </Col>
                </Row>
                <Map 
                    ref={this.myMap}    
                    google={this.props.google} 
                    zoom={8}
                    onReady={this.initMap}  
                    containerStyle={mapStyle} 
                    style={mapStyle}
                    // initialCenter={{lat:43.8148024, lng:-79.5509472}}
                    // initialCenter={{lat:43.6905867, lng:-79.4751292}} 
                    initialCenter={{lat: this.lat ? this.lat : 43.6907917, lng: this.lng ? this.lng :-79.4761116}}
                >
                    <ClusterWrapper
                        mapData={mapData}
                        generateIcon = {generateIcon}
                        onMarkerClick = {this.props.onMarkerClick}
                    />

                    {/* {this.props.mapData && this.props.mapData.map((m,i)=>{ */}                                        
                    {/* {mapData && mapData.map((m,i)=>{
                        return(
                            (m.locationlat !== 0 || m.locationlng !== 0) &&
                            <Marker 
                                onClick={this.props.onMarkerClick} 
                                name={m.devicename} 
                                attr={m.msg} 
                                position={{lat:m.locationlat,lng:m.locationlng}} 
                                key={i} 
                                icon={generateIcon(m.color,m.asseticonsvg)}
                            >                                
                            </Marker>
                    );})} */}

                    {pathArr.length > 0 && pathArr.map((item, index) =>(
                        <Polyline
                        path={item}
                        strokeColor="#0000FF"
                        strokeOpacity={0.8}
                        strokeWeight={2} 
                        icons={[{
                            icon: lineSymbol,
                            // offset: '100%',
                            offset: '70%',
                            // repeat: '100px'
                        }]}
                        />
                    ))}
                   
                  
                  
                    {/* {this.props.mapData && this.props.mapData.map((item,index)=>(
                        
                        item.asset && Array.isArray(item.asset) && item.asset.map((m, i) => {
                             
                             
                            let path = defaultIconPath;
                            let color = defaultColor;
                            if (item.assetIcon && item.assetIcon.iconsvg){
                                path = item.assetIcon.iconsvg;
                            }
                            if (m.color){
                                color = m.color;
                            }
                            //  
                            return(
                                <Marker 
                                    onClick={this.props.onMarkerClick} 
                                    name={m.assetname} 
                                    // attr={m.msg} 
                                    device={m.device}
                                    position={{lat:m.locationlat,lng:m.locationlng}} 
                                    key={i} 
                                    icon={generateIcon(color,path)}
                                >
                                </Marker>
                            );
                        })                        
                    )
                    )} */}
                    <InfoWindow marker={this.props.values.activeMarker} 
                                visible={this.props.values.showingInfoWindow} 
                                // conClick={this.props.handleMapInfoWinClick}
                                onClose={this.props.handleMapInfoWinClose}
                    >
                        <div >
                            <h4><b>{this.props.values.selectedPlace.name}</b></h4>
                            <br/>
                            <Table>
                                {/* <caption style={{captionSide:"top"}}>{new Date(this.props.values.selectedPlace.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' })}</caption> */}
                                <caption style={{captionSide:"top"}}>{new Date(this.props.values.selectedPlace.timestamp).toLocaleString('en-US', { timeZone: 'UTC' })}</caption>
                                <tbody>
                                    {/* <tr>
                                        <td>attr</td>
                                        <td>{this.props.values.selectedPlace.name}</td>
                                    </tr> */}
                                    {/* {
                                        Object.keys(this.props.values.selectedPlace.attr).map((key,i)=>{

                                            <tr>
                                                <td>{key}</td>
                                                <td>{this.props.values.selectedPlace.attr[key]}</td>
                                            </tr>
                                        })
                                    } */}
                                    {renderInfoWin()}
                                </tbody>
                            </Table>
                            {/* <MapInfoWindow device = {this.props.values.selectedPlace.device}/> */}
                        </div>
                    </InfoWindow>
                    {/* {this.state.markers.map((m,i)=>{
                         
                         
                        return(
                            <Marker onClick={this.onMarkerClick} name={m.info} position={{lat:m.lat,lng:m.lng}} key={i}>
                                <InfoWindow visible={this.state.showingInfoWindow} >
                                    <div>
                                        <h4>{m.info}</h4>
                                    </div>
                                </InfoWindow>
                            </Marker>
                        );})} */}
                </Map>
                {this.props.loading && 
                    <div style={{position: 'Absolute ', height: '100%', width: '100%', top: '0px', left: '0px', display: 'flex', textAlign: 'center', alignItems: 'center'}}>
                        <Spinner 
                            as="span"
                            animation="border"
                            variant="primary"
                            // size="sm"
                            // role="status"
                            // aria-hidden="true"
                            style={{display: 'inline-block', margin: 'auto' }}
                        /> 
                    </div>
                } 
            </div>
        )
    }
}

// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
//     libraries: ['drawing','places'],
// })(MapDeviceContainer);

function filterMapData(mapData, pathArr){
     
    let reArr = [];
    const dataMap = groupBy(mapData, (msg)=>(msg.deviceid));
    //  
    if (dataMap && dataMap.size > 1){
        dataMap.forEach((v,k,m)=>{
            //  
            let maxItem = getMaxItem (v, item => (
                new Date(item.timestamp)
            ));
            reArr.push(maxItem);
        });
    }
    else if(dataMap.size === 1){
        // mapData.forEach((item, index) => {
        //     if (item.msg && item.msg.Lat && item.msg.Lat.value && item.msg.Lng && item.msg.Lng.value){
        //         item.locationlat = item.msg.Lat.value;
        //         item.locationlng = item.msg.Lng.value;
        //     }
        // });
        if(mapData.length > 1) {
            for (let i = 0 ; i < mapData.length - 1 ; i++){
                // if (mapData[i].msg && mapData[i].msg.Lat && mapData[i].msg.Lat.value && mapData[i].msg.Lng && mapData[i].msg.Lng.value){
                //     mapData[i].locationlat = mapData[i].msg.Lat.value;
                //     mapData[i].locationlng = mapData[i].msg.Lng.value;
                // }
                let points =  [
                        {lat: mapData[i].locationlat, lng: mapData[i].locationlng},
                        {lat: mapData[i+1].locationlat, lng: mapData[i+1].locationlng},
                ];
                pathArr.push(points);
            }

        }
        reArr = mapData;
    }
   
     
    return reArr;
}


function mapStateToProps(state) {   
    const allfences = state.fence.fenceData;
    const fenceObj = state.fence.fenceObj;
    const fenceStatus = state.fence.type;
    const props = {allfences, fenceObj, fenceStatus};
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadAllGeoFences,}, dispatch);
};

const enhance = compose(
    GoogleApiWrapper({
        // apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
        // apiKey: 'AIzaSyDXBuKv70EhwKMyHzrj6wc7_qPQFwmdyB4',
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['drawing','places'],
    }),
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(MapDeviceContainer);

// --------------------------------------------------------

class ClusterWrapper extends Component {
    constructor(props){
        super(props);
         
        this.markers = [];
    }

    componentDidMount(){
        // const script = document.createElement('script');
        // script.src = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js';
        // script.async = true;
        // document.body.appendChild(script);

         
        // this.renderCluster();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.google !== this.props.google || prevProps.mapData !== this.props.mapData) {
           
          if (this.clusterer) {
            // this.clusterer.setMap(null);
            this.clusterer.clearMarkers();
          }
          this.renderCluster();
        }
    }

    componentWillReceiveProps(nextProps){
         
    }

    renderMarker = () => {}

    renderCluster = () => {
        let {google, map} = this.props;
        if (this.props.mapData) {
            // ----------------------------------------------------------------------------------------
            const options = { legWeight: 3, minZoomLevel: 18 }; // Just an example of options - please set your own if necessary
            const oms = new OverlappingMarkerSpiderfier(map, options);
            // ----------------------------------------------------------------------------------------
            this.markers = this.props.mapData.map((m,i)=>{

                const ref = {
                    map: map,
                    name: m.devicename, 
                    attr: m.msg,
                    timestamp: m.timestamp,
                    position: {lat:m.locationlat,lng:m.locationlng}, 
                    icon: this.props.generateIcon(m.color,m.asseticonsvg)
                }
                
                let marker = new google.maps.Marker(ref);
                 
                // marker.addListener('click', (e)=> {this.props.onMarkerClick(ref, marker, e)});

                marker.addListener('spider_click', (e)=> {this.props.onMarkerClick(ref, marker, e)});
                oms.addMarker(marker);

                return marker;
            })

            this.clusterer = new MarkerClusterer(map, this.markers, {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', maxZoom: 18});
            // return clusterer.clearMarkers();
            // return this.markers;
        }

        return null;
        
    }

    // render(){
    //     return (
    //         <Fragment>
    //             {this.props.children ?
    //                 React.Children.only(
    //                     React.cloneElement(
    //                         this.props.children, 
    //                         // { marker: this.marker,
    //                         //     google: this.props.google,
    //                         //     map: this.props.map}
    //                         {...this.props}
    //                     )
    //                 ) : null
    //             }
    //         </Fragment>
    //     )
    // }

    render() {
        const currentPlayingTitle = 'Mad Max: Fury Road';
        const childrenWithExtraProp = React.Children.map(this.props.children, child => {
          return React.cloneElement(child, {
            ...this.props
          });
        });
    
        return (
          <Fragment >
            {/* {childrenWithExtraProp} */}
            {this.props.children}
          </Fragment>      
        );
      }
}
