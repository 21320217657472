/* eslint-disable */
import {
    ASSET_GET_RECURSIVE_DATA_SUCCEED,
    ASSET_GET_RECURSIVE_DATA_FAILED, 
    ASSET_RESET_RECURSIVE_DATA,
    ASSET_REMOVE_RECUSIVE_LEVEL ,
    ASSET_RECURSIVE_START,
} from '../../constants/actionTypes';

const initState = {    
    recursiveData: [],
    recursiveClassOptions: [],
    loading: false,
    error: 0,
    message: '',
    type: '',
}

export default function(state = initState, action){
    switch(action.type){
        case ASSET_RECURSIVE_START: 
            return({
                ...state,
                loading: true,
            })
        // case ASSET_GET_RECURSIVE_DATA_SUCCEED:
        //     let newData ;
        //     let classOptions = [];
        //     let payload ;
        //     // if (action.payload && action.payload.length > 0) {
        //     if (action.payload && action.payload.length >= 0) {
        //         // let level = action.payload[0].level;
        //         // newData = state.recursiveData.filter((item, index) => (item.level < level));
        //         // newData = [...newData, ...action.payload];

        //         let level = action.level;
        //          
        //         if (level == 0 && action.assetclassid) {
        //             payload = action.payload.filter((item, index) => (item.assetclassid == action.assetclassid));
                    
        //             let cAssetclassid = null;
        //             let cAssetclassname = null;
        //             for(let i=0; i < action.payload.length; i++ ) {
        //                 let classid = action.payload[i].assetclassid;
        //                 let classname = action.payload[i].assetclassname;
        //                 // if(classid != cAssetclassid && classname != cAssetclassname){
        //                 //     cAssetclassid = classid;
        //                 //     cAssetclassname = classname;
        //                 //     classOptions.push(action.payload[i]);
        //                 // }
        //                 if (classOptions.length > 0){
        //                     if (!classOptions.some((item, index) => (item.assetclassid == classid))){
        //                         classOptions.push(action.payload[i]);
        //                     }
        //                 }else{
        //                     classOptions.push(action.payload[i]);
        //                 }
        //             }

        //         }else{
        //             payload = action.payload;
        //             classOptions = [...state.recursiveClassOptions];
        //         }

        //         newData = state.recursiveData.filter((item, index) => (item.level <= level));
        //         // newData = [...newData, ...action.payload];
        //         newData = [...newData, ...payload];
        //     }else{
        //         // let level = action.level;
        //         //  
        //         // newData = state.recursiveData.filter((item, index) => (item.level <= level));
        //         // newData = [...newData];
        //         newData = [...state.recursiveData];
        //     }
        //      
        //      
        //     return{
        //         ...state,
        //         recursiveData: newData,
        //         recursiveClassOptions: classOptions, 
        //         loading: false,
        //         error: 0,
        //         message: '',
        //         type: action.type,
        //     }
        case ASSET_GET_RECURSIVE_DATA_SUCCEED:
            return{
                ...state,
                recursiveData: action.payload,
                // recursiveClassOptions: classOptions, 
                loading: false,
                error: 0,
                message: '',
                type: action.type,
            }
        // case ASSET_REMOVE_RECUSIVE_LEVEL:
        //     const levelindex = action.payload;
        //     if (levelindex && state.recursiveData) {
        //         newData = state.recursiveData.filter((item, index) => (item.level < levelindex));
        //     }else{
        //         newData = [...state.recursiveData];
        //     }
        //     return{
        //         ...state,
        //         recursiveData: newData,
        //         loading: false,
        //         error: 0,
        //         message: '',
        //         type: action.type,
        //     }
        case ASSET_RESET_RECURSIVE_DATA:
            return{
                ...state,
                recursiveData: [],
                recursiveClassOptions: [], 
                loading: false,
                error: 0,
                message: '',
                type: action.type,
            }
        case ASSET_GET_RECURSIVE_DATA_FAILED:
            return{
                ...state,
                recursiveData: [],
                recursiveClassOptions: [], 
                loading: false,
                error: 1,
                message: action.error,
                type: action.type,
            }
        default: 
            return {
                ...state,
                loading: false,
            }
    }
}
