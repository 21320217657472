/* eslint-disable */
import React, { Component, Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
  Container,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { EditIcon } from "mdi-react";
import MultiSelectionComponent, {
  SingleSelection,
} from "../../Asset/components/SelectionComponents";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import DecoderEditModal from "./DecoderEditModal";
import { loadClasses, loadAssets } from "../../../redux/actions/assetActions";

const inputStyle = {
  maxWidth: "30%",
  minHeight: "150px",
};

const deviceSelect = (value) => {
  this.setState({
    deviceToEdit: value,
  });
};

class EditMessageDecodePanel extends Component {
  constructor(props) {
    super(props);
    this.id = [];
    this.state = {
      selectedDeviceInfo: [],
      assetClassList: [],
      deviceToEdit: null,
      submitForm: null,
      renderTable: [],
      jsonInput: null,
      dataElementToEdit: {},
      allowedDeviceTypes: [],
      selectedAssetClassId: null,
      showEditModal: false,
    };
  }

  componentDidMount() {
    this.setState({
      allowedDeviceTypes: this.state.assetClassList.allowedDeviceTypes,
    });
  }

  onSubmit = () => {
    if (this.validateJson(this.state.jsonInput)) {
      const data = JSON.parse(this.state.jsonInput);
      let result = [];
      let statusData = this.jsonToArray(result.push(data));
      this.setState({
        renderTable: statusData,
      });
    } else {
      alert("Please input JSON format.");
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handelEditTableData = (param) => {
    if (param && this.state.renderTable.length > 0) {
      // console.log('===EDIT TABLEs', this.state.renderTable, param)
      let tempTableData = this.state.renderTable.map((el, ind) => {
        if (el.dataElement == param.dataElement) {
          el = param;
          return el;
        } else {
          return el;
        }
      });
      // console.log("===tempTableData", tempTableData);
      this.setState({
        renderTable: tempTableData,
      });
    }
    // this.setState({
    //   renderTable:
    // })
  };

  validateJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  jsonToArray = () => {
    const result = [];
    if (this.validateJson(this.state.jsonInput)) {
      const input = JSON.parse(this.state.jsonInput);
      for (let key of Object.keys(input)) {
        let obj = {
          dataElement: "",
          // from :"",
          // size :"",
          // to :"",
          // dataType :"",
          lowerLimit: "",
          upperLimit: "",
          // initialValue: "",
          // formula :"",
          calculateExpression: "",
          conditionExpression: "",
          attributeId: "",
          displayName: "",
          startPosition: "",
          endPosition: "",
          initialDataType: "",
          convertedDataType: "",
          subStartPoint: "",
          subEndPoint: "",
          readDirection: "",
        };
        obj.dataElement = key;
        result.push(obj);
      }

      return result;
    }
  };

  dataElement = (cell, row) => {
    return <div>{cell}</div>;
  };

  showEditModal = () => {
    this.setState((prevState) => ({
      showEditModal: !prevState.showEditModal,
    }));
    // setTimeout(() => {
    //   this.setState({ showEditModal: false });
    // }, 1);
  };

  editFrom = (cell, row) => {
    // this.editSelectedRow(row.dataElement);

    return (
      <div className="actions" style={{ cursor: "pointer" }}>
        <EditIcon color="#0066cc" onClick={() => this.editSelectedRow(row)} />
      </div>
    );
  };

  closePanel = () => this.setState({ dataElementToEdit: null });

  editSelectedRow = (dataElement) => {
    this.setState({
      dataElementToEdit: dataElement,
      showEditModal: true,
    });
  };

  selectedAssetClass = (event) => {
    this.state.selectedAssetClassId = parseInt(event.target.value);

    this.deviceTypeOptions();
  };

  deviceTypeOptions = () => {
    let options = [];
    if (this.state.selectedAssetClassId != null) {
      let selectedClass = this.state.assetClassList.find(
        (d) => d.id == this.state.selectedAssetClassId
      );
      this.state.allowedDeviceTypes = selectedClass.allowedDeviceTypes;
    }

    if (this.state.allowedDeviceTypes.length > 0) {
      let i = 0;
      while (i < this.state.allowedDeviceTypes.length) {
        options.push(this.state.allowedDeviceTypes[i]);
        i++;
      }
    }

    this.setState({
      allowedDeviceTypes: options,
    });
  };

  // sizeInput = (cell, row) => <div>{row.size}</div>;

  // fromInput = (cell, row) => <div>{cell}</div>;

  // toInput = (cell, row) => <div>{cell}</div>;

  // dataTypeInput = (cell, row) =><div>{cell}</div>;

  lowerLimitInput = (cell, row) => cell;

  upperLimitInput = (cell, row) => cell;

  // initialValueInput = (cell, row) => <div>{cell}</div>;

  // formulaInput = (cell, row) => <div>{cell}</div>;

  startPosition = (cell, row) => cell;

  endPosition = (cell, row) => cell;

  initialDataType = (cell, row) => cell;

  calculateExpression = (cell, row) => cell;

  conditionExpression = (cell, row) => cell;

  readDirection = (cell, row) => cell;

  attributeId = (cell, row) => cell;

  update = () => {
    let statusData = this.props.msgDecodeInfo;
    // console.log("===this.props.msgDecodeInfo", statusData);
    // console.log("===this.props.msgDecodeInfo.encryptionMethod",this.props.msgDecodeInfo.encryptionMethod);
  };

  render() {
    const assetClassList = this.props.assetClassList.map((c) => ({
      id: c.id,
      name: c.name,
      attribute: c.attributeMetas,
      allowedDeviceTypes: c.allowedDeviceTypes,
    }));
    if (assetClassList) {
      this.state.assetClassList = assetClassList;
    }
    let deviceList = this.props.deviceList.map((d) => ({
      id: d.deviceid,
      name: d.devicename,
      namealias: d.devicenamealias,
      devicetypename: d.devicetypename,
    }));
    let msgDecodeList = this.props.messageDecodeList.map((m) => ({
      id: m.messageDecodeItemId,
      name: m.name,
    }));
    return (
      <Card>
        <CardBody style={{ marginLeft: "0.5%" }}>
          <div>
            <Label for="messageDecodeName" style={{ display: "inline" }}>
              <h5>Message Decode Name</h5>
            </Label>
          </div>
          <div class="d-flex justify-content-between">
            <SingleSelection
              disabled={true}
              isSingle={true}
              options={msgDecodeList}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ name }) => name}
              isClearable={true}
              defaultValue={this.props.selectedMsgDecode}
              // onChange={this.deviceSelect(deviceList.id)}
              style={{ maxWidth: "30%", border: "0px" }}
            />
            {/* <Button>+ Add Device</Button> */}
          </div>
          <br />
          <br />
          <br />
          <Row style={{ maxWidth: "30%" }}>
            <Col>
              <p>Asset Class</p>
              <SingleSelection
                isSingle={true}
                forIdName="assetClass"
                options={assetClassList}
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                isClearable={true}
                onChange={this.selectedAssetClass}
                // defaultValue={}
              />
            </Col>
          </Row>
          <Row style={{ maxWidth: "30%" }}>
            <Col>
              <p>Device Type</p>
              <SingleSelection
                isSingle={true}
                options={
                  this.state.allowedDeviceTypes
                    ? this.state.allowedDeviceTypes
                    : this.props.assetClassList[0].allowedDeviceTypes
                }
                getOptionValue={({ id }) => id}
                getOptionLabel={({ name }) => name}
                isClearable={true}
              />
              {/* <Select
              options={this.deviceTypeOptions()}
            /> */}
            </Col>
            <Col>
              <p>Message Width</p>
              <Input
                type="text"
                defaultValue={this.props.msgDecodeInfo.messageLength}
              />
            </Col>
          </Row>
          <Row style={{ maxWidth: "30%", marginTop: "1%" }}>
            <Col>
              <p>Encruption Method</p>
              {/* <Select defaultValue={this.props.msgDecodeInfo.encryptionMethod}/> */}
              <SingleSelection
                defaultValue={this.props.msgDecodeInfo.encryptionMethod}
              />
            </Col>
            <Col>
              <p>Decryption Key</p>
              <Button
                className="btn"
                style={{ height: "58%", padding: "inherit" }}
              >
                Choose a file
              </Button>
            </Col>
          </Row>
          <br />
          <div>
            <Form>
              <FormGroup>
                <Label for="jsonBody">Body (Json): </Label>
                <div class="d-flex justify-content-start">
                  <Input
                    type="textarea"
                    name="jsonInput"
                    id="jsonBody"
                    style={inputStyle}
                    onChange={this.onInputChange}
                  />
                </div>
              </FormGroup>
              <Button
                color="custom"
                className="btn"
                type="button"
                size="sm"
                onClick={this.onSubmit}
              >
                Submit
              </Button>
            </Form>
          </div>
          <div style={{ maxWidth: "99%" }}>
            <BootstrapTable
              data={this.state.renderTable}
              tableHeaderClass="header_white"
            >
              <TableHeaderColumn
                dataField="dataElement"
                dataFormat={this.editFrom}
                width="5%"
              >
                Edit
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="dataElement"
                isKey={true}
                dataFormat={this.dataElement}
              >
                Data Name
              </TableHeaderColumn>
              {/* <TableHeaderColumn dataField="size" dataFormat={this.sizeInput}>Size</TableHeaderColumn> */}
              {/* <TableHeaderColumn dataField="from" dataFormat={this.fromInput}>From</TableHeaderColumn> */}
              <TableHeaderColumn
                dataField="attributeId"
                dataFormat={this.attributeId}
              >
                Attribute ID
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="startPosition"
                dataFormat={this.startPosition}
              >
                Start Position
              </TableHeaderColumn>
              {/* <TableHeaderColumn dataField="to" dataFormat={this.toInput}>To</TableHeaderColumn> */}
              <TableHeaderColumn
                dataField="endPosition"
                dataFormat={this.endPosition}
              >
                End Position
              </TableHeaderColumn>
              {/* <TableHeaderColumn dataField="dataType" dataFormat={this.dataTypeInput}>Data Type</TableHeaderColumn> */}
              <TableHeaderColumn
                dataField="initialDataType"
                dataFormat={this.initialDataType}
              >
                Initial Data Type
              </TableHeaderColumn>
              {/* <TableHeaderColumn
                dataField="conditionExpression"
                dataFormat={this.conditionExpression}
              >
                Condition Expression
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="readDirection"
                dataFormat={this.readDirection}
              >
                Read Direction
              </TableHeaderColumn> */}
              {/* <TableHeaderColumn dataField="initialValue" dataFormat={this.initialValueInput}>Initial Value</TableHeaderColumn> */}
              {/* <TableHeaderColumn dataField="formula" dataFormat={this.formulaInput}>Formula</TableHeaderColumn> */}
              {/* <TableHeaderColumn
                dataField="calculateExpression"
                dataFormat={this.calculateExpression}
              >
                Calculate Expression
              </TableHeaderColumn> */}
            </BootstrapTable>
            <Card>
              <CardBody>
                <DecoderEditModal
                  show={this.state.showEditModal}
                  toggle={this.showEditModal}
                  next={this.onNext}
                  editRow={this.state.dataElementToEdit}
                  handelEditTableData={this.handelEditTableData}
                />
              </CardBody>
            </Card>
          </div>
          <br />
          <div class="d-flex justify-content-end">
            <Button
              color="custom"
              style={{ marginBottom: 0 }}
              onClick={() => this.props.closePanel()}
            >
              Cancel
            </Button>
            <Button
              color="custom"
              style={{ marginBottom: 0 }}
              onClick={() => this.update()}
            >
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default EditMessageDecodePanel;
