/* eslint-disable */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import { connect } from "react-redux";
import { Redirect, withRouter } from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import { Alert, Col, Row } from "reactstrap";
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { forgotPassword } from '../../../redux/actions/userAction';
import { FORGOT_PASSWORD_ROOT } from '../../../constants/appConstants';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { changeThemeToCustom } from '../../../redux/actions/themeActions';
import { WEB_SERVICE_ROOT } from '../../../constants/appConstants';
import ThemeSwitcher from 'react-css-vars';
import './forgotpassword.scss';
 
class ForgotPasswordForm extends PureComponent {
 
  constructor() {
    super();
    this.state = {
      showPassword: false,
      showErrors: false,
      isLoading: false,
      // forgetSuccess: '',
      username: '',
      password: '',
      errors: {},
      forgetPasswordMessage: '',
      custom: false,
      loginTheme: {}
    };
  }

  componentDidMount() {
    this.initTheme();
  }
 
  componentWillUnmount() {
    this.setState({
      showPassword: false,
      showErrors: false,
      isLoading: false,
      username: '',
      password: '',
      errors: {},
      loggedIn: 'false',
      forgetPasswordMessage: ''
    });
 
  }
 
  showPassword = (e) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
      showErrors: false,
    });
  };
 
  onInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    switch (name) {
      case 'username':
        this.setState({
          username: value,
          showErrors: false
        });
        sessionStorage.setItem('username', event.target.value);
        break;
      case 'password':
        this.setState({
          password: value,
          showErrors: false
        });
        break;
      default:
        return this.state;
    }
  };
 
  handleTouch = (e) => {
    e.preventDefault();
    this.setState({ showErrors: false })
  }
 
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      { isLoading: true, showErrors: true }
    );
    this.props.forgotPassword(this.state.username);
    this.initTheme();
  }
 
  renderError = () => {
    if (!this.props.isAuthenticated && this.props.error === 1) {
      return (
        <Alert className='form__form-group-field alert-danger text-center'>
          <span className='text-center'>{this.props.message}</span>
        </Alert>
      )
    } else {
      return null;
    }
  };
 
  validateForm = () => {
    return this.state.username;
  }
 
  setPasswordMessage = (message) => {
    this.setState({ forgetPasswordMessage: message })
  }

  initTheme = () => {
    var x = document.URL
    fetch(`${WEB_SERVICE_ROOT}BrandingLogin?url=${x}`).then(response => response ? response.json() : {}).then(data => {
      if (data.length > 0) {
        let companyTheme = data[0]
        //set logo
        if (companyTheme) {
          this.props.changeThemeToCustom();
          this.setState({custom: true, loginTheme: companyTheme})
          // document.documentElement.style.setProperty('--appFontColor', companyTheme.loginFontColor);
          // if (companyTheme.loginLogo) {
          //   setLogo(`${WEB_SERVICE_LOGO}${companyTheme.loginLogo}`)
          // }
          // setloginTheme(companyTheme)
        }

      }
    }).catch(e => console.error(e.message));
  }
 
  render() {
    if (sessionStorage.getItem('isAuthenticated') && 'true' == sessionStorage.getItem('isAuthenticated')) {
      return <Redirect push to="/pages" />;
    }
 
    this.setPasswordMessage(this.props.forgetPasswordMessage);

    let url = window.location.href;
    let address = '';
    if (url.includes("#/")) {
      address = url.split("/#/")[0];
    }
 
    return (
      <ThemeSwitcher theme={this.state.loginTheme}>
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              {/* <div className="account__head"> */}
              <div style={{ marginBottom: "20px" }}>
                <h3 className="account__title">
                  <span className="account__logo login-font">Forgot Password?
                    {/* <span className="account__logo-accent">  </span> */}
                  </span>
                </h3>
                <span className="login-font">Enter your username to reset password</span>
              </div>
  
              <form className="form" onSubmit={this.handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Username</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <AccountOutlineIcon style={{ marginLeft: '8px', marginBottom: '10px' }} />
                    </div>
                    <input
                      name="username"
                      className="custom_input"
                      type="text"
                      value={this.state.username}
                      placeholder="Name"
                      onChange={this.onInputChange}
                      onClick={this.handleTouch}
                    />
                  </div>
                </div>
                <div className='form__form-group-field'>
                  {this.state.showErrors === true && this.renderError()}
                </div>
                <div className='form__form-group-field'>
                  <br />
                </div>
                {/* <button className="btn btn-primary account__btn account__btn--small" type="submit" disabled = {!this.validateForm()}>Submit</button> */}
                <ConfirmDeleteDialog
                  color="custom"
                  disabled={!this.validateForm()}
                  outline={false}
                  label={"Submit"}
                  noIcon={true}
                  className={'confirm-dialog'}
                  login={false}
                  style={{
                    padding: '10px 25px',
                    marginRight: '0px',
                    width: '100%'
                    // backgroundColor: '#678aff',
                    // borderColor:'#678aff'
                  }}
  
                  onConfirm={(e) => { this.handleSubmit(e) }}
                  msg={`Do you want to reset password? `}
                />
                <a className="btn account__btn account__btn--small" style={{ marginRight: "0px" }} href={address + FORGOT_PASSWORD_ROOT}>Back</a>
              </form>
  
              <h5>{this.state.forgetPasswordMessage}</h5>
            </div>
          </div>
        </div >
      </ThemeSwitcher>
    );
  }
}
 
 
const mapStateToProps = (state) => {
  return state.user;
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ forgotPassword, changeThemeToCustom }, dispatch);
};
 
const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);
 
export default enhance(ForgotPasswordForm);
 
//   <div style={{margin: '100px'}}>
//   <h2>{this.props.forgetPasswordMessage}</h2>
//   </div>