/* eslint-disable */
import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import PowerMgntDeviceInfo from "./PowerMgntDeviceInfo";
import StatusTable from "./StatusTable";

class PowerMgntDeviceBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    let filtedHistoryData = this.props.powerAttrList;
    return (
      <Col>
        <Row md={12}>
          <Nav tabs>
            <NavItem style={{ cursor: "pointer" }}>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => this.toggleTab("1")}
              >
                Device
              </NavLink>
            </NavItem>
            <NavItem style={{ cursor: "pointer" }}>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => this.toggleTab("2")}
              >
                Data
              </NavLink>
            </NavItem>
          </Nav>
        </Row>
        <Row>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <PowerMgntDeviceInfo
                deviceInfo={this.props.deviceInfo}
                assetInfo={this.props.assetInfo}
                assetData={this.props.assetData}
                activeTab={this.state.activeTab}
                statusData={this.props.statusData}
                deviceAttrList={this.props.deviceAttrList}
                selectedDeviceAttr={this.props.selectedDeviceAttr}
                messageDecodeList={this.props.messageDecodeList.filter(({ companyCode }) => companyCode == "METRO")}
                messageDecodeCalculateRuleList={this.props.deviceInfo ? this.props.messageDecodeList.filter(
                  ({ deviceTypeId }) => deviceTypeId == this.props.deviceInfo.devicetype
                ).map(m => ({ messageDecodeCalculateRule: m.calculateRules })) : []}
                messageDecodeGroup={this.props.messageDecodeGroup}
                currentAssetData={this.props.currentAssetData}
                powerAttrList={this.props.powerAttrList}
                historyDataObj={this.props.historyDataObj}
                toggle={this.props.toggle}
              />
            </TabPane>
            <TabPane tabId="2">
              {/** This is for data table  */}
              <StatusTable
                currentAsset={this.props.currentAssetData}
                toggle={this.props.toggle}
                // tableData = {this.props.historyData}
                tableData={filtedHistoryData}
                filterAttr={"Alarm"} />
            </TabPane>
          </TabContent>
        </Row>
      </Col>
    );
  }
}
export default PowerMgntDeviceBoard;
