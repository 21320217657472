/* eslint-disable */
import React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import OccupancyMainBoard from './components/OccupancyMainBoard';
import MainBoard from './components/MainBoard';
import { getCurrentUser } from '../../factories/auth';
import {UserContext} from '../../constants/myContexts';

const OccupancyPage = (props) => {
  let {userObj} = React.useContext(UserContext);
  if (!userObj) {
    toast.error('Can not fetch login user information, please re-login.');
    //props.history.push('/log_out');
  } else {
     
  }

  return (
    <div className="dashboard" style={{padding: '15px', margin:'15px'}}>
      <Row>
        <Col md={12}>
          <h2 className="page-title">Bungalow Occupancy Monitoring</h2>
        </Col>
      </Row>
      
      <MainBoard userObj = {userObj}/>
    </div>
  );
};

export default withRouter(OccupancyPage);

