/* eslint-disable */
import React, {Component} from 'react';


class TemplatePageBoard extends Component {
  render(){
    return(
      <div>
        hello
      </div>
    )
  }
}


export default TemplatePageBoard;