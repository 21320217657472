/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import {Modal} from "react-overlays";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { getCurrentUser } from '../../../factories/auth';

//import UserAccessForm from './userAccessForm';
import './ClientAddModal.css';
import { TheaterIcon, ThemeLightDarkIcon } from 'mdi-react';

class ClientSpecialLineModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showServiceModal: false,
            resellerCode: null,
            companyCode: null
        }
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            resellerCode: nextProps.selectedRow.resellerCode,
            companyCode: nextProps.selectedRow.companyCode
        })
        //Add new plan
        if (nextProps.show == true && nextProps.selectedSpecialLine == null) {
            //modal is already open, just recieved new prop like new carrier plan list
            if (this.state.showServiceModal == true) {
                //do noting, keep inital state
            }
            else {
                this.setState({
                    showServiceModal: true,
                    id: 0,
                    resellerId: nextProps.selectedRow.id,
                    companyId: nextProps.selectedRow.companyId,
                    lineNum: "",
                    specialLinePosition: "",
                    specialLine: ""
                })
            }
        }
        //Edit plan
        if (nextProps.show == true && nextProps.selectedSpecialLine != null) {


            //  var specialLinePositionLocal = "Not Applicable"
            //  console.log(nextProps.selectedSpecialLine.specialLinePosition)
            //  if (nextProps.selectedSpecialLine.specialLinePosition == 'S') {
            //      console.log('here1')
            //      nextProps.selectedSpecialLine.specialLinePosition = 'Start'
            //  }
            // else if (nextProps.selectedSpecialLine.specialLinePosition =='E') {
            //     console.log('here2')
            //     nextProps.selectedSpecialLine.specialLinePosition= 'End'
            // }


            //modal is already open, just recieved new prop like new carrier plan list

            if (this.state.showServiceModal == true) {
                //do noting, keep inital state
            }

            else {
                if (nextProps.selectedSpecialLine.resellerId == null) {

                    this.setState({
                        showServiceModal: true,
                        id: null,
                        resellerId: nextProps.selectedRow.id,
                        companyId: nextProps.selectedRow.companyId,
                        resellerCode: nextProps.selectedRow.resellerCode,
                        companyCode: nextProps.selectedRow.companyCode,
                        lineNum: nextProps.selectedSpecialLine.lineNum,
                        specialLine: nextProps.selectedSpecialLine.specialLine,
                        specialLinePosition: nextProps.selectedSpecialLine.specialLinePosition
                    })
                }
                else {

                    this.setState({
                        showServiceModal: true,
                        id: nextProps.selectedSpecialLine.id,
                        resellerId: nextProps.selectedRow.id,
                        companyId: nextProps.selectedRow.companyId,
                        resellerCode: nextProps.selectedRow.resellerCode,
                        companyCode: nextProps.selectedRow.companyCode,
                        lineNum: nextProps.selectedSpecialLine.lineNum,
                        specialLine: nextProps.selectedSpecialLine.specialLine,
                        specialLinePosition: nextProps.selectedSpecialLine.specialLinePosition
                    })

                }
            }


        }
        if (nextProps.show == false) {
            this.setState({
                showServiceModal: false
            })
        }
    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onInputCarrierPlanChange = (event) => {

        if (event.target.value != -1) {
            this.setState({
                itemCode: event.target.value,
            });
        }
        else {
            this.setState({
                itemCode: "",
            });
        }
    }
    validateForm = () => {
        if ((this.state.specialLinePosition != "" || this.state.lineNum != "") && this.state.specialLine != "") {

            return true;

        }
        return false;
    }
    onSubmit = () => {
        let data = {
            "id": this.state.id,
            "companyId": this.state.companyId,
            "resellerId": this.state.resellerId,
            "lineNum": this.state.lineNum,
            "specialLinePosition": this.state.specialLinePosition,
            "specialLine": this.state.specialLine,
            "companyCode": this.state.companyCode,
            "resellerCode": this.state.resellerCode
        }
        this.props.SetSpecialLine(data);
        this.onModalClose();
    }
    onRemovePlan = () => {
        let data = {
            "id": this.state.id,
            "resellerId": this.state.resellerId,
            "companyCode": this.state.companyCode,
            "resellerCode": this.state.resellerCode
        }
        this.props.DeleteSpecialLine(data);
        this.onModalClose();
    }
    onModalClose = () => {
        this.props.onClose();
    }
    render() {




        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showServiceModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onModalClose}>{(this.props.SpecialLine != null) ? "Customize " + this.props.SpecialLine.itemName : "Customize New Special Line"}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Special Line<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='specialLine'
                                    id='specialLine'
                                    placeholder='Special Line'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.specialLine}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Special Line Position<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='specialLinePosition'
                                    id='specialLinePosition'
                                    placeholder='Special Line Position'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    disabled={false}
                                    defaultValue={this.state.specialLinePosition}
                                >
                                    <option key={1} value={""}>Not Applicable</option>
                                    <option key={2} value={'S'}>Start</option>
                                    <option key={3} value={'E'}>End</option>
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Line Number<span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='number'
                                    name='lineNum'
                                    id='lineNum'
                                    placeholder='Line Number'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.lineNum}
                                />
                            </Col>
                        </Row>
                        <br />


                    </Col>

                    {/* <hr/> */}
                </ModalBody>
                <ModalFooter>
                    {(this.props.selectedSpecialLine != null && this.props.selectedSpecialLine.resellerId != null) ?
                        <Button outline color='danger' type="button">
                            <ConfirmDeleteDialog
                                label={"Remove"}
                                onConfirm={(e) => { this.onRemovePlan() }}
                                msg={`Do you want to delete the ${this.props.selectedSpecialLine.specialLine}? `}
                            />
                        </Button>
                        :
                        null
                    }
                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}  >
                            Submit
                        </Button>}
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}  >
                            Submit
                        </Button>}
                    <Button outline color='secondary' type="reset" onClick={this.onModalClose}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default ClientSpecialLineModal;