/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody, Form, CustomInput } from 'reactstrap';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import ChartBarIcon from 'mdi-react/ChartBarIcon';
import TableLargeIcon from 'mdi-react/TableLargeIcon';
import GoogleMapsIcon from 'mdi-react/GoogleMapsIcon';
import NumericIcon from 'mdi-react/NumericIcon';
import ImageIcon from 'mdi-react/ImageIcon';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import DashboardWidgetEditModal from './DashboardWidgetEditModal';
import DashboardAddmodal from './DashboardAddmodal';

class DasBoardEditModal extends Component{
    constructor(props){
        super(props);
        // this.props.modalList.forEach((item)=>{
        //     item.checked = true;
        // })
        console.log('this.props.modalList outside,',this.props.modalList)
        this.state={
            tital: 'Dashboard Element',
            modalList: [],
            isLocked: false
        }
    }
    //
    // componentWillReceiveProps(nextProps){
    //     console.log('dash modal will get props')
    //
    //
    //
    //
    //     if (nextProps.modalList && nextProps.modalList != this.state.rawModalList){
    //         let islocked = 0;
    //         if (nextProps.modalList && nextProps.modalList[0]){
    //             islocked = (nextProps.modalList[0].locked === 1);
    //         }
    //         this.setState({
    //             modalList: JSON.parse(JSON.stringify(nextProps.modalList)),
    //             rawModalList: nextProps.modalList,
    //             isLocked: islocked,
    //         });
    //     }
    // }

    handleCheckChange = (e, inde) => {
        console.log('this.props.modalList, ',this.props.modalList)

        console.log('e and index is ', e, inde)
         console.log('e.target.checked is ', e.target.checked)
        let tmp = this.props.modalList.map((item,index)=>{
            if(index===inde){item.checked = e.target.checked};return item
        })
        console.log('tmp is:',tmp)

        this.setState({
            modalList: tmp
        })



    }

    onOkayClick = () => {
         
         
         
       //  
         this.props.onDashBoardUpdateClick(this.state.modalList.filter(item=>item.checked == true));
         this.props.toggle();
    }

    onCancleClick = () => {
         
        this.props.toggle();
    }

    onIconClick = (e, layout, index) => {
         
        this.setState({
            currentLayout: layout,
            currentLayoutIndex : index
        });
        this.props.editModalToggle();
    }

    onEditOkayClick = (index, currentParameter, currentName) => {
        // layout.name = currentName;
        // layout.parameter = currentParameter;
         
         
        //  
        this.setState((preState)=>{
            // preState.modalList[index].hidden = value;
           preState.modalList[index].name = currentName;
           preState.modalList[index].parameter = currentParameter;
            
            
            return({
                modalList: preState.modalList, //JSON.parse(JSON.stringify(preState.modalList)),
            });
        });

        // this.setState((preState)=>{
        //     // preState.modalList[index].hidden = value;
        //     preState.modalList[index].name = currentName;
        //      
        //      
        //     return({
        //         modalList: preState.modalList,
        //     });
        // });
    }

    renderIcon = (layout, index) => {
        let icon = null;
        switch (layout.type) {
            case 'map':
            case 'AssetMap':
                return <GoogleMapsIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
            case 'Weather':
                return <GoogleMapsIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'chart':
            case 'DeviceChart':
            case 'DviceUsage':
            case 'MsgCountChart':
            case 'AttributeChart':
                return <ChartBarIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'table':
            case 'AlertTable':
            case 'ActionTable':
            case 'DeviceTable':
            case 'AssetTable':
                return <TableLargeIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
                // break;
            case 'AttributeValue':
                return <NumericIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
            case 'AssetElement':
                return <ImageIcon color='#ff0000' size={50} style={{cursor: 'pointer'}} onClick={(e) => {this.onIconClick(e, layout, index)}}/>;
            default: 
                return null;
        }

        // return {icon};
    }

    handleChange = name => event => {
        // this.setState({ ...this.state, [name]: event.target.checked });
        let isLocked = event.target.checked;
        this.setState((prevState) => {
            prevState.modalList.forEach((element, index)=>{
                if (isLocked){
                    element.locked = 1;
                }
                else{
                    element.locked = 0;
                }
            });
            return(
                { ...this.state, 
                    modalList: prevState.modalList,
                    [name]: isLocked, 
                }
            )
        });
    };
    render(){
        console.log('this.props.modalList in render',this.props.modalList)
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle} size='lg'>
                <ModalHeader toggle={this.props.toggle}>{this.state.tital}</ModalHeader>

                <ModalBody>

                    <Row>

                        {this.props.modalList && this.props.modalList.map((item, index)=>{
                            //let checked = item.display == 1 ? true : false
                            console.log('item is', item)
                            return(
                                <Col md={4} key={index}>
                                <Card>
                                    {/* <CardTitle>{item.title}</CardTitle> */}
                                    <CardBody>
                                    <Row>
                                    {/* {item.title} */}
                                    {item.name}
                                    </Row>
                                        {this.renderIcon(item, index)}
                                        <FormGroup row>    
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    // checked={item.hidden}
                                                    checked = {this.props.modalList[index].checked}
                                                    onChange={(e) =>this.handleCheckChange(e, index)}
                                                    // value={item.hidden}
                                                    color="custom"
                                                    disabled = {this.state.isLocked}
                                                />
                                            }
                                            label="Shown"
                                        />
                                        </FormGroup>
                                </CardBody>
                                </Card>
                                </Col>
                            );
                        })}
                        <Col md={4}>
                        <Card>
                        <CardBody>
                            <Row>
                            Add New Element
                            </Row>
                            {/* <PlusCircleOutlineIcon color='#cdcdcd' size={50}/> */}
                            <DashboardAddmodal
                                templateId={this.props.templateId}
                                templateName = {this.props.templateName}
                                parentToggle={this.props.toggle}/>
                        </CardBody>
                        </Card>
                        </Col>
                    </Row>
                    {/* <hr/>    */}
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkayClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                    <DashboardWidgetEditModal 
                        nestedVisible={this.props.nestedVisible} 
                        toggle={this.props.editModalToggle} 
                        closeAll={this.props.closeAll}
                        layout = {this.state.currentLayout}
                        index = {this.state.currentLayoutIndex}
                        onEditOkayClick = {this.onEditOkayClick}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

export default DasBoardEditModal;
