/* eslint-disable */
// import React from "react";
import React, { Component } from 'react';
import { Col, Row, Button, Input, Label } from 'reactstrap';

const PaymentMethod = (props) => {
    return (
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={5}>
                        <Input
                            type='select'
                            name='pm_paymentType'
                            id='pm_paymentType'
                            className="form-control"
                            onChange={props.onPaymentMethodChange}
                        >
                            {
                                props.remapPaymentAccountList.paymentMethods.map((method, index) => (
                                    (props.remapPaymentAccountList.selectedMethod === method) ?
                                        <option key={index} value={method} selected={true}>{props.remapPaymentAccountList.paymentTypes[method]}</option> :
                                        <option key={index} value={method}>{props.remapPaymentAccountList.paymentTypes[method]}</option>
                                ))
                            }
                        </Input>
                    </Col>
                    {(props.remapPaymentAccountList.selectedMethod != "ACH" && props.remapPaymentAccountList.selectedMethod != "CHK") ?
                        <Col md={5}>
                            <Input
                                type='select'
                                name='pm_paymentCard'
                                id='pm_paymentCard'
                                className="form-control"
                                onChange={props.onPaymentMethodChange}
                            >
                                {
                                    props.remapPaymentAccountList.selectedPayment == null && props.remapPaymentAccountList.defaultPayment == null ?
                                        <option key={-1} value={-1} selected={true}>Please Select</option> : null
                                }
                                {
                                    props.remapPaymentAccountList.paymentAccounts[props.remapPaymentAccountList.selectedMethod].map((ac, index) => (
                                        (props.remapPaymentAccountList.defaultPayment != null) ?
                                            <option key={index} value={ac.accountId} selected={true}>{ac.cardMask}</option> :
                                            <option key={index} value={ac.accountId} >{ac.cardMask}</option>
                                    ))
                                }

                            </Input>
                        </Col>
                        : null}
                </Row>
                <Row><Col><br /></Col></Row>
                <Row>
                    <Col md={6}>
                        <label style={{ marginBottom: '3px' }} for="pm_billingAddress"><span>Billing Address</span></label>
                        <Input
                            type='select'
                            name='pm_billingAddress'
                            id='pm_billingAddress'
                            className="form-control"
                            onChange={props.onPaymentMethodChange}
                            disabled={props.billingAddress.isSameWithShippingAddress}
                        >
                            {props.addressList.map((address, index) => (
                                <option key={index} value={address.id} selected={
                                    props.billingAddress.choseAddress == address.id ? true : false
                                }>{address.addressLine1}</option>
                            ))}
                            <option key={1} value={0} selected={props.billingAddress.choseAddress == 0 ? true : false}>New Billing Address</option>
                        </Input>
                    </Col>
                    <Col>
                        <div style={{ display: 'inline-flex' }}>
                            <Label style={{ display: 'inline', paddingTop: '30px' }} for="pm_sameShippingAddress">
                                <Input
                                    type="checkbox"
                                    name="pm_sameShippingAddress"
                                    id="pm_sameShippingAddress"
                                    style={{ accentColor: '#000000' }}
                                    onChange={props.onPaymentMethodChange}
                                    checked={props.billingAddress.isSameWithShippingAddress}
                                />
                                Same as shipping address
                            </Label>
                        </div>
                    </Col>
                </Row>
                <Row><Col><br /></Col></Row>
                <Row>
                    <Col md={6}>
                        <label style={{ marginBottom: '3px' }} for="pm_name"><span>Name </span><span style={{ color: 'grey' }}>(Optional)</span></label>
                        <Input
                            name='pm_name'
                            id='pm_name'
                            placeholder='ATTN'
                            onChange={props.onPaymentMethodChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.billingAddress.name}
                            disabled={props.billingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                </Row>
                <Row><Col><br /></Col></Row>
                <Row>
                    <Col md={6}>
                        <label style={{ marginBottom: '3px' }} for="pm_billingAddressLine1"><span>Address Line 1</span></label>
                        <Input
                            name='pm_billingAddressLine1'
                            id='pm_billingAddressLine1'
                            placeholder='Enter Street Address'
                            onChange={props.onPaymentMethodChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.billingAddress.addressLine1}
                            disabled={props.billingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                    <Col md={6}>
                        <label style={{ marginBottom: '3px' }} for="pm_billingAddressLine2"><span>Address Line 2 </span><span style={{ color: 'grey' }}>(Optional)</span></label>
                        <Input
                            name='pm_billingAddressLine2'
                            id='pm_billingAddressLine2'
                            placeholder='Entre Apt, Unit, Suite, Building'
                            onChange={props.onPaymentMethodChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.billingAddress.addressLine2}
                            disabled={props.billingAddress.choseAddress != 0 ? true : false}
                        // invalid={isSubmit && !this.validateFirstName()}
                        />
                    </Col>
                </Row>
                <Row><Col><br /></Col></Row>
                <Row>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="pm_city"><span>City</span></label>
                        <Input
                            name='pm_city'
                            id='pm_city'
                            placeholder='Enter City'
                            onChange={props.onPaymentMethodChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.billingAddress.city}
                            disabled={props.billingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="pm_zipCode"><span>Zip Code</span></label>
                        <Input
                            name='pm_zipCode'
                            id='pm_zipCode'
                            placeholder='Enter Zip Code'
                            onChange={props.onPaymentMethodChange}
                            className="form-control"
                            style={{ fontSize: '14px' }}
                            value={props.billingAddress.zipCode}
                            disabled={props.billingAddress.choseAddress != 0 ? true : false}
                        />
                    </Col>
                </Row>
                <Row><Col><br /></Col></Row>
                <Row>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="pm_stateProvince"><span>State/Province</span></label>
                        <Input
                            type='select'
                            name='pm_stateProvince'
                            id='pm_stateProvince'
                            className="form-control"
                            onChange={props.onPaymentMethodChange}
                            disabled={props.billingAddress.country === undefined || props.billingAddress.choseAddress != 0 ? true : false}
                        >
                            <option disabled={props.billingAddress.country === undefined || props.billingAddress.country == '' ? false : true} value="" selected>Choose a State/Province</option>
                            {props.provinceList.map((province, index) => (
                                props.billingAddress.province === province.abbrev ?
                                    <option key={index} value={province.abbrev} selected={true}>{province.name}</option> :
                                    <option key={index} value={province.abbrev}>{province.name}</option>
                            ))}
                        </Input>
                    </Col>
                    <Col md={5}>
                        <label style={{ marginBottom: '3px' }} for="pm_country"><span>Country</span></label>
                        <Input
                            type='select'
                            name='pm_country'
                            id='pm_country'
                            className="form-control"
                            onChange={(e) => { props.onCountryChange(e); props.onPaymentMethodChange(e) }}
                            disabled={props.billingAddress.choseAddress != 0 ? true : false}
                        >
                            <option disabled={true} value="" selected>Choose a Country</option>
                            {props.countryList.map((country, index) => (
                                props.billingAddress.country === country.value ?
                                    <option key={index} value={country.value} selected={true}>{country.label}</option> :
                                    <option key={index} value={country.value}>{country.label}</option>
                            ))}
                        </Input>
                    </Col>
                </Row>
            </Col>

            <Col md={12}><br /></Col>

            <Col md={12}>
                <Button outline color='secondary' type="reset"
                    onClick={() => { props.handleBack() }}
                >
                    Back
                </Button>
            </Col>
        </Row>
    )
}

export default PaymentMethod;