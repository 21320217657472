/* eslint-disable */
import React, { Component, useEffect, useState } from 'react';
import { DeleteCircleIcon, CloseIcon, CloseCircleIcon, PlusCircleIcon, EditIcon, DeleteIcon, TrashCanOutlineIcon, PlusIcon, ChevronLeftIcon, ChevronRightIcon, CogIcon, PowerIcon, CartPlusIcon, PlayIcon, PauseIcon, StopIcon } from 'mdi-react';

export function IconTextButton({ onClick, icon, color, label, disabled, className }) {
  return (
    <button style={{ border: 0, background: 'none', }}
      className={className}
      disabled={disabled}
      onClick={onClick}>
      {icon({
        size: 25,
        color: color,
        style: {
          marginRight: 5,
          marginBottom: 5,
          cursor: `${disabled ? 'not-allowed' : 'unset'}`
        }
      })}
      <span style={{ color: color }}>{label}</span>
    </button>
  )
}

export function RemoveSectionButton({ onClick }) {
  return (
    <div className='text-center'>
      <IconTextButton
        onClick={onClick}
        color='#fa0'
        label="Remove Section"
        icon={(props) => (<CloseCircleIcon {...props} />)}
      />
    </div>
  )
}

export function RemoveCircleIconButton({ onClick, label }) {
  return (
    <div className='text-center'>
      <IconTextButton
        onClick={onClick}
        color='#fa0'
        label={label}
        cursor='pointer'
        icon={(props) => (<CloseCircleIcon {...props} />)}
      />
    </div>
  )
}

export function PlusCircleIconButton({ onClick, label }) {
  return (
    <IconTextButton
      onClick={onClick}
      color='#fa0'
      label={label}
      icon={(props) => (<PlusCircleIcon {...props} />)}
    />
  )
}

export function EditTextButton({ onClick, label, disabled }) {
  return (
    <IconTextButton
      onClick={onClick}
      disabled={disabled}
      color='#0066cc'
      label={label}
      icon={(props) => (<EditIcon {...props} />)}
    />
  )
}

export function DeleteButton({ onClick, label }) {
  return (
    <IconTextButton
      onClick={onClick}
      color='#ff4861'
      label={label}
      icon={(props) => (<DeleteIcon {...props} />)}
    />
  )
}

export function ManageTextButton({ onClick, label }) {
  return (
    <IconTextButton
      onClick={onClick}
      color='#555555'
      label={label}
      icon={(props) => (<CogIcon {...props} />)}

    />
  )
}

export function ExitTextButton({ onClick, label }) {
  return (
    <IconTextButton
      onClick={onClick}
      color='#555555'
      label={label}
      icon={(props) => (<CloseIcon {...props} />)}
    />
  )
}

export function PowerTextButton({ onClick, label }) {
  return (
    <IconTextButton
      onClick={onClick}
      color='#555555'
      label={label}
      icon={(props) => (<PowerIcon {...props} />)}
    />
  )
}

export function CartPlusTextButton({ onClick, label }) {
  return (
    <IconTextButton
      onClick={onClick}
      color='#555555'
      label={label}
      icon={(props) => (<CartPlusIcon {...props} />)}
    />
  )
}

export function DeleteTextButton({ onClick, label, disabled }) {
  return (
    <IconTextButton
      onClick={onClick}
      disabled={disabled}
      color='#830300'
      label={label}
      icon={(props) => (<TrashCanOutlineIcon {...props} />)}
    />
  )
}

export function PlusIconButton({ onClick, label }) {
  return (
    <IconTextButton
      onClick={onClick}
      color='#526b2d'
      label={label}
      icon={(props) => (<PlusIcon {...props} />)}
    />
  )
}

export function BackButton({ onClick, label, size, color = '#526b2d' }) {
  return (
    <IconTextButton
      onClick={onClick}
      color={color}
      label={label}
      size={size}
      icon={(props) => (<ChevronLeftIcon {...props} />)}
    />
  )
}

export function NextButton({ onClick, label, size = 25, color = '#526b2d' }) {
  return (
    <button style={{ border: 0, background: 'none', }}
      onClick={onClick}>
      <span style={{ color: color }}>{label}</span>
      <ChevronRightIcon
        size={size}
        color={color}
        style={{
          marginRight: 5,
          marginBottom: 5
        }}
      />
    </button>
  )
}

export function PlayButton({onClick, label, size = 20, isPlay}) {
  return (
    <IconTextButton
      onClick={onClick}
      label={label}
      size={size}
      icon={(props)=> isPlay ? (<PlayIcon {...props}/>) : (<PauseIcon {...props}/>)}
      className={"mediaBtn"}
    />
  )
}

export function StopButton({onClick, size = 20}) {
  return (
    <IconTextButton
      onClick={onClick}
      label={"STOP"}
      size={size}
      icon={(props)=> (<StopIcon {...props}/>)}
      className={"mediaBtn"}
    />
  )
}