/* eslint-disable */
import React, { Component } from 'react';
import {Container, Row, Col, Button, Label, } from 'reactstrap';
import TrafficLightWidget from './TrafficLightWidget';
import DonutWidget from './DonutWidget';
import BatteryWidget from './BatteryWidget';
import AttributeListWidget from './AttributeListWidget';
import {TIME_ZONE} from '../../../constants/dataConstants';
import {weatherSeverity, hex2bin, splitStatusValue} from '../../../factories/utils';

const SnowMapInfo = ({props, toggle, alarmSetObj, statusDataProp }) => {
    
    let alarm = 0;
    let hexAlarm = null;
    let activeTab = 1;
    if (props.asset!=null){
        if(props.asset.grouping == "wind"){
            activeTab = 2;
        }
    }
    
    const tableTimeColoumnFormater = (cell) => {
        if (cell) {
            try{
                let displayTime = new Date(cell).toLocaleString('en-US', { timeZone: TIME_ZONE });  
                return(
                    <span>{displayTime}</span>
                );
            }
            catch(e){
                return null;
            }
        }else{
            return null;
        }
        
    }
    
    const asset = props && props.asset ? props.asset : {};

    return(
        <div style={{width: '200px', height: '300px'}}>
        <Row style={{margin: '0px'}}>
            <Col md={12} sm={12} style={{textAlign: 'center', paddingRight: '0', paddingLeft: '0', overflow: 'hidden'}}>
                <div style={{width: '50%'}} >
                <AttributeListWidget 
                    alarmSet={alarmSetObj} 
                    asset={asset} 
                    isSmall={true} 
                    toggle = {toggle}
                    activeTab={activeTab}
                    style={{height: '150px'}}
                />
                </div>
            </Col>
        </Row>
        <br/>
    </div>
    )
}
export default SnowMapInfo;