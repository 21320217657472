/* eslint-disable */
import React, { Component, Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  Label,
  Container,
  Input,
  Form,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { EditIcon } from "mdi-react";
import MultiSelectionComponent, {
  SingleSelection,
} from "../../Asset/components/SelectionComponents";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import DecoderEditModal from "./DecoderEditModal";
import { loadClasses, loadAssets } from "../../../redux/actions/assetActions";


const inputStyle = {
  maxWidth: "30%",
  minHeight: "150px",
};

const deviceSelect = (value) => {
  this.setState({
    deviceToEdit: value,
  });
};

class DeviceConfigBoard extends Component {
  constructor(props) {
    super(props);
    this.id = [];
    this.state = {
      selectedDeviceInfo: [],
      assetClassList: [],
      deviceToEdit: null,
      submitForm: null,
      renderTable: [],
      jsonInput: null,
      dataElementToEdit: {},
      allowedDeviceTypes: [],
      selectedAssetClassId: null,
      showEditModal: false,
    };
  }

  componentDidMount() {
    this.setState({
      allowedDeviceTypes: this.state.assetClassList.allowedDeviceTypes
    })
  }

  onSubmit = () => {
    if (this.validateJson(this.state.jsonInput)) {
      const data = JSON.parse(this.state.jsonInput);
      let result = [];
      let statusData = this.jsonToArray(result.push(data));
      this.setState({
        renderTable: statusData,
      });
    } else {
      alert("Please input JSON format.");
    };
  }
  
  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  
  handelEditTableData = (param) => {
    if(param && this.state.renderTable.length > 0){
      // console.log('===EDIT TABLEs', this.state.renderTable, param)
      let tempTableData = this.state.renderTable.map((el, ind) => {
        if(el.dataElement == param.dataElement){
          el = param
          return el
        }else{
          return el
        }
      })
      // console.log("===tempTableData", tempTableData);
      this.setState({
        renderTable: tempTableData
      })

    }
    // this.setState({
    //   renderTable:
    // })
  }

  validateJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  

  jsonToArray = () => {
    const result = [];
    if(this.validateJson(this.state.jsonInput)) {
      
      const input = JSON.parse(this.state.jsonInput);
      for(let key of Object.keys(input)) {
        let obj = {
          dataElement : "",
          from :"",
          size :"",
          to :"",
          dataType :"",
          lowerLimit :"",
          upperLimit :"",
          initialValue :"",
          formula :"",
        };
        obj.dataElement = key;
        result.push(obj);
      }

      return result;
    }
  }

  dataElement = (cell, row) => {
    return (
      <div>{cell}</div>
    )
  }

  showEditModal = () => {
    this.setState(prevState => ({
      showEditModal: !prevState.showEditModal
    }));
    // setTimeout(() => {
    //   this.setState({ showEditModal: false });
    // }, 1);
  }

  editFrom = (cell, row) => {
    // this.editSelectedRow(row.dataElement);
 
    return (
      <div className="actions" style={{ cursor: 'pointer' }}>
          <EditIcon color="#0066cc" onClick={() => this.editSelectedRow(row)}/>
          Edit
      </div>
    )
  }

  closePanel = () => this.setState({ dataElementToEdit: null });

  editSelectedRow = (dataElement) => {
    this.setState({
      dataElementToEdit: dataElement,
      showEditModal: true
    })
  }

  selectedAssetClass = (event) => {
    this.state.selectedAssetClassId = parseInt(event.target.value)
    
    this.deviceTypeOptions();
    

  }

  deviceTypeOptions = () => {
    let options = []
    if(this.state.selectedAssetClassId){
      let selectedClass = this.state.assetClassList.find(d => (d.id == this.state.selectedAssetClassId));
      this.state.allowedDeviceTypes = selectedClass.allowedDeviceTypes;
    };

    if(this.state.allowedDeviceTypes.length > 0){
      let i = 0;
      while(i < this.state.allowedDeviceTypes.length) {
        options.push(this.state.allowedDeviceTypes[i]);
        i++;
      }
    };

    this.setState({
      allowedDeviceTypes: options
    });
  }

  sizeInput = (cell, row) => {
    return(
      <div>{row.size}</div>
    )
  }

  fromInput = (cell, row) => {
    return(
      <div>{cell}</div>
    )
  }

  toInput = (cell, row) => {
    return(
      <div>{cell}</div>
    )
  }

  dataTypeInput = (cell, row) => {
    return(
      <div>{cell}</div>
    )
  }

  lowerLimitInput = (cell, row) => {
    return(
      <div>{cell}</div>
    )
  }

  upperLimitInput = (cell, row) => {
    return(
      <div>{cell}</div>
    )
  }

  initialValueInput = (cell, row) => {
    return(
      <div>{cell}</div>
    )
  }
  
  formulaInput = (cell, row) => {
    return(
      <div>{cell}</div>
    )
  }


  render() {
    const assetClassList = this.props.assetClassList.map((c) => ({ id: c.id, name: c.name, attribute: c.attributeMetas, allowedDeviceTypes: c.allowedDeviceTypes }));
    this.state.assetClassList = assetClassList;
    let deviceList = this.props.deviceList.map((d) => ({
      id: d.deviceid,
      name: d.devicename,
      namealias: d.devicenamealias,
      devicetypename: d.devicetypename,
    }));
    return (
      <Card>
        <CardBody style={{ marginLeft: "0.5%" }}>
          <div>
            <Label for="deviceName" style={{ display: "inline" }}>
              <h5>Device Name</h5>
            </Label>
          </div>
          <div class="d-flex justify-content-between">
            <SingleSelection
              isSingle={true}
              options={deviceList}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ name }) => name}
              isClearable={true}
              // onChange={this.deviceSelect(deviceList.id)}
              style={{ maxWidth: "30%", border: "0px" }}
            />
            <Button>+ Add Device</Button>
          </div>
          <br />
          <br />
          <br />
          <Row style={{ maxWidth: '30%' }}>
            <Col>
            <p>Asset Class</p>
            <SingleSelection
              isSingle={true}
              forIdName="assetClass"
              options={this.props.assetClassList}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ name }) => name}
              isClearable={true}
              onChange={this.selectedAssetClass}
              // defaultValue={}
            />
            </Col>
          </Row>
          <Row style={{ maxWidth: '30%' }}>
            <Col>
              <p>Device Type</p>
            <SingleSelection
              isSingle={true}
              options={this.props.assetClassList ? this.props.assetClassList[0].allowedDeviceTypes : this.state.allowedDeviceTypes}
              getOptionValue={({ id }) => id}
              getOptionLabel={({ name }) => name}
              isClearable={true}
            />
            {/* <Select
              options={this.deviceTypeOptions()}
            /> */}
            </Col>
            <Col>
              <p>Message Width</p>
              <Input type="text"/>
            </Col>
          </Row>
          <Row style={{ maxWidth: '30%', marginTop: '1%' }}>
            <Col>
              <p>Encruption Method</p>
              <Select />
            </Col>
            <Col>
              <p>Decryption Key</p>
              <Button
                className="btn"
                style={{ height: '58%', padding: 'inherit' }}
              >
                Choose a file
              </Button>
            </Col>
          </Row>
          <br />
          <div>
            <Form>
              <FormGroup>
                <Label for="jsonBody">Body (Json): </Label>
                <div class="d-flex justify-content-start">
                  <Input
                    type="textarea"
                    name="jsonInput"
                    id="jsonBody"
                    style={inputStyle}
                    onChange={this.onInputChange}
                  />
                </div>
              </FormGroup>
              <Button
                color="custom"
                className="btn"
                type="button"
                size="sm"
                onClick={this.onSubmit}
              >
                Submit
              </Button>
            </Form>
          </div>
          <div style={{ maxWidth: "99%" }}>
            <BootstrapTable
              data={this.state.renderTable}
              tableHeaderClass="header_white"
            >
              <TableHeaderColumn dataField="dataElement" dataFormat={this.editFrom}>Edit</TableHeaderColumn>
              <TableHeaderColumn dataField="dataElement" isKey={true} dataFormat={this.dataElement}>
                Data Element
              </TableHeaderColumn>
              <TableHeaderColumn dataField="size" dataFormat={this.sizeInput}>Size</TableHeaderColumn>
              <TableHeaderColumn dataField="from" dataFormat={this.fromInput}>From</TableHeaderColumn>
              <TableHeaderColumn dataField="to" dataFormat={this.toInput}>To</TableHeaderColumn>
              <TableHeaderColumn dataField="dataType" dataFormat={this.dataTypeInput}>Data Type</TableHeaderColumn>
              <TableHeaderColumn dataField="lowerLimit" dataFormat={this.lowerLimitInput}>Lower Limit</TableHeaderColumn>
              <TableHeaderColumn dataField="upperLimit" dataFormat={this.upperLimitInput}>Upper Limit</TableHeaderColumn>
              <TableHeaderColumn dataField="initialValue" dataFormat={this.initialValueInput}>Initial Value</TableHeaderColumn>
              <TableHeaderColumn dataField="formula" dataFormat={this.formulaInput}>Formula</TableHeaderColumn>
            </BootstrapTable>
            <Card>
              <CardBody>
            <DecoderEditModal show={this.state.showEditModal} toggle={this.showEditModal} next={this.onNext} editRow={this.state.dataElementToEdit} handelEditTableData={this.handelEditTableData}/>
              </CardBody>
            </Card>
          </div>
          <br />
          <div class="d-flex justify-content-end">
            <Button
              color="custom"
              style={{ marginBottom: 0 }}
              onClick={() => props.closePanel()}
            >
              Cancel
            </Button>
            <Button
              color="custom"
              style={{ marginBottom: 0 }}
              onClick={() => this.props.updateMessageDecodeOnStore()}
            >
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

// function mapStateToProps(state) {
//   const devicetypes = state.mapboard.types;
//   const currentDeviceList = state.asset.currentDeviceList;
// }

export default DeviceConfigBoard;
