/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label, BarChart, Bar } from 'recharts';
import { renderSpinner } from './PeopleCountBoard';
import moment from 'moment';
import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { groupByArray } from '../../../../factories/utils';
import IndividualCamChart from './IndividualCamChart';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Switch from '@material-ui/core/Switch';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { GreenSwitch } from '../../../Asset/components/AssetList';


const PeopleCountGraph = ({localData, limit, loadPeopleCount, camNum, camList, filterDate, handleDateChange}) => {
    const [chartData, setchartData] = useState([])
    // const [SortedData, setSortedData] = useState([])
    const [showOnPremises, setShowOnPremises] = useState(false)

    useEffect(() => {
        if(localData && localData.length > 0){
            //convert sql timestamp to normal time
            let sumCamDataByHour = []
            let camList = []
            //map through each cam and format timestamp and add timeLabel for graph
            localData.map(camData=>{
                camData.data.map(d=>{
                        d.time = moment(d.timestamp).local().format('MM-DD-YYYY HH:00')
                        d.timeLabel = moment(d.timestamp).local().format('HH:00')
                    }
                )
                let groupedByTimeCamData = groupByTime(camData.data, 'time')
                // console.log('GROUPED BY TIME CAM DATA', groupedByTimeCamData)
                for(const [key, value] of Object.entries(groupedByTimeCamData)){
                    let lastVal = value[value.length - 1]
                    let hourIndex = sumCamDataByHour.findIndex(c=>c.time === key)
                    if(hourIndex === -1){
                        sumCamDataByHour.push({
                            time: key,
                            timeLabel: lastVal.timeLabel,
                            entranceCount: lastVal.entranceCount,
                            exitCount: lastVal.exitCount
                        })
                    }else{
                        sumCamDataByHour[hourIndex].entranceCount += lastVal.entranceCount
                        sumCamDataByHour[hourIndex].exitCount += lastVal.exitCount
                    }
                }
            })
            // console.log('SUM CAM DATA BY HOUR', sumCamDataByHour)
            sumCamDataByHour = sumCamDataByHour.sort((a, b) => {
                if(a.time < b.time){
                    return -1
                }else if(a.time > b.time){
                    return 1
                }
                return 0
            })
            //reduce sumCamDataByHour to get different entranceCount and exitCount for each hour
            let tempChartData = sumCamDataByHour.reduce((acc, cur, index, arr)=>{
                let differentEntranceCount
                let differentExitCount
                let onPremises
                if(index > 0){
                    differentEntranceCount = cur.entranceCount - arr[index-1].entranceCount < 0 ? 0 : cur.entranceCount - arr[index-1].entranceCount
                    differentExitCount = cur.exitCount - arr[index-1].exitCount < 0 ? 0 : cur.exitCount - arr[index-1].exitCount
                    onPremises = differentEntranceCount - differentExitCount < 0 ? 0 : differentEntranceCount - differentExitCount
                }else{
                    differentEntranceCount = cur.entranceCount
                    differentExitCount = cur.exitCount
                    onPremises = cur.entranceCount - cur.exitCount < 0 ? 0 : cur.entranceCount - cur.exitCount
                }
                let addData = {
                    time: cur.time,
                    timeLabel: cur.timeLabel,
                    Limit: limit ? limit : 0, 
                    entranceCount: differentEntranceCount,
                    exitCount: differentExitCount, 
                    onPremises: onPremises
                }
                acc.push(addData)
                return acc
            }, [])
            // console.log('TEMP CHART DATA', tempChartData)
            setchartData(tempChartData)
            
        }else{
            setchartData([])
        }
    }, [localData, limit, filterDate, camNum, camList])


    const groupByTime = (arr) =>{
        let groupedArray = groupByArray(arr, 'time')
        return groupedArray
    }

    const handleDatePickerChange =(date) =>{
        date = moment(date).format('MM-DD-YYYY')
        handleDateChange(date);
        // console.log('========DATE CHANGE=========', date.toString());
        // loadPeopleCount(date.toString());
        // debugger;
    }

    const handleChange = (event) => {
        setShowOnPremises(event.target.checked);
    }
    
    return (
        <>
        <Row className="container center" style={{marginTop: "20px", width: "100%"}}>
            <MuiPickersUtilsProvider utils={MomentUtils}>

                <KeyboardDatePicker 
                    autoOk
                    inputVariant="outlined"
                    label="Choose Date"
                    onChange={date=>handleDatePickerChange(date)}    
                    variant="inline"
                    format="MM/DD/YYYY"
                    InputAdornmentProps={{ position: "start" }}
                    value={filterDate}
                    maxDate={new Date()}
                />
            </MuiPickersUtilsProvider>
            {/*TODO TEST RETREE !!!!!!!!!!!!!!!!!*/}
            {/*{<Button color="seconday" className="box" onClick={(event) => {
                this.props.getResellersTree({isGetAll:true})
            }}>getResellersTree</Button>}*/}
        </Row>
        {chartData.length > 0 && <Row className="container center" style={{marginTop: "20px",marginBottom: "20px", width: "100%"}}>
            <Typography>Entrance</Typography>
            <GreenSwitch
                checked={showOnPremises}
                onChange={handleChange}
                name="toggleSwitch"
            />
            <Typography>On Premises</Typography>
        </Row>}
        {showOnPremises && chartData.length > 0 && (
            <ResponsiveContainer height={250} width="98%" maxWidth={300}>
                <BarChart
                    data={chartData}
                    width={250} height={230}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="timeLabel">
                                <Label value="Hourly data" offset={-2} position="insideBottom" />
                        </XAxis>
                        <YAxis>
                            <Label value="Number of People" angle={-90} position="insideLeft" />
                        </YAxis>
                        <Tooltip />
                        <Legend verticalAlign="top" height={36}/>
                        {/* <Line type="monotone" dataKey="entranceCount" legendType="circle" stroke="#0066ff" />
                        <Line type="monotone" dataKey="exitCount" legendType="circle" stroke="#ff3300" /> */}
                        <Line type="monotone" dataKey="Limit" legendType="circle" stroke="#4f4443" />
                        <Bar dataKey="onPremises" name = "On Premises" fill="#33cc33" legendType="circle" strokeWidth={3} />
                </BarChart>
            </ResponsiveContainer>
        )}

        {!showOnPremises && chartData.length > 0 && ( 
            <ResponsiveContainer height={250} width="98%" maxWidth={300}>
                <BarChart
                    data={chartData}
                    width={250} height={230}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="timeLabel">
                                <Label value="Hourly data" offset={-2} position="insideBottom" />
                        </XAxis>
                        <YAxis>
                            <Label value="Number of People" angle={-90} position="insideLeft" />
                        </YAxis>
                        <Tooltip />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="entranceCount" name="Entrance Count" legendType="circle" fill="#0066ff" strokeWidth={3} />
                        {/* <Line type="monotone" dataKey="exitCount" legendType="circle" stroke="#ff3300" strokeWidth={2}  /> */}
                        {/* <Line type="monotone" dataKey="Limit" legendType="circle" stroke="#4f4443" />
                        <Line type="monotone" dataKey="On Premises" stroke="#33cc33" legendType="circle" strokeWidth={3} /> */}
                </BarChart>
            </ResponsiveContainer>
        )}


        </>
    )
}

// const mapStateToProps = (state) => ({
    
// })

// const mapDispatchToProps = (dispatch) => {
    
// }

export default PeopleCountGraph
