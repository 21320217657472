/* eslint-disable */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import InventoryBoard from './components/InventoryBoard';
import {UserContext} from '../../constants/myContexts';

const InventoryPage = () => {
  let {userObj, updateUserObj} = React.useContext(UserContext);
  if (!userObj) {
    toast.error('Can not fetch login user information, Please re-login.');
    props.history.push('/log_out');
  } else {
     
  }


  return(
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Inventory Master</h3>
          <hr />
        </Col>
      </Row>
      <Row>
        <InventoryBoard userObj={userObj} />
      </Row>
    </Container>
  )
};
export default withRouter(InventoryPage);
