/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup } from 'reactstrap';
import StepTemplate from './StepTemplate';

class StepAnalysis extends Component {
    constructor(props){
        super(props);
    }

    renderActionForm=()=>{
        return(
            <Form>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="startTime"  style={{display: 'inline', width: "100%"}} >Analysis Name</Label>
                                <Input type="text" id="startTime" name="startTime" width="100%" >
                                </Input>     
                            </Col>                   
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label for="endTime"  style={{display: 'inline', width: "100%"}} >Description</Label>
                                <Input type="textarea" id="endTime" name="endTime" width="100%">
                                </Input>  
                            </Col>                      
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        );
    }
    updateActionInfo = () => {

    }

    handleBack = () => {
        this.props.onBack();
    }

    handleNext = () => {
        // if (this.updateActionInfo()) {
        //     this.props.handleNext();
        // }else{
        //     // todo alert notification;
        // };     
        this.props.onNext();
    }

    render(){
        return(
            <StepTemplate
                classes = {this.props.classes}
                canGoBack = {this.props.canGoBack}
                canGoForward = {this.props.canGoForward}
                onBack = {this.handleBack}
                onNext = {this.handleNext}
                onCancel = {this.props.onCancel}
                text = {this.props.text}
            >
                {this.renderActionForm()}
            </StepTemplate>
        );
    }
}

export default StepAnalysis;