/* eslint-disable */
import React, { Component, Fragment, useState } from 'react';
import {
    compose, 
    bindActionCreators
} from "redux";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import classnames from 'classnames';
import {
    getContact, 
    upsertContact, 
    deleteContact,
} from '../../../redux/actions/jobAction'; 
import UserEditFrom from '../../AccountMgmt/components/userEditForm';
import ContactBoard from './ContactBoard';

class ProfileMainBoard extends Component {
    constructor(props){
        super(props);
        this.state={
            activeTab: '1',
        };
    }

    componentDidMount(){
        this.props.getContact();
    }
// ----------------------------------------------------
handleClose = () => {
    this.props.history.push('/pages/dashboard');
} 
renderUserEdit = () => {
    return(
        <Card>
            <CardBody>
                <UserEditFrom 
                    userObj={this.props.userObj} 
                    isCurrent = {true}
                    updateUserCallBack = {this.props.updateUserObj}
                    toggle={this.handleClose}
                />  
            </CardBody>
        </Card>
    );
}
// ----------------------------------------------------
renderUserContact = () => {
    return(
        <ContactBoard
            // label={'Edit Receiver'}
            userObj={this.props.userObj}
            toggle={this.handleClose}
            contactList={this.props.contactList}
            upsertContact={this.props.upsertContact}
            deleteContact = {this.props.deleteContact}
        />
    );
}
// ----------------------------------------------------
toggle = (tab) => {
    this.setState(prevState =>{
        if (prevState.activeTab !== tab){
            return {
                activeTab: tab
            }
        }else{
            return null;
        }
    });
}
render(){
    let { activeTab } = this.state;
    return(
        <div  style={{width: '75%'}}>
            <Nav tabs>
                <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => {this.toggle('1')}}>
                        <h4><b>User Info </b></h4>
                    </NavLink>
                </NavItem>
                <NavItem style={{ cursor: 'pointer' }}>
                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => {this.toggle('2')}}>
                        <h4><b>Contact </b></h4>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId='1'>
                    {this.renderUserEdit()}
                </TabPane>
                <TabPane tabId='2'>
                    {this.renderUserContact()}
                </TabPane>
            </TabContent>              
        </div>
    );
}
}

// export default ProfileMainBoard;

function mapStateToProps(state) {
    let { contactList } = state.job;
    return { contactList };
    // return state.weather;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getContact, 
        upsertContact, 
        deleteContact,
    }, dispatch);
};

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter
);

export default enhance(ProfileMainBoard);