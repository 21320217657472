/* eslint-disable */
import React, { Component, Fragment } from "react";
import MarkerClusterer from "@google/markerclusterer";
import { withRouter } from 'react-router-dom';


class ClusterWrapper extends Component {
  constructor(props) {
    super(props);

    this.markers = [];
    this.iconIntervals = [];
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.google !== this.props.google ||
      prevProps.mapData !== this.props.mapData
    ) {
    }
    this.clearAllMarkers();
    this.renderCluster();
  }

  componentWillReceiveProps(nextProps) {

  }

  componentWillUnmount() {
    this.clearAllMarkers();
  }

  clearAllMarkers = () => {
    if (this.iconIntervals) {
      this.iconIntervals.forEach((item, index) => {
        clearInterval(item);
      });
    }

    if (this.clusterer) {
      this.clusterer.clearMarkers();
    }
  };

  renderCluster = () => {
    let { google, map } = this.props;
    this.markers = [];
    if (this.props.mapData) {
      let that = this;

      this.props.mapData.forEach((item, index) => {
        let m = item;
        if (m.locationlat !== 0 || m.locationlng !== 0) {
          let path = that.props.defaultIconPath;
          let color = that.props.defaultColor;
          
          if (item.assetIcon && item.assetIcon.iconsvg) {
            path = item.assetIcon.iconsvg;
          };
          
          let alarmObj = this.props.alarmSetObj ? this.props.alarmSetObj[m.assetid] : null;
          if ( m.attributes && m.attributes["hexAlarm"] && m.attributes["hexAlarm"].length > 0) {
            delete m.attributes["hexAlarm"];
          };
          // let statusSet = setDonut(alarmObj, m.attrobutes, m.color);
          let statusSet = {status: 0, color: 'grey'};
          if(m.color != null && m.color != undefined) {
            statusSet.color = m.color;
            if(statusSet.color == "off" || statusSet.color == "OFF") {
              status.color = "GREY";
            }
          }
          color = statusSet.color;


          let ref = {
            map:map,
            name: m.assetname,
            timestamp: m.timestamp,
            attributes: m.attributes,
            asset: m,
            position: { lat: Number(m.locationlat), lng: Number(m.locationlng)},
            icon: that.props.generateIcon(color, path)
          };

          let marker = new google.maps.Marker(ref);
          // marker.addListener("click", (e) => {
          //   alert("Clicked")
          //   // let path = '/pages/surveillance';
          //   // this.props.history(push({
          //   //   pathname: path
          //   // }))
          // });
          marker.addListener("mouseover", (e)=>{
            console.log('ABC')
            this.props.onMarkerHover(ref, marker, e)
          })
          marker.addListener("mouseout", (e)=>{
            // alert("Mouse out")
            // // this.props.onMarkerOut()
          })
          this.markers.push(marker);
        }
      });

      this.clusterer = new MarkerClusterer(map, this.markers, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', maxZoom: 20 })
    }

    return null
  };

  render() {
    return (
      <Fragment>
        {this.props.children}
      </Fragment>
    )
  }
}

export default withRouter(ClusterWrapper);