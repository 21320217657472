/* eslint-disable */
import React, { Component } from 'react';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {BackButton, NextButton} from '../../../shared/components/Button/IconButton'; 

class InventoryPurchaseItemList extends Component {
    constructor(props){
        super(props);
        this.state={
            itemList:[],
        }
    }



    onBackClick = (e) => {
        this.props.onPurchaseItemBack();
    }
    onNextClick = (e) => {
        this.props.onPurchaseItemNext();
    }

    onInputValueChange = (value, obj) => {
        // this.setState(prevState => {
        //   var foundItem = prevState.itemList.find(ele => (ele.sku == obj.sku))
        //   if (foundItem) {
        //     foundItem.qty = value
        //   }else{
        //     prevState.itemList.push({sku: obj.sku, qty: value})
        //   }
        //   return {
        //     itemList: prevState.itemList,
        //   }
        // }, () => {
        //    
        // });
        this.props.onInputValueChange(value, obj);
    }
    InputFormater = (cell, row) => {
        // if (Number.isInteger(cell)) {
        //   const q = parseInt(cell);
        //   if (q > 0) {
        //     return (
        //       <Input type='number' min="0" step="1" max={cell} defaultValue='0' onChange={(e)=>{this.onInputValueChange(e.target.value, row)}} ></Input>
        //     );
        //   } 
        // }
        // return (
        //   <span>Out of Stock</span>
        // );
        return (
            <Input type='number' min="0" step="1" defaultValue='0' onChange={(e)=>{this.onInputValueChange(e.target.value, row)}} ></Input>
        );
    }

    render(){
        return (
            <Col md={12}>
            <BootstrapTable 
                  data={ this.props.supplierItemList}  
                  // tableHeaderClass = 'header_white' 
                  pagination = {false} 
                  tableHeaderClass = 'header_white' 
                   
                  bordered={ false } 
                  // insertRow 
                  ref='table' 
              >
                {/* <TableHeaderColumn dataField='' dataAlign="center"  width='5%' dataFormat={this.checkFormater}></TableHeaderColumn>  */}
                <TableHeaderColumn dataField='sku' isKey={ true } dataAlign="center" width='10%' >SKU</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='company' dataAlign="center" dataSort width='20%' >COMPANY</TableHeaderColumn>  */}
                <TableHeaderColumn dataField='name' dataAlign="center" dataSort width='10%' >Item</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='group' dataAlign="center" dataSort hidden >GROUP</TableHeaderColumn>                      */}
                <TableHeaderColumn dataField='modal' dataAlign="center" dataSort width='10%' >Modal</TableHeaderColumn>  
                <TableHeaderColumn dataField='vender' dataAlign="center" dataSort width='10%'>Vender</TableHeaderColumn>  
                <TableHeaderColumn dataField='price' dataAlign="center" dataSort width='15%' >Unit Price</TableHeaderColumn> 
                <TableHeaderColumn dataField='quantity' dataAlign="center"  width='10%' dataFormat={this.InputFormater}>Quantity</TableHeaderColumn>  
                {/* <TableHeaderColumn dataField='soldquantity' dataAlign="center" dataSort width='10%' >SOLD QUANTITY</TableHeaderColumn>   */}
                <TableHeaderColumn dataField='network' dataAlign="center" dataSort >Network</TableHeaderColumn>  
                              
            
            </BootstrapTable>
            <Row>
            <Col md={6} style={{display:'flex', justifyContent:'flex-start'}}>
              <BackButton
                  onClick={this.onBackClick} 
                  label="Back"
                  size={30}
                  color = 'black'
              />
            </Col>
            <Col md={6} style={{display:'flex', justifyContent:'flex-end'}}>
                <NextButton
                    onClick={this.onNextClick} 
                    label="Next"
                    size={30}
                    color = 'black'
                />
            </Col>
        </Row>
        </Col>
        )
    }
}
export default InventoryPurchaseItemList;