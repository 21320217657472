/*eslint-disable*/
import React, { Component, Fragment } from "react";
import uuid from "uuid/v4";
import AddPaymentModal from "./payment/addPaymentModal";
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {itemFinder, dropdownOnChangeHandler} from '../../../factories/utils';
import {BackButton, NextButton} from '../../../shared/components/Button/IconButton'; 
import { USAGE_PRICE } from '../../../constants/dataConstants';

class CompanyAccountPurchaseComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
        currentDeviceTypeId: 29,
        selectedSubPlan:"<1000",
        planDetail: [    
          {
            '1000': 400,
            '10000': 3558,
            '25000': 8031,
            '50000': 13982,
            '100000': 23274,
            '250000': 45133,
            '500000': 74336,
            '750000': 98894,
            '1000000': 114159,
            '1500000': 144690
          }
         
        ],
        plans: [
            {
              planId: 29,
              planName: "Basic - Annual", 
            }
          ],

          currentPlanItemId: null,
          currentPlanItemName:'',
          currentPlanItem: null,
          currentPlanItemRateList: null,
          autoPay: false,
          itemType: '1',
     };
   }

   componentDidMount() {
     this.props.getInventoryList()
   }

  handleChangeHandler = (e) => {
    // let value = dropdownOnChangeHandler(e);
    //     this.setState( { 
    //         currentPlanItemId: value.id,
    //         currentPlanItemName: value.name,
    //     });
   
    // const planItem = this.props.planItemsList.filter(item => (item.itemId == this.state.currentPlanItemId));
    // this.setState( { 
    //     currentPlanItem: planItem,
    //     currentPlanItemRateList: planItem.length !=0 ? planItem[0].itemRateList : []
    // });

    // let value = dropdownOnChangeHandler(e);
    // // const planItem = this.props.planItemsList.filter(item => (item.itemId == value.id));
    // const planItem = this.props.planItemsList.find(item => (item.itemId == value.id));
    // this.setState( { 
    //     currentPlanItemId: value.id,
    //     currentPlanItemName: value.name,
    //     currentPlanItem: planItem,
    //     // currentPlanItemRateList: planItem.length !=0 ? planItem[0].itemRateList : []
    //     currentPlanItemRateList: planItem ? planItem.itemRateList : []
    // });
    this.props.handlePlanChange(e)
    // console.log("debug handleChangeHandler " + this.state.currentPlanItemRateList)
        
    
}

handleAutoPayClick = (e) => {
  // let checked = e.target.checked;
  // this.setState({
  //   autoPay: checked
  // });
  this.props.handleAutoPayClick(e);
}

handBillCycleChange = (e) => {
  this.props.handBillCycleChange(e)
}

typeToggle = (value) => {
  // this.setState({
  //   itemType: value
  // });
  this.props.typeToggle(value);
}

renderPlans = () => {
    let planName = this.props.currentPlanItem ? this.props.currentPlanItem.itemName: null;  
    let planDescription = this.props.currentPlanItem ? this.props.currentPlanItem.itemDescription: '';
    let payFrequency = this.props.currentPlanItem && this.props.currentPlanItem.payFrequency ? this.props.currentPlanItem.payFrequency.itemPayFreqName: '';
    // let payPrice = this.props.currentPlanItem && this.props.currentPlanItem.unitPrice ? `CAD $ ${this.props.currentPlanItem.unitPrice}`: '';
    let billCycleDefault = this.props.billCycle;
    let billCycleMax = 31;
    let billCycleMin = 0;
    if (typeof payFrequency === 'string' && payFrequency.length > 0) {
      switch(payFrequency.trim()){
        case 'monthly':
          billCycleDefault = 30;
          billCycleMax = 31;
          billCycleMin = 28;
          break;
        case 'yearly':
          billCycleDefault = 365;
          billCycleMax = 366;
          billCycleMin = 365;
          break;
        case 'payoff':
          billCycleDefault = 0;
          billCycleMax = 0;
          billCycleMin = 0;
          break;
      }
      payFrequency = payFrequency.charAt(0).toUpperCase() + payFrequency.slice(1);
    }
    let payPrice = '';
    if (this.props.currentPlanItem && this.props.currentPlanItem.itemTypeId == 1) {
      payPrice = USAGE_PRICE;
    }
    else if (this.props.currentPlanItem && this.props.currentPlanItem.itemTypeId == 2 && this.props.currentPlanItem.unitPrice) {
      payPrice = `CAD $ ${this.props.currentPlanItem.unitPrice}`;
    }
    let showAuto = this.props.currentPlanItem && this.props.currentPlanItem.itemPayFrequencyId != 3;
    let currentPlanItemRateList = this.props.currentPlanItem ? this.props.currentPlanItem.itemRateList : []
    return (
      <Fragment>
        <Row style={{flexGrow: 1, justifyContent: 'left', alignItems: 'left'}}>
              <Col md={1} style={{display:'flex', justifyContent: 'flex-end', padding: '0px'}}>
                  <Label for="item"   style={{ display: 'inline'}}><h4><b>Plans </b></h4></Label>
              </Col>
              <Col md={4}>
                  <Input type="select" id="itemId" name="itemId" value = {this.state.currentPlanItemId} onChange={this.handleChangeHandler} style={{ display: 'inline'}}>
                      <option value={null}>No selection</option>
                      { this.props.planItemsList   && this.props.planItemsList.map((type, i) => (<option key={type.itemId} value={type.itemId}>{type.itemId} - {type.itemName}</option>))}
                  </Input> 
              </Col>
              
          </Row>
          <br/>
            <br/>
            {planName && <Row> <Col md={12}>
              <Row>
                  <h2>{planName}</h2>
              </Row>
              <br/>
              <Row>
                  <Col md={6}>
                    <Row>
                      <span style={{fontSize:'28px'}}><b>{payFrequency}</b></span>
                    </Row>
                    <Row style={{marginTop: '10px'}}>
                        <Col  md={3} style={{padding: '0px'}}>
                          <Label style={{ display: 'inline'}}>
                            <span style={{fontSize:'18px'}}>Billing Cycle</span>
                          </Label>
                        </Col>
                        <Col md={3}>
                          <Input type='number' min={billCycleMin} step="1" max={billCycleMax} defaultValue={billCycleDefault} onChange={(e)=>{this.handBillCycleChange(e)}} ></Input>                          
                        </Col>
                        <Col md={3}>
                          <span style={{fontSize:'18px'}}>day(s)</span>
                        </Col>
                        
                    </Row>
                    {showAuto && 
                      <Row style={{marginTop: '10px'}}>
                        <Col md={12}>
                          <Label  style={{ display: 'inline'}} >
                              <Input type="checkbox" checked={this.props.autoPay} onClick={this.handleAutoPayClick}/> 
                              Allow to pay the plan automatically
                          </Label>
                        </Col>
                      </Row>
                    }
                  </Col>
                  <Col md={6} style={{display:'flex', justifyContent: 'flex-end'}}>
                    <span style={{fontSize:'36px'}}>{payPrice}</span>
                  </Col>
              </Row>
              {/* <Row>
                  <Col  md={2}>
                    <Label style={{ display: 'inline'}}>
                      <span style={{fontSize:'18px'}}>Bill Cycle</span>
                    </Label>
                  </Col>
                  <Col md={1}>
                    <Input type='number' min="0" step="1" max={"365"} defaultValue='0' onChange={(e)=>{}} ></Input>
                  </Col>
                  
              </Row> */}
              <hr/>
              <Row>
                <br/>
                <h4>Plan's Details</h4>
              </Row>
              <Row>
                <div style={{padding:'5px 10px', fontSize: '16px'}}><p>{planDescription}</p></div>
              </Row>
              <br/>
              {currentPlanItemRateList && currentPlanItemRateList.length > 0 && 
              <Row>
                <br/>
                <h4>Plan's Rate</h4>
                <br/>
                <BootstrapTable 
                    data={ currentPlanItemRateList}  
                    // tableHeaderClass = 'header_white' 
                    pagination = {false} 
                      
                      
                    bordered={ false } 
                    // insertRow 
                    ref='table' 
                >

                  <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='10%'>Id</TableHeaderColumn>
                  <TableHeaderColumn dataField='itemId' dataAlign="center" dataSort width='10%' >Item Id</TableHeaderColumn> 
                  <TableHeaderColumn dataField='startPoint' dataAlign="center" dataSort width='10%' >Start Point</TableHeaderColumn>
                  <TableHeaderColumn dataField='endPoint' dataAlign="center" dataSort width='10%' >End Point</TableHeaderColumn>
                  <TableHeaderColumn dataField='rate' dataAlign="center" dataSort width='10%' >Rate ($)</TableHeaderColumn>
                                
            
              </BootstrapTable>
              </Row>
              }
              </Col>
              </Row>
            }
      </Fragment>
    );
} 

checkFormater = (cell, row) => {
  return (
    <Input type="checkbox" />
  )
}

InputFormater = (cell, row) => {
  if (Number.isInteger(cell)) {
    const q = parseInt(cell);
    if (q > 0) {
      return (
        <Input type='number' min="0" step="1" max={cell} defaultValue='0' ></Input>
      );
    } 
  }

  return (
    <span>Out of Stock</span>
  );
}

renderDevices = () => {
  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <br/>
                <h4>Devices </h4>
                <br/>
                <BootstrapTable 
                    data={ this.props.inventoryList}  
                    // tableHeaderClass = 'header_white' 
                    pagination = {false} 
                      
                      
                    bordered={ false } 
                    // insertRow 
                    ref='table' 
                >
                  <TableHeaderColumn dataField='' dataAlign="center"  width='5%' dataFormat={this.checkFormater}></TableHeaderColumn> 
                  <TableHeaderColumn dataField='upc' isKey={ true } dataAlign="center" width='10%' >UPC</TableHeaderColumn>
                  {/* <TableHeaderColumn dataField='company' dataAlign="center" dataSort width='20%' >COMPANY</TableHeaderColumn>  */}
                  <TableHeaderColumn dataField='mfg' dataAlign="center" dataSort width='10%' >MFG</TableHeaderColumn>
                  {/* <TableHeaderColumn dataField='group' dataAlign="center" dataSort hidden >GROUP</TableHeaderColumn>                      */}
                  <TableHeaderColumn dataField='oursku' dataAlign="center" dataSort width='10%' >SKU</TableHeaderColumn>  
                  <TableHeaderColumn dataField='mfgsku' dataAlign="center" dataSort width='10%'>MFG_SKU</TableHeaderColumn>  
                  <TableHeaderColumn dataField='quantity' dataAlign="center" dataSort width='15%' >REMAIN QUANTITY</TableHeaderColumn> 
                  <TableHeaderColumn dataField='quantity' dataAlign="center"  width='10%' dataFormat={this.InputFormater}>QUANTITY</TableHeaderColumn>  
                  {/* <TableHeaderColumn dataField='soldquantity' dataAlign="center" dataSort width='10%' >SOLD QUANTITY</TableHeaderColumn>   */}
                  <TableHeaderColumn dataField='network' dataAlign="center" dataSort >NETWORK</TableHeaderColumn>  
                                
            
              </BootstrapTable>
        </Col>
      </Row>
    </Fragment>
  );
}

  render() {

    
    const  plans  = this.state.plans;
    const  subPlans  = this.state.subPlans;
     

    const cardsstyle ={
        'margin-top': '50px',
        'width': '50%',
        'text-align': 'left'
      }

      const btn ={
         
        'float': 'right'
      }

      const onRowSelect = (row) => {
        const { store } = this.context;
      };

      const selectRowProp = {
        mode: 'radio',
        clickToSelect: true,
        bgColor: 'rgb(238, 193, 213)',
        onSelect: onRowSelect,
        hideSelectColumn: false,  //enable hide selection column.
      };

     //  
       
       
    let planName = this.state.currentPlanItem ? this.state.currentPlanItem.itemName: null;  
    let planDescription = this.state.currentPlanItem ? this.state.currentPlanItem.itemDescription: '';
    let payFrequency = this.state.currentPlanItem && this.state.currentPlanItem.payFrequency ? this.state.currentPlanItem.payFrequency.itemPayFreqName: '';
    // let payPrice = this.state.currentPlanItem && this.state.currentPlanItem.unitPrice ? `CAD $ ${this.state.currentPlanItem.unitPrice}`: '';
    let payPrice = '';
    if (this.props.currentPlanItem && this.props.currentPlanItem.itemTypeId == 1) {
      payPrice = USAGE_PRICE;
    }
    else if (this.props.currentPlanItem && this.props.currentPlanItem.itemTypeId == 2 && this.props.currentPlanItem.unitPrice) {
      payPrice = `CAD $ ${this.props.currentPlanItem.unitPrice}`;
    }
    let showAuto = this.state.currentPlanItem && this.state.currentPlanItem.itemPayFrequencyId != 3;
    return(
        <Col md={12}>
        <Card>
        <CardBody style={{padding:'0px 30px'}}>
          {/* {!this.props.isHide && 
          <Row>
              <Col md={1}>
                  <Label check>
                  <Input type="radio" name="radio1" checked={this.props.itemType === '1'}  onClick={() => { this.typeToggle('1'); }}/>{' '}
                      Plans
                  </Label>
              </Col>
              <Col md={1}>
                  <Label check>
                  <Input type="radio" name="radio1" checked={this.props.itemType === '2'}  onClick={() => { this.typeToggle('2'); }}/>{' '}
                      Devices
                  </Label>
              </Col>
          </Row>
          } */}
          <br/>
            {/* <br/>
            <br/>
            <br/> */}
          {/* <Row style={{flexGrow: 1, justifyContent: 'left', alignItems: 'left'}}>
              <Col md={1} style={{display:'flex', justifyContent: 'flex-end', padding: '0px'}}>
                  <Label for="item"   style={{ display: 'inline'}}><h4><b>Plans </b></h4></Label>
              </Col>
              <Col md={4}>
                  <Input type="select" id="itemId" name="itemId" value = {this.state.currentPlanItemId} onChange={this.handleChangeHandler} style={{ display: 'inline'}}>
                      <option value={null}>No selection</option>
                      { this.props.planItemsList   && this.props.planItemsList.map((type, i) => (<option key={type.itemId} value={type.itemId}>{type.itemId} - {type.itemName}</option>))}
                  </Input> 
              </Col>
              
          </Row>
            <br/>
            <br/>
            {planName && <Row> <Col md={12}>
              <Row>
                  <h2>{planName}</h2>
              </Row>
              <br/>
              <Row>
                  <Col md={6}>
                    <Row>
                      <span style={{fontSize:'28px'}}><b>{payFrequency}</b></span>
                    </Row>
                    {showAuto && 
                      <Row>
                        <Col md={12}>
                          <Label  style={{ display: 'inline'}} >
                              <Input type="checkbox" checked={this.state.autoPay} onClick={this.handleAutoPayClick}/> 
                              Allow to pay the plan automatically
                          </Label>
                        </Col>
                      </Row>
                    }
                  </Col>
                  <Col md={6} style={{display:'flex', justifyContent: 'flex-end'}}>
                    <span style={{fontSize:'36px'}}>{payPrice}</span>
                  </Col>
              </Row>
              <hr/>
              <Row>
                <br/>
                <h4>Plan's Details</h4>
              </Row>
              <Row>
                <div style={{padding:'5px 10px', fontSize: '16px'}}><p>{planDescription}</p></div>
              </Row>
              <br/>
              {this.state.currentPlanItemRateList && this.state.currentPlanItemRateList.length > 0 && 
              <Row>
                <br/>
                <h4>Plan's Rate</h4>
                <br/>
                <BootstrapTable 
                    data={ this.state.currentPlanItemRateList}  
                    // tableHeaderClass = 'header_white' 
                    pagination = {false} 
                      
                      
                    bordered={ false } 
                    // insertRow 
                    ref='table' 
                >

                  <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='10%'>Id</TableHeaderColumn>
                  <TableHeaderColumn dataField='itemId' dataAlign="center" dataSort width='10%' >Item Id</TableHeaderColumn> 
                  <TableHeaderColumn dataField='startPoint' dataAlign="center" dataSort width='10%' >Start Point</TableHeaderColumn>
                  <TableHeaderColumn dataField='endPoint' dataAlign="center" dataSort width='10%' >End Point</TableHeaderColumn>
                  <TableHeaderColumn dataField='rate' dataAlign="center" dataSort width='10%' >Rate ($)</TableHeaderColumn>
                                
            
              </BootstrapTable>
              </Row>
              }
              </Col>
              </Row>
            } */}
            {/* {this.props.itemType === '1' && 
              this.renderPlans()
            }
            {this.props.itemType === '2' && 
              this.renderDevices()
            } */}
            {this.renderPlans()}
            <br/>
            <Row>
             <Col md={6} >
             {/* <Button style={btn} variant="primary" color="custom" onClick={this.props.handlePurchaseCardClick} >Next</Button>  */}
              {/* <BackButton
                  onClick={this.props.handlePurchaseCompBackClick} 
                  label="Back"
                  size={30}
                  color = 'black'
                /> */}
             </Col>
             <Col md={6} style={{display:'flex', justifyContent:'flex-end'}}>
              <NextButton
                onClick={this.props.handlePurchaseCardClick} 
                label="Next"
                size={30}
                color = 'black'
              />
             </Col>
            </Row>     
          </CardBody>
          </Card>
    </Col>
    );
  }
}


export default CompanyAccountPurchaseComp;

