/* eslint-disable */
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';
import { Row, Col, Label, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, CustomInput, Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import VideoPlayer from '../../VideoPlayer';
import VideoMpegPlayer from '../../VideoMpegPlayer';
import VideoPlaybackPanel from '../../VideoPlaybackPanel';
import SingleAttributeDisplay from '../../SingleAttributeDisplay';
import AlarmAttributeDisplay from '../../AlarmAttributeDisplay';
import { postAssetAttribute } from '../../../../../redux/actions/assetActions';
import classnames from 'classnames';
import { getLastMsgBySingleAssetAsync, extractLastMsgs, extractSingleAssetLastMsg, extractSingleAssetMetaData } from '../../../../../redux/actions/surveilenceActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPlayer from '../../ReactPlayer';
import NVRPlay from '../../videoPlayer/nvr/NVRPlay';
import VideoDownLoadForm from '../../cameraList/VideoDownLoadForm';

// const WS_ROOT = 'wss://wssdemo.itsp-inc.com';

class DragCameraModalIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetId: this.props.assetid,
            modal: false,
            settingModal: false,
            activeTab: '1',
            deviceData: {},
            motion_count: 0,
            alarm: false,
            degreeVal: this.props.degree,
            locked: this.props.locked,
        }
    }

    componentDidMount() {
        var deltaX, deltaY;
        var self = this;
        this.loadAsset();
        const handleDrag = d3.drag()
            .subject(function () {
                const me = d3.select(this);
                return { x: me.attr('x'), y: me.attr('y') }
            })
            // .on("start", function () {
            //     var current = d3.select(this);
            //     deltaX = current.attr("x") - d3.event.x;
            //     deltaY = current.attr("y") - d3.event.y;
            // })
            .on('drag', function () {
                const me = d3.select(this);
                if (!self.props.locked) {
                    me.attr('x', d3.event.x);
                    me.attr('y', d3.event.y);
                }
                // console.log('x', d3.event.x)
                // console.log('y', d3.event.y)
            })
            .on('end', function () {
                const me = d3.select(this);

                if (!self.props.locked) {
                    self.props.updatePosition(d3.event.x, d3.event.y);
                }
            });

        const node = ReactDOM.findDOMNode(this.refs.useref);
        const tooltipid = ReactDOM.findDOMNode(this.refs.tooltipref);
        // const tooltipid = ReactDOM.findDOMNode(this.props.tooltipref);
        var icon = d3.select(node);
        var tooltip = d3.select(tooltipid);

        // var tooltip = d3.select("#tooltipid");
        // var icon = d3.select("#use1");

        // tooltip.text(this.props.assetname);

        icon.on('click', function () {
            // alert('click');
            self.toggle();
        })
            .on("mouseover", function () {
                //
                tooltip.raise();
                return tooltip
                    .style("visibility", "visible")
                    .style("font-size", "25px")
                    .style("font-family", "Roboto")
                    .style("fill", "black")
            })
            .on("mousemove", function () {
                //
                //
                //
                //
                //
                // return tooltip.style("top", (event.pageY-80)+"px")
                //             .style("left",(event.pageX-230)+"px")
                //             .text(self.props.assetname);
                //
                //
                // return tooltip.style("top", (d3.event.offsetY+30)+"px")
                // .style("left",(d3.event.offsetX)+"px")
                // .text(self.props.assetname);
                let ajustX = d3.event.offsetX;
                let ajustY = d3.event.offsetY;
                let svgWidth = window.innerWidth * 0.625;
                let svgHeight = window.innerHeight * 0.83;
                let svgHalfWidth = svgWidth / 2;
                if (svgWidth - d3.event.offsetX < svgHalfWidth) {
                    let tempSpan = d3.select("body")
                        .append("span")
                        .attr("id", "tempSpan")
                        .style("visibility", "hidden").style("font-size", "25px").style("font-family", "Roboto")
                        .text(self.props.name ? self.props.name : self.props.assetname);
                    let tooltipWidth = document.getElementById("tempSpan").offsetWidth;
                    tempSpan.remove();

                    let newX = d3.event.offsetX - tooltipWidth;
                    if (newX > 0) {
                        ajustX = newX;
                    }
                }
                if (svgHeight - d3.event.offsetY < 80) {
                    ajustY = d3.event.offsetY - 30;
                } else {
                    ajustY = d3.event.offsetY + 40;
                }

                return tooltip
                    .attr("y", (ajustY))
                    .attr("x", (ajustX))
                    .style("fill", "black")
                    .text(self.props.name ? self.props.name : self.props.assetname);
            })
            .on("mouseout", function () {
                //
                return tooltip.style("visibility", "hidden");
            })
            .on("contextmenu", function () {
                d3.event.preventDefault();
                //
                self.settingToggle();
                // return false;
            });
        handleDrag(icon);
        // handleDrag(d3.select("#use1"));
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.assetid !== prevProps.assetid) {
            this.loadAsset();
        }
        
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.port && nextProps.port != prevState.port) {
            return {
                port: nextProps.port
            }
        }

        if (nextProps.locked && nextProps.locked != prevState.locked) {
            return {
                locked: nextProps.locked
            }
        }

        if (nextProps.assetid && nextProps.assetid !== prevState.assetId) {
            return {
                assetId: nextProps.assetid
            }
        }
        // if (nextProps.degreeatrid && nextProps.degreeatrid != prevState.degreeatrid){
        //     return{
        //         degreeatrid: nextProps.degreeatrid
        //     }
        // }

        if (nextProps.updateData && nextProps.updateData.assetid == prevState.assetId) {
            let newData = extractLastMsgs(nextProps.updateData);
            if (newData) {
                return {
                    deviceData: { ...prevState.deviceData, ...newData },
                    motion_count: prevState.motion_count + 1,
                    alarm: true,
                }
            } else {
                return {
                    motion_count: prevState.motion_count + 1,
                    alarm: true,
                }
            }
        }

        if (nextProps.degree !== prevState.originDegreeVal) {
            //console.log('======================DEGREE CHANGE==========================', nextProps.degree)
            return {
                degreeVal: nextProps.degree,
                originDegreeVal: nextProps.degree
            }
        }

        return null;
    }


    loadAsset = () => {
        // let assetid = this.state.assetId
        //console.log('========================================================LOAD CAMERA=======================================', this.state.assetId)
        let assetid = this.state.assetId
        getLastMsgBySingleAssetAsync(assetid)
            .then(response => {
                //
                //
                let newData = extractSingleAssetLastMsg(response, assetid);
                let metaData = extractSingleAssetMetaData(response, assetid);
                //
                newData = newData ? newData : {}
                this.setState(prevState => (
                    {
                        deviceData: { ...prevState.deviceData, ...newData },
                        assetMeta: metaData
                    }
                ));
            })
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }


    settingToggle = () => {
        this.setState(prevState => ({
            settingModal: !prevState.settingModal,
        }));
    }

    tabToggle = (tab) => {
        this.setState(preState => (
            {
                activeTab: tab
            }
        ));
    }

    switchOff = () => {
        this.setState({
            alarm: false,
        });
    }

    getMetaDataByName = (name) => {
        let reVal = {};
        if (this.state.assetMeta) {
            for (let value of this.state.assetMeta) {
                if (value.attmetaname === name) {
                    reVal = value;
                    break;
                }
            }
        }
        return reVal;
    }

    onDegreeChange = (e) => {
        //console.log('=======================================DEGREE CHANGE', e.target.value)
        this.setState({
            degreeVal: e.target.value
        })
        //console.log('rotating to ', e.target.value)
        // switch (e.target.value) {
        //     case 0:
        //         this.setState({
        //             degreeValue: 0
        //         })
        //         return;
        //     case 1:
        //         this.setState({
        //             degreeValue: 45
        //         })
        //         return;
        //     case 2:
        //         this.setState({
        //             degreeValue: 90
        //         })
        //     case 3:
        //         this.setState({
        //             degreeValue: 135
        //         })
        //     case 4:
        //         this.setState({
        //             degreeValue: 180
        //         })
        //     case 5:
        //         this.setState({
        //             degreeValue: 225
        //         })
        //     case 6:
        //         this.setState({
        //             degreeValue: 270
        //         })
        //     case 7:
        //         this.setState({
        //             degreeValue: 315
        //         })
        //     default:
        //         return;
        // }
    }

    handleSetDegree = () => {
        // console.log('HANDLE SET DEGREE')
        if (this.state.degreeVal) {
            this.props.updateDegree(this.state.degreeVal);
        }
        this.settingToggle();
    }

    // updateDegreeAttribute = (deg, assetid, attributeid) => {
    //     if (!assetid || !attributeid) {
    //         console.warn("assetid or attributeid was not provided");
    //         return;
    //     }
    //     let param =  {
    //         assetid: assetid,
    //         attmetaid: attributeid,
    //         value: deg
    //     };
    //     this.props.postAssetAttribute(param).then(
    //         // res => {loadAssets();}
    //         res => {
    //             console.warn(`update camera ${assetid} degree: ${deg}`);
    //             // this.props.loadAssets();
    //         }
    //     ).catch((err) => {
    //         console.error(err)
    //     });
    // }


    renderMetaInfo = () => {
        let name = this.getMetaDataByName("name");
        let position = this.getMetaDataByName("camera location");
        return (
            <div>
                <Row>
                    <Col md={2}>
                        <h4>WAIV Asset:</h4>
                    </Col>
                    <Col md={4} style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ fontSize: "16px" }}>{this.props.assetname}</span>
                    </Col>
                    {/* <Col md={2}>
                    <h4>Location:</h4>
                </Col>
                <Col md={4} style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontSize: "16px"}}>{this.props.tempLocation}</span>
                </Col> */}

                </Row>
                <hr />
            </div>
        );
    }

    renderArrtibutes = () => {
        return (
            <Row>
                {this.state.deviceData && Object.values(this.state.deviceData).map(attributeData => (
                    <Col md={6}>
                        <SingleAttributeDisplay
                            assetId={this.state.assetId}
                            attribureData={attributeData}
                        />
                    </Col>
                ))}
            </Row>
        );
    }

    renderLiveFeedComponent = () => {
        // console.log('render live feed')
        if (this.props.nvrChannelList && this.props.nvrChannelList.length > 0) {
            return (
                <NVRPlay
                    type={'live'}
                    nvrIP={this.props.nvrIP}
                    camNumber={this.props.chid}
                    camName={this.props.name}
                    nvrChannelList={this.props.nvrChannelList}
                    port={this.props.httpPort}
                    nvr={this.props.nvr}
                />
            )
        } else {
            return (
                <VideoMpegPlayer
                    port={this.state.port}
                    chid={this.props.chid}
                    ch={this.props.ch}
                    width="100%"
                    height="500px"
                />
            )
        }
    }

    render() {
        const { assetname, url, httpurl, icon, x, y, name, nvr, ...props } = this.props;
        const devId = this.props.nvrChannelList?.find(x => x.name === name)?.devId;
        // let name = this.getMetaDataByName("name");
        let camNameAttr = name ? name : 'Camera'
        let portObj = this.getMetaDataByName("port");
        let port = portObj.value ? portObj.value : null;
        // console.table({assetname: assetname, chid: props.chid})
        // let useRtsp = props.ch && props.ch.includes("nvr");

        // console.table({name: name.value, chid: props.chid, ch: props.ch, port: this.state.port, degree: this.state.degreeVal, ip: props.ip})
        //console.log("PROPS: ", props.status)
        return (
            <Fragment>
                <defs>
                    <circle id="circle1" cx="50" cy="50" r="40" stroke="green" stroke-width="4" fill="yellow" />
                    <g id="video0" transform={`scale(0.20)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <g id="video1" transform={`scale(0.20) rotate(45)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <g id="video2" transform={`scale(0.20) rotate(90)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <g id="video3" transform={`scale(0.20) rotate(135)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <g id="video4" transform={`scale(0.20) rotate(180)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <g id="video5" transform={`scale(0.20) rotate(225)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <g id="video6" transform={`scale(0.20) rotate(270)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <g id="video7" transform={`scale(0.20) rotate(315)`} style={{ cursor: 'pointer' }}>
                        {/* <rect width="120" height="60" stroke="black" stroke-width="1" fill="black"/> */}
                        {/* <path d="M 105,50 197.5,5 197.5,95 Z"/> */}
                        <rect x="50" width="120" height="60" stroke="black" stroke-width="1" />
                        {/* <path d="M 105,50 0,5 0,95 Z"/> */}
                        <path d="M 50,30 0,0 0,60 Z" />
                    </g>
                    <filter x="0" y="0" width="1" height="1" id="solid">
                        <feFlood flood-color="white" result="bg" />
                        <feMerge>
                            <feMergeNode in="bg" />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                {/* <use id="use1" ref='useref' xlinkHref={"#circle1"} x={'100'} y={'100'}/> */}
                {/* <Tooltip title="Add" placement="top-start"> */}
                <use id="use1" ref='useref' xlinkHref={`#video${this.state.degreeVal}`} x={x ? x : '50'} y={y ? y : '50'} fill={props.status ? props.status.value == "online" ? "lime" : "red" : "red"} />
                <text id="tooltipid" ref='tooltipref' filter="url(#solid)" x={x ? x : '50'} y={y ? y : '50'} fill="blue"></text>
                {/* </Tooltip> */}
                {/* <UncontrolledTooltip placement="right" target="use1">
                    {assetname}
                </UncontrolledTooltip> */}
                {/* <div ref='tooltipref' style={{position: 'absolute', visibility: 'visible'}}>
                    {assetname}
                    testststsetestst
                </div> */}
                <Modal
                    animationType="slide"
                    transparent="false"
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    size={'lg'}
                    backdrop={false}
                >
                    <ModalHeader
                        toggle={this.toggle}
                    //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                    >
                        <span style={{ fontSize: "20px" }}>{camNameAttr}</span>
                        {/* <CameraIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/> */}
                    </ModalHeader>
                    <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                        {/* <VideoPlayer url={'ws://192.168.2.89:8081/webSocketService'}/> */}
                        {/* <VideoPlayer url={url} httpurl={httpurl}/> */}
                        {/* <VideoMpegPlayer/> */}
                        {this.renderMetaInfo()}
                        <div>
                            <Nav tabs>
                                <Button onClick={this.toggle}>{"< Back"}</Button>
                                <NavItem style={{ cursor: 'pointer' }}>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '1' })}
                                        onClick={() => { this.tabToggle('1'); }}
                                    >
                                        Live Video
                                    </NavLink>
                                </NavItem>
                                <NavItem style={{ cursor: 'pointer' }}>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.tabToggle('2'); }}
                                    >
                                        Video PlayBack
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '3' })}
                                        onClick={() => { this.tabToggle('3'); }}
                                    >
                                        Enviroment Measurement
                                    </NavLink>
                                </NavItem> */}
                            </Nav>
                            <TabContent activeTab={this.state.activeTab} style={{ paddingTop: '10px' }}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col md={12}>
                                            {this.renderLiveFeedComponent()}
                                        </Col>
                                    </Row>
                                </TabPane>
                                {/* <TabPane tabId="2">
                                    <VideoPlaybackPanel
                                        port={this.state.port}
                                        chid={props.chid}
                                        ch={props.ch}
                                    />
                                    <Button onClick={this.toggle}>Back to App</Button>
                                </TabPane> */}
                                <TabPane tabId="2">
                                    {this.state.activeTab === '2' && <VideoDownLoadForm chl={this.props.ch} chlNum={this.props.chid} devId={devId} nvrIP={this.props.nvrIP} HttpPort={this.props.HttpPort} nvr={nvr} />}
                                </TabPane>

                                {/* <TabPane tabId="3"> */}
                                {/* {this.renderArrtibutes()} */}
                                {/* <AlarmAttributeDisplay
                                        status={this.state.alarm}
                                        count={this.state.motion_count}
                                        switchOff={this.switchOff}
                                    />
                                </TabPane> */}
                            </TabContent>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal
                    animationType="slide"
                    transparent="false"
                    isOpen={this.state.settingModal}
                    toggle={this.settingToggle}
                >
                    <ModalHeader toggle={this.settingToggle}>{this.props.assetname}</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="rotationRange">
                                    {`Rotation ${this.state.degreeVal * 45}`}
                                </Label>
                                <CustomInput
                                    type="range"
                                    id="rotationRange"
                                    name="rotationRange"
                                    max={7}
                                    min={0}
                                    value={this.state.degreeVal}
                                    onChange={this.onDegreeChange}
                                // value={degree}
                                // onChange={this.props.handleDegreeChange}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.handleSetDegree}>Save</Button>{' '}
                        <Button color="custom" onClick={this.settingToggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ postAssetAttribute }, dispatch)
}
export default connect(null, mapDispatchToProps)(DragCameraModalIcon);