/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Row, Container } from "reactstrap";
import { UserContext } from "../../constants/myContexts";
import { getCurrentUser } from "../../factories/auth";
import MainBoard from "./components/MainBoard";

const PowerManagementPage = (props) => {
  let { userObj } = React.useContext(UserContext);
  if (!userObj) {
    toast.error("Can not fetch login user information, please re-login.");
  } else {
  }

  return (
    <div className="dashboard" style={{ padding: "15px", margin: "15px" }}>
      <Row>
        <Col md={12}>
          <h2 className="page-title">Power Management</h2>
        </Col>
      </Row>
      <MainBoard userObj={userObj} />
    </div>
  );
};

export default withRouter(PowerManagementPage);
