/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { compose, bindActionCreators } from 'redux'
import { connect } from "react-redux";
import ReactDOM from 'react-dom';
import { Map, GoogleApiWrapper, Marker, InfoWindow, Polyline } from 'google-maps-react';
import InfoWindowEx from './InfoWindowEx';
import { Container, Row, Col, Button, Table, Input, Label, Collapse } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
// import MapInfoWindow from './MapInfoWindow';
import { toast } from "react-toastify";
import { loadAllGeoFences } from '../../../redux/actions/fenceAction';
import { generateSelectOptions } from '../../../factories/utils';
import { getCurrentUser } from '../../../factories/auth';
import MarkerClusterer from "@google/markerclusterer";
import { MagnifyIcon, CloseIcon } from 'mdi-react';
// import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
import OccupancyMapInfo from './OccupancyMapInfoWin';
import {
    WHITE_COLOR,
    GREEN_COLOR,
    RED_COLOR,
    ORENGE_COLOR,
    YELLOW_COLOR,
    GREY_COLOR,
    LIGHT_GREY_COLOR,
    ATTRIBUTE_ORDER,
    ALARM_META_ATTRI_OBJ
} from '../../../constants/dataConstants';
import { setDonut } from '../../../factories/weather';

export class OccupancyMap extends Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.myMap = React.createRef();
        this.state = {
            msg: '',
            collapse: true,
            assetSelector:false,
            mapData:[],
            assetName:[],
            assetList:[]
        };
    }

    componentDidMount() {
        //  
    }

    componentWillReceiveProps(nextProps) { }
 
    setSelection = (googleShape) => {
        this.selectedShape = googleShape;
    }
    deleteSelectedShape = () => {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
        }
    }
    showShapOnMap = (shape) => {
        if (shape && this.myMap && this.myMap.current) {
            // alert('yes');
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            this.deleteSelectedShape();
            this.setShap(google, map, shape);
        } else {
            this.deleteSelectedShape();
        }
    }
    setShap = (google, map, shape) => {
        let self = this;
        let newCenter;
        let bounds;
        if (shape) {
            // self.deleteSelectedShape();
            switch (shape.type) {
                case 'circle':
                    const initCircle = new google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        center: shape.data.center,
                        radius: shape.data.radius,
                        editable: false
                    });
                    bounds = initCircle.getBounds();
                    newCenter = initCircle.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initCircle);
                    // this.setCircleData(initCircle); 
                    break;
                case 'rectangle':
                    const initRectangle = new google.maps.Rectangle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        bounds: shape.data,
                        editable: false
                    });
                    //   google.maps.event.addListener(initRectangle, 'bounds_changed', () => {
                    //       this.setRectangleData(initRectangle);
                    //        
                    //   });
                    bounds = initRectangle.getBounds();
                    newCenter = bounds.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initRectangle);
                    //   this.setRectangleData(initRectangle); 
                    break;
                case 'polygon':
                    google.maps.Polygon.prototype.getBoundingBox = function () {
                        var bounds = new google.maps.LatLngBounds();
                        this.getPath().forEach(function (element, index) {
                            bounds.extend(element)
                        });
                        return (bounds);
                    }
                    const initPolygon = new google.maps.Polygon({
                        paths: shape.data,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        editable: false
                    });

                    bounds = initPolygon.getBoundingBox();
                    newCenter = bounds.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initPolygon);
                    break;
            }
        }
    }

    setMapBounds = (mapData) => {
        if (this.myMap && this.myMap.current) {

            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            if (google, map) {
                let bounds = null;
                if (mapData && mapData.length > 0) {
                    bounds = new google.maps.LatLngBounds();
                    mapData.forEach((location) => {
                        bounds.extend(
                            new google.maps.LatLng(location.locationlat, location.locationlng),
                        );


                    });
                }
                if (bounds) {
                    map.fitBounds(bounds);
                }
            }
        }
    }

    getMapBounds = (google, map) => {

        let bounds = null;
        if (this.props.statusData && this.props.statusData.length > 0) {
            bounds = new google.maps.LatLngBounds();
            this.props.statusData.forEach((location) => {
                bounds.extend(
                    new google.maps.LatLng(location.locationlat, location.locationlng),
                );


            });
        }
        return bounds;
    }

    getCompanyBounds = (google, map) => {

        let bounds = null;
        if (this.props.userObj.company && this.props.userObj.company.businessArea) {
            //  
            let points = JSON.parse(this.props.userObj.company.businessArea);
            bounds = new google.maps.LatLngBounds();
            points.forEach((point, index) => {
                bounds.extend(
                    new google.maps.LatLng(point.lat, point.lng),
                );
            });
        }
        return bounds;
    }

    initMap(mapProps, map) {
        var self = this;
        const { google } = mapProps;

        let bounds = this.getMapBounds(google, map);
        //  
        if (bounds) {
            map.fitBounds(bounds);  
        }

        let intiStyles = [
            {
                featureType: "poi",
                elementType: "abels.text",
                stylers: [
                    { visibility: "off" },
                ]
            }
        ];
        map.setOptions({ styles: intiStyles });
        self.setShap(google, map, self.state.shape);
        var input = ReactDOM.findDOMNode(self.refs.inputTest);
        var searchBox = new google.maps.places.SearchBox(input);
        map.addListener('bounds_changed', function () {
            searchBox.setBounds(map.getBounds());
        });
        searchBox.addListener('places_changed', () => {
            var places = searchBox.getPlaces();
            if (places.length == 0) {
                return;
            }

            map.setCenter(places[0].geometry.location);
        });

        var controlMarkerUI = document.createElement('DIV');

        let searchBtn = <button
            style={{ border: 0, background: 'none', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;', height: '40px', width: '40px', margin: '10px' }}
            // onClick={(e)=>{alert('click')}}
            onClick={self.mapSearchBarToggle}
        >
            <MagnifyIcon size={25} />
        </button>;
        ReactDOM.render(searchBtn, controlMarkerUI);
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlMarkerUI);
        // --------------------------------------------
    }
    //--------------------------------------------------------------------
    handleFenceChange = (val) => {
        let id = val.value;
        let fence = this.props.allfences.find((e, i) => (e.fenceid == id));
        let currentPolygonData = null;
        let currentRectangleData = null;
        let currentCircleData = null;
        let selectedShape = null;
        let type = '';
        if (fence) {
            switch (fence.shape) {
                case 0:
                    type = 'circle';
                    currentCircleData = JSON.parse(fence.geodata);
                    break;
                case 1:
                    type = 'rectangle';
                    currentRectangleData = JSON.parse(fence.geodata);
                    break;
                case 2:
                    type = 'polygon';
                    currentPolygonData = JSON.parse(fence.geodata);
                    break;
            }

            selectedShape = { type: type, data: JSON.parse(fence.geodata) };
        }

        this.setState({
            shape: selectedShape,
        });

        this.showShapOnMap(selectedShape)
    }
    //---------------------------------------------------------------------
    handleAssetChange = (val) => {
        if(val.value=='-1'){
            this.state.mapData=this.props.statusData
            this.state.assetSelector=false;
            this.forceUpdate();
        }else{
            this.state.mapData=[]
            this.state.assetSelector=true;
            this.state.mapData.push(val.value)
            this.forceUpdate();
        }
        // this.setState({
        //     shape: selectedShape,
        // });

        // this.showShapOnMap(selectedShape)
    }
    //--------------------------------------------------------------------
    mapSearchBarToggle = () => {
        this.setState(prevState => ({
            collapse: !prevState.collapse,
        }));
    }
    //---------------------------------------------------------------------
    allAssets=()=>{
        this.state.assetSelector=false;
        this.state.mapData=this.props.statusData;
        this.forceUpdate();
    }
    render() {
        const mapStyle = {
            width: '100%',
            // height: '67vh',
            height: '800px',
            position: 'static',
            paddingTop: 10,
        };
        const mapContainerStyle = {
            width: '100%',
            position: 'relative',
        };
        let v = this.props.values.selectedPlace;
        let lat = this.props.values.lat;
        let lng = this.props.values.lng;

        // let lat = pos.lat;

        var icon = {
            path: "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0", //"M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
            fillColor: '#FF0000',
            fillOpacity: .6,
            anchor: new google.maps.Point(0, 0),
            strokeWeight: 0,
            scale: 1
        };

        const defaultIconPath = 'M125 410 c-56 -72 -111 -176 -120 -224 -7 -36 11 -83 49 -124 76 -85 223 -67 270 31 28 60 29 88 6 150 -19 51 -122 205 -148 221 -6 3 -32 -21 -57 -54z m110 -175 c35 -34 33 -78 -4 -116 -35 -35 -71 -37 -105 -7 -40 35 -43 78 -11 116 34 41 84 44 120 7z';
        const defaultColor = "#FF0000";

        const generateIcon = (color, path) => {
            let myPath = "M-10,0a10,10 0 1,0 20,0a10,10 0 1,0 -20,0"; 
            if (path && path.length > 0) {
                myPath = path;
            }
            return (
                {
                    path: myPath,
                    fillColor: color,
                    fillOpacity: 1,
                    strokeColor: '#000',
                    strokeWeight: 0,
                    scale: 0.1,
                    anchor: new google.maps.Point(185, 500)
                    
                }
            )
        };

        const renderInfoWin = () => {
            if (this.props.values.selectedPlace.attr) {


                return (
                    Object.keys(this.props.values.selectedPlace.attr).map((key, i) => (
                        key != 'Lat' && key != 'Lng' &&
                        <tr>
                            <td>{key}</td>
                            <td>{this.props.values.selectedPlace.attr[key].value}</td>
                        </tr>
                    ))
                )
            } else {

                return null;
            }
        }
        let pathArr = [];
        if(this.state.assetSelector==false){
            this.state.mapData=this.props.statusData;  
        }
        
        if (this.props.mapShow) {

            this.setMapBounds(this.state.mapData);
        }

        let lineSymbol = {
            path: 'M-2,4 0,0 M2,4 0,0',
            strokeOpacity: 1,
            scale: 2,
        };
    
    this.state.assetName=[];
    this.state.assetName.push({value:'-1',
        label:'No Selection...'})
    this.props.statusData.map(asset=>{
        var obj={value:asset,
            label:asset.assetname}
        this.state.assetName.push(obj) 
        return (0); 
        }); 
        return (
            <Row>
                <Col md={12}>
                    <div style={{ position: 'relative' }}>
                        
                        <Map
                            ref={this.myMap}
                            google={this.props.google}
                            zoom={20}
                            onReady={this.initMap}
                            containerStyle={mapContainerStyle}
                            style={mapStyle}
                            initialCenter={{ lat: 43.6907917, lng: -79.4761116 }}
                        >

                            <ClusterWrapper
                                mapData={this.state.mapData}
                                defaultIconPath={defaultIconPath}
                                defaultColor={defaultColor}
                                generateIcon={generateIcon}
                                onMarkerClick={this.props.onMarkerClick}
                                // onMarkerHover={this.props.onMarkerHover}
                                onMarkerOut={this.props.handleMapInfoWinClose}
                                alarmSetObj={this.props.alarmSetObj}
                            />

                            <InfoWindowEx marker={this.props.values.activeMarker}
                                visible={this.props.values.showingInfoWindow}
                                onClose={this.props.handleMapInfoWinClose}
                            >
                                <div style={{marginLeft:'8px'}}>
                                    <OccupancyMapInfo
                                        props={this.props.values.selectedPlace}
                                        toggle={this.props.toggle}
                                        alarmSetObj={this.props.alarmSetObj}
                                        statusDataProp={this.props.statusDataProp}
                                    />
                                </div>
                            </InfoWindowEx>
                        </Map>
                        <Collapse
                            isOpen={this.state.collapse}
                            style={{
                                width: '100%',
                                position: 'absolute',
                                zIndex: 900,
                                backgroundColor: 'grey',
                                opacity: 0.9,
                                paddingLeft: '5px',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                            }}
                        >
                            <Row>
                                <Col md={5}>
                                    <Select
                                        value={this.state.selectedFenceOption}
                                        options={this.state.fenceOptions}
                                        onChange={this.handleFenceChange}
                                        placeholder={'Select Geofence...'}
                                        menuPlacement="top"
                                    />
                                </Col>
                                <Col md={1}></Col>
                                
                                <Col md={3}>
                                <Select
                                        value={this.state.selectedAssetOption}
                                        options={this.state.assetName}
                                        onChange={this.handleAssetChange}
                                        menuPlacement="top"
                                        placeholder={'Select Asset...'}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Input
                                        id="pac-input"
                                        class="controls"
                                        type="text"
                                        placeholder="Search by Address"
                                        ref='inputTest'
                                        style={{ width: '100%' }} />
                                </Col>
                                <Col md={1} style={{ textAlign: 'right' }}>
                                    <button
                                        style={{ border: 0, background: 'none', height: '35px', width: '35px',hidden:true }}
                                        onClick={this.mapSearchBarToggle}
                                    >
                                        <CloseIcon 
                                        onClick={this.allAssets}
                                        size={25} />
                                    </button>;
                                </Col>
                            </Row>
                        </Collapse>
                        {this.props.loading &&
                            <div style={{
                                position: 'Absolute ',
                                height: '100%',
                                width: '100%',
                                top: '0px',
                                left: '0px',
                                display: 'flex',
                                textAlign: 'center',
                                alignItems: 'center',
                                backgroundColor: LIGHT_GREY_COLOR,
                                opacity: '0.6',
                            }}
                            >
                                <Spinner
                                    className="spinner"
                                    as="span"
                                    animation="border"
                                    variant="primary"
                                    style={{ display: 'inline-block', margin: 'auto' }}
                                />
                            </div>
                        }
                    </div>
                </Col>
            </Row>
        )
    }
}

function processAssetDataMsg(mapData, pointArr, selectedAttriId) {
    let reMapData = JSON.parse(JSON.stringify(mapData));
    if (reMapData && reMapData.length === 1 && reMapData[0].asset && reMapData[0].asset.length === 1) {
        let asset = reMapData[0].asset.pop();
        if (asset.device) {
            for (let i = 0; i < asset.device.length; i++) {
                if (asset.device[i].trackable) {
                    if (asset.device[i].msgs && asset.device[i].msgs.length > 0) {
                        let messages = asset.device[i].msgs;
                        let cloneasset = asset;
                        for (let mi = 0; mi < messages.length; mi++) {
                            let newAsset = JSON.parse(JSON.stringify(cloneasset));
                            for (let j = 0; j < newAsset.device.length; j++) {
                                let msgItem;
                                let newMsg = newAsset.device[j].msgs;
                                //  
                                if (j === i) {
                                    msgItem = JSON.parse(JSON.stringify(messages[mi]));
                                } else {
                                    let otherMessages = asset.device[j].msgs;
                                    msgItem = JSON.parse(JSON.stringify(otherMessages[mi]));
                                }

                                msgItem.msg = { ...newMsg[0].msg, ...msgItem.msg }


                                newAsset.device[j].msgs = [];
                                // newAsset.device[j].msgs.push(JSON.parse(JSON.stringify(messages[mi])));
                                newAsset.device[j].msgs.push(msgItem);
                                if (selectedAttriId) {
                                    // set asset color
                                    if (msgItem.msg) {
                                        Object.keys(msgItem.msg).forEach((key) => {
                                            //  
                                            //  
                                            //  
                                            if (msgItem.msg[key].id == selectedAttriId) {
                                                //  
                                                let colorValue = msgItem.msg[key].themeattributevalue;
                                                if (msgItem.msg[key].display !== false && colorValue && colorValue.length > 0) {
                                                    //  
                                                    // color = colorValue ;
                                                    newAsset['color'] = colorValue;
                                                }
                                            } else {
                                                // delete msg.msg[key];
                                            }
                                        });
                                    }
                                }
                            }
                            if (messages[mi].msg && messages[mi].msg.Lng && messages[mi].msg.Lat) {
                                newAsset.locationlat = messages[mi].msg.Lat.value;
                                newAsset.locationlng = messages[mi].msg.Lng.value;
                            }
                            cloneasset = newAsset;
                            reMapData[0].asset.push(newAsset);
                        }
                    }
                    break;
                }
            }
        }
        if (reMapData[0].asset.length === 0) {
            reMapData[0].asset.push(asset);
        }

        reMapData[0].asset.forEach((item, index) => {
            if (item.device) {
                let timestampe = new Date();
                item.device.forEach((d, i) => {
                    if (d.trackable && d.msgs && d.msgs.length === 1) {
                        timestampe = new Date(d.msgs[0].timestamp);
                        //  
                    }
                });

                item.device.forEach((d, i) => {
                    if (!d.trackable || d.msgs.length > 1) {
                        //  
                        let foundIndex = -1;
                        for (let m = 0; m < d.msgs.length; m++) {
                            if (d.msgs[m].timestamp <= timestampe) {
                                foundIndex = m;
                                // break;
                            } else {
                                break;
                            }
                        }
                        if (foundIndex >= 0) {
                            d.msgs = [d.msgs[foundIndex]];
                        } else {
                            d.msgs = [d.msgs[0]];
                        }

                    }
                });
            }
        });

        //  
        pathPoints(reMapData, pointArr);


    } else if (reMapData && reMapData.length >= 1 && selectedAttriId) {

        reMapData.forEach((ac, acIndex) => {

            ac.asset && ac.asset.forEach((at, atIndex) => {

                at.device && at.device.forEach((dv, dvIndex) => {

                    dv.msgs && dv.msgs.length > 0 && dv.msgs[0].msg && Object.keys(dv.msgs[0].msg).forEach((key) => {

                        if (dv.msgs[0].msg[key].id == selectedAttriId) {
                            let colorValue = dv.msgs[0].msg[key].themeattributevalue;

                            if (dv.msgs[0].msg[key].display !== false && colorValue && colorValue.length > 0) {
                                at['color'] = colorValue;
                            }
                        }
                    });
                });
            });
        });

    }

    return reMapData;
}

function postProcessAssetDataMsg(mapData, pointArr, selectedAttriId, multiAssets) {


    let reMapData = JSON.parse(JSON.stringify(mapData));
    reMapData = filterZeroPosition(reMapData);
    if (!multiAssets) {
        pathPoints(reMapData, pointArr);
    }
    // pathPoints(reMapData, pointArr);

    if (reMapData && selectedAttriId) {
        //  
        reMapData.forEach((ac, acIndex) => {
            //  
            ac.asset && ac.asset.forEach((at, atIndex) => {
                //  
                at.device && at.device.forEach((dv, dvIndex) => {
                    //  
                    dv.msgs && dv.msgs.length > 0 && dv.msgs[0].msg && Object.keys(dv.msgs[0].msg).forEach((key) => {
                        //  
                        if (dv.msgs[0].msg[key].id == selectedAttriId) {
                            let colorValue = dv.msgs[0].msg[key].themeattributevalue;
                            //  
                            if (dv.msgs[0].msg[key].display !== false && colorValue && colorValue.length > 0) {
                                at['color'] = colorValue;
                            }
                        }
                    });
                });
            });
        });
    }

    return reMapData;
}

function filterZeroPosition(mapData) {
    mapData && mapData.forEach((assetclass, acindex) => {
        if (assetclass.asset) {
            let filteredAsset = assetclass.asset.filter((asset, atindex) => (asset.locationlat != 0 && asset.locationlng != 0));
            assetclass.asset = filteredAsset;
        }
    });

    return mapData;
}

function processAssetData(mapData, pointArr) {
    let reMapData = JSON.parse(JSON.stringify(mapData));
    if (reMapData && reMapData.length === 1 && reMapData[0].asset && reMapData[0].asset.length === 1) {
        let asset = reMapData[0].asset.pop();
        // let newAssetArr = [];
        if (asset.device) {
            for (let i = 0; i < asset.device.length; i++) {
                if (asset.device[i].trackable) {
                    if (asset.device[i].msgs && asset.device[i].msgs.length > 0) {
                        let messages = asset.device[i].msgs;
                        for (let mi = 0; mi < messages.length; mi++) {
                            let newAsset = JSON.parse(JSON.stringify(asset));
                            newAsset.device[i].msgs = [];
                            newAsset.device[i].msgs.push(JSON.parse(JSON.stringify(messages[mi])));
                            //  
                            if (messages[mi].msg && messages[mi].msg.Lng && messages[mi].msg.Lat) {
                                newAsset.locationlat = messages[mi].msg.Lat.value;
                                newAsset.locationlng = messages[mi].msg.Lng.value;
                            }
                            reMapData[0].asset.push(newAsset);
                        }
                    }
                    break;
                };
            }
        }
        if (reMapData[0].asset.length === 0) {
            reMapData[0].asset.push(asset);
        }

        //  

        reMapData[0].asset.forEach((item, index) => {
            if (item.device) {
                let timestampe = new Date();
                item.device.forEach((d, i) => {
                    if (d.trackable && d.msgs && d.msgs.length === 1) {
                        timestampe = new Date(d.msgs[0].timestamp);
                        //  
                    }
                });

                item.device.forEach((d, i) => {
                    if (!d.trackable || d.msgs.length > 1) {
                        //  
                        let foundIndex = -1;
                        for (let m = 0; m < d.msgs.length; m++) {
                            if (d.msgs[m].timestamp <= timestampe) {
                                foundIndex = m;
                                break;
                            }
                        }
                        if (foundIndex >= 0) {
                            d.msgs = [d.msgs[foundIndex]];
                        }

                    }
                });
            }
        });

        //  
        pathPoints(reMapData, pointArr);

    }

    return reMapData;
}

function pathPoints(mapData, pathPoints) {
    // let pathPoints = null;
    if (mapData && mapData.length === 1 && mapData[0].asset && mapData[0].asset.length > 1) {
        let assets = mapData[0].asset;
        // pathPoints = [];
        for (let i = 0; i < assets.length - 1; i++) {
            let points = [
                { lat: Number(assets[i].locationlat), lng: Number(assets[i].locationlng) },
                { lat: Number(assets[i + 1].locationlat), lng: Number(assets[i + 1].locationlng) },
            ];
            pathPoints.push(points);
        }
    }
    //  
    // return pathPoints; 
}


function mapStateToProps(state) {
    const allfences = state.fence.fenceData;
    const fenceObj = state.fence.fenceObj;
    const fenceStatus = state.fence.type;
    const props = { allfences, fenceObj, fenceStatus };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadAllGeoFences, }, dispatch);
};
// export default connect(mapStateToProps, mapDispatchToProps)(StepCondition);

const enhance = compose(
    GoogleApiWrapper({
        // apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
        // apiKey: 'AIzaSyDXBuKv70EhwKMyHzrj6wc7_qPQFwmdyB4',
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['drawing', 'places'],
    }),
    connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(OccupancyMap);

// --------------------------------------------------------

class ClusterWrapper extends Component {
    constructor(props) {
        super(props);

        this.markers = [];
        this.iconIntervals = [];
    }

    componentDidMount() {

        // this.renderCluster();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.google !== this.props.google || prevProps.mapData !== this.props.mapData) {

            //   if (this.clusterer) {
            //     this.clusterer.clearMarkers();
            //   }
            //   this.clearAllMarkers();
            //   this.renderCluster();
        }
        this.clearAllMarkers();
        this.renderCluster();
    }

    componentWillReceiveProps(nextProps) {
        //  
    }

    componentWillUnmount() {

        this.clearAllMarkers();
    }

    clearAllMarkers = () => {
        if (this.iconIntervals) {
            this.iconIntervals.forEach((item, index) => {
                clearInterval(item);
            });
        }
        if (this.clusterer) {
            this.clusterer.clearMarkers();
        }
    }



    renderCluster = () => {
        let { google, map } = this.props;
        this.markers = [];
        if (this.props.mapData) {
            const options = { legWeight: 3, minZoomLevel: 18 }; // Just an example of options - please set your own if necessary
            // const oms = new OverlappingMarkerSpiderfier(map, options);
            // ----------------------------------------------------------------------------------------
            let that = this;

            this.props.mapData.forEach((item, index) => {
                // let that = this;
                // if (item.asset  && Array.isArray(item.asset)) {
                // item.asset.forEach((m, i) =>{
                let m = item;
                if (m.locationlat !== 0 || m.locationlng !== 0) {
                    let path = that.props.defaultIconPath;
                    let color = that.props.defaultColor;
                    if (item.assetIcon && item.assetIcon.iconsvg) {
                        path = item.assetIcon.iconsvg;
                    }
                    // if (m.color){
                    //     color = m.color;
                    // }
                    let alarmObj = this.props.alarmSetObj ? this.props.alarmSetObj[m.assetid] : null;
                    if (m.attributes && m.attributes['hexAlarm'] && m.attributes['hexAlarm'].length > 0) {
                        delete m.attributes['hexAlarm']
                    }
                    let statusSet = setDonut(alarmObj, m.attributes, m.color);  
                    color = statusSet.color;
                    let ref = {
                        map: map,
                        name: m.assetname,
                        // device: m.device,
                        timestamp: m.timestamp,
                        attributes: m.attributes,
                        asset: m,
                        position: { lat: Number(m.locationlat), lng: Number(m.locationlng) },
                        icon: that.props.generateIcon(color, path)
                    }
                    if (item.grouping == "wind") {
                        // ref.icon = that.props.generateIcon("red", path)
                        ref.icon = {
                            path:
                              "M 4 10 A 1 1 0 0 1 3 9 A 1 1 0 0 1 4 8 H 12 A 2 2 0 0 0 14 6 A 2 2 0 0 0 12 4 C 11.45 4 10.95 4.22 10.59 4.59 C 10.2 5 9.56 5 9.17 4.59 C 8.78 4.2 8.78 3.56 9.17 3.17 C 9.9 2.45 10.9 2 12 2 A 4 4 0 0 1 16 6 A 4 4 0 0 1 12 10 H 4 M 19 12 A 1 1 0 0 0 20 11 A 1 1 0 0 0 19 10 C 18.72 10 18.47 10.11 18.29 10.29 C 17.9 10.68 17.27 10.68 16.88 10.29 C 16.5 9.9 16.5 9.27 16.88 8.88 C 17.42 8.34 18.17 8 19 8 A 3 3 0 0 1 22 11 A 3 3 0 0 1 19 14 H 5 A 1 1 0 0 1 4 13 A 1 1 0 0 1 5 12 H 19 M 18 18 H 4 A 1 1 0 0 1 3 17 A 1 1 0 0 1 4 16 H 18 A 3 3 0 0 1 21 19 A 3 3 0 0 1 18 22 C 17.17 22 16.42 21.66 15.88 21.12 C 15.5 20.73 15.5 20.1 15.88 19.71 C 16.27 19.32 16.9 19.32 17.29 19.71 C 17.47 19.89 17.72 20 18 20 A 1 1 0 0 0 19 19 A 1 1 0 0 0 18 18 M 10 21 L 11.5 27 L 13 21",
                            fillColor: "blue",
                            fillOpacity: 0.75,
                            strokeWeight: 0,
                            rotation: 0,
                            scale: 1.5,
                            anchor: new google.maps.Point(11.5, 27),
                          }
                    }
                    
                    let marker = new google.maps.Marker(ref);
                    /* if (statusSet.status == 1) {
                        blinkSymbol(marker, this.iconIntervals);
                    } */
                    // blinkSymbol(marker, this.iconIntervals);
                    marker.addListener('click', (e) => { this.props.onMarkerClick(ref, marker, e) });
                    this.markers.push(marker);
                }
                // });
                // }
            });

            this.clusterer = new MarkerClusterer(map, this.markers, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', maxZoom: 18 });
            //  
            // return this.markers;
            // return this.clusterer;
        }

        return null;

    }

    render() {
        return (
            <Fragment >
                {this.props.children}
            </Fragment>
        );
    }
}

function blinkSymbol(marker, intervals) {
    const step = -0.9;
    const duration = 300; // ms
    const max = 1;
    let count = 0;
    let scale = 1;
    let icon = marker.getIcon();
    if (icon) {
        let orScale = icon.scale;
        const iconInterval = setInterval(() => {

            count++;
            if (count <= max && (scale + step) > 0) {
                icon.scale = icon.scale * (scale + step);
            } else {
                icon.scale = orScale;
                count = 0;
            }
            marker.setIcon(icon);
        }, duration);
        if (intervals) {
            intervals.push(iconInterval);
        }
    }


}