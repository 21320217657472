/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Col, Container, Row, Card, CardBody, CardTitle, CardText, Label, Input, Button } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import { MonitorCellphoneStarIcon } from 'mdi-react';
import Select from 'react-select';
import moment from 'moment';
import { toast } from "react-toastify";
import ReactPlayer from 'react-player';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
import VideoMpegPlaybackPlayer from './VideoMpegPlaybackPlayer';
import { AxiosNVR } from '../../../constants/myContexts';
import axios from 'axios';
import {VIDEO_PROXY_ROOT, VIDEO_ROOT} from '../../../constants/appConstants';
import {style} from '../../DashBoard/components/DashboardMap'
import {TimePicker} from '@material-ui/pickers'

// const VIDEO_ROOT = 'https://wssdemo.itsp-inc.com:8443/videos/'
// const VIDEO_ROOT = 'http://192.168.161.9:8080/videos/'

class VideoPlaybackPanel extends Component {
    constructor(props){
        super(props);
        this.fileList = [
            // {label: "2021-1-23-4-31-14", value: "2021-1-23-4-31-14.mp4"},
            // {label: "2021-1-23-4-32-35", value: "2021-1-23-4-32-35.mp4"},
            // {label: "2021-1-23-21-57-30", value: "2021-1-23-21-57-30.mp4"},
            // {label: "2021-1-24-12-47-14", value: "2021-1-24-12-47-14.mp4"},
            // {label: "2021-1-24-12-51-32", value: "2021-1-24-12-51-32.mp4"},
            // {label: "2021-1-24-12-52-32", value: "2021-1-24-12-52-32.mp4"},
            // {label: "2021-1-24-12-53-32", value: "2021-1-24-12-53-32.mp4"},
            // {label: "2021-1-24-12-54-32", value: "2021-1-24-12-54-32.mp4"},
            // {label: "2021-1-24-12-55-32", value: "2021-1-24-12-55-32.mp4"},
            // {label: "2021-1-24-1-11-49", value: "2021-1-24-1-11-49.mp4"},
            // {label: "2021-1-24-1-12-49", value: "2021-1-24-1-12-49.mp4"},
            // {label: "2021-1-24-1-13-49", value: "2021-1-24-1-13-49.mp4"},
            // {label: "2021-1-24-1-14-49", value: "2021-1-24-1-14-49.mp4"},
            // {label: "2021-1-24-1-15-49", value: "2021-1-24-1-15-49.mp4"},
            // {label: "2021-1-24-1-16-49", value: "2021-1-24-1-16-49.mp4"},
            // {label: "2021-1-26-6-15-41", value: "2021-1-26-6-15-41.mp4"},
            // {label: "2021-1-26-6-16-41", value: "2021-1-26-6-16-41.mp4"},
            // {label: "2021-1-26-6-17-42", value: "2021-1-26-6-17-42.mp4"},
            // {label: "2021-1-26-6-18-42", value: "2021-1-26-6-18-42.mp4"},
            // {label: "2021-1-27-7-00-02", value: "2021-1-27-7-00-02.mp4"},
            // {label: "2021-1-27-7-05-03", value: "2021-1-27-7-05-03.mp4"},
            // {label: "2021-1-27-7-10-03", value: "2021-1-27-7-10-03.mp4"}
        ]
        this.state = {
            date : null,
            videoFile: null,
            videoUrl: VIDEO_ROOT,
            videoFileOptions: [],
            timeDisabled: true,
            dateDisabled: false,
            dateList: [],
            selectedDate: null,
            time: null,
            timelen: null,
            isVideoMpegPlaybackPlayerMounted: false,
            downloadDate: null,
            downloadStartTime: null,
            downloadEndTime: null
        }
        this.handleDisableTimeInputs = this.handleDisableTimeInputs.bind(this);

    }

    componentDidMount(){
        // console.log('chid - video player',this.props.chid);
        // console.log('ch - video player',this.props.ch);
        let ch = this.props.ch ? this.props.ch : 'nvr';
        this.loadDateList(ch, this.props.chid);
    }
    componentDidUpdate(prevProps, prevState, snapshot){}
    componentWillUnmount(){}

    loadDateList(ch, chid){
        // console.log('====loading date======')
        // let urlStr = `http://localhost:8043/GetRecordDate/${ch}/${chid}`;
        // axios({
        //     url: urlStr,
        //     method: 'get',
        //     headers: {
        //         // 'Authorization': 'Basic YWRtaW46MTIzNDU2',
        //         // 'Content-Type': 'application/xml; charset="UTF-8"',
        //         'Access-Control-Allow-Origin': '*',
        //         'Access-Control-Allow-Methods': 'POST, GET',
        //         'Access-Control-Allow-Headers': 'X-Auth, Content-Type, Accept',
        //         'Access-Control-Allow-Credentials': 'true'
        //         // 'Access-Control-Max-Age': '86400'
        //     }
        // })
        // console.log(`${VIDEO_ROOT}GetRecordDate/${ch}/${chid}`)
        axios.get(`${VIDEO_PROXY_ROOT}GetRecordDate/${ch}/${chid}`).then(resp => {
             
            if ( resp.status == 200 ) {
                if (resp.data.config && resp.data.config.dateList) {
                    // console.log('date list', resp.data.config.dateList[0].item)
                    this.setState({
                        dateList: (resp.data.config.dateList[0].item).reverse()
                    });
                }
            }
        }).catch(error => {
            this.setState({
                dateList: []
            });
        });
    }

    dateFormatTransfer = (inputDate) => {
        try{
            let dateList = inputDate.split('-');
            if (dateList.length < 3) {
                throw new Error("wrong file name format");
            }
            return `${dateList[0]}-${dateList[1].padStart(2, '0')}-${dateList[2].padStart(2, '0')}`
        }
        catch(e) {
            console.error(e);
            return null;
        }
    }

    setTimeRange = () => {
        let time = null;
        let timelen = null;
        try{
            if (this.state.startTime && this.state.endTime && this.state.startTime.length > 0 && this.state.endTime.length > 0) {
                let startTime = `${this.state.startTime}:00`;
                let endTime = `${this.state.endTime}:00`;
                let startMoment = moment(`${startTime}`, 'HH:mm:ss');
                let endMoment = moment(`${endTime}`, 'HH:mm:ss');
                // console.log('start moment', startMoment);
                // console.log('end moment', endMoment);
                if (startMoment.isAfter(endMoment) || startMoment.isSame(endMoment)){
                    toast.error('The end time should be greater than the start time');
                    return;
                }
                // get current day
                // check if time is after current minute
                // use this.state.date
                // date format is 2021-10-19
                let currentMomentDate = moment(moment()).format("YYYY-MM-DD");
                let currentMomentTime = moment(`${moment(moment()).format('HH:mm:ss')}`, 'HH:mm:ss');
                if (currentMomentDate === this.state.date 
                        && (startMoment.isAfter(currentMomentTime) || endMoment.isAfter(currentMomentTime))) {
                    toast.error(`Video is not available for future date and time`);
                    return;
                }
                time = startTime;
                timelen = endMoment.diff(startMoment, 'seconds');
                // console.log('diff moment', timelen);
                this.setState(preState => ({
                    time: time,
                    timelen: timelen,
                    downloadStartTime: startTime,
                    downloadEndTime: endTime 
                }));
            }
        }
        catch(e) {
            console.error(e);
            return;
        }        
    }

    filterVideoFileByDate = () => {
        let reArr = [];
        let dataStr = '';
        let startTime = '';
        let endTime = '';
        if (this.state.date && this.state.date.length > 0){
             dataStr = this.state.date;       
        }
        else {
            return reArr;
        }
        if (this.state.startTime && this.state.startTime.length > 0){
            startTime = this.state.startTime;
        }else{
            startTime = '00:00';
        }
        if (this.state.endTime && this.state.endTime.length > 0){
            endTime = this.state.endTime;
        }else{
            endTime = '23:59';
        }
        let startMoment = moment(`${dataStr} ${startTime}`, 'YYYY-MM-DD HH:mm');
        let endMoment = moment(`${dataStr} ${endTime}`, 'YYYY-MM-DD HH:mm');
         
         
        if (startMoment.isAfter(endMoment)){
            toast.error('end time should be later than the start time')
            return reArr;
        }
        this.fileList.forEach(ele => {
            try{
                let dateList = ele.label.split('-');
                if (dateList.length < 6) {
                    throw new Error("wrong file name format");
                }
                let fileDate = moment(`${dateList[0]}-${dateList[1].padStart(2, '0')}-${dateList[2].padStart(2, '0')} ${dateList[3]}:${dateList[4]}:${dateList[5]}`, 'YYYY-MM-DD H:m:s');
               
                if (fileDate.isAfter(startMoment) && fileDate.isBefore(endMoment)) {
                    reArr.push(ele);
                }
            } 
            catch(e) {
                console.error(e);
                return;
            }
        });
        return reArr;
    }

    onDateChange = date => {
        // let fileName = value ? value.value : "";
        let downloadDate = moment(date.value).format('YYYY/MM/DD');
        // console.log('SELECTED DATE', downloadDate);
        this.setState({
            date: date ? date.value : null,
            startTime: '',
            endTime: '',
            selectedDate: date,
            timeDisabled: false,
            time: null,
            timelen: null,
            downloadDate: downloadDate
        })      
    }

    onDatePickerChange = e => {
        // if (e.target.value && e.target.value.length > 0){
            let currentDate = e.target.value;
            // let options = this.filterVideoFileByDate(currentDate);
            this.setState({
                // date: moment(e.target.value)
                date: currentDate,
                startTime: '',
                endTime: '',
                // videoFileOptions: options,
                videoFile: null,
                videoUrl: VIDEO_ROOT,
                timeDisabled: false
            }, () => {
                // this.setState({videoFileOptions: this.filterVideoFileByDate()});
            });
        // }
    }
    onTimeChange = e => {
        e.currentTarget.type = "time";
        let name = e.currentTarget.name;
        let value = e.currentTarget.value;
        if (name === 'startTime') {
            let startTime = `${value}:00`;
            let startMoment = moment(`${startTime}`, 'HH:mm:ss');
            console.log('START TIME', startTime);

            // get current day
            // check if time is after current minute
            // use this.state.date
            // date format is 2021-10-19
            let currentMomentDate = moment(moment()).format("YYYY-MM-DD");
            let currentMomentTime = moment(`${moment(moment()).format('HH:mm:ss')}`, 'HH:mm:ss');
            if (currentMomentDate === this.state.date
                    && startMoment.isAfter(currentMomentTime)) {
                this.setState({ endTime: '' })
                toast.error(`Video is not available for future date and time`);
                return;
            }          
        }

        this.setState({
            [name]: value
        }, () => {
        });
        if (name === 'startTime' && this.state.endTime) {
            this.setState({ endTime: '' })
        }
    }

    onTimeBlur = () => {
        this.setState({videoFileOptions: this.filterVideoFileByDate()});
        this.setTimeRange();
    }

    onTimeFocus = (e) => {
        e.currentTarget.type = "time";
    }

    
    onVideoFileNameChange = value => {        
        let fileName = value ? value.value : "";
        this.setState({
            videoFile: value,
            videoUrl: `${VIDEO_ROOT}${fileName}`
        })       
    }

    handleDisableTimeInputs = (booleanValue) => {
        this.setState(preState => ({
            timeDisabled: booleanValue,
            dateDisabled: booleanValue,
        }));
    }


    render(){
        return (
            <div>
                <Row style={{marginBottom: "5px"}}>
                    <Col md={4}>
                        {/* <SingleDatePicker
                            date={this.state.date} // momentPropTypes.momentObj or null
                            onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                            focused={this.state.focused} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                            id="date_picker" // PropTypes.string.isRequired,
                        /> */}
                        {/* <Input
                            type="date"
                            name="date"
                            id="date_picker"
                            placeholder="date placeholder"
                            value={this.state.date}
                            onChange={this.onDatePickerChange}
                            onBlur={this.onTimeBlur}
                        /> */}
                        {/* <button>X</button> */}
                        <Select
                            styles={style} 
                            isClearable={false}
                            // isSearchable
                            options = {this.state.dateList ? this.state.dateList.map(item => ({label: item, value: this.dateFormatTransfer(item)})) : []}
                            isDisabled={this.state.dateDisabled}
                            getOptionLabel={({ label }) => label}
                            getOptionValue={({ value }) => value}
                            value={this.state.selectedDate}
                            // // defaultValue={}
                            onChange = {this.onDateChange}
                            placeholder = {'Select Date...'}
                            // isDisabled={this.state.dateDisabled}
                            // styles={customStyles}
                            // {...props}
                        />
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="startTime"
                            id="time_start"
                            placeholder="Start Time"
                            value={this.state.startTime}
                            onChange={this.onTimeChange}
                            onBlur={this.onTimeBlur}
                            onFocus={this.onTimeFocus}
                            disabled={this.state.timeDisabled}
                        />
                        {/* <TimePicker name="startTime" value={this.state.startTime} onChange={this.onTimeChange} disabled={this.state.dateDisabled}/> */}
                    </Col>
                    <Col md={4}>
                        <Input
                            type="text"
                            name="endTime"
                            id="time_end"
                            placeholder="End Time"
                            value={this.state.endTime}
                            onChange={this.onTimeChange}
                            onBlur={this.onTimeBlur}
                            onFocus={this.onTimeFocus}
                            disabled={this.state.timeDisabled}
                        />
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={12}>
                    <Select 
                        isClearable
                        // isSearchable
                        options = {this.state.videoFileOptions}
                        getOptionLabel={({ label }) => label}
                        getOptionValue={({ value }) => value}
                        value={this.state.videoFile}
                        // // defaultValue={}
                        onChange = {this.onVideoFileNameChange}
                        // styles={customStyles}
                        // {...props}
                    />
                    </Col>
                </Row> */}
                <Row style={{padding: "20px"}}>
                    <Col md={12} style={{textAlign: "center", display: "flex", justifyContent: "center"}}>
                        {/* <ReactPlayer
                            // className='react-player fixed-bottom'
                            url = {this.state.videoUrl}
                            width='100%'
                            height='100%'
                            controls = {true}
                        /> */}
                        {/* <Player key={this.state.videoFile}>
                            <source src="https://waiviot.itsp-inc.com:8443/videos/avc01.mp4" codecs="hvc1"/>
                            <source src={this.state.videoUrl}/>
                        </Player> */}
                        <VideoMpegPlaybackPlayer
                            port = {this.state.port}
                            chid = {this.props.chid}
                            ch = {this.props.ch}
                            date = {this.state.date}
                            time = {this.state.time}
                            timelen = {this.state.timelen}
                            downloadDate = {this.state.downloadDate}
                            startTime = {this.state.downloadStartTime}
                            endTime = {this.state.downloadEndTime}
                            onDisableTimeInputs = {this.handleDisableTimeInputs}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default VideoPlaybackPanel