/* eslint-disable */
import axios from 'axios';
import https from 'https';
import { toast } from "react-toastify";
import {
    USER_LOGIN_SUCESS,
    USER_LOGIN_FAILED,
    USER_LOGOUT,
    USER_ADD_SUCCESS,
    USER_ADD_FAILED,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_FAILED,
    CHANGE_NVR_PASSWORD_SUCCESS,
    CHANGE_NVR_PASSWORD_FAIL,
    USER_REMOVE_SUCCESS,
    USER_REMOVE_FAILED,
    MULTI_USER_ADD_SUCCESS,
    MULTI_USER_ADD_FAILED,
    USER_GET_SUCCESS,
    USER_GET_FAILED,
    USER_ADD_ASSETCLASS_LIST_SUCCESS,
    USER_ADD_ASSETCLASS_LIST_FAILED,
    USER_CHECK_ASSETCLASS_LIST_SUCCESS,
    USER_CHECK_ASSETCLASS_LIST_FAILED,
    USER_ADD_DEVICETYPE_LIST_SUCCESS,
    USER_ADD_DEVICETYPE_LIST_FAILED,
    USER_CHECK_DEVICETYPE_LIST_SUCCESS,
    USER_CHECK_DEVICETYPE_LIST_FAILED,
    USER_GET_COMPANY_LIST_SUCCESS,
    USER_GET_COMPANT_LIST_FAILED,
    USER_GET_ROLES_SUCCESS,
    USER_GET_ROLES_FAILED,
    USER_GET_COMPANY_DEVICE_TYPES_SUCCESS,
    USER_GET_COMPANY_DEVICE_TYPES_FAILED,
    USER_GET_PAGE_CONTENT_SUCCESS,
    USER_GET_PAGE_CONTENT_FAILED,
    USER_GET_COMPANY_ASSET_CLASS_SUCCESS,
    USER_GET_COMPANY_ASSET_CLASS_FAILED,
    USER_GET_COMPANY_OBJ_SUCCESS,
    USER_GET_COMPANY_OBJ_FAILED,
    USER_UPDATE_RESELLER_OBJ_SUCCESS,
    USER_UPDATE_RESELLER_OBJ_FAILED,
    USER_DISABLE_RESELLER_OBJ_SUCCESS,
    USER_DISABLE_RESELLER_OBJ_FAILED,
    USER_UPDATE_COMPANY_OBJ_SUCCESS,
    USER_UPDATE_COMPANY_OBJ_FAILED,
    USER_DELETE_COMPANY_OBJ_SUCCESS,
    USER_DELETE_COMPANY_OBJ_FAILED,
    USER_GET_ALL_COMPANIES_SUCCESS,
    USER_GET_ALL_COMPANIES_FAILED,
    USER_GET_COUNTRY_LIST_SUCCESS,
    USER_GET_PROVINCE_LIST_SUCCESS,
    USER_GET_ACCOUNT_LIST_SUCCESS,
    USER_GET_ACCOUNT_LIST_FAILED,
    COMPANY_GET_BRANDING_SUCCESS,
    COMPANY_GET_BRANDING_FAILED,
    USER_GET_PLAN_ITEMS_SUCCESS,
    USER_GET_PLAN_ITEMS_FAILED,
    STORE_RESET,
    CARD_ADD_FAILED,
    CARD_ADD_SUCCESS,
    ACCOUNT_ADD_ACCOUNT,
    ACCOUNT_DELETE_ACCOUNT,
    GET_ACCOUNT_CARDS_SUCCESS,
    GET_ACCOUNT_CARDS_FAILED,
    COMPANY_GET_PAYMENT_ITEM_SUCCESS,
    COMPANY_GET_PAYMENT_ITEM_FAILED,
    COMPANY_PAYMENT_CHECKOUT_SUCCESS,
    COMPANY_PAYMENT_CHECKOUT_FAILED,
    COMPANY_PAYMENT_HISTORY_SUCCESS,
    COMPANY_PAYMENT_HISTORY_FAILED,
    COMPANY_TRANSACTION_HISTORY_SUCCESS,
    COMPANY_TRANSACTION_HISTORY_FAILED,
    COMPANY_TRANSACTION_REFUND_SUCCESS,
    COMPANY_TRANSACTION_REFUND_FAILED,
    COMPANY_PAYMENT_INVOICE_SUCCESS,
    COMPANY_PAYMENT_INVOICE_CLEAR,
    COMPANY_PAYMENT_INVOICE_FAILED,
    COMPANY_PAYMENT_INVOICE_DETAILS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_DETAILS_FAILED,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_SUCCESS,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_FAILED,

    COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
    COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,

    COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_SUCCESS,
    COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_FAILED,

    COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS,
    COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED,

    COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_FAILED,
    COMPANY_PAYMENT_USAGE_REPORT_SUCCESS,
    COMPANY_PAYMENT_USAGE_REPORT_FAILED,
    COMPANY_PAYMENT_ADJUST_INVOICE_SUCCESS,
    COMPANY_PAYMENT_ADJUST_INVOICE_FAILED,
    COMPANY_MONERIS_ACCOUNT_SUCCESS,
    COMPANY_MONERIS_ACCOUNT_FAILED,
    COMPANY_SET_CURRENT_COMPANY_CODE,
    COMPANY_MONERIS_ACCOUNT_START,
    CUSTOMER_COMPANY_GET_SUCCESS,
    CUSTOMER_COMPANY_GET_FAILED,
    CUSTOMER_GET_ORDERS_SUCCESS,
    CUSTOMER_GET_ORDERS_FAILED,
    CUSTOMER_UPDATE_ORDERS_SUCCESS,
    CUSTOMER_UPDATE_ORDERS_FAILED,
    ORDER_ADJUSTMENT_GET_SUCCESS,
    ORDER_ADJUSTMENT_GET_FAILED,
    ORDER_ADJUSTMENT_CLEAR,
    GET_ACTIVATED_SIM_DASHBOARD_SUCCESS,
    GET_ACTIVATED_SIM_DASHBOARD_FAILED,
    GET_SIM_USAGE_SUCCESS,
    GET_SIM_USAGE_FAILED,
    GET_CARRIER_LIST_SUCCESS,
    GET_CARRIER_LIST_FAILED,
    USER_GET_ALL_RESELLER_SUCCESS,
    USER_GET_ALL_RESELLER_FAILED,
    CONSUMER_ADD_SUCCESS,
    CONSUMER_ADD_FAILED,
    CONSUMER_GET_SUCCESS,
    CONSUMER_GET_FAILED,
    CONSUMER_GET_PLAN_USAGE_SUCCESS,
    CONSUMER_GET_PLAN_USAGE_FAILED,
    CONSUMER_UPDATE_SUCCESS,
    CONSUMER_UPDATE_FAILED,
    USER_GET_SIM_SERVICE_PLAN_SUCCESS,
    USER_GET_SIM_SERVICE_PLAN_FAILED,
    WEB_SERVICE_GET_SPECIAL_LINE_SUCCESS,
    WEB_SERVICE_GET_SPECIAL_LINE_FAILED,
    RESELLER_SET_SPECIAL_LINE_SUCCEED,
    RESELLER_SET_SPECIAL_LINE_FAILED,
    GET_PROMOTE_SUCCESS,
    GET_PROMOTE_FAILED,
    SET_PROMOTE_SUCCESS,
    SET_PROMOTE_FAILED,
    USER_GET_CARRIER_LIST_SUCCESS,
    USER_GET_CARRIER_LIST_FAILED,
    USER_GET_RESELLER_LIST_SUCCESS,
    USER_GET_RESELLER_LIST_FAILED,
    CLIENT_GET_FAILED,
    CLIENT_GET_SUCCESS,
    RESELLER_SET_ITEM_SUCCEED,
    RESELLER_SET_ITEM_FAILED,
    CLIENT_PAYMENT_CHECKOUT_SUCCESS,
    CLIENT_PAYMENT_CHECKOUT_FAILED,
    PAYMENT_ORDER_GET_ADDRESS_SUCCESS,
    PAYMENT_ORDER_GET_ADDRESS_FAILED,
    PAYMENT_ORDER_SET_ADDRESS_SUCCESS,
    PAYMENT_ORDER_SET_ADDRESS_FAILED,
    GET_PLAN_USAGE_DATA_SUCCESS,
    GET_PLAN_USAGE_DATA_FAILED,
    GET_IDENTIFIER_USAGE_DATA_FAILED,
    GET_IDENTIFIER_USAGE_DATA_SUCCESS,
    USER_GET_PAYMENT_ACCOUNT_LIST_SUCCESS,
    USER_GET_PAYMENT_ACCOUNT_LIST_FAILED,
    USER_UPDATE_PAYMENT_ACCOUNT_SUCCESS,
    USER_UPDATE_PAYMENT_ACCOUNT_FAILED,
    PAYMENT_ORDER_SET_DEFAULT_PAYMENT_SUCCESS,
    PAYMENT_ORDER_SET_DEFAULT_PAYMENT_FAILED,
    USER_FORGET_PASSWORD_SUCCESS,
    USER_FORGET_PASSWORD_NOT_FOUND,
    USER_FORGET_PASSWORD_FAILED,
    USER_FORGET_PASSWORD_NOT_ALLOWED,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAILED,
    CLIENT_GET_BUSINESS_UNITS_SUCCESS,
    CLIENT_GET_BUSINESS_UNITS_FAILED,
    CLIENT_GET_BUSINESS_UNIT_RELATION_SUCCESS,
    CLIENT_GET_BUSINESS_UNIT_RELATION_FAILED,
    CLEAR_CLIENT_DATA,
    GET_ACTIVATED_SIM_DASHBOARD_START,
    GET_TOTAL_USAGE_START,
    GET_TOTAL_USAGE_SUCCESS,
    GET_TOTAL_USAGE_FAILE, GET_PLAN_USAGE_DATA_START, GET_IDENTIFIER_USAGE_DATA_START,
    GENERATE_PURCHASE_ORDER_SUCCESS,
    GENERATE_PURCHASE_ORDER_FAILED,
    EMAIL_PURCHASE_ORDER_SUCCESS,
    EMAIL_PURCHASE_ORDER_FAILED,
    GET_USER_ACTIVITY_TRACKING_SUCCEED,
    GET_USER_ACTIVITY_TRACKING_FAILED,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED

} from '../../constants/actionTypes';

import {
    WEB_SERVICE_GET_BRANDING,
    WEB_SERVICE_SET_BRANDING,
    WEB_SERVICE_ROOT,
    WEB_SERVICE_GET_COMPANY_DEVICETYPE,
    WEB_SERVICE_GET_COMPANY_ASSETCLASS,
    WEB_SERVICE_GET_COMPANY,
    WEB_SERVICE_GET_RESELLER,
    WEB_SERVICE_DISABLE_RESELLER,
    WEB_SERVICE_GET_COUNTRY,
    WEB_SERVICE_GET_ROLES,
    WEB_SERVICE_USER_ROOT,
    WEB_SERVICE_USER_CHECK,
    WEB_SERVICE_USER_ADD,
    WEB_SERVICE_MULTI_USER_ADD,
    WEB_SERVICE_USER_GET_CONTENT,
    WEB_SERVICE_USER_UPDATE,
    WEB_SERVICE_CHANGE_NVR_PASSWORD,
    WEB_SERVICE_USER_REMOVE,
    WEB_SERVICE_USER_GET,
    WEB_SERVICE_USER_ADD_ASSETCLASS_LIST,
    WEB_SERVICE_USER_ADD_ASSETCLASS_USER,
    WEB_SERVICE_USER_CHECK_ASSETCLASS_LIST,
    WEB_SERVICE_USER_CHECK_DEVICETYPE_USER_LIST,
    WEB_SERVICE_USER_CHECK_DEVICETYPE_LIST,
    WEB_SERVICE_USER_ADD_DEVICETYPE_LIST,
    WEB_SERVICE_USER_SUCCESS_CODE,
    WEB_SERVICE_CHANGE_COMPANY_DEVICETYPE,
    WEB_SERVICE_CHANGE_COMPANY_ASSETCLASS,
    WEB_SERVICE_PAYMENT_ITEM,
    WEB_SERVICE_PAYMENT_CHECKOUT,
    WEB_SERVICE_PAYMENT_PAY,
    WEB_SERVICE_PAYMENT_HISTORY,
    WEB_SERVICE_TRANSACTION_HISTORY,
    WEB_SERVICE_REFUND_INVOICE,
    WEB_SERVICE_PAYMENT_INVOICE,
    WEB_SERVICE_PAYMENT_CARD_MGMT,
    WEB_SERVICE_PAYMENT_INVOICE_DETAILS,
    WEB_SERVICE_PAYMENT_INVOICE_ADJUSTMENT,
    WEB_SERVICE_PAYMENT_PAY_INVOICE,
    WEB_SERVICE_CUSTOMER_BY_COMPANY,
    WEB_SERVICE_GET_ORDERS,
    WEB_SERVICE_ORDER,
    WEB_SERVICE_UPDATE_ORDER,
    WEB_SERVICE_PAYMENT_DEVICE,
    WEB_SERVICE_PAYMENT_SERVICE,
    WEB_SERVICE_PAYMENT_UPC_DEVICE,
    WEB_SERVICE_PAYMENT_CHECHOUT_DEVICE,
    WEB_SERVICE_PAYMENT_PAY_DEVICE,
    WEB_SERVICE_PAYMENT_RELEASE_DEVICE,
    WEB_SERVICE_ORDER_ADJUSTMENT,
    WEB_SERVICE_TELELCOM_BACKEND_ROOT,
    WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM,
    WEB_SERVICE_SIFOX_GET_SIM_USAGE,
    WEB_SERVICE_SIFOX_GET_USAGE_FOR_RESELLER,
    WEB_SERVICE_GET_PLAN_USAGE,
    WEB_SERVICE_GET_IDENTIFIER_USAGE,
    WEB_SERVICE_SIFOX_GET_CLIENT_USAGE_STATS,
    WEB_SERVICE_SIFOX_GET_CURRENT_TOTAL_USAGE,
    WEB_SERVICE_GET_CARRIER,
    WEB_SERVICE_CONSUMER_ADD,
    WEB_SERVICE_CONSUMER_GET,
    WEB_SERVICE_UPDATE_CONSUMER,
    WEB_SERVICE_CONSUMER_GET_PLAN_USAGE,
    WEB_SERVICE_GET_OPERATIONCOUNTRY,
    WEB_SERVICE_GET_LOBS,
    WEB_SERVICE_GET_DIVISIONS,
    WEB_SERVICE_GET_SIM_SERVICE_PLAN,
    WEB_SERVICE_GET_SPECIAL_LINE,
    WEB_SERVICE_POST_SPECIAL_LINE,
    WEB_SERVICE_DELETE_SPECIAL_LINE,
    WEB_SERVICE_PROMOTE,
    WEB_SERVICE_GET_CARRIER_LIST,
    WEB_SERVICE_CLIENT_GET,
    WEB_SERVICE_SET_SERVICE_PLAN,
    WEB_SERVICE_DELETE_SERVICE_PLAN,
    WEB_SERVICE_SIFOX_ORDER_CLIENT_CHECKOUT,
    WEB_SERVICE_PAYMENT_INVOICE_GENERATE,
    WEB_SERVICE_PAYMENT_ORDER_ROOT,
    WEB_SERVICE_SIFOX_ORDER_CLIENT_GET_ACCOUNT_BY_ENTITY,
    WEB_SERVICE_PAYMENT_ORDER_GET_ADDRESS,
    WEB_SERVICE_PAYMENT_ORDER_SET_ADDRESS,
    WEB_SERVICE_PAYMENT_INVOICE_LIST,
    WEB_SERVICE_PAYMENTNEW_DEFAULT_PAYMENT,
    WEB_SERVICE_PAYMENTNEW_UPDATE_ACCOUNT,
    WEB_SERVICE_PAYMENTNEW_PAYMENT_ACCOUNTS,
    WEB_SERVICE_FORGET_PASSWORD,
    WEB_SERVICE_RESET_PASSWORD,
    WEB_SERVICE_PAYMENT_USAGE_REPORT,
    WEB_SERVICE_GET_BUSINESS_UNIT,
    WEB_SERVICE_UPDATE_BUSINESS_UNIT,
    WEB_SERVICE_UPDATE_BUSINESS_UNIT_RELATION,
    WEB_SERVICE_PAYMENT_GET_GL_CODES_BY_COMPANY,
    WEB_SERVICE_PAYMENT_ADD_GL_CODE_BY_COMPANY,
    WEB_SERVICE_PAYMENT_MODIFY_GL_CODE_BY_COMPANY,
    WEB_SERVICE_PAYMENT_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY,
    WEB_SERVICE_PAYMENT_GET_GL_CODE_REASONS_BY_COMPANY,
    WEB_SERVICE_PAYMENT_ADD_GL_CODE_REASON_BY_COMPANY,
    WEB_SERVICE_PAYMENT_MODIFY_GL_CODE_REASON_BY_COMPANY,
    WEB_SERVICE_PAYMENT_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY,
    WEB_SERVICE_PAYMENT_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY,
    WEB_SERVICE_PAYMENT_INVOICE_WITH_APPROVAL_DETAILS,
    WEB_SERVICE_PAYMENT_INVOICE_CONFIRM_APPROVAL,
    WEB_SERVICE_GET_RESELLER_TREE,
    WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM_FOR_DOWNLOAD,
    WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM_FOR_DOWNLOAD_BY_CVS,
    WEB_SERVICE_GENERATE_PURCHASE_ORDER,
    WEB_SERVICE_EMAIL_PURCHASE_ORDER,
    WEB_SERVICE_GET_USER_ACTIVITY_TRACKING

} from '../../constants/appConstants';
import { Countries } from '../../constants/dataConstants';
import {
    axiosGet, getCurrentUser, base64Encode,
    getJwttoken, axiosPost, axiosDelete, axiosPut, axiosGetWithBody
} from '../../factories/utils';
import log from 'loglevel';

// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
    const token = getJwttoken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

// const ACCOUNT_ROOT = 'http://192.168.161.9:8080/mk/';
//const ACCOUNT_ROOT = 'https://waiviot.itsp-inc.com:8443/mk/';
// const ACCOUNT_ROOT = 'http://192.168.2.84:8080/';
const ACCOUNT_ROOT = WEB_SERVICE_PAYMENT_ORDER_ROOT

export function handleSubmit(values) {


    // alert(values);
    userLogin(values.name, values.password);
}

export function forgotPassword(username) {

    let usernameInfo = {
        userName: username
    };

    let urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_FORGET_PASSWORD}`;

    const request = axios({
        url: urlStr,
        method: 'post',
        data: usernameInfo,
    })

    return ((dispatch) => {
        dispatch({
            type: STORE_RESET,
        })
        request.then((response) => {
            if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_FORGET_PASSWORD_SUCCESS,
                        payload: response.data

                    }
                )
            } else if (response.data.includes("User Not Found")) {
                dispatch(
                    {
                        type: USER_FORGET_PASSWORD_NOT_FOUND,
                        payload: response.data,
                        message: response.data,

                    }
                )
            } else if (response.data.includes("Not Allowed")) {
                dispatch({
                    type: USER_FORGET_PASSWORD_NOT_ALLOWED,
                    payload: response.data,
                    message: response.data
                })
            } else {
                dispatch({
                    type: USER_FORGET_PASSWORD_FAILED,
                    payload: response.data,
                    message: response.data
                })
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_FORGET_PASSWORD_FAILED,
                    message: error.message,

                }
            )
        }
        )
    }
    );
}

export function resetPassword(password, token) {
    let passwordInfo = {
        password: password,
        resetPasswordToken: token
    };
    let urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_RESET_PASSWORD}`;
    const request = axios({
        url: urlStr,
        method: 'post',
        data: passwordInfo
    })

    return ((dispatch) => {
        dispatch({
            type: STORE_RESET,
        })
        request.then((response) => {
            if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_RESET_PASSWORD_SUCCESS,
                        payload: response.data.userDetails,
                        token: response.data.jwttoken,

                    }
                )
            } else {
                dispatch(
                    {
                        type: USER_RESET_PASSWORD_FAILED,
                        message: 'Log In Failed',
                    }
                )
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_RESET_PASSWORD_FAILED,
                    message: error.message,

                }
            )
        }
        )
    }
    );
}

export function userLogin(userid, password) {
    // alert('log in');
    if ((!userid || userid.length === 0) || (!password || password.length === 0)) {
        // alert('yes');
        return dispatch => {
            // alert('okay');
            dispatch({
                //    type: USER_LOGIN_SUCESS,
                type: USER_LOGIN_FAILED,
                message: 'user name or password is empty'
            })
        }
    }

    let loginInfo = {
        userName: userid,
        password: password
    };

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_CHECK}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: loginInfo,
    });

    return ((dispatch) => {
        // dispatch({
        //     type: STORE_RESET,
        // })
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                // sessionStorage.setItem('currentUser', response.data.userDetails);
                sessionStorage.setItem('userid', userid)
                sessionStorage.setItem('password', password)
                sessionStorage.setItem('user_current_password', password);
                dispatch(
                    {
                        type: USER_LOGIN_SUCESS,
                        payload: response.data.userDetails,
                        token: response.data.jwttoken,
                        
                    }
                )
            } else {
                dispatch(
                    {
                        type: USER_LOGIN_FAILED,
                        // message: response.data.error,
                        message: 'Log In Failed',

                    }
                )
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_LOGIN_FAILED,
                    message: error.message,

                }
            )
        }
        )
    }
    );
}

export function userLogout() {
    return (dispatch => {
        dispatch(
            {
                type: USER_LOGOUT,
            }
        )
    })
}

export function getUsers(requestObj) {

    let companyCode = "";
    let department = "";
    let businessUnit = "";
    let resellerCode = "";

    if (sessionStorage.getItem('companyCode')) {
        companyCode = sessionStorage.getItem('companyCode') === "null" ? null : sessionStorage.getItem('companyCode');
    }
    if (sessionStorage.getItem('resellerCode')) {
        resellerCode = sessionStorage.getItem('resellerCode') === "null" ? null : sessionStorage.getItem('resellerCode');
    }

    if (sessionStorage.getItem('department')) {
        // department = sessionStorage.getItem('department');
    }

    if (sessionStorage.getItem('businessUnit')) {
        // businessUnit = sessionStorage.getItem('businessUnit');
    }

    let obj = {
        companyCode: companyCode,
        department: department,
        businessUnit: businessUnit,
        resellerCode: resellerCode
    };
    if (requestObj) {
        obj = requestObj;
    }

    let urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_GET}`;




    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        // params: {
        //     token: sessionStorage.getItem('authToken'),
        //     userName: criteria.userName || undefined
        // },
        data: obj,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_GET_SUCCESS,
                        payload: response.data.userDetails,

                    }
                );
            } else {
                let message = 'Getting Users Failed';
                console.error(message);
                dispatch(
                    {
                        type: USER_GET_FAILED,
                        message: message,

                    }
                )
            }

        }
        ).catch((error) => {
            console.error(error.message);
            dispatch(
                {
                    type: USER_GET_FAILED,
                    message: error.message,

                }
            )
        }
        )
    }
    );


}

/// for adding CSV file as userlist
export function addMultiUser(formdata) {
    let message = "";
    if (!formdata || formdata === null) {
        return dispatch => {
            message = "user input is not valid";
            dispatch({
                type: USER_ADD_FAILED,
                message: message
            });
            console.error(message);
            toast.error(message);
        }
    }
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_MULTI_USER_ADD}`;
    const request = axios({
        url: urlStr,
        method: 'post',
        data: formdata,
    });
    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                message = "Multiple users added successfully";
                dispatch(
                    {
                        type: MULTI_USER_ADD_SUCCESS,
                        payload: response.data.userDetails,
                    }
                );
                toast.success(message);
                dispatch(getUsers());
            } else {
                message = "Failed to add multiple users, please check content format or file format."
                dispatch(
                    {
                        type: MULTI_USER_ADD_FAILED,
                        message: message,
                        payload: response.data.userDetails,

                    }
                );
                console.error(message);
                toast.error(message);
            }
        }
        ).catch((error) => {
            message = 'Invalid content format or file format.';
            dispatch(
                {
                    type: MULTI_USER_ADD_FAILED,
                    message: error.message,

                }
            );
            console.error("Add multiple users error(s): " + error.message);
            toast.error(message);

        }
        )
    }
    );
}

export function addUser(userObj, reLoad = false) {
    if (!userObj || userObj === null) {
        return dispatch => {
            dispatch({
                type: USER_ADD_FAILED,
                message: 'user input is not valid'
            })
        }
    }

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_ADD}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userObj,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_ADD_SUCCESS,
                        payload: response.data.userDetails,

                    }
                );
                let userObje = response.data.userDetails;
                if (userObje.role.includes('ADMIN')) {

                    dispatch(setUserCompanyDevceType(userObj));
                }
                if (reLoad) {
                    dispatch(getUsers());
                }
                toast.success("Successfully Added User " + userObj.userName);
            } else {
                dispatch(
                    {
                        type: USER_ADD_FAILED,
                        message: 'Adding User Failed',

                    }
                )
                console.error(response.data.error);
                toast.error("Failed To Add User " + userObj.userName + ": " + response.data.errorInfo);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_ADD_FAILED,
                    message: error.message,

                }
            )
            console.error(error.message);
            toast.error("Error occurred when Adding User " + userObj.userName + ": " + error.message);
        }
        )
    }
    );
}

export function setUserCompanyDevceType(userObj) {
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }
    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    return dispatch => axios({
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_DEVICETYPE}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    }).then(res => {
        if (res.status === 200) {
            if (res.data && Array.isArray(res.data)) {

                let devicetypeidList = res.data.map((item, index) => (item.devicetypeid));

                let devicetypeMapObj = {
                    userId: userName,
                    devicetypeid: devicetypeidList,
                };
                dispatch(upsertUserDevicetypeList(devicetypeMapObj));
            }
        } else {
            toast.error(res.data);
        }

    }).catch(err => {
        console.log(err)
        toast.error(err.message);
    })
}

export function getUserCompanyDeviceTypes() {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_DEVICETYPE}`,
        criteria: null,
        logText: 'Get user company device type url ',
        sccessType: USER_GET_COMPANY_DEVICE_TYPES_SUCCESS,
        failedType: USER_GET_COMPANY_DEVICE_TYPES_FAILED,
        errorMessage: 'Failed to get company device.'
    };

    return (axiosGet(paramObj));
}

export function getUserCompanyAssetClass() {
    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY_ASSETCLASS}`,
        criteria: null,
        logText: 'Get user asset class type url ',
        sccessType: USER_GET_COMPANY_ASSET_CLASS_SUCCESS,
        failedType: USER_GET_COMPANY_ASSET_CLASS_FAILED,
        errorMessage: 'Failed to get asset class.'
    };

    return (axiosGet(paramObj));
}

export function getPageContent(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_GET_CONTENT}`,
        criteria: obj,
        logText: 'Getting page content',
        sccessType: USER_GET_PAGE_CONTENT_SUCCESS,
        failedType: USER_GET_PAGE_CONTENT_FAILED,
        errorMessage: 'Failed to get page content.'
    };

    return (axiosGet(paramObj));
}

export function changeNvrPassword(payload, callBack=null) {
    if (!payload || payload===null) {
        return dispatch => {
            dispatch({
                type: CHANGE_NVR_PASSWORD_FAIL,
                message: 'Missing parameters'
            })
        }
    }

    let clientName = payload.client_name
    delete payload.client_name

    const url = `${WEB_SERVICE_ROOT}${WEB_SERVICE_CHANGE_NVR_PASSWORD}${clientName}`
    const request = axios({
        url: url,
        method: 'post',
        data: payload
    })
    return ((dispatch) => {
        request.then((response) => {
            if (response.status===200) {
                if (response.data.success) {
                    sessionStorage.setItem('password', payload.client_pw)
                    sessionStorage.setItem('user_current_password', payload.client_pw)
                    dispatch({
                        type: CHANGE_NVR_PASSWORD_SUCCESS,
                        message: response.data.message
                    })
                } else {
                    dispatch({
                        type: CHANGE_NVR_PASSWORD_FAIL,
                        message: response.data.message
                    })
                }
            } else {
                dispatch({
                    type: CHANGE_NVR_PASSWORD_FAIL,
                    message: `HTTP request fail (${response.status})`
                })
            }
        })
        .catch((error) => {
            dispatch({
                type: CHANGE_NVR_PASSWORD_FAIL,
                message: error.message
            })
        })
    })
}

export function updateUser(userObj, reLoad = false, isCurrent = false, callBack = null) {
    if (!userObj || userObj === null) {
        return dispatch => {
            dispatch({
                type: USER_UPDATE_FAILED,
                message: 'user input is not valid'
            })
        }
    }

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_UPDATE}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userObj,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                // dispatch(
                //     {
                //         type: USER_UPDATE_SUCCESS,
                //         payload: response.data.userDetails,
                //     }
                // );
                if (response.status == 200 && isCurrent) {
                    dispatch(
                        {
                            type: USER_UPDATE_SUCCESS,
                            payload: response.data.userDetails,
                        }
                    );
                }
                if (response.status == 200 && reLoad) {
                    dispatch(getUsers());
                }
                if (response.status == 200 && isCurrent && callBack) {
                    callBack(response.data.userDetails);
                }
                toast.success("Successfully Updated User " + userObj.userName);
            } else {
                dispatch(
                    {
                        type: USER_UPDATE_FAILED,
                        message: 'Updating User Failed',
                    }
                )
                console.error(response.data.error);
                toast.error("Failed To Update User " + userObj.userName);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_UPDATE_FAILED,
                    message: error.message,
                }
            )
            console.error(error.message);
            toast.error("Error occurred when Updating User " + userObj.userName);
        }
        )
    }
    );
}

export function removeUser(userid, reLoad = false) {
    if (!userid || userid.length === 0) {
        return dispatch => {
            dispatch({
                type: USER_REMOVE_FAILED,
                message: 'user id is empty'
            })
        }
    }

    // const params = `?userid=${uerid}`;
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_REMOVE}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'text/plain'
        },
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        // data: userObj,
        data: userid
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_REMOVE_SUCCESS,
                        payload: response.data.userDetails,
                    }
                );
                if (response.status == 200 && reLoad) {
                    dispatch(getUsers());
                }
                toast.success("Successfully Removed User " + userid);
            } else {
                dispatch(
                    {
                        type: USER_REMOVE_FAILED,
                        message: 'Removing User Failed',
                    }
                )
                console.error(response.data.error);
                toast.error("Failed to remove User " + userid);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_REMOVE_FAILED,
                    message: error.message,
                }
            )
            console.error(error.message);
            toast.error("Error occurred when removing User " + userid);
        }
        )
    }
    );
}

export function getUserAssetclassList(userid) {
    if (!userid || userid.length === 0) {
        return dispatch => {
            dispatch({
                type: USER_CHECK_ASSETCLASS_LIST_FAILED,
                message: 'user id is empty'
            })
        }
    }

    // const params = `?userID =${userid}`;
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_CHECK_ASSETCLASS_LIST}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'text/plain'
        },
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userid,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_CHECK_ASSETCLASS_LIST_SUCCESS,
                        payload: response.data.classMap,
                    }
                );
            } else {
                dispatch(
                    {
                        type: USER_CHECK_ASSETCLASS_LIST_FAILED,
                        message: 'Fetching User Assetclass List Failed',
                    }
                )
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_CHECK_ASSETCLASS_LIST_FAILED,
                    message: error.message,
                }
            )
        }
        )
    }
    );
}

export function upsertUserAssetclassList(assetMapObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_ADD_ASSETCLASS_LIST}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: assetMapObj,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_ADD_ASSETCLASS_LIST_SUCCESS,
                        payload: response.data.classMap,
                    }
                );
                dispatch(getUserAssetclassList(assetMapObj.userId));
                toast.success("Successfully Updated the asset classes access for the user " + assetMapObj.userId);
            } else {
                dispatch(
                    {
                        type: USER_ADD_ASSETCLASS_LIST_FAILED,
                        message: 'Updating User Asset classes access setting Failed',
                    }
                );
                toast.error("Failed to Update the asset classes access for the user " + assetMapObj.userId);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_ADD_ASSETCLASS_LIST_FAILED,
                    message: error.message,
                }
            )
            toast.error("Error occurred when updating the asset classes access for the user " + assetMapObj.userId);
        }
        )
    }
    );

}

// ------------------------------------------------------------------------------------------------------------------------
export function getUserDevicetypeList(userid) {
    if (!userid || userid.length === 0) {
        return dispatch => {
            dispatch({
                type: USER_CHECK_DEVICETYPE_LIST_FAILED,
                message: 'user id is empty'
            })
        }
    }

    // const params = `?userID =${userid}`;
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_CHECK_DEVICETYPE_LIST}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'text/plain'
        },
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userid,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_CHECK_DEVICETYPE_LIST_SUCCESS,
                        payload: response.data.devicetypesMap,
                    }
                );
            } else {
                dispatch(
                    {
                        type: USER_CHECK_DEVICETYPE_LIST_FAILED,
                        message: 'Fetching User Devictype List Failed',
                    }
                )
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_CHECK_DEVICETYPE_LIST_FAILED,
                    message: error.message,
                }
            )
        }
        )
    }
    );
}

// --------------------------------------------
export function upsertUserDevicetypeList(devicetypeMapObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_USER_ADD_DEVICETYPE_LIST}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: devicetypeMapObj,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_ADD_DEVICETYPE_LIST_SUCCESS,
                        payload: response.data.devicetypesMap,
                    }
                );
                dispatch(getUserDevicetypeList(devicetypeMapObj.userId));
                toast.success("Successfully Updated the Device Type access for the user " + devicetypeMapObj.userId);
            } else {
                dispatch(
                    {
                        type: USER_ADD_DEVICETYPE_LIST_FAILED,
                        message: 'Adding/Updating User Device Types access Failed',
                    }
                );
                toast.error("Failed to Update the Device Type access for the user " + devicetypeMapObj.userId);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_ADD_DEVICETYPE_LIST_FAILED,
                    message: error.message,
                }
            )
            toast.error("Error occurred when updating the assetclas list assigned to the user " + devicetypeMapObj.userId);
        }
        )
    }
    );

}

export function getCompanyList() {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }
    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY}`;
    // const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;



    const request = axios({
        url: urlStr,
        method: 'get',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.status === 200) {
                dispatch(
                    {
                        type: USER_GET_COMPANY_LIST_SUCCESS,
                        payload: response.data,
                    }
                );
            } else {
                dispatch(
                    {
                        type: USER_GET_COMPANT_LIST_FAILED,
                        message: 'Getting compang list Failed',
                    }
                );
                console.error('Getting company list Failed');
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_GET_COMPANT_LIST_FAILED,
                    message: error.message,
                }
            );
            console.error(error.message);
        }
        )
    }
    );

}
export function getAllResellers(requestObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_RESELLER}`;
    let paramObj = {
        url: urlStr,
        criteria: requestObj,
        logText: 'Get all reseller Url ',
        sccessType: USER_GET_ALL_RESELLER_SUCCESS,
        failedType: USER_GET_ALL_RESELLER_FAILED,
        errorMessage: 'Failed to get all reseller.'
    };

    return (axiosGet(paramObj));
}

//todo test !!!!!!!!!!!!!!!
export function getResellersTree(requestObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_RESELLER_TREE}`;
    let paramObj = {
        url: urlStr,
        criteria: requestObj,
        logText: 'Get all reseller Url ',

        errorMessage: 'Failed to get all reseller.'
    };

    return (axiosGet(paramObj));
}

export function getDiscountList(filterObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_PROMOTE}`;
    let paramObj = {
        url: urlStr,
        criteria: filterObj,
        logText: 'Get all Discount Url ',
        sccessType: GET_PROMOTE_SUCCESS,
        failedType: GET_PROMOTE_FAILED,
        errorMessage: 'Failed to get all Discount.'
    };
    return (axiosGet(paramObj));
}
export function setDiscount(filterObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_PROMOTE}`;
    let paramObj = {
        url: urlStr,
        criteria: filterObj,
        logText: 'Set Discount Url ',
        sccessType: SET_PROMOTE_SUCCESS,
        failedType: SET_PROMOTE_FAILED,
        errorMessage: 'Failed to set Discount.',
    };
    return (axiosPost(paramObj));
}
export function getAllCompanies() {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;
    let paramObj = {
        url: urlStr,
        criteria: null,
        logText: 'Get all company Url ',
        sccessType: USER_GET_ALL_COMPANIES_SUCCESS,
        failedType: USER_GET_ALL_COMPANIES_FAILED,
        errorMessage: 'Failed to get all company.'
    };

    return (axiosGet(paramObj));
}
export function getSpecialLine(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_SPECIAL_LINE}`;
    let paramObj = {
        url: url,
        criteria: filterObj,
        logText: 'Get all Special Lines Url ',
        sccessType: WEB_SERVICE_GET_SPECIAL_LINE_SUCCESS,
        failedType: WEB_SERVICE_GET_SPECIAL_LINE_FAILED,
        errorMessage: 'Failed to get  special lines.'
    };
    return (axiosPost(paramObj));
}
export function SetSpecialLine(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_POST_SPECIAL_LINE}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    toast.success(response.data);
                    dispatch({
                        type: RESELLER_SET_SPECIAL_LINE_SUCCEED,
                        payload: response.data
                    });

                }
                else {
                    toast.error(response.data);
                    dispatch({
                        type: RESELLER_SET_SPECIAL_LINE_FAILED,
                        payload: response.data
                    });
                }

                this.getSpecialLine({ "companyCode": filterObj.companyCode, "resellerCode": filterObj.resellerCode })


                return response;
            })
        })
}
export function DeleteSpecialLine(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_DELETE_SPECIAL_LINE}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    toast.success(response.data);
                    dispatch({
                        type: RESELLER_SET_SPECIAL_LINE_SUCCEED,
                        payload: response.data
                    });

                }
                else {
                    toast.error(response.data);
                    dispatch({
                        type: RESELLER_SET_SPECIAL_LINE_FAILED,
                        payload: response.data
                    });
                }

                this.getSpecialLine({ "companyCode": filterObj.companyCode, "resellerCode": filterObj.resellerCode })


                return response;
            })
        })
}
export function getServicePlanItem(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_SIM_SERVICE_PLAN}`;
    let paramObj = {
        url: url,
        criteria: filterObj,
        logText: 'Get all SIM service plan items Url ',
        sccessType: USER_GET_SIM_SERVICE_PLAN_SUCCESS,
        failedType: USER_GET_SIM_SERVICE_PLAN_FAILED,
        errorMessage: 'Failed to get all SIM service plan item.'
    };
    return (axiosPost(paramObj));
}
export function SetServicePlan(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SET_SERVICE_PLAN}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            return (
                request.then((response) => {
                    if (response.status == 200 && response.data) {
                        toast.success(response.data);
                        dispatch({
                            type: RESELLER_SET_ITEM_SUCCEED,
                            payload: response.data
                        });

                    }
                    else {
                        toast.error(response.data);
                        dispatch({
                            type: RESELLER_SET_ITEM_FAILED,
                            payload: response.data
                        });
                    }
                    return response;
                })
            )
        })
}

export function DeleteServicePlan(filterObj) {
    const url = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_DELETE_SERVICE_PLAN}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: filterObj,
    });
    return (
        (dispatch) => {
            return (
                request.then((response) => {
                    if (response.status == 200 && response.data) {
                        toast.success(response.data);
                        dispatch({
                            type: RESELLER_SET_ITEM_SUCCEED,
                            payload: response.data
                        });

                    }
                    else {
                        toast.error(response.data);
                        dispatch({
                            type: RESELLER_SET_ITEM_FAILED,
                            payload: response.data
                        });
                    }

                    return response;
                })
            )
        })
}

export function getAllPlanItems() {
    const urlStr = `${ACCOUNT_ROOT}item?type=1`;
    let paramObj = {
        url: urlStr,
        criteria: null,
        logText: 'Get all plan items Url ',
        sccessType: USER_GET_PLAN_ITEMS_SUCCESS,
        failedType: USER_GET_PLAN_ITEMS_FAILED,
        errorMessage: 'Failed to get all plan item.'
    };

    return (axiosGet(paramObj));
}

export function getOneCompanyByCode(code) {
    const criteria = { code: code }
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`,
        criteria: criteria,
        logText: 'Get One company Url ',
        sccessType: USER_GET_COMPANY_OBJ_SUCCESS,
        failedType: USER_GET_COMPANY_OBJ_FAILED,
        errorMessage: 'Failed to get One company.'
    };

    return (axiosGet(paramObj));
}
export function createReseller(resellerObj) {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {
        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_RESELLER}`;
    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: resellerObj
    });
    return ((dispatch) => {
        return (
            request.then((response) => {
                if (response.status === 200) {
                    dispatch(
                        {
                            type: USER_UPDATE_RESELLER_OBJ_SUCCESS,
                            message: response.data.message,
                        }
                    );

                    if (response.data.message === "success") {

                        toast.success(response.data.message);
                    }
                    else {
                        toast.error(response.data.message);
                    }

                    dispatch(getAllResellers({ resellerCode: resellerObj.resellerCode }));


                } else {
                    dispatch(
                        {
                            type: USER_UPDATE_RESELLER_OBJ_FAILED,
                            message: response.data.message,
                        }
                    );
                    toast.error(response.data);
                }
                return response.data;
            }
            ))
    }
    );
}
export function disableReseller(resellerObj) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_DISABLE_RESELLER}`,
        criteria: resellerObj,
        logText: 'disable Client ',
        errorMessage: 'Failed to disable Client .',
        successMessage: 'Successfully disable Client .',
        sccessType: USER_DISABLE_RESELLER_OBJ_SUCCESS,
        failedType: USER_DISABLE_RESELLER_OBJ_FAILED,
    };
    return (axiosPost(paramObj));
}
export function updateReseller(resellerObj) {
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_RESELLER}`;
    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: resellerObj,
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });
}


export function updateCompany(companyObj) {
    companyObj = { ...companyObj, industryId: 1 }
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: companyObj,
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_UPDATE_COMPANY_OBJ_SUCCESS,
                        payload: response.data.company,
                    }
                );
                // dispatch(getCompanyList());
                dispatch(getAllCompanies());
                toast.success(`Successfully ${companyObj.isnew ? 'added' : 'updated'} the company ${companyObj.companyName}`);
            } else {
                dispatch(
                    {
                        type: USER_UPDATE_COMPANY_OBJ_FAILED,
                        message: response.data.errorInfo,
                    }
                );
                // console.error("Add/Update Company :" + response.data.errorInfo);
                toast.error(`Failed to ${companyObj.isnew ? 'Add' : 'Update'} the company ${companyObj.companyName} - ${response.data.errorInfo}`);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_UPDATE_COMPANY_OBJ_FAILED,
                    message: error.message,
                }
            )
            console.error("Add/Update Company :" + error.message);
            toast.error("Error occurred when updating the the company " + companyObj.companyName);
        }
        )
    }
    );
}

export function deleteCompany(companyObj) {

    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    let queryStr = `?code=${companyObj.companyCode}`
    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}${queryStr}`;


    const request = axios({
        url: urlStr,
        method: 'delete',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        // data: companyObj,
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: USER_DELETE_COMPANY_OBJ_SUCCESS,
                        // payload: response.data.company,
                    }
                );
                // dispatch(getCompanyList());
                // dispatch(updateCompanyDeviceList(companyObj.companyCode,[]));
                // dispatch(deleteCompanyAssociatedItems(companyObj));
                dispatch(getAllCompanies());
                toast.success("Successfully Delete the company " + companyObj.companyName);
            } else {
                dispatch(
                    {
                        type: USER_DELETE_COMPANY_OBJ_FAILED,
                        message: 'Deleting User Company Failed',
                    }
                );
                toast.error("Failed to Delete the company " + companyObj.companyName);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_DELETE_COMPANY_OBJ_FAILED,
                    message: error.message,
                }
            )
            console.error(error.message)
            toast.error("Error occurred when deleting the the company " + companyObj.companyName);
        }
        )
    }
    );
}

export function getCountries() {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {
        return (dispatch) => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        };
    }

    let { userName, companyCode, role } = userObj;

    let userTokenObj = { userName, companyCode, role };

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COUNTRY}`;

    const request = axios({
        url: urlStr,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            uTk: base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return (dispatch) => {
        request
            .then((response) => {
                let Countries = response.data;
                let reContryList = [];
                var reProvMap = new Map();
                if (response.data) {
                    Object.entries(Countries).forEach(([key, value], index) => {
                        reContryList.push({
                            label: value.name,
                            value: key,
                        });
                        reProvMap.set(key, value.provinces);
                    });
                }

                dispatch({
                    type: USER_GET_COUNTRY_LIST_SUCCESS,
                    payload: reContryList,
                    provinces: reProvMap
                });
            })
            .catch((error) => {
                console.log(error)
                dispatch({
                    type: USER_GET_COUNTRY_LIST_SUCCESS,
                    payload: [],
                    provinces: new Map()
                });
            });
    };
}

export function getProvinces(countyKey) {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {
        return (dispatch) => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        };
    }

    let { userName, companyCode, role } = userObj;

    let userTokenObj = { userName, companyCode, role };

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COUNTRY}`;

    const request = axios({
        url: urlStr,
        method: "get",
        headers: {
            "Content-Type": "application/json",
            uTk: base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return (dispatch) => {
        return (
            request
                .then((response) => {
                    let Countries = response.data;
                    let reProvList = [];
                    if (Countries) {
                        let selectedCountry = Countries[countyKey];
                        if (selectedCountry && selectedCountry.provinces) {
                            selectedCountry.provinces.forEach((element, index) => {
                                reProvList.push({
                                    label: element.name,
                                    value: element.abbrev,
                                });
                            });
                        }
                    }

                    dispatch({
                        type: USER_GET_PROVINCE_LIST_SUCCESS,
                        payload: reProvList,
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: USER_GET_COUNTRY_LIST_SUCCESS,
                        payload: [],
                    });
                })
        )

    };
}


export function getRoles() {

    let paramObj = {
        url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_ROLES}`,
        criteria: null,
        logText: 'Get Roles Url ',
        sccessType: USER_GET_ROLES_SUCCESS,
        failedType: USER_GET_ROLES_FAILED,
        errorMessage: 'Failed to get User Roles.'
    };

    return (axiosGet(paramObj));
}

export function getAccounts(companyCode) {

    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        url: `${ACCOUNT_ROOT}accountbycompany?id=${companyCode}`,
        criteria: null,
        logText: 'Get Accounts Url ',
        sccessType: USER_GET_ACCOUNT_LIST_SUCCESS,
        failedType: USER_GET_ACCOUNT_LIST_FAILED,
        errorMessage: 'Failed to get Accounts.'
    };

    return (axiosGet(paramObj));
}

export function getCards(id) {

    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        url: `${ACCOUNT_ROOT}card?id=` + id,
        criteria: null,
        logText: 'Get cards Url ',
        sccessType: GET_ACCOUNT_CARDS_SUCCESS,
        failedType: GET_ACCOUNT_CARDS_FAILED,
        errorMessage: 'Failed to get cards.'
    };

    return (axiosGet(paramObj));
}

export function createCard(cardObj, companyCode) {
    console.log("debug createCard cardObj " + cardObj.accId)
    console.log("debug createCard companyCode ", companyCode)
    return dispatch => axios({
        url: `${ACCOUNT_ROOT}card`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        data: cardObj
    }).then(res => {
        if (res.status === 200) {
            dispatch(getCards(cardObj.accId));
            dispatch(getAccounts(companyCode));
        }

        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })

    // const urlStr = 'http://192.168.2.58:8080/card';
    //
    //

    // const request = axios({
    //     url: urlStr,
    //     method: 'post',
    //     // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
    //     data: cardObj,
    // });

    // return ( (dispatch) => {
    //     request.then( (response) => {
    //             if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE){
    //                 dispatch(
    //                     {
    //                         type: CARD_ADD_SUCCESS,
    //                         payload: response.data,

    //                     }
    //                 );
    //                 let cardObje = response.data;
    //                 if (reLoad) {
    //                     dispatch(getAccounts());
    //                 }

    //                 toast.success("Successfully Added credit card " + cardObje.pan);
    //             }else{
    //                 dispatch(
    //                     {
    //                         type: CARD_ADD_FAILED,
    //                         message: 'Adding Card Failed',

    //                     }
    //                 )


    //             }

    //         }
    //     ).catch( (error) => {
    //             dispatch(
    //                 {
    //                     type: CARD_ADD_FAILED,
    //                     message: error.message,

    //                 }
    //             )
    //             console.error("debug add card error: " + error.message);
    //           //  toast.error("Error occurred when Adding card " + userObj.pan + ": " + error.message);
    //         }
    //     )
    // }
    // );
}

export const addToAccounts = (payload) => dispatch => dispatch({ type: ACCOUNT_ADD_ACCOUNT, payload })

export function postAccount(data) {
    console.log(data)
    return dispatch => axios({
        url: `${ACCOUNT_ROOT}account`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        data: data
    }).then(res => {
        if (res.status === 200 && res.data && res.data.account) {
            dispatch(getAccounts(res.data.account.companyCode));
        }

        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })
}

export function deleteAccount(data) {

    return dispatch => axios({
        url: ACCOUNT_ROOT + 'account?id=' + data.id,
        headers: {
            'Content-Type': 'application/json',
            //  "Access-Control-Allow-Origin": "*",
            //     'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
            //     "Access-Control-Allow-Headers": "X-Requested-With, Content-Type",
        },
        method: 'DELETE',
    }).then(res => {
        if (res.status === 200) {
            dispatch(getAccounts(data.companyCode));

            toast.success('Successfully delete the account');
        } else {
            console.error('debug delete account error');
            toast.error('error occurred when delete the payment');
        }
        return res;
    }).catch(err => {
        console.error(err);
        toast.error('error occurred when delete the payment');
        return err.response;
    })
}

export function postOrder(data) {
    console.log("debug postOrder  " + data)
    return dispatch => axios({
        // url: ACCOUNT_ROOT + 'order',
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_SERVICE}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        data: data
    }).then(res => {
        if (res.status === 200) {
            // dispatch(getAccounts());
        }
        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })
}


// WEB_SERVICE_CHANGE_COMPANY_DEVICETYPE,
// WEB_SERVICE_CHANGE_COMPANY_ASSETCLASS,
export function updateCompanyDeviceList(companycode, companyDeviceListArr) {

    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_CHANGE_COMPANY_DEVICETYPE}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: companyDeviceListArr,
        headers: {
            'Content-Type': 'application/json',
            'companycode': companycode,
        },

    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
                // dispatch(
                //     {
                //         type: USER_UPDATE_COMPANY_OBJ_SUCCESS,
                //         payload: response.data.company,
                //     }
                // );
                dispatch(getUserCompanyDeviceTypes());
                toast.success("Successfully Updated the company device list ");
            } else {
                // dispatch(
                //     {
                //         type: USER_UPDATE_COMPANY_OBJ_FAILED,
                //         message: response.data.errorInfo,
                //     }
                // );
                console.error("Add/Update Company : error ");
                toast.error("Failed to Update the company device list ");
            }

        }
        ).catch((error) => {
            // dispatch(
            //     {
            //         type: USER_UPDATE_COMPANY_OBJ_FAILED,
            //         message: error.message,
            //     }
            // )
            console.error("Add/Update Company :" + error.message);
            toast.error("Error occurred when updating the the company device list ");
        }
        )
    }
    );
}

export function updateCompanyAssetClass(companycode, companyAssetClassArr) {

    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_CHANGE_COMPANY_ASSETCLASS}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        data: companyAssetClassArr,
        headers: {
            'Content-Type': 'application/json',
            'companycode': companycode,
        },

    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(getUserCompanyAssetClass());
                toast.success("Successfully Updated the company asset class ");
            } else {
                console.error("Add/Update Company asset class: error");
                toast.error("Failed to Update the company asset class ");
            }

        }
        ).catch((error) => {
            console.error("Add/Update Company asset class:" + error.message);
            toast.error("Error occurred when updating the the company asset class ");
        }
        )
    }
    );
}

export function deleteCompanyAssociatedItems(companyObj) {

    let queryStr = `?companycode=${companyObj.companyCode}`
    const urlStr = `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_COMPANY}${queryStr}`;


    const request = axios({
        url: urlStr,
        method: 'delete',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        // data: companyObj,
        headers: {
            'Content-Type': 'application/json',
            // 'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(getUserCompanyDeviceTypes());
                toast.success("Successfully Delete the company associated items " + companyObj.companyName);
            } else {
                toast.error("Failed to Delete the company " + companyObj.companyName);
            }

        }
        ).catch((error) => {
            console.error(error.message)
            toast.error("Error occurred when deleting the the company " + companyObj.companyName);
        }
        )
    }
    );
}

export function getCustomerPurchaseDevices(upcObj) {
    // const startType = paramObj.startType;

    //   const {email, phone, subject, message} = val;
    // let title = 'IOT support-' + phone + ' - ' + subject;

    // const data = {
    //     "to": SUPPORT_EMAIL,
    //     "content": message,
    //     "subject":title,
    //     "msgfrom":email
    // }

    let paramObj = {
        // url: `${ACCOUNT_ROOT}${WEB_SERVICE_PAYMENT_ITEM}`,
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_DEVICE}${WEB_SERVICE_PAYMENT_UPC_DEVICE}`,
        //dataObject: templateObj,
        criteria: upcObj,
        logText: 'Get customer purchase devices ',
        errorMessage: 'Failed to get customer purchase devices.',
        successMessage: 'Successfully get customer purchase devices.',
        sccessType: COMPANY_GET_PAYMENT_ITEM_SUCCESS,
        failedType: COMPANY_GET_PAYMENT_ITEM_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        responseKeyGetter: (response) => (response.data.error),
        successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
}

export function generatePurchaseOrder(orderObj) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_GENERATE_PURCHASE_ORDER}`,
        criteria: orderObj,
        logText: 'Generate purchase order',
        errorMessage: 'Failed to generate purchase order',
        successMessage: 'Successfully generate purchase order',
        sccessType: GENERATE_PURCHASE_ORDER_SUCCESS,
        failedType: GENERATE_PURCHASE_ORDER_FAILED,
        // successCallback: () => { return(getNotificationTemplate())}, 
        // errorCallback: (msg) => {toast.error(msg)}
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };
    console.log
    return (axiosPost(paramObj));
}

export function customerCheckout(orderObj, successCallback = null) {




    let paramObj = {
        //   url: `${ACCOUNT_ROOT}${WEB_SERVICE_PAYMENT_CHECKOUT}`,
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_DEVICE}${WEB_SERVICE_PAYMENT_CHECHOUT_DEVICE}`,
        //dataObject: templateObj,
        criteria: orderObj,
        logText: 'Customer purchase checkout ',
        errorMessage: 'Failed to checkout.',
        successMessage: 'Successfully checkout.',
        sccessType: COMPANY_PAYMENT_CHECKOUT_SUCCESS,
        failedType: COMPANY_PAYMENT_CHECKOUT_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        successCallback: successCallback,
        // errorCallback: (msg) => {toast.error(msg)}
        responseKeyGetter: (response) => (response.data.error),
        successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
}

export function customerPayOrder(payObj) {

    log.debug('payObj', payObj);

    let paramObj = {
        // url: `${ACCOUNT_ROOT}${WEB_SERVICE_PAYMENT_PAY}`,
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_DEVICE}${WEB_SERVICE_PAYMENT_PAY_DEVICE}${payObj.id}`,
        //dataObject: templateObj,
        // criteria: payObj,
        logText: 'Customer pay order ',
        errorMessage: 'Failed to pay order.',
        successMessage: 'Successfully pay order.',
        // sccessType: COMPANY_PAYMENT_CHECKOUT_SUCCESS,
        // failedType: COMPANY_PAYMENT_CHECKOUT_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
    // return (axiosGet(paramObj));
}

export function customerReleaseOrder(payObj) {

    log.debug('payObj', payObj);

    let paramObj = {
        // url: `${ACCOUNT_ROOT}${WEB_SERVICE_PAYMENT_PAY}`,
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_DEVICE}${WEB_SERVICE_PAYMENT_RELEASE_DEVICE}${payObj.id}`,
        //dataObject: templateObj,
        // criteria: payObj,
        logText: 'Customer pay order ',
        errorMessage: 'Failed to pay order.',
        successMessage: 'Successfully pay order.',
        // sccessType: COMPANY_PAYMENT_CHECKOUT_SUCCESS,
        // failedType: COMPANY_PAYMENT_CHECKOUT_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
    // return (axiosGet(paramObj));
}
export function refundTransaction(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_REFUND_INVOICE}`,
        criteria: obj,
        logText: 'refund transaction ',
        errorMessage: 'Failed to refund transaction.',
        successMessage: 'Successfully refund transaction.',
        sccessType: COMPANY_TRANSACTION_REFUND_SUCCESS,
        failedType: COMPANY_TRANSACTION_REFUND_FAILED,
    };
    return (axiosPost(paramObj));
}
export function loadTransactionHistory(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_TRANSACTION_HISTORY}`,
        criteria: obj,
        logText: 'Get transaction history ',
        errorMessage: 'Failed to get transaction history.',
        successMessage: 'Successfully get transaction history.',
        sccessType: COMPANY_TRANSACTION_HISTORY_SUCCESS,
        failedType: COMPANY_TRANSACTION_HISTORY_FAILED,
    };
    return (axiosPost(paramObj));
}

export function getCustomerPaymentHistory(accObj) {
    let paramObj = {
        // url: `${ACCOUNT_ROOT}${WEB_SERVICE_PAYMENT_HISTORY}`,
        // url: `http://192.168.161.9:8080/paymentNew/${WEB_SERVICE_PAYMENT_HISTORY}`,
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_HISTORY}`,
        //dataObject: templateObj,
        criteria: accObj,
        logText: 'Customer get payment history ',
        errorMessage: 'Failed to get payment history.',
        successMessage: 'Successfully get payment history.',
        sccessType: COMPANY_PAYMENT_HISTORY_SUCCESS,
        failedType: COMPANY_PAYMENT_HISTORY_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
}

export function getCustomerInvoiceHistory(accObj) {


    let paramObj = {
        // url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_PAYMENT_ITEM}`,
        // url: `http://192.168.161.9:8080/paymentNew/${WEB_SERVICE_PAYMENT_INVOICE}`,
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_INVOICE_LIST}`,        //dataObject: templateObj,
        criteria: accObj,
        logText: 'Customer get invoice history ',
        errorMessage: 'Failed to get invoice history.',
        successMessage: 'Successfully get invoice history.',
        sccessType: COMPANY_PAYMENT_INVOICE_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
}

export function clearCustomerInvoiceHistory() {
    return (dispatch => {
        dispatch({
            type: COMPANY_PAYMENT_INVOICE_CLEAR,
        })
    })
}

export function getUsageReport(filterObj) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_USAGE_REPORT}`,
        criteria: filterObj,
        errorMessage: 'Failed to grab usage report details.',
        successMessage: 'Successfully grabbed usage report details.',
        sccessType: COMPANY_PAYMENT_USAGE_REPORT_SUCCESS,
        failedType: COMPANY_PAYMENT_USAGE_REPORT_FAILED,
    }
    return (axiosPost(paramObj));
}

export function getCustomerInvoiceDetails(invId) {


    let paramObj = {
        // url: `http://192.168.161.9:8080/paymentNew/${WEB_SERVICE_PAYMENT_INVOICE_DETAILS}`,
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_INVOICE_DETAILS}`,
        //dataObject: templateObj,
        // criteria: {invId: invId},
        criteria: { objId: invId },
        logText: 'Customer get invoice details ',
        errorMessage: 'Failed to get invoice details.',
        successMessage: 'Successfully get invoice details.',
        sccessType: COMPANY_PAYMENT_INVOICE_DETAILS_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_DETAILS_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '100',
    };

    return (axiosPost(paramObj));
}

export function getGeneralLedgerCodesByCompanyCode(companyCode) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_GET_GL_CODES_BY_COMPANY}`,
        criteria: { companyCode },
        logText: `Fetching general ledger codes by company code ${companyCode}.`,
        errorMessage: `Failed to fetch general ledger codes.`,
        successMessage: `Successfully fetched general ledger codes.`,
        sccessType: COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODES_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosGet(paramObj));
}

export function addGeneralLedgerCodeByCompanyCode(generalLedgerCodePayload) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_ADD_GL_CODE_BY_COMPANY}`,
        criteria: generalLedgerCodePayload,
        logText: `Adding general ledger code "${generalLedgerCodePayload.code}" for the ${generalLedgerCodePayload.companyCode} company.`,
        errorMessage: `Failed to add general ledger code.`,
        successMessage: `Successfully added general ledger code.`,
        sccessType: COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPost(paramObj));
}

export function modifyGeneralLedgerCodeByCompanyCode(generalLedgerCodePayload) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_MODIFY_GL_CODE_BY_COMPANY}`,
        criteria: generalLedgerCodePayload,
        logText: `Updating general ledger code with id "${generalLedgerCodePayload.id}" to "${generalLedgerCodePayload.code}" for the ${generalLedgerCodePayload.companyCode} company.`,
        errorMessage: `Failed to update general ledger code.`,
        successMessage: `Successfully updated general ledger code.`,
        sccessType: COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPut(paramObj));
}

export function getGeneralLedgerCodesReasonsByCompanyCode(companyCode) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_GET_GL_CODE_REASONS_BY_COMPANY}`,
        criteria: { companyCode },
        logText: `Fetching general ledger code reasons by company code ${companyCode}.`,
        errorMessage: `Failed to fetch general ledger code reasons.`,
        successMessage: `Successfully fetched general ledger code reasons.`,
        sccessType: COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_GET_GENERAL_LEDGER_CODE_REASONS_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosGet(paramObj));
}

export function addGeneralLedgerCodeReasonByCompanyCode(generalLedgerCodeReasonPayload) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_ADD_GL_CODE_REASON_BY_COMPANY}`,
        criteria: generalLedgerCodeReasonPayload,
        logText: `Adding general ledger code reason "${generalLedgerCodeReasonPayload.reason}" for the ${generalLedgerCodeReasonPayload.companyCode} company.`,
        errorMessage: `Failed to add general ledger code reason.`,
        successMessage: `Successfully added general ledger code reason.`,
        sccessType: COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPost(paramObj));
}

export function modifyGeneralLedgerCodeReasonByCompanyCode(generalLedgerCodeReasonPayload) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_MODIFY_GL_CODE_REASON_BY_COMPANY}`,
        criteria: generalLedgerCodeReasonPayload,
        logText: `Updating general ledger code reason "${generalLedgerCodeReasonPayload.reasonCode}" for the ${generalLedgerCodeReasonPayload.companyCode} company.`,
        errorMessage: `Failed to update general ledger code reason.`,
        successMessage: `Successfully updated general ledger code reason.`,
        sccessType: COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPut(paramObj));
}

export function getAdjustmentsByInvoiceByCompanyCode(invoiceAdjustmentPayload) {

    const { invoiceId, companyCode } = invoiceAdjustmentPayload

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY}`,
        criteria: { invoiceId, companyCode },
        logText: `Saving adjustments for invoice "${invoiceAdjustmentPayload.invoiceId}" for the ${invoiceAdjustmentPayload.companyCode} company.`,
        errorMessage: `Failed to fetch adjustments for invoice.`,
        successMessage: `Successfully fetched adjustments for invoice.`,
        sccessType: COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_GET_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosGet(paramObj));
}

export function updateCustomerInvoiceMiscellaneousDetailsJournal(invoiceMiscellaneousItemPayload) {

    let updateOp = invoiceMiscellaneousItemPayload.miscellaneous.id === 0 ? 'created' : 'updated'

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY}`,
        criteria: invoiceMiscellaneousItemPayload,
        logText: `Adding general ledger adjustment item with reason id "${invoiceMiscellaneousItemPayload.miscellaneous.reasonId}" for invoice with Id "${invoiceMiscellaneousItemPayload.invoiceId}".`,
        errorMessage: `Failed to update general ledger adjustment item.`,
        successMessage: `Successfully ${updateOp} general ledger adjustment item.`,
        sccessType: COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPost(paramObj));
}


export function cancelInvoiceMiscellaneousDetail(invoiceMiscellaneousItemPayload) {

    const { invoiceId, companyCode, miscellaneousInvoiceItemSelected } = invoiceMiscellaneousItemPayload
    const { id: miscellaneousInvoiceItemId } = miscellaneousInvoiceItemSelected

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY}`,
        criteria: { invoiceId, companyCode, miscellaneousInvoiceItemId },
        logText: `Canceling general ledger adjustment item with id "${miscellaneousInvoiceItemId}" for invoice with Id "${invoiceId}".`,
        errorMessage: `Failed to cancel general ledger adjustment item.`,
        successMessage: `Successfully canceled general ledger adjustment item.`,
        sccessType: COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_CANCEL_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosDelete(paramObj));
}

export function updateCustomerInvoiceWithAdjustments(invId) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_INVOICE_ADJUSTMENT}`,
        criteria: { objId: invId },
        logText: `Applying invoice adjustments for invoice with id "${invId}".`,
        errorMessage: `Failed to apply adjustments for invoice.`,
        successMessage: `Successfully applied adjustments for invoice.`,
        sccessType: COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_MAKE_ADJUSTMENT_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPost(paramObj));
}

export function getCustomerInvoiceApprovalDetails(invId) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_INVOICE_WITH_APPROVAL_DETAILS}`,
        criteria: { objId: invId },
        logText: `Fetching details for invoice with id "${invId}".`,
        errorMessage: `Failed to fetch details for invoice.`,
        successMessage: `Successfully fetched details for invoice.`,
        sccessType: COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_APPROVAL_DETAILS_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPost(paramObj));
}

export function confirmCustomerInvoiceApproval(invId) {

    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_INVOICE_CONFIRM_APPROVAL}`,
        criteria: { objId: invId },
        logText: `Confirming approval for invoice with id "${invId}".`,
        errorMessage: `Failed to confirm approval for invoice.`,
        successMessage: `Successfully confirmed approval for invoice.`,
        sccessType: COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS,
        failedType: COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED,
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: '200',
    };

    return (axiosPut(paramObj));
}

export function payInvoice(param) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_PAY_INVOICE}`,

        criteria: param,
        logText: 'adjust invoice details ',
        errorMessage: 'Failed to adjust invoice details.',
        successMessage: 'Successfully adjust invoice details.',
        sccessType: COMPANY_PAYMENT_ADJUST_INVOICE_SUCCESS,
        failedType: COMPANY_PAYMENT_ADJUST_INVOICE_FAILED,
    };

    return (axiosPost(paramObj));
}

export function createCustomerAccount(monerisStr) {
    //
    let paramObj = {
        // url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_PAYMENT_ITEM}`,
        // url: `http://192.168.161.9:8080/paymentNew/${WEB_SERVICE_PAYMENT_INVOICE}`,
        url: `https://waiviot.itsp-inc.com:8443/paymentNew/${WEB_SERVICE_PAYMENT_CARD_MGMT}`,
        //dataObject: templateObj,
        criteria: monerisStr,
        logText: 'Moneris Card Account Call back',
        errorMessage: 'Failed to create an account from moneris.',
        successMessage: 'Successfully created an account from moneris.',
        startType: COMPANY_MONERIS_ACCOUNT_START,
        sccessType: COMPANY_MONERIS_ACCOUNT_SUCCESS,
        failedType: COMPANY_MONERIS_ACCOUNT_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        responseKeyGetter: (response) => (response.data.statusCode),
        successContent: 0,
        responseMsgGetter: (response) => (response.data.message),
    };

    return (axiosPost(paramObj));
}

export function setCurrentCompanycode(code) {

    return (dispatch => {
        dispatch({
            type: COMPANY_SET_CURRENT_COMPANY_CODE,
            payload: code
        });
    });
}

export function getCustomerByCompanycode(companycode, callback = null) {
    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_CUSTOMER_BY_COMPANY}`,
        criteria: { code: companycode },
        logText: 'Get Customer by company code Url ',
        sccessType: CUSTOMER_COMPANY_GET_SUCCESS,
        failedType: CUSTOMER_COMPANY_GET_FAILED,
        errorMessage: 'Failed to get Customer from company code.',
        callback: callback,
    };

    return (axiosGet(paramObj));
}

export function getorderbycustomer(cusid, status = 'active', type = '1') {
    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        // url: `${ACCOUNT_ROOT}${WEB_SERVICE_GET_ORDERS}`,
        // criteria: {code: cusid, status: status, type: type},
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}`,
        criteria: { companycode: cusid, status: '1', type: type },
        logText: 'Get orders by customer Url ',
        sccessType: CUSTOMER_GET_ORDERS_SUCCESS,
        failedType: CUSTOMER_GET_ORDERS_FAILED,
        errorMessage: 'Failed to get orders from customer.',
        // callback: callback,
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };

    return (axiosGet(paramObj));
}

export function updateOrder(orderObj, successcallback = null, errorcallback = null) {
    // WEB_SERVICE_UPDATE_ORDER
    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_UPDATE_ORDER}`,
        criteria: orderObj,
        logText: 'Update orders Url ',
        sccessType: CUSTOMER_UPDATE_ORDERS_SUCCESS,
        failedType: CUSTOMER_UPDATE_ORDERS_FAILED,
        errorMessage: 'Failed to update orders.',
        plantSuccessCallback: successcallback,
        plantErrorCallback: errorcallback,
        responseKeyGetter: (response) => (response.data.error),
        successContent: "SUCCESS",
    };
    return (axiosPost(paramObj));
}

export function updateService(orderId, orderObj, successcallback = null, errorcallback = null) {
    let paramObj = {
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_SERVICE}${orderId}`,
        criteria: orderObj,
        logText: 'Update orders Url ',
        sccessType: CUSTOMER_UPDATE_ORDERS_SUCCESS,
        failedType: CUSTOMER_UPDATE_ORDERS_FAILED,
        errorMessage: 'Failed to update orders.',
        plantSuccessCallback: successcallback,
        plantErrorCallback: errorcallback,
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    }
    return (axiosPost(paramObj));
}

export function deleteService(orderId, successcallback = null, errorcallback = null) {
    let paramObj = {
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_SERVICE}${orderId}`,
        //   criteria: orderObj,
        logText: 'Delete order Url ',
        //   sccessType: CUSTOMER_UPDATE_ORDERS_SUCCESS,
        //   failedType: CUSTOMER_UPDATE_ORDERS_FAILED,
        errorMessage: 'Failed to delete the order.',
        plantSuccessCallback: successcallback,
        plantErrorCallback: errorcallback,
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    }
    return (axiosDelete(paramObj));
}

export function getAdjustmentByCompanycode(companycode, callback = null) {
    let paramObj = {
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER_ADJUSTMENT}`,
        criteria: { companycode: companycode },
        logText: 'Get Adjustment by company code Url ',
        sccessType: ORDER_ADJUSTMENT_GET_SUCCESS,
        failedType: ORDER_ADJUSTMENT_GET_FAILED,
        errorMessage: 'Failed to get Adjustment by company code.',
        callback: callback,
    }
    return (axiosGet(paramObj));
}

export function clearAdjustment() {
    return ((dispatch) => {
        dispatch({
            type: ORDER_ADJUSTMENT_CLEAR,
        })
    })
}

export function releaseOrder(orderid) {

    log.debug('payObj', orderid);

    let paramObj = {
        // url: `${ACCOUNT_ROOT}${WEB_SERVICE_PAYMENT_PAY}`,
        url: `${ACCOUNT_ROOT}${WEB_SERVICE_ORDER}${WEB_SERVICE_PAYMENT_DEVICE}${WEB_SERVICE_PAYMENT_RELEASE_DEVICE}${orderid}`,
        //dataObject: templateObj,
        // criteria: payObj,
        logText: 'Customer release order ',
        errorMessage: 'Failed to release order.',
        successMessage: 'Successfully release order.',
        // sccessType: COMPANY_PAYMENT_CHECKOUT_SUCCESS,
        // failedType: COMPANY_PAYMENT_CHECKOUT_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
    // return (axiosGet(paramObj));
}

export function getExportActivatedSimDashboardData(requestObj, callback = null) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM_FOR_DOWNLOAD_BY_CVS}`,
        criteria: requestObj,
        logText: 'Get activated sim list ',

        errorMessage: 'Failed to get reseller extension from reseller id.',
        callback: callback,
    };

    return axiosPost(paramObj);
}

export function getActivatedSimDashboard(requestObj, callback = null) {
    if (requestObj['page'] !== undefined && requestObj['page'] >= 0) {
        requestObj['page'] = requestObj['page'] - 1 < 0 ? 0 : requestObj['page'] - 1;
    }
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SIFOX_GET_ACTIVATED_SIM}`,
        criteria: requestObj,
        logText: 'Get activated sim list ',
        startType: GET_ACTIVATED_SIM_DASHBOARD_START,
        sccessType: GET_ACTIVATED_SIM_DASHBOARD_SUCCESS,
        failedType: GET_ACTIVATED_SIM_DASHBOARD_FAILED,
        errorMessage: 'Failed to get reseller extension from reseller id.',
        callback: callback,
    };

    return (axiosPost(paramObj));
}

/* export function getSimUsage(requestObj, callback = null){
    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SIFOX_GET_SIM_USAGE}`,
        criteria: {resellerId: resellerId},
        logText: 'Get sim usage ',
        sccessType: GET_SIM_USAGE_SUCCESS,
        failedType: GET_SIM_USAGE_FAILED,
        errorMessage: 'Failed to get reseller extension from reseller id.',
        callback: callback,
    };

    return (axiosGet(paramObj));
}
 */
export function getSimUsage(data) {
    console.log("debug postOrder  " + data)
    return dispatch => axios({
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SIFOX_GET_SIM_USAGE}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        data: data
    }).then(res => {

        if (res.status === 200) {
            // dispatch(getAccounts());
        }
        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })
}
export function getPlanUsage(data) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_PLAN_USAGE}`,
        criteria: data,
        logText: 'Get usage ',
        startType: GET_PLAN_USAGE_DATA_START,
        sccessType: GET_PLAN_USAGE_DATA_SUCCESS,
        failedType: GET_PLAN_USAGE_DATA_FAILED,
        errorMessage: 'Failed to get Usage',
    };

    return (axiosPost(paramObj));
}

export function getIdentifierUsage(data) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_IDENTIFIER_USAGE}`,
        criteria: data,
        logText: 'Get identifier usage ',
        startType: GET_IDENTIFIER_USAGE_DATA_START,
        sccessType: GET_IDENTIFIER_USAGE_DATA_SUCCESS,
        failedType: GET_IDENTIFIER_USAGE_DATA_FAILED,
        errorMessage: 'Failed to get identifier Usage',

    };

    return (axiosPost(paramObj));
}

//get total current month usage
export function getCurrentTotalUsage(data) {
    console.log("debug postOrder  " + data)
    return dispatch => axios({
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SIFOX_GET_CURRENT_TOTAL_USAGE}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        data: data
    }).then(res => {

        if (res.status === 200) {
            // dispatch(getAccounts());
        }
        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })
}

//get usages for client
export function getClientUsage(data) {
    console.log("debug postOrder  " + data)
    return dispatch => axios({
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SIFOX_GET_CLIENT_USAGE_STATS}`,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'post',
        data: data
    }).then(res => {
        if (res.status === 200) {
            // dispatch(getAccounts());
        }
        return res;
    }).catch(err => {
        console.error(err)
        return err.response;
    })
}

export function getUsageForReseller(requestObj, callback = null) {
    let paramObj = {
        // url: 'http://192.168.2.58:8080/account?id=1',
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_SIFOX_GET_USAGE_FOR_RESELLER}`,
        criteria: { resellerId: resellerId },
        logText: 'Get usage for reseller ',
        sccessType: GET_USAGE_FOR_RESELLER_SUCCESS,
        failedType: GET_USAGE_FOR_RESELLER_FAILED,
        errorMessage: 'Failed to get reseller extension from reseller id.',
        callback: callback,
    };

    return (axiosGet(paramObj));
}

export function getClients(requestObj) {

    let companyCode = "";
    let resellerCode = "";
    let department = "";
    let businessUnit = "";

    if (sessionStorage.getItem('companyCode')) {
        companyCode = sessionStorage.getItem('companyCode') === "null" ? null : sessionStorage.getItem('companyCode');
    }
    if (sessionStorage.getItem('resellerCode')) {
        resellerCode = sessionStorage.getItem('resellerCode') == null ? "" : sessionStorage.getItem('resellerCode');
    }

    let obj = {
        companyCode: companyCode,
        resellerCode: resellerCode,
        department: department,
        businessUnit: businessUnit,
    };


    console.log('requestObj')

    if (requestObj) {
        obj = requestObj;
    }

    let urlStr = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_CLIENT_GET}`;




    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json'
        },
        // params: {
        //     token: sessionStorage.getItem('authToken'),
        //     userName: criteria.userName || undefined
        // },
        data: obj,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_CLIENT_SUCCESS_CODE) {
                dispatch(
                    {
                        type: CLIENT_GET_SUCCESS,
                        payload: response.data.userDetails,

                    }
                );
            } else {
                let message = 'Getting Clients Failed';
                console.error(message);
                dispatch(
                    {
                        type: CLIENT_GET_FAILED,
                        message: message,

                    }
                )
            }

        }
        ).catch((error) => {
            console.error(error.message);
            dispatch(
                {
                    type: CLIENT_GET_FAILED,
                    message: error.message,

                }
            )
        }
        )
    }
    );


}

export function getCarrierList(requestObj, callback = null) {
    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_CARRIER}`,
        criteria: requestObj,
        logText: 'Get carrier',
        sccessType: GET_CARRIER_LIST_SUCCESS,
        failedType: GET_CARRIER_LIST_FAILED,
        errorMessage: 'Failed to get carrier.',
        callback: callback
    }
    return (axiosGet(paramObj));
}
export function getResellerCode(companyCode) {
    let queryStr = "";
    if (companyCode != undefined && companyCode != null && companyCode != "") {
        queryStr = `?companyCode=${companyCode}`;
    }
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_RESELLER_CODE}${queryStr}`,
        criteria: null,
        logText: 'Get Roles Url ',
        sccessType: GET_RESELLER_CODE_SUCCESS,
        failedType: GET_RESELLER_CODE_FAILED,
        errorMessage: 'Failed to get User Roles.'
    };

    return (axiosGet(paramObj));
}


export function getLobs(countryId) {
    let queryStr = "";
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_LOBS}${queryStr}`,
        criteria: { countryId: countryId },
        logText: 'Get Roles Url ',
        sccessType: "GET_RESELLER_CODE_SUCCESS",
        failedType: "GET_RESELLER_CODE_FAILED",
        errorMessage: 'Failed to get User Roles.'
    };

    return (axiosPost(paramObj));
}


export function getOperationCountries() {
    let queryStr = "";
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_OPERATIONCOUNTRY}${queryStr}`,
        criteria: null,
        logText: 'Get Roles Url ',
        sccessType: "GET_RESELLER_CODE_SUCCESS",
        failedType: "GET_RESELLER_CODE_FAILED",
        errorMessage: 'Failed to get User Roles.'
    };

    return (axiosPost(paramObj));
}

export function getDepartments(countryId, lobId) {
    let queryStr = "";
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_DIVISIONS}${queryStr}`,
        criteria: { countryId: countryId, lobId: lobId },
        logText: 'Get Roles Url ',
        sccessType: "GET_RESELLER_CODE_SUCCESS",
        failedType: "GET_RESELLER_CODE_FAILED",
        errorMessage: 'Failed to get User Roles.'
    };

    return (axiosPost(paramObj));
}

export function updateConsumer(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_UPDATE_CONSUMER}`,
        criteria: obj,
        logText: 'Update Consumer entity ',
        sccessType: CONSUMER_UPDATE_SUCCESS,
        failedType: CONSUMER_UPDATE_FAILED,
        errorMessage: 'Failed to update consumer.'
    };
    return (axiosPost(paramObj));
}

export function getConsumerPlanUsage(requestObj) {

    let urlStr = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_CONSUMER_GET_PLAN_USAGE}`;

    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },

        data: requestObj,
    });

    return ((dispatch) => {
        return (
            request.then((response) => {
                if (response.status == 200) {
                    dispatch(
                        {
                            type: CONSUMER_GET_PLAN_USAGE_SUCCESS,
                            payload: response.data,

                        }
                    );
                } else {
                    dispatch(
                        {
                            type: CONSUMER_GET_PLAN_USAGE_FAILED,
                            payload: response.data

                        }
                    )
                }

            }
            ).catch((error) => {
                console.error(error.message);
                dispatch(
                    {
                        type: CONSUMER_GET_PLAN_USAGE_FAILED,
                        payload: []
                    }
                )
            }
            ))
    }
    );
}
export function getConsumers(requestObj) {

    let companyCode = "";
    let resellerCode = "";
    let department = "";
    let businessUnit = "";

    if (sessionStorage.getItem('companyCode')) {
        companyCode = sessionStorage.getItem('companyCode') === "null" ? null : sessionStorage.getItem('companyCode');
    }
    if (sessionStorage.getItem('resellerCode')) {
        resellerCode = sessionStorage.getItem('resellerCode') == null ? "" : sessionStorage.getItem('resellerCode');
    }

    let obj = {
        companyCode: companyCode,
        resellerCode: resellerCode,
        department: department,
        businessUnit: businessUnit,
    };

    if (requestObj) {
        obj = requestObj;
    }

    let urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_CONSUMER_GET}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },

        data: obj,
    });

    return ((dispatch) => {
        return (
            request.then((response) => {
                if (response.data.error === 'Success') {
                    dispatch(
                        {
                            type: CONSUMER_GET_SUCCESS,
                            payload: response.data.userDetails,

                        }
                    );
                } else {
                    let message = 'Getting Clients Failed';
                    console.error(message);
                    dispatch(
                        {
                            type: CONSUMER_GET_FAILED,
                            message: message,

                        }
                    )
                }

            }
            ).catch((error) => {
                console.error(error.message);
                dispatch(
                    {
                        type: CONSUMER_GET_FAILED,
                        message: error.message,

                    }
                )
            }
            ))
    }
    );
}

export function addConsumer(userObj, reLoad = false, reLoadType) {
    if (!userObj || userObj === null) {
        return dispatch => {
            dispatch({
                type: CONSUMER_ADD_FAILED,
                message: 'user input is not valid'
            })
        }
    }

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_CONSUMER_ADD}`;


    const request = axios({
        url: urlStr,
        method: 'post',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        data: userObj,
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {
                dispatch(
                    {
                        type: CONSUMER_ADD_SUCCESS,
                        payload: response.data.userDetails,

                    }
                );
                let userObje = response.data.userDetails;
                if (userObje.role.includes('ADMIN')) {

                    dispatch(setUserCompanyDevceType(userObj));
                }
                if (reLoad) {
                    if (reLoadType == "consumer") {
                        dispatch(getConsumers(
                            {
                                companyCode: userObj.companyCode,
                                resellerCode: userObj.resellerCode,
                                currentUser: userObj.currentUser,
                                Roles: userObj.role
                            }
                        ));
                    }
                    else if (reLoadType == "nonclient") {
                        dispatch(getNonClients());
                    }
                    else {

                        dispatch(getUsers());
                    }

                }
                toast.success("Successfully Added User " + userObj.userName);
            } else {
                dispatch(
                    {
                        type: CONSUMER_ADD_FAILED,
                        message: 'Adding User Failed',

                    }
                )
                console.error(response.data.error);
                toast.error("Failed To Add User " + userObj.userName + ": " + response.data.errorInfo);
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: CONSUMER_ADD_FAILED,
                    message: error.message,

                }
            )
            console.error(error.message);
            toast.error("Error occurred when Adding User " + userObj.userName + ": " + error.message);
        }
        )
    }
    );
}
export function getCarriers() {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    const urlStr = `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}${WEB_SERVICE_GET_CARRIER_LIST}?companyCode=${companyCode}`;
    // const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;



    const request = axios({
        url: urlStr,
        method: 'get',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.status === 200) {
                dispatch(
                    {
                        type: USER_GET_CARRIER_LIST_SUCCESS,
                        payload: response.data.carriers,
                    }
                );
            } else {
                dispatch(
                    {
                        type: USER_GET_CARRIER_LIST_FAILED,
                        message: 'Getting carrier list Failed',
                    }
                );
                console.error('Getting carrier list Failed');
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_GET_CARRIER_LIST_FAILED,
                    message: error.message,
                }
            );
            console.error(error.message);
        }
        )
    }
    );

}

//get all resellers
export function getResellerList() {
    let userObj = getCurrentUser();
    if (userObj === null || userObj.userName.length === 0) {


        return dispatch => {
            dispatch({
                type: USER_GET_FAILED,
                error: "Failed to get current user, Please re-login",
                message: "Failed to get current user, Please re-login",
            });
        }
    }

    let { userName, companyCode, role } = userObj;
    let userTokenObj = { userName, companyCode, role };

    const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_RESELLER}`;
    // const urlStr = `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_COMPANY}`;



    const request = axios({
        url: urlStr,
        method: 'get',
        // httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        headers: {
            'Content-Type': 'application/json',
            'uTk': base64Encode(JSON.stringify(userTokenObj)),
        },
    });

    return ((dispatch) => {
        request.then((response) => {
            if (response.status === 200) {
                dispatch(
                    {
                        type: USER_GET_RESELLER_LIST_SUCCESS,
                        payload: response.data,
                    }
                );
            } else {
                dispatch(
                    {
                        type: USER_GET_RESELLER_LIST_FAILED,
                        message: 'Getting company list Failed',
                    }
                );
                console.error('Getting company list Failed');
            }

        }
        ).catch((error) => {
            dispatch(
                {
                    type: USER_GET_RESELLER_LIST_FAILED,
                    message: error.message,
                }
            );
            console.error(error.message);
        }
        )
    }
    );

}

export function getBrandingDetails(companyCode) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_BRANDING}`,
        criteria: { companyCode: companyCode },
        logText: 'Get Branding Url ',
        sccessType: COMPANY_GET_BRANDING_SUCCESS,
        failedType: COMPANY_GET_BRANDING_FAILED,
        errorMessage: 'Failed to get Branding.'
    };
    return (axiosGet(paramObj));
}
export function setBrandingDetails(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_SET_BRANDING}`,
        criteria: obj,
        logText: 'Get Branding Url ',
        sccessType: COMPANY_GET_BRANDING_SUCCESS,
        failedType: COMPANY_GET_BRANDING_FAILED,
        errorMessage: 'Failed to get Branding.'
    };
    return (axiosPost(paramObj));
}

export function getAccountsByEntity(companyCode, resellerId, customerId) {
    let queryStr = "";
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_SIFOX_ORDER_CLIENT_GET_ACCOUNT_BY_ENTITY}`,
        criteria: { companyCode: companyCode, resellerId: resellerId, customerId: customerId },
        logText: 'Get accounts Url ',
        sccessType: USER_GET_ACCOUNT_LIST_SUCCESS,
        failedType: USER_GET_ACCOUNT_LIST_FAILED,
        errorMessage: 'Failed to get Accounts.'
    };

    return (axiosPost(paramObj));
}

export function clientCheckout(orderObj, successCallback = null) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_SIFOX_ORDER_CLIENT_CHECKOUT}`,
        criteria: orderObj,
        logText: 'client purchase checkout ',
        errorMessage: 'Failed to checkout.',
        successMessage: 'Successfully checkout.',
        sccessType: CLIENT_PAYMENT_CHECKOUT_SUCCESS,
        failedType: CLIENT_PAYMENT_CHECKOUT_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        successCallback: successCallback,
        // errorCallback: (msg) => {toast.error(msg)}
        responseKeyGetter: (response) => (response.data.error),
        successContent: "SUCCESS",
    };
    return (axiosPost(paramObj));
}



export function clientPurchaseOrder(payObj) {

    let paramObj = {
        // url: `${ACCOUNT_ROOT}${WEB_SERVICE_PAYMENT_PAY}`,
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENT_INVOICE_GENERATE}`,
        //dataObject: templateObj,
        criteria: payObj,
        logText: 'Client purchase order ',
        errorMessage: 'Failed to purchase order.',
        successMessage: 'Successfully purchase order.',
        // sccessType: COMPANY_PAYMENT_CHECKOUT_SUCCESS,
        // failedType: COMPANY_PAYMENT_CHECKOUT_FAILED,
        // successCallback: () => { return(getNotificationTemplate())},
        // errorCallback: (msg) => {toast.error(msg)}
        // responseKeyGetter: (response) => (response.data.error),
        // successContent: "SUCCESS",
    };

    return (axiosPost(paramObj));
    // return (axiosGet(paramObj));
}

export function getAddress(obj) {

    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_PAYMENT_ORDER_GET_ADDRESS}`,
        criteria: obj,
        logText: 'Get addresses Url ',
        sccessType: PAYMENT_ORDER_GET_ADDRESS_SUCCESS,
        failedType: PAYMENT_ORDER_GET_ADDRESS_FAILED,
        errorMessage: 'Failed to Get Address list.'
    };

    return (axiosPost(paramObj));
}

export function setAddress(obj) {

    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_PAYMENT_ORDER_SET_ADDRESS}`,
        criteria: obj,
        logText: 'Set addresses Url ',
        sccessType: PAYMENT_ORDER_SET_ADDRESS_SUCCESS,
        failedType: PAYMENT_ORDER_SET_ADDRESS_FAILED,
        errorMessage: 'Failed to set address.'
    };

    return (axiosPost(paramObj));
}

export function getPaymentAccounts(resellerId) {
    let queryStr = "";
    if (resellerId != undefined && resellerId != null && resellerId != "") {
        queryStr = `?clientId=${resellerId}`;
    }
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENTNEW_PAYMENT_ACCOUNTS}${queryStr}`,
        criteria: null,
        logText: 'Get Payment Accounts ',
        sccessType: USER_GET_PAYMENT_ACCOUNT_LIST_SUCCESS,
        failedType: USER_GET_PAYMENT_ACCOUNT_LIST_FAILED,
        errorMessage: 'Failed to get Payment Accounts.'
    };

    return (axiosGet(paramObj));
}

export function updatePaymentMethod(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENTNEW_UPDATE_ACCOUNT}`,
        criteria: obj,
        logText: 'Updating Payment Accounts ',
        sccessType: USER_UPDATE_PAYMENT_ACCOUNT_SUCCESS,
        failedType: USER_UPDATE_PAYMENT_ACCOUNT_FAILED,
        errorMessage: 'Failed to update Payment Accounts.'
    }
    return (axiosPost(paramObj));
}

export function setDefaultPaymentMethod(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_PAYMENTNEW_DEFAULT_PAYMENT}`,
        criteria: obj,
        logText: 'Set Payment Account ',
        sccessType: USER_UPDATE_PAYMENT_ACCOUNT_SUCCESS,
        failedType: USER_UPDATE_PAYMENT_ACCOUNT_FAILED,
        errorMessage: 'Failed to set default payment method.'
    }
    return (axiosPost(paramObj));
}

export function getBusinessUnits(method, obj) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_BUSINESS_UNIT}${method}`,
        criteria: obj,
        logText: 'Get Client Business Units',
        sccessType: CLIENT_GET_BUSINESS_UNITS_SUCCESS,
        failedType: CLIENT_GET_BUSINESS_UNITS_FAILED,
        errorMessage: 'Failed to get Business Units.'
    };

    return (axiosGet(paramObj));
}

export function updateBusinessUnits(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_UPDATE_BUSINESS_UNIT}`,
        criteria: obj,
        logText: 'Update Client Business Units',
        sccessType: CLIENT_GET_BUSINESS_UNITS_SUCCESS,
        failedType: CLIENT_GET_BUSINESS_UNITS_FAILED,
        errorMessage: 'Failed to update Business Units.'
    };

    return (axiosPost(paramObj));
}
export function UpdateBusinessUnitRelations(obj) {
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_UPDATE_BUSINESS_UNIT_RELATION}`,
        criteria: obj,
        logText: 'Update Client Business Unit Relation',
        sccessType: CLIENT_GET_BUSINESS_UNIT_RELATION_SUCCESS,
        failedType: CLIENT_GET_BUSINESS_UNIT_RELATION_FAILED,
        errorMessage: 'Failed to update Business Unit Relation.'
    };

    return (axiosPost(paramObj));
}

export function getTotalUsage(companyCode, resellerId, startDate, endDate) {

    let paramObj = {
        url: `${WEB_SERVICE_TELELCOM_BACKEND_ROOT}usage/${companyCode}` + (resellerId != null && resellerId != '' ? `/resellerCode/${resellerId}` : '') + `?startDate=${startDate}&endDate=${endDate}`,
        criteria: null,
        logText: 'getTotalUsage ',
        startType: GET_TOTAL_USAGE_START,
        sccessType: GET_TOTAL_USAGE_SUCCESS,
        failedType: GET_TOTAL_USAGE_FAILE,
        errorMessage: 'Failed to getTotalUsage.'
    };

    return (axiosGet(paramObj));
}

export function ClearBusinessUnitRelations() {
    return ((dispatch) =>
        dispatch(
            {
                type: CLIENT_GET_BUSINESS_UNIT_RELATION_FAILED,
            }
        ))
}

export function ClearClientUserInformation() {
    return ((dispatch) =>
        dispatch(
            {
                type: CLEAR_CLIENT_DATA,
            }
        ))
}

export function EmailPurchaseOrder(orderObj) {
    const url = `${WEB_SERVICE_PAYMENT_ORDER_ROOT}${WEB_SERVICE_EMAIL_PURCHASE_ORDER}`;
    const request = axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: orderObj,
    });


    return (
        (dispatch) => {
            request.then((response) => {
                if (response.status == 200 && response.data) {
                    if (response.data.statusDetail.includes("Succeed")) {
                        dispatch({
                            type: EMAIL_PURCHASE_ORDER_SUCCESS,
                            payload: response.data.statusDetail
                        });
                        toast.success("Your order has been sent");
                    }
                    else {
                        toast.error("Failed to send order");
                    }

                }
                else {
                    toast.error(response.data.statusDetail);
                    dispatch({
                        type: EMAIL_PURCHASE_ORDER_FAILED,
                        payload: response.data.statusDetail
                    });
                }
                return response;
            })
        })
}

export function GetUserActivityTracking(orderObj){
    let paramObj = {
        url: `${WEB_SERVICE_USER_ROOT}${WEB_SERVICE_GET_USER_ACTIVITY_TRACKING}`,
        criteria: orderObj,
        sccessType: GET_USER_ACTIVITY_TRACKING_SUCCEED,
        failedType: GET_USER_ACTIVITY_TRACKING_FAILED,
        errorMessage: 'Failed to tracking.'
    };

    return (axiosGet(paramObj));
}