/*eslint-disable*/
import React, { Component, Fragment } from "react";
import uuid from "uuid/v4";
import { Container, Col, Row, CardGroup, Card, CardBody,CardFooter, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Collapse, Tooltip, UncontrolledTooltip } from 'reactstrap';
import  CreditCard from "react-credit-cards";
import {BackButton, NextButton} from '../../../shared/components/Button/IconButton'; 
import { USAGE_PRICE } from '../../../constants/dataConstants';
import log from 'loglevel';
import './smallcard.scss';
import CompanyActivityChangePlan from './CompanyActivityChangePlan';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';
import { toast } from "react-toastify";

class CompanyActivityBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
        payments: [
          {
            id: "p1234567",
            method: "Visa Credit Card",
            status: "Paid",
            date: "03/19",
            amount: "$100 CAD",
            status:'Active'
          },
          {
            id: "p1234568",
            method: "Master Credit Card",
            status: "Paid",
            date: "03/19",
            amount: "$100 CAD",
            status:'Active'
          },
          {
            id: "p1234569",
            method: "Visa Credit Card",
            status: "Paid",
            date: "03/19",
            amount: "$100 CAD",
            status:'Active'
          }
        ],
        modalObj: {},
        billcycleModalObj: {},
        paymentModalObj: {},
        itemModalObj: {},
    }
    
    this.addCard = this.addCard.bind(this);
  }

  toggle(indexStr){
    this.setState(prevState=>{
      prevState.modalObj[indexStr] = prevState.modalObj[indexStr] ? !prevState.modalObj[indexStr] : true;
      return ({
        modalObj: prevState.modalObj,
      });
    });
  }

  billcycleToggle(indexStr){
    this.setState(prevState=>{
      prevState.billcycleModalObj[indexStr] = prevState.billcycleModalObj[indexStr] ? !prevState.billcycleModalObj[indexStr] : true;
      return ({
        billcycleModalObj: prevState.billcycleModalObj,
      });
    });
  }

  paymentToggle(indexStr){
    this.setState(prevState=>{
      prevState.paymentModalObj[indexStr] = prevState.paymentModalObj[indexStr] ? !prevState.paymentModalObj[indexStr] : true;
      return ({
        paymentModalObj: prevState.paymentModalObj,
      });
    });
  }

  itemToggle(indexStr){
    this.setState(prevState=>{
      prevState.itemModalObj[indexStr] = prevState.itemModalObj[indexStr] ? !prevState.itemModalObj[indexStr] : true;
      return ({
        itemModalObj: prevState.itemModalObj,
      });
    });
  }

  addCard(card) {
    //How they know the item passing in is the items's array item?
    let newCard = { ...card, id: uuid() }; //add the id to the new item.
    this.setState(state => ({
      cards: [...state.cards, newCard] // How they know the state is the OLD state above?
      // add the new item to the current items state list
      // finally put them total to "items"
    }));
  }

  renderOrder = ({orderObj, index}) => {
    let itemObj = null;
    let accountObj = null;
    if (orderObj && Array.isArray(orderObj.orderItemDataList) && orderObj.orderItemDataList.length > 0) {
      itemObj = orderObj.orderItemDataList[0];
      if (Array.isArray(this.props.accountList)){
        accountObj = this.props.accountList.find(account => (orderObj.accountId == account.id));
        // let cardtype = Payment.fns.cardType(accountObj.cardMask);
        // log.debug('cardtype', cardtype);
      }
    }
    let payFrequency = orderObj && orderObj.payFrequency ? orderObj.payFrequency.itemPayFreqName: '';
    // let payPrice = this.props.currentPlanItem && this.props.currentPlanItem.unitPrice ? `CAD $ ${this.props.currentPlanItem.unitPrice}`: '';
    if (typeof payFrequency === 'string' && payFrequency.length > 0) {
      payFrequency = payFrequency.charAt(0).toUpperCase() + payFrequency.slice(1);
    }

    let payPrice = '';
    if (itemObj && itemObj.itemData && itemObj.itemData.itemTypeId == 1) {
      payPrice = USAGE_PRICE;
    }
    else if (itemObj && itemObj.itemData && itemObj.itemData.itemTypeId == 2 && itemObj.itemData.unitPrice) {
      payPrice = `CAD $ ${itemObj.itemData.unitPrice}`;
    }
    
    return(
      <div style={{marginBottom:'30px'}}>
        <Collapse isOpen={this.state.itemModalObj[index.toString()] ? false : true}>

        
        <Row style={{marginBottom: '15px'}}>
          <Col md={6} style={{padding: '0px'}}>
            <h3>{itemObj? itemObj.itemData.itemName: 'No item is shown'}</h3>
          </Col>
          <Col md={6} style={{textAlign:'right', padding: '0px'}}>
            <Button color="link" onClick={()=>{this.itemToggle(index.toString())}} size="sm" style={{color: 'blue', margin: '0px'}}>Change plan</Button>{' '}
            {/* <Button color="link" onClick={this.cancelPlan} size="sm" style={{color: 'red', margin: '0px'}}>Cancel plan</Button>{' '} */}
            <ConfirmDeleteDialog
              // modalVisible={this.state.confirmDialogOpen}
              onConfirm={() => {this.props.cancelPlan(orderObj.id)}}
              noIcon
              link
              label="Cancel plan"
              // toggleOff={this.toggleDialog}
              msg="Are you sure you want to cancel the current plan?"
            />
          </Col>
        </Row>
        <Row>
          <Col md={9}>
            <Row>
              <span style={{fontSize:'20px'}}><b>{payFrequency}</b></span>
            </Row>
            {/* <hr/> */}
            <br/>
            <Collapse isOpen={this.state.billcycleModalObj[index.toString()] ? false : true}>
            <Row style={{marginTop: '10px'}}>
              <Col  md={3} style={{padding: '0px'}}>
                <Label style={{ display: 'inline'}}>
                  <span style={{fontSize:'18px'}}>Billing Cycle</span>
                </Label>
              </Col>
              <Col md={2}>
                {/* <Input type='number' min="0" step="1" max={"365"} defaultValue={orderObj.billcycle} onChange={(e)=>{this.handBillCycleChange(e)}} ></Input>  */}
                <Label style={{ display: 'inline'}}>
                  <span style={{fontSize:'18px'}}>{orderObj.billcycle}</span>
                </Label>
              </Col>
              <Col md={3}>
                <span style={{fontSize:'18px'}}>day(s)</span>
              </Col>    
              <Col md={2}>
                <a href={'#'} onClick={(e)=>{ e.preventDefault(); this.billcycleToggle(index.toString()); }}>
                  change
                </a>
              </Col>            
            </Row>
            </Collapse>
            <Collapse isOpen={this.state.billcycleModalObj[index.toString()] ? true : false}>
              <BillCycleChangeForm
                orderObj={orderObj}
                onChange={()=>{}}
                toggle={()=>{this.billcycleToggle(index.toString())}}
                changePlan = {this.props.changePlan}
              />
            </Collapse>
            {/* <hr/> */}
            <br/>
            <Collapse isOpen={this.state.paymentModalObj[index.toString()] ? false : true}>
            <Row style={{marginTop: '10px'}}>
              <Col  md={3} style={{padding: '0px'}}>
                <Label style={{ display: 'inline'}}>
                  <span style={{fontSize:'18px'}}>Payment</span>
                </Label>
              </Col>
              <Col md={2}>
                {accountObj ? 
                  <CreditCard
                    number={accountObj.cardMask}
                    customCardsSupport={true}
                    customCardsStyles={{'width' : '5px'}}
                  /> 
                  :
                  <span style={{fontSize:'12px'}}>None</span>
                }
                                    
              </Col>
              <Col md={3}>
                  {accountObj && 
                    <span style={{fontSize:'12px'}}>{accountObj.cardMask}</span>
                  }
              </Col>       
              <Col md={2}>
                <a href={'#'} onClick={(e)=>{ e.preventDefault(); this.paymentToggle(index.toString());}}>
                  change
                </a>
              </Col>            
            </Row>
            
              <Row style={{marginTop: '10px'}}>
                <Col md={12}>
                  <Label  style={{ display: 'inline'}} >
                      {/* <Input type="checkbox" checked={this.props.autoPay} onClick={this.handleAutoPayClick}/>  */}
                      Allow to pay the plan automatically :  {orderObj && orderObj.autoPay ? 'Yes' : 'No'}
                  </Label>
                </Col>
              </Row>
            </Collapse>
            <Collapse isOpen={this.state.paymentModalObj[index.toString()] ? true : false}>
              <PaymentChangeForm
                orderObj = {orderObj}
                accountList = {this.props.accountList}
                onChange={()=>{}}
                toggle={()=>{this.paymentToggle(index.toString())}}
                changePlan = {this.props.changePlan}
              />
            </Collapse>  
            
          </Col>
          <Col md={3} style={{display:'flex', justifyContent: 'flex-end'}}>
            <span style={{fontSize:'20px'}}>{payPrice}</span>
          </Col>
        </Row>
        {/* <hr/> */}
        <br/>
        <Row>
          <Col md={3} style={{padding: '0px'}}>
            <h4>Plan's Details</h4>
          </Col>
          <Col md={6}>
            <a href={'#'} onClick={(e)=>{ e.preventDefault(); this.toggle(index.toString());}}>
              {this.state.modalObj[index.toString()] ? 'hide details' : 'show details'}
            </a>
          </Col>
        </Row>
        <Collapse isOpen={this.state.modalObj[index.toString()] ? true : false}>
        <Row>
          <div style={{padding:'5px 10px', fontSize: '16px'}}><p>{itemObj && itemObj.itemData && itemObj.itemData.itemDescription ? itemObj.itemData.itemDescription : ''}</p></div>
        </Row>
        <br/>
        {itemObj && itemObj.itemData && itemObj.itemData.itemRateList && itemObj.itemData.itemRateList.length > 0 && 
          <Row>
            <br/>
            <h4>Plan's Rate</h4>
            <br/>
            <BootstrapTable 
                data={ itemObj.itemData.itemRateList}  
                // tableHeaderClass = 'header_white' 
                pagination = {false} 
                  
                  
                bordered={ false } 
                // insertRow 
                ref='table' 
            >
              <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='10%'>Id</TableHeaderColumn>
              <TableHeaderColumn dataField='itemId' dataAlign="center" dataSort width='10%' >Item Id</TableHeaderColumn> 
              <TableHeaderColumn dataField='startPoint' dataAlign="center" dataSort width='10%' >Start Point</TableHeaderColumn>
              <TableHeaderColumn dataField='endPoint' dataAlign="center" dataSort width='10%' >End Point</TableHeaderColumn>
              <TableHeaderColumn dataField='rate' dataAlign="center" dataSort width='10%' >Rate ($)</TableHeaderColumn>
                            
        
          </BootstrapTable>
          </Row>
        }
        </Collapse>
        
        </Collapse>
        <Collapse isOpen={this.state.itemModalObj[index.toString()] ? true : false}>
          <Row>
            <CompanyActivityChangePlan
              orderObj = {orderObj}
              planItemsList = {this.props.planItemsList}
              toggle = {() => {this.itemToggle(index.toString())}}
              onChange = {() => {}}
              changePlan = {this.props.changePlan}
            />
          </Row>
        </Collapse>
        <hr/>
      </div>
    );
  }

  render() {
    const btn ={
      'float': 'right',
      'height': '50px'

    }


     
    if (this.props.orderList) {

    }

    return(
        <Col md={12}>  
        <Card>
        <CardBody>
        {/* <Row>
            <Col md={6}>
              <br/>
              <h3>Current Plan: IOT-Basic</h3>
              <ul>
              {this.state.payments.map(payment => {
                return (
                  <li key={payment.id}>plan description123</li>
                );
              })}
             </ul>
            </Col>
            

          </Row>
          <Row>
           <BootstrapTable 
                data={ this.state.payments }  
                tableHeaderClass = 'header_white' 
                pagination = {false} 
                  
                  
                bordered={ false } 
                search 
                // insertRow 
                ref='table' 
            >
              <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" width='10%' dataFormat={this.UPCLinkFormatter} >Payment ID</TableHeaderColumn>
              <TableHeaderColumn dataField='method' dataAlign="center" dataSort width='20%' >Payment Method</TableHeaderColumn> 
              <TableHeaderColumn dataField='status' dataAlign="center" dataSort width='10%' >Payment Status</TableHeaderColumn>
              <TableHeaderColumn dataField='date' dataAlign="center" dataSort width='10%' >Date Received</TableHeaderColumn>                     
              <TableHeaderColumn dataField='amount' dataAlign="center" dataSort width='10%' >Payment Amount Currency</TableHeaderColumn> 
              <TableHeaderColumn dataField='status' dataAlign="center" dataSort width='10%' >Status</TableHeaderColumn>
         
          </BootstrapTable>
          </Row> */}

          <Row>
            <Col md={12}>
            {this.props.orderList && this.props.orderList.map((order, index) => {
              return(
                <Fragment>
                  {this.renderOrder({orderObj:order, index})}
                </Fragment>
              );
            })}
            </Col>
          </Row>
          {/* <Row>
            <CompanyActivityChangePlan
               planItemsList = {this.props.planItemsList}
            />
          </Row> */}
          
          </CardBody>
          {/* <CardFooter style={{textAlign:'right', padding:'0px 0px 5px 0p',backgroundColor:'white'}}>
                  <Button color="link" onClick={this.props.goToPlan} size="sm" style={{color: 'blue', margin: '0px'}}>Change plan</Button>{' '}
                  <Button color="link" size="sm" style={{color: 'red', margin: '0px'}}>Cancel plan</Button>{' '}
                  
                </CardFooter> */}
          </Card>
    </Col>
    );
  }
}
export default CompanyActivityBoard;


// ---------------------------------------------------------------------------------------
const BillCycleChangeForm = ({orderObj, onChange, toggle, changePlan}) => {
  log.debug('BillCycleChangeForm', orderObj);
  const [input, setInput] = React.useState(orderObj ? orderObj.billcycle : 0);
  const inputOnChange = (e) => {
    // this.setState({
    //     [e.target.name]: e.target.value
    //  });
    setInput(e.target.value);
  }

  const handleChange = () => {
    if (!orderObj) {
      toast.error('Failed to update order. Please try again.');
      toggle();
      return
    }
    let defaultParamObj = orderObjTransfer(orderObj);
    let paramObj = {...defaultParamObj, ...{billcycle: input}};
    log.debug('billcycle change parameter', paramObj);
    changePlan(orderObj.id, paramObj);
    toggle();
  }

  const handleCancel = () => {
    toggle();
  }

  return (
    <Fragment>
      <Row style={{marginTop: '10px'}}>
        <Col  md={3} style={{padding: '0px'}}>
          <Label style={{ display: 'inline'}}>
            <span style={{fontSize:'18px'}}>Billing Cycle</span>
          </Label>
        </Col>
        <Col md={2}>
          {/* <Input type='number' min="0" step="1" max={"365"} defaultValue={orderObj ? orderObj.billcycle : 0} onChange={(e)=>{inputOnChange(e)}} ></Input> */}
          <Input type='number' min="0" step="1" max={"365"} value={input} onChange={(e)=>{inputOnChange(e)}} ></Input>
        </Col>
        <Col md={2}>
          <span style={{fontSize:'18px'}}>day(s)</span>
        </Col>                
      </Row>
      <Row>
        <Col md={12} style={{textAlign: 'right'}}>
        <Button onClick={handleChange} size='sm'>Save</Button>
        <Button onClick={handleCancel} size='sm'>Cancel</Button>
        </Col>
      </Row>
    </Fragment>
  );
}

// ---------------------------------------------------------------------------------------
const PaymentChangeForm = ({accountList, orderObj, onChange, toggle, changePlan}) => {

  const [accountid, setAccountid] =  React.useState(orderObj ? orderObj.accountId : 0);
  const [autopay, setAutopay] = React.useState(orderObj ? orderObj.autoPay : 0);

  const handleChange = () => {
    if (!orderObj) {
      toast.error('Failed to update order. Please try again.');
      toggle();
      return
    }
    let defaultParamObj = orderObjTransfer(orderObj);
    let paramObj = {...defaultParamObj, ...{accountId: accountid, autoPay: autopay}};
    log.debug('billcycle change parameter', paramObj);
    changePlan(orderObj.id, paramObj);
    toggle();
  }

  const handleCancel = () => {
    toggle();
  }

  const handleRadioClick = (e, row) => {
    setAccountid(row.id);
  }

  const handleAutoClick = (e) => {
    if (e.target.checked){
      setAutopay(1);
    }else{
      setAutopay(0);
    }
  }

  const radioButtonFormater = (cell, row) => {
    return (
      <Input type="radio" name={"radio"+orderObj.id} checked={row.id == accountid} onClick={(e)=>{handleRadioClick(e, row)}} />
    )
  }

  const tableActionColFormater = (cell, row) => {
    // const ac = this.props.account.filter(account => account.cardMask);
    const card = row;
    const cardwidth ={
      'width': '10px', 
    }
    return (
        <div>       
          <CardGroup>
            <Card >                    
              <CreditCard 
                  number={card.cardMask}
                  customCardsSupport={true}
                  customCardsStyles={cardwidth}
              />
            </Card>
          </CardGroup>       
        </div>
    )
  }

  return(
    <Fragment>
      <Card style={{backgroundColor: 'light gray'}}>
      <Row>
        <Col  md={3} style={{padding: '0px'}}>
          <Label style={{ display: 'inline'}}>
            <span style={{fontSize:'18px'}}>Payment</span>
          </Label>
        </Col>
      </Row>
      <Row>   
        <BootstrapTable
            data={ accountList }  
            pagination = {false} 
              
              
            bordered={ false } 
            // tableHeaderClass='col-hidden'
            headerStyle={ { display: 'none' } }
        >
            <TableHeaderColumn dataField='id' isKey={ true } dataAlign="center" hidden >ID</TableHeaderColumn>
            <TableHeaderColumn dataField='' dataAlign="center"  width='3%' dataFormat={radioButtonFormater}></TableHeaderColumn> 
            <TableHeaderColumn dataField='card' dataFormat={tableActionColFormater}  dataAlign="center" dataSort width='10%' ></TableHeaderColumn>
            <TableHeaderColumn dataField='cardMask' dataAlign="center" dataSort width='10%' >Card Number</TableHeaderColumn> 
            <TableHeaderColumn dataField='expDate' dataAlign="center" dataSort width='10%' >Expires on</TableHeaderColumn> 
        </BootstrapTable>
      </Row>
      <Row style={{marginTop: '10px'}}>
        <Col md={12}>
          <Label  style={{ display: 'inline'}} >
            {/* <Input type="checkbox" checked={orderObj ? orderObj.autoPay == 1 : false} onClick={(e)=>{handleAutoClick(e)}}/>  */}
            <Input type="checkbox" checked={autopay ? autopay == 1 : false} onClick={(e)=>{handleAutoClick(e)}}/> 
            Allow to pay the plan automatically
          </Label>
        </Col>
      </Row>
      <Row>
      <Col md={12} style={{textAlign: 'right'}}>
        <Button onClick={handleChange} size='sm'>Save</Button>
        <Button onClick={handleCancel} size='sm'>Cancel</Button>
        </Col>
      </Row>
      </Card>
    </Fragment>
  );
}

// ---------------------------------------------------------
const orderObjTransfer = (orderObj) => {
  let defaultData = {
    "accountId": null,
    "currency": "CAD",
    "comment": "1",
    "billcycle": 0,
    "autoPay": 0,
    // "orderItemDataList": []
  }
  let data = {}
  if (orderObj) {
    let orderItemDataList = [];
    if (orderObj.orderItemDataList) {
      orderObj.orderItemDataList.forEach((order, oindex)=>{
        orderItemDataList.push({
          "itemId": order.itemId,
          "itemQty": order.itemQty,
          "promoCode": order.promoCode,
        });
      });
    }
    data = {
      // "customerId": orderObj.customerId,
      "accountId": orderObj.accountId,
      "currency": orderObj.currency,
      "comment": orderObj.comment,
      "billcycle": orderObj.billcycle,
      "autoPay": orderObj.autoPay,
      // "orderItemDataList": orderItemDataList
    }
  }
  return {...defaultData, ...data}
}

