/* eslint-disable */
import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import {bindActionCreators, compose} from 'redux';
import {connect} from "react-redux";
import {Redirect, withRouter} from 'react-router-dom';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Link } from 'react-router-dom';
import {Alert, Col, Row} from "reactstrap";
import PropTypes from 'prop-types';
import renderCheckBoxField from '../../../shared/components/form/CheckBox';
import { resetPassword } from '../../../redux/actions/userAction';
import { FORGOT_PASSWORD_ROOT } from '../../../constants/appConstants';
import { WEB_SERVICE_ROOT } from '../../../constants/appConstants';
import { changeThemeToCustom } from '../../../redux/actions/themeActions';
import ThemeSwitcher from 'react-css-vars';

class ResetPasswordForm extends PureComponent {

    constructor() {
        super();
        this.state = {
          showPassword: false,
          showErrors: false,
          isLoading: false,
          forgetSuccess: false,
          username: '',
          password: '',
          passwordConfirm: '',
          errors: {},
          custom: false,
          loginTheme: {},
        };
      }
    
    componentWillUnmount() {
      this.setState({
          showPassword: false,
          showErrors: false,
          isLoading: false,
          username: '',
          password: '',
          errors: {},      
          loggedIn: 'false',
          
      });
    }
    
    componentDidMount() {
      this.initTheme();
    }

    initTheme = () => {
      var x = document.URL
      fetch(`${WEB_SERVICE_ROOT}BrandingLogin?url=${x}`).then(response => response ? response.json() : {}).then(data => {
        if (data.length > 0) {
          let companyTheme = data[0]
          //set logo
          if (companyTheme) {
            this.props.changeThemeToCustom();
            this.setState({custom: true, loginTheme: companyTheme})
          }
  
        }
      }).catch(e => console.error(e.message));
    }

      showPassword = (e) => {
        e.preventDefault();
        this.setState({
          showPassword: !this.state.showPassword,
          showErrors: false,
        });
      };
    
      onInputChange = (event) => {
        event.preventDefault();
        const{name, value} = event.target;
        // let errors = this.state.errors;
        switch (name) {
            case 'username':
                this.setState({
                    username: value,
                    showErrors: false
                });
                sessionStorage.setItem('username', event.target.value);
                break;
            case 'password':
                this.setState({
                    password: value,
                    showErrors: false
                });
                break;
            case 'passwordConfirm':
              this.setState({
                passwordConfirm: value,
                showErrors: false
            });
            default:
                return this.state;
        }
      };
    
      handleTouch = (e) =>{
        e.preventDefault();
        this.setState({showErrors: false})
      }
    
      handleSubmit = (e) => {
        e.preventDefault();
        this.initTheme();
        this.setState(
          {isLoading: true, showErrors: true}
        );
      let query = new URLSearchParams(this.props.location.search);
      let token = query.get('token');
        this.props.resetPassword(this.state.password, token);
      }
    
      renderError = () => {
        if (! this.props.isAuthenticated && this.props.error === 1) {
            return (
                <Alert className='form__form-group-field alert-danger text-center'>
                    <span className='text-center'>{this.props.message}</span>
                </Alert>
            )
        } else {
            return null;
        }
    };

    validatePassConfirm = () => {
      return (this.state.passwordConfirm && this.state.passwordConfirm.length > 0 && this.state.password === this.state.passwordConfirm);
    }

    validateForm = () => {
      return this.validatePassConfirm() ;
  }
    
      render() {
        if ( sessionStorage.getItem('isAuthenticated') && 'true' == sessionStorage.getItem('isAuthenticated')){
          return <Redirect push to="/pages" />; 
        }
    
        return (
          <ThemeSwitcher theme={this.state.loginTheme}>
            <div className="account">
            <div className="account__wrapper">
            <div className="account__card">
                <div className="account__head">
                  <h3 className="account__title login-font">Reset
                    <span className="account__logo login-font"> Password
                      <span className="account__logo-accent login-font">  Form</span>
                    </span>
                  </h3>
                </div>


                <form className="form" onSubmit={this.handleSubmit}>
                  <div className="form__form-group">
                  <span className="form__form-group-label">Password</span>
                  <div className="form__form-group-field">
                    <input
                        className="custom_input"
                        valid = {this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                        value={this.state.password}
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder=""
                        onChange={this.onInputChange}
                        onClick={this.handleTouch}
                    />
                    <button
                      className={`form__form-group-button${this.state.showPassword ? ' active' : ''}`}
                      onClick={e => this.showPassword(e)}
                    ><EyeIcon />
                    </button>
                  </div>

                  <span className="form__form-group-label">Confirm Password</span>
                  <div className="form__form-group-field">
                    <input
                        className="custom_input"
                        valid = {this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                        invalid = {this.state.passwordConfirm && this.state.password !== this.state.passwordConfirm}
                        value={this.state.passwordConfirm}
                        name="passwordConfirm"
                        type="password"
                        placeholder=""
                        onChange={this.onInputChange}
                        onClick={this.handleTouch}
                        disabled={!this.state.password}
                    />
                  </div>

                  </div>
                  <div className='form__form-group-field'>
                      {this.state.showErrors === true && this.renderError()}
                  </div>
                  <div className='form__form-group-field'>
                      <br/>
                  </div>
                  <button className="btn account__btn account__btn--small" type="submit" disabled = {!this.validateForm()}>Submit</button>
                  <a className="btn account__btn account__btn--small" href={FORGOT_PASSWORD_ROOT}>Home</a>
                </form>

                <h5>{this.props.resetPasswordMessage}</h5>
            </div>
            </div>
            </div>
          </ThemeSwitcher>
        );
      }
}

const mapStateToProps = (state) => {
    return state.user;
  };
  const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({resetPassword, changeThemeToCustom}, dispatch);
  };

  const enhance = compose(
    connect(mapStateToProps,mapDispatchToProps),
    withRouter
  );
  
  export default enhance(ResetPasswordForm);