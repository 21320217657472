/* eslint-disable */
import React, { Component } from 'react';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { hasRole } from '../../../../factories/auth';
import ConfirmDeleteDialog from '../../../../shared/components/Dialog/ConfirmationDialog';

import EventAction from './NotificationSteps/EventAction';
import LogicCondition from './NotificationSteps/LogicCondition';
import Notification from './NotificationSteps/Notification';
import { getCurrentUser } from '../../../../factories/auth';

class NotificationModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            isAdmin: false,
            actionId: 0,
            //Event Action
            eventName: "",
            eventDescription: "",
            //Logic Condition
            resellerId: 0,
            planId: 0,
            inventoryId: 0,
            logicValue: null,
            //Notification
            info: "",
            subject: "",
            message: "",
            callbackStep: 0,
            status: 1,

            activeStep: 0,
        }
    }
    objsteps = {
        "Event action": EventAction,
        "Logic condition": LogicCondition,
        "Notification": Notification
    };
    componentDidMount() {
        if (this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'USER'])) {

            this.setState({
                resellerId: 0,
                isAdmin: true
            })

        } else {
            if (this.props.userObj.resellerCode != null) {
                var reseller = this.props.resellerList.filter((reseller) => reseller.resellerCode === this.props.userObj.resellerCode);
                if (reseller.length == 1) {
                    this.setState({
                        resellerId: reseller[0].id,
                        isAdmin: false
                    })
                }
            }
        }

    }
    componentWillReceiveProps(nextProps, nextContent) {
        if (nextProps.actionInfo != null) {
            this.setState({
                actionId: nextProps.actionInfo.actionId,
                eventName: nextProps.actionInfo.eventName,
                eventDescription: nextProps.actionInfo.eventDescription,
                resellerId: nextProps.actionInfo.resellerId,
                planId: nextProps.actionInfo.itemId,
                inventoryId: nextProps.actionInfo.inventoryId,
                logicValue: nextProps.actionInfo.logicValue,
                info: nextProps.actionInfo.info,
                subject: nextProps.actionInfo.subject,
                message: nextProps.actionInfo.message,
                callbackStep: nextProps.actionInfo.alertType,
                status: nextProps.actionInfo.status,
                activeStep: 0,
            })
        } else {
            if (nextProps.userObj && hasRole(nextProps.userObj, ['SUPERADMIN', 'ADMIN', 'USER'])) {
                if (nextProps.resellerList.length > 0) {
                    this.setState({
                        resellerId: 0,
                        isAdmin: true,
                        actionId: 0,
                        eventName: "",
                        eventDescription: "",
                        planId: 0,
                        inventoryId: 0,
                        logicValue: null,
                        info: "",
                        subject: "",
                        message: "",
                        callbackStep: 0,
                        status: 1,
                        activeStep: 0,

                    })
                }
            } else {
                if (nextProps.userObj.resellerCode != null) {
                    var reseller = nextProps.resellerList.filter((reseller) => reseller.resellerCode === nextProps.userObj.resellerCode);
                    if (reseller.length == 1) {
                        this.setState({
                            resellerId: reseller[0].id,
                            isAdmin: false,
                            actionId: 0,
                            eventName: "",
                            eventDescription: "",
                            planId: 0,
                            inventoryId: 0,
                            logicValue: null,
                            info: "",
                            subject: "",
                            message: "",
                            callbackStep: 0,
                            status: 1,
                            activeStep: 0,
                        })
                    }
                }
            }
        }
    }
    validateEventAction = () => {
        return this.state.eventName &&
            this.state.eventName != "" &&
            this.state.eventName.length < 255 &&
            this.state.eventDescription &&
            this.state.eventDescription != "" &&
            this.state.eventDescription.length < 500;
    }
    validateLogicCondition = () => {
        return this.state.logicValue &&
            !isNaN(this.state.logicValue) &&
            parseFloat(this.state.logicValue) > 0;
    }
    validateNotification = () => {
        var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

        if (this.state.callbackStep == '0') { // email
            return this.state.info &&
                this.state.info != "" &&
                this.state.info.length < 45 &&
                emailRegex.test(this.state.info) &&
                this.state.subject &&
                this.state.subject != "" &&
                this.state.subject.length < 150 &&
                this.state.message &&
                this.state.message != "" &&
                this.state.message.length < 1000;
        }
        else if (this.state.callbackStep == '1') {//phone
            return this.state.info &&
                this.state.info != "" &&
                this.state.info.length < 45 &&
                phoneRegex.test(this.state.info) &&
                this.state.message &&
                this.state.message != "" &&
                this.state.message.length < 1000;
        }
        return false;
    }
    validateOnSubmit = () => {
        return this.state.activeStep === 2 &&
            this.validateEventAction() &&
            this.validateLogicCondition &&
            this.validateNotification();
    }
    handleNext = (step) => {
        if (step === 0) {
        } else if (step === 1) {

        } else if (step === 2) {

        }
        this.setState(prevState => ({
            activeStep: prevState.activeStep + 1,
        }));
    }
    handleBack = () => {
        this.setState(prevState => {
            return {
                activeStep: (prevState.activeStep - 1) >= 0 ? prevState.activeStep - 1 : 0,
            }
        });
    }
    onClose = () => {
        this.setState({
            eventName: "",
            eventDescription: "",
            //Logic Condition
            resellerId: 0,
            planId: 0,
            inventoryId: 0,
            logicValue: null,
            //Notification
            info: "",
            subject: "",
            message: "",
            callbackStep: 0,
            activeStep: 0
        }, () => {
            this.props.onClose()
        })
    }
    handleChange = (event, newValue) => {
        this.setState({
            info: "",
            subject: "",
            message: ""
        }, () => {
            this.setState({
                callbackStep: newValue
            })
        })
    }
    onInputChange = (event) => {
        if (event.target.name === "resellerId") {
            this.setState({
                resellerId: event.target.value,
                planId: 0,
                inventoryId: 0
            });
        }
        else if (event.target.name === "planId") {
            this.setState({
                planId: event.target.value,
                inventoryId: 0
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    };
    onSubmit = () => {
        var resellerId = null;
        if (this.state.resellerId > 0) {
            resellerId = this.state.resellerId;
        }
        var obj = {
            "actionId": this.state.actionId,
            "eventName": this.state.eventName,
            "eventDescription": this.state.eventDescription,
            "resellerId": resellerId,
            "itemId": this.state.planId,
            "inventoryId": this.state.inventoryId,
            "logicValue": this.state.logicValue,
            "info": this.state.info,
            "subject": this.state.subject,
            "message": this.state.message,
            "alertType": this.state.callbackStep,
            "status": 1
        }
        this.props.onSubmit(obj)
    }
    onDelete = () => {
        var obj = {
            "actionId": this.state.actionId,
            "eventName": this.state.eventName,
            "eventDescription": this.state.eventDescription,
            "resellerId": this.state.resellerId,
            "itemId": this.state.planId,
            "inventoryId": this.state.inventoryId,
            "logicValue": this.state.logicValue,
            "info": this.state.info,
            "subject": this.state.subject,
            "message": this.state.message,
            "alertType": this.state.callbackStep,
            "status": 0
        }
        this.props.onSubmit(obj)
    }

    render() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.props.showModal}
                size='lg'
            >
                <ModalHeader toggle={this.onClose}>Notification</ModalHeader >
                <ModalBody >
                    <Stepper activeStep={this.state.activeStep} orientation="vertical">
                        {Object.entries(this.objsteps).map(([label, CustomStep], index) => (
                            <Step key={label}>
                                <StepLabel><h3>{label}</h3></StepLabel>
                                <StepContent>
                                    <CustomStep
                                        handleNext={this.handleNext}
                                        handleBack={this.handleBack}
                                        handleChange={this.handleChange}
                                        onInputChange={this.onInputChange}
                                        //Event Action
                                        eventName={this.state.eventName}
                                        eventDescription={this.state.eventDescription}
                                        //Logic Condition
                                        isAdmin={this.state.isAdmin}
                                        resellerId={this.state.resellerId}
                                        planId={this.state.planId}
                                        inventoryId={this.state.inventoryId}
                                        logicValue={this.state.logicValue}
                                        activeSIMList={this.props.activeSIMList}
                                        resellerList={this.props.resellerList}
                                        ServicePlanList={this.props.ServicePlanList}
                                        //Notification
                                        info={this.state.info}
                                        subject={this.state.subject}
                                        message={this.state.message}
                                        callbackStep={this.state.callbackStep}
                                        //Validation
                                        validateEventAction={this.validateEventAction}
                                        validateLogicCondition={this.validateLogicCondition}
                                        validateNotification={this.validateNotification}


                                    />
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </ModalBody>
                <ModalFooter>
                    {(this.state.actionId > 0) ?
                        <Button outline color='danger' type="button">
                            <ConfirmDeleteDialog
                                label={"Remove"}
                                onConfirm={(e) => { this.onDelete() }}
                                msg={`Do you want to delete ${this.state.eventName}? `}
                            />
                        </Button>
                        :
                        null
                    }

                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" disabled={!this.validateOnSubmit()} onClick={this.onSubmit}>
                            Submit
                        </Button>}

                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" disabled={!this.validateOnSubmit()} onClick={this.onSubmit}>
                            Submit
                        </Button>}
                    <Button outline color='secondary' type="reset" onClick={this.onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default NotificationModal;