/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Bar} from 'react-chartjs-2';
import {loadDeviceInfo} from '../../../../redux/actions/mapActions';
import {getMessageCount} from '../../../../redux/actions/dashboardActions';
import {groupBy} from '../../../../factories/utils';
import BasicDashboadEditIcon from './BasicDashboadEditIcon';
import SimplePackDeviceEditIcon from './SimplePackDeviceEditIcon';

class DeviceMsgChartWidget extends Component {
    constructor(props){
        super(props);
        this.state={
            deviceid: '',
        }
        this.interval = null
    }

    componentDidMount(){
        // this.props.loadDeviceInfo();
        // this.props.getMessageCount('12C75E1');
        // this.props.getMessageCount(this.props.deviceid);
        if (this.interval){
            clearInterval(this.interval);
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.deviceid && nextProps.deviceid !== this.state.deviceid){
            // this.props.getMessageCount(deviceid);
            // this.props.getMessageCount(nextProps.deviceid);
            if (this.interval){
                clearInterval(this.interval);
            }
             
            this.props.getMessageCount(nextProps.deviceid);
            // this.interval = setInterval(() => { 
            this.setState({
                deviceid : nextProps.deviceid,
            })
        }
    }

    componentWillUnmount(){
         
        if (this.interval){
            clearInterval(this.interval);
        }
    }
    // static getDerivedStateFromProps(props, state) {

    //     // function groupBy(list, keyGetter) {
    //     //   const map = new Map();
    //     //   list.forEach((item) => {
    //     //        const key = keyGetter(item);
    //     //        const collection = map.get(key);
    //     //        if (!collection) {
    //     //            map.set(key, [item]);
    //     //        } else {
    //     //            collection.push(item);
    //     //        }
    //     //   });
    //     //   return map;
    //     // }
    
    //     if (props.currentDeviceList){
    //       const grouped = groupBy(props.currentDeviceList , device => device.devicetypename);
    //     //    
    //     //    
    //       const keys = Array.from(grouped.keys());
    //     //    
    //       let values = [];
    //       keys.forEach((key) =>{
    //         values.push(grouped.get(key).length);
    //     });
    //       return ({
    //         chartlabel : keys,
    //         chartdata : values
    //       });
    //     }else{
    //       return state;
    //     }
    //   }



    renderMsgCountChart = () => {
        let chartlabel = [];
        let chartdata = [];
        let h = JSON.parse(this.props.layout.position).h;
        let height = h * 30 - 20;

        if (this.props.msgCounts){
            // this.props.msgCounts.forEach((item,index )=> {
            //     chartlabel.push(item.time_range.toString());
            //     chartdata.push(item.count);
            // });
            Object.entries(this.props.msgCounts).forEach(([key, value], index) => {
                chartlabel.push(key);
                chartdata.push(value);
            });
        }
        const data = {
        labels: chartlabel,
        datasets: [
            {
            label: 'Number of Movements',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 0.5,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: chartdata
            }
        ]
        };
        return (
        // <Card>
        // <CardBody>
            <div>
            {/* <h5 className="bold-text">Device Type Usage</h5> */}
            <Bar
            key={h}
            data={data}
            width={60}
            height={height.toString()} //  height={450}
            options={{ maintainAspectRatio: false }}
            />
            </div>
        // </CardBody>          
        // </Card>
        );
    }

    render() {
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                            {this.props.dashName} 
                            {/* <BasicDashboadEditIcon 
                                size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                layout={this.props.layout}
                                onEditSubmit = {this.props.onEditSubmit}
                            />   */}
                             {/* {this.props.layout.display === 0 && */}
                            <SimplePackDeviceEditIcon
                                size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                layout={this.props.layout}
                                onEditSubmit = {this.props.onEditSubmit}
                            />  
                             {/* } */}
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                <CardBody style={{padding:15}}>                     
                    {this.renderMsgCountChart()}
                </CardBody>  
          </Card>
          </Fragment>
        );
    }

}

function mapStateToProps(state) {    
    const {currentDeviceList} = state.asset;
    const {msgCounts} = state.dashboard;
    const props = {currentDeviceList, msgCounts};
    return props;
};
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDeviceInfo,getMessageCount}, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DeviceMsgChartWidget);
