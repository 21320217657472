/* eslint-disable */
import React, { Component, Fragment } from "react";
import { Row, Col, CustomInput, Form, FormGroup, InputGroup, InputGroupAddon, Input, Button, Label } from 'reactstrap';
import Select from 'react-select';
import GeofenceModal from './GeofenceModal';
import BasicGeofenceModal from './BasicGeofenceModal';
import {generateSelectOptions} from '../../../factories/utils';
import {FENCE_UPDATE_FENCE_OBJ_SUCCEED} from '../../../constants/actionTypes';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import {EditableText} from '../../Asset/components/ClassDisplayDetail';

class BasicRulesForm extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedRule: null,
            geofenceModal: false,
            showFence: null,
            selectedAssetClassOption: {
                value: '0',
                label: 'No Select....'
            },

            templateList: [
                {
                    "templateid": 1,
                    "typeid": 1,
                    "typename": "Battery Low",
                    "description": "Battery Low",
                    "devicetypeid": "5cf828390499f50b995ae851",
                    "devicetypename": "Oyster",
                    "attributeid": 8,
                    "geofenceid": -1,
                    "operator": 5,
                    "value": 200
                },
                {
                    "templateid": 2,
                    "typeid": 2,
                    "typename": "Over speed",
                    "description": "movement speed exceed limit",
                    "devicetypeid": "5cf828390499f50b995ae851",
                    "devicetypename": "Oyster",
                    "attributeid": 7,
                    "geofenceid": -1,
                    "operator": 3,
                    "value": 90
                },
                {
                    "templateid": 3,
                    "typeid": 3,
                    "typename": "Out Geofence",
                    "description": "Out Geofence",
                    "devicetypeid": "5cf828390499f50b995ae851",
                    "devicetypename": "Oyster",
                    "attributeid": -1,
                    "geofenceid": 10,
                    "operator": 1,
                    "value": 10
                },
                {
                    "templateid": 4,
                    "typeid": 4,
                    "typename": "Inactive Device",
                    "description": "Inactive Device",
                    "devicetypeid": "5cf828390499f50b995ae851",
                    "devicetypename": "Oyster",
                    "attributeid": -1,
                    "geofenceid": 10,
                    "operator": 2,
                    "value": 10
                },
                {
                    "templateid": 5,
                    "typeid": 1,
                    "typename": "Battery Low",
                    "description": "Battery Low",
                    "devicetypeid": "5cae9309c563d629dbb162b0",
                    "devicetypename": "KS Technologies",
                    "attributeid": 28,
                    "geofenceid": -1,
                    "operator": 5,
                    "value": 20
                }
            ],
        }
        this.currentFenceId = -1;
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        // if (nextProps.fenceStatus === FENCE_UPDATE_FENCE_OBJ_SUCCEED && nextProps.fenceObj && this.currentFenceRuleitem){
        if (nextProps.fenceObj && nextProps.fenceObj !== this.props.fenceObj ){
            // this.currentFenceRuleitem.geofenceid = nextProps.fenceObj.fenceid;
            // this.currentFenceRuleitem.geofencename = nextProps.fenceObj.fencename;
            // this.setState((preState)=>{
            //     return({
            //         rawData: preState.rawData,
            //     });
            // });
            this.currentFenceId = nextProps.fenceObj.fenceid;
            this.props.handleGeofenchChange(nextProps.fenceObj);
            this.setState((preState)=>{
                return({
                    geofencename: nextProps.fenceObj.fencename,
                    geofenobj: nextProps.fenceObj
                });
            });
        }

        // if (nextProps.templateList && nextProps.templateList !== this.props.templateList){
        //     this.setState({
        //         templateList: nextProps.templateList
        //     });
        // }
    }

    handleGeofenceEditClick= (e) =>{
        // this.currentFenceId = ruleItem.geofenceid;
        // this.currentFenceGroupId = groupIndex;
        // this.currentFenceRuleId = index;
        // this.currentFenceRuleitem = ruleItem;
        // groupItem, ruleItem, groupIndex, index
        this.geofenceToggle();
    }

    geofenceToggle = () => {
        this.setState(prevState => ({
            geofenceModal: !prevState.geofenceModal,
        }));
    }

    handleAssetClassChange = (val) => {
        //  
        // this.props.loadTemplate(val.value);
        // this.setState({
        //     selectedAssetClassOption: val,
        // });
        this.props.handleAssetClassChange(val);
    }

    handleAssetChange = (val) => {
        this.props.handleAssetChange(val);
    }

    onRuleChange = (e, item) => {
        let index = e.target.value;
        // let value = this.state.templateList[index].value;
        //  
        let showFence = this.props.templateList[index].geofenceid > 0
        this.setState({
            // selectedRuleIndex: index,
            // selectedRuleValue: value,
            // selectedRule: item,
            showFence: showFence,
        });
        this.props.onRuleChange(e, item);
    }

    renderRuleLine(){
        return (
            <Form>
                 <FormGroup>
                    {/* <Label for="exampleCheckbox">Radios</Label> */}
                    <div>
                        {/* <CustomInput type="radio" id="ruleOption1" name="ruleOption" value={'1'} label="Geofence Alert" checked = {this.state.selectedRule === '1'} onChange={this.onRuleChange}/>
                        <CustomInput type="radio" id="ruleOption2" name="ruleOption" value={'2'} label="Low Betteray Alert" checked = {this.state.selectedRule === '2'} onChange={this.onRuleChange}/>
                        <CustomInput type="radio" id="ruleOption3" name="ruleOption" value={'3'} label="But not this disabled one"  checked = {this.state.selectedRule === '3'} onChange={this.onRuleChange}/>
                        <CustomInput type="radio" id="ruleOption4" name="ruleOption" value={'4'} label="Can't click this label to select!" checked = {this.state.selectedRule === '4'} onChange={this.onRuleChange}/> */}
                        {
                            this.props.templateList && this.props.templateList.map((item, index)=>{
                                return(
                                    <CustomInput 
                                        type="radio" id={"ruleOption"+"_" + index} 
                                        name="ruleOption" 
                                        // label={item.typename + ' - ' + item.devicetypename} 
                                        value = {index}
                                        onChange={(e) => {this.onRuleChange(e, item)}}>

                                        <b><span style={{fontSize: '15px'}}>{item.typename + ' - ' + item.devicetypename}</span></b>
                                    </CustomInput>
                                )
                            })
                        }
                    </div>
                </FormGroup>
            </Form>
        )
    }

    handleEditInputChange = (newVal) => {
        this.props.handleEditInputChange(newVal);
        // this.setState((prevState) => {
        //     prevState.selectedRule.value = newVal
        //     return ({
        //         selectedRuleValue: newVal,
        //         selectedRule: prevState.selectedRule,
        //     })
        // });
    }

    renderAssetLine(){
        let assetClassOptions = generateSelectOptions(this.props.assetClass, 'assetclassname', 'assetclassid');
        let allAssetOptions = [];
        let selectedClass;
        if (this.props.selectedAssetClassOption){
            selectedClass = this.props.assetClass.find((item, index) => (item.assetclassid === this.props.selectedAssetClassOption.value));
        }
        if (selectedClass && selectedClass.assets){
            allAssetOptions = generateSelectOptions(selectedClass.assets, 'assetname', 'assetid');
        }
         return(
            <div>
                {/* {this.state.selectedRule && */}
                <Row>
                    <Col md={3}>
                        <Label   style={{ display: 'inline'}}>Asset Class</Label>
                        <Select            
                            value= {this.props.selectedAssetClassOption}           
                            options={assetClassOptions}
                            onChange={this.handleAssetClassChange}
                        />
                    </Col>

                    {/* <Col md={1}></Col> */}
                   
                    <Col md={3}>
                        <Label  style={{ display: 'inline'}} > Asset </Label>                        
                            <Select
                                value= {this.props.selectedAssetOption}  
                                options={allAssetOptions}
                                onChange={this.handleAssetChange}
                            />                       
                    </Col>
                    { this.props.selectedRule && 
                    <Fragment>
                    {this.state.showFence ?
                     <Col md={3}>
                        <Label  style={{ display: 'inline'}} >Geofence</Label>
                        <InputGroup>
                            <Input placeholder="geofence..." value = {this.state.geofencename}/>
                            <InputGroupAddon addonType="append">
                                <Button color="custom" onClick = {(e) => this.handleGeofenceEditClick(e)} >
                                    <SquareEditOutlineIcon/>
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    : 
                    <Col md={3}>
                        <Label  style={{ display: 'inline'}} >Default Value</Label>
                        {/* <Input type='text' value = {this.state.geofencename}/> */}
                        {/* <InputGroup> */}
                        <EditableText
                            type='text'
                            //  defaultValue={this.props.layout ? this.props.layout.name : ''} 
                            //  defaultValue={this.state.selectedRuleValue}
                            defaultValue={this.props.selectedRule.value}  
                            exitOnEnterKeyPress={false}
                            onSave={newVal => {this.handleEditInputChange(newVal)}}
                        />
                        {/* </InputGroup> */}
                    </Col>
                    }
                    </Fragment>
                    }
                </Row>
               
            {/* } */}
            </div>
        )
    }

    handleMapOnAddClick = (selectedFenceOption) => {
        // this.currentFenceRuleitem.geofenceid = selectedFenceOption.value;
        // this.currentFenceRuleitem.geofencename = selectedFenceOption.label;
        this.setState((preState)=>{
            return({
                geofencename: selectedFenceOption.label,
            });
        });
    }

    handleOnAddNewClick = (shapeObj) => {
        this.props.upsertGeofence(shapeObj);
    }

    renderGeoModal = () => {
        let fenceOptions = generateSelectOptions(this.props.allfences, 'fencename', 'fenceid');

        return (
            // <GeofenceModal
            //     modalSetVisible = {this.state.geofenceModal}
            //     toggle = {this.geofenceToggle}
            //     allfences = {this.props.allfences}
            //     fenceOptions = {fenceOptions}
            //     fenceId = {this.currentFenceId}
            //     handleOnAddClick = {this.handleMapOnAddClick}
            //     handleOnAddNewClick = {this.handleOnAddNewClick}
            // />
            <BasicGeofenceModal
                modalSetVisible = {this.state.geofenceModal}
                toggle = {this.geofenceToggle}
                allfences = {this.props.allfences}
                fenceOptions = {fenceOptions}
                fenceId = {this.currentFenceId}
                handleOnAddClick = {this.handleMapOnAddClick}
                handleOnAddNewClick = {this.handleOnAddNewClick}
                fenceDefaultName = {this.props.actionname}
            />
        )
    }

   
    render(){
        return(
            <div>
                {this.renderAssetLine()}
                <hr/>
                {this.renderRuleLine()}
                {this.renderGeoModal()}
            </div>
        )
    }
}

export default BasicRulesForm; 

