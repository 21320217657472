/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import {Modal} from "react-overlays";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getCurrentUser } from '../../../factories/auth';

//import UserAccessForm from './userAccessForm';
import './ClientAddModal.css';

const backdropStyle = {
    position: 'fixed',
    zIndex: 1040,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#000',
    opacity: 0.5,
    overflow: 'auto',
};

const modalStyle = function () {
    const top = 5;
    const left = 5;

    return {
        position: 'fixed',
        width: 800,
        height: 650,
        zIndex: 1040,
        top: top + '%',
        left: left + '%',
        border: '0px solid #ffffff',
        backgroundColor: 'white',
        // boxShadow: '0 5px 15px rgba(0,0,0,.5)',
        padding: 10,
        overflow: 'auto',
        resize: 'both'
    };
};
class ClientDiscountModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showModal: false,
            promoteId: 0,
            companyId: 0,
            resellerId: 0,
            promoteName: "",
            promoteType: "",
            promoteValue: 0,
            validStartDate: null,
            validEndDate: null,
            promoteStatus: 1
        };
    }
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.show === true) {
            if (nextProps.selectedDiscount != null) {
                this.setState({
                    showModal: true,
                    promoteId: nextProps.selectedDiscount.promoteId,
                    companyId: nextProps.selectedDiscount.companyId,
                    resellerId: nextProps.selectedDiscount.resellerId,
                    promoteName: nextProps.selectedDiscount.promoteName,
                    promoteType: nextProps.selectedDiscount.promoteType,
                    promoteValue: nextProps.selectedDiscount.promoteValue,
                    validStartDate: nextProps.selectedDiscount.validStartDate.substring(0, 10),
                    validEndDate: nextProps.selectedDiscount.validEndDate.substring(0, 10),
                    promoteStatus: 1
                })
            }
            else {
                this.setState({
                    showModal: true,
                    promoteId: 0,
                    companyId: nextProps.selectedRow.companyId,
                    resellerId: nextProps.selectedRow.id,
                    promoteName: "",
                    promoteType: "dollar",
                    promoteValue: 0,
                    validStartDate: null,
                    validEndDate: null,
                    promoteStatus: 1
                })
            }
        }
        else if (nextProps.show === false) {
            this.setState({
                showModal: false
            })
        }

    }
    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };


    validateForm = () => {
        if (this.state.validStartDate != null && this.state.validEndDate != null && this.state.promoteName != "" && this.state.promoteType != "" && !isNaN(this.state.promoteValue)) {
            if (new Date(this.state.validStartDate).getTime() < new Date(this.state.validEndDate).getTime() && this.state.promoteValue > 0) {
                return true;
            }
        }
        return false;
    }
    onSubmit = () => {
        if (this.state.promoteId > 0) {
            var data = {
                "promoteId": this.state.promoteId,
                "companyId": this.state.companyId,
                "resellerId": this.state.resellerId,
                "promoteName": this.state.promoteName,
                "promoteType": this.state.promoteType,
                "promoteValue": this.state.promoteValue,
                "validStartDate": this.state.validStartDate,
                "validEndDate": this.state.validEndDate,
                "promoteStatus": 1
            }
        }
        else {
            var data = {
                "companyId": this.state.companyId,
                "resellerId": this.state.resellerId,
                "promoteName": this.state.promoteName,
                "promoteType": this.state.promoteType,
                "promoteValue": this.state.promoteValue,
                "validStartDate": this.state.validStartDate,
                "validEndDate": this.state.validEndDate,
                "promoteStatus": 1
            }
        }
        this.props.setDiscount(data).then(Response => this.props.getDiscountList({ "companyId": this.state.companyId, "resellerId": this.state.resellerId }));
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.props.onClose}>New Discount</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='promoteName'
                                    id='promoteName'
                                    placeholder='Discount Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.promoteName}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Amount <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <div style={{ display: "inline-block", width: "15%", paddingRight: "10px" }}>
                                    <Input
                                        type='select'
                                        name='promoteType'
                                        id='promoteType'
                                        placeholder='Discount type'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        defaultValue={this.state.promoteType}
                                    >
                                        <option key={1} value="dollar">$</option>
                                        <option key={2} value="percentage">%</option>
                                    </Input>
                                </div>
                                <div style={{ display: "inline-block", width: "85%" }}>
                                    <Input
                                        name='promoteValue'
                                        id='promoteValue'
                                        placeholder='Discount Amount'
                                        onChange={this.onInputChange}
                                        className="form-control"
                                        defaultValue={this.state.promoteValue}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Start Date <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type="date"
                                    name='validStartDate'
                                    id='validStartDate'
                                    placeholder='startDate'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.validStartDate}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>End Date <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type="date"
                                    name='validEndDate'
                                    id='validEndDate'
                                    placeholder='EndDate'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.validEndDate}
                                />
                            </Col>
                        </Row>
                    </Col>
                </ModalBody>
                <ModalFooter>

                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()} >
                            submit
                        </Button>}
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()} >
                            submit
                        </Button>}

                    <Button outline color='secondary' type="reset" onClick={this.props.onClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}
export default ClientDiscountModal;