/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Card, CardBody, Label, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';

import { AutoRenderAssetTable, RenderAssetTable, RenderAssetClassTable } from './RenderAssetTable'
import RenderAssetTableEdit from './RenderAssetTable'

import ClassCreateModal from './ClassCreateModal'
import EditAssetPanel from './EditAssetPanel';
import { SingleSelection } from './SelectionComponents'
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import ClassDisplayDetail from './ClassDisplayDetail';
import AlertModal from './AlertModal';

export default class ClassDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetClassToEdit: null,
      assetClassToDelete: null,
      classAttributes: [],
      modalVisible: false
    }
  }

  closeDetailsPanel = async () => {
    await this.props.reloadData("assetclass").then(() => this.setState({ assetClassToEdit: null }));
  }

  onTableEditClick = (id) => {
    this.setState({ assetClassToEdit: id });
    this.props.getAssetClassAttributeAjax(id).then(data => {
      if (data) {
        this.setState({ classAttributes: data });
      }
    });
  }
  onTableDeleteClick = (e, id) => {
    e.stopPropagation();
    let ac = this.props.assetClassList.find(ac => ac.id == id)
    if (ac && ac.assets && ac.assets.length == 0)
      this.setState({ assetClassToDelete: id })
    else
      // show alert
      // alert('Remove assets first before deleting')
      this.setState({ modalVisible: true });

  }
  deleteAssetClass = () => {
    this.closeDetailsPanel()
    this.props.deleteAssetClass(this.state.assetClassToDelete)
    this.toggleDeleteModalOff()
  }
  toggleDeleteModalOff = () => { this.setState({ assetClassToDelete: null }) }
  toggleAlertModalOff = () => {
    this.setState({ modalVisible: false });
  }

  render() {
    const onRowClick = this.onTableEditClick
    const onEditClick = this.onTableEditClick
    const onDeleteClick = this.onTableDeleteClick
    return (
      <>
        <ConfirmDeleteDialog modalVisible={!!this.state.assetClassToDelete}
          onConfirm={this.deleteAssetClass}
          toggleOff={this.toggleDeleteModalOff}
          msg="Are you sure you want to delete this asset?"
        />
        <AlertModal modalVisible={this.state.modalVisible}
          // onConfirm={this.deleteAssetClass}
          toggleOff={this.toggleAlertModalOff}
          msg="To remove Asset class, you need to remove the Asset linked to this asset class in 'All Assets' page"
        />
        <Collapse isOpen={this.state.assetClassToEdit == undefined || this.state.assetClassToEdit == null}>
          <Card>
            <div className='card-pg'>
              <ClassCreateModal
                assetList={this.props.assetList}
                deviceTypes={this.props.deviceTypes}
                addClassToStore={(data) => {
                  this.props.addClassToStore(data)
                  // Receives a callback from redux action with newly id
                  // .then(res => {
                  //   this.setState({ selectedClass: (res.payload && res.payload.id) })
                  // })
                }}
                updateClassOnStore={this.props.updateClassOnStore}
                assetClassList={this.props.assetClassList}
                userObj={this.props.userObj}
              />
              <RenderAssetClassTable
                assetClasses={this.props.assetClassList}
                tableActions={{ onRowClick, onEditClick, onDeleteClick }}
              />
            </div>
          </Card>
        </Collapse>
        <Collapse isOpen={this.state.assetClassToEdit != undefined && this.state.assetClassToEdit != null}>
          {this.state.assetClassToEdit &&
            <ClassDisplayDetail
              key={this.state.assetClassToEdit}
              closeDetailsPanel={this.closeDetailsPanel}
              deviceTypes={this.props.deviceTypes}
              assetList={this.props.assetList}
              details={this.props.assetClassList.find(({ id }) => id == this.state.assetClassToEdit)}
              updateClassOnStore={this.props.updateClassOnStore}
              customAttributeActions={{
                addNewCustomAttribute: this.props.addNewCustomAttribute,
                updateCustomAttribute: this.props.updateCustomAttribute,
                deleteCustomAttribute: this.props.deleteCustomAttribute,
              }}
              calculateAttributeActions={{
                upsertCalculateAttribute: this.props.upsertCalculateAttribute,
                deleteCalculateAttribute: this.props.deleteCalculateAttribute,
              }}
              assetClassList={this.props.assetClassList}
              companyList={this.props.companyList}
              userObj={this.props.userObj}
              classAttributes={this.state.classAttributes}
            />
          }
        </Collapse>
      </>
    );
  }
}

function LabelSelectionInput(props) {
  return (
    <Row style={{ flexGrow: 1, alignItems: 'center', marginBottom: 5 }}>
      <Col md={3} style={{ textAlign: 'left' }}>
        <Label htmlFor={props.forIdName} style={{ display: 'inline', width: '100%' }} > {props.label}</Label>
        <SingleSelection
          {...props} />
      </Col>
    </Row>
  )
}