/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Collapse } from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Select from 'react-select';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { toast } from "react-toastify";
import log from 'loglevel';

class CompanyPaymentAdjustment extends Component {
    constructor(props){
        super(props);
        this.state={
            data:[],
            startDateMoment: moment("2020-10-01"),
            endDateMoment: moment("2020-10-30"),
        }
    }

    handleDateRangeChange = ({ startDate, endDate }) => {
        //  
        //  
        this.setState({
            startDateMoment: startDate,
            endDateMoment: endDate
        }, this.loadPaymentHistory)
    }
    renderTimeRange = () => {
        return (
          <DateRangePicker
            isOutsideRange={() => false}
            startDate={this.state.startDateMoment} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={this.state.endDateMoment} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            showClearDates = {true}
            small = {true}
            block = {true}
            // disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
          />
                                                   
        );
    }

    timeFormat = (cell, row) => {
        let time = ''
        if (cell){
          // time = cell.split('.')[0].replace('T', ' ');
          // time = new Date(cell).toLocaleString('en-US', { timeZone: 'America/New_York' });  
          time = new Date(cell).toLocaleString('en-US', { timeZone: 'UTC' });  
        }
        
        return (
          <span>{time}</span>
        )
      }
    

    renderAdjustmentList = () => {
        const {startDateMoment, endDateMoment} = this.state;
        let data = this.props.adjustmentList;
        if (this.props.adjustmentList && startDateMoment && endDateMoment) {
            data = this.props.adjustmentList.filter(item => (startDateMoment <= moment(item.logDate) && endDateMoment >= moment(item.logDate)));
        }
        return(
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                        <Row style={{marginBottom:'15px'}}>
                            <Col md={6}>
                            </Col>
                            <Col md={6}>
                                {this.renderTimeRange()}
                            </Col>
                        </Row>
                        <Row mt={3}>
                            <Col md={12}>
                                <BootstrapTable
                                    //  data={ this.props.adjustmentList }  
                                     data={ data }  
                                     tableHeaderClass = 'header_white' 
                                     pagination = {true} 
                                       
                                       
                                     bordered={ false } 
                                     // insertRow 
                                     ref='table' 
                                >
                                    <TableHeaderColumn dataField='id' isKey={ true } dataFormat={this.invoiceIdFormater} dataAlign="center" width='8%' hidden >Log ID</TableHeaderColumn>
                                    <TableHeaderColumn dataField='orderId' dataAlign="center" dataSort width='10%' >Order ID</TableHeaderColumn> 
                                    <TableHeaderColumn dataField='orderName' dataAlign="center" dataSort width='15%' >Order</TableHeaderColumn> 
                                    {/* <TableHeaderColumn dataField='invTtl' dataAlign="center" dataSort width='8%' >Amount</TableHeaderColumn> */}
                                    <TableHeaderColumn dataField='operation' dataAlign="center" dataSort width='15%' >Adjustment Type</TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='fedTax' dataAlign="center" dataSort width='8%' >Federal Tax</TableHeaderColumn> */}
                                    {/* <TableHeaderColumn dataField='discountAmt' dataAlign="center" dataSort width='8%' >Discount</TableHeaderColumn> */}
                                    {/* <TableHeaderColumn dataField='invAmt' dataAlign="center" dataSort width='8%' >Total Amount</TableHeaderColumn> */}
                                    <TableHeaderColumn dataField='logDate' dataFormat={this.timeFormat} dataAlign="center" dataSort width='20%' >Adjustment Date</TableHeaderColumn>
                                    {/* <TableHeaderColumn dataField='createDt' dataFormat={this.timeFormat} dataAlign="center" dataSort width='12%' >Update Date</TableHeaderColumn> */}
                                    <TableHeaderColumn dataField='description' dataAlign="center" dataSort >Comment</TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }

    render() {
        return(
            <Fragment>
                {this.renderAdjustmentList()}
            </Fragment>
        );
    }

}

export default CompanyPaymentAdjustment;