/*eslint-disable*/
import React, { Component } from "react";
// import BootstrapTable from 'react-bootstrap-table-next';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import uuid from "uuid/v4";
import AddPaymentModal from "./payment/addPaymentModal";
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { toast } from "react-toastify";
import moment from 'moment';
import { MonitorCellphoneStarIcon } from "mdi-react";

class CompanyAccountHistoryComp extends Component {
  constructor(props) {
    super(props);

    this.state = {
        payments: [
          {
            id: "p1234567",
            method: "Visa Credit Card",
            status: "Paid",
            date: "03/19",
            amount: "$100 CAD"
          },
          {
            id: "p1234568",
            method: "Master Credit Card",
            status: "Paid",
            date: "03/19",
            amount: "$100 CAD"
          },
          {
            id: "p1234569",
            method: "Visa Credit Card",
            status: "Paid",
            date: "03/19",
            amount: "$100 CAD"
          }
        ],
        expanded: [0, 1],
        currentAccount: null,
        startDateMoment: moment("2020-04-30"),
        endDateMoment: moment("2020-05-10"),
    }
    
    this.addCard = this.addCard.bind(this);
  }

  handleOnExpand = (row, isExpand, rowIndex, e) => {
    alert("abc")
    if (isExpand) {
      this.setState(() => ({
        expanded: [...this.state.expanded, row.id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== row.id)
      }));
    }
  }

  addCard(card) {
    //How they know the item passing in is the items's array item?
    let newCard = { ...card, id: uuid() }; //add the id to the new item.
    this.setState(state => ({
      cards: [...state.cards, newCard] // How they know the state is the OLD state above?
      // add the new item to the current items state list
      // finally put them total to "items"
    }));
  }
  // --------------------------------------------------------------------------------------------
  loadPaymentHistory = () => {
    let startDate = null;
    let endDate = null;
    if (this.state.startDateMoment && this.state.endDateMoment){
      startDate = this.state.startDateMoment.format('YYYY-MM-DD');   
      endDate = this.state.endDateMoment.format('YYYY-MM-DD');             
    }else{
      console.error('Have to choose start and end date');
      toast.error('Have to choose start and end date');
      return 
    }     

    if (this.state.currentAccount) {
      var param = {
        // "accId": this.state.currentAccount.id,
        "objId": this.state.currentAccount.id,
        "fromDate": startDate,
        "toDate": endDate
        // "fromDate": "2020-04-30",
        // "toDate":"2020-05-10"
      };
      this.props.getCustomerPaymentHistory(param);
    }else{
      console.error('No account is selected');
      toast.error('No account is selected');
      return 
    }
   
  }

  handleAccountChange = (val) => {
    if (val) {
    }
     
    this.setState({
      currentAccount: val    
    }, () => {
      this.loadPaymentHistory();
    });
 }

  renderAccountDropdown = () => {
    return (
      <Select 
        // isClearable
        // isSearchable
        options = {this.props.accountList}
        getOptionLabel={({ cardMask }) => cardMask}
        getOptionValue={({ id }) => id}
        value={this.state.currentAccount}
        // // defaultValue={}
        onChange = {this.handleAccountChange}
        // styles={customStyles}
        // {...props}
      />
    );
  }

  handleDateRangeChange = ({ startDate, endDate }) => {
    //  
    //  
    this.setState({
        startDateMoment: startDate,
        endDateMoment: endDate
    }, this.loadPaymentHistory)
}

  renderTimeRange = () => {
    return (
      <DateRangePicker
        isOutsideRange={() => false}
        startDate={this.state.startDateMoment} // momentPropTypes.momentObj or null,
        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
        endDate={this.state.endDateMoment} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
        onDatesChange={this.handleDateRangeChange}//{({ startDateMoment, endDateMoment }) => this.setState({ startDateMoment, endDateMoment })} // PropTypes.func.isRequired,
        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        showClearDates = {true}
        small = {true}
        block = {true}
        // disabled = {!this.state.selectedAsset || this.state.selectedAsset.length == 0}
      />
                                               
    );
  }

  timeFormat = (cell, row) => {
    let dataStr ='';
    if (row){
      dataStr = `${row.trxDt} ${row.trxTime}`
    }
   
    return(
        <span>{dataStr}</span>
    );
}

  render() {
    const products = [...Array(2).keys()].map(p => {
      return {
        id: p,
        name: "IOT Basic",
        price: 2100 + p
      };
    });

    const expandRow = {
      renderer: row => (
        <div>
          <BootstrapTable
            keyField="id"
            data={ products } 
            columns={columns2}
          
          />
        </div>
      ),
      
    };

    const columns2 = [
      {
        dataField: "id",
        text: "Product ID"
      },
      {
        dataField: "name",
        text: "Product Name",
        sort: true
      },
      {
        dataField: "price",
        text: "Product Price"
      }
    ];

    const columns = [
      {
        dataField: "id",
        text: "id"
      },
      {
        dataField: "method",
        text: "Payment Method",
        sort: true
      },
      {
        dataField: "status",
        text: "Status"
      },
      {
        dataField: "date",
        text: "Paid Date"
      },
      {
        dataField: "amount",
        text: "Amount"
      }
    ];

    let data = [];
    let message = '';
    if (this.props.paymentHistory && Array.isArray(this.props.paymentHistory.historyList) && this.props.paymentHistory.historyList.length > 0) {
      let firstItem = this.props.paymentHistory.historyList[0];
      if (typeof firstItem === 'string' || firstItem instanceof String) {
        message = firstItem;
      }else{
        data = this.props.paymentHistory.historyList;
      }
    }
    return(
    <Col md={12}>
      <Card>
        <CardBody>
          <Row style={{marginBottom:'15px'}}>
            <Col md={6}>
              {this.renderAccountDropdown()}
            </Col>
            <Col md={6}>
              {this.renderTimeRange()}
            </Col>
          </Row>
          {/* <BootstrapTable
            keyField="id"
            data={ this.state.payments } 
            columns={columns}
            expandRow={expandRow}
          /> */}
            
          <Row mt={3}>
          <Col md={12}>
            <BootstrapTable 
                data={ data}  
                // tableHeaderClass = 'header_white' 
                pagination = {false} 
                  
                  
                bordered={ false } 
                // insertRow 
                ref='table' 
            >
                  <TableHeaderColumn dataField='trxId' isKey={ true } dataAlign="center" width='10%' hidden >Transaction Id</TableHeaderColumn>
                  <TableHeaderColumn dataField='trxNum' dataAlign="center" dataSort width='10%' >Transaction Number</TableHeaderColumn> 
                  <TableHeaderColumn dataField='ordrId' dataAlign="center" dataSort width='10%' >Order Id</TableHeaderColumn>
                  <TableHeaderColumn dataField='trxAmt' dataAlign="center" dataSort width='10%' >Transaction Amount</TableHeaderColumn>
                  <TableHeaderColumn dataField='' dataFormat={this.timeFormat} dataAlign="center" dataSort width='10%' >Transaction Date</TableHeaderColumn>
                  <TableHeaderColumn dataField='refNum' dataAlign="center" dataSort width='10%' >Reference Number</TableHeaderColumn>
                  <TableHeaderColumn dataField='complete' dataAlign="center" dataSort width='10%' >Complete</TableHeaderColumn>
                  <TableHeaderColumn dataField='msg' dataAlign="center" dataSort width='10%' >Message</TableHeaderColumn>
              </BootstrapTable>
              </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
    );
  }
}


export default CompanyAccountHistoryComp;

