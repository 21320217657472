/* eslint-disable */
import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_DEFAULT,
  CHANGE_THEME_TO_LIGHT,
  CHANGE_THEME_TO_CUSTOM,
  LOAD_LOGIN_THEME  
} from '../actions/themeActions';

const initialState = {
  className: 'theme-default',
  loginThemes: [],
  error: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      return { className: 'theme-dark' };
    case CHANGE_THEME_TO_LIGHT:
      return { className: 'theme-light' };
    case CHANGE_THEME_TO_DEFAULT:
      return { className: 'theme-default' };
    case CHANGE_THEME_TO_CUSTOM:
      return { className: 'theme-custom' };
    case LOAD_LOGIN_THEME:
      return {
        ...state,
        loginThemes: action.payload,
      }
    default:
      return state;
  }
}
