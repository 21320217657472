/* eslint-disable */
import PlusIcon from "mdi-react/PlusIcon";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCurrentUser, hasRole } from "../../../factories/auth";
import { clearSelectedCompantToSesstion } from "../../../factories/utils";
import {
    ClearBusinessUnitRelations,
    getBusinessUnits,
    getConsumers,
    getCountries,
    getDepartments,
    getLobs,
    getOperationCountries,
    getPageContent,
    getProvinces,
    getUsers,
    updateConsumer
} from "../../../redux/actions/userAction";

import { toast } from "react-toastify";
import ConsumerEditBoard from "./ConsumerEditBoard";
import ConsumerList from "./ConsumerList";
import ConsumerNavigation from "./ConsumerNavigation";

class ConsumerBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      isAdmin: false,
      listView: true,
      selectedRow: null,
    };
    this.userObj = getCurrentUser();
  }
  async componentDidMount() {
    this.props.getPageContent({ content: "Consumer" });
    if (
      this.props.userObj.resellerCode != null &&
      this.props.userObj.resellerCode != ""
    ) {
      this.props.getBusinessUnits("ByCode", {
        companyCode: this.props.userObj.companyCode,
        resellerCode: this.props.userObj.resellerCode,
      });
    }
    await this.props.getCountries();
    const check =
      this.props.userObj &&
      hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"]);
    this.setState({ isAdmin: check });
    this.props.getConsumers({
      companyCode: this.props.userObj.companyCode,
      resellerCode: this.props.userObj.resellerCode,
      currentUser: this.props.userObj.currentUser,
      Roles: this.props.userObj.role,
    });

    setTimeout(() => {
      let cval = this.props.countryList[0]
        ? this.props.countryList[0].value
        : "";
      if (cval) {
        console.log("cval", cval);
        this.props.getProvinces(cval);
      }
    }, 600);
  }

  handleAddNewButtonClick = (onClick) => {
    this.setState((preState) => ({
      showAddModal: !preState.showAddModal,
    }));

    setTimeout(() => {
      this.setState({ showAddModal: false });
    }, 1);
  };
  handleRowEditClick = (row, showAccount = false) => {
    this.setState(
      {
        showAccount: showAccount,
        selectedRow: row,
      },
      () => {
        this.props.getProvinces(row.customer.country);
        this.toggle();
      }
    );
  };

  toggle = () => {
    this.setState(
      (prevState) => {
        return {
          listView: !prevState.listView,
        };
      },
      () => {
        if (this.state.listView === true) {
          clearSelectedCompantToSesstion();
        }
      }
    );
  };
  onExitClient = () => {
    this.setState({
      listView: true,
      selectedRow: null,
    });
  };

  addNewUserButton = (onClick) => {
    return (
      <InsertButton
        btnContextual="btn-success"
        btnGlyphicon="glyphicon-edit"
        onClick={() => this.handleAddNewButtonClick(onClick)}
      >
        <PlusIcon /> Add Consumer
      </InsertButton>
    );
  };

  onUpdateConsumer = (obj) => {
    this.props.updateConsumer(obj).then((res) => {
      this.props.getConsumers({
        companyCode: this.props.userObj.companyCode,
        resellerCode: this.props.userObj.resellerCode,
        currentUser: this.props.userObj.currentUser,
        Roles: this.props.userObj.role,
      });
      if (res.status == 200) {
        toast.success(res.data);
      } else {
        toast.error(res.data);
      }
    });
  };
  renderCustomerList() {
    return (
      <ConsumerList
        consumerList={this.props.consumerList}
        isAdmin={this.state.isAdmin}
        handleRowEditClick={this.handleRowEditClick}
        showAddModal={this.state.showAddModal}
        addNewUserButton={this.addNewUserButton}
        countryList={this.props.countryList}
        getProvinces={this.props.getProvinces}
        userObj={this.props.userObj}
        BURelationshipList={this.props.BURelationshipList}
        getBusinessUnits={this.props.getBusinessUnits}
        ClearBusinessUnitRelations={this.props.ClearBusinessUnitRelations}
        pageContentList={this.props.pageContentList}
      />
    );
  }
  renderConsumerEditBoard() {
    return (
      <ConsumerEditBoard
        userObj={this.props.userObj}
        selectedRow={this.state.selectedRow}
        countryList={this.props.countryList}
        onUpdateConsumer={this.onUpdateConsumer}
        // provinceList={this.props.provinceList}
        getProvinces={this.props.getProvinces}
      />
    );
  }
  renderNavigation(tabObjs) {
    return (
      <ConsumerNavigation tabObjs={tabObjs} onExitClient={this.onExitClient} />
    );
  }
  render() {
    const { pageContentList } = this.props;

    let displayText;
    if (pageContentList.includes("Devices") /* TEISA */) {
      displayText = "Devices";
    } else {
      displayText = "Active SIM";
    }
    const checkPermission =
      this.props.userObj &&
      hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"]);

    let tabObjs = [
      {
        tabId: "1",
        label: "Consumer Info",
        renderTab: (props) => {
          return this.renderConsumerEditBoard(props);
        },
      },
    ];

    /*
        tabObjs.push(
            {
                tabId: '3',
                label: 'Inventory',
                renderTab: (props) => { console.log("Inventory") }
            }
        );

        tabObjs.push(
            {
                tabId: '4',
                label: displayText,
                renderTab: (props) => { console.log("Active SIM") }
            }
        );
        */
    // -------------------------------------------------------------------------------------------

    return (
      <div>
        {this.state.listView
          ? this.renderCustomerList()
          : this.renderNavigation(tabObjs)}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  let {
    companyObj,
    companyList,
    companyCodeList,
    resellerCodeList,
    userList,
    editUserObj,
    clientList,
    companyAll,
    countryList,
    provinceList,
    consumerList,
    BUCountryList,
    BULOBList,
    BUDivisionList,
    BURelationshipList,
    pageContentList,
  } = state.user;
  let props = {
    companyObj,
    companyList,
    companyCodeList,
    resellerCodeList,
    userList,
    editUserObj,
    clientList,
    companyAll,
    countryList,
    provinceList,
    consumerList,
    BUCountryList,
    BULOBList,
    BUDivisionList,
    BURelationshipList,
    pageContentList,
  };
  return props;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getConsumers,
      updateConsumer,
      getCountries,
      getProvinces,
      getOperationCountries,
      getLobs,
      getDepartments,
      getUsers,
      getBusinessUnits,
      ClearBusinessUnitRelations,
      getPageContent,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumerBoard);
