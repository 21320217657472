/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Line} from 'react-chartjs-2';

class SingleVarLineChartWidget extends Component {
    constructor(props){
        super(props);
        this.state={};
    }

    renderLineChart = (props) => {
        const {chartColor} = props;
        const chartlabel = ['A', 'B', 'C', 'D', 'E'];
        const chartdata1 = [
            {x:'2016-12-25', y:20},
            {x:'2016-12-26', y:30},
            {x:'2016-12-27', y:50},
            {x:'2016-12-28', y:40},
            {x:'2016-12-29', y:10}
        ];
        const chartdata2 = [
            {x:'2016-12-25', y:10},
            {x:'2016-12-26', y:40},
            {x:'2016-12-27', y:30},
            {x:'2016-12-28', y:20},
            {x:'2016-12-29', y:50}
        ];

        const data = {
            // labels: chartlabel,
            datasets: [
                {
                    label: 'Alarm Red',
                    // backgroundColor: 'rgba(255,99,132,0.2)',
                    // backgroundColor: 'red',
                    // borderColor: 'rgba(255,99,132,1)',
                    borderColor: chartColor,
                    pointRadius: 0,
                    // borderWidth: 0.2,
                    // barPercentage: 0.9,
                    // categoryPercentage: 0.6,
                    pointHoverBackgroundColor: 'rgba(255,99,132,0.4)',
                    pointHoverBorderColor: 'rgba(255,99,132,1)',
                    // data: chartdata1,
                    data: this.props.assetData.data,
                },
                // {
                //     label: 'Alarm Orange',
                //     backgroundColor: 'rgba(99,255,100,0.2)',
                //     borderColor: 'rgba(255,99,132,1)',
                //     // borderWidth: 0.2,
                //     barPercentage: 0.9,
                //     categoryPercentage: 0.6,
                //     hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                //     hoverBorderColor: 'rgba(255,99,132,1)',
                //     data: chartdata2
                // },
                // {
                //     label: 'Alarm Yellow',
                //     backgroundColor: 'rgba(99,255,255,0.2)',
                //     borderColor: 'rgba(255,99,132,1)',
                //     // borderWidth: 0.2,
                //     barPercentage: 0.9,
                //     categoryPercentage: 0.6,
                //     hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                //     hoverBorderColor: 'rgba(255,99,132,1)',
                //     data: chartdata1
                // }
            ]
        };

        const options = {
            aspectRatio: 2,
            responsive: true,
            maintainAspectRatio: true,
            showScale: false,
            scales: {
                // bounds: 'data',
                // xAxes: [{
                //     stacked: false,
                //     type: 'category',
                //     ticks:{
                        
                //     },
                //     gridLines: {
                //         // display: false,
                //         // color: "rgba(0, 0, 0, 0)",
                //         drawBorder: true,
                //     }
                // }],
                xAxes: [{
                    display: true,
                    // position: 'bottom',
                    type: 'time',
                    distribution: 'series',
                    // offset: true,
                    time: {
                        unit: 'day'
                    },
                    ticks:{
                        // min: '2016-12-24',
                        // max: '2016-12-30',
                        // sampleSize: 1,
                        source: 'data',
                        // display: false,
                        fontColor: chartColor,
                        maxRotation: 0,
                        padding: 10,
                        callback: function(value, index, values) {
                            //  
                            if (index === 0) {
                                return 'Last 24 hrs';
                            } 
                            if (index === values.length - 1) {
                                //  
                                return 'Now';
                            }
                            return null;
                        }
                    },
                    gridLines: {
                        // display: false,
                        display: true,
                        color: chartColor,
                        // color: "rgba(0, 0, 0, 0)",
                        drawBorder: true,
                        drawOnChartArea: false,
                        drawTicks: false,
                    }
                }],
                yAxes: [{
                    // stacked: false,
                    display: true,
                    // type: 'linear',
                    position: 'left',
                    // color:'black',
                    // // offset: true,
                    // scaleLabel: {
                    //     display: true,
                    // },
                    ticks: {
                        // min: 0,
                        // max: 70,
                        // sampleSize: 3,
                        display: false,
                        fontColor: chartColor,
                        // callback: function(value, index, values) {
                        //     //  
                        //     if (index === 0) {
                        //         //  
                        //         return values[0] + '/' + values[values.length-1];
                        //     }else{
                        //         return null;
                        //     }
                        //     // return null;
                        // }
                    },
                    scaleLabel: {
                        display: true,
                        // labelString: '1k = 1000'
                    },
                    gridLines: {
                        display: true,
                        color: chartColor,
                        // color: "rgba(0, 0, 0, 0)",
                        drawBorder: true,
                        drawOnChartArea: false,
                        drawTicks: false,

                    }
                }]
            },
            legend: {
                display: false,
                position: 'right',
                labels: {
                    boxWidth: 10,
                    fontSize: 12,
                    fontColor: 'rgb(255, 99, 132)'
                }
            }
        }
        return(
            <Line
                width={'200px'}
                height={null} 
                data={data} 
                options={options}
            >    
            </Line>
        )
    }

    render(){
        // let backgroundColor = this.props.assetData.backgroundcolor;
        // let chartColor = 'white';

        let chartColor = this.props.assetData.backgroundcolor;
        let backgroundColor = 'white'
        let displayname = this.props.assetData.displayname;
        let value = this.props.assetData.lastvalue;
        let units = this.props.assetData.units;

        return(
            <Card >
                <CardBody style={{padding: '20px 30px', border: '1px solid black', backgroundColor: backgroundColor,}}>
                    <Row style= {{margin: '0px 0px 10px 0px'}}>
                        <h4 style={{color: chartColor}}>
                        {value}
                        {' '}
                        {units}
                        {' '}
                        {displayname}</h4>
                    </Row>
                    <Row>
                        {this.renderLineChart({chartColor})}
                    </Row>
                </CardBody>
            </Card>
        );
    }
}

export default SingleVarLineChartWidget;