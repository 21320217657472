/* eslint-disable */
import { PARKING_KEYS, PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3 } from '../constants/dataConstants';

// get days of specified month
const mGetDate = (year, month) => {
    var aDay = new Date(year, month, 0);
    return aDay.getDate();
}

//get level data from original data source
const getLevelData = (originData) => {
    const objKeys = Object.keys(originData)
    let level1Data = []
    let level2Data = []
    let level3Data = []
    let levelTotalData = []

    for (let key of objKeys) {
        // done if we got all level data
        if (level1Data && level2Data && level3Data && levelTotalData) {
            break;
        }
        if (key.includes(":Level1") ||
            key.includes(":Level2") ||
            key.includes(":Level3") ||
            key.includes(":LevelTotal")) {
            let levelName = key.split(":")[1]
            switch (levelName) {
                case "Level1":
                    // only have to get one device level data
                    if (!level1Data) {
                        level1Data = originData[key].data
                    }
                    break;
                case "Level2":
                    // only have to get one device level data
                    if (!level2Data) {
                        level2Data = originData[key].data
                    }
                    break;
                case "Level3":
                    // only have to get one device level data
                    if (!level3Data) {
                        level3Data = originData[key].data
                    }
                    break;
                case "LevelTotal":
                    // only have to get one device level data
                    if (!levelTotalData) {
                        levelTotalData = originData[key].data
                    }
                    break;
                default:
                //should not be here, do nothing
            }
        }
    }
    return [levelTotalData, level1Data, level2Data, level3Data]
}

const joinLevelData = (data) => {
    let joinedData = []
    let l1data = [];
    let l2data = [];
    let l3data = [];
    let ltdata = [];
    if (data.length > 0) {
        if (l1data != null) {
            for (let l1data of data[1]) {

                l1data["level"] = 1

                joinedData.push(l1data)

            }
        }

        if (l2data != null) {
            for (let l2data of data[2]) {

                l2data["level"] = 2

                joinedData.push(l2data)

            }
        }

        if (l3data != null) {
            for (let l3data of data[3]) {

                l3data["level"] = 3

                joinedData.push(l3data)

            }
        }

        if (ltdata != null) {
            for (let ltdata of data[0]) {

                ltdata["level"] = 0

                joinedData.push(ltdata)

            }
        }
    }

    return joinedData;
}

const formatMonthLevelData = (originData) => {
    let formattedMonthLevelData = []
    let level1MonthData = []
    let level2MonthData = []
    let level3MonthData = []
    let levelTotalMonthData = []

    const objKeys = Object.keys(originData)
    for (let key of objKeys) {
        // done if we got all level data
        if (level1MonthData && level2MonthData && level3MonthData && levelTotalMonthData) {
            break;
        }
        if (key.includes(":Level1") ||
            key.includes(":Level2") ||
            key.includes(":Level3") ||
            key.includes(":LevelTotal")) {
            let levelName = key.split(":")[1]
            switch (levelName) {
                case "Level1":
                    // only have to get one device level data
                    if (!level1MonthData) {
                        level1MonthData = originData[key].data
                    }
                    break;
                case "Level2":
                    // only have to get one device level data
                    if (!level2MonthData) {
                        level2MonthData = originData[key].data
                    }
                    break;
                case "Level3":
                    // only have to get one device level data
                    if (!level3MonthData) {
                        level3MonthData = originData[key].data
                    }
                    break;
                case "LevelTotal":
                    // only have to get one device level data
                    if (!levelTotalMonthData) {
                        levelTotalMonthData = originData[key].data
                    }
                    break;
                default:
                //should not be here, do nothing
            }
        }
    }

    //now combine the level data into one data structure
    let levelMonthData = []
    let l1data = [];
    let l2data = [];
    let l3data = [];
    let ltdata = [];

    if (l1data != null) {
        for (l1data of level1MonthData) {
            l1data["level"] = 1
            levelMonthData.push(l1data)
        }
    }

    if (l2data != null) {
        for (l2data of level2MonthData) {
            l2data["level"] = 2
            levelMonthData.push(l2data)
        }
    }

    if (l3data != null) {
        for (l3data of level3MonthData) {
            l3data["level"] = 3
            levelMonthData.push(l3data)
        }
    }

    if (ltdata != null) {
        for (let ltdata of levelTotalMonthData) {
            ltdata["level"] = 0
            levelMonthData.push(ltdata)
        }
    }

    //get days of specified month
    const mGetDate = (year, month) => {
        var aDay = new Date(year, month, 0);
        return aDay.getDate();
    }

    let oneDay = new Date()
    const theMonth = oneDay.getMonth()
    const monthDays = mGetDate(oneDay.getFullYear(), theMonth + 1)
    oneDay.setDate(1)
    // generate level data array of the month in each day
    // {
    //      slot: date -- format: m/d  (only the first day of the month is m/d/yyyy)
    //      total: total level data of the day
    //      l1: level 1 data of the day
    //      l2: level 2 data of the day
    //      l3: level 3 data of the day
    // }
    //
    for (let i = 1; i <= monthDays; i++) {
        if (i == 1) {
            formattedMonthLevelData.push({
                slot: (theMonth + 1) + '/' + oneDay.getDate(), //+ '/' + oneDay.getFullYear(),
                total: 0,
                l1: 0,
                l2: 0,
                l3: 0
            })
        } else {
            formattedMonthLevelData.push({
                slot: (theMonth + 1) + '/' + oneDay.getDate(),
                total: 0,
                l1: 0,
                l2: 0,
                l3: 0
            })
        }
        //move to next day
        oneDay.setDate(i + 1)
    }
    // sum level data of each day 
    levelMonthData.forEach((fd) => {
        let recordDate = new Date(fd.x)
        if (recordDate.getFullYear() == oneDay.getFullYear() && recordDate.getMonth() == theMonth) {
            switch (fd.level) {
                case 0:
                    //formattedMonthLevelData[recordDate.getDate()-1].total = parseInt(fd.y, 10);
                    formattedMonthLevelData[recordDate.getDate() - 1].total = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[0]).toFixed(2));
                    break;
                case 1:
                    //formattedMonthLevelData[recordDate.getDate()-1].l1 = parseInt(fd.y, 10);
                    formattedMonthLevelData[recordDate.getDate() - 1].l1 = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[1]).toFixed(2));
                    break;
                case 2:
                    //formattedMonthLevelData[recordDate.getDate()-1].l2 = parseInt(fd.y, 10);
                    formattedMonthLevelData[recordDate.getDate() - 1].l2 = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[2]).toFixed(2));
                    break;
                case 3:
                    //formattedMonthLevelData[recordDate.getDate()-1].l3 = parseInt(fd.y, 10);
                    formattedMonthLevelData[recordDate.getDate() - 1].l3 = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[3]).toFixed(2));
                    break;
            }
        }
    })

    return formattedMonthLevelData;
}

const getChartHour = (date) => {
    return date.getMinutes() < 30 ? date.getHours() : date.getHours() + 1
}

export const formatDayLevelData = (originData) => {
    let formattedDayLevelData = []
    let levelData = getLevelData(originData)
    let joinedLevelData = joinLevelData(levelData)
    let today = new Date()

    for (let i = 1; i <= 24; i++) {
        formattedDayLevelData.push({
            slot: i,
            total: 0,
            totaln: 0,
            l1: 0,
            l1n: 0,
            l2: 0,
            l2n: 0,
            l3: 0,
            l3n: 0
        })
    }

    // average level data of each hour
    joinedLevelData.forEach((fd) => {
        let recordDate = new Date(fd.x)
        if (recordDate.getFullYear() == today.getFullYear() &&
            recordDate.getMonth() == today.getMonth() &&
            recordDate.getDate() == today.getDate()) {
            switch (fd.level) {
                case 0:
                    formattedDayLevelData[getChartHour(recordDate) - 1].total = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[0]).toFixed(2));
                    break
                case 1:
                    formattedDayLevelData[getChartHour(recordDate) - 1].l1 = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[1]).toFixed(2));
                    break
                case 2:
                    formattedDayLevelData[getChartHour(recordDate) - 1].l2 = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[2]).toFixed(2));
                    break
                case 3:
                    formattedDayLevelData[getChartHour(recordDate) - 1].l3 = parseFloat((parseInt(fd.y, 10) / PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[3]).toFixed(2));
                    break
            }
        }
    })



    return formattedDayLevelData
}