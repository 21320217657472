/* eslint-disable react/no-array-index-key */
import React from 'react';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './Panel';

const data01 = [{ name: 'Item 1', value: 10, fill: '#2cd8c8' },
  { name: 'Item 2', value: 37, fill: '#91a0ff' },
  { name: 'Item 3', value: 21, fill: '#3d5bf2' },
  { name: 'Item 4', value: 32, fill: '#7e44db' }];

const style = {
  left: 0,
  width: 150,
  lineHeight: '24px',
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    vslue: PropTypes.string,
  })).isRequired,
};

const ActivityRating = ({ t }) => (
  <Panel lg={12} xl={6} md={12} title={t('Example AR Chart')}>
    <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--fitness" width="100%" height={360}>
      <PieChart className="dashboard__chart-pie-container">
        <Tooltip />
        <Pie data={data01} dataKey="value" cy={180} innerRadius={130} outerRadius={160} label />
        <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style} content={renderLegend} />
      </PieChart>
    </ResponsiveContainer>
  </Panel>
);

ActivityRating.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ActivityRating);
