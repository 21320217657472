/* eslint-disable */
import React, { Component, useState } from 'react';
import { Row, Col } from 'reactstrap';
import AttributeIcon from './AttributeIcon';
import { getAttributeThreshold, getAttributeUnits } from '../../../redux/actions/surveilenceActions';
import '../../../scss/animation.scss';

const GREEN_COLOR = '#00cc00';
const RED_COLOR = '#ff0000';

class SingleAttributeDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attribureData: null,
            ruleList: null
        }
    }

    componentDidMount() { }

    componentWillReceiveProps(nextProps) {
        if (nextProps.assetId && nextProps.attribureData) {
            if (nextProps.assetId !== this.assetId || nextProps.attribureData.id !== this.attributeid) {
                this.assetId = nextProps.assetId;
                this.attributeid = nextProps.attribureData.id;
                getAttributeThreshold(nextProps.assetId, nextProps.attribureData.id).then(ruleList => {
                    this.setState({
                        ruleList: ruleList
                    });
                });
            }
        }
    }

    loadThreshold = async () => {
        let ruleList = await getAttributeThreshold(this.assetId, this.attributeid);
        this.setState({ ruleList });
    }

    checkRule = () => {
        try {
            let expressStr = '';
            let dilimiter = ' ';
            this.props.attribureData && this.state.ruleList && this.state.ruleList.forEach((rule, index) => {
                if (index === 0) {
                    expressStr = this.props.attribureData.value.toString() + getOperator(rule.operator) + rule.value.toString();
                } else {
                    expressStr += dilimiter + getRelation(rule.relation) + dilimiter + this.props.attribureData.value.toString() + getOperator(rule.operator) + rule.value.toString();
                }
            });
            return eval(expressStr);
        } catch (e) {
            return false;
        }
    }

    render() {
        let data = {};
        if (this.props.attribureData) {
            data = { ...this.props.attribureData };
            data.units = getAttributeUnits(data.attributename);
            data.isBlink = this.checkRule();
        }
        return (
            <SingleAttributeElement
                {...data}
            />
        );
    }
}

const SingleAttributeElement = ({ attributename, displayname, value, units, isBlink }) => {
    const smallTextStyle = {
        fontSize: '16px',
        lineHeight: '1.6',
    }
    const normalTextStyle = {
        fontSize: '16px',
        lineHeight: '1.6',
    }

    return (
        <div className="sensor-wrapper">
            <div className="status-line" style={{ backgroundColor: isBlink ? RED_COLOR : GREEN_COLOR }}></div>
            <div className="sensor-container">
                <div className="sensor-detail">
                    <AttributeIcon attributeName={attributename} />
                    <div>{value} {attributename === 'voc' ? "PPM" : units}</div>
                </div>
                <div className='sensor-label'>{displayname === 'Temprature' ? 'Temperature' : displayname}</div>
            </div>
        </div>
    );
}

function getOperator(code) {
    switch (code) {
        case 1:
            return "=";
        case 2:
            return ">";
        case 3:
            return ">=";
        case 4:
            return "<";
        case 5:
            return "<="
        case 6:
            return "!="
        default:
            return ""
    }
}

function getRelation(code) {
    switch (code) {
        case 1:
            return "&&";
        case 2:
            return "||";
        default:
            return "";
    }
}

export default SingleAttributeDisplay;
