/* eslint-disable */
import {
    SAVE_USER_DATA_STARTED,
    SAVE_USER_DATA_SUCCEED,
    SAVE_USER_DATA_FAILED,
    ADD_USER_DATA_STARTED,
    ADD_USER_DATA_SUCCEED,
    ADD_USER_DATA_FAILED,
    DELETE_USER_DATA_STARTED,
    DELETE_USER_DATA_SUCCEED,
    DELETE_USER_DATA_FAILED
} from '../../../constants/actionTypes';//'../../const'

const initState = {
    loading: false,
    type: '',
    payload: ''
};

export default function (state = initState, action) {
    switch (action.type) {
        case SAVE_USER_DATA_STARTED:
            return {
                loading: true,
                type: SAVE_USER_DATA_STARTED
            };
        case SAVE_USER_DATA_SUCCEED:
            return {
                ...state,
                loading: false,
                type: SAVE_USER_DATA_SUCCEED,
                payload: action.payload
            };
        case SAVE_USER_DATA_FAILED:
            return {
                ...state,
                loading: false,
                type: SAVE_USER_DATA_FAILED,
                payload: action.payload
            };
        case ADD_USER_DATA_STARTED:
            return {
                loading: true,
                type: ADD_USER_DATA_STARTED
            };
        case ADD_USER_DATA_SUCCEED:
            return {
                ...state,
                loading: false,
                type: ADD_USER_DATA_SUCCEED,
                payload: action.payload
            };
        case ADD_USER_DATA_FAILED:
            return {
                ...state,
                loading: false,
                type: ADD_USER_DATA_FAILED,
                payload: action.payload
            };
        case DELETE_USER_DATA_STARTED:
            return {
                loading: true,
                type: DELETE_USER_DATA_STARTED
            };
        case DELETE_USER_DATA_SUCCEED:
            return {
                ...state,
                loading: false,
                type: DELETE_USER_DATA_SUCCEED,
                payload: action.payload
            };
        case DELETE_USER_DATA_FAILED:
            return {
                ...state,
                loading: false,
                type: DELETE_USER_DATA_FAILED,
                payload: action.payload
            };
        default:
            return state;
    }
}