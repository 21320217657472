/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
// import {Tabs, Tab} from 'react-bootstrap';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// import 'bootstrap/dist/css/bootstrap.min.css';
import classnames from 'classnames';
import GeoFenceMap from './GeoFenceMap';
import GeoFenceMapCard from './GeoFenceMapCard';
import GeoFenceListCard from './GeoFenceListCard';
import GeoFenceActionCard from './GeoFaenceActionCard';
import icon_add from '../../../shared/img/icon/add.png';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { toast } from "react-toastify";
import {loadDevice, loadDeviceInfo } from '../../../redux/actions/mapActions'
import {loadAllGeoFences, updateGeofence, deleteGeofence, toggleSuccessAlert, toggleErrorAlert} from '../../../redux/actions/fenceAction'
import NewFenceModal from './NewFenceModal'
import { Alert, UncontrolledAlert  } from 'reactstrap';
import AlertMessage from '../../AlertComponent/components/AlertMessage';
import {FENCE_MSG_TOGGLE_OFF} from '../../../constants/actionTypes';
import {toggleAlertOff} from '../../../redux/actions/alertAction';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class GeoFenceBoard extends Component{
    constructor(props){
        super(props);
        this.myMapBoard = React.createRef();
        this.confirmDialog = React.createRef();
        this.state={
            modalSetVisible: false,
            currentDeivecTypeId: '5cf828390499f50b995ae851',
            currentDeviceTypeName: 'Oyster',
            fenceDeviceTypeName: '',
            currentDeviceId: '',
            currentDeviceName: '',
            fenceDeviceName: '',
            showDetails: false,
            // currentShape: {
            //     type:'polygon', 
            //     data:[
            //         [
            //             {lat: 43.822311404399926, lng: -79.56390763395996},
            //             {lat: 43.82020588194602, lng: -79.55043221586914},
            //             {lat: 43.812402415964726, lng: -79.5622768508789},
            //             {lat: 43.8135172592541, lng: -79.5758380996582}
            //         ],
            //     ]
            // },
            currentShape: {},
            fenceData: [
                {   fenceid: 0,
                    fencename: 'fence 1',
                    shape: 0, 
                    devicetypeid: '',
                    devicetypename: 't1',
                    deviceid:'',
                    devicename: 'aaaa', 
                    geodata:{
                        center: {lat: 43.8135172592541, lng: -79.5758380996582},
                        radius: 80
                    }
                },
                {   fenceid: 2,
                    fencename: 'fence 2',
                    shape: 1, 
                    devicetypeid: '',
                    devicetypename: 't1',
                    deviceid:'',
                    devicename: 'bbbb', 
                    geodata:{
                            south: 43.81258922466195, 
                            west: -79.57085991972656, 
                            north: 43.8211357979583, 
                            east: -79.55026055449218
                        }
                },
                {   fenceid: 3,
                    fencename: 'fence 3',
                    shape: 2,
                    devicetypeid: '',
                    devicetypename: 't2',
                    deviceid:'',
                    devicename: 'cccc', 
                    geodata:[
                        [
                            {lat: 43.822311404399926, lng: -79.56390763395996},
                            {lat: 43.82020588194602, lng: -79.55043221586914},
                            {lat: 43.812402415964726, lng: -79.5622768508789},
                            {lat: 43.8135172592541, lng: -79.5758380996582}
                        ],
                    ]
                },
            ],
            activeTab: '1',
        }
    }

    componentWillReceiveProps(nextProps) {
        // alert("yew timeout componentWillReceiveProps");
        if (nextProps.successvisible == true){
            // alert("yes timeout");
            window.setTimeout(()=>{
                nextProps.toggleSuccessAlert()
              },2000)
        }
        if (nextProps.errorvisible == true){
            window.setTimeout(()=>{
                nextProps.toggleErrorAlert()
              },2000)
        }

    }

    // static getDerivedStateFromProps(props, state) {
    //     alert("yew timeout getDerivedStateFromProps");
    //     return({
    //         // currentDeviceTypeId : props.currentDeviceTypeId,
    //         // currentDeviceTypeName : props.currentDeviceTypeName,
    //         // currentDeviceId : props.currentDeviceId,
    //         // currentDeviceName : props.currentDeviceName,
    //     });
    // } 

    // UNSAFE_componentWillMount( nextProps,  nextState){
    //     alert("yew timeout componentWillUpdate");
    // }

    componentDidMount(){
        this.props.loadDevice();
        this.props.loadDeviceInfo({devicetype: this.state.currentDeivecTypeId},'fence');
        // alert('call');
        this.props.loadAllGeoFences({devicetypeid: this.state.currentDeivecTypeId});
        // this.props.loadDivceMapData({devicetype:this.props.currentDeviceTypeId});
        // this.props.loadDeviceAttr({devicetypeid: this.props.currentDeviceTypeId});
    }

    onDeviceTypeChange = (e) =>{
        // alert(e.target.value);
        // alert(e.target.selectedIndex);
        let index = e.target.selectedIndex
        // alert(e.target.options[index].text);
        //  
        this.setState({
            currentDeivecTypeId: e.target.value,
            currentDeviceTypeName: e.target.options[index].text
        });
        this.props.loadDeviceInfo({devicetype: e.target.value},'fence')
    }
    onDeviceChange = (e) =>{
        // alert( 'yes');
        let index = e.target.selectedIndex
        this.setState({
            currentDeviceId: e.target.value,
            currentDeviceName: e.target.options[index].text
        });
    }
    onFilterClick = (e) => {
        this.props.loadAllGeoFences({devicetypeid: this.state.currentDeivecTypeId, deviceid: this.state.currentDeviceId});
    }

    onNewClick = (e) => {
        // this.setState({
        //     modalSetVisible: true,
        // });

        this.onAddFence('');
    }

    // handleFenceListRowClick = (row) => {
    //     let id = row.fenceid;
    //     let fence = this.state.fenceData.find((e, i) => (e.fenceid === id));
    //     // alert(fence.type);
    //      
        
    //     //  
    //     let type = '' ;
    //     switch(fence.shape){
    //         case 0:
    //             type = 'circle';
    //             break;
    //         case 1:
    //             type = 'rectangle';
    //             break;
    //         case 2:
    //             type = 'polygon';
    //             break;
    //     }
    //     this.setState({
    //         currentShape: {type: type, data: fence.geodata},
    //         currentFenceName: fence.fencename,
    //         fenceDeviceTypeName: fence.devicetypename,
    //         fenceDeviceName: fence.devicename
    //     });
    // }

    renderTopCard(){
        return(
            <Col md={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={4}>
                                <Label for="deviceType" style={{display: 'inline', width: "100%"}} >Device Type</Label>
                                <Input type="select" id="deviceType" name="deviceType" width="100%" value={this.state.currentDeivecTypeId} onChange={this.onDeviceTypeChange} >                   
                                    {this.props.devicetypes && this.props.devicetypes.map((item, i) => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        )
                                    })}                                             
                                </Input>
                            </Col>
                            <Col md={4}>
                                <Label for="deviceType" style={{display: 'inline', width: "100%"}} >Device</Label>
                                <Input type="select" id="deviceType" name="deviceType" width="100%" value={this.state.currentDeviceId} onChange={this.onDeviceChange} >     
                                    <option value={0}>All</option>
                                    {/* <option value={1}>Device 1</option>
                                    <option value={2}>Device 2</option>                                                            */}
                                    {this.props.currentDeviceList && this.props.currentDeviceList.map((item, i)=>{
                                        return(
                                            <option key={i} value={item.deviceid}>{item.devicename}</option>
                                        )
                                    })}
                                </Input>
                            </Col>
                            <Col md={4} style={{ textAlign: 'center', justifyContent: 'center',alignItems: 'center' }} >
                                <Label style={{ display: 'inline', width: "100%"}} >&nbsp;</Label>
                                <Button variant="primary" color="custom" type="button" onClick={this.onFilterClick}>
                                    Search
                                </Button>
                                <Button outline variant="primary"  color="custom" type="button" onClick={this.onNewClick}>
                                    <img src={icon_add} style={{width: 20, height: 20}} alt={"Add new"}  /> {'   '}
                                    Add new
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        )

    }

    handleFenceListRowClick = (row) => {
        let id = row.fenceid;
        // alert(id);
         
        let fence = this.props.fenceData.find((e, i) => (e.fenceid == id));
        // alert(fence.type);
         
        
        //  
        let type = '' ;
        switch(fence.shape){
            case 0:
                type = 'circle';
                break;
            case 1:
                type = 'rectangle';
                break;
            case 2:
                type = 'polygon';
                break;
        }
        this.setState({
            currentShape: {type: type, data: JSON.parse(fence.geodata)},
            currentFenceName: fence.fencename,
            currentFenceId: fence.fenceid,
            fenceDeviceTypeName: fence.devicetypename,
            fenceDeviceName: fence.devicename,
            showDetails: true,
        });
    }

    handleRowDeleteClick = (row) => {
         
         
        let id = row.fenceid;
        let fence = this.props.fenceData.find((e, i) => (e.fenceid === id));
        // alert('fence');
         
        this.props.deleteGeofence(fence);
        if (this.state.currentFenceId === id){
            this.setState({
                currentFenceName: '',
                currentFenceId: 0,
                currentShape: {},
                showDetails: false,
            });
        }
    }

    renderFenceList() {
        return(
            // <GeoFenceListCard data = {this.state.fenceData} handleRowClick = {this.handleFenceListRowClick} handleRowDeleteClick = {this.handleRowDeleteClick}/>
            <GeoFenceListCard data = {this.props.fenceData} handleRowClick = {this.handleFenceListRowClick} handleRowDeleteClick = {this.handleRowDeleteClick} handleNewClick ={this.onNewClick}/>
        )
    }

    handleSubmitClick = (obj) =>{
        if (this.state.currentShape && this.state.currentFenceName && this.state.currentFenceName.length > 0){
            this.props.updateGeofence(obj);
            this.setState({
                currentFenceName: '',
                currentFenceId: 0,
                currentShape: {},
                showDetails: false,
            });
        }else{
            toast.error("Fence Name / Shape is empty", {
                autoClose: false
            });
        }
    }
    handleCancleClick = (e) =>{
        this.setState({
            currentFenceName: '',
            currentFenceId: 0,
            currentShape: {},
            showDetails: false,
        });
    }

    handleInputChange = (newVal) => {
        this.setState({
            currentFenceName: newVal,
        });
    }

    renderMapCard() {
        return(
            <GeoFenceMapCard ref={this.myMapBoard} 
                            shape = {this.state.currentShape}
                            devicetypeid = {this.state.currentDeivecTypeId}
                            devicetypename = {this.state.fenceDeviceTypeName}
                            deviceid = {this.state.currentDeviceId}
                            devicename = {this.state.fenceDeviceName}
                            fencename = {this.state.currentFenceName}
                            fenceid = {this.state.currentFenceId}
                            handleSubmitClick = {this.handleSubmitClick}
                            handleCancleClick = {this.handleCancleClick}
                            handleInputChange = {this.handleInputChange}
            />
        )
    }

    renderMapModal = () => {
        return(
            <Modal 
                isOpen = {this.state.showDetails}
                size = 'lg'
            >
                <ModalHeader>
                    <h4>
                        {this.state.currentFenceId > 0 ? "Edit Fence" : "New Fence"}
                    </h4>
                </ModalHeader>
                <ModalBody style={{padding: '0px'}}>
                    {this.renderMapCard()}
                </ModalBody>
            </Modal>
        )
    }

    toggle = (tab)=> {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
    }

    renderTab() {
        return(
        <Col md={12}>    
        <Card>
        <CardBody > 
            <div>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                    >
                        <h4><b>GeoFence Information</b></h4>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      <h4><b>Associated Actions</b></h4>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                    {this.renderMapCard()}            
                </TabPane>
                <TabPane tabId="2">                             
                    {/* <GeoFenceActionCard/>               */}
                    Coming Soon.....
                </TabPane>
            </TabContent>
            {/* <Tabs  >
                <Tab eventKey={"1"} title="Home">
                    <Sonnet />
                    tests
                </Tab>
                <Tab eventKey={"2"} title="Profile">
                    <Sonnet />
                    tests2
                </Tab>
                
            </Tabs> */}
            </div>
        </CardBody >
        </Card>
        </Col> 
        );
    }

    modalSetToggle = () => {
        this.setState(prevState => ({
            modalSetVisible: !prevState.modalSetVisible
        }));
    }

    onAddFence = (name) => {
        // alert(name);
        this.setState(prevState => ({
            currentShape:  {   
                fenceid: 0,
                fencename: name,
                shape: -1, 
                devicetypeid: prevState.currentDeivecTypeId,
                devicetypename: prevState.currentDeviceTypeName,
                deviceid:prevState.currentDeviceId,
                devicename: prevState.currentDeviceName, 
                geodata:{}
            },
            currentFenceName: name,
            currentFenceId: 0,
            fenceDeviceTypeName: prevState.currentDeviceTypeName,
            fenceDeviceName: prevState.currentDeviceName,
            showDetails: true,
        }));
    }

    renderModal(){
        return(
            <NewFenceModal 
                modalSetVisible = {this.state.modalSetVisible}
                toggle={this.modalSetToggle}
                onAddFence = {this.onAddFence}

            />
        )
    }

    renderAlert(){
       return(
                // <div>
                // <Alert color = "success" isOpen={this.props.successvisible} toggle={this.onDismiss} toggle={this.props.toggleSuccessAlert} >{this.props.message}</Alert>
                // <Alert color = "danger" isOpen={this.props.errorvisible} toggle={this.onDismiss} toggle={this.props.toggleErrorAlert}>{this.props.message}</Alert>
                // {/* <UncontrolledAlert color="custom" >
                //     {this.props.message}
                //     testestestets
                // </UncontrolledAlert> */}
                // </div>
                <AlertMessage
                    messagevisible = {this.props.messagevisible}
                    message =  {this.props.message}
                    messagetype = {this.props.messagetype}
                    error = {this.props.error}
                    toggle = {()=>{this.props.toggleAlertOff(FENCE_MSG_TOGGLE_OFF)}}
                />
            ); 
    }
    render(){
        return(
            <div style={{width: '100%'}}>
                {/* {this.renderTopCard()} */}
                {/* <GeoFenceMap /> */}
                {/* {this.renderAlert()} */}
                {this.renderFenceList()}
                {/* {this.renderAlert()} */}
                {/* {this.renderMapCard()}
                <GeoFenceActionCard/> */}
                {/* {this.state.showDetails && this.renderTab()} */}
                {/* {this.state.showDetails && this.renderMapCard()}  */}
                {this.renderMapModal()}
                {this.renderModal()}
            </div>
        )
    }
        
}

// export default GeoFenceBoard;

function mapStateToProps(state) {
     
     
    const devicetypes = state.mapboard.types;
    // const devices = state.fence.currentDeviceList;
    // const currentTheme = state.mapfilter.currentThemeId;   
    // const props = {devicetypes, devices};

    const {currentDeviceTypeId,  currentDeviceId, currentDeviceTypeList, currentDeviceList, fenceData, error, message, type, successvisible, errorvisible, messagevisible, messagetype} = state.fence;
    const props = {devicetypes, currentDeviceTypeId, currentDeviceId, currentDeviceTypeList, currentDeviceList, fenceData, error, message, type, successvisible, errorvisible, messagevisible, messagetype} ;
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDevice, loadDeviceInfo, loadAllGeoFences, updateGeofence, deleteGeofence, toggleSuccessAlert, toggleErrorAlert, toggleAlertOff}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoFenceBoard);
