/* eslint-disable */
import React, {Component, Fragment } from 'react';
import {Container, Row, Col, Button, Table} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn, ExportCSVButton } from 'react-bootstrap-table';
import MinusIcon from 'mdi-react/MinusIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import {chunk} from '../../../factories/utils';
import DeviceDetailBoard from '../../DeviceDetail/components/DeviceDetailBoard';
import {ChevronLeftIcon} from 'mdi-react';
// import {
//     MAP_DEVICE_DATA,
//     MAP_ASSET_DATA ,
// } from '../../../constants/actionTypes'; 

class AssetTable extends Component{
    constructor(props){
        super(props);
        this.state={
            showDetails: false,
            curretnDeviceId: '',
        }
    }

// ----------------------------------------------------------------

    deviceColumnFormat = (cell, row) => {
        let colspan = 2;
        //  
        const handleDeviceOnClick = (e, deviceId) => {
            e.preventDefault();

            let deviceData = cell.find((item, index) => (item.deviceid === deviceId));
            
            this.setState({
                showDetails: true,
                curretnDeviceId: deviceId,
                currentDeviceData: deviceData,
            });
        }
        
        if (cell){
            // let chunkedDevices = chunk(cell, colspan); 
            // return (
            //     <table border='0'>
            //         {chunkedDevices.map((dviceRow, rindex) => (
            //             <tr>
            //                 {dviceRow.map((device, dindex)=>(
            //                     <td>
            //                         {/* <a href = {`/pages/device/${device.deviceid}`}  onClick={()=>{}}>{device.deviceid}</a> */}
            //                         <a href = {'#'}  onClick={(e)=>{handleDeviceOnClick(e, device.deviceid);}}>{device.deviceid}</a>
            //                     </td>
            //                 ))}
            //             </tr>
            //         ))}
            //     </table>
            // );

            return (
                <Row>
                    {
                        cell && cell.map((device, index) => (
                            <Col md={4}>
                                <a href = {'#'}  onClick={(e)=>{handleDeviceOnClick(e, device.deviceid);}}>{device.deviceid}</a>
                            </Col>
                        ))
                    }
                </Row>
            );
        }
        else{
            return null;
        }

    }

    csvDeviceFormat = (cell, row) => {
        let reDevices = '';
        let dilimeter = '';
        if (cell){
            cell.forEach((device, index) => {
                reDevices += dilimeter + device.devicename;
                dilimeter = ', ';
            });
        }

        return reDevices;
    }

    renderAssetsTb2 = (assetclass) => {
        let data = [];
    // this.props.assetData.forEach((assetclass, acindex)=>{
        let assetClassObj = {};
        let keys = ['assetid', 'assetname', 'className', 'description'];
        let labals = {assetid: 'Asset ID', assetname: 'Asset Name', className: 'Asset Class Name', description: 'Descriptions' };
        Object.entries(assetclass).forEach(([key, value],index) => {
            if (!Array.isArray(value)){
                assetClassObj[key] = value;
            }
        });
         
        assetclass.asset.forEach((asset, asindex) => {
            let assetObj = {...assetClassObj, ...asset};
            //  
            //  
            if (asset.assetattributes) {
                asset.assetattributes.forEach((attr, attrindex) => {
                    if (attr.attmetaname) {
                        if (!keys.includes(attr.attmetaname)){
                            keys.push(attr.attmetaname);
                            labals[attr.attmetaname] = attr.displayname ? attr.displayname : attr.attmetaname;
                        }   
                        assetObj[attr.attmetaname]=  attr.value ? attr.value : '';
                        // {
                        //     label: attr.displayname ? attr.displayname : attr.attmetaname,
                        //     value: attr.value ? attr.value : '',
                        //     id: attr.id ? attr.value: -1,
                        // };
                    }                       
                });
            }
            // let assetObj = {...assetObj, ...asset};
            data.push(assetObj);
        });
        keys.push('device');
        labals.device = 'Devices';

        data.forEach((item, index) => {
            keys.forEach((key, kindex) => {
                if (item[key] === undefined || item[key] === null) {
                    item[key] = '';
                }
            });
        });
    // });
        let that = this;
    return (
        <BootstrapTable 
        data = {data} 
        tableHeaderClass = 'header_white' 
        // option = {options}   
        keyField='assetid'          
          
         
        search 
        csvFileName={ 'export_asset_data.csv' } 
        exportCSV={ true }
        maxHeight = {'500'} 
    >
        {/* <TableHeaderColumn dataField='assetid' dataAlign="center" hidden >Asset ID</TableHeaderColumn>
        <TableHeaderColumn dataField='assetname' dataAlign="center"  >Asset Name</TableHeaderColumn>
        <TableHeaderColumn dataField='className' dataAlign="center" >Asset Class Name</TableHeaderColumn>    
        <TableHeaderColumn dataField='description' dataAlign="center"  >Description</TableHeaderColumn> */}
        {keys && labals && keys.map((key, index) => (
            key !== 'device' ?
            <TableHeaderColumn dataField={key} dataAlign="center" >{labals[key]}</TableHeaderColumn>
            :
            <TableHeaderColumn dataField={key} dataAlign="center" dataFormat={that.deviceColumnFormat} csvFormat={that.csvDeviceFormat} >{labals[key]}</TableHeaderColumn>
        ))}
        {/* <TableHeaderColumn dataField='device' dataFormat={this.deviceColumnFormat}  >Devices</TableHeaderColumn> */}
    </BootstrapTable>
    );
    }

    renderAssetClassTbs = () => {
         
        return (
            <div>
                {
                    this.props.assetData && this.props.assetData.map((assetclass, acindex)=>(            
                        <Fragment>
                            {this.renderAssetsTb2(assetclass)}
                            {/* {this.renderAssetsWithMsg(assetclass)} */}
                            <br/>
                        </Fragment>  
                    ))
                }
            </div>
        );
        
    }
// ----------------------------------------------------------------
deviceidFormat = (cell, row) => {
    const handleDeviceOnClick = (e, deviceId) => {
        e.preventDefault();

        let deviceData = chooseDevice(this.props.assetData, deviceId);

        // let deviceData = cell.find((item, index) => (item.deviceid === deviceId));
        
        this.setState({
            showDetails: true,
            curretnDeviceId: deviceId,
            currentDeviceData: deviceData,
        });
    }
    return(
        <a href = {'#'}  onClick={(e)=>{handleDeviceOnClick(e, cell);}}>{cell}</a>
    )
}
// ----------------------------------------------------------------
renderAssetsWithMsg = (assetclass) => {
    let data = [];
    let keys = [];
    let labals = {};
    if (assetclass && assetclass.asset) {
        let assetclassObj = Object.keys(assetclass).reduce((r, key)=>{
            if (Array.isArray(assetclass[key]) || key === 'description') {
                return r;
            }
            else{
                return(
                    {...r, [key]: assetclass[key]}
                )
            }
        }, {});
        assetclass.asset.forEach((asset, aindex) => {
            let assetObj = Object.keys(asset).reduce((r, key)=>{
                if (Array.isArray(asset[key]) || key === 'description') {
                    return r;
                }
                else{
                    return(
                        {...r, [key]: asset[key]}
                    )
                }
            }, {});
            if (asset.assetattributes) {
                asset.assetattributes.forEach((attri, atrindex)=>{
                    if (attri.attmetaname) {
                        if (!keys.includes(attri.attmetaname)){
                            keys.push(attri.attmetaname);
                            labals[attri.attmetaname] = attri.displayname ? attri.displayname : attri.attmetaname;
                        }   
                        assetObj[attri.attmetaname] = attri.value;
                    }                    
                });
            }
            if (asset.device) {               
                asset.device.forEach((device, dindex) => {
                    let deviceObj = (({deviceid}) => ({deviceid}))(device);
                    if (device.msgs) {
                        device.msgs.forEach((msg, mindex) => {
                            let msgObj = (({msgid, timestamp}) => ({msgid, timestamp}))(msg);
                            msg.msg && Object.entries(msg.msg).forEach(([key, value], index) => {
                                let entryObj = {
                                    ...assetclassObj,
                                    ...assetObj,
                                    ...deviceObj,
                                    ...msgObj,
                                    attribute: value.displayname,
                                    value: value.value,
                                }
                                data.push(entryObj);
                            });
                        });
                    }
                });
            }
        });
    }
     
    return (
        <BootstrapTable 
            data = {data} 
            tableHeaderClass = 'header_white' 
            // option = {options}   
            keyField='assetid'          
              
             
            search 
            csvFileName={ 'export_asset_data.csv' } 
            exportCSV={ true }
            maxHeight = {'500'} 
        >
            <TableHeaderColumn dataField='assetid' dataAlign="center" hidden >Asset ID</TableHeaderColumn>
            <TableHeaderColumn dataField='assetname' dataAlign="center"  >Asset Name</TableHeaderColumn>
            <TableHeaderColumn dataField='className' dataAlign="center" >Asset Class Name</TableHeaderColumn>                
            {/* {keys && labals && keys.map((key, index) => (
                <TableHeaderColumn dataField={key} dataAlign="center" >{labals[key]}</TableHeaderColumn>
            ))} */}
            <TableHeaderColumn dataField='deviceid' dataAlign="center" dataFormat={this.deviceidFormat} >Devices</TableHeaderColumn>
            <TableHeaderColumn dataField='timestamp' dataAlign="center"  >Message Time</TableHeaderColumn>
            <TableHeaderColumn dataField='attribute' dataAlign="center"  >Attribute</TableHeaderColumn>
            <TableHeaderColumn dataField='value' dataAlign="center"  >Value</TableHeaderColumn>
        </BootstrapTable>
    );
}
// ----------------------------------------------------------------
    renderDetailsTb = (deviceId) => {
        const backOnClick = (e) => {
            e.preventDefault();
            this.setState({
                showDetails: false,
                // curretnDeviceId: deviceId,
            });
        }
        return(
            <div>
                <div>
                    <Button onClick={backOnClick}>
                        <ChevronLeftIcon/> Back
                    </Button>
                </div>
                {/* <DeviceDetailBoard deviceId = {deviceId}/> */}
                {this.renderMessageTb(deviceId)}
            </div>
        )
    }

    renderMessageTb = (deviceId) => {
        // let currentDeviceData = cell.find((item, index) => (item.deviceid === deviceId));
        // let deviceData = chooseDevice(this.props.assetData, deviceId);
        // if (deviceData){
        if (this.state.currentDeviceData){
        // if (currentDeviceData){
            let currentDeviceData = this.state.currentDeviceData;
            // let deviceData = this.state.currentDeviceData;
            let data = [];
            let deviceObj = {};
            let keys = [];
            let labels = {};
            // keys = ['msgid', 'devicename', 'devicetypename', 'timestamp'];
            // labels = {msgid : 'Message Id', devicename : 'Device Name', devicetypename : 'Device Type', timestamp : 'Message Time'}

            // for(let i=0; i < deviceData.length; i++){
            //     let currentDeviceData = deviceData[i];
            
                Object.entries(currentDeviceData).forEach(([key, value], index) => {
                    if (! Array.isArray(value)){
                        deviceObj[key] = value;
                        // keys.push(key);
                    }
                });

                // keys = ['msgid', 'devicename', 'devicetypename', 'lastmessagetime'];
                // labels = {msgid : 'Message Id', devicename : 'Device Name', devicetypename : 'Device Type', lastmessagetime : 'Last Message Time'}

                keys = ['msgid', 'devicename', 'devicetypename', 'timestamp'];
                labels = {msgid : 'Message Id', devicename : 'Device Name', devicetypename : 'Device Type', timestamp : 'Message Time'}

                if (currentDeviceData.msgs) {
                    currentDeviceData.msgs.forEach((msg, index) => {
                        let msgObj = {...deviceObj,...msg};
                         
                        // msgObj.lastmessagetime = new Date( msgObj.lastmessagetime).toLocaleString('en-US', { timeZone: 'America/New_York' });
                        // msgObj.timestamp = new Date( msgObj.timestamp).toLocaleString('en-US', { timeZone: 'America/New_York' });
                        msgObj.timestamp = new Date( msgObj.timestamp).toLocaleString('en-US', { timeZone: 'UTC' });
                        delete msgObj[msg];
                        // keys = [...keys, ...Object.keys(msgObj)];

                        if (msg.msg){
                             
                             
                            Object.entries(msg.msg).forEach(([attr, attrObj],mindex)=>{
                                // if (attr !== 'Lng' && attr !== 'Lat'  && attrObj.display !== false ){
                                 
                                if (attrObj.display !== false ){
                                    msgObj[attr] = attrObj.value;                                
                                    if (! keys.includes(attr) ){
                                        keys.push(attr);
                                        labels[attr] = attrObj.displayname ? attrObj.displayname : attr;
                                    }
                                }
                            });
                        }
                        data.push(msgObj);
                    });
                }
            // }
             
             

            if (keys.includes('Lng') && keys.includes('Lat')) {
                keys = keys.filter((val, idx, arr) => ( val !== 'Lng' && val !=='Lat'));               
                keys.push('Lat');
                keys.push('Lng');
            }

            return (
                <BootstrapTable 
                    data = {data}
                    tableHeaderClass = 'header_white' 
                    keyField='msgid'
                      
                     
                    search 
                    csvFileName={ 'export_asset_device_data.csv' } 
                    exportCSV={ true }
                    maxHeight = {'600'} 
                >
                    {/* <TableHeaderColumn dataField={'id'} dataAlign="center" isKey > Message ID </TableHeaderColumn> */}
                    {
                        keys.map((key,i) => {
                            return(
                                <TableHeaderColumn 
                                    dataField={key} 
                                    dataAlign="center" 
                                    // dataSort = {key === 'lastmessagetime' ? true : false }
                                    dataSort = {key === 'timestamp' ? true : false }
                                >
                                    {labels[key]}
                                </TableHeaderColumn>
                            )
                        })
                    }
                </BootstrapTable>
            );
        }
        else{
            return null;
        }
    }

// ----------------------------------------------------------------
    render(){
        return (
            <Container style={{paddingTop:'20px'}}>
                <Row>
                    {this.state.showDetails ? this.renderDetailsTb(this.state.curretnDeviceId) : this.renderAssetClassTbs()}
                </Row>
            </Container>
        );
    }

}

export default AssetTable;


function chooseDevice(mapData, deviceid){
    let deviceArr = [];
    if (mapData) {
        mapData.forEach((assetclass, cindex)=>{
            assetclass.asset && assetclass.asset.forEach((asset, aindex)=>{
                asset.device && asset.device.forEach((device, dindex) => {
                    if (device.deviceid === deviceid) {
                        deviceArr.push(device);
                    }
                });
            });
        });
    }
     
    return deviceArr;
}