/* eslint-disable */
import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { ViewDashboardIcon } from 'mdi-react';
import { getPageContent } from '../../../redux/actions/userAction';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { withTranslation } from 'react-i18next';
import logo from '../../../shared/img/logo/sigfoxbl.jpeg';


class SidebarContent extends Component {

  static propTypes = {
    // changeToDark: PropTypes.func.isRequired,
    // changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.userObj = getCurrentUser()
  }

  componentDidMount() {
    this.props.getPageContent({ "content": "Telecom App" });
    this.props.getPageContent({ "content": "Company" });
    this.props.getPageContent({ "content": "Smart Building" });
  }

  hideSidebar = () => {
    this.props.onClick();
  };
  /**
   * vertical application editing. If a vertical Apps are edited, change the function here
   *
   * @param {*} userObj
   * @param {*} t
   * @returns
   */
  verticalAppLoading = (userObj, t) => {
    const apps = ["Telecom App", "Billing App", "Weather App", "Occupancy App", "Garage App", "Snow App", "Opg App", "Power Management", "Template App"];
    if (this.props.numOfApps == 1) {
      return (
        <SidebarLink icon="iconA" title={"Home"} route={"/pages" + this.props.route} onClick={this.hideSidebar} />
      )
    }
    else if (this.props.numOfApps == 0 || !apps.some(a => userObj.sidebarContent.includes(a))) {
      return null;
    }
    return (
      <SidebarCategory title={t('menu.verticalApp')} icon="iconB">
        {userObj.sidebarContent.includes("Telecom App") && <SidebarLink icon="iconI" title="Telecom App" route="/pages/TelecomApp" onClick={this.hideSidebar} />}
        {userObj.sidebarContent.includes("Billing App") && <SidebarLink icon="iconI" title="Billing App" route="/pages/BillingApp" onClick={this.hideSidebar} />}
        {userObj.sidebarContent.includes("Weather App") && <SidebarLink icon="iconJ" title="Weather App" route="/pages/WeatherApp" onClick={this.hideSidebar} />}
        {userObj.sidebarContent.includes("Occupancy App") && <SidebarLink icon="icon1" title="Occupancy App" route="/pages/OccupancyApp" onClick={this.hideSidebar} />}
        {userObj.sidebarContent.includes("Garage App") && <SidebarLink icon="icon2" title="Parking App" route="/pages/ParkingApp" onClick={this.hideSidebar} />}
        {userObj.sidebarContent.includes("Snow App") && <SidebarLink icon="icon3" title="SCD App" route="/pages/SnowApp" onClick={this.hideSidebar} />}
        {userObj.sidebarContent.includes("Opg App") && <SidebarLink icon="icon1" title="OPG App" route="/pages/OpgApp" onClick={this.hideSidebar} />}
        {userObj.sidebarContent.includes("Power Management") && <SidebarLink icon="icon1" title="Power Management" route="/pages/PowerManagement" onClick={this.hideSidebar} />}
        {/* ------- Waiv new layout  ------- */}
        {userObj && userObj.sidebarContent.includes("Template App") && <SidebarLink icon="iconG" title="Template App" route="/pages/TemplateApp" onClick={this.hideSidebar} />}
        {/* ------- Waiv new layout  ------- */}
      </SidebarCategory>
    )
  }

  smartBuildingAppLoading = (userObj, t) => {
    if (userObj.sidebarContent.includes("Smart Building")) {
      return (<SidebarCategory title='Smart Building' icon="iconC">
        <SidebarLink title="Live Feed" route="/pages/surveillance" onClick={this.hideSidebar} />
        {/*hide*/}
        {/*<SidebarLink title="DeviceOrigin" route="/pages/devicemanagorigin" onClick={this.hideSidebar} />*/}
        <SidebarLink title="Devices" route="/pages/devicemanage" onClick={this.hideSidebar} />
      </SidebarCategory>
      )
      //    <SidebarLink icon="iconH" title="Smart Building" route="/pages/surveillance" onClick={this.hideSidebar} />
    } else {
      return null;
    }
  }

  render() {
    const { pageContentList, t } = this.props;
    let userObj = getCurrentUser();
    let textDisplay = [];
    if (pageContentList.includes("Sigfox Billing App") /* TEISA */
      && pageContentList.includes("Reseller")
      && pageContentList.includes("User Access")
      && pageContentList.includes("Company name TEISA")) {
      textDisplay = ['Sigfox Billing App', 'Reseller', 'User Access', 'TEISA']
    } else {
      textDisplay = ['Telecom App', 'Client', 'Users', 'Company']
    }
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {(!sessionStorage.getItem('isAuthenticated') || 'true' != sessionStorage.getItem('isAuthenticated')) &&
            <SidebarLink title="Log In" icon="exit" route="/log_in" onClick={this.hideSidebar} />}
          {/* <SidebarCategory title="Layout" icon="layers">
            <button className="sidebar__link" onClick={this.props.changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button className="sidebar__link" onClick={this.props.changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory> */}
        </ul>
        <ul className="sidebar__block">
          {/* <SidebarCategory title="Example Pages" icon="diamond">
            <SidebarLink title="Page one" route="/pages/one" onClick={this.hideSidebar} />
            <SidebarLink title="Page two" route="/pages/two" onClick={this.hideSidebar} />
            <SidebarLink title="Page three" route="/pages/three" onClick={this.hideSidebar} />
            <SidebarLink title="Page four" route="/pages/four" onClick={this.hideSidebar} />
          </SidebarCategory> */}


          {userObj && userObj.sidebarContent.includes("Dashboard") && <SidebarLink icon="iconA" title={t('menu.dashboard')} route="/pages/dashboard" onClick={this.hideSidebar} />}
          {this.smartBuildingAppLoading(userObj, t)}
          {this.verticalAppLoading(userObj, t)}
          {userObj && userObj.sidebarContent.includes("Device Location") && <SidebarLink title={t('menu.devicelocation')} route="/pages/map" onClick={this.hideSidebar} icon="map-marker" />}
          {(
            userObj.sidebarContent.includes("Attribute Setting") ||
            userObj.sidebarContent.includes("Attribute Theme")
          ) ?
            <SidebarCategory title={t('menu.displaysetting')} icon="iconC">
              {userObj && userObj.sidebarContent.includes("Attribute Setting") && <SidebarLink title="Attribute Setting" route="/pages/attribute/management" onClick={this.hideSidebar} />}
              {userObj && userObj.sidebarContent.includes("Attribute Theme") && <SidebarLink title="Attribute Theme" route="/pages/attribute/setting" onClick={this.hideSidebar} />}
            </SidebarCategory>
            :
            null}

          {userObj && userObj.companyCode !== 'ONRT' && userObj.userName !== 'ONrouteLegal' && !userObj.userName.includes('grt') && <SidebarCategory title={t('menu.management')} icon="iconD">
            {userObj && userObj.sidebarContent.includes("Asset") && <SidebarLink title="Asset Management" route="/pages/asset" onClick={this.hideSidebar} />}
            {/* {userObj && hasRole(userObj, ['SUPERADMIN']) && userObj.userName !== 'ONrouteLegal' && userObj.companyCode !== 'ONRT' && <SidebarLink title="Inventory Master" route="/pages/inventory" onClick={this.hideSidebar} />} */}
            {userObj && userObj.sidebarContent.includes("Device Config") && <SidebarLink title="Device Configuration" route="/pages/deviceconfig" onClick={this.hideSidebar} />}
            {userObj && userObj.sidebarContent.includes("Event") && <SidebarLink title="Event" route="/pages/action/" onClick={this.hideSidebar} />}
            {userObj && userObj.sidebarContent.includes("actionNotification") && <SidebarLink title="Notification" route="/pages/actionNotification/" onClick={this.hideSidebar} />}


            {userObj && userObj.sidebarContent.includes("Geofence") && <SidebarLink title="Geofence" route="/pages/geofence" onClick={this.hideSidebar} />}
            {userObj && userObj.sidebarContent.includes("Template") && <SidebarLink title="Template" route="/pages/notificationtemplate" onClick={this.hideSidebar} />}
            {userObj && userObj.sidebarContent.includes("Dashboard Setting") && <SidebarLink title="Dashboard Setting" route="/pages/dashboardsetting" onClick={this.hideSidebar} />}
            {userObj && userObj.sidebarContent.includes("Reporting") && <SidebarLink title="Reporting" route="/pages/reports" onClick={this.hideSidebar} />}

            {userObj && userObj.sidebarContent.includes("Company")
              && <SidebarLink
                title={textDisplay[3]}
                route="/pages/company"
                onClick={this.hideSidebar}
              />}

            {userObj && userObj.sidebarContent.includes("Client") && <SidebarLink title={textDisplay[1]} route="/pages/client" onClick={this.hideSidebar} />}

            {userObj && userObj.sidebarContent.includes("Consumer") && <SidebarLink title="Consumer" route="/pages/consumer" onClick={this.hideSidebar} />}

            {userObj && userObj.sidebarContent.includes("Users") && <SidebarLink title={textDisplay.length > 0 ? textDisplay[2] : 'Users'} route="/pages/accountmgmt" onClick={this.hideSidebar} />}

            {/* ------- Waiv new layout  ------- */}
            {userObj && userObj.sidebarContent.includes("Template Page App") && <SidebarLink title="Template Page App" route="/pages/templatepageapp" onClick={this.hideSidebar} />}
            {/* ------- Waiv new layout  ------- */}
          </SidebarCategory>}

          {/* <SidebarCategory title="Event Management" icon="alarm"> */}
          {/* <SidebarLink title="Event" route="/pages/action/" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Criteria Managment" route="/pages/event" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Geofence" route="/pages/geofence" onClick={this.hideSidebar} /> */}
          {/* </SidebarCategory>                     */}

          {/* <SidebarLink title="Account" route="/pages/accountmgmt" onClick={this.hideSidebar} icon="users" />
          {
            userObj && hasRole(userObj, ['SUPERADMIN', 'ADMIN']) &&
            <SidebarLink title="Company" route="/pages/company" onClick={this.hideSidebar} icon="diamond" />
          } */}
          {/* <SidebarLink title="Company" route="/pages/company" onClick={this.hideSidebar} icon="diamond" /> */}

          <SidebarLink title="Contact Us" route="/pages/support" onClick={this.hideSidebar} icon="iconE" />
          {/* <SidebarLink title="Testing" route= "/pages/notification/test" onClick={this.hideSidebar} icon="diamond" /> */}

        </ul>
        {userObj && userObj.sidebarContent.includes("Bottom Left Logo") && <div className="sidebar_bottom_logo">
          <ul className="sidebar__block">
            <li className="sidebar__link" style={{ cursor: "default", padding: '0', backgroundColor: 'white' }}>
              <p className="sidebar__link-title logo_area" >
                <img src={logo} style={{ height: '55px', width: '255px' }} />
              </p>
            </li>
          </ul>
        </div>}


      </div>
    );
  }
}

function mapStateToProps(state) {
  const { pageContentList } = state.user
  const props = { pageContentList }

  return props;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getPageContent
  }, dispatch);
};

SidebarContent = (connect(mapStateToProps, mapDispatchToProps))(SidebarContent)

export default (withTranslation('common'))(SidebarContent);
