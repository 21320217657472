/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardTitle, CardBody } from 'reactstrap';
import { EditableText } from '../../Asset/components/ClassDisplayDetail';
import {
    // loadDevice, 
    loadAssetClass,
} from '../../../redux/actions/mapActions';
import {
    getAssetList,
    addDashBoardLayout,
} from '../../../redux/actions/dashboardActions';
import {
    getAttributes,
    clearAttributes,
} from '../../../redux/actions/attributeMgmtAction';
import { toast } from "react-toastify";
import { UserContext } from '../../../constants/myContexts';
import { isStringEmpty } from '../../../factories/utils';
import Select from "react-select";
import { getCurrentUser, hasRole } from '../../../factories/auth';

class AttrParamAddElement extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            currentAssetClassId: null,
            currentAssetId: null,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
            devices: [],
            customStyles: {}
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }

    componentDidMount() {
        const { userObj, updateUserObj } = this.context;

        this.props.loadAssetClass();
    }

    handleInputChange = (newVal) => {
        this.setState((prevState) => {
            // if (prevState.layout){
            //     prevState.layout.name = newVal;
            //     return ({
            //         currentName: newVal,
            //         layout: prevState.layout,
            //     })
            // }else{
            //     return null;
            // }

            return ({
                currentName: newVal,
                // layout: prevState.layout,
            });
        }, () => {


        });
    }

    renderTitle = () => {
        return (

            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{ display: 'inline' }} ><h4><b> Name</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                        type='text'
                        //  defaultValue={this.props.layout ? this.props.layout.name : ''} 
                        defaultValue={this.state.currentName ? this.state.currentName : ''}
                        exitOnEnterKeyPress={false}
                        onSave={newVal => { this.handleInputChange(newVal) }}
                    />
                </Col>
            </Row>
        )
    }

    onAssetClassChange = (selectedOption) => {
        let assetClassId = selectedOption ? selectedOption.value : null;
        this.setState({
            currentAssetClassId: assetClassId,
            currentAssetId: null,
            devices: [],
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
        });
        this.props.getAssetList({ assetclassid: assetClassId });
        this.props.clearAttributes();
    }
    renderAssetClass = () => {
        const assetClassOptions = [{ value: null, label: 'None' }, ...this.props.assetClasses.map(assetclass => ({
            value: assetclass.assetclassid,
            label: assetclass.assetclassname
        }))];

        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="assetclass" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        id="assetclass"
                        name="assetclass"
                        options={assetClassOptions}
                        value={assetClassOptions.find(option => option.value === this.state.currentAssetClassId)}
                        onChange={this.onAssetClassChange}
                    />
                </Col>
            </Row>
        )
    }

    setDeviceList = (assets) => {
        if (assets && this.state.currentAssetId) {
            let assetObj = assets.find((asset) => (asset.assetid == this.state.currentAssetId));
            if (assetObj) {
                let devices = assetObj.devices;
                this.setState({
                    devices: devices,
                });
            }
        }
    }

    onAssetChange = (selectedOption) => {
        let assetId = selectedOption ? selectedOption.value : null;
        this.setState({
            currentAssetId: assetId,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
        }, () => {
            this.setDeviceList(this.props.assets);
        });
        this.props.clearAttributes();
    }

    renderAsset = () => {
        const assetOptions = [{ value: null, label: 'None' }, ...this.props.assets.map(asset => ({
            value: asset.assetid,
            label: asset.assetname
        }))];
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="asset" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        id="asset"
                        name="asset"
                        options={assetOptions}
                        value={assetOptions.find(option => option.value === this.state.currentAssetId)}
                        onChange={this.onAssetChange}
                    />
                </Col>
            </Row>
        )
    }

    setAttrList = () => {

        if (this.state.devices && this.state.currentDeviceId && this.state.currentDeviceId != this.state.preiousDeviceId) {
            let deviceObj = this.state.devices.find((device) => (device.deviceid == this.state.currentDeviceId));
            if (deviceObj) {
                this.setState({ preiousDeviceId: this.state.currentDeviceId });
                this.props.getAttributes(deviceObj.devicetype);
            }
        }
    }

    onDeviceChange = (selectedOption) => {
        let deviceId = selectedOption ? selectedOption.value : null;
        this.setState({
            currentDeviceId: deviceId,
            currentAttrId: null,
            currentAttrName: null,
        }, () => {
            this.setAttrList();
        });
    }

    renderDevice = () => {
        const deviceOptions = [{ value: null, label: 'None' }, ...this.state.devices.map(device => ({
            value: device.deviceid,
            label: device.devicename
        }))];

        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="device" style={{ display: 'inline', width: "100%" }} ><h4><b>Device</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        id="device"
                        name="device"
                        options={deviceOptions}
                        value={deviceOptions.find(option => option.value === this.state.currentDeviceId)}
                        onChange={this.onDeviceChange}
                    />
                </Col>
            </Row>
        )
    }

    onAttrChange = (selectedOption) => {
        this.setState({
            currentAttrId: selectedOption ? selectedOption.value : null,
            currentAttrName: selectedOption ? selectedOption.attributename : null
        });
    }


    renderAttribute = () => {
        const attributeOptions = [{ value: null, label: 'None' }, ...this.props.attributes.map(attr => ({
            value: attr.attributeid,
            label: attr.displayname,
            attributename: attr.attributename
        }))];
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="attribute" style={{ display: 'inline', width: "100%" }} ><h4><b>Attribute</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        id="attribute"
                        name="attribute"
                        options={attributeOptions}
                        value={attributeOptions.find(option => option.value === this.state.currentAttrId)}
                        onChange={this.onAttrChange}
                    />
                </Col>
            </Row>
        )
    }

    onCancleClick = (e) => {
        this.props.toggle();
    }

    validateInput() {
        // let isValidated = true;
        let hasUser = this.context != undefined && this.context != null && this.context.userObj != undefined && this.context.userObj != null;
        if (!hasUser) {

            toast.error("Can not get user infomation");
        }
        let isInput = !isStringEmpty(this.state.currentName) && !isStringEmpty(this.state.currentAttrName);
        let isSelect = this.state.currentAssetId != null && this.state.currentAssetId != 0 && this.state.currentDeviceId != null && this.state.currentDeviceId != 0 && this.state.currentAttrId != null && this.state.currentAttrId != 0
        if (!isInput || !isSelect) {

            toast.error("Input is not valid");
        }


        return hasUser && isInput;
    }

    onOkClick = (e) => {

        if (!this.validateInput()) {

            return;
        }

        let position = { w: 3, h: 7, x: 8, y: 8 };
        let parameter = {
            assetid: this.state.currentAssetId,
            deviceid: this.state.currentDeviceId,
            attrid: this.state.currentAttrId,
            attrname: this.state.currentAttrName
        };
        let layout = {
            name: this.state.currentName,
            type: "AttributeValue",
            width: 9,
            locked: 0,
            display: 1,
            position: JSON.stringify(position),
            parameter: JSON.stringify(parameter),
            username: this.context.userObj.userName,
            createdby: this.context.userObj.userName,
            companycode: this.context.userObj.companyCode,
            selected: 1,
            // createdtime: "2019-02-04T03:28:50.000+0000",
            createdtime: new Date().toUTCString(),
        }
        layout = { ...this.props.currentLayout, ...layout };
        this.props.addDashBoardLayout(layout);
        this.props.toggle();
        this.props.parentToggle();
    }

    render() {
        return (
            <Card>
                <CardBody>
                    {/* <CardTitle>Set Attribute Value</CardTitle> */}
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderTitle()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {/* {this.props.layout && this.renderParameterInput(this.props.layout.type)} */}
                            {this.renderAssetClass()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderAsset()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderDevice()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderAttribute()}
                        </Col>
                    </Row>
                    <hr />
                    <div style={{ textAlign: 'right' }}>
                        <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                        <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

// export default AttrParamAddElement;

function mapStateToProps(state) {
    // const types = state.mapboard.types;
    const assets = state.dashboard.assetList;
    const assetClasses = state.mapboard.assetClass;
    const attributes = state.attributemgmt.attributes;
    const props = {
        // types,  
        assetClasses,
        assets,
        attributes,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // loadDevice, 
        loadAssetClass,
        getAssetList,
        getAttributes,
        clearAttributes,
        addDashBoardLayout,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AttrParamAddElement);