/* eslint-disable */
import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
import { getCurrentUser, hasRole } from '../../../factories/auth';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    addConsumer,
    getAllResellers,
    //addUser, getCompanyList,
    getRoles
} from '../../../redux/actions/userAction';


class ConsumerEditBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            state: "",
            country: "",
            statesList: [],
        }
        this.userObj = getCurrentUser();
    }
    async componentDidMount() {
        if (this.props.selectedRow != null) {
            this.setState({
                customerId: this.props.selectedRow.customer.customerId,
                resellerId: this.props.selectedRow.customer.resellerId,
                operation: this.props.selectedRow.country,
                lob: this.props.selectedRow.lineOfBusiness,
                division: this.props.selectedRow.division,
                userName: this.props.selectedRow.username,

                busName: this.props.selectedRow.customer.businessName,
                email: this.props.selectedRow.customer.email,
                phoneNum: this.props.selectedRow.customer.phone,
                phoneExt: "",
                firstName: this.props.selectedRow.customer.firstName,
                lastName: this.props.selectedRow.customer.lastName,
                address1: this.props.selectedRow.customer.addressLine1,
                address2: this.props.selectedRow.customer.addressLine2,
                city: this.props.selectedRow.customer.city,
                state: this.props.selectedRow.customer.state,
                country: this.props.selectedRow.customer.country,
                zipCode: this.props.selectedRow.customer.zipCode,
                password: "",
                passwordConfirm: ""
            })
        }
        let cval = this.props.countryList[0] ? this.props.countryList[0].value : "";
        if (cval) {
            await this.props.getProvinces(cval);

            setTimeout(() => {
                this.setState({
                    statesList: this.props.provinceList,
                });
            }, 500);
        }
    }
    /*
    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selectedRow != null) {
            this.setState({
                customerId: nextProps.selectedRow.customerId,
                resellerId: nextProps.selectedRow.customer.resellerId,
                operation: nextProps.selectedRow.operationCountryString,
                lob: nextProps.selectedRow.lobString,
                division: nextProps.selectedRow.divisionString,
                userName: nextProps.selectedRow.userName,
                busName: nextProps.selectedRow.customer.businessName,
                email: nextProps.selectedRow.email,
                phoneNum: nextProps.selectedRow.phoneNum,
                phoneExt: "",
                firstName: nextProps.selectedRow.customer.firstName,
                lastName: nextProps.selectedRow.customer.lastName,
                address1: nextProps.selectedRow.customer.addressLine1,
                address2: nextProps.selectedRow.customer.addressLine2,
                city: nextProps.selectedRow.customer.city,
                state: nextProps.selectedRow.customer.state,
                country: nextProps.selectedRow.customer.country,
                zipCode: nextProps.selectedRow.customer.zipCode,
                password: "",
                passwordConfirm: ""
            })
        }
    }
    */

    validPasswordInput = () => {
        if (this.state.password != "") {
            return this.validatePassConfirm() && this.validatePassword()
        }
        else {
            return true;
        }
    }
    validatePassConfirm = () => {
        return (this.state.passwordConfirm && this.state.password === this.state.passwordConfirm);
    }
    validatePassword = () => {
        return (this.state.password && this.state.password !== '');
    }
    validateAddress = () => {
        return (this.state.address1 != "" && this.state.address1);
    }

    validateCity = () => {
        return (this.state.city != "" && this.state.city);
    }

    validateCode = () => {
        return (this.state.zipCode != "" && this.state.zipCode);
    }

    validateForm = () => {
        return this.validPasswordInput() && this.validateCity() && this.validateAddress() && this.validateCode() && this.validatePhone() && this.validateEmail() && this.validateLastName() && this.validateFirstName();
    }
    validateFirstName = () => {
        return (this.state.firstName && this.state.firstName !== '');
    }
    validateLastName = () => {
        return (this.state.lastName && this.state.lastName !== '');
    }
    validateExtension = () => {
        var regex = /^[0-9]*$/;
        return (regex.test(this.state.phoneExt))
    }
    validatePhone = () => {
        var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return (this.state.phoneNum && regex.test(this.state.phoneNum));
    }
    validateEmail = () => {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (this.state.email && regex.test(this.state.email));
    }


    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    /*onCountryChange = (event) => {
        let key = event.target.name;
        let value = event.target.value;
        this.props.getProvinces(value);
        this.setState(prevState => {
            prevState.country = value;
            prevState.province = '';
            return ({
                country: prevState.country,
            })
        });
    }*/

    onCountryChange = async (event) => {
        let value = event.target.value;
        await this.props.getProvinces(value);

        setTimeout(() => {
            this.setState((prevState) => {
                prevState.country = value;
                prevState.province = "";
                return {
                    country: prevState.country,
                    statesList: this.props.provinceList,
                };
            });
        }, 500);
    };

    onSubmit = () => {
        let phoneNo = this.state.phoneNum;
        if (phoneNo) {
            phoneNo = phoneNo.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
        var data = {
            customerId: this.state.customerId,
            resellerId: this.state.resellerId,
            businessName: this.state.busName,
            email: this.state.email,
            phoneNum: phoneNo,
            //phoneExt: "",
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            addressLine1: this.state.address1,
            addressLine2: this.state.address2,
            city: this.state.city,
            state: this.state.state,
            country: this.state.country,
            zipCode: this.state.zipCode,
            password: this.state.password,

        }

        this.props.onUpdateConsumer(data)
    }
    render() {
        let labelStyle = { textAlign: 'right', verticalAlign: 'middle', marginTop: 'auto', marginBottom: 'auto' };

        return (
            <Card>
                <CardBody>
                    <Col md={12}>
                        <h3>Information</h3>
                        <br />
                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Country </span>
                            </Col>
                            <Col md={8}>
                                <span style={{ fontSize: "14px" }}>{this.state.operation}</span>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Line of Business </span>
                            </Col>
                            <Col md={8}>
                                <span style={{ fontSize: "14px" }}>{this.state.lob}</span>
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Division </span>
                            </Col>
                            <Col md={8}>
                                <span style={{ fontSize: "14px" }}>{this.state.division}</span>
                            </Col>
                        </Row>

                        <br />

                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Name </span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='busName'
                                    placeholder='Business Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.busName}

                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Phone Number <span style={{ color: 'red' }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='phoneNum'
                                    placeholder='Phone Number'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.phoneNum}
                                    invalid={!this.validatePhone()}
                                />
                            </Col>
                            {/*
                            <Col md={1} style={labelStyle}>
                                <span>Ext. </span>
                            </Col>
                            <Col md={2}>
                                <Input
                                    name='phoneExt'
                                    placeholder='Extension'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.phoneExt}
                                    valid={this.validateExtension()}
                                />
                            </Col>
    */}
                        </Row>

                        <br />

                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Email <span style={{ color: 'red' }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='email'
                                    placeholder='Email'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.email}
                                    invalid={!this.validateEmail()}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Contact First Name <span style={{ color: 'red' }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='firstName'
                                    placeholder='First Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.firstName}
                                    invalid={!this.validateFirstName()}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Contact Last Name <span style={{ color: 'red' }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='lastName'
                                    placeholder='Last Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.lastName}
                                    invalid={!this.validateLastName()}
                                />
                            </Col>
                        </Row>
                        <br />
                        <hr style={{ borderTop: "3px dotted  #8c8b8b" }} />
                        <h3>Address</h3>
                        <br />
                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Address Line 1 <span style={{ color: 'red' }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='address1'
                                    placeholder='Address Line 1'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.address1}
                                    invalid={!this.validateAddress()}

                                />
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Address Line 2</span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='address2'
                                    placeholder='Address Line 2'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.address2}
                                />
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>City <span style={{ color: 'red' }}>*</span></span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='city'
                                    placeholder='City'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.city}
                                    invalid={!this.validateCity()}
                                />
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2} style={labelStyle}>
                                <span>Zip Code <span style={{ color: 'red' }}>*</span></span>
                            </Col>
                            <Col md={3}>
                                <Input
                                    name='zipCode'
                                    placeholder='Zip Code'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={this.state.zipCode}
                                    invalid={!this.validateCode()}
                                />
                            </Col>
                        </Row>
                        <br />

            <Row>
              <Col md={2} style={labelStyle}>
                <span>
                  {this.userObj.companyCode &&
                  this.userObj.companyCode !== "TEISA"
                    ? "State"
                    : "State/City"}{" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
              </Col>
              <Col md={3}>
                <Input
                  type="select"
                  name="state"
                  placeholder="State"
                  onChange={this.onInputChange}
                  className="form-control"
                  value={this.state.state}
                >
                  {this.userObj.companyCode && this.state.statesList  &&
                  this.state.statesList .map((option, index) => {
                      //   if (
                      //     this.userObj.companyCode &&
                      //     this.userObj.companyCode !== "TEISA"
                      //   ) {
                      return (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      );
                      //   }
                      //   if (
                      //     this.userObj.companyCode &&
                      //     this.userObj.companyCode == "TEISA"
                      //   ) {
                      //     return (
                      //       <option key={index} value={"Paraguay"}>
                      //         {option.label}
                      //       </option>
                      //     );
                      //   }
                    })}
                </Input>
              </Col>
              <Col md={2} style={labelStyle}>
                <span>
                  Country <span style={{ color: "red" }}>*</span>
                </span>
              </Col>
              <Col md={3}>
                <Input
                  type="select"
                  name="country"
                  placeholder="Country"
                  onChange={this.onCountryChange}
                  className="form-control"
                  value={this.state.country}
                >
                    {this.userObj.companyCode && this.props.countryList && this.props.countryList.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        {
                            (hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"])) ?
                                <>

                                    <hr style={{ borderTop: "3px dotted  #8c8b8b" }} />
                                    <h3>Change Account</h3>
                                    <br />
                                    <Row>
                                        <Col md={2} style={labelStyle}>
                                            <span>Username </span>
                                        </Col>
                                        <Col md={8}>
                                            <span style={{ fontSize: "14px" }}>{this.state.userName}</span>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col md={2} style={labelStyle} >
                                            <span>Password <span style={{ color: "red" }}>*</span></span>
                                        </Col>
                                        <Col md={8}>
                                            {/* <span>User Name</span> */}
                                            <Input
                                                valid={this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                                                name='password'
                                                id='password'
                                                type='password'
                                                onChange={this.onInputChange}
                                                className="form-control"
                                                value={this.state.password}
                                            // disabled={this.props.userName !== ''}
                                            />
                                        </Col>
                                    </Row>
                                    <br />

                                    <Row>
                                        <Col md={2} style={labelStyle} >
                                            <span>Password Confirmation</span>
                                        </Col>
                                        <Col md={8}>
                                            {/* <span>User Name</span> */}
                                            <Input
                                                // valid = {this.state.passConfirmed}
                                                valid={this.state.passwordConfirm && this.state.password === this.state.passwordConfirm}
                                                // invalid = {this.state.passConfirmed}
                                                invalid={this.state.passwordConfirm && this.state.password !== this.state.passwordConfirm}
                                                name='passwordConfirm'
                                                id='passwordConfirm'
                                                type='password'
                                                onChange={this.onInputChange}
                                                className="form-control"
                                                //value={this.state.passwordConfirm}
                                                disabled={!this.state.password || this.state.password == ''}
                                            // disabled = {true}
                                            />
                                        </Col>
                                    </Row>
                                    <div style={{ textAlign: 'right' }}>
                                        <Button color='custom' type='button' onClick={this.onSubmit} disabled={!this.validateForm()}>
                                            Save Changes
                                        </Button>
                                    </div>
                                </>
                                :
                                null

                        }

                    </Col>
                </CardBody>
            </Card>
        )
    }
}




const mapStateToProps = (state) => {
    return state.user;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        addConsumer,
        getRoles,
        getAllResellers
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsumerEditBoard);
