/* eslint-disable */
import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container, Col, Row, Card, CardBody, CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup } from 'reactstrap';
import {
    Button,
    InputGroup,
    InputGroupAddon,
} from 'reactstrap';
import Select from 'react-select';
import StepTemplate from './StepTemplate';
import AssetSelectModal from './AssetSelectModal';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import PlusCircleOutlineIcon from 'mdi-react/PlusCircleOutlineIcon';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';

import { loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff, getActionRule, upsertActionRule, clearActionRule } from '../../../redux/actions/jobAction';
import { loadAllGeoFences, upsertGeofence } from '../../../redux/actions/fenceAction';
import { loadAssetClass, loadAllAssets, getAssetClassAttribute } from '../../../redux/actions/mapActions';
// import {getRuls} from '../../../redux/actions/ruleActions'; 
import { generateSelectOptions } from '../../../factories/utils';
import GeofenceModal from './GeofenceModal';
import { FENCE_UPDATE_FENCE_OBJ_SUCCEED } from '../../../constants/actionTypes';
import { toast } from "react-toastify";

function VariableContainer({ children }) {
    return (
        <div
            className='container'
            style={{
                border: '1px solid #ddd',
                padding: 20,
                marginBottom: 10,
                marginTop: 10,
            }}
        >
            {children}
            {/* <RemoveSectionButton onClick={onClick} /> */}
        </div>
    )
}

class StepCondition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetModal: false,
            geofenceModal: false,
            currentDeivecTypeId: '5cf828390499f50b995ae851',
            rawData: [],
            // rawData: [
            //     {
            //         "groupid": 0,
            //         "groupindex": 0,
            //         "grouprelation": 0,
            //         "ruleitems": [
            //             {
            //                 "actionid": "e250e806-324a-4de2-8a0a-0fe0cbbaf102",
            //                 "assetid": 1,
            //                 "assetname": "Asset 1",
            //                 "deviceid": -1,
            //                 "devicename": "",
            //                 "attributeid": 1,
            //                 "attributename": "Speed",
            //                 "geofenceid": 1,
            //                 "geofencename": "polygo01",
            //                 "operator": 0,
            //                 "value": 0,
            //                 "relation": 0
            //             },
            //             {
            //                 "actionid": "e250e806-324a-4de2-8a0a-0fe0cbbaf102",
            //                 "assetid": 1,
            //                 "assetname": "Asset 1",
            //                 "deviceid": 1,
            //                 "devicename": "Devcie 1",
            //                 "attributeid": -1,
            //                 "attributename": "Position",
            //                 "geofenceid": 2,
            //                 "geofencename": "rectangle01",
            //                 "operator": 0,
            //                 "value": 0,
            //                 "relation": 1
            //             }
            //         ]
            //     },
            //     {
            //         "groupid": 1,
            //         "groupindex": 0,
            //         "grouprelation": 1,
            //         "ruleitems": [
            //             {
            //                 "actionid": "e250e806-324a-4de2-8a0a-0fe0cbbaf102",
            //                 "assetid": 1,
            //                 "assetname": "Asset 1",
            //                 "deviceid": 2,
            //                 "devicename": "Device 2",
            //                 "attributeid": 3,
            //                 "attributename": "Temperature",
            //                 "geofenceid": 3,
            //                 "geofencename": "circle01",
            //                 "operator": 0,
            //                 "value": 0,
            //                 "relation": 0
            //             },
            //             {
            //                 "actionid": "e250e806-324a-4de2-8a0a-0fe0cbbaf102",
            //                 "assetid": 2,
            //                 "assetname": "Asset 2",
            //                 "deviceid": -1,
            //                 "devicename": "",
            //                 "attributeid": 4,
            //                 "attributename": "0",
            //                 "geofenceid": 8,
            //                 "geofencename": "circlefortype",
            //                 "operator": 0,
            //                 "value": 0,
            //                 "relation": 1
            //             }
            //         ]
            //     },
            //     // {
            //     //     "groupid": 0,
            //     //     "groupindex": 0,
            //     //     "grouprelation": 2,
            //     //     "ruleitems": [

            //     //     ]
            //     // }
            // ],
            // assetOption: [
            //     {value: '111', label: 'asset 1'},
            //     {value: '222', label: 'asset 2'},
            //     {value: '333', label: 'asset 3'},
            //     {value: '444', label: 'asset 4'},
            //     {value: '555', label: 'asset 5'},
            // ],
            // devceOption: [
            //     {value: '111', label: 'devce 1'},
            //     {value: '222', label: 'devce 2'},
            //     {value: '333', label: 'devce 3'},
            //     {value: '444', label: 'devce 4'},
            //     {value: '555', label: 'devce 5'},
            // ],
            test: 0,
        };
        this.currentGroupId = -1;
        this.currentRuleId = -1;
        this.currentRule = null;
        this.currentActionId = 'e250e806-324a-4de2-8a0a-0fe0cbbaf102';
        this.currentFenceId = -1;
        this.currentFenceGroupId = -1;
        this.currentFenceRuleId = -1;
        this.currentFenceRuleitem = null;
    }

    componentDidMount() {

        // this.props.loadAction({actionid:this.props.actionId});
        this.props.loadAssetClass(null, false);
        // this.props.loadAllGeoFences({devicetypeid: this.state.currentDeivecTypeId});
        this.props.loadAllGeoFences();
        this.props.getActionRule({ actionid: this.props.actionId });
        // this.props.getRuls();
    }
    componentWillUnmount() {

        this.props.clearActionRule();
        this.setState({
            rawData: [],
        });
    }
    componentWillReceiveProps(nextProps) {



        let fenceOptions = [];
        let assetClassOptions = [];
        if (nextProps.actionRules) {


            this.setState({
                rawData: nextProps.actionRules,
            });
        }
        if (nextProps.allfences) {
            fenceOptions = generateSelectOptions(nextProps.allfences, 'fencename', 'fenceid');
        }
        if (nextProps.fenceStatus === FENCE_UPDATE_FENCE_OBJ_SUCCEED && nextProps.fenceObj && this.currentFenceRuleitem) {
            this.currentFenceRuleitem.geofenceid = nextProps.fenceObj.fenceid;
            this.currentFenceRuleitem.geofencename = nextProps.fenceObj.fencename;
            this.setState((preState) => {
                return ({
                    rawData: preState.rawData,
                });
            });
        }
        // if (nextProps.assetClass){
        //     assetClassOptions = generateSelectOptions(nextProps.assetClass, 'assetclassname', 'assetclassid');
        // }
        this.setState({
            fenceOptions: fenceOptions,
            assetClassOptions: assetClassOptions,
            actionId: nextProps.actionId,
        });
    }

    modalToggle = () => {
        this.setState(prevState => ({
            assetModal: !prevState.assetModal,
        }));
    }

    geofenceToggle = () => {
        this.setState(prevState => ({
            geofenceModal: !prevState.geofenceModal,
        }));
    }

    handleAssetModalAddClick = (groupId, ruleId, selectedAssetOption, selectedDeviceOption, selectedAttributeOption, selectedAssetClassOption) => { // alert('1');
        // if (groupId < 0  || selectedAssetOption === null || selectedAttributeOption === null){
        //     return null;
        // }
        if (groupId < 0 || selectedAssetClassOption === null || selectedAttributeOption === null) {
            return null;
        }
        let that = this;
        // alert('2');
        this.setState((preState) => {

            let deviceId = -1;
            let devicename = "";
            if (selectedDeviceOption !== null) {
                deviceId = selectedDeviceOption.value;
                devicename = selectedDeviceOption.label;
            }
            let assetId = -1;
            let assetname = "";
            if (selectedAssetOption !== null) {
                assetId = selectedAssetOption.value;
                assetname = selectedAssetOption.label;
            }
            let relation = 1;

            if (preState.rawData[groupId].ruleitems.length <= 0) {
                relation = 0;
            }
            if (ruleId === -1) {
                // let newRule = {                   
                //     "actionid": that.currentActionId,
                //     "assetid": selectedAssetOption.vlaue,
                //     "assetname": selectedAssetOption.label,
                //     "deviceid": deviceId,
                //     "devicename": devicename,
                //     "attributeid": selectedAttributeOption.value,
                //     "attributename": selectedAttributeOption.label,
                //     "geofenceid": -1,
                //     "geofencename": "",
                //     "operator": 0,
                //     "value": 0,
                //     "relation": relation,
                // }
                let newRule = {
                    "actionid": that.props.actionId,
                    "assetclassid": selectedAssetClassOption.value,
                    "assetclassname": selectedAssetClassOption.label,
                    "assetid": assetId,
                    "assetname": assetname,
                    // "deviceid": deviceId,
                    // "devicename": devicename,
                    "attributeid": selectedAttributeOption.value,
                    "attributename": selectedAttributeOption.label,
                    "geofenceid": -1,
                    "geofencename": "",
                    "operator": 1,
                    "value": 0,
                    "relation": relation,
                }



                preState.rawData[groupId].ruleitems.push(newRule);
            } else {


                let rule = preState.rawData[groupId].ruleitems[ruleId];
                rule.assetclassid = selectedAssetClassOption.value,
                    rule.assetclassname = selectedAssetClassOption.label,
                    rule.assetid = selectedAssetOption ? selectedAssetOption.value : -1;
                rule.assetname = selectedAssetOption ? selectedAssetOption.label : '';
                rule.deviceid = deviceId;
                rule.devicename = devicename;
                rule.attributeid = selectedAttributeOption.value;
                rule.attributename = selectedAttributeOption.label;
            }
            return ({
                rawData: preState.rawData,
            });
        });

    }

    handleRelationChange = (e, groupIndex, ruleItem) => {
        ruleItem.relation = e.target.value;
        this.setState((preState) => {
            // preState.rawData[groupIndex].ruleitems[]
            return ({
                rawData: preState.rawData,
            });
        });
    }

    handleGroupOperatorChange = (e, groupitem, groupId) => {
        groupitem.grouprelation = e.target.value;
        this.setState((preState) => {
            return ({
                rawData: preState.rawData,
            });
        });
    }

    handleOperatorChange = (e, ruleItem, index) => {
        ruleItem.operator = e.target.value;
        this.setState((preState) => {
            return ({
                rawData: preState.rawData,
            });
        });
    }

    handleValueChange = (e, ruleItem, groupIndex, index) => {
        ruleItem.value = e.target.value;
        this.setState((preState) => {
            return ({
                rawData: preState.rawData,
            });
        });
    }

    handleRuleRemoveClick = (e, groupItem, groupIndex, index) => {

        this.setState((preState) => {
            if (!groupItem.ruleitems || groupItem.ruleitems.length < index) {
                return null;
            }
            let removedRuleItems = groupItem.ruleitems.splice(index, 1);
            if (groupItem.ruleitems.length === 0) {
                preState.rawData.splice(groupIndex, 1);
                if (preState.rawData.length > 0) {
                    preState.rawData[0].grouprelation = 0;
                }
            } else {
                groupItem.ruleitems[0].relation = 0;
            }
            return ({
                rawData: preState.rawData,
            });
        });
    }

    handleAssetEditClick = (e, groupItem, ruleItem, groupIndex, index) => {


        this.currentGroupId = groupIndex;
        this.currentRuleId = index;
        this.currentRule = ruleItem;
        // this.setState((preState)=>({
        //     currentGroupId: groupIndex,
        //     currentRuleId: index,
        //     currentRule: ruleItem,
        //     assetModal: !preState.assetModal,
        // }));
        // this.props.getAssetClassAttribute({assetclassid:ruleItem.assetclassid});
        this.modalToggle();
    }

    handleGeofenceEditClick = (e, groupItem, ruleItem, groupIndex, index) => {
        this.currentFenceId = ruleItem.geofenceid;
        this.currentFenceGroupId = groupIndex;
        this.currentFenceRuleId = index;
        this.currentFenceRuleitem = ruleItem;
        this.geofenceToggle();
    }

    renderRuleLine(groupItem, ruleItem, groupIndex, index) {
        return (
            <div key={index}>
                {(ruleItem.relation > 0) &&
                    <Row>
                        <Col md={1}>
                            <Input type="select" value={ruleItem.relation} width="20%" onChange={(e) => { this.handleRelationChange(e, groupIndex, ruleItem) }} >
                                <option value={1}>{'AND'}</option>
                                <option value={2}>{'OR'}</option>
                                {/* <option value={3}>{'NOT'}</option> */}
                            </Input>
                        </Col>
                    </Row>
                }
                <Row>
                    {/* <Col md={3} style={{textAlign: 'right', verticalAlign: 'middle'}}>
                        <Label style={{ display: 'inline'}}><h5>{ruleItem.attributename} :</h5></Label>
                    </Col> */}
                    <Col md={1}>
                    </Col>
                    <Col md={2}>
                        {/* <Label  style={{ display: 'inline'}} >Asset</Label>
                        <InputGroup>
                            <Input type='text' placeholder="Asset..." value = {ruleItem.assetname}/>
                            <InputGroupAddon addonType="append">
                                        <Button color="custom" onClick = {(e) => this.handleAssetEditClick(e, groupItem, ruleItem, groupIndex, index)}>
                                            <SquareEditOutlineIcon/>
                                        </Button>
                                    </InputGroupAddon>
                        </InputGroup> */}
                        <Label style={{ display: 'inline' }} >Asset Class</Label>
                        <InputGroup>
                            <Input type='text' placeholder="Asset Class..." value={ruleItem.assetclassname} />
                            <InputGroupAddon addonType="append">
                                <Button
                                    style={{ padding: '6px' }}
                                    color="custom"
                                    onClick={(e) => this.handleAssetEditClick(e, groupItem, ruleItem, groupIndex, index)}>
                                    <SquareEditOutlineIcon />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col md={2}>
                        {/* <Label  style={{ display: 'inline'}} >Device</Label>
                    <InputGroup>
                        <Input type='text' placeholder="Device..." value = {ruleItem.devicename} />
                        <InputGroupAddon addonType="append">
                                        <Button color="custom" onClick = {(e) => this.handleAssetEditClick(e, groupItem, ruleItem, groupIndex, index)}>
                                            <SquareEditOutlineIcon/>
                                        </Button>
                                    </InputGroupAddon>
                        </InputGroup> */}
                        <Label style={{ display: 'inline' }} >Asset</Label>
                        <InputGroup>
                            <Input type='text' placeholder="Asset..." value={ruleItem.assetname} />
                            <InputGroupAddon addonType="append">
                                <Button style={{ padding: '6px' }} color="custom" onClick={(e) => this.handleAssetEditClick(e, groupItem, ruleItem, groupIndex, index)}>
                                    <SquareEditOutlineIcon />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    <Col md={2}>
                        <Label style={{ display: 'inline' }} >Attribute</Label>
                        <InputGroup>
                            <Input type='text' placeholder="Attribute..." value={ruleItem.attributename} />
                            <InputGroupAddon addonType="append">
                                <Button style={{ padding: '6px' }} color="custom" onClick={(e) => this.handleAssetEditClick(e, groupItem, ruleItem, groupIndex, index)} >
                                    <SquareEditOutlineIcon />
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                    {
                        ruleItem.attributeid === -1 ?
                            <React.Fragment>
                                <Col md={2}>
                                    <Label style={{ display: 'inline' }} >Geofence</Label>
                                    <InputGroup>
                                        <Input placeholder="geofence..." value={ruleItem.geofencename} />
                                        <InputGroupAddon addonType="append">
                                            <Button color="custom" onClick={(e) => this.handleGeofenceEditClick(e, groupItem, ruleItem, groupIndex, index)} >
                                                <SquareEditOutlineIcon />
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Col>
                                <Col md={1}>
                                    <Label style={{ display: 'inline' }} >Operation</Label>
                                    <Input type="select" value={ruleItem.operator} width="20%" onChange={(e) => { this.handleOperatorChange(e, ruleItem, index) }} >
                                        <option value={1}>{'In'}</option>
                                        <option value={2}>{'Out'}</option>
                                        <option value={3}>{'Stay'}</option>
                                    </Input>
                                </Col>
                                <Col md={1}>
                                    <Label style={{ display: 'inline' }} >Value</Label>
                                    <Input type="text" value={ruleItem.value} width="30%" onChange={(e) => { this.handleValueChange(e, ruleItem, index) }}></Input>
                                </Col>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Col md={1}>
                                    <Label style={{ display: 'inline' }} >Operation</Label>
                                    <Input type="select" value={ruleItem.operator} width="20%" onChange={(e) => { this.handleOperatorChange(e, ruleItem, index) }} >
                                        <option value={1}>{'='}</option>
                                        <option value={2}>{'>'}</option>
                                        <option value={3}>{'>='}</option>
                                        <option value={4}>{'<'}</option>
                                        <option value={5}>{'<='}</option>
                                        <option value={6}>{'<>'}</option>
                                    </Input>
                                </Col>
                                <Col md={1}>
                                    <Label style={{ display: 'inline' }} >Value</Label>
                                    <Input type="text" value={ruleItem.value} width="30%" onChange={(e) => { this.handleValueChange(e, ruleItem, groupIndex, index) }}></Input>
                                </Col>
                            </React.Fragment>
                    }

                    <Col md={1}>
                        {/* <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} /> */}
                        {/* <Label  >&nbsp;</Label> */}
                        <br />
                        <Button outline variant="primary" color="danger" type="button" style={{ margin: 'auto' }} size='sm' value={index} onClick={(e) => { this.handleRuleRemoveClick(e, groupItem, groupIndex, index) }} >
                            <TrashCanOutlineIcon color='#ff0000' />
                        </Button>
                    </Col>
                </Row>
            </div>
        )
    }

    // renderRuleSet(){
    //     return(
    //         <div>
    //             {
    //                 this.state.rawData && this.state.rawData[0].ruleitems && 
    //                 this.state.rawData[0].ruleitems .map((item, i) =>{
    //                     return(
    //                         this.renderRuleLine(item, i)
    //                     )
    //                 })
    //             }
    //         </div>
    //     )
    // }
    handleAddItemClick = (e, groupitem, groupId) => {
        this.currentGroupId = groupId;
        this.currentRuleId = -1;
        this.currentRule = null;
        this.modalToggle();
    }

    handleDeleteGroupClick = (e, groupitem, groupId) => {
        this.setState((preState) => {
            if (!preState.rawData || preState.rawData.length < groupId) {
                return null;
            }
            preState.rawData.splice(groupId, 1);
            if (preState.rawData.length > 0) {
                preState.rawData[0].grouprelation = 0;
            }

            return ({
                rawData: preState.rawData,
            });
        });
    }

    renderRuleCard(groupitem, groupId) {
        return (
            <div>
                {
                    groupitem.ruleitems && groupitem.grouprelation > 0 &&
                    <Row>
                        <Col md={1}>
                            <Input type="select" width="20%" value={groupitem.grouprelation} onChange={(e) => { this.handleGroupOperatorChange(e, groupitem, groupId) }}  >
                                <option value={1}>{'AND'}</option>
                                <option value={2}>{'OR'}</option>
                                {/* <option value={3}>{'END'}</option> */}
                            </Input>
                        </Col>
                    </Row>
                }
                {
                    groupitem.ruleitems &&
                    <VariableContainer>
                        {/* <Card style={{ backgroundColor: '#D3D3D3', borderColor: '#333', margin: 5  }}>
                        <CardBody style={{ backgroundColor: '#D3D3D3', borderColor: '#333', padding: 10 }}> */}

                        {groupitem.ruleitems.map((item, i) => {
                            return (
                                this.renderRuleLine(groupitem, item, groupId, i)
                            )
                        })}

                        <br />

                        <div style={{ textAlign: 'right' }}>
                            <Button variant="primary" color="custom" type="button" style={{ margin: 'auto' }} size='sm' onClick={(e) => this.handleAddItemClick(e, groupitem, groupId)}>
                                <PlusCircleOutlineIcon /> Add Item
                            </Button> {' '}
                            <Button outline variant="primary" color="danger" type="button" style={{ margin: 'auto' }} size='sm' onClick={(e) => this.handleDeleteGroupClick(e, groupitem, groupId)}>
                                <TrashCanOutlineIcon color='#ff0000' /> Remove Group
                            </Button>
                        </div>
                        {/* <br/>
                        {groupitem.ruleitems.map((item, i) =>{
                            return(                            
                                this.renderRuleLine(groupitem, item, groupId, i)                            
                            )
                        })} */}
                        {/* </CardBody>
                    </Card> */}
                    </VariableContainer>
                }

            </div>
        )
    }

    renderGroups = () => {
        return (
            <div>
                {
                    this.state.rawData &&
                    this.state.rawData.map((item, i) => {
                        return (
                            this.renderRuleCard(item, i)
                        )
                    })
                }
                {/* {this.renderRuleCard(this.state.rawData[0], 0)} */}
            </div>
        )
    }

    handleAddGroupClick = () => {

        let newGroup = {
            "groupid": 0,
            "groupindex": 0,
            "grouprelation": 1,
            "ruleitems": []
        }

        this.setState((preState) => {
            if (!preState.rawData) {
                return null;
            }
            const relation = preState.rawData.length === 0 ? 0 : 2;
            let newGroup = {
                "groupid": 0,
                "groupindex": 0,
                "grouprelation": relation,
                "ruleitems": []
            }
            preState.rawData.push(newGroup);

            return ({
                rawData: preState.rawData,
            });
        });
    }

    handleMapOnAddClick = (selectedFenceOption) => {
        this.currentFenceRuleitem.geofenceid = selectedFenceOption.value;
        this.currentFenceRuleitem.geofencename = selectedFenceOption.label;
        this.setState((preState) => {
            return ({
                rawData: preState.rawData,
            });
        });
    }

    handleOnAddNewClick = (shapeObj) => {
        this.props.upsertGeofence(shapeObj);
    }

    handleNextClick = () => {


        // this.props.onNext();
        if (this.validateActionRule()) {
            this.props.upsertActionRule(this.state.rawData, this.props.actionId);
        }
        // this.props.upsertActionRule(this.state.rawData, this.props.actionId);
    }

    validateActionRule = () => {
        let reValue = true;
        if (this.state.rawData) {
            this.state.rawData.forEach((group, gindex) => {
                if (group.ruleitems) {
                    group.ruleitems.forEach((rule, rindex) => {
                        if (!rule.assetclassid || rule.assetclassid < 0) {
                            reValue = false;
                            toast.error("Please select a asset class. ");
                        }
                        if (rule.attributeid == -1 && rule.geofenceid == -1) {
                            reValue = false;
                            toast.error("Please select/create a geo fence.");
                        }
                    });
                }
                // else{
                //     reValue = false
                // }
            })
        }
        // else{
        //     reValue = false;
        // }
        return reValue;
    }

    render() {
        return (
            <StepTemplate
                classes={this.props.classes}
                canGoBack={this.props.canGoBack}
                canGoForward={this.props.canGoForward}
                onBack={this.props.onBack}
                onNext={this.handleNextClick}
                onSkip={this.props.onNext}
                onCancel={this.props.onCancel}
                text={this.props.text}
            >
                <div style={{ textAlign: 'left' }}>
                    <Button variant="primary" color="custom" type="button" style={{ margin: 'auto' }} size='sm' onClick={this.handleAddGroupClick} >
                        <PlusCircleOutlineIcon /> Add Group
                    </Button>
                </div>
                {/* {this.renderRuleSet()} */}
                {this.renderGroups()}
                <AssetSelectModal
                    modalSetVisible={this.state.assetModal}
                    assetClass={this.props.assetClass}
                    groupId={this.currentGroupId}
                    ruleId={this.currentRuleId}
                    ruleItem={this.currentRule}
                    toggle={this.modalToggle}
                    onAddClick={this.handleAssetModalAddClick}
                />
                <GeofenceModal
                    modalSetVisible={this.state.geofenceModal}
                    toggle={this.geofenceToggle}
                    allfences={this.props.allfences}
                    fenceOptions={this.state.fenceOptions}
                    fenceId={this.currentFenceId}
                    handleOnAddClick={this.handleMapOnAddClick}
                    handleOnAddNewClick={this.handleOnAddNewClick}
                />
            </StepTemplate>
        );
    }
}

// export default StepCondition; 

function mapStateToProps(state) {



    const allfences = state.fence.fenceData;
    const fenceObj = state.fence.fenceObj;
    const fenceStatus = state.fence.type;
    const assetClass = state.mapboard.assetClass;
    // const allrules = state.rule.rules;
    // const props = {...state.job, allfences, allrules};
    const props = { ...state.job, allfences, fenceObj, fenceStatus, assetClass };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ loadAssetOptions, loadAssetDevice, loadAction, closeAlertOff, loadAllGeoFences, upsertGeofence, loadAssetClass, loadAllAssets, getAssetClassAttribute, getActionRule, upsertActionRule, clearActionRule }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StepCondition);