/*eslint-disable*/
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip, Card, CardBody, CardFooter, Col } from "reactstrap";
import { getCurrentUser, hasRole} from '../../../factories/auth';
import { toast } from "react-toastify";
import ContactList from '../../Contact/components/ContactList' //;'./ContactList';
import AddContactForm from "../../Contact/components/AddContactForm";
import ContactModal from "../../Contact/components/CreateNewContactModal";
import {AccountEditIcon} from 'mdi-react';

const ContactBoard = props => {
    // const { buttonLabel, className } = props;
    // const userObj = getCurrentUser();
    let userObj = props.userObj;
    // const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [contactObj, setContactObj] = useState(null);

    // const toggle = () => setModal(!modal);
    const toggle = () => {
        var currentLocation = window.location;
        currentLocation.pathname == "/pages/userprofile" ? history.back() : props.toggle()
    };

    const togglEdit = () => setModalEdit(!modalEdit);

    const validateContactName = (name) => {
        return name && name.length > 0 ;
    }

    const validatePhoneNumber = (phoneNum) => {
        var regex =  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return (phoneNum && regex.test(phoneNum));
    }
    
    const validateEmail = (email) => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;        
        return  (email && regex.test(email));
    }

    const validateContactObj = () => {
        let val = false;
        if (contactObj){
            let validateName = validateContactName(contactObj.name);
            let validateEmail = validateContactName(contactObj.email);
            let validatePhone = validateContactName(contactObj.phone);
            if (!validateName) {
                toast.error('Name can NOT be empty.');
            }
            if (!validateEmail) {
                toast.error('Email Address is NOT valid.');
            }
            if (!validatePhone) {
                toast.error('Phone Number is NOT valid.');
            }

            val = validateName && validateEmail && validatePhone;
        }
        return val;
    }
  
    const handleSubmit = () => {
      if (userObj === null || userObj.userName.length === 0){
        // this.props.history.push('/log_out');
        toast.error('Can NOT get current user information, please login again');
        return;
      }
  
    //   if (!name || name.length === 0) {
    //     toast.error('Name can NOT be empty');
    //     return;
    //   }
  
    //   if (!validateEmail(email)) {
    //     toast.error('Email address is not valid');
    //     return;
    //   }
  
    //   if (!validatePhoneNumber(phone)) {
    //     toast.error('Phone number is not valid');
    //     return;
    //   }
      
    //   let contactObj = {
    //     id: 0, //0 or not exist, will create new contact
    //     name: name, //if id not exist  but name exist, update
    //     email: email,
    //     phone: phone,
    //     emailenable: isEmailChecked,
    //     phoneenable: isPhoneChecked,
    //     owner: userObj.userName,
    //     companycode: userObj.companyCode,
    //     createdtime: null
    //   };

        if (validateContactObj()){
             
            props.upsertContact(contactObj);
        }
        togglEdit();
  
    };

    const handleTextInputChange = (e) => {
        if (contactObj){
            let key = e.target.name;
            let value = e.target.value;
            // let contectObjClone = JSON.parse(JSON.stringify(contactObj))
            const contactObjNew= {...contactObj, [key]: value};
            setContactObj(contactObjNew);
        }
        
    }

    const handleCheckInputChange = (e) => {
        if (contactObj){
            let key = e.target.name;
            let value = e.target.checked;
            // let contectObjClone = JSON.parse(JSON.stringify(contactObj))
            const contactObjNew= {...contactObj, [key]: value};
            setContactObj(contactObjNew);
        }
        
    }

    const editContact = (contactid) => {
        if (props.contactList) {
            let foundId = props.contactList.findIndex((item, index) => (item.id == contactid));
            if (foundId > -1){
                setContactObj(props.contactList[foundId]);
                togglEdit();
            }else{
                toast.error('Can NOT found the selected contact.');
            }
            
        }
    }

    const renderEditModle = () => {
        let name = contactObj ? contactObj.name : '';
        let email = contactObj ? contactObj.email : '';
        let phone = contactObj ? contactObj.phone : '';
        let isEmailChecked = contactObj ? contactObj.emailenable : false;
        let isPhoneChecked = contactObj ? contactObj.phoneenable : false;
        return (
            <Modal
                isOpen={modalEdit}
                // toggle={togglEdit}
                // className={className}
            >
                <ModalHeader toggle={togglEdit}>Edit a Receiver</ModalHeader>
                <ModalBody>
                <AddContactForm
                    name = {name}
                    email = {email}
                    phone = {phone}
                    isEmailChecked = {isEmailChecked}
                    isPhoneChecked = {isPhoneChecked}
                    // onFormSubmit={handleSubmit}
                    onNameChange={handleTextInputChange}
                    onPhoneChange={handleTextInputChange}
                    onEmailChange={handleTextInputChange}
                    onEmailEnableChange={handleCheckInputChange}
                    onPhoneEnableChange={handleCheckInputChange}
                />
                </ModalBody>
                <ModalFooter>
                <Button outline color="custom" onClick={handleSubmit} disabled={!contactObj}>
                    Submit
                </Button>{" "}
                <Button outline color="secondary" onClick={togglEdit}>
                    Cancel
                </Button>
                </ModalFooter>
      </Modal>
        );
    }
   
  
    return (
      <div>
        {/* <button id="ButtonEdit" className="btn btn-success" onClick={toggle}> */}
          {/* {props.label} */}
          {/* <i class="fas fa-user-edit fa-lg"></i> */}
          {/* <AccountEditIcon size={32}/> */}
        {/* </button> */}
        {/* <UncontrolledTooltip placement="top" target="ButtonEdit">
                Edit Receivers
        </UncontrolledTooltip> */}
        <Card
        //   isOpen={modal}
        //   toggle={toggle}
          // className={className}
        //   size = 'lg'
        >
          {/* <ModalHeader toggle={toggle}>Edit Receivers</ModalHeader> */}
          <CardBody>
            {/* <AddContactForm
              name = {name}
              onFormSubmit={handleSubmit}
              onNameChange={handleNameChange}
              onPhoneChange={handlePhoneChange}
              onEmailChange={handleEmailChange}
              onEmailEnableChange={handleIsEmailEnableChange}
              onPhoneEnableChange={handleIsPhoneEnableChange}
            /> */}
            <Col md={3} style={{textAlign: 'left'}}>
                <ContactModal 
                    label={'Add Receiver'}
                    className={'container'}
                    upsertContact={props.upsertContact}
                />
            </Col>
            <ContactList 
                contactList = {props.contactList} 
                editContact = {editContact}
                deleteContact = {props.deleteContact}
            />
            {renderEditModle()}
         <hr/>
          <div style={{textAlign: 'right'}}>
            {/* <Button color="secondary" onClick={handleSubmit}>
              Submit
            </Button>{" "} */}
            <Button outline color="custom" onClick={()=>history.back()}>
                Cancel
            </Button>
          </div>
          </CardBody>
        </Card>
      </div>
    );
  };
  
  export default ContactBoard;