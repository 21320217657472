/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import check_mark_circle from '../../../shared/img/icon/check_mark_circle.svg';
import icon_edit from '../../../shared/img/icon/edit.png';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_add from '../../../shared/img/icon/add.png';
import PlusIcon from 'mdi-react/PlusIcon';
import {EditTextButton, DeleteTextButton} from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class AttributeThemeList extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleRowClick = (row) => {
        //  alert(`You click row id: ${row.id}`);
        this.props.handleRowClick(row);
        // alert('yes');
    }

    handleRowDeleteClick = (row) => {
        this.props.handleRowDeleteClick(row);
    }
    handleAddNewClick = () => {
        this.props.handleAddNew();
    }

    // options = {
    //     onRowClick: this.handleRowClick,
    //     onRowDoubleClick: function(row) {
    //         // alert(`You double click row id: ${row.id}`);
    //     }
    // };


    editButtonFormatter = (cell, row) => {
        let path = `/pages/device/${cell}`;
        return (
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.handleRowClick(row); }}/>{' '}
                {/* <DeleteTextButton label={"Remove"} onClick={(e) => { this.handleRowDeleteClick(row); }}/> */}
                <ConfirmDeleteDialog 
                    label={"Remove"} 
                    onConfirm={(e)=>{this.handleRowDeleteClick(row);}}
                    msg={'Do you want to delete the select Geofence ? '}
                />
                {/* <img src={icon_edit} style={{ width: 20, height: 20 }} alt={"Edit"} onClick={(e) => { this.handleRowClick(row); }} />{' '} */}
                {/* <img src={icon_delete} style={{ width: 20, height: 20 }} alt={"Delete"} onClick={(e) => { this.handleRowDeleteClick(row); }} /> */}
            </div>
        )
    }
        // --------------------------------------------------------------------------------------------------
        handleInsertButtonClick = (onClick) => {
            // Custom your onClick event here,
            // it's not necessary to implement this function if you have no any process before onClick        
            // this.props.history.push('/pages/geofence');
            // return <Redirect  to='/pages/asset/' />
            // this.props.history.push('/pages/actionedit/0');
            this.handleAddNewClick();
            // onClick();
          }
          createCustomInsertButton = (onClick) => {
            return (
              <InsertButton
                btnText='Add New'
                btnContextual='btn-success'
                // className='my-custom-class'
                // style={{backgroundColor:'#ffffff'}}
                btnGlyphicon='glyphicon-edit'
                onClick={ () => this.handleInsertButtonClick(onClick) }>
                    {/* <PlusCircleOutlineIcon size={100}/> */}
                    {/* <img src={icon_add} style={{width: 30, height: 30}} />  */}
                    <PlusIcon size={'large'}/>
                    Add New Theme
                </InsertButton>
            // <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
            );
          }
        // --------------------------------------------------------------------------------------------
        tbToolBar = props => {
            return (
                <Row>
                    <Col md={4}>
                        {props.insertBtn}
                    </Col>
                    <Col md={2} style = {{textAlign: 'right'}}>
                    <Label for="asset" style={{display: 'block', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                    </Col>
                    <Col md={5}>
                        {/* <Label for="asset" style={{display: 'inline', width: "100%"}} ><h5><b>Asset Class</b></h5></Label> */}
                        <Input className="attributeInput" type="select" id="asset" name="asset" width="100%" value={this.props.currentAssetClassId} onChange={this.props.handleAssetClassChange} >
                            {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                            {this.props.assetClass && this.props.assetClass.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                        </Input>
                    </Col>
                    {/* <Col md={6}></Col>
                    <Col md={3}>
                        { props.searchField }
                        { props.components.searchPanel }
                    </Col> */}
                </Row>
            )
        }
        // --------------------------------------------------------------------------------------------

    render() {
         
        const datetimeFormatter = (cell, row) => {
            if (cell && cell.length > 0){
                return cell.split(".")[0].replace('T', ' ');
            }else{
                return null
            }
        }
        const options = {
            // page: 1,  // which page you want to show as default
            // sizePerPageList: [{
            //     text: '5', value: 5
            // }, {
            //     text: '10', value: 10
            // }, {
            //     text: 'All', value: this.props.themes.length
            // }], // you can change the dropdown list for size per page
            // sizePerPage: 5,  // which size per page you want to locate as default
            // pageStartIndex: 1, // where to start counting the pages
            // paginationSize: 5,  // the pagination bar size.
            // prePage: '<', // Previous page button text
            // nextPage: '>', // Next page button text
            // firstPage: '<<', // First page button text
            // lastPage: '>>', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
            // onRowClick: this.handleRowClick,
            // onRowDoubleClick: function(row) {
            //     // alert(`You double click row id: ${row.id}`);
            // }
            insertBtn: this.createCustomInsertButton,
            btnGroup: this.tbToolBar
        };

        return (
            <Col md={12}>
                <Card>
                    <CardBody>
                        {/* <div style={{ textAlign: 'right', paddingRight: 30 }}>
                            <img src={icon_add} style={{ width: 30, height: 30 }} alt={"Delete"} onClick={(e) => { this.handleAddNewClick(); }} /> */}
                            {/* <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleAddNewClick}>
                            New Rule
                        </Button> */}
                    {/* </div>
                    <br/> */}
                    <BootstrapTable data={ this.props.themes } tableHeaderClass = 'header_white' pagination = {false} options={ options }     search insertRow bordered={ false } maxHeight={500}>
                        <TableHeaderColumn dataField='themeid' isKey={ true } dataAlign="center" >Theme ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='themename' dataAlign="center" dataSort >Theme Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='themename' dataAlign="center" dataSort >Description</TableHeaderColumn> 
                        <TableHeaderColumn dataField='createdby' dataAlign="center" dataSort >Created By</TableHeaderColumn>      
                        <TableHeaderColumn dataField='createdtime' dataAlign="center" dataFormat={datetimeFormatter} >Created Time</TableHeaderColumn>     
                        {/* <TableHeaderColumn dataField='createtime' dataSort >Create Time</TableHeaderColumn> */}
                        {/* <TableHeaderColumn dataField='shape' >Shape</TableHeaderColumn>  */}
                        <TableHeaderColumn dataFormat={ this.editButtonFormatter } dataAlign="center" ></TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        </Col>
        )   
    }
}
export default AttributeThemeList;