/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {
    // compose, 
    bindActionCreators
} from "redux";
import { connect } from "react-redux";
import { Col, Container, Row } from 'reactstrap';
import { WEB_SUB_CHANNEL_WEATHER, WEB_SUB_CHANNEL_WEATHER_ALARM, WEB_SUB_EVENT_WEATERHR, WEB_SUB_EVENT_ALARM, WEB_SUB_CHANNEL_DEVICE, WEB_SUB_EVENT_DEVICE } from '../../../constants/appConstants';
import { PARKING_KEYS } from '../../../constants/dataConstants';
import moment from 'moment';
import {
    getWeatherAssetData,
    clearWeatherAssetData,
    getCompanyAssetClass,
    getWeatherAlarmCount,
    clearWeatherAlarmCount,
    getWeatherAssetHistoryData,
    getWeatherAsset24HData,
    getWeatherAsset1MonthData,
    clearWeatherAssetHistoryData,
    updateWeatherAssetData,
    getWeatherHourlyAlarmCount,
    updatePubSubAssetData,
    pubsubUpdateWeatherAssetHistoryData,
    pubsubUpdateWeatherAsset24HData,
    getLightning24HAlarmCount,
} from '../../../redux/actions/weatherAction';
import {
    getCalculateResult,
    getLatestCalculateResult
} from '../../../redux/actions/assetActions';
import GarageMainBoard from './GarageMainBoard';
import HistoryBoard from './HistoryBoard';
import { toast } from 'react-toastify';
import { reformWeatherAssetData, reformAlertPubsubData, updateStatusAsset } from '../../../factories/weather';
import { TIME_OPTION, ATTRIBUTE_NAME_ORDER_PARKING, LIGHT_GREY_COLOR } from '../../../constants/dataConstants';
import { ConsoleLineIcon } from 'mdi-react';
import Spinner from 'react-bootstrap/Spinner';

const WEATHER_ASSETCLASS_NAME = 'WeatherStation';
const WEATHER_ASSETCLASS_NAME_ARRAY = ['ParkingGarage'];
import { WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT, WEB_SUB_WEBSOCKET_PARKING_DEVICEMESSAGE, WEB_SUB_WEBSOCKET_PARKING_ALERTMESSAGE, WEB_SUB_WEBSOCKET_PARKING_ALARMMESSAGE } from '../../../constants/appConstants';
import { getJwttoken } from '../../../factories/utils';
import { Client } from '@stomp/stompjs';
import { ThemeProvider } from 'styled-components';
var stompClient;
class MainBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHistory: false,
            startDateMoment: null,
            endDateMoment: null,
            startTime: null,
            endTime: null,
            isTimeDisabled: true,
            currentAsset: null,
            assetList: [],
            alarmList: [],
            alarmSet: {},
            alarmSetObj: {},
            statusData: [],
            refresh: false,
            interval:null
        };
        this.weatherClassId = null;
        // this.eventSource = new EventSource(WEB_SUB_CHANNEL_WEATHER);
        // this.alertEventSource = new EventSource(WEB_SUB_CHANNEL_WEATHER_ALARM);
        // this.deviceEventSource = new EventSource(WEB_SUB_CHANNEL_DEVICE);
    }

    componentDidMount() {
        // this.eventSource.addEventListener(
        //     WEB_SUB_EVENT_WEATERHR, 
        //     (e)=> {

        //         this.refreshAssetData(e.data);
        //     } 
        // );
        // this.alertEventSource.addEventListener(
        //     WEB_SUB_EVENT_ALARM, 
        //     (e)=> {

        //         this.refreshAlarm(e.data);
        //         this.refreshAlarmSetObjByAlert(e.data);
        //         this.updateStatusAssetByAlert(e.data);
        //     } 
        // );
        // this.deviceEventSource.addEventListener(
        //     WEB_SUB_EVENT_DEVICE, 
        //     (e)=> {

        //         // this.refreshDeviceData(e.data);
        //         this.refreshAttributeHistoryData(e.data);
        //     } 
        // );
        // --------------------------------------------
        this.loadAssetByCompanyClass();

        window.addEventListener("resize", this.resize);
        this.resize();
        this.connectSocket();
    }
    //------------------------------------------------------------------------------
    connectSocket = () => {
        if (stompClient !== null && stompClient !== undefined) {
            stompClient.deactivate();
            //stompClient = null;

        }

        let query = '?q=' + getJwttoken();
        ///////////////////////////////////////////////////
        stompClient = new Client({
            brokerURL: WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT + query,
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            onConnect: this.connect_callback,
            onDisconnect: this.disconnect_callback
        });

        stompClient.activate();
        //////////////////////////////////////////////////
        //let socket_URL = new SockJS(WEB_SUB_WEBSOCKET_WEATHER_ENDPOINT+query);
        //stompClient = Stomp.over(socket_URL); 
        //stompClient.reconnectDelay=5000; 
        //stompClient.connect({"Header1": "Value" }, this.connect_callback,this.connectSocket) ;

    }
    connect_callback = () => {
        // called back after the stompClient is connected and authenticated to the STOMP server
        stompClient.subscribe(WEB_SUB_WEBSOCKET_PARKING_DEVICEMESSAGE, this.callbackDeviceMessage);
        stompClient.subscribe(WEB_SUB_WEBSOCKET_PARKING_ALERTMESSAGE, this.callbackAlertMessage);
        stompClient.subscribe(WEB_SUB_WEBSOCKET_PARKING_ALARMMESSAGE, this.callbackAlarmMessage);
        this.setState({ refresh: false });
    }
    disconnect_callback = () => {
        stompClient.deactivate();
        // called back after the stompClient is connected and authenticated to the STOMP server

    }


    callbackDeviceMessage = (message) => {
        // called when the client receives a STOMP DeviceMessage from the server

        if (message.body) {
            //console.log("got DeviceMessage with body " + message.body)
        } else {
            // 
        }
        this.refreshAttributeHistoryData(message.body);
    }
    callbackAlertMessage = (message) => {
        // called when the client receives a STOMP AlertMessage from the server


        if (message.body) {
            //console.log("got AlertMessage with body " + message.body)
        } else {
            //  
        }
        this.refreshAlarm(message.body);
        this.refreshAlarmSetObjByAlert(message.body);
        this.updateStatusAssetByAlert(message.body);
    }
    callbackAlarmMessage = (message) => {
        // called when the client receives a STOMP alarmMessage from the server      
        if (message.body) {
            //console.log("got AlarmMessage with body " + message.body)
        } else {
            //  
        }
        //this.refreshAssetData(message.body);
    }

    //-----------------------------------------------------------------

    async componentWillUnmount() {
        await clearInterval(this.state.interval)
        this.setState({interval:null})
        this.clearAssetData();
        // if (this.eventSource){
        //     this.eventSource.close();
        // }
        // if (this.alertEventSource){
        //     this.alertEventSource.close();
        // }
        // if (this.deviceEventSource){
        //     this.deviceEventSource.close();
        // }
        if (stompClient !== null && stompClient !== undefined) {
            stompClient.deactivate();


        }

        window.removeEventListener("resize", this.resize)

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.assetData && nextProps.assetData.length > 0 && nextProps.assetData != this.props.assetData) {

            let statusData = reformWeatherAssetData(nextProps.assetData);

            if (statusData && statusData[0].grouping == "parking") {
                this.setState({
                    statusData,
                });
            }
            // }
            // }
        }
    }
    // ------------------------------------------------------------------------------------------
    resize = () => {
        let currentIsSmall = (window.innerWidth <= 1370);
        this.setState(prev => {
            if (currentIsSmall !== prev.isSmall) {
                return ({
                    isSmall: currentIsSmall
                });
            }
        });
    }
    // ------------------------------------------------------------------------------------------
    // ----------- load helpers ------------------
    loadAssetByCompanyClass = async () => {
        try {
            if (this.weatherClassId == null) {
                let res = await getCompanyAssetClass();
                //  
                if (res.status === 200 && res.data) {
                    this.weatherClassId = [];
                    for (let i = 0; i < res.data.length; i++) {

                        if (WEATHER_ASSETCLASS_NAME_ARRAY.includes(res.data[i].assetclassname)) {
                            let assetclassId = res.data[i].assetclassid;
                            this.weatherClassId.push(assetclassId);
                            let assets = res.data[i].assets;
                            this.setState(prevState => {
                                if (Array.isArray(prevState.assetList) && prevState.assetList.length > 0) {
                                    return ({
                                        assetList: [...prevState.assetList, ...assets],
                                    });
                                } else {
                                    return ({
                                        assetList: assets,
                                    });
                                }
                            })
                        }
                    }
                } else {
                    throw new Error('Failed to get Weather Asset Class!');
                }
            }
            if (this.weatherClassId != null) {
                this.loadAssetData(this.weatherClassId);
            } else {
                throw new Error('Could not find Weather Asset Class!');
            }
        }
        catch (err) {
            console.error(err);
            toast.error(err);
        }

    }

    loadAssetData = (assetclassId) => {
        let params = {
            // entitygroup:[assetclassId],
            entitygroup: assetclassId,
            entity: '',
            starttime: "",
            endtime: "",
            latest: "1",
            themeid: "",
        };
        this.props.getWeatherAssetData(params);

    }

    // ------------------------------------------------------------------------------------------
    // ----------- update helplers ---------------
    refreshAssetData = (data) => {
        //  
        try {
            let subObj = JSON.parse(data);
            //  
            if (subObj) {
                //this.props.assetData
                let isInclude = this.props.assetData.some((item) => (item.assetid === subObj.device.assetid));
                let isDevice = Object.keys(PARKING_KEYS).some(key => Object.keys(subObj).includes(key))
                //  
                //
                //console.log('isInclude && isDevice')  
                //console.log(isInclude && isDevice)  
                if (isInclude && isDevice) {
                    this.props.updateWeatherAssetData(this.props.assetData, subObj);
                }
                let isCurrent = (this.state.currentAsset && subObj.device && this.state.currentAsset.assetid === subObj.device.assetid);
                if (this.state.showHistory && isCurrent && isDevice) {
                    this.updataHistoryData()
                }
            }
        }
        catch (e) {
            console.error('sub-error-alert', e);
        }
    }
    clearAssetData = () => {

    }

    refreshAlarm = (data) => {
        //  
        try {
            if (data) {
                let dataObj = JSON.parse(data);
                //  
                if (dataObj && dataObj.msgList) {
                    //  
                    this.setState({
                        alarmList: dataObj.msgList,
                        alarmObj: dataObj
                    });

                    this.setState(prevState => {
                        //  
                        for (let alarm of dataObj.msgList) {
                            //  
                            //  
                            //  
                            if (alarm.assetid && alarm.attrsMap) {
                                //  
                                if (!prevState.alarmSet[alarm.assetid]) {
                                    //  
                                    prevState.alarmSet[alarm.assetid] = {};
                                }
                                //  
                                for (let attrubute in alarm.attrsMap) {
                                    prevState.alarmSet[alarm.assetid][attrubute] = {
                                        status: dataObj.status,
                                        value: alarm.attrsMap[attrubute].value,
                                        timestamp: alarm.attrsMap[attrubute].timestamp,
                                    }
                                }
                            }
                        }

                        return ({
                            alarmSet: prevState.alarmSet,
                        })
                    });
                }
            }
        }
        catch (e) {
            console.error('sub-error-alarm', e);
        }
    };

    refreshAlarmSetObjByAlert = (alertPubsubData) => {
        this.setState(prevState => {
            let updateData = reformAlertPubsubData(alertPubsubData, prevState.alarmSetObj);
            return ({
                alarmSetObj: updateData,
            });
        }, () => {

        });
    }

    updateStatusAssetByAlert = (alertPubsubData) => {
        this.setState(prevState => {
            let updateData = updateStatusAsset(alertPubsubData, prevState.statusData);
            return ({
                statusData: updateData,
            });
        }, () => {

        })
    }

    refreshDeviceData = (data) => {
        //  

        try {
            if (data) {
                //  
                let dataObj = JSON.parse(data);
                //  
                if (dataObj && dataObj.msgs && dataObj.msgs.length > 0 && dataObj.msgs[dataObj.msgs.length - 1].msg) {
                    let hasAssetObj = this.props.assetData.some((item) => (item.assetid === dataObj.assetid));
                    //  
                    //  
                    //  
                    if (hasAssetObj) {
                        let msgObj = dataObj.msgs[dataObj.msgs.length - 1].msg;
                        let isInclude = !Object.keys(msgObj).some(item => (Object.values(PARKING_KEYS).includes(item)));
                        //  
                        //  
                        if (isInclude) {
                            // updata asset data.
                            //  
                            // this.props.updatePubSubAssetData(this.props.assetData, dataObj);
                            this.loadAssetByCompanyClass();
                            let isCurrent = (this.state.currentAsset && this.state.currentAsset.assetid === dataObj.assetid);
                            if (this.state.showHistory && isCurrent) {
                                this.updataHistoryData()
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.error('sub-error-device', e);
        }
    }
    // -------------------------------------------------------------------------------------------
    toggle = (toggle, asset) => {

        if (toggle != undefined && toggle != null && typeof toggle === 'boolean') {
            //   setShowHistory(toggle);
            this.setState({
                showHistory: toggle,
            }, async() => { 
                 await this.onShowHistoryChange(asset);
                 if(this.state.interval === null){
                    let inIntervId = setInterval(this.reloadData, 900000);
                    this.setState({
                       interval:inIntervId
                    })
                 }


            });
        } else {
            //   setShowHistory(!showHistory);
            this.setState(prevState => ({
                showHistory: !prevState.showHistory,
            }),
                async () => {
                    
                     this.onShowHistoryChange()
                     await clearInterval(this.state.interval)
                     this.setState({
                         interval:null
                     })
                     }
            );
        }

    }

    reloadData = () => {
        let { currentAsset} = this.state;

        let endDateStr = moment().format('YYYY-MM-DD');
        let assetId = currentAsset ? currentAsset.assetid : 0;
        if (assetId > 0){
            let obj = {
                "assetId": assetId,
                "deviceId": 0,
                "msgId": 0,
                "attributeId": 0,
                "startTime": endDateStr + " " + TIME_OPTION[0].label,
                "endTime": endDateStr + " " + TIME_OPTION[24].label
    
            }
        
            this.props.getLatestCalculateResult({assetId: assetId})
            this.props.getCalculateResult(obj);
        }

    }
        


    handleFilterChange = (newState) => {
        this.setState(prevState => (
            { ...newState }
        ),
            () => {
                this.updataHistoryData();
                this.updateAttributeHistoryData();
            }
        );
    }

    onShowHistoryChange = (asset) => {
        if (this.state.showHistory) {
            this.onHistoryShow(asset);
        } else {
            this.onHistoryHide();
        }
    }
    onHistoryShow = (asset) => {
        // let endtime = new Date();
        let endtime = moment();
        let starttime = moment(endtime).subtract(3, 'days');
        // let currentAsset = asset;
        let currentAsset = this.state.assetList.find((item, index) => (item.assetid === asset.assetid));
        if (!currentAsset) {
            currentAsset = this.state.assetList[0];
        }
        // let currentAsset = this.state.assetList[0];
        this.setState({
            currentAsset: currentAsset,
            startDateMoment: starttime,
            endDateMoment: endtime,
        },
            () => {
                this.props.getLatestCalculateResult({assetId: asset.assetid})
                this.updataHistoryData();           //might not be necessary
                this.updateAttributeHistoryData(); //might not be necessary
            }
        );
    }
    onHistoryHide = () => {
        this.props.clearWeatherAlarmCount();
        this.props.clearWeatherAssetHistoryData();
    }

    updataHistoryData = () => {
        let { currentAsset, startDateMoment, endDateMoment, startTime, endTime, isTimeDisabled } = this.state;
        //  
        let startDateStr = startDateMoment ? startDateMoment.utc().format('YYYY-MM-DD') : '';
        let endDateStr = endDateMoment ? endDateMoment.utc().format('YYYY-MM-DD') : '';
        let defaultStr = moment().format('YYYY-MM-DD');
        if (startDateStr === '' || endDateStr === '') {
            return;
        }
        if (startDateStr === endDateStr) {
            if (isTimeDisabled) {
                this.setState({
                    isTimeDisabled: false,
                });
            }
            if (startTime && endTime) {
                let hourlyParam = {
                    assetid: currentAsset ? currentAsset.assetid : 0, //1118,
                    starttime: `${startDateStr}:${startTime.value}:${endTime.value}`,
                    cntHours: 1,//'2020-02-27',
                }
                this.props.getWeatherHourlyAlarmCount(hourlyParam);
                // return;
            }

        } else {
            if (!isTimeDisabled) {
                this.setState({
                    isTimeDisabled: true,
                });
            }
            let param = {
                assetid: currentAsset ? currentAsset.assetid : 0, //1118,
                // starttime: startDateMoment ? startDateMoment.utc().format('YYYY-MM-DD') : '',//'2020-02-20',
                // endtime: endDateMoment ? endDateMoment.utc().format('YYYY-MM-DD') : '',//'2020-02-27',
                starttime: startDateStr,
                endtime: endDateStr,
            }
            let obj = {

                "assetId": currentAsset ? currentAsset.assetid : 0,
                "deviceId": 0,
                "msgId": 0,
                "attributeId": 0,
                "startTime": defaultStr  + " " + TIME_OPTION[0].label,
                "endTime": defaultStr  + " " + TIME_OPTION[24].label

            }
            this.props.getLatestCalculateResult({assetId: currentAsset ? currentAsset.assetid : 0})
            this.props.getCalculateResult(obj);
            this.props.getWeatherAlarmCount(param);

        }
    }

    updateAttributeHistoryData = () => {
        let { currentAsset, startDateMoment, endDateMoment, startTime, endTime, isTimeDisabled } = this.state;
        let startTimestamp = startDateMoment ? startDateMoment.utc().valueOf() : 0;
        let endTimestamp = endDateMoment ? endDateMoment.utc().valueOf() : moment().utc().valueOf();



        if (startTimestamp === endTimestamp) {
            if (isTimeDisabled) {
                this.setState({
                    isTimeDisabled: false,
                });
            }
            if (startTimestamp != 0 && startTime && endTime) {

                let startDateStr = startDateMoment.format('YYYY-MM-DD');
                if (startDateStr) {
                    startTimestamp = moment(`${startDateStr}T${startTime.value}`).utc().valueOf();
                    endTimestamp = moment(`${startDateStr}T${endTime.value}`).utc().valueOf();
                }
            }
        }
        //  
        //  


        const assetCreteria = {
            entity: currentAsset ? currentAsset.assetid : 0,
        }
        const historyCreteria = {
            ...assetCreteria,
            starttime: startTimestamp, //startDateMoment ? startDateMoment.utc().valueOf(): 0,
            endtime: endTimestamp, //endDateMoment ? endDateMoment.utc().valueOf() : moment().utc().valueOf(),
        }
        // this.props.getWeatherAssetHistoryData(historyCreteria);

        // fetching 24 hour data
        this.props.getWeatherAsset24HData(assetCreteria);

        // fetching 1 month data
        this.props.getWeatherAsset1MonthData(assetCreteria);

        // this.props.getLightning24HAlarmCount(currentAsset.assetid);
    }

    refreshAttributeHistoryData = (pubsubData) => {
        let { startDateMoment, endDateMoment, } = this.state;
        this.props.pubsubUpdateWeatherAssetHistoryData(pubsubData, startDateMoment, endDateMoment);
        this.props.pubsubUpdateWeatherAsset24HData(pubsubData);
    }
    // -------------------------------------------------------------------------------------------
    renderSpinner = () => {
        return (
            <Fragment>
                {this.props.assetDataLoading &&
                    <div style={{
                        position: 'Absolute ',
                        height: '100%',
                        width: '100%',
                        top: '0px',
                        left: '0px',
                        display: 'flex',
                        textAlign: 'center',
                        alignItems: 'center',
                        backgroundColor: LIGHT_GREY_COLOR,
                        opacity: '0.6',
                    }}
                    >
                        <Spinner
                            as="span"
                            animation="border"
                            variant="primary"
                            // size="sm"
                            // role="status"
                            // aria-hidden="true"
                            style={{ display: 'inline-block', margin: 'auto' }}
                        />
                    </div>
                }
            </Fragment>
        );
    }

    render() {
        //  
        //  
        //  
        //  
        let currentAssetData = null;
        let historyObjData = null
        let history24HObjData = null;
        //console.log('here12')

        if (this.state.currentAsset && this.props.assetData) {
            currentAssetData = this.props.assetData.find((item) => (item.assetid == this.state.currentAsset.assetid));
            //  
        }
        if (this.state.currentAsset && this.props.assetHistoryObj) {
            if (this.props.assetHistoryObj[this.state.currentAsset.assetid]) {
                historyObjData = [];
                let dataList = Object.values(this.props.assetHistoryObj[this.state.currentAsset.assetid]);
                for (let attrname of ATTRIBUTE_NAME_ORDER_PARKING) {
                    let attr = dataList.find(item => (attrname == item.displayname));
                    if (attr) {
                        historyObjData.push(attr);
                    }
                }
            }
        }
        if (this.state.currentAsset && this.props.asset24HObj) {
            //  
            if (this.props.asset24HObj[this.state.currentAsset.assetid]) {
                // history24HObjData = Object.values(this.props.asset24HObj[this.state.currentAsset.assetid]);
                history24HObjData = [];
                let dataList = Object.values(this.props.asset24HObj[this.state.currentAsset.assetid]);
                for (let attrname of ATTRIBUTE_NAME_ORDER_PARKING) {
                    let attr = dataList.find(item => (attrname == item.displayname));
                    if (attr) {
                        history24HObjData.push(attr);
                    }
                }
            }
            //  
            //  
        }
        //console.log('here2')
         
        
        //
        return (
            <Fragment>
                {/* <Row> */}
                {
                    this.state.showHistory ?
                        <HistoryBoard
                            currentAsset={this.state.currentAsset}
                            // assetData = {this.props.assetData} 
                            currentAssetData={currentAssetData}
                            startDateMoment={this.state.startDateMoment}
                            endDateMoment={this.state.endDateMoment}
                            startTime={this.state.startTime}
                            endTime={this.state.endTime}
                            isTimeDisabled={this.state.isTimeDisabled}
                            assetList={this.state.assetList}
                            countData={this.props.alarmCounts}
                            countUnit={this.props.countUnit}
                            historyData={this.props.assetHistoryData}
                            historyObjData={historyObjData} //Monthly Data
                            history24HObjData={history24HObjData} //24H Data
                            allAttriList={this.props.allAttriList}
                            toggle={this.toggle}
                            handleFilterChange={this.handleFilterChange}
                            isSmall={this.state.isSmall}
                            // alarmList = {this.state.alarmList}
                            // alarmObj = {this.state.alarmObj}
                            alarmSet={this.state.alarmSet}
                            assetHistoryLoading={this.props.assetHistoryLoading}
                            assetCountLoading={this.props.assetCountLoading}
                            assetHourlyCountLoading={this.props.assetHourlyCountLoading}
                            alarm24HCounts={this.props.alarm24HCounts}
                            alarm24HCountUnit={this.props.alarm24HCountUnit}
                            alarm24HCountLoading={this.props.alarm24HCountLoading}
                            statusData={this.state.statusData}
                            asset24HObj={this.props.asset24HObj}
                            asset1MonthObj={this.props.asset1MonthObj}
                            calculatedResult = {this.props.calculatedResult}
                            getCalculateResult={this.props.getCalculateResult}
                            latestCalculateResult = {this.props.latestCalculateResult}

                        />
                        :
                        <GarageMainBoard
                            userObj={this.props.userObj}
                            toggle={this.toggle}
                            assetDataLoading={this.props.assetDataLoading}
                            assetData={this.props.assetData}
                            assetExpendData={this.props.assetExpendData}
                            isSmall={this.state.isSmall}
                            // alarmList = {this.state.alarmList}
                            alarmObj={this.state.alarmObj}
                            alarmSet={this.state.alarmSet}
                            alarm24HCounts={this.props.alarm24HCounts}
                            statusData={this.state.statusData}
                            alarmSetObj={this.state.alarmSetObj}
                        //
                        />
                }
                {/* </Row> */}
                <div>
                    {this.renderSpinner()}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {


    const weather = state.weather;
    // const deviceTypes = [];
    const props = { weather, ...state.asset };
    
    return {...weather, ...state.asset};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getWeatherAssetData,
        clearWeatherAssetData,
        getWeatherAlarmCount,
        clearWeatherAlarmCount,
        getWeatherAssetHistoryData,
        getWeatherAsset24HData,
        getWeatherAsset1MonthData,
        clearWeatherAssetHistoryData,
        updateWeatherAssetData,
        getWeatherHourlyAlarmCount,
        updatePubSubAssetData,
        pubsubUpdateWeatherAssetHistoryData,
        pubsubUpdateWeatherAsset24HData,
        getLightning24HAlarmCount,
        getCalculateResult,
        getLatestCalculateResult
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(MainBoard);