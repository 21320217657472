/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, CardTitle, CardSubtitle, CardText, Label, Input, Button, Form, FormGroup } from 'reactstrap';
import { EditIcon } from 'mdi-react';
import {
    getAssetRecursiveData,
} from '../../../../redux/actions/dashboardActions';
import { itemFinder, groupBy } from '../../../../factories/utils';
import AssetDataEditElement from './AssetDataEditModal';
import ConfirmDeleteDialog from "../../../../shared/components/Dialog/ConfirmationDialog";
import DeleteIcon from "mdi-react/DeleteIcon";


class AssetWidget extends Component {
    constructor(props){
        super(props);
        this.state = {
            modal : false,
            assetid : null,
        }
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        if( nextProps.dashName 
            && nextProps.parameter 
            && nextProps.parameter.assetid 
            && (nextProps.parameter.assetid != this.state.assetid || nextProps.dashName !== this.props.dashName || nextProps.parameter.attributes !== this.state.attributes)
        ) { 
             
             
            this.setState({
                assetid: nextProps.parameter.assetid,
                attributes: nextProps.parameter.attributes
            });

            nextProps.getAssetRecursiveData(nextProps.parameter.assetid, nextProps.dashName);
        }
    }

// ------------------------------------------
    renderAssetCard=()=>{
        let h = JSON.parse(this.props.layout.position).h;
        let height = h * 30 - 20;

        let imgUrl = ''; //'http://13.88.246.7:8080/upload/sigfox/building_01.png';
        let assetTitle = '';
        const customerAttrs = [];
        let calculateAttrs = [] ; //[{id: 0, name: 'attribute', value: 100}];
        let deviceList = null; //new Map();
        // const deviceAttrs = [{id: 0, name: 'attribute', value: 100, timestamp: '2020'}];
        // deviceList.set('device name', deviceAttrs);

         
         
        if (this.props.assetdata && this.props.assetdata[this.props.dashName]) {
             
            const assetObj = this.props.assetdata[this.props.dashName].find(item => (item.assetid == this.state.assetid));
             
            if (assetObj) {
                assetTitle = assetObj.assetname
                if (assetObj.assetattributes){
                    const imgAttr = assetObj.assetattributes.find(item => (item.attmetaname == 'Image'));
                    if (imgAttr) {
                        imgUrl = imgAttr.value
                    }
                }
                if (assetObj.calculateAttributes) {
                    let calList = assetObj.calculateAttributes.filter(item => (this.props.parameter.attributes.some(para => (para.attributename == item.name && para.attrid == item.id))));
                    calculateAttrs = calList.map(item => ({id:item.id, name: item.displayname, value: item.value}));
                }
                if (assetObj.msgAttributes) {
                    let deviceAttList = assetObj.msgAttributes.filter(item => (this.props.parameter.attributes.some(para => (para.attributename == item.attributename && para.attrid == item.id))));
                    let deviceAttrs = deviceAttList.map(item => ({devicename: item.devicename, id: item.id, name: item.attributename, value: item.value, timestamp:item.timestamp }));
                    deviceList = groupBy(deviceAttrs, device => device.devicename);
                }
                
            }
        }
        let colA = 7;
        let colB = 5;
        if (( !calculateAttrs || calculateAttrs.length <= 0) && ( !deviceList || deviceList.size <= 0)){
            colA = 12;
            colB = 0;
        } 
        return(
            <Card style={{padding: '0px'}}>
       
                <CardBody  onMouseEnter={this.toggleHoverHistory} onMouseLeave={this.toggleHoverHistory} style={{padding: '0px'}}> 
                {/* <CardImg style={this.state.hoverHistory ? {"opacity": '0.3'}: {"opacity": '1'}}  top width="100%"  src={history_img} alt="Card image cap" />
            
                    <Button style={this.state.hoverHistory ? styles.middle2: styles.middle} variant="primary" color="custom" onClick={this.props.handleHistoryClick}>History</Button>  */}
                    <Row>
                        <Col md={colA}>
                            <img src={imgUrl} alt="Card image cap" width="100%" height={height.toString()}></img>
                            <div style={{margin: '10px',textAlign: 'center'}}>
                                {/* <h3><b>{title}</b></h3> */}
                                <span><h3><b>{assetTitle}</b></h3></span>
                            </div>

                        </Col>
                        <Col md={colB} style={{display: 'flex', alignContent: 'center', padding: '0px 15px 0px 0px'}}>
                            {/* <Button color="link" onClick={this.props.handlePaymentClick}><h4>Payment</h4></Button>  */}
                            {/* <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleHistoryClick()}}><span style={{fontSize:'18px'}}><b>Transactions</b></span></a> */}
                            <div  style={{margin: '15px 0px 0px 0px', textAlign: 'center'}}>
                                {calculateAttrs && calculateAttrs.length > 0 &&
                                    <div>
                                    <h5><b>Calculate Attributes</b></h5>
                                    {calculateAttrs.map(attri =>(
                                        <div>
                                            <div style={{margin: '10px 0px 0px 0px', width:'100%'}}>
                                                {attri.name}
                                            </div>
                                            <div style={{margin: '0px 0px 10px 0px', width:'100%'}}>
                                                {attri.value}
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                }

                                {deviceList && deviceList.size > 0 && Array.from(deviceList).map( ([key, value]) => (
                                    <div>
                                        <h5><b>{key}</b></h5>
                                        {value && value.map(attr => (
                                            <div>
                                                <div style={{margin: '10px 0px 0px 0px'}}>
                                                    {attr.name}
                                                </div>
                                                <div style={{margin: '0px 0px 10px 0px'}}>
                                                    {attr.value}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
// ------------------------------------------
    toggle = () => {
         
        this.setState((prevState) => ({
            modal : !prevState.modal
        }))
    }
// ------------------------------------------


    deleteWidget = (val) => {
        console.log ('val is',val)
        console.log('id in delete is ',this.props.layout.id)

        this.props.onDeleteSubmit(this.props.layout.id);

        // this.setState(prevState=>({
        //     modal: ! prevState.modal,
        // }));
    }




    render(){
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                            {this.props.dashName}


                            <div style={{float: 'right', display:'inline', cursor: 'pointer'}}>
                                {this.props.layout.locked == 0 && <ConfirmDeleteDialog
                                    // label={""}
                                    icon = {(props)=>(<DeleteIcon size={15} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} {...props} />)}
                                    style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                    onConfirm={(e)=>{this.deleteWidget()}}
                                    msg={'Do you want to delete the selected widget ? '}
                                />}
                            </div>



                            {/* {this.props.layout.display === 0 && */}
                            <EditIcon size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}} onClick={this.toggle}/>
                            {/* } */}
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                {/* <CardBody style={{padding:15}}>   */}
                    {/* {this.renderAttributes()} */}
                    {this.renderAssetCard()}
                {/* </CardBody>   */}
          </Card>
          {/* <AttrParamEditModal            
            // assetList = {this.props.assetList}
            layout = {this.props.layout}
            parameter = {this.props.parameter}
            modal={this.state.modal}
            toggle={this.toggle}
            onEditSubmit={this.props.onEditSubmit}
          /> */}
          <AssetDataEditElement            
            // assetList = {this.props.assetList}
            layout = {this.props.layout}
            parameter = {this.props.parameter}
            modal={this.state.modal}
            toggle={this.toggle}
            onEditSubmit={this.props.onEditSubmit}
          />
          </Fragment>
        );
    }
}

function mapStateToProps(state) { 
    const props = {...state.dashboard};
    return props;
};
    
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getAssetRecursiveData,
        // getAssetsData, 
        // loadAssetList
        // getAttributeThreshold,
        // updatePubSubDevice,
    }, dispatch);
};
  
export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetWidget));
