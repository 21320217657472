/* eslint-disable */

import Worker from "./nvrDecode.worker";
import _, { initial } from 'lodash';

export const initNVRDecoderWorker = (handleDecoderOnLoad, handleOnVideoFrame, handleOnBufferError, handleOnErrorCode) =>{
    // console.log("INIT DECODER")
    let decoderWorker

    decoderWorker = new Worker();
    decoderWorker.addEventListener('message', (event) => {
        // console.log('Message from web worker:', event.data.message);
        switch(event.data.cmd){
            case "ready":
                handleDecoderOnLoad()
                break;
            case "getVideoFrame":
                //StartDecode()
                handleOnVideoFrame(event.data.data);
                break;
            case "bufferError":
                handleOnBufferError();
                break;
            case "errorCode":
                handleOnErrorCode(event.data.code, event.data.url);
                break;
            default:
                console.log("Unsupport messsage: " + event.data.message);
        }
    });

  
    return decoderWorker
}

