/* eslint-disable */
import React from 'react'

import { Input } from 'reactstrap'

export default function SingleSelection({ forIdName, onChange, value, defaultValue, options, getOptionLabel, getOptionValue, err }) {
  if (!getOptionLabel) getOptionLabel = ({ label }) => label;
  if (!getOptionValue) getOptionValue = ({ value }) => value;
  return (
    <Input type="select" width="90%"
      style={{ borderColor: err ? 'red' : '' }}
      id={forIdName}
      name={forIdName}
      onChange={onChange}
      value={value}
    >
      {!defaultValue && <option hidden disabled value=''> --- Select An Option --- </option>}
      {options && options.map(o => ({ ...o, label: getOptionLabel(o), value: getOptionValue(o) }))
        .map(({ value, label }) => {
          // console.log(value)
          if (value)
            return (<option key={value} value={value}>{label}</option>)
          return
        })}
    </Input>
  )
}