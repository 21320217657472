/* eslint-disable */
import React, { Component, useState, Fragment } from 'react';
import { GREY_COLOR } from '../../../constants/dataConstants';
import './blinking.scss';

const BatteryWidget = (props) => {
    let {id, level, style} = props;

    const rederBarrery = () => {
         
        if(level != null && level != undefined){
            switch(level){
                case -1:
                    return(
                        <Fragment>
                            {renderNull()}
                        </Fragment>
                    )
                case 0:
                    return(
                        <Fragment>
                            {renderEmpty()}
                        </Fragment>
                    )
                case 1:
                    return(
                        <Fragment>
                            {renderLow()}
                        </Fragment>
                    )
                case 2:
                    return(
                        <Fragment>
                            {renderMidamLow()}
                        </Fragment>
                    )
                case 3:
                    return(
                        <Fragment>
                            {renderMidamHigh()}
                        </Fragment>
                    )
                case 4:
                    return(
                        <Fragment>
                            {renderFull()}
                        </Fragment>
                    )
                default: 
                    return null;
            }
        }
    }

    const renderEmpty = () => {
        return (
            <g
                id="g4880"
                className= {'pulse_high'}
            >
                <rect
                    ry="28.571428"
                    y="579.61194"
                    x="54.571423"
                    height="394.28571"
                    width="270"
                    id="rect4700"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#d40000',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#d40000;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="5.0285716"
                    y="560.29767"
                    x="152.85715"
                    height="10.057143"
                    width="79.542854"
                    id="rect4702"
                    style={{opacity:1,fill:'#ff0000',fillOpacity:1,stroke:'#d40000',strokeWidth:12.79999924,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#ff0000;fill-opacity:1;stroke:#d40000;stroke-width:12.79999924;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="866.7547"
                    // x="83.142845"
                    x="154.095232"
                    height="71.428551"
                    // width="212.85716"
                    width="70.952387"
                    id="rect4704"
                    style={{opacity:1,fill:'#d40000',fillOpacity:1,stroke:'none',strokeWidth:20, strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#d40000;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                 <rect
                    ry="0"
                    // y="866.7547"
                    y="614.18341"
                    // x="83.142845"
                    x="154.095232"
                    // height="71.428551"
                    height="230.285652"
                    // width="212.85716"
                    width="70.952387"
                    id="rect4704"
                    style={{opacity:1,fill:'#d40000',fillOpacity:1,stroke:'none',strokeWidth:20, strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#d40000;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
            </g>
        )
    };
    const renderLow = () => {
        return (
            <g
                id="g4880"
                className= {'pulse_mid'}
            >
                <rect
                    ry="28.571428"
                    y="579.61194"
                    x="54.571423"
                    height="394.28571"
                    width="270"
                    id="rect4700"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#d40000',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#d40000;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="5.0285716"
                    y="560.29767"
                    x="152.85715"
                    height="10.057143"
                    width="79.542854"
                    id="rect4702"
                    style={{opacity:1,fill:'#ff0000',fillOpacity:1,stroke:'#d40000',strokeWidth:12.79999924,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#ff0000;fill-opacity:1;stroke:#d40000;stroke-width:12.79999924;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="866.7547"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id="rect4704"
                    style={{opacity:1,fill:'#d40000',fillOpacity:1,stroke:'none',strokeWidth:20, strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#d40000;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
            </g>
        )
    };

    const renderMidamLow = () => {
        return (
            <g
            id="g4874">
                <rect
                    ry="28.571428"
                    y="579.61194"
                    // x="401.47617"
                    x="54.571423"
                    height="394.28571"
                    width="270"
                    id="rect4700-1"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#aa4400',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="5.0285716"
                    y="560.29767"
                    // x="499.76187"
                    x="152.85715"
                    height="10.057143"
                    width="79.542854"
                    id="rect4702-9"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#aa4400',strokeWidth:12.79999924,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:12.79999924;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="866.7547"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id="rect4704-3"
                    style={{opacity:1,fill:'#aa4400',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="782.56421"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id="rect4704-7-0"
                    style={{opacity:1,fill:'#aa4400',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
         </g>
        )
    }

    const renderMidamHigh = () => {
        return (
            <g
            id="g4874">
                <rect
                    ry="28.571428"
                    y="579.61194"
                    // x="401.47617"
                    x="54.571423"
                    height="394.28571"
                    width="270"
                    id="rect4700-9"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#ffcc00',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="5.0285716"
                    y="560.29767"
                    // x="499.76187"
                    x="152.85715"
                    height="10.057143"
                    width="79.542854"
                    id="rect4702-0"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#ffcc00',strokeWidth:12.79999924,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:12.79999924;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="866.7547"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id="rect4704-1"
                    style={{opacity:1,fill:'#ffcc00',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="782.56421"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id="rect4704-7-6"
                    style={{opacity:1,fill:'#ffcc00',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="698.37384"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id='rect4704-77-1'
                    style={{opacity:1,fill:'#ffcc00',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
         </g>
        )
    }

    const renderFull = () => {
        return (
            <g
            id="g4874">
                <rect
                    ry="28.571428"
                    y="579.61194"
                    // x="401.47617"
                    x="54.571423"
                    height="394.28571"
                    width="270"
                    id="rect4700-9"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#00a900',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="5.0285716"
                    y="560.29767"
                    // x="499.76187"
                    x="152.85715"
                    height="10.057143"
                    width="79.542854"
                    id="rect4702-0"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:'#00a900',strokeWidth:12.79999924,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:12.79999924;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="866.7547"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id="rect4704-1"
                    style={{opacity:1,fill:'#00a900',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="782.56421"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id="rect4704-7-6"
                    style={{opacity:1,fill:'#00a900',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="0"
                    y="698.37384"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id='rect4704-77-1'
                    style={{opacity:1,fill:'#00a900',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                 <rect
                    ry="0"
                    y="614.18341"
                    // x="430.04761"
                    x="83.142845"
                    height="71.428551"
                    width="212.85716"
                    id='rect4704-77-1'
                    style={{opacity:1,fill:'#00a900',fillOpacity:1,stroke:'none',strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:#aa4400;fill-opacity:1;stroke:none;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
         </g>
        )
    }
    const renderNull = () => {
        return (
            <g
            id="g4874">
                <rect
                    ry="28.571428"
                    y="579.61194"
                    // x="401.47617"
                    x="54.571423"
                    height="394.28571"
                    width="270"
                    id="rect4700-9"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:GREY_COLOR,strokeWidth:20,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:20;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />
                <rect
                    ry="5.0285716"
                    y="560.29767"
                    // x="499.76187"
                    x="152.85715"
                    height="10.057143"
                    width="79.542854"
                    id="rect4702-0"
                    style={{opacity:1,fill:'none',fillOpacity:1,stroke:GREY_COLOR,strokeWidth:12.79999924,strokeMiterlimit:4,strokeDasharray:'none',strokeOpacity:1}} 
                    // style="opacity:1;fill:none;fill-opacity:1;stroke:#aa4400;stroke-width:12.79999924;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" 
                />

         </g>
        )
    }

    let width= style && style.width ? style.width: "10mm";
    let height= style && style.height ? style.height: "20mm";
    let  viewBox= style && style.viewBox ? style.viewBox: "0 0 354.3307 566.92913";
    const svgid = id ? id : 'svg4152';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            // // width="400mm"
            // // height="160mm"
            // width="10mm"
            // height="20mm"
            width = {width}
            height = {height}
            // // viewBox="0 0 1417.3228 566.92913"
            // viewBox="0 0 354.3307 566.92913"
            viewBox = {viewBox}
            // id="svg4152"
            id={id}
            version="1.1"
            // className= {'pulse_mid'} 
        >
            <g
                id="layer1"
                transform="translate(0,-485.43307)"
            >
               {rederBarrery()}
            </g>
        </svg>
    );
}

export default BatteryWidget;