/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import check_mark_circle from '../../../shared/img/icon/check_mark_circle.svg'; 
import icon_edit from '../../../shared/img/icon/edit.png';
import icon_delete from '../../../shared/img/icon/delete.png';
import icon_add from '../../../shared/img/icon/add.png';
import PlusIcon from 'mdi-react/PlusIcon';
import {EditTextButton, DeleteTextButton} from '../../../shared/components/Button/IconButton';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class GeoFenceListCard extends Component {

    constructor(props){
        super(props);
        this.state={
            // data: [
            //     {   id: 0,
            //         name: 'fence 1',
            //         type: 'circle', 
            //         devicetypename: 't1',
            //         devicename: 'aaaa', 
            //         data:{
            //             center: {lat: 43.8135172592541, lng: -79.5758380996582},
            //             radius: 80
            //         }
            //     },
            //     {   id: 1,
            //         name: 'fence 2',
            //         type: 'rectangle', 
            //         devicetypename: 't1',
            //         devicename: 'bbbb', 
            //         data:{
            //                 south: 43.81258922466195, 
            //                 west: -79.57085991972656, 
            //                 north: 43.8211357979583, 
            //                 east: -79.55026055449218
            //             }
            //     },
            //     {   id: 2,
            //         name: 'fence 3',
            //         type: 'polygon',
            //         devicetypename: 't2',
            //         devicename: 'cccc', 
            //         data:[
            //             [
            //                 {lat: 43.822311404399926, lng: -79.56390763395996},
            //                 {lat: 43.82020588194602, lng: -79.55043221586914},
            //                 {lat: 43.812402415964726, lng: -79.5622768508789},
            //                 {lat: 43.8135172592541, lng: -79.5758380996582}
            //             ],
            //         ]
            //     },
            // ],
            // data2: [{id: 1, name:'n1',type:'t1'},{id: 2, name:'n2',type:'t2'},{id: 3, name:'n3',type:'t3'}]
        }
    }

    handleRowClick = (row) => {
        //  alert(`You click row id: ${row.id}`);
         this.props.handleRowClick(row);
        // alert('yes');
    }

    handleRowDeleteClick = (row) => {
        this.props.handleRowDeleteClick(row);
    }
    
    // options = {
    //     onRowClick: this.handleRowClick,
    //     onRowDoubleClick: function(row) {
    //         // alert(`You double click row id: ${row.id}`);
    //     }
    // };


    editButtonFormatter = (cell, row) => {
        let path = `/pages/device/${cell}`;
        return(
            // <a href={path} onClick = {() => {alert(cell); alert(row['devicename']);}}>{cell}</a>
            <div>
                <EditTextButton label={'Edit'} onClick={(e)=>{this.handleRowClick(row);}}/>{' '}
                {/* <DeleteTextButton label={"Remove"} onClick={(e)=>{this.handleRowDeleteClick(row);}}/> */}
                <ConfirmDeleteDialog 
                    label={"Remove"} 
                    onConfirm={()=>{this.handleRowDeleteClick(row);}}
                    msg={'Do you want to delete the select Geofence ? '}
                />
                {/* <img src={icon_edit} style={{width: 20, height: 20}} alt={"Edit"} onClick={(e)=>{this.handleRowClick(row);}}/>{' '} */}
                {/* <img src={icon_delete} style={{width: 20, height: 20}} alt={"Delete"} onClick={(e)=>{this.handleRowDeleteClick(row);}} /> */}
            </div>
        )
    }

    newEditButtonFormatter = (cell, row) => {
        return(
            <div>
                <EditTextButton label={''} onClick={(e)=>{this.handleRowClick(row);}}/>{' '}
            </div>
        )
    }
    removeButtonFormatter =(cell, row)=>{
        return(
            <div>
                <ConfirmDeleteDialog 
                    label={""} 
                    onConfirm={()=>{this.handleRowDeleteClick(row);}}
                    msg={'Do you want to delete the select Geofence ? '}
                />
            </div>
        )
    }

    shapeFormatter = (cell, row) => {
        let shape = '';
        switch (cell) {
            case 0 :
                shape = "Circle";
                break;
            case 1 :
                shape = "Rectangle";
                break;
            case 2 :
                shape = "Polygon";
                break;
            default: 
                shape = "";                
        }
        return(
            <span>{shape}</span>
        );
    }

    thumbnailFormatter = (cell, row) => {
        return(
            <div onClick={(e)=>{this.handleRowClick(row);}} style={{cursor:'pointer'}}>
                {mapThumbnail(row)}
            </div>
        )
    }

    handleAddNewButtonClick = (onClick) => {
        // this.props.addNewToggle();
        this.props.handleNewClick();
    }

    addNewUserButton = (onClick) => {
        return (
            <InsertButton
              btnText='Add New'
              btnContextual='btn-success'
              // className='my-custom-class'
            //   style={{backgroundColor:'#ffffff'}}
              btnGlyphicon='glyphicon-edit'
              onClick={ () => this.handleAddNewButtonClick(onClick) }>
                  {/* <PlusCircleOutlineIcon size={100}/> */}
                  {/* <img src={icon_add} style={{width: 30, height: 30}} /> */}
                  <PlusIcon/> Add New
              </InsertButton>
          // <button style={ { color: 'red' } } onClick={ onClick }>Add rows</button>
          );
    }

    render(){
         
        const options = {
            page: 1,  // which page you want to show as default
            sizePerPageList: [ {
              text: '5', value: 5
            }, {
              text: '10', value: 10
            }, {
              text: 'All', value:  this.props.data ? this.props.data.length : 0
            } ], // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            // paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            // paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
            // onRowClick: this.handleRowClick,
            // onRowDoubleClick: function(row) {
            //     // alert(`You double click row id: ${row.id}`);
            // }
            insertBtn: this.addNewUserButton,
          };
        return(
            <Col md={12}>
            <Card>
                <CardBody>
                    <BootstrapTable data={ this.props.data } tableHeaderClass = 'header_white' pagination = {false} options={ options }    search insertRow bordered={ false } maxHeight={600} >
                        <TableHeaderColumn dataField='fenceid' dataAlign="center" isKey={ true } hidden >Fence ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='fencename' dataAlign="center" dataSort >Fence Name</TableHeaderColumn> 
                        <TableHeaderColumn dataField='companyname' dataAlign="center" dataSort >Company</TableHeaderColumn> 
                        {/* <TableHeaderColumn dataField='devicename' dataAlign="center" dataSort >Device Name</TableHeaderColumn> */}
                        {/* <TableHeaderColumn dataField='shape' dataAlign="center" >Shape</TableHeaderColumn>  */}
                        <TableHeaderColumn dataField='shape' dataAlign="center" dataFormat={ this.shapeFormatter }>Shape</TableHeaderColumn> 
                        <TableHeaderColumn dataField="geodata"  dataAlign="center" dataFormat={ this.thumbnailFormatter } ></TableHeaderColumn>

                                    {/*Split the edit and remove button to match other manamge page format*/}
                        {/* <TableHeaderColumn dataAlign="center" dataFormat={ this.editButtonFormatter } >Edit</TableHeaderColumn> */}
                        <TableHeaderColumn dataAlign="center" dataFormat={ this.newEditButtonFormatter } >Edit</TableHeaderColumn>
                        <TableHeaderColumn dataAlign="center" dataFormat={ this.removeButtonFormatter } >Delete</TableHeaderColumn>
                        
                    </BootstrapTable>
                </CardBody>
            </Card>
        </Col>
        )   
    }
}
export default GeoFenceListCard;


function setPolygonPoints(dataStr) {
    let reVal = '';
    let minlat = null ;
    let maxlat = null ;
    let minlng = null ;
    let maxlng = null ;
    let centerlat = null ;
    let centerlng = null ;
    try{
        let polyObj = JSON.parse(dataStr);
        if (polyObj && polyObj.length > 0) {
            polyObj[0].forEach((element, index)=>{
                // reVal = reVal + '|' + element.lat.toString() + ',' + element.lng.toString();
                reVal = reVal + `|${element.lat},${element.lng}`;
                if (minlat === null || element.lat < minlat){
                    minlat = element.lat
                } 
                if (maxlat === null || element.lat > maxlat){
                    maxlat = element.lat
                } 
                if (minlng === null || element.lng < minlng){
                    minlng = element.lng
                } 
                if (maxlng === null || element.lng > maxlng){
                    maxlng = element.lng
                } 
            });
            if (polyObj[0][0]){
                reVal = reVal + `|${polyObj[0][0].lat},${polyObj[0][0].lng}`;
            }
        }

        if (minlat && maxlat && minlng && maxlng) {
            centerlat = minlat + ((maxlat - minlat) / 2);
            centerlng = minlng + ((maxlng - minlng) / 2);
        }
        
        return {data: reVal, center: {lat: centerlat, lng: centerlng}}        
    }
    catch(e){
        return null;
    }
}

function setRectanglePoints(dataStr) {
    let reVal = '';
    let centerlat = null ;
    let centerlng = null ;
    try{
        let polyObj = JSON.parse(dataStr);
        if (polyObj) {
           reVal = `|${polyObj.north},${polyObj.west}|${polyObj.north},${polyObj.east}|${polyObj.south},${polyObj.east}|${polyObj.south},${polyObj.west}|${polyObj.north},${polyObj.west}`;
           centerlat = polyObj.south + ((polyObj.north - polyObj.south) / 2);
           centerlng = polyObj.west + ((polyObj.east - polyObj.west) / 2);
        }
    
        return {data: reVal,center: {lat: centerlat, lng: centerlng}}        
    }
    catch(e){
        return null;
    }
}

function setCirclePoints(dataStr , p = 18){
    let reVal = '';
    let centerlat = null ;
    let centerlng = null ;
    try{
        const polyObj = JSON.parse(dataStr);
        if (polyObj) {
            const lat = polyObj.center.lat;
            const lng = polyObj.center.lng;
            const rad = polyObj.radius;

            centerlat = lat;
            centerlng = lng;

            const r    = 6371;
            const pi   = Math.PI;
           
            const _lat  = (lat * pi) / 180;
            const _lng  = (lng * pi) / 180;
            const d    = (rad/1000) / r;
            
            var i = 0;
            
            for(i = 0; i <= 360; i+=p) {
                var brng = i * pi / 180;
            
                var pLat = Math.asin(Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(brng));
                var pLng = ((_lng + Math.atan2(Math.sin(brng) * Math.sin(d) * Math.cos(_lat), Math.cos(d) - Math.sin(_lat) * Math.sin(pLat))) * 180) / pi;
                pLat = (pLat * 180) / pi;
            
                reVal += "|" + pLat + "," + pLng;
            }
            
        }
        return {data: reVal,center: {lat: centerlat, lng: centerlng}}        
    }
    catch(e){
        return null;
    }

}

const mapThumbnail = (fence) => {
    let lat = 43.6907917;
    let lng = -79.4761116;
    let pointsObj = null;
    let pointsStr = '';
     
     
    if (fence){
        switch(fence.shape){
            case 0:
                pointsObj = setCirclePoints(fence.geodata);
                if (pointsObj) {
                    pointsStr = pointsObj.data;
                    lat = pointsObj.center.lat;
                    lng = pointsObj.center.lng;
                }
                break;
            case 1:
                pointsObj = setRectanglePoints(fence.geodata);
                if (pointsObj) {
                    pointsStr = pointsObj.data;
                    lat = pointsObj.center.lat;
                    lng = pointsObj.center.lng;
                }
                break;
            case 2:
                pointsObj = setPolygonPoints(fence.geodata);
                if (pointsObj) {
                    pointsStr = pointsObj.data;
                    lat = pointsObj.center.lat;
                    lng = pointsObj.center.lng;
                }
                break;
            default:
        }
    }
    const uri = 'https://maps.googleapis.com/maps/api/staticmap?';
    let staticMapSrc =uri + 'center=' + lat + ',' + lng;
    staticMapSrc += '&size=120x120';
    staticMapSrc += '&path=color:0xff0000ff:weight:1';
    if (pointsStr && pointsStr !== '') {
        staticMapSrc += pointsStr;
    }
    staticMapSrc += `&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;

    return(
        <Fragment>
            <img src={staticMapSrc} style={{width: 120, height: 120}}/>
        </Fragment>
    )
} 