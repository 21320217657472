/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class InventoryReceive extends Component {
    constructor(props){
        super(props);
        this.state = {
            orderId: null,
            selectedFile: null,
            modal: false,
            data: [
                {
                    ordrId:'001',
                    vender:'Air Box Wifi',
                    totAmt:'200',
                    status:'payed',
                    createDt:'2020-10-07',
                    updateDt:'2020-10-07',
                    comment: '',
                }
            ]
        };
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onFileChangeHandler = (e) => {
        // this.props.uploadFile(e.target.files[0]);
        this.setState({
            selectedFile: e.target.files[0],
        });
    }

    onSaveClick = (e) => {
        if (!this.state.orderId) {
            toast.error('Please select a Order');
            return;
        }

        if (!this.state.selectedFile) {
            toast.error('Please select a CSV files');
            return;
        }

        this.props.uploadFile(this.state.selectedFile, this.state.orderId);
        this.toggle();
          
    }

    onUploadClick = (e, orderObj) => {
        this.setState({
            orderId: orderObj.ordrId
        });
        this.toggle();
    }

    onCancelClick = (e) => {
        this.toggle();
    }

    renderFileloaderModal=()=>{
        return (
            <div>
                {/* <Button color="custom" onClick={this.toggle}>
                    Upload Received Devices
                </Button> */}
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader  
                        toggle={this.toggle}
                        //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                    >
                        Upload Received Devices
                    </ModalHeader>
                    <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                        <Row>
                            {/* <Col></Col> */}
                            <Label style={{ display: 'inline'}}> <h4>Order :</h4> </Label>
                            {/* <Col></Col> */}
                            <Label style={{ display: 'inline', marginLeft:'10px'}}> <h4>{this.state.orderId}</h4> </Label>
                        </Row>
                        <Row style={{marginTop: 10}}>
                            <Col md={10}>
                                <div className="form-group files color">
                                    {/* <label htmlFor="firstName">
                                        <input type="file" className="form-control" name="file" onChange={this.onFileChangeHandler} />
                                    </label> */}
                                    <input type="file" name="file" class="custom-file-input" id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01" onChange={this.onFileChangeHandler}/>
                                    <label class="custom-file-label" for="inputGroupFile01">Choose a CSV file</label>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button outline color="custom" onClick={this.onSaveClick}>Save</Button>
                        <Button outline color="secondary" onClick={this.onCancelClick}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    orderIdFormater = (cell, row) => {
        return (
            // <a href={'#'} onClick={(e) => { e.preventDefault(); this.props.handleRowEditClick(row, true);}}>{cell}</a>
            <a href={'#'} onClick={(e) => { e.preventDefault(); this.handleInvoiceClick(cell);}}>{cell}</a>
        );
    }

    actionFiledFormater = (cell, row) => {
        return (
            <div>
                <Button variant="primary" color="custom" type="button" onClick={(e)=>{ this.onUploadClick(e, row) }} size='sm'>Load Received</Button>
            </div>
        );
    }

    renderReceivedList = () => {
        return(
            <BootstrapTable
                 data={ this.state.data }  
                 // tableHeaderClass = 'header_white' 
                 pagination = {true} 
                 tableHeaderClass = 'header_white' 
                   
                 search
                 bordered={ false } 
                 // insertRow 
                 ref='table' 
            >
                {/* <TableHeaderColumn dataField='invId' isKey={ true } dataFormat={this.invoiceIdFormater} dataAlign="center" width='8%' >Invoice Id</TableHeaderColumn> */}
                <TableHeaderColumn dataField='ordrId' isKey={ true } dataFormat={this.orderIdFormater} dataAlign="center" dataSort width='8%' >Order</TableHeaderColumn> 
                <TableHeaderColumn dataField='vender' dataAlign="center" dataSort width='10%'>Vender</TableHeaderColumn>
                {/* <TableHeaderColumn dataField='provTax' dataAlign="center" dataSort width='8%' >Province Tax</TableHeaderColumn>
                <TableHeaderColumn dataField='fedTax' dataAlign="center" dataSort width='8%' >Federal Tax</TableHeaderColumn>
                <TableHeaderColumn dataField='discountAmt' dataAlign="center" dataSort width='8%' >Discount</TableHeaderColumn> */}
                <TableHeaderColumn dataField='totAmt' dataAlign="center" dataSort width='10%' >Total Amount</TableHeaderColumn>
                <TableHeaderColumn dataField='status' dataAlign="center" dataSort width='10%' >Status</TableHeaderColumn>
                <TableHeaderColumn dataField='createDt' dataFormat={this.timeFormat} dataAlign="center" dataSort width='12%'>Create Date</TableHeaderColumn>
                <TableHeaderColumn dataField='updateDt' dataFormat={this.timeFormat} dataAlign="center" dataSort width='12%'>Update Date</TableHeaderColumn>
                <TableHeaderColumn dataField='comment' dataAlign="center" width='15%' >Comment</TableHeaderColumn>
                <TableHeaderColumn dataField='action' dataFormat={this.actionFiledFormater} dataAlign="center"></TableHeaderColumn>
            </BootstrapTable>
        );
    }

    render(){
        return (
            <Fragment>
                {/* <Row>
                    {this.renderFileloaderModal()}
                </Row> */}
                <Row>
                    <Col md={12}>
                        {this.renderReceivedList()}
                        {this.renderFileloaderModal()}
                    </Col>
                </Row>                
            </Fragment>
        );
    }
}
export default InventoryReceive;
