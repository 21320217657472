/* eslint-disable */
import React, { Component } from 'react'
import { Card, CardBody, Table, Tooltip } from 'reactstrap';
import { EditIcon, DeleteIcon, EmailIcon, PlayArrowIcon, InboxMultipleIcon, FileDocumentBoxMultipleIcon, TrashCanOutlineIcon } from 'mdi-react'
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

export default class ReportsList extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const height = this.divElement.clientHeight
    this.props.setListHeight(height)
  }
  render() {
    return (
      <div
        ref={(divElement) => {
          this.divElement = divElement
        }}
      >
        <Card style={{ paddingBottom: 0, border: '5px solid white', borderWidth: '30px 0px 30px 0px' }}>
          <div className='card-pginside'
            style={{
              padding: '0px 30px 0px 30px',
              maxHeight: '75vh',
              overflow: 'auto',
            }}>
            {this.props.button}
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Format</th>
                  <th>Company</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.props.reports.map((t, i) => <ReportItem
                  key={i}
                  i={i}
                  {...t}
                  handleReportDeleteOnClick={this.props.handleReportDeleteOnClick}
                  handleReportEditOnClick={this.props.handleReportEditOnClick}
                />)}
              </tbody>
            </Table>
          </div>
        </Card>
      </div>
    )
  }
}

// function ReportItem({ reportname, format, status, i }) {
function ReportItem({ i, ...report }) {
  return (
    <tr>
      <td>{report.reportname}</td>
      <td>{report.format}</td>
      <td>{report.companyname}</td>
      <td>{report.status}</td>
      <ReportActions
        i={i}
        id={report.reportid}
        handleReportDeleteOnClick={report.handleReportDeleteOnClick}
        handleReportEditOnClick={report.handleReportEditOnClick}
      />
    </tr>
  )
}

const fillerFunction = () => console.log("Clicked")


class ReportActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tooltipOpen: false
    }
  }

  toggle = () => this.setState({ tooltipOpen: !this.state.tooltipOpen })
  render() {
    // let targetIdInbox = "Inbox_" + this.props.i;
    return (
      <td className='actions'>
        <a onClick={fillerFunction}
        // id={targetIdInbox}
        ><InboxMultipleIcon /></a>
        {/* <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={targetIdInbox} toggle={this.toggle}
          style={{ fontSize: '10px', backgroundColor: '#777' }}
        >
          Show Logs
        </Tooltip> */}
        <a onClick={fillerFunction}><FileDocumentBoxMultipleIcon /></a>
        <a onClick={fillerFunction}><EmailIcon /></a>
        <a onClick={(e) => { this.props.handleReportEditOnClick(e, this.props.id) }}><EditIcon style={{ cursor: 'pointer' }} color='#0066cc' /></a>
        {/* <a onClick={(e) => {this.props.handleReportDeleteOnClick(e, this.props.id)}}><DeleteIcon color = '#830300' /></a> */}
        {/* <a onClick={(e) => {this.props.handleReportDeleteOnClick(e, this.props.id)}}><TrashCanOutlineIcon color = '#830300' /></a> */}
        <ConfirmDeleteDialog
          label={''}
          onConfirm={(e) => { this.props.handleReportDeleteOnClick(e, this.props.id); }}
          msg={'Do you want to delete the select Report ? '}
        />
      </td>
    )
  }
}

