/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getCurrentUser} from '../../../factories/auth';

class CompanyAddEditGeneralLedgerCodeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            generalLedgerCodeItemSelected: null,
        }
        this.onInputChange = this.onInputChange.bind(this)
        this.validateForm = this.validateForm.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.onClose = this.onClose.bind(this)

        this.userObj = getCurrentUser();
    }

    componentWillReceiveProps(nextProps, nextContent) {
        this.setState({
            showModal: nextProps.showModal
        })
        if (nextProps.generalLedgerCodeItemSelected != null) {
            this.setState({
                isEditMode: nextProps.isEditMode,
                generalLedgerCodeItemSelected: nextProps.generalLedgerCodeItemSelected,
                generalLedgerCode: nextProps.generalLedgerCodeItemSelected.generalLedgerCode,
                description: nextProps.generalLedgerCodeItemSelected.description,
            })
        }
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    validateForm = () => {
        const { isEditMode, generalLedgerCode, description } = this.state

        if (!isEditMode) {
            if ((generalLedgerCode && generalLedgerCode.trim() && generalLedgerCode.length <= 50)
                && (description && description.trim() && description.length <= 50)) {
                return true
            }
        } else {
            if (description && description.trim()) {
                return true
            }
        }

        return false
    }

    onSubmit = () => {
        const { onSave, onClose } = this.props
        let { generalLedgerCodeItemSelected,
            generalLedgerCode, description } = this.state

        generalLedgerCodeItemSelected.generalLedgerCode = generalLedgerCode.trim()
        generalLedgerCodeItemSelected.description = description.trim()
        onSave(generalLedgerCodeItemSelected)
        onClose()
    }

    onClose = () => {
        this.props.onClose()
    }

    render() {

        let { showModal, isEditMode, generalLedgerCode, description } = this.state

        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={showModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader
                    toggle={this.onClose}
                >
                    {
                        isEditMode ? 'Edit a GL Code' : 'Add a GL Code'
                    }
                </ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <br />
                        <Row>
                            <Col md={2}>
                                <span>Code <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='generalLedgerCode'
                                    id='generalLedgerCode'
                                    placeholder='General Ledger Code'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={generalLedgerCode}
                                    invalid={!generalLedgerCode || generalLedgerCode.trim() === "" || generalLedgerCode.length > 50}
                                />
                                <div class="invalid-feedback">
                                    General Ledger Code can have a maximum of 50 characters
                                </div>
                            </Col>
                        </Row>
                        <br />

                        <Row>
                            <Col md={2}>
                                <span>Description <span style={{ color: 'red' }}>*</span></span>
                            </Col>

                            <Col md={8}>
                                <Input
                                    name='description'
                                    id='description'
                                    placeholder='Description'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    value={description}
                                    invalid={!description || description.trim() === "" || description.length > 50}
                                />
                                <div class="invalid-feedback">
                                    Description can have a maximum of 50 characters
                                </div>
                            </Col>
                        </Row>
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>
                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}>
                            Submit
                        </Button>}
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" onClick={this.onSubmit} disabled={!this.validateForm()}>
                            Submit
                        </Button>}
                    <Button outline color='secondary' type="reset" onClick={this.onClose} >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default CompanyAddEditGeneralLedgerCodeModal