/*eslint-disable*/
import React from "react";
import { render } from "react-dom";
import Card from "react-credit-cards";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData
} from "./paymentUtils";

// import "react-credit-cards/es/styles-compiled.css";
// import './regularcard.scss';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      issuer: "",
      focused: "",
      formData: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
    }

    this.setState({ [target.name]: target.value });
  };

  // handleSubmit = e => {
  //   e.preventDefault();
  //   const { issuer } = this.state;
  //   const formData = [...e.target.elements]
  //     .filter(d => d.name)
  //     .reduce((acc, d) => {
  //       acc[d.name] = d.value;
  //       return acc;
  //     }, {});

  //   this.setState({ formData });
  //   this.form.reset();
  // };

  handleSubmit(evt) {
    evt.preventDefault();
    this.props.addCard(this.state);
    this.props.toggle(false);
    this.setState({
      number: "",
      name: "",
      expiry: "",
      cvc: "",
      issuer: "",
      focused: "",
      formData: null
    });
  }

  render() {
    const { name, number, expiry, cvc, focused, issuer, formData } = this.state;
    return (
      <div key="Payment">
        <div className="App-payment">
          <Card
            number={number}
            name={name}
            expiry={expiry}
            cvc={cvc}
            focused={focused}
            callback={this.handleCallback}
          />
          <br />
          <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
            <div className="form-group">
              <input
                type="tel"
                name="number"
                className="form-control"
                placeholder="Card Number"
                pattern="[\d| ]{16,22}"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Name"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
              />
            </div>
            <div className="row">
              <div className="col-6">
                <input
                  type="tel"
                  name="expiry"
                  className="form-control"
                  placeholder="Valid Thru"
                  pattern="\d\d/\d\d"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
              <div className="col-6">
                <input
                  type="tel"
                  name="cvc"
                  className="form-control"
                  placeholder="CVC"
                  pattern="\d{3,4}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                />
              </div>
            </div>
            <input type="hidden" name="issuer" value={issuer} />
            <br />
            <div className="form-actions">
              <button className="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default PaymentForm;
