/* eslint-disable */
import React from "react";
import {Redirect} from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { compose, bindActionCreators } from 'redux';
import {connect } from 'react-redux';
// import {bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import { makeStyles } from '@material-ui/core/styles';
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
// import {Button} from 'reactstrap';
import { Container, Col, Row, Card, CardBody,  CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip } from 'reactstrap';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';

import StepTemplate from './StepTemplate';
import StepAction from './StepAction';
import StepCondition from './StepCondition';
import StepNotification from './StepNotification';

import {stepForward, stepBackward, stepReset} from '../../../redux/actions/jobAction';

const styles = (theme => ({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));

class ActionEditStepper extends React.Component {
    constructor(props){
        super(props);
        this.state={
            activeStep: 0,
        }
    }

    objsteps = {
        "Event action": StepAction,
        "Logic condition": StepCondition,
        "Notification": StepNotification
      };

    componentWillUnmount(){
         
    }

      getSteps() {
        return ['Event action', 'Logic condition', 'Notification'];
      }
      
    //   getStepContent(step) {
    //     switch (step) {
    //       case 0:
    //         return `For each ad campaign that you create, you can control how much
    //                 you're willing to spend on clicks and conversions, which networks
    //                 and geographical locations you want your ads to show on, and more. test`;
    //       case 1:
    //         return 'An ad group contains one or more ads which target a shared set of keywords.';
    //       case 2:
    //         return `Try out different ad text to see what brings in the most customers,
    //                 and learn how to enhance your ads using features like ad extensions.
    //                 If you run into any problems with your ads, find out how to tell if
    //                 they're running and how to resolve approval issues.`;
    //       default:
    //         return 'Unknown step';
    //     }
    //   }

      handleNext = () => {
        // setActiveStep(prevActiveStep => prevActiveStep + 1);
        this.props.stepForward();
        // this.setState((preState) => ({
        //     activeStep: preState.activeStep + 1,
        // }));
      };
    
      handleBack = () => {
        // setActiveStep(prevActiveStep => prevActiveStep - 1);
        this.props.stepBackward();
        // this.setState((preState) => ({
        //     activeStep: preState.activeStep - 1,
        // }));
      };

      handleCancel = () => {
        this.props.history.push(`/pages/actionNotification`);
      }
    
      handleReset = () => {
        // setActiveStep(0);
        this.props.stepReset();
        // this.setState({
        //     activeStep: 0,
        // });
      };

    render(){
        const steps = this.getSteps();
        // const classes = this.useStyles();
        const { classes } = this.props;
        const { activeStep } = this.props; //this.state;
         
         
        return(
            <div 
            className={classes.root}
            >
            <Stepper activeStep={this.props.activeStep} orientation="vertical">
                {/* {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel><h3>{label}</h3></StepLabel>
                    <StepContent>
                        <Card>
                            <CardBody>
                    <Typography>{this.getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}>
                        <div style= {{textAlign: 'center'}}>
                        <Button
                            disabled={activeStep === 0}
                            onClick={this.handleBack}
                            className={classes.button}
                        >
                            Back
                            <ChevronUpIcon color = {activeStep === 0 ?'#d3d3d3': '#000000'}/>
                        </Button>
                        <Button
                            variant="contained"
                            color="custom"
                            onClick={this.handleNext}
                            className={classes.button}
                        >
                            {activeStep === steps.length - 1 ? 'Complete' : 'Save'}
                            {activeStep !== steps.length - 1 && <ChevronDownIcon color="#fff" />}
                        </Button>
                        </div>
                    </div>
                    </CardBody>
                    </Card>
                    <StepTemplate 
                        classes = {classes}
                        canGoBack = {activeStep !== 0}
                        canGoForward = {activeStep !== steps.length - 1}
                        onBack = {this.handleBack}
                        onNext = {this.handleNext}
                        text = {this.getStepContent(index)}
                    />
                    </StepContent>
                </Step>
                ))} */}

                {Object.entries(this.objsteps).map(([label, CustomStep], index) => (
                    <Step key={label}>
                        <StepLabel><h3>{label}</h3></StepLabel>
                        <StepContent>
                            <CustomStep
                            classes={classes}
                            canGoBack={activeStep !== 0}
                            canGoForward={activeStep !== steps.length - 1}
                            onBack={this.handleBack}
                            onNext={this.handleNext}
                            onCancel = {this.handleCancel}
                            actionId={this.props.actionId}
                            actionPassId = {this.props.actionPassId}
                            // text = {this.getStepContent(index)}
                            />
                        </StepContent>
                    </Step>
                ))}

            </Stepper>
    
            {/* {this.isFinished() && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={this.handleReset} className={classes.button}>
                  Reset
                </Button>
              </Paper>
            )} */}
          </div>
        );
    }
}

// export default withStyles(styles) (ActionEditStepper);

const mapStateToProps = (state) => {
    return state.job;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({stepForward, stepBackward, stepReset}, dispatch);
};

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withRouter
);

export default enhance(ActionEditStepper);