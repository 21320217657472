/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import {
    getGeneralLedgerCodesByCompanyCode,
    getGeneralLedgerCodesReasonsByCompanyCode,
    addGeneralLedgerCodeReasonByCompanyCode,
    modifyGeneralLedgerCodeReasonByCompanyCode
} from '../../../redux/actions/userAction';
import { Card, CardBody, Col, Button, Collapse } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import { sortCaret } from '../../../shared/components/Table/tableElements';
import { EditTextButton, DeleteTextButton } from '../../../shared/components/Button/IconButton'
import uuid from "uuid/v4";
import { cloneDeep } from 'lodash'
import CompanyAddGeneralLedgerCodeReasonModal from './CompanyAddGeneralLedgerCodeReasonModal'

class CompanyAddGeneralLedgerCodeReason extends Component {
    constructor(props) {
        super(props);
        this.state = {
            init: false,
            userObj: getCurrentUser(),
            isGeneralLedgerCodeReasonEditable: false,
            showGeneralLedgerCodeReasonAddEditModal: false,
            generalLedgerCodeReasonItemTemplate: null
        }
        this.onUpdateCompanyAddGeneralLedgerCodeReason
            = this.onUpdateCompanyAddGeneralLedgerCodeReason.bind(this)
        this.onCloseCompanyAddGeneralLedgerCodeReasonModal
            = this.onCloseCompanyAddGeneralLedgerCodeReasonModal.bind(this)
        this.onOpenCompanyAddGeneralLedgerCodeReasonModal
            = this.onOpenCompanyAddGeneralLedgerCodeReasonModal.bind(this)
        this.addNewGeneralLedgerCodeReasonButton = this.addNewGeneralLedgerCodeReasonButton.bind(this)
        this.cellGeneralLedgerCodeReasonEditButton = this.cellGeneralLedgerCodeReasonEditButton.bind(this)
        this.cellGeneralLedgerCodeReasonDeleteButton = this.cellGeneralLedgerCodeReasonDeleteButton.bind(this)
    }

    async componentDidMount() {
        const { getGeneralLedgerCodesByCompanyCode, getGeneralLedgerCodesReasonsByCompanyCode } = this.props
        const { userObj } = this.state
        const { companyCode } = userObj

        await getGeneralLedgerCodesByCompanyCode(companyCode)
        await getGeneralLedgerCodesReasonsByCompanyCode(companyCode)
    }

    componentDidUpdate(prevProps) {
        let { init } = this.state
        let { generalLedgerCodeUsageReasons, generalLedgerCodes } = this.props

        if (((generalLedgerCodeUsageReasons !== prevProps.generalLedgerCodeUsageReasons)
            || (!init)) && generalLedgerCodes.length > 0) {
            let generalLedgerCodeUsageReasonsCopy = cloneDeep(generalLedgerCodeUsageReasons)

            for (let glr = 0; glr < generalLedgerCodeUsageReasonsCopy.length; glr++) {
                let element = generalLedgerCodeUsageReasonsCopy[glr];
                element.operationsEdit = true

                const generalLedgerCodeResult = generalLedgerCodes.filter(
                    (generalLedgerCode) => generalLedgerCode.id === element.codeId)

                element.generalLedgerCode = generalLedgerCodeResult[0].generalLedgerCode
            }

            if (!init) {
                this.setState({
                    init: true
                })
            }

            this.setState({
                init: true,
                generalLedgerCodeReasonsListWithIndexOperation: generalLedgerCodeUsageReasonsCopy
            })
        }
    }

    onUpdateCompanyAddGeneralLedgerCodeReason = async (generalLedgerCodeReasonItemTemplate) => {

        const { userObj, isGeneralLedgerCodeReasonEditable } = this.state
        const { getGeneralLedgerCodesByCompanyCode,
            addGeneralLedgerCodeReasonByCompanyCode,
            modifyGeneralLedgerCodeReasonByCompanyCode,
            getGeneralLedgerCodesReasonsByCompanyCode } = this.props
        const { companyCode, userName } = userObj

        let theReasonId = 0

        if (isGeneralLedgerCodeReasonEditable) {
            theReasonId = generalLedgerCodeReasonItemTemplate.id
        }

        const generalLedgerCodeReasonPayload = {
            reasonId: theReasonId,
            reasonCode: generalLedgerCodeReasonItemTemplate.reasonCode,
            reason: generalLedgerCodeReasonItemTemplate.reason,
            codeId: parseInt(generalLedgerCodeReasonItemTemplate.codeId),
            companyCode: companyCode,
            userName: userName
        }

        await getGeneralLedgerCodesByCompanyCode(companyCode)

        if (isGeneralLedgerCodeReasonEditable) {
            await modifyGeneralLedgerCodeReasonByCompanyCode(generalLedgerCodeReasonPayload)
        } else {
            await addGeneralLedgerCodeReasonByCompanyCode(generalLedgerCodeReasonPayload)
        }
        await getGeneralLedgerCodesReasonsByCompanyCode(companyCode)

    }

    onCloseCompanyAddGeneralLedgerCodeReasonModal = (generalLedgerCodeReasonItemTemplate) => {

        this.setState({
            showGeneralLedgerCodeReasonAddEditModal: false
        })

    }

    onOpenCompanyAddGeneralLedgerCodeReasonModal = (generalLedgerCodeReasonItemTemplate) => {

        this.setState({
            showGeneralLedgerCodeReasonAddEditModal: true,
            generalLedgerCodeReasonItemSelected: generalLedgerCodeReasonItemTemplate
        })

    }

    addNewGeneralLedgerCodeReasonButton = () => {

        /* create a sample row item */
        const generalLedgerCodeReasonItemTemplate = {
            listItemIndex: 0,
            operationsIndex: uuid(),
            operationsEdit: true,
            operationsDelete: true,
            reasonCode: "",
            reason: "",
            generalLedgerCode: "",
            codeId: -1,
            reasonId: 0
        }

        if (hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"])) {
            return (
                <InsertButton
                    btnContextual='btn-success'
                    btnGlyphicon='glyphicon-edit'
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            isGeneralLedgerCodeReasonEditable: false
                        }, () => {
                            this.onOpenCompanyAddGeneralLedgerCodeReasonModal(generalLedgerCodeReasonItemTemplate)
                        });
                    }}
                >
                    <PlusIcon /> Add a General Ledger Code Reason
                </InsertButton>
            );
        }
    }

    cellGeneralLedgerCodeReasonEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton
                    label={''}
                    onClick={(e) => {
                        e.preventDefault();

                        this.setState({
                            isGeneralLedgerCodeReasonEditable: true
                        }, () => {
                            this.onOpenCompanyAddGeneralLedgerCodeReasonModal(cloneDeep(row))
                        });

                    }} />
            </div>
        )
    }

    cellGeneralLedgerCodeReasonDeleteButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <DeleteTextButton
                    label={''}
                    onClick={(e) => {
                        e.preventDefault();
                    }} />
            </div>
        )
    }

    render() {

        const options = {
            paginationPosition: 'bottom',
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
        };

        const { generalLedgerCodes } = this.props

        const { showGeneralLedgerCodeReasonAddEditModal,
            isGeneralLedgerCodeReasonEditable,
            generalLedgerCodeReasonItemSelected,
            generalLedgerCodeReasonsListWithIndexOperation } = this.state

        return (<>
            <CompanyAddGeneralLedgerCodeReasonModal
                showModal={showGeneralLedgerCodeReasonAddEditModal}
                isEditMode={isGeneralLedgerCodeReasonEditable}
                generalLedgerCodes={generalLedgerCodes}
                generalLedgerCodeReasonItemSelected={generalLedgerCodeReasonItemSelected}
                onClose={this.onCloseCompanyAddGeneralLedgerCodeReasonModal}
                onSave={this.onUpdateCompanyAddGeneralLedgerCodeReason}
            />
            <Card>
                <CardBody className="mainCardBody">
                    {/* <div style={{ marginBottom: '20px' }}> */}
                    {/* <h3>General Ledger Code Reasons</h3> */}
                    {/* <div style={{ textAlign: 'left', marginTop: '20px' }}> */}
                    {this.addNewGeneralLedgerCodeReasonButton()}
                    {/* </div> */}
                    {/* </div> */}
                    <BootstrapTable
                        data={generalLedgerCodeReasonsListWithIndexOperation}
                        options={options}
                        tableHeaderClass='header_white'
                        bordered={false}
                        height='100%'
                        pagination={true}
                        exportCSV
                        csvFileName='Company_GL_Code_Reasons.csv'
                    >
                        <TableHeaderColumn dataField='id' isKey={true} hidden={true}>
                            Index
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='createdAtFormatted' dataSort
                            dataAlign="center" caretRender={sortCaret}
                            hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                            csvHeader='Date'
                        >
                            Date
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='generalLedgerCode' dataSort
                            dataAlign="center" caretRender={sortCaret}
                            hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                            csvHeader='GL Code'
                        >
                            GL Code
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='reasonCode' dataSort
                            dataAlign="center" caretRender={sortCaret}
                            hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                            csvHeader='Reason Code'
                        >
                            Reason Code
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField='reason' dataSort
                            dataAlign="center" caretRender={sortCaret}
                            hidden={false} filter={{ type: 'TextFilter', placeholder: ' ' }}
                            csvHeader='Reason'
                        >
                            Reason
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField='operationsEdit'
                            dataAlign="center"
                            dataFormat={this.cellGeneralLedgerCodeReasonEditButton}
                            width="10%"
                            editable={false}
                            export={false}
                            hidden={(!hasRole(this.props.userObj, ["SUPERADMIN", "ADMIN"])) ? true : false}

                        >
                            Edit
                        </TableHeaderColumn>
                        {/*
                        <TableHeaderColumn
                            dataField='operationsDelete'
                            dataAlign="center"
                            dataFormat={this.cellGeneralLedgerCodeReasonDeleteButton}
                            width="10%"
                            editable={false}
                        >
                            Delete
                        </TableHeaderColumn>
                        */}
                    </BootstrapTable>
                </CardBody>
            </Card>
        </>)
    }
}

const mapStateToProps = (state) => {

    let { generalLedgerCodes, generalLedgerCodeUsageReasons } = state.user;

    let props = { generalLedgerCodes, generalLedgerCodeUsageReasons }

    return props
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getGeneralLedgerCodesByCompanyCode,
        getGeneralLedgerCodesReasonsByCompanyCode,
        addGeneralLedgerCodeReasonByCompanyCode,
        modifyGeneralLedgerCodeReasonByCompanyCode
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAddGeneralLedgerCodeReason);