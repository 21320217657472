/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';

class VideoPlayer extends Component {
    constructor(props){
        super(props);
        this.myPlayer = React.createRef();
        this.state = {
            videoSrc: ""
        }
    }

    componentDidMount(){
        // this.ws = new WebSocket('ws://192.168.2.89:8081/webSocketService');
        // if (this.props.url) {
            this.ws = new WebSocket(this.props.url);
            this.ws.onopen = (e) => {
                console.log('web socket connection established!')
            }
            this.ws.onmessage = (e) => {
                var data = JSON.parse(e.data);
                if (data.code === 0) {
                    console.log(e.data)
                } else if (data.code === 201) {
                    // $("#show_video").attr("src", "data:image/*;base64," + data.data)
                    this.setState({
                        videoSrc:  "data:image/*;base64," + data.data
                    });
                }
            }
        // }

    }

    componentWillUnmount(){
        try{
            if (this.ws && this.ws.readyState != WebSocket.CLOSED && this.ws.readyState != WebSocket.CLOSING) {
                this.ws.close();
                 
            }
        }catch(e){
             
        }
       
    }

// --------------------------------------

    renderImgPlayer = () => {
        return (
            <div>
                <img id={this.myPlayer} src={this.state.videoSrc}></img>
                {/* <img id={this.myPlayer} src={this.props.httpurl}></img> */}
            </div>
        )
    }

    render(){
        return (
            <div style={{padding: "20px"}}>
                {this.renderImgPlayer()}
            </div>
        );
    }
}

export default VideoPlayer;