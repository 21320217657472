/* eslint-disable */
import React, { Component } from 'react';
import Card from "react-credit-cards";
import { Button, ButtonGroup, Row, Col, Input, Label,  Card as Card2, CardGroup } from 'reactstrap';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../../shared/components/Table/tableElements';

import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';

class SelectPayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentOption: [],
            selectedOption: "",
            comment:"",
            isPaidOff:false,
            selectedPaymentAccount:null
        }
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps, nextContext) {
    }

    tableActionColFormater = (cell, row) => {
        // const ac = this.props.account.filter(account => account.cardMask);
        const card = row;
        const cardwidth = {
          'width': '10px',
        }
        return (
          <div>
            <CardGroup >
              <Card2 >
                <Card
                  number={card.cardMask}
                  customCardsSupport={true}
                  customCardsStyles={cardwidth}
                />
              </Card2>
            </CardGroup>
          </div>
        )
    }

    selectPaymentOption(option) {
        this.setState({
            selectedOption: option,
            comment:"",
            isPaidOff: false,
            selectedPaymentAccount: null
        });
        //payByOption,comment,isPaidOff,accountId
        this.props.handlePaymentOptionChange(option,"",false,null);
    }

    handleCommentChange = (event) => {
        //event.target.value
        this.setState({
            comment: event.target.value
        });
        this.props.handlePaymentOptionChange(this.state.selectedOption,event.target.value,this.state.isPaidOff,this.state.selectedPaymentAccount);
    }

    onPaidClicked = (e) => {
        this.setState({
            isPaidOff:e.target.checked
        });
        this.props.handlePaymentOptionChange(this.state.selectedOption,this.state.comment,e.target.checked,this.state.selectedPaymentAccount);
    }

    onRadioClick = (value) => {
        this.setState({
            selectedPaymentAccount:value
        });
        this.props.handlePaymentOptionChange(this.state.selectedOption,"",false,value);
      }

    radioButtonFormater = (cell, row) => {
        //check if it is selected
        let radioChecked = false;
        if (this.props.selectedPaymentAccount != null) {
            if (this.props.selectedPaymentAccount.id == row.id) {
            radioChecked = true;
            }
        }
        return (
            <Input type="radio" checked={radioChecked} name="radio1" onChange={() => { this.onRadioClick(row) }} />
        )
    }

    renderPaymentOption() {
        if (this.state.selectedOption == "CC") {
            const aList = this.props.accountList==null?[]:this.props.accountList.filter(function (acct) {
                return acct.paymentMethod != "ACH" && acct.paymentMethod != "CHK";
              });
            const selectRowProp = {
                mode: 'radio1'
            };
            return (
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", padding: "10px" }}>
                    <BootstrapTable
                        data={aList}
                        tableHeaderClass='header_white'
                        pagination={false}
                         
                        //selectRow={selectRowProp}
                         
                        bordered={false}
                    >
                        <TableHeaderColumn dataField='id' isKey={true} dataAlign="center" hidden >ID</TableHeaderColumn>
                        <TableHeaderColumn dataField='' dataAlign="center" width='5%' dataFormat={this.radioButtonFormater}></TableHeaderColumn>
                        <TableHeaderColumn dataField='card' dataAlign="center" dataFormat={this.tableActionColFormater} dataSort>Card Type</TableHeaderColumn>
                        <TableHeaderColumn dataField='cardMask'  dataAlign="center" dataSort >Card Number</TableHeaderColumn>
                        <TableHeaderColumn dataField='expDate' dataAlign="center" dataSort >Expires on</TableHeaderColumn>
                    </BootstrapTable>
                </div >
            );
        }
        else if (this.state.selectedOption == "CHK" || this.state.selectedOption == "ACH") {
            return (
                <div style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", padding: "10px" }}>
                    <Row>
                        <Col md={2}>
                            <label>Mark as paid</label>
                        </Col>
                        <Col md={8}>
                            <Label check>
                                <Input type="checkbox" checked={this.state.isPaidOff} onChange={(e) => { this.onPaidClicked(e) }} />Paid off via {this.state.selectedOption}
                            </Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <label>Comment:</label>
                        </Col>
                        <Col md={8}>
                            <Input type="textarea" rows="3" cols="40" value={this.state.comment} onChange={this.handleCommentChange} />
                        </Col>
                    </Row>
                    {/* <span style={{color: "rgb(153, 153, 153)"}}><b>NOTE:</b> This invoice will be marked as paid off for this payment option.</span> */}
                </div>
            );
        }
        else {
            return (
                <div>
                    no payment option selected
                </div>
            )
        }
    }
    render() {
        return (
            <div>
                <h3>Select Payment</h3>
                <br />
                <ButtonGroup>
                    <Button color="custom" active={this.state.selectedOption=='CC'?true:false} onClick={() => this.selectPaymentOption("CC")}>CC</Button>
                    <Button color="custom" active={this.state.selectedOption=='ACH'?true:false} onClick={() => this.selectPaymentOption("ACH")}>ACH</Button>
                    <Button color="custom" active={this.state.selectedOption=='CHK'?true:false} onClick={() => this.selectPaymentOption("CHK")}>CHK</Button>
                </ButtonGroup>
                {this.renderPaymentOption()}
            </div>
        )
    }
}
export default SelectPayment;