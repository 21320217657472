/* eslint-disable */
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DevicesManageMain from './components/DevicesManageMain';

const DevicesPage = () => {


  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Devices</h3>
        </Col>
      </Row>
      <Row>
        <DevicesManageMain />
      </Row>
    </Container>
  )
};

// export default AssetPage;


export default DevicesPage;
