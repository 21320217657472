/* eslint-disable */
import React, { Component } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Spinner from 'react-bootstrap/Spinner';
import {
    loadDeviceAttr, 
    loadDivceMapData, 
    bindFilterAssetClass, 
    bindFilterFrom, 
    bindFilterTo, 
    bindFilterTheme, 
    bindFilterAttribute, 
    bindFilterDeviceType,
    filterMapDataByAttribute, 
    getAssetsData, 
    getThemes,
    getAssetClassAttribute,
    filterAssetDataByAttribute,
} from '../../../redux/actions/mapActions';
import {getThemeList, getDeviceTypeThemeList} from '../../../redux/actions/attributeThemeAction';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    MAP_DEVICE_ATTR_LOAD_SUCCEED, 
    MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED,
    MAP_DEVICE_DATA,
    MAP_ASSET_DATA ,
} from '../../../constants/actionTypes'; 

class FilterContainer extends Component{
    constructor(props){
        super(props);
         
        this.state = {
            // types: [{name:'test1'},{name:'test2'},{name:'test3'}],
            selectedType:'',
            selectedTheme:'',
            selectedArr:'',
            attrs: this.props.attr,
            currentAssetId: 5,
            currentAssetClassId: 1,
            deviceTypeDisable: false,
            themeDisable: false,
            showEmpgy: true,
        }
    }

    selectedArr = '';

    componentDidMount(){
         
        this.props.getThemes();
        if (this.props.assetClasses && this.props.assetClasses.length > 0){
             
            let selectAssetClass = this.props.assetClasses[0]
            this.props.bindFilterAssetClass(selectAssetClass.assetclassid);
            this.props.bindFilterDeviceType("0");
            this.setState({
                deviceTypeDisable: true,
            });
        }else if (this.props.types && this.props.types.length > 0){
             
            let selectDeviceType = this.props.types[0]
            this.props.bindFilterAssetClass(0);
            this.props.bindFilterDeviceType(selectDeviceType.id);
            this.setState({
                deviceTypeDisable: false,
            });
        }else {
             
            this.props.bindFilterAssetClass(0);
            this.props.bindFilterDeviceType("0");
            this.setState({
                deviceTypeDisable: false,
            });
        }
    }

    componentWillReceiveProps(nextProps){
         
        if (nextProps.currentThemeId){
             
             
        }
        if (nextProps.currentThemeId){
             
             
        }
    }

    componentWillUpdate( nextProps,  nextState){
         
         
    }

    onDeviceTypeChange = (e)=>{
         
         
        this.props.bindFilterDeviceType(e.currentTarget.value);
        this.props.getDeviceTypeThemeList(e.currentTarget.value);
        this.props.loadDeviceAttr({devicetypeid : e.currentTarget.value});
    };

    onAssetClassChange = (e) => {
        let classid = e.target.value;
        this.props.bindFilterAssetClass(classid);
        this.props.getThemeList(classid);
        // this.props.getAssetClassAttribute({assetclassid: classid});
        this.toggleDropDown(classid);
    }

    onThemeChange = (e) =>{
        // alert(e.currentTarget.value);
        let themeid = e.target.value; //e.currentTarget.value
        this.props.bindFilterTheme(themeid);
        this.props.bindFilterAttribute('');
         
         
         
        this.props.getAssetClassAttribute({assetclassid: this.props.currentAssetClassId, themeid: themeid});
        // if (this.state.deviceTypeDisable){
        //     this.props.getAssetClassAttribute({assetclassid: this.props.currentAssetClassId, themeid: themeid});
        // }
        // else{

        // }
        // this.onRefreshClick(null);
        let assetclassid = this.props.currentAssetClassId;
        let devicetypeid = this.props.currentDeviceTypeId;
        let fromtime = this.props.currentFromTime;
        let totime = this.props.currentToTime;
        this.loadData(assetclassid, devicetypeid, themeid, fromtime, totime);
    }

    // onMessageTypeChange = (e) => {
    //     this.props.bindFilterMessageType(e.currentTarget.value);
    // }

    onFromChange = (e) => {
        // alert(e.currentTarget.value);
        this.props.bindFilterFrom(e.currentTarget.value);
    }

    onToChange = (e) => {
        // alert(e.currentTarget.value);
        this.props.bindFilterTo(e.currentTarget.value);
    }

    onAttributeChange = (e) => {
        // alert(e.currentTarget.value);
        this.props.bindFilterAttribute(e.currentTarget.value);
        // this.props.filterMapDataByAttribute(e.currentTarget.value);
        this.props.filterAssetDataByAttribute(e.currentTarget.value);
        // if (this.state.deviceTypeDisable){
        //     this.props.filterAssetDataByAttribute(e.currentTarget.value);
        // }
        // else{
        //     this.props.filterMapDataByAttribute(e.currentTarget.value);
        // }
    }

    // onAssetChange = (e) => {
    //     let id = e.target.value;
    //     this.props.loadDeviceAttr({assetid : id});
    //     this.setState({
    //         currentAssetId: id,
    //     });
    // }

    toggleDropDown = (assetClassVal) => {
         
         
        if (assetClassVal === 0 || assetClassVal === '0') {
            //  
            this.setState({ 
                deviceTypeDisable: false,
                themeDisable: true,
            });
            let deviceTypeId = this.props.types[0].id;
            this.props.bindFilterDeviceType(deviceTypeId);
            this.props.loadDeviceAttr({devicetypeid : deviceTypeId});
            this.props.bindFilterTheme('0');
        }else{
            //  
            this.setState({ 
                deviceTypeDisable: true,
                themeDisable: false,
            });
            this.props.getAssetClassAttribute({assetclassid: assetClassVal});
            this.props.bindFilterDeviceType('0');
        }
    }

    loadData = (assetclassid, devicetypeid, themeid, fromtime, totime) => {
        let params;
        if (this.state.deviceTypeDisable){
            params = {assetclassid: assetclassid};
        }
        else{
            params = {devicetype: devicetypeid};
        } 

        if (themeid && themeid.length > 0 && themeid !== "0" ){
            params = Object.assign(params, {themeid: themeid});
        }
        
        let latest = true;
        if (fromtime || fromtime.length > 0 ){
            let date = new Date(fromtime).toISOString().split('.')[0];
            
            params = Object.assign(params, {starttime: date});
            latest = false;
        }
        if (totime || totime.length > 0 ){
            let date = new Date(totime).toISOString().split('.')[0];
            
            params = Object.assign(params, {endtime: date});
            latest = false;
        }
        if(latest){
            params = Object.assign(params, {latest: 1});
        }else{
            params = Object.assign(params, {latest: 0});
        }
         
        // this.props.loadDivceMapData(params);
        if (this.state.deviceTypeDisable){
            this.props.getAssetsData(params);
        }
        else{
            this.props.loadDivceMapData(params);
        }
        // this.props.getAssetsData(params);
        this.props.bindFilterAttribute('');
    }

    onRefreshClick =(e) => {
        // let params = {devicetype:this.props.currentDeivecType};
        // params = Object.assign(params, {assetid: this.state.currentAssetId});
        // let params = {assetid: this.state.currentAssetId, assetclassid: this.props.currentAssetClassId};
        let params;
        if (this.state.deviceTypeDisable){
            params = {assetclassid: this.props.currentAssetClassId};
        }
        else{
            params = {devicetype: this.props.currentDeviceTypeId};
        } 
        // params = {assetclassid: this.props.currentAssetClassId};
        // if (this.props.currentMessageType || this.props.currentMessageType.length > 0 ){
        //     params = Object.assign(params, {messagetype: this.props.currentMessageType});
        // }
        if (this.props.currentThemeId && this.props.currentThemeId.length > 0 && this.props.currentThemeId !== "0" ){
            params = Object.assign(params, {themeid: this.props.currentThemeId});
        }
        
        let latest = true;
        if (this.props.currentFromTime || this.props.currentFromTime.length > 0 ){
            let date = new Date(this.props.currentFromTime).toISOString().split('.')[0];
            
            params = Object.assign(params, {starttime: date});
            latest = false;
        }
        if (this.props.currentToTime || this.props.currentToTime.length > 0 ){
            let date = new Date(this.props.currentToTime).toISOString().split('.')[0];
            
            params = Object.assign(params, {endtime: date});
            latest = false;
        }
        if(latest){
            params = Object.assign(params, {latest: 1});
        }else{
            params = Object.assign(params, {latest: 0});
        }
         
        // this.props.loadDivceMapData(params);
        if (this.state.deviceTypeDisable){
            this.props.getAssetsData(params);
        }
        else{
            this.props.loadDivceMapData(params);
        }
        // this.props.getAssetsData(params);
        this.props.bindFilterAttribute('');
    }

    render(){
        //  
        //  
         
         
         
        let attrData = [];
        // this.props.assetClassAttr && Object.keys(this.props.assetClassAttr).forEach((typeKey, tindex)=>{
        //     this.props.assetClassAttr[typeKey] && Object.keys(this.props.assetClassAttr[typeKey]).forEach((attributeKey, aindex)=>{
        //         let attribute =  this.props.assetClassAttr[typeKey][attributeKey];
        //         if (attribute){
        //             let attrItem = {id: attribute.attributeid, name: attribute.displayname};
        //             attrData.push(attrItem);
        //         }
        //     });
        // });
        // --------------------------------------------------------------------------------------------------------------------------
        // this.props.assetClassAttr && this.props.assetClassAttr.map((assetclass, aindex) => {
        //     assetclass.atrributes && assetclass.atrributes.map((attr, atindex) => {
        //         let attrItem = {
        //             id: attr.attributeid, 
        //             name: attr.displayname
        //         };
        //         attrData.push(attrItem);
        //     });
        // });
        // ---------------------------------------------------------------------------------------------------------------------------
        if (this.props.mapActionType === MAP_DEVICE_ATTR_LOAD_SUCCEED|| this.props.dataType === MAP_DEVICE_DATA){
             
            attrData = this.props.deviceTypeAttr;
        }else if (this.props.mapActionType === MAP_GET_ASSETCLASS_ATTRIBUTE_SUCCEED || this.props.dataType === MAP_ASSET_DATA){
             
            this.props.assetClassAttr && this.props.assetClassAttr.map((assetclass, aindex) => {
                assetclass.atrributes && assetclass.atrributes.map((attr, atindex) => {
                    let attrItem = {
                        id: attr.attributeid, 
                        name: attr.displayname
                    };
                    attrData.push(attrItem);
                });
            });
        }
         
        let assetClassNoSelect = ! this.props.currentAssetClassId || this.props.currentAssetClassId  == 0 || this.props.currentAssetClassId == '0';
        let deviceThpeNoSelect = ! this.props.currentDeviceTypeId || this.props.currentDeviceTypeId  == 0 || this.props.currentDeviceTypeId == '0';
        let refrashDisabled = assetClassNoSelect && deviceThpeNoSelect;
        // ---------------------------------------------------------------------------------------------------------------------------
        return (
            <div style={{ width: "100%"}}>
            <Form md={12}>
                <Row form>
                    {/* <Col md={3}>
                        <FormGroup row>                            
                            <Col md={12}>
                                <Label for="deviceType" style={{display: 'inline', width: "100%"}} >Device Type</Label>
                                <Input type="select" id="deviceType" name="deviceType" width="100%" value={this.props.currentDeivecType} onChange={this.onDeviceTypeChange} >      
                                    <option value={0}>NONE</option>                              
                                    {this.props.types && this.props.types.map((type,i) => (<option key={type.id} value={type.id}>{type.name}</option>))}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col> */}
                    <Col md={3}>
                        <FormGroup row>
                            {/* <Label for="deviceType" md={3} style={{ width: "100%"}} >Device Type</Label> */}
                            <Col md={12}>
                                <Label for="asset" style={{display: 'inline', width: "100%"}} ><h4><b>Asset Class</b></h4></Label>
                                <Input type="select" id="asset" name="asset" width="100%" value={this.props.currentAssetClassId} onChange={this.onAssetClassChange} >                                    
                                    {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
                                    <option value={0}>NONE</option> 
                                    {this.props.assetClasses && this.props.assetClasses.map((assetclass, i) => (<option key={assetclass.assetclassid} value={assetclass.assetclassid}>{assetclass.assetclassname}</option>))}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup row>                            
                            <Col md={12}>
                                <Label for="deviceType" style={{display: 'inline', width: "100%"}} ><h4><b>Device Type</b></h4></Label>
                                <Input type="select" id="deviceType" name="deviceType" width="100%" value={this.props.currentDeviceTypeId} onChange={this.onDeviceTypeChange} disabled={this.state.deviceTypeDisable} >      
                                    {/* {this.state.deviceTypeDisable && <option value={'0'}> </option>}                               */}
                                    {this.state.showEmpgy && <option value={'0'}> No Selection </option>}  
                                    {this.props.types && this.props.types.map((type,i) => (<option key={type.id} value={type.id}>{type.name}</option>))}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                    {/* <Col md={3}>
                        <FormGroup row >
                            <Label for="attribute" md={2}>Attribute</Label>
                            <Col md={12}>
                                <Label for="messageType"  style={{ display: 'inline'}} >Message Type</Label>
                                <Input type="select" id="messageType" name="messageType" width="100%" value={this.props.currentMessageType} onChange={this.onMessageTypeChange}>
                                    <option >Message Type I</option>
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col> */}
                    {/* <Col md={2}>
                        <FormGroup row >
                            <Label for="theme" md={2}>Theme</Label>
                            <Col md={12}>
                                <Label for="theme" style={{display: 'inline', width: "100%"}}>Theme</Label>
                                <Input type="select" id="theme" name="theme" width="100%" value={this.props.currentTheme} onChange={this.onThemeChange}>
                                    <option value={0}>NONE</option>
                                    {this.props.themes && this.props.themes.map((theme, i) => (<option key={theme.themeid} value={theme.themeid}>{theme.themename}</option>))}
                                    {this.props.themeList && this.props.themeList.map((theme, i) => (<option key={theme.themeid} value={theme.themeid}>{theme.themename}</option>))}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col> */}
                    {/* <Col md={3}>
                        <FormGroup row >
                            <Label for="attribute" md={2}>Attribute</Label>
                            <Col md={12}>
                                <Label for="attribute" style={{display: 'inline', width: "100%"}}>Attribute</Label>
                                <Input type="select" id="attribute" name="attribute" width="100%" >
                                    <option>All</option>
                                    {this.props.attr && this.props.attr.map(
                                        (item,i) =>(item.name != 'Lat' && item.name != 'Lng' && <option key={i} value={item.name}>{item.name}</option>)
                                    )}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col> */}
                {/* </Row>
                <Row> */}
                    <Col md={2}>
                        <FormGroup row >
                            {/* <Label for="startTime" md={2} >From</Label> */}
                            <Col md={12}>
                                <Label for="startTime"  style={{display: 'inline', width: "100%"}} ><h4><b>From</b></h4></Label>
                                <Input type="date" id="startTime" name="startTime" width="100%" value={this.props.currentFromTime} onChange={this.onFromChange}>
                                </Input>     
                            </Col>                   
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup row >
                            {/* <Label for="endTime" md={2} >To</Label> */}
                            <Col md={12}>
                                <Label for="endTime"  style={{display: 'inline', width: "100%"}} ><h4><b>To</b></h4></Label>
                                <Input type="date" id="endTime" name="endTime" width="100%" value={this.props.currentToTime} onChange={this.onToChange}>
                                </Input>  
                            </Col>                      
                        </FormGroup>
                    </Col>
                    <Col md={2} style={{ textAlign: 'center', justifyContent: 'center',alignItems: 'center' }}>
                        <Label for="theme" style={{ display: 'block'}} >&nbsp;</Label>
                        <Button variant="primary" color="custom" type="button" onClick={this.onRefreshClick} disabled = {this.props.loading || refrashDisabled}>
                            {this.props.loading && 
                                <Spinner 
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> 
                            }
                            Refresh
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="theme" md={2}>Theme</Label> */}
                            <Col md={12}>
                                <Label for="theme" style={{display: 'inline', width: "100%"}}><h4><b>Theme</b></h4></Label>
                                <Input type="select" id="theme" name="theme" width="100%" value={this.props.currentThemeId} onChange={this.onThemeChange} disabled={this.state.themeDisable}>
                                    <option value={0}>NONE</option>
                                    {/* <option value={1}>Theme 1</option>
                                    <option value={2}>Theme 2</option>
                                    <option value={3}>Theme 3</option>
                                    <option value={4}>Theme 4</option> */}
                                    {/* {this.props.themes && this.props.themes.map((theme, i) => (<option key={theme.themeid} value={theme.themeid}>{theme.themename}</option>))} */}
                                    {this.props.themeList && this.props.themeList.map((theme, i) => (<option key={theme.themeid} value={theme.themeid}>{theme.themename}</option>))}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup row >
                            {/* <Label for="attribute" md={2}>Attribute</Label> */}
                            <Col md={12}>
                                <Label for="attribute" style={{display: 'inline', width: "100%"}}><h4><b>Attribute</b></h4></Label>
                                <Input type="select" id="attribute" name="attribute" width="100%" value={this.props.currentAttributeId} onChange={this.onAttributeChange} >
                                    <option value={0}>All</option>
                                    {/* {this.props.attr && this.props.attr.map(
                                        (item,i) =>(item.name != 'Lat' && item.name != 'Lng' && <option key={i} value={item.name}>{item.name}</option>)
                                    )} */}
                                    { attrData.map(
                                        (item,i) =>(item.name != 'Lat' && item.name != 'Lng' && <option key={i} value={item.id}>{item.name}</option>)
                                    )}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
        </Form>
        </div>
        );
    }

    // render(){
    //     return (
    //         // <Container>
    //         //     <Row md={12}>
    //         //        {this.renderSearchForm()}
    //         //     </Row>
    //         // </Container>
    //         <div>
    //             <div style={{backgroundColor: "#FF0000", width: "100%"}}>red</div>
    //              {this.renderSearchForm()}
    //         </div>
            
    //     );
    // }

}

function mapStateToProps(state) {
    const themes = state.mapboard.themes;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const deviceTypeAttr = state.mapboard.attr;
    const themeList = state.attributetheme.themeList;
    const mapActionType = state.mapboard.status;
    const loading = state.mapboard.loading;
    const dataType = state.mapboard.dataType;
    return {...state.mapfilter, themes, assetClassAttr, deviceTypeAttr, mapActionType, loading, dataType, themeList};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        loadDeviceAttr, 
        loadDivceMapData, 
        bindFilterAssetClass, 
        bindFilterFrom, 
        bindFilterTo, 
        bindFilterTheme, 
        bindFilterAttribute, 
        bindFilterDeviceType,
        filterMapDataByAttribute, 
        getAssetsData, 
        getThemes, 
        getAssetClassAttribute, 
        filterAssetDataByAttribute ,
        getThemeList,
        getDeviceTypeThemeList,
    }, dispatch);
};

// export default FilterContainer;
export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer);