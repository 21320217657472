/* eslint-disable */
import React, { Component, useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  Label,
  Button,
  Input,
  FormGroup,
} from "reactstrap";
import MultiSelectionComponent, {
  DeviceMultiSelection,
  SubDeviceMultiSelection,
  SingleSelection,
  DeleteConfirmation,
} from "./SelectionComponents";
import ConfirmDeleteDialog from "../../../shared/components/Dialog/ConfirmationDialog";
import {
  AttributeInfo,
  EditableAttribute,
  UneditableAttribute,
  EditableText,
  multiSelectStyles,
  UploadImageAttribute,
} from "./ClassDisplayDetail";
import { WEB_SERVICE_ROOT } from "../../../constants/appConstants";
import { clearGroupDevices } from "../../../redux/actions/mapActions";
import axios from "axios";
import { getAssetRecursiveData } from "../../../redux/actions/dashboardActions";

export default function EditDevicePanel(props) {
  const deviceDefaultValue = props.assetInfo?.devices.map(d => ({ id: d.deviceid, name: d.devicename, namealias: d.devicenamealias }));
  // console.log("===deviceDefaultValue", deviceDefaultValue);
  const [selectedDevices, setDevices] = React.useState(deviceDefaultValue);
  // const subDeviceDefaultValue = [];
  // const [selectedDevices, setDevices] = React.useState(subDeviceDefaultValue);
  const [selectedDevicesBuf, setDevicesBuf] = React.useState(
    deviceDefaultValue
  );
  
  const optionList = props.deviceList
    .filter((id) => id.deviceid != props.deviceInfo.deviceid && id.parentdeviceid != id.deviceid)
    .map((filteredList) => filteredList);
  const onChange = (selected, action) => {
    var updatedList = props.deviceList;
    var returnList = {devices: []};
    if (action.action == "remove-value") {
      for (var i = 0; i < updatedList.length; i++) {
        if (updatedList[i].deviceid == action.removedValue.deviceid) {
          updatedList[i].parentdeviceid = null;
        }
      }
      returnList.devices = updatedList.filter(d => d.parentdeviceid == props.deviceInfo.deviceid);
      props.updateDeviceOnStore(
        {
          deviceid: returnList,
          data: {
            subDevices: updatedList,
          },
        },
        () => {
          setDevicesBuf(updatedList);
        },
        () => {
          setSelectedDSubDevices(selectedDevicesBuf);
        }
      );
    } else if (action.action == "select-option") {
      for (var i = 0; i < updatedList.length; i++) {
        if (updatedList[i].deviceid == action.option.deviceid) {
          updatedList[i].parentdeviceid = props.deviceInfo.deviceid;
        }
      }
      returnList.devices = updatedList.filter(d => d.parentdeviceid == props.deviceInfo.deviceid);
      props.updateDeviceOnStore(
        {
          deviceid: returnList,
          data: {
            subDevices: updatedList,
          },
        },
        () => {
          setDevicesBuf(updatedList);
        },
        () => {
          setSelectedDSubDevices(selectedDevicesBuf);
        }
      );
    }
    const options = updatedList
      .filter((d) => d.parentdeviceid == props.deviceInfo.deviceid)
      .map((d) => d);
    return setSelectedDSubDevices(options);
  };

  
  // console.log("===props.assetInfo", props.assetInfo);
  let assetClassInfo = props.assetClassList.find(ac => ac.id == props.assetInfo.assetclassid);
  let deviceInfo = props.deviceList.find(d => d.id == props.devices.deviceid);
  let subDeviceOptions = [];
  if(assetClassInfo) {
    subDeviceOptions = props.devices.filter((item, index) => (item.devicetype == assetClassInfo.allowedDeviceTypes[0].id));
    // console.log("===subDeviceOptions", subDeviceOptions);
  };
  
  const groupedDevices = dtList => dtList.map(dt => ({
    label: dt.name,
    options: props.deviceList.filter(d => d.devicetype == dt.id)
  }));

  // const deviceOptions = groupedDevices(props.deviceTypes.filter(dt => assetClassInfo.allowedDeviceTypes.some(dT => dT.id == dt.id)));
  
  // const updateDeviceOnBlur = () => {
  //   if (selectedDevices != props.assetInfo.devices)
  //     props.updateDeviceOnStore(
  //       {
  //         assetid: props.assetInfo.assetid,
  //         data: { devices: selectedDevices },
  //       },
  //       () => {
  //         setDevicesBuf(selectedDevices);
  //       },
  //       () => {
  //         setDevices(selectedDevicesBuf);
  //       }
  //     );
  // };


 
  return (
    <div>
      <Card>
        <CardBody>
          <h3>Edit Device</h3>
          <br />
          <Row>
            <Col md={4} style={{ padding: 0 }}>
              <UneditableAttribute name={props.deviceInfo.deviceid} md={12} />
              <br />
              {/* <EditableAttribute
                name="Description"
                inputStyles={{ height: 100, paddingTop: 8 }}
                md={12}
                type="textarea"
                // defaultValue={props.deviceInfo.description}
              /> */}
            </Col>
            {/* Hide for now */}
            {/* <Col md={8}>
              <AttributeInfo name="Sub Devices">
                <SubDeviceMultiSelection
                  key={props.deviceList.deviceid}
                  // onBlur={updateDeviceOnBlur}
                  options={optionList}
                  styles={multiSelectStyles}
                  closeMenuOnSelect={false}
                  // onChange={(options) => setSelectedDSubDevices(options || [])}
                  onChange={onChange}
                  value={props.deviceInfo.parentdeviceid ? hasParent[0] : selectedSubDevices}
                  defaultValue={deviceDefaultValue}
                  isDisabled={props.deviceInfo.parentdeviceid ? true : false}
                  placeHolder="HAHA"
                />
              </AttributeInfo>
              <AttributeInfo name="Parent Device ID">
                <Input
                  disabled={true}
                  value={props.deviceInfo.parentdeviceid}
                />
              </AttributeInfo>
            </Col> */}
            {/* Hide for now */}
          </Row>
          <hr />
          <Row>
            <Col className="iconHover">
              <span style={{ fontSize: 18, color: "#646777" }}>
                Custom Attributes
              </span>
            </Col>
          </Row>
          <br />
          <CustomAttributes
            deviceid={props.deviceInfo.deviceid}
            deviceAttributes={props.deviceInfo.attributes}
            attributes={props.assetInfo.assetattributes}
            attrmetas={
              props.assetClassList.find(
                (aCL) => aCL.id == props.assetInfo.assetclassid
              ).attributeMetas
            }
            updateDeviceAttribute={props.updateDeviceAttribute}
            deleteDeviceAttribute={props.deleteDeviceAttribute}
          />
          {/* {console.log("===attributes", props.assetInfo.assetattributes)}
          {console.log("===attrmeta", props.assetClassList.find(aCL => aCL.id == props.assetInfo.assetclassid).attributeMetas)} */}
          <Col className="text-right">
            <Button
              color="custom"
              style={{ marginBottom: 0 }}
              onClick={() => props.closePanel()}
            >
              Close
            </Button>
          </Col>
        </CardBody>
      </Card>
    </div>
  );
}

class CustomAttributes extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let attmetattt = this.props.deviceAttributes.map(info => ({attmetaid: info.attmetaid, value: info.value, deviceid: info.deviceid}));
    let result = attmetattt.map(att => ({ deviceid: att.deviceid, attmetaid: att.attmetaid.attributemetaid, attmetaname: att.attmetaid.name, type: att.attmetaid.type, value: att.value}))

    return (
      <Row>
        {this.props.attrmetas.map((attrmeta) => {
          let attribute = result.find(
            ({ attmetaid }) => attmetaid == attrmeta.attributemetaid
          );
          // console.log("===attribute", attribute);
          let type = (type) => {
            switch (type) {
              case "image":
                return "image";
              case "date":
                return "date";
              case "num":
                return "number";
              case "string":
              default:
                return "text";
            }
          };
          if (attrmeta.type !== "image") {
            return (
              <EditableAttribute
                key={attrmeta.attributemetaid}
                name={attrmeta.name + ": " + attrmeta.type}
                defaultValue={attribute && attribute.value}
                type={type(attrmeta.type)}
                onSave={(newVal) => {
                  let id = attribute ? attribute.id : 0;
                  // console.log(this.props.id)
                  // console.log(id)
                  // console.log(newVal)
                  if (attrmeta.type === "num") {
                    newVal = Number(newVal);
                  }
                  // let data = attribute
                  //   ? {
                  //       value: newVal,
                  //     }
                  //   : {
                  //       assetid: 1,
                  //       attmetaid: attrmeta.attributemetaid,
                  //       attmetaname: attrmeta.name,
                  //       value: newVal,
                  //     };
                  let param = {
                    deviceid: this.props.deviceid,
                    attmetaid: attrmeta.attributemetaid,
                    value: newVal,
                  };
                  this.props.updateDeviceAttribute(param);
                }}
              />
            );
          }
        })}
      </Row>
    );
  }
}
