/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter,Card, CardTitle, CardBody } from 'reactstrap';
import Select from 'react-select';
import { ChromePicker } from 'react-color';
import { EditableText } from '../../Asset/components/ClassDisplayDetail';
import { toast } from "react-toastify";
import { UserContext } from '../../../constants/myContexts';
import {
    // loadDevice, 
    loadAssetClass,
    getAssetClassAttribute,
    clearAssetClassAttribute,
} from '../../../redux/actions/mapActions';
// import {
//     getAssetList,
// } from '../../../redux/actions/dashboardActions';
import {
    getAttributes,
    clearAttributes,
} from '../../../redux/actions/attributeMgmtAction';
import { isStringEmpty, groupBy } from '../../../factories/utils';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import { multiSelectStyles } from '../../Asset/components/ClassDisplayDetail';

class AssetAttrEditform extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            showColorPicker: false,
            currentName: '',
            currentAssetId: null,
            currentDeviceId: null,
            currentAttrId: null,
            currentAttrName: null,
            currentUnit: '',
            currentEditColor: '#FF0000',
            selectedOption: null,
            customStyles: {},
            assets: []
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }
    componentDidMount() {
        const { userObj, updateUserObj } = this.context;

        this.props.loadAssetClass(null, false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.parameter !== this.props.parameter) {
            this.handleParameterChange();
        }
        if (prevProps.assetClasses !== this.props.assetClasses) {
            this.handleAssetClassesChange();
        }
        if (prevProps.assetClassAttr !== this.props.assetClassAttr) {
            this.handleAssetClassAttrChange();
        }
        if (prevProps.name !== this.props.name) {
            this.setState({ currentName: this.props.name, rawName: this.props.name });
        }
    }

    handleParameterChange = () => {
        const parameter = JSON.parse(this.props.parameter);
        const { assetclassid, assetid, deviceid, attid, attname, attunit, attcolor } = parameter;
        this.setState({
            currentAssetClassId: assetclassid,
            currentAssetId: assetid,
            currentDeviceId: deviceid,
            currentAttrId: attid,
            currentAttrName: attname,
            currentUnit: attunit,
            currentEditColor: attcolor,
            currentParameter: this.props.parameter,
        }, () => {
            this.props.getAssetClassAttribute({ assetclassid: assetclassid });
        });
    };

    handleAssetClassesChange = () => {
        if (this.state.currentAssetClassId) {
            const assetClassObj = this.props.assetClasses.find(item => item.assetclassid === this.state.currentAssetClassId);
            if (assetClassObj) {
                this.setState({ assets: assetClassObj.assets });
            }
        }
    };

    handleAssetClassAttrChange = () => {
        if (this.state.currentAttrId && this.state.currentAttrName) {
            const selectedOption = this.props.assetClassAttr[0].atrributes.find(item =>
                this.state.currentAttrId === item.attributeid && this.state.currentAttrName === item.attributename);
            this.setState({ selectedOption });
        }
    };

    handleInputChange = (fieldName, newVal) => {
        this.setState({ [fieldName]: newVal });
    };

    componentWillReceiveProps(nextProps) {


        if (nextProps.parameter && nextProps.parameter != this.state.currentParameter) {
            let parameter = JSON.parse(nextProps.parameter);

            const { assetclassid, assetid, deviceid, attid, attname, attunit, attcolor } = parameter;
            this.setState({
                currentAssetClassId: assetclassid,
                currentAssetId: assetid,
                currentDeviceId: deviceid,
                currentAttrId: attid,
                currentAttrName: attname,
                currentUnit: attunit,
                currentEditColor: attcolor,
                currentParameter: nextProps.parameter,
            }, () => {
                this.props.getAssetClassAttribute({ assetclassid: assetclassid });
            });
            // this.props.getAssetClassAttribute({assetclassid: assetclassid});
        }
        if (nextProps.assetClasses && this.state.currentAssetClassId) {
            //  
            //  
            const assetClassObj = nextProps.assetClasses.find(item => (item.assetclassid == this.state.currentAssetClassId));
            //  
            if (assetClassObj) {
                this.setState({
                    assets: assetClassObj.assets,
                });
            }
        }
        if (nextProps.assetClassAttr && nextProps.assetClassAttr.length > 0 && nextProps.assetClassAttr[0].atrributes) {
            if (this.state.currentAttrId && this.state.currentAttrName) {
                // currentAttrList = selectedOption.map(item=>({attrid: item.attributeid, attributename:item.attributename}));
                let selectedOption = nextProps.assetClassAttr[0].atrributes.find(item => (this.state.currentAttrId == item.attributeid && this.state.currentAttrName == item.attributename));
                this.setState({
                    selectedOption: selectedOption,
                    // currentAttrList: parameter.attributes,
                    // rawparameter: nextProps.layout.parameter
                });
            }
        }
        if (nextProps.name && nextProps.name !== this.state.rawName) {
            this.setState({
                currentName: nextProps.name,
                rawName: nextProps.name,
            });
        }

    }
    // ------------------------------------------------------------
    // handleInputChange = (fieldName, newVal) => {
    //     this.setState((prevState) => {
    //         // if (prevState.layout){
    //         //     prevState.layout.name = newVal;
    //         //     return ({
    //         //         currentName: newVal,
    //         //         layout: prevState.layout,
    //         //     })
    //         // }else{
    //         //     return null;
    //         // }

    //         return ({
    //             [fieldName]: newVal,
    //             // layout: prevState.layout,
    //         });
    //     }, () => {


    //     });
    // }

    renderTitle = () => {
        return (

            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{ display: 'inline' }} ><h4><b> Name</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                        type='text'
                        //  defaultValue={this.props.layout ? this.props.layout.name : ''} 
                        defaultValue={this.state.currentName ? this.state.currentName : ''}
                        exitOnEnterKeyPress={false}
                        onSave={newVal => { this.handleInputChange('currentName', newVal) }}
                    />
                </Col>
            </Row>
        )
    }

    onAssetClassChange = selectedOption => {
        if (!selectedOption) {
            this.setState({
                currentAssetClassId: null,
                currentAssetId: null,
                devices: [],
                currentDeviceId: null,
                currentAttrId: null,
                currentAttrName: null,
                selectedOption: null,
            });
        } else {
            const assetClassId = selectedOption.value;
            this.setState({
                currentAssetClassId: assetClassId,
                currentAssetId: null,
                devices: [],
                currentDeviceId: null,
                currentAttrId: null,
                currentAttrName: null,
                selectedOption: null,
            });
            this.getAssetList(assetClassId);
            this.props.getAssetClassAttribute({ assetclassid: assetClassId });
            this.props.clearAttributes();
        }
    };

    getAssetList = (assetclassid) => {
        let assetList = [];
        if (this.props.assetClasses) {
            const assetClassObj = this.props.assetClasses.find(item => item.assetclassid === assetclassid);
            if (assetClassObj) {
                assetList = assetClassObj.assets;
            }
        }
        this.setState({
            assets: assetList,
        });
    };

    renderAssetClass = () => {
        const { assetClasses } = this.props;
        const { currentAssetClassId } = this.state;

        const selectedAssetClass = assetClasses.find(assetclass => assetclass.assetclassid === currentAssetClassId);

        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="assetclass" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        isClearable
                        isSearchable
                        options={[
                            { value: 0, label: 'NONE' },
                            ...assetClasses.map(assetclass => ({
                                value: assetclass.assetclassid,
                                label: assetclass.assetclassname
                            }))
                        ]}
                        value={selectedAssetClass ? { value: selectedAssetClass.assetclassid, label: selectedAssetClass.assetclassname } : null}
                        onChange={selectedOption => this.onAssetClassChange(selectedOption)}
                    />
                </Col>
            </Row>
        )
    }


    onAssetChange = selectedOption => {
        if (!selectedOption) {
            this.setState({
                currentAssetId: null,
                currentDeviceId: null,
                currentAttrId: null,
                currentAttrName: null,
                selectedOption: null,
            });
        } else {
            const assetId = selectedOption.value;
            this.setState({
                currentAssetId: assetId,
                currentDeviceId: null,
                currentAttrId: null,
                currentAttrName: null,
                selectedOption: null,
            });
            // this.setDeviceList(this.props.assets);
        }
        // this.props.clearAttributes();
    };

    // setDeviceList = (assets) => {
    //     // let devices = this.state.devices ? this.state.devices : [];
    //     if (assets && this.state.currentAssetId) {
    //         let assetObj = assets.find((asset)=>(asset.assetid == this.state.currentAssetId));
    //         if (assetObj) {
    //             let devices = assetObj.devices;
    //             this.setState({
    //                 devices: devices,
    //             }, () =>{
    //                 //  
    //                 // this.setAttrList();
    //             });
    //         }
    //     }     
    // }

    renderAsset = () => {
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="asset" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        styles={this.state.customStyles}
                        isClearable
                        isSearchable
                        options={[
                            { value: null, label: 'None' },
                            ...(Array.isArray(this.state.assets) ? this.state.assets.map(asset => ({
                                value: asset.assetid,
                                label: asset.assetname,
                            })) : [])
                        ]}
                        value={this.state.currentAssetId && Array.isArray(this.state.assets) ? {
                            value: this.state.currentAssetId,
                            label: this.state.assets.find(asset => asset.assetid === this.state.currentAssetId)?.assetname
                        } : null}
                        onChange={this.onAssetChange}
                    />
                </Col>
            </Row>
        )
    }


    // onDeviceChange = (e) =>{
    //     let deviceId = e.target.value;
    //     this.setState({
    //         currentDeviceId: deviceId,
    //         currentAttrId: null,
    //         currentAttrName: null,
    //     },() =>{
    //         this.setAttrList();
    //     });
    // }

    // setAttrList = () => {
    //      
    //     if (this.state.devices && this.state.currentDeviceId && this.state.currentDeviceId != this.state.preiousDeviceId) {
    //         let deviceObj = this.state.devices.find((device)=>(device.deviceid == this.state.currentDeviceId));
    //         if (deviceObj) {
    //             this.setState({preiousDeviceId: this.state.currentDeviceId});
    //             this.props.getAttributes(deviceObj.devicetype);
    //         }
    //     }
    // }

    // renderDevice = () => {
    //     //  
    //     // let devices = [];
    //     // if (this.props.assets && this.state.currentAssetId) {
    //     //     let assetObj = this.props.assets.find((asset)=>(asset.assetid == this.state.currentAssetId));
    //     //     if (assetObj) {
    //     //         devices = assetObj.devices;
    //     //     }
    //     // }
    //     return (
    //         <Row>
    //             <Col md={3} style={{textAlign: 'left' }}>
    //                 <Label for="device" style={{display: 'inline', width: "100%"}} ><h4><b>Device</b></h4></Label>
    //             </Col>
    //             <Col md={8}>
    //                 <Input type="select" id="device" name="device" width="100%" value={this.state.currentDeviceId} onChange={this.onDeviceChange} >                                    
    //                     {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
    //                     <option value={0}>NONE</option> 
    //                     {/* {devices.map((device, i) => (<option key={device.deviceid} value={device.deviceid}>{device.devicename}</option>))} */}
    //                     {this.state.devices.map((device, i) => (<option key={device.deviceid} value={device.deviceid}>{device.devicename}</option>))}
    //                 </Input>
    //             </Col>
    //         </Row>
    //     )
    // }

    // ---------------------------------------------------------------------------------------------------------------------------
    // onAttrChange = (e) => {
    //      
    //     let index = e.target.selectedIndex
    //     this.setState({
    //         currentAttrId: e.target.value,
    //         currentAttrName: e.target.options[index].text
    //     });
    // }

    // renderAttribute = () => {
    //      
    //     return (
    //         <Row>
    //             <Col md={3} style={{textAlign: 'left' }}>
    //                 <Label for="attribute" style={{display: 'inline', width: "100%"}} ><h4><b>Attribute</b></h4></Label>
    //             </Col>
    //             <Col md={8}>
    //                 <Input type="select" id="attribute" name="attribute" width="100%" value={this.state.currentAttrId} onChange={this.onAttrChange} >                                    
    //                     {/* {this.props.assets && this.props.assets.map((asset, i) => (<option key={asset.assetid} value={asset.assetid}>{asset.assetname}</option>))} */}
    //                     <option value={0}>NONE</option> 
    //                     {/* {devices.map((device, i) => (<option key={device.deviceid} value={device.deviceid}>{device.devicename}</option>))} */}
    //                     {this.props.attributes && this.props.attributes.map((attr, i) => (<option key={attr.attributeid} value={attr.attributeid}>{attr.attributename}</option>))}
    //                 </Input>
    //             </Col>
    //         </Row>
    //     )
    // }
    // --------------------------------------------------------------------------------------------------------------
    onAttrChange = selectedOption => {
        this.setState({
            selectedOption: selectedOption,
            currentAttrId: selectedOption ? selectedOption.attributeid : null,
            currentAttrName: selectedOption ? selectedOption.attributename : null,
        });
    };

    renderAttribute = () => {

        let attributeOptions = [];
        if (this.props.assetClassAttr && this.props.assetClassAttr.length > 0 && this.props.assetClassAttr[0].atrributes) {
            let attributeMap = groupBy(this.props.assetClassAttr[0].atrributes, attr => attr.devicetypename);
            for (let [key, value] of attributeMap) {
                let group = {
                    label: key,
                    options: value,
                }
                attributeOptions.push(group);
            }
        }
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="attribute" style={{ display: 'inline', width: "100%" }} ><h4><b>Attribute</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Input type="select" id="attribute" name="attribute" width="100%" value={this.state.currentAttrId} onChange={this.onAttrChange} >                                    
                        <option value={0}>NONE</option> 
                        {this.props.attributes && this.props.attributes.map((attr, i) => (<option key={attr.attributeid} value={attr.attributeid}>{attr.attributename}</option>))}
                    </Input> */}
                    <Select
                        // isMulti
                        styles={this.state.customStyles}
                        isClearable
                        isSearchable
                        options={attributeOptions}
                        getOptionLabel={({ displayname }) => displayname}
                        getOptionValue={({ attributeid }) => attributeid}
                        value={this.state.selectedOption}
                        onChange={this.onAttrChange}
                    />
                </Col>
            </Row>
        )
    }
    // ------------------------------------------------------------
    renderAttrUnit = () => {
        return (
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{ display: 'inline' }} ><h4><b> Unit</b></h4></Label>
                </Col>
                <Col md={8}>
                    <EditableText
                        type='text'
                        defaultValue={this.state.currentUnit ? this.state.currentUnit : ''}
                        // defaultValue={this.state.currentUnit ? this.state.currentUnit : ''} 
                        exitOnEnterKeyPress={false}
                        onSave={newVal => { this.handleInputChange('currentUnit', newVal) }}
                    />
                </Col>
            </Row>
        );
    }

    handleShowColorClick = (e) => {
        e.stopPropagation();
        this.setState(
            (preState) => (
                { showColorPicker: !preState.showColorPicker }
            )
        )
    }

    handleColorOnClick = (event) => {
        // alert('click event');
        event.preventDefault();
        event.stopPropagation();
    }

    handleChangeComplete = (color, event) => {
        this.setState({ currentEditColor: color.hex });
    }

    renderAttrColor = () => {
        return (
            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left' }}>
                {/* <Col md={3} style={{textAlign: 'left' }}>
                        <Label for="upinput" >Color</Label>
                    </Col> */}
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label style={{ display: 'inline' }} ><h4><b> Color</b></h4></Label>
                </Col>

                <Col md={8}>
                    {/* <Input type="text" id="colorinput" name="colorinput" width="60%" style={{float: 'left'}} value={this.props.colorvalue} onClick={this.handleShowColorClick} ></Input> */}
                    <div style={{ backgroundColor: this.state.currentEditColor, width: 20, height: 20, float: 'left' }} onClick={this.handleShowColorClick} ></div>
                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', top: 20, zIndex: 100 }}>
                            {this.state.showColorPicker &&
                                <div onClick={this.handleColorOnClick}>
                                    <ChromePicker
                                        color={this.state.currentEditColor}
                                        onChangeComplete={this.handleChangeComplete}
                                    />
                                </div>}
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
    // ------------------------------------------------------------
    validateInput = () => {
        // let isValidated = true;
        let hasUser = this.context != undefined && this.context != null && this.context.userObj != undefined && this.context.userObj != null;
        if (!hasUser) {

            toast.error("Can not get user infomation");
        }
        let isInput = !isStringEmpty(this.state.currentName);
        let isAttInput = !isStringEmpty(this.state.currentAttrName);
        let isSelect = this.state.currentAssetId != null && this.state.currentAssetId != 0;
        isSelect = isSelect && this.state.currentAssetClassId != null && this.state.currentAssetClassId != 0;
        isSelect = isSelect && this.state.currentAttrId != null && this.state.currentAttrId != 0;
        // let isTooLong = this.state.currentAttrList != undefined && this.state.currentAttrList != null && this.state.currentAttrList.length > 4;
        if (!isInput || !isAttInput || !isSelect) {

            toast.error("Input is not valid");
        }
        // if (isTooLong) {
        //      
        //     toast.error("Max attributes are 4");
        // }
        //  
        //  
        return hasUser && isInput && isAttInput && isSelect;
    }
    onOkClick = () => {
        if (!this.validateInput()) {
            return;
        }
        let parameter = {
            assetclassid: this.state.currentAssetClassId,
            assetid: this.state.currentAssetId,
            deviceid: this.state.currentDeviceId,
            attid: this.state.currentAttrId,
            attname: this.state.currentAttrName,
            attunit: this.state.currentUnit,
            attcolor: this.state.currentEditColor,
        }


        this.props.handleOk(this.context.userObj, this.state.currentName, JSON.stringify(parameter));
    }
    onCancleClick = () => {
        this.props.handleCancle();
    }
    render() {
        return (
            <Fragment>

                <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                        {this.renderTitle()}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                        {this.renderAssetClass()}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                        {this.renderAsset()}
                    </Col>
                </Row>
                {/* <Row style={{marginBottom: '10px'}}>
                    <Col md={12}>
                        {this.renderDevice()}
                    </Col>
                </Row> */}
                <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                        {this.renderAttribute()}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                        {this.renderAttrUnit()}
                    </Col>
                </Row>
                <Row style={{ marginBottom: '10px' }}>
                    <Col md={12}>
                        {this.renderAttrColor()}
                    </Col>
                </Row>
                <hr />
                <div style={{ textAlign: 'right' }}>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    // const types = state.mapboard.types;
    // const assets = state.dashboard.assetList;
    const assetClasses = state.mapboard.assetClass;
    const assetClassAttr = state.mapboard.assetClassAttr;
    const attributes = state.attributemgmt.attributes;
    const props = {
        // types,  
        // assets,
        assetClasses,
        assetClassAttr,
        attributes,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // loadDevice, 
        loadAssetClass,
        getAssetClassAttribute,
        clearAssetClassAttribute,
        // getAssetList,
        getAttributes,
        clearAttributes,
        // addDashBoardLayout,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetAttrEditform);