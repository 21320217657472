/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Container, Card, CardBody, CardFooter, Row, Col, Button, Label, UncontrolledTooltip } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';
import DonutWidget from './DonutWidget';
import BatteryWidget from './BatteryWidget';
import AttribureIcon from './AttributeIcon';
import { FaCircle,FaClock,FaFacebookMessenger,FaTemperatureHigh,FaTemperatureLow } from "react-icons/fa";
import { MdVibration,MdOutlineMoreTime,MdOutlineAvTimer } from "react-icons/md";
import { HiOutlineLightningBolt } from "react-icons/hi";
import {
    ATTRIBUTE_TYPE,
    ATTRIBUTE_NAME,
    TIME_ZONE,
    WHITE_COLOR,
    GREEN_COLOR,
    RED_COLOR,
    ORENGE_COLOR,
    YELLOW_COLOR,
    GREY_COLOR,
    LIGHT_GREY_COLOR,
    ATTRIBUTE_ORDER,
    ATTRIBUTE_WIND_ORDER,
    ALARM_META_ATTRI_OBJ,
    LEGEND_LIST,
    xxSmallTextStyle,
    xSmallTextStyle,
    smallTextStyle,
    normalTextStyle,
} from '../../../constants/dataConstants';
import { lightningColorMaper } from '../../../factories/weather';
import styled, { keyframes, css } from "styled-components";
import './blinking.scss';
import { ConsoleIcon, RelativeScaleIcon } from 'mdi-react';

const valueRowStyle = {
    margin: '5px 10px',
    padding: '5px',
    justifyContent: 'center',
    // lineHeight: '30px',
    // minHeight: '30px'
}

const rowClass = {
    // color: 'black', 
    // fontWeight: 'normal',
    margin: '5px 10px',
    padding: '5px 30px',
    justifyContent: 'space-evenly', //'center'
    alignContent: 'center',
    lineHeight: '30px',
    height: '30px',
    // backgroundColor: GREEN_COLOR, //hasrule ? GREEN_COLOR : GREY_COLOR,
    color: 'white',
    position: 'relative',
    // animation: 'alertBlink 1s infinite',
}
const backClass = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: GREEN_COLOR, //hasrule ? GREEN_COLOR : GREY_COLOR,
    animation: 'alertBlink 1s infinite',
}
const testClass = {
    color: 'black',
    position: 'absolute',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    fontSize: '2.5vh',
    lineHeight: '2.5vh'
}

const AttributeListWidget = ({ alarmSet, asset, isSmall, toggle, activeTab }) => {
    if(asset.assetid!=null){
        let data=asset;
        let deviceid=null;
        //data.attributes['LastOccupancy'].sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
        //asset.factorDeviceId=data.attributes['LastOccupancy'][0].deviceid;  
    }

    let alarmObj = alarmSet ? alarmSet[asset.assetid] : null;
    // let donutData = setDonut(alarmObj, asset.attributes)
   

    return <div style={{ minWidth: '196px', maxWidth: '400px' }}>
        {/* <Row style={{ padding: '5px', justifyContent: 'center' }}>
            <DonutWidget status={donutData.status} color={donutData.color} />
        </Row> */}
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem', whiteSpace: 'nowrap', overflow: 'hidden'}}>{assetnameFormat(asset, toggle)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{operatingstatusFormat(asset,1)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{lastMessageTimeFormat(asset)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{currentFormat(asset)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{tempFormat(asset)}</span>
        </Row>
        <Row style={valueRowStyle}>
            <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{operatingstatusFormat(asset,2)}</span>
        </Row>
        {/* <BatteryWidget level={parseInt(batteryLevel(asset))} style={{ width: '23px', height: '45px' }}/> */}
        {/* <BatteryWidget level={3} style={{ width: '23px', height: '45px' }}/> */}
        {/* {(activeTab == 1) ?
            ATTRIBUTE_ORDER && ATTRIBUTE_ORDER.filter(item => item.display).map(attr => (
                <AttributeDisplayElement
                    alarmObj={alarmObj}
                    attr={attr}
                    asset={asset}
                    isSmall={isSmall}
                />
            ))
            :
            ATTRIBUTE_WIND_ORDER && ATTRIBUTE_WIND_ORDER.filter(item => item.display).map(attr => (
                <AttributeDisplayElement
                    alarmObj={alarmObj}
                    attr={attr}
                    asset={asset}
                    isSmall={isSmall}
                />
            ))
    
    } */}

    </div>
}


const AlarmDisplayElement = ({ attr, val, isSmall }) => {
    const key = attr.attributename;
    // const val = key === 'hexAlarm' ? asset[key] : null ;
    const colorVal = lightningColorMaper(val ? val.value : null);
    const status = val ? val.status : false;
    const displayObj = LEGEND_LIST.find(item => (item.color === colorVal));

    return <Row style={rowClassGnerator(status, null, key, colorVal, true, isSmall)}>
        {isSmall &&
            <span style={{ alignSelf: 'flex-start' }}>
                <AttribureIcon attributeName={key} />
            </span>
        }
        {/* <span style={{ ...(displayObj && displayObj.label && displayObj.label.length < 15 ? smallTextStyle : xSmallTextStyle), width: isSmall ? '80%' : '100%', textAlign: isSmall ? 'right' : 'center' }}> */}
        <span style={{ ...(displayObj && displayObj.label && displayObj.label.length < 15 ? smallTextStyle : xSmallTextStyle), width: isSmall ? '80%' : '100%', textAlign: isSmall ? 'right' : 'center', fontSize: '1rem', lineHeight: '1rem' }}>
            {displayObj ? displayObj.label : ''}
            {/* {' '}{attr.units} */}
        </span>
    </Row>
}

const BatteryDisplayElement = ({ alarmObj, attributeName, valArray, isSmall }) => {
    return (
        <Row style={rowClassGnerator(false, null, attributeName, 'red', true, isSmall)}>
            {valArray && valArray.map((val, index) => {
                var deviceIdRegNero = new RegExp("1B30D[a-zA-Z0-9]{2}|NS[0-9]{8}");
                if (!deviceIdRegNero.test(val.deviceid + val.devicename)) {
                    let value = parseFloat(val.value);
                    const id = `battery_${index}`;
                    if (alarmObj && alarmObj[val.deviceid] && alarmObj[val.deviceid][attributeName]) {
                        value = alarmObj[val.deviceid][attributeName].value;
                    }
                    let level = 0
                    let percentage = "0%";
                    if (Number.isNaN(value)) {
                        level = -1;
                        percentage = "No Battery Detected";
                    }
                    if (!Number.isNaN(value) && value > 3.6 && value < 4.3) {
                        //full battery: 4.3 | Empty battery 3.6
                        level = Math.round((((value - 3.6) / .7) * 5) - .5);
                        percentage = Math.round(((value - 3.6) / .7) * 100) + "%"
                    }
                    if (value >= 4.3) {
                        level = 4;
                        percentage = "100%"
                    }
                    // 
                    let tooltiptext = !val.devicename || val.devicename.length == 0 ? 'No device name - ' + val.deviceid+" - "+percentage : val.devicename + " - "+ val.deviceid+" - " + percentage;
                    return (
                        <Fragment>
                            <Tooltip title={tooltiptext}>
                                <div id={id}>
                                    <BatteryWidget level={level} style={{ width: '23px', height: '45px' }} />
                                </div>
                            </Tooltip>
                        </Fragment>
                    )
                }
            })}

        </Row>
    )
}

const AttributeDisplayElement = ({ alarmObj, attr, asset, isSmall }) => {
    const attributeName = attr.attributename;
    let valArray = null;
    let status = false;
    let groupData = null;

    if (asset.attributes && Array.isArray(asset.attributes[attributeName]) && asset.attributes[attributeName].length > 0) {
        valArray = asset.attributes[attributeName];
        if (attr.group) {
            groupData = []

            attr.group.forEach(item => {
                let valObj = {
                    ...item,
                    displayVal: null,
                };
                if (asset.attributes[item.attributename] && asset.attributes[item.attributename].length > 0) {
                    valObj = {
                        ...item,
                        displayVal: asset.attributes[item.attributename][0],
                    }
                }
                groupData.push(valObj);
            });
        }

    }

    let displayVal = valArray ? valArray[0] : null;

    if (attributeName === 'hexAlarm') {
        return (
            <AlarmDisplayElement attr={attr} val={displayVal} isSmall={isSmall} />
        )
    }
    else if (attributeName === 'Battery') {
        return (
            <BatteryDisplayElement alarmObj={alarmObj} attributeName={attributeName} valArray={valArray} isSmall={isSmall} />
        )
    } else {
        if (attributeName != "CO2") {
            status = displayVal ? displayVal.status : false;
            if (displayVal && alarmObj && alarmObj[displayVal.deviceid] && alarmObj[displayVal.deviceid][attributeName]) {
                status = alarmObj[displayVal.deviceid][attributeName].status ? true : false;
            }
            let groupVal = '';
            let temp = '';
            let templow = '';
            let temphigh = '';
            if (attributeName === 'Temperature' && groupData && groupData.length === 3) {

                groupData.forEach((val, index) => {
                    if (val.displayVal && val.displayVal.value) {
                        groupVal = groupVal + val.displayVal.value + val.units;
                        groupVal = groupVal + (index == 0 ? ' - ' : index == 1 ? ' + ' : ' ');
                    }
                });
                temp = groupData[1].displayVal.value;
                templow = groupData[0].displayVal.value;
                temphigh = groupData[2].displayVal.value;
            }
            // ------------- style test -----------------------
            let alarmColor = 'red';
            let blink = keyframes`
            from {background-color: white; color: black; font-weight: bold;}
            to {background-color: ${alarmColor}; color: white; font-weight: bold;}
        `;

            return (

                <Row style={rowClassGnerator(status, null, attributeName, (displayVal ? (displayVal.value ? GREEN_COLOR : GREY_COLOR) : GREY_COLOR), attr.hasrule, isSmall)}>
                    {isSmall &&
                        <span style={{ alignSelf: 'flex-start' }}>
                            <AttribureIcon attributeName={attributeName} />
                        </span>
                    }
                    {attributeName === 'Temperature' && groupData && groupData.length === 3 ?
                        <span class="subsup" style={{fontSize: '0.9rem', lineHeight: '0.9rem'}}>
                            {temp ? temp : ''}{' '}{attr.units}
                            <sup>{temphigh}{temp ? attr.units : ""}</sup>
                            <sub>{templow}{temp ? attr.units : ""}</sub>
                        </span>
                        :
                        <span style={{fontSize: '1rem', lineHeight: '1rem'}}>{displayVal ? displayVal.value : ''}{' '}{attr.units}</span>
                    }
                </Row>

            )
        } else {
            return (<span></span>);
        }
    }
}

const assetnameFormat = (cell, toggle) => {
    const handleDeviceOnClick = (e, assetname) => {
        e.preventDefault();
        toggle(true, cell);
    }
    return (
        // <a href={'#'} onClick={(e) => { handleDeviceOnClick(e, cell.assetname); }}>{cell.devicenamealias}</a>
        <div>{cell.devicenamealias}</div>
    )
}

const timestampFormat = (cell) => {
    if (cell) {
        if(cell.charAt(0)=="+"){
            cell=new Date().getFullYear()+cell.substr(6);
        }
        let displayTime = new Date(cell).toLocaleString('en-US', { timeZone: TIME_ZONE });
        return displayTime;
    } else {
        return null;
    }
}

const batteryLevel = (asset) => {
    let batteryByte;
    if(asset.attributes){
        batteryByte = asset.attributes.Battery.value;
        if(batteryByte == null)
            batteryByte = 1;
    }
    
    return batteryByte;
}


const lastMessageTimeFormat = (asset) => {
    let unformattedTime;
    if(asset.attributes){
        unformattedTime = asset.attributes.LastMessageTime.value;
        if(unformattedTime !== null){
            let displayTime = new Date(unformattedTime).toLocaleString('en-US', { timeZone: TIME_ZONE });
            // return "Last Message " + displayTime;
            return (<div><h5>Last Message<br></br></h5> <h6>{displayTime}</h6></div>)
        }
        else
            return <h5>No Last Message Time</h5>;
            
    }
}
const currentFormat = (asset) => {
    if(asset.attributes){
        var current = asset.attributes.Current.value;
        if(current !== null){
            
            return <h5> Current: {current} Amps
            </h5>
        }
        else
            return <h5>No Current</h5>;
            
    }
}
const tempFormat = (asset) => {
    
    if(asset.attributes){
        var temp = asset.attributes.Temperature.value;
        if(temp !== null){ 
            return <h5> Temperature: {temp} &#8451;
            </h5>
        }
        else
            return <h5>No Current</h5>;
            
    }
}

const operatingstatusFormat = (asset,statusorvibration) => {
    if(asset.attributes){
        var alarmColor = asset.attributes.Alarm.value;
        var alarmText='ON'
        var vibration='ON'
        if(alarmColor=='RED'){
            alarmText='Asleep'
            vibration='OFF'
        }else if(alarmColor=='YELLOW'){
            alarmText='Startup / Issues'
            vibration='OFF'
        }else if(alarmColor=='OFF' || alarmColor=='GREY'){
            alarmColor='GREY'
            alarmText='Down'
            vibration='OFF'
        }
        if(alarmColor!== null){
            // return "Last Message " + displayTime;
            if(statusorvibration==1){
                return <h5>  Status: {alarmText} </h5>
            } else {
                return  <h5> Vibration: {vibration}</h5>
               
            }
            
        }
        else{
            if(statusorvibration==1){
                return <h5>No Last Message Time</h5>;
            } else {
                return <h5>No Vibration Status</h5>;
            }
        }
            
            
    }
}

const averageDataFormat = (asset, isTotalMonthly) => {
    let joinedString;
    let splitArray;
    let splitString;
    let totalOccupancy=0;
    let totalAverageTime=0;
    if(asset.attributes){
        for (let n=0;n<asset.attributes.AverageData.length;n++){
            joinedString = asset.attributes.AverageData[n].value;
            if(joinedString !== null){
                splitArray = joinedString.split('-', 2);
                totalOccupancy+=parseInt(splitArray[0]);
                totalAverageTime+=parseInt(splitArray[1]);
            }  
        }
        //joinedString = asset.attributes.AverageData[0].value;
        if(totalOccupancy !== 0){
            //splitArray = joinedString.split('-', 2);
            isTotalMonthly ? splitString = "Total Monthly Occupation " + totalOccupancy : splitString = "Average Time Occupied " + Math.floor(totalAverageTime / 60) + "H " + (totalAverageTime % 60) + "M";
        } else {
            isTotalMonthly ? splitString = "No Monthly Occupation" : splitString = "No Average Time Occupied";
        }
    }

    return <h5>{splitString}</h5>;
}

function rowClassGnerator(status, item, attributeName, color = GREEN_COLOR, hasrule = false, isSmall = false) {
    let backgroupdColor = attributeName === 'Battery' ? 'white' : hasrule ? color : GREY_COLOR;
    let horizontalAlign = attributeName != 'Battery' && isSmall ? 'space-between' : 'space-evenly'; //'center';
    let alarmColor = backgroupdColor;

    if (attributeName !== 'hexAlarm') {
        alarmColor = RED_COLOR;
    }

    let reClass = {
        margin: '5px 10px',
        padding: '6px 30px',
        justifyContent: horizontalAlign, //'center'
        alignContent: 'center',
        lineHeight: isSmall ? null : '30px',
        height: isSmall ? null : '30px',
        backgroundColor: backgroupdColor,
        color: 'black',
    };

    if (status == true) {
        return ({
            ...reClass,
            backgroundColor: alarmColor,
            animation: 'alertBlink 1s infinite',
        });
    } else {
        return ({
            ...reClass,
        })
    }
}

// function setDonut(alarmObj, attributes) {


//     let reValue = { status: 0, color: 'black' };

//     if (attributes && attributes.Alarm && attributes.Alarm.length > 0) {
//         const color = lightningColorMaper(attributesAlarm.value);
//         const status = attributes.Alarm.status ? 1 : 0;
//         reValue = {
//             ...reValue,
//             status,
//             color,
//         }
//     }

//     if (attributes) {
//         ATTRIBUTE_ORDER && ATTRIBUTE_ORDER.filter(item => item.display && item.attributename != 'Alarm').forEach(attr => {
//             let attributename = attr.attributename;
//             Array.isArray(attributes[attributename]) && attributes[attributename].forEach(vattr => {
//                 if (vattr.status) {
//                     reValue = {
//                         ...reValue,
//                         status: 1,
//                         color: RED_COLOR,
//                     }
//                 }
//             });
//         });
//     }

//     if (alarmObj) {
//         for (let key in alarmObj) {
//             for (let attr of ATTRIBUTE_ORDER) {
//                 if (attr.hasrule == true && alarmObj[key][attr.attributename] && alarmObj[key][attr.attributename].status == true) {
//                     reValue = {
//                         ...reValue,
//                         status: 1, //0, 
//                         color: RED_COLOR,
//                     }
//                 }
//             }
//         }
//     }
//     return reValue
// }

export default AttributeListWidget;