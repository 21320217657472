/* eslint-disable */
import React, { Component } from 'react';
import { ChromePicker} from 'react-color';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { ENGINE_METHOD_DIGESTS } from 'constants';

class NewRuleModal extends Component{
    constructor(props){
        super(props);
        this.state = {
            btnDisable: false,
            name: ''
        }
    }

    static getDerivedStateFromProps(props, state) {
        return({
            // currentDeviceTypeId : props.currentDeviceTypeId,
            // currentDeviceTypeName : props.currentDeviceTypeName,
            // currentDeviceId : props.currentDeviceId,
            // currentDeviceName : props.currentDeviceName,
        });
    } 

    handleNameOnchange =(e) => {
        // this.props.onNameChange(e);
        let btnDisable = (e.target.value && e.target.value.trim().length>0);
        this.setState({
            name: e.target.value,
            btnDisable: btnDisable
        });
    }

    handleDeviceTypeDropdownChange = (e) => {
        this.props.handleDeviceTypeDropdownChange(e);
    }

    handleDeviceDropdownChange = (e) => {
        this.props.handleDeviceDropdownChange(e);
    }

    // handleDeviceTypeDropdownChange = (e) => {
    //     let selectedValue = e.target.value;
    //     let index = e.target.selectedIndex;
    //     let name = e.target.options[index].text;
    //     // alert(name);
    //     this.props.loadRuleDevice({devicetype: e.target.value});
    //     this.setState(
    //         {
    //             currentDeviceTypeId: selectedValue,
    //             currentDeviceTypeName: name                
    //         }
    //     )
    // }

    // handleDeviceDropdownChange = (e) => {
    //     alert('test');
    //     let selectedValue = e.target.value;
    //     let index = e.target.selectedIndex;
    //     let name = e.target.options[index].text;
    //     // alert(name);
    //     this.setState(
    //         {
    //             currentDeviceId: selectedValue,
    //             currentDeviceName: name                
    //         }
    //     )
    // }
    
    OnAddClick = (e) => {
        this.props.onAddRule(this.state.name);
        this.setState({
            name: '',
            btnDisable: false,
        });
        this.props.toggle();
    }

    OnCancleClick = (e) =>{
        this.setState({
            name: '',
            btnDisable: false,
        });
        this.props.toggle();
    }

    render(){
        return(
            <Modal animationType="slide" transparent={false} isOpen={this.props.modalSetVisible}
                    toggle={this.props.toggle}>
                <ModalHeader toggle={this.toggle}>New Rule</ModalHeader>
                <ModalBody>
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="rulename" >Name</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="text" id="rulename" name="rulename" width="90%" value={this.state.name} onChange={this.handleNameOnchange} >                                    
                                </Input>
                            </Col>
                    </Row>     
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="ruledivicetype" >Device Type</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="select" id="ruledivicetype" name="ruledivicetype" width="90%" value={this.props.currentDeviceTypeId} onChange={this.handleDeviceTypeDropdownChange}>        
                                    {this.props.currentDeviceTypeList && this.props.currentDeviceTypeList.map((type,i) => (<option key={type.devicetypeid} value={type.devicetypeid}>{type.devicetypename}</option>))}                            
                                </Input>
                            </Col>
                    </Row>    
                    <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                            <Col md={3} style={{textAlign: 'left' }}>
                                <Label for="devicename" >Device</Label>
                            </Col>
                            
                            <Col md={8}>
                                <Input type="select" id="devicename" name="devicename" width="90%" value={this.props.currentDeviceId} onChange={this.handleDeviceDropdownChange} >             
                                    <option value = {0}>All</option>                
                                        {this.props.currentDeviceList.map((item,i) => {
                                            return(
                                                <option key={i} value={item.deviceid}>{item.devicename}</option>
                                            )
                                        })}                                  
                                </Input>
                            </Col>
                    </Row>                   
                </ModalBody>
                
                <ModalFooter>
                    <Button color="secondary" onClick={this.OnAddClick} disabled={!this.state.btnDisable}>Add</Button>{' '}
                    <Button color="custom" onClick={this.OnCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
    )
    }
}

export default NewRuleModal;

