/* eslint-disable */
import React, {Component, PureComponent} from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from './Panel';
import {PARKING_KEYS, PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3} from '../../../../constants/dataConstants';
import { cloneDeep, sortBy } from 'lodash';
import moment from 'moment';
//import CustomTooltipTestBootstrapCard from './CustomTooltipTestBootstrapCard'

class ChartTwo extends Component {
  constructor(props){
    super(props);
    this.state = {
      formattedMonthLevelData: [],
    }
  }

  componentWillReceiveProps(nextProps) { 
        // data format function
        
        const formatMonthLevelData = (originData) => {
          let formattedMonthLevelData = []
          let level1MonthData = []
          let level2MonthData = []
          let level3MonthData = []
          let levelTotalMonthData = []

          const objKeys = Object.keys(originData)
          for (let key of objKeys) {
              // done if we got all level data
              if (level1MonthData && level2MonthData && level3MonthData && levelTotalMonthData) {
                  break;
              }
              if (key.includes(":Level1") ||
                  key.includes(":Level2") ||
                  key.includes(":Level3") ||
                  key.includes(":LevelTotal")) {
                  let levelName = key.split(":")[1]
                  switch (levelName) {
                      case "Level1":
                          // only have to get one device level data
                          if (!level1MonthData) {
                              level1MonthData = originData[key].data
                          }
                          break;
                      case "Level2":
                          // only have to get one device level data
                          if (!level2MonthData) {
                              level2MonthData = originData[key].data
                          }
                          break;
                      case "Level3":
                          // only have to get one device level data
                          if (!level3MonthData) {
                              level3MonthData = originData[key].data
                          }
                          break;
                      case "LevelTotal":
                          // only have to get one device level data
                          if (!levelTotalMonthData) {
                              levelTotalMonthData = originData[key].data
                          }
                          break;
                      default:
                      //should not be here, do nothing
                  }
              }
          }
          //now combine the level data into one data structure
          let levelMonthData = []
          let l1data=[];
          let l2data=[];
          let l3data=[];
          let ltdata=[];

          if(l1data != null) {
            for (l1data of level1MonthData) {
              l1data["level"] = 1
              levelMonthData.push(l1data)
            }
          }
         
          if(l2data != null) {
            for (l2data of level2MonthData) {
              l2data["level"] = 2
              levelMonthData.push(l2data)
            }
          }
          
          if(l3data != null) {
            for (l3data of level3MonthData) {
              l3data["level"] = 3
              levelMonthData.push(l3data)
            }
          }
          
          if(ltdata != null) {
            for (let ltdata of levelTotalMonthData) {
              ltdata["level"] = 0
              levelMonthData.push(ltdata)
            }
          }
          

          //get days of specified month
          const mGetDate = (year, month) => {
              var aDay = new Date(year, month, 0);
              return aDay.getDate();
          }

          let oneDay = new Date()
          const theMonth = oneDay.getMonth()
          const monthDays = mGetDate(oneDay.getFullYear(), theMonth + 1)
          oneDay.setDate(1)
          // generate level data array of the month in each day
          // {
          //      slot: date -- format: m/d  (only the first day of the month is m/d/yyyy)
          //      total: total level data of the day
          //      l1: level 1 data of the day
          //      l2: level 2 data of the day
          //      l3: level 3 data of the day
          // }
          //
        
          for (let i = 1; i <= monthDays; i++) {
              if (i == 1) {
                  formattedMonthLevelData.push({
                      slot: (theMonth + 1) + '/' + oneDay.getDate(), //+ '/' + oneDay.getFullYear(),
                      total: 0,
                      l1: 0,
                      l2: 0,
                      l3: 0
                  })
              } else {
                  formattedMonthLevelData.push({
                      slot: (theMonth + 1) + '/' + oneDay.getDate(),
                      total: 0,
                      l1: 0,
                      l2: 0,
                      l3: 0
                  })
              }
              //move to next day
              oneDay.setDate(i + 1)
          }
     
          // sum level data of each day 
          levelMonthData.forEach((fd) => {
              let recordDate = new Date(fd.x)
              if (recordDate.getFullYear() == oneDay.getFullYear() && recordDate.getMonth() == theMonth) {
                  switch (fd.level) {
                      case 0:
                          //formattedMonthLevelData[recordDate.getDate()-1].total = parseInt(fd.y, 10);
                          formattedMonthLevelData[recordDate.getDate()-1].total = parseFloat((parseInt(fd.y, 10) /PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[0]).toFixed(2));
                          break;
                      case 1:
                        //formattedMonthLevelData[recordDate.getDate()-1].l1 = parseInt(fd.y, 10);
                          formattedMonthLevelData[recordDate.getDate()-1].l1 = parseFloat((parseInt(fd.y, 10) /PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[1]).toFixed(2));
                          break;
                      case 2:
                        //formattedMonthLevelData[recordDate.getDate()-1].l2 = parseInt(fd.y, 10);
                          formattedMonthLevelData[recordDate.getDate()-1].l2 = parseFloat((parseInt(fd.y, 10) /PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[2]).toFixed(2));
                          break;
                      case 3:
                        //formattedMonthLevelData[recordDate.getDate()-1].l3 = parseInt(fd.y, 10);
                          formattedMonthLevelData[recordDate.getDate()-1].l3 = parseFloat((parseInt(fd.y, 10) /PARKING_SPOTS_TOTAL_LEVEL1_LEVEL2_LEVEL3[3]).toFixed(2));
                          break;
                  }
              }
          }) 
          return formattedMonthLevelData;
      }

      //data process
      if (nextProps.asset1MonthObj && nextProps.currentAsset.assetid) {

          let formattedMonthLevelData = formatMonthLevelData(nextProps.asset1MonthObj[nextProps.currentAsset.assetid])

         this.setState({ formattedMonthLevelData })
      }
  }

  toPercent = (decimal, fixed = 0) =>
  {
    return `${(decimal * 100).toFixed(fixed)}%`;
  } 

  getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

    return this.toPercent(ratio, 2);
  };
  renderTooltipContent = (o) => {
    const { payload, label } = o;
    const total = payload.reduce((result, entry) => result + entry.value, 0);
  
    return (
      <div className="customized-tooltip-content">
        <ul className="list">
          {payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.color }}>
              {`${entry.name}: ${this.toPercent(entry.value,2)}`}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  render() {
    
      const { t } = this.props;

      class CustomizedAxisTick extends PureComponent {
          render() {
              const { x, y, payload } = this.props;
              return (
                  <g transform={`translate(${x},${y})`}>
                      <text x={0} y={0} dy={16} textAnchor="end"
                          fill="#666" transform="rotate(-35)" fontSize={12} fontWeight={400}>
                          {payload.value}
                      </text>
                  </g>
              );
          }
    }

    const renderColorfulLegendText = (value, entry) => {
      const { color } = entry;
  
      return <span style={{ color, marginRight: 20 }}>{value}</span>;
    };

    return(
        <Panel xs={12} lg={12} title={t('Occupancy Rate for the Current Month (By Dates)')}>
          <ResponsiveContainer height={500} className="dashboard__area">
            {/* <AreaChart data={this.state.formattedTotalVehicleData} margin={{ top: 20, left: -15, bottom: 20 }}> */}
            <AreaChart data={this.state.formattedMonthLevelData} margin={{ top: 20, left: -15, bottom: 20, right:15}}>
              {/* <XAxis dataKey="date" tickLine={false} tick={{fontSize: 8}}/> */}
              <XAxis dataKey="slot" tick={<CustomizedAxisTick />} interval={0}/>
              {/* <XAxis dataKey="slot" dy={10} interval={0}/> */}
              {/* <YAxis type="number" tickFormatter={value => `${value}`} tickLine={false}/> */}
              <YAxis type="number" tickFormatter={this.toPercent} tickLine={false}/>
              {/* <YAxis domain={[0,'dataMax']} tickFormatter={toPercent} tickLine={false} tickCount={1}/> */}
              {<Tooltip content={this.renderTooltipContent} />}
              <Legend iconType="square" formatter={renderColorfulLegendText}/>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              {/* <Area name="Occupancies" type="monotone" dataKey="y" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.2} /> */}
              {/* <Area name="Walking" type="monotone" dataKey="walking" fill="#4ce1b6" stroke="#4ce1b6" fillOpacity={0.2} /> */}
              <Area name="Total Lot Occupancies" stackId="1" type="monotone" dataKey="total" fill="#9aabf3" stroke="#4666e2" fillOpacity={0.5} />
              <Area name="Level 1 Lot Occupancies" stackId="2" type="monotone" dataKey="l1" fill="#9dd3fb" stroke="#4baff8" fillOpacity={0.5} />
              <Area name="Level 2 Lot Occupancies" stackId="3" type="monotone" dataKey="l2" fill="#efdde3" stroke="#f0c7ce" fillOpacity={0.5} />
              <Area name="Level 3 Lot Occupancies" stackId="4" type="monotone" dataKey="l3" fill="#ebcbfd" stroke="#e2a4fc" fillOpacity={0.5} />
            </AreaChart>
          </ResponsiveContainer>
        </Panel>
    )
  }

}

ChartTwo.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ChartTwo);
