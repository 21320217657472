/* eslint-disable */
import { PageLayoutHeaderIcon } from "mdi-react";
import {
  MESSAGE_DECODE_LOAD_SUCCEED,
  MESSAGE_DECODE_LOAD_FAILED,
  MESSAGE_DECODE_ADD_MESSAGE_DECODE,
  MESSAGE_DECODE_LOAD_LIST_START,
  MESSAGE_DECODE_LOAD_LIST_SUCCEED,
  MESSAGE_DECODE_LOAD_LIST_FAILED,
  MESSAGE_DECODE_LOAD_GROUP_START,
  MESSAGE_DECODE_LOAD_GROUP_SUCCEED,
  MESSAGE_DECODE_LOAD_GROUP_FAILED,
  MESSAGE_DECODE_UPDATE_ONE_MESSAGE_DECODE,
  MESSAGE_DECODE_REMOVE_MESSAGE_DECODE,
} from "../../constants/actionTypes";
/**
 * Create Group 
 * POST http://localhost:8080/MessageDecode/Group
 * {
 *    "name":"name323"
 * }
 * 
 * Update Group
 * POST http://localhost:8080/MessageDecode/Group
 * {
 *    "groupId":2,
 *    "name":"name211"
 * }
 * 
 * Get Group
 * GET http://localhost:8080/MessageDecode/Group?groupId=2
 * 
 * Delete Group
 * DELETE http://localhost:8080/MessageDecode/Group?groupId=3
 * 
 * 
 */
const initState = {
  messageDecodeList: [],
  messageDecodeGroup: [],
  deviceTypeId: "",
  messageLength: "",
  encryptionMethod: "",
  decryptionKey: "",
  bodyMessage: "",
  status: "",
  calculateRules: [],
  module: "",
  messageDecodeLoading: false,

  error: 0,
  message: "",
  type: "",
};

export default function(state = initState, action) {
  switch (action.type) {
    case MESSAGE_DECODE_LOAD_LIST_START:
      return {
        ...state,
        messageDecodeLoading: true,
        error: 0,
        type: action.type,
      };
    case MESSAGE_DECODE_LOAD_LIST_SUCCEED:
      return {
        ...state,
        messageDecodeLoading: false,
        messageDecodeList: action.payload,
        error: 0,
        message: "Message decode list updated.",
        type: action.type,
      };
    case MESSAGE_DECODE_ADD_MESSAGE_DECODE:
      return {
        ...state,
        messageDecodeList: assignNextIdToNewItemThenPushToList(
          [...state.messageDecodeList],
          action.payload,
          "messageDecodeItemId"
        ),
        error: 0,
        message: "Added message decode to list",
        type: action.type,
      };
    case MESSAGE_DECODE_UPDATE_ONE_MESSAGE_DECODE:
      return {
        ...state,
        messageDecodeList: updateElemHavingId(
          [...state.messageDecodeList],
          action.payload,
          "messageDecodeItemId"
        ),
        error: 0,
        message: "Updated a message decode in list.",
        type: action.type,
      };
    case MESSAGE_DECODE_REMOVE_MESSAGE_DECODE:
      return {
        ...state,
        messageDecodeList: removeElemHavingId(
          [...state.messageDecodeList],
          action.payload,
          "messageDecodeItemId"
        ),
        error: 0,
        message: "Removed a message decode from list.",
        type: action.type,
      };
    case MESSAGE_DECODE_LOAD_LIST_FAILED:

    case MESSAGE_DECODE_LOAD_GROUP_START:
      return {
        ...state,
        messageDecodeLoading: true,
        error: 0,
        type: action.type,
      };
    case MESSAGE_DECODE_LOAD_GROUP_SUCCEED:
      return {
        ...state,
        messageDecodeLoading: false,
        messageDecodeGroup: action.payload,
        error: 0,
        message: "Message decode group updated.",
        type: action.type,
      };
    case MESSAGE_DECODE_LOAD_GROUP_FAILED:
      return {
        ...state,
        messageDecodeLoading: false,
        error: 1,
        message: action.error,
        type: action.type,
      };
    default:
      return { ...state };
  }
}

function assignNextIdToNewItemThenPushToList(list, item, idField) {
  let nextIdFromGreatest =
    list.reduceRight((max, a) => Math.max(max, a[idField]), 0) + 1;
  item = { [idField]: nextIdFromGreatest, ...item };
  list.push(item);
  return list;
}

function updateElemHavingId(list, id, item, idField) {
  let index = list.findIndex((l) => l[idField] == id);
  if (index == -1)
    return assignNextIdToNewItemThenPushToList(list, item, idField);
  list[index] = { ...list[index], ...item };
  return list;
}

function removeElemHavingId(list, id, idField) {
  let index = list.findIndex((l) => l[idField] == id);
  list.splice(index, 1);
  return list;
}
