/* eslint-disable */
import PropTypes from 'prop-types'
import axios from 'axios'
import { toast } from "react-toastify";
import {
   ASSET_LOAD_DEVMALIST_SUCCEED,
   ASSET_LOAD_DEVMAOPTION_SUCCEED,
   ASSET_LOAD_ASSET_SUCCEED,
   ASSET_LOAD_ASSET_FAILED,
   ASSET_LOAD_CLASS_SUCCEED,
   ASSET_LOAD_CLASS_FAILED,
   ASSET_ADD_ASSET,
   ASSET_UPDATE_ASSET,
   ASSET_UPDATE_ONE_ASSET,
   ASSET_REMOVE_ASSET,
   ASSET_ADD_ASSET_CLASS,
   ASSET_UPDATE_ASSET_CLASS,
   ASSET_UPDATE_ONE_ASSET_CLASS,
   ASSET_REMOVE_ASSET_CLASS,
   ASSET_ADD_ATTR_ASSET_CLASS,
   ASSET_UPDATE_ATTR_ASSET_CLASS,
   ASSET_REMOVE_ATTR_ASSET_CLASS,
   ASSET_ADD_ATTR_ASSET,
   ASSET_UPDATE_ATTR_ASSET,
   ASSET_DELETE_ATTR_ASSET,
   ASSET_LOAD_ICON_SUCCEED,
   ASSET_LOAD_ICON_FAILED,
   USER_GET_FAILED,
   GET_CALCULATE_ATTRIBUTE_SUCCESS,
   GET_CALCULATE_ATTRIBUTE_FAILED,
   GET_CALCULATE_RESULT_SUCCESS,
   GET_CALCULATE_RESULT_FAILED,
   GET_LATEST_CALCULATE_RESULT_SUCCESS,
   GET_LATEST_CALCULATE_RESULT_FAILED, ASSET_LOAD_DEVMALIST_FAILED,
   ASSET_LOAD_CAMERA_LIST_SUCCEED, ASSET_LOAD_CAMERA_LIST_FAILED,
   ASSET_LOAD_SENSOR_LIST_SUCCEED, ASSET_LOAD_SENSOR_LIST_FAILED,

} from '../../constants/actionTypes';
import {
   WEB_SERVICE_LOCAL,
   WEB_SERVICE_USER_SUCCESS_CODE,
   WEB_SERVICE_ROOT,
   WEB_SERVICE_USER_ROOT,
   WEB_SERVICE_DEVICE_NAME,
   WEB_SERVICE_DEVICE_ACTIVE,
   WEB_SERVICE_GET_CALCULATE_ATTRIBUTE,
   WEB_SERVICE_POST_CALCULATE_ATTRIBUTE,
   WEB_SERVICE_RESET_CALCULATE_ATTRIBUTE,
   WEB_SERVICE_UPDATE_CALCULATE_ATTRIBUTE,
   WEB_SERVICE_UPDATE_BATCH_CALCULATE_ATTRIBUTE,
   WEB_SERVICE_DEL_CALCULATE_ATTRIBUTE,
   WEB_SERVICE_GET_CALCULATE_RESULT,
   WEB_SERVICE_GET_LATEST_CALCULATE_RESULT
} from '../../constants/appConstants';
// import {base64Encode} from '../../factories/utils';
import { axiosGet, axiosPost, axiosDelete, getCurrentUser, base64Encode, getJwttoken } from '../../factories/utils';
import moment from "moment";
// const token = getJwttoken();
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
axios.interceptors.request.use(function (config) {
   const token = getJwttoken();
   config.headers.Authorization = `Bearer ${token}`;
   return config;
});

// 2.7   Server
// 2.103 Yan
// const apiUrl = 'http://13.88.246.7:8080/sigfox'
const apiUrl = WEB_SERVICE_ROOT.slice(0, -1); //str.substring(0, str.length - 1);

// const assetRoute = 'http://192.168.2.103:8080/Assets';             // NEW
// const assetRoute = 'http://13.88.246.7:8080/sigfox/Assets';
const assetRoute = `${WEB_SERVICE_ROOT}Assets`;
// const assetClassRoute = 'http://192.168.2.103:8080/AssetClass';    // NEW
// const assetClassRoute = 'http://13.88.246.7:8080/sigfox/AssetClass';
const assetClassRoute = `${WEB_SERVICE_ROOT}AssetClass`;
// const assetClassRoute = 'http://192.168.2.91:8080/AssetClassN?assetclassid=2';    // NEW
// const assetClassRoute = 'http://192.168.2.91:8080/AssetClassN';    // NEW
// 291:8080/
// const assetClassAttributeMetaRoute = 'http://13.88.246.7:8080/sigfox/Attributemeta';
const assetClassAttributeMetaRoute = `${WEB_SERVICE_ROOT}Attributemeta`;
// const assetAssetAttributeRoute = 'http://13.88.246.7:8080/sigfox/Assetattribute';
const assetAssetAttributeRoute = `${WEB_SERVICE_ROOT}Assetattribute`;
// const iconRoute = 'http://13.88.246.7:8080/sigfox/Icon';
const iconRoute = `${WEB_SERVICE_ROOT}Icon`;

const attributemetaRoute = 'http://192.168.2.39:8080/Assetattribute'
const attributeMetaRoute = '/Attributemeta'

const urlCameraList = `${WEB_SERVICE_ROOT}CameraList`;
const urlSensorList = `${WEB_SERVICE_ROOT}SensorList`;



export function loadCameraList() {
   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {
      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, please re-login",
            message: "Failed to get current user, please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return ((dispatch) => axios({
      url: `${urlCameraList}`,
      method: 'get',
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
   }).then(res => {
      dispatch({
         type: ASSET_LOAD_CAMERA_LIST_SUCCEED,
         payload: res.data.data
      });
   }).catch(err => {
      dispatch({
         type: ASSET_LOAD_CAMERA_LIST_FAILED,
         payload: err.message
      })
   }))
}

export function loadSensorList() {
   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {
      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, please re-login",
            message: "Failed to get current user, please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return ((dispatch) => axios({
      url: `${urlSensorList}`,
      method: 'get',
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
   }).then(res => {
      dispatch({
         type: ASSET_LOAD_SENSOR_LIST_SUCCEED,
         payload: res.data.data
      });
   }).catch(err => {
      dispatch({
         type: ASSET_LOAD_SENSOR_LIST_FAILED,
         payload: err.message
      })
   }))
}

// Asset => devices All at once

// ?assetid = ... (optional)
export function loadAssets() {
   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {


      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, please re-login",
            message: "Failed to get current user, please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return dispatch => axios({
      url: assetRoute,
      method: 'get',
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
   }).then(res => {
      dispatch({
         type: ASSET_LOAD_ASSET_SUCCEED,
         payload: res.data
      })
   }).catch(err => {
      dispatch({
         type: ASSET_LOAD_ASSET_FAILED,
         payload: err.message
      })
   })
}

export function loadClasses() {
   // name
   // description
   // device types

   //

   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {


      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, Please re-login",
            message: "Failed to get current user, Please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return dispatch => axios({
      url: assetClassRoute,
      method: 'get',
      // headers: {
      //       'Content-Type': 'application/json',
      //       'uTk': base64Encode('Admin'),
      // },
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
   }).then(res => {
      dispatch({
         type: ASSET_LOAD_CLASS_SUCCEED,
         payload: res.data
            .map(({ assetclassid, assetclassname, devicetypes, assetclassattrmetadata, ...a }) => ({
               id: assetclassid,
               name: assetclassname,
               allowedDeviceTypes: devicetypes.map(dt => ({
                  id: dt.devicetypeid,
                  name: dt.devicetypename
               })),
               attributeMetas: assetclassattrmetadata,
               ...a
            }))
      })
   }).catch(err => {
      dispatch({
         type: ASSET_LOAD_CLASS_FAILED,
         payload: err.message
      })
   })

   const mockAssetClassData = [
      {
         id: 1, name: "Class 1", description: 'Description of a class',
         allowedDeviceTypes: [],
         assets: [],
         attributeMetas: [
            { attributemetaid: 1, assetclassid: 1, name: 'City', type: 'num', active: 'y' },
            { attributemetaid: 2, assetclassid: 1, name: 'Country', type: 'num', active: 'y' },
         ]
      }
   ]
   let res = mockAssetClassData
   return dispatch => {
      dispatch({
         type: ASSET_LOAD_CLASS_SUCCEED,
         payload: res
      })
   }
}

export function postAsset(data) {

   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {


      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, Please re-login",
            message: "Failed to get current user, Please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   //    if (data.devices) data.devices = data.devices.map(({ id }) => ({ deviceid: id }));
   // if (data.devices) data.devices = data.devices.map(({ deviceid }) => ({ deviceid: deviceid }));
   if (data.devices) {
      let filteredDevice = data.devices.map(({ id }) => ({ deviceid: id }));

      if (!filteredDevice || filteredDevice.length === 0 || !filteredDevice[0].deviceid) {
         filteredDevice = data.devices.map(({ deviceid }) => ({ deviceid: deviceid }));
      }
      data.devices = filteredDevice;
   }

   console.log(data)
   return dispatch => axios({
      url: assetRoute,
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
      method: 'post',
      data: data
   }).then(res => {
      if (res.status === 200) {
         dispatch(loadClasses());
      }

      return res;
   }).catch(err => {
      console.error(err)
      return err.response;
   })
}
export function deleteAsset(data) {
   return dispatch => axios({
      url: assetRoute + '?assetid=' + data.assetid,
      method: 'delete'
   }).then(res => {
      if (res.status === 200) {
         dispatch(loadClasses());
      }
      return res;
   }).catch(err => {
      console.log(err)
   })
}

export const resetCalculateAttribute = (assetid) => {
   let criteria = null;
   if (assetid != undefined && assetid != null) {
      criteria = {
         assetId: assetid
      }
   }
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_RESET_CALCULATE_ATTRIBUTE}`,
      criteria: criteria,
      logText: 'Reset calculate attributes.',
      errorMessage: 'Failed to reset calculate attributes.'
   };

   return (axiosGet(paramObj));
}

export const deleteCamera = (assetid) => (dispatch) => {

   let url = `${assetRoute}?assetid=${assetid}`;
   axios.delete(url).then(res => {
      if (res.status == 200) {
         dispatch(removeFromAssets(assetid))
         dispatch(loadClasses());
      } else {
         console.log(res.data.error)
      }
   })
}


export function postAssetClassDT(data) {
   let userObj = getCurrentUser();
   //if unauthenticated
   if (userObj === null || userObj.userName.length === 0) {


      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, Please re-login",
            message: "Failed to get current user, Please re-login",
         });
      }
   }
   //getting user information
   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return dispatch => axios({
      url: assetClassRoute + "_DT",  //https://waiviot.itsp-inc.com:8443/sigfox-test/AssetClass
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)), //return '' -> empty for now
      },
      method: 'post',
      data: data
   }).then(res => {
      if (res.status === 200) {

         if (data.assetclassid !== res.data.assetclassid) {

            let assetMapObj = {
               userId: userName,
               classid: res.data.assetclassid
            }
            dispatch(addAssetClassToUser(assetMapObj));
         }
      }
      let { assetclassid, classname, devicetypes, attributemetas, asseticon, ...rest } = res.data
      let { iconscale, ...iconData } = asseticon
      let storeData = {
         id: assetclassid,
         name: classname,
         allowedDeviceTypes: devicetypes.map(dt => ({
            id: dt.devicetypeid,
            name: dt.devicetypename
         })),
         attributeMetas: attributemetas,
         ...iconData,
         ...rest
      }
      return storeData
   }).catch(err => {
      console.log(err)
      return (err.response);
   })
}

export function addAssetClassToUser(assetMapObj) {

   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {


      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, Please re-login",
            message: "Failed to get current user, Please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return dispatch => axios({
      // url: 'http://192.168.2.7:8444/addAssetclassMap',
      url: `${WEB_SERVICE_USER_ROOT}addAssetclassMap`,
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
      method: 'post',
      data: assetMapObj
   }).then(res => {
      if (res.data.error === WEB_SERVICE_USER_SUCCESS_CODE) {

         toast.success("Successfully Added Asset Class for the User ");
      } else {
         console.error(response.data.error);
         toast.error(" Failed to Add Asset Class for the User");
      }
   }).catch(err => {

      toast.error(" Failed to Add Asset Class for the User");
   })
}

export function postAssetClassAL(data) {

   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {


      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, Please re-login",
            message: "Failed to get current user, Please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return dispatch => axios({
      url: assetClassRoute + "_AL",
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
      method: 'post',
      data: data
   }).then(res => {
      let { assetclassid, classname, devicetypes, attributemetas, asseticon, ...rest } = res.data
      let { iconscale, ...iconData } = asseticon
      let storeData = {
         id: assetclassid,
         data: {
            name: classname,
            allowedDeviceTypes: devicetypes.map(dt => ({
               id: dt.devicetypeid,
               name: dt.devicetypename
            })),
            attributeMetas: attributemetas,
            ...iconData,
            ...rest
         }
      }
      return storeData
   }).catch(err => {
      console.error(err);

      return err.response;
   })
}

export function deleteAssetClass(data) {
   let userObj = getCurrentUser();
   if (userObj === null || userObj.userName.length === 0) {


      return dispatch => {
         dispatch({
            type: USER_GET_FAILED,
            error: "Failed to get current user, Please re-login",
            message: "Failed to get current user, Please re-login",
         });
      }
   }

   let { userName, companyCode, role } = userObj;
   let userTokenObj = { userName, companyCode, role };

   return dispatch => axios({
      url: assetClassRoute + '?assetclassid=' + data.assetclassid,
      method: 'delete',
      headers: {
         'Content-Type': 'application/json',
         'uTk': base64Encode(JSON.stringify(userTokenObj)),
      },
   }).then(res => {

      console.log(res)
      return res
   }).catch(err => {


      toast.error(err.message);
   })
}

export function postAssetClassAttributemeta(data) {
   return dispatch => axios({
      url: assetClassAttributeMetaRoute,
      method: 'post',
      data: data
   }).then(res => {

      console.log(res)
      return res.data
   }).catch(err => {

   })
}

export function deleteAssetClassAttributemeta(data) {
   return dispatch => axios({
      url: assetClassAttributeMetaRoute + '?attributemetaid=' + data.attributemetaid,
      method: 'delete'
   }).then(res => {

      console.log(res)
      return res
   }).catch(err => {


      toast.error(err.message);
   })
}
//
export function postAssetAttribute(data) {
   let dataWithUser = {
      user: sessionStorage.getItem("currentUser"),
      companyCode: sessionStorage.getItem("companyCode"),
      ...data
   }
   return dispatch => axios({
      url: assetAssetAttributeRoute,
      method: 'post',
      data: dataWithUser
   }).then(res => {

      // console.log(res)
      return res.data
   }).catch(err => {

      toast.error(err.message);
   })
}

export function postFaceAttribute(data) {

   console.log('postfaceattr---->', data)

   return dispatch => axios({
      url: '',
      method: 'post',
      data: data
   }).then(res => {

      console.log(res)
      return res.data
   }).catch(err => {

      toast.error(err.message);
   })
}

export function postVisitAttribute(data) {

   console.log('postfaceattr---->', data)

   return dispatch => axios({
      url: '',
      method: 'post',
      data: data
   }).then(res => {

      console.log(res)
      return res.data
   }).catch(err => {

      toast.error(err.message);
   })
}
// --------------------------------------------------------
export function deviceNameChangeAjax(deviceObj) {
   return dispatch => axios({
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_DEVICE_NAME}`,
      method: 'post',
      data: deviceObj
   }).then(res => {
      return res;
   }).catch(err => {
      return err
   })
}
export function deviceActiveAjax(deviceid, status) {
   // const param = {deviceid, status}
   const queryStr = `?deviceid=${deviceid}&status=${status}`;
   return dispatch => axios({
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_DEVICE_ACTIVE}${queryStr}`,
      method: 'get',
      // data: param
   }).then(res => {
      return res;
   }).catch(err => {
      return err
   })
}

export function uploadAttributeImage(assetid, attributeid, file) {
   let params = new FormData();
   params.append('assetid', assetid);
   params.append('attmetaid', attributeid);
   params.append('image', file);
   return (
      dispatch => axios({
         url: `${assetRoute}/uploadimage`,
         method: 'post',
         headers: {
            // 'Content-Type': 'multipart/form-data' ,
            "Accept": "application/json",
            "type": "formData"
         },
         data: params
      }).then(res => {

         return res
      }).catch(err => {
         console.err(err);
         return err
      })

   )
}
export function getCalculateAttribute(attObj) {
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_CALCULATE_ATTRIBUTE}`,
      criteria: attObj,
      logText: 'Post calculate attribute Url ',
      sccessType: GET_CALCULATE_ATTRIBUTE_SUCCESS,
      failedType: GET_CALCULATE_ATTRIBUTE_FAILED,
      errorMessage: 'Failed to update calculate attribute.',
      successMessage: 'Successfully create/update a calculate attribute.',
   };

   return (axiosGet(paramObj));
}
export function updateCalculateAttribute(attObj) {
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_UPDATE_CALCULATE_ATTRIBUTE}`,
      criteria: attObj,
      logText: 'Update calculate attribute Url ',
      errorMessage: 'Failed to update calculate attribute.',
      successMessage: 'Successfully create/update a calculate attribute.',

   };

   return (axiosPost(paramObj));
}
export function updateBatchCalculateAttribute(attObj) {
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_UPDATE_BATCH_CALCULATE_ATTRIBUTE}`,
      criteria: attObj,
      logText: 'Update calculate attribute Url ',
      errorMessage: 'Failed to update calculate attribute.',
      successMessage: 'Successfully create/update a calculate attribute.',

   };

   return (axiosPost(paramObj));
}
export function upsertCalculateAttribute(attObj) {
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_POST_CALCULATE_ATTRIBUTE}`,
      //  dataObject: attObj,
      criteria: attObj,
      //  startType: '',
      //  sccessType: '',
      //  failedType: '',
      logText: 'Post calculate attribute Url ',
      errorMessage: 'Failed to update calculate attribute.',
      successMessage: 'Successfully create/update a calculate attribute.',
      //  successCallback: () => { return(loadClasses())},
      // errorCallback: (msg) => {toast.error(msg)}
      //  responseKeyGetter: (response) => (response.data),
      //  successContent: true,
   };

   return (axiosPost(paramObj));
   // axiosPost(paramObj);
}

export function deleteCalculateAttribute(attObj) {
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_DEL_CALCULATE_ATTRIBUTE}`,
      dataObject: attObj,
      // criteria: {templateid: templateId},
      logText: 'Delete calculate attribute Url ',
      errorMessage: 'Failed to delete calculate attribute.',
      successMessage: 'Successfully deleted the calculate attribute.',
      // callback: () => { return(getNotificationTemplate())},
      // errorCallback: (msg) => {toast.error(msg)}
   };

   return (axiosDelete(paramObj));
}
// --------------------------------------------------------
export function getCalculateResult(obj) {
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_CALCULATE_RESULT}`,
      criteria: obj,
      logText: 'Post calculate result Url ',
      sccessType: GET_CALCULATE_RESULT_SUCCESS,
      failedType: GET_CALCULATE_RESULT_FAILED,
      errorMessage: 'Failed to get calculate result.',
      successMessage: 'Successfully got the calculate results.',
   };

   return (axiosPost(paramObj));
   // axiosPost(paramObj);
}
export function getLatestCalculateResult(obj) {
   let paramObj = {
      url: `${WEB_SERVICE_ROOT}${WEB_SERVICE_GET_LATEST_CALCULATE_RESULT}`,
      criteria: obj,
      logText: 'Post calculate result Url ',
      sccessType: GET_LATEST_CALCULATE_RESULT_SUCCESS,
      failedType: GET_LATEST_CALCULATE_RESULT_FAILED,
      errorMessage: 'Failed to get calculate result.',
      successMessage: 'Successfully got the calculate results.',
   };

   return (axiosPost(paramObj));
   // axiosPost(paramObj);
}
// --------------------------------------------------------
const assetClassTypeParams = {
   assetclassid: PropTypes.string.isRequired,
   assetclassname: PropTypes.arrayOf(PropTypes.number)
}
let a = {
   "assetclassid": 1,                                      //required   ，0 or not exist in database will create new assetclass record,  or will update
   "assetclassname": "pet",                     //required
   "description": null,   //required
   "iconid": 3,            //required if modify icon, if dont change icon,just remove this property or give 0
   "devicetypes": [
      {
         "assetdevicetypeid": 2,
         "assetclassid": 1,                                             //optional
         "assetclassname": "pet",
         "devicetypeid": "5d1f5bd8c563d62829dcadb6",   //required
         "devicetypename": "Alps Demo Units"                 //required
      }
   ]
}

const deviceTypeParams = {
   deviceid: PropTypes.string,
   devicename: PropTypes.string,
   devicetype: PropTypes.string,
   devicenamealias: PropTypes.string,
   parentdeviceid: PropTypes.string,
   hasparent: PropTypes.string,
   subdevices: PropTypes.arrayOf(PropTypes.object),
   deviceattributes: PropTypes.arrayOf(PropTypes.Object),
}

const assetTypeParams = {
   assetid: PropTypes.number,
   assetname: PropTypes.string,
   assetclassid: PropTypes.number,
   assetclassname: PropTypes.string,
   description: PropTypes.string,
   devices: PropTypes.arrayOf(PropTypes.object),
   assetattributes: PropTypes.arrayOf(PropTypes.object),
}

const formatSelVals = [
   { label: 'Boolean', value: 'boolean' },
   { label: 'Date', value: 'date' },
   { label: 'List', value: 'list' },
   { label: 'Text', value: 'string' },
   { label: 'Number', value: 'num' }]
const addUpdateAttributeMetaPropType = {
   payload: PropTypes.shape({
      id: PropTypes.number.isRequired,
      data: PropTypes.shape({
         attributemetaid: PropTypes.number,
         name: PropTypes.string,
         type: PropTypes.oneOf(formatSelVals.map(e => e.value)),
      })
   })
}
export const updateAssetClassAttribute = (payload) => dispatch => dispatch({ type: ASSET_UPDATE_ATTR_ASSET_CLASS, payload })
export const addAssetClassAttribute = (payload) => dispatch => dispatch({ type: ASSET_ADD_ATTR_ASSET_CLASS, payload })
updateAssetClassAttribute.propTypes = addUpdateAttributeMetaPropType
addAssetClassAttribute.propTypes = addUpdateAttributeMetaPropType
export const deleteAssetClassAttribute = (payload) => dispatch => dispatch({ type: ASSET_REMOVE_ATTR_ASSET_CLASS, payload })
deleteAssetClassAttribute.propTypes = {
   payload: PropTypes.shape({
      id: PropTypes.number.isRequired,
      key: PropTypes.string
   })
}

const addUpdateAttributePropType = {
   payload: PropTypes.shape({
      id: PropTypes.number.isRequired,
      data: PropTypes.shape({
         id: PropTypes.number.isRequired,
         data: PropTypes.object
      })
   })
}

export const addAssetAttribute = (payload) => dispatch => dispatch({ type: ASSET_ADD_ATTR_ASSET, payload })
export const updateAssetAttribute = (payload) => dispatch => dispatch({ type: ASSET_UPDATE_ATTR_ASSET, payload })
addAssetAttribute.propTypes = addUpdateAttributePropType
updateAssetAttribute.propTypes = addUpdateAttributePropType
export const deleteAssetAttribute = (payload) => dispatch => dispatch({ type: ASSET_DELETE_ATTR_ASSET, payload })
deleteAssetAttribute.propTypes = {
   payload: PropTypes.shape({
      id: PropTypes.number.idRequired,
      attrid: PropTypes.number.isRequired
   })
}
/// header: 'City', key: 'city', value: "Toronto", format: 'text', description: ''

export const updateAssets = (payload) => dispatch => dispatch({ type: ASSET_UPDATE_ASSET, payload })
export const updateAssetClasses = (payload) => dispatch => dispatch({ type: ASSET_UPDATE_ASSET_CLASS, payload })

export const updateOneAsset = (payload) => dispatch => dispatch({ type: ASSET_UPDATE_ONE_ASSET, payload })
updateOneAsset.propTypes = {
   payload: PropTypes.shape({
      id: PropTypes.number.isRequired,
      data: PropTypes.shape(assetTypeParams)
   })
}

export const updateOneAssetClass = (payload) => dispatch => dispatch({ type: ASSET_UPDATE_ONE_ASSET_CLASS, payload })
updateOneAssetClass.propTypes = {
   payload: PropTypes.shape({
      id: PropTypes.number.isRequired,
      data: PropTypes.shape(assetClassTypeParams)
   })
}

export const addToAssets = (payload) => dispatch => dispatch({ type: ASSET_ADD_ASSET, payload })
export const addToAssetClasses = (payload) => dispatch => dispatch({ type: ASSET_ADD_ASSET_CLASS, payload })
addToAssets.propTypes = {
   payload: PropTypes.shape(assetTypeParams)
}
addToAssetClasses.propTypes = {
   payload: PropTypes.shape(assetClassTypeParams)
}


/////////
// WIP //
/////////

export function loadIcons() {
   return dispatch => axios({
      url: iconRoute,
      method: 'get',
   }).then(res => {
      dispatch({
         type: ASSET_LOAD_ICON_SUCCEED,
         payload: res.data
      })
   }).catch(err => {
      dispatch({
         type: ASSET_LOAD_ICON_FAILED,
         payload: err.message
      })
   })
}

export const deleteIcon = icon => dispatch =>{
   console.log('delete', icon.iconname);
   let url = `${iconRoute}?id=${icon.iconid}`
   axios.delete(url).then(res => {
      console.log(res)
      if(res.status == 200) {
         dispatch(removeIcon(icon.iconid))
         toast.success('Icon deleted successfully.')
         dispatch(loadIcons());
      }
   }).catch(err=>{
      toast.error('Icon is being used. Cannot delete')
   })
}


export const removeFromAssets = (payload) => dispatch => dispatch({ type: ASSET_REMOVE_ASSET, payload })
export const removeFromAssetClasses = (payload) => dispatch => dispatch({ type: ASSET_REMOVE_ASSET_CLASS, payload })
export const removeIcon = (payload) => dispatch => dispatch({ type: ASSET_DELETE_ICON, payload })
removeFromAssets.propTypes = {
   payload: PropTypes.number
}
removeFromAssetClasses.propTypes = {
   payload: PropTypes.number
}

