/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label } from 'recharts';
import { renderSpinner } from './CarCountBoard';
import moment from 'moment';
import Select from 'react-select';
import { Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody } from 'reactstrap';
import { groupByArray } from '../../../../factories/utils';
import { Camera } from '@material-ui/icons';
import { getCurrentUser, hasRole } from '../../../../factories/auth';
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import BootstrapTable from 'react-bootstrap-table-next';
import VideoMpegPlayer from '../VideoMpegPlayer';
import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import ReactPlayer from '../ReactPlayer';
import { Client } from '@stomp/stompjs';
import NVRPlay from '../videoPlayer/nvr/NVRPlay';


// import './SmartBuilding.css'




export default function IndividualCamChart({ camData, loadCarCount, nvrIP, nvrChannelList, httpPort, ccNVR }) {
    // const [LocalData, setLocalData] = useState(localData)
    const User = getCurrentUser();
    const [CameraChannel, setCameraChannel] = useState(null);
    const [CameraName, setCameraName] = useState(null);
    const [CameraIP, setCameraIP] = useState(null);
    const [NVRChannel, setNVRChannel] = useState(null)
    const [FilterDate, setFilterDate] = useState(moment(new Date()).format('MM-DD-YYYY'));
    const [SelectStyle, setSelectStyle] = useState({
        menu: (provided, state) => ({
            ...provided,
            width: "100%",
            cursor: "pointer",
            zIndex: 9999
        }),
        option: (provided, state) => ({
            ...provided,
            color: "black",
            width: "100%",
            cursor: "pointer",
            '&:active': { backgroundColor: user.detail.appButtonColorHover },
            backgroundColor: state.isFocused ? user.detail.appButtonColorHover : "white",
        }),
        control: (base, selectState) => ({
            ...base,
            // height: 35,
            // minHeight: 35
            width: "100%",
            height: 35,
            minHeight: 35,
            cursor: "pointer",
            borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor,
            // This line disable the blue border
            boxShadow: selectState.isFocused ? 0 : 0,
            '&:hover': { borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor }
        }),
        indicatorsContainer: (base, selectState) => ({
            ...base,
            padding: "0",
        }),
    })
    const [SelectedCam, setSelectedCam] = useState(null)
    const [CameraOptions, setCameraOptions] = useState(null)
    const [CamData, setCamData] = useState(null)
    const [FilteredCamData, setFilteredCamData] = useState(null)
    const user = getCurrentUser();
    useEffect(() => {
        let groupedDataByCam
        if (camData) {
            setNVRChannel(camData.nvr)
            setCameraChannel(camData.cameraChannel)
            setCameraName(camData.camera?.name)
            setCameraIP(camData.cameraIp)
        } else {
            //set render live feed info data to null
            setNVRChannel(null)
            setCameraChannel(null)
            setCameraName(null)
            setCameraIP(null)
        }
    }, [camData])

    const renderLiveFeedComponent = () => {
        if(httpPort && nvrIP && nvrChannelList && nvrChannelList.length > 0){
            return (
                <NVRPlay
                type={'live'}
                nvrChannelList={nvrChannelList}
                nvrIP={nvrIP}
                camNumber={CameraChannel}
                camName={CameraName}
                port={httpPort}
                nvr={ccNVR}/>
            )

        } else {
            return (<ReactPlayer
                type="cc"
                ch={NVRChannel}
                chid={CameraChannel}
                height="400px"
                width="100%" />)
        }
    }

    return (
        <>
            <Row>
                <Col md={6}>
                    <p className="footage-header">Real Time Footage</p>
                </Col>
            </Row>
            <Row>
                <Col md={2}>
                </Col>
                <Col md={8}>
                    {renderLiveFeedComponent()}
                </Col>
                <Col md={2}>
                </Col>
            </Row>
        </>
    )
}