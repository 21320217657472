/* eslint-disable */
import React from 'react';

import { Col, Container, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import WeatherMainBoard from './components/WeatherMainBoard';
import HistoryBoard from './components/HistoryBoard';
import MainBoard from './components/MainBoard';
import { getCurrentUser } from '../../factories/auth';
import {UserContext} from '../../constants/myContexts';

const WeatherPage = (props) => {
  // let userObj = getCurrentUser();
  let {userObj} = React.useContext(UserContext);
  if (!userObj) {
    toast.error('Can not fetch login user information, Please re-login.');
    props.history.push('/log_out');
  } else {
     
  }

  // let [showHistory, setShowHistory] = React.useState(false);
  
  // const toggle = (toggle) => {
  //   if (toggle != undefined && toggle != null && typeof toggle === 'boolean'){
  //     setShowHistory(toggle);
  //   }else{
  //     setShowHistory(!showHistory);
  //   }
    
  // }

  return (
    <div className="dashboard" style={{padding: '15px', margin:'15px'}}>
      <Row>
        <Col md={12}>
          <h2 className="page-title">Weather Management and Lightning Warning System</h2>
        </Col>
      </Row>
      {/* <Row>
        {
          showHistory ?
          <HistoryBoard userObj = {userObj} toggle={toggle}/>
          :
          <WeatherMainBoard userObj = {userObj} toggle={toggle}/>          
        }
      </Row> */}
      {/* <Row style={{width: '100%', justifyContent:'center', margin: 0}}> */}
      {/* <Row style={{margin: '0px'}}>
        <Col md={12}> */}
        {/* <div> */}
        
        <MainBoard userObj = {userObj}/>
        {/* </div> */}
        {/* </Col>
      </Row> */}
    </div>
  );
};

export default withRouter(WeatherPage);

