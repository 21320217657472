/* eslint-disable */
import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';

import { Card, CardBody, Button, Collapse, Row, Col } from 'reactstrap';

import ReportsList from './ReportsList';
import ReportAdd from './ReportAddComponent';
import {loadReports, deleteOneReportAjex, deactiveOneReportAjex, activeOneReportAjex, getOneReport, upsertOneReport} from '../../../redux/actions/reportActions';

let testData = []
for (let i = 0; i < 26; i++) {
  testData.push({
    name: String.fromCharCode(65 + i),
    data: 100000 + i,
    periodicity: "2016-03-" + (i < 10 ? '0' + (i + 1) : (i + 1)),
  })
}

let buttonStyle = {
  width: 100,
  zIndex: 7,
  float: 'right',
}

class ReportsBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // showReportAdd: false,
      collapse: false,
      showReportAdd: true,
      listComponentHeight: '80vh',
    }
  }

  componentDidMount(){
    this.props.loadReports();
  }

  toggle = () => {
    // Reset state of ReportAdd Component
    this.state.collapse ?
      this.setState({
        collapse: !this.state.collapse,
      }) :
      this.setState({
        showReportAdd: true,
      }, () => this.setState({
        collapse: !this.state.collapse
      }))
  }
  setListHeight = (h) => {
    this.setState({ listComponentHeight: h })
  }

  onEntering = () => {
    this.setState({ showReportAdd: true })
  }
  onExited = () => {
    this.setState({ showReportAdd: false })
  }
  handleReportDeleteOnClick = (e, id) => {
    this.props.deleteOneReportAjex(id);
    // this.props.deleteOneReportAjex(id).then(
    //   res => {this.props.loadReports()}
    // );
  }

  handleReportEditOnClick = (e, id) => {
     
    this.props.getOneReport(id);
    this.toggle();
  }

  handleReportAddNewOnClick = (e) => {
    if (!this.state.collapse){
      this.props.getOneReport();
    }
    this.toggle();
  }

  render() {
    let toggleButton = (label, style, onClick) => (
      <Button color='primary' style={{ ...buttonStyle, ...style }} onClick={onClick}>{label}</Button>
    )
     
     
     
    return (
      <>
        <Row>
          <Col md={12} className='flex'>
            <h3 className="page-title">
              Reports
              <Button color='success' style={buttonStyle} onClick={this.handleReportAddNewOnClick}>
                {this.state.collapse ? 'Cancel' : 'Create'}
              </Button>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Collapse isOpen={this.state.collapse}
              onEntering={this.onEntering}
              onExited={this.onExited}
            >
              {this.state.showReportAdd &&
                <ReportAdd
                  height={this.state.listComponentHeight}
                  toggle={this.toggle}
                  gerneralPart = {this.props.currentGeneralSection}
                  sectionList = {this.props.currentSectionList}
                  reportId = {this.props.currentReportId}
                  upsertOneReport = {this.props.upsertOneReport}
                />
              }
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Collapse isOpen={!this.state.collapse}>
              <ReportsList
                reports={this.props.reportList} 
                setListHeight={this.setListHeight}
                handleReportDeleteOnClick = {this.handleReportDeleteOnClick}
                handleReportEditOnClick = {this.handleReportEditOnClick}
              />
            </Collapse>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => { 
  const props = {...state.report}
  return props;
}
  
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({loadReports, deleteOneReportAjex, deactiveOneReportAjex, activeOneReportAjex, getOneReport, upsertOneReport }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsBoard)
