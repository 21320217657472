/* eslint-disable */
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Card, CardBody } from "reactstrap";
import { hasRole } from "../../../factories/auth";
import { ManageTextButton } from "../../../shared/components/Button/IconButton";
import ConfirmDeleteDialog from "../../../shared/components/Dialog/ConfirmationDialog";
import { sortCaret } from "../../../shared/components/Table/tableElements";
import ConsumerAddModal from "./ConsumerAddModal";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getProvinces
} from '../../../redux/actions/userAction';

class ConsumerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      operationCountryList: [],
    };
  }
  componentDidMount() {
    let cval = this.props.countryList[0] ? this.props.countryList[0].value : "";
    if (cval) {
      this.props.getProvinces(cval);
    }
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      isAdmin: nextProps.isAdmin,
    });
  }

  nameFormatter = (cell) => {
    if (cell != null) {
      return <span style={{ fontWeight: "bold" }}>{cell}</span>;
    }
  };
  countryFormatter = (cell) => {
    if (cell != null) {
      return `${cell.country.name}`;
    }
  };
  LOBFormatter = (cell) => {
    if (cell != null) {
      return cell.lineOfBusiness.name;
    }
  };
  divisionFormatter = (cell) => {
    if (cell != null) {
      return cell.division.name;
    }
  };

  tableActionColFormater = (cell, row) => {
    return (
      <div>
        <ManageTextButton
          label={""}
          onClick={(e) => {
            this.handleRowEditClick(row);
          }}
        />{" "}
      </div>
    );
  };
  handleRowEditClick = (row) => {
    this.props.handleRowEditClick(row);
  };

  tableActionColFormaterDelete = (cell, row) => {
    return (
      <div>
        <ConfirmDeleteDialog
          label={""}
          onConfirm={(e) => {
            this.handleRowDeleteClick(row);
          }}
          msg={"Do you want to delete the select Consumer ? "}
        />
      </div>
    );
  };
  render() {
    const check =
      this.props.userObj &&
      hasRole(this.props.userObj, [
        "SUPERADMIN",
        "ADMIN",
        "CLIENT",
        "CLIENTADMIN",
      ]);
    const options = {
      insertBtn: this.props.addNewUserButton,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "500",
          value: 500,
        },
      ],
    };
    var customerList = this.props.consumerList.map((customerView) => {
      if (customerView.customer === null) {
        return {};
      }

      let nameData = "";
      let resellerData = "";
      let countryData = "";
      let lineOfBusinessData = "";
      let divisionData = "";
      let customerData = "";
      let usernameData = "";

      if (
        customerView != null &&
        customerView.customer != null &&
        customerView.customer.businessName != null &&
        customerView.customer.businessName != ""
      ) {
        nameData = customerView.customer.businessName;
      } else {
        if (
          customerView != null &&
          customerView.customer != null &&
          customerView.customer.firstName != null &&
          customerView.customer.firstName != ""
        ) {
          nameData = customerView.customer.firstName;
        }
      }

      if (
        customerView != null &&
        customerView.resellerName != null &&
        customerView.resellerName != ""
      ) {
        resellerData = customerView.resellerName;
      }

      if (
        customerView != null &&
        customerView.customer != null &&
        customerView.customer.businessUnit != null &&
        customerView.customer.businessUnit.country != null &&
        customerView.customer.businessUnit.country.name != null
      ) {
        countryData = customerView.customer.businessUnit.country.name;
      }

      if (
        customerView != null &&
        customerView.customer != null &&
        customerView.customer.businessUnit != null &&
        customerView.customer.businessUnit.lineOfBusiness != null &&
        customerView.customer.businessUnit.lineOfBusiness.name != null
      ) {
        lineOfBusinessData =
          customerView.customer.businessUnit.lineOfBusiness.name;
      }

      if (
        customerView != null &&
        customerView.customer != null &&
        customerView.customer.businessUnit != null &&
        customerView.customer.businessUnit.division != null &&
        customerView.customer.businessUnit.division.name != null
      ) {
        divisionData = customerView.customer.businessUnit.division.name;
      }

      if (customerView != null && customerView.customer != null) {
        customerData = customerView.customer;
      }

      if (customerView != null && customerView.userName != null) {
        usernameData = customerView.userName;
      }

      return {
        name: nameData,
        /*customerView.customer.businessName != null &&
                customerView.customer.businessName != ""
                  ? customerView.customer.businessName
                  : customerView.customer.firstName*/ resellerName: resellerData /*customerView.resellerName*/,
        country: countryData,
        /*customerView.customer.businessUnit != null
                  ? customerView.customer.businessUnit.country.name
                  : ""*/ lineOfBusiness: lineOfBusinessData,
        /*customerView.customer.businessUnit != null
                  ? customerView.customer.businessUnit.lineOfBusiness.name
                  : ""*/ division: divisionData,
        /*customerView.customer.businessUnit != null
                  ? customerView.customer.businessUnit.division.name
                  : ""*/ customer: customerData /*customerView.customer*/,
        username: usernameData /*customerView.userName*/,
      };
    });

    const { pageContentList } = this.props;

    let textDisplay = [];
    if (pageContentList.includes("Reseller")) {
      textDisplay = ["Reseller"];
    } else {
      textDisplay = ["Client"];
    }

    return (
      <Card>
        <CardBody>
          <ConsumerAddModal
            show={this.props.showAddModal}
            countryList={this.props.countryList}
            provinceList={this.props.provinceList}
            getProvinces={this.props.getProvinces}
            userObj={this.props.userObj}
            toggle={this.renderAddNewModal}
            BURelationshipList={this.props.BURelationshipList}
            getBusinessUnits={this.props.getBusinessUnits}
            ClearBusinessUnitRelations={this.props.ClearBusinessUnitRelations}
          />
          <BootstrapTable
            data={customerList}
            tableHeaderClass="header_white"
            options={options}
            bordered={false}
            insertRow={check}
            pagination
            exportCSV
            csvFileName="Consumer_List.csv"
          >
            <TableHeaderColumn
              row="0"
              rowSpan="1"
              dataField="name"
              dataSort
              isKey={true}
              dataAlign="center"
              filter={{ type: "TextFilter", placeholder: " " }}
              dataFormat={this.nameFormatter}
              caretRender={sortCaret}
              csvHeader="Consumer"
            >
              Consumer
            </TableHeaderColumn>
            {
              <TableHeaderColumn
                row="0"
                rowSpan="1"
                dataField="resellerName"
                dataSort
                hidden={this.props.isAdmin ? false : true}
                export={true}
                dataAlign="center"
                filter={{ type: "TextFilter", placeholder: " " }}
                caretRender={sortCaret}
                csvHeader={textDisplay[0]}
              >
                {textDisplay[0]}
              </TableHeaderColumn>
            }
            <TableHeaderColumn
              row="0"
              rowSpan="1"
              dataField="country"
              dataSort
              dataAlign="center"
              filter={{ type: "TextFilter", placeholder: " " }}
              caretRender={sortCaret}
              csvHeader="Country"
            >
              Country
            </TableHeaderColumn>
            <TableHeaderColumn
              row="0"
              rowSpan="1"
              dataField="lineOfBusiness"
              dataSort
              dataAlign="center"
              filter={{ type: "TextFilter", placeholder: " " }}
              caretRender={sortCaret}
              csvHeader="LOB"
            >
              LOB
            </TableHeaderColumn>
            <TableHeaderColumn
              row="0"
              rowSpan="1"
              dataField="division"
              dataSort
              dataAlign="center"
              filter={{ type: "TextFilter", placeholder: " " }}
              caretRender={sortCaret}
              csvHeader="Department"
            >
              Department
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="action"
              dataAlign="center"
              width="10%"
              dataFormat={this.tableActionColFormater}
              export={false}
            >
              Manage
            </TableHeaderColumn>
            {/*
                        <TableHeaderColumn
                            dataField='action'
                            dataAlign="center"
                            width="10%"
                            dataFormat={this.tableActionColFormaterDelete}
                        >
                            Delete
                        </TableHeaderColumn>
        */}
          </BootstrapTable>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return state.user;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getProvinces
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsumerList);
