/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { Col, Row, Label, Input, Button, Container, Card, CardBody } from 'reactstrap';
import WidgetTitleEditComponent from './WidgetTitleEditComponent';
import { toast } from 'react-toastify';

class SimpleNameEditForm extends Component {
    constructor(props) {
        // defaultLayout, onOkClick, onCancleClick
        super(props);
        this.state = {
            layout: this.props.defaultLayout,
            // layoutid: null,
        }
    }

    onNameChange = (layout) => {
        this.setState({
            layout: layout
        });
    }

    validateName = () => {
        let reValue = {
            validated: true,
            message: 'Success',
        }
        if (!this.state.layout || !this.state.layout.name || this.state.layout.length <= 0) {
            reValue.validated = false;
            reValue.message = 'Widget Name CAN NOT be empty'
        }

        return reValue;
    }

    onOkClick = () => {
        const {validated, message} = this.validateName();
        if (validated) {
            this.props.onOkClick(this.state.layout);
        }else{
            toast.error(message);
        }
        
    }

    render(){
         
        return(
            <Card>
                <CardBody>
                    <WidgetTitleEditComponent
                        defaulLayout={this.state.layout}
                        onChangeHandler = {this.onNameChange}
                    />
                    <hr/>
                    <div style={{textAlign: 'right'}}>
                        <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                        <Button color="custom" onClick={this.props.onCancleClick}>Cancel</Button>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

export default SimpleNameEditForm;