/* eslint-disable */
import React, { Component } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
} from "reactstrap";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  loadDevice,
  loadDeviceInfo,
  postDevice,
  postDeviceAttribute,
  getAssetClassAttributeAjax,
  getDeviceAttributes,
} from "../../../redux/actions/mapActions";
import { getCompanyList } from "../../../redux/actions/userAction";
import {
  loadAssets,
  loadClasses,
  addToAssets,
  addToAssetClasses,
  updateAssets,
  updateAssetClasses,
  updateOneAsset,
  updateOneAssetClass,
  removeFromAssets,
  removeFromAssetClasses,
  addAssetClassAttribute,
  updateAssetClassAttribute,
  deleteAssetClassAttribute,
  addAssetAttribute,
  updateAssetAttribute,
  deleteAssetAttribute,
  postAsset,
  deleteAsset,
  postAssetClassDT,
  postAssetClassAL,
  deleteAssetClass,
  loadIcons,
  postAssetClassAttributemeta,
  deleteAssetClassAttributemeta,
  postAssetAttribute,
  uploadAttributeImage,
  upsertCalculateAttribute,
  deleteCalculateAttribute,
  getCalculateAttribute,
  updateCalculateAttribute,
  updateBatchCalculateAttribute,
  resetCalculateAttribute,
} from "../../../redux/actions/assetActions";
import { getCurrentUser, hasRole } from "../../../factories/auth";
import AssetDisplayAll from "./AssetDisplayAll";
import ClassDisplay from "./ClassDisplay";
import AssetList from "./AssetList";
import DeviceList from "./DeviceList";
import SchemaBoard from "../../Schema/components/SchemaBoard"; //'./components/SchemaBoard';

class AssetBoard extends Component {
  constructor(props) {
    super(props);

    let index = localStorage.getItem("activeTab");
    this.state = {
      //activeTab: '2',
      activeTab: index ? index : "2",
    };
    this.userObj = getCurrentUser();
  }

  componentDidMount() {
    // Load initial data
    this.props.loadDevice();
    this.props.loadDeviceInfo();
    this.props.loadAssets();
    this.props.loadClasses();
    this.props.loadIcons();
    this.props.getCompanyList();
    this.props.getDeviceAttributes();

    // debugger;
  }

  // toggleTab = (tab) => {
  //    if (this.state.activeTab !== tab) {
  //       this.setState({ activeTab: tab })
  //    }
  // }

  toggleTab = (tab) => {
    localStorage.setItem("activeTab", tab);
    if (this.state.activeTab !== tab) {
      this.setState(
        {
          activeTab: tab,
          mapShow: tab == "2",
        },
        () => {
          if (this.state.activeTab == "2") {
            this.onMapShow();
          }
        }
      );
    }
  };

  reloadData = async (type) => {
    if (type == "assetclass") {
      await this.props.loadClasses();
    } else if (type == "asset") {
      await this.props.loadAssets();
    } else if (type == "device") {
      await this.props.loadDevice();
      await this.props.loadDeviceInfo();
      await this.props.getDeviceAttributes();
    } else {
      this.props.loadDevice();
      this.props.loadDeviceInfo();
      this.props.loadAssets();
      this.props.loadClasses();
      this.props.loadIcons();
      this.props.getCompanyList();
      this.props.getDeviceAttributes();
    }
  }

  onMapShow = () => { };

  fetchDevicesOfType = (type) =>
    this.props.currentDeviceList
      .filter((device) => device.devicetype === type)
      .map((device) => {
        return {
          // Standardize and cleaning up naming
          id: device.deviceid,
          name: device.devicename,
          namealias: device.devicenamealias,
        };
      });

  // Assign new id before pushing to store. For front-end key purposes
  // Possibly tag all new items for persisting to backend
  addAssetClassToStore = (data) => {
    const { id, name, allowedDeviceTypes, ...d } = data;
    let postData = {
      assetclassid: id,
      assetclassname: name,
      devicetypes: allowedDeviceTypes.map((dt) => ({
        devicetypeid: dt.id,
        devicetypename: dt.name,
      })),
      ...d,
    };

    this.props.postAssetClassDT(postData).then((res) => {
      // // this.props.loadClasses()
      // this.props.addToAssetClasses(res)
      if (res && !res.status) {
        this.props.addToAssetClasses(res);
      }
    });
  };
  // Return value
  addAssetToStore = (data) => {
    // console.log(data)
    this.props.postAsset(data).then((res) => {
      if (res && res.status === 200 && res.data && res.data.length > 0) {
        //
        //
        let storeData = res.data[0];
        // storeData.devices = data.devices.map(d => this.props.currentDeviceList.find(({ deviceid }) => deviceid == d.deviceid));
        //
        this.props.addToAssets(storeData);
        this.props.loadDeviceInfo();
      } else if (!res || res.status !== 200) {
        toast.error("The asset can not be added.");
      }
    });
  };
  updateClassOnStore = (data, successCallback, errorCallback) => {
    let preData = {
      ...this.props.assetClassList.find(({ id }) => id == data.id),
      ...data.data,
    };
    let postData = {
      // Required Data
      assetclassid: preData.id,
      assetclassname: preData.name,
      devicetypes: preData.allowedDeviceTypes.map(({ id, name }) => ({
        devicetypeid: id,
        devicetypename: name,
      })),
      description: preData.description,
      iconid: preData.iconid,
      assets: preData.assets,
      parentclass: preData.parentclass,
    };
    if (preData.companycode) {
      postData.companycode = preData.companycode;
    }
    // console.log(postData)
    // console.log(data)
    let apiCall = this.props.postAssetClassAL;
    if ("allowedDeviceTypes" in data.data) {
      apiCall = this.props.postAssetClassDT;
    }

    // console.log(postData)

    apiCall(postData).then((res) => {
      // this.props.loadClasses()
      // this.props.updateOneAssetClass(data)

      if (res && res.status) {
        if (res.status === 200) {
          this.props.loadClasses();
          if (successCallback) {
            successCallback();
          }
        } else if (res.status === 406) {
          toast.error(
            "There are alarts/reports that relate to the device type, you can not delete the device type."
          );
          if (errorCallback) {
            errorCallback();
          }
        } else {
          toast.error("Errors occurred during updating the asset class");
          if (errorCallback) {
            errorCallback();
          }
        }
      } else if (res && !res.status) {
        this.props.loadClasses();
        if (successCallback) {
          successCallback();
        }
      }
    });
  };

  upsertAssetClassAttributeMeta = (data) => {
    this.props.postAssetClassAttributemeta(data).then((res) => {
      this.props.loadClasses();
    });
  };
  deleteAssetClassAttributeMeta = (data) => {
    this.props.deleteAssetClassAttributemeta(data).then((res) => {
      this.props.loadClasses();
    });
  };

  upsertAssetClassCalculateAttribute = (data) => {
    this.props.upsertCalculateAttribute(data).then((res) => {
      this.props.loadClasses();
    });
    // this.props.upsertCalculateAttribute(data);
  };
  deleteAssetClassCalculateAttribute = (data) => {
    this.props.deleteCalculateAttribute(data).then((res) => {
      this.props.loadClasses();
    });
  };

  updateAssetOnStore = (data, successCallback, errorCallback) => {
    let postData = {
      ...this.props.assetList.find(({ assetid }) => assetid == data.assetid),
      ...data.data,
    };
    // console.log("===postDataAsset", postData);

    // console.log(postData)
    // console.log(data)

    this.props.postAsset(postData).then((res) => {
      //
      if (res && res.status === 200 && res.data && res.data.length > 0) {
        let resData = res.data[0];
        // this.props.loadClasses()
        this.props.updateOneAsset({ assetid: resData.assetid, data: resData });
        this.props.loadDeviceInfo();
        if (successCallback) {
          successCallback();
        }
      } else {
        if (res && res.status === 406) {
          toast.error(
            "There are alerts/reports that associate with the Asset, you can not delete it."
          );
        } else {
          toast.error("Errors occured during deleting the asset");
        }
        if (errorCallback) {
          errorCallback();
        }
      }
      // let resData = res.data[0]
      // // this.props.loadClasses()
      // this.props.updateOneAsset({ assetid: resData.assetid, data: resData });
      // this.props.loadDeviceInfo();
    });
  };
  deleteAssetClass = (assetclassid) => {
    this.props.deleteAssetClass({ assetclassid }).then((res) => {
      // this.props.loadClasses()
      this.props.removeFromAssetClasses(assetclassid);
    });
  };
  deleteAsset = (assetid) => {
    this.props.deleteAsset({ assetid }).then((res) => {
      // this.props.loadAssets()
      this.props.removeFromAssets(assetid);
      this.props.loadDeviceInfo();
    });
  };

  updateAssetAttribute = (data) => {
    console.log("updateAssetAttribute");

    this.props.postAssetAttribute(data).then((res) => {
      this.props.loadAssets();
    });
  };

  updateDeviceAttribute = (data) => {
    // console.log("===updateDeviceAttribute");

    this.props.postDeviceAttribute(data).then((res) => {
      this.props.getDeviceAttributes();
      this.props.loadDevice();
      this.props.loadDeviceInfo();
    });
  };

  updateDeviceOnStore = (data, successCallback, errorCallback) => {
    let postData = { ...data.devices, ...data.data };
    // console.log("===this.props.currentDeviceList", this.props.currentDeviceList);

    this.props.postDevice(postData).then((res) => {
      if (res && res.status === 200 /* && res.data && res.data.length > 0 */) {
        // let resData = res.data[0];
        // this.props.updateOneDevice({ deviceid: resData.deviceid, data: resData });
        this.props.loadDeviceInfo();
        if (successCallback) {
          successCallback();
        }
      } else {
        if (res && res.status === 406) {
          toast.error("Error 406");
        } else {
          toast.error("Errors occured during deleting the device");
        }
        if (errorCallback) {
          errorCallback();
        }
      }
    });
  };

  updateImageAttribute = (assetid, attributeid, file) => {
    if (!this.isFileImage(file)) {
      toast.error("Input File is not a image");
      return;
    }
    //  else{
    //    toast.error('Input File is not a image');
    //     return;
    //  }
    this.props
      .uploadAttributeImage(assetid, attributeid, file)
      .then((res) => {
        if (res.status === 200) {
          this.props.loadAssets();
          toast.success("Successly Upload the image");
        } else {
          // nothing
          toast.error("Error ocurred during ploading the image");
        }
      })
      .catch((err) => {
        // nothing
        toast.error("Error ocurred during ploading the image");
      });
  };

  isFileImage = (file) => {
    console.log("valide file", file);
    const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

    return file && acceptedImageTypes.includes(file["type"]);
  };
  render() {
    let assetList = this.props.assetList;
    // console.log("===this.props.assetClassList", this.props.assetClassList);
    return (
      <Col md={12}>
        {/* <RenderTestingButtons props={this.props} state={this.state} /> */}
        <NavTabs activeTab={this.state.activeTab} toggleTab={this.toggleTab} />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <ClassDisplay
              assetList={this.props.assetList}
              deviceTypes={this.props.deviceTypes}
              assetClassList={this.props.assetClassList}
              //////////////////////////////////////////////////////////////////
              // May need to refactor props: Possible WIP  /////////////////////
              //////////////////////////////////////////////////////////////////
              fetchDevicesOfType={this.fetchDevicesOfType}
              deleteAsset={this.deleteAsset}
              updateAssetOnStore={this.updateAssetOnStore}
              //////////////////////////////////////////////////////////////////
              addClassToStore={this.addAssetClassToStore}
              updateClassOnStore={this.updateClassOnStore}
              deleteAssetClass={this.deleteAssetClass}
              //    addNewCustomAttribute={this.props.addAssetClassAttribute}
              //    updateCustomAttribute={this.props.updateAssetClassAttribute} //
              //    deleteCustomAttribute={this.props.deleteAssetClassAttribute} //
              addNewCustomAttribute={this.upsertAssetClassAttributeMeta}
              updateCustomAttribute={this.upsertAssetClassAttributeMeta}
              deleteCustomAttribute={this.deleteAssetClassAttributeMeta}
              upsertCalculateAttribute={this.upsertAssetClassCalculateAttribute}
              deleteCalculateAttribute={this.deleteAssetClassCalculateAttribute}
              getAssetClassAttributeAjax={this.props.getAssetClassAttributeAjax}
              //---------------------------------------------------------------
              companyList={this.props.companyList}
              userObj={this.userObj}
              reloadData = {this.reloadData}
            />
          </TabPane>
          <TabPane tabId="2" style={{ cursor: "pointer" }}>
            <AssetDisplayAll
              assetList={this.props.assetList}
              calculatedAttributes={this.props.calculatedAttributes}
              updateBatchCalculateAttribute={
                this.props.updateBatchCalculateAttribute
              }
              getCalculateAttribute={this.props.getCalculateAttribute}
              assetClassList={this.props.assetClassList}
              deviceTypes={this.props.deviceTypes}
              deviceList={this.props.currentDeviceList
                .filter((item) => item.assetid == null || item.assetid == 0)
                .map((d) => ({
                  id: d.deviceid,
                  name: d.devicename,
                  devicetype: d.devicetype,
                  devicetypename: d.devicetypename,
                  hasparent: d.hasparent,
                  parentid: d.parentdeviceid,
                }))}
              fetchDevicesOfType={this.fetchDevicesOfType}
              saveAssetToStore={this.addAssetToStore}
              updateAssetOnStore={this.updateAssetOnStore}
              deleteAsset={this.deleteAsset}
              addAssetAttribute={this.props.addAssetAttribute}
              //    updateAssetAttribute={this.props.updateAssetAttribute}
              updateAssetAttribute={this.updateAssetAttribute}
              updateImageAttribute={this.updateImageAttribute}
              deleteAssetAttribute={this.props.deleteAssetAttribute}
              reloadData = {this.reloadData}
            />
          </TabPane>
          <TabPane tabId="3">
            {/* Nothing here */}
            <AssetList
              assetClassList={this.props.assetClassList}
              assetList={this.props.assetList}
              devices={this.props.currentDeviceList
                .filter((item) => item.assetid == null || item.assetid == 0)
                .map((d) => ({
                  id: d.deviceid,
                  name: d.devicename,
                  devicetype: d.devicetype,
                  devicetypename: d.devicetypename,
                }))}
              deviceList={this.props.currentDeviceList}
              deviceAttributes={this.props.deviceAttr}
              loading={this.props.currentDeviceListLoading}
              deviceTypes={this.props.deviceTypes}
              // deviceList={this.props.currentDeviceList.filter((item) => (item.assetid == null || item.assetid == 0)).map(d => ({ id: d.deviceid, name: d.devicename, devicetype: d.devicetype, devicetypename: d.devicetypename }))}
              fetchDevicesOfType={this.fetchDevicesOfType}
              updateDeviceOnStore={this.updateDeviceOnStore}
              updateDeviceAttribute={this.updateDeviceAttribute}
              reloadData = {this.reloadData}
            />
          </TabPane>
          <TabPane tabId="4">
            {/* Nothing here */}
            {/* <AssetList deviceList={this.props.currentDeviceList} /> */}
            <Card>
              <div className="card-pg">
                <SchemaBoard />
              </div>
            </Card>
          </TabPane>
          {/* <TabPane tabId="5">
                  <DeviceList
                     // assetList={this.props.assetList}
                     deviceList={this.props.currentDeviceList}
                     // loading={this.props.currentDeviceListLoading}
                     // calculatedAttributes={this.props.calculatedAttributes}
                     // getCalculateAttribute={this.props.getCalculateAttribute}
                     ////////
                     assetList={this.props.assetList}
                     calculatedAttributes={this.props.calculatedAttributes}
                     updateBatchCalculateAttribute = {this.props.updateBatchCalculateAttribute}
                     getCalculateAttribute={this.props.getCalculateAttribute}
                     assetClassList={this.props.assetClassList}
                     deviceTypes={this.props.deviceTypes}
                     // deviceList={this.props.currentDeviceList.filter((item) => (item.assetid == null || item.assetid == 0)).map(d => ({ id: d.deviceid, name: d.devicename, devicetype: d.devicetype, devicetypename: d.devicetypename }))}
                     fetchDevicesOfType={this.fetchDevicesOfType}
                     saveAssetToStore={this.addAssetToStore}
                     updateAssetOnStore={this.updateAssetOnStore}
                     deleteAsset={this.deleteAsset}
                     addAssetAttribute={this.props.addAssetAttribute}
                     //    updateAssetAttribute={this.props.updateAssetAttribute}
                     updateAssetAttribute={this.updateAssetAttribute}
                     updateImageAttribute={this.updateImageAttribute}
                     deleteAssetAttribute={this.props.deleteAssetAttribute}

                  />
               </TabPane> */}
        </TabContent>
      </Col>
    );
  }
}

function NavTabs({ activeTab, toggleTab }) {
  return (
    <Nav
      tabs
      style={{
        border: "none",
      }}
    >
      <NavItem style={{ cursor: "pointer" }}>
        <NavLink /* style={{
               border: 'none'
            }} */
          className={classnames({ active: activeTab === "1" })}
          onClick={() => {
            toggleTab("1");
          }}
        >
          <h4>
            <b>Asset Classes</b>
          </h4>
        </NavLink>
      </NavItem>
      <NavItem style={{ cursor: "pointer" }}>
        <NavLink /* style={{
               border: 'none'
            }} */
          className={classnames({ active: activeTab === "2" })}
          onClick={() => {
            toggleTab("2");
          }}
        >
          <h4>
            <b>All Assets</b>
          </h4>
        </NavLink>
      </NavItem>
      {/* <NavItem style={{ cursor: "pointer" }}>
            <NavLink
               className={classnames({ active: activeTab === '5'})}
               onClick={() => { toggleTab('5')}}
            >
               <h4><b>Device List</b></h4>
            </NavLink>
         </NavItem> */}
      <NavItem style={{ cursor: "pointer" }}>
        <NavLink /* style={{
               border: 'none'
            }} */
          className={classnames({ active: activeTab === "3" })}
          onClick={() => {
            toggleTab("3");
          }}
        >
          <h4>
            <b>Devices</b>
          </h4>
        </NavLink>
      </NavItem>
      <NavItem style={{ cursor: "pointer" }}>
        <NavLink /* style={{
               border: 'none'
            }} */
          className={classnames({ active: activeTab === "4" })}
          onClick={() => {
            toggleTab("4");
          }}
        >
          <h4>
            <b>Asset Structure</b>
          </h4>
        </NavLink>
      </NavItem>
    </Nav>
  );
}

function RenderTestingButtons({ props, state }) {
  return (
    <>
      <Button
        variant="primary"
        color="custom"
        onClick={() => {
          console.log(props);
        }}
      >
        Show Props
      </Button>
      <Button
        variant="primary"
        color="custom"
        onClick={() => {
          console.log(state);
        }}
      >
        Show State
      </Button>
    </>
  );
}

// export default AssetBoard;

function mapStateToProps(state) {
  //
  //
  const deviceTypes = state.mapboard.types;
  const companyList = state.user.companyList;
  // const deviceTypes = [];
  const props = { deviceTypes, companyList, ...state.asset };
  // console.log('========== ASSET BOARD ============')
  //
  //
  //
  return props;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loadDevice,
      loadDeviceInfo,
      loadAssets,
      loadClasses,
      addToAssets,
      addToAssetClasses,
      updateAssets,
      updateAssetClasses,
      updateOneAsset,
      updateOneAssetClass,
      removeFromAssets,
      removeFromAssetClasses,
      addAssetClassAttribute,
      updateAssetClassAttribute,
      deleteAssetClassAttribute,
      addAssetAttribute,
      updateAssetAttribute,
      deleteAssetAttribute,
      postAsset,
      deleteAsset,
      postAssetClassDT,
      postAssetClassAL,
      deleteAssetClass,
      postAssetClassAttributemeta,
      deleteAssetClassAttributemeta,
      loadIcons,
      postAssetAttribute,
      getDeviceAttributes,
      postDeviceAttribute,
      postDevice,
      getCompanyList,
      uploadAttributeImage,
      upsertCalculateAttribute,
      deleteCalculateAttribute,
      getAssetClassAttributeAjax,
      getCalculateAttribute,
      updateCalculateAttribute,
      updateBatchCalculateAttribute,
      resetCalculateAttribute,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetBoard);
