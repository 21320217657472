/* eslint-disable */
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { Container, Col, Row, Card, CardHeader, CardBody,  CardTitle, CardText, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, UncontrolledTooltip, Form, FormGroup, Button } from 'reactstrap';
import Select from 'react-select';
import StepTemplate from './StepTemplate';
import {loadAssetClass, loadAllAssets} from '../../../redux/actions/mapActions';
import {loadAllGeoFences, upsertGeofence} from '../../../redux/actions/fenceAction';
import {getActionById, getActionRule, getActionAlert, upsertAction, getRuleTemplate, upsertActionAjax, upsertActionRule , upsertActionAlert, getActions} from '../../../redux/actions/jobAction';
import BasicAlertInfoForm from './BasicAlertInfoForm';
import BasicRulesForm from './BasicRulesForm';
import BasicNotificationForm from './BasicNotificationForm';
import {getCurrentUser, hasRole} from '../../../factories/auth';
import {getContact, upsertContact, deleteContact, getNotificationTemplate} from '../../../redux/actions/jobAction';

class BasicAlertEditForm extends Component {
    constructor(props){
        super(props);
        let userObj = getCurrentUser();
         
         
        if (!userObj) {
            toast.error('Can not fetch login user information, Please re-login.');
            this.props.history.push('/log_in');
        }else{
             
             
        }
        this.state={
            userObj: userObj,
            actionInfo:  {
                "actionid": this.props.actionPassId,
                "actionname": "",
                "actiondescription": "",
                "triggertype": 0,
                "weekday": "[]",
                "actiontime": "00:00:00",
                "createtime": new Date().toISOString().split('.')[0],
                "createdby_user_id": userObj.userName,
                "company_code": userObj.department,
                "active" : true,
                "action": "Alert",
            },
            actionAlert: [],
            triggerid: 0,
            weekOption :  [
                { value: 1, label: 'Mon' },
                { value: 2, label: 'Tue' },
                { value: 3, label: 'Wed' },
                { value: 4, label: 'Thu' },
                { value: 5, label: 'Fri' },
                { value: 6, label: 'Sat' },
                { value: 7, label: 'Sun' },
            ],

            eventName: null,
            selectedPeriod: null,
            selectedRule: null,
            selectedTemplateid: 0,
            selectedAssetClassOption : null,
            selectedAssetOption: null,
            selectedContactClassOption: null,
            selectedContactOption: null,
            allAssetList: [],
        }
    }

    // ---------------------------------------------------------
    componentDidMount(){
        // this.props.getActionById(this.props.actionPassId);
        // let userObj = getCurrentUser;
        // if (!userObj) {
        //     toast.error('Can not fetch login user information, Please re-login.');
        //     this.props.history.push('/log_in');
        // }
        this.props.loadAssetClass();
        // this.props.loadAllGeoFences({devicetypeid: this.state.currentDeivecTypeId});
        this.props.loadAllGeoFences();
        this.props.getContact();
        this.props.getNotificationTemplate();

         
         
         
        // debugger;
        let actionid = this.props.actionPassId;
        if ( 0 != actionid ) {
             
            this.props.getActionById(this.props.actionPassId);
            this.props.getActionRule({actionid: this.props.actionPassId});
            this.props.getActionAlert({actionid: this.props.actionPassId});
        }else{
            // let newActionInfo = {
            //     "actionid": this.props.actionPassId,
            //     "actionname": "",
            //     "actiondescription": "",
            //     "triggertype": 0,
            //     "weekday": "[]",
            //     "actiontime": "00:00:00",
            //     "createtime": new Date().toISOString().split('.')[0],
            //     "createdby_user_id": userObj.userName,
            //     "company_code": userObj.department,
            //     "active" : true,
            //     "action": "Alert",
            // };
            // let newActionAlert = [
            //     {
            //         weekday: [],
            //         starttime: "",
            //         endtime: "",
            //         actionid: this.props.actionPassId,
            //         alerttype: "01",
            //         destination: "",
            //         title: "",
            //         content: "",
            //     },
            //     {
            //         weekday: [],
            //         starttime: "",
            //         endtime: "",
            //         actionid: this.props.actionPassId,
            //         alerttype: "02",
            //         destination: "",
            //         title: "",
            //         content: "",
            //     },
            // ];
             
            // this.setState({
            //     actionAlert: newActionAlert,
            // });
            // this.setState({
            //     actionAlert: newActionAlert,
            //     actionInfo : newActionInfo,
            // })
        }       
    };
    componentWillReceiveProps(nextProps){
        // if (nextProps.actionInfo && nextProps.actionInfo.length > 0 && nextProps.actionId > 0 && nextProps.actionId !== this.props.actionId){
        //     const actionInfo = nextProps.actionInfo[0];
        //     let weekday = [];
        //     if (!actionInfo){
        //         return null;
        //     }
        //     if (actionInfo.weekday){
        //         const infoWeekDay = JSON.parse(actionInfo.weekday);
        //         infoWeekDay.forEach((day,index)=>{
        //             let selectOption = this.state.weekOption.find((item,index) =>(item.label === day));
        //             weekday.push(selectOption);
        //         });
        //          
        //          
        //     }
        //     this.setState({
        //         eventName: actionInfo.actionname,
        //         description: actionInfo.actiondescription,
        //         triggerid: actionInfo.triggertype,
        //         selectedPeriod: actionInfo.weekday,
        //         triggerTime: actionInfo.actiontime,
        //         selectedPeriod: weekday,
        //     });
        // }
        if (nextProps.actionInfo && nextProps.actionInfo !== this.props.actionInfo){ //actionPassId != 0
             
             
            if (nextProps.actionInfo.length > 0 ){
                this.setState({
                    actionInfo: nextProps.actionInfo[0],
                });
            }

        }
        if (nextProps.actionAlert && nextProps.actionAlert !== this.props.actionAlert){
             
            this.setState({
                actionAlert: nextProps.actionAlert,
            });
        }

        if (nextProps.templateList && nextProps.templateList !== this.props.templateList){
            this.setState({
                templateList: nextProps.templateList
            });
        }
    }
    // ---------------------------------------------------------

    handleTriggerChange = (e) => {
        let value = Number(e.target.value);
        this.setState({
            triggerid : value,
        });
    }

    handleInputChange = (e) => {
         
        this.setState({
            [e.target.name]: e.target.value
         });
    }

    handlePeriodsSelect = (value) =>{
         
         
        this.setState({
            selectedPeriod: value,
        });
    }

    // renderActionForm=()=>{
    //     return(
    //         <Form>
    //             <Row>
    //                 <Col md={3}>
    //                     <FormGroup row >
    //                         {/* <Label for="startTime" md={2} >From</Label> */}
    //                         <Col md={12}>
    //                             <Label for="eventName"  style={{display: 'inline', width: "100%"}} >Event Name</Label>
    //                             <Input 
    //                                 type="text" 
    //                                 id="eventName" 
    //                                 name="eventName" 
    //                                 width="100%" 
    //                                 value={this.state.eventName} 
    //                                 onChange={this.handleInputChange} 
    //                                 valid = {this.state.eventName }
    //                                 invalid = {!this.state.eventName}
    //                                 required={'required'}
    //                             >
    //                             </Input>     
    //                         </Col>                   
    //                     </FormGroup>
    //                 </Col>
    //             </Row>
    //             <Row>
    //                 <Col md={8}>
    //                     <FormGroup row >
    //                         {/* <Label for="endTime" md={2} >To</Label> */}
    //                         <Col md={12}>
    //                             <Label for="description"  style={{display: 'inline', width: "100%"}} >Description</Label>
    //                             <Input type="textarea" id="description" name="description" width="100%" value={this.state.description} onChange={this.handleInputChange} >
    //                             </Input>  
    //                         </Col>                      
    //                     </FormGroup>
    //                 </Col>
    //             </Row>
    //         </Form>
    //     );
    // }

    renderNotificationForm = () => {

    }

    updateActionInfo = () => {

    }

    handleBack = () => {
        this.props.onBack();
    }

    // handleNext = () => {
    //     // if (this.updateActionInfo()) {
    //     //     this.props.handleNext();
    //     // }else{
    //     //     // todo alert notification;
    //     // };  
    //     // alert(this.state.triggerTime);   
    //     if (! this.validate()){
    //         return;
    //     }
    //     if (sessionStorage.getItem('currentUserObj')){
    //         let createDate =new Date().toISOString().split('.')[0]; //.replace('T', ' ');
    //         let weekday = [];
    //         let actiontime = '00:00:00';
    //         if(this.state.selectedPeriod){
    //             this.state.selectedPeriod.forEach((item, index)=>{
    //                 weekday.push(item.label);
    //             });
    //         }
    //         let timeRegex = /^\d{2}[:]\d{2}$/;
    //         if (this.state.triggerTime && timeRegex.test(this.state.triggerTime)){
    //              
    //              
    //             actiontime = this.state.triggerTime + ':00';
    //         }
            
    //         try{
    //             let userObj = JSON.parse(sessionStorage.getItem('currentUserObj'));
    //             let actionObj = {
    //                 "actionid": this.props.actionPassId,
    //                 "actionname": this.state.eventName,
    //                 "actiondescription": this.state.description,
    //                 "triggertype": this.state.triggerid,
    //                 "weekday": JSON.stringify(weekday),
    //                 "actiontime": actiontime,
    //                 "createtime": createDate,
    //                 "createdby_user_id": userObj.userName,
    //                 "company_code": userObj.department,
    //                 "active" : true,
    //                 "action": "Alert",
    //             }
    //              
    //             this.props.upsertAction(actionObj);
               
    //         }catch(e){
    //             console.error(e);
    //         }   
    //     }
    // }

    validate = () => {
        if (! this.state.eventName || this.state.eventName.length === 0) {
            toast.error('Please input a event name.');
            return false;
        } 

        if (this.state.triggerid === '1' && (!this.state.selectedPeriod || this.state.selectedPeriod.length === 0)) {
            toast.error('Please choose time.');
            return false;
        }

        return true;
    }

    handleActionInfoInputChange  = (e) => {
         
         
        let key = e.target.name;
        let value = e.target.value;
        this.setState(prevState => {          
            prevState.actionInfo[key] = value;
            return({
                actionInfo: prevState.actionInfo,
            })
        });
    }

    handleActionAlertPeriodChange = (value) => {
        let weekday = [];
        if (value){
            value.forEach((item, index)=>{
                weekday.push(item.label);
            });
        }
        this.setState(prevState => {
            if (prevState.actionAlert.length == 0 ){
                let newElement = {
                    weekday:JSON.stringify(weekday),
                    starttime: '00:00:00',
                    endtime: '00:00:00',
                    actionid: this.props.actionPassId,
                    alerttype: "01",
                    destination: "",
                    title: "",
                    content: "",
                }
                prevState.actionAlert.push(newElement);
            } else {
                prevState.actionAlert.forEach((item, index) => {
                     
                    item.weekday = JSON.stringify(weekday);                    
                });
            }
             
            return ({
                actionAlert: prevState.actionAlert,
            })
        });
    }

    handleActionAlertTimeInputChange = (event) => {
        // console.log(alertType)
        let key = event.target.name;
        let value = event.target.value;
        this.setState(prevState => {
            if (prevState.actionAlert.length == 0 ){
                let newElement = {
                    weekday:'[]',
                    starttime: '00:00:00',
                    endtime: '00:00:00',
                    actionid: this.props.actionPassId,
                    alerttype: "01",
                    destination: "",
                    title: "",
                    content: "",
                }
                newElement[key] = value;
                prevState.actionAlert.push(newElement);
            } else {
                prevState.actionAlert.forEach((item, index) => {
                     
                    
                        //  
                    item[key] = value;
                    
                });
            }
             
            return ({
                actionAlert: prevState.actionAlert,
            })
        });
        
    } 

    handleAlertNotificationChange = (event, alertType) => {
        let key = event.target.name;
        let value = event.target.value;
         
         
        this.setState(prevState => {
            switch (key) {
                case 'title':
                    prevState.actionAlert.forEach((item, index) => {item.title = value});
                    break;
                case 'content':
                    prevState.actionAlert.forEach((item, index) => {item.content = value});
                    break;
            }

            return({
                [key]: value,
                actionAlert: prevState.actionAlert,
            });
        });
    }

    handleActionAlertInputChange = (event, alertType) => {
        console.log(alertType)
        let key = event.target.name;
        let value = event.target.value;
        // if (this.state.actionAlert){
        //     let element = this.state.actionAlert.find((item,index)=>(item.alerttype === alertType));   

        // }
        this.setState(prevState => {
             
             
            if (prevState.actionAlert){
                //  
                //  
                let element = prevState.actionAlert.find((item,index)=>(item.alerttype === alertType)); 
                if (element){
                    element[key] = value;
                }else{
                    let weekday = "[]";
                    let starttime = "00:00:00";
                    let endtime = "00:00:00";
                    if (prevState.actionAlert.length > 0 ){
                        let item = prevState.actionAlert[0];
                        weekday = item.weekday;
                        starttime = item.starttime;
                        endtime = item.endtime;
                    }
                    let newElement = {
                        weekday:weekday,
                        starttime: starttime,
                        endtime: endtime,
                        actionid: this.props.actionPassId,
                        alerttype: alertType,
                        destination: "",
                        title: "",
                        content: "",
                    }
                    newElement[key] = value;
                    prevState.actionAlert.push(newElement);
                }
                 
                return ({
                    actionAlert: prevState.actionAlert,
                })
            }
        });
    }

    loadTemplate = (assetid) => {
         
        this.props.getRuleTemplate(assetid);
    }

    onRuleChange = (e, item) => {
        let index = e.target.value;
        let value = this.state.templateList[index].value;
         
        // let showFence = this.state.templateList[index].geofenceid > 0
        this.setState({
            selectedRuleIndex: index,
            selectedRuleValue: value,
            selectedRule: item,
            // showFence: showFence,
        });
    }

    handleAssetClassChange = (val) => {
         
        this.loadTemplate(val.value);
        this.setState({
            selectedAssetClassOption: val,
            selectedAssetOption: null,
        });
    }

    handleAssetChange = (val) => {
        this.setState({
            selectedAssetOption: val,
        })
    }

    
    handleEditInputChange = (newVal) => {
        this.setState((prevState) => {
            prevState.selectedRule.value = newVal
            return ({
                selectedRuleValue: newVal,
                selectedRule: prevState.selectedRule,
            })
        });
    }

    handleGeofenchChange = (fenceObj) => {
        this.setState((prevState) => {
            if (prevState.selectedRule.geofenceid > 0) {
                prevState.selectedRule.geofenceid = fenceObj.fenceid;
                prevState.selectedRule.geofencename = fenceObj.fencename;
                prevState.selectedRule.attributename = 'Position';
                prevState.selectedRule.value = fenceObj.fenceid;
                return ({
                    selectedRuleValue: fenceObj.fenceid,
                    selectedRule: prevState.selectedRule,
                })
            }
           
        });
    }

    handleContactChange = val => {
         
        let notifications = [];
        // this.state.actionAlert
        let title = this.state.title ? this.state.title : '';
        let content = this.state.content ? this.state.content : ''; 
        if (val && val.length > 0) {
            val.forEach((item, index) => {
                if (item.emailenable) {
                    let newElement = {
                        weekday:'[]',
                        starttime: '00:00:00',
                        endtime: '00:00:00',
                        actionid: this.props.actionPassId,
                        alerttype: "01",
                        destination: item.email,
                        title: title,
                        content: content,
                        contactid : item.id,
                        notification_templateid: 0,
                    }
                    notifications.push(newElement);
                }

                if (item.phoneenable) {
                    let newElement = {
                        weekday:'[]',
                        starttime: '00:00:00',
                        endtime: '00:00:00',
                        actionid: this.props.actionPassId,
                        alerttype: "02",
                        destination: item.phone,
                        title: title,
                        content: content,
                        contactid : item.id,
                        notification_templateid: 0,
                    }
                    notifications.push(newElement);
                }
            });
           
        }
        this.setState({
            // selectedContactOption: val,
            actionAlert: notifications,
        });
    };

    handleNotificationTemplateChange = (val) => {
         
        if (!val){
            this.setState({
                actionAlert: null,
            });

            return;
        }
        if (val.contactid && this.props.contactList) {
            let notifications = [];
            let contactidArr = JSON.parse(val.contactid);
            // let title = val.title; 
            // let content = val.content;
            // let weekday = val.weekday;
            // let starttime = val.starttime;
            // let endtime = val.endtime;
            this.props.contactList.forEach((item, index) => {
                if (contactidArr.includes(item.id) ){
                    if (item.emailenable) {
                        let newElement = {
                            weekday: val.weekday,
                            starttime: val.starttime,
                            endtime: val.endtime,
                            actionid: this.props.actionPassId,
                            alerttype: "01",
                            destination: item.email,
                            title: val.title,
                            content: val.content,
                            contactid : item.id,
                            notification_templateid: 0,
                        }
                        notifications.push(newElement);
                    }
    
                    if (item.phoneenable) {
                        let newElement = {
                            weekday: val.weekday,
                            starttime: val.starttime,
                            endtime: val.endtime,
                            actionid: this.props.actionPassId,
                            alerttype: "02",
                            destination: item.phone,
                            title: val.title,
                            content: val.content,
                            contactid : item.id,
                            notification_templateid: 0,
                        }
                        notifications.push(newElement);
                    }
                }
            });
        
            this.setState({
                // selectedContactOption: val,
                actionAlert: notifications,
            });

        }
    }


    validateForm = () => {
        if (!this.validateActiionInfo()){
            toast.error('Action Infomation is not valid.');
            return false;
        }
        if (!this.validateActionRule()) {
            toast.error('Action Rule is not valid.');
            return false;
        }
        if (!this.validateNotification()) {
            toast.error('Action Notification is not valid.');
            return false;
        }
        return true;
    }

    validateActiionInfo = () => {
        return (this.state.actionInfo && this.state.actionInfo.actionname && this.state.actionInfo.actionname.length > 0);
    }
    SaveAction = () =>{
        try{
            // let userObj = JSON.parse(sessionStorage.getItem('currentUserObj'));
            // let createDate =new Date().toISOString().split('.')[0];
            // let actionObj = {
            //     "actionid": 0,
            //     "actionname": this.state.actionInfo.actionname,
            //     "actiondescription": this.state.actionInfo.description,
            //     "triggertype": 0,
            //     "weekday": '[]',
            //     "actiontime": '00:00:00',
            //     "createtime": createDate,
            //     "createdby_user_id": userObj.userName,
            //     "company_code": userObj.department,
            //     "active" : true,
            //     "action": "Alert",
            // }
            //  
            //  

             

            if (this.validateForm()) {
                this.props.upsertActionAjax(this.state.actionInfo).then(response => {
                    //  
                    if (response){
                        //  
                        this.SaveRule(response);
                        this.SaveNotification(response);
                        this.props.getActions();
                    }
                });
                this.props.history.push(`/pages/action`);
            }

            
            // this.props.upsertActionAjax(this.state.actionInfo).then(response => {
            //     //  
            //     if (response){
            //         //  
            //         this.SaveRule(response);
            //         this.SaveNotification(response);
            //     }
            // });

        //     this.props.postAssetClassDT(postData)
        //     .then(res => {
        //     // this.props.loadClasses()
        //     this.props.addToAssetClasses(res)
        //  })
            
        }catch(e){
            console.error(e);
        }   
        
    }

    validateActionRule = () => {
        let validateAssetClass =  this.state.selectedAssetClassOption && this.state.selectedAssetClassOption.value;
        let validateRule = this.state.selectedRule && this.state.selectedRule.value;
        return validateAssetClass && validateRule;
    }

    SaveRule = (actionid) => {
        let assetclassid = this.state.selectedAssetClassOption.value;
        let assetclassname = this.state.selectedAssetClassOption.label;
        let assetid = this.state.selectedAssetOption ? this.state.selectedAssetOption.value : -1;
        let assetname = this.state.selectedAssetOption ? this.state.selectedAssetOption.label : '';
        
        let newRule = [
            {
                "groupid": 0,
                "groupindex": 0,
                "grouprelation": 0,
                "ruleitems": [
                    {
                        "actionid": actionid,
                        "assetclassid": assetclassid,
                        "assetclassname": assetclassname,
                        "assetid": assetid,
                        "assetname": assetname,
                        "attributeid": this.state.selectedRule.attributeid,
                        "attributename": this.state.selectedRule.attributename,
                        "geofenceid": this.state.selectedRule.geofenceid,
                        "geofencename": this.state.selectedRule.geofencename,
                        "operator": this.state.selectedRule.operator,
                        "value": this.state.selectedRule.value,
                        "relation": 0,
                        "templateid": this.state.selectedTemplateid,
                    }
                ]
            }
        ];
        this.props.upsertActionRule(newRule, actionid);
    }

    validatePhoneNumber = (phoneNum) => {
        var regex =  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        return (phoneNum && regex.test(phoneNum));
    }

    validateEmail = (email) => {
        // var regex = /^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g;
        // return  (this.state.email && this.state.email.match(regex) !== null);
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;        
        return  (email && regex.test(email));
    }

    validateEmailAlert = () => {
        let reValue = false;
        if (this.state.actionAlert && this.state.actionAlert.length > 0) {
            let element = this.state.actionAlert.find((item,index)=>(item.alerttype === '01'));  
            if (element){
                let vemailaddress = this.validateEmail(element.destination);
                reValue = element.title && element.title.length > 0 && element.content && element.content.length && vemailaddress;
            }
        } 

        return reValue;
    }
    validateSMSAlert = () => {
        let reValue = false;
        if (this.state.actionAlert && this.state.actionAlert.length > 0) {
            let element = this.state.actionAlert.find((item,index)=>(item.alerttype === '02'));  
            if (element){
                let vphoneno = this.validatePhoneNumber(element.destination);
                reValue = element.content && element.content.length && vphoneno;
            }
        } 
        return reValue;
    }

    // validateNotification = () => {
    //     return (this.validateEmailAlert() || this.validateSMSAlert());
    // }

    validateNotification = () => {
        // return (this.validateEmailAlert() || this.validateSMSAlert());
        // let validateContact = (this.state.selectedContactOption && this.state.selectedContactOption.length>0); actionAlert
        let validateContact = (this.state.actionAlert && this.state.actionAlert.length>0);
        // let validateMessage = (this.state.content && this.state.content.length);
        let validateMessage = true;
        for(let i = 0 ; i < this.state.actionAlert.length; i++){
            validateMessage = (this.state.actionAlert[i].content && this.state.actionAlert[i].content.length > 0);
            if (!validateMessage){
                break;
            }
        }
         
         
         
        return (validateContact && validateMessage);
    }

    SaveNotification = (actionid) => {
       
        let timeRegex = /^\d{2}[:]\d{2}$/;
        
        if (this.state.actionAlert.length > 0) {
            let alertArray = this.state.actionAlert
             
             
            alertArray.forEach((item, index) => {
                 
                item.actionid = actionid;    
                if (item.starttime && timeRegex.test(item.starttime)){
            
                    item.starttime = item.starttime + ':00';
                }else {
                    item.starttime = '00:00:00';
                }
                if (item.endtime && timeRegex.test(item.endtime)){
                    
                    item.endtime = item.endtime + ':00';
                }else {
                    item.endtime = '00:00:00';
                }

            });
            this.props.upsertActionAlert(alertArray);
        }
    }

    handleSubmitOnclick = () => {
        this.SaveAction();
    }

    handleCancelOnclick = () => {
        this.props.history.push(`/pages/action`);
    }
    

    render(){
        return(
            <div>
                <Card>
                    {/* <CardHeader>Information</CardHeader> */}
                    <CardBody>
                        <CardTitle><h4><b>Information</b></h4></CardTitle>
                        <BasicAlertInfoForm 
                            actionInfo = {this.state.actionInfo}
                            handleInputChange = {this.handleActionInfoInputChange}
                        />
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle><h4><b>PreDefined Rules</b></h4></CardTitle>
                        <BasicRulesForm
                            allfences = {this.props.allfences}
                            fenceObj = {this.props.fenceObj}
                            assetClass = {this.props.assetClass}
                            templateList = {this.state.templateList}
                            selectedRule = {this.state.selectedRule}
                            selectedAssetClassOption = {this.state.selectedAssetClassOption}
                            selectedAssetOption = {this.state.selectedAssetOption}
                            handleAssetClassChange = {this.handleAssetClassChange}
                            handleAssetChange = {this.handleAssetChange}
                            onRuleChange = {this.onRuleChange}
                            handleEditInputChange = {this.handleEditInputChange}
                            handleGeofenchChange = {this.handleGeofenchChange}
                            // loadTemplate = {this.loadTemplate}
                            upsertGeofence = {this.props.upsertGeofence}
                            actionname = {this.state.actionInfo && this.state.actionInfo.actionname ? this.state.actionInfo.actionname : ''}
                        />
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <CardTitle><h4><b>Notification</b></h4></CardTitle>
                        <Row>
                        <Col md={12} >
                            <BasicNotificationForm
                                actionAlert = {this.state.actionAlert}
                                // handleInputChange = {this.handleActionAlertInputChange}
                                handleInputChange = {this.handleAlertNotificationChange}
                                handleTimeInputChange = {this.handleActionAlertTimeInputChange}
                                handlePeriodsSelect = {this.handleActionAlertPeriodChange}
                                contactList={this.props.contactList}
                                // selectedContactOption={this.state.selectedContactOption}
                                handleContactChange={this.handleContactChange}
                                upsertContact={this.props.upsertContact}
                                deleteContact={this.props.deleteContact}
                                title = {this.state.title}
                                content = {this.state.content}
                                userObj = {this.state.userObj}
                                notificationTemplateList = {this.props.notificationTemplateList}
                                handleNotificationTemplateChange = {this.handleNotificationTemplateChange}
                            />
                        </Col>
                        </Row>
                         <Row>
                           <Col md={12} style={{ textAlign: 'right' }}>
                                <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleCancelOnclick}>
                                    Cancel
                                </Button>{' '}
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleSubmitOnclick}>
                                    Submit
                                </Button>
                           </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

// export default StepAction;

const mapStateToProps = (state) => {
    const allfences = state.fence.fenceData;
    const fenceObj = state.fence.fenceObj;
    // const fenceStatus = state.fence.type;
    const assetClass = state.mapboard.assetClass;
    const templateList = state.job.templateList;
    const props = {...state.job, assetClass, allfences, fenceObj, templateList}
    return props;
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getActionById, 
        getActionRule, 
        getActionAlert, 
        upsertAction, 
        getRuleTemplate, 
        loadAssetClass, 
        loadAllGeoFences, 
        upsertGeofence, 
        upsertActionAjax, 
        upsertActionRule, 
        upsertActionAlert, 
        getActions,
        getContact, 
        upsertContact, 
        deleteContact,
        getNotificationTemplate,
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasicAlertEditForm));