/* eslint-disable */
import { ConsoleLineIcon, ThermostatBoxIcon } from 'mdi-react';
import React, { Component, Fragment, useEffect, useState } from 'react';
import {Card, CardBody, Col, Row, Button, Container} from 'reactstrap';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import BatteryWidget from './BatteryWidget';
import DonutWidget from './DonutWidget';
import Tooltip from '@material-ui/core/Tooltip';
import { FaSearch } from 'react-icons/fa';
import {
    GREEN_COLOR, 
    RED_COLOR, 
    ORENGE_COLOR, 
    GREY_COLOR,
    TIME_ZONE,
} from '../../../constants/dataConstants';

class OccupancyBoard extends Component {
    constructor(props){
        super(props);
        this.id=[];
        this.state = {
            currentHour: 0,
            currentMin: 0
        }
    }
    componentdDidUpdate(){
        console.log("Board Updated!")
    }

    batteryRender = (cell) => {
        let batteryLevel;
        let batteryText;
        if(cell.OccupancyBattery){
            for (let n=0;n<cell.OccupancyBattery.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.OccupancyBattery[n].deviceid){
                        batteryLevel = Math.round(cell.OccupancyBattery[n].value);
                        
                        break;
                    }
                }
            }
            if(batteryLevel == null)
                batteryText = 'Unknown';
        }

        if(batteryLevel == "0"){
            batteryText = ' : Low'
        }else if(batteryLevel == "4"){
            batteryText = ' : Medium'
        }else if(batteryLevel == "5"){
            batteryText = ' : High'
        }else if(batteryLevel == "3" || batteryLevel == "2" || batteryLevel == "1"){
            batteryLevel = "0";
            batteryText = ' : Low'
        } else {
            batteryText = 'Unknown'
        }

        return(
            <div style={{paddingLeft: '38%'}}>
                <Tooltip title={"Battery"}>
                    <BatteryWidget level={parseInt(batteryLevel)} style={{ width: '23px', height: '45px' }} /> 
                </Tooltip> 
                {batteryText}
            </div>
            // <div>
            //     {/* <Row> */}
            //         <span style={{width: '100%'}}>
            //         <Tooltip title={"Battery"}>
            //             <BatteryWidget level={parseInt(batteryLevel)} style={{ width: '23px', height: '30px' }} /> 
            //         </Tooltip> 
            //             <span style={{maxWidth: '30%'}}>
            //                 {batteryText}
            //             </span>
            //         </span>
            //     {/* </Row> */}
            // </div>
        )
    }

    

    lastMessage = (cell, row) => {
        let lastTimeReceived;

        if(cell.LastMessageTime){
            for (let n=0;n<cell.LastMessageTime.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.LastMessageTime[n].deviceid){
                        lastTimeReceived = cell.LastMessageTime[n].value;
                        break;
                    }
                }
            }
            (lastTimeReceived !== null) ? lastTimeReceived = new Date(lastTimeReceived).toLocaleString('en-US', { timeZone: TIME_ZONE }) : lastTimeReceived = "null";
        }

        return (
            // <span>{lastTimeReceived}</span>
            `${lastTimeReceived}`
        )
    }

    monthlyOccupied = (cell, row) => {
        let redCount = [];
        if(this.props.countListData) {
            let countData = this.props.countListData;
            let countDataKeys = Object.keys(countData);
    
            for(let i in countDataKeys) {
                if(row.assetid.toString()) {
                    if(row.assetid === parseInt(countDataKeys[i])) {
                        Object.values(countData)[i].forEach((item, index) => {
                            let accessCounts = {access: item.red};
                            redCount.push(accessCounts);
                        });
                    }
                } 
            }
        }
        const calendarMonthAccess = redCount.reduce((calendarMonthAccess, object) => {
            return calendarMonthAccess + object.access;
        }, 0);

        return(
                `${calendarMonthAccess}` ? `${calendarMonthAccess}` : "N/A"
        )
    }

    lastOccupied = (cell, row) => {
        let lastTimeOccupied;
        if(cell.LastOccupancy){
            for (let n=0;n<cell.LastOccupancy.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.LastOccupancy[n].deviceid){
                        lastTimeOccupied = cell.LastOccupancy[n].value;
                        break;
                    }
                }
            }
            (lastTimeOccupied !== null) ? lastTimeOccupied = new Date(lastTimeOccupied).toLocaleString('en-US', { timeZone: TIME_ZONE }) : lastTimeOccupied = "null";
        }
        return (
            // <span>{lastTimeOccupied}</span>
            `${lastTimeOccupied}`
        )

    }
    
    occupancyStatus = (cell, row) => {
        let donutColor;
        let occupancyMessage;
        
        // const timer = () => {
        //     setTimeout(switchStatus, 360000)
        // }

        // const switchStatus = () => {
        //     if(occupancyMessage == "Recently Occupied"){
        //         occupancyMessage = "Unoccupied";
        //         donutColor = GREEN_COLOR;
        //     }
        // };
        // console.log("===cell", cell);
        // console.log("===row", row);
        
        if(cell.Alarm){
            let occupancyStatus;
            for (let n=0;n<cell.Alarm.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.Alarm[n].deviceid){
                        occupancyStatus = cell.Alarm[n].value;
                        break;
                    }
                }
            }
            switch(occupancyStatus){
                case('GREEN'):
                    occupancyMessage = "Unoccupied";
                    donutColor = GREEN_COLOR;
                    break;
                case("ORANGE"):
                    occupancyMessage = "Recently Occupied";
                    donutColor = ORENGE_COLOR;
                    // timer();
                    break;
                case("RED"):
                    occupancyMessage = "Occupied";
                    donutColor = RED_COLOR;
                    break;
                default:
                    occupancyMessage = "Unknown";
                    donutColor = GREY_COLOR;
            }


        }

        return (
            <div style={{ alignItems: 'flex-start', justifyContent: 'flex-start', verticalAlign: 'top', paddingLeft: '25%'}}>
                <DonutWidget style={{width: '35px', alignItems: 'stretch', justifyContent: 'flex-start', alignContent: 'flex-start'}} color={donutColor}/>
                <span>{occupancyMessage}</span>
            </div>
            // `${occupancyMessage}`
            )
    }   

    assetnameFormat = (cell, row) => {
        const handleDeviceOnClick = (e, assetname) => {
            e.preventDefault();
            this.props.toggle(true, row);
        }
        return (
            <a href={'#'} onClick={(e) => { handleDeviceOnClick(e, cell); }}>{cell}</a>
        )
    }

    batteryRenderCSV = (cell, row) => {
        let batteryLevel;

        if(cell.OccupancyBattery){
            for (let n=0;n<cell.OccupancyBattery.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.OccupancyBattery[n].deviceid){
                        batteryLevel = cell.OccupancyBattery[n].value;
                        break;
                    }
                }
            }
            if(batteryLevel == null)
                batteryLevel = 1;
        }
        
        switch(batteryLevel){
            case("5"):
                batteryLevel = "5";
                break;
            case("4"):
                batteryLevel = "4";
                break;
            case("0"):
                batteryLevel = "0";
                break;
            case("3"):
                batteryLevel = "0";
                break;
            case("2"):
                batteryLevel = "0";
                break;
            case("1"):
                batteryLevel = "0";
                break;
            default:
                batteryLevel = "Unknown"
        }
        
        return batteryLevel;

    }
    
    occupancyStatusCSV = (cell, row) => {
        if(cell.Alarm){
            let occupancyStatus;
            for (let n=0;n<cell.Alarm.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.Alarm[n].deviceid){
                        occupancyStatus = cell.Alarm[n].value;
                        break;
                    }
                }
            }
            let donutColor;
            let occupancyMessage;
            
            switch(occupancyStatus){
                case('GREEN'):
                    occupancyMessage = "Unoccupied";
                    donutColor = GREEN_COLOR;
                    break;
                case("ORANGE"):
                    occupancyMessage = "Recently Occupied";
                    donutColor = ORENGE_COLOR;
                    // timer();
                    break;
                case("RED"):
                    occupancyMessage = "Occupied";
                    donutColor = RED_COLOR;
                    break;
                default:
                    occupancyMessage = "Unknown";
                    donutColor = GREY_COLOR;
            }

            return occupancyMessage;
        }
    }

    monthlyOccupiedCSV = (cell, row) => {
        let redCount = [];
        if(this.props.countListData){
            let countData = this.props.countListData;
            let countDataKeys = Object.keys(countData);

            for(let i in countDataKeys) {
                if(row.assetid == parseInt(countDataKeys[i])) {
                    Object.values(countData)[i].forEach((item, index) => {
                        let accessCounts = {access: item.red};
                        redCount.push(accessCounts);
                    });
                }
            }
        }

        const calendarMonthAccess = redCount.reduce((calendarMonthAccess, object) => {
            return calendarMonthAccess + object.access;
        }, 0);

        return calendarMonthAccess;

    }
    /* monthlyOccupiedCSV = (cell) => {
        let totalCount = 0;
        let donutColor;
        let occupancyMessage;

        if(cell.Alarm){
            let occupancyStatus;
            for (let n=0;n<cell.Alarm.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.Alarm[n].deviceid){
                        occupancyStatus = cell.Alarm[n].value;
                        break;
                    }
                }
            }
            switch(occupancyStatus){
                case('GREEN'):
                    occupancyMessage = "Unoccupied";
                    donutColor = GREEN_COLOR;
                    break;
                case("ORANGE"):
                    occupancyMessage = "Recently Occupied";
                    donutColor = ORENGE_COLOR;
                    break;
                case("RED"):
                    occupancyMessage = "Occupied";
                    donutColor = RED_COLOR;
                    break;
                default:
                    occupancyMessage = "Unknown";
                    donutColor = GREY_COLOR;
            }
            if(occupancyStatus.case == "RED") {
                totalCount += 1;
            }

        }

        return totalCount;
    } */

    lastOccupiedCSV = (cell, row) => {
        let lastTimeOccupied;

        if(cell.LastOccupancy){
            for (let n=0;n<cell.LastOccupancy.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.LastOccupancy[n].deviceid){
                        lastTimeOccupied = cell.LastOccupancy[n].value;
                        break;
                    }
                }
            }
            (lastTimeOccupied !== null) ? lastTimeOccupied = new Date(lastTimeOccupied).toLocaleString('en-US', { timeZone: TIME_ZONE }) : lastTimeOccupied = "null";
        }

        return lastTimeOccupied;
    }

    lastMessageCSV = (cell, row) => {
        let lastTimeReceived;

        if(cell.LastMessageTime){
            for (let n=0;n<cell.LastMessageTime.length;n++){
                for(const property in this.id){
                    if(this.id[property]==cell.LastMessageTime[n].deviceid){
                        lastTimeReceived = cell.LastMessageTime[n].value;
                        break;
                    }
                }
            }
            (lastTimeReceived !== null) ? lastTimeReceived = new Date(lastTimeReceived).toLocaleString('en-US', { timeZone: TIME_ZONE }) : lastTimeReceived = "null";
        }

        return lastTimeReceived;
    }
    render(){
        let options = {
            noDataText: this.props.assetDataLoading ? 'Loading...' : 'No data to display',
        };
        this.id=[]
        let statusData = this.props.statusDataProp;
        let alarmList = this.props.alarmListProp;
        for(let i=0;i<statusData.length;i++){
            let data=statusData[i];
            let deviceid=null;
            data.attributes['LastOccupancy'].sort((a,b) => (a.value < b.value) ? 1 : ((b.value < a.value) ? -1 : 0));
            //console.log('data.attributesLastOccupanc')
            //console.log(data.attributes['LastOccupancy'])
            this.id.push(data.attributes['LastOccupancy'][0].deviceid)
        }
        //console.log('this.id')
        //console.log(this.id)
        // console.log("===this", statusData);
        return(
            <Card>
                <CardBody>
                    <BootstrapTable data = { statusData } options={ options } tableHeaderClass='header_white' exportCSV csvFileName='Occupancy Monitoring.csv'>
                        <TableHeaderColumn dataField='assetname' dataFormat={this.assetnameFormat} filter={{type: 'TextFilter', placeholder:'Search...', delay: 1000}} dataAlign='center' csvHeader='Station Name' isKey>Station Name</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.occupancyStatus} headerAlign='center' /* dataAlign='center' */ csvFormat={this.occupancyStatusCSV} csvHeader='Current Occupancy Status'>Current Occupancy Status</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.batteryRender}  headerAlign='center' /* dataAlign='center' */ csvFormat={this.batteryRenderCSV} csvHeader='Battery Level'>Battery Level</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.monthlyOccupied} dataAlign='center' csvFormat={this.monthlyOccupiedCSV} csvHeader='Monthly Access Count'>Monthly Access Count</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.lastOccupied} dataAlign='center' csvFormat={this.lastOccupiedCSV} csvHeader='Last Amount of Time Occupied' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>Last Amount of Time Occupied</TableHeaderColumn>
                        <TableHeaderColumn dataField='attributes' dataFormat={this.lastMessage} dataAlign='center' csvFormat={this.lastMessageCSV} csvHeader='Last Message Received' filterFormatted filter={{type: 'TextFilter', placeholder: 'Search...'}}>Last Message Received</TableHeaderColumn>
                    </BootstrapTable>
                </CardBody>
            </Card>
        )
    }
}


export default OccupancyBoard;