/* eslint-disable */
import React, { Component, useState } from "react";
import { Chip } from '@material-ui/core';
import { DeleteIcon, DoneIcon } from 'mdi-react';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import ConfirmDeleteDialog from '../../../shared/components/Dialog/ConfirmationDialog';

class ChipGrouping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: {},
            userObj: getCurrentUser()

        }
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps, nextContext) {

    }
    handleDelete = (itemName) => {
        var obj = {
            type: this.props.grouping,
            companyId: this.props.user.companyId,
            resellerId: this.props.user.id,
            name: itemName.name,
            status: 0
        }

        this.props.update(obj)
    }

    handleOnClick = (item) => {
        if (item.name != this.state.clicked.name) {
            this.setState({
                clicked: item
            }, () => { this.props.onChipChange(item, this.props.grouping) })
        }
        else {
            this.setState({
                clicked: {}
            }, () => { this.props.onChipChange({}, this.props.grouping) })
        }
    }

    render() {
        return (
            <div className="chip-background" style={{ borderRadius: "5px" }}>
                {
                    this.props.list.map(item => {
                        return (

                            <Chip
                                avatar={(this.state.clicked.name == item.name) ? <DoneIcon /> : null}
                                className={(this.state.clicked.name == item.name) ? "chip-active" : "chip-inactive"}
                                // deleteIcon={(hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ? <DeleteIcon /> : null}
                                deleteIcon={(hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ? <ConfirmDeleteDialog label={""}
                                    msg={'Do you want to delete the selected business unit? '} onConfirm={()=>{this.handleDelete(item)}} /> : null}
                                onDelete={(hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ? () => this.handleDelete(item) : null}
                                label={item.name}
                                onClick={(hasRole(this.state.userObj, ['SUPERADMIN', 'ADMIN', "CLIENTADMIN"])) ? () => this.handleOnClick(item) : null}
                                style={{ padding: "10px", margin: "10px" }}
                            />

                        )
                    }
                    )
                }
            </div>
        );
    }
}
export default ChipGrouping;