/* eslint-disable */
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Container, Col, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {BackButton, NextButton} from '../../../shared/components/Button/IconButton'; 
import { toast } from "react-toastify";

class InventoryPurchaseSummary extends Component {
    constructor(props){
        super(props);
    }

    Submit = () => {
        const {itemList, currentSupplier, userObj, sendOrder, onCheckout} = this.props;
        if (currentSupplier && currentSupplier.email) {
            if (Array.isArray(itemList) && itemList.length > 0){
                let emailEle = <div>{this.renderSummary()}</div>;
                let message = ReactDOMServer.renderToString(emailEle);
                let to = currentSupplier.email;
                let from = userObj.company && userObj.company.email? userObj.company.email : 'liao_xinqing@yahoo.ca';
                let subject = 'Itsp Order';

                let params = {to, from, subject, message};
                sendOrder(params)
                .then(res => {
                    if (res.status == 200 && res.data.status == "Sucess") {
                         
                        toast.success("The order has been successfully sent out!");
                        onCheckout();
                    }else{
                         
                        toast.error("Error occurred when sending your email, please send again.");
                    }
                    
                }).catch(err => {
                     
                    toast.error("Error occurred when sending your email, please send again.");
                });

            }else{
                toast.error('No items are selected');
            }

        }else{
            toast.error('Can not find supplier email');
        }
        
    }

    renderSummary = () => {
        const {shippingAddress, itemList, supplierItemList} = this.props;
        let orderItems = [];
        if (Array.isArray(itemList) && Array.isArray(supplierItemList)){
            itemList.forEach((item, index)=>{
                let foundItem = supplierItemList.find((sitem, sindex)=>(sitem.sku == item.sku));
                if (foundItem) {
                    orderItems.push({...foundItem, ...{quantity: item.qty}})
                }
            });
        }
        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <h4 class="mb-3">Shipping address</h4>
                        <div class="mb-1">
                            {shippingAddress.name && <div><label>{shippingAddress.name}</label><br/></div>}
                            {shippingAddress.address && <div><label>{shippingAddress.address}</label><br/></div>}
                            {shippingAddress.address2 && <div><label>{shippingAddress.address2}</label><br/></div>}
                            <label>{shippingAddress.city + " " + shippingAddress.province + " " + shippingAddress.country + " " + shippingAddress.zip}</label>
                            {shippingAddress.phone && <div><label>{"Tel: " + shippingAddress.phone}</label><br/></div>}
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop:'15px', marginBottom: '20px'}}>
                    <Col md={12}>
                        <h4 class="mb-3">Order Items</h4>
                        <div class="mb-1">
                        <BootstrapTable 
                            data={ orderItems }  
                            // tableHeaderClass = 'header_white' 
                            pagination = { false } 
                              
                             
                            bordered={ false }
                            ref='table' 
                        >
                            <TableHeaderColumn dataField='sku' isKey={ true } dataAlign="center" width='10%' >SKU</TableHeaderColumn>
                            <TableHeaderColumn dataField='name' dataAlign="center" dataSort width='15%' >Item</TableHeaderColumn>
                            <TableHeaderColumn dataField='modal' dataAlign="center" dataSort width='15%' >Modal</TableHeaderColumn>  
                            <TableHeaderColumn dataField='vender' dataAlign="center" dataSort width='15%'>Vender</TableHeaderColumn>  
                            <TableHeaderColumn dataField='price' dataAlign="center" dataSort width='15%' >Unit Price</TableHeaderColumn>  
                            <TableHeaderColumn dataField='network' dataAlign="center" dataSort >NETWORK</TableHeaderColumn>
                            <TableHeaderColumn dataField='quantity' dataAlign="center"  width='10%'>QUANTITY</TableHeaderColumn> 
                            
                            {/* <TableHeaderColumn dataField='card' dataFormat={this.tableActionColFormater}  dataAlign="center" dataSort width='10%' >Payment card</TableHeaderColumn> */}
                                                    
                        </BootstrapTable>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

    render(){
        return (
            <div>
                {this.renderSummary()}
                <div class='row'>
                    <div class='col-md-6' style={{display:'flex', justifyContent:'flex-start'}}>
                    <BackButton
                        onClick={this.props.onBackClick} 
                        label="Back"
                        size={30}
                        color = 'black'
                    />
                    </div>
                    <div class='col-md-6' style={{display:'flex', justifyContent:'flex-end'}}>
                        <Button
                            onClick={this.Submit} 
                            // color='primary'
                            variant="primary" 
                            color="custom"
                        >
                            Send Order
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default InventoryPurchaseSummary;