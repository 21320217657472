/* eslint-disable */
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {
    sortCaret,
    // AdjustableDiv
} from '../../../shared/components/Table/tableElements';
import { getCurrentUser, hasRole } from '../../../factories/auth';
import {Col} from "reactstrap";

class ClientInactiveSIMList extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    onBeforeSaveCell = (row, cellName, cellValue) => {
        if (this.props.userObj && hasRole(this.props.userObj, ['SUPERADMIN', 'ADMIN', 'USER', 'CLIENTADMIN', 'CLIENTUSER'])) {
            if (cellValue != "") {
                return true;
            }
            else {
                toast.error("name can't be empty")
                return false;
            }
        }
        return false;

    }

    csvFormatter = (cell, row) => {
        return cell + '\t';
    }

    onAfterSaveCell = (row, cellName, cellValue) => {
        this.props.setSimName({ "id": row.inventoryId, "resellerId": row.resellerId, "itemName": cellValue })
    }
    carrierNameFormatter = (cell) => {
        if (cell != null) {
            return `${cell.carrierName}`
        }
    }

    renderPaginationPanel = (props) => {
        let dropdown = props.components.sizePerPageDropdown;
        let beginning;
        let totalData;
        beginning=(props.currPage - 1) * dropdown.props.currSizePerPage +1 ;
        totalData=(parseInt(beginning) + parseInt(dropdown.props.currSizePerPage))-1 ;
        return (
            <>
                {
                    (this.props.inactiveSIMList.length == 0) ?
                        <>
                            <Col md={12} xs={12} sm={12} lg={12} style={{ display: "block", fontSize: "14px" }}>
                                <span style={{ float: "right" }}> 0 - 0 of 0</span>
                            </Col>
                        </>
                        : <>
                            <Col md={6} xs={6} sm={6} lg={6}>
                                {props.components.sizePerPageDropdown}
                            </Col>
                            <Col md={6} xs={6} sm={6} lg={6} style={{ display: "block", fontSize: "14px" }}>

                                <span style={{ float: "right" }}>{props.components.pageList}</span>
                                <span style={{ float: "right", fontSize: "14px", verticalAlign: "text-top", marginRight: "10px", marginTop: "9px" }}> {beginning} - {totalData} of {this.props.inactiveSIMList.length}</span>
                            </Col>

                        </>

                }
            </>
        );
    }

    render() {
        let options = {
            noDataText: "No Inventory Found",
            sizePerPageList: [{
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            }],
            paginationPanel: this.renderPaginationPanel,
        }
        let cellEditProp = {
            //mode: 'click',
            //blurToSave: true,
            //beforeSaveCell: this.onBeforeSaveCell, // a hook for before saving cell
            //afterSaveCell: this.onAfterSaveCell  // a hook for after saving cell
        };

        return (

            <BootstrapTable
                data={this.props.inactiveSIMList}
                tableHeaderClass='header_white'
                options={options}
                exportCSV
                csvFileName='Client_Inactive_Device.csv'

                bordered={false}
                height='100%'
                pagination={true}
                //expandableRow={this.isExpandableRow}
                //expandComponent={this.expandComponent}
                expandColumnOptions={{
                    //expandColumnComponent: this.expandColumnComponent,
                }}
                cellEdit={cellEditProp}
            >
                <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} csvHeader='Name'>Name</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='identifierType' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} editable={false} csvHeader='Identifier Type'>Identifier Type</TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='identifierValue' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} editable={false} csvHeader='Identifier Value' csvFormat={this.csvFormatter}>Identifier Value </TableHeaderColumn>
                <TableHeaderColumn row='0' rowSpan='1' dataField='carrierName' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: ' ' }} caretRender={sortCaret} editable={false} hidden={(this.props.isAdmin) ? false : true} csvHeader='Carrier'>Carrier</TableHeaderColumn>
            </BootstrapTable>
        )
    }

}

export default ClientInactiveSIMList;