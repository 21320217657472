/* eslint-disable */
import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Col, Row, Label, Input, Button, Container } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Card, CardTitle, CardBody } from 'reactstrap';
import { EditableText } from '../../Asset/components/ClassDisplayDetail';
import {
    // loadDevice, 
    loadAssetClass,
} from '../../../redux/actions/mapActions';
import {
    getAssetList,
} from '../../../redux/actions/dashboardActions';
import {
    getAttributes,
} from '../../../redux/actions/attributeMgmtAction';
import Select from 'react-select';
import { getCurrentUser, hasRole } from '../../../factories/auth';

class AttrParamEditModal extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            tital: 'Dashboard Element',
            modalList: this.props.modalList,
            devices: [],
            currentAssetClassId: null,
            currentAssetId: null,
            currentDeviceId: null,
            currentAttrId: null,
            customStyles: {}
            // selectedDeviceTypeId: null,
            // modal: true,
        }
        this.state.customStyles = {
            option: (provided, state) => ({
                ...provided,
                color: "black",
                '&:active': { backgroundColor: this.userObj.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? this.userObj.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                height: 35,
                minHeight: 35,
                borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? this.userObj.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }

    componentDidMount() {
        let parameter = this.props.parameter;
        if (parameter) {
            this.setState({
                currentAssetId: parameter.assetid,
                currentDeviceId: parameter.deviceid ? parameter.deviceid : null,
                currentAttrId: parameter.attrid ? parameter.attrid : null,
                currentAttrName: parameter.attrname ? parameter.attrname : null,
                currentName: this.props.layout ? this.props.layout.name : prevProps.layout,
                layout: this.props.layout ? this.props.layout : prevProps.layout

            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(prevProps.parameter) !== JSON.stringify(this.props.parameter) ||
            (prevProps.assetClasses.length != this.props.assetClasses.length) ||
            !this.state.currentAssetClassId
        ) {
            let parameter = this.props.parameter
            if (parameter.assetid) {
                if (this.props.assetClasses.length > 0) {
                    let isBreak = false;
                    let assetClass = this.props.assetClasses.find(a => {
                        return a.assets.length > 0 && a.assets.some(asset => asset.assetid == parseInt(parameter.assetid))
                    })
                    // console.log('assetClass', assetClass)
                    if (assetClass) {
                        this.setState({
                            currentAssetClassId: assetClass.assetclassid,
                            currentAssetId: parameter.assetid,
                            currentDeviceId: parameter.deviceid ? parameter.deviceid : null,
                            currentAttrId: parameter.attrid ? parameter.attrid : null,
                            currentAttrName: parameter.attrname ? parameter.attrname : null,
                            currentName: this.props.layout ? this.props.layout.name : prevProps.layout,
                            layout: this.props.layout ? this.props.layout : prevProps.layout

                        }, () => {
                            this.props.getAssetList({ assetclassid: assetClass.assetclassid })
                        })
                    }
                }
            }
        }
        if (this.props.assets && (JSON.stringify(prevProps.assets) !== JSON.stringify(this.props.assets) || prevState.currentAssetId !== this.state.currentAssetId)) {
            this.setDeviceList(this.props.assets);
        }

        if (prevProps.modal !== this.props.modal && prevState.currentAssetClassId) {
            this.props.getAssetList({ assetclassid: prevState.currentAssetClassId })
        }


    }


    handleInputChange = (newVal) => {
        this.setState((prevState) => {
            if (prevState.layout) {
                prevState.layout.name = newVal;
                return ({
                    currentName: newVal,
                    layout: prevState.layout,
                })
            } else {
                return null;
            }
        });
    }

    renderTitle = () => {
        return (

            <Row style={{ flexGrow: 1, justifyContent: 'left', alignItems: 'left', marginBottom: 5 }}>
                <Col md={3}>
                    <Label style={{ display: 'inline' }} ><h4><b> Name</b></h4></Label>
                </Col>
                <Col md={8}>
                    {/* <Label style={{display: 'inline'}} >{this.props.fencename}</Label> */}
                    <EditableText
                        type='text'
                        defaultValue={this.props.layout ? this.props.layout.name : ''}
                        exitOnEnterKeyPress={false}
                        onSave={newVal => { this.handleInputChange(newVal) }}
                    />
                </Col>
            </Row>
        )
    }

    onAssetClassChange = (selectedOption) => {
        const assetClassId = selectedOption ? selectedOption.value : null;
        this.props.getAssetList({ assetclassid: assetClassId });
        this.setState({
            currentAssetClassId: assetClassId,
            currentAssetId: null,
            currentDeviceId: null,
            currentAttrId: null,
            devices: [],
        });
    }


    renderAssetClass = () => {
        const assetClassOptions = [{ value: null, label: 'NONE' }, ...this.props.assetClasses.map((assetclass) => ({
            value: assetclass.assetclassid,
            label: assetclass.assetclassname,
        }))];

        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="assetclass" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset Class</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        id="assetclass"
                        name="assetclass"
                        options={assetClassOptions}
                        value={assetClassOptions.find(option => option.value === this.state.currentAssetClassId)}
                        onChange={this.onAssetClassChange}
                        isClearable={true}
                        placeholder="Select Asset Class"
                        styles={this.state.customStyles}
                    />
                </Col>
            </Row>
        )
    }

    onAssetChange = (selectedOption) => {
        const assetId = selectedOption ? selectedOption.value : null;
        this.setState({
            currentAssetId: assetId,
            currentDeviceId: null,
            currentAttrId: null,
        }, () => {
            this.setDeviceList(this.props.assets);
        });
    }

    renderAsset = () => {
        const assetOptions = [{ value: null, label: 'NONE' }, ...this.props.assets.map((asset) => ({
            value: asset.assetid,
            label: asset.assetname,
        }))];
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="asset" style={{ display: 'inline', width: "100%" }} ><h4><b>Asset</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        id="asset"
                        name="asset"
                        options={assetOptions}
                        value={assetOptions.find(option => option.value === this.state.currentAssetId)}
                        onChange={this.onAssetChange}
                        isClearable={true}
                        placeholder="Select Asset"
                        styles={this.state.customStyles}
                    />
                </Col>
            </Row>
        )
    }

    onDeviceChange = (selectedOption) => {
        const deviceId = selectedOption ? selectedOption.value : null;
        this.setState({
            currentDeviceId: deviceId,
        }, () => {
            this.setAttrList();
        });
    }

    setDeviceList = (assets) => {
        // let devices = this.state.devices ? this.state.devices : [];
        if (assets && this.state.currentAssetId) {
            let assetObj = assets.find(asset => asset.assetid === this.state.currentAssetId);
            if (assetObj) {
                let devices = assetObj.devices;
                this.setState({
                    devices: devices,
                }, () => {

                    this.setAttrList();
                });
            }
        }

    }

    renderDevice = () => {
        //
        // let devices = [];
        // if (this.props.assets && this.state.currentAssetId) {
        //     let assetObj = this.props.assets.find((asset)=>(asset.assetid == this.state.currentAssetId));
        //     if (assetObj) {
        //         devices = assetObj.devices;
        //     }
        // }
        const deviceOptions = [{ value: null, label: 'NONE' }, ...this.state.devices.map((device) => ({
            value: device.deviceid,
            label: device.devicename,
        }))];
        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="device" style={{ display: 'inline', width: "100%" }} ><h4><b>Device</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        id="device"
                        name="device"
                        options={deviceOptions}
                        value={deviceOptions.find(option => option.value === this.state.currentDeviceId)}
                        onChange={this.onDeviceChange}
                        isClearable={true}
                        placeholder="Select Device"
                        styles={this.state.customStyles}
                    />
                </Col>
            </Row>
        )
    }

    setAttrList = () => {
        // console.log('set attr list')
        if (this.state.devices && this.state.currentDeviceId && this.state.currentDeviceId !== this.state.previousDeviceId) {
            let deviceObj = this.state.devices.find((device) => (device.deviceid === this.state.currentDeviceId));
            if (deviceObj) {
                this.setState({ previousDeviceId: this.state.currentDeviceId });
                this.props.getAttributes(deviceObj.devicetype);
            }
        }
    }

    onAttrChange = (selectedOption) => {
        const attrId = selectedOption ? selectedOption.value : null;
        const attrName = selectedOption ? selectedOption.attributename : '';

        this.setState({
            currentAttrId: attrId,
            currentAttrName: attrName
        });
    }

    renderAttribute = () => {
        const attributeOptions = [{ value: null, label: 'NONE' }, ...this.props.attributes.map((attr) => ({
            value: attr.attributeid,
            label: attr.displayname,
            attributename: attr.attributename,
        }))];

        return (
            <Row>
                <Col md={3} style={{ textAlign: 'left' }}>
                    <Label for="attribute" style={{ display: 'inline', width: "100%" }} ><h4><b>Attribute</b></h4></Label>
                </Col>
                <Col md={8}>
                    <Select
                        id="attribute"
                        name="attribute"
                        options={attributeOptions}
                        value={attributeOptions.find(option => option.value === this.state.currentAttrId)}
                        onChange={this.onAttrChange}
                        isClearable={true}
                        placeholder="Select Attribute"
                        styles={this.state.customStyles}
                    />
                </Col>
            </Row>
        )
    }

    onCancleClick = (e) => {
        this.props.toggle();
    }
    onOkClick = (e) => {
        this.setState((prevState) => {
            if (prevState.layout) {
                let parameter = {
                    assetid: prevState.currentAssetId,
                    deviceid: prevState.currentDeviceId,
                    attrid: prevState.currentAttrId,
                    attrname: prevState.currentAttrName,
                }
                prevState.layout.parameter = JSON.stringify(parameter);
                return ({
                    layout: prevState.layout,
                })
            } else {
                return null;
            }
        }, () => {
            this.props.onEditSubmit(this.state.layout);
        });
        // this.props.onEditSubmit(this.state.layout);
        this.props.toggle();
    }

    render() {
        return (
            <Modal
                animationtype="slide"
                transparent="false"
                isOpen={this.props.modal}
                toggle={this.props.toggle}
                size={'lg'}
            >
                <ModalHeader
                    toggle={this.props.toggle}
                //style={{ backgroundColor: '#70bbfd', color: 'white',}}
                >
                    {this.state.tital}
                </ModalHeader>
                <ModalBody style={{ marginLeft: 20, marginRight: 20 }}>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderTitle()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {/* {this.props.layout && this.renderParameterInput(this.props.layout.type)} */}
                            {this.renderAssetClass()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderAsset()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderDevice()}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }}>
                        <Col md={12}>
                            {this.renderAttribute()}
                        </Col>
                    </Row>
                    {/* <br/> */}

                    {/* <Row style={{flexGrow: 1, justifyContent: 'center', alignItems: 'center'}}>
                    </Row>                    */}
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={this.onOkClick} >OK</Button>{' '}
                    <Button color="custom" onClick={this.onCancleClick}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

// export default AttrParamEditModal;

function mapStateToProps(state) {
    // const types = state.mapboard.types;
    const assets = state.dashboard.assetList;
    const assetClasses = state.mapboard.assetClass;
    const attributes = state.attributemgmt.attributes;
    const props = {
        // types,  
        assetClasses,
        assets,
        attributes,
    };
    return props;
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        // loadDevice, 
        loadAssetClass,
        getAssetList,
        getAttributes,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AttrParamEditModal);