/* eslint-disable */
import React, { Component } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import ActiveUsageCard from './ActiveUsage/ActiveUsageCard';
import DashBoardActiveClientTable from './TotalUsage/DashBoardActiveClientTable';
import DashboardInfo from './DashboardInfo';
import DashboardGraph from './DashboardGraph';
import './Dashboard.css';


class DashboardPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  handleAssetChange = (value) => {

    this.props.handleFilterChange({
      currentAsset: value,
      // startTime: null,
      // endTime: null,
    });
  }



  render() {
    return (
      <Card>
        <CardBody>
          <DashboardInfo
            loading={this.props.loading}
            dashboardActivatedSimList={this.props.dashboardActivatedSimList}
            activeSIMList={this.props.activeSIMList}
            inactiveSIMList={this.props.inactiveSIMList}
            suspendedSIMList={this.props.suspendedSIMList}
            activityReportList={this.props.activityReportList}
          />
          <DashboardGraph
            loading={this.props.loading}
            LoadUsageDate={this.props.LoadUsageDate}
            type={this.props.type}
            currentUserObj={this.props.currentUserObj}
          />
          {/*
            <DashBoardActiveClientTable
              refreshTime={new Date()}
              getClients={this.props.getClients}
              clientList={this.props.clientList}
              consumerList={this.props.consumerList}
              getConsumers={this.props.getConsumers}
              currentUserObj={this.props.currentUserObj}
            />
          */}
          <ActiveUsageCard
            LoadUsageDate={this.props.LoadUsageDate}
            exportSimListData={this.props.exportSimListData}
            pusageType={this.props.type}
            filterIdentifierValueSort={this.props.filterIdentifierValueSort}
            filterDeviceNameSort={this.props.filterDeviceNameSort}
            filterClientNameSort={this.props.filterClientNameSort}
            filterStatusSort={this.props.filterStatusSort}
            filterDataUsageSort={this.props.filterDataUsageSort}
            filterStartTime={this.props.filterStartTime}
            filterEndTime={this.props.filterEndTime}
            currentUserObj={this.props.currentUserObj}
            onPageChange={this.props.onPageChange}
            page={this.props.page}
            pageSize={this.props.pageSize}
          />
        </CardBody>

      </Card>
    );
  }

}
export default DashboardPanel;
