/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { sortCaret } from '../../../../shared/components/Table/tableElements';

import { BackButton, NextButton } from '../../../../shared/components/Button/IconButton';

class SelectEndUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumerList: [],
      endUser: null
    }
  }
  componentDidMount() {

  }
  componentWillReceiveProps(nextProps, nextContext) {
    var list = []
    if (nextProps.consumerList.length > 0) {
      list = nextProps.consumerList.filter(consumer => consumer.resellerCode == nextProps.selectedRow.resellerCode);
    }
    this.setState({
      consumerList: list
    })

  }

  onRowSelect = (row, isSelected, e) => {
    if (isSelected) {
      this.setState({ endUser: row })
    }
    else {
      this.setState({ endUser: null })
    }
  }

  handleOnNext = () => {
    this.props.onNext(this.state.endUser)

  }
  handleOnBack = () => {
    this.setState({ endUser: null });
    this.props.onBack();
  }

  render() {

    const selectRowProp = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      columnWidth: '40px',
      bgColor: '#F5EBCF',
      selected: [(this.state.endUser != null) ? this.state.endUser.name : ""]
    };
    var customerList = [];
    if (this.props.consumerList.length > 0) {
      customerList = this.props.consumerList.map((customerView) => {

        let nameData = ''
        let resellerData = ''
        let countryData = ''
        let lineOfBusinessData = ''
        let divisionData = ''
        let customerData = ''
        let usernameData = ''

        if (
          customerView != null &&
          customerView.customer != null &&
          customerView.customer.businessName != null &&
          customerView.customer.businessName != ""
        ) {
          nameData = customerView.customer.businessName;
        } else {
          if (
            customerView != null &&
            customerView.customer != null &&
            customerView.customer.firstName != null &&
            customerView.customer.firstName != ""
          ) {
            nameData = customerView.customer.firstName;
          }
        }

        if (
          customerView != null &&
          customerView.resellerName != null &&
          customerView.resellerName != ""
        ) {
          resellerData = customerView.resellerName;
        }

        if (
          customerView != null &&
          customerView.customer != null &&
          customerView.customer.businessUnit != null &&
          customerView.customer.businessUnit.country != null &&
          customerView.customer.businessUnit.country.name != null
        ) {
          countryData = customerView.customer.businessUnit.country.name;
        }

        if (
          customerView != null &&
          customerView.customer != null &&
          customerView.customer.businessUnit != null &&
          customerView.customer.businessUnit.lineOfBusiness != null &&
          customerView.customer.businessUnit.lineOfBusiness.name != null
        ) {
          lineOfBusinessData =
            customerView.customer.businessUnit.lineOfBusiness.name;
        }

        if (
          customerView != null &&
          customerView.customer != null &&
          customerView.customer.businessUnit != null &&
          customerView.customer.businessUnit.division != null &&
          customerView.customer.businessUnit.division.name != null
        ) {
          divisionData = customerView.customer.businessUnit.division.name;
        }

        if (customerView != null && customerView.customer != null) {
          customerData = customerView.customer;
        }

        if (customerView != null && customerView.userName != null) {
          usernameData = customerView.userName;
        }

        return {
          name: nameData
                  /*customerView != null &&
                  customerView.customer != null &&
                  customerView.customer.businessName != null &&
                  customerView.customer.businessName != ""
                    ? customerView.customer.businessName
                    : customerView.customer.firstName ? customerView.customer.firstName : ''*/,
          resellerName: resellerData,
          country: countryData
                  /*customerView.customer.businessUnit != null
                    ? customerView.customer.businessUnit.country.name
                    : ""*/,
          lineOfBusiness: lineOfBusinessData
                  /*customerView.customer.businessUnit != null
                    ? customerView.customer.businessUnit.lineOfBusiness.name
                    : ""*/,
          division: divisionData
                  /*customerView.customer.businessUnit != null
                    ? customerView.customer.businessUnit.division.name
                    : ""*/,
          customer: /*customerView.customer*/customerData,
          username: /*customerView.userName*/usernameData,
        };
      });
    }
    return (
      <div>
        <Row>
          <Col md={8} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <BackButton
              onClick={this.handleOnBack}
              label="Back"
              size={30}
              color='black'
            />
          </Col>
          <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          </Col>
          <Col md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <NextButton
              onClick={this.handleOnNext}
              label="Submit"
              size={30}
              color='black'
            />
          </Col>
        </Row>
        <h3>Select End User SIM(s)</h3>
        <br />
        <BootstrapTable
          data={customerList}
          tableHeaderClass='header_white'
          selectRow={selectRowProp}
          bordered={false}
          height='100%'
          pagination
        >
          <TableHeaderColumn row='0' rowSpan='1' dataField='name' dataSort isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Consumer' }} caretRender={sortCaret}>Consumer</TableHeaderColumn>
          <TableHeaderColumn row='0' rowSpan='1' dataField='country' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Country' }} caretRender={sortCaret}>Country</TableHeaderColumn>
          <TableHeaderColumn row='0' rowSpan='1' dataField='lineOfBusiness' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'LOB' }} caretRender={sortCaret}>LOB</TableHeaderColumn>
          <TableHeaderColumn row='0' rowSpan='1' dataField='division' dataSort dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Department' }} caretRender={sortCaret}>Department</TableHeaderColumn>
        </BootstrapTable>
      </div>

    )
  }
}
export default SelectEndUser