/* eslint-disable */
import React, { Component } from 'react';
import { Col, Container, Row, Card, CardBody, Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup } from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { EditTextButton } from '../../../../shared/components/Button/IconButton';

class StaticData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showItemModal: false,
            simId: 0,
            simReseller: 0,
            simPrice: 0,
            simItemDescription: "",
            simItemName: "",
            carrier: -1
        };
    }

    priceFormatter = (cell, row) => {
        var per = "";
        if (row.billCycle == 30) {
            per = "/month"
        }
        else if (row.billCycle == 365) {
            per = "/year"
        }
        var price = cell.toFixed(2);
        return `$ ${price}${per}`;
    }

    cellItemEditButton = (cell, row, enumObject, index) => {
        return (
            <div>
                <EditTextButton label={'Edit'} onClick={(e) => { this.onEditItemOpen(row) }} />
            </div>
        );
    };

    onItemOpen = () => {
        this.setState({
            showItemModal: true,
            simId: 0,
            simReseller: 0,
            simItemDescription: "",
            simPrice: "",
            simItemName: "",
            carrier: -1
        })
    }

    onEditItemOpen = (row) => {

        this.setState({
            showItemModal: true,
            simId: row.itemId,
            companyCode: this.props.currentUserObj.companyCode,
            simItemDescription: row.itemDescription,
            simPrice: row.unitPrice,
            simItemName: row.itemName,
            carrier: row.carrier.carrierId
        })

    }
    onItemClose = () => {
        this.setState({
            showItemModal: false
        })
    }

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    validateForm = (Management) => {
        if (Management == "item") {
            if (this.state.simItemDescription != "" && this.state.simItemName != "" && !isNaN(this.state.simPrice)) {
                if (parseFloat(this.state.simPrice) >= 0 && parseFloat(this.state.carrier) >= 0) {
                    return true;
                }
            }
        }
        return false;

    }

    onItemSubmit = () => {
        var data = {};
        if (this.state.simId > 0) {
            data = {
                "id": this.state.simId,
                "companyCode": this.props.currentUserObj.companyCode,
                "itemName": this.state.simItemName,
                "itemDescription": this.state.simItemDescription,
                "unitPrice": this.state.simPrice,
                "carrierId": this.state.carrier
            }
        }
        else {
            data = {
                "companyCode": this.props.currentUserObj.companyCode,
                "itemName": this.state.simItemName,
                "itemDescription": this.state.simItemDescription,
                "unitPrice": this.state.simPrice,
                "carrierId": this.state.carrier
            }
        }
        this.props.SetDeviceType(data);
        this.onItemClose();
    }

    renderItemModal() {
        return (
            <Modal
                style={{ maxWidth: '800px' }}
                isOpen={this.state.showItemModal}
                size='lg'
                contentClassName="custom-modal-style"
            >
                <ModalHeader toggle={this.onItemClose}>{(this.state.simId != 0) ? `Customize` : `Add New Device Type`}</ModalHeader >
                <ModalBody >
                    <Col md={12}>
                        <Row>
                            <Col md={2} >
                                <span>Carrier <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    type='select'
                                    name='carrier'
                                    id='carrier'
                                    placeholder='Carrier'
                                    className="form-control"
                                    onChange={this.onInputChange}
                                    value={this.state.carrier}
                                >
                                    <option key={-1} value={-1}>None Selected</option>
                                    {this.props.carrierList.map((carrier, index) => (
                                        (this.state.carrier == carrier.carrierId) ? <option key={index} value={carrier.carrierId} selected>{carrier.carrierName}</option> : <option key={index} value={carrier.carrierId}>{carrier.carrierName}</option>
                                    ))}
                                </Input>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Name <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simItemName'
                                    id='simItemName'
                                    placeholder='Item Name'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.simItemName}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Description <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                {(this.state.simId != 0) ?
                                    <Input
                                        type='select'
                                        name='simItemDescription'
                                        id='simItemDescription'
                                        placeholder='Item Type'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        disabled={true}
                                        value={this.state.simItemDescription}
                                    >
                                        {["None Selected", "SIM CARD"].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </Input>
                                    :

                                    <Input
                                        type='select'
                                        name='simItemDescription'
                                        id='simItemDescription'
                                        placeholder='Item Type'
                                        className="form-control"
                                        onChange={this.onInputChange}
                                        disabled={false}
                                        defaultValue={this.state.simItemDescription}
                                    >
                                        {["None Selected", "SIM CARD"].map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </Input>
                                }
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={2} >
                                <span>Price <span style={{ color: "red" }}>*</span></span>
                            </Col>
                            <Col md={8}>
                                <Input
                                    name='simPrice'
                                    id='simPrice'
                                    placeholder='Price'
                                    onChange={this.onInputChange}
                                    className="form-control"
                                    defaultValue={this.state.simPrice}
                                />
                            </Col>
                        </Row>
                        <br />
                    </Col>
                </ModalBody>
                <ModalFooter>
                    <Button outline color='custom' type="reset" onClick={this.onItemSubmit} disabled={!this.validateForm("item")}>
                        Submit
                    </Button>
                    <Button outline color='secondary' type="reset" onClick={this.onItemClose} >
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }

    render() {

        return (
            <div>
                {this.renderItemModal()}
                <InsertButton
                    btnText='Add Device Types'
                    btnContextual='btn-success'
                    btnGlyphicon='glyphicon-edit'
                //onClick={this.onItemOpen}
                >
                    Add CSV File
                </InsertButton>
                <div class="d-none"><BootstrapTable
                    data={this.props.SIMTypeList}
                    tableHeaderClass='header_white'
                    striped
                    hover
                    hidden={true}
                    bordered={false}
                    height='100%'
                    bodyStyle={{ overflow: 'overlay' }}
                    containerStyle={{ fontSize: "1vw" }}

                    pagination
                >
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemName' isKey={true} dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Name' }} dataSort >SIM Name</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='itemDescription' dataAlign='center' filter={{ type: 'TextFilter', placeholder: 'Description' }} dataSort>SIM Description</TableHeaderColumn>
                    <TableHeaderColumn row='0' rowSpan='1' dataField='unitPrice' dataFormat={this.priceFormatter.bind(this)} dataAlign='center' filter={{ type: 'NumberFilter', delay: 1000 }} dataSort>Price</TableHeaderColumn>
                    <TableHeaderColumn
                        dataField='Operations'
                        dataAlign="center"
                        dataFormat={this.cellItemEditButton.bind(this)}
                    >
                        Operations
                    </TableHeaderColumn>
                </BootstrapTable>
                </div>
            </div >
        )
    }
}
export default StaticData;