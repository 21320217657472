import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
// import MapBoard from './components/MapBoard';
import MainBoard from './components/MainBoard';

const MapPage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h2 className="page-title"><b>Device Location</b></h2>
      </Col>
    </Row>
    <Row>
      {/* <MapBoard /> */}
      <MainBoard />
    </Row>
  </Container>
);

export default MapPage;
