/* eslint-disable */
import React, { Component } from 'react'

import { Button, Card, CardBody } from 'reactstrap'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import classnames from 'classnames'

import SingleSelection from './SelectionComponents'
import ReportAddDataComponent from './ReportAddDataComponent';

export default class ReportAddComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // activeTab: '2',
      activeTab: '1'
    }
  }

  reportMainSettingsState;
  reportChartDataState;

  componentDidMount() {
    // this.props.loadReports();
  }

  componentWillReceiveProps(nextProps) { }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab })
    }
  }

  reportMainSettingsCallback = (state) => {
    // this.reportMainSettingsState = state;
    this.reportMainSettingsState = { ...this.reportMainSettingsState, ...state };


  }

  reportAddDataCallBack = (state) => {
    // this.reportChartDataState = {...this.reportChartDataState, ...state};
    this.reportChartDataState = { ...state };


  }

  headerStateCallBack = (state, id) => {



  }

  chartStateCallBack = (state, id) => {



  }

  submitFormData = () => {


    if (!this.reportMainSettingsState || !this.reportChartDataState) {
      return;
    }
    let sendcron = generateSendcron(this.reportMainSettingsState.freqPeriod, this.reportMainSettingsState.freqTime);
    if (!sendcron) {
      return;
    }
    let reportObj = {
      "reportname": this.reportMainSettingsState.reportName,
      "timezone": this.reportMainSettingsState.timezone,
      "format": this.reportMainSettingsState.format,
      "datetime": this.reportMainSettingsState.dataTime,
      "sendcron": sendcron,
      "subject": this.reportMainSettingsState.subject,
      "msgbody": this.reportMainSettingsState.messageBody,
      "receiver": this.reportMainSettingsState.emails,
      "status": "Active"
    };
    if (this.props.gerneralPart.reportid) {
      reportObj.reportid = this.props.gerneralPart.reportid;
    }
    // if (this.props.reportId && this.props.reportId > 0){
    //   reportObj.reportid = this.props.reportId;
    // }
    let sectionList = [];
    let section = {};
    let templateList = [];
    let flag;


    Object.entries(this.reportChartDataState).forEach(([key, value], index) => {
      if (Number(key) % 10 === 0) {
        flag = 0;
        if (index !== 0) {
          section.templateList = templateList;
          sectionList.push(section);
        }
        section = {};
        templateList = [];
        section.headstr = value.header;
        section.headsize = value.headerSize;
      } else {
        flag = 1
        let template = {
          name: value.chartName,
          type: value.chartType,
        }
        let ruleList = [];
        if (value.variablesToChart) {
          value.variablesToChart.forEach((item, rindex) => {
            let rule = {
              assetclassid: Number(item.assetClass),
              assetid: Number(item.asset),
              colname: item.variable,
              operation: Number(item.operation),
              time: Number(item.time)
            }
            ruleList.push(rule)
          });
        }
        template.ruleList = ruleList;
        templateList.push(template);
      }
    });
    if (flag === 1) {
      section.templateList = templateList;
      sectionList.push(section);
    }
    reportObj.sectionList = sectionList;


    // alert('yes');
    this.props.upsertOneReport(reportObj);
    this.props.toggle();
  }
  render() {
    let sectionList = parseSectionList(this.props.sectionList)



    return (
      <div style={{ height: this.props.height }}>
        {/* {this.props.button('Create')} */}
        <NavTabs activeTab={this.state.activeTab} toggleTab={this.toggleTab} />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1" >
            <Card style={{ paddingBottom: 0 }}>
              <CardBody style={{ borderRadius: '0px 5px 5px 5px' }}>
                <ReportMainSettingsComponent
                  gerneralPart={this.props.gerneralPart}
                  stateCallback={this.reportMainSettingsCallback}
                />
                <div className='text-right'>
                  <Button style={{ marginBottom: 0 }} onClick={() => this.toggleTab('2')}>Next</Button>
                </div>
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="2">
            <Card style={{ paddingBottom: 0 }}>
              <CardBody
                style={{
                  maxHeight: '77.5vh',
                  overflow: 'auto',
                }}
              >
                <ReportAddDataComponent
                  // sectionList={this.props.sectionList}
                  sectionList={sectionList}
                  reportAddDataCallBack={this.reportAddDataCallBack}
                  headerStateCallBack={this.headerStateCallBack}
                  chartStateCallBack={this.chartStateCallBack}
                />
                <Row>
                  <Col>
                    <Button style={{ marginBottom: 0 }} onClick={() => this.toggleTab('1')}>Back</Button>
                  </Col>
                  <Col className='text-right'>
                    <Button color="secondary" style={{ marginBottom: 0 }} onClick={this.submitFormData}>Save Report</Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}

class ReportMainSettingsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reportName: 'New Report',
      timezone: '',
      dataTime: 10,
      format: 'pdf',
      email: '',
      freqPeriod: '1',
      freqTime: '12:00',
      subject: 'Your Report New Report',
      messageBody: 'Hi, your report New Report is ready for download\n{{url}}\nAll the best',
      emails: ''
    }
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.gerneralPart && nextProps.gerneralPart !== this.state.gerneralPart) { //&& nextProps.gerneralPart == this.state.gerneralPart
      let sendcronObj = parseSendcron(nextProps.gerneralPart.sendcron);
      if (!sendcronObj) {
        return;
      }
      this.setState({
        gerneralPart: nextProps.gerneralPart,
        reportName: nextProps.gerneralPart.reportname,
        timezone: nextProps.gerneralPart.timezone,
        dataTime: nextProps.gerneralPart.datetime,
        format: nextProps.gerneralPart.format,
        subject: nextProps.gerneralPart.subject,
        messageBody: nextProps.gerneralPart.msgbody,
        emails: nextProps.gerneralPart.receiver,
        freqPeriod: sendcronObj.freqPeriod,
        freqTime: sendcronObj.freqTime,
      });
    }
  }

  componentDidUpdate() {


    this.props.stateCallback(this.state);
  }

  handleChange = (event) => {
    const { id, value } = event.currentTarget;


    this.setState({ [id]: value });
    // this.setState(
    //     (prevState, props) => {
    //       return ({ [id]: value });
    //     }, 
    //     () => {
    //         console.log(this.state[id]) 
    //     }
    // );
  }
  // handleTimeChange = (event) => {
  //   const { id, value } = event.currentTarget
  //   this.setState({ frequency: { ...this.state.frequency, [id]: value } })
  // }
  render() {
    const timezoneOptions = [
      // { value: 121, label: "Hello 1" },
      // { value: 122, label: "Hello 2" },
      // { value: 123, label: "Hello 3" },
      // { value: 124, label: "Hello 4" },
      // { value: 125, label: "Hello 5" },
      // { value: 126, label: "Hello 6" },
      // { value: 121, label: "America/Toronto" },
      // { value: 122, label: "America/Kingston" },
      // { value: 123, label: "America/Ottawa" },
      // { value: 124, label: "America/Montreal" },
      // { value: 125, label: "America/Quebec" },
      // { value: 126, label: "America/Lonton" },
      // { value: 127, label: "America/Winnipeg" },
      // { value: 128, label: "America/Edmonto" },
      // { value: 129, label: "America/Calgary" },
      // { value: 130, label: "America/Vancouver" },
      { value: "America/Toronto", label: "America/Toronto" },
      // { value: "America/Kingston", label: "America/Kingston" },
      // { value: "America/Ottawa", label: "America/Ottawa" },
      // { value: "America/Montreal", label: "America/Montreal" },
      // { value: "America/Quebec", label: "America/Quebec" },
      { value: "America/Halifax", label: "America/Halifax" },
      { value: "America/Winnipeg", label: "America/Winnipeg" },
      { value: "America/Edmonton", label: "America/Edmonton" },
      // { value: "America/Calgary", label: "America/Calgary" },
      { value: "America/Vancouver", label: "America/Vancouver" },
    ]
    const dataTimeOptions = [
      // { value: 10, label: "Today" },
      // { value: 11, label: "Yesterday" },
      // { value: 12, label: "Last 24 Hours" },
      // { value: 70, label: "This Week" },
      // { value: 71, label: "Last Week" },
      // { value: 72, label: "Last 7 Days" },
      // { value: 300, label: "This Month" },
      // { value: 301, label: "Last Month" },
      // { value: 302, label: "Last 30 Days" },
      { value: 1, label: "Today" },
      { value: 2, label: "Yesterday" },
      { value: 3, label: "Last 24 Hours" },
      { value: 4, label: "This Week" },
      { value: 5, label: "Last Week" },
      { value: 6, label: "Last 7 Days" },
      { value: 7, label: "This Month" },
      { value: 8, label: "Last Month" },
      { value: 9, label: "Last 30 Days" },
    ]
    const formatOptions = [
      // { value: 1, label: "PDF" },
      // { value: 2, label: "Excel" },
      { value: 'pdf', label: "PDF" },
      { value: 'excel', label: "Excel" },
    ]
    return (
      <>
        <LabelTextInput
          forIdName='reportName' label='Report Name: '
          onChange={this.handleChange}
          value={this.state.reportName}
        />
        <LabelSelectionInput
          forIdName="timezone" label="Time Zone: "
          onChange={this.handleChange}
          value={this.state.timezone}
          options={timezoneOptions}
        />
        <LabelSelectionInput
          forIdName="dataTime" label="Data Time: "
          onChange={this.handleChange}
          value={this.state.dataTime}
          options={dataTimeOptions}
        />
        <LabelSelectionInput
          forIdName="format" label="Report Format: "
          onChange={this.handleChange}
          value={this.state.format}
          options={formatOptions}
        />
        <hr />
        {/* <Input>Schedule</Input> 
      Checkbox Goes Here
        */}
        <EmailFrequencySelection onChange={this.handleChange} values={{ period: this.state.freqPeriod, time: this.state.freqTime }} />
        <LabelTopTextInput
          forIdName="subject" label="Subject: "
          onChange={this.handleChange}
          value={this.state.subject}
        />
        <LabelTopTextInput big
          forIdName="messageBody" label="Message Body: "
          onChange={this.handleChange}
          value={this.state.messageBody}
        />
        <LabelTopTextInput
          forIdName="emails" label="Send to the Following Emails: (Enter a Comma Separated List)"
          onChange={this.handleChange}
          value={this.state.emails}
        />
      </>
    )
  }
}

function LabelTopTextInput({ forIdName, onChange, label, value, big }) {
  return (
    <FormGroup row>
      <Col>
        <Label htmlFor={forIdName}>{label}</Label>
        <Input type={`text${big ? 'area' : ''}`} width="90%" style={big && { height: 112 }}
          value={value}
          id={forIdName}
          name={forIdName}
          onChange={onChange} />
      </Col>
    </FormGroup>
  )
}

function EmailFrequencySelection({ onChange, values }) {
  let options = [{ value: 1, label: 'Day' }, { value: 7, label: 'Week' }, { value: 30, label: 'Month' }]
  return (
    <FormGroup row>
      <Label md={2} htmlFor={"freqPeriod"}>Send This Report Every</Label>
      <Col md={2}>
        <SingleSelection forIdName={"freqPeriod"} onChange={onChange} value={values.period} options={options} />
      </Col>
      <Label md={1} htmlFor={"freqTime"}>at</Label>
      <Col md={2}>
        <Input type="time" name="freqTime" id="freqTime"
          value={values.time}
          onChange={onChange} />
      </Col>
    </FormGroup>
  )
}

function LabelSelectionInput(props) {
  return (
    <FormGroup row>
      <Label md={2} htmlFor={props.forIdName}>{props.label}</Label>
      <Col md={10}>
        <SingleSelection
          {...props} />
      </Col>
    </FormGroup>
  )
}

function LabelTextInput({ forIdName, onChange, label, value, big }) {
  return (
    <FormGroup row>
      <Label md={2} htmlFor={forIdName}>{label}</Label>
      <Col md={10}>
        <Input type={`text${big ? 'area' : ''}`} width="90%" style={{ marginBottom: 5 }}
          value={value}
          id={forIdName}
          name={forIdName}
          onChange={onChange} />
      </Col>
    </FormGroup>
  )
}

function NavTabs({ activeTab, toggleTab }) {
  return (
    <Nav tabs style={{
      border: 'none'
    }}>
      <NavItem style={{ cursor: 'pointer' }}>
        <NavLink style={{
          border: 'none'
        }}
          className={classnames({ active: activeTab === '1' })}
          onClick={() => { toggleTab('1'); }}>
          General Options
        </NavLink>
      </NavItem>
      <NavItem style={{ cursor: 'pointer' }}>
        <NavLink style={{
          border: 'none'
        }}
          className={classnames({ active: activeTab === '2' })}
          onClick={() => { toggleTab('2'); }}>
          Data Sets
        </NavLink>
      </NavItem>
    </Nav>
  )
}

function parseSectionList(sList) {
  let parsedList = {};
  let keyIndex = 0;
  let key;
  if (sList && Array.isArray(sList)) {
    sList.forEach((item, index) => {
      let header = {
        header: item.headstr,
        headerSize: item.headsize,
      };
      key = (keyIndex + 1) * 10;
      parsedList[key] = header;
      keyIndex++;
      if (item.templateList) {
        item.templateList.forEach((template, tindex) => {
          let section = {
            chartType: template.type,
            chartName: template.name,
          };
          let variablesToChart = [];
          if (template.ruleList) {
            template.ruleList.forEach((rule, rindex) => {
              let variable = {
                assetClass: rule.assetclassid,
                asset: rule.assetid,
                // variable: rule.Vbat,
                variable: rule.colname,
                operation: rule.operation,
                time: rule.time,
              };
              variablesToChart.push(variable);
            });
          }
          section.variablesToChart = variablesToChart;
          key = ((keyIndex + 1) * 10) + 1;
          parsedList[key] = section;
          keyIndex++;
        });
      }
    });
  }
  return parsedList;
}

function generateSendcron(freqPeriod, freqTime) {
  let period;
  let timeArr;
  let sendCronArr = ['0', '*', '*', '*', '*', '*'];
  try {
    if (!freqPeriod || !freqTime) {
      return null;
    }

    timeArr = freqTime.split(':');
    if (timeArr.length < 2) {
      return null;
    }
    sendCronArr[1] = Number(timeArr[1]).toString();
    sendCronArr[2] = Number(timeArr[0]).toString();
    switch (freqPeriod) {
      case '1':
        sendCronArr[3] = "?";
        break;
      case '7':
        sendCronArr[5] = '1';
        break;
      case '30':
        sendCronArr[3] = "1";
        sendCronArr[4] = "1-12";
        break;
      default:
        return null;
    }
  }
  catch (e) {
    console.error(e.message);
    return null;
  }
  return (sendCronArr.join(' '));
}

function parseSendcron(sendCron) {
  if (!sendCron) {
    return null;
  }
  let sendCronArr = sendCron.split(' ');
  if (sendCronArr.length < 6) {
    return null;
  }

  let freqPeriod;
  let freqTime;

  let dayOfWeek = parseInt(sendCronArr[5]);
  let month = parseInt(sendCronArr[4]);
  let dayOfMounth = parseInt(sendCronArr[3]);
  let hour = parseInt(sendCronArr[2]);
  let minute = parseInt(sendCronArr[1]);
  let hourStr = '';
  let minuteStr = '';
  if (isNaN(hour) || hour < 0 || hour > 24) {
    return null;
  } else {
    hourStr = '00' + hour.toString();
    hourStr = hourStr.substring(hourStr.length - 2, hourStr.length);
  }
  if (isNaN(minute) || minute < 0 || minute > 59) {
    return null;
  } else {
    minuteStr = '00' + minute.toString();
    minuteStr = minuteStr.substring(minuteStr.length - 2, minuteStr.length);
  }
  freqTime = hourStr + ':' + minuteStr;

  if (!isNaN(dayOfMounth)) {
    freqPeriod = '30';
  } else if (!isNaN(dayOfWeek)) {
    freqPeriod = '7';
  } else if (isNaN(dayOfMounth) && isNaN(dayOfWeek) && isNaN(month)) {
    freqPeriod = '1';
  } else {
    return null;
  }

  return { freqPeriod: freqPeriod, freqTime: freqTime }

}