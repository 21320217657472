/* eslint-disable */
import {
    SEARCH_USER_STARTED,
    SEARCH_USER_FAILED,
    SEARCH_USER_SUCCEED
} from '../../../constants/actionTypes';//'../../const'

const initState = {
    loading: false,
    type: '',
    payload: ''
};

export default function (state = initState, action) {
    switch (action.type) {
        case SEARCH_USER_STARTED:
            return {
                loading: true,
                type: SEARCH_USER_STARTED
            };
        case SEARCH_USER_SUCCEED:
            return {
                ...state,
                loading: false,
                type: SEARCH_USER_SUCCEED,
                // payload: action.payload.data.data
                payload: action.payload
            };
        case SEARCH_USER_FAILED:
            return {
                ...state,
                loading: false,
                type: SEARCH_USER_FAILED,
                payload: action.payload
            };
        default:
            return state;
    }
}