/*eslint-disable*/
import React, { Component, Fragment } from "react";
import PowerManagementBoard from "./PowerManagementBoard";
import {
  Col,
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classNames from "classnames";
import { getCurrentUser } from "../../../factories/utils";
import { SingleSelection } from "../../Asset/components/SelectionComponents";

class PowerManagementMainBoard extends Component {
  constructor(props) {
    super(props);
    let index = localStorage.getItem("activeTab");
    this.state = {
      activeTab: index ? index : "1",
    };

    this.userObj = getCurrentUser();
  }

  componentDidMount() {}

  toggleTab = (tab) => {
    localStorage.setItem("activeTab", tab);
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  render() {
    // console.log("===this.props.historyDataObjPowerMain", this.props.historyDataObj);
    return (
      <Col md={12}>
        <Card>
          <PowerManagementBoard
            assetDataLoading={this.props.power.assetDataLoading}
            assetData={this.props.assetData}
            setDeviceid={this.props.setDeviceid}
            activeTab={this.state.activeTab}
            statusData={this.props.statusData}
            toggle={this.props.toggle}
            assetClassList={this.props.assetClassList}
            assetList={this.props.assetList}
            deviceList={this.props.deviceList}
            deviceAttrList={this.props.deviceAttrList}
            messageDecodeList={this.props.messageDecodeList}
            currentAssetData={this.props.currentAssetData}
            assetHistoryObj={this.props.assetHistoryObj}
            historyDataObj={this.props.historyDataObj}
            powerAttrList={this.props.powerAttrList}
            messageDecodeGroup={this.props.messageDecodeGroup}
            historyDataProcessor={this.props.historyDataProcessor}
            updateAttributeHistoryData={this.props.updateAttributeHistoryData}
            power={this.props.power}
          />
        </Card>
        {/* <NavTabs activeTab={this.state.activeTab} toggleTab={this.toggleTab} />
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
          </TabPane>
        </TabContent> */}
      </Col>
    );
  }
}

function NavTabs({ activeTab, toggleTab }) {
  return (
    <Nav tabs style={{ border: "none" }}>
      <NavItem style={{ cursor: "pointer" }}>
        <NavLink
          className={classNames({ active: activeTab === "1" })}
          onClick={() => {
            toggleTab("1");
          }}
        >
          <h4>
            <b>Power Management</b>
          </h4>
        </NavLink>
      </NavItem>
    </Nav>
  );
}

export default PowerManagementMainBoard;
