/* eslint-disable */
import React, { Component, Fragment, useState,  useEffect } from 'react';
import { CustomInput, Input, Form, FormGroup, Label, Row, Col, Card, CardBody } from 'reactstrap';

const TimerSlider = ({propConfig, updateConfig, appname, ...res}) => {
    let [config, setConfig] = useState(propConfig);
    useEffect(()=>{setConfig(propConfig);},[propConfig]);
    // let [config, setConfig] = useState({});
    // setConfig(propConfig);
    //  
    //  
    const defaultLabel = 'Timer';
    const defaultValue = '0';
    // console.log("===OBJ===", config);
    // console.log("===OBJ===", config.itemname);
    // let id = config.id;
    // if(id == 12) {
    //     config.unit = 'km/h';
    //     config.displayname = 'wind high threshold(km/h)';
    // }
   
    let unit = 1; 
    let unitLabel = 'second(s)';
    switch(config.unit){
        case 'h':
            unit = 3600;
            unitLabel = 'hour(s)';
            break;
        case 'm':
            unit = 60;
            unitLabel = 'minute(s)';
            break;
        case 'q':
            unit = 60 * 15;
            unitLabel = 'x 15 minutes';
            break;
        case 'd':
            unit = 60 * 10;
            unitLabel = 'x 10 minutes';
            break;
        case 'm/s':
            unit = 1;
            unitLabel = 'km/h';
            break;
        // case 's':
        //     unit = 1;
        //     unitLabel = 'seconds(s)';
        default:
            unit = 1;
            unitLabel = config.unit;
            // break;
        
    }
    const battery_box = [
        {alarm: 'LOW', color: 'red'},
        {alarm: 'MEDIUM', color: 'orange'},
        {alarm: 'HIGH', color: 'green'},
    ];

    const alarm_box = [   
        {alarm: 'RED', color: 'red'},
        {alarm: 'ORANGE', color: 'orange'},
        {alarm: 'YELLOW', color: 'yellow'},
    ];
    const renderRangeValue = (value, unitLabel) => {
        // let id = config.id; 
        // if(id) {
        //     if(id == 12) {
        //         value = Math.round(value * 3.6);
        //     }
        // }
        if (unitLabel === 'rgb') {
            const alarmList = alarm_box.slice(0, alarm_box.length-value);
            // return <span style={{backgroundColor: 'red', color: 'white', padding: '1px, 5px'}}>RED</span>
            return <Fragment>
                {alarmList && alarmList.map(item => (
                    <span style={{backgroundColor: item.color, color: 'white', padding: '1px 5px', margin : '1px 5px', border: 'solid 1px black'}}>{item.alarm}</span>
                ))}
            </Fragment>
        }
        else if (unitLabel === 'io') {
            return <Label for="exampleCustomRange">{value == 0 ? 'off' : 'on'}</Label>;
        }
        else if(unitLabel === 'lmh') {
            const batteryList = battery_box.slice(0, value + 1);
            return <Fragment>
                {batteryList && batteryList.map(item => (
                    <sapn style={{ backgroundColor: item.color, color: 'white', padding: '1px 5px', margin: '1px 5px', border: 'solid 1px black' }}>{item.alarm}</sapn>
                ))}
            </Fragment>
        }
        else{
            return <Label for="exampleCustomRange">{value} {unitLabel}</Label>;
        }
    }
    const handleTimertChange = (e) => {
        //  
        let newConfig = {
            ...config,
            // itemvalue: e.target.value * 3600
            itemvalue: e.target.value * unit
            // itemvalue: config.itemname == 'wind(m/s)' ? e.target.value : e.target.value * unit,
            // unit: config.itemname == 'wind(m/s)' ? 'm/s' : defaultValue
        };
        // console.log('===config',config)

        // console.log('===e.target.value',e.target.value)
        // console.log('===newConfig',newConfig)
        // if (newConfig.itemname == 'wind(m/s)'){
        //     newConfig.itemvalue = newConfig.itemvalue * 3.6
        //     newConfig.unit = 'km/h'
        //     setConfig(newConfig);   
        // }

        setConfig(newConfig);
    }
    const handleTimerBlur = (e) => {
         
        // updateConfig(config);
    }
    const handleTimerUp = (e) => {
        updateConfig(config, appname);
    }

    // let hours = config && config.itemvalue ? Math.round(config.itemvalue/3600) : defaultValue;
    let times
    // if (config.unit == 'm/s'){
    //     times = config && config.itemvalue ? Math.round(config.itemvalue * unit) : defaultValue;
    // }else{
        times = config && config.itemvalue ? Math.round(config.itemvalue/unit) : defaultValue;
    // }
    // let mins = config && config.itemvalue ? Math.round((config.itemvalue % 3600) / 60) : defaultValue;
    return (
        
        <Form style={{width: '80%'}}>
            <FormGroup>
                    <Label for="exampleCustomRange">
                        <b>
                            {config && config.displayname ? (config.displayname == 'wind high threshold(m/s)' ? 'wind high threshold(km/h)': config.displayname) : defaultLabel}
                        </b>
                    </Label>
                <Row>
                    <Col md={{ size: unitLabel==='io' ? 1:8, offset: 0 }}>
                        <CustomInput 
                            type="range" 
                            id="exampleCustomRange" 
                            name="customRange" 
                            // max='500'
                            // min='0'
                            // max={Math.round(config.range_high/3600)}
                            // min={Math.round(config.range_log/3600)}
                            // value={config && config.itemvalue ? Math.round(config.itemvalue/3600) : defaultValue }
                            max={config ? Math.round(config.range_high/unit) : 0}
                            min={config ? Math.round(config.range_low/unit) : 0}
                            value={config && config.itemvalue ? Math.round(config.itemvalue/unit) : defaultValue }
                            // value={config && config.itemvalue ? (config.unit == 'm/s'? Math.round(config.itemvalue * unit) : Math.round(config.itemvalue/unit)): defaultValue }
                            // value = {config && config.itemvalue ? config.itemvalue : defaultValue}
                            onChange={handleTimertChange}
                            onBlur={handleTimerBlur}
                            onMouseUp={handleTimerUp}
                        />
                    </Col>
                    <Col md={{ size: 4, offset: unitLabel==='io' ? 7:0 }}>
                        {/* <Label for="exampleCustomRange">{hours} hour(s)</Label> */}
                        {/* <Label for="exampleCustomRange">{times} {unitLabel}</Label> */}
                        {renderRangeValue(times, unitLabel)}
                    </Col>
                </Row>
            </FormGroup>
        </Form>       
    );
};

const TimerSetting = (props) => {
    let {configs, updateConfig} = props;
    //  
    // const updataConfig = (configObj) => {
    //     updateConfig(configObj);
    // } 
    // const configs = [
    //     {
    //         "id": 1,
    //         "appname": "weather",
    //         "modulename": "alarm",
    //         "itemname": "timer_x(s)",
    //         "itemvalue": "60"
    //     },
    //     {
    //         "id": 2,
    //         "appname": "weather",
    //         "modulename": "alarm",
    //         "itemname": "timer_t(s)",
    //         "itemvalue": "300"
    //     }
    // ];
   
    // let myconfig = configs ? configs.filter((item)=>(item.itemname === "timer_t(s)")) : [];
    // let myconfig = configs ? configs : [];
    // console.log("===OBJ===", configs);
    return (
    <Col md={12}>
    <Card>
        <CardBody>
            {configs && configs.map((config, cindex)=>(
                <TimerSlider
                    key={cindex}
                    propConfig={config}
                    updateConfig={updateConfig}
                    appname = {props.appname}
                />
            ))}
        </CardBody> 
    </Card>
    </Col>
    );
}
export default TimerSetting;