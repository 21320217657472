import React from 'react';
import { Col, Container, Row } from 'reactstrap';
// import ExampleCard from '../ExampleTwo/components/ExampleCard';
import GeoFenceBoard from './components/GeoFenceBoard';

const GeofencePage = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Geofence Management</h3>
        <hr />
      </Col>
    </Row>
    <Row>
      <GeoFenceBoard />
    </Row>
  </Container>
);

export default GeofencePage;
