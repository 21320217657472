/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import log from 'loglevel';
import { USER_GET_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS,
    COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS,
    COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED,
    COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS,
    COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED,
    CONTACT_US_EMAIL_SEND_SUCCEED,
    CONTACT_US_EMAIL_SEND_FAILED
} from '../constants/actionTypes';
import { ATTRIBUTE_TYPE, ATTRIBUTE_NAME } from '../constants/dataConstants';
const token = getJwttoken();
axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {ExcelRenderer} from 'react-excel-renderer';
import { ABCIcon } from 'mdi-react';
import { MAILGUN_ROOT, MESSAGER_ROOT, SUPPORT_EMAIL } from '../constants/appConstants';


export const getSelectStyles = ()=>{
    const user = getCurrentUser();
    if(user){
        return {
            menu: (provided, state) => ({
                ...provided,
                width: "100%",
                cursor:"pointer",
                zIndex: 9999
            }),
            option: (provided, state) => ({
                ...provided,
                color: "black",
                width: "100%",
                cursor:"pointer",
                '&:active': { backgroundColor: user.detail.appButtonColorHover },
                backgroundColor: state.isFocused ? user.detail.appButtonColorHover : "white",
            }),
            control: (base, selectState) => ({
                ...base,
                // height: 35,
                // minHeight: 35
                width:"100%",
                height: 35,
                minHeight: 35,
                cursor:"pointer",
                borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor,
                // This line disable the blue border
                boxShadow: selectState.isFocused ? 0 : 0,
                '&:hover': { borderColor: selectState.isFocused ? user.detail.appButtonColorHover : base.borderColor }
            }),
            indicatorsContainer: (base, selectState) => ({
                ...base,
                padding: "0",
            }),
        }
    }
    return null
}

export const groupByArray = (objectArray, property)=>{
    return objectArray.reduce((acc, obj) => {
       const key = obj[property];
       if (!acc[key]) {
          acc[key] = [];
       }
       // Add object to list for given key's value
       acc[key].push(obj);
       return acc;
    }, {});
}

export const groupByArrayObject = (objectArray, property, objproperty)=>{
    return objectArray.reduce((acc, obj) => {
       const key = obj[property][objproperty];
       if (!acc[key]) {
          acc[key] = [];
       }
       //Add object to list for given key's value
       acc[key].push(obj);
       return acc;
    }, {});
}

// const grouped = groupBy(pets, pet => pet.type);
export function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
// ------------------------------------------------
export function getMaxItem (list, keyGetter) {
    if (list && Array.isArray(list)){
        let maxItem = list.reduce((accumulator, currentValue, currentIndex, array)=>{
            if (accumulator == null){
                return currentValue;
            }else{
                const currentTime = keyGetter(currentValue);
                const accumulTime = keyGetter(accumulator);
                if (accumulTime >= currentTime) {
                    return accumulator;
                }else{
                    return currentValue
                    ;
                }
            }
        }, null);
        return maxItem;
    }else{
        return null;
    }
}
// ------------------------------------------------
export function chunk(array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
      chunked_arr.push(array.slice(index, size + index));
      index += size;
    }
    return chunked_arr;
  }
// ------------------------------------------------
export function isStringEmpty(inputStr) {
    return (inputStr == undefined) || (inputStr == null) || (inputStr.length === 0);
}
// ------------------------------------------------

export function itemFinder(list, key, value) {
    let selectedItem = null; 
    selectedItem = list.find((item) => {
        return (
            item[key] === value
        );
    });
    return selectedItem;
}

export function dropdownOnChangeHandler(e) {
    let id = e.target.value;
    let index = e.target.selectedIndex;
    let name = e.target.options[index].text;
    return ({
        id: id,
        name: name
    });
}

export function generateSelectOptions(list, labelKey, valueKey) {
    let options = [];
    if (list && Array.isArray(list)){
        list.forEach((item)=>{
            const label = item[labelKey];
            const value = item[valueKey];
            options.push({
                label: label, 
                value: value,
            });
        });
    }
    return options;
}

//React polling every "delay" seconds
export const useInterval = (callback, delay) => {

    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
  
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
// -------------------------------------------------------------------
export function base64Encode (str) {
    // const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // let encoded = btoa(str);
    // let encodedArr = encoded.split('');
    // let startArr = encodedArr.splice(0,4);
    // let reversedArr = startArr.reverse();
    // let processedArr = [...reversedArr, ...encodedArr];
    // let randomChar = characters.charAt(Math.floor(Math.random() * characters.length));
    // processedArr.splice(9,0,randomChar);
    // let returnVal = processedArr.join('');   
    // return (returnVal);
    return ('')

    // let token = sessionStorage.getItem('token');
    //  
    //  
    // return (token);
   
}

export function getJwttoken(){
    let token = sessionStorage.getItem('jToken');
    //  
    //  
    return (token);
}
// -------------------------------------------------------------------
export function getCurrentUser(){
    try{
        if (sessionStorage.getItem('currentUserObj')){
            let currentUser = JSON.parse(sessionStorage.getItem('currentUserObj'));
            return currentUser;
        }else{
            throw new Error("Failed to get current user, Please re-login");
        }
    }
    catch(err) {
        console.error(err.message);
        return null;
    }
}

export function setBrandingDetail(detail){
    try{
        if (sessionStorage.getItem('currentUserObj')){
            var currentUser = JSON.parse(sessionStorage.getItem('currentUserObj'));
            currentUser.detail = detail
            sessionStorage.setItem('currentUserObj', JSON.stringify(currentUser));
            return currentUser;
        }else{
            throw new Error("Failed to get current user, Please re-login");
        }
    }
    catch(err) {
        console.error(err.message);
        return null;
    }
}

// -------------------------------------------------------------------
// export function axiosGet(paramObj){

//     const urlRoot = paramObj.url;
//     const criteria = paramObj.criteria;
//     const logText = paramObj.logText;
//     const sccessType = paramObj.sccessType;
//     const failedType = paramObj.failedType;
//     const errorMessage = paramObj.errorMessage;

//     let userObj = getCurrentUser();
//     // let token = getJwttoken();
//     if (userObj === null || userObj.userName.length === 0){
//     //    
//        
//       return dispatch => {
//         dispatch({
//           type: USER_GET_FAILED,
//           error: "Failed to get current user, Please re-login",
//           message: "Failed to get current user, Please re-login",
//         });
//       }
//     }
//     let {userName, companyCode, role} = userObj;
//     let userTokenObj = {userName, companyCode, role};

//     let queryString = '';
//     if (criteria){
//         queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
//         queryString = "?" + queryString;
//     }  
//     const urlStr = `${urlRoot}${queryString}`;
//      

//     const request = axios({
//         url: urlStr,
//         method: 'get',
//         headers: {
//             'Content-Type': 'application/json',
//             // 'uTk': base64Encode(JSON.stringify(userTokenObj)),
//             // 'Authorization': "Bearer " + token,
//             // 'Authorization': "Bearer " + sessionStorage.getItem('token'),
//         },
//     });

//     return (
//         (despatch) => {
//             request.then( (response) => {
//                 if (response.status == 200) {
//                     despatch({
//                         type:sccessType,
//                         payload: response.data
//                     });
//                     if (paramObj.callback) {
//                         despatch(paramObj.callback(response.data));
//                     }
//                 }else{
//                     despatch({
//                         type: failedType,
//                         error: errorMessage,
//                     });
//                     if (paramObj.errorCallback) {
//                         despatch(paramObj.errorCallback(errorMessage));
//                     }
//                 }
//             }).catch( (error) => {
//                 despatch({
//                     type: failedType,
//                     error: error.message,
//                 });
//                 if (paramObj.errorCallback) {
//                     despatch(paramObj.errorCallback(error.message));
//                 }
//             })
//         }
//     );
// }

// --------------------------------------------------------

export function axiosAsyncCall(paramObj){
    const urlRoot = paramObj.url;
    const method = paramObj.method;
    const queryData = paramObj.queryData;
    const bodyData = paramObj.bodyData;
    const responseKeyGetter = paramObj.responseKeyGetter;
    const successContent = paramObj.successContent;
    const timeout = paramObj.timeout

    let queryString = '';
    if (queryData){
        queryString = Object.keys(queryData).map(key => key + '=' + queryData[key]).join('&');
        queryString = "?" + queryString;
    }  
    const urlStr = `${urlRoot}${queryString}`;
    const axiosParam = {
        url: urlStr,
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: timeout
    }
    if (bodyData){
        axiosParam.data = bodyData;
    }

    return axios(axiosParam).then( response => {
        let successCheck = true;
        if (responseKeyGetter){
            successCheck = (successContent === responseKeyGetter(response))
        }
        if ((response.status == 200 || response.status == 201 || response.status == 302) && successCheck){
            return Promise.resolve(response.data)
        }else{
            log.error(response);
            return Promise.reject(response);
        }
    }).catch( err => {
        log.error(err);
        return Promise.reject(err.response);
    });
    
}

export function axiosGet(paramObj){

    const urlRoot = paramObj.url;
    const criteria = paramObj.criteria;
    const logText = paramObj.logText;
    const startType = paramObj.startType;
    const sccessType = paramObj.sccessType;
    const failedType = paramObj.failedType;
    const errorMessage = paramObj.errorMessage;
    const timeout = paramObj.timeout

    let userObj = getCurrentUser();
    // let token = getJwttoken();
    if (userObj === null || userObj.userName.length === 0){
    //    
       
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }
    let {userName, companyCode, role} = userObj;
    let userTokenObj = {userName, companyCode, role};

    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
        queryString = "?" + queryString;
    }  
    const urlStr = `${urlRoot}${queryString}`;
     

    const request = axios({
        url: urlStr,
        method: 'get',
        // timeout: 1000000000,
        headers: {
            'Content-Type': 'application/json',
            // 'uTk': base64Encode(JSON.stringify(userTokenObj)),
             //'Authorization': "Bearer " + token,
            // 'Authorization': "Bearer " + sessionStorage.getItem('token'),
        },
        timeout: timeout
    });

    return (
        (despatch) => {
            if (startType) {
                despatch({
                    type:startType,
                });
            }
            return request.then( (response) => {
                // 
                if (response.status == 200) {
                    if (sccessType) {
                        despatch({
                            type:sccessType,
                            payload: response.data,
                            parameters: criteria
                        });
                    }
                    if (paramObj.callback) {
                         
                        despatch(paramObj.callback(response.data));
                    }
                }else{
                    if (failedType) {
                        despatch({
                            type: failedType,
                            error: errorMessage,
                        });
                    }
                    if (paramObj.errorCallback) {
                        despatch(paramObj.errorCallback(errorMessage));
                    }
                    console.error('post error: ', errorMessage);
                }
                return response;
            }).catch( (error) => {
                if (failedType) {
                    despatch({
                        type: failedType,
                        error: error.message,
                    });
                }
                if (paramObj.errorCallback) {
                    despatch(paramObj.errorCallback(error.message));
                }
                console.error('post error: ', error);
            })
        }
    );
}

// ----------------------------------------------------------------------------
export function axiosPost(paramObj){


    const url = paramObj.url;
    const criteria = paramObj.criteria;
    const logText = paramObj.logText;
    const startType = paramObj.startType;
    const sccessType = paramObj.sccessType;
    const failedType = paramObj.failedType;
    const successMessage = paramObj.successMessage;
    const errorMessage = paramObj.errorMessage;
    const responseKeyGetter = paramObj.responseKeyGetter;
    const successContent = paramObj.successContent;
    const successCallback = paramObj.successCallback;
    const errorCallback = paramObj.errorCallback;
    const responseMsgGetter = paramObj.responseMsgGetter;
    const plantErrorCallback = paramObj.plantErrorCallback;
    const plantSuccessCallback = paramObj.plantSuccessCallback;

    let userObj = getCurrentUser();
    // let token = getJwttoken();
    if (userObj === null || userObj.userName.length === 0){
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }
    // let {userName, companyCode, role} = userObj;

    const urlStr = `${url}`;
    //  

    const request = axios({
        url: urlStr,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
        data: criteria,
    });

    return (
        (despatch) => {
            if (startType) {
                despatch({
                    type:startType,
                });
            }
            // dispatch(loadingDataStart());
            return(
                request.then( (response) => {
                    let successCheck = true;
                    if (responseKeyGetter){
                        successCheck = (successContent === responseKeyGetter(response))
                    }
                    if ((response.status == 200 || response.status == 201) && successCheck) {
                        if(sccessType){
                            despatch({
                                type:sccessType,
                                payload: response.data
                            });
                        }
                        if (successCallback) {
                            despatch(successCallback(response.data));
                        }
                        if (plantSuccessCallback) {
                            plantSuccessCallback(response.data);
                        }

                        /* Show toasts */
                        switch (sccessType) {
                            case COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_SUCCESS:
                            case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_SUCCESS:
                            case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_SUCCESS:
                            case CONTACT_US_EMAIL_SEND_SUCCEED:
                                toast.success(successMessage);
                                break;
                            default:
                                ;
                        }

                    }else{
                        let errorMsg = ''
                        if ((response.status == 200 || response.status == 201) && responseMsgGetter) {
                            errorMsg = responseMsgGetter(response)
                        }
                        errorMsg = errorMessage? errorMessage: errorMsg;

                        if(failedType){
                            despatch({
                                type: failedType,
                                // error: errorMessage,
                                error: errorMsg,
                            });
                        }                    
                        if (errorCallback) {
                            // despatch(errorCallback(errorMessage));
                            despatch(errorCallback(errorMsg));
                        }
                        if (plantErrorCallback) {
                            plantErrorCallback(errorMsg);
                        }
                        // console.error('post error: ', errorMessage);

                        switch (failedType) {
                            case COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED:
                            case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED:
                            case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED:
                            case CONTACT_US_EMAIL_SEND_FAILED:
                                toast.error(errorMessage);
                                break;
                            default:
                                ;
                        }

                    }
                    return response;
                }).catch( (error) => {
                    if(failedType){
                        despatch({
                            type: failedType,
                            error: error.message,
                        });
                    }                
                    if (errorCallback) {
                        despatch(errorCallback(error.message));
                    }
                    if (plantErrorCallback) {
                        plantErrorCallback(error.message);
                    }

                    switch (failedType) {
                        case COMPANY_PAYMENT_INVOICE_ADD_ADJUSTMENTS_BY_INVOICE_BY_COMPANY_FAILED:
                        case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_REASON_FAILED:
                        case COMPANY_PAYMENT_INVOICE_ADD_GENERAL_LEDGER_CODE_FAILED:
                            toast.error(error.message);
                            break;
                        default:
                            ;
                    }

                    return error.response;
                })
            )
        }
    );
}

// ----------------------------------------------------------------------------
export function axiosPut(paramObj){

    const url = paramObj.url;
    const criteria = paramObj.criteria;
    const logText = paramObj.logText;
    const startType = paramObj.startType;
    const sccessType = paramObj.sccessType;
    const failedType = paramObj.failedType;
    const successMessage = paramObj.successMessage;
    const errorMessage = paramObj.errorMessage;
    const responseKeyGetter = paramObj.responseKeyGetter;
    const successContent = paramObj.successContent;
    const successCallback = paramObj.successCallback;
    const errorCallback = paramObj.errorCallback;
    const responseMsgGetter = paramObj.responseMsgGetter;
    const plantErrorCallback = paramObj.plantErrorCallback;
    const plantSuccessCallback = paramObj.plantSuccessCallback;

    let userObj = getCurrentUser();
    // let token = getJwttoken();
    if (userObj === null || userObj.userName.length === 0){
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }
    // let {userName, companyCode, role} = userObj;

    const urlStr = `${url}`;
    //  

    const request = axios({
        url: urlStr,
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
        },
        data: criteria,
    });

    return (
        (despatch) => {
            if (startType) {
                despatch({
                    type:startType,
                });
            }
            // dispatch(loadingDataStart());
            return(
                request.then( (response) => {
                    let successCheck = true;
                    if (responseKeyGetter){
                        successCheck = (successContent === responseKeyGetter(response))
                    }
                    if ((response.status == 200 || response.status == 201) && successCheck) {
                        if(sccessType){
                            despatch({
                                type:sccessType,
                                payload: response.data
                            });
                        }
                        if (successCallback) {
                            despatch(successCallback(response.data));
                        }
                        if (plantSuccessCallback) {
                            plantSuccessCallback(response.data);
                        }

                        switch (sccessType) {
                            case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_SUCCESS:
                            case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_SUCCESS:
                            case COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_SUCCESS:
                                toast.success(successMessage);
                                break;
                            default:
                                ;
                        }

                    }else{
                        let errorMsg = ''
                        if ((response.status == 200 || response.status == 201) && responseMsgGetter) {
                            errorMsg = responseMsgGetter(response)
                        }
                        errorMsg = errorMessage? errorMessage: errorMsg;

                        if(failedType){
                            despatch({
                                type: failedType,
                                // error: errorMessage,
                                error: errorMsg,
                            });
                        }                    
                        if (errorCallback) {
                            // despatch(errorCallback(errorMessage));
                            despatch(errorCallback(errorMsg));
                        }
                        if (plantErrorCallback) {
                            plantErrorCallback(errorMsg);
                        }
                        // console.error('post error: ', errorMessage);
                        console.error('put error: ', errorMsg);

                        switch (failedType) {
                            case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED:
                            case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED:
                            case COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED:
                                toast.error(errorMessage);
                                break;
                            default:
                                ;
                        }
                    }
                    return response;
                }).catch( (error) => {
                    if(failedType){
                        despatch({
                            type: failedType,
                            error: error.message,
                        });
                    }                
                    if (errorCallback) {
                        despatch(errorCallback(error.message));
                    }
                    if (plantErrorCallback) {
                        plantErrorCallback(error.message);
                    }
                    console.error('put error: ', error)

                    switch (failedType) {
                        case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_REASON_FAILED:
                        case COMPANY_PAYMENT_INVOICE_MODIFY_GENERAL_LEDGER_CODE_FAILED:
                        case COMPANY_PAYMENT_INVOICE_CONFIRM_APPROVAL_FAILED:
                            toast.error(error.message);
                            break;
                        default:
                            ;
                    }

                    return error.response;
                })
            )
        }
    );
}

// ----------------------------------------------------------------------------
export function axiosGetWithBody(paramObj){

    const url = paramObj.url;
    const criteria = paramObj.criteria;
    const logText = paramObj.logText;
    const startType = paramObj.startType;
    const sccessType = paramObj.sccessType;
    const failedType = paramObj.failedType;
    const errorMessage = paramObj.errorMessage;
    const responseKeyGetter = paramObj.responseKeyGetter;
    const successContent = paramObj.successContent;
    const successCallback = paramObj.successCallback;
    const errorCallback = paramObj.errorCallback;
    const responseMsgGetter = paramObj.responseMsgGetter;
    const plantErrorCallback = paramObj.plantErrorCallback;
    const plantSuccessCallback = paramObj.plantSuccessCallback;

    let userObj = getCurrentUser();
    // let token = getJwttoken();
    if (userObj === null || userObj.userName.length === 0){
      return dispatch => {
        dispatch({
          type: USER_GET_FAILED,
          error: "Failed to get current user, Please re-login",
          message: "Failed to get current user, Please re-login",
        });
      }
    }
    // let {userName, companyCode, role} = userObj;

    const urlStr = `${url}`;
    //  

    const request = axios({
        url: urlStr,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        data: criteria,
    });

    return (
        (despatch) => {
            if (startType) {
                despatch({
                    type:startType,
                });
            }
            // dispatch(loadingDataStart());
            return(
                request.then( (response) => {
                    let successCheck = true;
                    if (responseKeyGetter){
                        successCheck = (successContent === responseKeyGetter(response))
                    }
                    if ((response.status == 200 || response.status == 201) && successCheck) {
                        if(sccessType){
                            despatch({
                                type:sccessType,
                                payload: response.data
                            });
                        }
                        if (successCallback) {
                            despatch(successCallback(response.data));
                        }
                        if (plantSuccessCallback) {
                            plantSuccessCallback(response.data);
                        }
                    }else{
                        let errorMsg = ''
                        if ((response.status == 200 || response.status == 201) && responseMsgGetter) {
                            errorMsg = responseMsgGetter(response)
                        }
                        errorMsg = errorMessage? errorMessage: errorMsg;

                        if(failedType){
                            despatch({
                                type: failedType,
                                // error: errorMessage,
                                error: errorMsg,
                            });
                        }                    
                        if (errorCallback) {
                            // despatch(errorCallback(errorMessage));
                            despatch(errorCallback(errorMsg));
                        }
                        if (plantErrorCallback) {
                            plantErrorCallback(errorMsg);
                        }
                        // console.error('post error: ', errorMessage);
                        console.error('post error: ', errorMsg);
                    }
                    return response;
                }).catch( (error) => {
                    if(failedType){
                        despatch({
                            type: failedType,
                            error: error.message,
                        });
                    }                
                    if (errorCallback) {
                        despatch(errorCallback(error.message));
                    }
                    if (plantErrorCallback) {
                        plantErrorCallback(error.message);
                    }
                    console.error('post error: ', error)
                    return error.response;
                })
            )
        }
    );
}

// ----------------------------------------------------------------------------

export function axiosDelete(paramObj){
    let message = '';
    
    const urlRoot = paramObj.url;
    const dataObject = paramObj.dataObject;
    const criteria = paramObj.criteria;
    const logText = paramObj.logText;
    const startType = paramObj.startType;
    const sccessType = paramObj.sccessType;
    const failedType = paramObj.failedType;
    const successMessage = paramObj.successMessage;
    const errorMessage = paramObj.errorMessage;
    const responseKeyGetter = paramObj.responseKeyGetter;
    const successContent = paramObj.successContent;

    let queryString = '';
    if (criteria){
        queryString = Object.keys(criteria).map(key => key + '=' + criteria[key]).join('&');
        queryString = "?" + queryString;
    }  
    const urlStr = `${urlRoot}${queryString}`;
     

    const request = axios({
        url : urlStr,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        },
        data: dataObject,
    }); 
    // const request = axios.delete( 
    //     urlStr, 
    //     { 
    //         headers: {'Accept': 'application/json'},
    //     }
    // );
    //  
    return(
        (dispatch) => {
            if (startType) {
                dispatch({
                    type:startType,
                });
            }
            return(
            request.then((response)=>{
                // alert('yes');
                //  
                let successCheck = true;
                if (responseKeyGetter){
                    successCheck = (successContent === responseKeyGetter(response))
                }
                // if (response.status == 200 && response.data === true){
                if (response.status == 200 && successCheck){
                    //  
                    if (sccessType) {
                        dispatch({
                            type:sccessType,
                            payload: response.data
                        });
                    }
                    if (paramObj.callback) {
                        //  
                        dispatch(paramObj.callback(response.data));
                    }
                    if (paramObj.plantSuccessCallback) {
                        paramObj.plantSuccessCallback(response.data);
                    }
                    toast.success(successMessage);
                }else{
                    //  
                    if (failedType) {
                        dispatch({
                            type: failedType,
                            error: errorMessage,
                        });
                    }

                    if (paramObj.errorCallback) {
                        dispatch(paramObj.errorCallback(errorMessage));
                    }

                    if (paramObj.plantErrorCallback) {
                        paramObj.plantErrorCallback(errorMessage);
                    }

                    console.error('Delete error(s) : ' + errorMessage);
                    toast.error(errorMessage);
                }
               
            }).catch((error)=>{
                //  
                if (failedType) {
                    dispatch({
                        type: failedType,
                        error: error.message,
                    });
                }
                if (paramObj.errorCallback) {
                    dispatch(paramObj.errorCallback(error.message));
                }
                if (paramObj.plantErrorCallback) {
                    paramObj.plantErrorCallback(error.message);
                }
                console.error('Delete error(s) : ' + error.message);
                toast.error(error.message);
            })
            )
        }
    );
}

export function processAssetData(AssetData){
    //  
    //  
    let reData = null;
    AssetData && AssetData.forEach((assetClass, asIndex) => {
        // choose asset class propterties
        if (asIndex === 0) {
            reData = [];
        }
        let {asssetClassId: classid, className: classname, assetIcon: asseticon} = assetClass;
        assetClass.asset && assetClass.asset.forEach((asset, aIndex) => {
            // if (aIndex === 0) {
            //     reData = [];
            // }
           
            // choose asset propterties
            let {assetid,assetname,locationlat,locationlng,grouping,description: assetdescription} = asset
            // create new asset object
            let assetObj = {classid, classname, asseticon, assetid, assetname, locationlat, locationlng, grouping, assetdescription};            
            assetObj.attributes = null;
            assetObj.devices = null;
            assetObj.grouping = "weather";
            // put asset meta attribute into new asset attributes array. 
            if (asset.assetattributes){
                assetObj.attributes = asset.assetattributes.map((attr, attrindex)=>{
                    if (attr.attmetaname === ATTRIBUTE_NAME.LAT && attr.value !== undefined && attr.value !== null && attr.value != '0'){
                        assetObj.locationlat = attr.value;
                    }
                    if (attr.attmetaname === ATTRIBUTE_NAME.LNG  && attr.value !== undefined && attr.value !== null && attr.value != '0' ){
                        assetObj.locationlng = attr.value;
                    }
                    if (attr.attmetaname === ATTRIBUTE_NAME.GRP && attr.value !== undefined && attr.value !== null && attr.value != '0' ){
                        assetObj.grouping = attr.value;
                    }
                    //console.log(assetObj.grouping)
                    return (
                        {
                            attributeid: attr.attmetaid,
                            attributename: attr.attmetaname,
                            displayname: attr.attmetaname,
                            value: attr.value,
                            display: true,
                            theme: null,
                            type: ATTRIBUTE_TYPE.META,
                            deviceid: null,
                            msg: null,
                            attritime:null,
                            datatype: null,
                        }
                    );
                });
            }
            if (asset.device && asset.device.length > 0){
                // create and set device array for the new asset object
                let deviceArr = [];
                asset.device.forEach((device, dIndex) => {
                    let deviceObj =  Object.entries(device)
                                           .filter(([key, value]) => (typeof value !== 'object'))
                                           .reduce((obj, [key, value])=> {
                                               obj[key]=device[key];
                                               return obj;
                                           },{})
                    deviceArr.push(deviceObj);
                });
                //  
                //  
                assetObj.devices = deviceArr;

                // pick up the devices that have msgs
                let assetDevices = asset.device.filter((device)=>( device.msgs && device.msgs.length > 0));
                // check msgs for each device and re-orgnize the msgs to convert msg to the new asset object attribute list.
                if (assetDevices && assetDevices.length > 0){
                    // get msgs array of the first device
                    let devide1st = assetDevices[0];
                    // loop msgs array
                    devide1st.msgs.forEach((msg, mIndex)=> {
                        let newAssetObj = JSON.parse(JSON.stringify(assetObj));
                        // loog device list
                        // let timestamp;
                        // let dmsg = msg;
                        let timestamp = msg.timestamp;
                        assetDevices.forEach((device, di)=>{
                            if (di > 0) {
                                // get the same index msg of the current loop device
                                let nmsg = device.msgs[mIndex];
                                let timedate1 = new Date(timestamp);
                                let timedate2 = new Date(nmsg.timestamp);
                                if (timedate2 > timedate1) {
                                    timestamp = nmsg.timestamp;
                                }
                            }
                        });
                        let dmsg = msg;
                        assetDevices.forEach((device, di)=>{
                            // let dmsg = msg;
                            
                            if (di > 0) {
                                // get the same index msg of the current loop device
                                dmsg = device.msgs[mIndex];
                            }
                            //  
                            // add msg to the asset object attribute list.
                            if (dmsg.msg) {
                                //  
                                let newAttrs = Object.entries(dmsg.msg)
                                                     .reduce((array,[key, value])=>{
                                                        if (device.trackable) {
                                                            if (value.attributename === ATTRIBUTE_NAME.LAT){
                                                                newAssetObj.locationlat = value.value;
                                                            }
                                                            if (value.attributename === ATTRIBUTE_NAME.LNG){
                                                                newAssetObj.locationlng = value.value;
                                                            }
                                                        }
                                                          let atrr =  {
                                                              attributeid: value.id,
                                                              attributename: value.attributename,
                                                              displayname: value.displayname,
                                                              value: value.value,
                                                              display: value.display,
                                                              theme: null,
                                                              type: ATTRIBUTE_TYPE.DEVICE,
                                                              deviceid: device.deviceid,
                                                              msg: {msgid:dmsg.msgid, msgtimestamp: dmsg.timestamp},
                                                            //   attritime: dmsg.timestamp,
                                                                // msg: {msgid:dmsg.msgid, msgtimestamp: timestamp},
                                                              attritime: timestamp,
                                                              datatype: value.type,
                                                          }
                                                          array.push(atrr);
                                                          return array;
                                                     },[]);
                                //  
                                // newAssetObj.timestamp = dmsg.timestamp;
                                newAssetObj.timestamp = timestamp;
                                newAssetObj.attributes = [...newAssetObj.attributes, ...newAttrs];
                            }
                            
                        });
                        reData.push(newAssetObj);
                    });
                }else{
                    reData.push(assetObj);
                }
            }
            else{
                reData.push(assetObj);
            }
            
        });
    });
    //  
    //  
    return reData;
}
// ----------------------------------------------------------------------------
// timeData = [{x: value, y: value}, ...]
export function filtXDataset(inputRawData, inputCount){
    if(!inputRawData || inputCount <= 0 || inputRawData.length <= inputCount){
        return inputRawData;
    }
    let inputData = inputRawData.map(item => ({x: new Date(item.x).getTime(), y: Number(item.y)}));
     
    let startX = inputData[0].x;
    let endX = inputData[inputData.length-1].x;
    let interval = 0;
    if (endX > startX){
        interval = (endX - startX)/inputCount;
    }else{
        return inputRawData
    }
     
    let xSeries = [];
    for (let xItem = startX; xItem < (endX + interval); xItem += interval){
        xSeries.push(xItem)
    }
    if (xSeries.length < 2){
        return inputRawData
    }
    console.log('xSeries', xSeries)
    let checkStartIndex = 0;
    let checkEndIndex = 1;
    let processData = [];
    let subX= 0;
    let subY = 0;
    let subCount= 0;
    for (const [i, data]  of inputData.entries()){
        if ( data.x >= xSeries[checkStartIndex] && data.x < xSeries[checkEndIndex]){
            subY += data.y;
            subX += data.x;
            subCount++;
        }else{
            if (subCount > 0 ){
                processData.push({x: new Date(Math.round(subX/subCount)), y: Math.round(10 * subY/subCount)/10});
            }
            subX= 0;
            subY = 0;
            subCount= 0;
            checkEndIndex++;
            let notFound = true;
            for (let i = checkEndIndex; i < xSeries.length; i++) {
                if ( data.x >= xSeries[i-1] && data.x < xSeries[i]){
                    subY += data.y;
                    subX += data.x;
                    subCount++;
                    checkStartIndex = i-1;
                    checkEndIndex = i;
                    notFound = false;
                    break;
                }
            }        
            if(notFound){
                break;
            }    
        }
        if (i === inputData.length - 1) {
            if (subCount > 0 ){
                processData.push({x: new Date(Math.round(subX/subCount)), y: Math.round(10 * subY/subCount)/10});
            }
        }
    }
     
    return processData
    
}
// ----------------------------------------------------------------------------

const SELECTED_COMPANY_KEY = 'selectedCompanyCode';
export function setSelectedCompantToSesstion(companyCode){
    sessionStorage.setItem(SELECTED_COMPANY_KEY, companyCode);
}

export function getSelectedCompantToSesstion(){
    return sessionStorage.getItem(SELECTED_COMPANY_KEY);
}

export function clearSelectedCompantToSesstion(){
    sessionStorage.removeItem(SELECTED_COMPANY_KEY);
}

//------------------------Weather App--------------------------------
export function mapWeatherSeverity(input){
     
    let reSeverity = 0 ;
    const inputBit = hex2bin(input);
    if (inputBit) {
        if (inputBit.charAt(3) === '0'){
            reSeverity = 4;
        }else if (inputBit.charAt(0) === '0') {
            reSeverity = 3;
        }else if (inputBit.charAt(1) === '0') {
            reSeverity = 2;
        }else if (inputBit.charAt(2) === '0') {
            reSeverity = 1;
        }

    }
     
    return reSeverity;
} 
export function splitStatusValue(input){
    let inputStr = String(input).trim();
    if (inputStr.length > 2){
        return {status: 1 , alarm: inputStr.slice(inputStr.length-2)}
    }else{
        return {status: 0 , alarm: inputStr}
    }
}
export function hex2bin(decimal){
     
    // const parsed = parseInt(hex, 16);
    // if(isNaN(parsed)){return null}
    const parsed = parseInt(decimal, 10);
    if(isNaN(parsed)){return null}  
    return parsed.toString(2).padStart(4,'0').slice(-4);
}
export function expandAssetAttributes(data){
    let result = data.reduce(
                    (re, asset)=>{
                        // console.log("RE RE", re, asset)
                        let { assetid, assetname,timestamp, attributes } = asset;
                        let accumulate = attributes.reduce(( ata , attribute)=>{
                                                            let assetObj = {assetid, assetname,timestamp, ...attribute};
                                                            ata.push(assetObj);
                                                            return ata;
                                                        },
                                                        re
                                                     );
                        return accumulate
                    },
                    []
                );
     
    return result;
}
export function ExportCSV (dFile) {
    // if(this.state.downlaodFlag==true){
    //     this.state.downlaodFlag=false;
    // }else{
    //     return;
    // }
    if(dFile.datasheetList!=null && dFile.datasheetList!=undefined){
        var csvData=dFile.datasheetList;
    }else {
        toast.error(dFile.message)
        return;
    }
    
    var fileName='OutputFile';
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // var csvData2 = [
    //      ["firstname", "lastname", "email"],
    //      ["Ahmed", "Tomi", "ah@smthing.co.com"],
    //     ["Raed", "Labes", "rl@smthing.co.com"],
    //      ["Yezzi", "Min l3b", "ymin@cocococo.com"]
    //    ];
    var csvData1=[];
    var csvData2=[];
    var csvData3=[];
    csvData1=Object.values(csvData[0])
    csvData2=Object.values(csvData[1])
    csvData3=Object.values(csvData[2])

    //var csvData1=[csvData[1],csvData[2],csvData[3],csvData[4]];
    const ws1 = XLSX.utils.json_to_sheet(csvData1);
    const ws2 = XLSX.utils.json_to_sheet(csvData2);
    const ws3 = XLSX.utils.json_to_sheet(csvData3);
    
    /////////////////////////////code to delete row//////////////////////
    function ec(r, c){
        return XLSX.utils.encode_cell({r:r,c:c});
      }
    function delete_row(ws, row_index){
        var variable = XLSX.utils.decode_range(ws["!ref"])
        for(var R = row_index; R < variable.e.r; ++R){
          for(var C = variable.s.c; C <= variable.e.c; ++C){
            ws[ec(R,C)] = ws[ec(R+1,C)];
          }
        }
        variable.e.r--
        ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
    }
      ///////////////////////////////////////////////////////////
    delete_row(ws1,0)
    delete_row(ws2,0)
    delete_row(ws3,0)
    const wb = { Sheets: { 'Headers': ws1,'Details': ws2,'SpecialLines': ws3 }, SheetNames: ['Headers','Details','SpecialLines'] };
    const wb1 = { Sheets: { 'Headers': ws1}, SheetNames: ['Headers']};
    const wb2 = { Sheets: { 'Details': ws2}, SheetNames: ['Details']};
    const wb3 = { Sheets: { 'SpecialLines': ws3}, SheetNames: ['SpecialLines']};
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const excelBuffer1 = XLSX.write(wb1, { bookType: 'xlsx', type: 'array' });
    const excelBuffer2 = XLSX.write(wb2, { bookType: 'xlsx', type: 'array' });
    const excelBuffer3 = XLSX.write(wb3, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    const data1 = new Blob([excelBuffer1], {type: fileType});
    const data2 = new Blob([excelBuffer2], {type: fileType});
    const data3 = new Blob([excelBuffer3], {type: fileType});
    //FileSaver.saveAs(data, fileName + fileExtension);
    return [data1,data2,data3,data];       
}
export function fileHandler(file)  {
    //console.log(file)
    let fileObj = file;
    var cols,rows;
    //just pass the fileObj as parameter
    var catchReturn=ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err)           
      }
      else{
        
        cols=resp.cols
        rows=resp.rows
      }
    }); 
    
    return [rows,cols];             
    
}

export function sendSMS(phones, content){
    if(phones && phones.length > 0){
        phones.map(async(phone)=>{
            try{
                console.log('SENDING MESSAGE TO', phone);
                let request = await axios.post(`${MESSAGER_ROOT}`,{
                    notificationId: "123459",
                    accountId: "123458",
                    customerId: "123458",
                    applicationId: "VP",
                    status: "C",
                    sendMethod: "02",
                    receiveAddr: phone,
                    noticContent: content
                })
                const data = request.data;
                console.log(data);
            }catch(err){
                console.log(err);
                // debugger;
            }
        })
    }
}
export function sendEmail(emails, content, subject){
    if(emails && emails.length > 0){
        emails.map(async(email)=>{
            try{
                console.log('SENDING EMAIL TO', email);
                let request = await axios.post(`${MESSAGER_ROOT}`,{
                    notificationId: "123459",
                    accountId: "123458",
                    customerId: "123458",
                    applicationId: "VP",
                    status: "C",
                    sendMethod: "01",
                    receiveAddr: email,
                    noticContent: content,
                    msgfrom: `${SUPPORT_EMAIL}`,
                    subject: subject
                })
                const data = request.data;
                console.log(data);
            }catch(err){
                console.log(err);
                // debugger;
            }
        })
    }
}


