/* eslint-disable */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Map, GoogleApiWrapper, Marker, InfoWindow} from 'google-maps-react';
import {Container, Row, Col, Button, Table, Card, CardBody, Label, Input} from 'reactstrap';
import { compose } from 'redux'

class BasicGeoFenceMapSearch extends Component{
    constructor(props){
        super(props);
        this.initMap = this.initMap.bind(this);
        // this.selectedShap;
        this.myMap = React.createRef();
        this.myInput = React.createRef();
        this.state={
            // testCode: [
            //     {lat: 43.822311404399926, lng: -79.56390763395996},
            //     {lat: 43.82020588194602, lng: -79.55043221586914},
            //     {lat: 43.812402415964726, lng: -79.5622768508789},
            //     {lat: 43.8135172592541, lng: -79.5758380996582}
            // ],
            mapCenter: {lat:43.8148034, lng:-79.5509472},
            toggal1: true,
            toggal2: false,
        }
    }

    markers = [];

    
    componentWillReceiveProps(nextProps){
        // alert('okay');
        if (nextProps.shape && this.myMap && this.myMap.current){
            // alert('yes');
            let mapComponent = this.myMap.current;
            let google = mapComponent.props.google;
            let map = mapComponent.map;
            this.deleteSelectedShape();
            this.setShap(google, map, nextProps.shape);
        }else{
            this.deleteSelectedShape();
        }
    }

    selectedShape; //Google Shap object
    currentPolygonData = null;
    currentRectangleData = null;
    currentCircleData = null;
    currentShapeType = -1

    getCurrentShapeType = () => {
        return this.currentShapeType;
    }

    setSelection = (shape) => {        
        this.selectedShape = shape;
        this.props.setSelection(shape);
    }

    deleteSelectedShape = () => {
        if (this.selectedShape) {
            this.selectedShape.setMap(null);
        }
    }

    setPloygonData = (polygon) => {
        if (polygon.getPaths().getArray() && polygon.getPaths().getArray().length > 0) {
            this.currentPolygonData = [];
            this.currentShapeType = 2;
        }else{
            return;
        }
            
        polygon.getPaths().getArray().forEach((item,index) => {
            let path = [];
            item.getArray().forEach((item, index) => {
                //  
                path.push(item.toJSON());
            });
            this.currentPolygonData.push(path);
        });

        this.props.setPloygonData( this.currentShapeType, this.currentPolygonData);
    }

    setRectangleData = (rectangle) => {
        this.currentShapeType = 1;
        this.currentRectangleData = rectangle.getBounds().toJSON();

        this.props.setRectangleData( this.currentShapeType, this.currentRectangleData);
    }

    setCircleData = (circle) => {
        this.currentShapeType = 0;
        this.currentCircleData = {
            center: circle.getCenter().toJSON(),
            radius: circle.getRadius()
        };

        this.props.setCircleData( this.currentShapeType, this.currentCircleData);
    }

    setShap = (google, map, shape) => {
        // let shape = this.props.shape;
        // alert('test');
        let self = this;
        let newCenter;
        let bounds;
        if (shape && shape != null) {
            // alert('test yes');
            switch(shape.type) {
                case 'circle':
                    const initCircle=new google.maps.Circle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        center: shape.data.center,
                        radius: shape.data.radius,
                        editable: true
                      });
                    google.maps.event.addListener(initCircle, 'radius_changed', function (event) {
                        self.setCircleData(initCircle);     
                         
                    });
                
                    google.maps.event.addListener(initCircle, 'center_changed', function (event) {               
                        self.setCircleData(initCircle);     
                         
                    });
                    bounds = initCircle.getBounds();
                    newCenter = initCircle.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initCircle);
                    this.setCircleData(initCircle); 
                    break;
                case 'rectangle':
                    const initRectangle=new google.maps.Rectangle({
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        bounds: shape.data,
                        editable: true
                      });
                      google.maps.event.addListener(initRectangle, 'bounds_changed', () => {
                          this.setRectangleData(initRectangle);
                           
                      });
                      bounds = initRectangle.getBounds();
                      newCenter = bounds.getCenter();
                      map.setCenter(newCenter);
                      map.fitBounds(bounds);
                      this.setSelection(initRectangle);
                      this.setRectangleData(initRectangle); 
                    break;
                case 'polygon':
                    google.maps.Polygon.prototype.getBoundingBox = function(){
                        var bounds = new google.maps.LatLngBounds();
                        this.getPath().forEach(function(element,index) {
                          bounds.extend(element)
                        });
                        return(bounds);
                    }
                    const initPolygon=new google.maps.Polygon({
                        paths: shape.data,
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                        map: map,
                        editable: true
                    });
                    
                    let paths = initPolygon.getPaths();
                    // let pType = typeof paths;
                    // alert(pType);
                    // alert(Array.isArray(paths));
                    // if (Array.isArray(paths)) {
                    //     alert('true');
                    // }
                    paths && paths.forEach((path) => {
                        google.maps.event.addListener(path, 'insert_at', () => {
                            // alert('insert_at');
                            this.setPloygonData(initPolygon);
                             
                        });
                        google.maps.event.addListener(path, 'remove_at', () => {
                            // alert('remove_at');
                            this.setPloygonData(initPolygon);
                             
                        });
                        google.maps.event.addListener(path, 'set_at', () => {
                            // alert('set_at');
                            this.setPloygonData(initPolygon);
                             
                        });
                    });
                    
                    bounds = initPolygon.getBoundingBox();
                    newCenter = bounds.getCenter();
                    map.setCenter(newCenter);
                    map.fitBounds(bounds);
                    this.setSelection(initPolygon);
                    this.setPloygonData(initPolygon); 
                    break;
            }
        }else{
            // alert('test no');
            // self.deleteSelectedShape();
        }
    }


    initMap(mapProps, map) {
        // alert('load init Map');
        var self = this;
        const {google} = mapProps;
        
        const drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [                  
                //   google.maps.drawing.OverlayType.POLYGON,
                  google.maps.drawing.OverlayType.CIRCLE,
                //   google.maps.drawing.OverlayType.RECTANGLE
                ]
            },
            circleOptions: {
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
                clickable: false,
                editable: true,
                zIndex: 1
              },
            //   rectangleOptions: {
            //     strokeColor: '#FF0000',
            //     strokeOpacity: 0.8,
            //     strokeWeight: 2,
            //     fillColor: '#FF0000',
            //     fillOpacity: 0.35,
            //     clickable: false,
            //     editable: true,
            //     zIndex: 1
            //   },
            //   polygonOptions: {
            //     strokeColor: '#FF0000',
            //     strokeOpacity: 0.8,
            //     strokeWeight: 2,
            //     fillColor: '#FF0000',
            //     fillOpacity: 0.35,
            //     clickable: false,
            //     editable: true,
            //     zIndex: 1
            //   },
            map: map
        });
    
        // var triangleCoords = [
        //     {lat: 25.774, lng: -80.190},
        //     {lat: 18.466, lng: -66.118},
        //     {lat: 32.321, lng: -64.757}
        // ];
    
        // const bermudaTriangle = new google.maps.Polygon({
        //     paths: self.state.testCode,
        //     strokeColor: '#FF0000',
        //     strokeOpacity: 0.8,
        //     strokeWeight: 3,
        //     fillColor: '#FF0000',
        //     fillOpacity: 0.35,
        //     map: map
        // });

        self.setShap(google, map, self.props.shape);
        // --------------------------------------------
            var input = ReactDOM.findDOMNode(self.refs.inputTest);
            var searchBox = new google.maps.places.SearchBox(input);
            // map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
            map.addListener('bounds_changed', function() {
                searchBox.setBounds(map.getBounds());
            });
            searchBox.addListener('places_changed', () => {
                // alert('test');
                var places = searchBox.getPlaces();
                // alert(places.length);
                if (places.length == 0) {
                    return;
                }

                this.markers.forEach(function(marker) {
                    marker.setMap(null);
                });
                this.markers = [];

                for (var i = 0; i < places.length; i++) 
                {
                 //   createMarker(results[i]);
                   var marker = new google.maps.Marker({
                     map: map,
                     position: places[i].geometry.location
                   });
                   this.markers.push(marker);
                }
                map.setCenter(places[0].geometry.location);
            });

        //     var request = {
        //         query: '235 Yorkland Blvd, North York, ON M2J 4Y8',
        //         fields: ['name', 'geometry'],
        //       };

        //     let service = new google.maps.places.PlacesService(map);

        //     service.findPlaceFromQuery(request, function(results, status) {
        //         alert('test');
        //         alert(status);
        //       if (status === google.maps.places.PlacesServiceStatus.OK) {
        //         alert(results.length);  
        //         for (var i = 0; i < results.length; i++) {
        //         //   createMarker(results[i]);
        //           var marker = new google.maps.Marker({
        //             map: map,
        //             position: results[i].geometry.location
        //           });
        //         }
    
        //         map.setCenter(results[0].geometry.location);
        //       }
        //     });
          
    
        //   const createMarker = (place) => {
        //     var marker = new google.maps.Marker({
        //       map: map,
        //       position: place.geometry.location
        //     });
        // }
        // --------------------------------------------
    
            // google.maps.event.addListener(drawingManager, 'circlecomplete', function(circle) {
            //     var radius = circle.getRadius();
            //     alert(radius);
            // });
    
        google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
            var newShape = event.overlay;
            self.deleteSelectedShape();
            self.setSelection(event.overlay);
            google.maps.event.addListener(newShape, 'click', function (e) {
                self.setSelection(newShape);
            });
            if (event.type == 'circle') {
                // var radius = newShape.getRadius();
                // alert(radius);       
                self.setCircleData(newShape);     
                 
                // self.testUpdate();
            }
            if (event.type == 'rectangle'){
                self.setRectangleData(newShape);
                 
            }
            if (event.type == 'polygon'){
                self.setPloygonData(newShape);
                 
            }
        });
    
    }

    // renderTitle(){
    //     return(
    //         <Row>
    //             <Col md={3}>
    //                 <Label style={{display: 'inline'}} ><h4>Device Type :</h4></Label>
    //                 <Label style={{display: 'inline'}} >{this.props.devicetypename}</Label>
    //             </Col>
    //             <Col md={3}>
    //                 <Label style={{display: 'inline'}} ><h4>Device :</h4></Label>
    //                 <Label style={{display: 'inline'}} >{this.props.devicename}</Label>
    //             </Col>
    //             <Col md={3}>
    //                 <Label style={{display: 'inline'}} ><h4>Fence :</h4></Label>
    //                 <Label style={{display: 'inline'}} >{this.props.fencename}</Label>
    //             </Col>
    //         </Row>
    //     )
    // }

    // handleSubmitClick = (e) => {
    //     // alert('submit');
    //      
    //     let data = "";
    //     switch(this.currentShapeType){
    //         case 0:
    //             data = JSON.stringify(this.currentCircleData);
    //             break;
    //         case 1:
    //             data = JSON.stringify(this.currentRectangleData);
    //             break;
    //         case 2:
    //             data = JSON.stringify(this.currentPolygonData);
    //             break;
    //     }
    //     let shapeObj = {
    //         deviceid: this.props.deviceid,
    //         devicename: this.props.devicename,
    //         devicetypeid: this.props.devicetypeid,
    //         devicetypename: this.props.devicetypename,
    //         fenceid: this.props.fenceid,
    //         fencename: this.props.fencename,
    //         geodata: data,
    //         shape: this.currentShapeType
    //     }
    //      
    //      
    //      
    //     this.props.handleSubmitClick(shapeObj);
    // }
    // handleCancleClick = (e) => {
    //     this.props.handleCancleClick(e);
    // }

    render(){
        // const mapStyle = {
        //     width: '100%',
        //     height: '30vh',
        //     position : 'static',
        //     paddingTop: 10
        // }; 
        const mapStyle = {
            width: this.props.width,
            height: this.props.height,
            position : 'static',
            paddingTop: 10
        };   
        return(
            <div>
             {/* <Col md={12}>            
                 <Card>
                     <CardBody > */}
                        {/* <h4>{this.props.fencename}</h4> */}
                        {/* {this.renderTitle()} */}
                        {/* <Row>
                        <Col md={12} >
                        <div style={{padding: 10, backgroundColor: 'white'}}>          */}
                        {/* <Col md={3}> */}
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Input id="pac-input" class="controls" type="text" placeholder="Search Box" ref='inputTest' style={{width:'50%'}}/>     
                        </div>     
                        {/* </Col> */}
                            <Map ref={this.myMap} containerStyle={mapStyle} style={mapStyle}
                                google={this.props.google} 
                                zoom={14}  
                                onReady={this.initMap}
                                myValue = {this.state.title}
                                initialCenter={this.state.mapCenter}
                                center={this.state.mapCenter}
                                >      
                            </Map>                    
                        {/* </div>
                        </Col>
                        </Row> */}
                        
                        {/* <Row>
                        <Col md={12} style={{ textAlign: 'right' }}>
                                <br/>
                                <Button outline variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleCancleClick}>
                                    Cancel
                                </Button> {' '}
                                <Button variant="primary" color="custom" type="button" style={{margin:'auto'}} onClick={this.handleSubmitClick}>
                                    Submit
                                </Button>
                           </Col>
                        </Row> */}
                    {/* </CardBody>
                </Card>
            </Col> */}
            </div>
        )
    }
    
}

// export default GeoFenceMap;
// export default GoogleApiWrapper({
//     apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
//     libraries: ['drawing'],
// })(GeoFenceMapCard);

//
const enhance = compose(
    GoogleApiWrapper({
        // apiKey: 'AIzaSyBU3vMoNMizLEX6tkmbSG8rMMZTJUMxmcM',
        // apiKey: 'AIzaSyDXBuKv70EhwKMyHzrj6wc7_qPQFwmdyB4',
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['drawing','places'],
    })
);

export default enhance(BasicGeoFenceMapSearch);
