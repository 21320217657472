/* eslint-disable */
import React, { Component, Fragment } from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardHeader, CardBody, Label, } from 'reactstrap';
import {Doughnut} from 'react-chartjs-2';
import {loadDeviceInfo} from '../../../../redux/actions/mapActions';
import BasicDashboadEditIcon from './BasicDashboadEditIcon';
import { minWidth } from '@material-ui/system';
import ConfirmDeleteDialog from "../../../../shared/components/Dialog/ConfirmationDialog";
import DeleteIcon from "mdi-react/DeleteIcon";

class DeviceUsageDonutWidget extends Component {
    constructor(props){
        super(props);
        this.state={
        }
    }

    componentDidMount(){
        this.props.loadDeviceInfo();
    }

    componentWillReceiveProps(nextProps){}
    static getDerivedStateFromProps(props, state) {
    
        if (props.currentDeviceList && props.currentDeviceList !== state.rawCurrentDeviceList){
          const total = props.currentDeviceList.length;
          let used = 0;
          props.currentDeviceList.forEach((item,index)=>{
              if (item.assetid && item.assetid > 0){
                  used++;
              }
          });

          const unused = total - used;
          

          return ({
            labels : ['Used', 'Unused'],
            data : [used, unused],
            rawCurrentDeviceList: props.currentDeviceList
          });
        }else{
          return state;
        }
    }

    renderDeviceCountDonut() {
        const data = {
            labels: this.state.labels,
            datasets: [{
                data: this.state.data,
                backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
                ],
                hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56'
                ]
            }]
        };
        return (
            <div>
              <Doughnut 
                data={data} 
                options={{ 
                    maintainAspectRatio: true }}
              />
            </div>
        );
    }
    deleteWidget = (val) => {
        console.log ('val is',val)
        console.log('id in delete is ',this.props.layout.id)

        this.props.onDeleteSubmit(this.props.layout.id);

        // this.setState(prevState=>({
        //     modal: ! prevState.modal,
        // }));
    }
    render() {
        return(
            <Fragment>
            <Card >
                <CardHeader className={'dragBar'}> 
                    {/* <div className="card__title"> */}
                        <h5 className="bold-text">
                            {this.props.dashName}

                            <div style={{float: 'right', display:'inline', cursor: 'pointer'}}>
                                {this.props.layout.locked == 0 && <ConfirmDeleteDialog
                                    // label={""}
                                    icon = {(props)=>(<DeleteIcon size={15} style={{float: 'right', display:'inline', cursor: 'pointer', marginLeft: '10px'}} />)}
                                    style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                    onConfirm={(e)=>{this.deleteWidget()}}
                                    msg={'Do you want to delete the selected widget ? '}
                                />}
                            </div>
                            {/* {this.props.layout.display === 0 && */}
                            <BasicDashboadEditIcon 
                                size={17} style={{float: 'right', display:'inline', cursor: 'pointer'}}
                                layout={this.props.layout}
                                onEditSubmit = {this.props.onEditSubmit}
                            />    
                            {/* } */}
                        </h5>                      
                    {/* </div> */}
                </CardHeader>
                <CardBody style={{padding:15}}>                     
                    {this.renderDeviceCountDonut()}
                </CardBody>  
          </Card>
          </Fragment>
        );
    }
}

function mapStateToProps(state) {    
    const {currentDeviceList} = state.asset;
    const props = {currentDeviceList};
    return props;
};
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({loadDeviceInfo}, dispatch);
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DeviceUsageDonutWidget);
