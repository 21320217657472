/*eslint-disable*/
import React, { Component } from "react";
import  Card from "react-credit-cards";
import uuid from "uuid/v4";
import AddPaymentModal from "./payment/addPaymentModal";
import EditPaymentModal from "./payment/editPaymentModal";
import {Row, Col, Card as Card2, Collapse, CardBody,  CardGroup, Label, Input, Button, Table, CardFooter} from 'reactstrap';
import { useState } from "react";
import PaymentForm from "./payment/paymentForm";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import './mediemcard.scss';
import './smallcard.scss';
import FormControl from '@material-ui/core/FormControl';

class CompanyAccountPaymentComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [
        {
          name: "Evan1",
          number: "5434 **** **** 3414",
          cvc: "434",
          expiry: "03/19",
          id: uuid()
        },
        {
          name: "Evan2",
          number: "4934 **** **** 3415",
          cvc: "435",
          expiry: "03/19",
          id: uuid()
        }
      ],
      accountToPayment: [],
      collapse:false
    };
    this.addCard = this.addCard.bind(this);
    
    
    
  }

  addCard(card) {
   
    let newCard = { ...card, id: uuid() }; //add the id to the new item.
     
     
    
    let defaultData = {
      accId:  this.props.account[0].id,
      pan: newCard.number.replace(/\s/g, ''),
      expdate: "2911",        
      cvd:"123", 
      streetNumber:"235",   
      streetName:"Test Yorkland blvd", 
      zipCode:"M2J4Y8"   
     }
     // SPECIFY CORRECT ATTRIBUTES
     let data = {
        accId:  this.props.account[0].id,
        pan: newCard.number.replace(/\s/g, ''),
        expdate: "2911",        
        cvd:"123", 
        streetNumber:"235",   
        streetName:"Test Yorkland blvd", 
        zipCode:"M2J4Y8"   
     }
     this.props.createNewCard({ ...defaultData, ...data }, this.props.companyObj.companyCode)
  }

  deleteTodo(value){
    let cards = this.state.cards.slice();  
    cards.splice(cards.indexOf(value), 1);
    this.setState({cards});  

     
 }

 showMonerisForm = () =>{
    this.setState({
      collapse: !this.state.collapse,
    })
}


  render() {
    const { cards } = this.state;
     

    const cardsstyle ={
        'margin': '0 auto',
        'max-width': '1280px',
        'text-align': 'center'
      }

    const cardList = {
            'display': 'flex',
            'margin-top': '30px',
            'flex-wrap': 'wrap',
            'justify-content': 'space-between',
         
    } 
    
    const aList = this.props.accountList;
     

     

    const ac = this.props.account.filter(account => account.cardMask);
    this.state.accountToPayment = ac;
    
     
     
    return (
      <div className="App-cards" style={ cardsstyle}>
        <div className="App-cards-list" style={cardList}>
        {/* <CardGroup> */}
          {ac && ac.map(card => (
            <Col md={5}>
            <Card2 style={{ border: '1px solid #D3D3D3', padding: '0px' }}>
              <CardBody>
                <Row>
                <Col md={4}>
                <Card 
                  number={card.cardMask} 
                />
                </Col>
                <Col md={8}>
                  <Row><Col md={3}></Col><Col md={9}><span style={{fontSize:'15px'}}>{card.cardMask}</span></Col></Row>
                  <Row><Col md={3}></Col><Col md={9}><span>Expires </span>{card.expDate}</Col></Row>
                
                </Col>
                </Row>
                {/* <br/> */}
                {/* <Button color="danger" onClick={this.deleteTodo.bind(this, card)}>Remove</Button>{' '} */}
                </CardBody>
                <CardFooter style={{textAlign:'right', padding:'0px 0px 5px 0p',backgroundColor:'white'}}>
                <Button color="link" onClick={this.deleteTodo.bind(this, card)} size="sm" style={{color: 'red', margin: '0px'}}>Remove</Button>{' '}
                <form action="https://esqa.moneris.com/HPPDP/index.php">
                <input hidden type="text" id="fname" name="res_id" value="T9MQBtore5"/>
                <input hidden type="text" id="lname" name="res_key" value="resTJL84UZLA"/>
               
                {/* <CardFooter style={{textAlign:'right', padding:'0px 0px 5px 0p',backgroundColor:'white'}}>  */}
                  {/* <Button color="link" onClick={this.deleteTodo.bind(this, card)} size="sm" style={{color: 'red', margin: '0px'}}>Remove</Button>{' '} */}
                  {/* <EditPaymentModal addCard={this.addCard} acountInfo = {ac} label={'Change'}
                    color="link" size="sm" style={{color: 'blue', margin: '0px'}}
                  ></EditPaymentModal> */}
                  
                <Button color="link" value="Update" type="submit" size="sm" style={{color: 'blue', margin: '0px'}}>Update</Button>{' '}
                {/* </CardFooter> */}
                </form>
                </CardFooter>
                </Card2>
                </Col>
          ))} 
          {/* </CardGroup> */}
        </div>
        { ac == '' &&
          <AddPaymentModal addCard={this.addCard} acountInfo = {ac}></AddPaymentModal>
        }
    <br/><br/>
       <Row>
            <Col md={6}>
              <Collapse isOpen={ this.state.collapse}>
               
            </Collapse>
            </Col>
       </Row>

          
      </div>
    );
  }
}


export default CompanyAccountPaymentComp;

