/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUser } from '../../../redux/actions/userAction';
import {BootstrapTable, ExportCSVButton, TableHeaderColumn} from 'react-bootstrap-table';
import { Trans } from "react-i18next";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, Col, Row, Button, Container } from 'reactstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { ThemeProps } from "../../../shared/prop-types/ReducerProps";
import { getCurrentUser, hasRole } from '../../../factories/auth';
import PropTypes from "prop-types";

class UserClientAccessForm extends Component {
    constructor(props) {
        super(props);
        this.userObj = getCurrentUser();
        this.state = {
            resellerCode: "",
        }
    }
    componentDidMount(){
        this.setState({
            resellerCode: this.props.userObj.resellerCode,
        })
    }

    onRowSelect = (row, isSelected, e) => {
        if(isSelected){
            this.setState({resellerCode:row.resellerCode});
        }
        else{
            this.setState({resellerCode:""});
        }
    }
    onClose = () => {
        this.props.toggle();
    }
    validateForm = () => {
        
        if(this.state.resellerCode == ""){
            return false;
        }
        else{
            return true;
        }
    }
    onSubmit = () =>{

        this.props.updateUser({userName:this.props.userObj.userName,resellerCode:this.state.resellerCode});
        this.onClose();
    }


    render() {
        const selectRowProp = {
            mode: 'radio',
            columnWidth: '40px',
            clickToSelect: true,
            onSelect: this.onRowSelect,
            selected:[this.state.resellerCode]
        };
        const options = {
            sizePerPageList: [ {
                text: '10', value: 10
            }, {
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '500', value: 500
            } ],
        }
        const { theme,user } = this.props;
        return (

            <div className={theme.className}>
                <br />
                <BootstrapTable
                    data={user.resellerList.filter(reseller => reseller.status === 1)}
                    exportCSV
                    csvFileName='User_Client_Access.csv'
                    tableHeaderClass='header_white'
                    selectRow={selectRowProp}
                    pagination
                    options={options}
                    >
                    <TableHeaderColumn dataField='resellerCode'  isKey={true} hidden = {true} filter={{ type: 'TextFilter', placeholder: ' ' }}>Client Name</TableHeaderColumn>
                    <TableHeaderColumn style={{color:"black"}} dataField='resellerName'  filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Client Name'>Client Name</TableHeaderColumn>
                    <TableHeaderColumn style={{color:"black"}} dataField='accountNumber' filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Account'>Account</TableHeaderColumn>
                    <TableHeaderColumn style={{color:"black"}} dataField='mvno'  filter={{ type: 'TextFilter', placeholder: ' ' }} csvHeader='Client Identifier'>Client Identifier</TableHeaderColumn>
                </BootstrapTable>
                <br />
                <hr />
                <div class="d-flex justify-content-end">

                    {this.userObj.companyCode && this.userObj.companyCode == "TEISA" &&
                        <Button className="btn-withhover" outline color='custom' type="button" disabled={!this.validateForm()} onClick={this.onSubmit} >
                        Submit
                        </Button>}
                    
                    {this.userObj.companyCode && this.userObj.companyCode !== "TEISA" &&
                        <Button outline color='custom' type="button" disabled={!this.validateForm()} onClick={this.onSubmit} >
                            Submit
                        </Button>}
                    
                    <Button outline color='secondary' type="reset" onClick={this.onClose} >
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {user:state.user,theme:state.theme};
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserClientAccessForm);